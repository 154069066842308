import {
  AbsentTypes,
  Checkboxes,
  SelectInput,
  SERVICES,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { useFormik } from "formik"
import moment from "moment"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AuthContext } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"
import { japaneseAlphaRegex } from "../../../utils/validation"

const Wrapper = styled.div`
  .operation-title {
    margin: 20px 0;
    border-left: 4px solid ${theme.blue5};
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    padding-left: 12px;
    margin-bottom: 16px;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .operation-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .mb-20 {
      margin-bottom: 20px;
    }
    .mb-12 {
      margin-bottom: 12px;
    }
    .label-option-container {
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
      .operation-label {
        margin-top: 2px;
        width: 150px;
        min-width: 150px;
      }
      .operation-option {
        max-width: 100%;
        span:not(.ant-checkbox):not(.ant-checkbox-inner) {
          white-space: break-spaces;
        }
        @media (max-width: 768px) {
          .ant-checkbox + span {
            white-space: pre-line;
            word-break: break-all;
            padding-left: 15px;
          }
        }
      }
    }
  }
  .multiple-within-multiple {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .multiple-input-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
  }
  .absence-date {
    height: 40px;
  }
  @media print {
    display: none;
  }
`
export interface IAbsenceRecordOperationOptions {
  child_id: number
  facility_ids: Array<any>
  date_from: string
  date_to: string
  services: Array<any>
  absence_types: Array<any>
  page?: number
  pageSize?: number
}

interface IAbsenceRecordOperation {
  defaultOptions: IAbsenceRecordOperationOptions
  childOptions: Array<any>
  updateDisplayParams: (value: any) => void
}

export const AbsenceRecordOperation = ({
  defaultOptions,
  childOptions,
  updateDisplayParams,
}: IAbsenceRecordOperation) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const facilityOptions = facilities.map((facility) => {
    return {
      label: facility?.facility_name_short,
      value: facility?.id,
    }
  })

  const formik = useFormik({
    initialValues: { ...defaultOptions, furigana_sort: null },
    onSubmit: () => {
      updateDisplayParams(formik.values)
    },
  })

  const getChildOptions = () => {
    let options = childOptions
    if (formik.values.furigana_sort) {
      const regex = japaneseAlphaRegex(formik.values.furigana_sort)
      options = childOptions.filter((child) => child.furigana_name.match(regex))
      return options
    }
    return [{ label: "--", value: 0 }].concat(options)
  }

  return (
    <Wrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <div className={"operation-title"}>{t("Bulk setting")}</div>
        <div className={"operation-row"}>
          <div className={"label-option-container mb-20"}>
            <div className={"caret-right operation-label"}>
              {t("Child name")}
            </div>
            <div className={"operation-option"}>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={t("All")}
                  width={75}
                  height={40}
                  value={formik.values.furigana_sort}
                  options={FuriganaAlphabetsOption}
                  onChange={(val) => {
                    formik.setFieldValue("furigana_sort", val)
                  }}
                />
                <SelectInput
                  placeholder={t("All")}
                  width={180}
                  height={40}
                  options={getChildOptions()}
                  value={formik.values.child_id}
                  onChange={(val) => {
                    formik.setFieldValue("child_id", val)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container mb-20"}>
            <div className={"caret-right operation-label"}>
              {t("Narrow date")}
            </div>
            <div className={"operation-option"}>
              <div className={"multiple-input-container"}>
                <DatePicker
                  className={"absence-date"}
                  date={moment(formik.values.date_from)}
                  onDateChange={(val) => {
                    if (val)
                      formik.setFieldValue(
                        "date_from",
                        val.format("YYYY-MM-DD")
                      )
                    else
                      formik.setFieldValue(
                        "date_from",
                        moment().format("YYYY-MM-DD")
                      )
                  }}
                  format={"YYYY年MM月DD日"}
                />
                {"〜"}&nbsp;&nbsp;
                <DatePicker
                  className={"absence-date"}
                  date={moment(formik.values.date_to)}
                  onDateChange={(val) => {
                    if (val)
                      formik.setFieldValue("date_to", val.format("YYYY-MM-DD"))
                    else
                      formik.setFieldValue(
                        "date_to",
                        moment().format("YYYY-MM-DD")
                      )
                  }}
                  format={"YYYY年MM月DD日"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container mb-12"}>
            <div className={"caret-right operation-label"}>
              {t("Display facility")}
            </div>
            <div className={"operation-option"}>
              <div className={"multiple-within-multiple"}>
                <div className={"multiple-input-container"}>
                  <OwnerButton
                    typeOf={"check-all"}
                    text={t("Check All")}
                    onClick={() => {
                      const allFacilities = []
                      facilityOptions.map((f) => allFacilities.push(f.value))
                      formik.setFieldValue("facility_ids", allFacilities)
                    }}
                  />
                  <OwnerButton
                    typeOf={"secondary"}
                    text={t("Uncheck All")}
                    onClick={() => {
                      formik.setFieldValue("facility_ids", [])
                    }}
                  />
                </div>
                <div className={"multiple-input-container"}>
                  <Checkboxes
                    options={facilityOptions}
                    value={formik.values.facility_ids}
                    onChange={(val) => {
                      formik.setFieldValue("facility_ids", val)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container mb-12"}>
            <div className={"caret-right operation-label"}>
              {t("Use service")}
            </div>
            <div className={"operation-option"}>
              <Checkboxes
                options={SERVICES}
                value={formik.values.services}
                onChange={(val) => {
                  formik.setFieldValue("services", val)
                }}
              />
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container mb-12"}>
            <div className={"caret-right operation-label"}>
              {t("Displaying record")}
            </div>
            <div className={"operation-option"}>
              <Checkboxes
                options={AbsentTypes}
                value={formik.values.absence_types}
                onChange={(val) => {
                  formik.setFieldValue("absence_types", val)
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Display change")}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </Wrapper>
  )
}
