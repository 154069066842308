import React from "react"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { Button, theme } from "@project/shared"

const Wrapper = styled.div<any>`
  grid-area: pagination;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  gap: 0.5rem;
  @media screen and (max-width: 426px) {
    gap: 0;
    div:nth-child(2) > button {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: ${({ btnRadius }) => (btnRadius ? btnRadius + "px" : "5px")};
    height: fit-content;
    padding: 5px 5px;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      white-space: pre-wrap;
      word-break: break-all;
      padding: 4px 2px;
      flex-wrap: wrap;
    }
  }
  @media (max-width: ${theme.breakpoints.xxs}) {
    flex-wrap: wrap;
  }
  @media print {
    display: none !important;
  }
`
interface IProps {
  prev: string
  current: string
  next: string
  handleClick: (key: string) => void
  isDisabled?: boolean
  btnRadius?: number
}
export const YearPaginateBtn: React.FC<IProps> = ({
  prev,
  current,
  next,
  handleClick,
  isDisabled,
  btnRadius,
}) => {
  return (
    <Wrapper
      className={"work-record-pagination-container"}
      btnRadius={btnRadius}
    >
      <Button
        className={"left-btn"}
        onClick={() => handleClick("prev")}
        disabled={isDisabled}
      >
        <DoubleLeftOutlined />
        <span className={"left-btn-label"}>{prev}</span>
      </Button>
      <Button
        className={"active active-btn"}
        onClick={() => handleClick("current")}
        disabled={isDisabled}
      >
        {current}
      </Button>
      <Button
        className={"right-btn"}
        onClick={() => handleClick("next")}
        disabled={isDisabled}
      >
        <span className={"right-btn-label"}>{next}</span>
        <DoubleRightOutlined />
      </Button>
    </Wrapper>
  )
}
