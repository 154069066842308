// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  .underline {
    text-decoration: underline;
    color: ${theme.blue5};
  }
  p {
    margin-bottom: 0;
  }

  .error-message {
    color: ${theme.red3};
    font-size: 12px;
    font-weight: 400;
  }

  .footer-button-section {
    margin-top: 30px;
    text-align: center;
    & > * {
      margin-inline: auto;
    }
    .proceed-btn {
      border-radius: 5px;
    }
    .guide-text {
      margin: 0;
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      color: ${theme.black};
    }
  }
`

export const GuideText = styled.ul`
  margin: 0;
  margin-top: 30px;
  margin-left: 24px;
  li {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: ${theme.black};
  }
`

export const BulkPeriodSettingOpSecContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  .quote-text {
    margin-bottom: 13px;
  }

  .field-wrapper_year-month {
    margin-bottom: 24px;
    .range-date-picker-group .faculty-datepicker {
      min-width: 160px;
      .ant-picker {
        width: 100%;
        padding: 9px 14px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      .range-date-picker-group .faculty-datepicker {
        min-width: 100px;
      }
    }
  }

  button {
    white-space: break-spaces;
  }
`

export const ErrorMessage = styled.p`
  color: ${theme.red2};
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0;
`

export const RangeDatePickerGroup = styled.div`
  .date-pickers {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;

    .datePicker-separator {
      font-size: 16px;
      font-weight: 500;
    }
  }
`

export const UsageRecordRewriteUsageContainer = styled.div`
  .operation-rounded-container {
    .multiple-rewrite-form-container {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      margin-top: 24px;
      .title-wrapper {
        margin-bottom: 24px;
      }
      .full-width-label {
        width: 100%;
      }
      .holiday-option-checkbox {
        .ant-checkbox-wrapper {
          margin-bottom: 0;
        }
      }
      .days-of-increased-use-checkbox {
        .checkbox-group .ant-checkbox-group {
          .ant-checkbox-wrapper {
            min-width: 103px;
          }
        }
      }

      .usage-record-form-action-btn-group {
        margin-top: 20px;
        .print-btn {
          background: ${theme.blue5};
          border-color: ${theme.blue5};
          color: ${theme.base};
          margin-right: auto;
        }

        .add-child-btn {
          gap: 10px;
          padding: 7px 24px;
          border-radius: 100px;
          border: 1px solid ${theme.blue5};
          background: ${theme.blue6};
          &:hover {
            filter: brightness(105%);
          }
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }

    @media print {
      box-shadow: none;
    }
  }
`
