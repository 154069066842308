// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const Container = styled.div`
  .underline {
    text-decoration: underline;
    color: ${theme.blue5};
  }
  p,
  ul,
  h4 {
    margin-bottom: 0;
  }
`

export const FilterOperationContainer = styled.div`
  .operation-rounded-container {
    margin-top: 30px;
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`

export const FilterOperationContent = styled.div`
  &.filter-operation-content {
    display: flex;
    margin-top: 24px;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;

    .date-picker {
      padding: 9px 14px;
      max-width: 177px;
      min-width: 140px;
      width: 100%;
      input {
        line-height: 100%;
      }
    }

    .facility-select,
    .child-select {
      .ant-select-selector {
        padding: 4.2px 14px;
        height: auto !important;
      }
    }

    .form-button-group {
      button.search-button {
        padding: 9px 16px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    display: grid;

    .form-button-group {
      button.search-button {
        margin-right: 10px;
      }
    }
    .quote-text {
      margin-bottom: 10px;
    }
    .furigana-child-selector {
      @media (max-width: ${theme.breakpoints.xs}) {
        margin-right: 0px;
      }
    }
  }
`

export const DashedDivider = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  border-bottom: 1px dashed ${theme.gray2};
`

export const FilterBlockExtraContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start !important;
  @media (max-width: ${theme.breakpoints.xs}) {
    .quote-text {
      margin-bottom: 10px;
    }
  }
`

export const ProgressRecordContainer = styled.div`
  .operation-rounded-container {
    margin-top: 30px;

    /* progress record head section styling starts here */
    .table-head {
      justify-content: space-between;
      margin-bottom: 9px;

      .table-title {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        color: ${theme.black};
      }
      .month-controller {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        button {
          padding: 8px 16px;
          height: auto;
          width: auto;
          border-radius: 10px;
          span {
            line-height: 100%;
          }
          &.active {
            background: ${theme.blue6};
            border-color: ${theme.blue6};
          }
          @media (max-width: ${theme.breakpoints.xs}) {
            margin: 10px 10px 0px 0px;
          }
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        .month-controller {
          align-self: flex-end;
        }
      }
    }
    /* progress record head section styling ends here */

    /* progress record body section styling starts here */
    .progress-record-body {
      margin-top: 24px;
      .print-btn {
        border-color: #0782c8;
        background: #0782c8;
        padding: 11px 12px;
        color: ${theme.base};
        margin-bottom: 20px;
      }

      .table-container-custom {
        /* Upper pagination */
        .total_count {
          margin-bottom: 20px;
          .pagination-btns > div {
            margin: 0;
          }
        }

        .ant-table-container {
          border-color: ${theme.gray2};
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          overflow: hidden;
          table {
            border-color: ${theme.gray2};
            thead.ant-table-thead {
              tr th.ant-table-cell {
                background-color: ${theme.gray2};
                border-color: inherit;
                white-space: break-spaces;
                text-align: center;
                padding: 12px 16px;

                &:before {
                  background-color: ${theme.gray3} !important;
                }
              }
            }

            tbody.ant-table-tbody .ant-table-row {
              .ant-table-cell {
                background-color: ${theme.base} !important;
                text-align: center;
                border-color: inherit;
                padding: 8px;
              }
            }
          }
        }

        /* Bottom pagination */
        .bottom-pagination-wrapper {
          margin-top: 20px;
          .pagination-class > div {
            margin: 0;
          }
        }
      }

      /* table styling for print mode starts here*/
      @media print {
        @page {
          margin-left: 0.3in !important;
          margin-right: 0.3in !important;
          margin-top: 0.5in !important;
          margin-bottom: 0 !important;
        }
        .table-container-custom {
          .ant-table-container {
            position: relative;
            border-radius: 0px;
            border-color: ${theme.black};
            margin-left: 2.5px;
            border: none;
            .ant-table-content {
              overflow-x: hidden !important;
            }
            &:before,
            &:after {
              box-shadow: none;
            }
            table {
              border-color: inherit;
              width: 500px !important;
              border-radius: 0px;
              border: none;

              thead.ant-table-thead {
                tr th.ant-table-cell {
                  width: calc(75% / 2);
                  font-size: 9pt;
                  padding: 8px;
                  line-height: 110%;
                  border-color: inherit;
                  background: transparent;
                  border: 0.5px solid ${theme.black};
                  border-top-width: 2px;
                  outline: 1px solid ${theme.black};

                  &:first-child {
                    border-radius: 0;
                    border-left-width: 2px;
                  }

                  &:nth-child(4) {
                    border-right-width: 2px;
                  }

                  &:before {
                    display: none;
                  }
                  &.item-head-cell {
                    width: auto;
                    min-width: 50px;
                  }

                  &.program-category-column {
                    width: 25%;
                  }

                  &.goal-achievement-column {
                    width: 140px;
                    min-width: 140px;
                  }
                }
              }

              tbody.ant-table-tbody {
                .ant-table-row {
                  .ant-table-cell {
                    font-size: 9pt;
                    outline: 1px solid ${theme.black};
                    border: 0.5px solid ${theme.black};

                    &:first-child {
                      border-left-width: 2px;
                    }

                    &:nth-child(4) {
                      border-right-width: 2px;
                    }
                  }

                  &:last-child .ant-table-cell {
                    border-bottom-width: 2px;
                  }
                }

                .ant-table-placeholder td.ant-table-cell {
                  border-right: 2px solid ${theme.black};
                  border-left: 2px solid ${theme.black};
                  border-bottom: 2px solid ${theme.black};
                  outline: none !important;
                  .ant-table-expanded-row-fixed {
                    border: none;
                    &:before {
                      content: "表示できる情報がありません";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      /* table styling for print mode ends here*/
    }

    /* progress record body section styling ends here */
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }

    @media print {
      padding: 0;
      margin-top: 24px;
      box-shadow: none;
      .title-wrapper {
        display: none;
      }
      .progress-record-body {
        margin-top: 0;
        overflow: hidden;
      }
    }
  }
`

export const PrintOnlyHeaderContent = styled.div`
  .heading {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    background-color: #f3f3f3;
  }

  .more-info {
    justify-content: space-between;
    margin: 10px 0;
    p {
      font-size: 14px;
    }
  }
`

export const PseudoEditLink = styled.div`
  text-decoration: underline;
  color: ${theme.gray2};
  cursor: not-allowed;
`
