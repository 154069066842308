import {
  Pagination,
  SelectInput,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Skeleton } from "antd"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { fetchAbsenceRecord } from "../../../services/attendanceTable"
import { OwnerButton } from "../../atoms"
import { AbsenceRecordOperation, AbsenceRecordTable } from "../../molecules"
import { IAbsenceRecordOperationOptions } from "../../molecules/AbsenceRecordOperation"
import { scrollToSelectedElement } from "../../../utils"
import { useRef } from "react"

const PageWrapper = styled.div`
  margin: 20px 0;
  .absence-info-container {
    margin: 30px 0;
    .button-and-paginations {
      margin: 24px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      @media print {
        display: none;
      }
      .buttons-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .back-button {
          border-color: ${theme.blue5};
          background: rgba(7, 130, 200, 0.2);
        }
        .print-button {
          background: ${theme.blue5};
          border: none;
          height: 38px;
        }
      }
      .pagination-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;

        .pagination-page > div {
          margin: 0;
        }
      }
    }
    .print-title {
      display: none;
    }
  }
  .no-data-container {
    margin: 15px 0;
  }
  @media print {
    .absence-info-container {
      .print-title {
        height: 30px;
        background-color: #f3f3f3;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        display: flex;
        align-items: center;
        justify-content: center;
        & > span {
          margin-left: 13px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
    .absence-info-container > div {
      padding: 0;
    }
    .absence-table {
      max-width: 100% !important;
      .break-line.addition-type-cell {
        max-width: 5px !important;
        white-space: pre-wrap !important;
        word-break: break-word !important;
      }
      .min-width-class-remarks {
        min-width: 15px !important;
      }

      table tbody.ant-table-tbody tr td {
        border-bottom-width: 1px !important;
      }
    }
  }
`

interface IAbsenceRecordPage {
  defaultOperationOptions: IAbsenceRecordOperationOptions
  childOptions: Array<any>
  backLink: string
  updateDisplayParams: (value: any) => void
}

const PER_PAGE_OPTIONS = [
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 125,
    value: 125,
  },
  {
    label: 150,
    value: 150,
  },
]

export const AbsenceRecordPage = ({
  defaultOperationOptions,
  childOptions,
  backLink,
  updateDisplayParams,
}: IAbsenceRecordPage) => {
  const { t } = useTranslation()
  const router = useRouter()
  const contentRef = useRef<HTMLDivElement>(null)
  const { data: AbsenceRecords, isLoading } = useQuery(
    ["absence-records", defaultOperationOptions],
    async () => {
      const queries = []
      Object.keys(defaultOperationOptions).map((key) => {
        if (Array.isArray(defaultOperationOptions[key])) {
          if (defaultOperationOptions[key].length) {
            queries.push(`${key}=${defaultOperationOptions[key].join(",")}`)
          }
        } else if (defaultOperationOptions[key]) {
          queries.push(`${key}=${defaultOperationOptions[key]}`)
        }
      })
      const response = await fetchAbsenceRecord(queries.join("&"))
      return response
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (response: any) => {
        // group data for same children
        const groupedData = []
        response?.data?.map((absentData) => {
          const index = groupedData?.findIndex(
            (d) =>
              d?.child_id == absentData?.child_id &&
              d?.facility_id == absentData?.facility?.id
          )
          const absent_record = {
            date: absentData?.date,
            facility_name: absentData?.facility?.facility_name_short,
            attendance_type: absentData?.attendance_type,
            absence_reason: absentData?.absence_reason,
            staff_name: absentData?.staff?.staff_name,
            child_name: absentData?.child?.child_name,
            service:
              absentData?.child?.child_receiving_certificates?.[0]
                ?.service_type,
          }
          if (index == -1) {
            groupedData?.push({
              child_id: absentData?.child_id,
              facility_id: absentData?.facility?.id,
              absent_records: [absent_record],
            })
          } else {
            groupedData[index].absent_records.push(absent_record)
          }
        })
        return {
          count: response?.count,
          data: groupedData,
        }
      },
    }
  )

  const renderButtonsAndPagination = (showpageSize) => (
    <div className={"button-and-paginations"}>
      <div className={"buttons-container"}>
        <OwnerButton
          typeOf={"secondary"}
          className={"back-button"}
          text={`<< ${t("Back")}`}
          onClick={() => {
            router.push(backLink)
          }}
        />
        <OwnerButton
          typeOf={"print"}
          text={t("Print this")}
          className={"print-button"}
          onClick={() => {
            if (typeof window != "undefined") window.print()
          }}
        />
      </div>
      <div className={"pagination-container"}>
        <div className={"pagination-page"}>
          <Pagination
            current={defaultOperationOptions?.page}
            pageSize={defaultOperationOptions?.pageSize}
            total={AbsenceRecords?.count}
            onChange={(page, pageSize) => {
              updateDisplayParams({
                ...defaultOperationOptions,
                page,
                pageSize,
              })
            }}
          />
        </div>
        {showpageSize && (
          <div className={"pagination-per-page"}>
            <SelectInput
              options={PER_PAGE_OPTIONS}
              width={60}
              height={38}
              value={defaultOperationOptions?.pageSize}
              onChange={(val) => {
                updateDisplayParams({
                  ...defaultOperationOptions,
                  page: 1,
                  pageSize: val,
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <PageWrapper>
      <AbsenceRecordOperation
        defaultOptions={defaultOperationOptions}
        childOptions={childOptions}
        updateDisplayParams={(values) => {
          scrollToSelectedElement(contentRef)
          updateDisplayParams(values)
        }}
      />
      <div className={"absence-info-container"} ref={contentRef}>
        <TopRoundedWhiteCard title={t("Absence Record")} noTitleOnPrint>
          {isLoading ? (
            <>
              <br />
              <Skeleton active />
            </>
          ) : AbsenceRecords?.count ? (
            <>
              {renderButtonsAndPagination(true)}
              <div className={"absence-record-table-container"}>
                <div className={"print-title"}>
                  <span>{t("Absence Record")}</span>
                </div>
                {AbsenceRecords?.data?.map((data) => (
                  <AbsenceRecordTable
                    key={data?.id}
                    records={data?.absent_records}
                  />
                ))}
              </div>
              {renderButtonsAndPagination(false)}
            </>
          ) : (
            <div className={"no-data-container"}>{t("No Data Found")}</div>
          )}
        </TopRoundedWhiteCard>
      </div>
    </PageWrapper>
  )
}
