import {
  Checkboxes,
  theme,
  Checkbox,
  PRINT_SETTINGS,
  REMARK_CONTENT,
  ADDITIONAL_ITEM,
  DIGITALSIGN_OPTION,
  PRINT_SETTINGS_VALUES,
  ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED,
  PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED,
  ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED,
  ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED,
  REMARK_CONTENT_VALUES,
  RadioButtons,
  ABSENCE_REASON_DISPLAY_VALUES,
  DIGITALSIGN_VALUES,
  DIGITALSIGN_SUB_OPTION,
} from "@project/shared"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Title } from "../../../utils"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  childIds?: string
  marginBottom?: string
  handleSearch?: (value: string) => void
  setIsButtonClicked?: React.Dispatch<any>
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      year?: any
      month?: any
      content_of_remarks: string[]
      addition_items: any[]
      show_digital_signature: string[]
      print_setting: string[]
      showAbsence: string
      remark_actual_expense: string
      display_absent_reason: string
    }>
  >
  queryParam?: {
    year?: any
    month?: any
    content_of_remarks: string[]
    addition_items: any[]
    show_digital_signature: string[]
    print_setting: string[]
    showAbsence: string
    remark_actual_expense: string
    display_absent_reason: string
  }
  setCurrentPage?: (val: number) => void
  isLoadingData?: boolean
  year?: any
  month?: any
  handleDisplayChange?: ({ year, month }: { year: any; month: any }) => void
  FACILITY_OPTION?: {
    value: string
    label: string
  }[]
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .title-wrap {
    min-width: 125px;
    max-width: 125px;

    @media (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .select-up {
    margin-right: 6px;
  }
  /* @media (max-width: 767px) {
      .select-up {
        width: 100%;
      }
    } */
  @media (max-width: 456px) {
    .search-btn {
      font-size: 14px;
    }
    .reset-button {
      font-size: 14px;
    }
  }
  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .text-field {
    @media (max-width: 769px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .facility-wrapper {
    display: flex;
    .reset-button,
    .set-button {
      height: 30px;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 456px) {
    .facility-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .checkbox-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .info-wrapper {
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
  @media (max-width: 456px) {
    font-size: 14px;
  }
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: pre-wrap;
  width: 100%;
  .ant-checkbox + span {
    white-space: pre-wrap;
  }

  .check-box {
    white-space: pre-wrap;
  }
  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .calender {
    display: flex;
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  .date-picker {
    height: 40px;
    width: 160px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .select-down {
      width: 100%;
      margin-right: 0;
    }
    .select-class {
      margin-top: 5px;
      width: 100%;
    }
  }

  @media (max-width: 590px) {
    .date-picker {
      width: auto;
    }
  }
`

const CustomCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ServiceRecordSheetOperationComponentPrintDetail: React.FC<
  iOperationOptionComponent
> = (props) => {
  const {
    header,
    isLoadingData,
    setQueryParam,
    setIsButtonClicked,
    childIds,
    queryParam,
  } = props
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const [printSetting, setPrintSetting] = useState(queryParam?.print_setting)
  const [remark, setRemark] = useState(queryParam?.content_of_remarks)
  const [additionalItem, setAdditionalItem] = useState(
    queryParam?.addition_items
  )
  const [showAbsence, setShowAbsence] = useState(queryParam?.showAbsence)
  const [digitalSign, setDigitalSign] = useState(
    queryParam?.show_digital_signature
  )
  const [showUnusedFacility, setShowUnusedFacility] = useState(null)
  const [remarkActualExpense, setRemarkActualExpense] = useState(
    queryParam?.remark_actual_expense
  )
  const [displayAbsentReason, setDisplayAbsentReason] = useState(
    queryParam?.display_absent_reason
  )

  const handleSearch = () => {
    setIsButtonClicked(true)
    const params = {
      content_of_remarks: remark,
      addition_items: additionalItem,
      show_digital_signature: digitalSign,
      print_setting: printSetting,
      showAbsence: showAbsence,
      showUnusedFacility: showUnusedFacility,
      remark_actual_expense: remarkActualExpense,
      display_absent_reason: displayAbsentReason,
    }
    setQueryParam((prev) => {
      return {
        ...prev,
        ...params,
      }
    })
    updateParams(
      {
        ...params,
        year: queryParam?.year,
        month: queryParam?.month,
      },
      `/service-provision-record-sheet/print/detail/${childIds}`
    )
  }

  const handleReset = () => {
    setIsButtonClicked(true)
    setPrintSetting([])
    setRemark([])
    setAdditionalItem([])
    setShowAbsence(null)
    setDigitalSign([])
    setShowUnusedFacility(null)
    setRemarkActualExpense(
      ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED[0]?.value
    )
    setDisplayAbsentReason(ABSENCE_REASON_DISPLAY_VALUES[0]?.VALUE)
    const params = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
    setQueryParam((prev) => {
      return {
        ...prev,
        content_of_remarks: null,
        addition_items: null,
        show_digital_signature: null,
        print_setting: null,
        showAbsence: null,
        showUnusedFacility: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        remark_actual_expense:
          ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED[0]?.value,
        display_absent_reason: ABSENCE_REASON_DISPLAY_VALUES[0]?.VALUE,
      }
    })
    updateParams(
      { ...params },
      `/service-provision-record-sheet/print/detail/${childIds}`
    )
  }

  const printSettingOptions = useMemo(() => {
    let finalSetting = []
    PRINT_SETTINGS?.forEach((setting) => {
      finalSetting.push(setting)
      if (
        setting.value ==
          PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE &&
        printSetting?.includes(
          PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE
        )
      ) {
        finalSetting = finalSetting.concat(
          ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED
        )
      }
    })
    if (
      printSetting?.includes(
        PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE
      )
    )
      finalSetting = finalSetting.concat(
        PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED
      )
    return finalSetting
  }, [printSetting])

  const renderPrintConfigurationOptions = () => (
    <CustomCheckboxContainer>
      {printSettingOptions?.map((option) => {
        const isChecked = printSetting?.includes(option.value)
        return (
          <>
            <Checkbox
              key={option.value}
              label={option.label}
              value={option.value}
              checked={isChecked}
              onChange={(e) => {
                let newValues = []
                if (e?.target?.checked) {
                  newValues = [...printSetting]
                  newValues.push(option.value)
                  if (
                    option.value ==
                      PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE &&
                    !newValues.includes(
                      PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE
                    )
                  ) {
                    // default check
                    newValues.push(
                      PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE
                    )
                  }
                } else {
                  newValues = printSetting?.filter((v) => v != option.value)
                }
                setPrintSetting(newValues)
              }}
            />
          </>
        )
      })}
    </CustomCheckboxContainer>
  )

  const handleRemarkChange = (val) => {
    const isChecked = remark?.includes(val)
    let newRemarks = []
    if (isChecked) {
      newRemarks = remark?.filter((v) => v != val)
    } else {
      newRemarks = [...remark]
      newRemarks.push(val)
    }
    setRemark(newRemarks)
  }

  const renderRemarksOptions = () => (
    <CustomCheckboxContainer>
      {REMARK_CONTENT?.map((remarkContent) => {
        const isChecked = remark?.includes(remarkContent.value)
        return (
          <>
            <Checkbox
              key={remarkContent.value}
              label={remarkContent.label}
              value={remarkContent.value}
              checked={isChecked}
              onClick={() => handleRemarkChange(remarkContent.value)}
            />
            {remarkContent.value == REMARK_CONTENT_VALUES.ACTUAL_EXPENSES &&
              isChecked && (
                <RadioButtons
                  options={
                    ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED
                  }
                  value={remarkActualExpense}
                  onChange={(e) => setRemarkActualExpense(e?.target?.value)}
                />
              )}
            {remarkContent.value ==
              REMARK_CONTENT_VALUES.REASON_EXTRA_CHARGE_ABSENTEEISM &&
              isChecked &&
              showAbsence == "1" && (
                <RadioButtons
                  options={
                    ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED
                  }
                  value={displayAbsentReason}
                  onChange={(e) => setDisplayAbsentReason(e?.target?.value)}
                />
              )}
          </>
        )
      })}
    </CustomCheckboxContainer>
  )

  const digitalSignOptions = useMemo(() => {
    let finalOptions = []
    finalOptions = finalOptions.concat(DIGITALSIGN_OPTION)
    if (
      digitalSign?.includes(
        DIGITALSIGN_VALUES.SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE
      )
    )
      finalOptions = finalOptions.concat(DIGITALSIGN_SUB_OPTION)
    return finalOptions
  }, [digitalSign])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Print configuration")} </Text>
        </TitleWrap>
        <SelectWrap>{renderPrintConfigurationOptions()}</SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Absence information")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={showAbsence ? true : false}
            label={t(
              "Absence (No addition)、Display information that does not calculate Absence Support Addition II"
            )}
            onChange={(evt) => {
              setShowAbsence(evt.target.checked ? "1" : null)
            }}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Contents of remarks")} </Text>
        </TitleWrap>
        <SelectWrap>{renderRemarksOptions()}</SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Addition item")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={additionalItem}
            onChange={(values) => setAdditionalItem(values)}
            options={ADDITIONAL_ITEM}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Digital Sign Settings")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={digitalSign}
            onChange={(values) => setDigitalSign(values)}
            options={digitalSignOptions}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={showUnusedFacility}
            label={t("Show unused facilities")}
            className={"check-box"}
            onChange={(evt) => {
              setShowUnusedFacility(evt.target.checked ? "1" : null)
            }}
          />
        </SelectWrap>
      </SearchWrapper>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Switch display")}
          shape={"circle"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ServiceRecordSheetOperationComponentPrintDetail }
