import { Gridder } from "@project/shared"
import { useTranslation } from "react-i18next"

export const ChildInfoGrid = ({ childId, parentId }) => {
  const { t } = useTranslation()
  const items: any[] = [
    {
      title: t("Child Information"),
      iconPath: "/assets/icons/child-care.svg",
      isActive: false,
      routes: `/child/information/${childId}`,
    },
    {
      title: t("Child Recipient Certificate"),
      iconPath: "/assets/icons/daily-report.svg",
      isActive: false,
      routes: `/child/profile/${childId}/recipient-certificate-form`,
    },
    {
      title: t("Facility Application "),
      iconPath: "/assets/icons/service-record.svg",
      isActive: false,
      routes: `/child/profile/facility-application/${childId}`,
    },
    {
      title: t("Parent Information"),
      iconPath: "/assets/icons/user.svg",
      isActive: false,
      routes: `/parents/detail/${parentId}`,
    },
    {
      title: t("Service Provision record / care record"),
      iconPath: "/assets/icons/service-delivery-sheet.svg",
      isActive: false,
      routes: `/user-service-provision-result?childId=${childId}&facility=0`,
    },
    {
      title: t("Service Provision Record Sheet"),
      iconPath: "/assets/icons/service-delivery-sheet.svg",
      isActive: false,
      routes: `/service-provision-record-sheet/detail/${childId}`,
    },
    {
      title: t("Individual Support Plan Related"),
      iconPath: "/assets/icons/individual-support-plan.svg",
      isActive: false,
      routes: `/individual-support-plans?child_id=${childId}&facility=0`,
    },
    {
      title: t("Contract Report"),
      iconPath: "/assets/icons/service-delivery-sheet.svg",
      isActive: false,
      routes: `/contract-report?child=${childId}`,
    },
    {
      title: t("Recurring Schedule"),
      iconPath: "/assets/icons/progress-report.svg",
      isActive: false,
      routes: `/child/profile/recurring-schedule-setting-list/${childId}`,
    },
    {
      title: t("Various Additions & Minutes Management"),
      iconPath: "/assets/icons/PenPaper.svg",
      isActive: false,
      routes: `/minutes-addition`,
    },
  ]
  return (
    <div>
      <Gridder items={items} title={""} />
    </div>
  )
}
