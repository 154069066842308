import { API } from "@project/shared"
import { IRevenueManagementOperationOptions } from "../components/molecules/RevenueManagementOperation"

export interface ITreatmentAdditions {
  fiscalYear: number
  facilityIds?: string | string[]
}

export interface INHIFUserBillingListParams {
  year: number
  month: number
  facilityIds?: string | string[] | number | number[]
  displayContent?: string | string[]
  show_actual_cost_datas?: boolean
  childIds?: string | string[] | number
  is_afterschool?: string | string[] | number | number[]
  load_usage_details?: boolean
  print_page?: string
}

export const getRevenueData = (filters: IRevenueManagementOperationOptions) =>
  API.get(
    `/revenue-management/${filters?.year}/${
      filters?.month
    }?facilityIds=${filters?.facilityIds?.join(",")}`
  )

export const getRevenueGraphData = (
  filters: IRevenueManagementOperationOptions
) =>
  API.get(
    `/revenue-management/graph/${filters?.year}/${
      filters?.month
    }?facilityIds=${filters?.facilityIds?.join(",")}`
  )

export const getTreatmentAdditions = (v) => {
  let url = `/revenue/treatment-improvement-addition/${v.year}`
  if (v.facility_ids) {
    url = `/revenue/treatment-improvement-addition/${
      v.year
    }?facility_ids=${v?.facility_ids?.join(",")}`
  }
  return API.get(url)
}

export const getNHIFUserBillingList = (params: INHIFUserBillingListParams) => {
  let url = `revenue-management/nhif/child_output_table?year=${
    params?.year
  }&month=${params?.month < 10 ? `0${params?.month}` : params?.month}`

  if (params?.facilityIds) {
    url += `&facilityIds=${params.facilityIds}`
  }
  if (params?.displayContent) {
    url += `&displayContent=${params.displayContent}`
  }
  if (params?.show_actual_cost_datas) {
    url += `&show_actual_cost_datas=${params?.show_actual_cost_datas}`
  }
  if (params?.is_afterschool) {
    url += `&is_afterschool=${params?.is_afterschool}`
  }

  return API.get(url)
}

export const getReceiptProvisionDetails = (
  params: INHIFUserBillingListParams
) => {
  const url = `revenue-management/nhif/receipt-provision-details/${params?.year}/${params?.month}?printPage=${params?.print_page}`
  return API.get(url)
}

export const postReceiptProvisionDetails = (data) => {
  const url = `revenue-management/nhif/receipt-provision-details/${data?.year}/${data?.month}`
  return API.post(url, data)
}

export const getNHIFOutputReceiptDetails = (
  params: INHIFUserBillingListParams
) => {
  let url = `revenue-management/nhif/child_output_print_detail?year=${params?.year}&month=${params?.month}`
  if (params?.facilityIds) {
    url += `&facilityIds=${params.facilityIds}`
  }
  return API.get(url)
}

export const getNHIFOutputReceiptDetails_2 = (
  params: INHIFUserBillingListParams
) => {
  let url = `revenue-management/nhif/child_output_print_detail?year=${params?.year}&month=${params?.month}`
  if (params?.facilityIds) {
    url += `&facilityIds=${params.facilityIds?.toString()}`
  }
  if (params?.childIds) {
    url += `&child_ids=${params.childIds}`
  }
  url += `&is_afterschool=${params?.is_afterschool?.toString()}`
  if (params?.load_usage_details) {
    url += `&load_usage_details=true`
  }
  return API.get(url)
}

export const getServiceProvisionResultPerformanceSheet = (queryParams) => {
  const date = queryParams.date.split("-")
  const params = {}
  const url = `/service-provision-record/performance-sheet/${date[0]}/${date[1]}`
  if (queryParams?.facilities?.length > 0) {
    params["facility_ids"] = queryParams?.facilities?.join(",")
  }
  if (queryParams?.usedService?.length > 0) {
    params["service_used"] = queryParams?.usedService?.join(",")
  }
  if (queryParams?.displaySignature?.length > 0) {
    params["display_no_provision_children"] = true
  }
  if (queryParams?.after_school?.length > 0) {
    params["is_afterschool"] = queryParams?.after_school
  }

  return API.get(url, { params })
}

export interface ICSVDownloadProps {
  year: number
  month: number
  facility_id: number
  service_type: number
  receipt_option: number // billing method
  csv_for: number
  option_1?: number
  option_2?: number
  option_3?: number
  option_str_1?: string
  option_str_2?: string
  option_str_3?: string
  child_ids?: string
  days_over?: number
  sibling_upper_limit?: number
  sibling_upper_limit_addition?: number
  sibling_parent_list?: string
  sibling_child_list?: string
  sibling_order_no?: string
  school_closure?: number
  change_burden_max_amount_lists?: string
}

export const getNHIFCSVLink = (payload: ICSVDownloadProps) =>
  API.post(`/receipt`, payload)

export const getNHIFUserBillingListWithoutPaymentCity = (
  params: INHIFUserBillingListParams
) => {
  let url = `revenue-management/nhif/child_output_table_without_payment_city?year=${
    params?.year
  }&month=${params?.month < 10 ? `0${params?.month}` : params?.month}`

  if (params?.facilityIds) {
    url += `&facilityIds=${params.facilityIds}`
  }

  if (params?.is_afterschool) {
    url += `&is_afterschool=${params?.is_afterschool}`
  }

  return API.get(url)
}
