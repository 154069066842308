import {
  ACCEPT_NOT,
  APPLICATION_ACCEPT,
  Checkboxes,
  DISPLAY_NOT,
  Grid,
  MARK_READ_ACTION,
  PageCard,
  RadioButtons,
  RECORD_SETTING,
  Required,
  SEND_NOT,
  SERVICE_PROVISION_RECORD,
  SETTING_CALENDAR,
  SET_NOT,
  SET_UP_NOT,
  SUPPORT_PLAN_OPTION,
  TextField,
  USE_NOT,
  CALANDER_HOURS_MINUTES,
  SelectInput,
  getMonthList,
  getDaysList,
} from "@project/shared"
import { Skeleton } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
// import { useMutation, useQuery } from "react-query"
import * as yup from "yup"
import {
  AuthContext,
  Divisor,
  FormTitle,
  HorizontalInfo,
  Info,
  StyledForm,
} from "../../../utils"
import { Button } from "../../atoms"
import { OwnerButton } from "../../atoms/OwnerButton"
// import {
//   createProvisionalCity,
//   getProvisionalCity,
//   updateProvisionalCity,
// } from "../../../services"

interface iFacilitySetupForm {
  id?: number
}
const Wrapper = styled.div`
  .grid-header {
    padding: 28px 28px 28px 18px;
  }
  .button-wrap {
    margin-top: 30px;
    display: flex;
    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #000000;
  }
  @media (max-width: 1050px) {
    .button-wrap {
      flex-direction: column;
      button {
        :first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  @media (max-width: 874px) {
    .styled-form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .grid-header {
      width: 100%;
    }
    .grid-header ~ div:not(.grid-header) {
      padding-left: 0;
      padding-right: 0;
      border-right: 0;
      width: 100%;
    }
  }
`
const DateWrapper = styled.div<{ isSideMenu: boolean }>`
  display: flex;
  .select {
    margin-right: 5px;
  }
  .ant-select {
    width: 80px;
  }
  @media (max-width: 1360px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 10px;
    }
  }
  @media (max-width: 978px) {
    .ant-select {
      width: 54px;
    }
  }
  ${(props) =>
    props.isSideMenu === false &&
    css`
      flex-direction: column;
      gap: 10px;
    `}
`
const FromDate = styled.div<{ isSideMenu?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${(props) =>
    props.isSideMenu === false &&
    css`
      @media (max-width: 1192px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
      }
    `}
`
const InnerFromDate = styled.div<{ isSideMenu?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const FacilitySetupForm: React.FC<iFacilitySetupForm> = () => {
  const { t } = useTranslation()
  const [facilitySetupLoading] = useState(false)
  const [facilitySetupFetching] = useState(false)
  const router = useRouter()
  const { collapsed } = useContext(AuthContext)

  const initialValues = {
    setting_calendar_items: "",
    facility_application: "",
    application_time: "",
    application_accept_items: "",
    individual_support_plan_check: "",
    service_provision_condition: "",
    service_provision_record: "",
    digital_sign: "",
    individual_support_plan_setting: "",
    individual_support_plan_option: "",
    record_setting: "",
    enter_email: "",
    pick_up_email: "",
    instructor_affiliated_narrow: "",
    information_after_billing: "",
    make_activities_optional: "",
    child_development_support: "",
    fromMonth: null,
    fromDay: null,
    fromHour: null,
    fromMinute: null,
    toMonth: null,
    toDay: null,
    toHour: null,
    toMinute: null,
  }

  const validationSchema = yup.object().shape({
    setting_calendar_items: yup.string(),
    facility_application: yup.string(),
    application_time: yup.string(),
    application_accept_items: yup.string(),
    individual_support_plan_check: yup.string(),
    service_provision_condition: yup.string(),
    service_provision_record: yup.string(),
    digital_sign: yup.array(),
    individual_support_plan_setting: yup.string(),
    individual_support_plan_option: yup.array(),
    record_setting: yup.array(),
    enter_email: yup.string(),
    pick_up_email: yup.string(),
    instructor_affiliated_narrow: yup.string(),
    information_after_billing: yup.string(),
    make_activities_optional: yup.string(),
    child_development_support: yup.string(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      //   mutateProvisionalCity(value)
    },
  })
  return (
    <Wrapper>
      <PageCard
        title={t("My page")}
        style={{ marginTop: "22px" }}
        extra={
          <>
            <span>
              {t("※Please refer to this manual for My Page settings.")}
            </span>
            <Button
              text={t("See Manual")}
              shape={"circle"}
              borderColor={"#D2D1D1"}
            />
          </>
        }
      >
        {facilitySetupLoading || facilitySetupFetching ? (
          <StyledForm>
            <Skeleton />
          </StyledForm>
        ) : (
          <StyledForm className={"styled-form"}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Setting calendar display items")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Checkboxes
                name={"setting_calendar_items"}
                onChange={(value) =>
                  formik.setFieldValue("setting_calendar_items", value)
                }
                options={SETTING_CALENDAR}
                value={formik.values.setting_calendar_items}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Facility application")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={ACCEPT_NOT}
                value={formik.values.facility_application}
                name={"facility_application"}
                onChange={(evt) =>
                  formik.setFieldValue("facility_application", evt.target.value)
                }
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Application time")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DateWrapper isSideMenu={collapsed}>
                <FromDate isSideMenu={collapsed}>
                  <InnerFromDate>
                    <SelectInput
                      options={getMonthList()}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("fromMonth", e)
                      }
                      className={"select"}
                      defaultValues={formik.values.fromMonth || "--"}
                    />
                    <SelectInput
                      options={getDaysList()}
                      className={"select"}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("fromDay", e.target.value)
                      }
                      defaultValues={formik.values.fromDay || "--"}
                    />
                    <div className={"select"}>{t("日の")}</div>
                  </InnerFromDate>
                  <InnerFromDate>
                    <SelectInput
                      options={CALANDER_HOURS_MINUTES.hours}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("fromHour", e)
                      }
                      className={"select"}
                      defaultValues={formik.values.fromHour || "--"}
                    />
                    <div className={"select"}>{t(":")}</div>
                    <SelectInput
                      options={CALANDER_HOURS_MINUTES.minutes}
                      className={"select"}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("fromMinute", e.target.value)
                      }
                      defaultValues={formik.values.fromMinute || "--"}
                    />
                    <div className={"select"}>{t("〜")}</div>
                  </InnerFromDate>
                </FromDate>
                <FromDate isSideMenu={collapsed}>
                  <InnerFromDate>
                    <SelectInput
                      options={getMonthList()}
                      height={40}
                      onChangeValues={(e) => formik.setFieldValue("toMonth", e)}
                      className={"select"}
                      defaultValues={formik.values.toMonth || "--"}
                    />
                    <SelectInput
                      options={getDaysList()}
                      className={"select"}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("toDay", e.target.value)
                      }
                      defaultValues={formik.values.toDay || "--"}
                    />
                    <div className={"select"}>{t("日の")}</div>
                  </InnerFromDate>
                  <InnerFromDate>
                    <SelectInput
                      options={CALANDER_HOURS_MINUTES.hours}
                      height={40}
                      onChangeValues={(e) => formik.setFieldValue("toHour", e)}
                      className={"select"}
                      defaultValues={formik.values.toHour || "--"}
                    />
                    <div className={"select"}>{t(":")}</div>
                    <SelectInput
                      options={CALANDER_HOURS_MINUTES.minutes}
                      height={40}
                      onChangeValues={(e) =>
                        formik.setFieldValue("toMinute", e.target.value)
                      }
                      defaultValues={formik.values.toMinute || "--"}
                    />
                  </InnerFromDate>
                </FromDate>
              </DateWrapper>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Application accept display item")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Checkboxes
                name={"application_accept_items"}
                onChange={(value) =>
                  formik.setFieldValue("application_accept_items", value)
                }
                options={APPLICATION_ACCEPT}
                value={formik.values.application_accept_items}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Individual support plan for printing Check display")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={DISPLAY_NOT}
                value={formik.values.individual_support_plan_check}
                name={"individual_support_plan_check"}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Records of service provision Condition to mark as read")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={MARK_READ_ACTION}
                value={formik.values.service_provision_condition}
                name={"service_provision_condition"}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Digital signature in Service provision record sheet")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={SET_UP_NOT}
                value={formik.values.service_provision_record}
                name={"service_provision_record"}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Allow Digital sign in Service provision record sheet")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Checkboxes
                name={"digital_sign"}
                onChange={(value) =>
                  formik.setFieldValue("digital_sign", value)
                }
                options={SERVICE_PROVISION_RECORD}
                value={formik.values.digital_sign}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Display settings for individual support plans")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={DISPLAY_NOT}
                value={formik.values.individual_support_plan_setting}
                name={"individual_support_plan_setting"}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Individual support plan display options")}
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Info style={{ marginBottom: "10px" }}>
                {t("Display contents")}
              </Info>
              <Checkboxes
                name={"display"}
                onChange={(value) =>
                  formik.setFieldValue("individual_support_plan_option", value)
                }
                options={SUPPORT_PLAN_OPTION}
                value={formik.values.individual_support_plan_option}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Service provision record")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Record settings for service \nprovision record")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <Checkboxes
                  name={"record_setting"}
                  onChange={(value) =>
                    formik.setFieldValue("record_setting", value)
                  }
                  options={RECORD_SETTING}
                  value={formik.values.record_setting}
                />
                <div className={"button-wrap"}>
                  <OwnerButton
                    typeOf={"secondary"}
                    className={"cancel-btn"}
                    text={t("保護者向けマイページのプレビュー")}
                  />

                  <OwnerButton
                    typeOf={"secondary"}
                    text={t("印刷時のプレビュー")}
                  />
                </div>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Service provision check items")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <OwnerButton
                  typeOf={"primary"}
                  text={t("Register new service provision check items")}
                  onClick={() => router.push("/service-provisions/add")}
                />
                <Info>
                  {t(
                    "*If you have registered a new service provision check item, please refresh the page once."
                  )}
                </Info>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Receipt email setting")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Enter/exit email")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <RadioButtons
                  name={"enter_email"}
                  onChange={formik.handleChange}
                  options={SEND_NOT}
                  value={formik.values.enter_email}
                />
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Pick-up email")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <RadioButtons
                  name={"pick_up_email"}
                  onChange={formik.handleChange}
                  options={SEND_NOT}
                  value={formik.values.pick_up_email}
                />
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Management page setting")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Instructor affiliated facility \nnarrowing setting")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <RadioButtons
                  name={"instructor_affiliated_narrow"}
                  onChange={formik.handleChange}
                  options={SET_NOT}
                  value={formik.values.instructor_affiliated_narrow}
                />
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t(
                      "Information after billing information \nis confirmed prevent editing"
                    )}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <RadioButtons
                  name={"information_after_billing"}
                  onChange={formik.handleChange}
                  options={SET_NOT}
                  value={formik.values.information_after_billing}
                />
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Facility application settings")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t(
                      "Participate when applying for \nfacilities Make activities optional"
                    )}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <RadioButtons
                  name={"make_activities_optional"}
                  onChange={formik.handleChange}
                  options={USE_NOT}
                  value={formik.values.make_activities_optional}
                />
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t(
                "Estimated sales per person for day service such as after school"
              )}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t(
                      "Child development support \nEstimated sales per person"
                    )}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"child_development_support"}
                    value={formik.values.child_development_support}
                    onChange={formik.handleChange}
                    maxLength={30}
                    placeholder={t("11000")}
                    className={"half-field"}
                  />
                  <Info className={"info"}>
                    {t("Yen (Up to 6 digit numbers)")}
                  </Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <div className={"button-wrapper"}>
              <OwnerButton
                typeOf={"secondary"}
                onClick={() => router.push("/facility")}
                className={"cancel-btn"}
                text={t("Cancel")}
              />

              <OwnerButton
                type={"submit"}
                typeOf={"primary"}
                text={t("Save")}
              />
            </div>
          </StyledForm>
        )}
      </PageCard>
    </Wrapper>
  )
}

export { FacilitySetupForm }
