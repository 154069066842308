import { API } from "@project/shared"

export const getAdditionItemManagementFacilityById = async (
  facility_id: string,
  date: string
) => {
  return API.get(`/facility-addition-setting-management/${facility_id}/${date}`)
}

export const bulkCreateUpdateAdditionItemFacility = async (values: any) => {
  return API.post("/facility-addition-setting-management/bulk", values)
}

export const getAdditionItemManagementList = async (
  facility_id: number,
  year: number,
  month: number
) => {
  return API.get(
    `/facility-addition-setting-management/${facility_id}?year=${year}&month=${month}`
  )
}
