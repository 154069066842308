import styled from "styled-components"

export const Wrapper = styled.div<any>`
  display: flex;
  overflow: ${(props) => props?.overflow || "scroll"};
  align-items: center;
  border: 1px solid #fdd95f;
  border-radius: 12px;
  padding: 20px;
  .blue {
    background: #cde6f4;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .yellow-box.box2 {
    background-color: #fdd95f;
  }
  .box1 {
    display: inline;
    padding: 8px 5px 8px 10px;
    gap: 10px;
    height: 36px;
    z-index: 100;
  }
  .poly {
    display: flex;
    align-items: center;
    * {
      width: auto;
    }
  }
  .polygon {
    transform: rotate(45deg);
    width: 24px;
    height: 24px;
  }
  .polygon1 {
    position: relative;
    right: 11px;
    z-index: 1;
  }
  .count-wrapper {
    margin-left: 10px;
    margin-right: 35px;
  }
  .yellow {
    background: #fdd95f;
    min-width: 80px;
  }
  .box2 {
    border-radius: 10px;
    padding: 14px 12px;
    margin-right: 10px;
  }
  .poly-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  @media (max-width: 768px) {
    * {
      font-size: 14px;
    }
    small {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 1172px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }
  @media print {
    display: flex;
    padding: 2px 20px !important;
    .box1,
    .box2 {
      font-size: 12px !important;
      height: 30px;
      padding: 4px;
    }
  }
`
