import {
  SelectInput,
  TextField,
  Checkboxes,
  DISPLAY_CONTENT,
  STATUS,
  theme,
  getLocalstorageItem,
} from "@project/shared"
import { Typography } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import React, { FormEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { fetchParent, getAllChildUnpaginated } from "../../../services"
import { AuthContext, Highlighter, Title, TitleWrapper } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iContactOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  setIsButtonClicked?: React.Dispatch<any>
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      keyword: string
      dateOfContact: string
      facilityIds: string
      childId: string
      status: string
      parentId: string
      displayContent: string
    }>
  >
  queryParam?: {
    keyword: string
    dateOfContact: string
    facilityIds: string
    childId: string
    status: string
    parentId: string
    displayContent: string
  }
  isLoadingData?: boolean
  setCurrentPage?: (val: number) => void
}

const Wrapper = styled.form<iContactOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }

  @media screen and (max-width: 426px) {
    .child-search-wrapper {
      margin-top: -23px;
      margin-bottom: -3px;
    }
  }

  .field-error:empty {
    display: none;
  }

  .field-error:not(:empty) {
    display: block;
  }

  .select-up {
    margin-right: 6px;
    width: 300px;
  }
  .title-wrap {
    min-width: 150px;
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 555px) {
      width: 100%;
    }
  }
  @media (max-width: 405px) {
    width: 100%;
    .select-up {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }

  .facility-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    @media screen and (max-width: 426px) {
      width: 100%;
      gap: 10px;
      flex-direction: row !important;
      .check-button {
        padding: 12px 12px !important ;
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    &:last-child {
      margin-left: 10px;
    }
  }
  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  .select-wrapper {
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
  }
  .text-field {
    width: 712px;
  }
  .calender {
    display: flex;
    align-items: center;
    gap: 9px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  @media (max-width: 800px) {
    .text-field {
      width: 100%;
    }
  }
  @media (max-width: 555px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
    .select-wrapper {
      width: 100%;
    }
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 426px) {
    .select-wrapper {
      flex-direction: column;
      width: 100%;
    }
    .long-select {
      width: 100%;
    }
    .select-down {
      margin-right: 0px;
      min-width: 80px;
      max-width: 80px;
    }
    .ant-select {
      width: 100%;
    }
  }
  .flex-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .check-boxes {
    .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 555px) {
        flex-direction: column;
      }
    }
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
    @media (max-width: 768px) {
      .ant-checkbox + span {
        white-space: pre-line;
        word-break: break-all;
        padding-left: 10px;
      }
    }
  }

  .status-check-box .checkbox-group .ant-checkbox-group .ant-checkbox-wrapper {
    span:last-child {
      white-space: nowrap;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`
const Text = styled(Title)`
  font-size: 16px;
`

const ContactOperationComponent: React.FC<iContactOptionComponent> = (
  props
) => {
  const { header, setQueryParam, setCurrentPage } = props
  const { t } = useTranslation()
  const router = useRouter()
  const {
    parentId: pId,
    childId: cId,
    facilityIds: fIds,
    status: sId,
    dateOfContact: date,
    keyword: k,
    displayContent: dispaly,
  } = router?.query as any
  const facilitiesIds = fIds || getLocalstorageItem("contact", "facilityIds")
  const [check, setCheck] = useState(
    facilitiesIds?.split(",")?.map((v) => +v) || null
  )
  const [status, setStatus] = useState(
    sId?.split(",") || (dispaly == "2" ? ["0", "1"] : ["0", "1", "2"])
  )
  const { facilities } = useContext(AuthContext)
  const [keyword, setKeyword] = useState(k || "")
  const [parentName, setParentName] = useState(null)
  const [parentId, setParentId] = useState(+pId || null)
  const [childId, setChildId] = useState(+cId || null)
  const [childName, setChildName] = useState(null)
  const [displayContent, setDisplayContent] = useState(
    dispaly || (DISPLAY_CONTENT ? DISPLAY_CONTENT[0]?.value : "1")
  )
  const [facilityIds, setFacilityIds] = useState(
    fIds?.split(",")?.map((v) => +v) || ""
  )
  const [otherParentFacilities, setOtherParentFacilities] = useState([])
  const [otherFacilities, setOtherFacilities] = useState([])
  const [dateOfContact, setDateOfContact] = useState<{ from: any; to: any }>({
    from: date?.split("_")[0] || "",
    to: date?.split("_")[1] || "",
  })
  const [updateParams] = useUpdateSearchParams()
  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const allFacility = FACILITY_OPTION.map((item) => item.value)

  const handleSearch = () => {
    const getStatus = STATUS(displayContent)
      ?.map((el) => el?.value)
      ?.join(",")
    const params = {
      parentId: parentId || "",
      childId: childId || "",
      displayContent: displayContent,
      facilityIds: Array.isArray(facilityIds)
        ? facilityIds?.join(",")
        : facilityIds,
      status: Array.isArray(status) ? status?.join(",") : status || getStatus,
      dateOfContact: `${
        moment(dateOfContact.from).isValid()
          ? moment(dateOfContact.from).format("YYYY-MM-DD")
          : null
      }_${
        moment(dateOfContact.to).isValid()
          ? moment(dateOfContact.to).format("YYYY-MM-DD")
          : null
      }`,
      keyword: keyword,
    }
    setCurrentPage(1)
    setQueryParam({
      ...params,
      childId: String(params?.childId),
      parentId: String(params?.parentId),
      dateOfContact:
        params?.dateOfContact === "null_null" ? null : params?.dateOfContact,
    })
    updateParams(
      {
        ...params,
        dateOfContact:
          params?.dateOfContact === "null_null" ? null : params?.dateOfContact,
      },
      "/contact"
    )
  }
  const handleReset = () => {
    setKeyword("")
    setCheck(null)
    setStatus(["0", "1", "2"])
    setParentId(null)
    setParentName(0)
    setChildId(null)
    setDisplayContent("1")
    setChildName(0)
    setFacilityIds("")
    setDateOfContact({
      from: "",
      to: "",
    })
    setOtherFacilities(OTHER_CHILD_OPTIONS)
    setCurrentPage(1)
    setQueryParam((prev) => {
      return {
        ...prev,
        displayContent: "1",
        parentId: null,
        childId: null,
        facilityIds: null,
        status: null,
        dateOfContact: null,
        keyword: "",
      }
    })
    updateParams({}, "/contact")
  }
  const SORT_OPTION = FuriganaAlphabetsOption

  const { isLoading: isLoadingParent, data: parentList } = useQuery(
    ["fetch-all-parent", "1", null, "Infinity"],
    fetchParent,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const OTHER_PARENT_OPTIONS =
    !isLoadingParent &&
    parentList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.parent_name || "", //Check for Furigana name first
        furigana: item?.parent_name_furigana,
      }
    })

  !isLoadingParent &&
    OTHER_PARENT_OPTIONS?.unshift({
      value: 0,
      label: "---",
    })

  const handleSortParent = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)
    const filteredFacilities =
      value == 0
        ? OTHER_PARENT_OPTIONS
        : OTHER_PARENT_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )
    setParentName(value)
    setOtherParentFacilities(filteredFacilities)
  }

  useEffect(() => {
    setParentName(0)
    setOtherParentFacilities(OTHER_PARENT_OPTIONS)
  }, [isLoadingParent])

  const { isLoading, data: childList } = useQuery(
    ["fetch-all-child"],
    getAllChildUnpaginated,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )
  const OTHER_CHILD_OPTIONS = childList?.data?.map((item) => {
    return {
      value: item?.id || "",
      label: item?.child_name || "", //Check for Furigana name first
      furigana: item?.child_name_furigana,
    }
  })

  !isLoading &&
    OTHER_CHILD_OPTIONS?.unshift({
      value: 0,
      label: "---",
    })

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)
    const filteredFacilities =
      value == 0
        ? OTHER_CHILD_OPTIONS
        : OTHER_CHILD_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.furigana?.match(regex)
          )
    setChildName(value)
    setOtherFacilities(filteredFacilities)
  }
  useEffect(() => {
    setChildName(0)
    setOtherFacilities(OTHER_CHILD_OPTIONS)
  }, [isLoading, childList])
  return (
    <Wrapper
      {...props}
      onSubmit={(e: FormEvent) => {
        e.preventDefault()
        handleSearch()
      }}
    >
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>

      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display content switching")} </Text>
        </TitleWrap>
        <SelectInput
          height={40}
          options={DISPLAY_CONTENT}
          className={"select-up"}
          onChange={(evt) => {
            const getStatus = STATUS(String(evt))?.map((el) => el?.value)
            setStatus([...getStatus])
            setDisplayContent(String(evt))
          }}
          value={displayContent}
        />
      </SearchWrapper>

      <SearchWrapper className={"parent-search-wrapper large-select"}>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Parent name")} </Text>
        </TitleWrap>
        <div className={"select-wrapper"}>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            className={"select-down"}
            onChange={(value) => {
              handleSortParent(value)
              setParentName(value)
            }}
            value={parentName}
            placeholder={"---"}
          />
          <SelectInput
            width={200}
            height={40}
            options={otherParentFacilities}
            className={"long-select"}
            onChange={(value) => setParentId(value)}
            value={parentId}
            placeholder={t("All")}
            clear
          />
        </div>
      </SearchWrapper>

      <SearchWrapper className={"child-search-wrapper large-select"}>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Child name")} </Text>
        </TitleWrap>
        <div className={"select-wrapper"}>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            onChange={(value) => {
              handleSort(value)
              setChildName(value)
            }}
            className={"select-down"}
            value={childName}
            placeholder={"---"}
          />
          <SelectInput
            width={200}
            height={40}
            options={otherFacilities}
            onChange={(value) => setChildId(value)}
            className={"long-select"}
            value={childId}
            placeholder={t("All")}
            clear
          />
        </div>
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Facility ")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            className={"check-button"}
            text={t("Check all")}
            onClick={() => {
              const facilityValuesIds = allFacility.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"check-button"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds("")
              setCheck(null)
            }}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}></TitleWrap>
        <Checkboxes
          key={"footerInformation"}
          name={"footerInformation"}
          value={check}
          onChange={(values) => {
            const facilityValuesIds = values.join(",")
            setFacilityIds(facilityValuesIds)
            setCheck(values)
          }}
          options={FACILITY_OPTION}
        />
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Status")} </Text>
        </TitleWrap>
        <div className={"flex-row status-check-box check-boxes"}>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={status}
            onChange={(values) => {
              const statusList = values.join(",")
              setStatus(statusList)
            }}
            options={STATUS(displayContent)}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Date of contact")} </Text>
        </TitleWrap>
        <div className={"calender"}>
          <DatePicker
            onDateChange={(val) =>
              setDateOfContact({
                ...dateOfContact,
                from: moment(val),
              })
            }
            date={
              moment(dateOfContact?.from).isValid()
                ? moment(dateOfContact?.from)
                : null
            }
            format={"YYYY年MM月DD日"}
            style={{ width: "150px" }}
          />
          <Typography.Text>{"~"}</Typography.Text>
          <DatePicker
            onDateChange={(val) =>
              setDateOfContact({
                ...dateOfContact,
                to: moment(val),
              })
            }
            date={
              moment(dateOfContact?.to).isValid()
                ? moment(dateOfContact?.to)
                : null
            }
            format={"YYYY年MM月DD日"}
            style={{ width: "150px" }}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Keyword")} </Text>
        </TitleWrap>
        <TextField
          name={"company_name"}
          height={"50px"}
          placeholder={t("Message keyword received from parents")}
          bgcolor={"#fff"}
          className={"text-field"}
          value={keyword}
          onChange={(evt) => setKeyword(evt.target.value)}
        />
      </SearchWrapper>
      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ContactOperationComponent }
