import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
//libraries
import { Tabs } from "antd"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
//components
import { PageCard, theme } from "@project/shared"
import {
  FacilityApplicationPrintOperation,
  YearPaginateBtn,
} from "../../molecules"
import ApplicationLegend from "../../molecules/FacilityApplications/ApplicationLegend"
import { FacilityApplicationBulkPrint } from "./FacilityApplicationBulkPrint"
import { OwnerButton } from "../../atoms"

//styles
import {
  ButtonsContainer,
  WrapperContainer,
} from "./FacilityApplicationPrint.styles"
import { useQuery } from "react-query"
import { getChildById } from "../../../services/monitoring"
import { getIndividualFacility } from "../../../services/facilityApplication"
import { getDateStringWithDay, scrollToSelectedElement } from "../../../utils"
import moment from "moment"
import { FacilityApplicationsList } from "./FacilityApplicationsPrintList"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

export const FacilityApplicationPrint = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const mode = router?.query?.mode as string
  const childID = +(router.query.id as string)
  const queryYear = router.query.year
  const queryMonth = router.query.month
  const displaySetting = router?.query?.display_settings as string
  const currentDate = new Date()
  const divRef = useRef<HTMLDivElement>(null)
  const componentRef = useRef()
  const [isChangeMade, setIsChangeMade] = useState(false)

  const displayOptions =
    mode === "list"
      ? [
          {
            label: t("Applied facility"),
            value: "1",
          },
          {
            label: t("Actual cost"),
            value: "2",
          },
          {
            label: t("Transportation (pick)"),
            value: "3",
          },
          {
            label: t("Transportation (Drop)"),
            value: "4",
          },
          {
            label: t("Transportation place (Pick)"),
            value: "5",
          },
          {
            label: t("Transportation place (Drop)"),
            value: "6",
          },
          {
            label: t("Pick up desire time"),
            value: "7",
          },
          {
            label: t("Drop off desire time"),
            value: "8",
          },
          {
            label: t("Remarks"),
            value: "9",
          },
          {
            label: t("Cancel waiting"),
            value: "10",
          },
        ]
      : [
          {
            label: t("Applied facility"),
            value: "1",
          },
          {
            label: t("Event"),
            value: "2",
          },
          {
            label: t("Pickup"),
            value: "3",
          },
          {
            label: t("Drop"),
            value: "4",
          },
          {
            label: t("Pick up desire time"),
            value: "5",
          },
          {
            label: t("Drop off desire time"),
            value: "6",
          },
          {
            label: t("Remarks"),
            value: "7",
          },
          {
            label: t("Service used"),
            value: "8",
          },
        ]

  const getAllDisplayOptions = () => {
    const allDisplayOptions = []
    displayOptions.map((opt) => allDisplayOptions?.push(opt.value))
    return allDisplayOptions
  }

  const [searchParams, setSearchParams] = useState({
    childId: childID,
    year: +queryYear ?? currentDate.getFullYear(),
    month: +queryMonth ?? currentDate.getMonth() + 1,
    display_settings: displaySetting || getAllDisplayOptions(),
  })
  useEffect(() => {
    if (isChangeMade) {
      scrollToSelectedElement(divRef)
    }
  }, [isChangeMade])
  const { data: childData } = useQuery(
    ["fetchChildById", childID],
    getChildById,
    {
      cacheTime: 0,
      retry: 1,
      enabled: !!childID,
      refetchOnWindowFocus: false,
      select: (data) => ({ ...data?.data }),
    }
  )
  const childName = childData?.child?.child_name || ""

  const {
    data: facilityData,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(
    ["getIndividualFacility", searchParams],
    () =>
      getIndividualFacility(
        searchParams?.childId,
        searchParams?.year,
        searchParams?.month
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      select: ({ data }) => data,
      onSuccess: () => {
        if (isChangeMade) {
          setIsChangeMade(false)
        }
      },
    }
  )

  const updateURl = (year: number, month: number) => {
    updateParams(
      { year, month, mode: mode },
      `/child/profile/facility-application/${searchParams?.childId}/print`
    )
  }

  const handleMonthChange = (key: string) => {
    setIsChangeMade(true)
    if (key === "prev") {
      if (searchParams.month <= 1) {
        setSearchParams({
          ...searchParams,
          year: searchParams?.year - 1,
          month: 12,
        })
        updateURl(searchParams?.year - 1, 12)
      } else {
        setSearchParams({
          ...searchParams,
          month: searchParams?.month - 1,
        })
        updateURl(searchParams?.year, searchParams?.month - 1)
      }
    } else if (key === "current") {
      setSearchParams({
        ...searchParams,
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
      })
      updateURl(currentDate.getFullYear(), currentDate.getMonth() + 1)
    } else {
      if (searchParams?.month >= 12) {
        setSearchParams({
          ...searchParams,
          year: searchParams?.year + 1,
          month: 1,
        })
        updateURl(searchParams?.year + 1, 1)
      } else {
        setSearchParams({
          ...searchParams,
          month: searchParams?.month + 1,
        })
        updateURl(searchParams?.year, searchParams?.month + 1)
      }
    }
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const printAttendanceCalendar = () => {
    handlePrint()
  }
  const buttons = (type?: string) => {
    return (
      <ButtonsContainer>
        {type === "btm" ? (
          <OwnerButton
            text={t("<< Back")}
            shape={"round"}
            typeOf={"secondary"}
            onClick={() => router.back()}
          />
        ) : (
          <OwnerButton
            text={t("Back")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => router.back()}
          />
        )}
        <div>
          <OwnerButton
            text={t("Print")}
            shape={"round"}
            typeOf={"print"}
            onClick={printAttendanceCalendar}
            className={"print_btn"}
          />

          <OwnerButton
            text={mode === "list" ? t("カレンダー表示") : t("一覧表示")}
            shape={"round"}
            typeOf={"print"}
            className={"print_list_btn"}
            onClick={() => {
              if (mode === "list") {
                router.push(
                  `/child/profile/facility-application/${childID}/print?mode=calender&year=${searchParams?.year}&month=${searchParams?.month}`
                )
              } else {
                router.push(
                  `/child/profile/facility-application/${childID}/print?mode=list&year=${searchParams?.year}&month=${searchParams?.month}`
                )
              }
            }}
          />
        </div>
      </ButtonsContainer>
    )
  }
  const translationKey =
    mode === "calender"
      ? "{{childName}}さん’s {{date}} print facility application list"
      : "{{childName}}さん’s {{date}} facility application print"

  return (
    <WrapperContainer>
      <FacilityApplicationPrintOperation
        queryParams={searchParams}
        setSearchParams={(val) => {
          setSearchParams({ ...val })
          setIsChangeMade(true)
        }}
        displayOptions={displayOptions}
      />
      <PageCard
        title={t(translationKey, {
          childName,
          date: getDateStringWithDay(
            moment(`${searchParams?.year}-${searchParams?.month}`)
          ),
        })}
        className={"content__container"}
        toRight
        extra={
          <YearPaginateBtn
            prev={t("Prev month")}
            current={t("This month")}
            next={t("Next month")}
            handleClick={(val) => handleMonthChange(val)}
          />
        }
      >
        <div
          style={{
            width: "100%",
          }}
          ref={divRef}
        >
          <Tabs
            type={"card"}
            items={[
              {
                label: (
                  <span style={{ color: theme.black }}>
                    {t(childData?.info?.service_to_use)}
                  </span>
                ),
                key: "1",
                children: (
                  <div className={"tab_content"} ref={componentRef}>
                    <div
                      className={"print_page_time"}
                      style={{ marginBottom: "8px" }}
                    >
                      {t(
                        "{{childName}}さん {{date}}" +
                          "の" +
                          t("Facility application list"),
                        {
                          childName,
                          date: getDateStringWithDay(
                            moment(
                              `${searchParams?.year}-${searchParams?.month}`
                            )
                          ),
                        }
                      )}
                    </div>
                    <div className={"legend__content"}>
                      <ApplicationLegend
                        overflow={"hidden"}
                        applicationCount={
                          facilityData?.summary?.application_count || 0
                        }
                        contractCount={facilityData?.summary?.contract_days}
                        facilityName={
                          childData?.child?.facility?.facility_name_short ||
                          childData?.info?.facility_name_short
                        }
                        absentDates={
                          Array.isArray(facilityData?.summary?.absent_days) &&
                          facilityData?.summary?.absent_days?.length > 0
                            ? facilityData?.summary?.absent_days?.join(
                                `${t("Day")},`
                              ) + t("Day")
                            : ""
                        }
                      />
                    </div>

                    {mode === "list" ? (
                      <FacilityApplicationsList
                        childId={searchParams?.childId}
                        childData={childData}
                        year={searchParams?.year}
                        month={searchParams?.month}
                        facilityData={facilityData}
                        contractedFacilities={[]}
                        isLoading={isLoading || isFetching}
                        applicationCount={
                          facilityData?.summary?.application_count || 0
                        }
                        displaySettings={searchParams?.display_settings
                          ?.toString()
                          .split(",")}
                        isCDSChild={childData?.child?.service_type == 2}
                      />
                    ) : (
                      <FacilityApplicationBulkPrint
                        childId={searchParams?.childId}
                        childData={childData}
                        year={searchParams?.year}
                        month={searchParams?.month}
                        facilityData={facilityData}
                        contractedFacilities={[]}
                        isLoading={isLoading || isFetching}
                        serviceName={childData?.info?.service_to_use || ""}
                        displaySettings={searchParams?.display_settings
                          ?.toString()
                          .split(",")}
                      />
                    )}
                    {mode === "list" && (
                      <>
                        <ApplicationLegend
                          overflow={"hidden"}
                          applicationCount={
                            facilityData?.summary?.application_count || 0
                          }
                          contractCount={facilityData?.summary?.contract_days}
                          facilityName={
                            childData?.child?.facility?.facility_name_short ||
                            childData?.info?.facility_name_short
                          }
                          absentDates={
                            Array.isArray(facilityData?.summary?.absent_days) &&
                            facilityData?.summary?.absent_days?.length > 0
                              ? facilityData?.summary?.absent_days?.join(
                                  `${t("Day")},`
                                ) + t("Day")
                              : ""
                          }
                        />
                      </>
                    )}
                  </div>
                ),
              },
            ]}
            tabBarExtraContent={buttons()}
          />
          <div className={"bottom_ctas"}>{buttons("btm")}</div>
          <div className={"bottom_pagi"}>
            <div>
              <YearPaginateBtn
                prev={t("Prev month")}
                current={t("This month")}
                next={t("Next month")}
                handleClick={(val) => handleMonthChange(val)}
              />
            </div>
          </div>
        </div>
      </PageCard>
    </WrapperContainer>
  )
}
