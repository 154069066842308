import { API } from "@project/shared"

export const fetchAllCompanyUsers = async () => {
  return API.get("company_user/user/")
}

export const addUser = (data) => {
  return API.post("company_user/create", data)
}

export const fetchUser: any = (id: string) => {
  return API.get(`company_user/user/${id}`)
}

export const editUser = (id: string, data) => {
  return API.put(`company_user/user/${id}`, data)
}

export const deleteUser: any = (id: string | number) => {
  return API.delete(`company_user/user/${id}`)
}
