// packages
import { t } from "i18next"
import moment from "moment"
import { Radio } from "antd"

// icons
import { StarFilled } from "@ant-design/icons"

// styled components
import { GoalAchievementSelectField, PrintOnlyHeaderContent } from "../styles"

// constants
import { goalAchievementRateOptions } from "../constants"

// types
import { GoalAchievementRadioButtonsProps } from "../types"

export const GoalAchievementRadioButtons: React.FC<
  GoalAchievementRadioButtonsProps
> = ({ onChange, value }) => {
  return (
    <GoalAchievementSelectField>
      <div className={"radio-group"}>
        <Radio.Group
          name={"goalAchievementRate"}
          onChange={onChange}
          value={value}
        >
          {goalAchievementRateOptions.map((item) => {
            return (
              <Radio key={item.value.toString()} value={item.value}>
                <span className={"radio-label"}>{item.label}</span>
                <StarFilled />
              </Radio>
            )
          })}
        </Radio.Group>
      </div>
    </GoalAchievementSelectField>
  )
}

export const TableTitle = ({
  currentDate,
  currentProgramName,
}: {
  currentDate: moment.Moment
  currentProgramName: string
}) => (
  <span>
    {t(
      "{{year}}年{{month}}月 Program progress record information ({{programCatName}})",
      {
        year: moment(currentDate).format("YYYY"),
        month: moment(currentDate).format("MM"),
        programCatName: currentProgramName || "Program category name",
      }
    )}
  </span>
)

export const PrintOnlyHeader = ({
  currentChildName,
  currentDate,
  categoryName,
}: {
  currentChildName: string
  currentDate: moment.Moment
  categoryName: string
}) => {
  return (
    <PrintOnlyHeaderContent className={"only-print"}>
      <h3 className={"heading"}>
        {t("Program progress record")}
        {"("}
        {categoryName}
        {")"}
      </h3>
      <div className={"more-info flexbox"}>
        <p className={"year-month"}>
          {moment(currentDate).format("YYYY年MM月")}
        </p>
        <p className={"child-name"}>
          {t("Child name ")}
          {": "}
          <span>{currentChildName}</span>
        </p>
      </div>
    </PrintOnlyHeaderContent>
  )
}

export * from "./FormBlock"
export * from "./ProgramProgressRecInfoBlock"
