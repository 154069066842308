import { API } from "@project/shared"

interface BusinessDayRegistrationProps {
  facilityId: number
  currentYear: number
  currentMonth: number
}
export const fetchBusinessDayRegistration = async ({
  facilityId,
  currentYear,
  currentMonth,
}: BusinessDayRegistrationProps) => {
  return API.get(`/business-days/${facilityId}/${currentYear}/${currentMonth}`)
}

export const saveBusinessDayRegistration = async (values: any) => {
  return API.post(`/business-days`, values)
}

export const deleteBusinessDayRegistration = async (values: any) => {
  return API.post(`/business-days/delete`, values)
}

export const fetchFacilityService = async ({
  facilityId,
  currentYear,
  currentMonth,
}) => {
  return API.get(
    `/facilities/status_settings/${facilityId}/${currentYear}/${currentMonth}`
  )
}

export const saveFacilityBusinessDayForYear = (values) =>
  API.post("/business-days/bulk-update", values)

export const getFacilityBulkSetting = (query: string) =>
  API.get(`/business-days/get-bulk-setting?${query}`)
