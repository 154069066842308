import styled from "styled-components"
import { Title } from "../../../utils"
export const WrapperContainer = styled.div`
  @media print {
    display: none !important;
  }
`

export const Content = styled.div`
  .child_selector {
    .selector {
      display: flex;
      flex-direction: row !important;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }
  .settings_checkboxes {
    .checkboxes {
      /* margin-top: 0.5rem; */
    }
  }
`
export const Block = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &.date-select {
    .ant-select-selection-item {
      margin-left: 8px !important;
    }
  }
  &.child-name-select {
    .ant-select {
      margin-right: 20px;
    }
    .ant-select-selection-item {
      margin-left: 15px !important;
    }
  }
  input {
    background-color: #ffffff;
  }
  @media (max-width: 512px) {
    .child-select-last {
      width: 100% !important;
    }
    * {
      font-size: 14px !important;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    [class*="SelectInput__Wrapper"] {
      height: 40px;
    }
    [class^="SelectInput__SelectWrapper"] {
      margin-right: 6px;
    }
    .ant-select {
      margin-right: 0 !important;
      margin-bottom: 20px;
      * {
        font-size: 14px;
      }
    }
    .child-select {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
  }
  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }
  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  @media (max-width: 767px) {
    /* flex-direction: row; */
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
      }
    }
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`
export const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  .textfield {
    margin-left: 30px;
  }
`
export const Text = styled(Title)`
  font-size: 16px;
`
export const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
`
