import {
  Checkboxes,
  setItemToLocalstorage,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Col, Row } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"

const OperationWrapper = styled.div`
  margin-bottom: 20px;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .ant-row {
    margin-bottom: 20px;
    align-items: flex-start;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .check-btns {
    display: flex;
    flex-wrap: nowrap !important;

    @media screen and (max-width: 400px) {
      button {
        padding: 12px 22px;
      }
      .uncheck-btn {
        margin-left: 14px !important;
      }
    }
  }
  .multiple-input-container,
  .year,
  .month {
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 426px) {
      gap: 0;
      button:last-child {
        margin-left: 14px;
      }
    }
  }
  .multiple-facilities-checkboxes {
    margin-top: 10px;
    @media (max-width: 767px) {
      .ant-checkbox + span {
        white-space: pre-line;
        word-break: break-all;
        padding-left: 10px;
      }
    }
  }
  .operation-label {
    margin-top: 4px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    .ant-row {
      align-items: flex-start;
      flex-direction: column;
    }
    .operation-label {
      margin-bottom: 5px;
    }
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
    .change-display {
      width: 100%;
      @media screen and (max-width: 426px) {
        margin-top: 10px;
        button {
          margin-left: 0px !important;
        }
      }
    }
  }
`

export interface IRevenueManagementOperationOptions {
  year: number
  month: number
  facilityIds: Array<any>
}

interface IRevenueManagementOperation {
  facility_options: Array<any>
  operationOptions: IRevenueManagementOperationOptions
  setOperationOptions: any
}

export const RevenueManagementOperation = ({
  facility_options,
  operationOptions,
  setOperationOptions,
}: IRevenueManagementOperation) => {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: operationOptions,
    onSubmit: (values) => {
      setItemToLocalstorage("revenue_management_key", {
        facility_ids: values?.facilityIds?.join(",") || null,
      })
      setOperationOptions(values)
    },
  })

  const checkAllFacilities = () => {
    const values = facility_options.map((facility) => {
      return facility.value
    })
    formik.setFieldValue("facilityIds", values)
  }

  const uncheckAllFacilities = () => {
    formik.setFieldValue("facilityIds", [])
  }

  useEffect(() => {
    formik.setValues(operationOptions)
  }, [operationOptions])

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation options")}>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Display facility")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container check-btns"}>
              <OwnerButton
                typeOf={"check-all"}
                onClick={() => {
                  checkAllFacilities()
                }}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Uncheck all")}
                onClick={() => {
                  uncheckAllFacilities()
                }}
                className={"uncheck-btn"}
              />
            </div>
            <div
              className={
                "multiple-input-container multiple-facilities-checkboxes"
              }
            >
              <Checkboxes
                options={facility_options}
                name={"facilityIds"}
                onChange={(facilities) => {
                  formik.setFieldValue("facilityIds", facilities)
                }}
                value={formik.values.facilityIds}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Year month")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <DatePicker
                date={
                  moment(
                    moment({
                      year: +formik.values.year,
                      month: +formik.values.month - 1,
                    })
                  )?.isValid()
                    ? moment({
                        year: +formik.values.year,
                        month: +formik.values.month - 1,
                      })
                    : null
                }
                style={{
                  height: "40px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment("2021/01/01")) ||
                    (current && current > moment().add(6, "year"))
                  )
                }}
                onDateChange={(value) => {
                  formik.setFieldValue("year", moment(value).format("YYYY"))
                  formik.setFieldValue("month", moment(value).format("M"))
                }}
              />
              <div className={"change-display"}>
                <OwnerButton
                  typeOf={"secondary"}
                  text={t("Change display")}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
