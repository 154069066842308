import { theme } from "@project/shared"
import styled from "styled-components"

export const WrapperContainer = styled.div`
  .content__container {
    margin-top: 16px;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
    border-bottom: 1px solid ${theme.gray2};
    background-color: #fff;
    & .ant-tabs-tab {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid ${theme.gray2};
      min-width: 200px !important;
    }
    & .ant-tabs-tab-active {
      background-color: #fff;
      border-bottom: 2px solid ${theme.blue5};
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column !important;
      .ant-tabs-nav-operations,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
        display: none !important;
      }
    }
  }
  .tab_content {
    padding: 10px;
    border: 1px solid ${theme.gray2};
    border-top: 0;
    position: relative;
    .print_page_time {
      display: none;
      @media print {
        display: block;

        font-weight: bold;
        color: ${theme.black};
        font-size: 18px !important;
      }
    }
    .legend__content {
      margin-bottom: 14px;
    }
  }
  .inner_item {
    align-items: center !important;
  }

  .bottom_ctas {
    width: 100%;
    margin-top: 0.8rem;
  }
  .bottom_pagi {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 0.8rem;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .ant-card-extra {
      width: 100% !important;
    }
  }
  @media only print {
    .content__container {
      margin-top: 0 !important;
    }
    .ant-card .ant-card-head {
      margin: 0 !important;
      display: none;
    }
    .ant-tabs-nav {
      display: none;
    }
    .ant-card-body {
      margin: 0 !important;
    }

    .tab_content {
      padding: 0 !important;
      .print_page_time {
        font-size: 10px !important;
      }

      .legend__content {
        margin-bottom: 10px !important;
        position: absolute;
        top: 0;
      }
    }
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  flex-wrap: wrap;
  & > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    .print_btn {
      background: #858686;
    }
    .print_list_btn {
      background: #858686;
    }
  }
`
