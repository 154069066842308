import { Empty, Popconfirm, Tabs } from "antd"
import { useFormik } from "formik"
import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import * as yup from "yup"
import {
  Button,
  OwnerButton,
  PageHeaderComponent,
  UserRegistrationFacilityPermissionSection,
  UserRegistrationForm,
} from "../../../components"
import {
  COMMON_SCREEN_TO_ALL_FACILITY,
  TABBED_FACILITIES,
  USER_REGISTRATION_PERMISSIONS,
} from "../../../constants/facilityPermissionList"

import { BreadCrumb, theme } from "@project/shared"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { createResponseBodyForUserManagement } from "../../../utils/utilityFunction"

interface UserRegistrationTemplateProps {
  pageTitle: string
  breadCrumbItems: any
  copiedCellData?: any
  copiedPermissions?: any
  userIDToCopyFrom?: any
  handleSubmitInProgress?: boolean
  handleSubmit?: (responseBody?: any) => void
  isEditForm?: boolean
  isCreating?: boolean
  isAdminInitially?: boolean
  handleDelete?: () => void
  showDeleteButton?: boolean
  isDeleteLoading?: boolean
}

const PageWrapper = styled.div`
  padding: 0 40px;
  @media (max-width: 610px) {
    padding: 0px 20px;
  }

  @media screen and (max-width: 588px) {
    .tabs-button-wrapper {
      display: grid !important;
      gap: 10px !important;
      align-items: center;
      grid-template-columns: repeat(auto-fill, minmax(94px, 94px));
      button {
        margin-right: 0 !important;
      }
      div {
        min-width: max-content;
      }
    }
  }
`

const CardWrapper = styled.div`
  background: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  padding: 13px 30px;
  border-radius: 12px;
  .card-title-wrapper {
    .card-title {
      padding-bottom: 12px;
      border-bottom: 1px solid #d2d1d1;
    }
  }
  .submit-button-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 542px) {
      flex-direction: column;
      align-items: center;
    }
    button {
      border: 1px solid #d2d1d1;
      border-radius: 100px;
      height: 40px;
      &.primary {
        width: 124px !important;
        background: #0782c8 !important;
        color: white;
      }
      &.secondary {
        margin-right: 10px;
        width: 140px !important;
        background: #f3f3f3;
      }
    }
  }

  .user-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      :first-child {
        margin-right: 10px;
      }
      :nth-child(2) {
        margin-right: 10px;
      }
    }

    .delete-button {
      background: ${theme.red2};
      border-color: ${theme.red2};

      @media (max-width: 542px) {
        margin-top: 10px;
      }
    }
  }

  .ant-tabs-tab {
    border-radius: 12px 12px 0px 0px !important;
    min-width: 102px;
    margin: 0px !important;
    text-align: center;
    div {
      margin: auto;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border: 1px solid ${theme.gray2};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    opacity: 80%;
    text-shadow: none;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: black !important;
    background: #fff;
  }

  .ant-tabs-tab:hover {
    color: black !important;
    background-color: #f5f5f5;
  }
  .ant-tabs-tab:focus {
    color: black !important;
  }
  .ant-tabs-tab:active {
    color: black !important;
  }

  .ant-tabs-ink-bar {
    background: ${theme.blue5};
    visibility: visible !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }

  .tabs-button-wrapper {
    display: flex;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    gap: 5px;
    button {
      width: 94px;
      height: 40px;
      border-radius: 5px;
      padding: 0px;
      background: white;
      color: #191919;
      border-color: #d2d1d1;
      margin-right: 10px;

      span {
        font-size: 14px;
      }
      &.active {
        background: #cde6f4;
        border: 1px solid #0782c8;
      }
    }
  }

  /* Tab section starts here */
  .custom-tabs-group {
    .cutom-tab-pane {
      border: 1px solid #d2d1d1;
      padding-top: 26px;
    }
  }

  .custom-tabs-group__disabled,
  .common-permission-section-wrapper__disabled {
    pointer-events: none;

    /* Common screen to all facilities */
    &.common-permission-section-wrapper {
      .section-button-wrapper .active .ant-btn,
      .each-permission-wrapper .active .ant-btn {
        background: #eaf5ff;
        border: 1px solid #83aec8;
        color: rgba(0, 0, 0, 0.25) !important;
      }

      .each-permission-wrapper {
        background: #f3f3f3;
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab-disabled {
        background: #fafafa;
        border: 1px solid #f0f0f0;
        .ant-tabs-tab-btn {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    /* Dynamic screen to all facilities */
    .ant-tabs-content {
      background: #f3f3f3;
      .cutom-tab-pane {
        .section-button-wrapper .active .ant-btn,
        .each-permission-wrapper .active .ant-btn {
          background: #eaf5ff;
          border: 1px solid #83aec8;
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }
  /* Tab section ends here*/

  .custom-tabs-group__disabled button,
  .common-permission-section-wrapper__disabled button {
    opacity: 0.5;
  }

  @media (max-width: 410px) {
    padding: 13px 20px;
  }
`

const Headline = styled.div`
  margin: 30px 0px;
  border-bottom: 1px solid #d2d1d1;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`

const UserRegistration: React.FC<UserRegistrationTemplateProps> = memo(
  ({
    copiedCellData,
    handleSubmit,
    pageTitle,
    breadCrumbItems,
    copiedPermissions,
    userIDToCopyFrom,
    isEditForm = false,
    isCreating = false,
    isAdminInitially = false,
    handleDelete,
    showDeleteButton = false,
    isDeleteLoading = false,
  }) => {
    const { t } = useTranslation()
    const [tabbedPermission, setTabbedPermission] = useState<any>({
      common: COMMON_SCREEN_TO_ALL_FACILITY,
    })
    const [allPermissionOfTabSetTo, setAllPermissionOfTabTo] = useState({
      つくば: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    })

    const initialValuesForUserRegistration = {
      user_name: "",
      user_type: "0",
      auth_new_delete_flag: [],
      affiliation_facility: [],
      affiliation_facility_tabs: [],
      primary_facility_id: "",
      output_parent_and_child: [],
      email: "",
      password: "",
      isEditForm,
    }

    const router = useRouter()

    const [activeTab, setActiveTab] = useState<any>()

    const addPermissionTabForCopiedCell = (tabId: string) => {
      setTabbedPermission((prevState) => ({
        ...prevState,
        [tabId]: JSON.parse(JSON.stringify(copiedPermissions[tabId])),
      }))
    }

    useEffect(() => {
      if (copiedCellData && (userIDToCopyFrom || isEditForm)) {
        if (copiedPermissions?.common) {
          addPermissionTabForCopiedCell("common")
        }
        formik.setValues({ ...copiedCellData })
        copiedCellData?.affiliation_facility_tabs?.forEach(({ label }) => {
          addPermissionTabForCopiedCell(label)
        })
      }
    }, [])

    const validationSchema = yup.object().shape({
      user_name: yup
        .string()
        .required(t("Required"))
        .max(20, t("Please enter within 20 characters")),
      user_type: yup.string().required(t("Required")),
      affiliation_facility: yup.array().required(t("Required")),
      primary_facility_id: yup.string().required(t("Required")),
      email: yup
        .string()
        .email(t("Enter Email"))
        .min(4, t("(min 4 alphanumeric characters)"))
        .required(t("Required")),
      isEditForm: yup.boolean().notRequired(),
      password: yup
        .string()
        .min(6, t("(6-16 alphanumeric characters)"))
        .max(16, t("(6-16 alphanumeric characters)"))
        .when("isEditForm", {
          is: false,
          then: yup
            .string()
            .min(6, t("(6-16 alphanumeric characters)"))
            .max(16, t("(6-16 alphanumeric characters)"))

            .required(t("Required")),
        }),
    })

    const formik = useFormik({
      initialValues: initialValuesForUserRegistration,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (v) => {
        const authNewDeleteArr =
          v?.auth_new_delete_flag.join("").split("") || []
        const outPutParentChildArr =
          v?.output_parent_and_child.join("").split("") || []

        const common_facility_permissions = createResponseBodyForUserManagement(
          tabbedPermission?.common
        )

        const selectedFacilities = formik.values.affiliation_facility_tabs
        const AllTabbedFacilityPermissions = selectedFacilities.map(
          ({ label: each, value }) => {
            return createResponseBodyForUserManagement(
              tabbedPermission[each],
              value
            )
          }
        )
        const request_body = {
          email: v.email,
          password: v.password,
          user_type: v.user_type,
          user_name: v.user_name,
          primary_facility_id: v.primary_facility_id,
          output_parent_and_child: outPutParentChildArr.length > 0 ? "1" : "0",
          auth_new_delete_flag: authNewDeleteArr.length > 0 ? "1" : "0",
          common_facility_permissions,
          user_facility_permissions: AllTabbedFacilityPermissions,
        }
        handleSubmit(request_body)
      },
    })

    useEffect(() => {
      if (formik.values.affiliation_facility_tabs) {
        if (
          !activeTab ||
          !formik?.values?.affiliation_facility_tabs?.find(
            (tab) => tab?.label == activeTab
          )
        )
          setActiveTab(formik?.values?.affiliation_facility_tabs[0]?.label)
      }
    }, [formik.values.affiliation_facility_tabs])

    const handleAffiliationFacilityChange = (facilities: any) => {
      const selectedAffiliationFacility = facilities.map(
        (facility: any) => facility.value
      )

      formik.setFieldValue("affiliation_facility", selectedAffiliationFacility)
      formik.setFieldValue("affiliation_facility_tabs", facilities)

      facilities?.forEach((facility: any) => {
        setTabbedPermission((prev) => {
          return {
            ...prev,
            [facility.label]: tabbedPermission[facility.label]
              ? tabbedPermission[facility.label]
              : JSON.parse(JSON.stringify(TABBED_FACILITIES)),
          }
        })
      })
      if (!formik?.values?.primary_facility_id) {
        formik.setFieldValue(
          "primary_facility_id",
          selectedAffiliationFacility[0]
        )
      } else {
        if (
          selectedAffiliationFacility?.includes(
            formik?.values?.primary_facility_id
          )
        ) {
          formik.setFieldValue(
            "primary_facility_id",
            formik?.values?.primary_facility_id
          )
        } else {
          formik.setFieldValue(
            "primary_facility_id",
            selectedAffiliationFacility[0]
          )
        }
      }
    }

    const handleSelect = (id, value) => {
      formik.setFieldValue(id, value)
      // if user is admin then select all permissions as browse/edit
      if (id == "user_type" && value == "1") {
        try {
          const tabs = formik.values.affiliation_facility_tabs || []
          if (Array.isArray(tabs))
            tabs?.forEach((tab) => {
              if (tab?.label)
                handleEntireTabPermissionChange(
                  tab?.label,
                  USER_REGISTRATION_PERMISSIONS.browseAndEdit
                )
            })

          const commonTabs = Object.keys(tabbedPermission?.common) || []

          if (Array.isArray(commonTabs))
            commonTabs?.forEach((common) => {
              handleEntireSectionPermissionChange(
                "common",
                common,
                USER_REGISTRATION_PERMISSIONS.browseAndEdit
              )
            })
        } catch (e) {
          formik.setFieldValue("user_type", "1")
        }
      }
    }

    const handleEntireTabPermissionChange = (tabId, permission) => {
      const updatedPermissions = { ...tabbedPermission[tabId] }
      Object.keys(updatedPermissions).forEach((eachSection) =>
        updatedPermissions[eachSection].forEach((eachPermission) => {
          eachPermission.permission = permission
        })
      )
      setTabbedPermission({
        ...tabbedPermission,
        [tabId]: JSON.parse(JSON.stringify(updatedPermissions)),
      })
      setAllPermissionOfTabTo({
        ...allPermissionOfTabSetTo,
        [tabId]: permission,
      })
    }

    const handleEntireSectionPermissionChange = (
      tabId,
      sectionId,
      permission
    ) => {
      const updatedPermissions = tabbedPermission[tabId][sectionId]
      updatedPermissions?.forEach((eachPermission) => {
        eachPermission.permission = permission
      })

      setTabbedPermission({
        ...tabbedPermission,
        [tabId]: {
          ...tabbedPermission[tabId],
          [sectionId]: [...updatedPermissions],
        },
      })
    }

    const handleSinglePermissionChange = (
      tabId,
      sectionId,
      permissionId,
      permission
    ) => {
      const updatedPermissions = tabbedPermission[tabId][sectionId]
      updatedPermissions.forEach((eachSection) => {
        if (eachSection.id === permissionId) {
          eachSection.permission = permission
        }
      })
      setTabbedPermission({
        ...tabbedPermission,
        [tabId]: {
          ...tabbedPermission[tabId],
          [sectionId]: [...updatedPermissions],
        },
      })
    }

    useEffect(() => {
      if (formik.submitCount == 0) return
      if (formik.isValid) return
      scroll(0, 0)
    }, [formik.submitCount, formik.isValid])

    return (
      <>
        <PageWrapper>
          <BreadCrumb items={breadCrumbItems} />
          <PageHeaderComponent
            title={pageTitle}
            iconPath={"/assets/icons/facility.svg"}
          />
          <CardWrapper>
            <form onSubmit={formik.handleSubmit}>
              <div className={"card-title-wrapper"}>
                <p className={"card-title"}>{t("User account information")}</p>
              </div>
              <UserRegistrationForm
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                handleAffiliationFacilityChange={
                  handleAffiliationFacilityChange
                }
                values={formik.values}
                errors={formik?.touched && formik.errors}
                handleSelect={handleSelect}
                touchedFields={formik.touched}
                isEditForm={isEditForm}
                setFieldValue={formik.setFieldValue}
                disableUserTypeChange={isAdminInitially}
              />
              <Headline>
                <p>{t("Screens that can be set for each facility")}</p>
              </Headline>
              {formik.values?.affiliation_facility_tabs?.length ? (
                <Tabs
                  type={"card"}
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  className={`custom-tabs-group ${
                    formik.values.user_type === "1"
                      ? "custom-tabs-group__disabled"
                      : ""
                  }`}
                  onChange={setActiveTab}
                >
                  {(formik.values.affiliation_facility_tabs || []).map(
                    ({ label: eachTab }) => {
                      return (
                        <Tabs.TabPane
                          key={eachTab}
                          tab={eachTab}
                          className={"cutom-tab-pane"}
                          forceRender={true}
                          disabled={formik.values.user_type === "1"} // 1 is for admin user and admin user can't change facility permissions
                        >
                          <div className={"tabs-button-wrapper"}>
                            <Button
                              text={t("Browse/Edit")}
                              className={`tab-button ${
                                allPermissionOfTabSetTo[eachTab] ===
                                USER_REGISTRATION_PERMISSIONS.browseAndEdit
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleEntireTabPermissionChange(
                                  eachTab,
                                  USER_REGISTRATION_PERMISSIONS.browseAndEdit
                                )
                              }
                              disabled={formik.values.user_type === "1"}
                            />
                            <Button
                              className={`tab-button ${
                                allPermissionOfTabSetTo[eachTab] ===
                                USER_REGISTRATION_PERMISSIONS.browseOnly
                                  ? "active"
                                  : ""
                              }`}
                              text={t("Browse Only")}
                              onClick={() =>
                                handleEntireTabPermissionChange(
                                  eachTab,
                                  USER_REGISTRATION_PERMISSIONS.browseOnly
                                )
                              }
                              disabled={formik.values.user_type === "1"}
                            />
                            <Button
                              className={`tab-button ${
                                allPermissionOfTabSetTo[eachTab] ===
                                USER_REGISTRATION_PERMISSIONS.dontDisplay
                                  ? "active"
                                  : ""
                              }`}
                              text={t("Don't Display")}
                              onClick={() =>
                                handleEntireTabPermissionChange(
                                  eachTab,
                                  USER_REGISTRATION_PERMISSIONS.dontDisplay
                                )
                              }
                              disabled={formik.values.user_type === "1"}
                            />
                            <div style={{ color: theme.red2 }}>
                              {" "}
                              {`* ${t("Bulk setting")}`}
                            </div>
                          </div>
                          {Object.keys(tabbedPermission[eachTab] || [])?.map(
                            (each) => {
                              return (
                                <UserRegistrationFacilityPermissionSection
                                  title={each}
                                  tabId={eachTab}
                                  permissions={tabbedPermission[eachTab][each]}
                                  handleSectionPermissionChange={
                                    handleEntireSectionPermissionChange
                                  }
                                  handlePermissionChange={
                                    handleSinglePermissionChange
                                  }
                                  key={each}
                                />
                              )
                            }
                          )}
                        </Tabs.TabPane>
                      )
                    }
                  )}
                </Tabs>
              ) : (
                <div>
                  <Empty
                    description={
                      <span>
                        {t("Please select affiliation facility first")}
                      </span>
                    }
                  />
                </div>
              )}
              <Headline>
                <p>{t("Common screen to all facilities")}</p>
              </Headline>
              <div>
                <div
                  className={`common-permission-section-wrapper ${
                    formik.values.user_type === "1"
                      ? "common-permission-section-wrapper__disabled"
                      : ""
                  }`}
                >
                  {Object.keys(tabbedPermission?.common || []).map((each) => {
                    return (
                      <UserRegistrationFacilityPermissionSection
                        title={each}
                        permissions={tabbedPermission["common"][each]}
                        key={each}
                        handleSectionPermissionChange={
                          handleEntireSectionPermissionChange
                        }
                        handlePermissionChange={handleSinglePermissionChange}
                        tabId={"common"}
                      />
                    )
                  })}
                </div>
              </div>
              <div className={"submit-button-wrapper"}>
                <div className={"user-button-container"}>
                  <OwnerButton
                    text={t("Cancel")}
                    typeOf={"secondary"}
                    type={"button"}
                    onClick={() => {
                      router.push("/users")
                    }}
                  />
                  <div style={{ margin: "0px 13px" }}>
                    <OwnerButton
                      type={"submit"}
                      typeOf={"primary"}
                      text={t("Save")}
                      isLoading={isCreating}
                      disabled={isCreating}
                    />
                  </div>
                </div>
                {showDeleteButton ? (
                  <>
                    <div className={"user-button-container"}>
                      <Popconfirm
                        title={t("Deleting.Is that OK?")}
                        onConfirm={handleDelete}
                        okText={"OK"}
                        cancelText={t("Cancel")}
                        okButtonProps={{ size: "middle" }}
                        cancelButtonProps={{ size: "middle" }}
                      >
                        <OwnerButton
                          text={t("Delete")}
                          typeOf={"primary"}
                          className={"delete-button"}
                          isLoading={isDeleteLoading}
                        />
                      </Popconfirm>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </CardWrapper>
        </PageWrapper>
      </>
    )
  }
)

export { UserRegistration }
