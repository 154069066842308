import { theme } from "@project/shared"
import { Table } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

// /STYLES
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .summary__table_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    &--left {
      border: 1px solid ${theme.black};
      table {
        tbody {
          tr {
            th,
            td {
              border: 1px solid ${theme.black};
              padding: 5px 2px;
              font-size: 12px;
              height: 25px !important;
            }
          }
        }
      }
    }
    &--right {
      height: fit-content;
      border: 1px solid ${theme.black};
      table {
        tbody {
          tr {
            th,
            td {
              border: 1px solid ${theme.black};
              padding: 8px 5px;
              min-width: 35px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .summary__table_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      max-width: 50%;
      margin: auto;
      text-align: center;
      p {
        font-size: 12px;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
  #summary__table {
    border: 1px solid ${theme.black};
    overflow-x: auto;
    table {
      /* border-collapse: collapse; */
      width: 100%;
      tbody {
        tr {
          td {
            border: 1px solid ${theme.black};
            padding: 0 12px !important;
            position: relative;
            font-size: 12px;
            & > div,
            span {
              font-size: 12px;
            }
            #cross__box {
              overflow: hidden;
              background: ${theme.black};
              position: absolute;
              top: 50%;
              bottom: 0;
              left: -0.1rem;
              right: -0.1rem;
              height: 1px;
              transform: rotate(-30deg);
            }
          }
          td.yen__units {
            .units {
              font-size: 12px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          td.blank-row {
            border-left: none !important;
            border-right: none !important;
            height: 8px;
          }
          td.no-right-border {
            border-right: none !important;
          }
          td.thick-left-border {
            border-left: 2px solid #000 !important;
          }
          td.min-wd-20 {
            min-width: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    .summary__table_bottom {
      flex-direction: column;
      align-items: flex-start;
    }
    .summary__table_footer {
      .content {
        max-width: 100%;
      }
    }
  }
  @media print {
    .summary__table_bottom {
      &--right {
        table {
          tbody {
            tr {
              td {
                padding: 2px !important;
              }
            }
          }
        }
      }
    }
    #summary__table {
      border: 0 !important;
      table {
        page-break-inside: auto !important;
        thead {
          display: table-header-group !important;
        }
        tbody {
          tr {
            page-break-inside: avoid !important;
            page-break-after: auto !important;
            td {
              padding: 0 2px !important;
              min-width: 15px;
              font-size: 9pt !important;
              span {
                font-size: 6pt !important;
              }
              div {
                font-size: 9pt !important;
              }
            }
          }
        }
      }
    }
    .summary__table_footer {
      .content {
        max-width: 100% !important;
        p {
          font-size: 9pt;
        }
      }
    }
  }
`

export const SummaryColumnTable: React.FC<any> = ({
  data,
  showHowManyColumn = false,
  total_page,
  page_number,
}) => {
  const { t } = useTranslation()
  const getNumberValue = (dataValue, dir = "start", pad = 6) => {
    const initial_number = `${dataValue}`?.split(".")[0] || ""
    const initial_values = initial_number
      ? dir === "start"
        ? `${initial_number}`
            ?.padStart(pad, "0")
            ?.split("")
            ?.map((v) => (v === "-" ? 0 : +v))
        : `${initial_number}`
            ?.padEnd(pad, "0")
            ?.split("")
            ?.map((v) => (v === "-" ? 0 : +v))
      : []
    const arrayToModify = [...initial_values]
    const index = arrayToModify?.findIndex((element) => element !== 0)
    const first = [...arrayToModify]?.slice(0, index)?.map(() => "")
    const last = [...arrayToModify]?.slice(index, initial_values?.length)
    return index !== -1 ? [...first, ...last] : initial_values?.map(() => "")
  }
  const zeroBreakDownArray = ["", "", "", "", "", "0"]
  const dataSources = [
    {
      title: t("Service type code"),
      service_name:
        data?.ServiceType === 1
          ? t("After school day service")
          : t("Child development support"),
      colSpan: true,
      colSpanValue:
        data?.c_09 === "63"
          ? t("After school day service")
          : t("Child development support"),
      number_value: data?.c_09 || "",
    },
    {
      title: t("Service used days"),
      service_name:
        data?.ServiceType === 1
          ? t("After school day service")
          : t("Child development support"),
      colSpan: true,
      colSpanValue: t("Days"),
      number_value: getNumberValue(data?.c_10, "start", 2) || [],
    },
    {
      title: t("Number of payment units"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_11?.replace(".", "")) || [],
      total: getNumberValue(data?.c_11),
    },
    {
      title: t("Unit price per unit"),
      service_name: "",
      colSpan: false,
      colSpanValue: t("Yen/Unit"),
      number_value:
        getNumberValue(data?.c_12?.replace(".", ""), "start", 4) || [],
      total: getNumberValue(data?.c_12),
    },
    {
      title: t("Total cost"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_13) || [],
      total: getNumberValue(data?.c_13),
    },
    {
      title: t("Amount equivalent to 10%"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_14),
    },
    {
      title: t("User burden ②"),
      service_name: "",
      colSpan: false,
      number_value:
        data?.c_15 == "0" ? zeroBreakDownArray : getNumberValue(data?.c_15),
      total: getNumberValue(data?.c_15),
    },
    {
      title: t("Upper limit monthly amount adjustment (smaller number of ①②)"),
      service_name: "",
      colSpan: false,
      number_value:
        data?.c_16 == "0" ? zeroBreakDownArray : getNumberValue(data?.c_16),
      total:
        data?.c_16 == "0" ? zeroBreakDownArray : getNumberValue(data?.c_16),
    },
    {
      title: t("User charge after adjustment"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_17),
      total: getNumberValue(data?.c_17),
    },
    {
      title: t("User burden after upper limit management"),
      service_name: "",
      colSpan: false,
      number_value:
        data?.c_18 > 0
          ? getNumberValue(data?.c_18)
          : data?.c_18 == 0
          ? zeroBreakDownArray
          : ["", "", "", "", "", "", ""],
      total:
        data?.c_18 > 0
          ? getNumberValue(data?.c_18)
          : data?.c_18 == 0
          ? zeroBreakDownArray
          : ["", "", "", "", "", "", ""],
    },
    {
      title: t("Determined user fee"),
      service_name: "",
      colSpan: false,
      number_value:
        data?.c_19 == "0" ? zeroBreakDownArray : getNumberValue(data?.c_19),
      total:
        data?.c_19 == "0" ? zeroBreakDownArray : getNumberValue(data?.c_19),
    },
    {
      title: t("Invoice amount"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_20),
      total: getNumberValue(data?.c_20),
    },
    {
      title: "",
      service_name: "",
      colSpan: false,
      number_value: [],
      total: [],
      className: "blank-row",
    },
    {
      title: t("Municipal subsidy invoice amount"),
      service_name: "",
      colSpan: false,
      number_value: getNumberValue(data?.c_21),
      total: getNumberValue(data?.c_21),
    },
  ]
  const tdWithExtraBorder = (index, children, additionalClass = "") => {
    return {
      props: {
        style: {
          borderBottom:
            dataSources?.length - 1 !== index && (index === 4 || index >= 8)
              ? "2px solid black"
              : "",
        },
        className: additionalClass,
      },

      children: children,
    }
  }

  const columns: any = [
    {
      key: 1,
      title: "",
      render: () => t("Billed amount summary column"),
      className: "sum__first-column no-right-border",
      width: 10,
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: dataSources?.length,
          }
        }
        if (index !== 0) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
    },
    {
      key: 2,
      title: "",
      render: (data, __, index) => {
        const child = (
          <>
            {index === 11 ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    borderRight: "1px solid black",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {data?.title}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {t("benefits")}
                </div>
              </div>
            ) : (
              <div>{data?.title}</div>
            )}
          </>
        )

        return tdWithExtraBorder(
          index,
          child,
          data?.className || "" + " thick-left-border"
        )
      },
    },
    {
      key: 3,
      title: "",
      width: 10,
      children: [
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = data?.number_value?.length
              ? data?.number_value[0]
              : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = data?.number_value?.length
              ? data?.number_value[1]
              : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child = data?.colSpan
              ? data?.colSpanValue
              : data?.number_value?.length
              ? data?.number_value[2]
              : ""
            const className =
              (data?.className || "") + " " + data?.colSpan ? "min-wd-20" : ""
            return tdWithExtraBorder(index, child, className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 4,
              }
            }

            return {}
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child = data?.number_value?.length
              ? data?.number_value[3]
              : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          width: 20,
          title: 6,
          align: "center",
          className: "yen__units",
          render: (data, __, index) => {
            const child = data?.colSpanValue ? (
              <span className={"units"}>{data?.colSpanValue}</span>
            ) : data?.number_value?.length ? (
              data?.number_value[4]
            ) : (
              ""
            )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 2,
              }
            }
            return {}
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = data?.number_value?.length
              ? data?.number_value[5]
              : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
    {
      key: 4,
      title: "",
      width: 10,
      children: [
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child =
              data?.colSpan && index === 1
                ? data?.colSpanValue
                : data?.number_value?.length
                ? ""
                : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 4,
              }
            }

            return {}
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          width: 20,
          title: 6,
          align: "center",
          className: "yen__units",
          render: (data, __, index) => {
            const child = data?.colSpanValue ? (
              <span className={"units"}>{data?.colSpanValue}</span>
            ) : (
              <span></span>
            )
            return tdWithExtraBorder(index, child, data?.className)
          },

          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 2,
              }
            }
            return {}
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
    {
      key: 4,
      title: "",
      width: 10,
      children: [
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child =
              data?.colSpan && index === 1
                ? data?.colSpanValue
                : data?.number_value?.length
                ? ""
                : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 4,
              }
            }

            return {}
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          width: 20,
          title: 6,
          align: "center",
          className: "yen__units",
          render: (data, __, index) => {
            const child = data?.colSpanValue ? (
              <span className={"units"}>{data?.colSpanValue}</span>
            ) : (
              <span></span>
            )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 2,
              }
            }
            return {}
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
    {
      key: 4,
      title: "",
      width: 10,
      children: [
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child =
              data?.colSpan && index === 1
                ? data?.colSpanValue
                : data?.number_value?.length
                ? ""
                : ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 4,
              }
            }

            return {}
          },
        },
        {
          width: 5,
          title: 6,
          align: "center",
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          width: 20,
          title: 6,
          align: "center",
          className: "yen__units",
          render: (data, __, index) => {
            const child = data?.colSpanValue ? (
              <span className={"units"}>{data?.colSpanValue}</span>
            ) : (
              <span></span>
            )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 2,
              }
            }
            return {}
          },
        },
        {
          width: 5,
          title: 6,
          render: (data, __, index) => {
            const child = ""
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0 || index === 1) {
              return {
                colSpan: 0,
              }
            }
            if (index === 3) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
    {
      key: 15,
      title: "",
      width: 10,
      align: "center",
      children: [
        {
          key: 1,
          width: 5,
          align: "center",
          render: (data, __, index) => {
            const child =
              index === 0 ? (
                t("Total")
              ) : index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[0]
              ) : (
                ""
              )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 6,
              }
            }
            return {}
          },
        },
        {
          key: 2,
          width: 5,
          render: (data, __, index) => {
            const child =
              index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[1]
              ) : (
                ""
              )

            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          key: 3,
          width: 5,
          render: (data, __, index) => {
            const child =
              index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[2]
              ) : (
                ""
              )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          key: 4,
          width: 5,
          render: (data, __, index) => {
            const child =
              index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[3]
              ) : (
                ""
              )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          key: 5,
          width: 5,
          render: (data, __, index) => {
            const child =
              index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[4]
              ) : (
                ""
              )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
        {
          key: 6,
          width: 5,
          render: (data, __, index) => {
            const child =
              index === 3 || index === 5 || index === 6 ? (
                <div id={"cross__box"}></div>
              ) : data?.total ? (
                data?.total[5]
              ) : (
                ""
              )
            return tdWithExtraBorder(index, child, data?.className)
          },
          onCell: (_, index) => {
            if (index === 0) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <div id={"summary__table"}>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSources}
          showHeader={false}
        />
      </div>
      <div className={"summary__table_bottom"}>
        <div className={"summary__table_bottom--left"}>
          <table>
            <tbody>
              <tr>
                <td rowSpan={2} className={"thick-right-border"}>
                  {t(
                    "Children with disabilities in specific facilities Benefits for meals, etc."
                  )}
                </td>
                <td
                  colSpan={4}
                  className={"thick-right-border thick-bottom-border"}
                >
                  {t("Calculated daily amount")}
                </td>
                <td
                  colSpan={2}
                  className={"thick-right-border thick-bottom-border"}
                >
                  {t("Days")}
                </td>
                <td
                  colSpan={5}
                  className={"thick-right-border thick-bottom-border"}
                >
                  {t("Prefecture invoice amount")}
                </td>
                <td
                  colSpan={5}
                  className={"thick-right-border thick-bottom-border"}
                >
                  {t("Calculated actual cost")}
                </td>
              </tr>
              <tr>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>

                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>

                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>

                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
                <td className={"thick-right-border wd-17"}></td>
              </tr>
            </tbody>
          </table>
        </div>
        {showHowManyColumn && (
          <div className={"summary__table_bottom--right"}>
            <table>
              <tbody>
                <tr>
                  <td></td>
                  <td>{total_page}</td>
                  <td>{t("Out of")}</td>
                  <td></td>
                  <td>{page_number}</td>
                  <td>{t("st or nd")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className={"summary__table_footer"}>
        <div className={"content"}>
          <p>
            {t(
              'note.　The handicapped child related to the decision to receive the benefit is a day care child eligible for free commuting stipulated in Article 24, Item 3 of the Order for Enforcement of the Child Welfare Law (Government Order No. 74 of 1948) or the same order Even if the child is eligible for free admission as stipulated in Article 27-2, Item 3, in the column of "MaximumMonthly Amount Paid by User ①",Enter the upper limit monthly fee for the user calculated assuming that the child with disabilities is not eligible for free tuition.'
            )}
          </p>
        </div>
      </div>
    </Wrapper>
  )
}
