import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { theme } from "@project/shared"

const StyledInputWrapper = styled.div<{
  isBordered?: boolean
  isHalfBorder?: boolean
}>`
  display: flex;
  border: 1px solid #d2d1d1;

  @media print {
    page-break-inside: avoid;
    border: 1px solid black !important;
    &:not(:first-child) {
      border-top: 0px solid black !important;
    }
  }

  @media (max-width: 1186px) {
    flex-direction: column;
    border: 0px;
    width: 100%;

    @media print {
      display: flex;
      flex-direction: row;
      width: auto;
    }
  }
  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f3f3f3;
    min-width: 190px;
    max-width: 190px;

    @media print {
      min-width: 150px !important;
      max-width: 150px !important;
      border-top: ${(props) =>
        props.isBordered
          ? "1px solid black !important"
          : props.isHalfBorder
          ? "0.5px solid gray !important"
          : null};
    }

    .label {
      margin: auto;
      margin-left: 10px;
    }
    .label-info {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .question-icon {
        margin-left: 12px;
      }
      .label {
        background: #e00000;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        padding: 3px 8px;
        margin: auto;
      }
    }
    @media (max-width: 1186px) {
      min-width: 100%;
      max-width: 100%;
      min-height: 40px;
      border: 1px solid #d2d1d1;
      @media print {
        min-width: 300px;
        max-width: 300px;
        background: #f3f3f3 !important;
      }
    }
  }
  .children-wrapper {
    white-space: pre-line;
    word-break: break-all;
    display: flex;
    flex: 1;
    align-self: center;
    padding: 20px;
    @media print {
      border-left: 1px solid black !important;
      border-top: ${(props) =>
        props.isBordered ? "1px solid black !important" : "none !important"};
    }
    .error {
      input {
        border-color: red;
      }
      .ant-select-selector {
        border-color: red;
      }
    }
    .input {
      width: 420px;
      border-radius: 5px;
      input {
        background: white !important;
      }
    }
    span.custom-span {
      margin-top: auto;
      margin-bottom: auto;
    }
    .input-info {
      color: #444748;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 20px;
    }
    .block {
      display: block;
    }
    .required {
      color: #e00000;
    }
    .select {
      .ant-select-selector {
        width: 200px;
        height: 40px !important;
        border-radius: 5px;
        align-items: center;
      }
    }
    @media (max-width: 1186px) {
      width: 100%;
      align-self: flex-start;
      padding: 20px 0px;
      flex-direction: column;
      .input {
        width: 100%;
      }
      .select {
        .ant-select-selector {
          width: auto;
        }
      }
      .custom-span {
        margin-left: 0px;
      }
      p.input-info {
        margin-left: 0px;
      }
      @media print {
        display: block !important;
        padding: 20px;
      }
    }

    .over-rider-text-area-class {
      textarea {
        height: 96px !important;
        min-height: 96px !important;
        max-height: 96px !important;

        @media (max-width: 550px) {
          height: 100px !important;
          min-height: 100px !important;
          max-height: 100px !important;
        }
      }
    }
    .no-created-wrapper {
      .ant-select .ant-select-arrow {
        display: flex !important;
      }
    }
  }

  @media print {
    border: 0px;
    border-right: 1px solid ${theme.black};
    .label-wrapper {
      border: 1px solid ${theme.black};
      border-right: 0px;
      border-bottom: 0px;
    }

    .children-wrapper {
      border: 1px solid ${theme.black};
      border-right: 0px;
      border-bottom: 0px;
    }

    &:last-child .label-wrapper,
    &:last-child .children-wrapper {
      border-bottom: 1px solid ${theme.black};
    }
  }
`

interface StyledFormWrapperProps {
  isRequired?: boolean
  label: string
  children: React.ReactElement
  className?: string
  isBorder?: boolean
  isHalfBorder?: boolean
}

const StyledFormWrapper = ({
  isRequired = false,
  label,
  children,
  className,
  isBorder,
  isHalfBorder,
}: StyledFormWrapperProps) => {
  const { t } = useTranslation()
  return (
    <StyledInputWrapper
      className={className}
      isBordered={isBorder}
      isHalfBorder={isHalfBorder}
    >
      <div className={"label-wrapper"}>
        <div>
          <p className={"label"}>{t(label)}</p>
        </div>
        <div className={"label-info"}>
          {isRequired && <p className={"label"}>{"必須"}</p>}
        </div>
      </div>
      <div className={"children-wrapper"}>{children}</div>
    </StyledInputWrapper>
  )
}

export { StyledFormWrapper }
