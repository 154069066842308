import { useQuery } from "react-query"
import { getMaximumResultSlipOutput } from "../../../services/NHIFbilling"
import {
  MaximumResultOutputSlipResponse,
  ProvisionCity,
  ProvisionCityDispatcher,
  SheetData,
  SheetsDispatcher,
  SiblingSheetData,
  SiblingSheetsDispatcher,
} from "./types"

import { notification } from "antd"
import { t } from "i18next"

export function callMaximumResultOutputSlip(
  currentSearchQueries: {
    year: number
    month: number
    facility: number
  },
  afterSchool: number,
  dataDispatcher: ProvisionCityDispatcher,
  sheetsDispather: SheetsDispatcher,
  siblingSheetsDispather: SiblingSheetsDispatcher
) {
  return useQuery(
    ["maximum-result-output-slip", currentSearchQueries],
    () =>
      getMaximumResultSlipOutput({
        facilityId: currentSearchQueries?.facility,
        year: currentSearchQueries?.year,
        month: currentSearchQueries?.month,
        query: afterSchool ? `is_afterschool=${afterSchool}` : "",
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: ({ data }: { data: MaximumResultOutputSlipResponse }) => {
        // 市区町村でグループ化
        dataDispatcher(() => {
          const cities = new Map<string, ProvisionCity>()

          data.output.forEach((city) => {
            cities.set(city.municipality_number, {
              ...city,
              receipt_sibling_upper_limit_managements: [],
            })
          })

          data.sibling.forEach((city) => {
            if (cities.has(city.municipality_number)) {
              cities.get(
                city.municipality_number
              ).receipt_sibling_upper_limit_managements =
                city.receipt_sibling_upper_limit_managements
            } else {
              cities.set(city.municipality_number, {
                ...city,
                receipt_upper_limit_managements: [],
              })
            }
          })

          return Array.from(cities.values())
        })

        // 上限管理結果票（印刷用）に チェックボックスフラグ と データを格納
        sheetsDispather(() => {
          const sheetData: SheetData = {}
          data.output.map((pCity) => {
            pCity.receipt_upper_limit_managements.map((rUpperLimitManage) => {
              sheetData[rUpperLimitManage.id] = {
                checked: false,
                receiptUpperLimitManagement: rUpperLimitManage,
              }
            })
          })
          return sheetData
        })

        // 複数児童の上限管理結果票（印刷用）に チェックボックスフラグ と データを格納
        siblingSheetsDispather(() => {
          const siblingSheetData: SiblingSheetData = {}
          data.sibling.map((pCity) => {
            pCity.receipt_sibling_upper_limit_managements.map(
              (rSiblingUpperLimitManage) => {
                siblingSheetData[rSiblingUpperLimitManage.id] = {
                  checked: false,
                  receiptSiblingUpperLimitManagement: rSiblingUpperLimitManage,
                }
              }
            )
          })
          return siblingSheetData
        })
      },
      onError: (e: any) => {
        const msg =
          e?.status == 403
            ? "User doesn't have permission to view this page"
            : e?.error?.data?.message ||
              "Something went wrong. Please contact administrator"
        notification.error({
          message: t(msg),
        })
      },
    }
  )
}
