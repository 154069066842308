import { dynamicLangString, theme } from "@project/shared"
import { notification, Skeleton } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { getAllStaffList } from "../../../services/attendanceSchedule"
import {
  fetchBulkChildAttendance,
  saveBulkChildAttendance,
} from "../../../services/attendanceTable"
import {
  UserAttendanceBulkEditInfoTable,
  UserAttendanceBulkEditOperation,
} from "../../molecules"
import { IDisplayOptions } from "../../molecules/UserAttendanceBulkEditOperation"
import Link from "next/link"

const Container = styled.div`
  margin: 20px 0;
`

const IndividualSupportAttendanceAlert = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${theme.red2};
  background: #ffffff;
  color: ${theme.red2};
  .underlined {
    text-decoration: underline;
  }
`
interface IBulkUserAttendanceEditPage {
  defaultDisplayOptions: IDisplayOptions
}

export const BulkUserAttendanceEditPage = ({
  defaultDisplayOptions,
}: IBulkUserAttendanceEditPage) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { isLoading: staffListLoading, data: staffList } = useQuery(
    ["staffList"],
    async () => {
      const staffData = await getAllStaffList()
      const staffOptions = []
      if (staffData.data) {
        staffData.data.map((staff) => {
          const staffObj = {
            key: staff?.id,
            label: staff?.staff_name,
            value: staff?.id,
            furigana_name: staff?.staff_name_furiganaame,
          }
          staffOptions.push(staffObj)
        })
      }
      return staffOptions
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const [displayParams, setDisplayParams] = useState(defaultDisplayOptions)
  const [userAttendanceData, setUserAttendanceData] = useState([])
  const [bulkOptions, setBulkOptions] = useState({})

  const { data: attendanceData, isLoading } = useQuery(
    ["getUserAttendanceForDay", displayParams],
    async () => {
      const query = []
      if (displayParams.facilityIds.length)
        query.push("facility_id=" + displayParams.facilityIds.join(","))
      if (displayParams.date) query.push("date=" + displayParams.date)
      if (displayParams.services.length)
        query.push("user_service_id=" + displayParams.services.join(","))
      const { data } = await fetchBulkChildAttendance(query.join("&"))
      const attendance_data = data?.attendance_data?.map((aData) => {
        const child_actual_cost_burden = aData?.actual_costs?.map((aCost) => {
          const actualCostBurden = aData?.child_actual_cost_burden?.find(
            (acb) => acb?.actual_cost_id == aCost?.id
          )
          return {
            actual_cost_burden_user_record_id:
              actualCostBurden?.actual_cost_burden_user_record_id,
            actual_cost_id: aCost?.id,
            actual_cost_name: aCost?.actual_cost_name,
            actual_cost_price:
              actualCostBurden?.actual_cost_price || aCost?.price,
            child_id: aData?.child_id,
            date: aData?.date?.split("T")?.[0],
            facility_id: aData?.facility_id,
            used_flag: actualCostBurden ? true : false,
          }
        })
        return {
          ...aData,
          entry_hour: aData?.entry_time
            ? aData?.entry_time?.split(":")?.[0]
            : null,
          entry_minute: aData?.entry_time
            ? aData?.entry_time?.split(":")?.[1]
            : null,
          out_hour: aData?.out_time ? aData?.out_time?.split(":")?.[0] : null,
          out_minute: aData?.out_time ? aData?.out_time?.split(":")?.[1] : null,
          temperature1: aData?.temperature
            ? aData?.temperature?.toString()?.split(".")?.[0]
            : null,
          temperature2: aData?.temperature
            ? aData?.temperature?.toString()?.split(".")?.[1]
              ? aData?.temperature?.toString()?.split(".")?.[1]
              : aData?.temperature?.toString()?.split(".")?.[0]
              ? "00"
              : null
            : null,
          save_staff_id: aData?.save_staff_id ? aData?.save_staff_id : null,
          absence_reason: aData?.absence_reason ? aData?.absence_reason : null,
          child_actual_cost_burden,
          next_scheduled_date:
            aData?.next_scheduled_date &&
            moment(aData?.next_scheduled_date).isValid()
              ? moment(aData?.next_scheduled_date).format("MM月DD日")
              : "",
        }
      })
      setUserAttendanceData(attendance_data)
      return {
        attendance_stats: data?.attendance_stats,
        attendance_data: data?.attendance_data,
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const childWithoutOfferTime = useMemo(() => {
    const list = []
    attendanceData?.attendance_data?.forEach((attendance) => {
      if (!attendance?.has_offer_time) {
        list.push({
          childName: attendance?.child_name,
          link: attendance?.individual_support_plan_id
            ? `/individual-support-plans/edit/${attendance?.individual_support_plan_id}?child_id=${attendance?.child_id}`
            : `/individual-support-plans/add?child_id=${attendance?.child_id}`,
        })
      }
    })
    return list
  }, [attendanceData?.attendance_data])

  const getDayAttendanceLink = () => {
    return `/user-attendance-table/day-list?date=${
      displayParams?.date
    }&facilityId=${displayParams?.facilityIds?.join(",")}`
  }

  const { mutate: saveBulkAttendance, isLoading: isSaving } = useMutation(
    saveBulkChildAttendance,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Attendance", "Updated Successfully"]),
        })
        router.push(getDayAttendanceLink())
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const handleDisplayChange = (values) => {
    setDisplayParams(values)
    const queries = []
    Object.keys(values).map((key) => {
      if (Array.isArray(values[key])) {
        if (values[key].length) queries.push(`${key}=${values[key].join(",")}`)
      } else if (values[key]) {
        queries.push(`${key}=${values[key]}`)
      }
    })
    router.push("?" + queries.join("&"), undefined, { shallow: true })
  }

  const handleBulkChange = (values) => {
    setBulkOptions({ ...values, reset: false })
  }

  const handleReset = () => {
    setBulkOptions({ ...bulkOptions, reset: true })
  }

  const onDayChange = (type: "yesterday" | "today" | "tomorrow") => {
    let newDate = moment(displayParams.date)
    switch (type) {
      case "yesterday":
        newDate = newDate.subtract(1, "day")
        break
      case "today":
        newDate = moment()
        break
      case "tomorrow":
        newDate = newDate.add(1, "day")
    }
    const newDisplayOptions = {
      ...displayParams,
      date: newDate.format("YYYY-MM-DD"),
    }
    handleDisplayChange(newDisplayOptions)
  }

  return (
    <Container>
      {childWithoutOfferTime?.length ? (
        <IndividualSupportAttendanceAlert>
          <img
            src={"/assets/icons/NotificationAlert.svg"}
            height={20}
            width={20}
          />
          &nbsp;&nbsp;&nbsp;
          {t(
            "If there is no provision time registered in the individual support plan other table, the calculated number of hours on the service provision record sheet will be automatically recorded as 0.5. If you would like to calculate the number of hours according to the provided hours, please register the individual support plan other table before recording attendance."
          )}
          <br />
          {t("Target children")}
          {":"}
          <br />
          {childWithoutOfferTime?.map((childInfo, index) => (
            <>
              {index > 0 ? ", " : ""}
              <Link href={childInfo?.link} key={childInfo?.childName}>
                <a target={"_blank"} className={"underlined"}>
                  {childInfo?.childName}
                </a>
              </Link>
            </>
          ))}
        </IndividualSupportAttendanceAlert>
      ) : (
        <></>
      )}
      <UserAttendanceBulkEditOperation
        defaultDisplayOptions={displayParams}
        onDisplayChange={handleDisplayChange}
        onBulkReflect={handleBulkChange}
        onReset={handleReset}
        staffList={staffList}
      />
      {isLoading || staffListLoading ? (
        <Skeleton />
      ) : (
        <UserAttendanceBulkEditInfoTable
          date={displayParams.date}
          handlePagination={onDayChange}
          stats={attendanceData?.attendance_stats ?? {}}
          attendanceData={userAttendanceData}
          updateUserAttendance={setUserAttendanceData}
          bulkOptions={bulkOptions}
          staffList={staffList}
          handleSave={saveBulkAttendance}
          cancelRoute={getDayAttendanceLink()}
          isSaving={isSaving}
        />
      )}
    </Container>
  )
}
