import React, { useEffect, useRef, useState } from "react"
import { Typography } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Button } from "../../atoms"
import { theme } from "@project/shared"
import { TimeRangeInput } from "../../molecules/TimeRangeInput"

const Wrapper = styled.div<any>`
  .top_bnt_container {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
    padding-left: 5px;
    /* justify-content: center; */
  }
  .inner_container {
    margin: 13px auto;

    width: ${({ width }) => (width ? `${width}px` : `fit-content`)};
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 900;
      pointer-events: none;
      background: rgba(224, 0, 0, 0.1);
    }
    .box {
      position: relative;
      z-index: 0;
      pointer-events: ${(props) => (props?.isDisabled ? "none" : "auto")};
      cursor: auto;
      padding: 7px;
      &_top {
        background: ${theme.green1};
        border-radius: 5px 5px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
          color: ${theme.base};
          font-weight: 400;
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
      }
      & .picker_container {
        padding: 8px 10px;
        h5 {
          font-size: 14px;
        }
        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
          div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            .time_sep,
            .range_sep {
              font-size: 18px;
              color: ${theme.black};
              font-weight: bold;
            }
          }
        }
      }
    }
    .child_development_support {
      background: #4cb476;
    }
    .other_services {
      background: #e26827;
    }
  }
  @media (max-width: 600px) {
    .inner_container {
      width: calc(100vw - 80px);
    }
    .inputs {
      flex-direction: row;
    }
  }
  @media (max-width: 415px) {
    padding: 6px;
    .gIbLVq {
      padding: 8px 0px !important;
    }
  }
`

const ButtonWrapper = styled.div`
  padding: 8px 10px;
  .add-remove-btn-wrapper {
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
  }
  .add-btn,
  .remove-button {
    width: 90px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    .add-btn,
    .remove-button {
      padding: 0px;
    }
    .add-remove-btn-wrapper {
      flex-direction: row;
      gap: 0px;
      button:not(:last-child) {
        margin-right: 0.3rem;
      }
    }
  }
`

export type CalendarHours = {
  id: number
  from: {
    hours: string
    minutes: string
  }
  to: {
    hours: string
    minutes: string
  }
}
interface CalanderTimeRangePorps {
  width?: number
  handleChange?: (val) => void
  forDay?: string
  dayDetail?: any
  service?: {
    name: string
    color: string
  }
}
export const CalanderTimeRange: React.FC<CalanderTimeRangePorps> = ({
  width,
  handleChange,
  forDay,
  dayDetail,
  service,
}) => {
  const { t } = useTranslation()

  const initialValues = {
    from: {
      hours: "",
      minutes: "",
    },
    to: {
      hours: "",
      minutes: "",
    },
  }

  const getHours = (key) => {
    const ids = [1, 2, 3]
    const businessHours = []
    if (!dayDetail)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    for (let i = 1; i <= ids.length; i++) {
      const START_HOUR = dayDetail ? dayDetail[`${key}_start_hour${i}`] : null
      const START_MINUTE = dayDetail
        ? dayDetail[`${key}_start_minute${i}`]
        : null
      const END_HOUR = dayDetail ? dayDetail[`${key}_end_hour${i}`] : null
      const END_MINUTE = dayDetail ? dayDetail[`${key}_end_minute${i}`] : null
      if (i === 1 || START_HOUR || START_MINUTE || END_HOUR || END_MINUTE)
        businessHours.push({
          id: i,
          from: {
            hours: START_HOUR ?? null,
            minutes: START_MINUTE ?? null,
          },
          to: {
            hours: END_HOUR ?? null,
            minutes: END_MINUTE ?? null,
          },
        })
    }
    return businessHours
  }

  const initialRuns = useRef(0)

  const [isDisabled, setIsDisabled] = useState<boolean>(
    dayDetail?.holiday === "1"
  )

  const [businessHoursTime, setBusinessHoursTime] = useState<
    Array<CalendarHours>
  >(getHours("business"))
  const [serviceProvisonTime, setServiceProvisonTime] = useState<
    Array<CalendarHours>
  >(getHours("service"))

  useEffect(() => {
    if (initialRuns.current < 1) {
      initialRuns.current++
      return
    }
    const changeObject = {
      day: forDay,
      changeFor: "holiday",
      value: isDisabled ? "1" : "0",
    }
    handleChange(changeObject)
  }, [isDisabled])

  const onTimeRangeChange = ({
    id,
    range,
    unit,
    value,
    previousData,
    setter,
  }: any) => {
    const data = [...previousData]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      [range]: {
        ...data[index][range],
        [unit]: value,
      },
    }
    if (unit == "hours") {
      if (!data[index][range]?.minutes) {
        data[index][range].minutes = "00"
      } else if (!value) {
        data[index][range].minutes = ""
      }
    }
    setter(data)
  }

  const handleNewRangeAddtion = (
    previousData: any[],
    setter: (data: any[]) => void
  ) => {
    setter([
      ...previousData,
      { id: previousData?.length + 1, ...initialValues },
    ])
  }
  const handleRangeRemove = (
    previousData: any[],
    setter: (data: any[]) => void
  ) => {
    const data = previousData?.splice(-1)[0]
    setter(previousData?.filter((v) => v.id !== data.id))
  }

  const containerStyle: React.CSSProperties = {
    background: isDisabled ? "rgba(224, 0, 0, 0.1)" : "#f3f3f3",
    pointerEvents: isDisabled ? "none" : "auto",
    zIndex: isDisabled ? "900" : "auto",
  }

  return (
    <Wrapper width={width} isDisabled={isDisabled}>
      <div className={"inner_container"}>
        <div className={"top_bnt_container"}>
          <Button
            text={isDisabled ? t("Holiday") : t("Business")}
            backgroundColor={isDisabled ? theme.disabledBlue : theme.blue5}
            color={theme.base}
            padding={10}
            width={60}
            onClick={() => {
              setIsDisabled(!isDisabled)
            }}
          />
        </div>
        <div className={"box"}>
          {service && service?.name && service?.color && (
            <div className={"box_top"} style={{ background: service.color }}>
              <Typography.Title level={5}>{service.name}</Typography.Title>
            </div>
          )}

          <div style={containerStyle}>
            {businessHoursTime?.map((range) => (
              <TimeRangeInput
                disabled={isDisabled}
                width={54}
                key={range?.id}
                minutesDisabled={isDisabled}
                range={range}
                rangeName={`${t("Business hour")} ${
                  range?.id === 1 ? "" : range?.id
                }`}
                handleChange={(val) => {
                  const changeObject = {
                    day: forDay,
                    changeFor:
                      "business_" +
                      (val.range === "from" ? "start" : "end") +
                      "_" +
                      (val.unit === "hours" ? "hour" : "minute") +
                      val.id,
                    value: val.value,
                  }
                  handleChange(changeObject)
                  if (!val.value && val.unit === "hours") {
                    changeObject.changeFor =
                      "business_" +
                      (val.range === "from" ? "start" : "end") +
                      "_minute" +
                      val.id
                    handleChange(changeObject)
                  }
                  onTimeRangeChange({
                    ...val,
                    previousData: businessHoursTime,
                    setter: setBusinessHoursTime,
                  })
                }}
              />
            ))}
            <AddRemoveBtn
              length={businessHoursTime?.length}
              disabled={isDisabled}
              handleNewRangeAddtion={() =>
                handleNewRangeAddtion(businessHoursTime, setBusinessHoursTime)
              }
              handleRangeRemove={() =>
                handleRangeRemove(businessHoursTime, setBusinessHoursTime)
              }
            />
          </div>

          {/* //service provison time */}
          <div style={containerStyle}>
            {serviceProvisonTime?.map((range) => (
              <TimeRangeInput
                disabled={isDisabled}
                key={range?.id}
                range={range}
                width={54}
                minutesDisabled={isDisabled}
                rangeName={`${t("Service provision time")} ${
                  range?.id === 1 ? "" : range?.id
                }`}
                handleChange={(val) => {
                  const changeObject = {
                    day: forDay,
                    changeFor:
                      "service_" +
                      (val.range === "from" ? "start" : "end") +
                      "_" +
                      (val.unit === "hours" ? "hour" : "minute") +
                      val.id,
                    value: val.value,
                  }
                  handleChange(changeObject)
                  if (!val.value && val.unit === "hours") {
                    changeObject.changeFor =
                      "service_" +
                      (val.range === "from" ? "start" : "end") +
                      "_minute" +
                      val.id
                    handleChange(changeObject)
                  }
                  onTimeRangeChange({
                    ...val,
                    previousData: serviceProvisonTime,
                    setter: setServiceProvisonTime,
                  })
                }}
              />
            ))}
            <AddRemoveBtn
              length={serviceProvisonTime?.length}
              disabled={isDisabled}
              handleNewRangeAddtion={() =>
                handleNewRangeAddtion(
                  serviceProvisonTime,
                  setServiceProvisonTime
                )
              }
              handleRangeRemove={() =>
                handleRangeRemove(serviceProvisonTime, setServiceProvisonTime)
              }
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export const AddRemoveBtn = ({
  length,
  handleNewRangeAddtion,
  handleRangeRemove,
  disabled,
}: {
  length: number
  handleRangeRemove: () => void
  handleNewRangeAddtion: () => void
  disabled?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <ButtonWrapper>
      {length >= 3 ? (
        <Button
          text={t("Remove")}
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"transparent"}
          icon={"close"}
          onClick={handleRangeRemove}
          disabled={disabled}
        />
      ) : length >= 2 ? (
        <div className={"add-remove-btn-wrapper"}>
          <Button
            className={"add-btn"}
            text={t("Add")}
            shape={"circle"}
            borderColor={theme.gray2}
            backgroundColor={"transparent"}
            icon={"add"}
            onClick={handleNewRangeAddtion}
            disabled={disabled}
          />
          <Button
            className={"remove-button"}
            text={t("Remove")}
            shape={"circle"}
            borderColor={theme.gray2}
            backgroundColor={"transparent"}
            icon={"close"}
            onClick={handleRangeRemove}
            disabled={disabled}
          />
        </div>
      ) : (
        <Button
          className={"add-btn"}
          text={t("Add")}
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"transparent"}
          icon={"add"}
          onClick={handleNewRangeAddtion}
          disabled={disabled}
        />
      )}
    </ButtonWrapper>
  )
}
