import { API } from "@project/shared"

export const getChildInfo = (id) => API.get(`/child/${id}`)

export const getActiveContractFacilities = ({ query }) =>
  API.get(`/recurring-schedule-settings/facilities${query}`)

export const getRecurringScheduleSetting = ({ year, month, query }) =>
  API.get(`/recurring-schedule-settings/${year}/${month}${query && query}`)

export const createUpdateRecurringScheduleSetting = (payload) =>
  API.post("recurring-schedule-settings", JSON.stringify(payload))

export const removeOneRecurringScheduleSetting = (id) =>
  API.delete(`/recurring-schedule-settings/${id}`)
