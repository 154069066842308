// packages
import { t } from "i18next"
import { Button } from "antd"

// styled components
import { PrintOnlyHeaderContent } from "../styles"

// icons
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"

// types
import { ProgressRecordHeaderProps } from "../types"

export const PrintOnlyHeader: React.FC<Partial<ProgressRecordHeaderProps>> = ({
  currentChildName,
  currentDate,
}) => {
  return (
    <PrintOnlyHeaderContent className={"only-print"}>
      <h3 className={"heading"}>{t("Program progress record")}</h3>
      <div className={"more-info flexbox"}>
        <p className={"year-month"}>{currentDate.format("YYYY年MM月")}</p>
        <p className={"child-name"}>
          {t("Child name ")}
          {": "}
          <span>{currentChildName ?? t("All")}</span>
        </p>
      </div>
    </PrintOnlyHeaderContent>
  )
}

export const ProgressRecordHeader: React.FC<ProgressRecordHeaderProps> = ({
  children,
  currentDate,
  currentChildName,
  handleMonthChange,
}) => {
  const currentYear = +currentDate.format("YYYY")
  const currentMonth = +currentDate.format("MM")

  return (
    <div className={"flexbox table-head"}>
      <h4 className={"table-title"}>
        {children ? (
          children
        ) : (
          <span>
            {t("{{year}}年{{month}}月Program progress record ({{childName}})", {
              year: currentYear.toString().padStart(2, "0"),
              month: currentMonth.toString().padStart(2, "0"),
              childName: currentChildName,
            })}
          </span>
        )}
      </h4>
      <div className={"month-controller no-print"}>
        <Button
          onClick={() => {
            if (currentMonth == 1) {
              handleMonthChange(currentYear - 1, 12)
            } else {
              handleMonthChange(currentYear, currentMonth - 1)
            }
          }}
          className={"left-btn"}
        >
          <DoubleLeftOutlined />
          <span className={"left-btn-label"}>{t("Last month")}</span>
        </Button>
        <Button
          className={"active active-btn"}
          onClick={() => {
            handleMonthChange(
              new Date().getFullYear(),
              new Date().getMonth() + 1
            )
          }}
        >
          {t("This month")}
        </Button>
        <Button
          onClick={async () => {
            if (currentMonth == 12) {
              handleMonthChange(currentYear + 1, 1)
            } else {
              handleMonthChange(currentYear, currentMonth + 1)
            }
          }}
          className={"right-btn"}
        >
          <span className={"right-btn-label"}>{t("Next month")}</span>
          <DoubleRightOutlined />
        </Button>
      </div>
    </div>
  )
}

export * from "./FilterActionBlock"
export * from "./ProgressRecordBlock"
