import styled from "styled-components"
import { theme } from "@project/shared"

export const Wrapper = styled.div`
  .options__wrapper {
    padding: 36px 0;
    .options__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .options {
        display: flex;
        gap: 1rem;
        align-items: center;
        &--text {
          display: flex;
          align-items: center;
          min-width: 6.5rem;
          svg {
            margin-right: 0.5rem;
            color: ${theme.gray2};
            font-size: 14px;
            font-weight: bold;
          }
        }
        &--selector {
          width: 150px;
        }
        &--selector.facility-sel-input-container {
          width: auto;

          @media (max-width: ${theme.breakpoints.sm}) {
            > .dynamic-dropdown-width {
              max-width: 200px;
            }
          }
        }
        .year__month--selector {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;
          .year__selector,
          .month__selector {
            display: flex;
            gap: 0.3rem;
            align-items: center;
            .ant-select-selector {
              width: 80px;
            }
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        .options {
          flex-direction: column;
          align-items: flex-start;
          .year__month--selector {
            /* flex-direction: column; */
            align-items: flex-start;
          }
        }
      }

      @media screen and (max-width: ${theme.breakpoints.sm}) {
        gap: 0px;
        > div:not(:last-child) {
          margin-bottom: 1rem;
        }
        .options {
          gap: 0px;
          > div {
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0px;
            }
            &.year__month--selector {
              gap: 0px;
              > div {
                margin-right: 1em;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
`
