import { Table } from "antd"
import { useTranslation } from "react-i18next"
import { CalanderTimeRange, YearPaginateBtn } from "../../../molecules"
import styled from "styled-components"
import { Grid, PageCard, TextField, theme } from "@project/shared"
import { OwnerButton, Tag } from "../../../atoms"
import router from "next/router"
import { Divisor } from "../../../../utils"

const WorkStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const Wrapper = styled.div`
  margin-top: 30px;
  .numbered-section-first {
    margin: 0px 0 10px;
    background-color: rgb(243, 243, 243);
    width: 100%;
    padding: 12px;
    span {
      display: inline-flex;
      justify-content: center;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background: #0782c8;
      color: #fff;
    }
  }
  .numbered-section {
    margin: 28px 0 10px;
    background-color: rgb(243, 243, 243);
    width: 100%;
    padding: 12px;
    span {
      display: inline-flex;
      justify-content: center;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background: #0782c8;
      color: #fff;
    }
  }
  .overwrite-para {
    margin-top: 26px;
    span {
      color: #e00000;
    }
  }
  .override-facilities-checkboxes {
    display: block;
    width: 100%;
  }

  .select-wrap {
    display: flex;
    margin-top: 3px;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      margin-bottom: 20px;
    }
    .select-down {
      margin-left: 15px;
    }
  }

  .overflow-text {
    @media (max-width: 380px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .mb-flex-row {
    display: flex;
    @media (max-width: 380px) {
      display: flex;
    }
    .last-select {
      margin-left: 10px;
    }
  }

  .occupation-subject {
    @media (max-width: 380px) {
      margin-left: 30px;
    }
  }

  .radio-btn {
    @media (max-width: 429px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      white-space: pre;
      align-items: flex-start;
    }
  }

  .mobile-width-100 {
    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .width-100 {
    @media (max-width: 700px) {
      min-width: 100%;
    }
  }

  .ant-row {
    @media (max-width: 640px) {
      flex-flow: column !important;
    }
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }
  table > tbody > tr > td {
    vertical-align: baseline;
    padding: 0px;
  }

  .ant-pagination {
    display: none;
  }

  .business-tag {
    margin-left: 10px;
  }

  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .wd-56 {
    min-width: 56px;
  }
`

export const BusinessTable = ({
  formik,
  updateBusinessDays,
  year,
  title,
  serviceObj,
  updateYear,
  isSaving = false,
  isLoading = false,
  hasSetting = false,
}: {
  formik: any
  title: string
  updateBusinessDays: (val) => void
  serviceObj: {
    name: string
    color: string
  }
  year: number
  updateYear: (val) => void
  isSaving?: boolean
  isLoading?: boolean
  hasSetting?: boolean
}) => {
  const { t } = useTranslation()
  const updateBusinessDay = (val) => {
    const currentBusinessDays = formik.values?.business_days
    if (val?.day) {
      currentBusinessDays[val?.day][val?.changeFor] = val?.value
      if (val?.changeFor?.includes("_hour")) {
        const endKey = val?.changeFor?.replace("_hour", "_minute")
        if (!currentBusinessDays[val?.day][endKey]) {
          currentBusinessDays[val?.day][endKey] = "00"
        }
      }

      updateBusinessDays({
        ...formik.values,
        business_days: currentBusinessDays,
      })
    }
  }

  const handleTimeChange = (key, value) => {
    if (value?.match(/^[0-9]\d*$/)) {
      formik.setFieldValue(key, value)
    } else if (!value) {
      formik.setFieldValue(key, "")
    }
  }

  const renderCalendarTimeRange = (details) => {
    const dayDetail: any = {}

    dayDetail.holiday = details?.[`holiday`]

    dayDetail.business_start_hour1 = details?.[`business_start_hour1`]
    dayDetail.business_start_minute1 = details?.[`business_start_minute1`]

    dayDetail.business_end_hour1 = details?.[`business_end_hour1`]
    dayDetail.business_end_minute1 = details?.[`business_end_minute1`]

    dayDetail.business_start_hour2 = details?.[`business_start_hour2`]
    dayDetail.business_start_minute2 = details?.[`business_start_minute2`]

    dayDetail.business_end_hour2 = details?.[`business_end_hour2`]
    dayDetail.business_end_minute2 = details?.[`business_end_minute2`]

    dayDetail.business_start_hour3 = details?.[`business_start_hour3`]
    dayDetail.business_start_minute3 = details?.[`business_start_minute3`]

    dayDetail.business_end_hour3 = details?.[`business_end_hour3`]
    dayDetail.business_end_minute3 = details?.[`business_end_minute3`]

    dayDetail.service_start_hour1 = details?.[`service_start_hour1`]
    dayDetail.service_start_minute1 = details?.[`service_start_minute1`]

    dayDetail.service_end_hour1 = details?.[`service_end_hour1`]
    dayDetail.service_end_minute1 = details?.[`service_end_minute1`]

    dayDetail.service_start_hour2 = details?.[`service_start_hour2`]
    dayDetail.service_start_minute2 = details?.[`service_start_minute2`]

    dayDetail.service_end_hour2 = details?.[`service_end_hour2`]
    dayDetail.service_end_minute2 = details?.[`service_end_minute2`]

    dayDetail.service_start_hour3 = details?.[`service_start_hour3`]
    dayDetail.service_start_minute3 = details?.[`service_start_minute3`]

    dayDetail.service_end_hour3 = details?.[`service_end_hour3`]
    dayDetail.service_end_minute3 = details?.[`service_end_minute3`]

    return (
      <div className={"calendar"}>
        <CalanderTimeRange
          handleChange={updateBusinessDay}
          forDay={details?.day}
          dayDetail={dayDetail}
          service={serviceObj}
        />
      </div>
    )
  }

  const columns = [
    {
      title: t("Sun"),
      dataIndex: "sun",
      key: "sun",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Mon"),
      dataIndex: "mon",
      key: "mon",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Tue"),
      dataIndex: "tue",
      key: "tue",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Wed"),
      dataIndex: "wed",
      key: "wed",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Thu"),
      dataIndex: "thu",
      key: "thu",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Fri"),
      dataIndex: "fri",
      key: "fri",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
    {
      title: t("Sat"),
      dataIndex: "sat",
      key: "sat",
      render: (data) => renderCalendarTimeRange(data),
      align: "center" as const,
    },
  ]

  const ButtonWrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    margin-top: 30px;
    button {
      :first-child {
        margin-right: 38px;
      }
    }
  `

  return (
    <Wrapper>
      <PageCard>
        <div className={"header-container"}>
          <div className={"title-container"}>
            <span>{title}</span>
            {hasSetting ? (
              <Tag backgroundColor={"#E26827"} className={"business-tag"}>
                <span style={{ color: "#ffffff" }}>{t("Set already")}</span>
              </Tag>
            ) : (
              <Tag backgroundColor={"#F3F3F3"} className={"business-tag"}>
                <span style={{ color: "#000000" }}>{t("No Setting")}</span>
              </Tag>
            )}
          </div>
          <div className={"pagination-container"}>
            <YearPaginateBtn
              prev={t("Last year")}
              current={t("This year")}
              next={t("Next year")}
              handleClick={(key) => {
                switch (key) {
                  case "next":
                    updateYear(year + 1)
                    break
                  case "current":
                    updateYear(new Date().getFullYear())
                    break
                  case "prev":
                    updateYear(year - 1)
                    break
                }
              }}
            />
          </div>
        </div>
        <Divisor />
        <div className={"numbered-section-first"}>
          <span>{"1"}</span>
          {t("Set business days, business hours, and service hours")}
        </div>
        <p style={{ width: "100%" }}>
          {t(
            'From the calendar below, click "Open", "Holiday" and select the business day or the day when the lunch will be provided.'
          )}
          <br />
          {t(
            "Please set the time by selecting the pull-down menu for business hours and service hours for each day."
          )}
        </p>
        <Table
          dataSource={[formik.values?.business_days]}
          columns={columns}
          bordered
          loading={isLoading}
        />
        <div className={"numbered-section"}>
          <span>{"2"}</span>
          {t("Set the registered contents of the work style list")}
        </div>
        <WorkStyle>
          <Grid className={"grid-header"} background>
            {t("Number of hours that full-time staff should work")}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <span className={"wd-56"}>{t("1 day:")}</span>
                <TextField
                  width={"80px"}
                  height={"40px"}
                  bgcolor={"white"}
                  name={"day_hours"}
                  value={formik.values.day_hours}
                  onChange={(e) =>
                    handleTimeChange("day_hours", e.target.value)
                  }
                />
                <span>{t("Hours")}</span>
                <TextField
                  width={"80px"}
                  height={"40px"}
                  bgcolor={"white"}
                  name={"day_minutes"}
                  value={formik.values.day_minutes}
                  onChange={(e) =>
                    handleTimeChange("day_minutes", e.target.value)
                  }
                />{" "}
                <span>{t("Minute ")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  marginTop: "10px",
                }}
              >
                <span className={"wd-56"}>{t("1 week:")}</span>
                <TextField
                  width={"80px"}
                  height={"40px"}
                  bgcolor={"white"}
                  name={"week_hours"}
                  value={formik.values.week_hours}
                  onChange={(e) =>
                    handleTimeChange("week_hours", e.target.value)
                  }
                />{" "}
                <span>{t("Hours")}</span>
                <TextField
                  width={"80px"}
                  height={"40px"}
                  bgcolor={"white"}
                  name={"week_minutes"}
                  value={formik.values.week_minutes}
                  onChange={(e) =>
                    handleTimeChange("week_minutes", e.target.value)
                  }
                />{" "}
                <span>{t("Minute ")}</span>
              </div>
            </div>
          </Grid>
          <Grid className={"flex-break"} />
        </WorkStyle>
        <ButtonWrapper>
          <OwnerButton
            typeOf={"secondary"}
            onClick={() => {
              router.push("/facility")
            }}
            text={t("Cancel")}
            disabled={isSaving}
          />
          <OwnerButton
            type={"button"}
            onClick={() => {
              formik.handleSubmit()
            }}
            shape={"circle"}
            text={t("Save")}
            isLoading={isSaving}
            disabled={isLoading}
          />
        </ButtonWrapper>
      </PageCard>
    </Wrapper>
  )
}
