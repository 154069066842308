import {
  SelectInput,
  Checkboxes,
  theme,
  RadioButtons,
  YES_NO_LIST,
  COMMENT_STATUS,
  SERVICES,
} from "@project/shared"
import { Typography } from "antd"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getAllChildUnpaginated } from "../../../services"
import { AuthContext, Highlighter, Title, TitleWrapper } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../../molecules"
import { japaneseAlphaRegex } from "../../../utils/validation"
import { useUpdateCommentSearchParams } from "../../../utils/useUpdateSearchParams"

interface iContactOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      facilityIds: string
      userServiceIds: string
      childId: string
      status: string
      displayLatestComment: boolean
      serviceProvisionDateRange: any
      commentDateRange: any
    }>
  >
  queryParam?: {
    facilityIds: string
    userServiceIds: string
    childId: string
    status: string
    displayLatestComment: boolean
    serviceProvisionDateRange: any
    commentDateRange: any
  }
  isLoadingData?: boolean
}

const Wrapper = styled.div<iContactOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 10px;
  }
  .select-up {
    margin-right: 6px;
    width: 300px;
  }
  .title-wrap {
    min-width: 200px;
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 555px) {
      width: 100%;
    }
  }
  @media (max-width: 405px) {
    width: 100%;
    .select-up {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`

const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;

    @media screen and (max-width: 426px) {
      width: 100%;
      gap: 10px;
      flex-direction: row !important;

      .check-button {
        padding: 12px 12px !important ;
      }
    }
  }

  .checkbox-wrapper {
    display: flex;
  }
  .check-button {
    height: 30px !important;
    &:last-child {
      margin-left: 10px;

      @media screen and (max-width: 426px) {
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  .select-wrapper {
    display: flex;
  }
  .text-field {
    width: 712px;
  }
  .calender {
    display: flex;
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  @media (max-width: 800px) {
    .text-field {
      width: 100%;
    }
  }
  @media (max-width: 555px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
    .select-wrapper {
      width: 100%;
    }
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 426px) {
    .select-wrapper {
      flex-direction: row;
      width: 100%;
      column-gap: 18px;
    }
    .select-down {
      margin-right: 0px;
      margin-bottom: 10px;
      min-width: 80px;
      max-width: 80px;
    }

    .long-select {
      width: 67%;
    }
    .ant-select {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .check-box-wrapper {
      .ant-checkbox-wrapper {
        span {
          word-break: break-all;
        }
      }
    }
  }
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .select-class {
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .date-picker {
    height: 40px;
    width: 160px;
    @media (max-width: 590px) {
      width: auto;
    }
  }

  .calender {
    display: flex;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
    .seperator {
      margin: auto 9px;
    }
  }
`

const DateWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`

const Text = styled(Title)`
  font-size: 16px;
`

const CommentOperationComponent: React.FC<iContactOptionComponent> = (
  props
) => {
  const { header, isLoadingData, setQueryParam, queryParam } = props
  const { t } = useTranslation()
  const [check, setCheck] = useState(
    queryParam?.facilityIds ? [+queryParam?.facilityIds] : []
  )
  const [userServiceIds, setUserServiceIds] = useState(null)
  const [status, setStatus] = useState(null)
  const { facilities } = useContext(AuthContext)
  const [childName, setChildName] = useState(null)
  const [childId, setChildId] = useState(null)
  const [isLatestComment, setIsLatestComment] = useState(null)
  const [otherFacilities, setOtherFacilities] = useState([])
  const [facilityIds, setFacilityIds] = useState("")
  const [commentDate, setCommentDate] = useState<{ from: any; to: any }>({
    from: null,
    to: null,
  })
  const [provisionDate, setProvisionDate] = useState<{ from: any; to: any }>({
    from: null,
    to: null,
  })
  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const allFacility = FACILITY_OPTION.map((item) => item.value)
  const [updateParams] = useUpdateCommentSearchParams()

  const handleSearch = () => {
    setQueryParam((prev) => {
      return {
        ...prev,
        facilityIds: facilityIds,
        userServiceIds: userServiceIds,
        childId: childId,
        status: status,
        displayLatestComment: isLatestComment,
        serviceProvisionDateRange:
          moment(provisionDate.from).isValid() &&
          moment(provisionDate.to).isValid()
            ? `${
                moment(provisionDate.from).isValid()
                  ? moment(provisionDate.from).format("YYYY-MM-DD")
                  : null
              }_${
                moment(provisionDate.to).isValid()
                  ? moment(provisionDate.to).format("YYYY-MM-DD")
                  : null
              }`
            : null,
        commentDateRange:
          moment(commentDate.from).isValid() && moment(commentDate.to).isValid()
            ? `${
                moment(commentDate.from).isValid()
                  ? moment(commentDate.from).format("YYYY-MM-DD")
                  : null
              }_${
                moment(commentDate.to).isValid()
                  ? moment(commentDate.to).format("YYYY-MM-DD")
                  : null
              }`
            : null,
      }
    })

    updateParams(
      {
        childId: childId,
        commentDateRange:
          moment(commentDate.from).isValid() && moment(commentDate.to).isValid()
            ? `${
                moment(commentDate.from).isValid()
                  ? moment(commentDate.from).format("YYYY-MM-DD")
                  : null
              }_${
                moment(commentDate.to).isValid()
                  ? moment(commentDate.to).format("YYYY-MM-DD")
                  : null
              }`
            : null,
      },
      "/parents/comment"
    )
  }
  const handleReset = () => {
    setCheck(null)
    setStatus(null)
    setUserServiceIds(null)

    setChildName(null)
    setChildId(null)
    setIsLatestComment(null)
    setFacilityIds("")
    setProvisionDate({
      from: null,
      to: null,
    })
    setCommentDate({
      from: null,
      to: null,
    })
    setQueryParam((prev) => {
      return {
        ...prev,
        facilityIds: "",
        userServiceIds: null,
        childId: "",
        status: "",
        displayLatestComment: null,
        serviceProvisionDateRange: null,
        commentDateRange: null,
      }
    })
  }
  const SORT_OPTION = FuriganaAlphabetsOption
  const { isLoading, data: childList } = useQuery(
    ["fetch-all-child"],
    getAllChildUnpaginated,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )
  const OTHER_CHILD_OPTIONS = !isLoading && [
    { value: null, label: t("All") },
    ...childList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.child_name || "", //Check for Furigana name first
        furigana: item?.child_name_furigana,
      }
    }),
  ]

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)
    const filteredFacilities =
      value == 0
        ? OTHER_CHILD_OPTIONS
        : OTHER_CHILD_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )
    setChildName(value)
    setOtherFacilities(filteredFacilities)
  }
  useEffect(() => {
    setChildName(0)
    setOtherFacilities(OTHER_CHILD_OPTIONS)
  }, [isLoading])
  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display facility")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            className={"check-button"}
            text={t("Check all")}
            onClick={() => {
              const facilityValuesIds = allFacility.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"check-button"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds("")
              setCheck(null)
            }}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}></TitleWrap>
        <div className={"check-box-wrapper"}>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={check}
            onChange={(values) => {
              const facilityValuesIds = values.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(values)
            }}
            options={FACILITY_OPTION}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("User service")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            options={SERVICES}
            value={userServiceIds}
            onChange={(val) => {
              setUserServiceIds(val?.join(","))
            }}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper className={"search-wrapper large-select"}>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Child name")} </Text>
        </TitleWrap>
        <div className={"select-wrapper"}>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            onChange={(value) => {
              handleSort(value)
              setChildName(value)
            }}
            className={"select-down"}
            defaultValue={"--"}
            value={childName}
          />
          <SelectInput
            width={200}
            height={40}
            options={otherFacilities}
            onChange={(value) => setChildId(value)}
            className={"long-select"}
            placeholder={t("All")}
            value={childId}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Status")} </Text>
        </TitleWrap>
        <SelectInput
          width={80}
          height={40}
          options={COMMENT_STATUS}
          onChange={(value) => setStatus(value)}
          className={"select-down"}
          defaultValue={"--"}
          value={status}
          placeholder={t("All")}
        />
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display latest comments")} </Text>
        </TitleWrap>
        <RadioButtons
          options={YES_NO_LIST}
          onChange={(evt) => setIsLatestComment(evt.target.value)}
          value={isLatestComment}
        />
      </SearchWrapper>
      <DateWrap>
        <SearchWrapper>
          <TitleWrap className={"title-wrap"}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Service provision date")} </Text>
          </TitleWrap>
          <div className={"calender"}>
            <DatePicker
              onDateChange={(val) =>
                setProvisionDate({
                  ...provisionDate,
                  from: moment(val),
                })
              }
              date={
                moment(provisionDate?.from).isValid()
                  ? moment(provisionDate?.from)
                  : null
              }
              format={"YYYY年MM月DD日"}
            />
            <Typography.Text>{"~"}</Typography.Text>
            <DatePicker
              onDateChange={(val) =>
                setProvisionDate({
                  ...provisionDate,
                  to: moment(val),
                })
              }
              date={
                moment(provisionDate?.to).isValid()
                  ? moment(provisionDate?.to)
                  : null
              }
              format={"YYYY年MM月DD日"}
            />
          </div>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap className={"title-wrap"}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Comment date")} </Text>
          </TitleWrap>
          <div className={"calender"}>
            <DatePicker
              onDateChange={(val) =>
                setCommentDate({
                  ...commentDate,
                  from: moment(val),
                })
              }
              date={
                moment(commentDate?.from).isValid()
                  ? moment(commentDate?.from)
                  : null
              }
              format={"YYYY年MM月DD日"}
            />
            <Typography.Text>{"~"}</Typography.Text>
            <DatePicker
              onDateChange={(val) =>
                setCommentDate({
                  ...commentDate,
                  to: moment(val),
                })
              }
              date={
                moment(commentDate?.to).isValid()
                  ? moment(commentDate?.to)
                  : null
              }
              format={"YYYY年MM月DD日"}
            />
          </div>
        </SearchWrapper>
      </DateWrap>
      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { CommentOperationComponent }
