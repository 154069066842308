export enum USER_REGISTRATION_PERMISSIONS {
  dontDisplay = 0,
  browseOnly = 1,
  browseAndEdit = 2,
}

export const COMMON_SCREEN_TO_ALL_FACILITY = {
  "Common info": [
    {
      id: "parents_management",
      label: "Parents Management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "recurring_schedule",
      label: "Recurring Schedule",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Invoice management": [
    {
      id: "joint_facility_management",
      label: "Joint facility management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Master management": [
    {
      id: "instructor_master",
      label: "Instructor master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "shift_master",
      label: "Shift master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "program_master",
      label: "Program master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "service_provision_content_master",
      label: "Service provision content master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "actual_cost_items_master",
      label: "Actual cost items master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "provision_city_master",
      label: "Provision city master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "day_nursery_schools_master",
      label: "Day nursery/Schools",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "joint_facility_master",
      label: "Joint facility",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "consultation_support_office_master",
      label: "Consultation support office",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "shuttle_car_master",
      label: "Shuttle car master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "progress_management_master",
      label: "Progress management master",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "My page settings": [
    {
      id: "facility_application_setting",
      label: "Facility application setting",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "my_display_setting",
      label: "My page display setting",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Contents management": [
    {
      id: "facility_tour_management",
      label: "Facility tour application management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
}

export const TABBED_FACILITIES = {
  "Child info": [
    {
      id: "child_care",
      label: "Child Care",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "recepient_certificate",
      label: "Recipient's certificate/contract supply amount",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "service_provision_record",
      label: "Service provision record",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "individual_support_plan",
      label: "Individual support plan",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "attendance_management",
      label: "Attendance management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Facility operation": [
    {
      id: "activity_management",
      label: "Activity management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "attendance_schedule",
      label: "Attendance schedule",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "attendance_record_table",
      label: "Attendance record table",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "business_hour_management",
      label: "Business hour management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "facility_information_history",
      label: "Facility information/history",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "program_progress_record",
      label: "Program progress record",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "system_status_management",
      label: "System status management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "facility_application_management",
      label: "Facility application management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "transportation_management",
      label: "Transportation management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "daily_business_report",
      label: "Daily Business Report",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "actual_cost_burden_schedule",
      label: "Actual cost burden schedule",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Contents management": [
    {
      id: "activity_record",
      label: "Activity record",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Form management": [
    {
      id: "service_provision_form",
      label: "Service provision record sheet",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "contract_report_form",
      label: "contract report",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "remuneration_notification_form",
      label: "Notification form regarding remuneration calculation categories",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "self_eval_form",
      label: "List of self-evaluation charts for parents",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "monthly_status_form",
      label:
        "Monthly use status table (support for children with disabilities)",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "treatment_improvement_form",
      label: "Treatment improvement addition amount result table",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "addition_minutes_form",
      label: "Various additions and minutes management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "Billing management": [
    {
      id: "revenue_management",
      label: "Revenue management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "additional_facility_management",
      label: "Additional item management(facility)",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "additional_children_item_management",
      label: "Addition item management(children)",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "actual_cost_burden_table",
      label: "Actual cost burden record table",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "cap_intelligence_management",
      label: "Upper limit management result information management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "municipal_billing_info_management",
      label: "Municipal sub-component billing amount information management",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
    {
      id: "bill_csv_management",
      label: "国保連請求 帳票・CSV出力",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "System settings": [
    {
      id: "system_mail_settings",
      label: "System mail settings",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
  "My page settings": [
    {
      id: "notes_from_parents",
      label: "Notes from parents",
      permission: USER_REGISTRATION_PERMISSIONS.browseAndEdit,
    },
  ],
}

export const DUMMY = {
  つくば: { ...TABBED_FACILITIES },
  山武: { ...TABBED_FACILITIES },
  "(放) 宮子": { ...TABBED_FACILITIES },
}
