import {
  dynamicLangString,
  Grid,
  Required,
  SelectInput,
  TextField,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Spin } from "antd"
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { DatePicker } from "../.."
import {
  createProgramRecord,
  getProgramRecord,
  updateProgramRecord,
} from "../../../services"
import {
  AuthContext,
  Divisor,
  FormTitle,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms"
import {
  DailyBusinessReportUpload,
  getBase64,
  handleDownload,
} from "../DailyBusinessReport/DailyBusinessReportUpload"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"

const EditorComponent = dynamic(() => import("./Editor/Editor"), {
  ssr: false,
})

interface IProgramRecordFormProps {
  isEdit?: boolean
  isDeleting?: boolean
  handleDelete?: () => void
  showDeleteButton?: boolean

  id?: any
}

interface IStyleProps {
  top?: boolean
}

export const PUBLISH_OPTIONS = [
  { label: t("公開 "), value: "1" }, //Publish_Yes
  { label: t("非公開 "), value: "0" }, //Publish_No
]

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  margin-bottom: 40px;
  padding: 13px 30px 30px 36px;
  .add-article-btn {
    padding: 5px 8px;
  }

  @media screen and (min-width: 767px) and (max-width: 3000px) {
    .label-container {
      min-width: 300px !important;
      max-width: 300px !important;
    }
  }

  @media screen and (max-width: 426px) {
    .add-article-block-form {
      margin-bottom: 0px !important;
    }

    .last-padding-grid {
      padding-bottom: 0px !important;
    }

    .styled-article-form {
      margin-bottom: 0 !important;
    }

    .styled-setting-form {
      margin-bottom: 0 !important;
    }

    .label-container {
      border-top: 1px solid #d2d1d1 !important;
      padding: 12px !important;
    }
  }

  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
  .delete-article-btn {
    margin-bottom: 20px;
  }
  .ant-select-clear {
    transform: scale(1.2);
    margin-right: 6px;
    opacity: 1;
    color: #d2d1d1;
    display: flex;
    align-items: center;
  }

  .ant-select-clear:hover {
    color: #555755;
  }

  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
  .has-error-picker {
    border: 1px solid #e00000 !important;
  }
`

const GridFlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWrapper = styled.div<IStyleProps>`
  display: flex;
  margin-bottom: ${(props) => (props.top ? "-0.85rem" : "0.85rem")};
  margin-top: ${(props) => (props.top ? "20px" : "0")};

  button:not(:last-child) {
    margin-right: 0.625rem;
  }

  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }

  .cancel-btn,
  .save-btn {
    @media (max-width: 486px) {
      width: 100%;
    }
  }
`
const StyledFormWrapper = styled(StyledForm)<IStyleProps>`
  margin-bottom: ${(props) => (props.top ? "2.1rem" : "1.875rem")};
  .thumbnail {
    width: 100px;
    object-fit: contain;
  }

  button.download-btn {
    padding: 6px 16px;
    border-radius: 4px;
    margin-top: 10px;
  }
`
const HeaderContentWrapper = styled.div<IStyleProps>`
  padding-top: ${(props) => (props.top ? "10px" : "20px")};
  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e00000;
  }
`
const ArticleBlockButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  .add-article-btn {
    height: 30px;
    width: 206px;
  }
`
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProgramRecordListForm: React.FC<IProgramRecordFormProps> = ({
  isEdit,
  id,
  handleDelete,
  isDeleting,
  showDeleteButton,
}) => {
  moment.updateLocale("ja", {
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月",
    ],
  })
  const { t } = useTranslation()
  const router = useRouter()
  const [uploadCount, setUploadCount] = useState(1)

  const { facilities } = useContext(AuthContext)
  const FACILITY_OPTIONS = facilities.map((item) => {
    return {
      ...item,
      label: item?.facility_name_short,
      value: item?.id,
    }
  })
  const handleData = (data) => {
    formik?.setFieldValue("content1", data)
  }
  const handleData2 = (data) => {
    formik?.setFieldValue("content2", data)
  }
  const handleData3 = (data) => {
    formik?.setFieldValue("content3", data)
  }
  const handleData4 = (data) => {
    formik?.setFieldValue("content4", data)
  }
  const handleData5 = (data) => {
    formik?.setFieldValue("content5", data)
  }
  const handleData6 = (data) => {
    formik?.setFieldValue("content6", data)
  }
  const handleData7 = (data) => {
    formik?.setFieldValue("content7", data)
  }
  const handleData8 = (data) => {
    formik?.setFieldValue("content8", data)
  }
  const handleData9 = (data) => {
    formik?.setFieldValue("content9", data)
  }
  const handleData10 = (data) => {
    formik?.setFieldValue("content10", data)
  }

  const getUploadCountInEdit = (values: any) => {
    let uploadCount = 1
    if (values) {
      // if (values?.content1 !== "") uploadCount += 1

      if (values?.content2 !== "") uploadCount += 1

      if (values?.content3 !== "") uploadCount += 1

      if (values?.content4 !== "") uploadCount += 1

      if (values?.content5 !== "") uploadCount += 1

      if (values?.content6 !== "") uploadCount += 1

      if (values?.content7 !== "") uploadCount += 1

      if (values?.content8 !== "") uploadCount += 1

      if (values?.content9 !== "") uploadCount += 1

      if (values?.content10 !== "") uploadCount += 1
    }
    if (uploadCount === 0) uploadCount = 1
    return uploadCount
  }
  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "ant-picker":
          return "has-error-picker"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  const {
    data: response,
    isLoading: parentDataLoading,
    isFetching: parentDataFetching,
  } = useQuery<any, Error>(
    ["program", id],
    () => id && getProgramRecord(Number(id)),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: isEdit === undefined ? false : true,
      select: ({ data }) => {
        const fileredData = data
        return {
          article_title: fileredData?.article_title,
          date: fileredData?.date,
          facility_id: fileredData?.facility_id,
          content1: fileredData?.article_content_1,
          content2: fileredData?.article_content_2,
          content3: fileredData?.article_content_3,
          content4: fileredData?.article_content_4,
          content5: fileredData?.article_content_5,
          content6: fileredData?.article_content_6,
          content7: fileredData?.article_content_7,
          content8: fileredData?.article_content_8,
          content9: fileredData?.article_content_9,
          content10: fileredData?.article_content_10,
          image1: fileredData?.article_content_img_1,
          image2: fileredData?.article_content_img_2,
          image3: fileredData?.article_content_img_3,
          image4: fileredData?.article_content_img_4,
          image5: fileredData?.article_content_img_5,
          image6: fileredData?.article_content_img_6,
          image7: fileredData?.article_content_img_7,
          image8: fileredData?.article_content_img_8,
          image9: fileredData?.article_content_img_9,
          image10: fileredData?.article_content_img_10,
          image1_filename: fileredData?.image1_filename,
          image2_filename: fileredData?.image2_filename,
          image3_filename: fileredData?.image3_filename,
          image4_filename: fileredData?.image4_filename,
          image5_filename: fileredData?.image5_filename,
          image6_filename: fileredData?.image6_filename,
          image7_filename: fileredData?.image7_filename,
          image8_filename: fileredData?.image8_filename,
          image9_filename: fileredData?.image9_filename,
          image10_filename: fileredData?.image10_filename,
          thumbnail1_is_image: fileredData?.article_content_img_1?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail2_is_image: fileredData?.article_content_img_2?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail3_is_image: fileredData?.article_content_img_3?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail4_is_image: fileredData?.article_content_img_4?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail5_is_image: fileredData?.article_content_img_5?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail6_is_image: fileredData?.article_content_img_6?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail7_is_image: fileredData?.article_content_img_7?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail8_is_image: fileredData?.article_content_img_8?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail9_is_image: fileredData?.article_content_img_9?.startsWith(
            "data:image"
          )
            ? true
            : false,
          thumbnail10_is_image: fileredData?.article_content_img_10?.startsWith(
            "data:image"
          )
            ? true
            : false,
          release_flag: String(+fileredData?.release_flag) || "0",
        }
      },
      onSuccess: (data) => {
        const count = getUploadCountInEdit(data)
        setUploadCount(count)
      },
    }
  )

  const validationSchema = yup.object().shape({
    date: yup.string().required(t("Required")),
    article_title: yup
      .string()
      .required(t("Required"))
      .max(200, t("(within 200 char)")),
    content1: yup.string().required(t("Required")),
    content2:
      uploadCount >= 2 ? yup.string().required(t("Required")) : yup.string(),
    content3:
      uploadCount >= 3 ? yup.string().required(t("Required")) : yup.string(),
    content4:
      uploadCount >= 4 ? yup.string().required(t("Required")) : yup.string(),
    content5:
      uploadCount >= 5 ? yup.string().required(t("Required")) : yup.string(),
    content6:
      uploadCount >= 6 ? yup.string().required(t("Required")) : yup.string(),
    content7:
      uploadCount >= 7 ? yup.string().required(t("Required")) : yup.string(),
    content8:
      uploadCount >= 8 ? yup.string().required(t("Required")) : yup.string(),
    content9:
      uploadCount >= 9 ? yup.string().required(t("Required")) : yup.string(),
    content10:
      uploadCount >= 10 ? yup.string().required(t("Required")) : yup.string(),
    article_content_img_1: yup.string(),
  })

  const [articleBlock, setArticleBlock] = useState({
    article2: {
      id: "1",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article3: {
      id: "2",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article4: {
      id: "3",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article5: {
      id: "4",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article6: {
      id: "5",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article7: {
      id: "6",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article8: {
      id: "7",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article9: {
      id: "8",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
    article10: {
      id: "9",
      image: "",
      content: "",
      isImage: false,
      active: false,
    },
  })

  const handleThumbnailInfo = (thumbnailKey: string, isImage: boolean) => {
    switch (thumbnailKey) {
      case "image1":
        formik.setFieldValue("thumbnail1_is_image", isImage)
        break
      case "image2":
        formik.setFieldValue("thumbnail2_is_image", isImage)
        break
      case "image3":
        formik.setFieldValue("thumbnail3_is_image", isImage)
        break
      case "image4":
        formik.setFieldValue("thumbnail4_is_image", isImage)
        break
      case "image5":
        formik.setFieldValue("thumbnail5_is_image", isImage)
        break
      case "image6":
        formik.setFieldValue("thumbnail6_is_image", isImage)
        break
      case "image7":
        formik.setFieldValue("thumbnail7_is_image", isImage)
        break
      case "image8":
        formik.setFieldValue("thumbnail8_is_image", isImage)
        break
      case "image9":
        formik.setFieldValue("thumbnail9_is_image", isImage)
        break
      case "image10":
        formik.setFieldValue("thumbnail10_is_image", isImage)
        break
    }
  }

  const handleUploadFieldAdd = () => {
    if (uploadCount === 1) {
      setUploadCount(2)
      setArticleBlock({
        ...articleBlock,
        article2: { ...articleBlock.article2, active: true },
      })
    } else if (uploadCount === 2) {
      setUploadCount(3)
      setArticleBlock({
        ...articleBlock,
        article3: { ...articleBlock.article3, active: true },
      })
    } else if (uploadCount === 3) {
      setUploadCount(4)
      setArticleBlock({
        ...articleBlock,
        article4: { ...articleBlock.article4, active: true },
      })
    } else if (uploadCount === 4) {
      setUploadCount(5)
      setArticleBlock({
        ...articleBlock,
        article5: { ...articleBlock.article5, active: true },
      })
    } else if (uploadCount === 5) {
      setUploadCount(6)
      setArticleBlock({
        ...articleBlock,
        article6: { ...articleBlock.article6, active: true },
      })
    } else if (uploadCount === 6) {
      setUploadCount(7)
      setArticleBlock({
        ...articleBlock,
        article7: { ...articleBlock.article7, active: true },
      })
    } else if (uploadCount === 7) {
      setUploadCount(8)
      setArticleBlock({
        ...articleBlock,
        article8: { ...articleBlock.article8, active: true },
      })
    } else if (uploadCount === 8) {
      setUploadCount(9)
      setArticleBlock({
        ...articleBlock,
        article9: { ...articleBlock.article9, active: true },
      })
    } else if (uploadCount === 9) {
      setUploadCount(10)
      setArticleBlock({
        ...articleBlock,
        article10: { ...articleBlock.article10, active: true },
      })
    }
  }

  const handleUploadFieldDelete = (id) => {
    if (uploadCount === 1) {
      return null
    } else if (id === 2) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article2: { ...articleBlock.article2, active: false },
      })
      formik.setFieldValue("image2", "")
      formik?.setFieldValue("content2", "")
    } else if (id === 3) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article3: { ...articleBlock.article3, active: false },
      })
      formik.setFieldValue("image3", "")
      formik?.setFieldValue("content3", "")
    } else if (id === 4) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article4: { ...articleBlock.article4, active: false },
      })
      formik.setFieldValue("image4", "")
      formik?.setFieldValue("content4", "")
    } else if (id === 5) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article5: { ...articleBlock.article5, active: false },
      })
      formik.setFieldValue("image5", "")
      formik?.setFieldValue("content5", "")
    } else if (id === 6) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article6: { ...articleBlock.article6, active: false },
      })
      formik.setFieldValue("image6", "")
      formik?.setFieldValue("content6", "")
    } else if (id === 7) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article7: { ...articleBlock.article7, active: false },
      })
      formik.setFieldValue("image7", "")
      formik?.setFieldValue("content7", "")
    } else if (id === 8) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article8: { ...articleBlock.article8, active: false },
      })
      formik.setFieldValue("image8", "")
      formik?.setFieldValue("content8", "")
    } else if (id === 9) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article9: { ...articleBlock.article9, active: false },
      })
      formik.setFieldValue("image9", "")
      formik?.setFieldValue("content9", "")
    } else if (id === 10) {
      setUploadCount(id - 1)
      setArticleBlock({
        ...articleBlock,
        article10: { ...articleBlock.article10, active: false },
      })
      formik.setFieldValue("image10", "")
      formik?.setFieldValue("content10", "")
    }
  }
  const handleThumbnailChange = (
    info: UploadChangeParam<UploadFile>,
    thumbnailKey: string
  ) => {
    if (info?.file?.status === "done") {
      getBase64(info?.file?.originFileObj as RcFile, (url) => {
        formik.setFieldValue(thumbnailKey, url)
        formik.setFieldValue(`${thumbnailKey}_filename`, info?.file?.name)
      })

      const newThumbInfo = {
        isImage: info?.file?.type.startsWith("image/"),
      }
      handleThumbnailInfo(thumbnailKey, newThumbInfo.isImage)
    }
  }

  const defaultDate = moment().format("YYYY-MM-DDT05:45:00.000[Z]")

  const initialValues = response || {
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    image6: "",
    image7: "",
    image8: "",
    image9: "",
    image10: "",
    content1: "",
    content2: "",
    content3: "",
    content4: "",
    content5: "",
    content6: "",
    content7: "",
    content8: "",
    content9: "",
    content10: "",
    name: "",
    furigana: "",
    tel_no: "",
    email: "",
    date: defaultDate,
    recipient_certificate: "",
    diagnosis: "",
    source: "",
    desire_visit: "",
    address: "",
    remarks: "",
    release_flag: "1",
    facility_id: facilities && facilities[0] ? facilities[0]?.id : "",
    article_title: "",
    thumbnail1_is_image: false,
    thumbnail2_is_image: false,
    thumbnail3_is_image: false,
    thumbnail4_is_image: false,
    thumbnail5_is_image: false,
    thumbnail6_is_image: false,
    thumbnail7_is_image: false,
    thumbnail8_is_image: false,
    thumbnail9_is_image: false,
    thumbnail10_is_image: false,
    image1_filename: "",
    image2_filename: "",
    image3_filename: "",
    image4_filename: "",
    image5_filename: "",
    image6_filename: "",
    image7_filename: "",
    image8_filename: "",
    image9_filename: "",
    image10_filename: "",
  }

  const { isLoading: isSaving, mutate: mutateCreateProgramRecord } =
    useMutation(isEdit ? updateProgramRecord : createProgramRecord, {
      onSuccess: () => {
        notification.success({
          message: `${dynamicLangString([
            "Program record",
            isEdit ? "Updated Successfully" : "Created Successfully",
          ])}`,
        })
        router.push("/program-record")
      },
      onError: (error?: any) => {
        const msg =
          error?.status == 403
            ? "User doesn't have permission to edit this page"
            : error?.status == 406
            ? "Program record for that day has already been registered"
            : error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        facility_id: Number(values?.facility_id) || undefined,
        date: moment(values?.date).isValid()
          ? moment(values?.date).format("YYYY-MM-DD")
          : values?.date,
        article_title: values?.article_title,
        article_content_1: values?.content1,
        article_content_img_1: values?.image1,
        article_content_2: values?.content2,
        article_content_img_2: values?.image2,
        article_content_3: values?.content3,
        article_content_img_3: values?.image3,
        article_content_4: values?.content4,
        article_content_img_4: values?.image4,
        article_content_5: values?.content5,
        article_content_img_5: values?.image5,
        article_content_6: values?.content6,
        article_content_img_6: values?.image6,
        article_content_7: values?.content7,
        article_content_img_7: values?.image7,
        article_content_8: values?.content8,
        article_content_img_8: values?.image8,
        article_content_9: values?.content9,
        article_content_img_9: values?.image9,
        article_content_10: values?.content10,
        article_content_img_10: values?.image10,
        release_flag: values?.release_flag === "1" || false,
        image1_filename: values?.image1_filename,
        image2_filename: values?.image2_filename,
        image3_filename: values?.image3_filename,
        image4_filename: values?.image4_filename,
        image5_filename: values?.image5_filename,
        image6_filename: values?.image6_filename,
        image7_filename: values?.image7_filename,
        image8_filename: values?.image8_filename,
        image9_filename: values?.image9_filename,
        image10_filename: values?.image10_filename,
        id: id,
      }
      mutateCreateProgramRecord(data)
    },
  })

  useEffect(() => {
    if (isEdit == true) {
      if (uploadCount === 1) {
        return null
      } else if (uploadCount === 2) {
        setUploadCount(2)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
        })
      } else if (uploadCount === 3) {
        setUploadCount(3)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
        })
      } else if (uploadCount === 4) {
        setUploadCount(4)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
        })
      } else if (uploadCount === 5) {
        setUploadCount(5)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
        })
      } else if (uploadCount === 6) {
        setUploadCount(6)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
          article6: { ...articleBlock.article6, active: true },
        })
      } else if (uploadCount === 7) {
        setUploadCount(7)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
          article6: { ...articleBlock.article6, active: true },
          article7: { ...articleBlock.article7, active: true },
        })
      } else if (uploadCount === 8) {
        setUploadCount(8)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
          article6: { ...articleBlock.article6, active: true },
          article7: { ...articleBlock.article7, active: true },
          article8: { ...articleBlock.article8, active: true },
        })
      } else if (uploadCount === 9) {
        setUploadCount(9)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
          article6: { ...articleBlock.article6, active: true },
          article7: { ...articleBlock.article7, active: true },
          article8: { ...articleBlock.article8, active: true },
          article9: { ...articleBlock.article9, active: true },
        })
      } else if (uploadCount === 10) {
        setUploadCount(10)
        setArticleBlock({
          ...articleBlock,
          article2: { ...articleBlock.article2, active: true },
          article3: { ...articleBlock.article3, active: true },
          article4: { ...articleBlock.article4, active: true },
          article5: { ...articleBlock.article5, active: true },
          article6: { ...articleBlock.article6, active: true },
          article7: { ...articleBlock.article7, active: true },
          article8: { ...articleBlock.article8, active: true },
          article9: { ...articleBlock.article9, active: true },
          article10: { ...articleBlock.article10, active: true },
        })
      }
    }
  }, [response])

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  if (parentDataLoading || parentDataFetching) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  const isImg = (file) => {
    return file && file["type"] === "image"
  }
  return (
    <>
      <HeaderContentWrapper>
        <span>
          {t(
            "※Machine-dependent characters and half-width katakana cannot be used."
          )}
        </span>
      </HeaderContentWrapper>
      <ButtonWrapper top>
        <OwnerButton
          typeOf={"secondary"}
          text={t("Cancel")}
          className={"cancel-btn"}
          onClick={() => router.back()}
        />
        <OwnerButton
          typeOf={"primary"}
          text={t("Save")}
          className={"save-btn"}
          type={"submit"}
          onClick={() => formik.submitForm()}
          isLoading={isSaving}
        />
      </ButtonWrapper>
      <Wrapper>
        <FormTitle>{t("Article information")}</FormTitle>
        <Divisor />
        <StyledFormWrapper
          className={"styled-article-form"}
          onSubmit={formik.handleSubmit}
        >
          <Grid className={"grid-header"} background>
            <div className={"label_tag"}>
              <GridFlexHeader>
                <div>{t("Date")}</div>
                <Required />
              </GridFlexHeader>
            </div>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <DatePicker
              name={"date"}
              date={
                moment(formik?.values?.date).isValid()
                  ? moment(formik?.values?.date, "YYYY-MM-DD")
                  : null
              }
              onDateChange={(e) => formik.setFieldValue("date", e)}
              placeholder={null}
              className={getErrorClass("date", "ant-picker")}
              style={{ height: "40px" }}
              format={"YYYY年MM月DD日"}
            />
            <ErrorCounterWrapper>
              {formik.errors.date && formik.touched.date && (
                <ErrorStyled>
                  {formik.errors.date ? t("Required") : ""}
                </ErrorStyled>
              )}
            </ErrorCounterWrapper>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header label-container"} background>
            <div className={"label_tag"}>{t("Article writing facility")}</div>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <SelectInput
              name={"furigana"}
              options={FACILITY_OPTIONS}
              placeholder={t("All")}
              value={formik?.values?.facility_id || null}
              onChange={(val) => {
                formik.setFieldValue("facility_id", val)
              }}
              width={150}
              height={40}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header label-container"} background>
            <GridFlexHeader>
              <div className={"label_tag"}>{t("Title")}</div>
              <Required />
            </GridFlexHeader>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <TextField
              name={"article_title"}
              maxLength={200}
              value={formik.values?.article_title}
              onChange={formik.handleChange}
              error={
                formik.touched.article_title && formik.errors.article_title
              }
              className={getErrorClass("article_title", "input")}
            />
          </Grid>
        </StyledFormWrapper>

        <FormTitle>
          {t("Article block")}
          {"1"}
        </FormTitle>
        <Divisor />
        <StyledFormWrapper top className={"add-article-block-form "}>
          <Grid className={"grid-header"} background>
            <div className={"label_tag"}>
              <GridFlexHeader>
                <div>{t("Activity contents")}</div>
                <Required />
              </GridFlexHeader>
            </div>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <EditorComponent
              handleData={handleData}
              data={formik?.values?.content1}
              defaultContent={response?.content1}
            />
            {/* content1 */}
            <ErrorCounterWrapper>
              {formik.errors.content1 && formik.touched.content1 && (
                <ErrorStyled>
                  {formik.errors.content1 ? t("Required") : ""}
                </ErrorStyled>
              )}
            </ErrorCounterWrapper>
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header label-container"} background>
            <div className={"label_tag"}>{t("Image/PDF file")}</div>
          </Grid>

          <Grid className={"flex-full grid-row-last last-padding-grid"}>
            <div className={"file-upload"} style={{ marginTop: "17px" }}>
              {formik.values?.image1 !== "" && (
                <>
                  {formik.values?.thumbnail1_is_image ||
                  isImg(formik?.values?.image1) ? (
                    <img
                      src={formik.values?.image1}
                      alt={"thumbnail1"}
                      className={"thumbnail"}
                    />
                  ) : (
                    <img
                      src={"/assets/icons/daily-report.svg"}
                      alt={"thumbnail1"}
                      width={"40px"}
                    />
                  )}
                </>
              )}

              <DailyBusinessReportUpload
                currentFile={formik.values.image1}
                onChange={(value) => {
                  handleThumbnailChange(value, "image1")
                }}
                handleDelete={() => {
                  formik.setFieldValue("image1", "")
                }}
                fileName={formik.values["image1_filename"]}
              />

              {formik.values.image1 && (
                <OwnerButton
                  typeOf={"draft"}
                  text={t("Download")}
                  className={"download-btn"}
                  type={"button"}
                  shape={"circle"}
                  onClick={() =>
                    handleDownload(
                      formik.values["image1"],
                      formik.values["image1_filename"]
                    )
                  }
                />
              )}
            </div>
          </Grid>
        </StyledFormWrapper>
        {articleBlock.article2.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"2"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData2}
                  data={formik?.values?.content2}
                  defaultContent={response?.content2}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content2 && formik.touched.content2 && (
                    <ErrorStyled>
                      {formik.errors.content2 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image2 !== "" && (
                    <>
                      {formik.values?.thumbnail2_is_image ? (
                        <img
                          src={formik.values?.image2}
                          alt={"thumbnail2"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail2"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image2}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image2")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image2", "")
                    }}
                    fileName={formik.values["image2_filename"]}
                  />

                  {formik.values.image2 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image2"],
                          formik.values["image2_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(2)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article3.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"3"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData3}
                  data={formik?.values?.content3}
                  defaultContent={response?.content3}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content3 && formik.touched.content3 && (
                    <ErrorStyled>
                      {formik.errors.content3 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image3 !== "" && (
                    <>
                      {formik.values?.thumbnail3_is_image ? (
                        <img
                          src={formik.values?.image3}
                          alt={"thumbnail3"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail3"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image3}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image3")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image3", "")
                    }}
                    fileName={formik.values["image3_filename"]}
                  />

                  {formik.values.image3 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image3"],
                          formik.values["image3_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(3)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article4.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"4"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData4}
                  data={formik?.values?.content4}
                  defaultContent={response?.content4}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content4 && formik.touched.content4 && (
                    <ErrorStyled>
                      {formik.errors.content4 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image4 !== "" && (
                    <>
                      {formik.values?.thumbnail4_is_image ? (
                        <img
                          src={formik.values?.image4}
                          alt={"thumbnail4"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail4"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image4}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image4")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image4", "")
                    }}
                    fileName={formik.values["image4_filename"]}
                  />

                  {formik.values.image4 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image4"],
                          formik.values["image4_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(4)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article5.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"5"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData5}
                  data={formik?.values?.content5}
                  defaultContent={response?.content5}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content5 && formik.touched.content5 && (
                    <ErrorStyled>
                      {formik.errors.content5 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image5 !== "" && (
                    <>
                      {formik.values?.thumbnail5_is_image ? (
                        <img
                          src={formik.values?.image5}
                          alt={"thumbnail5"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail5"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image5}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image5")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image5", "")
                    }}
                    fileName={formik.values["image5_filename"]}
                  />

                  {formik.values.image5 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image5"],
                          formik.values["image5_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(5)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article6.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"6"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData6}
                  data={formik?.values?.content6}
                  defaultContent={response?.content6}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content6 && formik.touched.content6 && (
                    <ErrorStyled>
                      {formik.errors.content6 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image6 !== "" && (
                    <>
                      {formik.values?.thumbnail6_is_image ? (
                        <img
                          src={formik.values?.image6}
                          alt={"thumbnail6"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail6"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image6}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image6")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image6", "")
                    }}
                    fileName={formik.values["image6_filename"]}
                  />

                  {formik.values.image6 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image6"],
                          formik.values["image6_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(6)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article7.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"7"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData7}
                  data={formik?.values?.content7}
                  defaultContent={response?.content7}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content7 && formik.touched.content7 && (
                    <ErrorStyled>
                      {formik.errors.content7 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image7 !== "" && (
                    <>
                      {formik.values?.thumbnail7_is_image ? (
                        <img
                          src={formik.values?.image7}
                          alt={"thumbnail7"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail7"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image7}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image7")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image7", "")
                    }}
                    fileName={formik.values["image7_filename"]}
                  />

                  {formik.values.image7 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image7"],
                          formik.values["image7_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(7)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article8.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"8"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData8}
                  data={formik?.values?.content8}
                  defaultContent={response?.content8}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content8 && formik.touched.content8 && (
                    <ErrorStyled>
                      {formik.errors.content8 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image8 !== "" && (
                    <>
                      {formik.values?.thumbnail8_is_image ? (
                        <img
                          src={formik.values?.image8}
                          alt={"thumbnail8"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail8"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image8}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image8")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image8", "")
                    }}
                    fileName={formik.values["image8_filename"]}
                  />

                  {formik.values.image8 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image8"],
                          formik.values["image8_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(8)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article9.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"9"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form"}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData9}
                  data={formik?.values?.content9}
                  defaultContent={response?.content9}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content9 && formik.touched.content9 && (
                    <ErrorStyled>
                      {formik.errors.content9 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image9 !== "" && (
                    <>
                      {formik.values?.thumbnail9_is_image ? (
                        <img
                          src={formik.values?.image9}
                          alt={"thumbnail9"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail9"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image9}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image9")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image9", "")
                    }}
                    fileName={formik.values["image9_filename"]}
                  />

                  {formik.values.image9 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image9"],
                          formik.values["image9_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(9)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {articleBlock.article10.active && (
          <>
            <FormTitle>
              {t("Article block")}
              {"10"}
            </FormTitle>
            <Divisor />
            <StyledFormWrapper top className={"styled-form "}>
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>
                  <GridFlexHeader>
                    <div>{t("Activity contents")}</div>
                    <Required />
                  </GridFlexHeader>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <EditorComponent
                  handleData={handleData10}
                  data={formik?.values?.content10}
                  defaultContent={response?.content10}
                />
                <ErrorCounterWrapper>
                  {formik.errors.content10 && formik.touched.content10 && (
                    <ErrorStyled>
                      {formik.errors.content10 ? t("Required") : ""}
                    </ErrorStyled>
                  )}
                </ErrorCounterWrapper>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label_tag"}>{t("Image/PDF file")}</div>
              </Grid>

              <Grid className={"flex-full grid-row-last"}>
                <div className={"file-upload"} style={{ marginTop: "17px" }}>
                  {formik.values?.image10 !== "" && (
                    <>
                      {formik.values?.thumbnail10_is_image ? (
                        <img
                          src={formik.values?.image10}
                          alt={"thumbnail10"}
                          className={"thumbnail"}
                        />
                      ) : (
                        <img
                          src={"/assets/icons/daily-report.svg"}
                          alt={"thumbnail10"}
                          width={"40px"}
                        />
                      )}
                    </>
                  )}

                  <DailyBusinessReportUpload
                    currentFile={formik.values.image10}
                    onChange={(value) => {
                      handleThumbnailChange(value, "image10")
                    }}
                    handleDelete={() => {
                      formik.setFieldValue("image10", "")
                    }}
                    fileName={formik.values["image10_filename"]}
                  />

                  {formik.values.image10 && (
                    <OwnerButton
                      typeOf={"draft"}
                      text={t("Download")}
                      className={"download-btn"}
                      type={"button"}
                      shape={"circle"}
                      onClick={() =>
                        handleDownload(
                          formik.values["image10"],
                          formik.values["image10_filename"]
                        )
                      }
                    />
                  )}
                </div>
              </Grid>
            </StyledFormWrapper>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Remove")}
              onClick={() => {
                handleUploadFieldDelete(10)
              }}
              className={"delete-article-btn"}
            />
          </>
        )}
        {uploadCount < 10 && (
          <ArticleBlockButtonWrapper>
            <OwnerButton
              icon={"add"}
              typeOf={"check-all"}
              text={t("Add article block")}
              onClick={() => {
                handleUploadFieldAdd()
              }}
              className={"add-article-btn"}
            />
          </ArticleBlockButtonWrapper>
        )}
        <FormTitle>{t("Setting information")}</FormTitle>
        <Divisor />
        <StyledFormWrapper className={"styled-setting-form"}>
          <Grid className={"grid-header"} background>
            <div className={"label_tag"}>{t("Publish")}</div>
          </Grid>

          <Grid className={"flex-full grid-row-last"}>
            <SelectInput
              name={"furigana"}
              options={PUBLISH_OPTIONS}
              width={100}
              height={40}
              popupClassName={"select_dropdown_width"}
              value={formik?.values?.release_flag}
              onChange={(val) => {
                formik.setFieldValue("release_flag", val)
              }}
            />
          </Grid>
        </StyledFormWrapper>
        <ButtonWrapper style={{ justifyContent: "space-between" }}>
          <ButtonWrapper>
            <OwnerButton
              typeOf={"secondary"}
              className={"cancel-btn"}
              text={t("Cancel")}
              onClick={() => router.push("/program-record")}
            />

            <OwnerButton
              typeOf={"primary"}
              text={t("Save")}
              className={"save-btn"}
              type={"submit"}
              onClick={() => formik.submitForm()}
              isLoading={isSaving}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <div>
              {isEdit && showDeleteButton && (
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  okText={"OK"}
                  onConfirm={handleDelete}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                >
                  <OwnerButton
                    text={t("Delete ")}
                    typeOf={"primary"}
                    className={"delete-button"}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              )}
            </div>
          </ButtonWrapper>
        </ButtonWrapper>

        <HeaderContentWrapper top>
          <span>
            {t(
              "※Machine-dependent characters and half-width katakana cannot be used."
            )}
          </span>
        </HeaderContentWrapper>
      </Wrapper>
    </>
  )
}
export { ProgramRecordListForm }
