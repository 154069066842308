import { Table } from "@project/shared"
import { t } from "i18next"
import { CityNumber } from "./common/CityNumber"
import { SiblingChild } from "../../../../../types"
import { maskDisabilityWord } from "../../../common/utils"

const CIRCLE_NUMBERS = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
}

enum FirstValue {
  CITY_NUMBER_INDEX,
  PARENT_NAME_INDEX,
  RECEIVING_CERTIFICATE_NUMBER_INDEX,
  CHILD_START_INDEX,
}

type Props = {
  municipalityNumber: string
  parentName: string
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function OtaChildDetail({
  municipalityNumber,
  parentName,
  siblingChildren,
  printConfig,
}: Props) {
  const CITY_NUMBER_INDEX = 0
  const RECEIVING_CERTIFICATE_NUMBER_INDEX = 1
  const PARENT_NAME_INDEX =
    RECEIVING_CERTIFICATE_NUMBER_INDEX + siblingChildren.length
  const CHILD_NAMES_INDEX = PARENT_NAME_INDEX + 1

  return (
    <Table
      columns={[
        {
          dataIndex: "label",
          render: (v) => <p>{v}</p>,
          onCell: (_, index: number) => {
            if (
              index === RECEIVING_CERTIFICATE_NUMBER_INDEX ||
              index === CHILD_NAMES_INDEX
            ) {
              return { rowSpan: siblingChildren.length }
            }

            if (index === CITY_NUMBER_INDEX || index === PARENT_NAME_INDEX) {
              return { colSpan: 1 }
            }

            return { rowSpan: 0, colSpan: 0 }
          },
        },
        {
          dataIndex: "number",
          width: "24px",
          render: (v) => <p>{v}</p>,
          onCell: (_, index: number) => {
            if (index === CITY_NUMBER_INDEX || index === PARENT_NAME_INDEX) {
              return { colSpan: 0 }
            }
            return {}
          },
        },
        {
          dataIndex: "value",
          width: "160px",
          render: (v, _, index) => {
            if (index === FirstValue.CITY_NUMBER_INDEX) {
              return <CityNumber number={v} />
            }
            return <p>{v}</p>
          },
          onCell: (_, index) => {
            if (index === CITY_NUMBER_INDEX || index === PARENT_NAME_INDEX) {
              return { colSpan: 2 }
            }
            return {}
          },
        },
      ]}
      dataSource={[
        {
          label: t("Provision city No."),
          number: "",
          value: municipalityNumber,
        },
        ...siblingChildren.map((child) => ({
          label: "受給者証番号",
          number: CIRCLE_NUMBERS[child.child_no],
          value: child.receiving_certificate_number,
        })),
        {
          label: maskDisabilityWord("支給決定障害者等氏名", printConfig),
          number: "",
          value: parentName,
        },
        ...siblingChildren.map((child) => ({
          label: maskDisabilityWord("支給決定に係る障害児氏名", printConfig),
          number: CIRCLE_NUMBERS[child.child_no],
          value: child.receiving_certificate_number,
        })),
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
