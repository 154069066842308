// packages
import { Skeleton } from "antd"

// components
import { RecordsListJsxItem } from "../components"

// types
import { FormFieldsProps } from "../../../molecules/FormFields/types"
import { OperationOptionsDataProps, OperationOptionsProps } from "../types"

export const OperationOptions = ({
  showParentSign,
  isDigi_parentSignDisabled,
  formik,
}: OperationOptionsProps): OperationOptionsDataProps[] => [
  {
    label: "Parental Consent Indication Settings",
    name: "parentalConsent",
    options: [
      {
        value: "1",
        label: `Display "I agree to receive XX support"`,
      },
      {
        value: "2",
        label: "consent date",
      },
      {
        value: "3",
        label: "Parental Consent Signature column",
      },
    ],
  },
  {
    label: "Parental verification display settings",
    name: "parentalVerification",
    options: [
      {
        value: "1",
        label: `Display "I have confirmed the above."`,
      },
      {
        value: "2",
        label: "Confirm day",
      },
      {
        value: "3",
        label: "Parental Confirm Signature column",
        onChange: (e) => {
          const digiSignVals = formik.values["digitalSign"]
          const indexOfDigiParentSign = digiSignVals.indexOf("2")

          if (!e.target.checked) {
            formik.setFieldValue(
              "parentalVerification",
              formik.values["parentalVerification"].filter((val) => val !== "3")
            )
          }
          if (e.target.checked) {
            if (formik.values["parentalVerification"].includes("3")) return
            formik.setFieldValue("parentalVerification", [
              ...formik.values["parentalVerification"],
              "3",
            ])
          }
          formik.setFieldValue("parentSign", [])

          if (indexOfDigiParentSign !== -1) {
            digiSignVals.splice(indexOfDigiParentSign, 1)
            formik.setFieldValue("digitalSign", digiSignVals)
          }
        },
      },
    ],
  },
  {
    label: "Digital sign settings",
    name: "digitalSign",
    options: [
      {
        value: "1",
        label: "Date (agreement date, confirmation date) signature",
      },
      {
        value: "2",
        label: "Parents sign",
        isDisabled: isDigi_parentSignDisabled,
      },
      {
        value: "3",
        label: "Related agency signature",
      },
      {
        value: "4",
        label: "Consultation support office sign",
      },
      {
        value: "5",
        label: "interview staff sign",
      },
    ],
  },
  {
    label: "Stamp settings",
    name: "stamp",
    options: [
      {
        value: "1",
        label: "Parents stamp",
      },
      {
        value: "2",
        label: "Related agency stamp",
      },
      {
        value: "3",
        label: "Consultation support office stamp",
      },
      {
        value: "4",
        label: "Interview staff stamp",
      },
    ],
  },
  {
    label: "Parents sign column",
    name: "parentSign",
    hide: !showParentSign,
    options: [
      {
        value: "1",
        label: `Change "Name of guardian" To "Name of guardian and family representative"`,
      },
    ],
  },
]

export const RecordListingSchema = (
  {
    childName,
    parentsName,
    beneficiaryCertiNum,
    correspondingPerson,
    implementedDateTime,
    consultationSupportOffice,
    AdditionMinuteFreeItems,
  },
  showConsultationSupportOfficeSign: boolean,
  showCorrespondingPersonSign: boolean,
  showConsultationSupportOfficeMark: boolean,
  showCorrespondingPersonMark: boolean
): FormFieldsProps => {
  const dynamicRecords =
    AdditionMinuteFreeItems?.length > 0
      ? AdditionMinuteFreeItems.sort(
          (a, b) =>
            a.addition_and_minutes_free_item_id -
            b.addition_and_minutes_free_item_id
        ).map((item, index) => {
          return {
            fieldLabel: item.FreeTitle,
            isRowFlexed: true,
            fields: [
              {
                fieldType: { type: "text", text: item.FreeRemarks },
              },
            ],
            index: index + 1,
          }
        })
      : []

  return [
    {
      fieldLabel: "Child name",
      isRowFlexed: true,
      fields: [
        {
          fieldType: { type: "text", text: childName },
        },
      ],
    },
    {
      fieldLabel: "Beneficiary certificate number",
      isRowFlexed: true,
      fields: [
        {
          fieldType: { type: "text", text: beneficiaryCertiNum },
        },
      ],
    },
    {
      fieldLabel: "Parents name",
      isRowFlexed: true,
      fields: [
        {
          fieldType: { type: "text", text: parentsName },
        },
      ],
    },
    {
      fieldLabel: "Consultation support office",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: (
              <RecordsListJsxItem
                text={consultationSupportOffice}
                showSignField={showConsultationSupportOfficeSign}
                showMark={showConsultationSupportOfficeMark}
              />
            ),
          },
        },
      ],
    },
    {
      fieldLabel: "Corresponding person",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: (
              <RecordsListJsxItem
                text={correspondingPerson}
                showSignField={showCorrespondingPersonSign}
                showMark={showCorrespondingPersonMark}
              />
            ),
          },
        },
      ],
    },
    {
      fieldLabel: "Implemented date & time",
      isRowFlexed: true,
      fields: [
        {
          fieldType: { type: "text", text: implementedDateTime },
        },
      ],
    },
    ...dynamicRecords,
  ]
}

export const LoadingListingSchema = (): FormFieldsProps => {
  return [
    {
      fieldLabel: "Loading...",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: <Skeleton.Input />,
          },
        },
      ],
    },
    {
      fieldLabel: "Loading...",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: <Skeleton.Input />,
          },
        },
      ],
    },
    {
      fieldLabel: "Loading...",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: <Skeleton.Input />,
          },
        },
      ],
    },
    {
      fieldLabel: "Loading...",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "JSX-element",
            JSXElementData: <Skeleton.Input />,
          },
        },
      ],
    },
  ]
}

export const HeadMoreInfo = ({ facilityName, serviceType, createdAt }) => [
  {
    label: "Facility Name",
    value: facilityName,
    key: "facilityName",
  },
  {
    label: "Use service",
    value: serviceType,
    key: "serviceType",
  },
  {
    label: "Created date",
    value: createdAt,
    key: "createdAt",
  },
]
