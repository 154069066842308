import { Modal } from "antd"
import { t } from "i18next"
import { useQuery } from "react-query"
import { getAllHandicapServices } from "../../../services/handicapServices"
import { OwnerButton } from "../../atoms"
import { OwnerTable } from "../../molecules"

interface IProps {
  open: boolean
  onCancel: () => void
}
const ServiceCodesModal: React.FC<IProps> = ({ open, onCancel }) => {
  const {
    data: servicesData,
    isFetching,
    isLoading,
  } = useQuery("get-all-services", getAllHandicapServices, {
    retry: 1,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.data?.map((each) => {
        return {
          service_code: each?.decide_service_code,
          code_name: each?.decide_service_code_name,
        }
      })
    },
  })

  const columns = [
    {
      dataIndex: "code_name",
      title: t("Decide code name"),
    },
    {
      dataIndex: "service_code",
      title: t("Decide code"),
      width: 200,
    },
  ]

  return (
    <Modal
      open={open}
      centered
      title={t("Decide Service Code List")}
      onCancel={onCancel}
      width={900}
      footer={
        <OwnerButton text={t("Ok")} typeOf={"secondary"} onClick={onCancel} />
      }
    >
      <OwnerTable
        dataSource={servicesData}
        columns={columns}
        loading={isFetching || isLoading}
      />
    </Modal>
  )
}

export { ServiceCodesModal }
