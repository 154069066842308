import { PRINT_STYLE_VALUES } from "../../../constants"
import { Sheet } from "../../../types"
import { DefaultFlame } from "./flame/DefaultFlame"
import { HiroshimaFlame } from "./flame/HiroshimaFlame"
import { KobeFlame } from "./flame/KobeFlame"
import { KyotoFlame } from "./flame/KyotoFlame"
import { ShizuokaFlame } from "./flame/ShizuokaFlame"

type Props = {
  sheet: Sheet
  printStyle: string
  printConfig: string[]
}

/**
 * 利用者負担額上限管理結果票（通常バージョン）
 */
export function OneChildSheet({
  sheet,
  printConfig = [],
  printStyle = "",
}: Props) {
  const props = {
    receiptUpperLimitManagement: sheet.receiptUpperLimitManagement,
    printConfig,
  }

  switch (printStyle) {
    case PRINT_STYLE_VALUES.KOBE:
      return <KobeFlame {...props} />

    case PRINT_STYLE_VALUES.HIROSHIMA:
      return <HiroshimaFlame {...props} />

    case PRINT_STYLE_VALUES.KYOTO:
      return <KyotoFlame {...props} />

    case PRINT_STYLE_VALUES.SHIZUOKA:
      return <ShizuokaFlame {...props} />
  }

  return <DefaultFlame {...props} />
}
