import {
  BUTTON_DISPLAY_LIST,
  BUTTON_DISPLAY_LIST1,
  BUTTON_DISPLAY_LIST2,
  BUTTON_DISPLAY_LIST3,
  Checkbox,
  Checkboxes,
  Grid,
  ImageUpload,
  PageCard,
  RadioButtons,
  TextField,
  theme,
  USING_LIST,
} from "@project/shared"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button, OwnerButton } from "../../../components"
import { AuthContext } from "../../../utils"
import { MyPagePermission } from "../../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"

const Wrapper = styled.section`
  .ant-card {
    .ant-card-head {
      .ant-card-head-wrapper {
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5em;
          .ant-card-extra {
            align-items: flex-start;
          }
        }
      }
    }
  }
  .facilities_box {
    .grid-content {
      .inner-grid-content {
        label {
          span:last-child {
            word-break: break-all;
            text-indent: 0;
          }
        }
      }
    }
  }
`
const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
    cursor: pointer;
    word-break: break-all;
    text-indent: 0;
  }
  .text-field-headertext {
    margin-top: 20px;
  }
  .button-wrapper {
    display: flex;
    margin-top: 30px;
    button {
      :first-child {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      justify-content: center;
    }
  }
`

interface IProps {
  loading?: boolean
  pageSettings?: any
  handleSubmit: (value: any) => void
}

const PageSettings: React.FC<any> = ({
  loading,
  pageSettings,
  handleSubmit,
}: IProps) => {
  const { t } = useTranslation()
  const { facilities, permissions, isOwner } = useContext(AuthContext)

  const hasEditAccess = useMemo(() => {
    return (
      isOwner || hasPermissionForMenu(permissions, MyPagePermission, "write")
    )
  }, [permissions, isOwner])

  const router = useRouter()

  const facilityOptions = facilities?.map((x: any) => ({
    value: x.id.toString(),
    label: x.facility_name_short,
  }))
  const [displayButtonItems, setDisplayButtonItems] = useState([])
  const [displayButtonItems1, setDisplayButtonItems1] = useState([])
  const [displayButtonItems2, setDisplayButtonItems2] = useState([])
  const [displayButtonItems3, setDisplayButtonItems3] = useState([])
  const [formLoading, setFormLoading] = useState(false)

  const DISPLAY_OPTION_LIST = [
    {
      label: t("Don't display"),
      value: "dont_display_text",
    },
    { label: t("Display text"), value: "display_text" },
    { label: t("Company information"), value: "company_information" },
  ]

  const settingsParams = {
    service_provision_record: 0,
    service_provision_care_record_digital_sign: 0,
    activity_record: 0,
    service_provision_record_sheet: 0,
    contact_facility: 0,
    mail_setting: 0,
    password_change: 0,
    header_type: null,
    header_item: "",
    header_text: "",
    header_facility_id: 0,
    enable_accept_only_facility_schedule_date: 0,
    footer_company_info_flg: null,
    footer_facility_info_flg: 0,
    logo_image: "",
    service_provision_care_record_comment: [],
    footer_information: [],
  }

  const initialValues = pageSettings || settingsParams

  useEffect(() => {
    const fillButtons = []
    const fillButtons1 = []
    const fillButtons2 = []
    const fillButtons3 = []

    BUTTON_DISPLAY_LIST.map((x) => x.value).forEach((key) => {
      if (initialValues[key] > 0) {
        fillButtons.push(key)
      }
    })

    BUTTON_DISPLAY_LIST1.map((x) => x.value).forEach((key) => {
      if (
        initialValues?.individual_support_plan_setting &&
        initialValues?.individual_support_plan_setting[key] > 0
      ) {
        fillButtons1.push(key)
      }
    })
    BUTTON_DISPLAY_LIST2.map((x) => x.value).forEach((key) => {
      if (
        initialValues?.individual_support_plan_draft_setting &&
        initialValues?.individual_support_plan_draft_setting[key] > 0
      ) {
        fillButtons2.push(key)
      }
    })

    BUTTON_DISPLAY_LIST3.map((x) => x.value).forEach((key) => {
      if (
        initialValues?.monitoring_setting &&
        initialValues?.monitoring_setting[key] > 0
      ) {
        fillButtons3.push(key)
      }
    })

    setDisplayButtonItems(fillButtons)
    setDisplayButtonItems1(fillButtons1)
    setDisplayButtonItems2(fillButtons2)
    setDisplayButtonItems3(fillButtons3)

    if (pageSettings?.header_type > -1 && pageSettings?.header_type < 3) {
      const headerType = pageSettings?.header_type
      if (DISPLAY_OPTION_LIST[headerType] !== undefined)
        pageSettings.header_item = DISPLAY_OPTION_LIST[headerType].value
    } else if (pageSettings?.header_type === 3) {
      pageSettings.header_item = String(pageSettings?.header_facility_id)
    }
  }, [])

  useEffect(() => {
    // Setting the default values here
    if (
      pageSettings?.individual_support_plan_draft_setting === null &&
      pageSettings?.individual_support_plan_setting === null &&
      pageSettings?.monitoring_setting === null
    ) {
      const item1Values = BUTTON_DISPLAY_LIST1?.map((item) => item?.value)
      setDisplayButtonItems1(item1Values)
      const item2Values = BUTTON_DISPLAY_LIST2?.map((item) => item?.value)
      setDisplayButtonItems2(item2Values)
      const item3Values = BUTTON_DISPLAY_LIST3?.map((item) => item?.value)
      setDisplayButtonItems3(item3Values)
      formik.setFieldValue("enable_accept_only_facility_schedule_date", 1)
      formik.setFieldValue("service_provision_care_record_digital_sign", 1)
      formik.setFieldValue("header_item", "dont_display_text")
    }
  }, [pageSettings])

  const getUniqueList = (list1, list2) => {
    const uniqueSet = new Set([...list1, ...list2])
    return Array.from(uniqueSet)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const isNonFacility = DISPLAY_OPTION_LIST.findIndex(
        (item) => item.value == values.header_item
      )
      if (isNonFacility != undefined && isNonFacility > -1) {
        values.header_type =
          DISPLAY_OPTION_LIST.findIndex(
            (item) => item.value == values.header_item
          ) || 0

        values.header_facility_id = 0
      } else {
        values.header_facility_id = Number(values.header_item)
        values.header_type = 3
      }

      const postData = {
        service_provision_record: values.service_provision_record,
        service_provision_care_record_digital_sign:
          values.service_provision_care_record_digital_sign,
        activity_record: values.activity_record,
        service_provision_record_sheet: values.service_provision_record_sheet,
        contact_facility: values.contact_facility,
        mail_setting: values.mail_setting,
        password_change: values.password_change,
        header_facility_id: values.header_facility_id,
        header_text: values.header_text,
        enable_accept_only_facility_schedule_date:
          values.enable_accept_only_facility_schedule_date,
        footer_company_info_flg: values.footer_company_info_flg,
        footer_facility_info_flg: values.footer_facility_info_flg,
        logo_image: values.logo_image || "",
        individual_support_plan_setting: {},
        individual_support_plan_draft_setting: {},
        monitoring_setting: {},
        header_type: values.header_type,
        service_provision_care_record_comment:
          values?.service_provision_care_record_comment || [],
        footer_information: values?.footer_information || [],
      }

      BUTTON_DISPLAY_LIST.map((x) => x.value).forEach((key) => {
        postData[key] = displayButtonItems.includes(key) ? 1 : 0
      })

      BUTTON_DISPLAY_LIST1.map((x) => x.value).forEach((key) => {
        postData.individual_support_plan_setting[key] =
          displayButtonItems1.includes(key) ? 1 : 0
      })

      BUTTON_DISPLAY_LIST2.map((x) => x.value).forEach((key) => {
        postData.individual_support_plan_draft_setting[key] =
          displayButtonItems2.includes(key) ? 1 : 0
      })
      BUTTON_DISPLAY_LIST3.map((x) => x.value).forEach((key) => {
        postData.monitoring_setting[key] = displayButtonItems3.includes(key)
          ? 1
          : 0
      })

      handleSubmit(postData)
    },
  })

  const parseFooterInformation =
    formik.values.footer_information?.map(
      (facilityId: string) => +facilityId
    ) || []

  return (
    <Wrapper>
      <PageCard
        title={t("My page display setting")}
        extra={
          <>
            <span>
              {t("※Please refer to this manual for My Page display settings.")}
            </span>
            <Button
              text={t("See Manual")}
              shape={"circle"}
              borderColor={"#D2D1D1"}
            />
          </>
        }
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid className={"grid-header"} background>
            {t("Button display")}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Checkboxes
              name={"display"}
              onChange={(val) => setDisplayButtonItems(val)}
              value={displayButtonItems.join(",")}
              options={BUTTON_DISPLAY_LIST}
            />
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            {t("Individual Support Plan")}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Checkboxes
              name={"display"}
              onChange={(val) => setDisplayButtonItems1(val)}
              value={displayButtonItems1.join(",")}
              options={BUTTON_DISPLAY_LIST1}
            />
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            {t("Individual Support Plan (Draft)")}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Checkboxes
              name={"display"}
              onChange={(val) => setDisplayButtonItems2(val)}
              value={displayButtonItems2.join(",")}
              options={BUTTON_DISPLAY_LIST2}
            />
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            {t("Monitoring")}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Checkboxes
              name={"display"}
              onChange={(val) => setDisplayButtonItems3(val)}
              value={displayButtonItems3.join(",")}
              options={BUTTON_DISPLAY_LIST3}
            />
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            {t(
              "Apply for a facility only on the scheduled date registered in the recurring schedule setting"
            )}
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <RadioButtons
              options={USING_LIST}
              name={"enable_accept_only_facility_schedule_date"}
              value={formik.values.enable_accept_only_facility_schedule_date}
              onChange={(e) => {
                formik.setFieldValue(
                  "enable_accept_only_facility_schedule_date",
                  +e.target.value
                )
              }}
            />
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            {t("Service provision record/Care record's comment")}
          </Grid>
          <Grid className={"flex-full grid-row-last facilities_box"}>
            <Checkboxes
              name={"record"}
              onChange={(val) =>
                formik.setFieldValue(
                  "service_provision_care_record_comment",
                  val
                )
              }
              value={formik.values.service_provision_care_record_comment?.join(
                ","
              )}
              options={facilityOptions}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header"} background>
            <span>
              {t("Service provision record/")} <br />{" "}
              {t("Care record digital sign function")}
            </span>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <RadioButtons
              options={USING_LIST}
              name={"service_provision_care_record_digital_sign"}
              value={formik.values.service_provision_care_record_digital_sign}
              onChange={(e) => {
                formik.setFieldValue(
                  "service_provision_care_record_digital_sign",
                  +e.target.value
                )
              }}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header"} background>
            {t("Header information")}
          </Grid>
          <Grid className={"flex-full grid-row-last facilities_box"}>
            <p className={"text-required"}>
              {t(
                "※If you want to display any text, please check the box to display text and enter the text in the input field below."
              )}
            </p>
            <RadioButtons
              options={getUniqueList(
                DISPLAY_OPTION_LIST.slice(0, 3),
                facilityOptions
              )}
              value={formik.values?.header_item}
              name={"header_item"}
              onChange={(e) => {
                formik.setFieldValue("header_item", e.target.value.toString())
              }}
            />
            <TextField
              disabled={
                formik.values.header_item !== "display_text" &&
                formik.values.header_item !== 1
              }
              name={"header_text"}
              value={formik.values.header_text}
              onChange={formik.handleChange}
              error={formik.touched.header_text && formik.errors?.header_text}
              onBlur={formik.handleBlur}
              placeholder={t("Example: Please contact the contracted facility")}
              className={"text-field text-field-headertext"}
              showCounting
              bgcolor={"white"}
              maxLength={100}
              type={"textarea"}
              rows={4}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header"} background>
            {t("Footer information2")}
          </Grid>
          <Grid className={"flex-full grid-row-last facilities_box"}>
            <Checkbox
              name={"footer_company_info_flg"}
              checked={formik.values?.footer_company_info_flg}
              onChange={formik.handleChange}
              label={t("Company information")}
            />

            <Checkboxes
              name={"footer"}
              onChange={(value) =>
                formik.setFieldValue("footer_information", value)
              }
              options={getUniqueList([], facilityOptions)}
              value={parseFooterInformation?.join(",")}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <Grid className={"grid-header"} background>
            {t("Logo")}
          </Grid>
          <Grid className={"flex-full grid-row-last last-table-grid"}>
            <ImageUpload
              isLoading={(value) => setFormLoading(value)}
              currentImage={formik.values.logo_image}
              onChange={(val) => formik.setFieldValue("logo_image", val)}
            />
          </Grid>
          <Grid className={"flex-break"} />
          <div className={"button-wrapper"}>
            <OwnerButton
              text={t("Cancel")}
              typeOf={"secondary"}
              disabled={loading}
              onClick={() => router.push("/")}
            />
            <OwnerButton
              text={t("Save")}
              typeOf={"primary"}
              disabled={!hasEditAccess || formLoading || loading}
              type={"submit"}
              isLoading={loading}
            />
          </div>
        </StyledForm>
      </PageCard>
    </Wrapper>
  )
}

export { PageSettings }
