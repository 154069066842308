import { PageCard, Table } from "@project/shared"
import { Button } from "../../../components"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import { useContext, useState } from "react"
import { useQuery } from "react-query"
import { fetchMonthlyActivities } from "../../../services/monthylActivities"
import { AuthContext, getPaddedMonth } from "../../../utils"
import Link from "next/link"
import { useRouter } from "next/router"
import { ColumnsType } from "antd/lib/table/interface"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { MonthActivitiesPermission } from "../../../utils/PermissionKeys"

const DivStyled = styled.div`
  margin-top: 30px;
`

const DescStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-end;
  }

  a {
    color: #0a82c8;
  }
  .activity-description {
    line-height: 1.8;
  }
  .pagination-buttons-container {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    min-width: 275px;
    button {
      min-width: 75px;
      max-height: 40px;
      padding: 12px 20px;
      border-radius: 8px;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
      margin-left: 0px;
      max-width: 360px;
      justify-content: flex-end;
      .first-btn {
        width: 70px;
      }
    }
    @media (max-width: 426px) {
      gap: 0;
      button:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
`

const TableContainerStyled = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 25px;
  table {
    width: 100%;
  }
  table th {
    min-width: 104px;
  }
  table th:nth-child(1) {
    min-width: 68px;
  }
  table th:nth-child(2) {
    min-width: 180px;
  }
  table th:not(:nth-child(-n + 2)) {
    min-width: 140px;
  }
  .ant-table-tbody > tr > td {
    padding: 10px;
  }
`

const PageMonthlyActivitiesList = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [year, setYear] = useState(new Date().getFullYear())

  const MonthylActivitiesHeaderColumns: ColumnsType<any> = [
    {
      title: t("Month"),
      dataIndex: "month",
      key: "month",
      align: "center",
    },
    {
      title: t("Print"),
      dataIndex: "print",
      key: "print",
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            icon={"print"}
            text={record.print}
            color={"#FFFFFF"}
            backgroundColor={"#858686"}
            onClick={() =>
              router.push(
                `/monthly-activities/printing?year=${year}&month=${+getPaddedMonth(
                  record.month
                )}`
              )
            }
          />
        </div>
      ),
    },
  ]
  const MonthlyActivitiesData = []

  const { isLoading, data: monthlyActivitiesResponse } = useQuery({
    queryKey: [year],
    queryFn: () => fetchMonthlyActivities(year),
    refetchOnWindowFocus: false,
  })
  const { facilities, permissions, isOwner } = useContext(AuthContext)

  const updateFacilitiesHeader = () => {
    facilities.map((facility) => {
      const hasEditAccess =
        isOwner ||
        hasPermissionForFacility(
          permissions,
          MonthActivitiesPermission,
          "write",
          facility?.id
        )
      MonthylActivitiesHeaderColumns.push({
        title: facility?.facility_name_short,
        dataIndex: facility?.id.toString(),
        key: facility?.id.toString(),
        align: "center",
        render: ({ isSet, facilityId, year, month }) => {
          const renderButtons = () =>
            isSet ? (
              <Button
                text={t("Set already")}
                color={"#ffffff"}
                backgroundColor={"#E26827"}
                disabled={!hasEditAccess}
              />
            ) : (
              <Button text={t("No setting")} disabled={!hasEditAccess} />
            )

          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {hasEditAccess ? (
                <Link
                  href={`${window.location}/add?facility_id=${facilityId}&year=${year}&month=${month}`}
                >
                  <a>{renderButtons()}</a>
                </Link>
              ) : (
                renderButtons()
              )}
            </div>
          )
        },
      })
    })
  }

  const updateMonthlyActivitiesData = () => {
    // creating for 12 months
    for (let i = 1; i <= 12; i++) {
      const tempData: any = {
        id: i,
        month: t(`${i.toString()}月`),
        print: t("Print this"),
      }

      const iMonthData = monthlyActivitiesResponse?.data?.data?.find(
        (thatMonth) => thatMonth.month == i
      )

      facilities.map((facility) => {
        tempData[facility.id.toString()] = {
          facilityId: facility.id,
          year: year,
          month: i,
        }
        if (!iMonthData) {
          tempData[facility.id.toString()].isSet = 0
          return
        }

        tempData[facility.id.toString()].isSet = iMonthData.info.find(
          (mData) => mData.facility_id == facility.id
        )
          ? 1
          : 0
      })

      MonthlyActivitiesData.push(tempData)
    }
  }

  updateFacilitiesHeader()
  updateMonthlyActivitiesData()

  return (
    <DivStyled>
      <PageCard
        title={
          <Trans i18nKey={"monthlySettingTitle"}>
            {"Monthly activity settings for "}
            {{ year }}
          </Trans>
        }
      >
        <DescStyled>
          <div className={"activity-description"}>
            <Trans i18nKey={"monthlyActivityFirstDescription"}>
              {
                "For months without monthly activity settings, activities are displayed based on the activity settings for "
              }
              <a
                style={{ textDecoration: "underline" }}
                href={"/facility"}
                target={"_blank"}
              >
                {"each facility"}
              </a>
              {"."}
            </Trans>
            <br />
            {t(
              'When setting, please click the "With setting" or "Without setting" button.'
            )}
          </div>
          <div className={"pagination-buttons-container"}>
            <Button
              className={"first-btn"}
              icon={"left-arrow"}
              text={t("Previous Year")}
              borderColor={"#D2D1D1"}
              onClick={() => setYear(year - 1)}
            />
            <Button
              className={"first-btn"}
              text={t("This year")}
              backgroundColor={"rgba(7, 130, 200, 0.2)"}
              onClick={() => setYear(new Date().getFullYear())}
            />
            <Button
              className={"first-btn"}
              icon={"right-arrow"}
              iconPosition={"right"}
              text={t("Next Year")}
              borderColor={"#D2D1D1"}
              onClick={() => setYear(year + 1)}
            />
          </div>
        </DescStyled>
        <TableContainerStyled>
          <Table
            loading={isLoading}
            columns={MonthylActivitiesHeaderColumns}
            dataSource={MonthlyActivitiesData}
            bordered
            className={"always-present-scrollbar"}
            scroll={{ x: "scroll" }}
          />
        </TableContainerStyled>
      </PageCard>
    </DivStyled>
  )
}

export { PageMonthlyActivitiesList }
