import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RightOutlined } from "@ant-design/icons"
import { Button, Divider, Typography } from "antd"
import moment from "moment"
import {
  Checkboxes,
  PageCard,
  RadioButtons,
  SelectInput,
  CALANDER_HOURS_MINUTES,
  LOCATIONS_FOR_TRANSPORT_BULK_EDIT,
  DeleteConfimationModal,
  TextField,
  Checkbox,
  theme,
  LOCATIONS,
} from "@project/shared"
import { Wrapper } from "./TransportationsBulkFilter.styles"
import { DatePicker } from "../../molecules"
import { OwnerButton } from "../../atoms"

import { useTransportBulkEditContext } from "../../../context/TransportBulkEditContext"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { japaneseAlphaRegex } from "../../../utils/validation"

type BulkSettingsFilterProps = {
  type: string
  handleStateChange: (val: string, key: string, time?: string) => void
  state?: any
  data?: any
}

const BulkSettingsFilter: React.FC<BulkSettingsFilterProps> = ({
  type,
  handleStateChange,
  state,
  data,
}) => {
  const remainingData = data.slice(1)

  useEffect(() => {
    remainingData.forEach((item) => {
      if (item.driver_staff_id === state.driver_staff_id) {
        handleStateChange(null, "driver_staff_id")
      }
    })
  }, [data])

  const { t } = useTranslation()
  const { instructors, cars } = useTransportBulkEditContext()
  const handleFuriganaFilter = (val: string) => {
    const regex = japaneseAlphaRegex(val)
    const filterData =
      val === "---" || !val
        ? instructors
        : instructors?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )

    return filterData
  }
  return (
    <>
      <div className={"bulk_setting__content"}>
        <div className={"top"}>
          <div className={"icon_text"}>
            <RightOutlined className={"icon"} />
            <div className={"text"}>{type}</div>
          </div>
          <div className={"condition"}>
            <RadioButtons
              options={[
                {
                  label: t("Yes"),
                  value: "yes",
                },
                {
                  label: t("No"),
                  value: "no",
                },
              ]}
              value={state?.status || null}
              onChange={({ target: { value } }) =>
                handleStateChange(value, "status")
              }
            />
          </div>
        </div>

        <div className={"mini-flex"}>
          <div className={"time__picker"}>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Desired pick up time")}</div>
              </div>
              <div className={"inputs"}>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.desired_time?.hr}
                    options={CALANDER_HOURS_MINUTES?.hours}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "desired_time", "hr")
                    }
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={
                      state?.desired_time?.hr && !state?.desired_time?.min
                        ? "00"
                        : state?.desired_time?.hr && state?.desired_time?.min
                        ? state?.desired_time?.min
                        : ""
                    }
                    disabled={
                      !state?.desired_time?.hr ||
                      !state?.status ||
                      state?.status === "no"
                    }
                    options={CALANDER_HOURS_MINUTES?.minutes}
                    onChange={(val) =>
                      handleStateChange(val, "desired_time", "min")
                    }
                  />
                </div>
              </div>
            </div>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Place(After day)")}</div>
              </div>

              <div className={"inputs__place"}>
                <div className={"input__select--long"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    options={LOCATIONS}
                    value={state?.after_day}
                    disabled={state?.status === "no"}
                    onChange={(val) => handleStateChange(val, "after_day")}
                  />
                </div>
                {state?.after_day === 3 && (
                  <div
                    className={"input__select--long"}
                    style={{
                      marginTop: "0.4rem",
                    }}
                  >
                    <TextField
                      bgcolor={"transparent"}
                      height={"40px"}
                      placeholder={t("Place")}
                      value={state?.after_place || ""}
                      onChange={({ target: { value } }) => {
                        handleStateChange(value, "after_place")
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Arrival time")}</div>
              </div>

              <div className={"inputs"}>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.arrival_time?.hr}
                    disabled={!state?.status || state?.status === "no"}
                    options={CALANDER_HOURS_MINUTES?.hours}
                    onChange={(val) =>
                      handleStateChange(val, "arrival_time", "hr")
                    }
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={
                      state?.arrival_time?.hr && !state?.arrival_time?.min
                        ? "00"
                        : state?.arrival_time?.hr && state?.arrival_time?.min
                        ? state?.arrival_time?.min
                        : ""
                    }
                    disabled={
                      !state?.arrival_time?.hr ||
                      !state?.status ||
                      state?.status === "no"
                    }
                    options={CALANDER_HOURS_MINUTES?.minutes}
                    onChange={(val) =>
                      handleStateChange(val, "arrival_time", "min")
                    }
                  />
                </div>
              </div>
            </div>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Conductor")}</div>
              </div>

              <div className={"inputs"}>
                <SelectInput
                  width={60}
                  height={40}
                  padding={"0px 5px"}
                  notFoundContent={t("No data")}
                  value={state?.s_furigana || ""}
                  options={FuriganaAlphabetsOption}
                  onChange={(val) => handleStateChange(val, "s_furigana")}
                  disabled={state?.status === "no"}
                />
                <div className={"input__select--long"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    options={handleFuriganaFilter(state?.s_furigana) || []}
                    value={state?.escort_staff_id}
                    disabled={state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "escort_staff_id")
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={"time__picker"}>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>
                  {type === t("Drop off")
                    ? t("Drop Off Time")
                    : t("Picked up time")}
                </div>
              </div>
              <div className={"inputs"}>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.pickup_time?.hr}
                    options={CALANDER_HOURS_MINUTES?.hours}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "pickup_time", "hr")
                    }
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={
                      state?.pickup_time?.hr && !state?.pickup_time?.min
                        ? "00"
                        : state?.pickup_time?.hr && state?.pickup_time?.min
                        ? state?.pickup_time?.min
                        : ""
                    }
                    disabled={
                      !state?.pickup_time?.hr ||
                      !state?.status ||
                      state?.status === "no"
                    }
                    options={CALANDER_HOURS_MINUTES?.minutes}
                    onChange={(val) =>
                      handleStateChange(val, "pickup_time", "min")
                    }
                  />
                </div>
              </div>
            </div>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Place (Child dev)")}</div>
              </div>

              <div className={"inputs__place"}>
                <div className={"input__select--long"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    options={LOCATIONS_FOR_TRANSPORT_BULK_EDIT}
                    value={state?.place_id}
                    disabled={state?.status === "no"}
                    onChange={(val) => handleStateChange(val, "place_id")}
                  />
                </div>
                {state?.place_id === 3 && (
                  <div
                    className={"input__select--long"}
                    style={{
                      marginTop: "0.4rem",
                    }}
                  >
                    <TextField
                      bgcolor={"transparent"}
                      height={"40px"}
                      placeholder={t("Place")}
                      value={state?.other_place || ""}
                      onChange={({ target: { value } }) => {
                        handleStateChange(value, "other_place")
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Vehicle")}</div>
              </div>

              <div className={"inputs__place"}>
                <div className={"input__select--long"}>
                  <SelectInput
                    height={40}
                    padding={"0px 5px "}
                    options={cars || []}
                    value={state?.car_id}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) => handleStateChange(val, "car_id")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"time__picker"}>
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Driver")}</div>
              </div>
              <div className={"inputs"}>
                <div className={"input__select select_first"}>
                  <SelectInput
                    width={60}
                    height={40}
                    padding={"0 5px "}
                    notFoundContent={t("No data")}
                    value={state?.d_furigana || ""}
                    options={FuriganaAlphabetsOption}
                    onChange={(val) => handleStateChange(val, "d_furigana")}
                    disabled={state?.status === "no"}
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select--long"}>
                  <SelectInput
                    height={40}
                    padding={"0px 5px"}
                    value={state?.driver_staff_id}
                    options={handleFuriganaFilter(state?.d_furigana) || []}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "driver_staff_id")
                    }
                  />
                </div>
              </div>
            </div>

            {/* Transportation start time time_picker starts here */}
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Transportation start time")}</div>
              </div>
              <div className={"inputs"}>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.transportation_start_time?.hr}
                    options={CALANDER_HOURS_MINUTES?.hours}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "transportation_start_time", "hr")
                    }
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.transportation_start_time?.min}
                    disabled={
                      !state?.transportation_start_time?.hr ||
                      !state?.status ||
                      state?.status === "no"
                    }
                    options={CALANDER_HOURS_MINUTES?.minutes}
                    onChange={(val) =>
                      handleStateChange(val, "transportation_start_time", "min")
                    }
                  />
                </div>
              </div>
            </div>
            {/* Transportation start time time_picker ends here */}

            {/* Transportation end time time_picker starts here */}
            <div className={"time__picker--content"}>
              <div className={"text_icon"}>
                <RightOutlined className={"icon"} />
                <div className={"text"}>{t("Transportation end time")}</div>
              </div>
              <div className={"inputs"}>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.transportation_end_time?.hr}
                    options={CALANDER_HOURS_MINUTES?.hours}
                    disabled={!state?.status || state?.status === "no"}
                    onChange={(val) =>
                      handleStateChange(val, "transportation_end_time", "hr")
                    }
                  />
                </div>
                <b>{":"}</b>
                <div className={"input__select"}>
                  <SelectInput
                    height={40}
                    padding={"0 5px"}
                    value={state?.transportation_end_time?.min}
                    disabled={
                      !state?.transportation_end_time?.hr ||
                      !state?.status ||
                      state?.status === "no"
                    }
                    options={CALANDER_HOURS_MINUTES?.minutes}
                    onChange={(val) =>
                      handleStateChange(val, "transportation_end_time", "min")
                    }
                  />
                </div>
              </div>
            </div>
            {/* Transportation end time time_picker ends here */}
          </div>
        </div>
      </div>
    </>
  )
}

export const TransportationsBulkFilter = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const {
    facilities,
    setIsCheckAll,
    pickUp,
    setPickUp,
    dropOff,
    setDropOff,
    onFacilityChecked,
    displayChange,
    setDisplayChange,
    handleReflection,
    handleReset,
    handleUpdateDisplay,
    data,
  } = useTransportBulkEditContext()
  return (
    <>
      <Wrapper>
        <PageCard title={t("Operation options")}>
          <div className={"filter__container"}>
            <div className={"title"}>{t("Display Change")}</div>
            <div className={"filter__box"}>
              <div className={"filter__box--label"}>
                <RightOutlined className={"icon"} />
                <Typography.Text>{t("Facility")}</Typography.Text>
              </div>
              <div className={"filter__box--value"}>
                <div className={"value__content"}>
                  <div className={"check_uncheck_all"}>
                    <Button
                      icon={
                        <img src={"/assets/icons/check.svg"} alt={"icon"} />
                      }
                      shape={"round"}
                      onClick={() => {
                        setIsCheckAll(true)
                        setDisplayChange({
                          ...displayChange,
                          facility: facilities?.map((li) => `${li.id}`),
                        })
                      }}
                    >
                      <Typography.Text className={"span"}>
                        {t("Check all")}
                      </Typography.Text>
                    </Button>
                    <Button
                      shape={"round"}
                      onClick={() => {
                        setDisplayChange({
                          ...displayChange,
                          facility: [],
                        })
                        setIsCheckAll(false)
                      }}
                    >
                      <Typography.Text className={"span"}>
                        {t("Uncheck all")}{" "}
                      </Typography.Text>
                    </Button>
                  </div>
                  <div className={"checkboxs break-word"}>
                    {facilities?.map((value) => (
                      <Checkbox
                        key={value?.id}
                        onChange={onFacilityChecked}
                        checked={displayChange?.facility?.includes(
                          `${value?.id}`
                        )}
                        value={value?.id || ""}
                        label={value?.facility_name_short || ""}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={"filter__box"}>
              <div className={"filter__box--label"}>
                <RightOutlined className={"icon"} />
                <Typography.Text>{t("Service Used")}</Typography.Text>
              </div>
              <div className={"filter__box--value"}>
                <div className={"value__content"}>
                  <div className={"checkboxs"}>
                    <Checkboxes
                      options={[
                        { label: t("After school day service"), value: "1" },
                        { label: t("Child development support"), value: "2" },
                      ]}
                      value={`${displayChange?.service_type}`}
                      onChange={(val) =>
                        setDisplayChange({
                          ...displayChange,
                          service_type: val,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"filter__box"}>
              <div className={"filter__box--label"}>
                <RightOutlined className={"icon"} />
                <Typography.Text>{t("List display")}</Typography.Text>
              </div>
              <div className={"filter__box--value"}>
                <div className={"value__content"}>
                  <div className={"checkboxs"}>
                    <Checkboxes
                      options={[
                        { label: t("Greet"), value: "1" },
                        { label: t("Drop off"), value: "2" },
                      ]}
                      value={`${displayChange?.list_display}`}
                      onChange={(val) =>
                        setDisplayChange({
                          ...displayChange,
                          list_display: val,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"filter__box"}>
              <div className={"filter__box--label"}>
                <RightOutlined className={"icon"} />
                <Typography.Text>{t("Date")}</Typography.Text>
              </div>
              <div className={"filter__box--value"}>
                <div className={"value__content"}>
                  <div className={"date_box"}>
                    <DatePicker
                      onDateChange={(val) =>
                        setDisplayChange({
                          ...displayChange,
                          date: moment(val),
                        })
                      }
                      date={
                        moment(displayChange?.date).isValid()
                          ? moment(displayChange?.date)
                          : null
                      }
                      style={{ height: "40px", width: "150px" }}
                      format={"YYYY年MM月DD日"}
                    />
                    <OwnerButton
                      text={t("Update display")}
                      shape={"circle"}
                      typeOf={"secondary"}
                      className={"update-display"}
                      onClick={handleUpdateDisplay}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={"bulk_setting__container"}>
              <div className={"title"}>{t("Bulk Settings")}</div>
              <br />
              <BulkSettingsFilter
                type={t("Greet")}
                state={pickUp}
                data={data.pickUpOtherInfo}
                handleStateChange={(
                  val: string,
                  key: string,
                  time?: string
                ) => {
                  if (
                    key === "desired_time" ||
                    key === "pickup_time" ||
                    key === "arrival_time" ||
                    key === "transportation_start_time" ||
                    key === "transportation_end_time"
                  ) {
                    if (time === "hr") {
                      setPickUp({
                        ...pickUp,
                        [key]: {
                          ...pickUp[key],
                          [time]: val,
                          min: pickUp[key].min === "" ? "00" : pickUp[key].min,
                        },
                      })
                    } else if (time === "min") {
                      setPickUp({
                        ...pickUp,
                        [key]: {
                          ...pickUp[key],
                          [time]: val,
                        },
                      })
                    }
                  } else {
                    setPickUp({
                      ...pickUp,
                      [key]: val,
                    })
                  }
                }}
              />

              <Divider />

              <BulkSettingsFilter
                type={t("Drop off")}
                state={dropOff}
                data={data.dropOffOtherInfo}
                handleStateChange={(
                  val: string,
                  key: string,
                  time?: string
                ) => {
                  if (
                    key === "desired_time" ||
                    key === "pickup_time" ||
                    key === "arrival_time" ||
                    key === "transportation_start_time" ||
                    key === "transportation_end_time"
                  ) {
                    if (time === "hr") {
                      setDropOff({
                        ...dropOff,
                        [key]: {
                          ...dropOff[key],
                          [time]: val,
                          min:
                            dropOff[key].min === "" ? "00" : dropOff[key].min,
                        },
                      })
                    } else if (time === "min") {
                      setDropOff({
                        ...dropOff,
                        [key]: {
                          ...dropOff[key],
                          [time]: val,
                        },
                      })
                    }
                  } else {
                    setDropOff({
                      ...dropOff,
                      [key]: val,
                    })
                  }
                }}
              />
            </div>
            <div className={"filter__container--bottom"}>
              <OwnerButton
                text={t("Reflect")}
                shape={"circle"}
                onClick={() => {
                  setSelected("reflect")
                  setIsModalOpen(true)
                }}
              />
              <OwnerButton
                text={t(" Reset")}
                shape={"circle"}
                typeOf={"secondary"}
                onClick={() => {
                  setSelected("reset")
                  setIsModalOpen(true)
                }}
              />
              <Typography.Text
                style={{
                  color: theme?.red1,
                }}
              >
                {t(
                  `※If there is a student whose record you do not want to overwrite, remove the check and press "Reflect"`
                )}
              </Typography.Text>
            </div>
          </div>
        </PageCard>
      </Wrapper>

      {isModalOpen && (
        <DeleteConfimationModal
          open={isModalOpen}
          msg={t(
            "Are you sure to reflect?\nIf there are record it will be over written."
          )}
          width={"545px"}
          isOkButtonText={"untranslatedOK"}
          onCancel={() => setIsModalOpen(false)}
          onOk={() => {
            if (selected === "reflect") {
              handleReflection()
            } else {
              handleReset()
            }
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}
