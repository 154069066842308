import { useRouter } from "next/router"

// Packages
import { t } from "i18next"
import moment from "moment"

// components
import { DatePicker, OwnerButton, QuoteText } from "../../.."
import { SelectInput, TopRoundedWhiteCard } from "@project/shared"

// common components
import { OperationFieldWrapper } from "."

// Styled components
import { OperationWrapper } from "../styles"

// types
import { OperationActionBlockProps } from "../types"

export const OperationActionBlock: React.FC<OperationActionBlockProps> = ({
  onReset,
  currentDate,
  initialValues,
  onDisplayChange,
  facilityOptions,
  handleInputChange,
  currentFacilityId,
  handleSelectedItemsDeletion,
  isDeleting,
  isDeleteBtnDisable,
}) => {
  const { year, month, day } = initialValues
  const submittedYear = moment(currentDate).format("YYYY")
  const submittedMonth = moment(currentDate).format("MM")
  const submittedDay = moment(currentDate).format("DD")
  const router = useRouter()
  return (
    <OperationWrapper>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Basic information on actual expenses")}
      >
        {/* Filter operation form starts here */}
        <QuoteText margin={"16px 0"}>{t("Narrow Down")}</QuoteText>
        <div className={"upper-search-operation"}>
          <OperationFieldWrapper label={t("Date")}>
            <DatePicker
              className={"date-picker"}
              name={"date"}
              date={moment({ year: +year, month: +month - 1, day: +day })}
              onDateChange={(value) => {
                const year = moment(value).format("YYYY")
                const month = moment(value).format("MM")
                const day = moment(value).format("DD")
                handleInputChange("year", +year)
                handleInputChange("month", +month)
                handleInputChange("day", +day)
              }}
              format={"YYYY年MM月DD日"}
            />
          </OperationFieldWrapper>

          <OperationFieldWrapper label={t("Institution")}>
            <SelectInput
              width={150}
              name={"facility"}
              className={"facility-select"}
              value={initialValues.facility}
              dropdownMatchSelectWidth={false}
              placeholder={t("All")}
              options={facilityOptions}
              onChange={(value) => {
                handleInputChange("facility", value)
              }}
            />
          </OperationFieldWrapper>

          <div className={"form-button-group flexbox"}>
            <OwnerButton
              text={t("Search")}
              typeOf={"search"}
              className={"search-button btn"}
              onClick={() => {
                onDisplayChange()
              }}
            />
            <OwnerButton
              className={"btn"}
              text={t("Reset")}
              typeOf={"secondary"}
              onClick={() => {
                onReset()
              }}
            />
          </div>
        </div>
        {/* Filter operation form ends here */}

        {/* Upper operation_container extra operations starts here */}
        <div className={"footer-btns-group flexbox"}>
          <OwnerButton
            className={"add-btn"}
            text={t("Add new data")}
            typeOf={"primary"}
            onClick={() => {
              router.push(
                `/actual-cost-burden/add?year=${submittedYear}&month=${submittedMonth}&day=${submittedDay}&facilityId=${currentFacilityId}`
              )
            }}
          />
          <OwnerButton
            className={"delete-btn"}
            text={t("Delete checked data")}
            typeOf={"secondary"}
            onClick={handleSelectedItemsDeletion}
            isLoading={isDeleting}
            disabled={isDeleteBtnDisable}
          />
        </div>
        {/* Upper operation_container extra operations ends here */}
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
