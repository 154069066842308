import { API } from "@project/shared"

export interface IReturnServiceProvision {
  count?: number
  data?: any
}

interface IServiceProvisionParam {
  page?: string
  pageSize?: string
}

export const fetchServiceProvision = async ({
  queryKey,
}): Promise<IReturnServiceProvision> => {
  const params = {} as IServiceProvisionParam
  params.page = queryKey[1]
  params.pageSize = "20"
  return API.get(`/service-content-category`, {
    params,
  })
}

export const fetchAllServiceProvision = async ({
  queryKey,
}): Promise<IReturnServiceProvision> => {
  const params = {} as IServiceProvisionParam
  params.page = queryKey[1]
  params.pageSize = "Infinity"
  return API.get(`/service-content-category`, {
    params,
  })
}

export const getServiceProvision = async (id) => {
  return API.get(`/service-content-category/${id}`)
}

export const createServiceProvision = async (values): Promise<any> => {
  return API.post(`/service-content-category`, values)
}

export const updateServiceProvision = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/service-content-category/${id}`, values)
}

export const deleteServiceProvision = async (id: string) => {
  return API.delete(`/service-content-category/${id}`)
}

export const updateMarkAsRead = async (values) => {
  const id = values.child_service_content_care_log_id
  delete values.id
  return API.put(`/child-service-content-care-log/mark-as-read/${id}`, values)
}
