import React, { useRef } from "react"
import router from "next/router"

// packages
import { t } from "i18next"
import moment from "moment"
import { useFormik } from "formik"

// styled components
import { ProgressRecInfoContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { PrintOnlyHeader } from "."
import { TopRoundedWhiteCard } from "@project/shared"

// hooks
import { useTable } from "../hooks/useTable"

// types
import {
  RecTableDataSourcesProps,
  ProgramProgressRecInfoBlockProps,
} from "../types"
import { Popconfirm } from "antd"

export const ProgramProgressRecInfoBlock: React.FC<
  ProgramProgressRecInfoBlockProps
> = ({
  programOptions,
  rawStaffOptions,
  currentChildName,
  isStaffListLoading,
  initialRecTableData,
  currentSearchQueries,
  recTableDataUpdateFn,
  isRecTableDataLoading,
  isRecTableDataUpdating,
  currentProgramCategoryName,
  showDelete,
  handleDelete,
  isDeleting,
}) => {
  const currentDate = currentSearchQueries.yearMonth
  const tableRef = useRef(null)

  const formik = useFormik<RecTableDataSourcesProps>({
    initialValues: {
      individualDayRecords: {},
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      recTableDataUpdateFn(values)
    },
  })

  const { programCatTable } = useTable(
    programOptions,
    currentDate,
    formik,
    rawStaffOptions,
    initialRecTableData,
    isRecTableDataLoading,
    isStaffListLoading
  )

  return (
    <ProgressRecInfoContainer ref={tableRef}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t(
          "{{year}}年{{month}}月 Program progress record information ({{programCatName}})",
          {
            year: moment(currentDate).format("YYYY"),
            month: moment(currentDate).format("MM"),
            programCatName:
              currentProgramCategoryName || "Program category name",
          }
        )}
      >
        <div className={"progress-record-info-body"}>
          <OwnerButton
            className={"print-btn no-print"}
            text={t("Print ")}
            typeOf={"print"}
            onClick={() => {
              window.print()
            }}
          />

          {/* This element only displays on print mode */}
          <PrintOnlyHeader
            categoryName={currentProgramCategoryName}
            currentDate={currentDate}
            currentChildName={currentChildName}
          />

          {/* Table starts here */}
          {programCatTable}
          {/* Table ends here */}
        </div>
        <div className={"button-container-with-delete"}>
          <div className={"form-button-group flexbox no-print"}>
            <OwnerButton
              className={"btn"}
              text={t("Cancel")}
              typeOf={"secondary"}
              onClick={() => router.push("/program-progress-record-list")}
            />
            <OwnerButton
              text={t("save")}
              typeOf={"primary"}
              className={"save-button btn"}
              isLoading={isRecTableDataUpdating}
              onClick={() => {
                formik.handleSubmit()
              }}
            />
          </div>
          {showDelete && (
            <Popconfirm
              title={t("Deleting.Is that OK?")}
              okText={"OK"}
              cancelText={t("Cancel")}
              okButtonProps={{ size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
              onConfirm={handleDelete}
            >
              <OwnerButton
                text={t("Delete")}
                typeOf={"primary"}
                className={"delete-button btn"}
                isLoading={isDeleting}
              />
            </Popconfirm>
          )}
        </div>
      </TopRoundedWhiteCard>
    </ProgressRecInfoContainer>
  )
}
