// packages
import { t } from "i18next"

// types
import { TableDataProps } from "../types"

export const sourceData: TableDataProps[] = [
  {
    id: 1,
    key: "1",
    division: 1,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 2,
    key: "2",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 3,
    key: "3",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 4,
    key: "4",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 5,
    key: "5",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 6,
    key: "6",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 7,
    key: "7",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },

  {
    id: 8,
    key: "8",
    division: 2,
    item: [
      {
        child: "安藤 明日",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
      {
        child: "高倉 さとみ",
        businessNumber: "239912910",
        nameOfBusiness: "放課後デイサービス シュシュ",
        totalCost: 123120,
        userBurden: 4600,
        afterCtrlResultUserBurden: 4600,
      },
    ],
  },
]

export const divisionOptions = [
  {
    label: t("Division 1"),
    value: 1,
  },
  {
    label: t("Division 2"),
    value: 2,
  },
]

export const PRINT_CONFIGURATION_OPTION_VALUES = {
  PRINT_FORM_NUMBER: "1",
  PRINT_BY_OFFICE: "2",
  HIDE_PERSONAL_INFORMATION: "3",
  PRINT_MARK_ON_CONFIRMATION: "4",
  CHANGE_DISABILITY_WORD: "5",
}

export const PRINT_CONFIGURATION_OPTIONS = [
  {
    label: t("Print the form number"),
    value: PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER,
  },
  {
    label: t("Print by office"),
    value: PRINT_CONFIGURATION_OPTION_VALUES.PRINT_BY_OFFICE,
  },
  {
    label: t("Hide personal information"),
    value: PRINT_CONFIGURATION_OPTION_VALUES.HIDE_PERSONAL_INFORMATION,
  },
  {
    label: t(
      "Print a mark in the confirmation column for the name of the person with a disability, etc. for which payment has been determined."
    ),
    value: PRINT_CONFIGURATION_OPTION_VALUES.PRINT_MARK_ON_CONFIRMATION,
  },
  {
    label: t(`Change the expression “disability” to “disability”`),
    value: PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD,
  },
]

export const PRINT_STYLE_VALUES = {
  NATIONAL: "1",
  KYOTO: "2",
  KOBE: "3",
  HIROSHIMA: "4",
  OTA: "5",
  SHIZUOKA: "6",
  KISARAZU: "7",
}

export const PRINT_STYLE_OPTIONS = [
  {
    label: t("National standard style"),
    value: PRINT_STYLE_VALUES.NATIONAL,
  },
  {
    label: t("Kyoto city style"),
    value: PRINT_STYLE_VALUES.KYOTO,
  },
  {
    label: t("Kobe city style"),
    value: PRINT_STYLE_VALUES.KOBE,
  },
  {
    label: t("Hiroshima city style"),
    value: PRINT_STYLE_VALUES.HIROSHIMA,
  },
  {
    label: t("Ota ward style"),
    value: PRINT_STYLE_VALUES.OTA,
  },
  {
    label: t("Shizuoka city style"),
    value: PRINT_STYLE_VALUES.SHIZUOKA,
  },
  {
    label: t("Kisarazu city style"),
    value: PRINT_STYLE_VALUES.KISARAZU,
  },
]
