import React from "react"
import Link from "next/link"

// packages
import moment from "moment"
import { Button, Typography } from "antd"
import { Trans, useTranslation } from "react-i18next"

//styles
import { Wrapper } from "./ChangeBillingInfo.style"
import { theme } from "@project/shared"

export const ChangeBillingInfo = ({
  isUpdating,
  editConfirmed,
  showChangeBilling,
  recentlyUpdatedInfo,
  currentBillingMethod,
  onChangeBillingMethod,
  onEditFinalizedBilling,
  isBillingConfirmed,
  onEditConfirmedBillingInformation,
  handleOutput,
  userFeeConfirmed,
}: {
  isUpdating: boolean
  editConfirmed: boolean
  recentlyUpdatedInfo: any
  showChangeBilling: boolean
  currentBillingMethod: string
  onChangeBillingMethod: () => void
  onEditFinalizedBilling: () => void
  isBillingConfirmed: boolean
  onEditConfirmedBillingInformation: () => void
  handleOutput: () => void
  userFeeConfirmed: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const hasRecentlyUpdatedInfo =
    !isBillingConfirmed || editConfirmed
      ? false
      : recentlyUpdatedInfo?.child_attendance?.length ||
        recentlyUpdatedInfo?.child_receiving_certificate?.length ||
        recentlyUpdatedInfo?.facility_schedule?.length
      ? true
      : false
  const LIST = [
    {
      label: t("Select children to output"),
      scrollId: "selectChildrenToOutput",
    },
    {
      label: t("List of User Fees"),
      scrollId: "listOfUserCharges",
    },
    {
      label: t("User burden maximum amount management result sheet"),
      scrollId: "userBurdenUpperLimitManagementResultSlip",
    },
    {
      label: t("Determining the User Burden Amount"),
      scrollId: "betermineUserBurden",
    },
    {
      label: t(
        "Output of information to be submitted to the National Health Insurance Federation"
      ),
      scrollId: "outputInformationNHIF",
    },
    {
      label: t("Other forms printing, etc."),
      scrollId: "printPtherList",
    },
  ]

  const displayRecentlyUpdatedChilds = () => {
    const getDatesInArray = (stringDates) => {
      let dates = []
      try {
        dates = JSON.parse(stringDates)
      } catch (e) {
        dates = []
      }
      return dates?.sort()
    }

    return (
      <>
        {recentlyUpdatedInfo?.child_attendance?.map((child, index) => (
          <li key={index}>
            {child?.child_name} {t("Attendance")} {" : "}
            {getDatesInArray(child?.dates)?.map((date, index) => (
              <Link
                href={`/user-attendance-table/day-list?date=${date}&facilityId=${child?.facility_id}`}
                key={index}
              >
                {/* japanese date format */}
                <a>{`${index > 0 ? ", " : ""}${moment(date).format(
                  "YYYY年MM月DD日"
                )}`}</a>
              </Link>
            ))}
          </li>
        ))}
      </>
    )
  }

  const displayRecentlyUpdatedFacilityScheduleChilds = () => {
    return (
      <>
        {t("Facility application")} {" : "}
        {recentlyUpdatedInfo?.facility_schedule?.map((child, index) => (
          <Link
            key={index}
            href={`/child/profile/facility-application/${
              child?.child_id
            }?date=${child?.year}-${child?.month
              ?.toString()
              ?.padStart(2, "0")}-01`}
          >
            <a>
              {index > 0 ? ", " : ""}
              {child?.child_name}
            </a>
          </Link>
        ))}
      </>
    )
  }

  const displayRecentlyUpdatedChildReceivingCertificateChilds = () => {
    return (
      <>
        {t("Child Recipient Certificate")} {" : "}
        {recentlyUpdatedInfo?.child_receiving_certificate?.map(
          (certificate, index) => (
            <Link
              key={index}
              href={`/child/profile/${certificate?.child_id}/recipient-certificate-form/edit/${certificate?.id}`}
            >
              <a>
                {index > 0 ? ", " : ""}
                {certificate?.child?.child_name}
              </a>
            </Link>
          )
        )}
      </>
    )
  }

  return (
    <Wrapper>
      <ul>
        {LIST?.map((val, index) => (
          <li key={index}>
            <a
              onClick={() => {
                document.getElementById(val?.scrollId)?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }}
            >
              {val?.label}
            </a>
          </li>
        ))}
      </ul>
      <div></div>
      {hasRecentlyUpdatedInfo ? (
        <div className={"info-recently-updated"}>
          <div className={"heading"}>
            <img src={"/assets/icons/exclamation.png"} alt={""} />
            {t(
              `The following pages have been updated and may cause differences between the data being finalized.`
            )}{" "}
            <br />
            {t(
              `To reflect the updated information in the finalized data, please click on "Edit finalized billing information" and then click on Finalize billing information again.`
            )}
          </div>
          <ul className={"content"}>
            {recentlyUpdatedInfo?.child_attendance?.length > 0 ? (
              <li>
                <ul>{displayRecentlyUpdatedChilds()}</ul>
              </li>
            ) : (
              <></>
            )}
            {recentlyUpdatedInfo?.facility_schedule?.length > 0 ? (
              <li>{displayRecentlyUpdatedFacilityScheduleChilds()}</li>
            ) : (
              <></>
            )}
            {recentlyUpdatedInfo?.child_receiving_certificate?.length > 0 ? (
              <li>{displayRecentlyUpdatedChildReceivingCertificateChilds()}</li>
            ) : (
              <></>
            )}
          </ul>
          <div className={"button-container"}>
            <Button
              icon={
                <img
                  src={"/assets/icons/icon_edit.svg"}
                  height={18}
                  width={18}
                  alt={""}
                />
              }
              onClick={onEditConfirmedBillingInformation}
            >
              {t("Edit confirmed billing information")}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showChangeBilling ? (
        <div className={"cta_box"}>
          <Typography.Text>
            <Trans
              i18nKey={"currentBillingMethodSetAs"}
              method={currentBillingMethod}
            >
              {
                "It is set to bill the National Health Insurance Federation with"
              }
              <span style={{ color: theme.red1 }}>
                {{ method: currentBillingMethod } as any}
              </span>
            </Trans>
          </Typography.Text>
          <button
            onClick={onChangeBillingMethod}
            className={"change-billing-info-button"}
          >
            {t("Change billing information >")}
          </button>
        </div>
      ) : (
        <></>
      )}
      {editConfirmed && userFeeConfirmed ? (
        <div className={"confirmed-billing-info-alert"}>
          <div className={"title"}>
            <img src={"/assets/icons/exclamation.png"} alt={""} />
            {t(
              `Billing information and user charges are being finalized. To select a child for output or change information, click "Edit finalized billing information" and to print various forms or output CSV, click "Output various forms and CSV".`
            )}
          </div>
          <div className={"button-container"}>
            <Button
              icon={
                <img
                  src={"/assets/icons/icon_edit.svg"}
                  height={18}
                  width={18}
                  alt={""}
                />
              }
              onClick={onEditFinalizedBilling}
              loading={isUpdating}
              disabled={isUpdating}
            >
              {t("Edit finalized billing information")}
            </Button>
            <Button onClick={handleOutput}>
              {t("Output various forms and CSV")}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
