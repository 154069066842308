import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import moment from "moment"
import {
  CALANDER_HOURS_MINUTES,
  Checkbox,
  DeleteConfimationModal,
  SelectInput,
  Table,
  TextField,
  theme,
} from "@project/shared"
import { Button, OwnerButton } from "../../atoms"
import {
  Wrapper,
  WorkingHoursContainer,
  CatBtnWrapper,
  VacationTimeColumn,
} from "./StaffAttendanceRecordResults.styles"
import { useMutation, useQueryClient } from "react-query"
import { createStaffAttendanceRecordResult } from "../../../services"
import { toastMsg } from "../../../utils/toastMsg"

interface IProps {
  results: any
  activeBtn: string
  onPaginateByDate: (val: string) => void
  createDate: any
  isLoading?: boolean
  facilityId: undefined | number
}

export const StaffAttendanceRecordResults: React.FC<IProps> = ({
  results,
  createDate,
  activeBtn,
  onPaginateByDate,
  facilityId,
  isLoading,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [page] = React.useState(1)
  const queryClient = useQueryClient()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>(results?.data || [])
  const deleteData = useRef(null)

  const redirectToDayAttendance = () => {
    router.push(
      `/staff-attendance/detail?facilityIds=${facilityId}&date=${createDate}`
    )
  }

  const { isLoading: isCreating, mutate } = useMutation(
    createStaffAttendanceRecordResult,
    {
      onSuccess: () => {
        toastMsg("success", t("Updated Successfully"))
        queryClient.invalidateQueries({
          queryKey: ["staff-attendence-results-record"],
        })
        setOpenDeleteModal(false)
        deleteData.current = null
        redirectToDayAttendance()
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )
  useEffect(() => {
    setDataSource(results?.data)
  }, [results])

  //update state->datasource when table cell value chnage
  const handleValueChange = ({
    rowId,
    key,
    value,
    extra = {},
  }: {
    rowId: number
    key: string
    value: string
    extra?: any
  }) => {
    const data = [...dataSource]?.map((val) =>
      (val?.id || val?.tempId) === rowId
        ? { ...val, [key]: value, ...extra }
        : val
    )
    setDataSource(data)
  }
  const renderRowCtaBtn = (rowId: number, info: number) => (
    <CatBtnWrapper>
      <OwnerButton
        text={t("attendance at work")}
        typeOf={"secondary"}
        shape={"round"}
        onClick={() => {
          const key = `attendance_at_work${info}`
          const data = dataSource?.map((val) =>
            val?.tempId === rowId ? { ...val, [key]: true } : val
          )
          setDataSource(data)
        }}
      />
      <OwnerButton
        text={t("absenteeism")}
        typeOf={"secondary"}
        shape={"round"}
        onClick={() => {
          const key = `attendance_type${info}`
          const data = dataSource?.map((val) =>
            val?.tempId === rowId ? { ...val, [key]: true } : val
          )

          setDataSource(data)
        }}
      />
    </CatBtnWrapper>
  )
  const renderWorkingHours = (row, workingHours: number) => {
    return (
      <>
        <div className={"hours_ranges_container"}>
          <div className={"hour-text"}>
            <span>
              {workingHours === 1
                ? t("Work information") + "1"
                : t("Work information") + "2"}
            </span>
          </div>

          {!row?.attendance_type && !row?.attendance_type1 ? (
            <>
              <div className={"range"}>
                {row?.attendance_at_work ? (
                  <>
                    <div className={"start_date"}>
                      <SelectInput
                        width={70}
                        height={40}
                        options={CALANDER_HOURS_MINUTES?.hours}
                        value={row?.attendance_start_time?.split(":")[0] || ""}
                        onChange={(val) =>
                          handleValueChange({
                            rowId: row?.tempId,
                            key: row?.startKey,
                            value: `${val}:${
                              row?.attendance_start_time?.split(":")[1] || ""
                            }`,
                          })
                        }
                      />

                      <span>{":"}</span>
                      <SelectInput
                        width={70}
                        height={40}
                        options={CALANDER_HOURS_MINUTES?.minutes}
                        value={row?.attendance_start_time?.split(":")[1] || ""}
                        disabled={
                          row?.attendance_start_time?.split(":")[0] === "00" ||
                          !row?.attendance_start_time?.split(":")[0]
                        }
                        onChange={(val) =>
                          handleValueChange({
                            rowId: row?.tempId,
                            key: row?.startKey,
                            value: `${
                              row?.attendance_start_time?.split(":")[0]
                            }:${val}`,
                          })
                        }
                      />
                    </div>
                    <span>{"~"}</span>
                    <div className={"end_date"}>
                      <SelectInput
                        width={70}
                        height={40}
                        options={CALANDER_HOURS_MINUTES?.hours}
                        range={"from"}
                        unit={["hours", "minutes"]}
                        value={row?.attendance_end_time?.split(":")[0] || ""}
                        onChange={(val) =>
                          handleValueChange({
                            rowId: row?.tempId,
                            key: row?.endKey,
                            value: `${val}:${
                              row?.attendance_end_time?.split(":")[1] || ""
                            }`,
                          })
                        }
                      />
                      <span>{":"}</span>
                      <SelectInput
                        width={70}
                        height={40}
                        options={CALANDER_HOURS_MINUTES?.minutes}
                        range={"from"}
                        unit={["hours", "minutes"]}
                        value={row?.attendance_end_time?.split(":")[1] || ""}
                        disabled={
                          row?.attendance_end_time?.split(":")[0] === "00" ||
                          !row?.attendance_end_time?.split(":")[0]
                        }
                        onChange={(val) =>
                          handleValueChange({
                            rowId: row?.tempId,
                            key: row?.endKey,
                            value: `${
                              row?.attendance_end_time?.split(":")[0]
                            }:${val}`,
                          })
                        }
                      />
                    </div>{" "}
                  </>
                ) : row?.attendance_at_work1 ? (
                  <>
                    {workingHours === 2 && !row?.record_working_hours2 ? (
                      <OwnerButton
                        text={t("Record work information 2")}
                        typeOf={"secondary"}
                        shape={"round"}
                        onClick={() => {
                          const data = dataSource?.map((val) =>
                            val?.tempId === row?.tempId
                              ? { ...val, record_working_hours2: true }
                              : val
                          )
                          setDataSource(data)
                        }}
                      />
                    ) : (
                      renderRowCtaBtn(row?.tempId, workingHours)
                    )}
                  </>
                ) : null}
              </div>
              {row?.attendance_at_work && (
                <div className={"break_time"}>
                  <span className={"text"}>{t("Break time")}</span>
                  <div className={"break_time_input"}>
                    <div className={"input"}>
                      <TextField
                        bgcolor={"transparent"}
                        padding={"4px 6px"}
                        value={row?.attendance_rest_minits || ""}
                        onChange={(event) =>
                          handleValueChange({
                            rowId: row?.tempId,
                            key: row?.minitsKey,
                            value: event?.currentTarget?.value,
                          })
                        }
                      />
                    </div>
                    <span>{t("minutes")}</span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                color: theme.red1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                borderLeft: `1px solid ${theme.gray2}`,
                minWidth: "200px",
              }}
            >
              {!row?.attendance_type1 ? t("absent") : ""}
            </div>
          )}
        </div>
      </>
    )
  }

  const defaultColumns: any[] = useMemo(
    () => [
      {
        title: t("日"),
        render: (_, __, index) => <span>{(page - 1) * 10 + index + 1}</span>,
        width: 10,
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Staff name")}</span>,
        key: "staff-name",
        render: (row) => {
          return {
            props: {
              style: { padding: `5px 5px` },
            },
            children: <span>{row?.staff_name || row?.staff?.staff_name}</span>,
          }
        },
      },

      {
        title: t("Working hours"),
        key: "workinghours",
        render: (row) => {
          return {
            props: {
              style: { padding: 0 },
            },
            children: (
              <WorkingHoursContainer className={"hi"}>
                {renderWorkingHours(
                  {
                    tempId: row?.tempId,
                    attendance_start_time: row?.attendance_start_time,
                    attendance_end_time: row?.attendance_end_time,
                    attendance_rest_minits: row?.attendance_rest_minits,
                    minitsKey: "attendance_rest_minits",
                    startKey: "attendance_start_time",
                    endKey: "attendance_end_time",
                    attendance_at_work: row?.attendance_at_work1,
                    attendance_at_work1: true,
                    attendance_type: row?.attendance_type1,
                    attendance_type1: null,
                    set_vacation_time: row?.set_vacation_time,
                  },
                  1
                )}
                {renderWorkingHours(
                  {
                    tempId: row?.tempId,
                    attendance_start_time: row?.attendance_start_time2,
                    attendance_end_time: row?.attendance_end_time2,
                    attendance_rest_minits: row?.attendance_rest_minits2,
                    set_vacation_time: row?.set_vacation_time,
                    minitsKey: "attendance_rest_minits2",
                    startKey: "attendance_start_time2",
                    endKey: "attendance_end_time2",
                    attendance_at_work: row?.attendance_at_work2,
                    attendance_at_work1: row?.attendance_at_work1,
                    attendance_type: row?.attendance_type2,
                    attendance_type1: row?.attendance_type1,
                    record_working_hours2: row?.record_working_hours2,
                  },
                  2
                )}
              </WorkingHoursContainer>
            ),
          }
        },
      },
      {
        title: t("Vacation time"),
        render: (row) => (
          <VacationTimeColumn>
            <div className={"set_vacation_box"}>
              <label htmlFor={"set-vacation-time"}>
                <Checkbox
                  id={"set-vacation-time"}
                  checked={row?.set_vacation_time}
                  onChange={({ target: { checked } }) => {
                    const data = dataSource?.map((val) =>
                      val?.tempId === row?.tempId
                        ? { ...val, set_vacation_time: checked }
                        : val
                    )
                    setDataSource(data)
                  }}
                  label={t("set vacation time")}
                />
              </label>
            </div>
            <div className={"vacation_time"}>
              <div className={"input_box"}>
                <TextField
                  bgcolor={"transparent"}
                  padding={"4px 6px"}
                  value={row?.vacation_time}
                  disabled={!row?.set_vacation_time}
                  maxLength={2}
                  onChange={(event) =>
                    handleValueChange({
                      rowId: row?.tempId,
                      key: "vacation_time",
                      value: event?.currentTarget?.value,
                    })
                  }
                />
              </div>
              <span style={{ whiteSpace: "nowrap" }}>{t("time")}</span>
            </div>
          </VacationTimeColumn>
        ),
      },
      {
        title: t("Remarks"),
        render: (row) => (
          <TextField
            bgcolor={"transparent"}
            padding={"4px 6px"}
            width={"200px"}
            value={row?.remarks || ""}
            onChange={(event) =>
              handleValueChange({
                rowId: row?.tempId,
                key: "remarks",
                value: event?.currentTarget?.value,
              })
            }
          />
        ),
      },
      {
        title: t("Delete"),
        key: "operation",
        render: (row) => {
          const isDisabled = row?.id === 0 ? true : false
          return {
            props: {
              style: { whiteSpace: "nowrap" },
            },
            children: !isDisabled ? (
              <div
                style={{ color: theme.blue5, cursor: "pointer" }}
                onClick={() => {
                  const data = dataSource?.find(
                    (val) => val?.id && val?.tempId === row?.tempId
                  )

                  if (data) {
                    data.is_deleted = true
                    data.date = moment(data?.date)
                    data.attendance_type2 = 0
                    deleteData.current = [data]
                    setOpenDeleteModal(true)
                  }
                }}
              >
                {t("Delete")}
              </div>
            ) : (
              <div style={{ minWidth: "100px" }}></div>
            ),
          }
        },
      },
    ],
    [dataSource]
  )

  return (
    <>
      <Wrapper>
        <div className={"top_content"}>
          <div className={"pagi"}>
            <Button
              text={t("Prev day")}
              padding={10}
              backgroundColor={
                activeBtn === "prev" ? theme.blue5 : "transparent"
              }
              color={activeBtn === "prev" ? theme.base : "inherit"}
              borderColor={theme.gray2}
              icon={"left-arrow"}
              onClick={() => {
                onPaginateByDate("prev")
              }}
            />
            <Button
              text={t("Today")}
              padding={10}
              backgroundColor={
                activeBtn === "today" ? theme.blue5 : "transparent"
              }
              borderColor={theme.gray2}
              iconPosition={"right"}
              color={activeBtn === "today" ? theme.base : "inherit"}
              onClick={() => {
                onPaginateByDate("today")
              }}
            />
            <Button
              text={t("Next day")}
              padding={10}
              backgroundColor={
                activeBtn === "next" ? theme.blue5 : "transparent"
              }
              color={activeBtn === "next" ? theme.base : "inherit"}
              borderColor={theme.gray2}
              icon={"right-arrow"}
              iconPosition={"right"}
              onClick={() => {
                onPaginateByDate("next")
              }}
            />
          </div>
        </div>
        <Table
          columns={defaultColumns}
          dataSource={dataSource}
          minWidth={5}
          scroll={{ x: true }}
          loading={isLoading}
          bordered
        />
        <div className={"btns-conten"}>
          <OwnerButton
            text={t("Cancel")}
            typeOf={"secondary"}
            shape={"circle"}
            onClick={() => redirectToDayAttendance()}
            disabled={isCreating}
          />
          <OwnerButton
            text={t("Save")}
            shape={"circle"}
            isLoading={isCreating}
            disabled={isCreating || dataSource?.length <= 0}
            onClick={() => {
              const finalData: any[] = dataSource
                ?.filter(
                  (val) =>
                    (val?.attendance_start_time && val?.attendance_end_time) ||
                    val?.attendance_type1 ||
                    val?.attendance_type2
                )
                ?.map(({ ...val }) => {
                  const values: any = {
                    id: val?.id,
                    staff_id: val?.staff_id,
                    facility_id: val?.facility_id,
                    date: moment.utc(createDate.toLocaleString()),
                    attendance_start_time: val?.attendance_start_time,
                    attendance_end_time: val?.attendance_end_time,
                    attendance_type: val?.attendance_type1 ? 2 : 1,
                    rest_hours: val?.vacation_time
                      ? parseInt(val?.vacation_time, 10)
                      : null,
                    rest_minits_flg: val?.set_vacation_time ? 1 : 0,
                    remarks: val?.remarks,
                    attendance_rest_minits: parseInt(
                      val?.attendance_rest_minits,
                      10
                    ),
                  }

                  if (values.record_working_hours2)
                    values.attendance_type2 = val?.attendance_type2 ? 2 : 1

                  if (
                    val?.attendance_start_time2 &&
                    val?.attendance_end_time2
                  ) {
                    values.attendance_start_time2 = val?.attendance_start_time2
                    values.attendance_end_time2 = val?.attendance_end_time2
                    values.attendance_rest_minits2 = parseInt(
                      val?.attendance_rest_minits2,
                      10
                    )
                  }
                  return values
                })
                ?.filter((att) => att?.attendance_type)
              mutate({
                facilityId: facilityId,
                values: finalData,
              })
            }}
          />
        </div>
      </Wrapper>

      <DeleteConfimationModal
        open={openDeleteModal}
        title={t(" Reset")}
        msg={t("Are you sure you want the reset attendance information?")}
        maskClosable={isLoading ? false : true}
        isOkButtonText={t("Confirm Reset")}
        footer={null}
        onCancel={() => {
          deleteData.current = null
          setOpenDeleteModal(false)
        }}
        onOk={() => {
          mutate({
            facilityId,
            values: deleteData.current,
          })
        }}
      />
    </>
  )
}
