import React, { useContext } from "react"

import { useTranslation } from "react-i18next"
import { AuthContext, getWarekiDOB } from "../../../../utils"
import { Table } from "antd"
import {
  DateInfo,
  FacilityInfoContent,
  Title,
  Wrapper,
  DateMonthInfo,
  PaymentDecisionColumn,
} from "./PrintContent.style"

import moment from "moment"
import { formatJaEra } from "../../../molecules/MonthlyUsageList/common/mapper"
import wareki from "wareki"

type FacilityInfo = {
  business_number?: string
  address?: string
  facility_name?: string
  facility_name_short?: string
  phone_no?: string
}

export const PrintContent = ({ data }: any) => {
  const { t } = useTranslation()
  const SERVICE_TYPE = {
    61: t("Child development support"),
    63: t("After school day service"),
  }
  const { facilities, otherFacilities } = useContext(AuthContext)

  function getFacilityInfo(): FacilityInfo {
    const type = data?.list[0]?.management_facility_type
    const id = data?.list[0]?.facilityId
    if (type === 1) {
      return facilities?.find((v) => v?.id === id)
    }
    if (type === 2) {
      const jointFac = otherFacilities?.find((v) => v?.id === id)
      return {
        business_number: jointFac?.facility_number || "",
        // po_box_no: jointFac?.fax_no || "",
        address: jointFac?.address || "",
        facility_name: jointFac?.facility_name || "",
        phone_no: jointFac?.tel_no || "",
      }
    }
    return {}
  }

  const facility_info_columns: any = [
    {
      title: "",
      render: () => t("business person"),
      width: 20,
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 4 }
        }
        return { rowSpan: 0 }
      },
    },
    {
      title: "",
      dataIndex: "label",
      width: "150px",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "",
      width: "335px",
      dataIndex: "value",
      render: (_, val) => {
        if (val?.isNumber) {
          return (
            <div id={"business_number"} style={{}}>
              {String(val?.value)
                ?.padEnd(10, ".")
                ?.split("")
                ?.map((v, idx) => (
                  <div className={"number"} key={idx}>
                    {v !== "." ? v : ""}
                  </div>
                ))}
            </div>
          )
        }
        return val?.value
      },
    },
  ]

  const facility_info_data: any[] = [
    {
      key: "1",
      label: t("Business no."),
      value: data?.facility?.business_number || "",
      isNumber: true,
    },
    {
      key: "2",
      label: t("Address (location)"),
      value: `${data?.facility?.address || ""}`,
    },
    {
      key: "3",
      label: t("Phone no."),
      value: data?.facility?.phone_no || "",
    },
    {
      key: "4",
      label: t("Official name"),
      value: data?.facility?.facility_name || "",
    },
  ]
  const today = moment().format("YYYY-MM-DD")

  const era = formatJaEra(
    wareki(`${data?.year}-${data?.month}`, {
      unit: true,
    })
  )

  return (
    <Wrapper>
      <Title>{t("List of user fees")}</Title>
      <DateInfo>{getWarekiDOB(today, true)}</DateInfo>
      <FacilityInfoContent>
        <div className={"print_facility_info_left"}>
          <div className={"print_facility_info_left--top"}>
            <div>{"（提供先）"}</div>
            <div>
              {getFacilityInfo()?.facility_name ??
                getFacilityInfo()?.facility_name_short}
            </div>
            <div>{t("As follow")}</div>
          </div>
          <div className={"print_facility_info_left--bottom"}></div>
        </div>
        <div className={"print_facility_info_right"}>
          <Table
            columns={facility_info_columns}
            dataSource={facility_info_data}
            pagination={false}
            showHeader={false}
          />
        </div>
      </FacilityInfoContent>
      <DateMonthInfo>
        <table id={"DateMonthInfo"}>
          <tbody>
            <tr>
              <td className={"w-large"}>{era?.split("").slice(0, 2)}</td>
              <td>{era?.split("").slice(2, 3)}</td>
              <td>{era?.split("").slice(3, 4)}</td>
              <td className={"w-large"}>{t("Year")}</td>
              {data?.month?.split("")?.map((v) => (
                <td key={v}>{v}</td>
              ))}
              <td className={"w-large"}>{t("Month")}</td>
            </tr>
          </tbody>
        </table>
      </DateMonthInfo>
      <PaymentDecisionColumn>
        <div id={"PaymentDecisionColumnTable"}>
          <Table
            columns={[
              {
                title: t("Item number"),
                dataIndex: "key",
                width: 50,
                align: "center",
              },
              {
                title: t("Payment Decision Disabled Persons Column"),
                align: "center",
                render: (row) => {
                  return (
                    <div id={"PaymentDecisionColumnInnerTable"}>
                      {/* //Provision city No.- Beneficiary number start */}
                      <div className={"inner__table__content"}>
                        <div className={"content"}>
                          <span className={"label"}>
                            {t("Provision city No.")}
                          </span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              {String(row?.city_number)
                                ?.padEnd(10, ".")
                                ?.split("")
                                ?.map((v, index) => (
                                  <div key={index} className={`value--content`}>
                                    <span
                                      className={`${
                                        v === "." ? "with__bg" : ""
                                      }`}
                                    >
                                      {v !== "." ? v : ""}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className={"content"}>
                          <span className={"label"}>
                            {t("Beneficiary number")}
                          </span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              {String(row?.certificate_number)
                                ?.padEnd(10, ".")
                                ?.split("")
                                ?.map((v, index) => (
                                  <div key={index} className={`value--content`}>
                                    <span
                                      className={`${
                                        v === "." ? "with__bg" : ""
                                      }`}
                                    >
                                      {v !== "." ? v : ""}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className={"content"}>
                          <span className={"label"}>{t("full name")}</span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              <div className={"value--content--large"}>
                                {row?.child_name}{" "}
                                {`(${row?.child_name_furigana})`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* //Provision city No.- Beneficiary number end */}

                      {/* //total cost- user burden  start*/}
                      <div className={"inner__table__content"}>
                        <div className={"content"}>
                          <span className={"label"}>{t("Total cost")}</span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              {String(Math.floor(row?.total_cost))
                                ?.padStart(6, ".")
                                ?.split("")
                                ?.map((v, index) => (
                                  <div key={index} className={"value--content"}>
                                    {v !== "." ? v : ""}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className={"content"}>
                          <span className={"label"}>{t("User burden")}</span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              {String(Math.floor(row?.user_burden))
                                ?.padStart(6, ".")
                                ?.split("")
                                ?.map((v, index) => (
                                  <div key={index} className={"value--content"}>
                                    {v !== "." ? v : ""}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className={"content"}>
                          <span className={"label"}>{t("")}</span>
                          <div className={"value__container"}>
                            <div className={"value"}>
                              {""
                                ?.padStart(6, ".")
                                ?.split("")
                                ?.map((v, index) => (
                                  <div key={index} className={"value--content"}>
                                    {v !== "." ? v : ""}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* //total cost- user burden end */}

                      {/* Service provided start */}
                      <div className={"service__provided"}>
                        <span
                          className={"service__provided--label"}
                          style={{ width: "1.5rem" }}
                        >
                          {t("Service provided")}
                        </span>
                        <div className={"service__provided--value--container"}>
                          <div className={"service__provided--value"}>
                            <div className={"code__container"}>
                              <div className={"code"}>
                                {row?.service_div ? row.service_div[0] : ""}
                              </div>
                              <div className={"code"}>
                                {row?.service_div ? row.service_div[1] : ""}
                              </div>
                            </div>
                            <div className={"name"}>
                              {SERVICE_TYPE[row?.service_div ?? 0]
                                ? SERVICE_TYPE[row.service_div]
                                : ""}
                            </div>
                          </div>
                          <div className={"service__provided--value"}>
                            <div className={"code__container"}>
                              <div className={"code"}></div>
                              <div className={"code"}></div>
                            </div>
                            <div className={"name"}></div>
                          </div>
                          <div className={"service__provided--value"}>
                            <div className={"code__container"}>
                              <div className={"code"}></div>
                              <div className={"code"}></div>
                            </div>
                            <div className={"name"}></div>
                          </div>
                        </div>
                      </div>
                      {/* Service provided end */}
                    </div>
                  )
                },
              },
            ]}
            dataSource={data?.list?.map((v, index) => ({
              ...v,
              key: index + 1,
            }))}
            pagination={false}
          />
        </div>
      </PaymentDecisionColumn>
    </Wrapper>
  )
}
