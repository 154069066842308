import { t } from "i18next"
import { Title } from "../../../PrintListContent.style"

export function KyotoSheetTitle() {
  return (
    <Title>
      {t(
        "User burden maximum amount management result sheet (Kyoto City style)"
      )}
    </Title>
  )
}
