import {
  CALANDER_HOURS_MINUTES,
  Checkboxes,
  PageCard,
  SelectInput,
  WEEK_DAYS,
  TimeRanges,
  theme,
} from "@project/shared"
import { Card, Col, DatePicker, Row, Typography } from "antd"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { OwnerButton } from "../../.."
import { useUpdateSearchParams } from "../../../../utils/useUpdateSearchParams"
import styled from "styled-components"

const { Meta } = Card

const Wrapper = styled.div`
  margin-top: 36px;

  .flex-100 {
    flex-basis: 100%;
  }
  .flex-class {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    @media (max-width: 640px) {
      align-items: flex-start;
    }
  }
  .meta-body {
    flex-basis: 100%;
    .ant-card-meta-detail {
      flex-basis: 100%;
    }
    .ant-card-meta-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #191919;
      position: relative;
      padding-left: 7px;
      border-left: 3px solid #0782c8;
      margin-bottom: 17px;
    }
    .ant-card-meta-description {
      .hours_container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .range {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  .flexbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #191919;
    width: 100%;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      gap: 0.2rem;
    }
  }

  .timerange-container {
    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
      > div {
        &.range1 {
          margin-right: 0.2rem;
        }
        &.range2 {
          margin-left: 0.2rem;
        }
        gap: 0px;
        > div {
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .bulk-hour-container {
    max-width: 1600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .each-bulk-hour {
      display: flex;
      gap: 5px;
      width: 33%;
      flex-wrap: wrap;
      align-items: center;
    }
    @media (max-width: 1100px) {
      .each-bulk-hour {
        max-width: 100%;
      }
    }
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      flex-direction: column;
      .each-bulk-hour {
        width: 100%;
      }
    }
  }

  .update-button-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #191919;
    @media (max-width: 516px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      .update-btn {
        margin-bottom: 18px;
      }
    }
  }
  .facility-select {
    @media (max-width: 640px) {
      margin-top: 3px;
    }
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
    min-width: 50px;
  }
  .pg-sp {
    margin-bottom: 38px;
  }
  .mb-sp {
    margin-bottom: 20px;
  }
  .business-tag {
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-right: 0px !important;
      margin-left: 4px;
    }
  }

  .mt-0 {
    margin-top: 0;
  }
  .border-sp {
    border-bottom: 1px dashed #d2d1d1;
    padding-bottom: 10px;
  }
  .ant-col {
    margin-top: auto;
    margin-bottom: auto;
  }
  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    margin-top: 6px;
    margin-bottom: 0;
  }
  .flex-time-fields {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 5px;
  }
  @media (max-width: 1387px) {
    .col-breaker {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .ant-col-2 {
      min-width: 130px;
      @media (max-width: 519px) {
        min-width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .overwrite-para {
    margin-top: 26px;
    span {
      color: #e00000;
    }
  }
  .override-facilities-checkboxes {
    display: block;
    width: 100%;
  }

  .select-wrap {
    display: flex;
    margin-top: 3px;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      margin-bottom: 20px;
    }
    .select-down {
      margin-left: 15px;
    }
  }

  .overflow-text {
    @media (max-width: 380px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .mb-flex-row {
    display: flex;
    @media (max-width: 380px) {
      display: flex;
    }
    .last-select {
      margin-left: 10px;
    }
  }

  .occupation-subject {
    @media (max-width: 380px) {
      margin-left: 30px;
    }
  }

  .radio-btn {
    @media (max-width: 429px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      white-space: pre;
      align-items: flex-start;
    }
  }

  .mobile-width-100 {
    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .width-100 {
    @media (max-width: 700px) {
      min-width: 100%;
    }
  }

  .ant-row {
    @media (max-width: 640px) {
      flex-flow: column !important;
    }
  }
  @media (max-width: 375px) {
    .ant-card-body {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  @media (max-width: 1200px) {
    .mobile-full-width {
      max-width: 100%;
    }
    .mobile-direction-column {
      flex-direction: column;
    }
    .mobile-single-line {
      flex-direction: unset !important;
      min-width: 100%;
      .ant-col-1 {
        min-width: 10%;
      }
      .ant-col-2 {
        min-width: 18%;
      }
      .ant-col-3 {
        min-width: 30%;
      }
    }
  }
  .error-field {
    border: 1px solid ${theme.red2} !important;
  }
  .ant-checkbox-group {
    @media (max-width: 854px) {
      padding-left: 3px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    .business-reg-card {
      .ant-card-head-wrapper {
        .ant-card-head-title,
        .ant-card-extra span {
          font-size: 14px;
        }
      }
    }
  }
`

export const OperationOption = ({
  BUSINESS_REGISTRATION_FACILITY,
  onSubmit,
  applyBulkSetting,
  filterOptions,
}: {
  BUSINESS_REGISTRATION_FACILITY: any
  onSubmit: (value: any) => void
  applyBulkSetting: (values: any) => void
  filterOptions: {
    year: number
    facility: number
  }
}) => {
  const { t } = useTranslation()

  const intialHours = {
    from: {
      hours: "",
      minutes: "",
    },
    to: {
      hours: "",
      minutes: "",
    },
  }

  const [tempFilterOptions, setTempFilterOptions] = useState(filterOptions)

  useEffect(() => {
    setTempFilterOptions(filterOptions)
  }, [filterOptions])

  const [regularHolidays, setRegularHolidays] = useState(null)
  const [workBusinessDays, setWorkBusinessDays] = useState(null)
  const [businessHours, setbusinessHours] = useState([
    {
      id: 1,
      ...intialHours,
    },
    {
      id: 2,
      ...intialHours,
    },
    {
      id: 3,
      ...intialHours,
    },
  ])
  const [serviceHours, setServiceHours] = useState([
    {
      id: 1,
      ...intialHours,
    },
    {
      id: 2,
      ...intialHours,
    },
    {
      id: 3,
      ...intialHours,
    },
  ])

  const handleDateChange = (year: number) => {
    return moment({ year: +year })
  }

  const onInputChange = ({ value, id, range, unit, previousData, setter }) => {
    const data = [...previousData]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      [range]: {
        ...data[index][range],
        [unit]: value,
      },
    }
    if (unit == "hours") {
      if (!data[index][range]?.minutes) {
        data[index][range].minutes = "00"
      } else if (!value) {
        data[index][range].minutes = ""
      }
    }
    setter(data)
  }

  const reflectCalendarTimeRanges = () => {
    applyBulkSetting({
      regular_holidays: regularHolidays || [],
      work_business_days: workBusinessDays || [],
      business_hours: businessHours,
      service_hours: serviceHours,
    })
  }
  const [updateParams] = useUpdateSearchParams()

  return (
    <Wrapper>
      <PageCard title={t("Operation options")}>
        <Meta
          title={t("Display settings")}
          className={"meta-body"}
          description={
            <>
              <div className={"flexbox mb-sp"}>
                <Row className={"flex-100"}>
                  <Col span={3} className={"caret-right"}>
                    {t("Facility")}
                  </Col>
                  <Col span={21}>
                    <SelectInput
                      className={
                        "facility-select dynamic-dropdown-width min-w-150"
                      }
                      width={"auto"}
                      height={40}
                      dropdownMatchSelectWidth={false}
                      placeholder={t("All")}
                      options={BUSINESS_REGISTRATION_FACILITY}
                      onChange={(e) =>
                        setTempFilterOptions({ ...filterOptions, facility: e })
                      }
                      value={+tempFilterOptions?.facility}
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp"}>
                <Row className={"flex-100"}>
                  <Col span={3} className={"caret-right"}>
                    {t("Year")}
                  </Col>
                  <Col span={21}>
                    <div className={"update-button-wrapper"}>
                      <div className={"select-wrap"}>
                        <DatePicker
                          value={
                            +tempFilterOptions?.year
                              ? handleDateChange(+tempFilterOptions?.year)
                              : null
                          }
                          style={{
                            height: "40px",
                          }}
                          picker={"year"}
                          format={"YYYY年"}
                          onChange={(value) => {
                            setTempFilterOptions({
                              ...tempFilterOptions,
                              year: +moment(value).format("YYYY"),
                            })
                          }}
                        />
                      </div>
                      <OwnerButton
                        className={"update-btn"}
                        typeOf={"secondary"}
                        text={t("Update display")}
                        onClick={() => {
                          onSubmit({
                            year: tempFilterOptions?.year,
                            facility: tempFilterOptions?.facility,
                          })

                          updateParams(
                            {
                              facilityId: tempFilterOptions?.facility,
                              year: tempFilterOptions?.year,
                            },
                            "/bulk-business-day"
                          )
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          }
        />
        <Meta
          title={t("Bulk setting")}
          className={"meta-body"}
          description={
            <>
              <div className={"flexbox mb-sp border-sp"}>
                <Row className={"flex-100"}>
                  <Col span={4} className={"caret-right mobile-single-line"}>
                    {t("Regular Holidays")}
                  </Col>
                  <Col span={20} className={"mobile-full-width"}>
                    <Checkboxes
                      options={WEEK_DAYS?.map((val) => ({
                        ...val,
                        disabled: workBusinessDays?.includes(val?.value),
                      }))}
                      onChange={(e) => setRegularHolidays(e)}
                      value={regularHolidays}
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <Row className={"flex-100"}>
                  <Col span={4} className={"caret-right mobile-single-line"}>
                    {t("Business Days")}
                  </Col>
                  <Col span={20} className={"mobile-full-width"}>
                    <Checkboxes
                      options={WEEK_DAYS?.map((val) => ({
                        ...val,
                        disabled: regularHolidays?.includes(val?.value),
                      }))}
                      onChange={(e) => setWorkBusinessDays(e)}
                      value={workBusinessDays}
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <div className={"bulk-hour-container"}>
                  {businessHours?.map((v, i) => (
                    <Fragment key={i}>
                      <div className={"each-bulk-hour"}>
                        <div className={"caret-right bulk-hour-label"}>
                          <Typography.Text>
                            <>
                              {t("Business hours")}
                              {i + 1}
                            </>
                          </Typography.Text>
                        </div>
                        <div>
                          <div className={"range flexbox timerange-container"}>
                            <TimeRanges
                              className={"range1"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"from"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "from",
                                  unit,
                                  setter: setbusinessHours,
                                  previousData: businessHours,
                                })
                              }
                              values={v?.from?.hours + ":" + v?.from?.minutes}
                            />
                            {"〜"}
                            <TimeRanges
                              className={"range2"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"to"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "to",
                                  unit,
                                  setter: setbusinessHours,
                                  previousData: businessHours,
                                })
                              }
                              values={v?.to?.hours + ":" + v?.to?.minutes}
                            />
                          </div>
                        </div>
                        <Col className={"col-breaker"} />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <div className={"bulk-hour-container"}>
                  {serviceHours?.map((v, i) => (
                    <Fragment key={i}>
                      <div className={"each-bulk-hour"}>
                        <div className={"caret-right bulk-hour-label"}>
                          <Typography.Text>
                            <>
                              {t("Service hours")}
                              {i + 1}
                            </>
                          </Typography.Text>
                        </div>
                        <div>
                          <div className={"range flexbox timerange-container"}>
                            <TimeRanges
                              className={"range1"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"from"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "from",
                                  unit,
                                  setter: setServiceHours,
                                  previousData: serviceHours,
                                })
                              }
                              values={v?.from?.hours + ":" + v?.from?.minutes}
                            />
                            {"〜"}
                            <TimeRanges
                              className={"range2"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"to"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "to",
                                  unit,
                                  setter: setServiceHours,
                                  previousData: serviceHours,
                                })
                              }
                              values={v?.to?.hours + ":" + v?.to?.minutes}
                            />
                          </div>
                        </div>
                        <Col className={"col-breaker"} />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>

              <OwnerButton
                type={"button"}
                onClick={reflectCalendarTimeRanges}
                text={t("Reflect")}
                typeOf={"secondary"}
              />
            </>
          }
        />
      </PageCard>
    </Wrapper>
  )
}
