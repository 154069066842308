import React, { useContext, useEffect, useRef, useState } from "react"
import {
  AttendanceTable as SharedTable,
  SelectInput,
  theme,
} from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { PrintButtonComponent, LabelsComponent } from "../../organisms"
import { OwnerButton } from "../../atoms"
import router from "next/router"
import { AuthContext } from "../../../utils"
import { useReactToPrint } from "react-to-print"
const Icon = <img src={"/assets/icons/Rectangle.svg"} alt={"icon"} />
const IconG = <img src={"/assets/icons/grayrect.svg"} alt={"icon"} />

interface ITablecomponentProps {
  dataSource: any
  columns: any
  total?: number
  pageSize?: number
  currentPage?: number
  handlePaginationChange?: (a: number) => void
  loading?: boolean
  scroll?: { x?: string | number | true } | Record<string, never>
  bordered?: boolean
  displayInfo?: boolean
  displayInfoNumbers?: number
  displayPrintButton?: boolean
  buttonText?: string
  additionalButton?: boolean
  onClickAdditionalButton?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  displayText?: string
  displayLabels?: boolean
  displayLabelsData?: any
  stats?: any
  footer?: boolean
  date?: string
  facility_ids?: Array<any>
  fitleredColumns?: any
  pageTitle?: string | undefined
}

const Wrapper = styled.div<ITablecomponentProps>`
  .main-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: ${(props) => (props.displayInfo ? "flex-start" : "center")};
    padding: 0px 5px;
    @media (max-width: 767px) {
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .to-input {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .bottom-pagination-wrapper {
    margin-top: 30px;
    padding: 0px;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin-bottom: 5px;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .button-wrap {
      display: flex;
      .add-child-flex-btn {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
          margin-right: 8px;
        }
      }

      .mr-20 {
        margin-right: 20px;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        .select-class {
          min-width: 200px !important;
        }
      }
    }

    .delete-button {
      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
  }
  .add-btn-wrap {
    margin-bottom: 20px;
  }

  @media print {
    .hide-on-print {
      display: none;
    }
    .stats_sections {
      margin: 0 !important;
    }
  }
`
const FilterWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  color: #191919;
  display: flex;
  margin-bottom: 14px;
  @media (max-width: 572px) {
    flex-direction: column;
  }
  .last-item {
    margin-left: 15px;
  }
  @media print {
    margin-bottom: 0 !important;
    span {
      display: none !important;
    }
  }
`

const AttendanceTablecomponent: React.FC<ITablecomponentProps> = (props) => {
  const { t } = useTranslation()
  const addFacilityId = useRef(null)
  const {
    dataSource,
    columns,
    loading,
    scroll,
    bordered,
    displayInfo,
    displayInfoNumbers,
    displayText,
    buttonText,
    additionalButton,
    displayPrintButton,
    stats,
    footer,
    date,
    facility_ids,
    onClickAdditionalButton,
    fitleredColumns,
    pageTitle,
  } = props
  const { facilities } = useContext(AuthContext)

  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })

  const contentToPrint = useRef(null)
  const [printing, setprinting] = useState({
    isPrinting: false,
    resolve: undefined,
  })
  const getDeleteFormLink = () => {
    const link = `/user-attendance-table/delete-form?`
    const queries = []
    if (facility_ids?.length) queries.push("facility_id=" + facility_ids)
    if (date) queries.push("date=" + date)
    return link + queries.join("&")
  }
  useEffect(() => {
    const { resolve, ...otherState } = printing
    if (resolve) {
      resolve()
      setprinting({ ...otherState, resolve: undefined })
    }
  }, [printing])
  const onPrint = useReactToPrint({
    content: () => contentToPrint?.current,
    onBeforeGetContent: () => {
      return new Promise((resolve: any) => {
        setprinting({
          isPrinting: true,
          resolve,
        })
      })
    },
    pageStyle: `@media print {
      *{
       -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact !important; /* Firefox 48 – 96 */
      print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
      }
    @page {
      size: landscape !important;
      margin:10mm 5mm !important;
      margin-left: 0.3in !important;
      margin-right: 0.3in !important;
      margin-top: 0.5in !important;
      margin-bottom: 0 !important;
    }
    .ant-table-content {
      overflow: visible !important;
      transform: none !important;
    }
     table {
      width: 100% !important;
    }
   table>tbody>tr>td{
      border:1px solid #D2D1D1 !important;
    }

    table>tbody>tr>td{
      border-top:0 !important;
    }
    table>thead>tr>th{
      padding:5px 2px !important;
      outline:none !important;
      max-width:50px !important;
    }
    table>thead>tr>th.id-container{
     min-width:30px !important;
    }
    table>thead>tr>th>span{
    font-size: 10px !important;
    break-work:break-all !important;
    }
    table>tbody>tr>td{
      font-size: 10px !important;
     padding:0 3px !important;
    }
    table>tbody>tr.ant-table-placeholder>td{
      padding: 16px 16px !important;
    }
    table>tbody>tr>td>a,table>tbody>tr>td>div,table>tbody>tr>td>span{
       font-size: 12px !important;
    }
     table>tbody>tr>td>div>button{
      display:none !important;

     }
  }
    `,
  })

  return (
    <Wrapper {...props}>
      <div className={"main-pagination-wrapper"}></div>
      {displayPrintButton && <PrintButtonComponent onPrint={onPrint} />}
      {!loading && stats && <LabelsComponent stats={stats} />}
      <div>
        {additionalButton && (
          <div className={"add-btn-wrap hide-on-print"}>
            <OwnerButton
              typeOf={"primary"}
              text={
                buttonText ||
                t("Service provision record/Care record/Life record")
              }
              onClick={onClickAdditionalButton}
            />
          </div>
        )}
        <div className={"info-wrapper"}>
          {displayInfo && (
            <div style={{ display: "flex", gap: "12px" }}>
              <FilterWrapper>
                <span>
                  {Icon}
                  &nbsp;
                  {t(
                    displayText ||
                      "Children who do not have a registered recipient certificate"
                  )}
                </span>
                {displayInfoNumbers > 1 && (
                  <span className={"last-item"}>
                    {t("Children without full facility contracts")}
                  </span>
                )}
              </FilterWrapper>
              <FilterWrapper>
                <span>
                  {IconG}
                  &nbsp;
                  {t("Cancel waiting")}
                </span>
              </FilterWrapper>
            </div>
          )}
        </div>
        <SharedTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={scroll || {}}
          bordered={bordered ? true : false}
        />
      </div>
      {footer ? (
        <div
          className={
            "main-pagination-wrapper bottom-pagination-wrapper hide-on-print"
          }
        >
          <div className={"button-wrap"}>
            <SelectInput
              width={"auto"}
              height={40}
              className={"select-class dynamic-dropdown-width min-w-150 mr-20"}
              options={FACILITY_OPTION}
              dropdownMatchSelectWidth={false}
              defaultValue={"----"}
              placeholder={t("All")}
              onChange={(val) => {
                addFacilityId.current = val
              }}
            />
            <div className={"add-child-flex-btn"}>
              <p>{t("To")}</p>
              <OwnerButton
                typeOf={"outlined"}
                className={"reset-button"}
                icon={"add"}
                text={t("Add child")}
                backgroundColor={"#fff"}
                onClick={() => {
                  if (addFacilityId.current)
                    router.push({
                      pathname: `/user-attendance-table/add/${addFacilityId.current}`,
                      query: `date=${date}`,
                    })
                }}
              />
            </div>
          </div>
          <div>
            <OwnerButton
              typeOf={"secondary"}
              className={"delete-button"}
              text={t("Go to delete form")}
              onClick={() => {
                router.push(getDeleteFormLink())
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* note this is for only print */}
      {printing?.isPrinting && (
        <div style={{ display: "none" }}>
          <div ref={contentToPrint}>
            <div
              style={{
                padding: "0px",
                color: theme.black,
                fontWeight: "bold",
              }}
            >
              {pageTitle}
            </div>
            {!loading && stats && <LabelsComponent stats={stats} />}
            <div>
              {additionalButton && (
                <div className={"add-btn-wrap hide-on-print"}>
                  <OwnerButton
                    typeOf={"primary"}
                    text={
                      buttonText ||
                      t("Service provision record/Care record/Life record")
                    }
                    onClick={onClickAdditionalButton}
                  />
                </div>
              )}
              <div className={"info-wrapper"}>
                {displayInfo && (
                  <FilterWrapper>
                    <span>
                      {Icon}
                      &nbsp;
                      {t(
                        displayText ||
                          "Children who do not have a registered recipient certificate"
                      )}
                    </span>
                    {displayInfoNumbers > 1 && (
                      <span className={"last-item"}>
                        {t("Children without full facility contracts")}
                      </span>
                    )}
                  </FilterWrapper>
                )}
              </div>
              <SharedTable
                dataSource={dataSource}
                columns={fitleredColumns?.filter(
                  (val) => val?.key !== "action"
                )}
                loading={loading}
                scroll={scroll || {}}
                bordered={bordered ? true : false}
              />
              {dataSource?.length <= 0 && (
                <p
                  style={{
                    textAlign: "center",
                    position: "relative",
                    marginTop: "-50px",
                  }}
                >
                  {t("No Data Found")}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export { AttendanceTablecomponent }
