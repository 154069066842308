import React, { useMemo, useState } from "react"
import { TextField } from "@project/shared"
import { Select } from "antd"
import { useQuery } from "react-query"
import styled from "styled-components"
import {
  getAllPrograms,
  getPrograms,
} from "../../../services/monthlyActivities"
import { OwnerButton } from "../../atoms"
import { t } from "i18next"

interface IActivitiesFormProps {
  uid: string
  data: any
  dateId: string
  handleProgramTextChange: any
  handleAddActivity: any
  handleProgramCategoryIdChange: any
  handleDeleteActivity: any
  allowDelete?: boolean
  programCategoriesData: any
  isProgramCategoryLoading: boolean
}

const Wrapper = styled.div<{ isValueOnSelectField?: boolean }>`
  display: flex;
  margin: 15px 0;
  @media (max-width: 616px) {
    margin: 1px 0;
  }
  .fields-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .input-field {
      display: flex;
    }
  }
  .select {
    width: 150px;
    height: 100%;
    margin-right: 10px;
    .ant-select-selector {
      height: 40px !important;
      border-radius: 5px;
      align-items: center;
    }
    .select:not(.select-small) {
      max-width: calc(100% - 40px);
    }
  }
  .select-small {
    width: 30px;
    .ant-select-selection-item {
      display: none;
    }
    .ant-select-arrow {
      top: 54% !important;
      right: 8px !important;
    }
  }
  .program-text {
    flex: 1;
    input {
      height: 40px;
      background: white;
    }
  }
  .button-wrapper {
    margin: auto;
    .calender-delete-button {
      background: none;
      border: none;
      color: #0782c8;
      text-decoration: underline;
      margin: 0px 10px;
      width: 100%;
      padding: 0 4px;
    }
    .calender-add-button {
      min-width: 60px;
      padding: 8px 12px;
    }
  }
  @media (max-width: 858px) {
    .fields-wrapper {
      gap: 10px;
    }
  }
  @media (max-width: 819px) {
    .select-large {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 1064px) {
    flex-direction: column;
    .fields-wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5px;
      .selector-wrapper {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .select-large {
          flex: auto;
          margin-right: 3px !important;
        }
        .select-small {
          flex: 0.5;
          margin-right: 0px !important;
          .ant-select-arrow {
            left: 25%;
            right: 25%;
          }
        }
      }
      .input-field {
        width: 100%;
      }
      .program-text {
        width: 100%;
      }
    }
  }
`

const ActivitiesForm: React.FC<IActivitiesFormProps> = ({
  uid,
  data,
  dateId,
  handleProgramTextChange,
  handleAddActivity,
  handleDeleteActivity,
  handleProgramCategoryIdChange,
  programCategoriesData,
  allowDelete = false,
  isProgramCategoryLoading,
}) => {
  const [isValue, setIsValue] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  addEventListener("resize", () => {
    setWidth(window.innerWidth)
  })
  const { data: allPrograms, isLoading: isProgramDataLoadings } = useQuery<any>(
    ["getAllPrograms"],
    getAllPrograms,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  )
  const { data: programData, isLoading: isProgramDataLoading } = useQuery<any>(
    ["programData", data?.program_id],
    getPrograms,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!data?.program_id,
    }
  )

  const defaultCategory = {
    label: t("Activity category"),
    value: 0,
  }

  const PROGRAM_CATEGORY_OPTIONS = useMemo(() => {
    const programCategories = programCategoriesData.map((each) => ({
      label: each.program_category_name,
      value: each.id,
    }))

    // Add Default Category
    programCategories.unshift(defaultCategory)
    return programCategories
  }, [programCategoriesData])

  const PROGRAM_OPTIONS = useMemo(() => {
    return programData?.data?.map((option) => {
      return {
        label: option.program_name,
        value: option.id,
      }
    })
  }, [programData?.data])

  const ALL_PROGRAM_OPTIONS = useMemo(() => {
    return (allPrograms?.data || ([] as any))?.map((option: any) => {
      return {
        label: option.program_name,
        value: option.id,
      }
    })
  }, [allPrograms?.data])

  const getLabelFromValue = (value): string => {
    let _value = undefined
    ALL_PROGRAM_OPTIONS.forEach((category) => {
      if (category.value === value) {
        _value = category.label
      }
    })
    return _value
  }

  return (
    <Wrapper isValueOnSelectField={isValue}>
      <div className={"fields-wrapper"}>
        <div className={"selector-wrapper"}>
          <Select
            loading={isProgramCategoryLoading}
            options={PROGRAM_CATEGORY_OPTIONS || []}
            value={+data.program_id ? data.program_id : 0}
            popupClassName={"select-programs-category-popup"}
            className={"select select-large"}
            onChange={(value) => {
              handleProgramTextChange(dateId, uid, "program_id", value)
              setIsValue(true)
            }}
            dropdownMatchSelectWidth={false}
          />
        </div>
        <div className={"input-field"}>
          <Select
            options={PROGRAM_OPTIONS || ALL_PROGRAM_OPTIONS || []}
            loading={isProgramDataLoading || isProgramDataLoadings}
            onChange={(value) => {
              handleProgramCategoryIdChange(dateId, uid, {
                label: getLabelFromValue(value),
                id: value,
              })
            }}
            value={data.program_category_id}
            popupClassName={"select-small-popup"}
            className={"select select-small"}
            placement={width <= 616 ? "topRight" : "topLeft"}
            dropdownMatchSelectWidth={false}
          />
          <TextField
            value={data.program_text}
            onChange={(e) =>
              handleProgramTextChange(
                dateId,
                uid,
                "program_text",
                e.target.value
              )
            }
            className={"program-text"}
          />
        </div>
      </div>
      <div className={"button-wrapper"}>
        {allowDelete ? (
          <OwnerButton
            text={t("delete")}
            onClick={() => handleDeleteActivity(dateId, uid)}
            className={"calender-delete-button"}
          />
        ) : (
          <OwnerButton
            text={t("add")}
            onClick={() => handleAddActivity(dateId)}
            typeOf={"secondary"}
            className={"calender-add-button"}
          />
        )}
      </div>
    </Wrapper>
  )
}

export { ActivitiesForm }
