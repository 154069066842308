import { API } from "@project/shared"

const BASE_ENDPOINT = "program-record"

export interface IReturnProgramRecord {
  count?: number
  data?: any
}

interface IProgramRecordSearchParam {
  page?: string
  pageSize?: string
  from_date?: string
  to_date?: string
  facility_ids?: string
}

interface ICreateProgramRecordParam {
  article_title?: string
  date?: string
  facility_id?: number
  article_content_1?: string
  article_content_img_1?: string
  article_content_2?: string
  article_content_img_2?: string
  article_content_3?: string
  article_content_img_3?: string
  article_content_4?: string
  article_content_img_4?: string
  article_content_5?: string
  article_content_img_5?: string
  article_content_6?: string
  article_content_img_6?: string
  article_content_7?: string
  article_content_img_7?: string
  article_content_8?: string
  article_content_img_8?: string
  article_content_9?: string
  article_content_img_9?: string
  article_content_10?: string
  article_content_img_10?: string
  release_flag?: boolean
}
export const fetchProgramRecord = async ({
  queryKey,
}): Promise<IReturnProgramRecord> => {
  const params = {} as IProgramRecordSearchParam
  params.page = queryKey[1]
  params.pageSize = "20"
  params.facility_ids = queryKey[2]?.facility
  params.from_date = queryKey[2]?.from_date || null
  params.to_date = queryKey[2]?.to_date || null

  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}

export const getProgramRecord = async (id: number) => {
  return API.get(`/${BASE_ENDPOINT}/${id}`)
}

export const createProgramRecord = async (
  values
): Promise<ICreateProgramRecordParam> => {
  delete values.id
  return API.post(`/${BASE_ENDPOINT}`, values)
}

export const updateProgramRecord = async (
  values
): Promise<ICreateProgramRecordParam> => {
  const id = values.id
  delete values.id
  return API.put(`/${BASE_ENDPOINT}/${id}`, values)
}

export const releaseProgramRecord = (id) => {
  return API.patch(`${BASE_ENDPOINT}/${id}/release`)
}

export const deleteProgramRecord = (id) => {
  return API.delete(`${BASE_ENDPOINT}/${id}`)
}
