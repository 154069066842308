import React, { useEffect, useState } from "react"
import { Upload, message, notification, Button } from "antd"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { CloseCircleFilled, DeleteOutlined } from "@ant-design/icons"
import { fileUpload } from "@project/shared/services"
import { RcFile } from "antd/lib/upload"
import { Wrapper, UploadButtonWrapper } from "./DailyBusinessReport.styles"
interface UploadProps {
  onChange?: (value?: any) => void
  handleDelete?: () => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentFile?: string
  fileName?: string
}
export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const handleDownload = (fileUrl, fileName) => {
  const link = document.createElement("a")
  link.href = fileUrl
  link.download =
    fileName || `easyy-program-record_download_img_${new Date().getTime()}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const DailyBusinessReportUpload: React.FC<UploadProps> = ({
  onChange,
  isLoading,
  currentFile,
  fileName,
  handleDelete,
}) => {
  const [file, setFile] = useState(null)
  const { t } = useTranslation()
  useEffect(() => {
    if (currentFile != "") {
      setFile(currentFile)
    }
  }, [currentFile])
  const { isLoading: uploadLoading } = useMutation("fileUpload", fileUpload, {
    onSuccess: (result) => {
      isLoading(false)
      onChange && onChange(result?.data)
      setFile(result?.data)
    },
    onError: (error?: any) => {
      isLoading(false)
      const msg = error?.data?.error?.message
      notification.error({
        message: msg ? t(`${msg}`) : t("An error has occurred"),
      })
    },
  })
  const beforeUpload = (file: any) => {
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC/PDF/WORD/EXCEL image with less than 10MB"
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
      return false
    }
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/heic"

    // it should also accept excel and word file
    const isDoc =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    if (isJpgOrPng || isDoc) {
      return true
    }

    message.error({
      content: t("You can only upload JPG/PNG/GIF/HEIC, Word or Excel file!"),
      key: "38",
      icon: <CloseCircleFilled onClick={() => message.destroy("38")} />,
    })
    return false
  }
  return (
    <Wrapper>
      {!file && (
        <>
          <UploadButtonWrapper>
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={onChange}
              maxCount={1}
              accept={".jpg,.gif,.png,.heic,.pdf,.doc,.docx,.xls,.xlsx"}
              customRequest={({ onSuccess }) => {
                onSuccess("ok")
              }}
            >
              <Button
                style={{
                  width: "130px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "#888787",
                }}
              >
                {uploadLoading ? t("アップロード") : t("Upload File")}
              </Button>
            </Upload>
            <div className={"file-area"}>
              <p className={"space"}>{t("File not selected")}</p>
            </div>
          </UploadButtonWrapper>
          <p className={"upload-hint"}>
            {t("(Image: jpg,gif,png,HEIC ／PDF、Word、Excel up to 10 Mb)")}
          </p>
        </>
      )}
      {file && (
        <div className={"upload-area"}>
          <span>{fileName || t("Uploaded File")}</span>
          <DeleteOutlined
            className={"delete-icon"}
            onClick={() => {
              setFile(null)
              handleDelete()
            }}
          />
        </div>
      )}
    </Wrapper>
  )
}
export { DailyBusinessReportUpload }
