import { Grid, PageCard, ACCOUNT_TYPE } from "@project/shared"
import { Skeleton } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getParent } from "../../../services"
import { Divisor, FormTitle, StyledForm } from "../../../utils"
import { OwnerButton } from "../../atoms"

interface iParent {
  id?: string | string[]
}
const Wrapper = styled.div`
  .button-wrap {
    margin-top: 30px;
    display: flex;
    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #000000;
  }
  .quater-field {
    width: 75%;
    input {
      width: 100%;
    }
  }
  @media (max-width: 1050px) {
    .button-wrap {
      flex-direction: column;
      button {
        :first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
    .quater-field {
      width: 100%;
    }
  }
  @media (max-width: 874px) {
    .styled-form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .grid-header {
      width: 100%;
    }
    .grid-header ~ div:not(.grid-header) {
      padding-left: 0;
      padding-right: 0;
      border-right: 0;
      width: 100%;
    }
  }
  .under-line {
    text-decoration: underline;
  }
`

const DisplayText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #191919;
  .notifi-setting {
    margin-right: 20px;
  }
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ParentDetailComponent: React.FC<iParent> = ({ id }) => {
  const { t } = useTranslation()
  const {
    data: parentData,

    isLoading: parentDataLoading,
    isFetching: parentDataFetching,
  } = useQuery<any, Error>(["parent", id], () => id && getParent(Number(id)), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: ({ data }) => {
      return data
    },
  })

  return (
    <Wrapper>
      <PageCard title={t("Parents information")} style={{ marginTop: "22px" }}>
        {parentDataLoading || parentDataFetching ? (
          <StyledForm>
            <Skeleton />
          </StyledForm>
        ) : (
          <StyledForm className={"styled-form"}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Parent name")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.parent_name}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Hiragana")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.parent_name_furigana}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Po box")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.zip_code}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Pref. city. street")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.address}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Phone number")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText className={"under-line"}>
                <a href={`tel:${parentData?.tel_no}`}>{parentData?.tel_no}</a>
              </DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Child")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {parentData?.children &&
                parentData?.children.map((child: any, index: number) => {
                  return (
                    <FlexBox key={index} style={{ paddingTop: "0.5rem" }}>
                      <DisplayText className={"under-line"}>
                        <a
                          href={`/child/profile/${child?.id}`}
                          target={"_blank"}
                        >
                          <a> {child?.child_name}</a>
                        </a>
                      </DisplayText>

                      {index === 0 && (
                        <OwnerButton
                          typeOf={"secondary"}
                          text={t("児童を追加する")}
                          onClick={() => window.open("/child/add", "_blank")}
                        />
                      )}
                    </FlexBox>
                  )
                })}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Relation with child")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.child_reretion}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Remarks")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DisplayText>{parentData?.parent_remaks}</DisplayText>
            </Grid>
            <Grid className={"flex-break"} />
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Emergency contact list")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact ph num 1")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText className={"under-line"}>
                  <a href={`tel:${parentData?.emergency_tel_no1}`}>
                    {parentData?.emergency_tel_no1}
                  </a>
                </DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Emergency contact 1")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.emergency_contact1}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact ph num 2")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText className={"under-line"}>
                  <a href={`tel:${parentData?.emergency_tel_no2}`}>
                    {parentData?.emergency_tel_no2}
                  </a>
                </DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Emergency contact 2")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.emergency_contact2}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact remarks")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.emergency_remarks}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Account information")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Financial institution name Kana")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>
                  {parentData?.financial_institution_name}
                </DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Branch name Kana")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.branch_name_katakana}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Financial institute code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.bank_no}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Branch code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.branch_no}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account Type")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>
                  {t(
                    ACCOUNT_TYPE.find(
                      (data) => data.value === parentData?.account_type
                    )?.label
                  )}
                </DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account Number")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.account_number}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account holder")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.account_holder_name}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Customer code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.customer_number}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle style={{ marginTop: "43px" }}>
              {t("Email notification setting")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Email Address")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.mail_address}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Email address(If multiple)")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>{parentData?.mail_address_multi}</DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Email notification settings")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <DisplayText>
                  <span className={"notifi-setting"}>
                    {parentData?.mail_receive_noti_enter_flag && t("Enter")}
                  </span>
                  <span className={"notifi-setting"}>
                    {parentData?.mail_receive_noti_leave_flag && t("Exit")}
                  </span>
                  <span className={"notifi-setting"}>
                    {parentData?.mail_receive_noti_pick_flag && t("Pick up")}
                  </span>
                  <span className={"notifi-setting"}>
                    {parentData?.mail_receive_noti_up_flag && t("Pick off")}
                  </span>
                </DisplayText>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
          </StyledForm>
        )}
      </PageCard>
    </Wrapper>
  )
}

export { ParentDetailComponent }
