import { API } from "@project/shared"
interface ServiceContentCheckResquest {
  category_id: number
  name: string
  category_info?: any
  StopDate?: string
  OrderNo?: number
}
interface Response {
  id: number
  name: string
  created_datetime?: string
  created_by?: string
  updated_datetime?: string
  updated_by?: string
  deleted_datetime: null
  deleted_by?: string
  is_deleted?: boolean
  category_id?: number
  category_info?: any
}
interface ServiceContentCheckResponse {
  count: number
  data: Response[]
}

export const fetchServiceProvisionsCategoryList = async () => {
  return API.get(`/service-content-category`)
}

export const createServiceProvisionCheckItem = async (
  values: ServiceContentCheckResquest
) => API.post("/service-content-check", { ...values })

export const GetServiceProvisionCheckItems = async ({
  page,
  pageSize,
  categoryId,
}: {
  page: number
  pageSize: number
  categoryId?: number
}): Promise<ServiceContentCheckResponse> =>
  API.get(
    `/service-content-check?page=${page}&pageSize=${pageSize}&${
      categoryId !== undefined && `categoryId=${categoryId}`
    }`
  )

export const GetServiceProvisionCheckItem = async (id: string) =>
  API.get(`/service-content-check/${id}`)

export const updateServiceProvisionCheckItem = async ({
  values,
  id,
}: {
  values: ServiceContentCheckResquest
  id: string
}) => API.put(`/service-content-check/${id}`, { ...values })

export const deleteServiceProvisionCheckItem = async (id: string) =>
  API.delete(`/service-content-check/${id}`)
