import { theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import { OwnerButton } from "../../atoms"
import styled from "styled-components"
import { RecordRewriteChildConfirmationTable } from "../../molecules/RecordRewriteChildConfirmationTable"
import { UsageRecordConfirmationProps } from "../UsageRecordRewriteFormPage/types"

const ChildConfirmationTableContainer = styled.div`
  margin: 20px 0;
  padding: 15px 18px;
  background: #ffffff;
  border-radius: 12px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    .back-button {
      background: rgba(7, 130, 200, 0.2);
      border: 1px solid ${theme.blue5};
      color: #000;
    }
    .confirm-button {
      background: #f04c22;
      border: none;
    }
  }
  .info-alert {
    font-size: 12px;
  }
`

export const UsageRecordRewriteConfirmationPage = ({
  isSaving = false,
  confirmationData,
  handleBack,
  handleSave,
}: UsageRecordConfirmationProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ChildConfirmationTableContainer>
        {confirmationData &&
          Object.keys(confirmationData)?.map((key) => (
            <RecordRewriteChildConfirmationTable
              key={key}
              confirmationData={confirmationData[key]}
            />
          ))}
      </ChildConfirmationTableContainer>
      <ButtonContainer>
        <div className={"buttons"}>
          <OwnerButton
            disabled={isSaving}
            text={t("Back")}
            className={"back-button"}
            onClick={handleBack}
          />
          <OwnerButton
            isLoading={isSaving}
            text={t("Confirm the change")}
            className={"confirm-button"}
            onClick={handleSave}
          />
        </div>
        <span className={"info-alert"}>
          {t("Press the button only once and wait until the screen changes")}
        </span>
      </ButtonContainer>
    </>
  )
}
