import { TopRoundedWhiteCard, SelectInput } from "@project/shared"
import { useFormik } from "formik"
import { t } from "i18next"
import styled from "styled-components"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import * as yup from "yup"
import { useState } from "react"
import moment from "moment"
import { DatePicker } from "../DatePicker"
import { japaneseAlphaRegex } from "../../../utils/validation"

const OperationWrapper = styled.div`
  padding: 20px 0;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .flexbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #191919;

    .input-second {
      @media (max-width: 550px) {
        width: 90px;
      }
    }
    .date-picker {
      @media (max-width: 550px) {
        width: 150px;
      }
    }
    @media screen and (max-width: 426px) {
      gap: 0;

      .input-second {
        margin-left: 0.5rem;
      }
    }
  }
  .option-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    row-gap: 10px;
    margin: 24px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 352px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 348px) {
      width: 100%;
    }
  }
  .operation-label {
    min-width: 120px;

    @media (max-width: 550px) {
      min-width: 100px;
    }
  }

  .operation-option .dynamic-dropdown-width {
    min-width: 150px;
  }

  @media (max-width: 768px) {
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
  }

  .change-display-container {
    @media (max-width: 640px) {
      display: none;
    }
  }

  .change-display-container-sp {
    display: none;
    @media (max-width: 640px) {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  margin: 20px 0;
  padding: 4px 8px;
  border-left: 4px solid #0782c8;
`

interface IOperationOptions {
  initialValues?: {
    year: number
    month: number
    facility: number
    staff: number
    furigana_sort?: string
  }
  facilityOptions: Array<any> | null
  handleDisplayChange: any
  staffOptions: Array<any> | null
}

const defaultInitials = {
  year: null,
  month: null,
  facility: null,
  staff: null,
  furigana_sort: null,
}

export const MonthlyStaffAttendanceOperationOptions = ({
  initialValues = defaultInitials,
  facilityOptions,
  staffOptions,
  handleDisplayChange,
}: IOperationOptions) => {
  const validationSchema = yup.object().shape({
    year: yup.number().required(),
    month: yup.number().required(),
    staff: yup.number().required(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleDisplayChange(formik.values)
    },
  })
  const getVisibleStaffObj = (filters) => {
    let resetSelectedStaff = true
    const newVisibleStaffOptions = staffOptions?.filter((option) => {
      let shouldBeVisible = false
      if (filters.furigana_sort) {
        const regex = japaneseAlphaRegex(filters.furigana_sort)
        if (!option.furigana_name.match(regex)) return
      }
      option.facilities.map((facility) => {
        if (shouldBeVisible) return
        if (filters.facility && filters.facility != facility.facility_id) return
        if (
          filters.year &&
          filters.month &&
          (filters.year < facility.year ||
            (filters.year == facility.year && filters.month < facility.month))
        )
          return
        if (formik.values.staff == option.value) resetSelectedStaff = false
        shouldBeVisible = true
      })
      if (shouldBeVisible) return option
    })
    return {
      staff: newVisibleStaffOptions,
      resetSelectedStaff,
    }
  }
  const [visibleStaffOptions, setVisibleStaffOptions] = useState(
    getVisibleStaffObj(formik.values).staff
  )
  const handleOperationChange = (name, value) => {
    formik.setFieldValue(name, value)
    if (name != "staff") {
      const filters = formik.values
      filters[name] = value
      const visibleObj = getVisibleStaffObj(filters)
      setVisibleStaffOptions(visibleObj.staff)
      if (visibleObj.resetSelectedStaff) formik.setFieldValue("staff", null)
    }
  }

  return (
    <OperationWrapper className={"section-not-to-print"}>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <TitleWrap>{t("Display settings")}</TitleWrap>
        <div className={"operation-container"}>
          <div className={"option-row"}>
            <div className={"caret-right operation-label"}>{t("Facility")}</div>
            <div className={"operation-option"}>
              <SelectInput
                className={"facility-picker dynamic-dropdown-width"}
                width={"auto"}
                height={40}
                placeholder={t("All")}
                name={"facility"}
                options={facilityOptions}
                defaultValue={initialValues.facility}
                dropdownMatchSelectWidth={false}
                onChange={(val) => handleOperationChange("facility", val)}
              />
            </div>
          </div>
          <div className={"option-row"}>
            <div className={"caret-right operation-label"}>
              {t("Year months")}
            </div>
            <div className={"operation-option"}>
              <div className={"flexbox"}>
                <DatePicker
                  className={"date-picker"}
                  date={
                    moment({
                      year: +formik.values.year,
                      month: +formik.values.month - 1,
                    })?.isValid()
                      ? moment({
                          year: +formik.values.year,
                          month: +formik.values.month - 1,
                        })
                      : null
                  }
                  style={{
                    height: "40px",
                  }}
                  picker={"month"}
                  format={"YYYY年MM月"}
                  showArrow={true}
                  disabledDate={(current) => {
                    return (
                      (current && current < moment().subtract(5, "year")) ||
                      (current && current < moment("2015/01/01")) ||
                      (current && current > moment().add(3, "year"))
                    )
                  }}
                  onDateChange={(value) => {
                    formik.setFieldValue("year", moment(value).format("YYYY"))
                    formik.setFieldValue("month", moment(value).format("MM"))
                    handleOperationChange("year", moment(value).format("YYYY"))
                    handleOperationChange("month", moment(value).format("M"))
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"option-row select-input_button-wrapper"}>
            <div className={"caret-right operation-label"}>{t("Staff")}</div>
            <div className={"operation-option"}>
              <div className={"flexbox"}>
                <SelectInput
                  width={60}
                  height={40}
                  placeholder={"---"}
                  options={FuriganaAlphabetsOption}
                  name={"furigana_sort"}
                  value={formik.values.furigana_sort}
                  onChange={(val) =>
                    handleOperationChange("furigana_sort", val)
                  }
                />
                <SelectInput
                  className={"input-second dynamic-dropdown-width"}
                  width={"auto"}
                  dropdownMatchSelectWidth={false}
                  height={40}
                  options={visibleStaffOptions}
                  name={"staff"}
                  defaultValue={initialValues.staff}
                  value={formik.values.staff}
                  placeholder={"---"}
                  onChange={(val) => {
                    handleOperationChange("staff", val)
                  }}
                />
              </div>
            </div>
            <div className={"change-display-container"}>
              {Object.keys(formik.errors).length == 0 && (
                <OwnerButton
                  text={t("Change display")}
                  typeOf={"secondary"}
                  onClick={() => formik.handleSubmit()}
                />
              )}
            </div>
          </div>
          <div className={"change-display-container-sp"}>
            {Object.keys(formik.errors).length == 0 && (
              <OwnerButton
                text={t("Change display")}
                typeOf={"secondary"}
                onClick={() => formik.handleSubmit()}
              />
            )}
          </div>
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
