import {
  SheetData,
  SheetsDispatcher,
  SiblingSheetData,
  SiblingSheetsDispatcher,
} from "../../types"

// チェックボックスの変更
export function handleSelectSheet(
  sheetId: number,
  data: SheetData | SiblingSheetData,
  dispatcher: SheetsDispatcher | SiblingSheetsDispatcher
) {
  if (!data[sheetId]) {
    return
  }
  const v = data[sheetId].checked
  data[sheetId].checked = !v

  dispatcher((prev) => ({
    ...prev,
    [sheetId]: {
      ...prev[sheetId],
      checked: !v,
    },
  }))
}

// 区分の変更
export function handleInformationCreationCategory(
  value: number,
  sheetId: number,
  data: SheetData,
  dispatcher: SheetsDispatcher
) {
  if (!data[sheetId]) {
    return
  }

  dispatcher((prev) => ({
    ...prev,
    [sheetId]: {
      ...prev[sheetId],
      receiptUpperLimitManagement: {
        ...prev[sheetId].receiptUpperLimitManagement,
        information_creation_category: value,
      },
    },
  }))
}

// 区分の変更（複数児童用）
export function handleSiblingInformationCreationCategory(
  value: number,
  sheetId: number,
  data: SiblingSheetData,
  dispatcher: SiblingSheetsDispatcher
) {
  if (!data[sheetId]) {
    return
  }

  dispatcher((prev) => ({
    ...prev,
    [sheetId]: {
      ...prev[sheetId],
      receiptSiblingUpperLimitManagement: {
        ...prev[sheetId].receiptSiblingUpperLimitManagement,
        information_creation_category: value,
      },
    },
  }))
}
