import React from "react"
import {
  Wrapper,
  NBLInfoContent,
  NBLMiddleInfoContent,
} from "./../../PrintListContent.style"

import { t } from "i18next"
import {
  ReceiptSiblingUpperLimitManagement,
  ReceiptUpperLimitManagement,
} from "../../../../types"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../constants"
import { YearMonth } from "../../common/header/YearMonth"
import { ManagementFacility } from "../../common/header/ManagementFacility"
import { DefaultChildDetail } from "../header/childDetail/DefaultChildDetail"
import { DefaultSignature } from "../../common/footer/signature/DefaultSignature"
import { HiroshimaFormat } from "../body/HiroshimaFormat"
import { HiroshimaSheetTitle } from "../header/sheetTitle/HiroshimaSheetTitle"
import { HiroshimaUpperMonthLimit } from "../../common/header/upperMonthLimit/HiroshimaUpperMonthLimit"
import { HiroshimaUpperLimitManagementResult } from "../../common/header/upperLimitManagementResult/HiroshimaUpperLimitManagementResult"

type Props = {
  receiptUpperLimitManagement:
    | ReceiptUpperLimitManagement
    | ReceiptSiblingUpperLimitManagement
  printConfig: string[]
}

// Same SiblingFormat
export function HiroshimaFlame({
  receiptUpperLimitManagement,
  printConfig = [],
}: Props) {
  return (
    <Wrapper>
      {printConfig.includes(
        PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER
      ) && <small>{t("(Form 2)")}</small>}

      {/* --- Start Header */}
      <HiroshimaSheetTitle />
      <YearMonth
        year={receiptUpperLimitManagement.year}
        month={receiptUpperLimitManagement.month}
      />
      <NBLInfoContent length={1}>
        <div className={"top__table_content"}>
          <DefaultChildDetail
            municipalityNumber={receiptUpperLimitManagement.municipality_number}
            receivingCertificateNumber={
              receiptUpperLimitManagement.receiving_certificate_number
            }
            childName={receiptUpperLimitManagement.child_name}
            parentName={receiptUpperLimitManagement.parent_name}
            printConfig={printConfig}
          />
        </div>
        <div className={"top__table_content"}>
          <ManagementFacility
            facilityName={receiptUpperLimitManagement.facility_name}
            facilityNumber={receiptUpperLimitManagement.facility_number}
          />
        </div>
      </NBLInfoContent>
      <NBLMiddleInfoContent>
        <div className={"NBLMiddleInfoContent"}>
          <div className={"nbl_info_top_content"}>
            <HiroshimaUpperMonthLimit
              burdenMaxMonth={receiptUpperLimitManagement.burden_max_month}
              localBurdenMaxMonth={
                receiptUpperLimitManagement.local_burden_max_month
              }
            />
          </div>
          <div className={"nbl_info_bottom_content"}>
            <HiroshimaUpperLimitManagementResult
              managementResult={receiptUpperLimitManagement.management_result}
            />
          </div>
        </div>
      </NBLMiddleInfoContent>
      {/* --- End Header */}

      {/* --- Start Body */}
      <HiroshimaFormat
        facilities={receiptUpperLimitManagement.facilities}
        totalAmount={receiptUpperLimitManagement.total_amount}
        totalBurdenAmount={receiptUpperLimitManagement.total_burden_amount}
        managementResultBurdenAmount={
          receiptUpperLimitManagement.management_result_burden_amount
        }
        managementResultInsuranceAmount={
          receiptUpperLimitManagement.total_management_result_insurance_amount
        }
        totalLocalExemption={receiptUpperLimitManagement.total_local_exemption}
        localBurdenAmount={receiptUpperLimitManagement.local_burden_amount}
      />
      {/* --- End Body */}

      {/* --- Start Footer */}
      <DefaultSignature printConfig={printConfig} />
      {/* --- End Footer */}
    </Wrapper>
  )
}
