import { theme } from "@project/shared"
import styled from "styled-components"

export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  .inner-grid-content {
    width: 100%;
  }
  .grid-header {
    width: 300px;
    padding: 28px 55px 28px 18px;
  }
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input,
  textarea {
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .grid-header {
      width: 100%;
      padding: 10px;
    }
  }

  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
  }
  .text-field-headertext {
    margin-top: 20px;
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #0782c8;
  }
  .title {
    white-space: pre-wrap;
    word-break: break-all;
  }
  .ant-card-grid:nth-child(10) {
    border-top: 1px solid #d2d1d1;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
  .ant-card-grid:nth-child(11) {
    border-top: 1px solid #d2d1d1;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
  .ant-card-grid:nth-child(20) {
    @media (max-width: 767px) {
      margin-top: 4px;
      border-bottom: 1px solid #d2d1d1;
    }
  }
  .image-sub {
    p {
      font-size: 12px;
    }
  }
`
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
`

export const ButtonSectionWithDelete = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .delete-button {
    background: ${theme.red2};
    border: none;
  }
`

export const ButtonSection = styled.div`
  display: flex;
  .cancel-btn {
    margin-right: 17px;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel-btn {
      margin-right: 10px;
    }
  }
`

export const UploadSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 180px;
  width: 100%;
  button {
    padding: 8px 8px;
  }
  .add_img {
    margin-top: 20px;
    height: 30px;
    width: 192px;
  }
`

export const Wrapper = styled.div`
  .file-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-btn {
      color: #888787;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      width: 130px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      .ant-space-item {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .space {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .upload-area {
    display: flex;
    align-items: center;
    gap: 5%;
    margin-top: 5px;
    .delete-icon {
      color: red;
      cursor: pointer;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .file-details {
        display: flex;
        align-items: center;
        width: 80%;
        p {
          word-break: break-all;
        }
      }
    }
  }
  .upload-hint {
    font-size: 14px;
    font-weight: 400;
    color: #191919;
    line-height: 24px;
    word-break: break-all;
  }
`

export const EnteredByWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 1019px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`

export const SelectRow = styled.div`
  display: flex;
  gap: 10px;
`
export const UploadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`
