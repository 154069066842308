import React, { useContext, useMemo } from "react"

// packages
import { t } from "i18next"
import moment from "moment"

// styled components
import { ProgressRecordContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { OwnerTable } from "../../../molecules"
import { TopRoundedWhiteCard } from "@project/shared"
import { PrintOnlyHeader, ProgressRecordHeader } from "."

// hooks
import { useTable } from "../hooks"

// types
import {
  ProgressProgramRecordDataProps,
  ProgressRecordBlockProps,
} from "../types"
import { AuthContext } from "../../../../utils"
import { hasPermissionForFacility } from "../../../../utils/SidebarUtils"
import { ProgramProgressRecordPermission } from "../../../../utils/PermissionKeys"

export const ProgressRecordBlock = React.forwardRef<
  HTMLDivElement,
  ProgressRecordBlockProps
>((props, ref: any) => {
  const {
    pageSize,
    currentPage,
    currentChildName,
    handleMonthChange,
    currentSearchQueries,
    handlePaginationChange,
    isProgramProgressRecordLoading,
    programProgressRecords: dataSources,
  } = props
  const { column, columnForPrint } = useTable()

  const { permissions, isOwner } = useContext(AuthContext)

  const dataSourceWithEditPermissionCheck = useMemo(() => {
    const data: Array<ProgressProgramRecordDataProps> = dataSources?.data?.map(
      (d) => ({
        ...d,
        edit_allowed:
          isOwner ||
          hasPermissionForFacility(
            permissions,
            ProgramProgressRecordPermission,
            "write",
            d.facility_id
          ),
      })
    )
    return data
  }, [dataSources, permissions, isOwner])

  return (
    <ProgressRecordContainer ref={ref}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        headerElement={
          <ProgressRecordHeader
            currentChildName={currentChildName || t("All")}
            currentDate={moment({
              year: +currentSearchQueries.year,
              month: +currentSearchQueries.month - 1,
            })}
            handleMonthChange={handleMonthChange}
          />
        }
      >
        <div className={"progress-record-body"}>
          <OwnerButton
            className={"print-btn no-print"}
            text={t("Print ")}
            typeOf={"print"}
            onClick={() => {
              window.print()
            }}
          />

          {/* Only display this in print mode :- starts here */}
          <PrintOnlyHeader
            currentChildName={currentChildName}
            currentDate={moment(
              `${currentSearchQueries.year}-${currentSearchQueries.month}`
            )}
          />
          {/* Only display this in print mode :- ends here here */}

          <div className={"table-container-custom"}>
            <OwnerTable
              className={"no-print always-present-scrollbar"}
              bordered
              columns={column}
              pageSize={pageSize}
              currentPage={currentPage}
              total={dataSources?.count}
              dataSource={dataSourceWithEditPermissionCheck}
              loading={isProgramProgressRecordLoading}
              handlePaginationChange={handlePaginationChange}
              scroll={{
                x: "max-content",
              }}
            />

            <OwnerTable
              className={"only-print print-table"}
              columns={columnForPrint}
              pageSize={pageSize}
              currentPage={currentPage}
              total={dataSources?.count}
              dataSource={dataSources?.data}
              loading={isProgramProgressRecordLoading}
              handlePaginationChange={handlePaginationChange}
              scroll={{
                x: "max-content",
              }}
            />
          </div>
        </div>
      </TopRoundedWhiteCard>
    </ProgressRecordContainer>
  )
})
