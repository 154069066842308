// packages
import { t } from "i18next"

// styled components
import { ChangeDisplayWrapper } from "../styles"

// components
import { Checkbox } from "@project/shared"
import { OwnerButton } from "../../../atoms"
import { useState } from "react"

export const ChangeDisplayBox: React.FC<{
  isDisplayByMunicipality: boolean
  setDisplayByMunicipality: (val) => void
}> = ({ isDisplayByMunicipality, setDisplayByMunicipality }) => {
  const [isChecked, setIsChecked] = useState(isDisplayByMunicipality)
  return (
    <ChangeDisplayWrapper className={"common-box-container"}>
      <div className={"change-display-box-content flexbox"}>
        <p className={"change-display-box-content_title"}>
          {t("Change display")}
        </p>
        <Checkbox
          label={t("Display by municipality")}
          checked={isChecked}
          name={"displayByMunicipality"}
          key={"displayByMunicipality"}
          onChange={() => {
            setIsChecked(!isChecked)
          }}
        />
        <OwnerButton
          className={"btn"}
          text={t("Change")}
          typeOf={"secondary"}
          onClick={() => {
            setDisplayByMunicipality(isChecked)
          }}
        />
      </div>
    </ChangeDisplayWrapper>
  )
}
