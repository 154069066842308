import {
  WEEK_DAYS,
  CALANDER_HOURS_MINUTES,
  Checkboxes,
  Grid,
  PageCard,
  RadioButtons,
  SelectInput,
  TextField,
  TimeRanges,
  BusinessCalendarPagination,
  BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION,
  DEFAULT_HOURS,
  DAYS_OPTION,
  CHILD_SERVICE_OPTION,
  theme,
  DONT_DO_LIST,
  NO_YES_STRING_LIST,
} from "@project/shared"
import {
  Card,
  Checkbox,
  Col,
  notification,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd"
import moment from "moment"
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import Calendar from "react-calendar"
import { getMonth } from "@wojtekmaj/date-utils"
import styled from "styled-components"
import { CalanderTimeRange, DatePicker, OwnerButton, Tag } from "../.."
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import {
  deleteBusinessDayRegistration,
  fetchBusinessDayRegistration,
  saveBusinessDayRegistration,
} from "../../../services"
import { useFormik } from "formik"
import * as yup from "yup"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { useRouter } from "next/router"
import { getDayOfWeek } from "react-calendar/dist/cjs/shared/dates"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { BusinessListPermission } from "../../../utils/PermissionKeys"

const Wrapper = styled.div<{ isMenuOpen?: boolean }>`
  margin-top: 36px;

  .flex-100 {
    flex-basis: 100%;
  }
  .flex-class {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    @media (max-width: 640px) {
      align-items: flex-start;
    }
  }
  .meta-body {
    flex-basis: 100%;
    .ant-card-meta-detail {
      flex-basis: 100%;
    }
    .ant-card-meta-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #191919;
      position: relative;
      padding-left: 7px;
      border-left: 3px solid #0782c8;
      margin-bottom: 17px;
    }
    .ant-card-meta-description {
      .hours_container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .range {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  .flexbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #191919;
    width: 100%;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      gap: 0.2rem;
    }
  }

  .timerange-container {
    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
      > div {
        &.range1 {
          margin-right: 0.2rem;
        }
        &.range2 {
          margin-left: 0.2rem;
        }
        gap: 0px;
        > div {
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .bulk-hour-container {
    max-width: 1600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .each-bulk-hour {
      display: flex;
      gap: 5px;
      width: 33%;
      flex-wrap: wrap;
      align-items: center;
    }
    @media (max-width: 1100px) {
      .each-bulk-hour {
        max-width: 100%;
      }
    }
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      flex-direction: column;
      .each-bulk-hour {
        width: 100%;
      }
    }
  }

  .update-button-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #191919;
    @media (max-width: 516px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      .update-btn {
        margin-bottom: 18px;
      }
    }
  }
  .facility-select {
    @media (max-width: 640px) {
      margin-top: 3px;
    }
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
    min-width: 50px;
  }
  .pg-sp {
    margin-bottom: 38px;
  }
  .mb-sp {
    margin-bottom: 20px;
  }
  .business-tag {
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-right: 0px !important;
      margin-left: 4px;
    }
  }

  .menu-expand {
    @media (max-width: ${(props) =>
        props?.isMenuOpen ? "940px" : "1143px"}) and (min-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  .mt-0 {
    margin-top: 0;
  }
  .border-sp {
    border-bottom: 1px dashed #d2d1d1;
    padding-bottom: 10px;
  }
  .ant-col {
    margin-top: auto;
    margin-bottom: auto;
  }
  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    margin-top: 6px;
    margin-bottom: 0;
  }
  .flex-time-fields {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 5px;
  }
  .wd-46 {
    width: 46px;
  }
  @media (max-width: 1387px) {
    .col-breaker {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .ant-col-2 {
      min-width: 130px;
      @media (max-width: 519px) {
        min-width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .numbered-section {
    margin: 28px 0 10px;
    margin-right: 12px;
    span {
      display: inline-flex;
      justify-content: center;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background: #0782c8;
      color: #fff;
    }
  }
  .overwrite-para {
    margin-top: 26px;
    span {
      color: #e00000;
    }
  }
  .override-facilities-checkboxes {
    display: block;
    width: 100%;
  }

  .select-wrap {
    display: flex;
    margin-top: 3px;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      margin-bottom: 20px;
    }
    .select-down {
      margin-left: 15px;
    }
  }

  .overflow-text {
    @media (max-width: 380px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .mb-flex-row {
    display: flex;
    @media (max-width: 380px) {
      display: flex;
    }
    .last-select {
      margin-left: 10px;
    }
  }

  .occupation-subject {
    @media (max-width: 380px) {
      margin-left: 30px;
    }
  }

  .radio-btn {
    @media (max-width: 429px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      white-space: pre;
      align-items: flex-start;
    }
  }

  .mobile-width-100 {
    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .width-100 {
    @media (max-width: 700px) {
      min-width: 100%;
    }
  }

  .ant-row {
    @media (max-width: 640px) {
      flex-flow: column !important;
    }
  }
  @media (max-width: 375px) {
    .ant-card-body {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  @media (max-width: 1200px) {
    .mobile-full-width {
      max-width: 100%;
    }
    .mobile-direction-column {
      flex-direction: column;
    }
    .mobile-single-line {
      flex-direction: unset !important;
      min-width: 100%;
      .ant-col-1 {
        min-width: 10%;
      }
      .ant-col-2 {
        min-width: 18%;
      }
      .ant-col-3 {
        min-width: 30%;
      }
    }
  }
  .error-field {
    border: 1px solid ${theme.red2} !important;
  }
  .ant-checkbox-group {
    @media (max-width: 854px) {
      padding-left: 3px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    .business-reg-card {
      .ant-card-head-wrapper {
        .ant-card-head-title,
        .ant-card-extra span {
          font-size: 14px;
        }
      }
    }
  }
`
// each date cell width 177.46px so total 177.46*4 ~ 1242px
const StyledCalendar = styled(Calendar)`
  min-width: 1242px;
  margin-top: 15px;
  [class*="neighboringMonth"] {
    cursor: default;
    abbr {
      display: none;
    }
  }
  [class*="weekday"] {
    border: 0.1px solid #d2d1d1;
    border-bottom: 0;
    text-align: center;
    background-color: #f3f3f3;
    min-width: 172px;
    abbr {
      cursor: default;
      text-decoration: none;
    }
  }
  [class*="tile"] {
    border: 0.1px solid #d2d1d1;
    background: transparent;
    pointer-events: auto;
    position: relative;
    display: flex;
    min-width: 172px;

    > div {
      margin: 0 auto;
    }
    abbr {
      background: #f3f3f3;
      border: 0.1px solid #d2d1d1;
      border-top: 0;
      border-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 37px;
    }
  }

  .react-calendar__month-view > div > div {
    width: auto !important;
  }
  @media (max-width: 600px) {
    min-width: calc(100vw - 60px);
    max-width: calc(100vw - 60px);
    overflow: auto;
    [class*="__days"],
    [class*="__weekdays"] {
      flex-direction: column;
    }
    .react-calendar__month-view__weekdays {
      display: none !important;
    }
    .react-calendar__tile.react-calendar__month-view__days__day:first-child {
      margin-left: 0px !important;
    }
  }
`
const WorkStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  margin-top: 30px;
  button {
    :first-child {
      margin-right: 38px;
    }
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .delete-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }
`

const SpinnerWrapper = styled(Spin)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const { Meta } = Card

const BusinessCalendar = ({ updateBreadCrumbName }) => {
  const { collapsed, permissions, isOwner } = useContext(AuthContext)
  const router = useRouter()
  const listRef = useRef<HTMLDivElement>(null)
  const { facilityId, year, month } = router.query

  const { facilities } = useContext(AuthContext)

  const BUSINESS_REGISTRATION_FACILITY = facilities?.map((facility) => ({
    label: facility.facility_name_short,
    value: facility.id,
  }))
  const hasFacility = BUSINESS_REGISTRATION_FACILITY?.find(
    (facility) => facility.value == facilityId
  )

  // handle invalid params do something later
  if (
    !hasFacility ||
    isNaN(+year) ||
    isNaN(+month) ||
    +month < 1 ||
    +month > 12
  ) {
    router.push("/business-list")
    return <></>
  }
  const { t } = useTranslation()
  const intialHours = {
    from: {
      hours: "",
      minutes: "",
    },
    to: {
      hours: "",
      minutes: "",
    },
  }

  const [businessHours, setbusinessHours] = useState([
    {
      id: 1,
      ...intialHours,
    },
    {
      id: 2,
      ...intialHours,
    },
    {
      id: 3,
      ...intialHours,
    },
  ])
  const [serviceHours, setServiceHours] = useState([
    {
      id: 1,
      ...intialHours,
    },
    {
      id: 2,
      ...intialHours,
    },
    {
      id: 3,
      ...intialHours,
    },
  ])

  const [currentYear, setCurrentYear] = useState<number>(+year)
  const [activeYear, setActiveYear] = useState<"current" | "next" | "prev">(
    "current"
  )
  const [currentMonth, setCurrentMonth] = useState<number>(+month)
  const [currentFacility, setCurrentFacility] = useState<number>(+facilityId)
  const [dayOptions, setDayOptions] = useState<Array<any>>(DAYS_OPTION)

  const getServiceObj = (id) => {
    const serviceId = facilities.find((fac) => fac.id == id)?.pref_id
    if (serviceId) {
      const childService = CHILD_SERVICE_OPTION.find(
        (item) => item.value == serviceId
      )
      return {
        name: childService?.label,
        color: childService?.colorCode,
      }
    }
    return {
      name: null,
      color: null,
    }
  }

  const [service, setService] = useState(getServiceObj(currentFacility))

  const getInitialBusinessDays = () => {
    const business_days = []

    const lastDay = new Date(+year, +month, 0).getDate()

    for (let day = 1; day <= lastDay; day++) {
      business_days.push({
        day: moment({ year: +year, month: +month - 1, day }).format(
          "YYYY-MM-DD"
        ),
        holiday: "0",
        ...DEFAULT_HOURS,
      })
    }

    return business_days
  }

  const { isLoading, data: businessDayRegistrationData } = useQuery<any>(
    ["businessDayRegistrationData", currentFacility, currentYear, currentMonth],
    async () => {
      return fetchBusinessDayRegistration({
        facilityId: currentFacility,
        currentYear,
        currentMonth,
      })
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      select: (response) => {
        const initialBusinessDays = getInitialBusinessDays()
        const hasBusinessDaySetting = response.data?.business_days?.length > 0
        const responseBusinessDays = response.data?.business_days || []
        response.data.business_days = initialBusinessDays.map((businessDay) => {
          const responseBusinessDay = responseBusinessDays?.find(
            (responseBusinessDay) =>
              moment(responseBusinessDay?.day).isSame(businessDay?.day)
          )
          if (responseBusinessDay) return responseBusinessDay
          return businessDay
        })
        return { ...response, hasBusinessDaySetting }
      },
      onSuccess: (resp) => {
        setHasSetting(resp?.hasBusinessDaySetting)
      },
    }
  )

  const hasDeleteAccess = useMemo(() => {
    const hasDate = businessDayRegistrationData?.data?.business_days.findIndex(
      (item) => item.business_end_hour1 !== null
    )
    if (hasDate == -1) return false
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        BusinessListPermission,
        "write",
        facilityId?.toString()
      )
    )
  }, [permissions, isOwner, businessDayRegistrationData])

  const initialValues = businessDayRegistrationData?.data || {
    facility_id: facilityId,
    year: year,
    month: month,
    time_setting: "0",
    day_hours: 0,
    day_minutes: 0,
    week_hours: 0,
    week_minutes: 0,
    occupations: [],
    modified_working: "0",
    modified_working_start_day: 0,
    modified_working_end_day: 0,
    modified_working_hours: 0,
    modified_working_minutes: 0,
    business_days: getInitialBusinessDays(),
    override_facilities: [],
  }

  const [loadingComplete, setLoadingComplete] = useState(false)
  const [calendarUpdating, setCalendarUpdating] = useState(false)

  const updateBusinessDay = (val) => {
    const { changeFor, day, value } = val
    let updatedBusinessDays = formik.values.business_days
    if (!updatedBusinessDays) updatedBusinessDays = []
    if (!Array.isArray(updatedBusinessDays)) return
    const positionOfCurrentBusinessDay = updatedBusinessDays.findIndex(
      (businessDay) => businessDay.day == day
    )
    if (positionOfCurrentBusinessDay > -1) {
      // add to business days
      updatedBusinessDays[positionOfCurrentBusinessDay][changeFor] = value
      if (changeFor?.includes("hour")) {
        const changeForMinute = changeFor.replace("hour", "minute")
        if (
          !updatedBusinessDays[positionOfCurrentBusinessDay][changeForMinute]
        ) {
          updatedBusinessDays[positionOfCurrentBusinessDay][changeForMinute] =
            "00"
        }
      }
    } else {
      const newDay = {
        day: day,
        holiday: "0",
        ...DEFAULT_HOURS,
      }
      newDay[changeFor] = value
      if (changeFor?.includes("hour")) {
        const changeForMinute = changeFor.replace("hour", "minute")
        newDay[changeForMinute] = "00"
      }
      updatedBusinessDays.push(newDay)
    }
    formik.setFieldValue("business_days", updatedBusinessDays)
  }

  const getDateInFormat = (date) => {
    const d = new Date(date),
      year = d.getFullYear()

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  const onInputChange = ({ value, id, range, unit, previousData, setter }) => {
    const data = [...previousData]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      [range]: {
        ...data[index][range],
        [unit]: value,
      },
    }
    if (unit == "hours") {
      if (!data[index][range]?.minutes) {
        data[index][range].minutes = "00"
      } else if (!value) {
        data[index][range].minutes = ""
      }
    }
    setter(data)
  }

  const [showNeighboringMonth, setShowNeigboringMonth] = useState<boolean>(true)
  const nmCallback = () =>
    setShowNeigboringMonth(!window.matchMedia("(max-width: 600px)").matches)

  useEffect(() => {
    nmCallback()
    window.addEventListener("resize", nmCallback, null)
    return () => {
      window.removeEventListener("resize", nmCallback, null)
    }
  }, [])

  const reflectCalendarTimeRanges = () => {
    scrollToSelectedElement(listRef)
    const initialBusinessData =
      businessDayRegistrationData?.data?.business_days?.map((el) => {
        return { ...el }
      })

    // We want to work with the data that was fetched
    let updatedBusinessDays = initialBusinessData
    if (!updatedBusinessDays) updatedBusinessDays = []
    setCalendarUpdating(true)
    const bulkBusinessHours = businessHours.filter(
      (range) => range.from.hours !== ""
    )

    const bulkServiceHours = serviceHours.filter(
      (range) => range.from.hours !== ""
    )

    const getTot = new Date(currentYear, currentMonth, 0).getDate()
    for (let i = 1; i <= getTot; i++) {
      const newDate = new Date(currentYear, currentMonth - 1, i)
      const dayOfWeek = "" + getDayOfWeek(newDate)
      if (formik.values.regular_holidays?.includes(dayOfWeek)) {
        const updateIndex = updatedBusinessDays.findIndex(
          (business) =>
            getDateInFormat(business.day) === getDateInFormat(newDate)
        )
        const updateObject =
          updateIndex === -1
            ? {
                day: getDateInFormat(newDate),
                holiday: "0",
                ...DEFAULT_HOURS,
              }
            : updatedBusinessDays[updateIndex]
        updateObject.holiday = "1"
        if (updateIndex === -1) {
          updatedBusinessDays.push(updateObject)
        } else {
          updatedBusinessDays[updateIndex] = updateObject
        }
      } else if (formik.values.work_business_days?.includes(dayOfWeek)) {
        const updateIndex = updatedBusinessDays.findIndex(
          (business) =>
            getDateInFormat(business.day) === getDateInFormat(newDate)
        )

        const updateObject =
          updateIndex === -1
            ? {
                day: getDateInFormat(newDate),
                holiday: "0",
                ...DEFAULT_HOURS,
              }
            : updatedBusinessDays[updateIndex]
        bulkBusinessHours.map((hr) => {
          updateObject["business_start_hour" + hr.id] = hr.from.hours
          updateObject["business_start_minute" + hr.id] = hr.from.minutes
          updateObject["business_end_hour" + hr.id] = hr.to.hours
          updateObject["business_end_minute" + hr.id] = hr.to.minutes
        })
        bulkServiceHours.map((hr) => {
          updateObject["service_start_hour" + hr.id] = hr.from.hours
          updateObject["service_start_minute" + hr.id] = hr.from.minutes
          updateObject["service_end_hour" + hr.id] = hr.to.hours
          updateObject["service_end_minute" + hr.id] = hr.to.minutes
        })

        // Set holiday to 0 or no holiday
        updateObject.holiday = "0"
        if (updateIndex === -1) {
          updatedBusinessDays.push(updateObject)
        } else {
          updatedBusinessDays[updateIndex] = updateObject
        }
      }
    }
    formik.setFieldValue("business_days", updatedBusinessDays)
    setTimeout(() => setCalendarUpdating(false), 1)
  }

  const [activeFacility, setActiveFacility] = useState(
    BUSINESS_REGISTRATION_FACILITY.find(
      (facility) => facility.value == facilityId
    )
  )

  const [hasSetting, setHasSetting] = useState(false)

  useEffect(() => {
    setActiveFacility(
      BUSINESS_REGISTRATION_FACILITY.find(
        (facility) => facility.value == currentFacility
      )
    )
    formik.setFieldValue("override_facilities", [currentFacility])
    setLoadingComplete(false)
  }, [currentFacility])

  const handleYearChange = (
    e: any,
    year: number,
    active: "current" | "next" | "prev"
  ) => {
    e.preventDefault()
    setCurrentYear(year)
    setActiveYear(active)
  }

  const handleMonthChange = (e: any, month: number) => {
    e.preventDefault()
    setCurrentMonth(month)
  }

  useEffect(() => {
    const totalDaysInCurrentMonth = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate()
    setDayOptions(DAYS_OPTION.slice(0, totalDaysInCurrentMonth))
    setLoadingComplete(false)
  }, [currentYear, currentMonth])

  useEffect(() => {
    if (businessDayRegistrationData?.data) {
      formik.setValues(businessDayRegistrationData.data)
      setLoadingComplete(true)
    }
  }, [isLoading])

  const { isLoading: isSubmitting, mutate } = useMutation(
    saveBusinessDayRegistration,
    {
      onSuccess: () => {
        notification.success({
          message: t("Business day registration saved successfully!"),
        })
        router.push("/business-list")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { isLoading: isDeleting, mutate: deleteBusinessDay } = useMutation(
    deleteBusinessDayRegistration,
    {
      onSuccess: () => {
        notification.success({
          message: t("Business day registration deleted successfully!"),
        })
        router.push("/business-list")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const validationSchema = yup.object().shape({
    facility_id: yup.number().required(),
    year: yup.number().required(),
    month: yup.number().min(1).max(12).required(),
    time_setting: yup.string().required(), // "0", "1" => full time staff
    day_hours: yup.number().when("time_setting", {
      is: (value) => value === "1",
      then: yup.number().required().min(0).max(24),
    }), // staff day hour
    day_minutes: yup.number().when("time_setting", {
      is: (value) => value === "1",
      then: yup.number().required().min(0).max(60),
    }),
    week_hours: yup.number().when("time_setting", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    week_minutes: yup.number().when("time_setting", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    modified_working: yup.string().required(), // "0", "1" => variable work hours
    modified_working_start_day: yup.number().when("modified_working", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    modified_working_end_day: yup.number().when("modified_working", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    modified_working_hours: yup.number().when("modified_working", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    modified_working_minutes: yup.number().when("modified_working", {
      is: (value) => value === "1",
      then: yup.number().required().min(0),
    }),
    business_days: yup
      .array()
      .of(
        yup.object({
          day: yup.string(),
          holiday: yup.string(),
          business_start_hour1: yup.string().nullable(),
          business_start_minute1: yup.string().nullable(),
          business_end_hour1: yup.string().nullable(),
          business_end_minute1: yup.string().nullable(),
          business_start_hour2: yup.string().nullable(),
          business_start_minute2: yup.string().nullable(),
          business_end_hour2: yup.string().nullable(),
          business_end_minute2: yup.string().nullable(),
          business_start_hour3: yup.string().nullable(),
          business_start_minute3: yup.string().nullable(),
          business_end_hour3: yup.string().nullable(),
          business_end_minute3: yup.string().nullable(),
          service_start_hour1: yup.string().nullable(),
          service_start_minute1: yup.string().nullable(),
          service_end_hour1: yup.string().nullable(),
          service_end_minute1: yup.string().nullable(),
          service_start_hour2: yup.string().nullable(),
          service_start_minute2: yup.string().nullable(),
          service_end_hour2: yup.string().nullable(),
          service_end_minute2: yup.string().nullable(),
          service_start_hour3: yup.string().nullable(),
          service_start_minute3: yup.string().nullable(),
          service_end_hour3: yup.string().nullable(),
          service_end_minute3: yup.string().nullable(),
        })
      )
      .typeError("Please set business days"),
    override_facilities: yup.array(),
  })

  if (
    !initialValues.override_facilities ||
    initialValues.override_facilities.length === 0
  )
    initialValues.override_facilities = [currentFacility]
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({ ...values })
    },
  })

  const handleDelete = () => {
    const payload = {
      year: formik.values.year,
      month: formik.values.month,
      facility_id: formik.values.facility_id,
    }
    deleteBusinessDay(payload)
  }

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) return
    if (formik.submitCount) {
      const firstErrorName = Object.keys(formik.errors)[0]
      const elements = document.getElementsByName(firstErrorName)
      document
        .querySelectorAll<HTMLElement>(".error-field")
        .forEach((e) => e.classList.remove("error-field"))
      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth", block: "center" })
        elements[0].classList.add("error-field")
      } else {
        notification.error({
          message: formik.errors[Object.keys(formik.errors)[0]].toString(),
        })
      }
    }
  }, [formik.submitCount])

  const handleDateChange = (year: number, month: number) => {
    return moment({ year: +year, month: +month - 1 })
  }
  const [updateParams] = useUpdateSearchParams()
  return (
    <Wrapper isMenuOpen={collapsed}>
      <PageCard title={t("Operation options")}>
        <Meta
          title={t("Display settings")}
          className={"meta-body"}
          description={
            <>
              <div className={"flexbox mb-sp"}>
                <Row className={"flex-100"}>
                  <Col span={3} className={"caret-right"}>
                    {t("Facility")}
                  </Col>
                  <Col span={21}>
                    <SelectInput
                      className={
                        "facility-select dynamic-dropdown-width min-w-150"
                      }
                      dropdownMatchSelectWidth={false}
                      width={"auto"}
                      height={40}
                      placeholder={t("All")}
                      options={BUSINESS_REGISTRATION_FACILITY}
                      onChange={(e) =>
                        formik.setFieldValue("search_facility_id", e)
                      }
                      value={
                        +formik.values.search_facility_id ||
                        +formik.values.facility_id
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp"}>
                <Row className={"flex-100"}>
                  <Col span={3} className={"caret-right"}>
                    {t("Year months")}
                  </Col>
                  <Col span={21}>
                    <div className={"update-button-wrapper"}>
                      <div className={"select-wrap"}>
                        <DatePicker
                          date={handleDateChange(
                            +formik.values.search_year || +formik.values.year,
                            +formik.values.search_month || +formik.values.month
                          )}
                          style={{
                            height: "40px",
                          }}
                          picker={"month"}
                          format={"YYYY年MM月"}
                          showArrow={true}
                          disabledDate={(current) => {
                            return (
                              (current && current < moment("2015/01/01")) ||
                              (current && current > moment().add(4, "year"))
                            )
                          }}
                          onDateChange={(value) => {
                            formik.setFieldValue(
                              "search_year",
                              moment(value).format("YYYY")
                            )
                            formik.setFieldValue(
                              "search_month",
                              moment(value).format("M")
                            )
                          }}
                        />
                      </div>
                      <OwnerButton
                        className={"update-btn"}
                        typeOf={"secondary"}
                        text={t("Update display")}
                        onClick={() => {
                          scrollToSelectedElement(listRef)
                          setCurrentFacility(
                            formik.values.search_facility_id ||
                              formik.values.facility_id
                          )
                          const search_facility_id =
                            formik.values.search_facility_id ??
                            formik.values.facility_id

                          setService(getServiceObj(search_facility_id))
                          setCurrentMonth(
                            formik.values.search_month || formik.values.month
                          )
                          setCurrentYear(
                            formik.values.search_year || formik.values.year
                          )
                          const facilityForName =
                            formik.values.search_facility_id ??
                            formik.values.facility_id
                          const facilityName = facilities.find(
                            (fac) => fac.id == facilityForName
                          )?.facility_name_short
                          updateBreadCrumbName(
                            t("businessDayBreadcrumb", {
                              facility_name: facilityName,
                              year:
                                formik.values.search_year || formik.values.year,
                              month:
                                formik.values.search_month ||
                                formik.values.month,
                            })
                          )
                          updateParams(
                            {
                              facilityId: search_facility_id,
                              year:
                                formik.values.search_year || formik.values.year,
                              month:
                                formik.values.search_month ||
                                formik.values.month,
                              facility_name: facilityName,
                            },
                            "/business-day-registration"
                          )
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          }
        />
        <Meta
          title={t("Bulk setting")}
          className={"meta-body"}
          description={
            <>
              <div className={"flexbox mb-sp border-sp"}>
                <Row className={"flex-100"}>
                  <Col span={4} className={"caret-right mobile-single-line"}>
                    {t("Regular Holidays")}
                  </Col>
                  <Col span={20} className={"mobile-full-width"}>
                    <Checkboxes
                      options={WEEK_DAYS?.map((val) => ({
                        ...val,
                        disabled: formik?.values?.work_business_days?.includes(
                          val?.value
                        ),
                      }))}
                      onChange={(e) =>
                        formik.setFieldValue("regular_holidays", e)
                      }
                      value={formik.values.regular_holidays}
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <Row className={"flex-100"}>
                  <Col span={4} className={"caret-right mobile-single-line"}>
                    {t("Business Days")}
                  </Col>
                  <Col span={20} className={"mobile-full-width"}>
                    <Checkboxes
                      options={WEEK_DAYS?.map((val) => ({
                        ...val,
                        disabled: formik?.values?.regular_holidays?.includes(
                          val?.value
                        ),
                      }))}
                      onChange={(e) =>
                        formik.setFieldValue("work_business_days", e)
                      }
                      value={formik.values.work_business_days}
                    />
                  </Col>
                </Row>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <div className={"bulk-hour-container"}>
                  {businessHours?.map((v, i) => (
                    <Fragment key={i}>
                      <div className={"each-bulk-hour"}>
                        <div className={"caret-right bulk-hour-label"}>
                          <Typography.Text>
                            <>
                              {t("Business hours")}
                              {i + 1}
                            </>
                          </Typography.Text>
                        </div>
                        <div>
                          <div className={"range flexbox timerange-container"}>
                            <TimeRanges
                              className={"range1"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"from"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "from",
                                  unit,
                                  setter: setbusinessHours,
                                  previousData: businessHours,
                                })
                              }
                              values={v?.from?.hours + ":" + v?.from?.minutes}
                            />
                            {"〜"}
                            <TimeRanges
                              className={"range2"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"to"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "to",
                                  unit,
                                  setter: setbusinessHours,
                                  previousData: businessHours,
                                })
                              }
                              values={v?.to?.hours + ":" + v?.to?.minutes}
                            />
                          </div>
                        </div>
                        <Col className={"col-breaker"} />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className={"flexbox mb-sp border-sp"}>
                <div className={"bulk-hour-container"}>
                  {serviceHours?.map((v, i) => (
                    <Fragment key={i}>
                      <div className={"each-bulk-hour"}>
                        <div className={"caret-right bulk-hour-label"}>
                          <Typography.Text>
                            <>
                              {t("Service hours")}
                              {i + 1}
                            </>
                          </Typography.Text>
                        </div>
                        <div>
                          <div className={"range flexbox timerange-container"}>
                            <TimeRanges
                              className={"range1"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"from"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "from",
                                  unit,
                                  setter: setServiceHours,
                                  previousData: serviceHours,
                                })
                              }
                              values={v?.from?.hours + ":" + v?.from?.minutes}
                            />
                            {"〜"}
                            <TimeRanges
                              className={"range2"}
                              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
                              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
                              range={"to"}
                              unit={["hours", "minutes"]}
                              width={54}
                              handleChange={(value, unit) =>
                                onInputChange({
                                  value,
                                  id: v?.id,
                                  range: "to",
                                  unit,
                                  setter: setServiceHours,
                                  previousData: serviceHours,
                                })
                              }
                              values={v?.to?.hours + ":" + v?.to?.minutes}
                            />
                          </div>
                        </div>
                        <Col className={"col-breaker"} />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>

              <OwnerButton
                type={"button"}
                onClick={reflectCalendarTimeRanges}
                text={t("Reflect")}
                typeOf={"secondary"}
              />
            </>
          }
        />
      </PageCard>
      <div className={"pg-sp"} />
      <div ref={listRef}>
        <PageCard
          className={"business-reg-card"}
          noBottomMargin
          title={t("facilityBusinessCalendar", {
            facility: activeFacility.label,
            year: currentYear,
            month: String(currentMonth).padStart(2, "0"),
          })}
          extra={
            hasSetting ? (
              <Tag backgroundColor={"#E26827"} className={"business-tag"}>
                <span style={{ color: "#ffffff" }}>{t("Set already")}</span>
              </Tag>
            ) : (
              <Tag backgroundColor={"#F3F3F3"} className={"business-tag"}>
                <span style={{ color: "#000000" }}>{t("No Setting")}</span>
              </Tag>
            )
          }
          background={"none"}
        >
          {!loadingComplete ? (
            <SpinnerWrapper size={"large"} className={"loader"} />
          ) : (
            <>
              <BusinessCalendarPagination
                year={+year}
                handleYearChange={handleYearChange}
                handleMonthChange={handleMonthChange}
                currentYear={currentYear}
                activeYear={activeYear}
                currentMonth={currentMonth}
              />
              <div className={"numbered-section"}>
                <span>{"1"}</span>
                {t("Set business days, business hours, and service hours")}
              </div>
              <p style={{ width: "100%" }}>
                {t(
                  'From the calendar below, click "Open", "Holiday" and select the business day or the day when the lunch will be provided.'
                )}
                <br />
                {t(
                  "Please set the time by selecting the pull-down menu for business hours and service hours for each day."
                )}
              </p>
              {isLoading || calendarUpdating ? (
                <SpinnerWrapper size={"large"} />
              ) : (
                <div style={{ overflow: "auto" }}>
                  <StyledCalendar
                    tileContent={({ activeStartDate, date }) =>
                      date.getMonth() === getMonth(activeStartDate) && (
                        <CalanderTimeRange
                          service={service}
                          forDay={getDateInFormat(date)}
                          handleChange={updateBusinessDay}
                          dayDetail={formik.values.business_days?.find(
                            (businessDay) =>
                              getDateInFormat(businessDay.day) ===
                              getDateInFormat(date)
                          )}
                        />
                      )
                    }
                    formatDay={(_, date) => moment(date).format("DD")}
                    showNavigation={false}
                    tileDisabled={({ activeStartDate, date }) =>
                      date.getMonth() !== getMonth(activeStartDate)
                    }
                    locale={"ja"}
                    calendarType={"US"}
                    showNeighboringMonth={showNeighboringMonth}
                    activeStartDate={new Date(currentYear, currentMonth - 1, 1)}
                  />
                </div>
              )}
              <div className={"numbered-section"}>
                <span>{"2"}</span>
                {t("Set the registered contents of the work style list")}
              </div>
              <WorkStyle>
                <Grid className={"grid-header"} background>
                  {t(
                    "Setting the number of hours that full-time staff should work"
                  )}
                </Grid>
                <Grid className={"flex-full grid-row-last"}>
                  <RadioButtons
                    options={DONT_DO_LIST}
                    onChange={(e) =>
                      formik.setFieldValue("time_setting", e.target.value)
                    }
                    value={formik.values.time_setting}
                  />
                  {formik.values.time_setting === "1" && (
                    <div
                      className={
                        "row-sp mobile-width-100 mobile-direction-column"
                      }
                    >
                      <Row className={"flex-100 mobile-single-line"}>
                        <div className={"flex-time-fields"}>
                          <span className={"wd-46"}>
                            {t("1Day")}
                            {":"}
                          </span>
                          <TextField
                            type={"number"}
                            width={"80px"}
                            bgcolor={"transparent"}
                            min={0}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "day_hours",
                                +e.target.value ?? ""
                              )
                            }
                            value={formik.values.day_hours}
                            name={"day_hours"}
                          />
                          <span>{t("hrs")}</span>
                          <TextField
                            type={"number"}
                            width={"80px"}
                            bgcolor={"transparent"}
                            min={0}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "day_minutes",
                                +e.target.value ?? ""
                              )
                            }
                            value={formik.values.day_minutes}
                            name={"day_minutes"}
                          />
                          <span>{t("mins")}</span>
                        </div>
                      </Row>
                      <Row className={"flex-100 mobile-single-line"}>
                        <div className={"flex-time-fields"}>
                          <span className={"wd-46"}>
                            {t("1week")}
                            {": "}
                          </span>
                          <TextField
                            type={"number"}
                            min={0}
                            width={"80px"}
                            bgcolor={"transparent"}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "week_hours",
                                +e.target.value ?? ""
                              )
                            }}
                            value={formik.values.week_hours}
                            name={"week_hours"}
                          />
                          <span>{t("hrs")}</span>
                          <TextField
                            type={"number"}
                            width={"80px"}
                            min={0}
                            bgcolor={"transparent"}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "week_minutes",
                                +e.target.value ?? ""
                              )
                            }
                            value={formik.values.week_minutes}
                            name={"week_minutes"}
                          />
                          <span>{t("mins")}</span>
                        </div>
                      </Row>
                    </div>
                  )}
                </Grid>
                <Grid className={"flex-break"} />
                <Grid className={"flex-break"} />
                <Grid className={"grid-header"} background>
                  {t("Number of hours a full-time employee should work")}
                </Grid>
                <Grid className={"flex-full grid-row-last"}>
                  <div
                    className={"flexbox overflow-text radio-btn"}
                    style={{ whiteSpace: "normal" }}
                  >
                    {t("Presence or absence of variable working hours system")}
                    <RadioButtons
                      name={"modified_working"}
                      options={NO_YES_STRING_LIST}
                      onChange={(e) =>
                        formik.setFieldValue("modified_working", e.target.value)
                      }
                      value={formik.values.modified_working}
                    />
                  </div>
                  {formik.values.modified_working === "1" && (
                    <div className={"row-sp"}>
                      <Row className={"flex-class"}>
                        <div>{t("Subjected occupation: ")}</div>

                        {BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION.map(
                          (occupation, index) => (
                            <div key={index + 1}>
                              <Checkbox
                                className={"overflow-text"}
                                key={occupation.key}
                                name={"occupations"}
                                onChange={(e) => {
                                  const updatedOccupations =
                                    formik.values.occupations
                                  updatedOccupations[occupation.key] = e.target
                                    .checked
                                    ? occupation.value
                                    : "0"
                                  formik.setFieldValue(
                                    "occupations",
                                    updatedOccupations
                                  )
                                }}
                                checked={
                                  formik.values.occupations[occupation.key] ===
                                  "1"
                                }
                              >
                                {occupation.label}
                              </Checkbox>
                            </div>
                          )
                        )}
                      </Row>
                      <Row className={"flex-100 mb-sp menu-expand"}>
                        <Col
                          style={{ minWidth: "222px" }}
                          span={8}
                          className={"width-100"}
                        >
                          {t(
                            "Transformation period (period to which the current month belongs): "
                          )}
                        </Col>

                        <Col span={3} className={"width-100"}>
                          <div className={"mb-flex-row"}>
                            <div>
                              <SelectInput
                                options={dayOptions}
                                width={80}
                                bgcolor={"transparent"}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "modified_working_start_day",
                                    e
                                  )
                                }
                                value={formik.values.modified_working_start_day}
                              />
                            </div>
                            &nbsp;
                            <span
                              style={{
                                minWidth: "30px",
                              }}
                            >
                              {"日"} {"~"}
                            </span>
                            <div className={"last-select"}>
                              <SelectInput
                                options={dayOptions}
                                width={80}
                                bgcolor={"transparent"}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "modified_working_end_day",
                                    e
                                  )
                                }
                                value={formik.values.modified_working_end_day}
                              />
                            </div>
                            &nbsp;
                            <span>{"日"}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={"flex-100 menu-expand"}>
                        <Col
                          style={{ minWidth: "224px" }}
                          span={8}
                          className={"width-100"}
                        >
                          {t(
                            "Number of hours to be worked during the above period: "
                          )}
                        </Col>
                        <Col span={3}>
                          <div className={"mb-flex-row"}>
                            <div>
                              <TextField
                                type={"number"}
                                width={"80px"}
                                min={0}
                                bgcolor={"transparent"}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "modified_working_hours",
                                    +e.target.value ?? ""
                                  )
                                }
                                value={formik.values.modified_working_hours}
                                name={"modified_working_hours"}
                              />
                            </div>
                            &nbsp;
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "33px",
                              }}
                            >
                              {t("hrs")}
                            </span>
                            <div className={"last-select"}>
                              <TextField
                                type={"number"}
                                width={"80px"}
                                min={0}
                                bgcolor={"transparent"}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "modified_working_minutes",
                                    +e.target.value ?? ""
                                  )
                                }
                                value={formik.values.modified_working_minutes}
                                name={"modified_working_minutes"}
                              />
                            </div>
                            &nbsp;
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {t("mins")}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Grid>
                <Grid className={"flex-break"} />
              </WorkStyle>
              <div className={"numbered-section"}>
                <span>{"3"}</span>
                {t("Select facilities to overwrite")}
              </div>
              <div className={"override-facilities-checkboxes"}>
                <Checkboxes
                  options={BUSINESS_REGISTRATION_FACILITY}
                  onChange={(e) =>
                    formik.setFieldValue("override_facilities", e)
                  }
                  value={formik.values.override_facilities}
                />
              </div>
              <p className={"overwrite-para"}>
                {t("overrideBusinessDaysNote", {
                  year: currentYear,
                  month: currentMonth,
                })}
                <br />

                <span>
                  {"※"}
                  {t(
                    "If there is a business day setting for each facility, it will be overwritten and saved."
                  )}
                </span>
              </p>
              <ButtonContainer>
                <ButtonWrapper>
                  <OwnerButton
                    typeOf={"secondary"}
                    onClick={() => {
                      router.push("/business-list")
                    }}
                    text={t("Cancel")}
                  />
                  <OwnerButton
                    type={"button"}
                    isLoading={isSubmitting}
                    onClick={() => formik.handleSubmit()}
                    shape={"circle"}
                    text={t("Save")}
                  />
                </ButtonWrapper>
                {hasDeleteAccess && (
                  <div className={"delete-container"}>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                      onConfirm={handleDelete}
                    >
                      <OwnerButton
                        type={"button"}
                        shape={"circle"}
                        text={t("Delete")}
                        className={"delete-button"}
                        isLoading={isDeleting}
                      />
                    </Popconfirm>
                  </div>
                )}
              </ButtonContainer>
            </>
          )}
        </PageCard>
      </div>
    </Wrapper>
  )
}

export { BusinessCalendar }
