import React, { useState } from "react"
//libraries
import { useTranslation } from "react-i18next"
//styles
import { Wrapper } from "./DataBackupPage.styles"
import { Checkbox, Spin, Typography, notification } from "antd"
import { OwnerButton } from "../../atoms"
import { OwnerTable } from "../../molecules"
import { useMutation, useQuery } from "react-query"
import {
  createDataBackup,
  getAllDataBackups,
} from "../../../services/dataBackup"
import moment from "moment"
import { useRouter } from "next/router"
import styled from "styled-components"

const CustomOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(0.7px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const DataBackupPageOrganism = (): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation()
  const columns = [
    {
      key: "created_date",
      title: t("Created date"),
      dataIndex: "created_at",
      render: (_, value) => {
        return (
          <span>
            {value?.created_at
              ? moment(value?.created_at).format("YYYY年MM月DD日 HH:mm")
              : ""}
          </span>
        )
      },
    },
    {
      key: "file_name",
      title: t("file name"),
      dataIndex: "file_name",
    },
    {
      key: "creator",
      title: t("Creator"),
      dataIndex: "created_by",
    },
  ]
  const { data: backups, refetch } = useQuery<any, Error>(
    ["get-all-backups"],
    () => getAllDataBackups(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,

      retry: 1,
      select: ({ data }) =>
        data?.map((i) => {
          return {
            created_at: moment(i?.created_datetime).format(
              "YYYY-MM-DD hh:mm:ss"
            ),
            file_name: i?.file_name,
            created_by: i?.created,
          }
        }),
    }
  )
  const [accept, setAccept] = useState(false)

  const { isLoading, mutate: backup_data } = useMutation(createDataBackup, {
    onSuccess: (data: any) => {
      notification.success({
        message: `${t("Data back up")} ${t("Created Successfully")}`,
      })
      refetch()
      router.push(data?.msg)
      setAccept(false)
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })

  return (
    <>
      {isLoading && (
        <CustomOverlay>
          <Spin size={"large"} />
        </CustomOverlay>
      )}
      <Wrapper>
        <div className={"policy_container"}>
          <div className={"policy_container-header"}>
            <div className={"title_content"}>
              <div className={"title_content-left"}>
                <img src={"/assets/icons/exclamation.png"} alt={""} />
                <div>{t("IMP")}</div>
              </div>
              <div className={"title_content-right"}>
                {t("You can download data for all facilities on this page")}
              </div>
            </div>
            <div className={"subtitle_content"}>
              {t(
                'Please confirm the following information, and if there are no problems, press the "I agree to the handling of information" button and press the "Create Data" button.'
              )}
            </div>
          </div>
          <div className={"policy_container-body"}>
            <Typography.Paragraph>
              {t(`This data contains personal information.`)}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t(
                `However, in accordance with Article XX and Article XX of the easyy Terms of Use, we cannot guarantee that the information will not be leaked, lost, or tampered with by a third party. However, in accordance with Article XX and Article XXof the easyy Terms of Use, we cannot guarantee the prevention of information leakage, loss, or alteration by third parties. Therefore, we shall not be liable for any damage incurred by the subscriber, user, or third party due to leakage or loss of information, falsification by a third party, etc., including restoration of transmitted information.`
              )}
            </Typography.Paragraph>
          </div>
        </div>

        <div className={"cta_container"}>
          <div className={"checkbox_btn"}>
            <Checkbox
              onChange={() => {
                setAccept(!accept)
              }}
              checked={accept}
            >
              {t("Agree on handling data terms & conditions")}
            </Checkbox>
          </div>
          <OwnerButton
            text={t("I accept the above precautions and create the data.")}
            typeOf={"print"}
            disabled={!accept || isLoading}
            onClick={() => backup_data()}
          />
        </div>

        <div className={"log_container"}>
          <OwnerTable
            columns={columns}
            dataSource={backups || []}
            scroll={{ x: "max-content" }}
          />
        </div>
      </Wrapper>
    </>
  )
}
