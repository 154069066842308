import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  overflow: auto;
  max-width: 100%;
`

export const VehicleTable = styled.table`
  height: fit-content;
  thead {
    tr {
      page-break-inside: avoid !important;
      th {
        text-align: center;
        border: 1px solid black;
        background-color: #ccc;
        padding: 5px;
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      page-break-inside: avoid !important;
      td {
        border: 1px solid black;
        text-align: center;
        padding: 4px;
        font-size: 14px;
        white-space: nowrap;
        page-break-inside: avoid !important;
        &:last-child {
          text-align: right;
        }
      }
      td.symbol-cell {
        text-align: center;
      }
      td.checkbox-cell {
        text-align: center;
      }
    }
    tr.merged-row {
      background-color: #ccc;
      td {
        text-align: center !important;
        padding: 6px 0 !important;
      }
    }
  }
`
