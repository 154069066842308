import { API, removeBlankAttributes } from "@project/shared"

export interface IReturnChild {
  count?: number
  data?: any
}

interface IChildParam {
  page?: string
  pageSize?: string
  child_monitoring_type?: string
  digital_signature_blank?: string
  draft_save_flg?: string
  child_id?: any
  period_start_date?: any
  period_end_date?: any
  facility_ids?: string
}

interface IChildDetailParam {
  page?: string
  pageSize?: string
  keyword?: string
  consultation_support_office_id?: number
  facility_id?: number
  other_facility_id?: number
  provision_city_id?: number
  child_service_ids?: string
  contract_month?: string
  contract_year?: string
  contract_facility_ids?: string
  show_child_without_contract?: string
}

export const fetchChildMonitoring = async ({
  queryKey,
}): Promise<IReturnChild> => {
  const params = {} as IChildParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.child_monitoring_type = queryKey[2]?.child_monitoring_type ? "1" : null
  params.digital_signature_blank = queryKey[2]?.digital_signature_blank
    ? "1"
    : null
  params.draft_save_flg = queryKey[2]?.draft_save_flg
  params.child_id = queryKey[2]?.child_id
  params.period_start_date = queryKey[2]?.period_start_date
  params.period_end_date = queryKey[2]?.period_end_date
  params.facility_ids = queryKey[2]?.facility_ids
  return API.get(`/child-monitoring`, {
    params,
  })
}

export const getAllChild = async (queryKeys?: any) => {
  const initialParams = {} as IChildDetailParam
  initialParams.pageSize = "Infinity"
  if (queryKeys?.facility_id) initialParams.facility_id = queryKeys.facility_id
  initialParams.contract_month =
    queryKeys?.contract_month || new Date().getMonth() + 1
  initialParams.contract_year =
    queryKeys?.contract_year || new Date().getFullYear()
  const params = removeBlankAttributes(initialParams)
  return API.get("/child", {
    params,
  })
}

// Gets all child for dropdowns, etc without pagination and extra data like certificates
export const getAllChildUnpaginated = async (queryKeys?: any) => {
  const initialParams = {} as IChildDetailParam
  initialParams.pageSize = "Infinity"
  if (queryKeys?.facility_id) initialParams.facility_id = queryKeys.facility_id
  if (queryKeys?.contract_year)
    initialParams.contract_year = queryKeys.contract_year
  if (queryKeys?.contract_month)
    initialParams.contract_month = queryKeys.contract_month
  if (queryKeys?.show_child_without_contract)
    initialParams.show_child_without_contract =
      queryKeys.show_child_without_contract
  const params = removeBlankAttributes(initialParams)
  return API.get("/child/child-data-only", {
    params,
  })
}

// Gets all child with/without contract
export const getAllChildUnpaginatedAllContract = async () => {
  const initialParams = {} as IChildDetailParam
  initialParams.pageSize = "Infinity"
  initialParams.show_child_without_contract = "show"
  const params = removeBlankAttributes(initialParams)
  return API.get("/child/child-data-only", {
    params,
  })
}
