import { Checkboxes, PageCard, SelectInput, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { useFormik } from "formik"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  .ant-card-body {
    padding-bottom: 0px;
  }
  .label-value-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    .label {
      background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px
        12px;
      padding-left: 14px;
    }
    .value {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      button {
        height: 20px;
        background: #ffffff;
      }
      .min-auto-width {
        width: auto;
        min-width: 100px;
      }
    }
  }
  .flex-col {
    display: flex;
    flex: 0 0 100%;
  }
  .facility-checkboxes {
    margin: 10px 0;
    width: 100%;
    @media (max-width: 768px) {
      .ant-checkbox + span {
        white-space: pre-line;
        word-break: break-all;
        padding-left: 10px;
      }
    }
  }
  .button-container {
    margin: 20px 0;
    width: 100%;
    display: flex;
    column-gap: 10px;
  }
`

const WarningContainer = styled.div`
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${theme.gray2};
  color: ${theme.red2};
  .warning-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    font-size: large;
  }
`

interface ISearchOptions {
  facility_ids: Array<any>
  output: string
}

interface ISearchSection {
  facilityOptions: Array<any>
  searchOptions: ISearchOptions
  onSearchUpdate: (val) => void
  onSearchReset: () => void
  resetFacilityId?: string[]
}

export const FilterSection = ({
  facilityOptions,
  searchOptions,
  onSearchReset,
  onSearchUpdate,
  resetFacilityId,
}: ISearchSection) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility } = router?.query // initially selected facility

  const formik = useFormik({
    initialValues: {
      ...searchOptions,
      output: searchOptions.output ? searchOptions.output : +facility,
    },
    onSubmit: () => {
      onSearchUpdate(formik.values)
    },
  })

  const selectAllFacilities = () => {
    const values = facilityOptions
      ?.filter((fac) => {
        if (!fac.disabled) {
          return fac?.value
        }
      })
      ?.map((fltr) => fltr.value)

    formik.setFieldValue("facility_ids", values)
  }

  const clearAllFacilities = () => {
    formik.setFieldValue("facility_ids", [])
  }

  return (
    <Wrapper>
      <PageCard title={t("Operation Options")}>
        <WarningContainer>
          <div className={"warning-title"}>
            <img
              src={"/assets/icons/exclamation.png"}
              alt={"alert"}
              height={18}
              width={18}
            />
            {t(
              "Facilities that have not confirmed their billing information cannot be selected. "
            )}
          </div>
          <div className={"warning-content"}>
            {t(
              'If you want to add up the billed amount for children who are also using other facilities, please check the facilities to be output and press "Reflect"'
            )}
          </div>
        </WarningContainer>
        <div className={"label-value-container"}>
          <div className={"label"}>{t("Facility to output")}</div>
          <div className={"value"}>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Check all")}
              onClick={selectAllFacilities}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Clear")}
              onClick={clearAllFacilities}
            />
          </div>
        </div>
        <div className={"facility-checkboxes"}>
          <Checkboxes
            options={facilityOptions}
            name={"facility_ids"}
            value={formik.values.facility_ids}
            onChange={(val) => formik.setFieldValue("facility_ids", val)}
          />
        </div>
        <div className={"label-value-container flex-col"}>
          <div className={"label"}>
            {t("Company information to be output and remarks on the invoice")}
          </div>
          <div className={"value"}>
            <SelectInput
              options={facilityOptions}
              className={"min-auto-width"}
              height={40}
              name={"output"}
              value={formik.values.output}
              onChange={(val) => formik.setFieldValue("output", val)}
            />
          </div>
        </div>
        <div className={"button-container"}>
          <OwnerButton
            typeOf={"primary"}
            text={t("Reflect")}
            onClick={() => formik.handleSubmit()}
          />
          <OwnerButton
            typeOf={"secondary"}
            text={t("Reset search")}
            onClick={() => {
              formik.setFieldValue("facility_ids", resetFacilityId)
              formik.setFieldValue("output", null)
              onSearchReset()
            }}
          />
        </div>
      </PageCard>
    </Wrapper>
  )
}
