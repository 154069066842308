import React from "react"
import { UpOutlined } from "@ant-design/icons"
import { Collapse, Typography } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "@project/shared"
import Link from "next/link"
import moment from "moment"

const Wrapper = styled(Collapse)`
  border: 1px solid ${theme.red1};
  padding: 0.5rem;
  border-radius: 0.5rem;

  gap: 1rem;
  .title {
    display: flex;
    width: 50px !important;
    margin-right: 0px !important;
    svg {
      font-size: 14px;
      margin: 0 0.3rem 0 0.2rem;
      color: ${theme.red3};
    }
  }
  .sub {
    color: ${theme.red3};
    font-size: 16px;
  }
  .ctas {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .button {
      width: fit-content;
      img {
        margin-top: -0.2rem !important;
        margin-right: 0.3rem;
      }
      svg {
        font-size: 10px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .info_date_content {
    .values {
      margin-right: 10px;
      color: ${theme.red3};
      .key {
        margin-right: 0.7rem;
      }
      .value {
        a {
          color: ${theme.red3};
        }
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.lg}) {
    flex-direction: column;
    .ant-collapse-header,
    .ant-collapse-extra {
      flex-direction: column;
      row-gap: 0.5rem;
      margin-left: 0 !important;
    }
    .ctas {
      .button {
        display: flex;
        align-items: center;
        height: fit-content;
        span {
          font-size: 12px !important;
        }
      }
    }
  }
`

export const BillingDateInfoBox = ({
  usedTransports,
}: {
  usedTransports: Array<any>
}): JSX.Element => {
  const { t } = useTranslation()

  const renderDates = (dates, facilityId) => {
    try {
      const parsedDates = JSON.parse(dates)
      return parsedDates?.sort()?.map((date, index) => (
        <Link
          href={`/user-transport-management/${date}?facilityIds=${facilityId}`}
          key={index}
        >
          <a>{`${index > 0 ? ", " : ""}${moment(date).format(
            "YYYY年MM月DD日"
          )}`}</a>
        </Link>
      ))
    } catch (error) {
      return <></>
    }
  }

  return (
    <Wrapper
      defaultActiveKey={["1"]}
      ghost
      expandIcon={({ isActive }) => {
        return (
          <div className={"title"}>
            <img src={"/assets/icons/exclamation.png"} alt={""} />
            <UpOutlined rotate={isActive ? 180 : 0} />
          </div>
        )
      }}
    >
      <Collapse.Panel
        key={"1"}
        header={
          <Typography.Text className={"sub"}>
            {t(
              "Following are calculated based on past history. Confirm if these children’s actually use transportation in mentioned date."
            )}
            <br />
            {t(
              "※ Displayed for confirmation. If the displayed information is correct, please proceed with the request"
            )}
          </Typography.Text>
        }
      >
        <div className={"info_date_content"}>
          {usedTransports?.map((val, index) => (
            <Typography.Text key={index} className={"values"}>
              <span className={"key"}>
                {val?.child_name} {":"}
              </span>
              <span className={"value"}>
                {renderDates(val?.dates, val?.facility_id)}
              </span>
            </Typography.Text>
          ))}
        </div>
      </Collapse.Panel>
    </Wrapper>
  )
}
