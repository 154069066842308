import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Typography } from "antd"
import { RightOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import { useRouter } from "next/router"
import {
  Checkbox,
  Checkboxes,
  PageCard,
  SelectInput,
  getLocalstorageItem,
  setItemToLocalstorage,
} from "@project/shared"
import { Content, Wrapper } from "./UsersWhoNeedMonitoringFilter.styles"
import {
  getAllChildUnpaginated,
  UsersWhoNeedMonitoringParams,
} from "../../../services"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { AuthContext } from "../../../utils"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

interface IProps {
  handleSearch: (values: UsersWhoNeedMonitoringParams) => void
  handleScroll?: () => void
  planStatus: "list" | "draft"
}

const DATE_FORMAT = "YYYY-MM-DD"
export const UsersWhoNeedMonitoringFilter: React.FC<IProps> = ({
  handleSearch,
  planStatus,
  handleScroll,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    child_id,
    service,
    display_settings,
    facility: urlFacility,
    children_without_services: urlShowNotRegisteredServices,
    date,
    expiry_date,
  }: any = router.query
  const storedData = getLocalstorageItem(
    "users_who_need_monitoring_key",
    "facility_ids"
  )
  const urlDate = date?.split("_")
  const urlExpiry = expiry_date?.split("_")
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useContext(AuthContext)
  const [sortOption, setSortOption] = useState(0)
  const [tempChild, setTempChild] = useState(child_id || null)

  const [serviceType, setServiceType] = useState(
    service?.split(",")?.map((val) => parseInt(val, 10)) || []
  )
  const [facility, setFacility] = useState(
    urlFacility?.split(",")?.map((val) => parseInt(val, 10)) ||
      storedData?.split(",")?.map((val) => parseInt(val, 10)) ||
      []
  )

  const [createdDate, setCreatedDate] = useState({
    from: urlDate && urlDate[0] ? moment(urlDate[0]).format(DATE_FORMAT) : null,
    to: urlDate && urlDate[1] ? moment(urlDate[1]).format(DATE_FORMAT) : null,
  })
  const [expiryDate, setExpiryDate] = useState({
    from:
      urlExpiry && urlExpiry[0]
        ? moment(urlExpiry[0]).format(DATE_FORMAT)
        : null,
    to:
      urlExpiry && urlExpiry[1]
        ? moment(urlExpiry[1]).format(DATE_FORMAT)
        : null,
  })
  const [disDisplaySettings, setDisplaySettings] = useState(
    display_settings?.split(",")?.map((val) => parseInt(val, 10)) || []
  )
  const [showUnRegisteredServices, setShowUnRegisteredServices] = useState(
    urlShowNotRegisteredServices?.split(",")?.map((val) => parseInt(val, 10)) ||
      []
  )
  const { data: children } = useQuery<any>(
    ["fetch-child-data-only"],
    getAllChildUnpaginated,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: ({ data }) =>
        data.map((d) => ({
          label: d.child_name,
          value: d.id,
          furigana: d.child_name_furigana,
        })),
    }
  )
  const URL = `/users-who-need-monitoring`
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target
    const updatedFacility = checked
      ? [...facility, value]
      : facility.filter((item) => item !== value)
    setFacility(updatedFacility)
  }

  const onSearch = () => {
    const values = {
      child_id: tempChild,
      service: serviceType?.toString(),
      facility: facility?.toString(),
      date: createdDate?.from
        ? `${createdDate?.from}_${
            createdDate?.to ||
            moment(createdDate?.from).add(1, "M").format(DATE_FORMAT)
          }`
        : "",
      expiry_date: expiryDate?.from
        ? `${expiryDate?.from}_${
            expiryDate?.to ||
            moment(expiryDate?.from).add(1, "M").format(DATE_FORMAT)
          }`
        : "",
      display_settings: disDisplaySettings?.toString(),
      children_without_services: showUnRegisteredServices?.toString(),
    }
    setItemToLocalstorage("users_who_need_monitoring_key", {
      facility_ids: values?.facility,
    })
    handleSearch(values)
    updateParams(values, URL)
    handleScroll && handleScroll()
  }
  const onResetSearch = () => {
    setTempChild("")
    setServiceType([])
    setFacility([])
    setCreatedDate({
      from: null,
      to: null,
    })
    setExpiryDate({
      from: null,
      to: null,
    })
    setDisplaySettings([])
    setShowUnRegisteredServices([])
    setServiceType([])
    setItemToLocalstorage("users_who_need_monitoring_key", {
      facility_ids: "",
    })
    handleSearch({
      child_id: null,
      service: "",
      facility: "",
      date: "",
      expiry_date: "",
      display_settings: "",
      children_without_services: "",
    })
    updateParams(
      {
        page: 1,
      },
      URL
    )
    handleScroll && handleScroll()
  }
  return (
    <Wrapper>
      <PageCard title={t("Operation options")}>
        <Content>
          <div className={"title"}>
            <Typography.Title level={5}>{t("Narrow down")}</Typography.Title>
          </div>
          <div className={"facility"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Facility")}
              </Typography.Paragraph>
            </div>
            <div className={"facility__checkbox"}>
              <div className={"check_uncheck_all"}>
                <Button
                  icon={<img src={"/assets/icons/check.svg"} alt={"icon"} />}
                  shape={"round"}
                  onClick={() => {
                    setFacility(facilities?.map((li) => li.id))
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Check all")}
                  </Typography.Text>
                </Button>
                <Button
                  shape={"round"}
                  onClick={() => {
                    setFacility([])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Uncheck all")}{" "}
                  </Typography.Text>
                </Button>
              </div>
              <div className={"checkboxs"}>
                {facilities?.map((faci, i) => (
                  <Checkbox
                    key={i}
                    onChange={onFacilityChecked}
                    checked={facility?.includes(faci?.id)}
                    value={faci?.id || ""}
                    label={faci?.facility_name_short}
                  />
                ))}
              </div>
            </div>
          </div>
          {planStatus === "list" ? (
            <div className={"use__service"}>
              <div className={"label"}>
                <Typography.Paragraph>
                  <RightOutlined className={"icon"} />
                  {t("Use Service")}
                </Typography.Paragraph>
              </div>
              <div className={"use__service--checkbox"}>
                <Checkboxes
                  options={[
                    {
                      value: "1",
                      label: t("After school day service"),
                    },
                    {
                      value: "2",
                      label: t("Child development support"),
                    },
                  ]}
                  value={serviceType}
                  onChange={setServiceType}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"status"}>
            <div className={"label"}>
              <Typography.Paragraph className={"long-label-text"}>
                <RightOutlined className={"icon"} />
                {t("Children whose services are not registered")}
              </Typography.Paragraph>
            </div>
            <div className={"display__settings--box"}>
              <Checkboxes
                options={[
                  {
                    value: "1",
                    label: t("Show"),
                  },
                ]}
                value={showUnRegisteredServices}
                onChange={setShowUnRegisteredServices}
              />
            </div>
          </div>
          <div className={"child__picker"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Child name")}
              </Typography.Paragraph>
            </div>
            <div className={"child__picker--furigana"}>
              <SelectInput
                options={FuriganaAlphabetsOption}
                height={40}
                value={sortOption}
                onChange={(val) => {
                  setSortOption(val)
                  if (
                    !children
                      .filter((data) => data.value === tempChild)?.[0]
                      ?.furigana?.startsWith(sortOption)
                  ) {
                    setTempChild(null)
                  }
                }}
                placeholder={"---"}
              />
            </div>
            <div className={"child__picker--name"}>
              <SelectInput
                height={40}
                width={"auto"}
                className={"dynamic-dropdown-width min-w-300"}
                options={
                  sortOption
                    ? children?.filter((child) =>
                        child.furigana?.startsWith(sortOption)
                      )
                    : children || []
                }
                onChange={setTempChild}
                value={tempChild}
                placeholder={t("All")}
              />
            </div>
          </div>
          <div className={"display__settings"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Display setting")}
              </Typography.Paragraph>
            </div>
            <div className={"status--box"}>
              <Checkboxes
                options={[
                  {
                    value: "1",
                    label: t("Only display alternative assistance"),
                  },
                ]}
                value={disDisplaySettings}
                onChange={setDisplaySettings}
              />
            </div>
          </div>
          <div className={"filter__cta"}>
            <OwnerButton
              text={t("Search")}
              icon={"search"}
              shape={"circle"}
              onClick={onSearch}
              className={"search_btn"}
            />
            <OwnerButton
              text={t("Reset search")}
              shape={"circle"}
              typeOf={"secondary"}
              onClick={onResetSearch}
              className={"reset_search_btn"}
            />
          </div>
        </Content>
      </PageCard>
    </Wrapper>
  )
}
