import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button, Card, Skeleton } from "antd"
import styled from "styled-components"
import { OwnerButton } from "../.."
import {
  MonthlyStaffAttendanceOperationOptions,
  AttendanceScheduleShiftTimeRange,
} from "../../molecules"
import { theme } from "@project/shared"
import moment from "moment"
import Calendar from "react-calendar"
// import { getMonth } from "@wojtekmaj/date-utils"
import { useTranslation, Trans } from "react-i18next"
import Link from "next/link"
import { useMutation } from "react-query"
import { toastMsg } from "../../../utils/toastMsg"
import { createStaffAttendanceShiftSchedule } from "../../../services/"
import { useState } from "react"
import { useRouter } from "next/router"
import { getMonth } from "@wojtekmaj/date-utils"

const MainContent = styled.div`
  margin: 20px 0;
  @media print {
    @page {
      size: auto;
      margin: 0;
    }
    * {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact !important; /* Firefox 48 – 96 */
      print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
    }
    body * {
      visibility: hidden;
    }
    .section-to-print {
      visibility: visible;
    }
    .section-not-to-print {
      display: none !important;
    }
    .schedule-button {
      display: none;
      padding: 0 !important;
    }
    .ant-card-body {
      padding: 0 24px;
      overflow: visible !important;
    }
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      padding: 0px !important;
      text-align: center;
    }
    .ant-select-arrow {
      display: none;
    }
    .schedule-container {
      margin: 0px !important;
    }
    .inner-schedule-container,
    .inner-schedule-container > div {
      padding: 0px !important;
    }
    .inputs {
      flex-direction: row !important;
      flex-wrap: wrap;
      gap: 0px !important;
      padding-bottom: 2px;
    }
    .inputs > div {
      max-height: 20px !important;
    }
    .react-calendar__month-view__days__day > div {
      padding-bottom: 0px !important;
    }
    .inputs > div:not(.break-time-container) > div {
      font-size: 12px !important;
      max-width: 14px !important;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .time_sep {
      margin: 0 3px !important;
      font-size: 14px !important;
    }
    .break-time-input-unit input {
      border: none !important;
      background: transparent !important;
      padding: 0 !important;
      max-width: 30px !important;
    }
    margin: 0 !important;
  }
  .custom-calendar-wrapper {
    overflow: auto;
    display: block;
    @media print {
      overflow: visible !important;
      display: table;
    }
    thead {
      display: none;
      @media print {
        border: none;
        display: table-header-group;
        th {
          border: thin solid #d2d1d1;
          border-bottom: none;
          background-color: #f3f3f3;
          width: calc(100% / 7);
        }
      }
    }
  }
`

const NoticeWrapper = styled.div`
  margin: 20px 0;
  padding: 20px 20px 30px 20px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #209472;
  color: #209472;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  div {
    margin-bottom: 5px;
  }
  .with-underline {
    text-decoration: underline;
  }
  @media print {
    display: none;
  }
`
const StyledMonthPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: 10px;
    height: 40px;
    margin-right: 10px;
    padding: 0px;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
  }
  @media print {
    display: none;
  }

  @media screen and (max-width: 426px) {
    margin-left: auto;
    margin-right: -10px;
    margin-top: 24px;
  }
`
const StyledPageCard = styled(Card)`
  margin: 40px 0;
  border-radius: 10px;
  padding-bottom: 40px;

  .info-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 24px;

    @media screen and (max-width: 426px) {
      gap: 0;
    }
  }
  .print-container {
    display: flex;
    justify-content: end;
    margin: 10px 0;
    button {
      background-color: ${theme.gray3} !important;
      border-color: ${theme.gray3} !important;
    }

    @media screen and (max-width: 426px) {
      .print-btn {
        margin-left: auto;
      }
    }
  }
  .button-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    @media screen and (max-width: 426px) {
      gap: 0;
      button:last-child {
        margin-left: 12px;
      }
    }
  }
  .with-underline {
    text-decoration: underline;
  }
  .color-blue {
    color: ${theme.link};
  }
  @media print {
    margin: 0 !important;
    padding-bottom: 0 !important ;
    border: 0 !important;
    .ant-card-head {
      padding-top: 10px;
      min-height: auto !important;
      margin-bottom: 2px !important;
      background: transparent !important;
      border-bottom: 0 !important;
      .ant-card-head-title {
        padding: 6px 0 !important;
        font-weight: bold !important;
      }
      .ant-card-head-title::first-letter {
        text-transform: capitalize !important;
      }
    }
  }
`
const StyledCalendar = styled(Calendar)`
  margin-top: 25px;
  margin-bottom: 25px;
  [class*="neighboringMonth"] {
    cursor: default;
    > div {
      visibility: hidden;
      display: none;
    }
    abbr {
      display: none;
    }
  }
  [class*="weekday"] {
    border: 0.1px solid #d2d1d1;
    border-bottom: 0;
    text-align: center;
    background-color: #f3f3f3;
    abbr {
      cursor: default;
      text-decoration: none;
    }
  }
  [class*="tile"] {
    border: 0.1px solid #d2d1d1;
    background: transparent;
    position: relative;
    display: flex;

    > div {
      padding: 40px 4px;
    }
    abbr {
      background: #f3f3f3;
      border: 0.1px solid #d2d1d1;
      border-top: 0;
      border-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 37px;
    }
  }

  @media print {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .react-calendar__month-view__days__day--neighboringMonth > div {
      display: block !important;
    }
    .react-calendar__month-view > div > div {
      display: table !important;
      overflow: visible !important;
      .react-calendar__month-view__weekdays {
        display: none !important;
      }
      .react-calendar__month-view__days {
        display: table-footer-group !important;
        width: 100%;

        padding: 100px !important;
        .react-calendar__tile {
          display: table-cell;
          width: 14.28%;
          height: 100%;
          page-break-inside: avoid;

          > div {
            padding: 10px 2px !important;
          }
          .attendance-content {
            .shift-select {
              .ant-select-selector {
                span {
                  font-size: 12px !important;
                }
              }
            }
            .schedule-container {
              height: fit-content !important;
              .inner-schedule-container {
                padding: 5px 3px 15px 3px !important;
                .content {
                  h5 {
                    font-size: 10px !important;
                    margin-bottom: 0 !important;
                  }
                  .inputs {
                    & > div {
                      font-size: 10px !important;
                    }
                    .first-hours,
                    .last-hours {
                      & > .left-align {
                        max-width: 13px !important;
                        .ant-select.left-align {
                          span {
                            font-size: 12px !important;
                          }
                        }
                      }
                      .time_sep,
                      .range_sep {
                        font-size: 12px !important;
                      }
                    }

                    .break-time-container {
                      gap: 0 !important;
                      margin-top: 2px !important;
                      p {
                        font-size: 10px !important;
                      }
                    }
                  }
                }
              }
            }
          }
          abbr {
            background: transparent !important;
            width: auto !important;
            height: auto !important;
            font-size: 12px !important;
            border: 0 !important;
            top: -8px !important;
            right: 5px !important;
          }
        }
        .react-calendar__tile:nth-child(7n) {
          border-right: 0.1px solid ${theme.gray2} !important;
        }
      }
    }
  }

  @media not print {
    @media screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
      overflow: auto;
      [class*="__days"],
      [class*="__weekdays"] {
        width: 100%;
      }
      .react-calendar__viewContainer {
        width: 980px;
      }
    }

    @media screen and (max-width: 600px) {
      max-width: 100%;
      overflow: auto;
      .react-calendar__viewContainer {
        width: 980px;
      }
    }
  }
`

export const StaffMonthlyAttendanceSchedulePage = ({
  year,
  month,
  facility,
  facilityOptions,
  handleDisplayChange,
  pageTitle,
  staffList,
  activeStaff,
  facilitySchedules,
  monthlyStaffData,
  monthlyDataLoading,
  holidays,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const monthlyStaffObject = {}
  monthlyStaffData?.attendance_staff_shift_schedule?.map((item) => {
    if (item?.deleted_datetime) item.is_deleted = true
    monthlyStaffObject[moment(item.date).format("YYYY-MM-DD")] = item
  })
  const [staffCalendarData, setStaffCalendarData] = useState({})
  const { mutate, isLoading } = useMutation(
    createStaffAttendanceShiftSchedule,
    {
      onSuccess: () => {
        toastMsg("success", t("Updated Successfully"))
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )
  const handleSave = () => {
    const postData = Object.values(staffCalendarData)
      .map(({ ...val }) => {
        const values: any = {
          staff_id: activeStaff.value,
          attendance_shift_id: val.attendance_shift_id,
          date: val?.date,
          attendance_start_time: val.attendance_start_time,
          attendance_end_time: val.attendance_end_time,
          is_deleted: val.is_deleted,
          attendance_rest_minits: parseInt(val.attendance_rest_minits, 10),
        }
        if (val?.id) {
          values.id = val?.id
        }
        if (val?.attendance_start_time2 && val?.attendance_end_time2) {
          values.attendance_start_time2 = val?.attendance_start_time2
          values.attendance_end_time2 = val?.attendance_end_time2
          values.attendance_rest_minits2 = parseInt(
            val.attendance_rest_minits2,
            10
          )
        }
        return values
      })
      .filter((val) => {
        return Boolean(!isNaN(val.attendance_shift_id))
      })
    mutate({ facilityId: facility.value, values: postData })
  }
  const getWeeklyScheduleForDay = (day) => {
    switch (day) {
      case 0:
        return monthlyStaffData?.sun_schedule
      case 1:
        return monthlyStaffData?.mon_schedule
      case 2:
        return monthlyStaffData?.tue_schedule
      case 3:
        return monthlyStaffData?.wed_schedule
      case 4:
        return monthlyStaffData?.thu_schedule
      case 5:
        return monthlyStaffData?.fri_schedule
      case 6:
        return monthlyStaffData?.sat_schedule
      default:
        return {}
    }
  }
  return (
    <MainContent>
      <MonthlyStaffAttendanceOperationOptions
        initialValues={{
          year,
          month,
          facility: facility?.value,
          staff: activeStaff?.value,
        }}
        facilityOptions={facilityOptions}
        staffOptions={staffList}
        handleDisplayChange={handleDisplayChange}
      />
      <NoticeWrapper>
        <img
          src={"/assets/icons/alert-icon-with-green-border.svg"}
          alt={"icon"}
        />
        <div>
          <div>{t("It reflects the work pattern of the week.")}</div>
          <div>
            {t(
              "Weekly work patterns: Sun/unspecified, Mon/early, Tue/early, Wed/early, Thu/early, Fri/early, Sat/unspecified"
            )}
          </div>
          <div className={"with-underline"}>
            <Link href={"/shift-master"}>
              <a>{t("Click here for weekly work pattern settings")}</a>
            </Link>
          </div>
        </div>
      </NoticeWrapper>
      <StyledPageCard className={"calendar-container"} title={pageTitle}>
        <div className={"info-pagination-wrapper section-not-to-print"}>
          <div className={"info-wrapper"}>
            {t(
              "Please select a shift from the pull-down menu for each date in the calendar below."
            )}
            <br />
            <Trans i18nKey={"shiftRegisterSelectInfo"}>
              {"The shifts that can be selected are those registered in the"}
              <a
                target={"_blank"}
                href={"/shift-master"}
                rel={"noopener noreferrer"}
                className={"with-underline color-blue"}
              >
                {"shift master."}
              </a>
            </Trans>
          </div>
          <StyledMonthPaginationWrapper>
            <Button
              onClick={() => {
                let currentYear = year
                let currentMonth = month
                if (month == 1) {
                  currentMonth = 12
                  --currentYear
                } else {
                  --currentMonth
                }
                handleDisplayChange({ year: currentYear, month: currentMonth })
              }}
              className={"left-btn"}
            >
              <DoubleLeftOutlined />
              <span className={"left-btn-label"}>{t("Last month")}</span>
            </Button>
            <Button
              className={"active active-btn"}
              onClick={() => {
                const today = new Date()
                const month = today.getMonth() + 1
                const year = today.getFullYear()
                handleDisplayChange({ year, month })
              }}
            >
              {t("This month")}
            </Button>
            <Button
              onClick={() => {
                let currentYear = year
                let currentMonth = month
                if (currentMonth == 12) {
                  ++currentYear
                  currentMonth = 1
                } else {
                  ++currentMonth
                }
                handleDisplayChange({ year: currentYear, month: currentMonth })
              }}
              className={"right-btn"}
            >
              <span className={"right-btn-label"}>{t("Next month")}</span>
              <DoubleRightOutlined />
            </Button>
          </StyledMonthPaginationWrapper>
        </div>
        <div className={"print-container section-not-to-print"}>
          <OwnerButton
            text={t("Print this")}
            icon={"print"}
            className={"print-btn"}
            onClick={() => {
              if (typeof window != "undefined") {
                const allDateBlocks = document.getElementsByClassName(
                  "react-calendar__tile"
                )
                const maxHeight = []
                for (let i = 0; i < allDateBlocks.length; i++) {
                  const thisHeight = allDateBlocks[i].clientHeight
                  const index = Math.floor(i / 7)
                  if (!maxHeight[index]) {
                    maxHeight[index] = thisHeight
                  } else if (thisHeight > maxHeight[index]) {
                    maxHeight[index] = thisHeight
                  }
                }
                let css = ``
                maxHeight.map((height, index) => {
                  for (let i = 1; i <= 7; i++) {
                    css += `.react-calendar__tile:nth-child(${
                      index * 7 + i
                    }){ .attendance-content { height: ${
                      height < 450
                        ? height <= 120
                          ? "120px"
                          : height < 250
                          ? "fit-content"
                          : "140px"
                        : "215px"
                    }}} `
                  }
                })
                const styleId = "attendance-print-style"
                const style =
                  document.getElementById(styleId) ||
                  document.createElement("style")
                style.id = styleId
                style.innerHTML = `
                    @media print {
                      ${css}
                    }
                  `
                document.head.appendChild(style)

                window.print()
              }
            }}
          />
        </div>
        {monthlyDataLoading ? (
          <Skeleton></Skeleton>
        ) : (
          <table className={"custom-calendar-wrapper"}>
            <thead>
              <tr>
                <th>{t("Sun")}</th>
                <th>{t("Mon")}</th>
                <th>{t("Tue")}</th>
                <th>{t("Wed")}</th>
                <th>{t("Thu")}</th>
                <th>{t("Fri")}</th>
                <th>{t("Sat")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={7}>
                  <StyledCalendar
                    className={"section-to-print custom-calendar-style"}
                    tileContent={({ date, activeStartDate }) => (
                      <AttendanceScheduleShiftTimeRange
                        currentDateString={moment(date).format("YYYY-MM-DD")}
                        scheduleOptions={facilitySchedules}
                        monthlyStaffData={monthlyStaffObject}
                        weeklyScheduleData={getWeeklyScheduleForDay(
                          date.getDay()
                        )}
                        calendarData={staffCalendarData}
                        setCalendarData={setStaffCalendarData}
                        isHoliday={
                          Array.isArray(holidays) &&
                          holidays.includes(moment(date).format("YYYY-MM-DD"))
                        }
                        renderBlank={
                          date.getMonth() != getMonth(activeStartDate)
                        }
                      />
                    )}
                    formatDay={(_, date) => moment(date).format("DD")}
                    showNavigation={false}
                    locale={"ja"}
                    calendarType={"US"}
                    activeStartDate={new Date(year, month - 1, 1)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <div className={"button-container section-not-to-print"}>
          <OwnerButton
            text={t("Cancel")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              const query = []
              if (year) query.push(`year=${year}`)
              if (month) query.push(`month=${month}`)
              if (facility) query.push(`facility=${facility?.value}`)
              router.push(`/attendance-schedule?${query.join("&")}`)
            }}
          />
          <OwnerButton
            text={t("Save")}
            shape={"circle"}
            onClick={handleSave}
            isLoading={isLoading}
          />
        </div>
      </StyledPageCard>
    </MainContent>
  )
}
