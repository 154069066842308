import { theme } from "@project/shared"
import { Typography } from "antd"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface IWrapper {
  disabled: boolean
}

const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: 100%;
  border: 1px solid ${theme.bgColor2};
  display: flex;

  gap: 3rem;

  a {
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  }

  .left {
    flex: 1;
    border-right: 1px solid ${theme.gray2};
  }
  .right {
    flex: 1;
  }

  ul {
    padding: 1rem 2rem;
    color: ${theme.blue5};
    li {
      a {
        text-decoration: underline;
        font-size: 14px;
      }
      div {
        font-size: 14px;
      }
    }
  }

  .disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.disabled ? "100%" : "0")};
    height: ${(props) => (props.disabled ? "100%" : "0")};
    background: rgba(255, 255, 255, 0.6);
    z-index: 99;
    pointer-events: none;
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    .left {
      border-right: unset !important;
      border-bottom: 1px solid ${theme.gray2};
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
`
export const BillingBottomListInfo: React.FC<{
  disabled: boolean
  selectedChildId?: string[]
  showBillingDifferenceLink?: boolean
  facility_id: string | number
  year: string | number
  month: string | number
  form_of_provision: string | number
}> = ({
  disabled,
  showBillingDifferenceLink,
  facility_id,
  year,
  month,
  form_of_provision,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper id={"printPtherList"} disabled={disabled}>
      <div className={"disable-overlay"}></div>
      <div className={"left"}>
        <ul>
          {[
            {
              title: t(
                "Disabled Child Outpatient Benefits, Admission Benefits, etc. Printing of Bills and Statements"
              ),
              subTitle: t(
                "※Please print out when submitting on paper or when storing"
              ),
              link: `national-health-insurance-billing/prints/disabled-child-outpatient-benefits?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
            },
            {
              title: t("Printing of service provision record sheet"),
              subTitle: t(
                "※Please print out when submitting on paper or when storing"
              ),
              link: `/service-provision-record-sheet/print?facility_ids=${facility_id}&certificate_applicable_year=${year}&certificate_applicable_month=${month}&select_all=1`,
            },
            {
              title: t("Print summary list"),
              subTitle: t(
                "※Please print this out if you want to see a list of each user's amount billed to the National Health Insurance Federation."
              ),
              link: `/national-health-insurance-billing/prints/print-summary-list?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
            },
            {
              title: t("Zengin format output"),
              subTitle: "",
              link: `/national-health-insurance-billing/prints/all-bank-format-output?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
            },
            {
              title: t("Print User Billing List"),
              subTitle: "",
              link: `/national-health-insurance-billing/prints/print-user-billing-list?facility_ids=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
            },
          ]?.map((val, index) => (
            <li key={index}>
              <Link href={`${val?.link}`} target={"_blank"}>
                <a target={"_blank"}>{t(val?.title)}</a>
              </Link>
              <Typography.Paragraph style={{ color: theme.black }}>
                {val?.subTitle}
              </Typography.Paragraph>
            </li>
          ))}
        </ul>
      </div>
      <div className={"right"}>
        <ul>
          {[
            {
              title: t("Printing invoices to users"),
              subTitle: t("※Please print when issuing invoices to users."),
              // link: `/national-health-insurance-billing/prints/disabled-child-outpatient-benefits?facility_id=${facility_id}&year=${year}&month=${month}`,
              link: `/national-health-insurance-billing/prints/print-invoice-customer?facility=${facility_id}&year=${year}&month=${month}&facility_ids=${facility_id}&after_school=${form_of_provision}`,
            },
            {
              title: t("Print receipts for users"),
              subTitle: t("※Please print when issuing invoices to users."),
              link: `/national-health-insurance-billing/prints/print-reciept-user?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
            },
            {
              title: t("Print proxy acknowledgment"),
              link: `/national-health-insurance-billing/prints/print-proxy-acknowledgement?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
              subTitle: "",
            },
            {
              title: t("Print address to user"),
              link: `/national-health-insurance-billing/prints/user-labels?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`,
              subTitle: "",
            },
            {
              title: t("Difference billing list"),
              link: `/national-health-insurance-billing/difference-invoice-list?facility_id=${facility_id}&year=${year}&month=${month}`,
              subTitle: "",
              hide: !showBillingDifferenceLink,
            },
          ]?.map(
            (val, index) =>
              !val?.hide && (
                <li key={index}>
                  <Link href={`${val?.link}`} target={"_blank"}>
                    <a target={"_blank"}>{t(val?.title)}</a>
                  </Link>
                  <Typography.Paragraph style={{ color: theme.black }}>
                    {val?.subTitle}
                  </Typography.Paragraph>
                </li>
              )
          )}
        </ul>
      </div>
    </Wrapper>
  )
}
