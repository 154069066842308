import { theme } from "@project/shared"
import styled from "styled-components"

export const HiroshimaBurdenTable = styled.table`
  width: 100%;
  .hiroshima-col {
    border: 1px solid ${theme.gray9};
    min-width: 2em;
    font-size: 12px;
    padding: 2px;
  }
  .ordered-list {
    list-style-position: inside;
    .list {
      font-size: 12px;
    }
  }
`
