import { API } from "@project/shared"
export const getAllChildByFacilityId = (facilityId: string) =>
  API.get(`/child?pageSize=infinity&facility_id=${facilityId}`)

export const bulkCreateAdditionSetting = (values: any) => {
  return API.post(`/child-addition-setting-management/bulk`, values)
}

export const getAllAdditionSettingChildDay = ({
  date,
  child_id,
  facility_id,
}: {
  date: string
  child_id?: string
  facility_id: string
}) => {
  const URL = child_id
    ? `/child-addition-setting-management?pageSize=Infinity&facility_id=${facility_id}&child_id=${child_id}&date=${date}`
    : `/child-addition-setting-management?pageSize=Infinity&facility_id=${facility_id}&date=${date}`
  return API.get(URL)
}
