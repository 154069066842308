import { SelectInput, theme } from "@project/shared"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button } from "../../atoms"
import { AttendanceScheduleTimeRangeInput } from "../AttendanceScheduleTimeRange"

const Wrapper = styled.div`
  background: #f5f5f5;
  border: 1px solid #d2d1d1;
  border-radius: 12px;
  padding: 8px;
  width: auto;
  max-width: 100%;
  &.invalid-schedule {
    h5 {
      color: ${theme.red2};
    }
    border-color: ${theme.red2};
  }
  .inner-schedule-container {
    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      .ant-typography {
        margin-bottom: 0;
      }
      .inputs {
        display: flex;
        flex-direction: row;
        .ant-select {
          width: 65px;
        }
      }
    }
  }
  .button-container {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

interface IScheduleButton {
  type: "remove" | "add"
}

export type CalendarHours = {
  id: number
  from: {
    hours: string
    minutes: string
  }
  to: {
    hours: string
    minutes: string
  }
  break_time: number
}

const QuickAttendanceScheduleUpdateForm = ({
  scheduleDetail = null,
  scheduleOptions = null,
  onSave,
}) => {
  const { t } = useTranslation()
  const scheduleOptionsWithReset: any = [
    {
      id: 0,
      label: "---",
      value: 0,
      attendance_end_time: null,
      attendance_end_time_2: null,
      attendance_rest_minits: 0,
      attendance_rest_minits_2: 0,
      attendance_start_time: null,
      attendance_start_time_2: null,
    },
  ].concat(scheduleOptions)
  const initialValues = {
    from: {
      hours: "--",
      minutes: "--",
    },
    to: {
      hours: "--",
      minutes: "--",
    },
    break_time: 0,
  }
  const possibleScheduleIds = [1, 2]

  const getHours = () => {
    const prefixKey = "attendance"
    const ids = possibleScheduleIds
    const shiftHours = []
    if (!scheduleDetail?.id)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    for (let i = 1; i <= ids.length; i++) {
      const postfixKey =
        !scheduleDetail?.daySchedule && i > 1 ? `_${i}` : i > 1 ? i : ""
      const startTimeKey = prefixKey + "_start_time" + postfixKey
      const startTimes = scheduleDetail[startTimeKey]?.split(":")
      const START_HOUR = startTimes ? startTimes[0] : null
      const START_MINUTE = startTimes ? startTimes[1] : null

      const endTimeKey = prefixKey + "_end_time" + postfixKey
      const endTimes = scheduleDetail[endTimeKey]?.split(":")
      const END_HOUR = endTimes ? endTimes[0] : null
      const END_MINUTE = endTimes ? endTimes[1] : null

      const breakTimeKey = prefixKey + "_rest_minits" + postfixKey
      const BREAK_TIME = scheduleDetail[breakTimeKey] ?? null
      if (
        i === 1 ||
        START_HOUR ||
        START_MINUTE ||
        END_HOUR ||
        END_MINUTE ||
        BREAK_TIME
      )
        shiftHours.push({
          id: i,
          from: {
            hours: START_HOUR ?? null,
            minutes: START_MINUTE ?? null,
          },
          to: {
            hours: END_HOUR ?? null,
            minutes: END_MINUTE ?? null,
          },
          break_time: BREAK_TIME ?? null,
        })
    }
    return shiftHours
  }

  const [isInvalid, setInvalid] = useState(false)
  const [shiftHours, setShiftHours] = useState<Array<CalendarHours>>(getHours())
  const [selectedAttendanceShift, setSelectedAttendanceShift] = useState(
    scheduleDetail?.daySchedule
      ? scheduleDetail?.attendance_shift_id
        ? scheduleDetail.attendance_shift_id
        : null
      : scheduleDetail?.id
      ? scheduleDetail.id
      : null
  )

  const isSelectedShiftHoliday = useMemo(() => {
    const selectedShift = scheduleOptionsWithReset?.find(
      (shift) => shift?.id == selectedAttendanceShift
    )
    return !!selectedShift?.number_of_employees_include_flg
  }, [selectedAttendanceShift])

  const onTimeRangeChange = ({ id, range, unit, value }: any) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    if (range == "break_time") {
      data[index] = {
        ...data[index],
        [range]: value,
      }
    } else {
      data[index] = {
        ...data[index],
        [range]: {
          ...data[index][range],
          [unit]: value,
        },
      }
    }
    setShiftHours(data)
  }

  const addNewSchedule = () => {
    setShiftHours([
      ...shiftHours,
      { id: shiftHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastSchedule = () => {
    const data = shiftHours?.splice(-1)[0]
    setShiftHours(shiftHours?.filter((v) => v.id !== data.id))
  }

  const ScheduleButton = ({ type }: IScheduleButton) => {
    const { t } = useTranslation()
    return (
      <div className={"schedule-button"}>
        <Button
          text={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"#ffffff"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewSchedule() : removeLastSchedule()
          }}
        />
      </div>
    )
  }

  const setTimeFromSchedule = (scheduleId) => {
    const scheduleData = scheduleOptionsWithReset.find(
      (schedule) => schedule.id == scheduleId
    )

    const getShiftData = (startKey, endKey, breakKey) => {
      if (!scheduleData) return null
      const startTime = scheduleData[startKey]?.split(":")
      const endTime = scheduleData[endKey]?.split(":")
      const breakTime = scheduleData[breakKey]

      if (startTime?.length < 2 && endTime?.length < 2 && !breakTime)
        return null

      return {
        from: {
          hours: startTime && startTime[0],
          minutes: startTime && startTime[1],
        },
        to: {
          hours: endTime && endTime[0],
          minutes: endTime && endTime[1],
        },
        break_time: breakTime,
      }
    }

    const newShifts = []

    if (
      scheduleId != 0 &&
      scheduleData &&
      scheduleData?.number_of_employees_include_flg != 1
    ) {
      possibleScheduleIds.map((id) => {
        const startKey =
          id > 1 ? "attendance_start_time_" + id : "attendance_start_time"
        const endKey =
          id > 1 ? "attendance_end_time_" + id : "attendance_end_time"
        const breakKey =
          id > 1 ? "attendance_rest_minits_" + id : "attendance_rest_minits"
        const shiftHour = getShiftData(startKey, endKey, breakKey)
        if (shiftHour) newShifts.push({ ...shiftHour, id })
      })
    } else {
      // to show field in UI
      newShifts.push({ id: 1, ...initialValues })
    }
    setShiftHours(newShifts)
  }

  const checkValidation = () => {
    // holiday shift
    if (shiftHours.length == 0) return true
    const timeObj = {
      startInMinute1: 0,
      endInMinute1: 0,
      restInMinute1: 0,
      startInMinute2: 0,
      endInMinute2: 0,
      restInMinute2: 0,
    }

    shiftHours.map((shiftHour) => {
      timeObj[`startInMinute${shiftHour.id}`] =
        +shiftHour.from.hours * 60 + +shiftHour.from.minutes
      timeObj[`endInMinute${shiftHour.id}`] =
        +shiftHour.to.hours * 60 + +shiftHour.to.minutes
      timeObj[`restInMinute${shiftHour.id}`] = +shiftHour.break_time
    })
    const netMinute =
      timeObj.endInMinute1 -
      timeObj.startInMinute1 -
      timeObj.restInMinute1 +
      (timeObj.endInMinute2 - timeObj.startInMinute2 - timeObj.restInMinute2)

    if (netMinute <= 0) return false
    return true
  }

  const handleSumbit = () => {
    if (!checkValidation()) {
      setInvalid(true)
      return
    }
    setInvalid(false)
    const scheduleData: any = {
      facility_id: scheduleDetail?.facilityId,
      staff_id: scheduleDetail?.staffId,
      date: scheduleDetail?.date,
      attendance_shift_id: selectedAttendanceShift,
    }
    shiftHours.map((shiftHour) => {
      const prefix = "attendance_"
      const postfix = shiftHour.id > 1 ? shiftHour.id : ""
      const startTimeKey = prefix + "start_time" + postfix
      scheduleData[startTimeKey] =
        shiftHour.from.hours + ":" + (shiftHour.from.minutes ?? "00")
      const endTimeKey = prefix + "end_time" + postfix
      scheduleData[endTimeKey] =
        shiftHour.to.hours + ":" + (shiftHour.to.minutes ?? "00")
      const breakTimeKey = prefix + "rest_minits" + postfix
      scheduleData[breakTimeKey] = +shiftHour.break_time
    })

    if (scheduleDetail?.daySchedule) scheduleData.id = scheduleDetail?.id
    onSave(scheduleData)
  }

  return (
    <Wrapper className={isInvalid ? "invalid-schedule" : ""}>
      <div className={"shift-select"}>
        <SelectInput
          placeholder={"-----"}
          options={scheduleOptionsWithReset}
          onChange={(val) => {
            setSelectedAttendanceShift(val)
            setTimeFromSchedule(val)
          }}
          value={selectedAttendanceShift}
          width={150}
        />
      </div>
      <div className={"schedule-container"}>
        {shiftHours.map((range, index) => (
          <div key={index} className={"inner-schedule-container"}>
            <AttendanceScheduleTimeRangeInput
              key={range?.id}
              range={range}
              rangeName={`${t("Working hours")} ${range?.id}`}
              handleChange={(val) => {
                onTimeRangeChange(val)
              }}
              disabled={!selectedAttendanceShift || isSelectedShiftHoliday}
            />
          </div>
        ))}
        <div className={"button-container"}>
          {shiftHours.length > 0 && (
            <ScheduleButton
              type={
                shiftHours.length == possibleScheduleIds.length
                  ? "remove"
                  : "add"
              }
            />
          )}
          <Button
            text={t("Save")}
            backgroundColor={theme.blue5}
            color={"#ffffff"}
            shape={"circle"}
            onClick={handleSumbit}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export { QuickAttendanceScheduleUpdateForm }
