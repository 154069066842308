import React, { Fragment, useRef, useState } from "react"
import { Skeleton, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { DisabledChildOutpatientBenefitsFilter } from "./DisabledChildOutpatientBenefitsFilter"
import { OwnerButton } from "../../atoms"
import { AlertContent, CtaBtns, PrintBoxContent, Wrapper } from "./index.style"
import { DisabledChildOutpatientBenefitsFacilitySumTable } from "./DisabledChildOutpatientBenefitsFacilitySumTable"
import { DisabledChildOutpatientBenefitsIndividualTable } from "./DisabledChildOutpatientBenefitsIndividualTable"

import { getDisabledChildOutpatientBenefitsPrintData } from "../../../services/NHIFbilling"
import moment from "moment"

interface DisabledChildOutpatientBenefitsProps {
  data?: any
}
export const DisabledChildOutpatientBenefits: React.FC<
  DisabledChildOutpatientBenefitsProps
> = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const printContentRef = useRef<HTMLDivElement>(null)
  const { child_ids, year, month, facility_id, after_school, city_ids } =
    router.query as any

  const [queryParams, setQueryParams] = useState({
    year: year || new Date().getFullYear(),
    month: month || new Date().getMonth() + 1,
    show_actual_cost_datas: true,
    child_ids: child_ids || "",
    cityIds: city_ids || "",
    show_zero_invoice_children: 0,
    after_school: after_school,
    facility: facility_id,
  })
  const [selectedPrintSettings, setSelectedPrintSettings] = useState([])
  const { isLoading, data: response } = useQuery({
    queryKey: ["disabled_child_out_patient_benefits", queryParams],
    queryFn: () => getDisabledChildOutpatientBenefitsPrintData(queryParams),
    select: (response) => {
      const sortedData = Array.isArray(response?.data)
        ? response?.data?.sort((a, b) => a?.CityId - b?.CityId)
        : []
      return {
        ...response,
        data: sortedData,
      }
    },
    refetchOnWindowFocus: false,
  })

  const getArrayWithBlankPaymentRows = (
    arraySize: number,
    sampleRow: object
  ) => {
    for (const keys in sampleRow) {
      sampleRow[keys] = ""
    }
    return new Array(arraySize).fill(sampleRow)
  }

  const handlePrint = useReactToPrint({
    content: () => printContentRef.current,
  })
  return (
    <Wrapper>
      <DisabledChildOutpatientBenefitsFilter
        onDisplaySwitch={(printSettings) => {
          setSelectedPrintSettings(printSettings)
          setQueryParams({
            ...queryParams,
            show_zero_invoice_children: printSettings?.includes("2") ? 1 : 0,
          })

          printContentRef?.current?.scrollIntoView({
            behavior: "smooth",
          })
        }}
      />
      <CtaBtns>
        <OwnerButton
          text={t("Cancel")}
          shape={"circle"}
          typeOf={"secondary"}
          onClick={() => {
            const baseURL = `/national-health-insurance-billing/prints/disabled-child-outpatient-benefits`
            let queryString = `year=${year}&month=${month}&after_school=${after_school}`
            if (typeof facility_id !== "undefined" && facility_id !== null) {
              queryString += `&facility_id=${facility_id}`
            }
            if (
              typeof child_ids !== "undefined" &&
              child_ids !== null &&
              child_ids !== ""
            ) {
              queryString += `&child_ids=${child_ids}`
            }
            router.push(`${baseURL}?${queryString}`)
          }}
        />
        <OwnerButton text={t("Print")} icon={"print"} onClick={handlePrint} />
      </CtaBtns>

      <AlertContent>
        <div className={"title__content"}>
          <img alt={"warn"} src={"/assets/icons/exclamation.png"} />
          <Typography.Text className={"title"}>
            {t(
              "If you want to print users with a billing amount of 0 yen, you need to change the operating options."
            )}
          </Typography.Text>
        </div>
        <Typography.Text className={"sub"}>
          {t(
            'Check the "Print users with a billing amount of 0 yen" checkbox and switch the display.'
          )}
        </Typography.Text>
      </AlertContent>

      <PrintBoxContent ref={printContentRef}>
        {isLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            {response?.data?.map((data, index) => {
              const showCityInfo = city_ids
                ?.split(",")
                ?.map((val) => +val)
                ?.includes(response?.data[index]?.CityId)
              const childIds = child_ids?.split(",")?.map((val) => +val)
              const childIndividualData = data?.ChildIndividualDataNew?.filter(
                (val) => childIds?.includes(val?.ChildId)
              )
              return (
                <Fragment key={index}>
                  {showCityInfo ? (
                    <DisabledChildOutpatientBenefitsFacilitySumTable
                      data={
                        data
                          ? {
                              Data: data.Data || [],
                              Month: data?.Month
                                ? `0${data?.Month}`.slice(-2)
                                : "",
                              InvoiceAmount: `${data?.InvoiceAmount}`?.split(
                                ""
                              ),
                              Year: data?.Year || "",
                              FacilityName: data?.FacilityName || "",
                              FacilityShortName: data?.FacilityShortName || "",
                              FacilityInvoiceName:
                                data?.FacilityInvoiceName || "",
                              FacilityInvoiceTitle:
                                data?.FacilityInvoiceTitle || "",
                              TelNo: data?.TelNo || "",
                              office_number: data?.office_number || "",
                              Address: data?.Address || "",
                              CityName: data?.CityName || "",
                              PostCode: data?.PostCode || "",
                            }
                          : undefined
                      }
                      selectedPrintSettings={selectedPrintSettings}
                    />
                  ) : null}

                  {childIndividualData?.map((individualData, index) => {
                    // limit payment table to 13 rows
                    // if there are more than 13 rows then show remaining data & copied data as another receipt
                    const groupLimit = 13
                    const c_04_to_c08s = []
                    if (individualData?.c_04_to_c08?.length >= groupLimit) {
                      const numberOfGroups = Math.ceil(
                        individualData?.c_04_to_c08?.length / groupLimit
                      )
                      for (let i = 0; i < numberOfGroups; i++) {
                        const slicedData = individualData?.c_04_to_c08.slice(
                          i * groupLimit,
                          (i + 1) * groupLimit
                        )
                        c_04_to_c08s.push([
                          ...slicedData,
                          ...getArrayWithBlankPaymentRows(
                            groupLimit - slicedData.length,
                            { ...(slicedData?.[0] || {}) }
                          ),
                        ])
                      }
                    } else {
                      c_04_to_c08s.push([
                        ...individualData?.c_04_to_c08,
                        ...getArrayWithBlankPaymentRows(
                          groupLimit - individualData?.c_04_to_c08.length,
                          { ...(individualData?.c_04_to_c08?.[0] || {}) }
                        ),
                      ])
                    }
                    return c_04_to_c08s?.map((paymentGroups, idx) => (
                      <div key={index + "" + idx} id={"disability__benefits"}>
                        <DisabledChildOutpatientBenefitsIndividualTable
                          data={{
                            ...individualData,
                            a_08: moment(individualData?.a_08).isValid()
                              ? `${moment(individualData?.a_08)?.year()}`
                              : "",
                            a_09: moment(individualData?.a_09).isValid()
                              ? `${moment(individualData?.a_09)?.month() + 1}`
                              : "",
                            a_10: moment(individualData?.a_10).isValid()
                              ? `${moment(individualData?.a_10)?.date()}`
                              : "",
                            a_11: moment(individualData?.a_11).isValid()
                              ? `${moment(individualData?.a_11).year()}`
                              : "",
                            a_12: moment(individualData?.a_12).isValid()
                              ? `${moment(individualData?.a_12)?.month() + 1}`
                              : "",
                            a_13: moment(individualData?.a_13).isValid()
                              ? `${moment(individualData?.a_13)?.date()}`
                              : "",
                            c_04_to_c08: paymentGroups,
                          }}
                          selectedPrintSettings={selectedPrintSettings}
                          page_number={idx + 1}
                          total_page={c_04_to_c08s?.length}
                        />
                      </div>
                    ))
                  })}
                </Fragment>
              )
            })}
          </>
        )}
      </PrintBoxContent>
    </Wrapper>
  )
}
