import { useFormik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import * as Yup from "yup"
import moment from "moment"
import locale from "antd/lib/date-picker/locale/ja_JP"
import {
  DeleteConfimationModal,
  Modal,
  TextField,
  theme,
} from "@project/shared"
import { InstructorInfo } from "../../molecules"
import { DatePicker } from "../../molecules/DatePicker"
import { Tag, OwnerButton } from "../../atoms"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"

const CommonLableStyle = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`
export const ModalBodyContainer = styled.div`
  padding: 24px 2rem 0px 2rem;
  .error_message {
    font-size: 12px;
    color: #eb5757;
    position: absolute;
  }
  .body_content {
    border: 1px solid ${theme.gray2};
    .form_control {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.gray2};
      }
      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
      }
      gap: 10px;
      .label {
        width: 30%;
        border-right: 1px solid ${theme.gray2};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 20px;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
          border-right: none;
          padding: 15px;
          padding-bottom: 0;
        }
      }
      .form_input {
        width: 70%;
        padding: 15px 0;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
          padding-left: 15px;
          padding-top: 0;
        }
        .work_period {
          display: flex;
          column-gap: 10px;
          align-items: center;
          height: 60px;
          .ant-picker {
            border-radius: 5px;
            padding-top: 8px;
            padding-bottom: 8px;
            width: 100%;
          }
          @media (max-width: ${theme.breakpoints.md}) {
            column-gap: 10px;
          }
        }
        .days {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            ${CommonLableStyle}
          }
        }
        .work {
          display: flex;
          align-items: center;
          gap: 40px;
          label {
            ${CommonLableStyle}
          }
          @media (max-width: ${theme.breakpoints.md}) {
            gap: 10px;
          }
        }
        .work_detail {
          margin-top: 10px;
          label {
            ${CommonLableStyle}
          }
        }
      }
    }
  }
`
const FooterContainers = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid ${theme.gray2};
  padding: 10px 24px;
`
interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY年MM月DD日"

const initialValues = {
  name: "",
  start_date: null,
  end_date: null,
  working_days: "",
  work_name: "",
  work_detail: "",
}
export const WorkCertificates: React.FC<IProps> = ({
  data,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      start_date: Yup.date().required(t("Required")),
      end_date: Yup.date().required(t("Required")),
      working_days: Yup.number()
        .max(99999, t("Required"))
        .required(t("Required")),
    }),
    onSubmit: (values) => {
      if (operation === "create") {
        onSave({ tempId: new Date().getTime(), ...values }, operation)
      } else {
        onSave({ tempId: selectedId, ...values }, operation)
      }
      setOperation("create")
      handleReset()
    },
  })
  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })
    setIsModalOpen(false)
  }

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "Edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  name: row?.name,
                  working_days: row?.working_days,
                  work_detail: row?.work_detail,
                  work_name: row?.work_name,
                  start_date: moment(row?.start_date),
                  end_date: moment(row?.end_date),
                },
              })

              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.blue1, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Facility or business name")}
        </span>
      ),
      key: "name",
      dataIndex: "name",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Work period")}</span>,
      key: "work_period",
      render: (row) => (
        <div>
          <span>{moment(row?.start_date).format(FORMAT)}</span> {"- "}
          <span>{moment(row?.end_date).format(FORMAT)}</span>
        </div>
      ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Number of days spent working")}
        </span>
      ),
      key: "working_days",
      dataIndex: "working_days",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Work details")}</span>,
      key: "work_detail",
      dataIndex: "work_detail",
    },
  ]
  return (
    <>
      <InstructorInfo
        title={t("List of work experience certificates")}
        columns={columns}
        dataSource={data}
        btnText={t("Register work experience certificates")}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        title={t("Register/Update work experience certificates")}
        closeIcon={<span style={{ marginLeft: "5px" }}>{t("Close")}</span>}
        open={isModalOpen}
        onCancel={() => {
          setSelectedId(null)
          setOperation("create")
          handleReset()
          setIsModalOpen(false)
        }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose
        centered
        noPadding
        btnRightMargin={"24px"}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 100px)",
        }}
        wrapClassName={"instructor__info-modal"}
      >
        <form onSubmit={formik.handleSubmit}>
          <ModalBodyContainer>
            <div className={"body_content"}>
              <div className={"form_control"}>
                <div className={"label"}>{t("Facility or business name")}</div>
                <div className={"form_input"}>
                  <TextField
                    bgcolor={theme.base}
                    padding={"8px 12px"}
                    borderradius={"0px"}
                    name={"name"}
                    value={formik?.values?.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>
                  <span> {t("Work period")}</span>
                  <Tag backgroundColor={theme.red2}>{t("Required")}</Tag>
                </div>
                <div className={"form_input"}>
                  <div className={"work_period"}>
                    <div
                      id={"w_start_date"}
                      style={{
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        name={"start_date"}
                        locale={locale}
                        format={FORMAT}
                        getPopupContainer={() =>
                          document.getElementById("w_start_date")
                        }
                        date={
                          moment(formik?.values?.start_date)?.isValid()
                            ? moment(formik?.values?.start_date)
                            : undefined
                        }
                        onDateChange={(e) =>
                          formik.setFieldValue("start_date", moment(e))
                        }
                        inputReadOnly
                      />
                      <ErrorCounterWrapper>
                        {formik.errors.start_date &&
                          formik.touched.start_date && (
                            <ErrorStyled>
                              {formik.errors.start_date ? t("Required") : ""}
                            </ErrorStyled>
                          )}
                      </ErrorCounterWrapper>
                    </div>
                    {"~"}
                    <div
                      id={"w_end_date"}
                      style={{
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        name={"end_date"}
                        locale={locale}
                        format={FORMAT}
                        getPopupContainer={() =>
                          document.getElementById("w_end_date")
                        }
                        date={
                          moment(formik?.values?.end_date)?.isValid()
                            ? moment(formik?.values?.end_date)
                            : undefined
                        }
                        onDateChange={(e) => {
                          formik.setFieldValue("end_date", moment(e))
                        }}
                        inputReadOnly
                        disabledDate={(current) =>
                          current.isBefore(moment(formik.values?.start_date))
                        }
                      />

                      <ErrorCounterWrapper>
                        {formik.errors.end_date && formik.touched.end_date && (
                          <ErrorStyled>
                            {formik.errors.end_date ? t("Required") : ""}
                          </ErrorStyled>
                        )}
                      </ErrorCounterWrapper>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>
                  {t("Number of days spent working")}{" "}
                  <Tag backgroundColor={theme.red2}>{t("Required")}</Tag>
                </div>
                <div className={"form_input"}>
                  <div className={"days"}>
                    <TextField
                      bgcolor={theme.base}
                      padding={"8px 12px"}
                      borderradius={"0px"}
                      name={"working_days"}
                      value={formik?.values?.working_days}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "working_days",
                          event.target.value?.slice(0, 5)
                        )
                      }}
                      error={
                        formik.touched.working_days &&
                        formik.errors.working_days
                      }
                      maxLength={5}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      type={"number"}
                      min={0}
                    />
                    <span>{t("Days")}</span>
                  </div>
                  {formik.errors.working_days && (
                    <ErrorCounterWrapper>
                      {formik.errors.working_days &&
                        formik.touched.working_days && (
                          <ErrorStyled>
                            {formik.errors.working_days}
                          </ErrorStyled>
                        )}
                    </ErrorCounterWrapper>
                  )}
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Work details")}</div>
                <div className={"form_input"}>
                  <div className={"work"}>
                    <label style={{ whiteSpace: "nowrap" }}>
                      {t("work")}
                      <span>{":"}</span>
                    </label>
                    <TextField
                      bgcolor={theme.base}
                      padding={"8px 12px"}
                      borderradius={"0px"}
                      name={"work_name"}
                      value={formik?.values?.work_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={"work_detail"}>
                    <label>{t("write work details in detail")}</label>
                    <TextField
                      bgcolor={theme.base}
                      padding={"8px 12px"}
                      borderradius={"0px"}
                      name={"work_detail"}
                      value={formik?.values?.work_detail}
                      onChange={formik.handleChange}
                      type={"textarea"}
                      rows={5}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBodyContainer>
          <br />
          <FooterContainers>
            <OwnerButton
              text={t("Save")}
              type={"submit"}
              shape={"circle"}
              borderColor={"black"}
              backgroundColor={"#FFFFFF"}
              disabled={!formik.isValid}
            />
            {operation === "edit" && (
              <OwnerButton
                text={t("Delete")}
                backgroundColor={theme.red1}
                borderColor={"black"}
                color={theme.red1}
                shape={"circle"}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
          </FooterContainers>
        </form>
      </Modal>

      {openDeleteModal && (
        <DeleteConfimationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
