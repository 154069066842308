import styled from "styled-components"

export const ConfirmationWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
  font-size: 10pt;
  &.top-dotted-border {
    border-top: 1px dotted black;
  }
  &.pt-10 {
    padding-top: 10px;
  }
  .footer-check {
    font-size: 10pt;
    width: 48%;
    margin-left: auto;
    text-align: left;
  }
  span {
    font-size: 10pt;
  }
`
