import { theme } from "@project/shared"
import styled, { css } from "styled-components"

const COMMON = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 5px;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.gray2};
  }
  span {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.black};
  }
`
export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .title {
    border-bottom: 1px solid ${theme.gray2};
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 100%;
    @media (max-width: ${theme.breakpoints?.md}) {
      word-break: break-all;
    }
  }
  .weekly-table-container {
    overflow-x: auto;
    white-space: nowrap;
    table {
      border-collapse: collapse;
      /* border-spacing: 0 0.2rem; */
      th {
        min-width: 150px;
      }
      th.extra_col {
        min-width: 10px !important;
        max-width: 10px !important;
        /* visibility: hidden; */
      }

      .date-column {
        min-width: 80px !important;
        .time,
        .time-range {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          background: ${theme.bgColor2};
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .time-range {
          font-size: 10px;
        }
      }
      .ant-table-thead > tr > th {
        background: ${theme.blue6};
        padding: 5px 16px !important;
      }
      .ant-table-thead > tr > th.extra_col {
        background-color: red !important;
        padding: 0px !important;
        visibility: hidden !important;
        border-bottom: unset !important;
        /* opacity: 0; */
      }
      .ant-table-tbody > tr > td {
        border-collapse: collapse;
        border-top: 1px solid ${theme.gray2} !important;
        border-bottom: 1px solid ${theme.gray2} !important;
        border-left: 1px solid ${theme.gray2} !important;
        &:last-child {
          border-right: 1px solid ${theme.gray2} !important;
        }
      }
      .ant-table-tbody > tr > td.extra_col {
        visibility: hidden !important;
        border-top: unset !important;
        border-bottom: unset !important;
        border-right: unset !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none !important;
      }
      .ant-table-tbody > tr:hover > td.date-column {
        background: ${theme.bgColor2} !important;
      }
      .time-content {
        ${COMMON}
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
          background: ${theme.gray2};
        }
      }
      .has-time-content {
        background: ${theme.blue6};
        ${COMMON};
        flex-direction: column;
        &:hover {
          cursor: pointer;
        }
        svg {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
      .empty-cell-row {
        min-width: 2px !important;
      }
      @media (max-width: ${theme.breakpoints?.md}) {
        .date-column {
          min-width: 70px !important;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    gap: 10px;
    div:nth-child(2) {
      margin: 10px 0;
    }
  }
`
const STYLES = css`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  white-space: break-spaces;
  .input-container-bulk {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-content {
      ${STYLES}
    }
  }
  .input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .input-content {
      ${STYLES}
    }
  }
  .days-container {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    .days {
      display: flex;
      justify-content: space-between;
      flex: auto;
      position: relative;

      .error_days:empty {
        display: none;
      }

      .error_days:not(:empty) {
        display: block;
        position: absolute;
        top: 30px;
      }
    }
  }
  .area-container {
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
    div {
      flex: auto;
    }
  }
  .cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 15px;
  }
`
