import { useRouter } from "next/router"
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { AuthContext, scrollToSelectedElement } from "../utils"
import moment from "moment"
import { useQuery } from "react-query"
import { getUserServiceProvisionPrint } from "../services/serviceProvisionResults"
interface TodaySituationBulkPrintContextProps {
  facility_options: any[]
  initialState: any
  handleSearch: (params: any) => void
  contentRef: any
  printData: any
  handleBulkCheck: (val: boolean) => void
  selectedChilds: any
  setSelectedChilds: (val: any[]) => void
  displayParams?: any
  isLoading: boolean
  total: number
  pageData: any
  handlePagination: (change: "P" | "S", val: number) => void
}
const TodaySituationBulkPrintContext = createContext<
  Partial<TodaySituationBulkPrintContextProps>
>({})

const TodaySituationBulkPrintContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const router = useRouter()
  const {
    keyword,
    facilities,
    createdStatus,
    usedServices,
    date_from,
    date_to,
  } = router.query
  const contentRef = useRef<HTMLDivElement>(null)
  const [selectedChilds, setSelectedChilds] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const handleBulkCheck = (check_all = true) => {
    if (check_all) {
      setSelectedChilds(printData?.data?.map((data) => data?.child_id))
    } else {
      setSelectedChilds([])
    }
  }

  const { facilities: allFacilities } = useContext(AuthContext)
  const facility_options = allFacilities?.map((facility) => {
    return {
      label: facility?.facility_name_short,
      value: facility?.id,
      key: facility?.id,
    }
  })

  const initialState = {
    keyword: keyword ?? "",
    facility_ids: facilities
      ? facilities?.toString()?.split(",")?.map(Number)
      : [],
    from_date:
      date_from && moment(date_from)?.isValid()
        ? moment(date_from)?.format("YYYY-MM-DD")
        : null,
    to_date:
      date_to && moment(date_to)?.isValid()
        ? moment(date_to)?.format("YYYY-MM-DD")
        : null,
    use_service: usedServices
      ? usedServices?.toString()?.split(",")?.map(Number)
      : [],
    draft: createdStatus == "DRAFT" ? 1 : 2,
    parents_comment: 2,
  }

  const [displayParams, setDisplayParams] = useState(initialState)

  const handleSearch = (params) => {
    setDisplayParams(params)
    scrollToSelectedElement(contentRef)
  }

  useEffect(() => {
    const queries = []
    if (displayParams?.keyword) {
      queries.push(`keyword=${displayParams?.keyword}`)
    }
    if (displayParams?.facility_ids?.length) {
      queries.push(`facilities=${displayParams?.facility_ids?.join(",")}`)
    }
    if (displayParams?.from_date) {
      queries.push(`date_from=${displayParams?.from_date}`)
    }
    if (displayParams?.to_date) {
      queries.push(`date_to=${displayParams?.to_date}`)
    }
    if (displayParams?.use_service?.length) {
      queries.push(`usedServices=${displayParams?.use_service?.join(",")}`)
    }
    if (displayParams?.draft == 1) {
      queries.push(`createdStatus=DRAFT`)
    }
    if (queries?.length)
      router.push(`?${queries.join("&")}`, undefined, { shallow: true })
  }, [displayParams])

  const { isLoading, data: printData } = useQuery(
    ["fetch-bulk-data", displayParams],
    () => {
      const queries = []
      if (displayParams?.keyword) {
        queries.push(`keyword=${displayParams?.keyword}`)
      }
      if (displayParams?.facility_ids?.length) {
        queries.push(`facilities=${displayParams?.facility_ids?.join(",")}`)
      }
      if (displayParams?.draft == 2) {
        queries.push(`created_status=APPROVED`)
      }
      if (displayParams?.use_service?.length) {
        queries.push(`used_services=${displayParams?.use_service?.join(",")}`)
      }
      if (displayParams?.from_date) {
        queries.push(`date_from=${displayParams?.from_date}`)
      }
      if (displayParams?.to_date) {
        queries.push(`date_to=${displayParams?.to_date}`)
      }
      queries.push("comment_required=1")
      queries.push("group_child=1")
      return getUserServiceProvisionPrint(`?${queries.join("&")}`)
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (response: any) => {
        return {
          count: response?.count,
          data: response?.data,
        }
      },
    }
  )
  const handlePagination = (change: "P" | "S", val: number) => {
    if (change === "P") {
      setPage(val)
    }
    if (change === "S") {
      setPageSize(val)
    }
  }
  return (
    <TodaySituationBulkPrintContext.Provider
      value={{
        initialState,
        selectedChilds,
        setSelectedChilds,
        isLoading,
        displayParams,
        handleBulkCheck,
        handleSearch,
        facility_options,
        printData: {
          ...printData,
        },
        contentRef,
        total: selectedChilds?.length,
        pageData: {
          page,
          pageSize,
        },
        handlePagination: handlePagination,
      }}
    >
      {children}
    </TodaySituationBulkPrintContext.Provider>
  )
}

const useTodaySituationBulkPrintContext = () => {
  const context = useContext(TodaySituationBulkPrintContext)
  if (context === undefined) {
    throw new Error(
      "TodaySituationBulkPrintContext must be used within a TodaySituationBulkPrintContext"
    )
  }
  return context
}

export {
  TodaySituationBulkPrintContextProvider,
  useTodaySituationBulkPrintContext,
}
