import { API } from "@project/shared"
import moment from "moment"

interface IAdditionMinutes {
  page: any
  pageSize: any
  params?: any
}

export const getAllAdditionMinutes = async ({
  page,
  pageSize,
  params,
}: IAdditionMinutes) => {
  let url = `/addition-minutes?page=${page}&pageSize=${pageSize}`
  if (params.facility_ids) url = `${url}&facility_ids=${params.facility_ids}`
  if (params.keyword) url = `${url}&keyword=${params.keyword}`
  if (params.use_service) url = `${url}&use_service=${params.use_service}`
  if (params.start_date_from && params.start_date_to)
    url = `${url}&implemented_range=${moment(params.start_date_from._d).format(
      "YYYY-MM-DD"
    )}_${moment(params.start_date_to._d).format("YYYY-MM-DD")}`
  return API.get(url)
}

export const getListingAdditionMinutes = async ({
  page,
  pageSize,
  params,
}: IAdditionMinutes) => {
  let url = `/addition-minutes/list?page=${page}&pageSize=${pageSize}`
  if (params.facility_ids) url = `${url}&facility_ids=${params.facility_ids}`
  if (params.keyword) url = `${url}&keyword=${params.keyword}`
  if (params.use_service) url = `${url}&use_service=${params.use_service}`
  if (params.start_date_from && params.start_date_to)
    url = `${url}&implemented_range=${moment(params.start_date_from._d).format(
      "YYYY-MM-DD"
    )}_${moment(params.start_date_to._d).format("YYYY-MM-DD")}`
  return API.get(url)
}
