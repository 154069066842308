import styled from "styled-components"
import { OperationOption, BusinessTable } from "../ReOccuringBusiness"
import { useFormik } from "formik"
import { CHILD_SERVICE_OPTION } from "@project/shared"
import { useEffect, useState, useContext, useMemo, useRef } from "react"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { useRouter } from "next/router"
import { Spin, notification } from "antd"
import { useMutation, useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import {
  saveFacilityBusinessDayForYear,
  getFacilityBulkSetting,
} from "../../../services"

const Container = styled.div``

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

const WEEK_PREFIX_ARRRAY = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

export const ReOccuringBusinessComponent = () => {
  const router = useRouter()

  const { t } = useTranslation()

  const { facilities } = useContext(AuthContext)
  const { facilityId, year } = router.query
  const BUSINESS_REGISTRATION_FACILITY = facilities?.map((facility) => ({
    label: facility.facility_name_short,
    value: facility.id,
  }))
  const hasFacility = BUSINESS_REGISTRATION_FACILITY?.find(
    (facility) => facility.value == facilityId
  )
  useEffect(() => {
    // Check if we're on the client side
    if (!hasFacility || isNaN(+year)) {
      //  router.push("/business-list")
    }
  }, [])

  const [filterOptions, setFilterOptions] = useState({
    year: +year,
    facility: +facilityId,
  })

  const [isBulkApplying, setApplyBulkSetting] = useState(false)

  const { data: bulkSetting, isLoading } = useQuery(
    ["fetch-latest-business-bulk-setting", filterOptions],
    () =>
      getFacilityBulkSetting(
        `facility=${filterOptions?.facility}&year=${filterOptions?.year}`
      ),
    {
      retry: 1,
      keepPreviousData: false,
      select: ({ data }) => data,
      onSuccess: (data) => {
        const setting = data?.setting
        const initialSetting: any = {}

        initialSetting.day_hours =
          typeof setting?.day_hours == "number" ? setting?.day_hours : ""
        initialSetting.day_minutes =
          typeof setting?.day_minutes == "number" ? setting?.day_minutes : ""
        initialSetting.week_hours =
          typeof setting?.week_hours == "number" ? setting?.week_hours : ""
        initialSetting.week_minutes =
          typeof setting?.week_minutes == "number" ? setting?.week_minutes : ""
        initialSetting.business_days = {}

        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
          const business_days = {}
          const dayPrefix = WEEK_PREFIX_ARRRAY?.[dayOfWeek]
          const prefix = dayPrefix + "_"
          business_days["day"] = dayPrefix
          business_days["prefix"] = prefix
          business_days[`holiday`] = setting?.[`${prefix}holiday`] || "0"
          business_days[`business_start_hour1`] =
            setting?.[`${prefix}business_start_hour1`]
          business_days[`business_start_minute1`] =
            setting?.[`${prefix}business_start_minute1`]
          business_days[`business_end_hour1`] =
            setting?.[`${prefix}business_end_hour1`]
          business_days[`business_end_minute1`] =
            setting?.[`${prefix}business_end_minute1`]
          business_days[`business_start_hour2`] =
            setting?.[`${prefix}business_start_hour2`]
          business_days[`business_start_minute2`] =
            setting?.[`${prefix}business_start_minute2`]
          business_days[`business_end_hour2`] =
            setting?.[`${prefix}business_end_hour2`]
          business_days[`business_end_minute2`] =
            setting?.[`${prefix}business_end_minute2`]
          business_days[`business_start_hour3`] =
            setting?.[`${prefix}business_start_hour3`]
          business_days[`business_start_minute3`] =
            setting?.[`${prefix}business_start_minute3`]
          business_days[`business_end_hour3`] =
            setting?.[`${prefix}business_end_hour3`]
          business_days[`business_end_minute3`] =
            setting?.[`${prefix}business_end_minute3`]
          business_days[`service_start_hour1`] =
            setting?.[`${prefix}service_start_hour1`]
          business_days[`service_start_minute1`] =
            setting?.[`${prefix}service_start_minute1`]
          business_days[`service_end_hour1`] =
            setting?.[`${prefix}service_end_hour1`]
          business_days[`service_end_minute1`] =
            setting?.[`${prefix}service_end_minute1`]
          business_days[`service_start_hour2`] =
            setting?.[`${prefix}service_start_hour2`]
          business_days[`service_start_minute2`] =
            setting?.[`${prefix}service_start_minute2`]
          business_days[`service_end_hour2`] =
            setting?.[`${prefix}service_end_hour2`]
          business_days[`service_end_minute2`] =
            setting?.[`${prefix}service_end_minute2`]
          business_days[`service_start_hour3`] =
            setting?.[`${prefix}service_start_hour3`]
          business_days[`service_start_minute3`] =
            setting?.[`${prefix}service_start_hour3`]
          business_days[`service_end_hour3`] =
            setting?.[`${prefix}service_end_hour3`]
          business_days[`service_end_minute3`] =
            setting?.[`${prefix}service_end_minute3`]
          initialSetting.business_days[dayPrefix] = business_days
        }

        formik.setValues(initialSetting)
      },
    }
  )

  const getServiceObj = (id) => {
    const serviceId = facilities.find((fac) => fac.id == id)?.pref_id
    if (serviceId) {
      const childService = CHILD_SERVICE_OPTION.find(
        (item) => item.value == serviceId
      )
      return {
        name: childService?.label,
        color: childService?.colorCode,
      }
    }
    return {
      name: null,
      color: null,
    }
  }

  const service = useMemo(
    () => getServiceObj(filterOptions?.facility),
    [filterOptions?.facility]
  )

  const cardTitle = useMemo(() => {
    const facilityName = BUSINESS_REGISTRATION_FACILITY?.find(
      (fac) => fac?.value == filterOptions?.facility
    )?.label
    return t("yearFacilityBusinessDay", {
      facilityName,
      year: filterOptions?.year,
    })
  }, [filterOptions])

  const getInitialBusinessDays = () => {
    const weekly_setting = {}
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
      const business_days = {}
      const dayPrefix = WEEK_PREFIX_ARRRAY?.[dayOfWeek]
      const prefix = dayPrefix + "_"
      business_days["day"] = dayPrefix
      business_days["prefix"] = prefix
      business_days[`holiday`] = "0"
      business_days[`business_start_hour1`] = null
      business_days[`business_start_minute1`] = null
      business_days[`business_end_hour1`] = null
      business_days[`business_end_minute1`] = null
      business_days[`business_start_hour2`] = null
      business_days[`business_start_minute2`] = null
      business_days[`business_end_hour2`] = null
      business_days[`business_end_minute2`] = null
      business_days[`business_start_hour3`] = null
      business_days[`business_start_minute3`] = null
      business_days[`business_end_hour3`] = null
      business_days[`business_end_minute3`] = null
      business_days[`service_start_hour1`] = null
      business_days[`service_start_minute1`] = null
      business_days[`service_end_hour1`] = null
      business_days[`service_end_minute1`] = null
      business_days[`service_start_hour2`] = null
      business_days[`service_start_minute2`] = null
      business_days[`service_end_hour2`] = null
      business_days[`service_end_minute2`] = null
      business_days[`service_start_hour3`] = null
      business_days[`service_start_minute3`] = null
      business_days[`service_end_hour3`] = null
      business_days[`service_end_minute3`] = null
      weekly_setting[dayPrefix] = business_days
    }

    return weekly_setting
  }

  const { mutate: saveBulkBusinessDays, isLoading: isSaving } = useMutation(
    saveFacilityBusinessDayForYear,
    {
      onSuccess: () => {
        notification.success({
          message: t("Business Days") + t("Updated Successfully"),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message

        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const initialValues = {
    day_hours: "",
    day_minutes: "",
    week_hours: "",
    week_minutes: "",
    business_days: getInitialBusinessDays(),
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const payload = {
        facility_id: filterOptions?.facility,
        year: filterOptions?.year,
        day_hours: values?.day_hours == "" ? null : +values?.day_hours,
        day_minutes: values?.day_minutes == "" ? null : +values?.day_minutes,
        week_hours: values?.week_hours == "" ? null : +values?.week_hours,
        week_minutes: values?.week_minutes == "" ? null : +values?.week_minutes,
      }

      Object?.keys(values?.business_days)?.map((weekDay) => {
        const prefix = weekDay + "_"
        const thisDaySetting = values?.business_days[weekDay]
        payload[`${prefix}holiday`] = thisDaySetting?.holiday
        payload[`${prefix}business_start_hour1`] =
          thisDaySetting?.business_start_hour1
        payload[`${prefix}business_start_minute1`] =
          thisDaySetting?.business_start_minute1
        payload[`${prefix}business_end_hour1`] =
          thisDaySetting?.business_end_hour1
        payload[`${prefix}business_end_minute1`] =
          thisDaySetting?.business_end_minute1
        payload[`${prefix}business_start_hour2`] =
          thisDaySetting?.business_start_hour2
        payload[`${prefix}business_start_minute2`] =
          thisDaySetting?.business_start_minute2
        payload[`${prefix}business_end_hour2`] =
          thisDaySetting?.business_end_hour2
        payload[`${prefix}business_end_minute2`] =
          thisDaySetting?.business_end_minute2
        payload[`${prefix}business_start_hour3`] =
          thisDaySetting?.business_start_hour3
        payload[`${prefix}business_start_minute3`] =
          thisDaySetting?.business_start_minute3
        payload[`${prefix}business_end_hour3`] =
          thisDaySetting?.business_end_hour3
        payload[`${prefix}business_end_minute3`] =
          thisDaySetting?.business_end_minute3
        payload[`${prefix}service_start_hour1`] =
          thisDaySetting?.service_start_hour1
        payload[`${prefix}service_start_minute1`] =
          thisDaySetting?.service_start_minute1
        payload[`${prefix}service_end_hour1`] =
          thisDaySetting?.service_end_hour1
        payload[`${prefix}service_end_minute1`] =
          thisDaySetting?.service_end_minute1
        payload[`${prefix}service_start_hour2`] =
          thisDaySetting?.service_start_hour2
        payload[`${prefix}service_start_minute2`] =
          thisDaySetting?.service_start_minute2
        payload[`${prefix}service_end_hour2`] =
          thisDaySetting?.service_end_hour2
        payload[`${prefix}service_end_minute2`] =
          thisDaySetting?.service_end_minute2
        payload[`${prefix}service_start_hour3`] =
          thisDaySetting?.service_start_hour3
        payload[`${prefix}service_start_minute3`] =
          thisDaySetting?.service_start_minute3
        payload[`${prefix}service_end_hour3`] =
          thisDaySetting?.service_end_hour3
        payload[`${prefix}service_end_minute3`] =
          thisDaySetting?.service_end_minute3
      })
      saveBulkBusinessDays(payload)
    },
  })

  const applyBulkSetting = (setting) => {
    setApplyBulkSetting(true)
    const businessDays = formik.values?.business_days
    if (Array.isArray(setting?.regular_holidays)) {
      setting?.regular_holidays?.forEach((dayString) => {
        const index = (+dayString + 1) % 7
        const dayPrefix = WEEK_PREFIX_ARRRAY?.[index]
        businessDays[dayPrefix][`holiday`] = "1"
        businessDays[dayPrefix][`business_start_hour1`] = null
        businessDays[dayPrefix][`business_start_minute1`] = null
        businessDays[dayPrefix][`business_end_hour1`] = null
        businessDays[dayPrefix][`business_end_minute1`] = null
        businessDays[dayPrefix][`business_start_hour2`] = null
        businessDays[dayPrefix][`business_start_minute2`] = null
        businessDays[dayPrefix][`business_end_hour2`] = null
        businessDays[dayPrefix][`business_end_minute2`] = null
        businessDays[dayPrefix][`business_start_hour3`] = null
        businessDays[dayPrefix][`business_start_minute3`] = null
        businessDays[dayPrefix][`business_end_hour3`] = null
        businessDays[dayPrefix][`business_end_minute3`] = null
        businessDays[dayPrefix][`service_start_hour1`] = null
        businessDays[dayPrefix][`service_start_minute1`] = null
        businessDays[dayPrefix][`service_end_hour1`] = null
        businessDays[dayPrefix][`service_end_minute1`] = null
        businessDays[dayPrefix][`service_start_hour2`] = null
        businessDays[dayPrefix][`service_start_minute2`] = null
        businessDays[dayPrefix][`service_end_hour2`] = null
        businessDays[dayPrefix][`service_end_minute2`] = null
        businessDays[dayPrefix][`service_start_hour3`] = null
        businessDays[dayPrefix][`service_start_minute3`] = null
        businessDays[dayPrefix][`service_end_hour3`] = null
        businessDays[dayPrefix][`service_end_minute3`] = null
      })
    }

    if (Array.isArray(setting?.work_business_days)) {
      setting?.work_business_days?.forEach((dayString) => {
        const index = (+dayString + 1) % 7
        const dayPrefix = WEEK_PREFIX_ARRRAY?.[index]
        businessDays[dayPrefix][`holiday`] = "0"
        if (Array.isArray(setting?.business_hours)) {
          setting?.business_hours?.forEach((businessHour) => {
            businessDays[dayPrefix][`business_start_hour${businessHour?.id}`] =
              businessHour?.from?.hours
            businessDays[dayPrefix][
              `business_start_minute${businessHour?.id}`
            ] = businessHour?.from?.minutes
            businessDays[dayPrefix][`business_end_hour${businessHour?.id}`] =
              businessHour?.to?.hours
            businessDays[dayPrefix][`business_end_minute${businessHour?.id}`] =
              businessHour?.to?.minutes
          })
        }

        if (Array.isArray(setting?.service_hours)) {
          setting?.service_hours?.forEach((serviceHour) => {
            businessDays[dayPrefix][`service_start_hour${serviceHour?.id}`] =
              serviceHour?.from?.hours
            businessDays[dayPrefix][`service_start_minute${serviceHour?.id}`] =
              serviceHour?.from?.minutes
            businessDays[dayPrefix][`service_end_hour${serviceHour?.id}`] =
              serviceHour?.to?.hours
            businessDays[dayPrefix][`service_end_minute${serviceHour?.id}`] =
              serviceHour?.to?.minutes
          })
        }
      })
    }

    formik
      .setValues({ ...formik.values, business_days: businessDays })
      .then(() => {
        setTimeout(() => {
          setApplyBulkSetting(false)
          scrollToSelectedElement(tableRef)
        }, 100)
      })
  }

  const tableRef = useRef(null)

  return (
    <Container>
      <OperationOption
        BUSINESS_REGISTRATION_FACILITY={BUSINESS_REGISTRATION_FACILITY}
        onSubmit={(value) => {
          setFilterOptions(value)
          scrollToSelectedElement(tableRef)
        }}
        filterOptions={filterOptions}
        applyBulkSetting={applyBulkSetting}
      />
      <div ref={tableRef}>
        {isBulkApplying || isLoading ? (
          <SpinContainer>
            <Spin size={"large"} />
          </SpinContainer>
        ) : (
          <BusinessTable
            formik={formik}
            title={cardTitle}
            updateBusinessDays={(val) => formik.setValues(val)}
            serviceObj={service}
            isSaving={isSaving}
            isLoading={isLoading}
            hasSetting={bulkSetting?.has_setting || false}
            year={filterOptions?.year}
            updateYear={(y) => setFilterOptions({ ...filterOptions, year: y })}
          />
        )}
      </div>
    </Container>
  )
}
