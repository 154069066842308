import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Button } from "antd"
import { RightOutlined } from "@ant-design/icons"
import { Checkbox, PageCard, theme } from "@project/shared"
import { Button as CustomButton } from "../../atoms"
import { SearchContentSection } from "./index.styles"
import { AuthContext } from "../../../utils"
import moment from "moment"
import { DatePicker } from "../../molecules"

interface IProps {
  setSearchParams: (date: Date, facilityIds: number[]) => void
  isLoading?: boolean
  defaultYear: number
  defaultMonth: number
  defaultFacilities?: number[]
  programFacilities?: number[]
}
export const OperationOptions: React.FC<IProps> = ({
  isLoading,
  setSearchParams,
  defaultYear,
  defaultMonth,
  defaultFacilities,
  programFacilities,
}) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [checkedFacility, setCheckedFacility] = useState(
    defaultFacilities || []
  )
  const [year, setYear] = useState(defaultYear)
  const [month, setMonth] = useState(defaultMonth)
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target
    if (!checked) {
      setCheckedFacility(checkedFacility.filter((item) => item !== value))
    } else {
      setCheckedFacility([...checkedFacility, value])
    }
  }

  useEffect(() => {
    setYear(defaultYear)
    setMonth(defaultMonth)
  }, [defaultMonth, defaultYear])

  return (
    <SearchContentSection>
      <PageCard title={t("Operation options")}>
        <div className={"content"}>
          <div className={"operation-section"}>
            <div className={"flex label"}>
              <RightOutlined className={"icon"} />
              <Typography.Paragraph>
                {t("Displaying facility")}
              </Typography.Paragraph>
            </div>
            <div className={"checkboxs_container"}>
              <div className={"check_uncheck_all"}>
                <Button
                  icon={<img src={"/assets/icons/check.svg"} alt={"icon"} />}
                  shape={"round"}
                  onClick={() => {
                    setCheckedFacility(facilities?.map((li) => li.id))
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Check all")}
                  </Typography.Text>
                </Button>
                <Button
                  shape={"round"}
                  onClick={() => {
                    setCheckedFacility([])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Uncheck all")}{" "}
                  </Typography.Text>
                </Button>
              </div>
              <div className={"checkboxs"}>
                {facilities
                  ?.filter((facility) =>
                    programFacilities.includes(facility.id)
                  )
                  ?.map((facility, i) => (
                    <Checkbox
                      key={i}
                      onChange={onFacilityChecked}
                      checked={checkedFacility?.includes(facility?.id)}
                      value={facility?.id || ""}
                      label={facility?.facility_name_short}
                      className={"facility_checkbox"}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className={"operation-section"}>
            <div className={"flex label"}>
              <RightOutlined className={"icon"} />
              <Typography.Paragraph>{t("Year month")}</Typography.Paragraph>
            </div>
            <div className={"flex align-center gap-6"}>
              <DatePicker
                date={moment(`${year}-${month.toString().padStart(2, "0")}`)}
                style={{
                  height: "40px",
                  width: "160px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment("2015/01/01")) ||
                    (current && current > moment().add(3, "year"))
                  )
                }}
                onDateChange={(val) => {
                  setYear(val.year())
                  setMonth(val.month() + 1)
                }}
              />
            </div>
          </div>
          <CustomButton
            text={t("Update display")}
            backgroundColor={theme.gray}
            borderColor={theme.gray2}
            shape={"circle"}
            color={theme.black}
            width={157}
            onClick={() =>
              setSearchParams(
                year && month && new Date(year, month - 1),
                checkedFacility
              )
            }
            disabled={isLoading}
          />
        </div>
      </PageCard>
    </SearchContentSection>
  )
}
