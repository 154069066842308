import { Checkbox, SelectInput } from "@project/shared"
import { SiblingSheetData, SiblingSheetsDispatcher } from "../../../../types"
import { addCommas } from "../../../../utils/commaSeparator"
import {
  handleSelectSheet,
  handleSiblingInformationCreationCategory,
} from "../../handler"
import { t } from "i18next"
import { DIVISION_ITEMS } from "../../constant"

export function buildColumns(
  siblingSheets: SiblingSheetData,
  setSiblingSheets: SiblingSheetsDispatcher,
  facilityLength: number
) {
  return [
    {
      title: t("Selection"),
      key: "selection",
      dataIndex: "receipt_sibling_upper_limit_management_id",
      width: 79,
      className: "no-print",
      render: (id) => (
        <Checkbox
          className={"selection-checkbox"}
          checked={siblingSheets[id]?.checked ?? false}
          onChange={() =>
            handleSelectSheet(id, siblingSheets, setSiblingSheets)
          }
        />
      ),
      onCell: (_, index) => ({ rowSpan: index === 0 ? facilityLength : 0 }),
    },
    {
      title: t("Division"),
      key: "division",
      dataIndex: "receipt_sibling_upper_limit_management_id",
      width: 143,
      className: "division-head-cell head-cell",
      render: (id) => {
        return (
          <SelectInput
            options={DIVISION_ITEMS}
            value={
              siblingSheets[id]?.receiptSiblingUpperLimitManagement
                ?.information_creation_category ?? 1
            }
            onChange={(val) => {
              handleSiblingInformationCreationCategory(
                val,
                id,
                siblingSheets,
                setSiblingSheets
              )
            }}
          />
        )
      },
      onCell: (_, index) => ({ rowSpan: index === 0 ? facilityLength : 0 }),
    },
    {
      title: t("Item"),
      key: "line_no",
      dataIndex: "line_no",
      width: 65,
      className: "item-head-cell head-cell",
    },
    {
      title: t("Child name"),
      key: "child_name",
      dataIndex: "child_name",
    },
    {
      title: t("Business number"),
      dataIndex: "facility_number",
      key: "businessNumber",
      width: 144,
      className: "business-number-head-cell head-cell",
    },

    {
      title: t("Name of business"),
      dataIndex: "facility_name",
      key: "nameOfBusiness",
      className: "name-of-business-head-cell head-cell",
    },

    {
      title: t("Total cost"),
      dataIndex: "total_amount",
      key: "totalCost",
      width: 144,
      className: "total-cost-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },

    {
      title: t("User burden"),
      dataIndex: "burden_amount",
      key: "userBurden",
      width: 144,
      className: "user-burden-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },

    {
      title: t("After control result user burden"),
      dataIndex: "management_result_burden_amount",
      key: "afterCtrlResultUserBurden",
      width: 144,
      className: "after-ctrl-result-user-burden-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },
  ]
}
