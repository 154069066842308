import Link from "next/link"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../.."

const Wrapper = styled.div`
  .schedule-card {
    width: 100%;
    padding: 20px;
    border: 1px solid;
    border-radius: 12px;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
    .schedule-card-title {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
    .schedule-card-underlined {
      text-decoration: underline;
    }
  }
  .warning-card {
    color: #ca3030;
    border-color: #ca3030;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .success-card {
    border-color: #4cb476;
    color: #4cb476;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .alert-card {
    color: #e26827;
    border-color: #e26827;
  }
`

export const AttendanceScheduleCards = ({ year, month, facilityId }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className={"schedule-card warning-card"}>
        <div>
          <div className={"schedule-card-title"}>
            <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
            <span>
              {t(
                "Subject to deduction for lack of service delivery personnel."
              )}
            </span>
          </div>
          <div className={"schedule-card-content"}>
            {t(
              "Please confirm staffing More than 10% of the hours are not met."
            )}
            <br />
            <span className={"schedule-card-underlined"}>
              <Link
                href={`/facility/system-status/${facilityId}?year=${year}&month=${month}`}
              >
                <a>{t("Click here for system status management")}</a>
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className={"schedule-card success-card"}>
        <div>
          <div className={"schedule-card-title"}>
            <img src={"/assets/icons/green-arrow.svg"} alt={"icon"} />
            <span>
              {t(
                "Met calculation requirements of addition (I) such as welfare staff placement."
              )}
            </span>
          </div>
          <div className={"schedule-card-content"}>
            {t("System status management")} {": "}
            <span className={"schedule-card-underlined"}>
              <Link
                href={`/facility/system-status/${facilityId}?year=${year}&month=${month}`}
              >
                <a>{t("Child development support")}</a>
              </Link>
            </span>
          </div>
        </div>
        <div className={"schedule-card-action"}>
          <OwnerButton text={t("View details")} typeOf={"secondary"} />
        </div>
      </div>
      <div className={"schedule-card alert-card"}>
        <div className={"schedule-card-title"}>
          <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
          <span>
            {t(
              "The latest registered information will be reflected because the system status for this month has not been registered."
            )}
          </span>
        </div>
        <div className={"schedule-card-content"}>
          <span>{t("More than 10% of the hours are not met.")}</span>
          <div className={"schedule-card-accord"}>
            <img src={"/assets/icons/orange-arrow.svg"} alt={"icon"} />
            <span className={"schedule-card-underlined"}>
              <Link
                href={`/facility/system-status/${facilityId}?year=${year}&month=${month}`}
              >
                <a>{t("displayStatusOfLatestInfo", { year, month })}</a>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
