import React from "react"

// packages
import { t } from "i18next"
import { Container } from "./styles"
import { useRouter } from "next/router"

// components
import { OwnerButton } from "../../atoms"
import { FilterOperationBlock, ProxyMoreInfoBlock } from "./components"

// types
import { ProxyPrintPageProps } from "./types"

export const ProxyPrintPage: React.FC<ProxyPrintPageProps> = ({
  currentSearchQueries,
  isBillingDetailsLoading,
  ChildOutputPrintDetails,
  handleCurrentSearchQueriesChange,
}) => {
  const router = useRouter()
  const { year, month, facility_id } = router.query

  return (
    <Container>
      <FilterOperationBlock
        currentSearchQueries={currentSearchQueries}
        handleCurrentSearchQueriesChange={handleCurrentSearchQueriesChange}
      />

      {/* middle action buttons group starts here */}
      <div className={"action-btn-group flexbox no-print"}>
        <OwnerButton
          typeOf={"secondary"}
          text={t("Cancel")}
          onClick={() => {
            router.push({
              pathname:
                "/national-health-insurance-billing/prints/print-proxy-acknowledgement",
              query: {
                year,
                month,
                facility_id,
              },
            })
          }}
        />
        <OwnerButton
          typeOf={"primary"}
          icon={"print"}
          className={"print-btn"}
          shape={"round"}
          text={t("Print")}
          onClick={() => {
            window.print()
          }}
        />
      </div>
      {/* middle action buttons group ends here */}

      <ProxyMoreInfoBlock
        currentSearchQueries={currentSearchQueries}
        ChildOutputPrintDetails={ChildOutputPrintDetails}
        isBillingDetailsLoading={isBillingDetailsLoading}
      />
    </Container>
  )
}
