import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import moment from "moment"
import router from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { OperationDailyReportView } from "../../molecules"
import { getBusinessDailyReportView } from "../../../services/businessDailyReport"
import {
  ATTENDANCE_LABEL_FOR_CHILD,
  isStaffScheduledForDate,
} from "../../../constants/attendance"
import { OwnerButton } from "../../atoms"
import { getDateStringFromDate } from "../../../utils"
import {
  ChildTable,
  ContentImageTable,
  CustomButton,
  HeaderTable,
  Spinner,
  StaffTable,
  Wrapper,
} from "./DailyBusinessReportViewPage.style"

interface IDailyBusinessReportView {
  current_facility: any
  current_date: string
  facilities: Array<any> | null
}

export const DailyBusinessReportViewPage = ({
  current_facility,
  current_date,
  facilities,
}: IDailyBusinessReportView) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useState({
    facility: current_facility?.value,
    date: current_date,
  })

  useEffect(() => {
    router.push(
      `?facility_id=${searchParams.facility}&date=${searchParams.date}`,
      undefined,
      { shallow: true }
    )
  }, [searchParams])

  const { data: reportData, isLoading } = useQuery(
    ["fetch-daily-business-report-view", searchParams],
    async () => {
      const { data } = await getBusinessDailyReportView({
        facilityId: searchParams.facility,
        date: searchParams.date,
      })
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (resp: any) => {
        const scheduledStaffs = []
        resp?.staff_attendance?.map((attData) => {
          const schedule = Array.isArray(attData?.staff_attendance_schedules)
            ? attData?.staff_attendance_schedules[0]
            : {}
          let weeklySchedule: any = {}
          if (!schedule?.id)
            switch (new Date(searchParams?.date).getDay()) {
              case 0:
                weeklySchedule = attData?.sun_schedule
                break
              case 1:
                weeklySchedule = attData?.mon_schedule
                break
              case 2:
                weeklySchedule = attData?.tue_schedule
                break
              case 3:
                weeklySchedule = attData?.wed_schedule
                break
              case 4:
                weeklySchedule = attData?.thu_schedule
                break
              case 5:
                weeklySchedule = attData?.fri_schedule
                break
              case 6:
                weeklySchedule = attData?.sat_schedule
                break
            }
          const record = Array.isArray(attData?.staff_attendance_results)
            ? attData?.staff_attendance_results[0]
            : {}
          if (
            isStaffScheduledForDate({
              dateSchedule: schedule,
              weeklySchedule: {
                sun_schedule: attData?.sun_schedule,
                mon_schedule: attData?.mon_schedule,
                tue_schedule: attData?.tue_schedule,
                wed_schedule: attData?.wed_schedule,
                thu_schedule: attData?.thu_schedule,
                fri_schedule: attData?.fri_schedule,
                sat_schedule: attData?.sat_schedule,
              },
              date: searchParams.date,
            })
          ) {
            scheduledStaffs.push({
              staff_name: attData?.staff?.staff_name,
              schedule_start_time:
                schedule?.attendance_start_time ??
                weeklySchedule?.attendance_start_time,
              schedule_end_time:
                schedule?.attendance_end_time ??
                weeklySchedule?.attendance_end_time,
              schedule_start_time2:
                schedule?.attendance_start_time2 ??
                weeklySchedule?.attendance_start_time_2,
              schedule_end_time2:
                schedule?.attendance_end_time2 ??
                weeklySchedule?.attendance_end_time_2,
              attendance_type: record?.attendance_type,
              attendance_start_time: record?.attendance_start_time,
              attendance_end_time: record?.attendance_end_time,
              attendance_type2: record?.attendance_type2,
              attendance_start_time2: record?.attendance_start_time2,
              attendance_end_time2: record?.attendance_end_time2,
              remarks: record?.remarks,
            })
          }
        })

        return { ...resp, staff_attendance: scheduledStaffs }
      },
    }
  )

  const savedDisplayContents = JSON.parse(
    localStorage.getItem("displayContents")
  )

  const initialDisplayContents = savedDisplayContents || [
    "facility",
    "attendance",
    "pickup",
    "date",
    "activity",
    "entry",
    "enter_exit",
    "content",
    "temperature",
    "service",
    "actual_cost",
    "manager",
  ]

  const [operations, setOperations] = useState({
    facility: current_facility,
    date: current_date,
    displayContents: initialDisplayContents,
    pickup_display_style: 2,
  })

  const CHILD_TABLE_COLUMNS: any = [
    {
      key: "id",
      displayKey: "id",
      dataIndex: "child_name",
      title: "id",
      className: "id-row",
      colSpan: 0,
      render: (text, obj, index) => {
        return index + 1
      },
    },
    {
      key: "name",
      displayKey: "name",
      dataIndex: "child_name",
      title: t("Child name"),
      sorter: (a, b) => a?.child_name?.localeCompare(b?.child_name),
      sortDirections: ["descend", "ascend", "descend"],
      colSpan: 2,
    },
    {
      key: "service",
      displayKey: "service",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Service used")}</span>,
      sorter: (a, b) => {
        const text1 =
          a?.service_type == 2
            ? t("Child development support")
            : a?.service_type == 1
            ? t("After school day service")
            : ""
        const text2 =
          b?.service_type == 2
            ? t("Child development support")
            : b?.service_type == 1
            ? t("After school day service")
            : ""
        return text1.localeCompare(text2)
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, obj) => {
        if (obj?.service_type == 2) return t("Child development support")
        else if (obj?.service_type == 1) return t("After school day service")
        return ""
      },
    },
    {
      key: "attendance",
      displayKey: "attendance",
      dataIndex: "attendance_type",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Attendance")}</span>,
      sorter: (a, b) => {
        const text1 = t(ATTENDANCE_LABEL_FOR_CHILD?.[a?.attendance_type || 0])
        const text2 = t(ATTENDANCE_LABEL_FOR_CHILD?.[b?.attendance_type || 0])
        return text1.localeCompare(text2)
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (att) => t(ATTENDANCE_LABEL_FOR_CHILD?.[att || 0]),
    },

    {
      key: "provision",
      displayKey: "provision",
      dataIndex: "form_of_provision",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Form of provision")}</span>
      ),
      sorter: (a, b) =>
        a?.form_of_provision.localeCompare(b?.form_of_provision),
      sortDirections: ["descend", "ascend", "descend"],
      render: (att) => (att ? t(att) : "-"),
    },
    {
      key: "enter",
      displayKey: "enter_exit",
      dataIndex: "start_time",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Entering the room")}</span>
      ),
      sorter: (a, b) => a?.start_time?.localeCompare(b?.start_time),
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: "exit",
      displayKey: "enter_exit",
      dataIndex: "end_time",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Leaving the room")}</span>
      ),
      sorter: (a, b) => a?.end_time?.localeCompare(b?.end_time),
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: "temperature",
      displayKey: "temperature",
      dataIndex: "temperature",
      title: (
        <span
          className={"body-temperature-column"}
          style={{ whiteSpace: "nowrap" }}
        >
          {t("Body temperature upon entry")}
        </span>
      ),
      render: (_, record) => {
        return record.temperature.length && record.temperature != "0"
          ? `${record.temperature}°C`
          : null
      },
      sorter: (a, b) => a?.temperature?.localeCompare(b?.temperature),
      sortDirections: ["descend", "ascend", "descend"],
    },

    {
      key: "actual_cost",
      displayKey: "actual_cost",
      title: t("Actual cost"),
      sorter: (a, b) =>
        a?.actual_costs?.[0]?.actual_cost_price >
        b?.actual_costs?.[0]?.actual_cost_price,
      sortDirections: ["descend", "ascend", "descend"],
      render: (obj) => {
        if (Array.isArray(obj?.actual_costs) && obj?.actual_costs?.length > 0) {
          return obj?.actual_costs?.map((cost) => (
            <>
              {`${cost?.actual_cost_name}:${cost?.actual_cost_price}${t(
                "Yen"
              )}`}
              <br />
            </>
          ))
        }
        return <>{t("None ")}</>
      },
    },
    {
      key: "pickup",
      displayKey: "pickup",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Pick-up destination")}</span>
      ),
      children: [
        {
          key: "pickup_place",
          dataIndex: "pickup_place",
          title: <span style={{ whiteSpace: "nowrap" }}>{t("(Pickup)")}</span>,
          sorter: (a, b) => a?.pickup_place > b?.pickup_place,
          sortDirections: ["descend", "ascend", "descend"],
          render: (text, row) => {
            if (!row?.is_pickup) return "―"
            if (operations.pickup_display_style == 1) return t(text)
            return "◯"
          },
        },
        {
          key: "drop_place",
          dataIndex: "drop_place",
          title: <span style={{ whiteSpace: "nowrap" }}>{t("(Drop)")}</span>,
          sorter: (a, b) => a?.drop_place > b?.drop_place,
          sortDirections: ["descend", "ascend", "descend"],
          render: (text, row) => {
            if (!row?.is_drop) return "―"
            if (operations.pickup_display_style == 1) return t(text)
            return "◯"
          },
        },
      ],
    },
    {
      key: "service_content",
      displayKey: "service_content",
      dataIndex: "service_content",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Service content")}</span>
      ),
    },
  ]

  const STAFF_TABLE_COLUMNS: any = [
    {
      key: "id",
      dataIndex: "id",
      title: "id",
      colSpan: 0,
      render: (text, obj, index) => index + 1,
    },
    {
      key: "name",
      dataIndex: "staff_name",
      title: t("Name"),
      colSpan: 2,
    },
    {
      key: "shift",
      dataIndex: "schedule_start_time",
      title: t("Shift"),
      render: (text, obj) => {
        const shiftText =
          obj?.schedule_start_time && obj?.schedule_end_time
            ? obj?.schedule_start_time + "-" + obj?.schedule_end_time
            : ""
        const shiftText2 =
          obj?.schedule_start_time2 && obj?.schedule_end_time2
            ? obj?.schedule_start_time2 + "-" + obj?.schedule_end_time2
            : ""
        return shiftText + (shiftText2 && `, ${shiftText2}`)
      },
    },
    {
      key: "attendance1",
      dataIndex: "attendance1",
      title: t("Achievement 1"),
      render: (text, obj) => {
        return obj?.attendance_start_time && obj?.attendance_end_time
          ? obj?.attendance_start_time + "-" + obj?.attendance_end_time
          : ""
      },
    },
    {
      key: "attendance2",
      dataIndex: "attendance2",
      title: t("Achievement 2"),
      render: (text, obj) =>
        obj?.attendance_start_time2 && obj?.attendance_end_time2
          ? obj?.attendance_start_time2 + "-" + obj?.attendance_end_time2
          : "",
    },
    {
      key: "remarks",
      dataIndex: "remarks",
      className: "no-right-border",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t(
            "Record of business performance (especially in the case of overtime, fill in the reason and content)"
          )}
        </span>
      ),
    },
  ]

  const getWeekDay = (day: number) => {
    switch (day) {
      case 0:
        return t("Sun")
      case 1:
        return t("Mon")
      case 2:
        return t("Tue")
      case 3:
        return t("Wed")
      case 4:
        return t("Thu")
      case 5:
        return t("Fri")
      case 6:
        return t("Sat")
    }
  }

  const onPrint = () => {
    if (typeof window !== undefined) {
      window.print()
    }
  }

  const getDisplayDate = () => (
    <>
      {moment(searchParams?.date).format("YYYY年MM月DD日")}
      {`（${getWeekDay(new Date(searchParams?.date).getDay())}）`}
    </>
  )

  const shouldBeDisplayed = (key) => operations?.displayContents?.includes(key)

  const displayFileContent = (blobStr) => {
    const newBlobStr = blobStr.split("|")[0]
    const strSplit = blobStr?.split(";")
    const fileName = blobStr.split("|").pop()
    let type = strSplit[0]?.replaceAll("data:", "")
    if (type) {
      type = type?.split("/")[0]
    }
    switch (type) {
      case "image":
        return <img src={newBlobStr} alt={"report"} />
      default:
        return (
          <a href={newBlobStr} download={fileName}>
            {fileName || t("Image・File")}
          </a>
        )
    }
  }

  return (
    <Wrapper>
      <div className={"section-not-to-print"}>
        <OperationDailyReportView
          facility={current_facility}
          date={current_date}
          facilities={facilities}
          onSubmit={(settings) => {
            setOperations(settings)
            if (
              searchParams.facility != settings.facility.value ||
              searchParams.date != settings.date
            )
              setSearchParams({
                facility: settings.facility.value,
                date: moment(settings.date).format("YYYY-MM-DD"),
              })
          }}
        />
      </div>
      <div className={"print-pagination-container section-not-to-print"}>
        <div className={"print-back-container"}>
          <CustomButton
            background={"rgba(7, 130, 200, 0.2)"}
            shape={"circle"}
            borderColor={theme.blue5}
            onClick={() => {
              router.back()
            }}
          >
            {"<<"}
            {t("Back")}
          </CustomButton>
          <OwnerButton
            text={t("Print")}
            typeOf={"primary"}
            icon={"print"}
            shape={"round"}
            onClick={onPrint}
          />
        </div>
        <div className={"pagination-container"}>
          <CustomButton
            background={"#ffffff"}
            borderColor={theme.gray2}
            onClick={() => {
              const prevDay = new Date(searchParams.date)
              prevDay.setDate(prevDay.getDate() - 1)
              setSearchParams({
                facility: searchParams.facility,
                date: getDateStringFromDate(prevDay),
              })
            }}
          >
            <DoubleLeftOutlined />
            {t("Prev day")}
          </CustomButton>
          <CustomButton
            background={"rgba(7, 130, 200, 0.2)"}
            onClick={() => {
              const today = new Date()
              setSearchParams({
                facility: searchParams.facility,
                date: getDateStringFromDate(today),
              })
            }}
          >
            {t("Today")}
          </CustomButton>
          <CustomButton
            background={"#ffffff"}
            borderColor={theme.gray2}
            onClick={() => {
              const nextDay = new Date(searchParams.date)
              nextDay.setDate(nextDay.getDate() + 1)
              setSearchParams({
                facility: searchParams.facility,
                date: getDateStringFromDate(nextDay),
              })
            }}
          >
            {t("Next day")}
            <DoubleRightOutlined />
          </CustomButton>
        </div>
      </div>
      {isLoading ? (
        <Spinner size={"large"} />
      ) : !reportData?.report?.id ? (
        <div className={"no-report-day"}>
          {t("Daily report is not registered.")}
        </div>
      ) : (
        <>
          <div className={"daily-report-container section-to-print"}>
            <div className={"title-container"}>
              {t("Business daily report")}
            </div>
            <div className={"table-container"}>
              <HeaderTable>
                <tr>
                  {(shouldBeDisplayed("facility") ||
                    shouldBeDisplayed("date")) && (
                    <td
                      className={"facility_date"}
                      rowSpan={
                        shouldBeDisplayed("facility") &&
                        shouldBeDisplayed("date")
                          ? 1
                          : 2
                      }
                    >
                      {shouldBeDisplayed("facility")
                        ? current_facility?.label
                        : getDisplayDate()}
                    </td>
                  )}
                  {shouldBeDisplayed("activity") && (
                    <td className={"white-space-no-wrap"}>
                      {t("Today's Activity")}
                    </td>
                  )}
                  {shouldBeDisplayed("entry") && (
                    <>
                      <td rowSpan={2} className={"stamp_label"}>
                        {t("Entry Person")}
                      </td>
                      <td rowSpan={2} className={"stamp_value"}>
                        {reportData?.report?.staff?.staff_name}
                      </td>
                    </>
                  )}
                  {shouldBeDisplayed("manager") && (
                    <>
                      <td rowSpan={2} className={"stamp_label"}>
                        {t("Facility Manager Confirmation")}
                      </td>
                      <td rowSpan={2} className={"stamp_value"}></td>
                    </>
                  )}
                  {shouldBeDisplayed("approval") && (
                    <>
                      <td rowSpan={2} className={"stamp_label"}>
                        {t("Approval")}
                      </td>
                      <td rowSpan={2} className={"stamp_value"}></td>
                    </>
                  )}
                </tr>
                <tr>
                  {shouldBeDisplayed("facility") &&
                    shouldBeDisplayed("date") && (
                      <td className={"facility_date"}>{getDisplayDate()}</td>
                    )}
                  {shouldBeDisplayed("activity") && (
                    <td>
                      {reportData?.today_activities
                        ?.split(",")
                        ?.map((activity) => (
                          <>
                            {activity}
                            <br />
                          </>
                        ))}
                    </td>
                  )}
                </tr>
              </HeaderTable>
            </div>
            <ChildTable
              className={"child-table"}
              columns={CHILD_TABLE_COLUMNS.filter(
                (col) =>
                  col.displayKey == "id" ||
                  col.displayKey == "name" ||
                  shouldBeDisplayed(col.displayKey)
              )}
              dataSource={reportData?.child_attendance}
              bordered={true}
              showSorterTooltip={false}
              pagination={false}
            />
            {shouldBeDisplayed("content") && (
              <div className={"table-container"}>
                <ContentImageTable>
                  <tr>
                    <td className={"label-cell"}>{t("Activities content")}</td>
                    <td>
                      {" "}
                      <div style={{ whiteSpace: "pre-line" }}>
                        {reportData?.report?.activities_content}
                      </div>
                    </td>
                  </tr>
                </ContentImageTable>
              </div>
            )}

            {shouldBeDisplayed("content") && (
              <div className={"table-container"}>
                <ContentImageTable>
                  <tr>
                    <td className={"label-cell"}>{t("Transfer")}</td>
                    <td>
                      {" "}
                      <div style={{ whiteSpace: "pre-line" }}>
                        {reportData?.report?.transfer}
                      </div>
                    </td>
                  </tr>
                </ContentImageTable>
              </div>
            )}

            {shouldBeDisplayed("content") && (
              <div className={"table-container"}>
                <ContentImageTable>
                  <tr>
                    <td className={"label-cell"}>{t("Remarks")}</td>
                    <td>
                      {" "}
                      <div style={{ whiteSpace: "pre-line" }}>
                        {reportData?.report?.minutes_text}
                      </div>
                    </td>
                  </tr>
                </ContentImageTable>
              </div>
            )}

            {shouldBeDisplayed("image") && (
              <div className={"table-container"}>
                <ContentImageTable>
                  <tr style={{ borderBottom: "0px", borderTop: "0px" }}>
                    <td rowSpan={2} className={"label-cell"}>
                      {t("Image file")}
                    </td>
                    <td className={"cell-image"}>
                      {reportData?.report?.file_image1 &&
                        displayFileContent(reportData.report.file_image1)}
                    </td>
                    <td className={"cell-image"}>
                      {reportData?.report?.file_image2 &&
                        displayFileContent(reportData.report.file_image2)}
                    </td>
                  </tr>
                  <tr>
                    <td className={"cell-image"}>
                      {reportData?.report?.file_image3 &&
                        displayFileContent(reportData.report.file_image3)}
                    </td>
                    <td className={"cell-image"}>
                      {reportData?.report?.file_image4 &&
                        displayFileContent(reportData.report.file_image4)}
                    </td>
                  </tr>
                </ContentImageTable>
              </div>
            )}
            {shouldBeDisplayed("staff") && (
              <div className={"staff-table"} style={{ borderTop: "0px" }}>
                <StaffTable
                  columns={STAFF_TABLE_COLUMNS}
                  dataSource={reportData?.staff_attendance}
                  pagination={false}
                  bordered={true}
                  rowClassName={(_, index) => `staff-row 
                    ${
                      index + 1 == reportData?.staff_attendance?.length
                        ? "no-bottom-border"
                        : ""
                    }`}
                />
              </div>
            )}
          </div>
          <div className={"footer-buttons section-not-to-print"}>
            <CustomButton
              background={"rgba(7, 130, 200, 0.2)"}
              shape={"circle"}
              borderColor={theme.blue5}
              onClick={() => {
                router.push("/daily-business-report/")
              }}
            >
              {"<<"}
              {t("Back")}
            </CustomButton>
            <OwnerButton
              text={t("Print")}
              typeOf={"primary"}
              icon={"print"}
              shape={"round"}
              onClick={onPrint}
            />
          </div>
        </>
      )}
    </Wrapper>
  )
}
