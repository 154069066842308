import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Table, Typography } from "antd"
import {
  CaretDownOutlined,
  CloseCircleOutlined,
  FormOutlined,
} from "@ant-design/icons"

import {
  Checkboxes,
  Modal,
  SelectInput,
  TextField,
  TIME_INTERVALS,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { ModalContent, ScheduleWrapper } from "./WeeklySchedule.styles"

interface IProps {
  dataSource: any[]
  setDataSource: (values: any) => void
}

export const WeeklySchedule: React.FC<IProps> = ({
  dataSource,
  setDataSource,
}) => {
  const { t } = useTranslation()
  const [isBulkAdd, setIsBulkAdd] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [schedule, setSchedule] = useState<any>({
    id: null,
    week_no: null,
    start_time: "",
    end_time: "",
    comment: "",
    sIndex: null,
    eIndex: null,
  })
  const [operation, setOperation] = useState<"create" | "edit">("create")
  const [selectedSchedule, setSelectedSchedule] = useState<any>(null)
  const [checkedWeeks, setCheckedWeeks] = useState<any>([])
  const [error, setError] = useState("")
  const [errorDays, setErrorDays] = useState("")
  const [isEndTimeNotSelected, setIsEndTimeNotSelected] = useState(false)

  //create number of rows for the table
  const defaultDataSource = Array.from({ length: 56 }, (_, i) => ({
    key: i,
  }))

  const handleModalClose = () => {
    setSchedule({
      id: null,
      week_no: null,
      start_time: "",
      end_time: "",
      comment: "",
    })
    setError("")
    setErrorDays("")
    setSelectedSchedule(null)
    setOperation("create")
    setCheckedWeeks([])
    setIsBulkAdd(false)
    setIsModalOpen(false)
  }
  const checkForDuplicationTime = (arrData?: any[]) => {
    const intervals_times = [...TIME_INTERVALS]
    const filter_data = arrData
      ? arrData
      : dataSource?.filter((val) => val?.week_no === schedule?.week_no)
    if (filter_data?.length) {
      const newStartIndex = intervals_times?.findIndex(
        (val) => val?.value === schedule?.start_time
      )
      const newEndIndex = intervals_times?.findIndex(
        (val) => val?.value === schedule?.end_time
      )
      const newTimesIndexRange = intervals_times
        ?.slice(newStartIndex, newEndIndex)
        ?.map((val) => val?.value)
      const duplicates = filter_data?.map((v) => {
        const interval = intervals_times
          ?.slice(v?.sIndex, v?.eIndex)
          ?.map((val) => val?.value)
        return interval?.filter((val) => newTimesIndexRange?.includes(val))
      })

      return duplicates?.flat()?.length ? true : false
    }
    return false
  }

  const onBulkScheduleAdd = () => {
    if (checkedWeeks.length < 1) {
      setErrorDays(t("Required"))
    } else {
      const duplicateArray = checkedWeeks?.map((val) => {
        const data = dataSource?.filter((s) => s?.week_no === +val)
        return checkForDuplicationTime(data)
      })
      if (!duplicateArray?.includes(true)) {
        const data = checkedWeeks?.map((val, i) => ({
          ...schedule,
          id: new Date().getTime() + i,
          week_no: parseInt(val, 10),
        }))
        setDataSource([...dataSource, ...data])
        handleModalClose()
      } else {
        setError(t("Duplication of time"))
      }
    }
  }

  const handleSingleScheduleAdd = () => {
    const duplicateArray = checkForDuplicationTime()

    if (!duplicateArray) {
      setDataSource([...dataSource, { ...schedule }])
      handleModalClose()
    } else {
      setError(t("Duplication of time"))
    }
  }
  const onScheduleEdit = () => {
    const data = dataSource
      ?.filter((val) => val?.week_no === schedule?.week_no)
      ?.filter((v) => v?.id !== schedule?.id)
    const haveDuplicate = checkForDuplicationTime(data)
    if (schedule?.end_time) {
      if (!haveDuplicate) {
        const data = dataSource?.map((val) =>
          val?.week_no === selectedSchedule?.week_no &&
          val?.id === selectedSchedule?.id
            ? {
                ...val,
                ...schedule,
              }
            : val
        )
        setDataSource(data)
        handleModalClose()
      } else {
        setError(t("Duplication of time"))
      }
    } else {
      setIsEndTimeNotSelected(true)
    }
  }
  const onScheduleDelete = () => {
    const data = dataSource?.filter((val) => val?.id !== selectedSchedule?.id)
    setDataSource(data)
    handleModalClose()
  }

  const handleRowSpans = (rowIndex: number, data?: any) => {
    const dataArray = data || []
    for (let i = 0; i <= dataArray.length; i++) {
      const element = data[i]

      for (
        let j = element?.startIndex + 1;
        j <= element?.rowSpan + element?.startIndex - 1;
        ++j
      ) {
        if (rowIndex === j) {
          return { rowSpan: 0 }
        }
      }
      if (rowIndex === element?.startIndex) {
        return { rowSpan: element?.rowSpan }
      }
    }
  }
  const findStartTimeInterval = () => {
    const intervals = [...TIME_INTERVALS]
    return intervals?.splice(0, intervals?.length - 1)
  }
  const findEndTimeIntervals = (value) => {
    const remainingIntervals = [...TIME_INTERVALS]
    const index = remainingIntervals?.findIndex((val) => val?.value === value)
    return remainingIntervals.splice(index + 1, remainingIntervals.length)
  }

  const findStartIndexAndRowSpanValue = (data) => {
    const rows = TIME_INTERVALS?.map((val) => val?.value)
    const withIndex = data?.map((val) => {
      const startIndex = rows?.findIndex((v) => v === val?.start_time)
      const endIndex = rows?.findIndex((v) => v === val?.end_time)
      const value =
        startIndex < 0 || endIndex < 0
          ? 0
          : rows?.findIndex((v) => v === val?.end_time) -
            rows?.findIndex((v) => v === val?.start_time)
      const rowSpan = value < 0 ? 0 : value
      return {
        ...val,
        startIndex: rows?.findIndex((v) => v === val?.start_time),
        endIndex: rows?.findIndex((v) => v === val?.end_time),
        rowSpan: rowSpan,
      }
    })

    return withIndex
  }

  const renderColumnContent = (i, week_no, data) => {
    const content = data?.find((val) => val.startIndex === i)
    const start_time = content?.start_time || TIME_INTERVALS[i].value
    const end_time = content?.end_time || TIME_INTERVALS[i + 1].value
    const defaultValues = {
      id: content?.id || new Date().getTime() + i,
      week_no: content?.week_no || week_no,
      start_time: start_time,
      end_time: end_time,
      comment: content?.comment || "",
      sIndex: TIME_INTERVALS?.findIndex((val) => val?.value === start_time),
      eIndex: TIME_INTERVALS?.findIndex((val) => val?.value === end_time),
    }

    return (
      <>
        {content?.start_time ? (
          <div
            className={"has-time-content"}
            onClick={() => {
              setOperation("edit")
              setSelectedSchedule((prev) => ({
                ...prev,
                ...defaultValues,
              }))
              setSchedule((prev) => ({
                ...prev,
                ...defaultValues,
              }))
              setIsModalOpen(true)
            }}
          >
            <span>
              {content?.start_time} <em>{"~"}</em>
              {content?.end_time}
            </span>
            <FormOutlined />
          </div>
        ) : (
          <div
            className={"time-content"}
            onClick={() => {
              setSchedule((prev) => ({
                ...prev,
                ...defaultValues,
              }))
              setIsModalOpen(true)
            }}
          />
        )}
      </>
    )
  }

  const columns: any = useMemo(
    () => [
      {
        key: 0,
        title: "",
        align: "center",
        width: 100,
        className: "date-column",
        render: (row, _, i) => {
          const hours = `0${Math.floor(i / 4) + 6}`
          const time = `${hours.slice(-2)}:00`
          return {
            children: <div className={"time"}>{time}</div>,
          }
        },
        onCell: (_, index) => {
          if (index === 0) {
            return { rowSpan: 4 }
          }
          if (index >= 4 && index % 4 === 0) {
            return { rowSpan: 4 }
          }
          return {
            rowSpan: 0,
          }
        },
      },
      {
        key: 1,
        width: 200,
        title: t("Monday "),
        align: "center",
        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 1) || []
          )
          return renderColumnContent(i, 1, data || [])
        },
        onCell: (_, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 1) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 2,
        title: t("Tuesday "),
        align: "center",
        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 2) || []
          )
          return renderColumnContent(i, 2, data || [])
        },
        onCell: (_, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 2) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 3,
        title: t("Wednesday "),
        align: "center",
        render: (_, __, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 3) || []
          )
          return renderColumnContent(i, 3, data || [])
        },
        onCell: (_, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 3) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 4,
        title: t("Thursday "),
        align: "center",
        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 4) || []
          )
          return renderColumnContent(i, 4, data || [])
        },
        onCell: (row, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 4) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 5,
        title: t("Friday "),
        align: "center",
        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 5) || []
          )
          return renderColumnContent(i, 5, data || [])
        },
        onCell: (row, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 5) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 6,
        title: t("Saturday "),
        align: "center",
        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 6) || []
          )
          return renderColumnContent(i, 6, data || [])
        },
        onCell: (row, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 6) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        key: 7,
        title: t("Sunday "),
        align: "center",

        render: (row, _, i) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 7) || []
          )
          return renderColumnContent(i, 7, data || [])
        },
        onCell: (row, index) => {
          const data = findStartIndexAndRowSpanValue(
            dataSource?.filter((val) => val?.week_no === 7) || []
          )
          return handleRowSpans(index, data)
        },
      },
      {
        width: 50,
        key: 8,
        title: "",
        className: "extra_col",
      },
    ],
    [dataSource]
  )

  return (
    <>
      <ScheduleWrapper>
        <div className={"title"}>
          {t(
            "Weekly schedule Click on the schedule to open the registration screen"
          )}
        </div>
        <div>
          <OwnerButton
            text={t("Add schedule in bulk")}
            typeOf={"secondary"}
            icon={"add"}
            onClick={() => {
              setSchedule((prev) => ({
                ...prev,
                start_time: "06:00",
                end_time: "06:15",
                sIndex: 0,
                eIndex: 1,
              }))
              setIsBulkAdd(true)
              setIsModalOpen(true)
            }}
          />
        </div>
        <div className={"weekly-table-container"}>
          <Table
            columns={columns}
            dataSource={defaultDataSource}
            pagination={false}
          />
        </div>
      </ScheduleWrapper>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          width={isBulkAdd ? 750 : ""}
          centered
          footer={null}
          title={t("Register new schedule")}
          closeIcon={
            <CloseCircleOutlined
              style={{
                fontSize: "20px",
              }}
            />
          }
          onCancel={handleModalClose}
        >
          <ModalContent>
            {error ? (
              <Typography.Text
                style={{
                  color: "red",
                }}
              >
                <CaretDownOutlined />
                <span>{error}</span>
              </Typography.Text>
            ) : null}

            <div
              className={isBulkAdd ? "input-container-bulk" : "input-container"}
            >
              <div className={"input-content"}>
                <span>
                  {t("Start time")} {":"}
                </span>
                <SelectInput
                  options={findStartTimeInterval()}
                  placeholder={"---"}
                  width={150}
                  height={40}
                  value={schedule?.start_time || null}
                  onChange={(val) => {
                    const index = TIME_INTERVALS?.findIndex(
                      (time) => time?.value === `${val}`
                    )
                    setSchedule((prev) => ({
                      ...prev,
                      start_time: val,
                      end_time: null,
                      sIndex: index,
                    }))
                  }}
                />
              </div>
              <div style={{}}>
                <div className={"input-content"}>
                  <span>
                    {t("End time")} {":"}
                  </span>
                  <SelectInput
                    options={findEndTimeIntervals(schedule?.start_time)}
                    placeholder={"---"}
                    width={150}
                    height={40}
                    value={schedule?.end_time || null}
                    onChange={(val) => {
                      const index = TIME_INTERVALS?.findIndex(
                        (time) => time?.value === `${val}`
                      )
                      setSchedule((prev) => ({
                        ...prev,
                        end_time: val,
                        eIndex: index,
                      }))
                      setIsEndTimeNotSelected(val ? false : true)
                    }}
                    error={isEndTimeNotSelected ? t("Required") : null}
                  />
                </div>
              </div>
            </div>
            {isBulkAdd ? (
              <div className={"days-container"}>
                <span>
                  {t("Day of week")} {":"}
                </span>
                <div className={"days"}>
                  <Checkboxes
                    options={[
                      { label: t("Mon"), value: "1" },
                      { label: t("Tue"), value: "2" },
                      { label: t("Wed"), value: "3" },
                      { label: t("Thu"), value: "4" },
                      { label: t("Fri"), value: "5" },
                      { label: t("Sat"), value: "6" },
                      { label: t("Sun"), value: "7" },
                    ]}
                    value={checkedWeeks}
                    onChange={(val) => setCheckedWeeks(val)}
                  />
                  <div className={"error_days"}>
                    {checkedWeeks.length === 0 ? (
                      <Typography.Text
                        style={{
                          color: "red",
                        }}
                      >
                        <span>{errorDays}</span>
                      </Typography.Text>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <div className={"area-container"}>
              <span>{t("Comment")}</span>
              <TextField
                type={"textarea"}
                rows={3}
                bgcolor={"transparent"}
                value={schedule?.comment}
                onChange={({ target: { value } }) =>
                  setSchedule((prev) => ({ ...prev, comment: value }))
                }
              />
            </div>
            <div className={"cta"}>
              <OwnerButton
                text={t("Cancel")}
                shape={"circle"}
                typeOf={"secondary"}
                onClick={handleModalClose}
              />
              {operation === "edit" ? (
                <>
                  <OwnerButton
                    text={t("Save")}
                    shape={"circle"}
                    typeOf={"primary"}
                    onClick={onScheduleEdit}
                  />
                  <OwnerButton
                    text={t("delete")}
                    shape={"circle"}
                    onClick={onScheduleDelete}
                  />
                </>
              ) : (
                <OwnerButton
                  text={t("Save")}
                  shape={"circle"}
                  typeOf={"primary"}
                  onClick={() => {
                    if (isBulkAdd) {
                      onBulkScheduleAdd()
                    } else {
                      handleSingleScheduleAdd()
                    }
                  }}
                />
              )}
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
