import React, { useEffect } from "react"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import styled from "styled-components"
import { Grid, Required, SelectInput, TextField, theme } from "@project/shared"
import { scrollToFirstErrorField, StyledForm } from "../../../utils"
import { OwnerButton } from "../../atoms"
import { Info } from "../AddConsultationSupportOfficeForm"
import { useQuery } from "react-query"
import { fetchServiceProvisionsCategoryList } from "../../../services"
import { Popconfirm, Spin } from "antd"

const InputContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: ${theme.breakpoints.md}) {
    display: block;
  }

  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
`
const SelectInputContainer = styled.div`
  width: 100%;
  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
`

const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }
`

interface IProps {
  isLoading?: boolean
  operation: "create" | "edit"
  defaultValues?: any
  handleSubmit: (val: any) => void
  showDeleteButton?: boolean
  isDeleting?: boolean
  handleDelete?: () => void
}

export const ServiceProvisionCheckItemForm: React.FC<IProps> = ({
  isLoading,
  operation,
  defaultValues,
  handleSubmit,
  showDeleteButton = false,
  isDeleting = false,
  handleDelete,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const categoryId = +router?.query?.category_id || undefined
  const { data: categoryLists, isLoading: categoryLoading } = useQuery({
    queryKey: ["service_provision_category_list"],
    queryFn: fetchServiceProvisionsCategoryList,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: (res: any) => {
      return res?.data?.map((val) => ({
        label: val?.service_content_category_name,
        value: val?.id,
      }))
    },
  })
  const formik = useFormik({
    initialValues: defaultValues || {
      category_id: null,
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(t("Required"))
        .max(100, t("Please enter number within 100 characters")),
      category_id: Yup.string().required(t("Required")),
    }),
    onSubmit: (values) => handleSubmit(values),
  })
  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  return (
    <>
      {categoryLoading ? (
        <Spinner size={"large"} />
      ) : (
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid className={"grid-header"} background>
            <div className={"label-wrapper"}>
              <div style={{ width: "fit-content" }}>
                {t("Service provision item category")}
              </div>
              <Required style={{ marginLeft: "4px" }} />
            </div>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <SelectInputContainer>
              <SelectInput
                options={categoryLists}
                height={50}
                disabled={operation == "edit" ? true : false}
                id={"category_id"}
                name={"category_id"}
                placeholder={t("Select Service provision item category")}
                bordercolor={theme.gray2}
                value={formik.values.category_id}
                className={getErrorClass("category_id", "input")}
                loading={categoryLoading}
                onChange={(val: any) =>
                  formik?.setFieldValue("category_id", val)
                }
                error={
                  formik.touched?.category_id &&
                  formik.errors?.category_id &&
                  t("Required")
                }
              />
            </SelectInputContainer>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header field-label-container"} background>
            <div className={"label-wrapper"}>
              <div>{t("Service provision check item name")}</div>
              <Required style={{ marginLeft: "4px" }} />
            </div>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <InputContainer>
              <TextField
                name={"name"}
                height={"50px"}
                className={getErrorClass("name", "input")}
                value={formik.values.name}
                onChange={formik.handleChange}
                maxLength={100}
                placeholder={t("例：読み書き")}
                error={formik.errors?.name}
              />
              <Info className={"info"}>{t("(within 100 characters)")}</Info>
            </InputContainer>
          </Grid>
          <Grid className={"flex-break"} />
          <ButtonContainer>
            <div className={"button-wrapper service-provision-form-btns"}>
              <OwnerButton
                typeOf={"secondary"}
                onClick={() =>
                  router.push(
                    categoryId
                      ? `/service-provision-items?category_id=${categoryId}`
                      : `/service-provision-items`
                  )
                }
                className={"cancel-btn"}
                text={t("Cancel")}
                disabled={isLoading}
              />
              <OwnerButton
                type={"submit"}
                typeOf={"primary"}
                disabled={isLoading}
                text={operation === "create" ? t("Save") : t("Edit")}
                isLoading={isLoading}
              />
            </div>
            {showDeleteButton && (
              <div className={"button-wrapper service-provision-form-btns"}>
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                  onConfirm={handleDelete}
                >
                  <OwnerButton
                    typeOf={"primary"}
                    text={t("Delete ")}
                    className={"delete-button"}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              </div>
            )}
          </ButtonContainer>
        </StyledForm>
      )}
    </>
  )
}
