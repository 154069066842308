import { DatePickerProps } from "antd"
import * as React from "react"
import CalendarIcon from "../../../public/assets/icons/CalendarIcon"
import { SharedDatePicker } from "@project/shared"
import { DatePickerCompProps } from "@project/shared/src/interface"

const DatePicker: React.FC<DatePickerCompProps & DatePickerProps> = (props) => (
  <SharedDatePicker {...props} icon={CalendarIcon} />
)
export { DatePicker }
