// packages
import { t } from "i18next"

// styled components
import { Tag as ChildNameCollTag } from "../styles"

// components
import { OwnerButton } from "../../.."
import { WithSorter } from "../components"
import moment from "moment"
// types
import { UseTableProps } from "../types"
import { ALL_SERVICE_USE_OPTIONS } from "../../../../constants/monitoring"
import { theme } from "@project/shared"

const useTable: UseTableProps = (
  setOpenDeleteModal,
  setUserId,
  setIsAddNewUserModalVisible
) => {
  const columns = [
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"Child name"}
            columnKey={"child_name"}
          />
        )
      },
      dataIndex: "child_name",
      key: "child_name",
      width: 171,
      sorter: (a, b) => a.child_name.localeCompare(b.child_name),
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"Using Service"}
            columnKey={"service_type"}
          />
        )
      },
      dataIndex: "service_type",
      key: "service_type",
      width: 241,
      render: (val) => {
        const color = val === 1 ? "rgb(226, 104, 39)" : "rgb(77, 162, 248)"

        const service_type = ALL_SERVICE_USE_OPTIONS.find(
          (option) => option.value === val
        )
        return service_type ? (
          <ChildNameCollTag bgcolor={color} color={theme.base}>
            {service_type?.label}
          </ChildNameCollTag>
        ) : (
          ""
        )
      },
      sorter: (a, b) => {
        const aColl = a.service_type ?? 0
        const bColl = b.service_type ?? 0
        return aColl - bColl
      },
    },

    {
      title: t("Price"),
      dataIndex: "cost",
      key: "price",
      width: 144,
      render: (val) => {
        return (
          <span>
            {"￥"}
            {val.toLocaleString()}
          </span>
        )
      },
    },

    {
      title: t("Edit"),
      key: "edit",
      width: 100,
      render: (record: any) => {
        return (
          <OwnerButton
            typeOf={"link"}
            text={t("Edit")}
            className={"link-btn"}
            onClick={() => {
              record.isEdit = true
              setUserId(record)
              setIsAddNewUserModalVisible(true)
            }}
          />
        )
      },
    },

    {
      title: t("delete"),
      key: "delete",
      width: 100,
      render: (record) => {
        return (
          <OwnerButton
            typeOf={"link"}
            text={t("delete")}
            className={"link-btn delete-link"}
            onClick={() => {
              setUserId(record)
              setOpenDeleteModal(true)
            }}
          />
        )
      },
    },
    {
      title: t("Last update"),
      width: 429,
      render: (data: any) => {
        return `${moment(data.last_update).format("YYYY年MM月DD日 HH:mm")} ${
          data?.user_name
        }`
      },
    },
  ]

  return {
    columns,
  }
}

export default useTable
