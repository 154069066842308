import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"
const Container = styled.div`
  background: ${theme.bgColor};
  padding: 10px 40px;

  .life-record-print-table {
    @media print {
      .ant-table {
        thead.ant-table-thead > tr > th.ant-table-cell,
        tbody.ant-table-tbody > tr > td.ant-table-cell {
          text-align: center;
          white-space: pre-line;
          word-break: break-all;
        }
      }
      .date {
        width: 150px;
      }
      .facility {
        width: 240px;
      }
      .life-record {
        width: 190px;
      }
      .entered-by {
        width: 180px;
      }
    }

    @media print and (orientation: landscape) {
      .date {
        width: 150px;
      }
      .facility {
        width: 240px;
      }
      .life-record {
        width: 300px;
      }
      .entered-by {
        width: 180px;
      }
    }
  }
  @media (max-width: 900px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 10px 5px;
  }
  @media print {
    background: #ffffff;
    &.print-stylizer {
      padding: 0px 0px;
    }
  }
`
interface PageContainerProps {
  children: React.ReactNode
}
export const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return <Container className={"print-stylizer"}>{children}</Container>
}
