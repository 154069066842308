import { dynamicLangString } from "@project/shared"
import { notification } from "antd"
import { useRouter } from "next/router"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { getAllChildUnpaginatedAllContract } from "../../../services"
import {
  addIndividualSupportPlan,
  deleteIndividualSupportPlan,
  getOneIndividualSupportPlan,
  updateIndividualSupportPlan,
} from "../../../services/individualSupportPlanning"
import { AuthContext } from "../../../utils"

export const useIndividualSupportPlanningAPIs = ({ id, type }: any) => {
  const { facilities } = useContext(AuthContext)
  const { push } = useRouter()
  const { t } = useTranslation()
  const URL =
    type === "LIST"
      ? `/individual-support-plans`
      : `/individual-support-draft-plans`
  const SUCCESS_MSG =
    type === "LIST"
      ? t("Individual support planning")
      : t("Individual support planning (draft)")

  const { data: childInformation, isLoading: childInfoIsLoading } = useQuery(
    ["getAllChildren", "show_child_without_contract"],
    getAllChildUnpaginatedAllContract,
    {
      select: (data) => {
        return data?.data.map((child) => ({
          label: child.child_name,
          value: child.id,
          furigana: child.child_name_furigana,
          recipient_number:
            child.child_receiving_certificates[0]
              ?.receiving_certificate_number || 0,
          facility_name: child?.facility?.facility_name_short,
          facility_id: child?.facility?.id,
          service_type:
            child?.child_receiving_certificates[0]?.service_type || null,
        }))
      },
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: 2,
      cacheTime: 2 * (60 * 1000),
    }
  )

  const {
    data: individualSupportPlanning,
    isLoading: individualSupportPlanningisLoading,
    isError: individualSupportPlanninghasError,
  } = useQuery([
    "getIndividualSupportPlanning",
    () => getOneIndividualSupportPlan(id),
    {
      enabled: !!id,
    },
  ])

  const FACILITIES_OPTIONS = facilities?.map((facility) => ({
    label: facility.facility_name_short,
    value: facility.id,
  }))

  const getFacilityAdminByFacilityID = (id: number) => {
    return facilities.find((each) => each.id === id)?.administrator_name
  }
  //child individual support plan apis for no draft
  const {
    mutate: addIndividualSupportPlanFunction,
    isLoading: addingIndividualSupportPlan,
  } = useMutation(addIndividualSupportPlan, {
    onSuccess: (response: any) => {
      notification.success({
        message: dynamicLangString([SUCCESS_MSG, "Created Successfully"]),
      })
      if (response?.data?.id) {
        push(`${URL}/detail/${response?.data?.id}`)
      } else {
        push(URL)
      }
    },
    onError: (err: any) => {
      const message =
        err?.data?.error ==
        "User does not have permission to access the resource"
          ? t("User doesn't have permission to create this page")
          : t("Something went wrong. Please contact administrator.")
      notification.error({
        message,
      })
    },
  })
  const {
    mutate: updateIndividualSupportPlanFunction,
    isLoading: updatingIndividualSupportPlan,
  } = useMutation(updateIndividualSupportPlan, {
    onSuccess: (_, variables: any) => {
      const id = variables?.values?.id
      notification.success({
        message: dynamicLangString([SUCCESS_MSG, "Updated Successfully"]),
      })
      if (id) {
        push(`${URL}/detail/${id}`)
      } else {
        push(URL)
      }
    },
    onError: (err: any) => {
      const message =
        err?.data?.error ==
        "User does not have permission to access the resource"
          ? t("User doesn't have permission to edit this page")
          : t("Something went wrong. Please contact administrator.")
      notification.error({
        message,
      })
    },
  })

  const { mutate: deleteSupportPlan, isLoading: isDeleting } = useMutation(
    deleteIndividualSupportPlan,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([SUCCESS_MSG, "Deleted Successfully"]),
        })
        push(URL)
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  return {
    childInformation,
    childInfoIsLoading,
    FACILITIES_OPTIONS,
    addIndividualSupportPlanFunction,
    addingIndividualSupportPlan,
    getFacilityAdminByFacilityID,
    individualSupportPlanning,
    individualSupportPlanningisLoading,
    individualSupportPlanninghasError,
    updateIndividualSupportPlanFunction,
    updatingIndividualSupportPlan,
    isDeleting,
    deleteSupportPlan,
  }
}
