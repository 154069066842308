import { theme } from "@project/shared"

import styled from "styled-components"
export const Wrapper = styled.div`
  width: 100%;
  .top_content {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
    }
    .pagi {
      display: flex;
      gap: 10px;
      @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  .btns-conten {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
`

export const ShiftContainer = styled.div`
  width: 150px;
  text-align: left;
`
export const WorkingHoursContainer = styled.div`
  .hours_ranges_container {
    width: 100%;
    display: flex;
    &:not(:first-child) {
      border-top: 1px solid ${theme.gray2};
    }
    .hour-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      white-space: nowrap;
      padding: 10px;
    }
    .range {
      padding: 10px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-left: 1px solid ${theme.gray2};
      border-right: 1px solid ${theme.gray2};
      .start_date,
      .end_date {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 3px;
      }
      button {
        width: max-content;
      }
    }
    .break_time {
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 0 10px;
      border-right: 1px solid ${theme.gray2};
      .text {
        white-space: nowrap;
      }
      .break_time_input {
        display: flex;
        align-items: center;

        gap: 5px;
        .input {
          width: 60px;
          margin-top: 10px;
        }
      }
    }
  }
`
export const CatBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
`
export const VacationTimeColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  width: fit-content;
  .set_vacation_box {
    label {
      display: flex;
    }
  }
  .vacation_time {
    display: flex;
    align-items: center;
    gap: 5px;
    .input_box {
      margin-top: 8px;
      min-width: 60px;
    }
  }
`
