import { API } from "@project/shared"

export const getProgramSchedule: any = async (params) => {
  const year = params.queryKey[1].year
  const month = params.queryKey[1].month
  const facility_id = params.queryKey[1].facility_id
  return API.get(`/program_schedule/facility/${facility_id}/${year}/${month}`)
}

export const getPrograms: any = async (params) => {
  const categoryId = params.queryKey[1]
  return API.get(`/programs?CategoryId=${categoryId}`)
}

export const getAllPrograms: any = async () => {
  return API.get(`/programs?page=1&pageSize=Infinity`)
}

export const getProgramsCategories: any = async () => {
  return API.get(`/program_categories`)
}

export const updateProgramSchedule: any = async (params: any) => {
  return API.post(
    `/program_schedule/facility/${params.facility_id}/${params.year}/${params.month}`,
    params.body
  )
}

export const deleteMonthlyActivity: any = async (params: any) =>
  API.delete(
    `/program_schedule/facility/${params.facility_id}/${params.year}/${params.month}`
  )
