import { t } from "i18next"

type Props = {
  managementResult: number
}

export function DefaultUpperLimitManagementResult({ managementResult }: Props) {
  return (
    <>
      <div className={"monthly_limit__amount"}>
        {/* TODO: translate */}
        <div className={"large"}>{"利用者負担上限額管理結果"}</div>
        <div className={"small"}>{managementResult}</div>
      </div>
      <div className={"text__content"}>
        <ol>
          <li>
            {t(
              `Since the user-paid amount was allocated at the managed facility, no user-paid amount is incurred at the other facilities.`
            )}
          </li>
          <li>
            {t(
              `Since the total amount of user charges is less than or equal to the maximum monthly amount to be borne no adjustment is made.`
            )}
          </li>
          <li>
            {t(
              `Since the total amount of user charges exceeded the maximum monthly amount to be borne, the following adjustment was made.`
            )}
          </li>
        </ol>
      </div>
    </>
  )
}
