import { useCallback } from "react"
import { useTranslation } from "react-i18next"

// styled components
import { ErrorMessage } from "../styles"

// components
import { SelectInput } from "@project/shared"
import { GoalAchievementRadioButtons } from "../components"

// types
import { UseTableData } from "../types"
import { FormFieldsProps } from "../../../molecules/FormFields/types"

// utils
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"
import { mapAllProgramCategoryResponse } from "../../../../utils/programProgressMapper"

const useForm = ({
  formik,
  childOptions,
  facilityOptions,
  isChildListLoading,
  programCategoryOptions,
  isProgramCategoryListLoading,
  pageType,
}: UseTableData) => {
  const mappedProgramCategoryOptions = mapAllProgramCategoryResponse(
    programCategoryOptions
  )
  const { t } = useTranslation()

  const recordFormSchema: () => FormFieldsProps = useCallback(
    () => [
      {
        required: true,
        fieldLabel: "Year month",
        isRowFlexed: true,
        fields: [
          {
            name: "yearMonth",
            fieldType: {
              type: "date",
              extraOptions: {
                format: "YYYY年MM月",
                picker: "month",
              },
            },
          },
        ],
      },
      {
        required: true,
        fieldLabel: "Facility",
        isRowFlexed: true,
        fields: [
          {
            name: "facilityId",
            width: "auto",
            className: "facility-dropdown-container",
            fieldType: {
              type: "dropdown",
              extraOptions: {
                option: facilityOptions,
                placeholder: "All",
                selectInputClassName: "dynamic-dropdown-width",
                dropdownMatchSelectWidth: false,
                onChange: (val) => {
                  formik.setFieldValue(`facilityId`, val)
                },
              },
            },
            isDisabled: pageType == "EDIT",
          },
        ],
      },

      {
        required: true,
        fieldLabel: "Child",
        isRowFlexed: true,
        fieldClassName: "child-dropdown-field",
        fields: [
          {
            name: "childId",
            width: 180,
            className: "child-dropdown-container",
            fieldType: {
              type: "JSX-element",
              JSXElementData: (
                <div>
                  <div className={"multiple-input-container flexbox"}>
                    <SelectInput
                      placeholder={"--"}
                      width={60}
                      height={40}
                      value={formik.values.furiganaSort}
                      options={FuriganaAlphabetsOption}
                      name={"furiganaSort"}
                      onChange={(val) => {
                        formik.setFieldValue(`furiganaSort`, val)
                        formik.setFieldValue(`childId`, childOptions[0].value)
                      }}
                      disabled={pageType == "EDIT"}
                    />
                    <SelectInput
                      placeholder={t("---")}
                      height={40}
                      options={childOptions}
                      className={"child-select dynamic-dropdown-width"}
                      name={"childId"}
                      value={isChildListLoading ? null : formik.values.childId}
                      loading={isChildListLoading}
                      onBlur={formik.handleBlur}
                      dropdownMatchSelectWidth={false}
                      onChange={(val) => {
                        formik.setFieldValue(`childId`, val)
                      }}
                      disabled={pageType == "EDIT"}
                    />
                  </div>
                  {formik.touched.childId && formik.errors.childId && (
                    <ErrorMessage>{formik.errors.childId}</ErrorMessage>
                  )}
                </div>
              ),
            },
          },
        ],
      },

      {
        required: true,
        fieldLabel: "Program category",
        isRowFlexed: true,
        fieldClassName: "program-cat-dropdown-field",
        fields: [
          {
            name: "programCategoryId",
            width: "auto",
            className: "program-cat-dropdown-container",
            fieldType: {
              type: "dropdown",
              extraOptions: {
                selectInputClassName: "dynamic-dropdown-width min-w-300",
                option: mappedProgramCategoryOptions,
                isLoading: isProgramCategoryListLoading,
                dropdownMatchSelectWidth: false,
              },
            },
          },
        ],
      },

      {
        fieldLabel: "Overall goal",
        isRowFlexed: true,
        fields: [
          {
            width: "100%",
            name: "overallGoal",
            fieldType: {
              type: "textarea",
              extraOptions: {
                rows: 4,
                maxLength: 255,
              },
            },
          },
        ],
      },

      {
        fieldLabel: "Review column",
        isRowFlexed: true,
        fields: [
          {
            width: "100%",
            name: "review",
            fieldType: {
              type: "textarea",
              extraOptions: {
                rows: 4,
                maxLength: 255,
              },
            },
          },
        ],
      },

      {
        fieldLabel: "Goal achievement",
        isRowFlexed: true,
        fields: [
          {
            name: "goalAchievementRate",
            fieldType: {
              type: "JSX-element",
              JSXElementData: (
                <GoalAchievementRadioButtons
                  onChange={formik.handleChange}
                  value={formik.values.goalAchievementRate}
                />
              ),
            },
          },
        ],
      },
    ],

    [formik, childOptions, programCategoryOptions]
  )

  return {
    recordFormSchema,
  }
}

export default useForm
