import React, { useState } from "react"
import { Typography } from "antd"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { useTranslation } from "react-i18next"

interface IShowMoreTextProps {
  article: string
  showRows?: number
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ShowMoreText = ({ article, showRows = 2 }: IShowMoreTextProps) => {
  const [ellipsis, setEllipse] = useState(true)
  const { Paragraph } = Typography
  const { t } = useTranslation()
  return (
    <div>
      <Paragraph
        ellipsis={ellipsis ? { rows: showRows, expandable: false } : false}
      >
        {article}
      </Paragraph>
      {article.length > 100 && (
        <ButtonWrapper>
          <OwnerButton
            text={ellipsis ? t("View all") : t("Hide")}
            icon={ellipsis ? "down" : "up"}
            iconPosition={"right"}
            typeOf={"secondary"}
            onClick={() => setEllipse(!ellipsis)}
          />
        </ButtonWrapper>
      )}
    </div>
  )
}

export { ShowMoreText }
