import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StatsSection = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
  border: 1px solid #d2d1d1;
  border-radius: 10px;
  padding: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 12px;

  .stat-container {
    display: flex;
    align-items: center;
    margin: 8px 20px 8px 0;
    flex-wrap: wrap;
  }
  .stat-label {
    position: relative;
    padding: 8px 2px 8px 8px;
    background: #cde6f4;
    margin-right: 20px;
  }
  .stat-label::after {
    position: absolute;
    top: 0;
    right: -20px;
    content: "";
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid #cde6f4;
    height: 100%;
    width: 20px;
  }
  @media (max-width: 768px) {
    .stat-container {
      margin-right: 0;
    }
  }
`

export const LabelsComponent = ({ stats }) => {
  const { t } = useTranslation()
  return (
    <StatsSection className={"stats_sections"}>
      {Object.keys(stats).map((key) => (
        <div className={"stat-container"} key={key}>
          <span className={"stat-label"}>{t(key)}</span>
          <span>
            {stats[key]}
            {t("people")}
          </span>
        </div>
      ))}
    </StatsSection>
  )
}
