import { theme } from "@project/shared"
import styled from "styled-components"

export const DataContainer = styled.div`
  margin: 25px 0;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 11px 30px 27px 30px;

  table {
    thead {
      tr {
        th:last-child {
          min-width: 100px;
        }
        th:nth-last-child(2) {
          min-width: 100px;
        }
      }
    }
  }

  .label-container {
    width: 300px !important;
  }

  @media screen and (max-width: 426px) {
    .label-container {
      border-top: 1px solid #d2d2d2 !important;
      padding: 10px !important;
      width: 100% !important;
    }

    .gcPXPg.grid-row-last {
      padding-bottom: 20px;
    }
  }

  .max-capacity input {
    width: 80px;
  }
  .month-pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: white;
      color: black;
      border: 1px solid #d2d1d1;
      border-radius: 10px;
      min-height: 40px;
      height: min-content;
      margin-right: 10px;
      padding: 5px;
      min-width: 72px;
      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
  }
  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
  .business-service-hrs-btn {
    @media (max-width: 975px) {
      white-space: normal;
    }
  }
  .facility-select .ant-select-selector {
    @media (max-width: 400px) {
      max-width: 92%;
    }
  }
  .facility-select .ant-select-arrow {
    @media (max-width: 400px) {
      margin-right: 1.5rem;
    }
  }

  .disabled-edit {
    color: ${theme.gray3};
    text-decoration: underline;
    cursor: not-allowed;
  }

  .disabled-button-link {
    background: ${theme.gray2} !important;
    border-color: ${theme.gray2} !important;
    color: rgba(0, 0, 0, 0.5) !important;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    &.md_data-container {
      padding: 10px 20px 15px 20px;
    }
  }
  .min-width {
    min-width: 122px;
  }
  .width50-class {
    min-width: 100px;
  }
`
