import { theme, WORK_EXPERIENCE, WORK_TYPES } from "@project/shared"
import { dynamicLangString } from "@project/shared/src/utils/index"
import { notification, Table } from "antd"
import { Excel } from "antd-table-saveas-excel"
import moment from "moment"
import Link from "next/link"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import {
  resetAttendanceStaffResults,
  saveAttendanceStaffResults,
  updateAttendanceStaffResults,
} from "../../../services/attendanceStaffResult"
import {
  createStaffAttendanceShiftSchedule,
  updateOneStaffAttendanceShiftSchedule,
} from "../../../services/staffAttendanceShiftSchedule"
import { AuthContext, getDateTimeStringFromDate } from "../../../utils"
import { AttendanceSchedulePermission } from "../../../utils/PermissionKeys"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { QuickAttendanceRecordUpdateForm } from "../QuickAttendanceRecordUpdateForm"
import { QuickAttendanceScheduleUpdateForm } from "../QuickAttendanceScheduleUpdateForm"

const Wrapper = styled.div`
  margin-bottom: 25px;
  .calendar-table {
    max-width: 100%;
    overflow: auto;
  }
  table {
    border-collapse: collapse;
  }
  table thead {
    border: 1px solid #444748;
    /* not a good approach but it works
     TODO: find a better way to do this
     setting width to directly column didn't work so I had to set it in the styling */
    tr th.full-time-conversion {
      min-width: 100px;
    }
  }
  table th {
    background: #ffffff;
    border-top: 1px solid #444748 !important;
    border-right: 1px solid #444748 !important;
    border-bottom: 0px !important;
    min-width: 75px;
    text-align: center;
    white-space: break-spaces;
  }
  table td {
    border-right: 1px solid #444748 !important;
    text-align: center;
    @media print {
      border-top: 1px solid #444748 !important;
      border-bottom: 1px solid #444748 !important;
    }
    &:first-child {
      border-left: 1px solid #444748 !important;
    }
  }
  table th:first-child {
    border-top-left-radius: 0px !important;
  }
  table th:last-child {
    border-top-right-radius: 0px !important;
  }
  table tr:last-child td {
    border-bottom: 1px solid #444748 !important;
  }
  th.years-experience {
    @media not print {
      min-width: 100px !important;
    }
  }
  table tr:first-child th {
    padding-top: 40px;
    padding-bottom: 40px;
    min-width: 80px;
  }
  table tr:not(:first-child) th {
    padding: 8px 4px;
  }
  table tr:first-child th:nth-child(-n + 3) {
    min-width: 150px;
  }
  table tr:first-child th:nth-last-child(2) {
    min-width: 150px;
  }
  thead tr:first-child th:last-child,
  table tr td:last-child {
    border-right: 1px solid #444748 !important;
  }
  .ant-table-tbody tr:not(:last-child) td {
    border-bottom: 1px solid #444748;
  }
  .styled-name {
    display: block;
    padding: 4px 8px;
    color: #ffffff;
    border-radius: 5px;
  }
  .styled-name:not(:last-child) {
    margin-bottom: 5px;
  }
  .styled-link {
    color: #0782c8;
    text-decoration: underline;
    cursor: pointer;
    @media print {
      color: ${theme.black};
      text-decoration: none;
      cursor: auto !important;
    }
  }
  .disabled-link {
    color: ${theme.black};
    text-decoration: underline;
    cursor: auto;
    @media print {
      color: ${theme.black};
      text-decoration: none;
      cursor: auto !important;
    }
  }
  .table-info {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }
  .table-info > span:not(:last-child) {
    margin-right: 10px;
  }
  .has-diagonal {
    position: relative;
  }
  .diagonal-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.8px;
    background-image: linear-gradient(
      to top left,
      transparent,
      transparent 49%,
      black 49%,
      black 51%,
      transparent 51%,
      transparent
    );
    @media print {
      top: 1px;
      height: 90%;
    }
  }
  .ant-table-cell {
    position: relative;
    .default-cursor {
      cursor: auto !important;
    }
    .day-info-container {
      min-height: 25px;
      min-width: 40px;
      cursor: pointer;
    }
    .quick-update-container {
      position: absolute;
      cursor: auto;
      z-index: 99;
      top: 12px;
      left: 50%;
      display: none;
      width: 670px;
      transform: translate(-50%, -100%);
      &:after {
        content: "";
        border-top: 10px solid #d2d1d1;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
      .close-modal {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
    .quick-update-container.show-modal {
      display: block;
    }
  }
  @media print {
    @page {
      size: landscape;
    }
  }
`

interface IAttendanceScheduleCalendarTable {
  year: number
  month: number
  facility: any
  calendarData: any
  detailLink: string
  calendarType: "Record" | "Schedule"
  staffLink: string
  downloadable?: boolean
  facilitySchedules?: Array<any>
  refetch?: any
  additionalExcelData?: any
  editable?: boolean
  aprilChangeApplicable?: boolean
}

export const AttendanceScheduleCalendarTable = ({
  year,
  month,
  facility,
  calendarData,
  detailLink,
  calendarType,
  staffLink,
  downloadable = false,
  facilitySchedules = [],
  refetch = null,
  additionalExcelData = {},
  editable = true,
  aprilChangeApplicable = false,
}: IAttendanceScheduleCalendarTable) => {
  const { t } = useTranslation()
  const { isOwner, permissions } = useContext(AuthContext)

  const firstDay = new Date(year, month - 1, 1)

  const getFirstDayOfWeek = () => {
    const fDay = firstDay.getDay()
    // because starting from monday
    if (fDay == 0) return 6
    return fDay - 1
  }

  const getDateInFormat = (date) => {
    const d = new Date(date),
      year = d.getFullYear()

    const month = "" + (d.getMonth() + 1),
      day = "" + d.getDate()

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-")
  }

  const prepareDate = (year, month, day) => {
    return (
      year +
      "-" +
      (month > 9 ? month : "0" + month) +
      "-" +
      (day > 9 ? day : "0" + day)
    )
  }

  const howManyDaysOnFirstWeek = () => {
    return 7 - getFirstDayOfWeek()
  }

  const getScheduleWorkHours = ({
    attendance_start_time,
    attendance_end_time,
    attendance_rest_minits,
    attendance_start_time_2,
    attendance_end_time_2,
    attendance_rest_minits_2,
  }) => {
    let startInMinute = 0,
      endInMinute = 0,
      startInMinute2 = 0,
      endInMinute2 = 0
    const restInMinute = attendance_rest_minits ? +attendance_rest_minits : 0
    const restInMinute2 = attendance_rest_minits_2
      ? +attendance_rest_minits_2
      : 0

    if (attendance_start_time) {
      startInMinute = attendance_start_time
        .split(":")
        .reduce((accumulator, currentValue, currentIndex) => {
          const multiplier = currentIndex == 0 ? 60 : 1
          const min = +currentValue * multiplier
          return accumulator + min
        }, 0)
    }

    if (attendance_end_time) {
      endInMinute = attendance_end_time
        .split(":")
        .reduce((accumulator, currentValue, currentIndex) => {
          const min = currentIndex == 0 ? +currentValue * 60 : +currentValue
          return accumulator + min
        }, 0)
    }

    if (attendance_start_time_2) {
      startInMinute2 = attendance_start_time_2
        .split(":")
        .reduce((accumulator, currentValue, currentIndex) => {
          const min = currentIndex == 0 ? +currentValue * 60 : +currentValue
          return accumulator + min
        }, 0)
    }

    if (attendance_end_time_2) {
      endInMinute2 = attendance_end_time_2
        .split(":")
        .reduce((accumulator, currentValue, currentIndex) => {
          const min = currentIndex == 0 ? +currentValue * 60 : +currentValue
          return accumulator + min
        }, 0)
    }

    const workHours =
      (endInMinute -
        startInMinute -
        restInMinute +
        (endInMinute2 - startInMinute2 - restInMinute2)) /
      60
    if (workHours >= 0) return Number(workHours.toPrecision(2))
    return null
  }

  const groupType1Colspan = () => (aprilChangeApplicable ? 4 : 3)

  const groupType2Colspan = () => (aprilChangeApplicable ? 4 : 3)

  const getEmployeeWorkYears = (joiningDate) => {
    if (!joiningDate) return null
    const today = new Date()
    const joinedDate = new Date(joiningDate)
    const diff: any =
      (today.getTime() - joinedDate.getTime()) / (24 * 3600 * 1000 * 365)
    return diff < 1 ? "" : Math.trunc(diff)
  }

  const dataSource = []
  const lastDay = new Date(year, month, 0)
  const totalDays = lastDay.getDate()
  const maxWeeks = Math.ceil((totalDays - howManyDaysOnFirstWeek()) / 7 + 1)
  const numberOfWeeks = Array.from({ length: maxWeeks }, (_, i) => i + 1)
  let currentDayOfWeek = getFirstDayOfWeek()
  let daysAccounted = 0

  const getDay = (day: number) => {
    switch (day) {
      case 0:
        return t("Mon")
      case 1:
        return t("Tue")
      case 2:
        return t("Wed")
      case 3:
        return t("Thu")
      case 4:
        return t("Fri")
      case 5:
        return t("Sat")
      case 6:
        return t("Sun")
      default:
        return ""
    }
  }
  const hasEditPermission = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AttendanceSchedulePermission,
        "write",
        facility
      ),
    [isOwner, permissions, facility]
  )
  let columns: any = [
    {
      title: t("Occupation"),
      dataIndex: "occupations",
      key: "occupation",
      onCell: (obj) => {
        if (!obj.groupType1) return {}
        if (obj.groupType1 == "1") return { colSpan: groupType1Colspan() }
        else if (obj.groupType1 == "2")
          return {
            colSpan: groupType2Colspan(),
          }
      },
      render: (text, obj) =>
        Array.isArray(obj.occupations) ? (
          obj.occupations.map((occ) => (
            <span
              className={"styled-name"}
              style={{ background: occ?.color, margin: "1px" }}
              key={occ.label}
            >
              {occ.label}
            </span>
          ))
        ) : (
          <>{text}</>
        ),
    },
    {
      title: t("Work style"),
      dataIndex: "workStyle",
      key: "workStyle",
      onCell: (obj) => {
        if (!obj.groupType1) return {}
        return { colSpan: 0 }
      },
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "fullName",
      onCell: (obj) => {
        if (!obj.groupType1) return {}
        return { colSpan: 0 }
      },
      render: (text, obj) =>
        editable ? (
          <span className={!hasEditPermission ? "" : "styled-link"}>
            {!hasEditPermission ? (
              <> {obj?.name?.fullName}</>
            ) : (
              <a
                href={`${staffLink}?staff_id=${obj?.name?.id}&facility_id=${facility}&year=${year}&month=${month}`}
              >
                {obj?.name?.fullName}
              </a>
            )}
          </span>
        ) : (
          <span className={"disabled-link"}> {obj?.name?.fullName} </span>
        ),
    },
  ]

  const aprilChangeColumn = [
    {
      title: t("Years of experience"),
      dataIndex: "yearsExperience",
      key: "yearsExperience",
      className: "years-experience",
      onCell: (obj) => {
        if (!obj.groupType1) return {}
        return { colSpan: 0 }
      },
    },
  ]

  const columnsAfterWeek = [
    {
      title: t("Month total"),
      className: "has-diagonal",
      dataIndex: "monthTotal",
      key: "monthlyTotal",
      onCell: (obj) => {
        if (!obj.groupType3) return {}
        return { colSpan: 3 }
      },
    },
    {
      title: t("Average working hours per week"),
      className: "has-diagonal",
      dataIndex: "averageWorkingHoursPerWeek",
      key: "averageWorkingHoursPerWeek",
      onCell: (obj) => {
        if (!obj.groupType3) return {}
        return { colSpan: 0 }
      },
      render: (text, obj) => {
        if (obj.noAverageWorkingHourPerWeek)
          return <div className={"diagonal-div"}></div>
        return text
      },
    },
    {
      title: t(`Number of people after full-time conversion`),
      dataIndex: "numberOfPeopleAfterFullTimeConversion",
      key: "noOfPeopleAfterFullTimeConversion",
      className: "full-time-conversion",
      onCell: (obj) => {
        if (!obj.groupType3) return {}
        return { colSpan: 0 }
      },
      render: (text, obj) => {
        if (obj.noPeopleAfterFullConversion)
          return <div className={"diagonal-div"}></div>
        return text
      },
      width: "101px",
    },
    {
      title: t("Presence or absence of qualifications and kinds"),
      dataIndex: "qualifications",
      key: "qualificationAndType",
      className: "qualification-and-type",
      onCell: (obj) => {
        if (!obj.groupType3) return {}
        return { colSpan: 2 }
      },
      render: (text, obj) =>
        obj?.qualifications?.map((qual) => <span key={qual}>{qual}</span>),
    },
    {
      title: t("Continuation work number of years"),
      dataIndex: "workNumberOfYears",
      key: "continuationWorkYears",
      onCell: (obj) => {
        if (!obj.groupType3) return {}
        return { colSpan: 0 }
      },
    },
  ]

  const columnsAfterWeekForExcel = [
    {
      title: t("Month total"),
      className: "has-diagonal",
      dataIndex: "monthTotal",
      key: "monthlyTotal",
      width: 40,
      render: (text, row) => {
        if (!row.groupType3)
          return {
            children: text,
          }
        return { children: text, props: { colSpan: 3 } }
      },
    },
    {
      title: t("Average working hours per week"),
      className: "has-diagonal",
      dataIndex: "averageWorkingHoursPerWeek",
      key: "averageWorkingHoursPerWeek",
      width: 40,
      render: (text, row) => {
        if (!row.groupType3) return { children: text ?? " " }
        return { children: text ?? " ", props: { colSpan: 0 } }
      },
    },
    {
      title: t("Number of people after full-time conversion"),
      dataIndex: "numberOfPeopleAfterFullTimeConversion",
      key: "noOfPeopleAfterFullTimeConversion",
      width: 50,
      render: (text, row) => {
        if (!row.groupType3) return { children: text ?? " " }
        return { children: text ?? " ", props: { colSpan: 0 } }
      },
    },
    {
      title: t("Presence or absence of qualifications and kinds"),
      dataIndex: "qualifications",
      key: "qualificationAndType",
      width: 50,
      render: (text, row) => {
        const props: any = {}
        if (row.groupType3) props.colSpan = 2
        if (Array.isArray(row.qualifications))
          return { children: row.qualifications.join(","), props }
        return {
          children: text,
          props,
        }
      },
    },
    {
      title: t("Continuation work number of years"),
      dataIndex: "workNumberOfYears",
      key: "continuationWorkYears",
      width: 40,
      render: (text, row) => {
        if (!row.groupType3) return { children: text ?? " " }
        return { children: text ?? " ", props: { colSpan: 0 } }
      },
    },
  ]
  const excelDayColWidth = 20
  let firstWeekHeader = {}
  let secondWeekHeader = {}
  const otherWeekHeaders = []
  let firstDayOfFirstWeek = ""
  const generateShiftHours = () => {
    const obj: any = {}
    facilitySchedules?.forEach((schedule) => {
      obj[
        schedule?.attendance_shift_display_name
      ] = `${schedule?.attendance_start_time}〜${schedule?.attendance_end_time}`
    })
    return obj
  }
  const generalShiftHours = generateShiftHours()
  const makeHeaderColumns = () => {
    if (aprilChangeApplicable) {
      columns = columns.concat(aprilChangeColumn)
    }

    let dayOfMonth = 1
    numberOfWeeks.map((week) => {
      const daysThisWeek = Array.from(
        {
          length:
            currentDayOfWeek != 0
              ? 7 - currentDayOfWeek
              : week == maxWeeks
              ? totalDays - daysAccounted
              : 7,
        },
        (_, i) => i + currentDayOfWeek
      )
      daysAccounted += daysThisWeek.length
      currentDayOfWeek = 0
      const newHeader: any = {
        title: t("whichWeek", { week }),
        children: [],
        width: excelDayColWidth,
      }

      if (week == 1) {
        firstDayOfFirstWeek = prepareDate(year, month, dayOfMonth)
      }

      daysThisWeek.map((day, index) => {
        const dateQuery = `date=${year}-${month
          .toString()
          .padStart(2, "0")}-${dayOfMonth.toString().padStart(2, "0")}`
        const detailLinkWithQuery = !editable
          ? "#"
          : calendarType == "Record"
          ? `${detailLink}?facilityIds=${facility}&${dateQuery}`
          : `${detailLink}?facility=${facility}&${dateQuery}`
        const child = {
          title: downloadable ? (
            "" + dayOfMonth
          ) : editable ? (
            <span className={"styled-link"}>
              <Link href={detailLinkWithQuery}>
                <a>{dayOfMonth}</a>
              </Link>
            </span>
          ) : (
            <span className={"disabled-link"}>{dayOfMonth}</span>
          ),
          width: excelDayColWidth,
          children: [
            {
              title: getDay(day),
              dataIndex: prepareDate(year, month, dayOfMonth),
              width: excelDayColWidth,
              onCell: (obj) => {
                if (!obj.groupType1 || obj.groupType1 != "2") return {}
                if (week == 1 && index == 0) {
                  // colspan of weeks except 1st
                  return {
                    colSpan: totalDays,
                  }
                }
                return { colSpan: 0 }
              },
              render: (text, obj) => {
                if (downloadable) {
                  if (obj.groupType1 == "2") {
                    if (week == 1 && index == 0) {
                      // colspan of weeks except 1st
                      return {
                        children: text,
                        props: {
                          colSpan: totalDays,
                        },
                      }
                    }
                    return { children: "", props: { colSpan: 0 } }
                  }
                  return text
                }
                // check if for date before returning
                if (typeof text == "object" && text?.editable)
                  return {
                    children: (
                      <div
                        className={`day-info-container ${
                          !editable ? "default-cursor" : ""
                        }`}
                        onClick={(e) => {
                          if (!editable) return
                          if (e.currentTarget != e.target) return
                          document
                            .querySelectorAll(".show-modal")
                            .forEach((elem) =>
                              elem.classList.remove("show-modal")
                            )
                          const child = e.currentTarget.querySelector(
                            ".quick-update-container"
                          )
                          if (child) {
                            child.classList.add("show-modal")
                          }
                        }}
                      >
                        {editable && (
                          <div className={"quick-update-container"}>
                            <div
                              className={"close-modal"}
                              onClick={(e) => {
                                e.currentTarget.parentElement.classList.remove(
                                  "show-modal"
                                )
                              }}
                            >
                              <img src={"/assets/icons/CloseIcon.svg"} />
                            </div>
                            {calendarType == "Schedule" ? (
                              <QuickAttendanceScheduleUpdateForm
                                scheduleOptions={facilitySchedules}
                                scheduleDetail={text}
                                onSave={handleScheduleSave}
                              />
                            ) : (
                              <QuickAttendanceRecordUpdateForm
                                recordDetail={text}
                                onSave={handleRecordSave}
                              />
                            )}
                          </div>
                        )}
                        {text.hours}
                      </div>
                    ),
                  }
                return text
              },
            },
          ],
        }
        newHeader.children.push(child)
        dayOfMonth++
      })
      columns.push(newHeader)
      if (downloadable) {
        if (week == 1) firstWeekHeader = newHeader
        else if (week == 2) secondWeekHeader = newHeader
        else otherWeekHeaders.push(newHeader)
      }
    })
    columns = columns.concat(columnsAfterWeek)
  }
  makeHeaderColumns()

  const makeScheduleTableData = () => {
    // let allMonthTotal = 0
    const workHoursForWorkPerformace = {
      monthTotal: 0,
    }

    const serviceHours = {}
    const userCountByDay = {}
    calendarData?.staff_schedules?.map((scheduleData) => {
      let hoursScheduled = 0
      const thisSchedule: any = {
        occupations: [],
        workStyle: "",
        name: {
          id: "",
          fullName: "",
        },
        yearsExperience: "",
        monthTotal: "",
        averageWorkingHoursPerWeek: "",
        numberOfPeopleAfterFullTimeConversion: "",
        qualifications: [],
        workNumberOfYears: "",
      }

      // determining occupation
      if (scheduleData?.occupation?.occupation_name)
        thisSchedule.occupations.push({
          label:
            scheduleData?.occupation?.occupation_tag ||
            scheduleData?.occupation?.occupation_name,
          color: scheduleData?.occupation?.color_code,
        })

      if (scheduleData?.occupation_2?.occupation_name)
        thisSchedule.occupations.push({
          label:
            scheduleData?.occupation_2?.occupation_tag ||
            scheduleData?.occupation_2?.occupation_name,
          color: scheduleData?.occupation_2?.color_code,
        })

      // determining work style
      thisSchedule.workStyle =
        WORK_TYPES.find((work) => work.value == scheduleData.workstyle_type)
          ?.label ?? ""

      thisSchedule.yearsExperience =
        WORK_EXPERIENCE.find((exp) => exp.value == scheduleData.experience_year)
          ?.label || ""

      // determining name
      thisSchedule.name = {
        id: scheduleData?.staff?.id,
        fullName: scheduleData?.staff?.staff_name,
      }

      // determining scheduled work hours for days
      for (let dayOfMonth = 1; dayOfMonth <= totalDays; dayOfMonth++) {
        const preparedDate = prepareDate(year, month, dayOfMonth)
        // check if holiday
        if (
          Array.isArray(calendarData?.holidays) &&
          calendarData.holidays.includes(preparedDate)
        ) {
          thisSchedule[preparedDate] = t("休")
          serviceHours[preparedDate] = 0
          userCountByDay[preparedDate] = 0
          if (!workHoursForWorkPerformace[preparedDate])
            workHoursForWorkPerformace[preparedDate] = 0
          continue
        }

        let scheduleForDate = scheduleData?.staff_attendance_schedules.find(
          (dateSchedule) => getDateInFormat(dateSchedule.date) == preparedDate
        )
        let daySchedule = true
        // get Schedule table from weekly pattern if not set for specific date
        if (scheduleForDate && !scheduleForDate?.attendance_shift?.id) {
          // schedule for date but shift is deleted
          scheduleForDate = {}
        } else if (!scheduleForDate) {
          const whichDayIsThisDay = new Date(
            year,
            month - 1,
            dayOfMonth
          ).getDay()
          switch (whichDayIsThisDay) {
            case 0:
              scheduleForDate = scheduleData?.sun_schedule
              break
            case 1:
              scheduleForDate = scheduleData?.mon_schedule
              break
            case 2:
              scheduleForDate = scheduleData?.tue_schedule
              break
            case 3:
              scheduleForDate = scheduleData?.wed_schedule
              break
            case 4:
              scheduleForDate = scheduleData?.thu_schedule
              break
            case 5:
              scheduleForDate = scheduleData?.fri_schedule
              break
            default:
              scheduleForDate = scheduleData?.sat_schedule
              break
          }
          daySchedule = false
        }

        // if deleted Schedule table then unset
        if (scheduleForDate?.deleted_datetime) scheduleForDate = {}

        const scheduleInfoForUpdate = {
          ...scheduleForDate,
          editable: true,
          daySchedule,
          facilityId: scheduleData?.facility_id,
          staffId: scheduleData?.staff_id,
          date: getDateTimeStringFromDate(new Date(preparedDate)),
        }

        // check if leave day Schedule table or retired by now
        const isShiftScheduledAsLeave =
          scheduleForDate?.number_of_employees_include_flg == 1 ||
          scheduleForDate?.attendance_shift?.number_of_employees_include_flg ==
            1
        if (
          isShiftScheduledAsLeave ||
          (scheduleData?.staff?.retirement_date &&
            moment(scheduleData.staff.retirement_date).isSameOrBefore(
              moment(preparedDate)
            ))
        ) {
          let label = t("休")
          const hours =
            (scheduleForDate?.use_rest_time_flg &&
              scheduleForDate?.rest_time_minutes) ||
            (scheduleForDate?.attendance_shift?.use_rest_time_flg &&
              scheduleForDate?.attendance_shift?.rest_time_minutes) ||
            0
          if (isShiftScheduledAsLeave) {
            label =
              hours ||
              scheduleForDate?.attendance_shift_display_name ||
              scheduleForDate?.attendance_shift?.attendance_shift_display_name
          }
          if (downloadable) thisSchedule[preparedDate] = label
          else
            thisSchedule[preparedDate] = {
              ...scheduleInfoForUpdate,
              hours: label,
            }
          if (!workHoursForWorkPerformace[preparedDate])
            workHoursForWorkPerformace[preparedDate] = hours
          continue
        }

        const workHoursThisDay = getScheduleWorkHours(
          !daySchedule
            ? scheduleForDate
            : {
                attendance_start_time: scheduleForDate.attendance_start_time,
                attendance_end_time: scheduleForDate.attendance_end_time,
                attendance_rest_minits: scheduleForDate.attendance_rest_minits,
                attendance_start_time_2: scheduleForDate.attendance_start_time2,
                attendance_end_time_2: scheduleForDate.attendance_end_time2,
                attendance_rest_minits_2:
                  scheduleForDate.attendance_rest_minits2,
              }
        )
        if (!isNaN(workHoursThisDay)) hoursScheduled += workHoursThisDay

        //thisSchedule[preparedDate] = workHoursThisDay ?? ""
        if (downloadable) thisSchedule[preparedDate] = workHoursThisDay
        else
          thisSchedule[preparedDate] = {
            ...scheduleInfoForUpdate,
            hours: workHoursThisDay ?? "",
          }

        // adding for work performance
        if (workHoursForWorkPerformace[preparedDate])
          workHoursForWorkPerformace[preparedDate] += workHoursThisDay
        else workHoursForWorkPerformace[preparedDate] = workHoursThisDay

        workHoursForWorkPerformace.monthTotal += workHoursThisDay

        // service hours
        serviceHours[preparedDate] =
          calendarData?.service_hours?.[preparedDate] || 0

        // keeping info of user count
        if (!userCountByDay[preparedDate]) {
          const uCount = calendarData?.facility_users[preparedDate]
          if (uCount) userCountByDay[preparedDate] = uCount
        }
      }

      // month total
      thisSchedule.monthTotal = hoursScheduled.toFixed(2)

      // average working hours (4 weeks considered)
      thisSchedule.averageWorkingHoursPerWeek = Math.trunc(hoursScheduled / 4)

      // number of people after full time conversion
      thisSchedule.numberOfPeopleAfterFullTimeConversion =
        hoursScheduled > 0 ? hoursScheduled / (4 * 40) : 0

      // qualifications
      scheduleData?.staff?.staff_qualification?.map((q) => {
        if (q?.qualification?.qualification_name)
          thisSchedule.qualifications.push(q?.qualification?.qualification_name)
      })

      // continuation years
      thisSchedule.workNumberOfYears = getEmployeeWorkYears(
        scheduleData?.staff?.joining_date
      )

      // adding to dataSource
      dataSource.push(thisSchedule)

      // summing all employee total month Schedule table
      // allMonthTotal += +thisSchedule.monthTotal
    })

    // row for Total work performance (hours)
    const rowForWorkPerformance = {
      ...workHoursForWorkPerformace,
      key: "workPerformanceHours",
      occupations: t("Total work performance (hours)"),
      monthTotal: workHoursForWorkPerformace.monthTotal.toFixed(2),
      averageWorkingHoursPerWeek: Math.trunc(
        workHoursForWorkPerformace.monthTotal / 4
      ),
      numberOfPeopleAfterFullTimeConversion:
        workHoursForWorkPerformace.monthTotal / (4 * 40),
      groupType1: "1",
    }

    dataSource.push(rowForWorkPerformance)

    // row for full time working hours info
    const rowForWorkingHours = {
      key: "fulltimeEmployeeWorkingHours",
      occupations: t(
        "Number of hours a full-time employee should work in the office/facility in a week"
      ),
      groupType1: "2",
      groupType3: "1",
    }
    rowForWorkingHours[firstDayOfFirstWeek] = 40 + t("Hrs")

    dataSource.push(rowForWorkingHours)

    // row for service hours
    const rowForServiceHours = {
      ...serviceHours,
      key: "shiftInfo",
      occupations: t("Service hours"),
      groupType1: "1",
      noAverageWorkingHourPerWeek: true,
      noPeopleAfterFullConversion: true,
    }

    dataSource.push(rowForServiceHours)

    // row for user count
    const rowForFacilityUsers = {
      ...userCountByDay,
      key: "facilityUsersCount",
      occupations: t("Number of users"),
      groupType1: "1",
      noAverageWorkingHourPerWeek: true,
      noPeopleAfterFullConversion: true,
    }

    dataSource.push(rowForFacilityUsers)
  }

  const makeRecordTableData = () => {
    // let allMonthTotal = 0
    const workHoursForWorkPerformace = {
      monthTotal: 0,
    }
    const serviceHours = {}
    const userCountByDay = {}
    calendarData?.staff_facility?.map((recordData) => {
      let hoursRecorded = 0
      const thisRecord: any = {
        occupations: [],
        workStyle: "",
        name: {
          id: "",
          fullName: "",
        },
        yearsExperience: "",
        monthTotal: "",
        averageWorkingHoursPerWeek: "",
        numberOfPeopleAfterFullTimeConversion: "",
        qualifications: [],
        workNumberOfYears: "",
      }

      // determining occupation
      if (recordData?.occupation?.occupation_name)
        thisRecord.occupations.push({
          label:
            recordData?.occupation?.occupation_tag ||
            recordData?.occupation?.occupation_name,
          color: recordData?.occupation?.color_code,
        })

      if (recordData?.occupation_2?.occupation_name)
        thisRecord.occupations.push({
          label:
            recordData?.occupation_2?.occupation_tag ||
            recordData?.occupation_2?.occupation_name,
          color: recordData?.occupation_2?.color_code,
        })

      // determining work style
      thisRecord.workStyle =
        WORK_TYPES.find((work) => work.value == recordData.workstyle_type)
          ?.label ?? ""

      // experience year
      thisRecord.yearsExperience =
        WORK_EXPERIENCE.find((exp) => exp.value == recordData.experience_year)
          ?.label || ""

      // determining name
      thisRecord.name = {
        id: recordData?.staff?.id,
        fullName: recordData?.staff?.staff_name,
      }

      // determining recorded work hours for days
      for (let dayOfMonth = 1; dayOfMonth <= totalDays; dayOfMonth++) {
        let isScheduled = true

        const preparedDate = prepareDate(year, month, dayOfMonth)
        // check if holiday
        if (
          Array.isArray(calendarData?.holidays) &&
          calendarData.holidays.includes(preparedDate)
        ) {
          thisRecord[preparedDate] = t("休")
          serviceHours[preparedDate] = 0
          userCountByDay[preparedDate] = 0
          if (!workHoursForWorkPerformace[preparedDate])
            workHoursForWorkPerformace[preparedDate] = 0
          continue
        }

        let scheduleForDay = recordData?.staff_shift_schedule?.find(
          (scheduleRecord) =>
            getDateInFormat(scheduleRecord.date) == preparedDate
        )

        if (
          scheduleForDay &&
          (scheduleForDay?.deleted_datetime ||
            !scheduleForDay?.attendance_shift?.id)
        ) {
          scheduleForDay.id = 0
        }

        if (!scheduleForDay) {
          // check schedule from weekly schedule
          const whichDayIsThisDay = new Date(
            year,
            month - 1,
            dayOfMonth
          ).getDay()
          switch (whichDayIsThisDay) {
            case 0:
              scheduleForDay = recordData?.sun_schedule
              break
            case 1:
              scheduleForDay = recordData?.mon_schedule
              break
            case 2:
              scheduleForDay = recordData?.tue_schedule
              break
            case 3:
              scheduleForDay = recordData?.wed_schedule
              break
            case 4:
              scheduleForDay = recordData?.thu_schedule
              break
            case 5:
              scheduleForDay = recordData?.fri_schedule
              break
            default:
              scheduleForDay = recordData?.sat_schedule
              break
          }
        }

        const recordForDate = recordData?.staff_attendance_results.find(
          (dateRecord) => getDateInFormat(dateRecord.date) == preparedDate
        )

        if (
          !recordForDate &&
          (!scheduleForDay ||
            scheduleForDay?.deleted_datetime ||
            !scheduleForDay?.id)
        ) {
          // either no schedule object, deleted or no id
          isScheduled = false
        }

        // get Schedule table from weekly pattern if not set for specific date
        if (!recordForDate) {
          let hours = ""
          let label = ""
          // check if leave day Schedule table
          if (
            scheduleForDay?.number_of_employees_include_flg == 1 ||
            scheduleForDay?.attendance_shift?.number_of_employees_include_flg ==
              1
          ) {
            hours =
              (scheduleForDay?.use_rest_time_flg &&
                scheduleForDay?.rest_time_minutes) ||
              (scheduleForDay?.attendance_shift?.use_rest_time_flg &&
                scheduleForDay?.attendance_shift?.rest_time_minutes) ||
              0
            label = hours || t("休")
            thisRecord[preparedDate] = label
            if (!workHoursForWorkPerformace[preparedDate])
              workHoursForWorkPerformace[preparedDate] = hours
          }
          thisRecord[preparedDate] = {
            editable: true,
            hours: label,
            facility_id: recordData?.facility_id,
            staff_id: recordData?.staff_id,
            date: preparedDate,
          }
        } else if (isScheduled) {
          // data for quick update
          const recordDetail = {
            ...recordForDate,
            editable: isScheduled,
            date: preparedDate,
          }

          // present calculation only for Schedule table
          const workHoursThisDay =
            (recordForDate?.rest_minits_flg && recordForDate?.rest_hours) ||
            getScheduleWorkHours({
              attendance_start_time: recordForDate?.attendance_start_time,
              attendance_end_time: recordForDate?.attendance_end_time,
              attendance_rest_minits: recordForDate?.attendance_rest_minits,
              attendance_start_time_2: recordForDate?.attendance_start_time2,
              attendance_end_time_2: recordForDate?.attendance_end_time2,
              attendance_rest_minits_2: recordForDate?.attendance_rest_minits2,
            })
          if (!isNaN(workHoursThisDay)) hoursRecorded += workHoursThisDay
          if (downloadable) thisRecord[preparedDate] = workHoursThisDay
          else
            thisRecord[preparedDate] = {
              ...recordDetail,
              hours: workHoursThisDay ?? "",
            }

          // adding for work performance
          if (workHoursForWorkPerformace[preparedDate])
            workHoursForWorkPerformace[preparedDate] += workHoursThisDay
          else workHoursForWorkPerformace[preparedDate] = workHoursThisDay

          workHoursForWorkPerformace.monthTotal += workHoursThisDay
        }

        // service hourse
        serviceHours[preparedDate] =
          calendarData?.service_hours?.[preparedDate] || 0

        // keeping info of user count
        if (!userCountByDay[preparedDate]) {
          const uCount = calendarData?.facility_users[preparedDate] || 0
          userCountByDay[preparedDate] = uCount
        }
      }

      // month total
      thisRecord.monthTotal = hoursRecorded.toFixed(2)

      // average working hours (4 weeks considered)
      thisRecord.averageWorkingHoursPerWeek = Math.trunc(hoursRecorded / 4)

      // number of people after full time conversion
      thisRecord.numberOfPeopleAfterFullTimeConversion =
        hoursRecorded / (4 * 40)

      // qualifications
      recordData?.staff?.staff_qualification?.map((q) => {
        if (q?.qualification?.qualification_name)
          thisRecord.qualifications.push(q?.qualification?.qualification_name)
      })

      // continuation years
      thisRecord.workNumberOfYears = getEmployeeWorkYears(
        recordData?.staff?.joining_date
      )

      // adding to dataSource
      dataSource.push(thisRecord)

      // summing all employee total month Schedule table
      // allMonthTotal += +thisRecord.monthTotal
    })

    // row for Total work performance (hours)
    const rowForWorkPerformance = {
      ...workHoursForWorkPerformace,
      key: "workPerformanceHours",
      occupations: t("Total work performance (hours)"),
      monthTotal: workHoursForWorkPerformace.monthTotal.toFixed(2),
      averageWorkingHoursPerWeek: Math.trunc(
        workHoursForWorkPerformace.monthTotal / 4
      ),
      numberOfPeopleAfterFullTimeConversion:
        workHoursForWorkPerformace.monthTotal / (4 * 40),
      groupType1: "1",
    }

    dataSource.push(rowForWorkPerformance)

    // row for full time working hours info
    const rowForWorkingHours = {
      key: "fulltimeEmployeeWorkingHours",
      occupations: t(
        "Number of hours a full-time employee should work in the office/facility in a week"
      ),
      groupType1: "2",
      groupType3: "1",
    }
    rowForWorkingHours[firstDayOfFirstWeek] = 40 + t("Hrs")

    dataSource.push(rowForWorkingHours)

    // row for shift info
    const rowForServiceHours = {
      ...serviceHours,
      key: "shiftInfo",
      occupations: t("Service hours"),
      groupType1: "1",
      noAverageWorkingHourPerWeek: true,
      noPeopleAfterFullConversion: true,
    }

    dataSource.push(rowForServiceHours)

    // row for user count
    const rowForFacilityUsers = {
      ...userCountByDay,
      key: "facilityUsersCount",
      occupations: t("Number of users"),
      groupType1: "1",
      noAverageWorkingHourPerWeek: true,
      noPeopleAfterFullConversion: true,
    }

    dataSource.push(rowForFacilityUsers)
  }

  if (calendarType == "Schedule") makeScheduleTableData()
  else if (calendarType == "Record") makeRecordTableData()

  const excelTopColData: Array<any> = [
    {
      top_title2:
        "従業者の勤務の体制及び勤務形態一覧表(予定表)[実地指導　事前提出資料]",
      top_title: t("ymCalendarType", {
        year: year,
        month: month?.toString()?.padStart(2, "0"),
        calendarType:
          calendarType == "Record" ? t("Record table") : t("Schedule table"),
      }),
    },
  ]

  const excelColumnsTop: any = !downloadable
    ? []
    : [
        {
          title: "",
          dataIndex: "top_title",
          width: 80,
          // width for occupation
          render: (text) => {
            const props: any = {}
            props.colSpan = 4
            return {
              children: text,
              props,
            }
          },
        },
        {
          title: "",
          dataIndex: "",
          width: 60,
          // width for work style
          render: (text) => {
            const props: any = {}
            props.colSpan = 0
            return {
              children: text,
              props,
            }
          },
        },
        {
          title: "",
          dataIndex: "",
          width: 80,
          // width for name
          render: (text) => {
            const props: any = {}
            props.colSpan = 0
            return {
              children: text,
              props,
            }
          },
        },
        {
          title: "",
          dataIndex: "",
          width: 60,
          // width for years of experience
          render: (text) => {
            const props: any = {}
            props.colSpan = 0
            return {
              children: text,
              props,
            }
          },
        },
        {
          title: "",
          dataIndex: "top_title2",
          width: excelDayColWidth,
          render: (text) => {
            const props: any = {}
            props.colSpan = totalDays
            return {
              children: text,
              props,
            }
          },
        },
        // placeholder cells to give fixed width for day cells, month total, av working hours, full time conversion,qualicifcation & continuation
        ...Array.from(new Array(totalDays + 4), (_, idx) => {
          return {
            title: "",
            dataIndex: "",
            width: idx < totalDays - 1 ? excelDayColWidth : 50,
            render: (text) => {
              const props: any = {}
              props.colSpan = 0
              return {
                children: text,
                props,
              }
            },
          }
        }),
      ]

  const getNameAndExperienceColumnsForExcel = () => {
    const cols = [
      {
        title: t("Name"),
        dataIndex: "name",
        key: "fullName",
        width: 40,
        render: (text, row) => {
          const props: any = {}
          if (row?.groupType1) props.colSpan = 0
          if (typeof row.name == "object")
            return {
              children: row?.name?.fullName,
              props,
            }
          return { children: text, props }
        },
      },
    ]

    if (aprilChangeApplicable) {
      cols.push({
        title: t("Years of experience"),
        dataIndex: "yearsExperience",
        key: "yearsExperience",
        width: 40,
        render: (text, row) => {
          const props: any = {}
          if (row?.groupType1) props.colSpan = 0
          return { children: text, props }
        },
      })
    }

    return cols
  }

  const excelColumns: any = !downloadable
    ? []
    : [
        {
          title: t("Service Type"),
          key: "serviceType",
          dataIndex: "",
          children: [
            {
              title: t("Capacity"),
              key: "capacity",
              dataIndex: "",
              children: [
                {
                  title: t("Capacity allocation division"),
                  key: "capacityAllocation",
                  dataIndex: "",
                  children: [
                    {
                      title: t("Occupation"),
                      dataIndex: "occupations",
                      key: "occupation",
                      width: 40,
                      render: (text, row) => {
                        const props: any = {}
                        if (row.groupType1 == "1")
                          props.colSpan = groupType1Colspan()
                        else if (row.groupType1 == "2")
                          props.colSpan = groupType2Colspan()
                        if (Array.isArray(row.occupations)) {
                          return {
                            children: row.occupations
                              .map((occupation) => occupation.label)
                              .join(","),
                            props,
                          }
                        }
                        return { children: text, props }
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: additionalExcelData?.capacity?.toString() || "",
              key: "capacityValue",
              dataIndex: "",
              children: [
                {
                  title: " ",
                  dataIndex: "",
                  children: [
                    {
                      title: t("Work style"),
                      dataIndex: "workStyle",
                      key: "workStyle",
                      width: 40,
                      render: (text, row) => {
                        const props: any = {}
                        if (row?.groupType1) props.colSpan = 0
                        return { children: text, props }
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: additionalExcelData?.service_type
            ? additionalExcelData?.service_type == 1
              ? t("After school day service")
              : t("Child Development Support")
            : "",
          key: "serviceTypeValue",
          dataIndex: "",
          children: [
            {
              title: t("Average number of actual users in the previous year"),
              key: "averageUsers",
              dataIndex: "",
              children: [
                {
                  title: " ",
                  children: getNameAndExperienceColumnsForExcel(),
                },
              ],
            },
            {
              title: additionalExcelData?.average_last_year
                ? additionalExcelData?.average_last_year?.toFixed(2) +
                  "人　(1日あたり)"
                : "0人　(1日あたり)",
              key: "averageUsersValue",
              dataIndex: "",
              children: [
                {
                  title: " ",
                  children: [firstWeekHeader],
                },
              ],
            },
          ],
        },
        {
          title: t("Office/facility name"),
          key: "facilityName",
          dataIndex: "",
          children: [
            {
              title: t("Standard number of employees required"),
              key: "standardEmployees",
              dataIndex: "",
              children: [
                {
                  title: t("Applicable system"),
                  key: "applicableSystem",
                  dataIndex: "",
                  children: [secondWeekHeader],
                },
              ],
            },
          ],
        },
        {
          title: additionalExcelData?.facility_official_name || "",
          key: "facilityNameValue",
          dataIndex: "",
          children: [
            {
              title: additionalExcelData?.standard_number_of_staffs + "名",
              key: "standardEmplyeeValue",
              dataIndex: "",
              children: [
                {
                  title: additionalExcelData?.additional_allowance || "",
                  key: "applicableSystemValue",
                  dataIndex: "",
                  children: otherWeekHeaders.concat(columnsAfterWeekForExcel),
                },
              ],
            },
          ],
        },
      ]

  const getShiftHoursAsDataSource = () => {
    const rowForWorkingHours = {
      key: "fulltimeEmployeeWorkingHours",
      occupations: t("Shift hours info"),
      groupType1: "2",
      groupType3: "1",
    }
    rowForWorkingHours[firstDayOfFirstWeek] = Object.keys(generalShiftHours)
      .map((key) => `${key} = ${generalShiftHours[key]}`)
      .join(",")
    return rowForWorkingHours
  }

  const { mutate: updateSchedule, isLoading: isScheduleSaving } = useMutation(
    ["save-Schedule"],
    async (data: any) =>
      data?.id
        ? updateOneStaffAttendanceShiftSchedule({
            facilityId: data?.facility_id,
            id: data.id,
            values: data,
          })
        : createStaffAttendanceShiftSchedule({
            facilityId: data?.facility_id,
            values: [data],
          }),
    {
      onSuccess: () => {
        refetch()
        notification.success({
          message: dynamicLangString([
            "Attendance Schedule table",
            "Updated Successfully",
          ]),
        })
        document
          .querySelectorAll(".show-modal")
          .forEach((elem) => elem.classList.remove("show-modal"))
      },
      onError: () =>
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        }),
    }
  )

  const handleScheduleSave = (data) => {
    updateSchedule(data)
  }

  const { mutate: updateRecord, isLoading: isRecordSaving } = useMutation(
    ["save-Record"],
    async (data: any) =>
      data?.id
        ? data?.is_delete
          ? resetAttendanceStaffResults(data.id)
          : updateAttendanceStaffResults({
              id: data.id,
              values: data,
            })
        : saveAttendanceStaffResults(data),
    {
      onSuccess: () => {
        refetch()
        notification.success({
          message: dynamicLangString(["Attendance", "Updated Successfully"]),
        })
        document
          .querySelectorAll(".show-modal")
          .forEach((elem) => elem.classList.remove("show-modal"))
      },
      onError: () =>
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        }),
    }
  )

  const handleRecordSave = (data) => {
    updateRecord(data)
  }

  const getDownloadFileName = () => {
    const type =
      calendarType == "Record" ? t("Record table") : t("Schedule table")
    return `${year}年${month
      ?.toString()
      ?.padStart(2, "0")}月勤務形態一覧表(${type}).xlsx`
  }

  return (
    <Wrapper>
      {downloadable && (
        <button
          id={`download_${calendarType}`}
          style={{ display: "none" }}
          onClick={() => {
            const excel = new Excel()

            excel
              .addSheet(
                calendarType == "Record"
                  ? t("Record table")
                  : t("Schedule table")
              )
              .setTHeadStyle({
                background: "FFFFFFFF",
                borderColor: "FFFFFFFF",
              })
              .setTBodyStyle({
                fontSize: 9,
                v: "center",
                h: "center",
                background: "FFFFFFFF",
                borderColor: "0000000",
                border: true,
              })
              .addColumns(excelColumnsTop)
              .addDataSource(excelTopColData)
              .setTHeadStyle({
                fontSize: 9,
                h: "center",
                v: "center",
                background: "FFFFFFFF",
                borderColor: "0000000",
              })
              .addColumns(excelColumns)
              .addDataSource(
                [].concat(dataSource).concat(getShiftHoursAsDataSource())
              )
              .saveAs(getDownloadFileName()) // List of Work Type
          }}
        ></button>
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={false}
        className={"calendar-table always-present-scrollbar"}
        loading={isScheduleSaving || isRecordSaving}
        scroll={{
          x: "scroll",
        }}
      />
      <div className={"table-info"}>
        {"※"}
        {Object.keys(generalShiftHours).map((key) => (
          <span key={key}>
            {key}
            {" = "}
            {generalShiftHours[key]}
          </span>
        ))}
      </div>
    </Wrapper>
  )
}
