// Facility Addition Types
export enum AdditionType {
  // ja: 定員超過
  EXCEED_CAPACITY = "1",
  // ja: サービス提供職員欠如減算
  LACK_OF_SERVICE_EMPLOYEE_SUBTRACTION = "2",
  // ja: 児童発達支援管理責任者欠如減算
  LACK_OF_CHILD_DEVELOPMENT_SUPPORT_MANAGER_SUBTRACTION = "3",
  // ja: 自己評価結果等未公表減算
  UNPUBLISHED_SUBTRACTION_OF_SELF_EVALUATION_RESULTS = "4",
  // ja: 身体拘束廃止未実施減算
  PHYSICAL_RESTRAINT_ABOLITION_NON_IMPLEMENTATION_SUBTRACTION = "5",
  // ja: 開所時間減算
  SUBTRACTION_OF_OPENING_TIME = "6",
  // ja: 提供時間区分
  PROVISION_TIME_DIVISION = "7",
  // ja: 児童指導員等加配加算
  ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS = "8",
  // ja: 専門的支援加算
  PROFESSIONAL_SUPPORT_ADDITION = "9",
  // ja: 福祉専門職員配置等加算
  WELFARE_SPECIALIST_PLACEMENT_ADDITION = "10",
  // ja: 支援プログラム未公表減算
  SUPPORT_PROGRAM_NOT_PUBLICIZED_SUBTRACTION = "11",
  // ja: 虐待防止措置未実施減算
  ABUSE_PREVENTION_NOT_IMPLEMENTED_SUBTRACTION = "12",
  // ja: 業務継続計画未策定減算
  BUSINESS_CONTINUITY_PLAN_NOT_IMPLEMENTED_SUBTRACTION = "13",
  // ja: 情報公表未報告減算
  INFORMATION_DISCLOSURE_NOT_REPORTED_SUBTRACTION = "14",
  // ja: 中核機能強化事業所加算
  CORE_FUNCTION_ENHANCEMENT_SITE_ADDITION = "15",
  // ja: 中核機能強化加算
  CORE_FUNCTION_ENHANCEMENT_ADDITION = "16",
  // ja: 専門的支援体制加算
  PROFESSIONAL_SUPPORT_SYSTEM_ADDITION = "17",
}
