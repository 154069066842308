// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const OperationWrapper = styled.div`
  padding: 20px 0;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }

  .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }

  .flexbox {
    display: flex;
    align-items: center;
    gap: 18px;
    color: #191919;

    @media screen and (max-width: 426px) {
      display: grid;
    }
  }
  .services-wrapper {
    @media (max-width: 929px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .services-wrapper_flex {
    @media (max-width: 630px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .flex-year-container {
    display: flex;
    column-gap: 6px;
    @media screen and (max-width: 426px) {
      column-gap: 0;
      span {
        margin-left: 6px;
      }
    }
  }
  .option-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
  }
  .option-row.services-wrapper {
    gap: 26px;
    @media screen and (max-width: 426px) {
      display: grid;
    }
  }
  .operation-label {
    min-width: 120px;

    @media (max-width: ${theme.breakpoints.sm}) {
      min-width: 80px;
    }
  }
  .operation-option {
    & .checkbox-wrapper .checkbox-group {
      .ant-checkbox-group {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 10px;

        label.ant-checkbox-wrapper {
          margin-bottom: 0;
        }
        @media screen and (max-width: 426px) {
          display: grid;
        }
      }
    }
  }
  .change-display-container {
    margin-top: 22px;
    margin-bottom: 11px;
  }

  @media (max-width: 768px) {
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
    .change-display-container {
      width: 100%;
    }
  }
`
