type Props = {
  burdenMaxMonth: number
  localBurdenMaxMonth: number
}

export function KyotoUpperMonthLimit({
  burdenMaxMonth,
  localBurdenMaxMonth,
}: Props) {
  return (
    <table className={"nbl_info_top_content__table"}>
      <tbody>
        <tr>
          <td className={"large_cell"}>{"利用者負担上限月額（国）"}</td>
          {String(burdenMaxMonth)
            .padStart(6, ".")
            .split("")
            .map((v, indx) => (
              <td key={indx}>{v !== "." ? v : ""}</td>
            ))}
          <td> {"利用者負担上限月額（市）"}</td>
          {String(localBurdenMaxMonth)
            .padStart(6, ".")
            .split("")
            .map((v, indx) => (
              <td key={indx}>{v !== "." ? v : ""}</td>
            ))}
        </tr>
      </tbody>
    </table>
  )
}
