import { t } from "i18next"
import React from "react"
import styled, { css } from "styled-components"
import { theme } from "@project/shared"
import { Button, Props } from "../Button"

interface iButton extends Props {
  label?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
  disabled?: boolean
  p?: string
  underlineText?: boolean
}

const StyledButton = styled(Button)<iButton>`
  padding: ${({ p }) => (p ? p : `12px 30px`)};
  text-decoration: ${({ underlineText }) =>
    underlineText ? "underline" : `unset`};
  &.service_provision_btn {
    padding: 12px 24px !important;
  }
  ${({ typeOf }) =>
    typeOf === "link"
      ? css`
          color: ${(props: any) => (props?.color ? props?.color : theme.blue5)};
          background: transparent;
          text-decoration: underline;
          font-weight: 400;
          font-size: 16px;
          &:hover {
            background: ${theme.gray4};
          }
        `
      : typeOf === "secondary"
      ? css`
          color: #191919;
          background-color: #f3f3f3;
          border: 1px solid #d2d1d1;
          &:hover {
            color: #191919;
            background-color: #f3f3f3;
            border: 1px solid #d2d1d1;
            opacity: 0.8;
          }
        `
      : typeOf === "draft"
      ? css`
          color: #191919;
          background: rgba(7, 130, 200, 0.2);
          border: 1px solid #0782c8;
          &:hover {
            color: #191919;
            background: rgba(7, 130, 200, 0.2);
            border: 1px solid #0782c8;
            opacity: 0.8;
          }
        `
      : typeOf === "check-all"
      ? css`
          border: 1px solid #d2d1d1;
          background: #ffffff;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "print"
      ? css`
          border: 1px solid #d2d1d1;
          background: grey;
          color: #ffffff;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "pdf"
      ? css`
          border: 1px solid #d2d1d1;
          background: grey;
          color: #ffffff;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "printBlack"
      ? css`
          border: 1px solid #d2d1d1;
          background: grey;
          color: #ffffff;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "show"
      ? css`
          border: 1px solid #d2d1d1;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #f3f3f3;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "outlined"
      ? css`
          border: 1px solid #d2d1d1;
          background: transparent;
          color: #000000;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "private-no-publish"
      ? css`
          border: 1px solid ${theme.orange};
          background: transparent;
          min-width: 150px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: ${theme.orange};
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "publish"
      ? css`
          background: ${theme?.orange1};
          color: #ffffff;
          min-width: 130px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          &:hover {
            border: 1px solid #d2d1d1;
            background: #ffffff;
            color: #000000;
            opacity: 0.8;
          }
        `
      : typeOf === "text-output"
      ? css`
          border: 1px solid ${theme.blue5};
          background-color: ${theme.blue6};
          border-radius: 100px !important;
          display: flex;
          align-items: center;
          padding: 8px 30px;
          font-size: 16px;

          line-height: 23.17px;
          color: ${theme.black};
          column-gap: 12px;
          div {
            margin: 0;
          }

          &:hover {
            filter: brightness(104%);
          }

          &:disabled {
            background-color: ${theme.bgColor2};
            border-color: ${theme.gray2};
            color: ${theme.gray3};
            filter: brightness(100%);
            cursor: not-allowed;
          }
        `
      : typeOf === "csv-btn"
      ? css`
          border: 1px solid ${theme.blue5};
          background-color: ${theme.blue6};
          border-radius: 100px !important;
          display: flex;
          align-items: center;
          padding: 8px 30px;
          font-size: 16px;

          line-height: 23.17px;
          color: ${theme.black};
          column-gap: 12px;
          div {
            margin: 0;
          }

          &:hover {
            filter: brightness(104%);
          }

          &:disabled {
            background-color: ${theme.bgColor2};
            border-color: ${theme.gray2};
            color: ${theme.gray3};
            filter: brightness(100%);
            cursor: not-allowed;
          }
        `
      : css`
          color: #f3f3f3;
          background-color: #0782c8;
          border-color: #0782c8;
          &:hover {
            color: #f3f3f3;
            background-color: #0782c8;
            border-color: #0782c8;
            opacity: 0.8;
          }
        `}
  cursor:${({ disabled }) => (disabled ? "not-allowed" : "normal")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`

const OwnerButton: React.FC<iButton> = (props) => {
  const { typeOf, onClick, className, text } = props
  switch (typeOf) {
    case "search":
      return (
        <StyledButton
          onClick={onClick}
          className={"search-button"}
          text={text || t("Search")}
          icon={"search"}
          shape={"circle"}
          {...props}
        />
      )
    case "primary":
      return (
        <StyledButton
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          color={"#ffffff"}
          {...props}
        />
      )
    case "secondary":
      return (
        <StyledButton
          typeOf={"secondary"}
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          {...props}
        />
      )

    case "copy":
      return (
        <Button
          text={t("Copy")}
          icon={"copy"}
          backgroundColor={"#858686 !important"}
          borderColor={"#858686 !important"}
          color={"white"}
          padding={5}
          className={"copy-btn"}
          {...props}
        />
      )
    case "draft":
      return (
        <StyledButton
          typeOf={"draft"}
          shape={"circle"}
          className={"draft-btn"}
          text={text || t("Save as Draft")}
          {...props}
        />
      )
    case "check-all":
      return (
        <StyledButton
          typeOf={"check-all"}
          icon={"check-all"}
          shape={"circle"}
          className={className}
          text={text || t("Check All")}
          {...props}
        />
      )
    case "outlined":
      return (
        <StyledButton
          typeOf={"outlined"}
          onClick={onClick}
          className={className}
          text={text}
          shape={"circle"}
          {...props}
        />
      )
    case "print":
      return (
        <StyledButton
          typeOf={"print"}
          onClick={onClick}
          className={className}
          text={text}
          icon={"print"}
          shape={"round"}
          {...props}
        />
      )
    case "pdf":
      return (
        <StyledButton
          typeOf={"print"}
          onClick={onClick}
          className={className}
          text={text}
          icon={"pdf"}
          shape={"round"}
          {...props}
        />
      )
    case "printBlack":
      return (
        <StyledButton
          typeOf={"print"}
          onClick={onClick}
          className={className}
          text={text}
          icon={"print"}
          shape={"round"}
          {...props}
        />
      )
    case "show":
      return (
        <StyledButton
          typeOf={"show"}
          onClick={onClick}
          className={className}
          text={text}
          iconPosition={"right"}
          icon={"down"}
          shape={"round"}
          {...props}
        />
      )
    case "private-no-publish":
      return (
        <StyledButton
          icon={"lock"}
          typeOf={"private-no-publish"}
          iconPosition={"left"}
          text={text}
          shape={"round"}
          backgroundColor={"orange"}
          borderColor={"orange"}
          onClick={onClick}
          {...props}
        />
      )
    case "publish":
      return (
        <StyledButton
          icon={"unlock"}
          typeOf={"publish"}
          iconPosition={"left"}
          text={text}
          shape={"round"}
          backgroundColor={"orange"}
          onClick={onClick}
          {...props}
        />
      )
    case "csv-btn":
      return (
        <StyledButton
          disabled={props.disabled}
          icon={"csv-icon"}
          typeOf={"csv-btn"}
          iconPosition={"left"}
          text={text}
          shape={"round"}
          backgroundColor={"orange"}
          onClick={onClick}
        />
      )

    case "text-output":
      return (
        <StyledButton
          disabled={props.disabled}
          icon={"text-output"}
          typeOf={"text-output"}
          iconPosition={"left"}
          text={text}
          shape={"round"}
          backgroundColor={"orange"}
          onClick={onClick}
        />
      )

    default:
      return <StyledButton text={text} typeOf={typeOf} {...props} />
  }
}

export { OwnerButton }
