// packages
import { t } from "i18next"
import moment from "moment"

// types
import { SelectInput } from "@project/shared"

// utils
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"

// types
import {
  FieldsProps,
  FormFieldsProps,
} from "../../../molecules/FormFields/types"
import {
  InitialRewriteData,
  UsageRecordFormFieldSchemaDataProps,
  UsageRecordRewriteSaveResponseType,
} from "../types"
import { getLanguage } from "../../../../utils/getLanguage"

export const usageRecordFormFieldSchema = ({
  formik,
  uniqueKey,
  childOptions,
  isChildListLoading,
}: UsageRecordFormFieldSchemaDataProps): FormFieldsProps => {
  const { child_id, furigana_sort } =
    formik.values.multipleRewriteFormList[uniqueKey] // here uniqueKey is used as a key to get the values from the formik.

  // conditional field, if child is not selected then show this field
  // in the entire form-fields.
  const conditionalField = [
    {
      fieldType: {
        type: "text",
        text: "Please select a child",
      } as const,
    },
  ]

  const getFieldsForStartTime = () => {
    const startTimeFields: Array<FieldsProps> = [
      {
        name: "startTimeRange",
        fieldType: {
          type: "dateRange",
          extraOptions: {
            startDateName: "startDate",
            endDateName: "endDate",
            format: getLanguage() === "ja" ? "YYYY年MM月DD日" : "YYYY-MM-DD",
            startDateValue:
              formik.values.multipleRewriteFormList[uniqueKey].startDate,
            endDateValue:
              formik.values.multipleRewriteFormList[uniqueKey].endDate,

            /**
             * 1. If the startDate is touched and has an error, show the error
             */
            startDateError:
              formik.errors?.multipleRewriteFormList &&
              formik.errors.multipleRewriteFormList[uniqueKey]?.startDate
                ? typeof formik.errors.multipleRewriteFormList[uniqueKey]
                    .startDate === "string"
                  ? formik.errors.multipleRewriteFormList[uniqueKey].startDate
                  : formik.errors.multipleRewriteFormList[uniqueKey]
                      .startDate[0]
                : null,

            /**
             * 2. If the endDate is touched and has an error, show the error
             */
            endDateError:
              formik.errors?.multipleRewriteFormList &&
              formik.errors?.multipleRewriteFormList?.[uniqueKey]?.endDate
                ? typeof formik.errors.multipleRewriteFormList?.[uniqueKey]
                    ?.endDate === "string"
                  ? formik?.errors?.multipleRewriteFormList?.[uniqueKey]
                      ?.endDate
                  : formik.errors?.multipleRewriteFormList?.[uniqueKey]
                      ?.endDate[0]
                : null,

            /**
             * 3. If the startDate or endDate has error and are touched, the startDateStatus is "error"
             */
            startDateStatus:
              formik.touched?.multipleRewriteFormList &&
              formik.errors?.multipleRewriteFormList &&
              (formik.touched.multipleRewriteFormList[uniqueKey]?.startDate ||
                formik.touched.multipleRewriteFormList[uniqueKey]?.endDate) &&
              (formik.errors.multipleRewriteFormList[uniqueKey]?.startDate ||
                formik.errors.multipleRewriteFormList[uniqueKey]?.endDate)
                ? "error"
                : null,

            /**
             * 4. If the endDate or startDate has error and are touched, the endDateStatus is "error"
             */
            endDateStatus:
              formik.touched.multipleRewriteFormList &&
              formik.errors.multipleRewriteFormList &&
              (formik.touched.multipleRewriteFormList[uniqueKey]?.startDate ||
                formik.touched.multipleRewriteFormList[uniqueKey]?.endDate) &&
              (formik.errors.multipleRewriteFormList[uniqueKey]?.startDate ||
                formik.errors.multipleRewriteFormList[uniqueKey]?.endDate)
                ? "error"
                : null,

            onStartDateChange(val) {
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.startDate`,
                val
              )
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.temporaryClosurePeriodDates`,
                null
              )
            },
            onEndDateChange(val) {
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.endDate`,
                val
              )
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.temporaryClosurePeriodDates`,
                null
              )
            },
          },
        },
      },
      {
        name: "holidayOption",
        className: "holiday-option-checkbox",
        fieldType: {
          type: "checkboxes",
          extraOptions: {
            values:
              formik.values.multipleRewriteFormList[uniqueKey].holidayOption,
            options: [
              {
                label: t("Saturdays and Sundays will be school holidays"),
                value: 1,
              },
              {
                label: t("Make public holidays school holidays"),
                value: 2,
              },
            ],
            onChange(event) {
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.holidayOption`,
                event
              )
            },
          },
        },
      },
    ]

    if (
      formik.values.multipleRewriteFormList[uniqueKey].startDate &&
      formik.values.multipleRewriteFormList[uniqueKey].endDate
    ) {
      startTimeFields.push({
        className: "full-width-label",
        fieldType: {
          type: "text",
          text: "Please select dates for school holidays during temporary school closures",
        },
      })
      startTimeFields.push({
        name: "temporaryClosurePeriodDates",
        className: "days-of-increased-use-checkbox",
        fieldType: {
          type: "checkboxes",
          extraOptions: {
            values:
              formik.values.multipleRewriteFormList[uniqueKey]
                .temporaryClosurePeriodDates,
            options: childOptions
              ?.find(
                (c) =>
                  c?.value ==
                  formik.values.multipleRewriteFormList[uniqueKey]?.child_id
              )
              ?.used_days?.filter(
                (day) =>
                  moment(day?.value)?.isSameOrAfter(
                    formik.values.multipleRewriteFormList[
                      uniqueKey
                    ].startDate.format("YYYY-MM-DD")
                  ) &&
                  moment(day?.value)?.isSameOrBefore(
                    formik.values.multipleRewriteFormList[
                      uniqueKey
                    ].endDate.format("YYYY-MM-DD")
                  )
              ),
            onChange(event) {
              formik.setFieldValue(
                `multipleRewriteFormList.${uniqueKey}.temporaryClosurePeriodDates`,
                event
              )
            },
          },
        },
      })
    }

    return startTimeFields
  }

  return [
    // This field is not available in HUG page, so not really sure how it will  be handled
    // that's why data coming here is hard coded.

    {
      fieldLabel: "select a child",
      isRowFlexed: true,
      required: true,
      fields: [
        {
          name: "childName",
          fieldType: {
            type: "JSX-element",
            JSXElementData: (
              <div>
                <div className={"multiple-input-container flexbox"}>
                  <SelectInput
                    placeholder={"--"}
                    width={60}
                    height={40}
                    value={furigana_sort}
                    options={FuriganaAlphabetsOption}
                    name={"furigana_sort"}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `multipleRewriteFormList.${uniqueKey}.furigana_sort`,
                        val
                      )
                    }}
                  />
                  <SelectInput
                    placeholder={"--"}
                    width={180}
                    height={40}
                    options={childOptions}
                    name={"child_id"}
                    value={child_id}
                    loading={isChildListLoading}
                    onBlur={() => {
                      formik.setFieldTouched(
                        `multipleRewriteFormList.${uniqueKey}.child_id`,
                        true
                      )
                    }}
                    onChange={(val) => {
                      /**
                       * The reason we are using 'multipleRewriteFormList.${uniqueKey}.child_id'
                       * as a key to set the value is because we are using the same formik
                       * for multiple forms, so we need to set the value for the specific form
                       * that is being used.
                       * [NOTE: So we are using the uniqueKey as a key to set the value.]
                       *
                       * NOTE: Reason for not resetting startDate and endDate is because
                       * Date should initially be set off of Bulk settings for period date range if available.
                       */
                      formik.setFieldValue(
                        `multipleRewriteFormList.${uniqueKey}.child_id`,
                        val
                      )
                      const childName = val
                        ? childOptions?.find((c) => c?.value == val)?.label
                        : "" || ""
                      formik.setFieldValue(
                        `multipleRewriteFormList.${uniqueKey}.child_name`,
                        childName
                      )
                    }}
                  />
                </div>
                {formik.touched.multipleRewriteFormList &&
                  formik.errors.multipleRewriteFormList &&
                  formik.touched.multipleRewriteFormList[uniqueKey]?.child_id &&
                  formik.errors.multipleRewriteFormList[uniqueKey]
                    ?.child_id && (
                    <div className={"error-message"}>
                      {
                        formik.errors.multipleRewriteFormList[uniqueKey]
                          .child_id
                      }
                    </div>
                  )}
              </div>
            ),
          },
        },
      ],
    },
    {
      fieldLabel: "Period of temporary school closure",
      isRowFlexed: true,
      fields: child_id ? getFieldsForStartTime() : conditionalField,
    },
    {
      fieldLabel: "Days of use increased due to temporary school closures",
      isRowFlexed: true,
      fields: child_id
        ? [
            {
              name: "daysOfIncreasedUse",
              className: "days-of-increased-use-checkbox",
              fieldType: {
                type: "checkboxes",
                extraOptions: {
                  values:
                    formik.values.multipleRewriteFormList[uniqueKey]
                      .daysOfIncreasedUse,
                  options: childOptions?.find(
                    (c) =>
                      c?.value ==
                      formik.values.multipleRewriteFormList[uniqueKey]?.child_id
                  )?.used_days,
                  onChange(event) {
                    formik.setFieldValue(
                      `multipleRewriteFormList.${uniqueKey}.daysOfIncreasedUse`,
                      event
                    )
                  },
                },
              },
            },
          ]
        : conditionalField,
    },
    {
      fieldLabel: "Extension support addition due to temporary use",
      isRowFlexed: true,
      fields: child_id
        ? childOptions?.find(
            (c) =>
              c?.value ==
              formik.values.multipleRewriteFormList[uniqueKey]?.child_id
          )?.extend_support_days?.length > 0
          ? [
              {
                name: "extendSupportDays",
                className: "days-of-increased-use-checkbox",
                fieldType: {
                  type: "checkboxes",
                  extraOptions: {
                    values:
                      formik.values.multipleRewriteFormList[uniqueKey]
                        .extendSupportDays,
                    options: childOptions?.find(
                      (c) =>
                        c?.value ==
                        formik.values.multipleRewriteFormList[uniqueKey]
                          ?.child_id
                    )?.extend_support_days,
                    onChange(event) {
                      formik.setFieldValue(
                        `multipleRewriteFormList.${uniqueKey}.extendSupportDays`,
                        event
                      )
                    },
                  },
                },
              },
            ]
          : [
              {
                fieldType: {
                  type: "text",
                  text: "Information on extension support addition is not registered",
                },
              },
            ]
        : conditionalField,
    },
  ]
}

export const initialUsageRecordFormValues = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  return {
    child_id: 0,
    holidayOption: [],
    furigana_sort: null,
    startDate: startDate,
    endDate: endDate,
    daysOfIncreasedUse: [],
    extendSupport: [],
  }
}

export const requestDataForUsageRecordRewrite = (requestData: {
  [key: string]: InitialRewriteData
}) => {
  const data: Array<UsageRecordRewriteSaveResponseType> = []
  if (requestData) {
    Object.keys(requestData)?.map((key) => {
      const childData = requestData[key]
      const obj: UsageRecordRewriteSaveResponseType = {
        child_id: childData.child_id,
        sat_sun_school_holiday_flag: childData.holidayOption?.includes(1)
          ? true
          : false,
        public_school_holiday_flag: childData.holidayOption?.includes(2)
          ? true
          : false,
        temporary_school_closure_period_start: childData?.startDate,
        temporary_school_closure_period_end: childData?.endDate,
        temporary_school_closure_dates:
          childData?.temporaryClosurePeriodDates?.map((date) =>
            moment(date).format("YYYY-MM-DDTHH:mm:SSZ")
          ),
        dates_of_use_increase: childData?.daysOfIncreasedUse?.map((date) =>
          moment(date).format("YYYY-MM-DDTHH:mm:SSZ")
        ),
        dates_of_extended_support: childData?.extendSupportDays?.map((date) =>
          moment(date).format("YYYY-MM-DDTHH:mm:SSZ")
        ),
      }
      data.push(obj)
    })
  }
  return data
}

export const TEMPORARY_SAVE_KEY_USAGE_RECORD_REWRITE =
  "temporary_usage_record_rewrite"
