import { BusinessCalendarPagination, theme } from "@project/shared"
import { Button, Card, Skeleton, notification } from "antd"
import moment from "moment"
import Calendar from "react-calendar"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { getMonth } from "@wojtekmaj/date-utils"
import {
  AttendanceDate,
  AttendanceOperation,
  AttendanceStats,
} from "../../molecules"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import {
  fetchAttendanceSchedule,
  fetchAttendanceScheduleStats,
} from "../../../services/attendanceTable"
import { useRouter } from "next/router"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { ChildAttendanceManagementPermission } from "../../../utils/PermissionKeys"

const StyledMonthPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: 10px;
    margin-right: 10px;
    padding: 8px 14px;
    height: auto;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
  }
`

const MainContent = styled.div`
  margin: 10px 0;
  .mt-10 {
    margin-top: 10px;
  }
  .print-button-container {
    margin-bottom: 20px;
    display: block;
    width: 100%;
  }
  .print-button-container button {
    background-color: ${theme.gray3} !important;
    border-color: ${theme.gray3} !important;
  }
  .pick-drop-section {
    display: flex;
    flex-wrap: wrap;
    margin: 4px 0 16px 0;
    @media (max-width: 575px) {
      row-gap: 0px;
    }
    button {
      padding: 8px;
    }
    .section1 {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 4px;
      button.pick {
        background: #79e3aa;
        border-color: #79e3aa;
        color: #ffffff;
        margin-right: 5px;
      }

      button.drop {
        background: #4da2f8;
        border-color: #4da2f8;
        color: #ffffff;
        margin-right: 5px;
      }

      .pick {
        cursor: default;
      }

      .drop {
        cursor: default;
      }
    }
    .section2 {
      display: flex;
      align-items: center;
      button {
        color: #191919;
        background: #d2d1d1;
        border-color: #d2d1d1;
        cursor: default;
        margin-right: 5px;
        @media (max-width: 768px) {
          color: #ffffff;
        }
      }
    }
    .marginLeft5 {
      margin-left: 3px;
    }
  }
  .calendar-wrapper {
    max-width: 100%;
    overflow-x: scroll;
    width: 100%;

    /* show scrollbar all time*/
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      /* color of the scroll thumb */
      border-radius: 20px;
      /* roundness of the scroll thumb */
      border: 3px solid transparent;
      /* creates padding around scroll thumb */
    }
    /* show scrollbar all time*/

    .react-calendar {
      margin-bottom: 0px;
    }

    @media print {
      display: none;
    }
  }

  .attendance-calendar .ant-card.ant-card-bordered {
    padding-top: 15px;
  }

  @media print {
    @page {
      margin: 5mm 0mm;
    }
    body * {
      visibility: hidden;
    }
    .section-to-print {
      visibility: visible;
    }
    .section-not-to-print {
      display: none;
    }
    .ant-card {
      border: none;
      box-shadow: none;
      padding: 0 10px 0 10px;
      .ant-card-head {
        height: auto;
        min-height: 0 !important;
        padding: 0;
        margin: 0 6px;
        .ant-card-head-wrapper {
          height: auto;
          flex-direction: row;
          .ant-card-extra {
            display: none;
          }
        }
      }
      .ant-card-body {
        padding: 0px !important;
        .pick-drop-section {
          gap: 10px;
          margin-bottom: 0;
          button {
            font-size: 8pt;
            padding: 2pt;
          }
          span {
            font-size: 8pt;
          }
        }
        .statistic-block {
          padding: 5px 10px;
          margin-bottom: 15px;
          display: flex !important;
          column-gap: 10px;
          .stat-container {
            span.stat-label {
              font-size: 7pt;
              &:after {
                border-bottom: 15.325px solid transparent;
                border-right: 10px solid transparent;
                border-left: 15px solid #cde6f4;
                border-top: 15.325px solid transparent;
                transform: translateX(20%);
              }
            }
            span {
              font-size: 8pt;
            }
          }

          @media print {
            column-gap: 20px;
            row-gap: 10px;
            margin-bottom: 10px;
            .stat-container {
              margin: 0;
            }
          }
        }

        .calendar-wrapper {
          .react-calendar {
            margin: 15px 0;
          }
        }
      }
    }
  }

  .print-calendar-container {
    display: none;
    @media print {
      margin-top: 10px;
      display: block;
      width: 100%;
      table {
        width: 100%;
        overflow: visible !important;
        table-layout: fixed;
      }
      thead {
        display: table-header-group;
      }
      tfoot {
        display: table-row-group;
      }
      tr {
        page-break-inside: avoid;
      }
      th {
        width: 14.28%;
        max-width: 14.28%;
        border: 1px solid ${theme.gray2};
      }
      td {
        height: 1px;
        width: 14.28%;
        vertical-align: top;
        border: 1px solid ${theme.gray2};
        text-align: center;
        position: relative;
        abbr {
          position: absolute;
          top: 0;
          right: 0;
        }
        &.neighbor-date {
          abbr {
            display: none;
          }
        }
        .user-attendance-date {
          padding: 25px 5px 5px 5px;
          .tag-value {
            margin-bottom: 4px;
          }

          @media print {
            background-color: transparent !important;
          }
        }
      }
    }
  }
`

const StyledPageCard = styled(Card)`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border-bottom: 1px solid #d2d1d1;
  align-items: center;
  .ant-card-head {
    font-size: 18px;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #d2d1d1;
    margin: 0 30px;
    padding: 0px;
  }
  .ant-card-body {
    border-radius: 0 0 12px 12px;
    margin: 0 6px !important;
    padding: 20px 24px 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .ant-card-head-wrapper {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .ant-card-head-title {
      flex: auto 0;
      padding: 0;
      white-space: unset;
      word-break: break-all;
    }
  }
  .action-btns-group {
    display: flex;
  }
  .ant-card-extra {
    margin-left: auto;
    align-self: flex-end;
    @media (max-width: 414px) {
      .section-not-to-print {
        justify-content: flex-end;
        row-gap: 10px;
        flex-wrap: wrap;
      }
    }
  }
  .month-pagination-container {
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    .ant-card-head-wrapper {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }
    .ant-card-extra {
      margin-left: 0;
    }
  }
  @media (max-width: 768px) {
    .ant-card-body {
      padding: 16px 16px;
    }
    .ant-card-head {
      margin: 0 16px;
    }
  }
`

const StyledCalendar = styled(Calendar)<{ isMoreGap?: number }>`
  margin-top: 25px;
  margin-bottom: 25px;
  [class*="neighboringMonth"] {
    cursor: default;
    abbr {
      display: none;
    }
  }
  [class*="weekday"] {
    border: 0.1px solid #d2d1d1;
    border-bottom: 0;
    text-align: center;
    background-color: #f3f3f3;
    abbr {
      cursor: default;
      text-decoration: none;
    }
  }
  [class*="tile"] {
    border: 0.1px solid #d2d1d1;
    background: transparent;
    pointer-events: none;
    position: relative;
    display: flex;
    * {
      pointer-events: auto;
    }
    > div {
      padding: 40px 4px;
    }
    abbr {
      background: #f3f3f3;
      border: 0.1px solid #d2d1d1;
      border-top: 0;
      border-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 37px;
    }
  }

  @media (max-width: 1498px) {
    overflow: auto;
    [class*="__days"],
    [class*="__weekdays"] {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    min-width: 1000px;
  }
  @media (max-width: 600px) {
    min-width: 875px;
  }
`
// 125px for each cell * 7 cells (min width in hug system)

const initialStats = {
  average_daily_users: "0人 / 0%",
  days_used: "0人",
  number_of_absentees_2_rate: "0人/0%",
  number_of_absentees_no_addition_rate: "0人/0%",
  number_of_absentees_rate: "0人/0%",
  number_of_participants: "0人",
  three_months_average_number_of_users_rate: "0人/0%",
  unregistered_users: "0人",
}

const LOCAL_STORAGE_USER_ATTENDANCE_KEY = "user_attendance_filters"

export const AttendancePage = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const contentRef = useRef<HTMLDivElement>(null)
  const {
    year: queryYear,
    month: queryMonth,
    facilityId: queryFacility,
  } = router?.query
  const { facilities, permissions, isOwner } = useContext(AuthContext)

  const [width, setWidth] = useState(window.innerWidth)

  const localStorageFilters = localStorage.getItem(
    LOCAL_STORAGE_USER_ATTENDANCE_KEY
  )

  let localStorageFiltersObject: any = {}

  try {
    localStorageFiltersObject = localStorageFilters
      ? JSON.parse(localStorageFilters)
      : {}
  } catch (e) {
    localStorageFiltersObject = {}
  }

  const year = queryYear || localStorageFiltersObject?.currentYear
  const month = queryMonth || localStorageFiltersObject?.currentMonth
  const facilityId =
    queryFacility || localStorageFiltersObject?.currentFacility?.value

  addEventListener("resize", () => {
    setWidth(window.innerWidth)
  })
  const componentRef = useRef(null)
  let activeFacility: any = ""
  const facilityOptions = facilities.map((facility) => {
    const facilityObj = {
      key: facility.id,
      label: facility.facility_name_short,
      value: facility.id,
    }
    if (facilityId && facilityId == facility.id) activeFacility = facilityObj
    return facilityObj
  })
  const today = new Date()
  const [queryParams, setQueryParams] = useState({
    currentYear: year ? +year?.toString() : today.getFullYear(),
    currentMonth: month ? +month?.toString() : today.getMonth() + 1,
    currentFacility: activeFacility
      ? activeFacility
      : Array.isArray(facilityOptions) && facilityOptions.length > 0
      ? facilityOptions[0]
      : null,
    withDesiredPickUps: localStorageFiltersObject?.withDesiredPickUps || "1",
    withActivity: localStorageFiltersObject?.withActivity || "1",
    withCommuters: localStorageFiltersObject?.withCommuters || "1",
  })

  const hasWriteAccess = useMemo(() => {
    if (isOwner) return true

    return hasPermissionForFacility(
      permissions,
      ChildAttendanceManagementPermission,
      "write",
      queryParams?.currentFacility?.value
    )
  }, [permissions, queryParams.currentFacility])

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_USER_ATTENDANCE_KEY,
      JSON.stringify(queryParams)
    )
  }, [queryParams?.currentFacility])

  const [schedules, setSchedules] = useState(null)
  const [stats, setStats] = useState(initialStats)

  const handleDisplayChange = ({
    facility,
    year,
    month,
    with_desired_pickup,
    with_program,
    with_commuters,
  }) => {
    const newFacility = facilityOptions.find(
      (option) => option.value == facility
    )
    const newQueryParams = {
      currentFacility: newFacility,
      currentYear: year,
      currentMonth: month,
      withDesiredPickUps: with_desired_pickup,
      withActivity: with_program,
      withCommuters: with_commuters,
    }
    router.push(
      `?year=${year}&month=${month}&facilityId=${facility || ""}`,
      undefined,
      { shallow: true }
    )
    setQueryParams(newQueryParams)
    scrollToSelectedElement(contentRef)
  }

  const { isLoading: isStatsLoading } = useQuery(
    ["attendanceScheduleStats", queryParams],
    () =>
      fetchAttendanceScheduleStats({
        facilityId: queryParams?.currentFacility?.value,
        year: queryParams?.currentYear,
        month: queryParams?.currentMonth,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
      onSuccess: (response) => {
        if (response?.data) setStats(response?.data)
        else {
          setStats(initialStats)
        }
      },
    }
  )

  const { isLoading: isScheduleLoading } = useQuery(
    ["attendanceScheduleData", queryParams],
    () => {
      const query = []

      if (queryParams?.withActivity == "1") {
        query.push("with_activity=1")
      }
      if (queryParams?.withCommuters == "1") {
        query.push("with_commuter=1")
      }

      return fetchAttendanceSchedule({
        facilityId: queryParams?.currentFacility.value,
        year: queryParams?.currentYear,
        month: queryParams?.currentMonth,
        query: query.length > 0 ? "?" + query.join("&") : "",
      })
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
      onSuccess: (response) => {
        if (response?.data?.schedules) setSchedules(response?.data?.schedules)
        else {
          setSchedules({})
        }
      },
    }
  )

  const getDateInFormat = (date) => {
    const d = new Date(date),
      year = d.getFullYear()

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  const getScheduleDetailForDay = (date) => {
    const formattedDate = getDateInFormat(date)
    let data: any = {}
    const matchingScheduleDate = schedules
      ? Object.keys(schedules)?.find(
          (scheduleDate) => formattedDate == getDateInFormat(scheduleDate)
        )
      : null
    if (matchingScheduleDate) {
      data = schedules[matchingScheduleDate]
    }
    data.formattedDate = formattedDate
    return data
  }

  const printAttendanceCalendar = () => {
    if (typeof window != "undefined") {
      const calendarId = "print-calendar"

      document.getElementById(calendarId)?.remove()

      const calendarTable = document.createElement("table")
      calendarTable.setAttribute("id", calendarId)

      const tableHeader = document.createElement("thead")

      const headersFromWeb = document.getElementsByClassName(
        "react-calendar__month-view__weekdays__weekday"
      )

      for (let i = 0; i < headersFromWeb?.length; i++) {
        const tableHead = document.createElement("th")
        tableHead.innerHTML = headersFromWeb[i].innerHTML
        tableHeader.appendChild(tableHead)
      }

      calendarTable.appendChild(tableHeader)

      const bodyFromWeb = document.getElementsByClassName(
        "react-calendar__tile"
      )

      const tableRows = []
      for (let i = 0; i < bodyFromWeb?.length; i++) {
        const index = Math.floor(i / 7)
        if (tableRows.length <= index) {
          const tableRowArr = document.createElement("tr")
          tableRows.push(tableRowArr)
        }
        const tableRow = tableRows[index]
        const tableCell = document.createElement("td")
        if (
          bodyFromWeb[i].classList.contains(
            "react-calendar__month-view__days__day--neighboringMonth"
          )
        ) {
          tableCell.classList.add("neighbor-date")
        }
        tableCell.innerHTML = bodyFromWeb[i].innerHTML
        tableRow.appendChild(tableCell)
        tableRows[index] = tableRow
      }

      const tableBody = document.createElement("tbody")

      tableRows?.map((row) => tableBody.appendChild(row))

      calendarTable.appendChild(tableBody)

      document
        .getElementsByClassName("print-calendar-container")?.[0]
        ?.appendChild(calendarTable)
      window.print()
    }
  }

  return (
    <MainContent ref={componentRef}>
      <div className={"section-not-to-print"}>
        <AttendanceOperation
          defaultFacility={queryParams?.currentFacility?.value}
          defaultYear={queryParams?.currentYear}
          defaultMonth={queryParams?.currentMonth}
          facilityOptions={facilityOptions}
          defaultDesiredPickUps={queryParams?.withDesiredPickUps}
          defaultActivity={queryParams?.withActivity}
          defaultCommuter={queryParams?.withCommuters}
          handleDisplayChange={handleDisplayChange}
        />
      </div>

      <div className={"attendance-calendar"} ref={contentRef}>
        <StyledPageCard
          title={t("facilityAttendanceTable", {
            facility_name: queryParams?.currentFacility?.label ?? "",
            year: queryParams?.currentYear ?? "",
            month: queryParams?.currentMonth?.toString().padStart(2, "0") ?? "",
          })}
          extra={
            <StyledMonthPaginationWrapper className={"section-not-to-print"}>
              <Button
                onClick={() => {
                  let year = queryParams?.currentYear
                  let month = queryParams?.currentMonth
                  if (month == 1) {
                    month = 12
                    --year
                  } else {
                    --month
                  }
                  setQueryParams((prev) => {
                    return {
                      ...prev,
                      currentYear: year,
                      currentMonth: month,
                    }
                  })
                  router.push(
                    `?year=${year}&month=${month}&facilityId=${
                      queryParams?.currentFacility?.value || ""
                    }`,
                    undefined,
                    { shallow: true }
                  )
                }}
                className={"left-btn"}
              >
                <DoubleLeftOutlined />
                <span className={"left-btn-label"}>{t("Last month")}</span>
              </Button>
              <Button
                className={"active active-btn"}
                onClick={() => {
                  const today = new Date()
                  const month = today.getMonth() + 1
                  const year = today.getFullYear()
                  setQueryParams((prev) => {
                    return {
                      ...prev,
                      currentYear: year,
                      currentMonth: month,
                    }
                  })
                  router.push(
                    `?year=${year}&month=${month}&facilityId=${
                      queryParams?.currentFacility?.value || ""
                    }`,
                    undefined,
                    { shallow: true }
                  )
                }}
              >
                {t("This month")}
              </Button>
              <Button
                onClick={() => {
                  let year = queryParams?.currentYear
                  let month = queryParams?.currentMonth
                  if (month == 12) {
                    ++year
                    month = 1
                  } else {
                    ++month
                  }
                  setQueryParams((val) => {
                    return {
                      ...val,
                      currentYear: year,
                      currentMonth: month,
                    }
                  })
                  router.push(
                    `?year=${year}&month=${month}&facilityId=${
                      queryParams?.currentFacility?.value || ""
                    }`,
                    undefined,
                    { shallow: true }
                  )
                }}
                className={"right-btn"}
              >
                <span className={"right-btn-label"}>{t("Next month")}</span>
                <DoubleRightOutlined />
              </Button>
            </StyledMonthPaginationWrapper>
          }
        >
          {isScheduleLoading || isStatsLoading ? (
            <>
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
            </>
          ) : (
            <>
              <AttendanceStats stats={stats} />
              <div
                className={"print-button-container section-not-to-print"}
                onTouchEnd={() => {
                  printAttendanceCalendar()
                }}
              >
                <OwnerButton
                  text={t("Print this")}
                  icon={"print"}
                  onClick={() => {
                    printAttendanceCalendar()
                  }}
                />
              </div>
              <div className={"pick-drop-section"}>
                <div className={"section1"}>
                  <OwnerButton text={t("Pick")} className={"pick"} />
                  <OwnerButton text={t("Drop")} className={"drop"} />
                  <span className={"marginLeft5"}>
                    {t(
                      "...Transportation is checked in the facility application"
                    )}
                  </span>
                </div>
                <div className={"section2"}>
                  <OwnerButton text={t("Pick")} className={"pick"} />
                  <OwnerButton text={t("Drop")} className={"drop"} />
                  <span className={"marginLeft5"}>
                    {t(
                      "・・・There is no check in the pick-up service of the facility application"
                    )}
                  </span>
                </div>
              </div>
              <div className={"calendar-wrapper"}>
                <StyledCalendar
                  isMoreGap={width}
                  tileContent={({ activeStartDate, date }) =>
                    date.getMonth() === getMonth(activeStartDate) && (
                      <AttendanceDate
                        className={"user-attendance-date"}
                        daySchedule={getScheduleDetailForDay(date)}
                        activityVisibility={queryParams?.withActivity}
                        commuterVisibility={queryParams?.withCommuters}
                        pickUpDropOffVisibility={
                          queryParams?.withDesiredPickUps
                        }
                        facilityId={queryParams?.currentFacility?.value}
                        disableLink={!hasWriteAccess}
                      />
                    )
                  }
                  formatDay={(_, date) => moment(date).format("DD")}
                  showNavigation={false}
                  locale={"ja"}
                  calendarType={"US"}
                  tileClassName={"tile"}
                  activeStartDate={
                    new Date(
                      queryParams?.currentYear,
                      queryParams?.currentMonth - 1,
                      1
                    )
                  }
                  className={"section-to-print custom-calendar"}
                />
              </div>
              <div className={"print-calendar-container"}></div>
              <div
                className={"print-button-container section-not-to-print mt-10"}
              >
                <OwnerButton
                  text={t("Print this")}
                  icon={"print"}
                  onClick={() => {
                    printAttendanceCalendar()
                  }}
                />
              </div>
              <div
                className={"month-pagination-container section-not-to-print"}
              >
                <BusinessCalendarPagination
                  hideNextButtons={true}
                  currentYear={queryParams?.currentYear}
                  currentMonth={queryParams?.currentMonth}
                  handleMonthChange={(e: any, month: number) => {
                    e.preventDefault()
                    setQueryParams((prev) => {
                      return { ...prev, currentMonth: month }
                    })
                  }}
                  handleYearChange={(e: any, year: number) => {
                    e.preventDefault()
                    setQueryParams((prev) => {
                      return { ...prev, currentYear: year }
                    })
                  }}
                  activeYear={"current"}
                  year={queryParams?.currentYear}
                />
              </div>
            </>
          )}
        </StyledPageCard>
      </div>
    </MainContent>
  )
}
