import { theme } from "@project/shared"
import styled, { css } from "styled-components"

const CLABEL = css`
  min-width: 180px;
  white-space: nowrap;
  @media (max-width: ${theme.breakpoints?.md}) {
    min-width: 100%;
    max-width: 100%;
  }

  svg {
    color: ${theme.gray2};
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
`
export const Wrapper = styled.div`
  .f__name {
    display: flex;
    gap: 5px;
    p {
      margin: 0;
    }
  }

  .filter__wrapper {
    padding: 36px 0;
  }
  .ant-checkbox + span {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding-left: 10px !important;
    }
  }
  .filter__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .content {
      display: flex;
      gap: 1rem;
      .label {
        ${CLABEL}
      }
      .check__items {
        .ant-checkbox-group {
          margin-left: -20px !important;
        }
        .ant-checkbox-wrapper span:last-child {
          text-align: left;
          text-indent: 0px !important;
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      .content {
        flex-direction: column;
        gap: 0rem;
      }
    }
  }
  .calender__container {
    width: 100%;
    margin-top: 5rem;
  }

  @media print {
    .ant-card-head {
      display: none !important;
    }
    .ant-card-bordered {
      border: none;
    }
    .filter__wrapper {
      display: none;
    }
    .content__container {
      .content__table {
        margin-top: 100px;
      }
      .btns__container {
        display: none;
      }
    }
    .calender__container {
      display: none;
    }
  }
`
export const TableContainer = styled.div`
  margin-top: 24px;
  table {
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    page-break-inside: avoid;
    tr {
      overflow: auto;
      th {
        background-color: #dcf4ff;
        @media print {
          background-color: transparent;
        }
        border: 1px solid black;
        @media print {
          border: 2px solid black;
        }
      }
    }
    td {
      min-width: 70px;
      border: 1px solid black;
      @media print {
        border: 2px solid black;
      }
      vertical-align: baseline;
      padding-left: 8px;
    }
  }
  .calendar {
    background-color: #2195cb;
    color: #fff;
    text-align: center;
    padding: 5px;

    @media print {
      display: none;
    }
  }

  .calendar_input_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .calendar_input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .time {
        font-size: 16px;
        font-weight: 500;
      }
      .comment {
        font-size: 14px;
      }
    }
  }
`

export const PrintPortrait = styled.div`
  @media print {
    @page {
      size: auto;
      margin: 0;
    }
  }
`

export const PrintLandscape = styled.div`
  @media print {
    @page {
      size: auto;
      margin: 0;
    }
  }
`
type IProps = {
  printSetting?: string
}
export const ContentContainer = styled.div<IProps>`
  @media print {
    @page {
      size: auto;
      margin-left: 0.3in !important;
      margin-right: 0.3in !important;
      margin-top: 0.25in !important;
      margin-bottom: 0.25in !important;
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  th.ant-table-cell,
  td.ant-table-cell {
    border-right: 1px solid ${theme.gray2} !important;
  }
  .ant-table-wrapper.items-detail-support-table .ant-table-tbody {
    vertical-align: top;
  }
  .items-detail-support-table {
    tr {
      page-break-inside: avoid;
    }
    .priority {
      width: 30px !important;
    }
    .support_guidelines {
      width: 7em !important;
    }
    .max-april {
      max-width: 500px;
      @media print {
        max-width: 20rem !important;
      }
    }
    .max-march {
      max-width: 600px;
      @media print {
        max-width: 20rem !important;
      }
    }
    .title-no-top-border {
      border-top: none !important;
    }
    .title-no-bottom-border {
      border-bottom: none !important;
    }
    .max-10-rem {
      max-width: max(200px, 10rem);
    }
    .max-5-rem {
      max-width: 5rem;
    }
    @media print {
      .max-2-jp-char {
        /* padding left + right + each jp char appx. width * n */
        max-width: calc(8px + 5px * 2) !important;
      }
      .max-3-jp-char {
        max-width: calc(8px + 5px * 3) !important;
      }
      .max-4-jp-char {
        max-width: calc(8px + 5px * 4) !important;
      }
      .max-6-jp-char {
        max-width: calc(8px + 5px * 6) !important;
      }
      .max-7-jp-char {
        max-width: calc(8px + 5px * 7) !important;
      }
      .max-8-jp-char {
        max-width: calc(8px + 5px * 8) !important;
      }
      .max-12-jp-char {
        max-width: calc(8px + 5px * 12) !important;
      }
    }
  }
  .short_label {
    @media print {
      min-width: 90px !important;
    }
  }

  .header-on-print {
    display: none;
    @media print {
      display: flex;
      justify-content: space-between;
    }
    .info {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      .box {
        .first {
          color: ${theme.gray3};
        }
      }
    }
  }
  .btns__container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: fit-content;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0;
      flex-direction: column;
      margin-bottom: 2rem;
      .draft-btn {
        margin-bottom: 1.5rem;
      }
    }
  }

  .ant-table-container {
    @media print {
      overflow: hidden;
    }
  }
  .top__container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    .info {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      .box {
        .first {
          color: ${theme.gray3};
        }
      }
      @media print {
        display: none;
      }
    }
    .no-header-on-print {
      @media print {
        display: none !important;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 2rem;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 0;
    }

    @media print {
      justify-content: flex-end;
    }
  }
  .mark {
    border-bottom: 0 !important;
  }
  .stats {
    display: flex;
    width: 100%;
    &--box {
      display: flex;
      border: 1px solid ${theme.gray2};
      align-items: center;
      width: 100%;
      .box__label {
        display: flex;
        align-items: center;
        background: ${theme.gray};
        border-right: 1px solid ${theme.gray2};
        padding: 18px 9px;
        height: 100%;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 9px;
        border-left: none;
        white-space: nowrap;
        height: 100%;
        width: 100%;
        max-width: 100%;
      }
      @media print {
        .box__label,
        .value {
          padding: 5px !important;
        }
      }
    }
    &--box:not(:first-child) {
      border-left: none;
    }
    @media screen and (max-width: ${theme.breakpoints.xlg}) {
      flex-direction: column;
      &--box {
        border: 1px solid ${theme.gray2} !important;
        &:not(:last-child) {
          border-bottom: none !important;
        }
        .box__label {
          max-width: 150px;
          min-width: 150px;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      &--box {
        flex-direction: column;
        align-items: flex-start;
        border: none !important;
        .box__label {
          max-width: 100% !important;
          min-width: 100%;
          border: 1px solid ${theme.gray2} !important;
        }
      }
    }
  }
  .content__grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    .table-row {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      width: 100%;
      .grid-header {
        padding: 18px;
        width: ${(props) =>
          props?.printSetting === "portrait" ? "170px !important" : "190px"};
        @media screen and (max-width: ${theme.breakpoints.md}) {
          width: 100% !important;
        }
      }
    }
    .table-row:not(:first-child) {
      .grid-header {
        border-top: 0;
      }
      .second-column {
        border-top: 0;
      }
    }
    @media print {
      display: table !important;
      margin-bottom: 0 !important;
      border-bottom: 1px solid ${theme.black} !important;
      border-top: 1px solid ${theme.black} !important;
      .table-row {
        page-break-inside: avoid !important;
        display: table-row !important;
        .grid-header {
          border: 1px solid ${theme.black} !important;
          border-top: 1px solid ${theme.black} !important;
          background: transparent !important;
          display: table-cell !important;
          padding: 8px 10px !important;
          position: relative;
          z-index: 8 !important;
          overflow: hidden !important;
          min-width: 190px !important;
          max-width: 190px !important;
          &:before {
            content: "";
            background: #d2d1d1 !important;
            @media print {
              background: transparent !important;
            }
            position: absolute;
            inset: 1px 1px;
            z-index: -1 !important;
          }
          border-left-width: 2px !important;
        }
        .second-column {
          display: table-cell !important;
          border: 1px solid ${theme.black} !important;
          border-top: 1px solid ${theme.black} !important;
          background: transparent !important;
          padding: 3px 10px !important;
          border-right-width: 2px !important;
          width: ${(prop) =>
            prop?.printSetting === "portrait"
              ? "100% !important"
              : "100% !important"};
        }
      }
      .table-row:nth-child(9) {
        .grid-header,
        .second-column {
          border-bottom: 0 !important;
        }
      }
      .flex-break .ant-card-grid {
        width: ${(prop) =>
          prop?.printSetting === "portrait" ? "200px !important" : undefined};
      }
    }
  }
  .content__table {
    table {
      th,
      td {
        padding: 8px;
        font-size: 14px;
        border-color: ${theme.gray2};
        &.ft-14 {
          font-size: 14px;
        }
        &.pre-line-wrap {
          white-space: pre-line;
          text-align: center;
        }
        &.consideration2 {
          max-width: 120px !important;
        }
      }
      td.align-left-td {
        text-align: left;
      }
      th.hide-head {
        display: none;
      }
      thead {
        tr > th {
          background: transparent !important;
        }
      }
      @media print {
        tbody {
          tr {
            th,
            td {
              font-size: 10px !important;
              padding: 0px 4px !important;
            }
          }
        }
      }
    }
    &.print-ml-neg1 {
      @media print {
        margin-left: -1px;
      }
    }
  }

  .content__footer {
    margin-top: 5px;
    .sign-img {
      height: auto;
      max-height: 26px;
      max-width: 163px;
    }
    @media print {
      margin-top: 0.5rem;
    }
    .agreement__content {
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;

      @media screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media print {
        margin-top: 0.5rem !important;
      }

      .left {
        flex: 1.5;
        .date {
          display: flex;
          gap: 2.5rem;
          align-items: center;

          &--info {
            display: flex;
            gap: 3rem;
            color: ${theme.black2};
          }
        }
        .left__content {
          display: flex;
          align-items: baseline;
          gap: 10px;
          .sign__input {
            width: 300px;
            border: 1px solid ${theme.gray2};
            padding: 12px 5px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            .icon {
              display: flex;
            }
            @media print {
              border: 2px solid black;
              .icon {
                display: none !important;
              }
            }
            .sign__input__container {
              display: flex;
              gap: 12px;
              align-items: center;
              justify-content: center;
            }
          }
          @media (max-width: ${theme.breakpoints.sm}) {
            gap: 0px;
            .sign__input {
              margin-right: 10px;
            }
          }
        }
      }
      &.condense {
        gap: 0rem;
        .left {
          flex: 1;
        }
      }
      .right {
        flex: 1;
        .m__name {
          display: flex;
          gap: 0.4rem;
          align-items: flex-end;
          .child_support {
            padding: 5px 0;
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            min-width: 200px;
            white-space: nowrap;
            &.sign-section {
              justify-content: flex-start;
            }
            p {
              margin: 0;
              &.child-dev-support-manager {
                font-size: 16px;
                font-weight: bold;
              }
            }
            .signatures__formOutlined {
              margin-left: auto;
            }

            @media (max-width: 879px) {
              flex-wrap: wrap;
            }

            @media (max-width: ${theme.breakpoints.sm}) {
              white-space: normal;
            }

            @media print {
              border-bottom-width: 2px;
            }
          }
          div {
            border-bottom: 1px solid ${theme.black};
          }

          @media (max-width: ${theme.breakpoints.sm}) {
            gap: 0;
            .child_support {
              margin-right: 0.4rem;
              gap: 0;
              & > p:first-child {
                margin-right: 1rem;
              }
            }
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        row-gap: 1.5rem;
      }

      @media print {
        align-items: center;
        padding-bottom: 20px;
        .anticon.anticon-form {
          display: none;
        }
      }
    }
    .bottom-btns {
      display: flex;
      justify-content: space-between;
      height: fit-content;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
      margin-top: 2rem;
      @media (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
        gap: 0px;

        .btns__container {
          margin-bottom: 15px;
        }
      }
    }
  }
  @media print {
    gap: 0.3rem !important;
    .btns__container {
      display: none;
    }
    .stats {
      overflow: hidden;
      border: 2px solid ${theme.black} !important;
      border-left: 0 !important;
      .stats--box {
        border: 0 !important;
      }
      .box__label {
        min-width: 80px !important;
        padding: 19px 18px;
        border-color: ${theme.black} !important;
        height: 100% !important;
        background: transparent !important;
        border-left: 2px solid ${theme.black} !important;
        border-right-width: 2px !important;
        position: relative;
        &:before {
          content: " ";
          background-color: ${theme.gray};
          width: 99.5%;
          height: 99.5%;
          position: absolute;
          inset: 0.5%;
          z-index: -1;
        }
      }
      .value {
        height: 99% !important;
        background: transparent !important;
      }
    }
    .content__footer {
      .bottom-btns {
        display: none !important;
      }
    }

    .content__table {
      .items-detail-support-table {
        table {
          border-collapse: collapse !important;
          page-break-inside: avoid !important;
          thead {
            display: table-row-group;
            tr {
              th {
                outline: 0 !important;
                border: 2px solid ${theme.black} !important;
                padding: 3px 4px !important;
              }
              th:not(:last-child) {
                border-right: 0 !important;
              }
            }
          }
          tbody {
            tr {
              td {
                border-color: ${theme.black} !important;
                .ant-table-expanded-row-fixed {
                  max-width: 100% !important;
                  width: 100% !important;
                  border-left: 1px solid ${theme.black} !important;
                  border-width: 2px !important;
                }
              }
              td:first-child {
                position: relative;
                border-left: 2px solid ${theme.black} !important;
                background: transparent !important ;
                z-index: 1;
                &::before {
                  content: " ";
                  position: absolute;
                  inset: 1px;
                  background: ${theme.bgColor2} !important;
                  @media print {
                    background: transparent !important;
                  }
                  z-index: -1;
                }
              }
            }
          }
        }
      }
      @media print {
        .items-detail-support-table {
          table thead tr th {
            border-width: 2px !important;
          }
          table tbody tr td {
            border-width: 2px !important;
            &:first-child {
              border-left-width: 2px !important;
            }
          }
        }
      }
    }
  }

  .title-pre-line {
    white-space: pre-wrap;
    text-align: center;
  }
`

export const RightAlignedDiv = styled.div`
  width: 100%;
  margin: 10px 0;
  text-align: right;
  font-size: 14px;
`

export const AttachedTableContainer = styled.div`
  width: 100%;
  overflow: auto;
  page-break-before: always;
  page-break-after: always;
  .attach-title {
    font-weight: bold;
    font-size: large;
    margin: 10px 0;
  }
  .child-info {
    width: max-content;
    margin-bottom: 10px;
    border: 1px solid ${theme.gray2};
    .gray-background {
      background: ${theme.gray};
      border-right: 1px solid ${theme.gray2};
    }
    .inline-blocks {
      display: inline-block;
      padding: 12px;
    }
  }
  .support-attached-table {
    border-collapse: collapse;
    tr {
      page-break-inside: avoid;
      &.highlight-row,
      th.ant-table-cell,
      td.first-column {
        background-color: ${theme.bgColor};
      }
      td.column-centered {
        text-align: center;
      }
      &.min-height-row td {
        height: max(80px, 8vh) !important;
        &:not(:first-child) {
          text-align: left !important;
        }
      }
    }
    .ant-table-thead .ant-table-cell,
    .ant-table-tbody .ant-table-cell {
      border: 1px solid ${theme.gray2};
      white-space: pre-wrap;
      padding: 16px 8px;
      font-size: 14px;
    }
  }
  @media print {
    .support-attached-table {
      .ant-table-thead .ant-table-cell,
      .ant-table-tbody .ant-table-cell {
        outline: none !important;
        padding: 2px;
        border: 1px solid #000;
        border-color: #000 !important;
        font-size: 10px !important;
        &.first-column {
          border-left: 2px solid #000 !important;
        }
        &.last-column {
          border-right: 2px solid #000 !important;
        }
      }
      thead > tr:first-child > th {
        border-top: 2px solid #000 !important;
      }
      tbody > tr:last-child > td {
        border-bottom: 2px solid #000 !important;
      }
      td.rt-thick-border {
        border-right: 2px solid #000 !important;
      }
    }
    .child-info {
      border: 2px solid #000;
      .gray-background {
        border-right: 2px solid #000;
      }
    }
  }
`
