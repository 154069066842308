import {
  BadgeGroup,
  BadgeGroupShape,
  Gridder,
  GridderShape,
  Griddy,
  InfoGridder,
  InfoGridderShape,
  Modal,
  TopCard,
  theme,
} from "@project/shared"
import { Skeleton, Space, Typography } from "antd"
import { t } from "i18next"
import moment from "moment"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import {
  getAllUsersNeedingCertificateRenewal,
  getAllUsersWhoNeedMonitoring,
} from "../../../services"
import {
  FetchTopPageResponse,
  TopPageResponse,
  fetchTopPageData,
} from "../../../services/topPage"
import { AuthContext, Containeer, getDateStringFromDate } from "../../../utils"
import {
  ProgramProgressRecordPermission,
  RevenueManagementPermission,
} from "../../../utils/PermissionKeys"
import {
  hasPermissionForFacility,
  hasPermissionForMenu,
} from "../../../utils/SidebarUtils"
import { Button } from "../../atoms"

const Wrapper = styled.div`
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.secondaryBg};

  .img-month-tag {
    display: flex;
    margin-left: 0px !important;
    gap: 8px;
    height: 0;
    @media screen and (max-width: 1200px) {
      height: auto;
    }
  }

  @media (max-width: 450px) {
    .ant-badge.ant-badge-not-a-wrapper {
      margin-right: 10px;
    }
  }

  @media (max-width: 450px) {
    .img-month-tag {
      display: flex;
      align-items: center;
    }
  }

  .section {
    > div:last-child {
      margin-bottom: 34px;
    }
    .badge-group-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      @media (max-width: 1201px) {
        flex-direction: column;
        align-items: start;
      }
      > img {
        margin-right: 5px;
      }
      > div {
        margin-left: 50px;
        @media (max-width: 1201px) {
          margin-left: 0;
          margin-top: 10px;
          flex-wrap: wrap;
          .ant-badge {
            margin-bottom: 10px;
          }
        }
      }
      .application-status_icon-with-text {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .badge-group-wrapper:last-child {
      margin-bottom: 0;
    }
    .topPage_facilities {
      h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: ${theme.black};
      }
      .facilities_tabs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 37px 0 38px 0;
        @media (max-width: 546px) {
          margin: 37px 0 28px 0;
          gap: 0px;
          .facilities-btn {
            margin: 0 10px 10px 0;
          }
        }
        .facility {
          background: ${theme.base};
          width: fit-content;
          padding: 9px 16px;
          border-radius: 100px;

          & > span {
            font-weight: 400;
            font-size: 16px;
            line-height: 12px;
          }
        }
      }
    }
  }
  .ant-card-head-title {
    white-space: normal;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`
const GriddyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const MonitoringModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  .grid-list {
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 1.5rem;
    padding-left: 2rem;
  }
`

const applicationStatusByMonths = (
  inquiryCount: number,
  months: Array<any>
): BadgeGroupShape[] => {
  const badgeResponse =
    months?.map((month) => {
      return {
        text: t(month?.progress_name),
        count: month?.total,
      }
    }) ?? []

  return [
    {
      text: t("Inquiry"),
      count: inquiryCount || 0,
    },
  ].concat(badgeResponse)
}

const TOP_PAGE_STORAGE_KEY = "topPagePreviousData"

const TopPage = () => {
  const { t } = useTranslation()
  const { facilities: initialFacilities } = useContext(AuthContext)
  const { permissions, isOwner } = useContext(AuthContext)

  // order main facility as first facility in the list if present
  const facilities = useMemo(() => {
    if (!permissions?.primary_facility_id) return initialFacilities
    const newFacilityOrder = []
    const mainFacility = initialFacilities?.find(
      (fac) => fac?.id == permissions?.primary_facility_id
    )
    if (mainFacility != undefined) {
      newFacilityOrder.push(mainFacility)
    }
    initialFacilities?.forEach((facility) => {
      if (facility?.id != permissions?.primary_facility_id)
        newFacilityOrder.push(facility)
    })
    return newFacilityOrder
  }, [initialFacilities, permissions])

  const getPreviousTopPageData = () => {
    try {
      const previousData = localStorage.getItem(TOP_PAGE_STORAGE_KEY)
      if (!previousData) return null
      const previousDataJSON = JSON.parse(previousData)
      return previousDataJSON
    } catch (e) {
      return null
    }
  }

  const [activeFacility, setActiveFacility] = useState({
    id: facilities ? facilities?.[0]?.id : null,
    facility_name: facilities ? facilities?.[0]?.facility_name_short : "",
  })

  const {
    isLoading,
    isFetching,
    data: response,
    refetch,
  } = useQuery<FetchTopPageResponse, Error>({
    queryKey: ["topPage", activeFacility?.id],
    queryFn: () => fetchTopPageData(activeFacility?.id),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (facilities?.length) {
      const previousTopPageData = getPreviousTopPageData()
      const selectedFacilityId =
        previousTopPageData?.facility_id || facilities[0]?.id
      const selectedFacility = facilities?.find(
        (facility) => facility?.id == selectedFacilityId
      )
      setActiveFacility({
        id: selectedFacility?.id || null,
        facility_name: selectedFacility?.facility_name_short || "",
      })
      refetch()
    }
  }, [facilities])

  useEffect(() => {
    const previousData = getPreviousTopPageData() || {}
    previousData.facility_id = activeFacility?.id
    localStorage.setItem(TOP_PAGE_STORAGE_KEY, JSON.stringify(previousData))
  }, [activeFacility])

  const { isLoading: certificateRenewalLoading, data: certificateRenewalData } =
    useQuery({
      queryKey: [
        "users-needing-certificate-renewal",
        "Infinity",
        {
          service: "1,2",
          children_without_services: "1",
          pageSize: "Infinity",
        },
      ],
      queryFn: () =>
        getAllUsersNeedingCertificateRenewal({
          queries: {
            service: "1,2",
            children_without_services: "1",
            pageSize: "Infinity",
          },
        }),
      select: (response) => {
        return response?.data
          ? response?.data?.map((val) => ({
              ...val,
              key: val?.id,
            }))
          : []
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    })

  const currentDate = new Date()

  const GRIDDER_ITEMS_1: GridderShape[] = [
    {
      title: t("Daily reports"),
      iconPath: "assets/icons/daily-report.svg",
      routes: `/daily-business-report?facility=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "facility_operation_permissions",
        "daily_business_report",
      ],
    },
    {
      title: t("Communication with Parents"),
      iconPath: "assets/icons/communication.svg",
      isActive: false,
      routes: `contact?displayContent=1&facilityIds=${activeFacility?.id}&status=0%2C1%2C2`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "facility_my_page_settings_permissions",
        "notes_from_parents",
      ],
    },
    {
      title: t("Service provision record/Care record\n・Life record"),
      iconPath: "assets/icons/service-record.svg",
      isActive: false,
      routes: `/user-service-provision-result?facilities=${
        activeFacility?.id
      }&date_from=${moment().format("YYYY-MM-DD")}&date_to=${moment().format(
        "YYYY-MM-DD"
      )}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "facility_form_management_permissions",
        "service_provision_form",
      ],
    },
    {
      title: t("Program record"),
      iconPath: "assets/icons/program-record-2_0.svg",
      isActive: false,
      routes: `/program-record?facility=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "common_facility_permissions",
        "master_management_permissions",
        "program_master",
      ],
    },
    {
      title: t("Program progress record"),
      iconPath: "assets/icons/progress-report.svg",
      isActive: false,
      routes: `/program-progress-record-list?year=${moment().format(
        "YYYY"
      )}&month=${moment().format("MM")}&facility_id=${
        activeFacility?.id
      }&currentPage=1`,
      requiresPermission: true,
      permissionKeys: ProgramProgressRecordPermission,
    },
  ]

  const GRIDDER_ITEMS_2: GridderShape[] = [
    {
      title: t("Child care"),
      iconPath: "assets/icons/child-care.svg",
      isActive: false,
      routes: `/child?page=1&contract_month=${moment().format(
        "MM"
      )}&contract_year=${moment().format("YYYY")}&contract_facility_ids=${
        activeFacility?.id
      }`,
      requiresPermission: true,
      permissionKeys: ["output_parent_and_child"],
    },
    {
      title: t("Attendance calendar"),
      iconPath: "assets/icons/attendance-calendar.svg",
      isActive: false,
      routes: `/user-attendance-table?year=${moment().format(
        "YYYY"
      )}&month=${moment().format("MM")}&facilityId=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "automatic_info_facilty_permissions",
        "attendance_management",
      ],
    },
    {
      title: t("Facility application management"),
      iconPath: "assets/icons/facility-application-management.svg",
      isActive: false,
      routes: "/facility-settings",
      requiresPermission: true,
      permissionKeys: [
        "common_facility_permissions",
        "my_page_permissions",
        "facility_application_setting",
      ],
    },
    {
      title: t("Service provision record sheet"),
      iconPath: "assets/icons/service-delivery-sheet.svg",
      isActive: false,
      routes: `/service-provision-record-sheet?year=${moment().format(
        "YYYY"
      )}&month=${moment().format("MM")}&facility_ids=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "automatic_info_facilty_permissions",
        "service_provision_record",
      ],
    },
    {
      title: t("Revenue management"),
      iconPath: "assets/icons/revenue-management.svg",
      isActive: false,
      routes: `/revenue-management?year=${moment().format(
        "YYYY"
      )}&month=${moment().format("MM")}&facilityIds=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "facility_billing_management_permissions",
        "revenue_management",
      ],
    },
  ]

  const GRIDDER_ITEMS_3: GridderShape[] = [
    {
      title: t("Assessment"),
      iconPath: "assets/icons/assessment.svg",
      isActive: false,
      routes: `/assessment?facility_ids=${activeFacility?.id}`,
      requiresPermission: false,
    },
    {
      title: t("Individual support plan (draft)"),
      iconPath: "assets/icons/individual-support-plan-draft.svg",
      isActive: false,
      routes: `/individual-support-draft-plans?facility=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "automatic_info_facilty_permissions",
        "individual_support_plan",
      ],
    },
    {
      title: t("Service personnel meeting \nMinutes of (support meeting)"),
      iconPath: "assets/icons/service-personnel-meeting.svg",
      isActive: false,
      routes: `/minutes?facility_ids=${activeFacility?.id}&page=1`,
      requiresPermission: false,
    },
    {
      title: t("Individual support plan"),
      iconPath: "assets/icons/individual-support-plan.svg",
      isActive: false,
      routes: `/individual-support-plans?facility=${activeFacility?.id}`,
      requiresPermission: true,
      permissionKeys: [
        "user_facility_permissions",
        "automatic_info_facilty_permissions",
        "individual_support_plan",
      ],
    },
    {
      title: t("Monitoring"),
      iconPath: "assets/icons/monitoring.svg",
      isActive: false,
      routes: `/monitoring?facility_ids=${activeFacility?.id}`,
      requiresPermission: false,
    },
  ]

  const infoGridderItems = (data: TopPageResponse): InfoGridderShape[] => [
    {
      title: t("Number of users today"),
      iconPath: "assets/icons/users.svg",
      isActive: false,
      current: data?.usersTodayCount,
      tooltip: t("clickable"),
      cancelWaiting: String(data?.cancelWaitingToday || 0),
      hasLink: true,
      link: `/user-attendance-table/day-list?facilityId=${
        activeFacility?.id
      }&date=${getDateStringFromDate(currentDate)}`,
    },
    {
      title: t("Commuter"),
      iconPath: "assets/icons/commuter.svg",
      isActive: false,
      tooltip: t("clickable"),
      current: data?.commuterCount,
      link: `/staff-attendance/detail?facilityIds=${
        activeFacility?.id
      }&date=${moment().format("YYYY-M-D")}`,
      hasLink: true,
    },
    {
      title: t("Capacity"),
      iconPath: "assets/icons/capacity.svg",
      isActive: false,
      tooltip: t("clickable"),
      current: data?.capacity?.high || 0,
      link: `/facility/system-status/${activeFacility?.id}`,
      hasLink: true,
    },
    {
      title: t("Users who need to update monitoring"),
      iconPath: "assets/icons/users-support.svg",
      isActive: false,
      tooltip: t("clickable"),
      current: data?.usersUpdatePlanCount,
      link: `/users-who-need-monitoring?facility=${activeFacility?.id}`,
      hasLink: true,
      info: "It's been less than a month. Please arrange an update",
    },
    {
      title: t("Number of registered people"),
      iconPath: "assets/icons/registered-people.svg",
      isActive: false,
      tooltip: t("clickable"),
      current: data?.registeredCount,
      link: `/child?contract_facility_ids=${activeFacility?.id}`,
      hasLink: true,
    },
    {
      title: t("Users who need to renew their beneficiary certificate"),
      iconPath: "assets/icons/users-support.svg",
      isActive: false,
      tooltip: t("clickable"),
      current: data?.usersRenewBeneficiaryCount,
      link: `/users-needing-certificate-renewal?facility=${activeFacility?.id}`,
      hasLink: true,
      info: "It's been less than a month. Please arrange an update",
    },
  ]

  const { isLoading: monitoringResponseLoading, data: monitoringData } =
    useQuery({
      queryKey: ["users-who-need-monitoring", "Infinity", {}],
      queryFn: () =>
        getAllUsersWhoNeedMonitoring({
          queries: {
            pageSize: "Infinity",
          },
        }),
      select: (response) => {
        return [
          ...response?.data?.map((val) => ({
            ...val,
            key: val?.id,
          })),
        ]
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    })

  const [isCarModalOpen, setIsCarModalOpen] = useState(false)
  const [isMonitoringModalOpen, setIsMonitoringModalOpen] = useState(false)

  const showRevenueInformation = useMemo(() => {
    if (isOwner) return true
    if (activeFacility?.id) {
      return hasPermissionForFacility(
        permissions,
        RevenueManagementPermission,
        "both",
        activeFacility?.id
      )
    }
    return false
  }, [activeFacility, permissions, isOwner])

  const badgeSkeleton = () =>
    Array.from({ length: 5 }, (_, i) => ({
      index: 1 + i,
    }))?.map((val) => (
      <Space key={val?.index}>
        <Skeleton.Button shape={"round"} active />
        <Skeleton.Avatar active />
      </Space>
    ))
  useEffect(() => {
    const MoniModalAlreadyDisplayed = localStorage.getItem("monitoryModalFlag")
    if (MoniModalAlreadyDisplayed) {
      return
    }

    //Display data from monitoring and benefit renewal in same model
    if (certificateRenewalData?.length > 0 || monitoringData?.length > 0) {
      setIsMonitoringModalOpen(true)
      localStorage.setItem("monitoryModalFlag", "true")
    }
  }, [certificateRenewalLoading, monitoringResponseLoading])

  useEffect(() => {
    const carModalAlreadyDisplayed = localStorage.getItem("carModalFlag")
    if (carModalAlreadyDisplayed) {
      return
    }
    //Display car inspections
    if (response?.data?.carInspections?.length > 0) {
      setIsCarModalOpen(true)
      localStorage.setItem("carModalFlag", "true")
    }
  }, [isFetching])

  const VISIBLE_GRIDDER_ITEMS_1 = GRIDDER_ITEMS_1?.filter(
    (item) =>
      isOwner ||
      !item.requiresPermission ||
      hasPermissionForMenu(permissions, item.permissionKeys)
  )

  const VISIBLE_GRIDDER_ITEMS_2 = GRIDDER_ITEMS_2?.filter(
    (item) =>
      isOwner ||
      !item.requiresPermission ||
      hasPermissionForMenu(permissions, item.permissionKeys)
  )

  const VISIBLE_GRIDDER_ITEMS_3 = GRIDDER_ITEMS_3?.filter(
    (item) =>
      isOwner ||
      !item.requiresPermission ||
      hasPermissionForMenu(permissions, item.permissionKeys)
  )

  return (
    <Wrapper>
      <Modal
        title={t("Notification for car inspection")}
        closeIcon={<span>{t("Close")}</span>}
        open={isCarModalOpen}
        onCancel={() => {
          setIsCarModalOpen(false)
        }}
        width={900}
        maskClosable={false}
        destroyOnClose
        centered
        btnRightMargin={"24px"}
        footer={null}
        noPadding
      >
        <Containeer>
          {response?.data?.carInspections?.map((carInspection: any) => (
            <p key={carInspection.id}>
              {t("{{shuttle}} inspection date for {{number}} is {{date}}.", {
                shuttle: carInspection.car_name,
                number: `${carInspection.car_number_01} ${carInspection.car_number_02} ${carInspection.car_number_03} ${carInspection.car_number_04}`,
                date: moment(carInspection.car_inspection_date).format(
                  "YYYY年MM月DD日"
                ),
              })}
            </p>
          ))}
        </Containeer>
      </Modal>

      {/* Monitoring Modal */}
      <Modal
        title={t("Notification")}
        closeIcon={<span>{t("Close")}</span>}
        open={isMonitoringModalOpen}
        onCancel={() => {
          setIsMonitoringModalOpen(false)
        }}
        width={900}
        maskClosable={false}
        destroyOnClose
        centered
        btnRightMargin={"24px"}
        footer={null}
      >
        <>
          {certificateRenewalData?.length &&
          certificateRenewalData?.length > 0 ? (
            <MonitoringModalContainer>
              <div>
                {t(
                  "List of users who need to update their beneficiary certificate"
                )}
                {":"}
              </div>
              <div>
                <ul className={"grid-list"}>
                  {certificateRenewalData?.map((renewalData) => {
                    return (
                      <li key={renewalData?.id}>{renewalData?.child_name}</li>
                    )
                  })}
                </ul>
              </div>
            </MonitoringModalContainer>
          ) : (
            ""
          )}

          {monitoringData?.length && monitoringData?.length > 0 ? (
            <MonitoringModalContainer>
              <div>
                {t("Users who need to update monitoring")}
                {":"}
              </div>
              <div>
                <ul className={"grid-list"}>
                  {monitoringData?.map((renewalData) => {
                    return (
                      <li key={renewalData?.id}>{renewalData?.child_name}</li>
                    )
                  })}
                </ul>
              </div>
            </MonitoringModalContainer>
          ) : (
            ""
          )}
        </>
      </Modal>

      <div className={"section"}>
        <div className={"topPage_facilities"}>
          <Typography.Title level={4}>
            {t("Management screen top")}
          </Typography.Title>
          <div className={"facilities_tabs"}>
            {facilities?.map((facility) => (
              <Button
                key={facility?.id}
                className={"facilities-btn"}
                text={facility?.facility_name_short}
                shape={"circle"}
                disabled={
                  (isLoading || isFetching) &&
                  activeFacility?.id !== facility?.id
                }
                backgroundColor={
                  facility?.id === activeFacility?.id
                    ? theme.yellow3
                    : theme.base
                }
                onClick={() => setActiveFacility(facility)}
              />
            ))}
          </div>
        </div>
        {showRevenueInformation && (
          <TopCard
            title={t("Revenue management")}
            titleIconPath={"assets/icons/revenue.svg"}
          >
            <GriddyWrapper>
              {isLoading || isFetching ? (
                <Skeleton.Input block active size={"small"} />
              ) : (
                <>
                  <Griddy>
                    {moment().format("YYYY")}
                    {"年"}
                    {moment().format("MM")}
                    {t("Sales as of")}
                    <span className={"display-num"}>
                      {response?.data?.revenue?.salesAmount
                        ? response?.data?.revenue?.salesAmount.toLocaleString(
                            "en"
                          )
                        : 0}
                    </span>
                    {"円"}
                  </Griddy>
                  <Griddy>
                    {t("Usage rate")}
                    <span className={"display-num"}>
                      {response?.data?.revenue?.usageRate || 0}
                    </span>
                    {"%"}
                  </Griddy>
                  <Griddy>
                    {moment().format("YYYY")}
                    {"年"}
                    {moment().format("MM")}
                    {t("Sales forecast for")}
                    <span className={"display-num"}>
                      {response?.data?.revenue?.salesForecastAmount
                        ? response?.data?.revenue?.salesForecastAmount.toLocaleString(
                            "en"
                          )
                        : 0}
                    </span>
                    {"円"}
                  </Griddy>
                </>
              )}
            </GriddyWrapper>
          </TopCard>
        )}
      </div>
      <div className={"section"}>
        <InfoGridder
          items={infoGridderItems(response?.data)}
          title={t("Daily work related")}
          isLoading={isLoading || isFetching}
          activeFacilityId={activeFacility?.id}
        />
      </div>
      <div className={"section"}>
        <TopCard
          title={t("Application status")}
          titleIconPath={"assets/icons/application-status.svg"}
          extra={
            <>
              {t("Year-to-date:")}
              <span className={"display-num"}>
                {response?.data?.applicationStatusYTD ?? 0}
              </span>
              {"人"}
            </>
          }
        >
          <div className={"badge-group-wrapper"}>
            <div className={"img-month-tag"}>
              <p className={"application-status_icon-with-text"}>
                <img src={"assets/icons/calendar.svg"} />
                <span>{t("This month")}</span>
              </p>
            </div>

            {isLoading || isFetching ? (
              badgeSkeleton()
            ) : (
              <BadgeGroup
                items={applicationStatusByMonths(
                  response?.data?.applicationStatus?.current_month_total,
                  response?.data?.applicationStatus?.current_month
                )}
              />
            )}
          </div>
          <div className={"badge-group-wrapper"}>
            <div className={"img-month-tag"}>
              <p className={"application-status_icon-with-text"}>
                <img src={"assets/icons/calendar.svg"} />
                {t("Last month")}
              </p>{" "}
            </div>

            {isLoading || isFetching ? (
              badgeSkeleton()
            ) : (
              <BadgeGroup
                items={applicationStatusByMonths(
                  response?.data?.applicationStatus?.last_month_total,
                  response?.data?.applicationStatus?.last_month
                )}
              />
            )}
          </div>
        </TopCard>
      </div>
      {VISIBLE_GRIDDER_ITEMS_1.length > 0 && (
        <div className={"section"}>
          <Gridder
            items={VISIBLE_GRIDDER_ITEMS_1}
            title={t("Daily work related head")}
          />
        </div>
      )}
      {VISIBLE_GRIDDER_ITEMS_2.length > 0 && (
        <div className={"section"}>
          <Gridder
            items={VISIBLE_GRIDDER_ITEMS_2}
            title={t("Operation management information")}
          />
        </div>
      )}
      {VISIBLE_GRIDDER_ITEMS_3.length > 0 && (
        <div className={"section"}>
          <Gridder
            items={VISIBLE_GRIDDER_ITEMS_3}
            title={t("Related information on individual support plans")}
          />
        </div>
      )}
    </Wrapper>
  )
}

export { TopPage }
