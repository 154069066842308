import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { theme } from "@project/shared"
import { useMutation } from "react-query"
import { useRouter } from "next/router"
import {
  CustomCardContainer,
  Wrapper,
} from "./InstructionQuqlificationList.styles"
import { Button, OwnerButton } from "../../atoms"
import { Qualification } from "./Qualification"
import { WorkCertificates } from "./WorkCertificates"
import { Occupations } from "./Occupations"
import { createInstructorInfo } from "../../../services"
import { toastMsg } from "../../../utils/toastMsg"
import { Popconfirm } from "antd"

export const CustomCard = ({
  title,
  children,
}: {
  title: React.ReactNode
  children: React.ReactNode
}) => {
  return <CustomCardContainer title={title}>{children}</CustomCardContainer>
}
interface IProps {
  newId?: number | null
  defaultQualifications?: any
  defaultWorkCertificates?: any
  defaultOccupations?: any
  refetchInstructors?: any
  staffInputValues?: any
  handleDelete?: (id: string) => void
  isDeleting?: boolean
}

export const InstructionInfoList: React.FC<IProps> = ({
  newId,
  defaultQualifications,
  defaultWorkCertificates,
  defaultOccupations,
  staffInputValues,
  handleDelete,
  isDeleting,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const id = router.query.id as string
  const [qualifications, setQualifications] = useState(
    defaultQualifications || []
  )
  const [workCertificates, setWorkCertificates] = useState(
    defaultWorkCertificates || []
  )
  const [occupations, setOccupations] = useState(defaultOccupations || [])

  const { isLoading: isCreating, mutate: createNew } = useMutation(
    createInstructorInfo,
    {
      onSuccess: () => {
        const msg = id ? t("Updated Successfully") : t("Created Successfully")
        toastMsg("success", t("Instructor master") + msg)
        router.push("/instructors")
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )

  const onInstructorInfoSave = () => {
    const staffId = newId || parseInt(id, 10)
    if (staffInputValues) {
      Object.keys(staffInputValues).forEach(
        (k) =>
          !staffInputValues[k] &&
          staffInputValues[k] !== undefined &&
          delete staffInputValues[k]
      )
    }

    const formValues = {
      staff_id: staffId,
      staff_details: staffId ? "" : JSON.stringify(staffInputValues),
      qualification_info: qualifications?.map(({ ...rest }) => {
        delete rest.tempId
        return {
          ...rest,
          year: rest?.year || null,
          qualification_id: rest?.qualification_id || null,
        }
      }),
      facility_info: occupations?.map(
        ({ one_week_work_pattern, year, ...rest }) => {
          delete rest.tempId
          delete rest?.occupations
          const pattern = {}
          for (const [key, value] of Object.entries(one_week_work_pattern)) {
            pattern[key] = value ? Number(value) : 0
          }
          return {
            ...rest,
            ...pattern,
            year: parseInt(year, 10),
          }
        }
      ),
      experience_info: workCertificates?.map(({ ...rest }) => {
        delete rest.tempId
        return {
          ...rest,
          working_days: Number(rest?.working_days),
        }
      }),
    }
    createNew(formValues)
  }

  const isDisabled = !id && !newId

  return (
    <Wrapper>
      <Qualification
        data={qualifications}
        onSave={(data: any, operation: string) => {
          if (operation === "edit") {
            const x = [...qualifications]
            const index = x?.findIndex((v) => v?.tempId === data?.tempId)
            if (index >= 0) {
              x.splice(index, 1, data)
            }
            setQualifications(x)
          } else {
            setQualifications([{ ...data }, ...qualifications])
          }
        }}
        onDelete={(id) =>
          setQualifications(qualifications?.filter((val) => val?.tempId !== id))
        }
      />

      <WorkCertificates
        data={workCertificates}
        onSave={(data: any, operation: string) => {
          if (operation === "edit") {
            const x = [...workCertificates]
            const index = x?.findIndex((v) => v?.tempId === data?.tempId)

            if (index >= 0) {
              x.splice(index, 1, data)
            }

            setWorkCertificates(x)
          } else {
            setWorkCertificates([{ ...data }, ...workCertificates])
          }
        }}
        onDelete={(id) =>
          setWorkCertificates(
            workCertificates?.filter((val) => val?.tempId !== id)
          )
        }
      />
      <Occupations
        data={occupations}
        onSave={(data: any, operation: string) => {
          if (operation === "edit") {
            const x = [...occupations]
            const index = x?.findIndex((v) => v?.tempId === data?.tempId)

            if (index >= 0) {
              x.splice(index, 1, data)
            }
            setOccupations(x)
          } else {
            setOccupations([{ ...data }, ...occupations])
          }
        }}
        onDelete={(id) =>
          setOccupations(occupations?.filter((val) => val?.tempId !== id))
        }
      />
      <div className={"instructors-button-container"}>
        <div className={"btns"}>
          <Button
            text={t("Cancel")}
            shape={"circle"}
            borderColor={theme.gray2}
            type={"button"}
            disabled={isCreating}
            onClick={() => router.push("/instructors")}
          />
          <OwnerButton
            text={t("Save")}
            shape={"circle"}
            typeOf={"primary"}
            type={"button"}
            onClick={onInstructorInfoSave}
            disabled={isDisabled || isCreating}
            isLoading={isCreating}
          />
        </div>
        {id && (
          <Popconfirm
            title={`${t("Data will be deleted")} ${t(
              'Please press the "delete execution" button if you like.'
            )}`}
            onConfirm={() => handleDelete(id)}
            okText={t("Execute delete")}
            cancelText={t("Cancel")}
            placement={"bottomLeft"}
          >
            <Button
              text={t("Delete")}
              shape={"circle"}
              backgroundColor={theme.red2}
              color={theme.base}
              isLoading={isDeleting}
              className={"delete-instructors"}
            />
          </Popconfirm>
        )}
      </div>
    </Wrapper>
  )
}
