import React, { useContext, useEffect, useMemo, useState } from "react"
import { Skeleton, Typography } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { RightOutlined } from "@ant-design/icons"
import {
  PageCard,
  removeBlankAttributes,
  SelectInput,
  theme,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { AuthContext, getDateString } from "../../../utils"
import { CalenderTable, DatePicker } from "../../molecules"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import styled from "styled-components"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { getAllChildUnpaginated } from "../../../services"
import { useQuery } from "react-query"
import { useRouter } from "next/router"
import { getAllChildAdditionSettingDay } from "../../../services/childAdditionSettingDay"
import ChildAdditionExtra from "./Extra"
import { japaneseAlphaRegex } from "../../../utils/validation"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { AdditionalItemChildPermission } from "../../../utils/PermissionKeys"

const Wrapper = styled.div`
  .child-second {
    margin-left: -50px;
    .child-name-class {
      .ant-select-selector {
        width: 300px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        .ant-select-selector {
          width: 200px;
        }
      }
      @media screen and (max-width: 392px) {
        .ant-select-selector {
          width: 170px;
        }
      }
      @media screen and (max-width: 362px) {
        .ant-select-selector {
          width: auto;
        }
      }
    }
  }
  .options__wrapper {
    padding: 36px 0;
    .options__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .options {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;
        &--text {
          display: flex;
          align-items: center;
          min-width: 6.5rem;
          svg {
            margin-right: 0.5rem;
            color: ${theme.gray2};
            font-size: 14px;
            font-weight: bold;
          }
        }
        &--selector {
          width: 150px;
        }
        &--selector.facility-sel-input-container {
          width: auto;
          @media (max-width: ${theme.breakpoints.sm}) {
            width: 100%;

            .facility-select-input {
              max-width: 200px;
            }
          }
        }
        .year__month--selector {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;
          .year__selector,
          .month__selector {
            display: flex;
            gap: 0.3rem;
            align-items: center;
            .ant-select-selector {
              width: 80px;
            }
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        display: grid;
        gap: 0.5rem;
        .options {
          flex-direction: column;
          align-items: flex-start;
          .year__month--selector {
            align-items: flex-start;
          }
          button {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  .flex-row {
    display: flex;
  }
`
const LOCAL_STORAGE_KEY = "child_additional_items_filter"
const AdditionalItemChildForm = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const params = router.query
  let localSearchObject: any = {}
  try {
    localSearchObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  } catch (e) {
    localSearchObject = {}
  }

  if (!params?.child_id && localSearchObject?.child_id) {
    params.child_id = localSearchObject.child_id
  }
  if (!params?.facility_id && localSearchObject?.facility_id) {
    params.facility_id = localSearchObject.facility_id
  }
  if (!params?.year && localSearchObject?.year) {
    params.year = localSearchObject.year
  }
  if (!params?.month && localSearchObject?.month) {
    params.month = localSearchObject.month
  }

  const { facilities, permissions, isOwner } = useContext(AuthContext)
  const getCurrentfacilityId = () =>
    facilities
      ?.map((el) => el?.id)
      ?.includes(parseInt(String(params?.facility_id)))
      ? params?.facility_id
      : facilities[0]?.id

  const facilityId = getCurrentfacilityId()
  const [updateParams] = useUpdateSearchParams()
  const [childId, setChildId] = useState(null)
  const [sortValue, setSortValue] = useState(null)
  const [childOptions, setChildOptions] = useState(null)
  const [tempSearchParams, setTempSearchParams] = useState<any>({
    facility_id: facilityId,
    child_id: params?.child_id ? params?.child_id : null,
    year: params?.year ? params?.year : moment().format("YYYY"),
    month: params?.month ? params?.month : moment().month() + 1,
  })
  const [searchParams, setSearchParams] = useState<any>({
    facility_id: facilityId,
    child_id: params?.child_id ? params?.child_id : null,
    year: params?.year ? params?.year : moment().format("YYYY"),
    month: params?.month ? params?.month : moment().month() + 1,
  })
  useEffect(() => {
    // facility may get updated based on permission
    const facId = getCurrentfacilityId()
    setTempSearchParams({ ...tempSearchParams, facility_id: facId })
    setSearchParams({ ...searchParams, facility_id: facId })
  }, facilities)
  const facilitiesOptions = facilities?.map((val) => ({
    label: val?.facility_name_short,
    value: val?.id,
  }))

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(searchParams))
  }, [searchParams])

  const {
    isLoading: childAdditionSettingDayLoading,
    data: childAdditionSettingDayData,
  } = useQuery({
    queryKey: ["childAdditionSettingDayData", searchParams],
    queryFn: () =>
      getAllChildAdditionSettingDay({
        page: 1,
        pageSize: "Infinity",
        searchParams,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    select: ({ data }) => {
      data.data_map = {}
      const filtered =
        data?.data?.filter((obj) => obj?.addition_data != 0) || []

      filtered?.map((additionalData) => {
        const dateString = moment(additionalData.date).format("YYYY-MM-DD")
        if (dateString in data?.data_map) {
          data.data_map[dateString][additionalData.id] = additionalData
        } else {
          data.data_map[dateString] = {}
          data.data_map[dateString][additionalData.id] = additionalData
        }
      })
      return data
    },
  })

  const { isLoading, data: childList } = useQuery(
    [
      "fetch-all-child",
      tempSearchParams?.facility_id,
      tempSearchParams?.year,
      tempSearchParams?.month,
    ],
    () =>
      getAllChildUnpaginated({
        facility_id: tempSearchParams?.facility_id,
        contract_year: tempSearchParams?.year,
        contract_month: tempSearchParams?.month,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const OTHER_CHILD_OPTIONS =
    !isLoading &&
    childList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.child_name || "", //Check for Furigana name first
        furigana: item?.child_name_furigana,
      }
    })
  !isLoading &&
    OTHER_CHILD_OPTIONS?.unshift({
      value: "0",
      label: "---",
    })

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)

    const filteredFacilities =
      value == 0
        ? OTHER_CHILD_OPTIONS
        : OTHER_CHILD_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )
    setSortValue(value)
    setChildOptions(filteredFacilities)
  }

  useEffect(() => {
    setChildId("")
    setChildOptions(OTHER_CHILD_OPTIONS)
  }, [isLoading])

  // If there is a child_id in the url, set the child_id in the url as the default value
  useEffect(() => {
    if (childOptions && typeof childOptions === "object") {
      const childId = childOptions?.find(
        (item) => item?.value == params?.child_id
      )?.value
      setChildId(childId)
    }
  }, [params, childOptions])

  const handleEditOnClick = ({ date }) => {
    const formatedDate = moment(date).format("YYYY-MM-DD")
    let url = ``
    if (+searchParams?.child_id) {
      url = `/additional-item-management/child/edit/${searchParams?.child_id}?facility_id=${searchParams?.facility_id}&date=${formatedDate}`
    } else {
      url = `/additional-item-management/child/bulk-edit/${tempSearchParams?.facility_id}?date=${formatedDate}`
    }

    router.push(url)
  }

  const extraInformation = ({ date }) => {
    return <ChildAdditionExtra data={childAdditionSettingDayData} date={date} />
  }

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AdditionalItemChildPermission,
        "write",
        searchParams?.facility_id
      ),
    [isOwner, permissions, searchParams]
  )

  return (
    <Wrapper>
      <div className={"options__wrapper"}>
        <PageCard title={t("Operation options")}>
          <div className={"options__content"}>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Facility")}</Typography.Text>
              </div>
              <div className={"options--selector facility-sel-input-container"}>
                <SelectInput
                  className={
                    "facility-select-input dynamic-dropdown-width min-w-150"
                  }
                  placeholder={t("All")}
                  width={"auto"}
                  dropdownMatchSelectWidth={false}
                  height={40}
                  options={facilitiesOptions}
                  value={parseInt(tempSearchParams?.facility_id)}
                  onChange={(val) =>
                    setTempSearchParams((prev) => ({
                      ...prev,
                      facility_id: val,
                    }))
                  }
                />
              </div>
            </div>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Year month")}</Typography.Text>
              </div>
              <div className={"year__month--selector"}>
                <DatePicker
                  date={
                    moment({
                      year: +tempSearchParams?.year,
                      month: +tempSearchParams?.month - 1,
                    })?.isValid()
                      ? moment({
                          year: +tempSearchParams?.year,
                          month: +tempSearchParams?.month - 1,
                        })
                      : null
                  }
                  style={{
                    height: "40px",
                  }}
                  picker={"month"}
                  format={"YYYY年MM月"}
                  showArrow={true}
                  disabledDate={(current) => {
                    return (
                      (current && current < moment("2021/01/01")) ||
                      (current && current > moment().add(6, "year"))
                    )
                  }}
                  onDateChange={(value) => {
                    setTempSearchParams((prev) => ({
                      ...prev,
                      month: moment(value).format("M"),
                      year: moment(value).format("YYYY"),
                    }))
                  }}
                />
              </div>
            </div>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Child")}</Typography.Text>
              </div>
              <div className={"flex-row"}>
                <div className={"options--selector"}>
                  <SelectInput
                    options={FuriganaAlphabetsOption}
                    width={80}
                    height={40}
                    value={sortValue}
                    placeholder={"---"}
                    onChange={(values) => {
                      handleSort(values)
                      setChildId(null)
                    }}
                  />
                </div>
                <div className={"child-second"}>
                  <SelectInput
                    className={"child-name-class"}
                    options={childOptions}
                    placeholder={t("All")}
                    height={40}
                    value={parseInt(childId) || null}
                    onChange={(values) => {
                      setChildId(values === "0" ? null : values)
                      setTempSearchParams((prev) => ({
                        ...prev,
                        child_id: values === "0" ? null : values,
                      }))
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <OwnerButton
                text={t("Update display ")}
                typeOf={"secondary"}
                onClick={() => {
                  const queries = removeBlankAttributes(tempSearchParams)
                  setSearchParams(tempSearchParams)
                  updateParams(queries, "/additional-item-management/child")
                }}
              />
            </div>
          </div>
        </PageCard>
      </div>

      <PageCard
        title={t(`{{facilityName}} {{date}}${t("’s daily addition items")}`, {
          facilityName: facilities?.find(
            (val) => val?.id === searchParams?.facility_id
          )?.facility_name_short,
          date: getDateString(searchParams?.year, searchParams?.month),
        })}
      >
        {childAdditionSettingDayLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <CalenderTable
            year={parseInt(tempSearchParams?.year, 10)}
            month={parseInt(tempSearchParams?.month, 10)}
            handleDateChange={(state: any) => {
              setTempSearchParams({
                ...tempSearchParams,
                ...state,
              })
              setSearchParams({
                ...tempSearchParams,
                ...state,
              })
            }}
            activeStartDate={
              new Date(parseInt(searchParams?.year), searchParams?.month - 1, 1)
            }
            handleEditOnClick={handleEditOnClick}
            extra={extraInformation}
            hasEditAccess={hasEditAccess}
          />
        )}
      </PageCard>
    </Wrapper>
  )
}

export { AdditionalItemChildForm }
