import moment from "moment"
import { useTranslation } from "react-i18next"
import { Typography } from "antd"
import { BusinessCalendarPagination } from "@project/shared"
import { OwnerButton } from "../../atoms"
import { MainWrapper, CostBurdenCalendarWrapper } from "./CalenderTable.styles"
import { YearPaginateBtn } from "../YearPaginationBtn"
import {
  getAfterDaySchoolServiceFields,
  getChildDevelopmentSupportFields,
} from "../../organisms/AdditionalItemEditBlock/common"
import React from "react"
import { Field } from "../../organisms/AdditionalItemEditBlock/common/fields"

type FieldsMap = {
  [k: string]: { label: string; [k: number]: { label: string } }
}

type AdditionRowProps = {
  fieldsMap: FieldsMap
  addition: any
}

function AdditionRow({ fieldsMap, addition }: AdditionRowProps) {
  if (!addition) {
    return <></>
  }

  const settingMap = fieldsMap[addition.addition_type]
  if (settingMap === undefined) {
    return <></>
  }

  const item = settingMap[addition?.addition_data]
  if (item === undefined) {
    return <></>
  }

  return (
    <>
      <div>{`${settingMap?.label}[${item.label}]`}</div>
      <br />
    </>
  )
}

type CalenderTableProps = {
  activeStartDate: any
  handleDateChange: (state: any) => void
  facility?: number
  year: number
  month: number
  handleEditOnClick?: any
  extra?: any
  businessDayHolidays?: any
  additionalSettingsData?: any
  costBurdenData?: any
  hasEditAccess?: boolean
}

export const CalenderTable: React.FC<CalenderTableProps> = ({
  activeStartDate,
  handleDateChange,
  facility,
  year,
  month,
  handleEditOnClick,
  extra,
  businessDayHolidays,
  additionalSettingsData,
  costBurdenData,
  hasEditAccess = false,
}) => {
  additionalSettingsData = additionalSettingsData || {}
  const { t } = useTranslation()

  const startOfMonth = moment(`${year}-${month}-1`)

  const childDevelopmentSupportFieldsMap = generateFieldsMap(
    getChildDevelopmentSupportFields(startOfMonth)
  )

  const afterDaySchoolServiceFieldsMap = generateFieldsMap(
    getAfterDaySchoolServiceFields(startOfMonth)
  )

  function generateFieldsMap(fields: Field[]): FieldsMap {
    const fieldsMap: {
      [k: string]: { label: string; [k: number]: { label: string } }
    } = {}
    fields.map((item) => {
      fieldsMap[item.name] = {
        label: item.label,
      }
      item.fields.map((fieldItem) => {
        fieldsMap[item.name][fieldItem.value] = {
          label: fieldItem.label,
        }
      })
    })
    return fieldsMap
  }

  const getCostBurdenDayItems = (dateString) => {
    const actualCostName = costBurdenData?.[dateString]
      ? costBurdenData?.[dateString]?.actual_cost?.actual_cost_name
        ? costBurdenData?.[dateString]?.actual_cost?.actual_cost_name
        : costBurdenData?.[dateString]?.actual_cost_detail
      : ""

    if (costBurdenData && costBurdenData?.[dateString]) {
      const costBurdenRecord = costBurdenData?.[dateString].map(
        (dayItem, index) => {
          const actualCostName = dayItem?.actual_cost?.actual_cost_name
            ? dayItem?.actual_cost?.actual_cost_name
            : dayItem?.actual_cost_detail
          return (
            <div key={`${dayItem}-${index}`}>
              <span>{`${actualCostName}: (${dayItem?.user_count} ${t(
                "people"
              )})`}</span>
            </div>
          )
        }
      )
      return costBurdenRecord
    }

    actualCostName ? (
      <div>
        <span>{`${actualCostName}: (${
          costBurdenData?.[dateString]?.user_count
        } ${t("people")})`}</span>
      </div>
    ) : (
      <div>
        <span>{`${actualCostName}: (0 ${t("people")})`}</span>
      </div>
    )
  }

  return (
    <MainWrapper>
      <div className={"top"}>
        <YearPaginateBtn
          prev={t("Prev month")}
          current={t("This month")}
          next={t("Next month")}
          handleClick={(key: string) => {
            if (key === "prev") {
              if (month <= 1) {
                handleDateChange({
                  facility,
                  year: year - 1,
                  month: 12,
                })
              } else {
                handleDateChange({
                  facility,
                  year,
                  month: month - 1,
                })
              }
            } else if (key === "current") {
              handleDateChange({
                facility,
                year: moment().format("YYYY"),
                month: moment().month() + 1,
              })
            } else {
              if (month >= 12) {
                handleDateChange({
                  facility,
                  year: year + 1,
                  month: 1,
                })
              } else {
                handleDateChange({
                  facility,
                  year,
                  month: month + 1,
                })
              }
            }
          }}
        />
      </div>
      <CostBurdenCalendarWrapper
        tileContent={({ date }) => {
          const dateString = moment(date).format("YYYY-MM-DD")
          const isHoliday = businessDayHolidays?.includes(dateString)
          return (
            <div className={"content"}>
              {isHoliday ? (
                <div className={"business-holiday"}>
                  <Typography.Text>{t("Holiday")}</Typography.Text>
                </div>
              ) : (
                <>
                  <div className={"content__child"}>
                    {extra && extra({ date: dateString })}
                    {Object.keys(additionalSettingsData).length
                      ? Object.keys(
                          additionalSettingsData?.[dateString] || {}
                        ).map((key) => {
                          const additions =
                            additionalSettingsData?.[dateString]?.[key]

                          return additions.map((addition, index) => {
                            if (
                              addition.service_type === 1 &&
                              addition.addition_data > 0
                            ) {
                              return (
                                <AdditionRow
                                  key={index}
                                  fieldsMap={afterDaySchoolServiceFieldsMap}
                                  addition={addition}
                                />
                              )
                            } else if (
                              addition.service_type === 2 &&
                              addition.addition_data > 0
                            ) {
                              return (
                                <AdditionRow
                                  key={index}
                                  fieldsMap={childDevelopmentSupportFieldsMap}
                                  addition={addition}
                                />
                              )
                            }
                            return null
                          })
                        })
                      : null}

                    {getCostBurdenDayItems(dateString)}

                    <OwnerButton
                      className={"edit-btn"}
                      text={t("Edit")}
                      typeOf={"secondary"}
                      p={"5px 12px "}
                      onClick={() => {
                        if (handleEditOnClick) {
                          handleEditOnClick({ date })
                        }
                      }}
                      disabled={!hasEditAccess}
                    />
                  </div>
                </>
              )}
            </div>
          )
        }}
        formatDay={(_, date) => moment(date).format("DD")}
        showNavigation={false}
        locale={"ja"}
        calendarType={"US"}
        activeStartDate={activeStartDate}
      />
      <div className={"bottom"}>
        <BusinessCalendarPagination
          hideNextButtons={true}
          currentYear={year}
          year={year}
          currentMonth={month}
          activeYear={"current"}
          handleYearChange={(_, val) =>
            handleDateChange({
              facility,
              year: val,
              month: 1,
            })
          }
          handleMonthChange={(r, val) =>
            handleDateChange({
              facility,
              year,
              month: +val,
            })
          }
          noFb={true}
        />
      </div>
    </MainWrapper>
  )
}
