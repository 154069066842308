const RightArrowIcon = () => (
  <svg
    width={"9"}
    height={"10"}
    viewBox={"0 0 9 10"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={"M1 1L3.85999 5.09023L1 9.21106"}
      stroke={"#444748"}
      strokeWidth={"1.2"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M5 1L7.85999 5.09023L5 9.21106"}
      stroke={"#444748"}
      strokeWidth={"1.2"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)
export default RightArrowIcon
