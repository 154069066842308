import { t } from "i18next"
import { DefaultFormat } from "./DefaultFormat"
import { DefaultProps } from "./types"
import { makeEmptyTdTags } from "../../common/utils"
import { KyotoTable } from "./../../common/body/BodyFormat.style"

type Props = DefaultProps

export function KyotoFormat({ ...props }: Props) {
  return (
    <>
      <DefaultFormat {...props} />
      <div style={{ width: "100%" }}>
        <KyotoTable>
          <tbody className={"kyoto-table-body"}>
            <tr>
              <td>
                {t(
                  "Amount that can be collected for community life support projects: If ① or ② > ③"
                )}
              </td>
              <td>{t("(① or ②-④)")}</td>
              {makeEmptyTdTags(6)}
            </tr>
            <tr>
              <td>
                {t(
                  "Amount that can be collected for community life support projects: In the case of ① or ②≦③"
                )}
              </td>
              <td>{t("(③-④)	")}</td>
              {makeEmptyTdTags(6)}
            </tr>
          </tbody>
        </KyotoTable>
        <KyotoTable style={{ width: "100%" }}>
          <tbody className={"kyoto-table-body"}>
            <tr>
              <td rowSpan={6} style={{ width: 40 }}>
                {t("User burden amount aggregation / adjustment column")}
              </td>
              <td
                className={"solid2 center minW120"}
                style={{ minWidth: 160 }}
                colSpan={1}
                rowSpan={1}
              >
                {t("Item number")}
              </td>
              {makeEmptyTdTags(5, { colSpan: 6 })}
              <td colSpan={6} rowSpan={3}>
                {t("Total")}
              </td>
            </tr>
            <tr>
              <td>{t("Office number")}</td>
              {makeEmptyTdTags(5, { colSpan: 6 })}
            </tr>
            <tr>
              <td>{t("Business name")}</td>
              {makeEmptyTdTags(5, { colSpan: 6 })}
            </tr>
            <tr>
              <td>{t("Total cost")}</td>
              {makeEmptyTdTags(36)}
            </tr>
            <tr>
              <td>{t("User fee")}</td>
              {makeEmptyTdTags(36)}
            </tr>
            <tr>
              <td>{t("User burden after management results")}</td>
              {makeEmptyTdTags(36)}
            </tr>
          </tbody>
        </KyotoTable>
      </div>
    </>
  )
}
