import { useTranslation } from "react-i18next"
import { BillingSectionHeader } from "../../molecules"
import { Collapse, Spin } from "antd"
import styled from "styled-components"
import { UpOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import { getNHIFUsageRecordRewrite } from "../../../services/NHIFbilling"
import moment from "moment"
import { RecordRewriteChildConfirmationTable } from "../../molecules/RecordRewriteChildConfirmationTable"
import Link from "next/link"
import { theme } from "@project/shared"

const AccordionWrapper = styled(Collapse)`
  .ant-collapse-header {
    background: #ffffff;
    .ant-collapse-header-text {
      text-decoration: underline;
    }
  }
  .ant-collapse-content {
    border: none;
  }
  .spin-container {
    display: flex;
    margin: 10px 0;
    justify-content: center;
  }
  .link-container {
    a {
      text-decoration: underline;
      color: ${theme.blue5};
    }
  }
`

interface IBillingConfirmChangedAttendance {
  facility_id: number
  year: number
  month: number
  disabled?: boolean
}

export const BillingConfirmChangedAttendance = ({
  facility_id,
  year,
  month,
  disabled = false,
}: IBillingConfirmChangedAttendance) => {
  const { t } = useTranslation()

  const { data: usageRecordData, isLoading } = useQuery(
    ["get-usage-record-rewrite"],
    () => getNHIFUsageRecordRewrite({ facility: facility_id, year, month }),
    {
      cacheTime: 1,
      keepPreviousData: false,
      retry: 1,
      select: ({ data }) => {
        return data?.usage_record_rewrite?.map((usageChild) => {
          const holidayOption = []
          if (usageChild?.sat_sun_school_holiday_flag) holidayOption.push(1)
          if (usageChild?.public_school_holiday_flag) holidayOption.push(2)
          const thisChild = data?.child_list?.find(
            (child) => child?.child_id == usageChild?.child_id
          )
          let child_name = thisChild?.child_name
          if (thisChild?.furigana_name)
            child_name += ` (${thisChild?.furigana_name})`
          return {
            furigana_sort: "",
            child_id: usageChild?.child_id,
            startDate: usageChild?.temporary_school_closure_period_start
              ? moment(
                  usageChild?.temporary_school_closure_period_start
                ).format("YYYY-MM-DD")
              : null,
            endDate: usageChild?.temporary_school_closure_period_end
              ? moment(usageChild?.temporary_school_closure_period_end).format(
                  "YYYY-MM-DD"
                )
              : null,
            closureDates: Array.isArray(
              usageChild?.temporary_school_closure_dates
            )
              ? usageChild?.temporary_school_closure_dates?.map((date) =>
                  moment(date)?.format("YYYY-MM-DD")
                )
              : [],
            holidayOption,
            daysOfIncreasedUse:
              usageChild?.dates_of_use_increase?.map((date) =>
                moment(date).format("YYYY-MM-DD")
              ) || [],
            extendSupportDays:
              usageChild?.dates_of_extended_support?.map((date) =>
                moment(date).format("YYYY-MM-DD")
              ) || [],
            child_name,
          }
        })
      },
    }
  )

  return (
    <>
      <BillingSectionHeader
        num={1}
        title={t("Confirm changed attendance information")}
        disabled={disabled}
      >
        <div className={"disable-overlay"}></div>
        <AccordionWrapper
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          <Collapse.Panel
            header={t(
              "View revised attendance information for {{month}}/{{year}}",
              {
                month: month?.toString()?.padStart(2, "0"),
                year,
              }
            )}
            key={"1"}
          >
            {isLoading ? (
              <div className={"spin-container"}>
                <Spin size={"large"} />
              </div>
            ) : (
              <>
                {usageRecordData?.map((data) => (
                  <RecordRewriteChildConfirmationTable
                    confirmationData={data}
                    key={data?.child_id}
                  />
                ))}
                <div className={"link-container"}>
                  <Link
                    href={`/national-health-insurance-billing/usage-record-rewrite?year=${year}&month=${month}&facilityId=${facility_id}`}
                  >
                    <a>{t("Click here to set attendance information")}</a>
                  </Link>
                </div>
              </>
            )}
          </Collapse.Panel>
        </AccordionWrapper>
      </BillingSectionHeader>
    </>
  )
}
