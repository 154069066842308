import { useRouter } from "next/router"
import { useContext, useEffect, useRef, useState } from "react"

// Packages
import { useFormik } from "formik"
import { t } from "i18next"

// Components
import { OwnerButton } from "../../atoms"
import { ActualCostBurdenTableBlock, OperationActionBlock } from "./components"

// styled components
import { Container } from "./styles"

// Utils
import { notification } from "antd"
import { useMutation, useQuery } from "react-query"
import {
  bulkdeleteActualCostBurdenUserRecord,
  fetchMonthlyActualCostBurdenRecord,
} from "../../../services/actualCostBurdenRecord"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

// Constants
let activeFacility = null
const getFacilityOptions = (
  facilities: any,
  facilityId?: string | string[]
) => {
  return facilities.map((fac) => {
    const facilityOption = {
      label: fac.facility_name_short,
      value: fac.id,
    }
    if (facilityId && facilityId == fac.id) {
      activeFacility = fac.id
    }
    return facilityOption
  })
}

export const ActualCostBurdenEditPage = ({
  updateCurrentDate,
  updateCurrentFacility,
}: {
  updateCurrentDate: (newDate: Date) => void
  updateCurrentFacility: (newFacility: string) => void
}) => {
  const today = new Date()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const { facilityId, year, month, day } = router.query
  const listRef = useRef<HTMLDivElement>(null)
  const [isButtonClicked, setIsButtonClicked] = useState(false)

  const [updateParams] = useUpdateSearchParams()
  const FACILITY_OPTIONS = getFacilityOptions(facilities, facilityId)
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as number[])
  const [currentSearchQueries, setCurrentSearchQueries] = useState({
    year: year ? +year : today.getFullYear(),
    month: month ? +month : today.getMonth() + 1,
    day: day ? +day : today.getDate(),
    facility: activeFacility
      ? activeFacility
      : FACILITY_OPTIONS.length > 0
      ? FACILITY_OPTIONS[0].value
      : null,
    page: 1,
  })

  const formik = useFormik({
    initialValues: currentSearchQueries,
    onSubmit: () => {
      handleDisplayChange()
    },
  })

  useEffect(() => {
    refetchCostBurden()
  }, [currentSearchQueries])

  // This function is used to update the search queries
  // [NOTE]: when the user changes the currentSearchQueries state changes
  // it should trigger the network request like in other pages.
  const handleDisplayChange = () => {
    const { year, month, day, facility } = formik.values
    updateParams(
      {
        year,
        month,
        day,
        facilityId: facility,
      },
      `/actual-cost-burden`
    )

    setCurrentSearchQueries(formik.values)
  }

  // This function is used to reset the search queries
  // when the user clicks on the reset button
  const handleReset = () => {
    activeFacility = null

    formik.setValues({
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
      facility: FACILITY_OPTIONS[0].value,
      page: 1,
    })
    formik.handleSubmit()
    handleScroll()
  }

  // This function is used to search the  queries
  // when the user clicks on the search button
  const handleSearch = () => {
    formik.handleSubmit()
    handleScroll()
  }

  const {
    isLoading: isFetching,
    data: costBurdenRecord,
    isFetching: isFetchingData,
    refetch: refetchCostBurden,
  } = useQuery({
    queryKey: ["cost-burden-record"],
    queryFn: () =>
      fetchMonthlyActualCostBurdenRecord({
        page: formik.values.page,
        facility_id: formik.values.facility,
        year: formik.values.year,
        month: formik.values.month,
        day: formik.values.day,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    select: (data) => {
      data?.data?.map((actualCostData) => {
        const actualCostname = actualCostData?.actual_cost?.actual_cost_name
          ? actualCostData?.actual_cost?.actual_cost_name
          : actualCostData?.actual_cost_detail
        actualCostData.actual_cost.actual_cost_name = actualCostname
      })
      return data
    },
    onSettled: () => {
      setIsButtonClicked(false)
    },
  })

  // handle input changes in the filter form
  const handleInputChange = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value)
  }

  // handle date changes for table
  // [Note]: This function is used to update the date
  // from the table header
  const handleDateChange = (year: number, month: number, day: number) => {
    formik.setFieldValue("month", month)
    formik.setFieldValue("year", year)
    formik.setFieldValue("day", day)
    setCurrentSearchQueries({
      ...currentSearchQueries,
      year: year,
      month: month,
      day: day,
    })
  }

  // handle page changes for table
  // [Note]: This function is used to update the page number
  // from the table header
  const handlePageChange = (page: number) => {
    formik.setFieldValue("page", page)
    setCurrentSearchQueries({
      ...currentSearchQueries,
      page: page,
    })
  }

  // handle table item selection for table
  // [Note]: This function is used to update the selectedRowKeys
  const handleTableItemSelect = (id: number) => {
    if (selectedRowKeys.includes(id)) {
      setSelectedRowKeys(selectedRowKeys.filter((key) => key !== id))
    } else {
      setSelectedRowKeys([...selectedRowKeys, id])
    }
  }

  const { isLoading: isDeleting, mutate: deleteCostBurdenRecords } =
    useMutation(
      () => bulkdeleteActualCostBurdenUserRecord({ ids: selectedRowKeys }),
      {
        onSuccess: () => {
          notification.success({
            message: `${t("Deleted Successfully")}`,
          })
          setSelectedRowKeys([])
          refetchCostBurden()
        },
        onError: (error?: any) => {
          const msg = error?.data?.error?.message
          notification.error({
            message: msg
              ? t(msg)
              : t("Something went wrong. Please contact administrator."),
          })
        },
      }
    )

  // handle selected items deletion from table.
  const handleSelectedItemsDeletion = () => {
    deleteCostBurdenRecords()
  }

  const handleScroll = () => {
    setIsButtonClicked(true)
  }

  useEffect(() => {
    if (isButtonClicked) {
      scrollToSelectedElement(listRef)
    }
  }, [handleScroll])

  useEffect(() => {
    const { year, month, day, facility } = currentSearchQueries
    if (year || month || day) {
      updateCurrentDate(new Date(year, month - 1, day))
    }
    if (facility) {
      const selectedFacility = FACILITY_OPTIONS.find(
        (fac) => fac.value == facility
      )
      updateCurrentFacility(selectedFacility?.label)
    }
  }, [
    currentSearchQueries.year,
    currentSearchQueries.month,
    currentSearchQueries.day,
    currentSearchQueries.facility,
  ])

  return (
    <Container>
      <OperationActionBlock
        facilityOptions={FACILITY_OPTIONS}
        onReset={handleReset}
        initialValues={formik.values}
        onDisplayChange={handleSearch}
        handleInputChange={handleInputChange}
        handleSelectedItemsDeletion={handleSelectedItemsDeletion}
        isDeleting={isDeleting}
        isDeleteBtnDisable={selectedRowKeys?.length <= 0}
        currentDate={
          new Date(
            currentSearchQueries.year,
            currentSearchQueries.month - 1,
            currentSearchQueries.day
          )
        }
        currentFacilityId={currentSearchQueries.facility}
      />

      <div className={"middle-back-btn"}>
        <OwnerButton
          onClick={() => {
            router.push("/actual-cost-burden-table")
          }}
          typeOf={"draft"}
          text={"<< " + t("Back")}
        />
      </div>
      <div ref={listRef}>
        <ActualCostBurdenTableBlock
          currentDay={currentSearchQueries.day ?? formik.values.day}
          currentYear={currentSearchQueries.year ?? formik.values.year}
          currentPage={formik.values.page}
          currentMonth={currentSearchQueries.month ?? formik.values.month}
          selectedRowKeys={selectedRowKeys}
          handlePageChange={handlePageChange}
          handleDateChange={handleDateChange}
          handleTableItemSelect={handleTableItemSelect}
          costBurdenRecord={costBurdenRecord}
          isLoading={isFetching || isFetchingData}
          currentFacilityName={
            FACILITY_OPTIONS.find(
              (fac) => fac.value == currentSearchQueries.facility
            )?.label
          }
          currentFacilityId={currentSearchQueries.facility}
        />
      </div>
    </Container>
  )
}
