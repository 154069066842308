// Packages
import moment from "moment"
import { t } from "i18next"
import { useRouter } from "next/router"

// Components
import { Checkbox } from "@project/shared"
import { OwnerButton } from "../../../atoms"

// types
import { UseTableProps } from "../types"
import { useContext } from "react"
import { AuthContext } from "../../../../utils"

export const useTable = ({
  currentDay,
  currentYear,
  currentMonth,
  currentFacilityId,
  handleTableItemSelect,
  selectedRowKeys,
}: UseTableProps) => {
  const router = useRouter()
  const { companyName } = useContext(AuthContext)

  const columns = [
    {
      title: t("delete"),
      key: "delete",
      width: 89,
      render: (_, record: any) => {
        return (
          <Checkbox
            className={"edit-checkbox"}
            checked={selectedRowKeys.includes(record.id)}
            onChange={() => {
              handleTableItemSelect(record.id)
            }}
          />
        )
      },
    },

    {
      title: t("Detail"),
      dataIndex: "detail",
      key: "detail",
      width: 122,
      render: (_, record: any) => {
        return (
          <OwnerButton
            typeOf={"link"}
            text={t("Detail")}
            className={"detail-button-link"}
            onClick={() => {
              router.push(
                `/actual-cost-burden/${record.id}/details?year=${currentYear}&month=${currentMonth}&day=${currentDay}&facilityId=${currentFacilityId}`
              )
            }}
          />
        )
      },
    },
    {
      title: t("Actual cost item name"),
      dataIndex: "actual_cost",
      key: "actual_cost",
      width: 171,
      render: (val: any) => {
        return <p className={"actual-cost-item-name"}>{val.actual_cost_name}</p>
      },
    },
    {
      title: t("No. of users"),
      dataIndex: "user_count",
      key: "user_count",
      width: 261,
      render: (val: string) => {
        return <p className={"no-of-users"}>{val}</p>
      },
    },
    {
      title: t("Last update"),
      dataIndex: "updated_datetime",
      key: "updated_datetime",
      width: 541,
      render: (val: string) => {
        return (
          <p className={"last-update"}>
            {val ? moment(val).format("YYYY年MM月DD日 HH:mm:ss") : val} &nbsp;
            {companyName}
          </p>
        )
      },
    },
  ]

  return {
    columns,
  }
}
