// packages
import { FormikProps } from "formik"

// styled components
import { FlexContainer } from "./common/formFields.styles"

// components
import { FieldWrapper, GetField } from "./common/formFields"

// types
import { FormFieldsProp } from "./types"

const FormFieldsGen = ({
  item,
  index: i,
  formik,
}: {
  item: FormFieldsProp
  index: number
  formik?: FormikProps<{
    [key: string]: any
  }>
  labelClassName?: string
  fieldClassName?: string
}) => {
  return (
    <FieldWrapper
      key={item.fieldLabel}
      label={item.fieldLabel}
      required={item.required}
      isFirstChild={i === 0 ? true : false}
      isRowFlexed={
        item.fieldLabel === "Children with disabilities" ? false : true
      }
      labelClassName={item.labelClassName}
      fieldClassName={item.fieldClassName}
    >
      <>
        <FlexContainer
          className={"fieldGen-flex-container"}
          $isRowFlexed={item.isRowFlexed}
        >
          {item?.fields?.map((field) => {
            const {
              name,
              label,
              width,
              require,
              infoNoti,
              fieldType,
              className,
              isDisabled,
              helperText,
              placeholder,
              initialValue,
            } = field
            return (
              <GetField // ! IMP: GetField is a component where switch cases for fieldType is checked and particular field is returned as defined in formField function.
                name={name}
                label={label}
                width={width}
                formik={formik}
                key={field.name}
                require={require}
                infoNoti={infoNoti}
                fieldType={fieldType}
                className={className}
                helperText={helperText}
                isDisabled={isDisabled}
                placeholder={placeholder}
                initialValue={initialValue}
              />
            )
          })}
        </FlexContainer>
      </>
    </FieldWrapper>
  )
}

export default FormFieldsGen
