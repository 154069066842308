const CalendarIconLight = () => (
  <svg
    width={"14"}
    height={"14"}
    viewBox={"0 0 14 14"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M4.51248 2.64258H2.02164C1.67585 2.64258 1.40039 2.92404 1.40039 3.26415V11.9779C1.40039 12.3238 1.68171 12.5994 2.02164 12.5994H11.9791C12.3249 12.5994 12.6004 12.318 12.6004 11.9779V3.26415C12.6004 2.91818 12.3191 2.64258 11.9791 2.64258H11.3579"
      }
      stroke={"#191919"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M4.51172 1.40039V3.89254"}
      stroke={"#191919"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M9.48828 1.40039V3.89254"}
      stroke={"#191919"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.37695 2.64258H9.48904"}
      stroke={"#191919"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M1.64648 5.70508H11.0238"}
      stroke={"#191919"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)
export default CalendarIconLight
