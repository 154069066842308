// packages
import { t } from "i18next"
import * as yup from "yup"
import { useFormik } from "formik"

// components
import { OwnerButton } from "../../../atoms"
import FormFieldsGen from "../../../molecules/FormFields"

// styled components
import { StyledFooter, StyledModal } from "../styles"

// constants
import { RegisterUserFormSchema } from "../constants"

// types
import { RegisterUserModalProps } from "../types"
import { useEffect, useState } from "react"

// schema validation
const validationSchema = yup.object().shape({
  child_id: yup.string().required(t("Required")),
  burden_record_id: yup.string().required(t("Required")),
  cost: yup
    .number()
    .required(t("Required"))
    .min(10, t("Min 10"))
    .max(999999, t("(Max 6 num)"))
    .typeError(t("Required")),
})

const Footer = ({
  onSubmit,
  loading,
}: {
  onSubmit: () => void
  loading: boolean
}) => {
  return (
    <StyledFooter>
      <OwnerButton
        className={"Submit-button"}
        typeOf={"primary"}
        text={t("Save")}
        isLoading={loading}
        onClick={onSubmit}
      />
    </StyledFooter>
  )
}

export const RegisterUserModel = ({
  onSave,
  onCancel,
  isVisible,
  handleSort,
  childOptions,
  burdenRecordId,
  selectedUser,
  actualItemDefaultCost = 0,
}: RegisterUserModalProps) => {
  if (selectedUser?.isEdit) {
    childOptions = [
      {
        label: selectedUser.child_name,
        value: selectedUser.child_id,
      },
    ]
  }

  const formik = useFormik({
    initialValues: {
      child_id: selectedUser?.child_id || "",
      burden_record_id: parseInt(burdenRecordId),
      cost: selectedUser?.cost || actualItemDefaultCost,
      id: selectedUser?.id || null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true)
      onSave(values)
    },
  })
  useEffect(() => {
    if (!isVisible) {
      formik.resetForm()
      setLoading(false)
    }
  }, [isVisible])

  const [loading, setLoading] = useState(false)

  return (
    <StyledModal
      className={"register-user-modal"}
      title={t("Register user")}
      open={isVisible}
      onCancel={onCancel}
      onOk={onSave}
      footer={
        <Footer onSubmit={() => formik.handleSubmit()} loading={loading} />
      }
      destroyOnClose={true}
    >
      {RegisterUserFormSchema({
        formik,
        handleSort,
        childOptions,
      }).map((item, i) => {
        if (item.fieldLabel === "Child name") {
          item.fields[0].isDisabled = selectedUser?.isEdit ? true : false
          item.fields[1].isDisabled = selectedUser?.isEdit ? true : false
        }

        return (
          <div className={"field-group"} key={item.fieldLabel}>
            <FormFieldsGen
              index={i}
              item={item}
              formik={formik}
              key={item.fieldLabel}
            />
          </div>
        )
      })}
    </StyledModal>
  )
}
