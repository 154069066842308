import React, { useState, useContext, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useQuery, useQueryClient } from "react-query"
import moment from "moment"
import {
  PageCard,
  Button as SharedButton,
  WORK_EXPERIENCE,
  WORK_TYPES,
  getLocalstorageItem,
  setItemToLocalstorage,
} from "@project/shared"
import { OwnerTable } from "../../molecules"
import { ContentWrapper, TableContentSection } from "./InstructorContent.styles"
import { FilterInstructor } from "./FilterInstructor"
import { fetchInstructors } from "../../../services"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { generateURLString } from "../../../utils/generateURLString"
import { InstructorMasterPermission } from "../../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"
import { pageSize } from "../../../constants/pageSize"

export const InstructorContent = () => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const queryClient = useQueryClient()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const {
    page: currentPage,
    keyword,
    joining_date,
    retirement_date,
    facility,
    occupation,
  } = router?.query as any
  const { permissions, isOwner } = useContext(AuthContext)
  const storedData = getLocalstorageItem("instructors", "facility")
  const hasWriteAccess =
    isOwner ||
    hasPermissionForMenu(permissions, InstructorMasterPermission, "write")

  const firstItemRef = useRef(null)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1
  )
  const defaultSelectedFacility = [0]

  const [searchParams, setSearchParams] = useState<any>({
    keyword: keyword || "",
    joining_date: joining_date || "",
    retirement_date: retirement_date || "",
    facility: facility || storedData || defaultSelectedFacility || "",
    occupation: occupation || "",
  })

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["instructors", page, searchParams],
    queryFn: () => fetchInstructors({ page, pageSize, searchParams }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  const FilterOutUniqueData = (array, key) => {
    const d = Array.from(
      new Map(array.map((item) => [item[key], item])).values()
    )
    return d
  }
  const columns = [
    {
      title: t("Edit"),
      dataIndex: "id",
      align: "center",
      key: "id",
      width: 40,
      render: (id) => {
        return (
          <SharedButton
            type={"link"}
            bordercolor={"none"}
            padding={"5px"}
            onClick={() => {
              queryClient.invalidateQueries({
                queryKey: ["edit_instructor"],
              })
              queryClient.invalidateQueries({
                queryKey: ["instructors"],
              })
            }}
            className={!hasWriteAccess ? "disabled-edit" : ""}
            disabled={!hasWriteAccess}
          >
            <Link
              href={generateURLString(
                `${router?.pathname}/edit/${id}`,
                router?.query as any
              )}
              as={`${router?.pathname}/edit/${id}`}
              passHref
            >
              <a style={{ textDecoration: "underline" }}>{t("Edit")}</a>
            </Link>
          </SharedButton>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Instructor name")}</span>,
      dataIndex: "staff_name",
      key: "staff_name",
      align: "center",
    },
    {
      title: <span className={"col-title"}>{t("Work style")}</span>,
      key: "work_style",
      align: "center",
      render: (row) => {
        const facilitiesData = FilterOutUniqueData(
          row?.staff_facility,
          "workstyle_type"
        )
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {facilitiesData?.map((work: any, i) => (
              <span key={i}>
                {
                  WORK_TYPES?.find((val) => val?.value === work?.workstyle_type)
                    ?.label
                }
                {facilitiesData.length > 1 &&
                  facilitiesData?.length - 1 !== i && <em>{", "}</em>}
              </span>
            ))}
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Affiliated facility")}</span>,
      dataIndex: "staff_facility",
      key: "staff_facility",
      align: "center",
      render: (row) => {
        const facilitiesData = FilterOutUniqueData(row, "facility_id")

        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {facilitiesData?.map((val: any, i) => {
              const facilityName =
                (facilities &&
                  facilities.find((item) => item.id == val?.facility_id)
                    ?.facility_name_short) ||
                ""

              return (
                // facility should exists
                facilityName && (
                  <span key={i}>
                    {facilityName}
                    {facilitiesData?.length > 1 &&
                      facilitiesData?.length - 1 !== i &&
                      facilityName && <em>{", "}</em>}
                  </span>
                )
              )
            })}
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Occupation")}</span>,
      dataIndex: "staff_facility",
      key: "staff_facility",
      align: "center",
      render: (row) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {row?.map((val, i) => {
              let experienceYearLabel =
                val?.year > 2024 || (val?.year == 2024 && val?.month >= 4)
                  ? !val?.experience_year
                    ? t("No setting ")
                    : WORK_EXPERIENCE?.find(
                        (we) => we.value == val?.experience_year
                      )?.label
                  : ""

              if (experienceYearLabel) {
                experienceYearLabel = `(${t(
                  "Child welfare work experience"
                )}: ${experienceYearLabel})`
              }
              return (
                <span key={i}>
                  {val?.occupation?.occupation_name} {experienceYearLabel}
                  {val?.occupation2?.occupation_name
                    ? `, ${val?.occupation2?.occupation_name}${experienceYearLabel}`
                    : ""}
                  {row?.length > 1 && row?.length - 1 !== i && <em>{", "}</em>}
                </span>
              )
            })}
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Joined date")}</span>,
      dataIndex: "joining_date",
      key: "joining_date",
      align: "center",
      render: (row) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(row).isValid() && moment(row).format("YYYY年MM月DD日")}
        </span>
      ),
    },
    {
      title: <span className={"col-title"}>{t("Retired date")}</span>,
      dataIndex: "retirement_date",
      key: "retirement_date",
      align: "center",
      render: (row) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(row).isValid() && moment(row).format("YYYY年MM月DD日")}
        </span>
      ),
    },
  ]

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["edit_instructors"],
    })
  }, [])
  useEffect(() => {
    if (buttonClicked === true) {
      scrollToSelectedElement(firstItemRef)
    }
  }, [buttonClicked, searchParams, page])

  return (
    <ContentWrapper>
      <FilterInstructor
        isLoading={isLoading || isFetching}
        defaultValues={{
          keyword,
          joining_date: joining_date ? joining_date?.split("_") : "",
          retirement_date: retirement_date ? retirement_date?.split("_") : "",
          facility: facility
            ? facility?.split(",")?.map(Number)
            : storedData?.split(",")?.map(Number) ||
              defaultSelectedFacility ||
              [],
          occupation: occupation ? occupation.split(",")?.map(Number) : [],
          page,
        }}
        setSearchParams={(values, action) => {
          const queries = action === "reset" ? {} : { ...values }
          setPage(1)
          setSearchParams({ ...values })
          updateParams(queries, "/instructors")
          setItemToLocalstorage("instructors", {
            facility: values?.facility || null,
          })
        }}
        setButtonClicked={setButtonClicked}
        hasWriteAccess={hasWriteAccess}
      />
      <PageCard title={t("List")}>
        <TableContentSection ref={firstItemRef}>
          <OwnerTable
            currentPage={page}
            total={response?.count}
            pageSize={pageSize}
            columns={columns}
            dataSource={response?.data}
            scroll={{ x: "max-content" }}
            handlePaginationChange={(val) => {
              const queries = {
                page: val,
                ...searchParams,
              }
              setPage(val)
              setButtonClicked(true)
              updateParams(queries, "/instructors")
            }}
            loading={isLoading || isFetching}
            className={"always-present-scrollbar"}
          />
        </TableContentSection>
      </PageCard>
    </ContentWrapper>
  )
}
