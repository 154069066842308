import styled, { css } from "styled-components"
import { theme } from "@project/shared"

export const RewardCalculationContent = styled.div`
  .remuneration_tab_top_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.gray2};
    padding: 5px 0;
    @media (max-width: ${theme.breakpoints.sm}) {
      display: block;
      border-bottom: 0 !important;
      flex-direction: column;
      .top_title {
        font-size: 14px;
        border-bottom: 1px solid ${theme.gray2};
        padding: 0 0 5px 0;
      }
      .pagination__cta {
        display: flex;
        justify-content: flex-end !important;
        align-items: flex-start !important;
        margin-top: 0.5rem;
      }
    }
  }
  .tab_top_btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
  }

  .tabs_container {
    width: 100%;
    .ant-tabs-nav {
      margin: 0px !important;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        @media (max-width: ${theme.breakpoints.sm}) {
          overflow: auto;
        }
      }
      .ant-tabs-tab {
        margin-left: 0 !important;
        border: 1px solid ${theme.gray2} !important;
        border-radius: 10px 10px 0px 0px !important;
        .ant-tabs-tab-btn {
          @media (max-width: ${theme.breakpoints.md}) {
            width: 100%;
            overflow-wrap: break-word;
            font-size: 11px;
          }
        }
        @media (max-width: ${theme.breakpoints.sm}) {
          margin-left: 0 !important;
          margin-top: 0.2rem !important;
        }
      }
      .ant-tabs-tab-active {
        border-bottom: 3px solid ${theme.blue5} !important;
        .ant-tabs-tab-btn {
          color: ${theme.black} !important;
        }
      }
    }
    .ant-tabs-content-holder {
      border: 1px solid ${theme.gray2} !important;
    }
    .tab_table {
      padding: 30px 0 30px 30px;
      @media (max-width: ${theme.breakpoints.lg}) {
        padding: 10px 5px 30px 5px;
      }
    }
  }
`
const hasData = css`
  display: flex;
  div {
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      border-right: 1px solid ${theme.gray2};
    }
    /* &:last-child {
      max-width: 66px;
    } */
    padding: 10px;
  }
`

export const TableContainer = styled.div`
  display: flex;
  position: relative;
  background: red;
  width: 100%;
  .overlay {
    background: ${theme.rgba.blue};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-content {
    width: 100%;

    overflow-x: scroll;
    white-space: nowrap;

    table {
      border-collapse: collapse;
      border: 1px solid ${theme.gray2};
      background: #f3f3f3;
      width: 100%;
      tr > th {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: ${theme.black};
        max-width: 70px;
      }
      thead > tr > td {
        padding: 5px;
        font-size: 14px;
        line-height: 1.5;
        border: 1px solid ${theme.gray2};
      }
      thead {
        .main_header {
          background: #f3f3f3;

          th {
            border-right: 1px solid ${theme.gray2};
            border: 1px solid ${theme.gray2};
            padding: 5px;
            word-break: break-all;
            white-space: pre-line;
          }
          th:first-child {
            width: 20px;
          }
        }

        .sub_header {
          .content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 10px;
            .users,
            .children_of,
            .percentage_of {
              display: flex;
              flex-direction: column;
              gap: 8px;
              div {
                background: #4da2f8;
                width: fit-content;
                color: ${theme.base};
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 8px;
                border-radius: 5px;
                font-size: 12px;
              }
              .value {
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: ${theme.black};
              }
            }
            .percentage_of {
              .box {
                flex-direction: column;
                div:last-child {
                  margin-top: -12px;
                }
              }
            }
          }
        }
      }
      tbody {
        .items_content {
          background: rgba(7, 130, 200, 0.1);
          .items_content-th {
            word-break: break-all;
          }
          td {
            /* background: red; */
            border-left: 1px solid ${theme.gray2};
            .items {
              display: flex;
              justify-content: space-between;
              .item_text {
                width: 100%;
                word-break: break-all;
                white-space: pre-line;
                padding: 10px;
                text-align: center;
                &:not(:last-child) {
                  border-right: 1px solid ${theme.gray2};
                }
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
        .items_data {
          border-bottom: 1px solid ${theme.gray2};
          .items_data-th {
            word-break: break-all;
          }
          .items_data-the {
            max-width: fit-content !important;
            width: fit-content !important;
            padding: 0px 5px !important;
          }
          td {
            border-left: 1px solid ${theme.gray2};
            .data {
              background: "trasparent";
              ${hasData}
            }
            .has_data {
              background: ${theme.base};
              ${hasData}
            }
          }
        }
      }
    }

    /* show scrollbar all time*/
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #fafafa;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      /* color of the scroll thumb */
      border-radius: 20px;
      /* roundness of the scroll thumb */
      border: 3px solid #fafafa;
      /* creates padding around scroll thumb */
    }
    /* show scrollbar all time*/
  }
  .no-data {
    flex: auto;
    border: 1px solid ${theme.gray2};
    margin-right: 36px;
  }
`
