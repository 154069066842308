// pacakges
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const Container = styled.div`
  .middle-back-btn {
    margin: 30px 0;
  }

  .divider {
    margin: 20px 0;
  }

  .underline {
    text-decoration: underline;
  }

  .back-btn {
    margin: 30px 0;
  }

  .btn-has-icon {
    padding: 6px 20px;
  }

  .btn-has-no-icon {
    padding: 7px 20px;
  }

  .common-select-input {
    .ant-select-selector {
      padding: 4.2px 14px !important;
      height: auto !important;
    }
  }

  p,
  ul,
  h4 {
    margin-bottom: 0 !important;
  }
`

export const FilterOpContainer = styled.div`
  .operation-rounded-container {
    margin-top: 30px;

    .extra-notice-container {
      margin-top: 24px;
    }

    .form-group {
      display: grid;
      margin-top: 24px;
      gap: 24px;
      .facility-output-field-group {
        align-items: flex-start;
        .facility-output-input-content {
          flex-direction: column;
          row-gap: 20px;
          align-items: flex-start;
        }
        .btn-group {
          button {
            padding: 6px 20px;
          }
          button:last-child {
            padding: 7px 20px;
          }
        }
      }

      .facility-select {
        width: 100%;
        max-width: 200px;
        min-width: 150px;
        .ant-select-selector {
          padding: 4.2px 14px;
          height: auto !important;
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`
export const NumbOfOutputCounter = styled.div`
  & > span {
    font-weight: 700;
    font-size: 16px;
  }
  color: ${theme.black};
  font-weight: 500px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 12px;
  border: 1px solid ${theme.gray2};
`

export const RequiredInfoBoxContainer = styled.div`
  padding: 24px 30px;
  border-radius: 12px;
  border: 1px solid ${theme.gray2};
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f1f1f1;
  align-items: flex-start;
  .title {
    font-size: 16px;
    color: ${theme.black};
    font-weight: normal;
  }

  .form-field-container {
    display: flex;
    column-gap: 48px;
    row-gap: 20px;
    flex-wrap: wrap;
    align-items: center;

    .form-label-and-field {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;

      .label {
        font-size: 16px;
        color: ${theme.black};
      }

      .form-field {
        &.withdraw-date-fields {
          gap: 5px;
          .month-select-field,
          .day-select-field {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }

        .ant-checkbox-wrapper {
          white-space: pre-wrap;
        }
      }
    }
  }
`

export const BankFormatOutputContainer = styled.div`
  .fieldGen-field-wrapper div.label {
    min-width: 100px;
  }

  .operation-rounded-container {
    .all-bank-format-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;

      .first-notice-box-description {
        font-weight: 400;
      }

      .third-notice-box-container {
        .notice-toggle-btn {
          cursor: pointer;

          .down-facing-arrow {
            &.active .red-down-facing-arrow {
              transform: rotate(180deg);
            }
          }
        }

        .bank-info-list-container {
          display: grid;
          grid-template-rows: 0fr;
          color: ${theme.black};
          transition: grid-template-rows 0.3s ease-in-out;
          .bank-info-list_content {
            overflow: hidden;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            .link {
              display: inline-block;
              color: ${theme.red2};
              margin-top: 24px;
            }
          }

          &.show {
            margin-top: 24px;
            grid-template-rows: 1fr;
            .bank-info-list_content {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`

export const AllTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .individual-table {
    .quote-text {
      margin-bottom: 13px !important;
    }
    .table-container {
      .ant-table-container {
        border-color: ${theme.gray2};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        overflow: hidden;
        table {
          border-color: ${theme.gray2};

          thead.ant-table-thead {
            .ant-table-column-sorter {
              display: none;
            }
            tr th {
              &:before {
                background-color: ${theme.black2} !important;
              }

              background-color: ${theme.gray2};
              border-color: ${theme.gray2};

              &.ant-table-selection-column {
                &:before {
                  position: absolute;
                  content: "";
                  inset: 0;
                  width: 1px;
                  height: 1.6em;
                  margin-left: auto;
                  margin-top: auto;
                  margin-bottom: auto;
                  background-color: rgba(0, 0, 0, 0.06);
                  background-color: ${theme.black2} !important;
                }
              }
            }
          }
          tbody.ant-table-tbody {
            tr {
              td {
                border-color: ${theme.gray2};

                &.ant-table-selection-column {
                  /* Stylizing antd checkbox accordingly */
                  .ant-checkbox-wrapper {
                    margin-left: auto !important;
                    margin-right: auto;
                    &.ant-checkbox-wrapper-disabled {
                      .ant-checkbox {
                        .ant-checkbox-inner {
                          background: ${theme.bgColor2};
                        }
                      }
                    }
                  }
                }
              }
              &.ant-table-row-selected {
                td {
                  background-color: ${theme.yellow4};
                }
              }
              &.disabled-row {
                td {
                  background-color: ${theme.bgColor2};
                }
              }
            }
          }
        }
      }
    }
  }
`
