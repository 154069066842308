import { API } from "@project/shared"

export interface IReturnProvisionalCity {
  count?: number
  data?: any
}

interface IProvisionalCityParam {
  page?: string
  pageSize?: string
}

export const fetchProvisionalCity = async ({
  queryKey,
}): Promise<IReturnProvisionalCity> => {
  const params = {} as IProvisionalCityParam

  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"
  return API.get(`/provisional-city`, {
    params,
  })
}

export const fetchAllProvisionalCity =
  async (): Promise<IReturnProvisionalCity> => {
    const params = {} as IProvisionalCityParam
    params.pageSize = "Infinity"
    return API.get(`/provisional-city`, {
      params,
    })
  }

export const getProvisionalCity = async (id: number) => {
  return API.get(`/provisional-city/${id}`)
}

export const createProvisionalCity = async (values): Promise<any> => {
  return API.post(`/provisional-city`, values)
}

export const updateProvisionalCity = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/provisional-city/${id}`, values)
}

export const deleteProvisionalCity = async (id: any) => {
  return API.delete(`/provisional-city/${id}`)
}
