import styled, { css } from "styled-components"
import { theme } from "@project/shared"
const SignImg = css`
  .sign-img {
    height: auto;
    max-height: 26px;
    max-width: 163px;
  }
`
export const FormWrapper = styled.div<any>`
  width: 100%;
  .consent-section {
    display: flex;
    justify-content: space-between;
  }
  .consent-section-row {
    width: 35%;
    div.ant-space-vertical {
      width: 90%;
    }
  }
  margin-top: ${(props) => (props.mt ? "40px" : "0px")};

  .child-name-wrapper {
    display: flex;
    & > :first-child {
      margin-right: 20px;
    }
  }
  p {
    margin: auto 0px;
  }

  .error {
    color: ${theme?.red2};
  }

  .error-border {
    border: 1px solid ${theme.red2} !important;
  }

  .faculty-datepicker {
    width: 160px;
    height: 40px;
  }
  .no-created-wrapper {
    display: flex;
    & > :first-child {
      margin-right: 10px;
    }
    p {
      margin: auto 0px;
    }
  }
  @media (max-width: 822px) {
    .child-name-wrapper {
      & > :first-child {
        margin-right: 10px;
      }
      .large-select {
        width: 200px;
      }
    }
  }
  @media (max-width: 373px) {
    .child-name-wrapper {
      flex-direction: column;
      & > :first-child {
        margin-bottom: 10px;
      }
    }
  }

  .buttons-wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .draft-button {
      margin-right: 0px;
      height: 40px;
      padding: 0px 30px;
      background-color: rgba(7, 130, 200, 0.2);
      color: black;
      border: 1px solid rgba(7, 130, 200, 1);
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }
    .flex-3 {
      .draft-button {
        margin-left: 10px;
      }
    }
    button {
      margin-right: 38px;
    }
    .last-btn {
      @media (max-width: 768px) {
        margin-left: -50px;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: ${(props) => (props.redundant ? "30px" : "0")};
      button {
        margin-right: 0px;
      }
      .redundant {
        display: flex;
      }
      margin-top: 0px;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        min-width: 120px;
        margin-bottom: 10px;
      }
      .draft-button {
        min-width: 190px;
      }
      .save-button {
        margin-inline: 38px;
      }
    }
    @media (max-width: 401px) {
      .save-button {
        margin-inline: 15px;
      }
    }
    @media (max-width: 355px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      .btn {
        width: 100%;
      }
      .draft-button {
        width: 100%;
      }
      .save-button {
        margin-inline: 0px;
      }
    }
  }

  .card_top {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap;
    @media (max-width: 475px) {
      justify-content: center;
    }
    .top-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      span {
        color: ${theme.gray3};
      }

      @media print {
        margin-left: auto;
        float: right;
        span {
          color: black;
        }
      }
    }
  }
  .top-btns {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    gap: 15px;

    .flex-row {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    @media print {
      display: none;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;

      .flex-row {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .bottom-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    gap: 15px;

    @media print {
      display: none;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 30px;
    }
    .left {
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      gap: 15px;

      @media (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
      }
    }
    .right {
      display: flex;
    }
  }
  .redundant {
    display: none;
  }
  @media (max-width: 768px) {
    .redundant {
      display: flex;
      margin-bottom: 30px;
    }
  }

  .info-wrapper {
    min-width: 30rem;
    @media (max-width: 768px) {
      padding-top: 20px;
    }
    .info-class {
      white-space: pre;
      color: #888787;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .flex-wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    .info-wrapper {
      align-self: flex-end;
    }
  }

  .designation-class {
    white-space: pre;
    display: flex;
    gap: 5px;
    &.margin-line {
      border-bottom: 1px solid ${theme.black};
      line-height: 2rem;
      justify-content: space-between;
    }
    .designation {
      display: flex;
      align-items: center;
      gap: 5px;
      ${SignImg}
    }
    @media (max-width: 768px) {
      font-size: 12px;
      .designation,
      b {
        font-size: 12px;
        flex-wrap: wrap;
      }
    }
  }

  .textfiled-wrapper {
    margin-top: 10px;
    width: 300px;
  }
  .sing-flex-mark {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
    div {
      display: flex;
      gap: 15px;
      align-items: center;
      width: 300px;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid ${theme.gray2};
      border-radius: 5px;
      ${SignImg}
    }
    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
      div {
        width: 100%;
      }
    }
  }
  .sign-flex {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid ${theme.gray2};
    border-radius: 5px;
    ${SignImg}
  }
  .hz-line {
    line-height: 40px;
  }
  .item-content-table {
    max-width: 100%;
    overflow: auto;
    table {
      border-collapse: collapse;
    }
    table > thead > tr > th {
      background: #f3f3f3;
      border: 1px solid #d2d2d2;
    }
    table > tbody > tr > td {
      border: 1px solid #d2d2d2;
    }
    @media (max-width: ${theme.breakpoints.xl}) {
      overflow-x: scroll !important;
    }
    @media print {
      overflow-x: visible !important;
    }
  }

  &.bottom-buttons-wrapper,
  &.top-buttons-wrapper,
  &.item-table-wrapper {
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 30px;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      .card_top {
        row-gap: 20px;
      }
    }
  }
  .guardian-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .print-label {
      margin: 20px 0px 0px 10px;
    }
    @media (max-width: 452px) {
      .print-label {
        margin: 20px 0px 0px 0px;
      }
    }
  }

  @media print {
    margin-top: 10px !important;
    border-collapse: collapse !important;
    color: black;
    table {
      border-collapse: separate !important;
    }
    .ant-table-container {
      .ant-table-thead > tr > th.ant-table-cell {
        outline: none !important;
        border: 2px solid black;
        border-right: 0px;
        background: transparent !important;
        background-color: transparent !important;
      }

      .ant-table-thead > tr > th:last-child {
        border-right: 2px solid;
        border-top-right-radius: 0px;
      }

      .ant-table-thead > tr > th:first-child {
        border-top-left-radius: 0px;
      }

      .ant-table-tbody > tr {
        page-break-inside: avoid;
        td {
          border: 2px solid black;
          border-top: 0;
          border-right: 0;
        }
      }

      .ant-table-tbody > tr > td:last-child {
        border-right: 2px solid black;
      }
    }
    .form-field-wrapper {
      display: table-row !important;
      border: 1px solid ${theme.black} !important;

      .label-wrapper,
      .children-wrapper {
        display: table-cell !important;
        border: 0px !important;
      }

      .label-wrapper {
        background: transparent !important;
        vertical-align: middle !important;
        max-width: 300px !important;
        width: 300px;
      }

      /* Had to use inline-table display in order to fix 
         the issue of the row breaking issue in print mode in safari browser
         [NOTE: using page-break-inside: avoid; on the parent element did not work
          hence had to write the below code
          ]
      */
      &.increase-specificity-wrapper {
        display: inline-table !important;
        width: 100%;
        border: 1px solid ${theme.black} !important;
        border-top: 1px solid ${theme.black} !important;
        border-bottom: 1px solid ${theme.black} !important;
        border-left-width: 2px !important;
        border-right-width: 2px !important;

        &:first-child {
          border-top-width: 2px !important;
        }

        &:last-child {
          border-bottom-width: 2px !important;
        }

        .label-wrapper {
          border-right: 2px solid ${theme.black} !important;
        }
      }
    }

    .anticon.anticon-form {
      display: none;
    }
    .guardian-container {
      border: 2px solid black !important;
    }

    .ant-table.ant-table-bordered > .ant-table-container {
      border-color: black !important;
      border-right: 1px solid black !important;
      .ant-table-content > table > tbody > tr {
        td {
          border: 1px solid black !important;
          &:first-child {
            border-left-width: 1.4px !important;
          }
          &:last-child {
            border-right-width: 1.4px !important;
          }
        }

        &:last-child {
          td {
            border-bottom-width: 1.4px !important;
          }
        }
      }
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      border: 1px solid black !important;
      &:first-child {
        border-left-width: 1px !important;
      }
      &:last-child {
        border-right-width: 1px !important;
      }
    }
  }
`
export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  margin-bottom: 40px;
  padding: 13px 30px 30px 36px;
  .for-print {
    display: none;
  }
  strong {
    font-weight: 400;
    color: #000;
  }
  .items_personal {
    min-width: 220px;
  }

  .specific_goals {
    min-width: 165px;
  }

  .goal_achievement {
    min-width: 120px;
  }

  .common_head {
    min-width: 70px;
  }

  .more-width-common-head {
    min-width: 30rem;
    max-width: 50rem;
  }

  .support_guidelines {
    min-width: 200px;
  }

  .providing_organization {
    min-width: 180px;
  }

  @media print {
    @page {
      margin-left: 0.3in !important;
      margin-right: 0.3in !important;
      margin-top: 0.5in !important;
      margin-bottom: 0 !important;
    }
    .more-width-common-head {
      min-width: 1rem;
      max-width: 26rem;
    }
    .items_personal,
    .specific_goals,
    .goal_achievement,
    .common_head,
    .support_guidelines,
    .providing_organization {
      min-width: 0px;
    }
    .specific_goals {
      max-width: 100px;
    }
    .items_personal {
      max-width: 120px;
    }
    color: black;
  }

  .ant-page-header-heading-title {
    white-space: pre-wrap;

    @media screen and (max-width: 426px) {
      width: 16rem;
    }
  }

  .ant-page-header-heading-left {
    display: flex;
    align-items: center;
    margin: 4px 0;
    overflow: hidden;
  }

  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 344px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }

  @media print {
    @page {
      size: auto;
      margin: 10mm 6mm;
    }
    .for-screen {
      display: none;
    }
    .for-print {
      display: block;
    }
    color: black;

    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 2px;
    }
    .card__top-content {
      display: flex !important;
      justify-content: space-between !important;
      .card__header_title {
        flex: 1 !important;
        font-size: 18pt;
        font-weight: bold;
      }
      .top-buttons-wrapper {
        margin-top: 0px !important;
        flex: 1 !important;
      }
    }
  }
`
export const OperationsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  margin-bottom: 40px;
  padding: 13px 30px 30px 36px;

  @media print {
    display: none;
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
`
export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
      }
    }
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }

  &.field-wrapper_over-rider-indentation {
    .checkbox-group .ant-checkbox-group .ant-checkbox-wrapper span:last-child {
      text-indent: 0px;
      margin-left: 10px;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }
`
export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  @media (max-width: ${theme.breakpoints.md}) {
    padding-left: 0px;
  }
`
export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 100px;

  .textfield {
    margin-left: 30px;
  }
`
export const Text = styled.div`
  font-size: 16px;
  white-space: nowrap;
  @media (max-width: ${theme.breakpoints.md}) {
    white-space: nowrap;
  }
`

export const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
