import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  .search {
    .title {
      border-left: 4px solid #0782c8;
      padding-left: 8px;
      margin-top: 10px;
    }
    .searchbar {
      display: flex;
      align-items: center;
      padding-top: 15px;
      flex-wrap: wrap;
      row-gap: 10px;
      .item {
        margin-right: 35px;

        /* conflicts with antpicker */
        button:not(.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn, .ant-picker-year-btn) {
          background-color: #f6f7f8;
          border: 1px solid #d2d1d1;
          color: #191919;
          padding-top: 4px;
          padding-bottom: 4px;
          height: auto;
          @media (max-width: 768px) {
            width: 132px;
          }
        }
        span {
          line-height: 30px;
        }
      }
      @media (max-width: 768px) {
        .item {
          margin-right: 16px;
        }
      }
      .flex-sp {
        display: flex;
      }
    }
    .ant-select-selector {
      width: 80px !important;
      height: 40px !important;
    }
    .ant-select {
      margin-right: 11px;
    }
    @media (min-width: 768px) {
      .item {
        label {
          margin-right: 11px;
        }
      }
    }
    @media (max-width: 768px) {
      .title {
        display: none;
      }
      .searchbar {
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        padding-top: 0px;
      }
    }
  }
  .picker-wrapper {
    display: flex;
    align-items: center;
  }
  .settings-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  [class*="ErrorCounter"] {
    position: absolute;
    bottom: 10px;
  }
  @media (max-width: 768px) {
    .search .searchbar .item:last-child {
      margin-right: 0;
      width: 100%;
    }
    .settings-wrapper {
      flex-direction: column-reverse;
    }
    .picker-wrapper {
      margin-bottom: 20px;
      input {
        margin-left: 0 !important;
      }
      label {
        margin-right: 10px;
      }
    }
  }
`
