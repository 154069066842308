import { Table } from "@project/shared"
import { BeneficiaryNumber } from "./common/BeneficiaryNumber"
import { ChildName } from "./common/ChildName"
import { SiblingChild } from "../../../../../types"

const emptyChild = {
  child_name: "",
  receiving_certificate_number: "",
}

type Props = {
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function KyotoChildDetail({ siblingChildren, printConfig }: Props) {
  const LEFT_CHILDREN_LENGTH = 2
  const RIGHT_CHILDREN_LENGTH = 2

  let leftChildren = siblingChildren.slice(0, LEFT_CHILDREN_LENGTH)
  const emptyLeftChildrenLength = LEFT_CHILDREN_LENGTH - leftChildren.length

  if (emptyLeftChildrenLength > 0) {
    leftChildren = [
      ...leftChildren,
      ...Array(emptyLeftChildrenLength).fill(emptyChild),
    ]
  }

  let rightChildren = siblingChildren.slice(
    LEFT_CHILDREN_LENGTH,
    LEFT_CHILDREN_LENGTH + RIGHT_CHILDREN_LENGTH
  )
  const emptyRightChildrenLenght = RIGHT_CHILDREN_LENGTH - rightChildren.length

  if (emptyRightChildrenLenght > 0) {
    rightChildren = [
      ...rightChildren,
      ...Array(emptyRightChildrenLenght).fill(emptyChild),
    ]
  }

  return (
    <Table
      columns={[
        {
          width: 100,
          dataIndex: "labelLeft",
          render: (v) => <p>{v}</p>,
        },
        {
          width: 200,
          dataIndex: "valueLeft",
        },
        {
          width: 100,
          dataIndex: "labelRight",
          render: (v) => <p>{v}</p>,
        },
        {
          width: 200,
          dataIndex: "valueRight",
        },
      ]}
      dataSource={[
        {
          labelLeft: "受給者証番号",
          valueLeft: (
            <BeneficiaryNumber
              number={leftChildren[0].receiving_certificate_number}
            />
          ),
          labelRight: "受給者証番号",
          valueRight: (
            <BeneficiaryNumber
              number={rightChildren[0].receiving_certificate_number}
            />
          ),
        },

        {
          labelLeft: "支給決定に係る児童氏名①（上限管理児童）",
          valueLeft: (
            <ChildName
              name={leftChildren[0].child_name}
              configuration={printConfig}
            />
          ),
          labelRight: "支給決定に係る児童氏名③",
          valueRight: (
            <ChildName
              name={rightChildren[0].child_name}
              configuration={printConfig}
            />
          ),
        },

        {
          labelLeft: "受給者証番号",
          valueLeft: (
            <BeneficiaryNumber
              number={leftChildren[1].receiving_certificate_number}
            />
          ),
          labelRight: "受給者証番号",
          valueRight: (
            <BeneficiaryNumber
              number={rightChildren[1].receiving_certificate_number}
            />
          ),
        },
        {
          labelLeft: "支給決定に係る児童氏名②",
          valueLeft: (
            <ChildName
              name={leftChildren[1].child_name}
              configuration={printConfig}
            />
          ),
          labelRight: "支給決定に係る児童氏名④",
          valueRight: (
            <ChildName
              name={rightChildren[1].child_name}
              configuration={printConfig}
            />
          ),
        },
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
