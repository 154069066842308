import { API } from "@project/shared"

const BASE_URL = "facilities"

export const getSystemStatus = async (id, year, month) => {
  const currentDate = new Date()
  return API.get(
    `${BASE_URL}/status_settings/${id}/${year ?? currentDate.getFullYear()}/${
      month ?? currentDate.getMonth() + 1
    }`
  )
}
export const updateSystemStatus = async ({ id, data }) => {
  return API.put(`${BASE_URL}/status_settings/${id}`, data)
}
export const getAllSystemStatus = async (year, month) => {
  return API.get(`${BASE_URL}/status_settings_date_wise/${year}/${month}`)
}
