import React from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
//libraries
import { Spin, Tabs } from "antd"

//components
import { PageCard, removeBlankAttributes, theme } from "@project/shared"
import { Button } from "../../atoms"
import { YearPaginateBtn } from "../../molecules"

//styles
import {
  RewardCalculationContent,
  TableContainer,
} from "./RemunerationCalculationTable.styles"
import { useRemunerationCalculationContext } from "../../../context/RemunerationCalculationContext"

export const RemunerationCalculationTable = ({ fiscalYear }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    isLoading,
    dataSource,
    onFilter,
    searchParams,
    calculatePercentage,
    contentRef,
  } = useRemunerationCalculationContext()
  const renderTable = (serviceType: number) => {
    const data =
      dataSource?.filter(
        ({ pref_id, pref_id2 }) =>
          pref_id === serviceType || pref_id2 === serviceType
      ) || []

    return (
      <Spin spinning={isLoading}>
        <TableContainer>
          <div className={"table-content"}>
            <table>
              <thead>
                <tr className={"main_header"}>
                  <th className={"month-col"}>{t("Month")}</th>
                  {data?.map((v, i) => (
                    <th key={i}>
                      {v?.facility_name_short || v?.facility_name || ""}
                    </th>
                  ))}
                </tr>
                <tr className={"sub_header"}>
                  <th>{t("Total")}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"content"}>
                        <div className={"users"}>
                          <div>
                            <span>{"①"}</span>
                            {t("Total number of users")}
                          </div>
                          <span className={"value"}>
                            {v?.total_user || 0}
                            {"人"}
                          </span>
                        </div>
                        <div className={"children_of"}>
                          <div>
                            <span>{"② ①"}</span>
                            {serviceType === 1
                              ? t("のうち指標の対象児")
                              : t("のうち未就学児")}
                          </div>
                          <span className={"value"}>
                            {v?.total_preschooler || 0}
                            {"人"}
                          </span>
                        </div>
                        <div className={"percentage_of"}>
                          <div className={"box"}>
                            <div>
                              <span>{"③"} </span>
                              {serviceType == 1
                                ? t("指標の対象児の割合")
                                : t("未就学児の割合")}
                            </div>
                            <div>{"(②/①)"}</div>
                          </div>
                          <span className={"value"}>
                            {isNaN(v?.preschoolers_in_percentage)
                              ? "0.00%"
                              : `${Number(
                                  v?.preschoolers_in_percentage
                                ).toFixed(2)}%`}
                          </span>
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className={"items_content"}>
                  <th className={"items_content-th"}>{t("項目")}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"items"}>
                        <div className={"item_text"}>
                          <span>{"①"}</span>
                          {t("Total number of users")}
                        </div>
                        <div className={"item_text"}>
                          <span>{"② ①"}</span>
                          {serviceType === 1
                            ? t("のうち指標の対象児")
                            : t("のうち未就学児")}
                        </div>
                        <div className={"item_text"}>
                          <span>{"③"}</span>
                          {serviceType == 1
                            ? t("指標の対象児の割合")
                            : t("未就学児の割合")}
                          <span>{"(②／①)"}</span>
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年04月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m4 ? v.child_count_m4 : 0}</div>
                        <div>{v?.pre_schooler_m4 ? v.pre_schooler_m4 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m4,
                            v.pre_schooler_m4
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年05月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m5 ? v.child_count_m5 : 0}</div>
                        <div>{v?.pre_schooler_m5 ? v.pre_schooler_m5 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m5,
                            v.pre_schooler_m5
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年06月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m6 ? v.child_count_m6 : 0}</div>
                        <div>{v?.pre_schooler_m6 ? v.pre_schooler_m6 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m6,
                            v.pre_schooler_m6
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年07月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m7 ? v.child_count_m7 : 0}</div>
                        <div>{v?.pre_schooler_m7 ? v.pre_schooler_m7 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m7,
                            v.pre_schooler_m7
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年08月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m8 ? v.child_count_m8 : 0}</div>
                        <div>{v?.pre_schooler_m8 ? v.pre_schooler_m8 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m8,
                            v.pre_schooler_m8
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年09月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m9 ? v.child_count_m9 : 0}</div>
                        <div>{v?.pre_schooler_m9 ? v.pre_schooler_m9 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m9,
                            v.pre_schooler_m9
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年10月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m10 ? v.child_count_m10 : 0}</div>
                        <div>
                          {v?.pre_schooler_m10 ? v.pre_schooler_m10 : 0}
                        </div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m10,
                            v.pre_schooler_m10
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年11月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m11 ? v.child_count_m11 : 0}</div>
                        <div>
                          {v?.pre_schooler_m11 ? v.pre_schooler_m11 : 0}
                        </div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m11,
                            v.pre_schooler_m11
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${fiscalYear}年12月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m12 ? v.child_count_m12 : 0}</div>
                        <div>
                          {v?.pre_schooler_m12 ? v.pre_schooler_m12 : 0}
                        </div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m12,
                            v.pre_schooler_m12
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${
                    fiscalYear + 1
                  }年01月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m1 ? v.child_count_m1 : 0}</div>
                        <div>{v?.pre_schooler_m1 ? v.pre_schooler_m1 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m1,
                            v.pre_schooler_m1
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${
                    fiscalYear + 1
                  }年02月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m2 ? v.child_count_m2 : 0}</div>
                        <div>{v?.pre_schooler_m2 ? v.pre_schooler_m2 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m2,
                            v.pre_schooler_m2
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className={"items_data"}>
                  <th className={"items_data-the"}>{`${
                    fiscalYear + 1
                  }年03月`}</th>
                  {data?.map((v, i) => (
                    <td key={i}>
                      <div className={"data"}>
                        <div>{v?.child_count_m3 ? v.child_count_m3 : 0}</div>
                        <div>{v?.pre_schooler_m3 ? v.pre_schooler_m3 : 0}</div>
                        <div>
                          {`${calculatePercentage(
                            v?.child_count_m3,
                            v.pre_schooler_m3
                          ).toFixed(2)}%`}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </TableContainer>
      </Spin>
    )
  }

  return (
    <RewardCalculationContent ref={contentRef}>
      <PageCard title={""}>
        <div className={"remuneration_tab_top_content"}>
          <div className={"top_title"}>
            {t("{{year}}年’s Remuneration calculation categories", {
              year: searchParams?.fiscal_year,
            })}
          </div>
          <div className={"pagination__cta"}>
            <YearPaginateBtn
              prev={t("Last year")}
              current={t("This year")}
              next={t("Next year")}
              isDisabled={isLoading}
              handleClick={(key: string) => {
                if (key === "prev") {
                  onFilter({ fiscal_year: searchParams?.fiscal_year - 1 })
                } else if (key === "current") {
                  onFilter({
                    fiscal_year: new Date().getFullYear(),
                  })
                } else {
                  onFilter({
                    fiscal_year: searchParams?.fiscal_year + 1,
                    fiscal_month: 1,
                  })
                }
              }}
            />
          </div>
        </div>
        <div className={"tab_top_btn"}>
          <Button
            text={t("Print")}
            backgroundColor={theme.gray3}
            color={theme.base}
            icon={"print"}
            disabled={isLoading}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { tempFacilities, ...rest } = searchParams
              router.push({
                pathname: `/remuneration-calculation/print`,
                query: removeBlankAttributes({
                  ...rest,
                  fiscal_year: new Date().getFullYear(),
                  fiscal_month: new Date().getMonth() + 1,
                  fiscal_day: new Date().getDate(),
                  facility: Array.isArray(searchParams?.facility)
                    ? searchParams!.facility!.join(",")
                    : searchParams?.facility,
                }),
              })
            }}
          />
        </div>
        <Tabs
          defaultActiveKey={"child-development-support"}
          type={"card"}
          className={"tabs_container"}
          tabBarGutter={10}
          onChange={(val) => onFilter({ service_type: val })}
          items={[
            {
              label: t("Child development support"),
              key: "2",
              children: <div className={"tab_table"}>{renderTable(2)}</div>,
            },
            {
              label: t("After day school service"),
              key: "1",
              children: <div className={"tab_table"}>{renderTable(1)}</div>,
            },
          ]?.map((val) => ({
            label: val?.label,
            key: val?.key,
            children: val?.children,
          }))}
        />
      </PageCard>
    </RewardCalculationContent>
  )
}
