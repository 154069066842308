import React from "react"
import { useTranslation } from "react-i18next"
import { Wrapper } from "./DisabledChildOutpatientBenefitsIndividualTable.style"
import { PaymentDetailContentTable } from "./PaymentDetailContentTable"
import { SummaryColumnTable } from "./SummaryColumnTable"
import moment from "moment"
import { formatJaEra } from "../../../molecules/MonthlyUsageList/common/mapper"
import wareki from "wareki"

import { GRADES } from "@project/shared"

export const DisabledChildOutpatientBenefitsIndividualTable: React.FC<any> = ({
  data,
  selectedPrintSettings,
  page_number = 1,
  total_page = 1,
}) => {
  const { t } = useTranslation()
  const getTdValues = (data: string, pad?: number) => {
    return data?.padEnd(pad || 6, "_")?.split("")
  }
  const getCurrentEra = (year, month) => {
    if (year && month) {
      const yearMonth = `${year}-${month.padStart(2, "0")}`
      const date = formatJaEra(
        wareki(yearMonth, {
          unit: true,
        })
      )
      const era = date?.slice(0, 2)
      const currentYear = date?.slice(2, 4)
      return { era, currentYear }
    }
    return {
      era: "",
      currentYear: "",
    }
  }

  const totalHospitalizedDays = () => {
    if (data?.HospitalizationDate) {
      const date = moment(data?.HospitalizationDate)
      const today = moment()
      return `${today.diff(date, "days")}`?.padStart(2, " ")?.split("")
    }
    return ""?.padStart(2, " ")?.split("")
  }
  const getGradeLabel = (grade) => {
    const grade_label = GRADES.find((item) => item.value === grade)
    return grade_label?.label || ""
  }
  const renderServiceInfo = (
    serviceType: number | undefined,
    thickRight: boolean,
    thickBottom: boolean,
    showTable?: boolean
  ) => {
    const classNamesToAdd = []
    if (thickRight) {
      classNamesToAdd.push("thick-right-border")
    }
    if (thickBottom) {
      classNamesToAdd.push("thick-bottom-border")
    }
    const borderClasses = classNamesToAdd?.join(" ")
    const lastCellClasses = thickBottom ? "thick-bottom-border" : ""
    return (
      <>
        <td className={borderClasses}>{t("Start year month day")}</td>
        <td className={borderClasses}>
          {showTable
            ? t("{{wareki}}", {
                wareki: t(getCurrentEra(data?.a_08, data?.a_09)?.era),
              })
            : ""}
        </td>

        <td className={borderClasses}>
          {showTable && serviceType && data?.a_08
            ? getCurrentEra(data?.a_08, data?.a_09)?.currentYear?.split("")[0]
            : ""}
        </td>
        <td className={borderClasses}>
          {showTable && serviceType && data?.a_08
            ? getCurrentEra(data?.a_08, data?.a_09)?.currentYear?.split("")[1]
            : ""}
        </td>
        <td className={borderClasses}>{t("Year")}</td>

        <td className={borderClasses}>
          {showTable && serviceType && data?.a_09
            ? data?.a_09?.padStart(2, "0")?.substring(0, 1) || ""
            : ""}
        </td>
        <td className={borderClasses}>
          {showTable && serviceType && data?.a_09
            ? data?.a_09?.padStart(2, "0")?.substring(1, 2) || ""
            : ""}
        </td>
        <td className={borderClasses}>{t("Month")}</td>
        <td className={borderClasses}>
          {showTable && serviceType && data?.a_10
            ? data?.a_10?.padStart(2, "0")?.substring(0, 1) || ""
            : ""}
        </td>
        <td className={borderClasses}>
          {showTable && serviceType && data?.a_10
            ? data?.a_10?.padStart(2, "0")?.substring(1, 2) || ""
            : ""}
        </td>
        <td className={borderClasses}>{t("Day")}</td>

        <td className={borderClasses}>{t("End year month day")}</td>
        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_11
            ? getCurrentEra(data?.a_11, data?.a_12)?.currentYear?.split(
                ""
              )[0] || ""
            : ""}
        </td>
        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_11
            ? getCurrentEra(data?.a_11, data?.a_12)?.currentYear?.split(
                ""
              )[1] || ""
            : ""}
        </td>
        <td className={borderClasses}>{t("Year")}</td>

        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_12
            ? data?.a_12?.padStart(2, "0")?.substring(0, 1) || ""
            : ""}
        </td>
        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_12
            ? data?.a_12?.padStart(2, "0")?.substring(1, 2) || ""
            : ""}
        </td>
        <td className={borderClasses}>{t("Month")}</td>

        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_13
            ? data?.a_13?.padStart(2, "0")?.substring(0, 1) || ""
            : ""}
        </td>
        <td id={"empty__cell"} className={borderClasses}>
          {showTable && serviceType && data?.a_13
            ? data?.a_13?.padStart(2, "0")?.substring(1, 2) || ""
            : ""}
        </td>
        <td className={borderClasses}>{t("Day")}</td>
        <td className={borderClasses}>{t("Used days")}</td>
        {showTable && serviceType && data?.c_03 ? (
          String(data?.c_03 || "")
            ?.padStart(2, "_")
            ?.split("")
            ?.map((v, i) => (
              <td key={i} className={borderClasses}>
                {v === "_" ? "" : v}
              </td>
            ))
        ) : (
          <>
            <td className={borderClasses}></td>
            <td className={borderClasses}></td>
          </>
        )}

        <td className={borderClasses}>{t("Hospitalization days")}</td>
        {showTable && serviceType ? (
          totalHospitalizedDays()?.map((v, i, row) => (
            <td
              id={"empty__cell"}
              key={i}
              className={i == row?.length - 1 ? lastCellClasses : borderClasses}
            >
              {v}
            </td>
          ))
        ) : (
          <td colSpan={4} className={lastCellClasses}></td>
        )}
      </>
    )
  }

  const getManagementResultAmount = () =>
    data?.ManagementResultAmount >= 0
      ? String(data?.ManagementResultAmount || "____0")
          ?.padStart(5, " ")
          .split("")
      : ["_", "_", "_", "_", "_"]

  return (
    <Wrapper>
      {selectedPrintSettings?.includes("1") && (
        <span id={"form__num"}>{t("(Form 2)")}</span>
      )}

      <div className={"individual__top_content"}>
        <div className={"individual__top_content--title"}>
          {t(
            "Request Form for Day Care Benefit, Residential Payment, etc. for Disabled Children"
          )}
        </div>
        <div className={"individual__top_content--tables"}>
          <div className={"tables__left"}>
            <div className={"tables__left--top"}>
              <div id={"table__left_top"}>
                <table>
                  <tbody>
                    <tr>
                      <th className={"thick-right-border thick-bottom-border"}>
                        {t("prefecture, etc., number")}
                      </th>
                      {getTdValues(data?.a_01 || "")?.map((value, idx) => (
                        <td
                          className={
                            idx === 5
                              ? "thick-bottom-border"
                              : "thick-right-border thick-bottom-border"
                          }
                          key={idx}
                        >
                          {value !== "_" ? value : ""}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th className={"thick-right-border"}>
                        {t("Grant municipality number")}
                      </th>
                      {getTdValues(data?.a_02 || "")?.map((value, idx) => (
                        <td
                          className={idx === 5 ? "" : "thick-right-border"}
                          key={idx}
                        >
                          {value !== "_" ? value : ""}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id={"table__left_middle"}>
                <table>
                  <tbody>
                    <tr>
                      <th className={"thick-right-border thick-bottom-border"}>
                        {t("Beneficiary certificate number")}
                      </th>
                      <td className={"thick-bottom-border"}>
                        <div id={"inner__cell__container"}>
                          {getTdValues(String(data?.a_03 || ""), 10)?.map(
                            (v, idx, row) => (
                              <div
                                key={idx}
                                className={
                                  idx == row?.length - 1
                                    ? "inner__cell"
                                    : "thick-right-border inner__cell"
                                }
                              >
                                {v !== "_" ? v : ""}
                              </div>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className={"thick-right-border thick-bottom-border"}>
                        {t("Name of guardian for benefit decision")}
                      </th>
                      <td className={"thick-bottom-border"}>
                        <div className={"p-2"}>{data?.a_04 || ""}</div>
                      </td>
                    </tr>
                    <tr>
                      <th className={"thick-right-border"}>
                        {t("related to payment decision Disabled child name")}
                      </th>
                      <td>
                        <div className={"p-2"}>{data?.a_05 || ""}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id={"table__left_middle"}>
                <table>
                  <tbody>
                    <tr>
                      <th className={"thick-right-border"}>
                        {t("User burden amount ①")}
                      </th>
                      <td>
                        <div id={"inner__cell__container"}>
                          {String(data?.c_02 || "")
                            ?.padStart(5, " ")
                            ?.split("")
                            ?.map((v, index, row) => (
                              <div
                                key={index}
                                className={
                                  index == row?.length - 1
                                    ? "inner__cell"
                                    : "inner__cell thick-right-border"
                                }
                              >
                                {v}
                              </div>
                            ))}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={"tables__right"}>
            <div id={"tables__right--top"}>
              <table>
                <tbody>
                  <tr>
                    <th
                      style={{ minWidth: "40px" }}
                      className={"thick-right-border"}
                    >
                      {t("{{wareki}}", {
                        wareki: t(getCurrentEra(data?.b_01, data?.b_02)?.era),
                      })}
                    </th>
                    <td className={"thick-right-border"}>
                      {t(
                        getCurrentEra(
                          data?.b_01,
                          data?.b_02
                        )?.currentYear?.substring(0, 1)
                      )}
                    </td>
                    <td className={"thick-right-border"}>
                      {t(
                        getCurrentEra(
                          data?.b_01,
                          data?.b_02
                        )?.currentYear?.substring(1, 2)
                      )}
                    </td>
                    <td className={"thick-right-border"}>{t("Year")}</td>
                    <td className={"thick-right-border"}>
                      {data?.b_02?.padStart(2, "0")?.substring(0, 1)}
                    </td>
                    <td className={"thick-right-border"}>
                      {data?.b_02?.padStart(2, "0")?.substring(1, 2)}
                    </td>
                    <td>{t("Month's")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id={"tables__right--top"}>
              <table>
                <tbody>
                  <tr>
                    <th rowSpan={2} className={"thick-right-border"}>
                      {t("Invoice company")}
                    </th>
                    <td className={"thick-right-border thick-bottom-border"}>
                      {t("Designated Office Number")}
                    </td>
                    {getTdValues(String(data?.a_06 || ""), 10)?.map(
                      (v, idx, row) => (
                        <td
                          key={idx}
                          className={`${
                            idx == row?.length - 1
                              ? "thick-bottom-border"
                              : "thick-right-border thick-bottom-border"
                          }`}
                        >
                          {v !== "_" ? v : ""}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <td className={"thick-right-border"}>
                      {t("Name of business operator and its place of business")}
                    </td>
                    <td colSpan={10} className={"office__name"}>
                      <div className={"office__name--top thick-bottom-border"}>
                        {data?.a_07_name}
                      </div>
                      <div className={"office__name--bottom"}>
                        <div className={"thick-right-border"}>
                          {t("Area division")}
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          {getGradeLabel(data?.c_01) || t("Others")}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id={"individual__middle_content"}>
        <div id={"individual__middle--top"}>
          <table>
            <tbody>
              <tr>
                <td rowSpan={2} className={"thick-right-border"}>
                  {t("User burden upper limit management office")}
                </td>
                <td className={"thick-right-border thick-bottom-border"}>
                  {t("Designated Office Number")}
                </td>
                {getTdValues(
                  String(data?.UpperLimitOfficeNumber || ""),
                  10
                )?.map((v, index) => (
                  <td
                    key={index}
                    id={"empty__cell"}
                    className={"thick-bottom-border thick-right-border"}
                  >
                    {v !== "_" ? v : ""}
                  </td>
                ))}

                <td className={"thick-bottom-border thick-right-border"}>
                  {t("Management result")}
                </td>
                <td
                  id={"empty__cell"}
                  className={"thick-bottom-border thick-right-border"}
                >
                  {data?.ManagementResult ? data?.ManagementResult : ""}
                </td>
                <td className={"thick-bottom-border thick-right-border"}>
                  {t("Management result amount")}
                </td>
                {getManagementResultAmount().map((v, index, row) => (
                  <td
                    key={index}
                    id={"empty__cell"}
                    className={
                      index == row?.length - 1
                        ? "thick-bottom-border"
                        : "thick-bottom-border thick-right-border"
                    }
                  >
                    {v !== "_" ? v : ""}
                  </td>
                ))}
              </tr>
              <tr>
                <td className={"thick-right-border"}>
                  {t("Business official name")}
                </td>
                <td colSpan={18}>{data?.UpperLimitBusinessName || ""}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id={"individual__middle--bottom"}>
          <table>
            <tbody>
              <tr>
                <td rowSpan={2} className={"thick-right-border"}>
                  {t("Service type")}
                </td>
                <td className={"thick-right-border thick-bottom-border"}>
                  {data?.c_09?.substring(0, 1)}
                </td>
                <td className={"thick-right-border thick-bottom-border"}>
                  {data?.c_09?.substring(1, 2)}
                </td>
                {renderServiceInfo(data?.c_09 || true, true, true, true)}
              </tr>
              <tr>
                <td className={"thick-right-border"}></td>
                <td className={"thick-right-border"}></td>
                {renderServiceInfo(data?.c_09 || true, true, false, false)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PaymentDetailContentTable data={data?.c_04_to_c08 || {} || undefined} />
      <SummaryColumnTable
        data={data || undefined}
        showHowManyColumn={selectedPrintSettings?.includes("3")}
        total_page={total_page}
        page_number={page_number}
      />
    </Wrapper>
  )
}
