import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: ${theme.base};
  padding: 20px;
  page-break-inside: avoid !important;
  &:not(:last-child) {
    border-bottom: 1px double ${theme.black};
  }
`
export const Title = styled.h3`
  text-align: center;
  color: ${theme.black};
  font-weight: 700;
`
export const DateInfo = styled.div`
  text-align: right;
`
export const FacilityInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  .print_facility_info_right {
    table {
      border-collapse: collapse !important;
      tbody {
        tr {
          page-break-inside: avoid !important;
          th,
          td {
            border: 1px solid ${theme.black} !important;
            padding: 7px !important;
            #business_number {
              display: flex;
              width: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              .number {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:not(:last-child) {
                  border-right: 1px solid ${theme.black};
                }
              }
            }
          }
        }
      }
    }
  }
`
export const DateMonthInfo = styled.div`
  table#DateMonthInfo {
    border-collapse: collapse !important;
    tbody {
      tr {
        page-break-inside: avoid !important;
        td {
          border: 1px solid ${theme.black} !important;
          padding: 0.3em;
          width: 10%;
          text-align: center;
        }
        td.w-large {
          width: 20% !important;
        }
      }
    }
  }
`
export const PaymentDecisionColumn = styled.div`
  margin-top: 2rem;
  #PaymentDecisionColumnTable {
    table {
      border-collapse: collapse !important;
      thead {
        tr {
          th {
            border: 1px solid ${theme.black} !important;
          }
        }
      }
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            padding: 0 !important;
            border: 1px solid ${theme.black} !important;
          }
        }
      }
      #PaymentDecisionColumnInnerTable {
        display: flex;
        .inner__table__content {
          border-right: 1px solid ${theme.black} !important;
          min-height: 60px;
          display: flex;
          flex-direction: column;
          .content {
            display: flex;
            width: 100%;
            height: 100%;
            /* align-items: center; */
            position: relative;
            &:not(:last-child) {
              border-bottom: 1px solid ${theme.black} !important;
            }
            .label {
              padding: 0.3rem 0.5rem;
              min-width: 100px !important;
              max-width: 100px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
            }
            .value__container {
              width: 100%;
              .value {
                display: flex;
                height: 100%;
                position: relative;
                width: 100%;
                &--content {
                  padding: 0.3rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 18px;
                  width: 100%;
                  border-right: 1px solid ${theme.black} !important;
                  position: relative;
                  &:first-child {
                    border-left: 1px solid ${theme.black} !important;
                  }
                  span {
                    font-size: 12px;
                  }
                  .with__bg {
                    background-color: ${theme.gray6} !important;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    @media print {
                      top: 0.5px !important;
                      bottom: 0.5px !important;
                      right: 0.5px !important;
                      left: 0.5px !important;
                    }
                  }
                }

                .value--content--large {
                  width: 100%;
                  border-left: 1px solid ${theme.black} !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .service__provided {
          display: flex;
          width: 100%;

          &--label {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
          &--value--container {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
            .service__provided--value {
              display: flex;
              width: 100%;
              height: 100%;
              border-bottom: 1px solid ${theme.black} !important;
              .code__container {
                display: flex;
                .code {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-right: 1px solid ${theme.black} !important;
                  padding: 0.3rem 0.5rem;
                  width: 30px;
                  max-width: 30px;
                  &:first-child {
                    border-left: 1px solid ${theme.black} !important;
                  }
                }
              }
            }
            .name {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              font-size: 12px;
              height: 100%;
            }
          }
        }
      }
    }
  }
`
