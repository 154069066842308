// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const Container = styled.div`
  .common-select-input {
    .ant-select-selector {
      padding: 4.2px 14px !important;
      height: auto !important;
    }
  }

  .action-btn-group {
    margin: 24px 0;

    .print-btn {
      padding: 11px 30px;
    }
  }

  p,
  ul,
  h4 {
    margin-bottom: 0 !important;
  }
`

export const FilterOperationContainer = styled.section`
  .operation-rounded-container {
    .form-group {
      margin-top: 24px;
      display: grid;
      gap: 24px;
      .receipt_and_issue-date-group {
        column-gap: 20%;
        .date-wrapper {
          width: auto;
        }
      }

      .save-btn {
        width: min-content;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`

export const ProxyMoreInfoContainer = styled.section`
  .label-cell {
    text-align: left !important;
  }
  .operation-rounded-container {
    .title-wrapper {
      display: none;
    }

    .multi-table-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;
      .ant-skeleton-element {
        display: block;
      }
      .individual-tabulated-info-container {
        border: 1px solid ${theme.gray2};
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .record-text {
          text-align: center;
        }

        .owner-table {
          .main-pagination-wrapper {
            display: none;
          }
          .ant-table-container {
            border-color: ${theme.black} !important;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            table {
              border-color: ${theme.black} !important;
              border-radius: 0 0 0 0;
              thead {
                display: none;
              }
              tbody.ant-table-tbody tr.ant-table-row {
                &:nth-child(6) {
                  td.label-cell {
                    text-align: center !important;
                  }
                }
                td {
                  border-color: ${theme.black};
                  background-color: ${theme.base};
                  padding: 8px 16px;
                  font-size: 14px;
                  white-space: pre-wrap;
                }
              }
            }
          }
        }

        .notice {
          text-align: right;
        }

        @media print {
          border-color: ${theme.black};
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }

    @media print {
      padding: 0;
      box-shadow: none;

      .multi-table-container {
        margin-top: 0;
        .individual-tabulated-info-container {
          page-break-after: always;
          border: none;
          padding: 0;

          .ant-table-container {
            border: none;
            table {
              border: none;

              tbody.ant-table-tbody tr.ant-table-row {
                &:first-child {
                  display: none;
                }

                &:nth-child(2) {
                  td.ant-table-cell {
                    border-top: 2px solid ${theme.black};
                  }
                }

                &:last-child td.ant-table-cell,
                &:nth-child(6) td.ant-table-cell:first-child {
                  border-bottom: 2px solid ${theme.black};
                }
                &:not(:nth-child(7)):not(:last-child)
                  td.ant-table-cell:first-child {
                  border-left: 2px solid ${theme.black};
                }

                .ant-table-cell {
                  border: 0.5px solid ${theme.black};
                  outline: 0.5px solid ${theme.black};

                  &:last-child {
                    border-right: 2px solid ${theme.black};
                  }
                }
              }
            }
          }

          &:last-child {
            page-break-after: avoid;
          }
        }
      }
    }
  }

  .billing-title {
    display: flex;
    border: 1px solid ${theme.black};
    max-width: 50rem;
    justify-content: center;
    margin: auto;
    padding: 4px 60px 4px 60px;
  }
`
export const BillingHead = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;

  &.direction-column {
    flex-direction: column;
  }

  .align-right {
    text-align: right;
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }

  @media print {
    flex-wrap: nowrap;
  }

  .billing-left-content {
    max-width: 380px;
    .parent-name {
      font-weight: 500;
      margin-top: 10px;
    }
  }
  .billing-right-content {
    flex: 1;
    text-align: right;
    .enveloped-layout {
      width: 100%;
      padding: 8px;
      font-weight: 600;
      min-width: 380px;
      line-height: 100%;
      text-align: center;
      white-space: break-spaces;
      border: 1px solid ${theme.gray5};

      @media (max-width: 595px) {
        min-width: 100%;
      }

      @media print {
        border-width: 2px;
        border-color: ${theme.black};
      }
    }
  }
`
