import { theme, TopRoundedWhiteCard } from "@project/shared"
import { useRouter } from "next/router"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { RevenueManagementOperation } from "../../molecules"
import { IRevenueManagementOperationOptions } from "../../molecules/RevenueManagementOperation"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts"
import { useQuery } from "react-query"
import { getRevenueGraphData } from "../../../services/revenueManagement"
import { Skeleton } from "antd"
import moment from "moment"
import { OwnerButton } from "../../atoms"
import ResizeObserver from "resize-observer-polyfill"
import { scrollToSelectedElement } from "../../../utils"

interface IGraphProps {
  show_legend: boolean
}

const Wrapper = styled.div<IGraphProps>`
  margin: 20px 0;
  .graph-info-container {
    margin-top: 40px;
    .graph-info-title {
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 20px;
      border-bottom: 1px solid ${theme.gray2};
    }
    .graph-content {
      margin: 20px 0;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: flex-start;
      overflow-x: auto;
      .graph {
        overflow-x: auto;
        min-width: 500px;
        width: ${(props) => (!props.show_legend ? "100%" : "70%")};
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          min-width: 100%;
          width: 100%;
        }
      }
      .graph_legend {
        min-width: 200px;
        height: auto;
        border: 1px solid ${theme.gray2};
        padding: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        .each_info {
          display: flex;
          align-items: center;
          .color_legend {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .go-back-container {
    margin-bottom: 20px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    .graph-info-container {
      .graph-content {
        gap: 20px;
        flex-direction: column;
        overflow-x: hidden;
      }
    }
  }
`

const TWELVE_DIFFERENT_COLORS = [
  "#4AB2C4",
  "#C5B47E",
  "#829457",
  "#953479",
  "#D8B83E",
  "#0285CB",
  "#E9A228",
  "#589475",
  "#948B12",
  "#4B5CE4",
  "#FF5800",
  "#C647A3",
]

interface ISalesTableProps {
  child_count: number
  title_background: string
  data_padding: string
  responsive_child_count?: number
}

const SalesTable = styled.div<ISalesTableProps>`
  width: 100%;
  margin: 32px 0 40px 0;
  display: grid;
  grid-template-columns: ${(props) =>
    props.child_count === 4
      ? "1fr 1fr 1fr 1fr"
      : props.child_count === 3
      ? "1fr 1fr 1fr"
      : "1fr 1fr"};
  .sales-title-data {
    border: 1px solid ${theme.gray2};
    .sales-title {
      background: ${(props) => props.title_background};
      padding: 10px;
      text-align: center;
      font-weight: 500;
      border-bottom: 1px solid ${theme.gray2};
    }
    .sales-data {
      padding: ${(props) => props.data_padding};
      text-align: center;
      font-size: 20px;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: ${(props) =>
      props?.responsive_child_count == 2 ? "1fr 1fr" : "1fr"};
  }
`

interface IRevenueManagementGraphPage {
  facility_options: Array<any>
  operationOptions: IRevenueManagementOperationOptions
  setOperationOptions: any
}

export const RevenueManagementGraphPage = ({
  facility_options,
  operationOptions,
  setOperationOptions,
}: IRevenueManagementGraphPage) => {
  const { t } = useTranslation()
  const router = useRouter()
  const contentRef = useRef<HTMLDivElement>(null)
  const { data: revenueGraphData, isLoading } = useQuery(
    ["revenue-management-graph", operationOptions],
    async () => {
      const { data } = await getRevenueGraphData(operationOptions)
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const graphData = []
  const facilitiesForLegend = []
  facility_options.map((facility, index) => {
    if (
      operationOptions.facilityIds.length == 0 ||
      operationOptions.facilityIds.includes(facility?.value)
    ) {
      const facilityData = revenueGraphData?.facility_sales?.find(
        (f) => f?.facility_id == facility?.value
      )

      if (Array.isArray(facilityData?.monthly_sales)) {
        facilityData?.monthly_sales?.map((sales) => {
          const indexOfExisting = graphData.findIndex(
            (d) => d?.name == sales?.label
          )
          if (indexOfExisting == -1) {
            const newObj = {
              name: sales?.label,
              year: sales?.year,
              month: sales?.month,
            }
            newObj[facility.value] = sales?.amount
            graphData.push(newObj)
          } else {
            graphData[indexOfExisting][facility.value] = sales?.amount
          }
        })
      }

      facilitiesForLegend.push({
        ...facility,
        color: TWELVE_DIFFERENT_COLORS[index],
      })
    }
  })

  useEffect(() => {
    const queries = []
    Object.keys(operationOptions).map((key) => {
      if (Array.isArray(operationOptions[key])) {
        if (operationOptions[key].length)
          queries.push(`${key}=${operationOptions[key].join(",")}`)
      } else if (operationOptions[key])
        queries.push(`${key}=${operationOptions[key]}`)
    })
    if (queries.length)
      router.push("?" + queries.join("&"), undefined, { shallow: true })
  }, [operationOptions])

  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }

  return (
    <Wrapper show_legend={facilitiesForLegend.length == 1 ? false : true}>
      <RevenueManagementOperation
        facility_options={facility_options}
        operationOptions={operationOptions}
        setOperationOptions={(values: any) => {
          setOperationOptions(values)
          scrollToSelectedElement(contentRef)
        }}
      />

      <div className={"go-back-container"}>
        <OwnerButton
          typeOf={"secondary"}
          text={`${t("<< Back")}`}
          onClick={() => {
            router.push({
              pathname: "/revenue-management",
              query: router.query,
            })
          }}
        />
      </div>

      <div className={"graph-page-data-container"} ref={contentRef}>
        <TopRoundedWhiteCard title={t("Sales status")}>
          {isLoading ? (
            <>
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <Skeleton active />
                <Skeleton active />
              </div>
            </>
          ) : (
            <>
              <SalesTable
                child_count={3}
                title_background={theme.blue6}
                data_padding={"30px 0"}
              >
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("Cumulative daily sales")}
                  </div>
                  <div className={"sales-data"}>{`${
                    revenueGraphData?.cumulative_daily_sales?.toLocaleString() ??
                    0
                  }${t("Yen")}`}</div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("Expected monthly sales")}
                  </div>
                  <div className={"sales-data"}>
                    {(revenueGraphData?.expected_monthly_sales?.toLocaleString() ??
                      0) + `${t("Yen")}`}
                  </div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>{t("Achievement rate")}</div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.achievement_rate + "%"}
                  </div>
                </div>
              </SalesTable>
              <SalesTable
                child_count={4}
                title_background={theme.secondaryBg}
                data_padding={"20px 0"}
                responsive_child_count={2}
              >
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("Number of days attended")}
                  </div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.number_of_days_attended ?? 0}
                  </div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>{t("Absence 1")}</div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.absent_count ?? 0}
                  </div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("Absence 1 (No addition)")}
                  </div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.absent_count_no_addition ?? 0}
                  </div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>{t("Scheduled")}</div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.scheduled_count ?? 0}
                  </div>
                </div>
              </SalesTable>
              <SalesTable
                child_count={2}
                title_background={theme.secondaryBg}
                data_padding={"20px 0"}
              >
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("average_daily_users")}
                  </div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.this_month_average_users_rate ??
                      "0人/0%"}
                  </div>
                </div>
                <div className={"sales-title-data"}>
                  <div className={"sales-title"}>
                    {t("three_months_average_number_of_users_rate")}
                  </div>
                  <div className={"sales-data"}>
                    {revenueGraphData?.three_month_average_users_rate ??
                      "0人/0%"}
                  </div>
                </div>
              </SalesTable>
              <div className={"graph-info-container"}>
                <div className={"graph-info-title"}>
                  {t("Transition of annual sales and achievement rate")}
                </div>
                <div className={"graph-content"}>
                  <div className={"graph"}>
                    <ResponsiveContainer
                      height={500}
                      minWidth={800}
                      width={"100%"}
                    >
                      <LineChart
                        data={graphData?.sort((a, b) =>
                          moment()
                            .set({ year: a?.year, month: a?.month - 1 })
                            .isBefore(
                              moment().set({
                                year: b?.year,
                                month: b?.month - 1,
                              })
                            )
                            ? -1
                            : 1
                        )}
                        margin={{
                          top: 5,
                          right: 20,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray={"5 5"} />
                        <XAxis dataKey={"name"} />
                        <YAxis />
                        {facilitiesForLegend.map((facility) => (
                          <Line
                            key={facility.value}
                            dataKey={facility.value}
                            stroke={facility.color}
                          />
                        ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  {facilitiesForLegend.length != 1 && (
                    <div className={"graph_legend"}>
                      {facilitiesForLegend.map((facility, index) => (
                        <div className={"each_info"} key={index}>
                          <div
                            className={"color_legend"}
                            style={{ background: facility?.color }}
                          ></div>
                          {facility?.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </TopRoundedWhiteCard>
      </div>
    </Wrapper>
  )
}
