import {
  FACILITY_LOCATIONS,
  LOCATIONS,
  PICK_DROP_CDS_OPTIONS,
  PageCard,
} from "@project/shared"
import { Typography } from "antd"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { fetchOneTransportData } from "../../../services/transport"
import { Divisor, WEEKLONGERDAYS } from "../../../utils"
import { OwnerButton } from "../../atoms"
import { Approval } from "./Approval"
import { DriverEntry } from "./DriverEntry"
import { DriversSection } from "./DriversSection"
import { DropoffTable } from "./DropoffTable"
import { OperationRow } from "./OperationRow"
import { PickupTable } from "./PickupTable"
import { PageContainer, PrintButtonsSection } from "./index.styles"

export const UserTransportPrinting = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const date = new Date(router.query.date as string)
  const year = date?.getFullYear()
  const month = date?.getMonth()
  const day = date?.getDate()
  const printContentRef = useRef<HTMLDivElement>(null)
  const initialFormValues = {
    driversEntry: true,
    showDaycares: true,
    showVehicles: true,
  }
  const [operationFormState, setOperationFormState] =
    useState(initialFormValues)
  const [displayState, setDisplayState] = useState(initialFormValues)
  const [oneTransportData, setOneTransportData] = useState<any>()

  const [queryParam] = useState({
    facilityIds: router?.query?.facilityIds,
    use_service: router?.query?.service_ids,
    date: null,
    driver: router?.query?.driver,
  })

  const getPlace = (obj: any, defaultString: string, isCDSChild: boolean) => {
    if (obj?.place_id === "3") {
      return obj?.other_place
    } else if (obj?.place_id === "1" && obj?.service_type === "2") {
      if (obj?.nursery_school_name === "") {
        return t("Nursery school")
      }
      return obj?.nursery_school_name
    }
    if (obj?.place_id === null) {
      return (
        (isCDSChild ? PICK_DROP_CDS_OPTIONS : FACILITY_LOCATIONS).find(
          ({ value }) => value === defaultString
        )?.label || defaultString
      )
    }
    return LOCATIONS.find(({ value }) => value === Number(obj?.place_id))?.label
  }

  useEffect(() => {
    refetch()
  }, [displayState])

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["oneTransport", queryParam],
    queryFn: () => fetchOneTransportData(queryParam, year, month + 1, day),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
    select: ({ data }) => {
      return {
        pickup_data: data?.pickup?.map((item, i) => {
          return {
            ...item,
            id: item?.id || new Date().getTime() + i,
            key: item?.id || new Date().getTime() + i,
            child_name: item?.child_name,
            childId: item?.child_id,
            facility_name: item?.facility_name_short,
            desired_pickup_time: item?.desired_pickup_time,
            picked_up_time: item?.time,
            arrival_time: item?.arrival_time,
            transportId: item?.child_facility_schedule_id,
            is_present: item?.is_present,
            use_transport: item?.use_transport,
            place_id: item?.place_id,
            place: displayState?.showDaycares
              ? getPlace(
                  item,
                  item?.desired_pick_up_place,
                  +item?.service_type === 2
                )
              : "",
            remarks: item?.remarks,
            route: item?.route,
            driver:
              data?.pickup_driver?.find(
                (driver) =>
                  driver?.driver_staff_id > 0 &&
                  driver?.route === item?.route &&
                  driver?.facility_id === item?.facility_id
              ) || {},
          }
        }),
        dropoff_data: data?.dropoff?.map((item, i) => {
          return {
            ...item,
            id: item?.id || new Date().getTime() + i,
            key: item?.id || new Date().getTime() + i,
            child_name: item?.child_name,
            childId: item?.child_id,
            facility_name: item?.facility_name_short,
            desired_drop_time: item?.desired_drop_time,
            dropped_time: item?.time,
            arrival_time: item?.arrival_time,
            transportId: item?.child_facility_schedule_id,
            is_present: item?.is_present,
            use_transport: item?.use_transport,
            place_id: item?.place_id,
            route: item?.route,
            place: displayState?.showDaycares
              ? getPlace(
                  item,
                  item?.desired_drop_off_place,
                  +item?.service_type === 2
                )
              : "",
            remarks: item?.remarks,
            driver:
              data?.dropoff_driver?.find(
                (driver) =>
                  driver?.driver_staff_id > 0 &&
                  driver?.route === item?.route &&
                  driver?.facility_id === item?.facility_id
              ) || {},
          }
        }),
        driver_info: data?.driver_info,
      }
    },
    onSuccess: (data) => {
      setOneTransportData(data)
    },
  })

  return (
    <PageContainer>
      <PageCard title={t("Operation options")}>
        <div className={"flex col"}>
          <div className={"operation-form"}>
            <OperationRow
              title={"Driver's Entry"}
              stateHook={"driversEntry"}
              operationFormState={operationFormState}
              setOperationFormState={setOperationFormState}
            />
            <OperationRow
              title={"Show daycare centers"}
              stateHook={"showDaycares"}
              operationFormState={operationFormState}
              setOperationFormState={setOperationFormState}
            />
            <OperationRow
              title={"Show Vehicle / Driver"}
              stateHook={"showVehicles"}
              operationFormState={operationFormState}
              setOperationFormState={setOperationFormState}
            />
            <div className={"btns-grid"}>
              <OwnerButton
                typeOf={"search"}
                onClick={() =>
                  setDisplayState(() => ({
                    driversEntry: operationFormState.driversEntry,
                    showDaycares: operationFormState.showDaycares,
                    showVehicles: operationFormState.showVehicles,
                  }))
                }
                text={t("Change display")}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Unfilter")}
                onClick={() => {
                  setDisplayState({ ...initialFormValues })
                  setOperationFormState({ ...initialFormValues })
                }}
              />
            </div>
          </div>
          <Divisor />
          <PrintButtonsSection>
            <OwnerButton
              typeOf={"draft"}
              text={"<< " + t("Back")}
              onClick={() => router.back()}
            />
            <OwnerButton
              text={t("Print")}
              icon={"print"}
              onClick={() => window.print()}
            />
          </PrintButtonsSection>
        </div>
        <div className={"info-section"} ref={printContentRef}>
          <div>
            <Typography.Title
              level={4}
              className={"title-typ"}
              style={{
                textAlign: "center",
              }}
            >
              {t(
                "{{year}}年{{month}}月{{day}}日（{{weekday}})’s transportation management",
                {
                  year,
                  month: (month + 1).toString().padStart(2, "0"),
                  day: day.toString().padStart(2, "0"),
                  weekday: WEEKLONGERDAYS[date.getDay()],
                }
              )}
            </Typography.Title>
          </div>

          <div
            style={{
              pageBreakAfter: "always",
            }}
          >
            <DriversSection
              show={displayState.showVehicles}
              driverName={
                oneTransportData?.driver_info?.driver_staff_name || ""
              }
              vehicleName={oneTransportData?.driver_info?.car_name || ""}
              vehicleNumber={oneTransportData?.driver_info?.car_number || ""}
            />
            <PickupTable
              data={oneTransportData?.pickup_data}
              loading={isLoading || isFetching}
              displaySettings={displayState}
            />
            <DropoffTable
              data={oneTransportData?.dropoff_data}
              loading={isLoading || isFetching}
              displaySettings={displayState}
            />
          </div>
          <div
            className={"footer-section"}
            style={{
              pageBreakBefore: "always",
            }}
          >
            <div
              style={{ display: displayState.driversEntry ? "unset" : "none" }}
            >
              <Typography.Title
                level={5}
                className={"entry-column-title"}
                style={{
                  textAlign: "center",
                }}
              >
                {t("Driver's entry column")}
              </Typography.Title>
            </div>

            <DriverEntry show={displayState.driversEntry} />
            <Approval />
          </div>
        </div>
      </PageCard>
    </PageContainer>
  )
}
