import Link from "next/link"

// packages
import { t } from "i18next"

// icons
import { StarFilled } from "@ant-design/icons"

// types
import { ColumnsType } from "antd/es/table"
import { ProgressProgramRecordDataProps } from "../types"
import { PseudoEditLink } from "../styles"

export const useTable = () => {
  const column: ColumnsType<ProgressProgramRecordDataProps> = [
    {
      title: t("Program  Category"),
      dataIndex: "program_category_name",
      key: "program_category_name",
      className: "program-category-column",
      width: 288,
    },

    {
      title: t("Overall goal"),
      dataIndex: "overall_goal",
      key: "overall_goal",
      width: 289,
    },

    {
      title: t("Review column"),
      dataIndex: "review_column",
      key: "review_column",
      width: 357,
    },

    {
      title: t("Goal achievement"),
      dataIndex: "goal_achievement",
      key: "goal_achievement",
      width: 140,
      className: "goal-achievement-column",
      render: (goalAchievement) => {
        return (
          <span>
            {goalAchievement}
            <StarFilled />
          </span>
        )
      },
    },

    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      width: 110,
      className: "no-print",
      render: (_, record) =>
        record?.edit_allowed ? (
          <Link
            href={`/program-progress-record-list/edit/${record.program_progress_id}`}
          >
            <a className={"underline"}>{t("Edit")}</a>
          </Link>
        ) : (
          <PseudoEditLink>{t("Edit")}</PseudoEditLink>
        ),
    },
  ]

  const columnForPrint: ColumnsType<ProgressProgramRecordDataProps> = [
    {
      title: t("Program  Category"),
      dataIndex: "program_category_name",
      key: "program_category_name",
      className: "program-category-column",
      width: 288,
    },

    {
      title: t("Overall goal"),
      dataIndex: "overall_goal",
      key: "overall_goal",
      width: 289,
    },

    {
      title: t("Review column"),
      dataIndex: "review_column",
      key: "review_column",
      width: 357,
    },

    {
      title: t("Goal achievement"),
      dataIndex: "goal_achievement",
      key: "goal_achievement",
      width: 140,
      className: "goal-achievement-column",
      render: (goalAchievement) => {
        return (
          <span>
            {goalAchievement}
            <StarFilled />
          </span>
        )
      },
    },
  ]

  return { column, columnForPrint }
}
