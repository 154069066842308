import React from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import { BillingBottomListInfo, BillingSectionHeader } from "../../molecules"
import { theme } from "@project/shared"
import { Spin, Typography } from "antd"
import { OwnerButton } from "../../atoms"
import { RightOutlined } from "@ant-design/icons"
import Link from "next/link"

const Content = styled.div`
  .select_box_container {
    .select_box {
      display: flex;
      gap: 2rem;
      .text {
        color: ${theme.red3};
        margin-top: 0.5rem;
        font-size: 14px;
      }
      @media screen and (max-width: ${theme.breakpoints.lg}) {
        flex-direction: column;
      }
    }
  }

  .btn_content {
    border: 1px solid ${theme.bgColor2};
    padding: 0.5rem;
    font-size: 14px;
    width: fit-content;
    margin-top: 0.8rem;
  }
  .upper-limit-not-registered-child {
    font-size: 14px;
    color: ${theme.red3};
    margin-bottom: 1.5rem;
  }
  .disabled-link {
    a {
      pointer-events: none;
      cursor: not-allowed;
    }
    opacity: 0.5;
  }
  .confirm-user-fee-container {
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    color: ${theme.red2};
    button {
      padding: 10px 16px;
      background: ${theme.blue5} !important;
    }
  }
`
const BtnStyle = css`
  color: #191919;
  background: rgba(7, 130, 200, 0.2);
  border: 1px solid #0782c8;
  padding: 0.5rem 0.5rem;
`
const OtherContent = styled.div`
  .top {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    span {
      font-size: 15px;
      color: ${theme.red3};
    }
    button {
      ${BtnStyle}
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .middle {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;

    .m_con {
      button {
        ${BtnStyle}
      }
      .text {
        font-size: 15px;
        color: ${theme.red3};
        margin-top: 0.5rem;
      }
    }

    @media screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
    }
  }

  .disabled_link {
    a {
      pointer-events: none;
      cursor: not-allowed;
    }
    opacity: 0.5;
  }
`
const SelectTypeBoxWrapper = styled.div<any>`
  .box_content {
    .box {
      background: ${(props) => (props?.isBgBlue ? theme.blue5 : "")};
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${(props) =>
        props?.isBgBlue ? theme.base : theme.black} !important;
      border: 1px solid
        ${(props) => (props?.isBgBlue ? theme.blue5 : theme.blue5)};
      border-radius: 4px;
      padding: 0 0.4rem;
      min-height: 35px;
      font-size: 16px;
      .txt {
        flex: auto;
        color: ${(props) =>
          props?.isBgBlue ? theme.base : theme.black} !important;
        font-size: 16px;
      }
    }
    .info_text {
      .text {
        margin-top: 0.4rem;
        color: ${theme.red3};
        font-size: 15px;
      }
    }
  }
`

interface IUpperLimitMonthlyDisableWrapper {
  disabled: boolean
}

const UpperLimitMonthlyDisableWrapper = styled.div<IUpperLimitMonthlyDisableWrapper>`
  position: relative;
  .disable-layout {
    z-index: 99;
    background: rgba(245, 245, 245, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    display: ${(props) => (props?.disabled ? "flex" : "none")};
    height: ${(props) => (props?.disabled ? "100%" : "0px%")};
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    text-align: center;
    font-weight: bolder;
    gap: 10px;
    a {
      margin: 20px;
      padding: 10px 16px;
      border-radius: 8px;
      background: ${theme.blue5};
      color: #ffffff;
      font-weight: 400;
    }
  }
`

const BottomButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`

const SelectTypeBox = ({
  text,
  info,
  isBgBlue,
}: {
  text: string
  info?: string
  isBgBlue?: boolean
}): JSX.Element => {
  return (
    <SelectTypeBoxWrapper isBgBlue={isBgBlue}>
      <div className={"box_content"}>
        <div className={"box"}>
          <div className={"txt"}>{text}</div>
          <RightOutlined />
        </div>
        <div className={"info_text"}>
          <Typography.Paragraph className={"text"}>{info}</Typography.Paragraph>
        </div>
      </div>
    </SelectTypeBoxWrapper>
  )
}

export const BillingFormContentTwo = ({
  disabled = false,
  facility_id,
  year,
  month,
  num,
}: {
  disabled?: boolean
  facility_id: number | string
  year: number | string
  month: number | string
  num: number
}) => {
  const { t } = useTranslation()

  return (
    <BillingSectionHeader
      num={num}
      title={t(
        "Create a list of user charges (Please submit to the upper limit management company by the 3rd)"
      )}
      id={"listOfUserCharges"}
      disabled={disabled}
    >
      <div className={"disable-overlay"}></div>
      <Content>
        <div className={"select_box_container"}>
          <div className={"select_box"}>
            <div>
              <Link
                href={`/print-list-users-fees?facility=${facility_id}&year=${year}&month=${month}`}
              >
                <a target={"_blank"}>
                  <SelectTypeBox
                    text={t("Print out the list of user fees")}
                    info={
                      disabled
                        ? t(
                            `Since there are no children of upper limit management companies at joint facilities, "Creation of user burden list" is unnecessary.`
                          )
                        : t(
                            "*If you do not perform upper limit management at your own facility, please print it out and submit it to the upper limit management operator."
                          )
                    }
                  />
                </a>
              </Link>
            </div>
          </div>
          <div className={"select_box"} />
        </div>
      </Content>
    </BillingSectionHeader>
  )
}
export const BillingFormContentThree = ({
  disabled,
  childList,
  facility_id,
  year,
  month,
  registeredChildCount,
  num,
  form_of_provision,
  disablePrintLink,
}: {
  disabled: boolean
  childList: Array<any>
  facility_id: number | string
  year: number | string
  month: number | string
  registeredChildCount: number
  num: number
  form_of_provision: string | number
  disablePrintLink?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <BillingSectionHeader
      num={num}
      title={t(
        "Creation of user burden upper limit management result slip (Please provide the upper limit management office to related offices by the 6th)"
      )}
      id={"userBurdenUpperLimitManagementResultSlip"}
      disabled={disabled}
    >
      <div className={"disable-overlay"}></div>
      <Content>
        <div className={"select_box_container"}>
          {childList?.length > 0 ? (
            <div className={"upper-limit-not-registered-child"}>
              {t(
                "The usage status of children with upper limit management is not registered, or the total cost is registered as 0 yen."
              )}
              <br />
              {childList?.map((child) => (
                <>
                  {"(" + child?.joint_facility_name + ")"}
                  <br />
                  {child?.children?.map((c, index) => (
                    <>
                      {index > 0 ? "," : ""}
                      {c?.child_name}
                    </>
                  ))}
                  <br />
                </>
              ))}
            </div>
          ) : (
            <></>
          )}
          <div className={"select_box"}>
            <div>
              <Link
                href={`/joint-facility-status-management?year=${year}&month=${month}`}
              >
                <a target={"_blank"}>
                  <SelectTypeBox
                    text={t("Registration of usage status of other facilities")}
                    info={t(
                      '※If your facility is an office that manages the maximum amount, please receive a "List of User Burdens" from related facilities and register it.'
                    )}
                  />
                </a>
              </Link>
              <div className={"btn_content"}>
                {t(
                  "Number of people registered in the management results sheet for the maximum user-payment limit for confirmed children {{value}} people",
                  {
                    value: registeredChildCount,
                  }
                )}
              </div>
            </div>
            <div
              className={disabled || disablePrintLink ? "disabled-link" : ""}
            >
              <Link
                href={`/national-health-insurance-billing-form-csv-output?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`}
              >
                <a target={"_blank"}>
                  <SelectTypeBox
                    isBgBlue
                    text={t(
                      "Printing of the user's maximum amount management result slip"
                    )}
                    info={t(
                      "※After registering the usage status of other facilities, please print out and submit to each related facility."
                    )}
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </BillingSectionHeader>
  )
}
export const BillingFormContentFour = ({
  disableRegistrationButton = false,
  disableMunicipalSubcomponentBilling = false,
  childRegCount,
  facility_id,
  year,
  month,
  updateConfirmedUserFee,
  num,
  userFeeConfirmed,
  isCalculating,
}: {
  disableRegistrationButton?: boolean
  disableMunicipalSubcomponentBilling?: boolean
  childRegCount?: number
  facility_id: number | string
  year: number | string
  month: number | string
  updateConfirmedUserFee: () => void
  num: number
  userFeeConfirmed: boolean
  isCalculating: boolean
}) => {
  const { t } = useTranslation()
  return (
    <BillingSectionHeader
      num={num}
      title={t("Determining the user's burden")}
      id={"betermineUserBurden"}
    >
      <Content>
        <div className={"select_box_container"}>
          <div className={"select_box"}>
            <div className={disableRegistrationButton ? "disabled-link" : ""}>
              <Link
                href={`/maximum-amount-management-result-information-management?facility_id=${facility_id}&year=${year}&month=${month}`}
              >
                <a target={"_blank"}>
                  <SelectTypeBox
                    text={t(
                      "Registration of user burden maximum amount management result slip"
                    )}
                    info={t(
                      '※Receive and register the "User Burden Maximum Amount Management Result Sheet".'
                    )}
                  />
                </a>
              </Link>
              <div className={"btn_content"}>
                {t(
                  "Confirmed number of registered children in municipal subcomponent billing amount information management {{value}} people",
                  {
                    value: childRegCount,
                  }
                )}
              </div>
            </div>
            <div
              className={
                disableMunicipalSubcomponentBilling ? "disabled-link" : ""
              }
            >
              <Link
                href={`/municipal-sub-component-billing-amount-information-management?facility_id=${facility_id}&year=${year}&month=${month}`}
              >
                <a target={"_href"}>
                  <SelectTypeBox
                    text={t(
                      "Municipal sub-component billing amount information management"
                    )}
                    info={t(
                      "※ If you need to register the billed amount of the municipal subsidy, please register here"
                    )}
                  />
                </a>
              </Link>

              <div className={"btn_content"}>
                {t(
                  "The number of people registered in the local government auxiliary component billing amount information management for confirmed children is {{value}}",
                  {
                    value: 0,
                  }
                )}
              </div>
            </div>
          </div>
          {!disableMunicipalSubcomponentBilling && !userFeeConfirmed ? (
            <div className={"confirm-user-fee-container"}>
              {t(
                "Please confirm the user's burden in order to create the data to be submitted to the National Health Insurance Federation"
              )}
              <OwnerButton
                typeOf={"print"}
                text={
                  isCalculating
                    ? t(
                        "Depending on the amount of data, you may need to wait for about 2 to 3 minutes"
                      )
                    : t("Confirm user fee")
                }
                onClick={updateConfirmedUserFee}
                isLoading={isCalculating}
                disabled={isCalculating}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Content>
    </BillingSectionHeader>
  )
}

export const BillingFormContentFive = ({
  disableOutputManagementResult = false,
  disabled = false,
  facility_id,
  year,
  month,
  num,
  handleCSVDownload,
  isCSVDownloading,
  form_of_provision,
  isCSVDownloadEnabled,
}: {
  disabled?: boolean
  disableOutputManagementResult?: boolean
  facility_id: number | string
  year: number | string
  month: number | string
  num: number
  handleCSVDownload: (val) => void
  isCSVDownloading: boolean
  form_of_provision: string | number
  isCSVDownloadEnabled: boolean
}) => {
  const { t } = useTranslation()
  return (
    <BillingSectionHeader
      num={num}
      title={t(
        "Output the information to be submitted to the National Health Insurance Federation((Please submit it to the National Health Insurance Federation by the 10th))"
      )}
      id={"outputInformationNHIF"}
      disabled={disabled}
    >
      <div className={"disable-overlay"}></div>
      {isCSVDownloading && (
        <div>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255,255,255,0.6)",
            }}
          >
            <div
              style={{
                textAlign: "center",
                zIndex: 999,
                background: theme.yellow4,
                borderRadius: "1rem",
              }}
            >
              <Spin size={"large"} style={{ padding: "1rem" }} />
              <div style={{ padding: "1rem" }}>
                <p>
                  {t(
                    "Depending on the amount of data, you may need to wait for about 2 to 3 minutes"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <OtherContent>
        <div className={"top"}>
          <Typography.Text>
            {t(
              "※Please do not open the submitted data. Also, do not change the file name."
            )}
          </Typography.Text>
        </div>
        <div className={"middle"}>
          <div className={"m_con"}>
            <OwnerButton
              text={t(
                "Output of bills  for children with disabilities &  others"
              )}
              shape={"circle"}
              icon={"excel"}
              onClick={() => handleCSVDownload(1)}
              disabled={!isCSVDownloadEnabled}
            />
            <Typography.Paragraph className={"text"}>
              {t(
                "※Submission to the National Health Insurance Federation is required."
              )}
            </Typography.Paragraph>
          </div>
          <div className={"m_con"}>
            <OwnerButton
              text={t("Output of service provision record sheet")}
              shape={"circle"}
              icon={"excel"}
              onClick={() => handleCSVDownload(2)}
              disabled={!isCSVDownloadEnabled}
            />
            <Typography.Paragraph className={"text"}>
              {t(
                "※Submission to the National Health Insurance Federation is required."
              )}
            </Typography.Paragraph>
          </div>
          <div
            className={`m_con ${
              disableOutputManagementResult ? "disabled_link" : ""
            }`}
          >
            <Link
              href={`/national-health-insurance-billing-form-csv-output?facility_id=${facility_id}&year=${year}&month=${month}&after_school=${form_of_provision}`}
            >
              <a target={"_blank"}>
                <SelectTypeBox
                  text={t(
                    "Output of the maximum user fee management result sheet"
                  )}
                  info={t(
                    "※Please submit if there is a user or applicable person who is managing the upper limit at your facility."
                  )}
                  isBgBlue
                />
              </a>
            </Link>
          </div>
        </div>
      </OtherContent>
    </BillingSectionHeader>
  )
}

export const BillingListSection = ({
  disableListUserCharges,
  facility_id,
  year,
  month,
  usageStatusOfOtherFacilities,
  userBurdenDeterminant,
  confirmedUserFee,
  setConfirmedUserFee,
  incrementStep = false,
  selectedChildId = [],
  disableOverlayMaximumBurden = false,
  showBillingDifferenceLink = false,
  showButtomButton = false,
  buttonText = "",
  handleButtonClick,
  handleCSVDownload,
  isCSVDownloading = false,
  isCalculating = false,
  form_of_provision = 0,
  isCSVDownloadEnabled,
}: {
  disableListUserCharges: boolean
  facility_id: number | string
  year: number | string
  month: number | string
  usageStatusOfOtherFacilities: any
  userBurdenDeterminant: any
  confirmedUserFee: boolean
  setConfirmedUserFee: (val) => void
  incrementStep?: boolean
  selectedChildId?: Array<any>
  disableOverlayMaximumBurden?: boolean
  showBillingDifferenceLink?: boolean
  showButtomButton?: boolean
  buttonText?: string
  handleButtonClick?: () => void
  handleCSVDownload: (val) => void
  isCSVDownloading?: boolean
  isCalculating: boolean
  form_of_provision: number | string
  isCSVDownloadEnabled: boolean
}) => {
  const { t } = useTranslation()
  const disableOutputManagementResult =
    usageStatusOfOtherFacilities?.disablePrintMaximumAmountManagementResult

  return (
    <>
      <BillingFormContentTwo
        disabled={disableListUserCharges}
        facility_id={facility_id}
        year={year}
        month={month}
        num={incrementStep ? 3 : 2}
      />
      <BillingFormContentThree
        disabled={
          usageStatusOfOtherFacilities?.disableRegistrationOfUsageOfJointFacilities
        }
        disablePrintLink={
          usageStatusOfOtherFacilities?.disablePrintMaximumAmountManagementResult
        }
        childList={usageStatusOfOtherFacilities?.children}
        registeredChildCount={
          usageStatusOfOtherFacilities?.registeredChildCount || 0
        }
        facility_id={facility_id}
        year={year}
        month={month}
        num={incrementStep ? 4 : 3}
        form_of_provision={form_of_provision}
      />
      <BillingFormContentFour
        disableRegistrationButton={
          userBurdenDeterminant?.disableRegistrationButton
        }
        childRegCount={userBurdenDeterminant?.childRegCount || 0}
        disableMunicipalSubcomponentBilling={
          userBurdenDeterminant?.disableMunicipalSubcomponentBilling
        }
        facility_id={facility_id}
        year={year}
        month={month}
        updateConfirmedUserFee={() => setConfirmedUserFee(true)}
        num={incrementStep ? 5 : 4}
        userFeeConfirmed={confirmedUserFee}
        isCalculating={isCalculating}
      />
      <UpperLimitMonthlyDisableWrapper disabled={disableOverlayMaximumBurden}>
        {disableOverlayMaximumBurden ? (
          <div className={"disable-layout"}>
            <img
              src={"/assets/icons/exclamation.png"}
              alt={"alert"}
              height={25}
              width={25}
            />
            {t(
              'When making a claim to the National Health Insurance Federation on "school holidays",'
            )}
            <br />
            {t(
              'It is necessary to change the user\'s maximum monthly burden to the "after school" user burden amount.'
            )}
            <br />
            {t('Please press "Change upper limit monthly amount".')}
            <Link
              href={`/national-health-insurance-billing/change-upper-limit?facility_id=${facility_id}&year=${year}&month=${month}`}
            >
              <a>{t("Change the upper limit of monthly burden")}</a>
            </Link>
          </div>
        ) : (
          <></>
        )}
        <BillingFormContentFive
          disableOutputManagementResult={disableOutputManagementResult}
          disabled={!confirmedUserFee}
          facility_id={facility_id}
          year={year}
          month={month}
          num={incrementStep ? 6 : 5}
          handleCSVDownload={handleCSVDownload}
          isCSVDownloading={isCSVDownloading}
          form_of_provision={form_of_provision}
          isCSVDownloadEnabled={isCSVDownloadEnabled}
        />
        <BillingBottomListInfo
          disabled={!confirmedUserFee}
          selectedChildId={selectedChildId}
          showBillingDifferenceLink={showBillingDifferenceLink}
          facility_id={facility_id}
          year={year}
          month={month}
          form_of_provision={form_of_provision}
        />
        {showButtomButton &&
          buttonText &&
          typeof handleButtonClick != "undefined" && (
            <BottomButton>
              <OwnerButton text={buttonText} onClick={handleButtonClick} />
            </BottomButton>
          )}
      </UpperLimitMonthlyDisableWrapper>
    </>
  )
}
