import React from "react"

// packages
import { t } from "i18next"

// components
import {
  Grid,
  SelectInput,
  TextField,
  TopRoundedWhiteCard,
  dynamicLangString,
} from "@project/shared"
import * as yup from "yup"

// styled components
import { ActualCostBurdenRegistrationContainer } from "./styles"
import { StyledForm } from "../../../utils"

import { useFormik } from "formik"
import { OwnerButton } from "../../atoms"
import { useMutation, useQuery } from "react-query"
import { fetchAllActualCost } from "../../../services/actualCost"
import { notification } from "antd"
import {
  editActualCostBurdenRecord,
  saveActualCostBurdenRecord,
} from "../../../services/actualCostBurdenRecord"
import moment from "moment"
import { useRouter } from "next/router"
import styled from "styled-components"

export const ActualCostSelectContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  flex-wrap: wrap;

  @media screen and (max-width: 426px) {
    width: 100%;
    gap: 0;
    div:last-child {
      margin-top: 10px;
    }

    .input-assessment-wrapper {
      width: 100% !important;
    }
  }
`

export const ActualCostBurdenRegistrationPage = ({
  acutalCostEditdata,
  currentDate,
  currentFacility,
}: {
  acutalCostEditdata?: any
  currentDate: Date
  currentFacility: {
    label: string
    value: any
  }
}) => {
  const date = moment(currentDate)
  const router = useRouter()

  const { data: actualCostData, isLoading } = useQuery<any, Error>({
    queryKey: ["actual-costs"],
    queryFn: () => fetchAllActualCost({ page: 1, pageSize: 100 }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: (response) => {
      return response?.data?.map((data) => {
        return {
          key: data.id,
          value: data.id,
          label: data.actual_cost_name,
          itemName: data.actual_cost_name,
          facility_id: data?.facility_id,
          child_development_support: data?.child_development_support,
          billingFacilities: JSON.parse(data?.billing_using_facility) || [],
        }
      })
    },
  })

  const { isLoading: isUpdating, mutate: createActualCostRecord } = useMutation(
    saveActualCostBurdenRecord,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Actual Cost", "Created Successfully"]),
        })
        router.push(
          `/actual-cost-burden?year=${date.year()}&month=${
            date.month() + 1
          }&day=${date.date()}&facilityId=${currentFacility?.value}`
        )
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { isLoading: isEditing, mutate: editActualCostRecord } = useMutation(
    editActualCostBurdenRecord,
    {
      onSuccess: ({ data }) => {
        if (data?.actual_cost_record?.id === acutalCostEditdata?.data?.id) {
          notification.success({
            message: `${t("Actual Cost updated Successfully")}`,
          })
        }
        router.push(
          `/actual-cost-burden/${
            data?.actual_cost_record?.id
          }/details?year=${date.year()}&month=${
            date.month() + 1
          }&day=${date.date()}&facilityId=${currentFacility.value}`
        )
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const validationSchema = yup.object().shape({
    actualCostItemCustomName: yup.string().when("actualCostItemId", {
      is: (actualCostItemId) => actualCostItemId === 0,
      then: yup.string().required("Field is required"),
    }),
  })
  const formik = useFormik({
    initialValues: {
      date: acutalCostEditdata?.data?.date
        ? moment(acutalCostEditdata?.data?.date)
        : moment(currentDate),
      facility_name:
        acutalCostEditdata?.data?.facility?.facility_name_short ||
        currentFacility?.label,
      actualCostItemCustomName:
        acutalCostEditdata?.data?.actual_cost_detail || "",
      actualCostItemId: acutalCostEditdata?.data?.actual_cost_id || null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!acutalCostEditdata?.data?.id) {
        createActualCostRecord({
          facility_id: currentFacility.value,
          actual_cost_id: values.actualCostItemId,
          actual_cost_detail: values.actualCostItemCustomName,
          date: date.format("YYYY-MM-DD"),
        })
      } else {
        editActualCostRecord({
          id: acutalCostEditdata?.data?.id,
          facility_id: currentFacility.value,
          actual_cost_id: values.actualCostItemId,
          actual_cost_detail: values.actualCostItemCustomName,
          date: date.format("YYYY-MM-DD"),
        })
      }
    },
  })
  const actualCostOptions = actualCostData ? actualCostData : []

  return (
    <ActualCostBurdenRegistrationContainer>
      <TopRoundedWhiteCard
        className={"white-card"}
        title={t("Register actual cost item")}
      >
        <div className={"data-registration-content"}>
          <StyledForm>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Date")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {formik.values.date?.format("YYYY年MM月DD日")}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Facility")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {formik.values.facility_name}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Actual cost item name")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <ActualCostSelectContainer>
                {!isLoading && (
                  <SelectInput
                    options={[
                      {
                        label: t("Register actual expenses individually"),
                        value: 0,
                      },
                      ...actualCostOptions,
                    ]}
                    width={300}
                    height={40}
                    onChange={(value) => {
                      formik.setFieldValue("actualCostItemId", value)
                      if (value !== 0) {
                        formik.setFieldValue("actualCostItemCustomName", "")
                      }
                    }}
                    value={formik.values.actualCostItemId}
                    className={"select"}
                  />
                )}
                <TextField
                  name={"facility_name_furigana"}
                  height={"40px"}
                  width={"400px"}
                  value={formik.values.actualCostItemCustomName}
                  disabled={!(formik.values.actualCostItemId === 0)}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "actualCostItemCustomName",
                      e.target.value
                    )
                  }
                />
              </ActualCostSelectContainer>
            </Grid>
          </StyledForm>

          <div className={"action-btn-group flexbox"}>
            <OwnerButton
              typeOf={"primary"}
              text={t("Save")}
              isLoading={isUpdating || isEditing || isLoading}
              onClick={() => {
                formik.handleSubmit()
              }}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Cancel")}
              onClick={() => router.back()}
            />
          </div>
        </div>
      </TopRoundedWhiteCard>
    </ActualCostBurdenRegistrationContainer>
  )
}
