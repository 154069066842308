import { API, removeBlankAttributes } from "@project/shared"

export const getMonitoring = async (id: string, child?: string) => {
  const params = removeBlankAttributes({ child })
  return API.get(`/child-monitoring/${id}`, {
    params,
  })
}

export const createMonitoring = (data: any) =>
  API.post(`/child-monitoring`, data)

export const updateMonitoring = async ({ data, id }) => {
  await API.put(`/child-monitoring/${id}`, data)
}

export const getAllChild = ({ queryKey }) => {
  let url = `/child`
  if (queryKey[1] === "infinity") {
    url = `/child?pageSize=Infinity`
  }
  return API.get(url)
}
export const getChildById = (param) => {
  const id = param.queryKey[1]
  return API.get(`/child/${id}`)
}
export const getMonitoringChildId = ({
  childId,
  createCount,
}: {
  childId: number
  createCount: number
  operation?: string
}) => {
  const params = removeBlankAttributes({
    create_count: createCount,
    from: "monitoring",
  })
  return API.get(`/child-support-plans/child/${childId}`, {
    params,
  })
}

export const getAllInstructors = (pageSize?: any) => {
  const url = pageSize ? `instructors?pageSize=${pageSize}` : `instructors`
  return API.get(url)
}

export const getAllStaffs = () => {
  const url = `instructors?pageSize=Infinity`
  return API.get(url)
}

// monitoring individual detail signature update
interface SigntureReqbj {
  digital_signature_image?: string //parent signature
  child_digital_signature_image?: string
  manager_digital_signature_image?: string
  manager_date_digital_signature_image?: string
  explanation_date_digital_signature_image?: string
  consent_date_digital_signature_image?: string
  id: number
}
export const updateSignatureOfMonitoringDetail = (values: SigntureReqbj) => {
  const data = removeBlankAttributes(values)
  return API.put(`/child-monitoring/update-signature/${values?.id}`, data)
}

export const deleteMonitoring = async (id: string) =>
  API.delete(`/child-monitoring/${id}`)
