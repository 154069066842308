// packages
import cuid from "cuid"
import moment from "moment"

// types
import { FormikProps } from "formik"
import { RawTableDataProps, RecTableDataSourcesProps } from "../types"

export const getTableRowData: (
  data: RawTableDataProps[],
  currentDate: moment.Moment,
  formik: FormikProps<RecTableDataSourcesProps>
) => void = (data, currentDate, formik) => {
  const { setFieldValue } = formik
  const selectedDate = moment(currentDate)
  const totalDays = selectedDate.daysInMonth()

  // If there is no data coming from API, then set the
  // dummy initial-values/table-row-data for each day of the month.
  // also if yearMonth is not equal to current month then set the
  // dummy initial-values/table-row-data for each day of the month.
  if (
    data?.length === 0 ||
    selectedDate.format("YYYY-MM") !==
      moment(data?.length > 0 && data[0].date).format("YYYY-MM")
  ) {
    formik.setFieldValue("individualDayRecords", {})
    for (let i = 1; i <= totalDays; i++) {
      const uniqueKey = cuid()
      const dayOfWeek = currentDate.clone().date(i).format("ddd")
      if (dayOfWeek === "土" || dayOfWeek === "日") {
        setFieldValue(`individualDayRecords.${uniqueKey}`, {
          key: uniqueKey,
          day: i,
          day_of_week: dayOfWeek,
          is_holiday: true,
          date: moment(currentDate).date(i).format("YYYY-MM-DD"),
          goal_achievement: 0,
        })
      } else {
        setFieldValue(`individualDayRecords.${uniqueKey}`, {
          key: uniqueKey,
          day: i,
          day_of_week: dayOfWeek,
          date: moment(currentDate).date(i).format("YYYY-MM-DD"),
          program_id: undefined,
          details: "",
          goal_achievement: 0,
          next_goal: "",
          staffId: null,
          is_holiday: false,
          furigana_sort: null,
        })
      }
    }
  }
  // If there is data coming from API, then set the
  // initial-values/table-row-data for each day of the month on the basis of the backend-data.
  else {
    formik.setFieldValue("individualDayRecords", {})
    data?.forEach((item) => {
      const uniqueKey = cuid()
      const day = moment(item.date).date()
      const dayOfWeek = currentDate.clone().date(day).format("ddd")
      if (dayOfWeek === "土" || dayOfWeek === "日") {
        setFieldValue(`individualDayRecords.${uniqueKey}`, {
          key: uniqueKey,
          day: day,
          day_of_week: dayOfWeek,
          is_holiday: true,
          date: moment(currentDate).date(day).format("YYYY-MM-DD"),
          goal_achievement: 0,
        })
      } else {
        setFieldValue(`individualDayRecords.${uniqueKey}`, {
          key: uniqueKey,
          day: day,
          day_of_week: dayOfWeek,
          date: moment(currentDate).date(day).format("YYYY-MM-DD"),
          program_id: item.program_id,
          details: item.details,
          goal_achievement: item.goal_achievement,
          next_goal: item.next_goal,
          staffId: item.staffId,
          is_holiday: false,
          furigana_sort: undefined,
        })
      }
    })
  }
}
