import React, { useEffect } from "react"

// packages
import { t } from "i18next"
import { useFormik } from "formik"

// components
import {
  Checkboxes,
  SelectInput,
  TopRoundedWhiteCard,
  getBusinessRegistrationYears,
} from "@project/shared"
import { OwnerButton } from "../../atoms"

// styled components
import { OperationWrapper } from "./styles"

// types
import { IMonthlyUsageChartProps } from "./types"

export const MonthlyUsageChartOperation: React.FC<IMonthlyUsageChartProps> = ({
  facilityOpt,
  initialValues,
  serviceTypeOpt,
  handleDisplayChange,
  formOfProvisionOpt,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleDisplayChange({
        ...values,
        multiType: values?.multiType,
        prefId: values?.prefId,
        prefId2: values?.prefId2,
        form_of_provision: values?.service_type?.includes(1)
          ? values?.form_of_provision
          : [],
        service_type:
          values?.service_type?.length > 0 ? values?.service_type : [1, 2],
      })
    },
  })
  useEffect(() => {
    formik.resetForm({
      values: initialValues,
    })
  }, [initialValues?.service_type, initialValues?.form_of_provision])
  return (
    <OperationWrapper className={"no-print"}>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <div className={"operation-container"}>
          <div className={"option-row"}>
            <div className={"caret-right operation-label"}>{t("Facility")}</div>
            <div className={"operation-option"}>
              <SelectInput
                width={"auto"}
                className={"dynamic-dropdown-width min-w-150"}
                name={"facility"}
                dropdownMatchSelectWidth={false}
                options={facilityOpt}
                defaultValue={initialValues.facility}
                onChange={(e) => {
                  const facilityProps = facilityOpt.find(
                    (item) => item.value === e
                  )
                  formik.setFieldValue("multiType", facilityProps?.multiType)
                  formik.setFieldValue("prefId", facilityProps?.prefId)
                  formik.setFieldValue("prefId2", facilityProps?.prefId2)
                  formik.setFieldValue("facility", e)
                }}
                placeholder={t("All")}
              />
            </div>
          </div>
          <div className={"option-row"}>
            <div className={"caret-right operation-label"}>{t("Year")}</div>
            <div className={"operation-option"}>
              <div className={"flexbox"}>
                <div className={"flex-year-container"}>
                  <SelectInput
                    width={80}
                    options={getBusinessRegistrationYears()}
                    name={"year"}
                    defaultValue={initialValues.year}
                    onChange={(e) => {
                      formik.setFieldValue("year", e)
                    }}
                  />
                  <span>{"年"}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={"option-row services-wrapper flexbox"}>
            <div className={"flexbox services-wrapper_flex"}>
              <div className={"caret-right operation-label"}>
                {t("Displaying service")}
              </div>
              <div className={"operation-option"}>
                <div className={"checkbox-wrapper"}>
                  <Checkboxes
                    key={"service_type"}
                    name={"service_type"}
                    options={serviceTypeOpt}
                    value={formik.values.service_type}
                    onChange={(e) => {
                      formik.setFieldValue("service_type", e)
                    }}
                  />
                </div>
              </div>
            </div>

            {formik?.values?.service_type?.includes(1) && (
              <div className={"flexbox services-wrapper_flex"}>
                <div className={"caret-right operation-label"}>
                  {t("After school service detailed display settings")}
                </div>
                <div className={"operation-option"}>
                  <div className={"checkbox-wrapper"}>
                    <Checkboxes
                      key={"footerInformation"}
                      name={"form_of_provision"}
                      options={formOfProvisionOpt}
                      value={formik.values.form_of_provision}
                      onChange={(e) => {
                        formik.setFieldValue("form_of_provision", e)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"change-display-container"}>
          <OwnerButton
            text={t("Update display")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
