import { theme } from "@project/shared"
import styled from "styled-components"
export const Wrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  width: 100%;
  .main_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`

export const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 1px solid ${theme.gray2};
  border-radius: 12px;
  padding: 1rem;
  .btns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: fit-content;
    .button {
      padding: 6px 8px;
    }
  }
  .search_btn {
    width: fit-content;
  }
  .ant-checkbox-group {
    label {
      span:last-child {
        word-break: break-all;
        text-indent: 0;
      }
    }
  }
`
export const ContentCTABtns = styled.div`
  display: flex;
  gap: 0.64rem;
  flex-wrap: wrap;
  button {
    padding: 8px 12px;
  }
`

export const InfoTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
