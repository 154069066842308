import styled from "styled-components"

export const Container = styled.div`
  background-color: #fff;
  padding: 12px 24px;
  border-radius: 8px;

  p {
    text-align: center;
  }

  .form {
    display: block;
    text-align: end;
  }

  .longReport {
    margin-top: 24px;
  }

  .record {
    display: block;
    text-align: center;
  }

  .second_container_title {
    margin-top: 24px;
    text-align: start;
  }

  .third_container_title {
    margin-top: 24px;
    text-align: start;
  }

  .miyazaki_communcal_container {
    text-align: start;
  }

  .fourth_container_title {
    margin-top: 24px;
    text-align: start;
  }

  .form_article {
    font-size: 10pt;
  }

  @media print {
    @page {
      margin: 0px;
      margin-top: 20px;
    }
    padding: 0px;
    page-break-inside: avoid;
  }
`

export const FirstContainer = styled.div`
  display: flex;
  overflow: auto;
`

interface LeftDivProps {
  noBorder?: boolean
}

export const LeftDiv = styled.div<LeftDivProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  border: ${(props) => (props?.noBorder ? "none" : "1px dotted #000")};
  padding: 8px 4px;
  margin-right: 30px;
  min-width: 300px;
  @media print {
    width: auto;
    min-width: 200px;
  }
`

export const RightDiv = styled.div`
  min-width: 600px;
  @media print {
    min-width: auto;
    width: 100%;
  }
  span {
    display: block;
    text-align: right;
  }
  .first_table {
    width: 100%;

    & tbody > tr {
      & th {
        padding: 8px 4px;
        border: 1px solid #000;
        font-size: 9pt;
        font-weight: normal;
      }
    }

    .first_table_header {
      background-color: #dcdada;
      min-width: 15px;
    }
  }
`

export const SecondContainer = styled.div<{ tableData: number }>`
  margin-top: 6px;
  overflow: auto;

  .second_table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;

    & tbody > tr {
      & th {
        padding: 8px 4px;
        border: 1px solid #000;
        font-size: 9pt;
        font-weight: normal;
      }
    }
    .second_table_header {
      background-color: #dcdada;
      min-width: 15px;
    }
  }
  .check-wrapper {
    display: flex;
    gap: 10px;
  }
  .check_container {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    span {
      font-size: 9pt;
    }
  }
`

export const ThirdContainer = styled.div`
  margin-top: 6px;
  overflow: auto;

  .reason {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .reason_container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
    .solid {
      position: absolute;
      top: 50%;
      width: 100%;
      left: 0;
      border-top: 1px solid #000;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }
  }

  .contract_date_header {
    display: flex;
    flex-direction: column;

    span:last-child {
      font-size: 12px;
    }
  }

  .third_table {
    table {
      border-collapse: collapse !important;
    }
    thead {
      tr {
        th {
          font-size: 14px;
          border: 1px solid black !important;
          @media print {
            outline: none !important;
            background: transparent;
          }
        }
      }
    }
    tbody {
      tr {
        page-break-inside: avoid;
        td {
          border: 1px solid black !important;
          border-top: 0px;
        }
        > :last-child {
          border-right: 1px solid black !important;
        }
      }
    }

    .ant-table.ant-table-bordered > .ant-table-container {
      border-left: none;
    }

    .first_column_first_header {
      padding: 10px;
      min-width: 250px;
      @media print {
        font-size: 12px;
        min-width: auto;
      }
    }
    .first_column_second_header {
      padding: 10px;
      min-width: 200px;
      @media print {
        font-size: 12px;
        min-width: auto;
      }
    }
    .first_column_third_header {
      padding: 10px;
      min-width: 100px;
      @media print {
        font-size: 12px;
        min-width: auto;
      }
    }
    .first_column_fourth_header {
      padding: 10px;
      min-width: 270px;
      @media print {
        font-size: 12px;
        min-width: auto;
      }
    }
    .first_column_fifth_header {
      padding: 10px;
      min-width: 200px;
      @media print {
        font-size: 12px;
        min-width: 140px;
      }

      border-right: 1px solid black !important;
    }
  }
`

export const FourthContainer = styled.div`
  margin-top: 6px;
  overflow: auto;

  .reason {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .reason_container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
    .solid {
      position: absolute;
      top: 50%;
      width: 100%;
      left: 0;
      border-top: 1px solid #000;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }
  }

  .fourth_table {
    table {
      border-collapse: collapse !important;
    }
    thead {
      tr {
        th {
          font-size: 14px;
          border: 1px solid black !important;
          @media print {
            outline: none !important;
            background: transparent;
          }
        }
      }
    }
    tbody {
      tr {
        page-break-inside: avoid;
        td {
          border: 1px solid black !important;
          border-top: 0px;
        }
      }
      > tr > td:last-child {
        border-right: 1px solid black !important;
      }
    }

    .ant-table.ant-table-bordered > .ant-table-container {
      border-left: none;
    }

    .second_column_first_header {
      padding: 10px;
      min-width: 200px;
      @media print {
        min-width: auto;
      }
    }
    .second_column_second_header {
      padding: 10px;
      min-width: 200px;
      @media print {
        min-width: auto;
      }
    }
    .second_column_third_header {
      padding: 10px;
      min-width: 250px;
    }
    @media print {
      min-width: auto;
    }
    .second_column_fourth_header {
      padding: 10px;
      min-width: 220px;
      @media print {
        min-width: auto;
      }
      border-right: 1px solid black !important;
    }
    .second_column_one_dot_two_header {
      padding: 10px;
      min-width: 200px;
      @media print {
        min-width: auto;
      }
    }

    .reason:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
      > span {
        margin-left: 10px;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        display: none;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: black;
        border-color: black;
      }
    }
    .reason:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 9px;
      > span {
        margin-left: 10px;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        display: none;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: black;
        border-color: black;
      }
    }
  }
`
export const FifthContainer = styled.div`
  margin-top: 22px;
  overflow: auto;

  .proxy_table {
    width: 100%;
    min-width: 600px;
    border: 1px solid #000;
    border-collapse: collapse;

    & tbody > tr {
      border: 1px solid #000;
      border-collapse: collapse;
      & th {
        padding: 8px 4px;
        border: 1px solid #000;
        font-weight: normal;
      }
    }
    .second_table_header {
      background-color: #dcdada;
      min-width: 15px;
    }
    .lp-10 {
      padding-left: 10px;
    }
    .no-top-border {
      border-top: none;
    }

    .no-bottom-border {
      border-bottom: none;
    }

    .agreement {
      text-align: center;
    }

    .reiwa {
      text-align: right;
      padding-right: 8px;
    }

    .address {
      text-align: center;
    }

    .name {
      text-align: center;
    }

    .empty {
      width: 50%;
      border: 1px solid #000;
    }
  }
`

export const CheckboxDiv = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: ${(props) => props.color};
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`
