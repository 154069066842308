import { theme } from "@project/shared"
import styled from "styled-components"

export const SectionHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.gray2};
  padding-bottom: 12px;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  .section-head-button {
    background: white;
    border: 1px solid ${theme.gray2};
    border-radius: 100px;
    padding: 10px 15px;
    margin: 0px;
  }
  .section-head-h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #191919;
  }
  .section-head-p {
    color: ${theme.red2};
    margin: 0px 15px;
  }
  @media (max-width: 1247px) {
    flex-direction: column;
    align-items: flex-start;
    .section-head-h3 {
      margin: 0px;
      padding: 0px;
    }
    .section-head-p {
      margin: 10px 0px;
    }
  }
`

export const FormButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  & :first-child {
    margin-right: 10px;
  }
  @media (max-width: 810px) {
    justify-content: center;
  }
`

export const StyledTag = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  .facility {
    border: none;
    padding: 10px;
    background: rgba(7, 130, 200, 0.2);
    border-radius: 10px;
    margin: auto 0px;
  }
  span {
    margin-right: 10px;
    margin-left: 10px;
  }
`

export const StyledAmount = styled.p`
  margin-left: 10px;
  margin-bottom: 0px;
`

export const Info = styled.div`
  color: ${theme.red2};
  margin: 20px 0px;
`

export const ColumnWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  .mb-0 {
    margin-bottom: 0px;
  }
`
export const CustomWrapper = styled.div`
  .clear-information-button {
    margin-left: 10px;
    color: ${theme.black} !important;
  }
  .table-date-picker {
    width: 100px !important;
  }
`

export const ContractPaymentOrangeDatePicker = styled.div<any>`
  display: flex;
  align-items: center;
  max-width: 550px;
  padding: 10px;
  margin: 10px 20px;
  flex-wrap: wrap;
  background: ${(props) => (props.nocolor ? "transparent" : theme.orange1)};
  color: ${(props) => (props.nocolor ? "black" : "white")};
  .contract-payment-datepicker {
    width: 150px;
    border-radius: 5px;
  }
  p {
    margin: auto 5px;
  }
  .custom-padded {
    padding-left: 160px;
    @media (max-width: 600px) {
      padding-left: 0px;
    }
  }
`

export const TableWrapper = styled.div`
  .ant-table-content {
    overflow: auto hidden;
  }
  table {
    th {
      border: 1px solid ${theme.gray2} !important;
    }
    th:first-child {
      border-radius: 0px !important;
    }
    th:last-child {
      border-radius: 0px !important;
    }
  }

  .facility-table {
    table {
      /* min-height: 407px; */
    }

    .ant-picker-dropdown {
      .ant-picker-content {
        min-height: 252px;
      }
    }
  }

  .min-width-class {
    min-width: 200px;
  }
`

export const ContractPaymentModalWrapper = styled.div`
  border: 1px solid ${theme.gray2};
  .modal-title {
    background: ${theme.bgColor};
    padding: 20px;
    margin-bottom: 20px;
  }
  .flex {
    display: flex;
    margin-top: 10px;
    p {
      margin-left: 10px;
    }
  }
  .items-center {
    align-items: center;
  }
  .gap-5 {
    gap: 10px;
  }

  .justify-center {
    justify-content: center;
  }
  .m-0 {
    margin: 0px;
  }
  .delete-button {
    color: #979797;
  }

  .flex-end {
    justify-content: flex-end;
  }
  .item {
    padding: 0px 20px;
    margin: 10px 0 0 0;
    table > thead > tr:first-child th:first-child {
      border-radius: 0px !important;
    }
    table > thead > tr:first-child th:last-child {
      border-radius: 0px !important;
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      overflow-x: scroll;
    }
    .title {
      background: #666;
      color: #fff;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 12px;
      border-radius: 2px;
    }
    .contract_end_month {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      margin: 12px 0;
    }
  }
  .save-button {
    border: 1px solid #d2d1d1;
    border-radius: 100px;
    color: ${theme.black};
    background: ${theme.lightBlue2};
  }

  .error-text {
    color: ${theme.red2};
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
  }

  .white-error {
    color: ${theme.base};
  }

  .field-with-error-group {
    width: max-content;
    margin-inline: auto;
    text-align: left;
  }

  .invalid-contract-payout {
    color: ${theme.red2};
  }

  @media (max-width: 768px) {
    overflow-x: scroll;
  }

  .date-picker-field-container {
    & .date-picker-field-content {
      align-items: flex-start;
      gap: 10px;
      .date-picker-label {
        margin-top: 5px;
      }
    }
    p {
      margin: 0;
    }
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  .faculty-datepicker {
    min-width: 150px !important;
    max-width: 150px !important;
    height: 40px;
  }
  .datepicker-seperator {
    margin: 0px 15px;
    font-size: 16px;
    font-weight: 500;
  }
  .children-wrapper {
    .disabled-child-info-wrapper {
      margin-top: 10px;
    }
    .disabled-children-main-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .disabled-child-input {
        display: flex;
        align-items: center;
        input {
          height: 40px;
          width: 80px;
          background: white;
          margin: 0px 10px;
        }
        .label {
          margin: auto;
        }
        .required {
          background: ${theme.red2};
          color: white;
          border-radius: 5px;
          padding: 3px 8px;
          font-size: 12px;
          margin: 0px 10px;
        }
      }
    }
    .beneficiary_certificate_number {
      margin: auto 0px;
      margin-right: 18px;
    }
    .small-select-wrapper {
      display: flex;
      .burden-upper-limit-monthly-amt-select-input.small-select {
        max-width: 100% !important;
        width: auto !important;
      }
    }

    .custom-select {
      min-width: 200px;
      margin-right: 10px;
      .ant-select-selector {
        height: 40px !important;
      }
      .ant-select-selection-item {
        line-height: 36px !important;
      }
      &.over-ride-select-class.pr-15 {
        min-width: 100px !important;
        .ant-select-selector {
          padding-right: 30px !important;
        }
      }
    }
    .small-select {
      min-width: 80px !important;
      max-width: 80px !important;
    }

    .benefit-day-select {
      min-width: 100px !important;
      max-width: 100px !important;
    }

    .medium-select {
      min-width: 200px !important;
      max-width: 200px !important;
    }
    .large-select {
      min-width: 300px !important;
      max-width: 300px !important;
    }
    .danger-info {
      color: ${theme.red2};
    }
    .custom-secondary-button {
      height: 30px;
      font-size: 14px;
      padding: 10px;
    }
    .copy {
      margin-left: 20px;
      height: 30px;
    }
    .payment_municipality_wrapper {
      p {
        margin: 15px 0px;
      }
    }
    .datepicker-wrapper {
      display: flex;
      align-items: center;
    }
    .datepicker-info-wrapper {
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }
    .min-auto-width {
      min-width: 300px;
      width: auto;
    }
    .no-padding {
      padding: 0px;
    }
  }
  @media (max-width: 1530px) {
    .children-wrapper {
      .disabled-children-main-wrapper {
        flex-direction: column;
        align-items: flex-start;
        .disabled-child-input {
          margin: 10px 0px;
        }
        .ant-checkbox-wrapper {
          margin-top: 15px;
        }
      }
    }
  }
  @media (max-width: 1123px) {
    .faculty-datepicker {
      min-width: 100px !important;
    }
  }
  @media (max-width: 406px) {
    .children-wrapper {
      .custom-select {
        width: 100% !important;
      }
      .large-select {
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  @media (max-width: 375px) {
    .children-wrapper {
      .datepicker-wrapper {
        max-width: 100%;
        flex-direction: column;
        .datepicker-seperator {
          margin: auto;
        }
      }
      .faculty-datepicker {
        max-width: 100% !important;
        .ant-picker {
          max-width: 100%;
        }
      }
    }
  }
`

export const ModalInfo = styled.div`
  border: 1px solid ${theme.red2};
  box-shadow: 0px 0px 15px ${theme.rgba.gray};
  border-radius: 12px;
  padding: 20px;
  color: ${theme.red2};
  margin: 20px 0;
`

export const TableSection = styled.div`
  width: 100%;
  button {
    margin-bottom: 25px;
  }
  .table-section-secondary-button {
    min-width: 215px;
    padding: 12px 30px;
  }
  .add-button {
    padding: 0px 10px;
    min-width: 200px;
    border: none;
    border-radius: 5px;
    span {
      margin-left: 5px;
    }
  }
  .table-section-description {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .paragraph {
      margin: 0px 15px;
      color: ${theme.red2};
      min-width: 200px;
    }
    button {
      margin: auto 0px;
      height: 30px;
      font-size: 14px;
    }
    .register-joint-company-button {
      height: auto;
    }
    .table-section-secondary-button {
      width: min-content !important;
      min-width: min-content !important;
      padding: 12px 15px;
    }
    @media (max-width: 1247px) {
      flex-direction: column;
      align-items: flex-start;
      .paragraph {
        margin: 10px 0px;
      }
    }
  }
`
