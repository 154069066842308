import Link from "next/link"
import React, { useCallback } from "react"

// packages
import { t } from "i18next"

// components
import FormFieldsGen from "../../../molecules/FormFields"

// types
import { BankInfoListProps } from "../types"
import { FormFieldsProps } from "../../../molecules/FormFields/types"

const useBankInfoList = (
  {
    directDebitConsignorNo,
    consignorNameKana,
    bankNumber,
    branchNumber,
    accountType,
    accountNumber,
  }: BankInfoListProps,
  selectedTransferAccountId: number
) => {
  const bankInfoListSchema: FormFieldsProps = [
    {
      fieldLabel: "Direct debit consignor number",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: directDebitConsignorNo,
          },
        },
      ],
    },
    {
      fieldLabel: "Consignor name Kana",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: consignorNameKana,
          },
        },
      ],
    },
    {
      fieldLabel: "Bank number",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: bankNumber,
          },
        },
      ],
    },
    {
      fieldLabel: "Branch number",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: branchNumber,
          },
        },
      ],
    },
    {
      fieldLabel: "Account type",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: accountType,
          },
        },
      ],
    },
    {
      fieldLabel: "Account number",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: accountNumber,
          },
        },
      ],
    },
  ]

  const BankInfoList = useCallback(
    (props: React.ComponentPropsWithRef<"div">) => {
      return (
        <div {...props}>
          {bankInfoListSchema.map((item, i) => {
            return <FormFieldsGen key={item.fieldLabel} item={item} index={i} />
          })}

          <Link href={`/facility/edit/${selectedTransferAccountId}`}>
            <a className={"underline link"} target={"_blank"}>
              {t("Click here to update facility information")}
            </a>
          </Link>
        </div>
      )
    },
    [
      bankNumber,
      accountType,
      branchNumber,
      accountNumber,
      consignorNameKana,
      directDebitConsignorNo,
    ]
  )

  return { BankInfoList }
}

export default useBankInfoList
