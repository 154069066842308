import { theme, Grid } from "@project/shared"
import { Card } from "../Card"
import styled from "styled-components"
import { Collapse, Skeleton } from "antd"
import { DownOutlined } from "@ant-design/icons"
import moment from "moment"

export interface IChildData {
  childName: string
  facilityName: string
  date: string[]
  key?: string | number
}

interface ChildTableProps {
  title?: string
  content?: string
  expandTitle?: string
  expandText?: string
  childData?: IChildData[]
  borderColor?: string
  icon?: string
  isPanel?: boolean
  showDate?: boolean
  isLoading: boolean
}

const GridContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .inner-grid-content div {
    color: black;
    white-space: pre-wrap;
  }

  @media screen and (max-width: 426px) {
    .last-container {
      border-left: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      border-top: none;
    }
  }
`

const { Panel } = Collapse

const StyledCollapse = styled(Collapse)<{ borderColor?: string }>`
  @media screen and (max-width: 600px) {
    * {
      font-size: 12px;
    }
    & .ant-collapse-content-box {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    color: ${theme.red2};
    margin-top: 12px;
    text-decoration: underline;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 8px;
  }
  .ant-collapse-item > .ant-collapse-content {
    padding: 0;
    color: ${theme.red1};
  }
`

export const ChildTable = ({
  title,
  content,
  expandTitle,
  icon,
  childData,
  isLoading,
}: ChildTableProps) => {
  return (
    <Card title={title} content={content} icon={icon} borderColor={"#E26827"}>
      <StyledCollapse
        className={"expand-title"}
        ghost
        expandIcon={() => (
          <DownOutlined
            style={{
              verticalAlign: "middle",
            }}
          />
        )}
      >
        <Panel header={expandTitle} key={1}>
          {isLoading ? (
            <GridContainer>
              <Skeleton active />
            </GridContainer>
          ) : (
            <GridContainer>
              {childData?.map((item: IChildData, index: number) => {
                const formattedDates =
                  item?.date && item?.date?.length > 0
                    ? item?.date.map((date) =>
                        moment(date).format("YYYY年MM月DD日")
                      )
                    : []
                return (
                  <>
                    <Grid className={"grid_layout"} width={"200px"} key={index}>
                      <div>{item.childName}</div>
                    </Grid>
                    <Grid className={"grid_layout"} width={"200px"}>
                      <div>{item.facilityName}</div>
                    </Grid>
                    <Grid className={"flex-full grid-row-last last-container"}>
                      <div>{String(formattedDates)}</div>
                    </Grid>
                    <Grid className={"flex-break"}></Grid>
                  </>
                )
              })}
            </GridContainer>
          )}
        </Panel>
      </StyledCollapse>
    </Card>
  )
}
