import { Table } from "@project/shared"
import { t } from "i18next"
import { CityNumber } from "./common/CityNumber"
import { BeneficiaryNumber } from "./common/BeneficiaryNumber"
import { ParentName } from "./common/ParentName"
import { ChildName } from "./common/ChildName"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../../constants"
import { maskName } from "../../../common/utils"

type Props = {
  municipalityNumber: string
  receivingCertificateNumber: string
  childName: string
  parentName: string
  printConfig: string[]
}

export function DefaultChildDetail({
  municipalityNumber,
  receivingCertificateNumber,
  childName,
  parentName,
  printConfig,
}: Props) {
  return (
    <Table
      columns={[
        {
          title: "",
          dataIndex: "label",
          width: "150px",
          render: (text) => <p>{text}</p>,
        },
        {
          width: "335px",
          dataIndex: "value",
          render: (_, val) => {
            switch (val.row) {
              case "city_no":
                // 市区町村番号
                return <CityNumber number={municipalityNumber} />

              case "beneficiary_no":
                // 受給者証番号
                return <BeneficiaryNumber number={receivingCertificateNumber} />

              case "parent_name":
                // 保護者名
                return (
                  <ParentName name={parentName} configuration={printConfig} />
                )

              case "child_name":
                // 児童名
                return (
                  <ChildName name={childName} configuration={printConfig} />
                )

              default:
                return <p>{val.value}</p>
            }
          },
        },
      ]}
      dataSource={[
        {
          key: "1",
          label: t("Provision city No."),
          isNumber: true,
          row: "city_no",
        },
        {
          key: "2",
          label: t("Beneficiary no."),
          row: "beneficiary_no",
        },
        {
          key: "3",
          label: printConfig.includes(
            PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
          )
            ? t(
                "Name of person with a disability for whom payment has been decided"
              )
            : t(
                "Name of person with a disability, etc. whose payment is determined"
              ),
          value: maskName(parentName, printConfig),
          row: "parent_name",
        },
        {
          key: "4",
          label: printConfig.includes(
            PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
          )
            ? t("Name of child with a disability related to payment decision ")
            : t("Name of child with a disability related to payment decision"),
          row: "child_name",
        },
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
