import {
  ACHIEVEMENT_GOAL_TYPE,
  EVALUATION_TYPES,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES,
  SelectInput,
  TextField,
  theme,
} from "@project/shared"
import { Table } from "antd"
import React, { useMemo } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

interface IChildMonitoringContentProps {
  data?: any
  handleFieldChange: (a: any) => void
  isAprilChangeApplicable?: boolean
}

const TableWrapper = styled.div`
  margin-top: 40px;
  th,
  td {
    border: 1px solid ${theme.gray2};
    border-right: none;
  }
  td:nth-child(5),
  td:nth-child(6) {
    vertical-align: middle;
  }
  th:last-child,
  td:last-child {
    border-right: 1px solid ${theme.gray2};
  }
  .input {
    display: inline-block;
    min-width: 115px;
  }

  .text-field {
    margin-top: 10px;
  }
`

const ChildMonitoringContent: React.FC<IChildMonitoringContentProps> = ({
  data,
  handleFieldChange,
  isAprilChangeApplicable = false,
}) => {
  const { t } = useTranslation()
  const COLUMN_DEFINATION = useMemo(() => {
    const cols = [
      {
        title: "",
        dataIndex: "",
        render: (_, __, index) => {
          return <p>{index + 1}</p>
        },
        onCell: (row) => {
          return {
            rowSpan: row?.titleRowSpan,
          }
        },
        width: 60,
        align: "center" as const,
      },
      {
        title: t("Items (personal needs, etc.)"),
        dataIndex: "title",
        width: 210,
        align: "center" as const,
        onCell: (row) => {
          return {
            rowSpan: row?.titleRowSpan,
          }
        },
      },
      {
        title: t("Specific goals"),
        dataIndex: "achievement_goals",
        width: 210,
        align: "center" as const,
      },
      {
        title: t("Guideline items"),
        isAprilChange: true,
        dataIndex: "support_guidelines",
        width: 220,
        render: (data) => {
          if (Array.isArray(data)) {
            return data?.map((eachData) => {
              return (
                <>
                  {
                    INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS?.find(
                      (opt) => opt?.value == eachData?.value
                    )?.label
                  }
                  &nbsp;
                  {eachData?.value ==
                    INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.OTHERS &&
                    eachData?.title}
                  <br />
                </>
              )
            })
          }
          return null
        },
      },
      {
        title: (
          <>
            {t("Service provider organization")}
            <br />
            {t("(provider, person in charge, etc.)")}
          </>
        ),
        dataIndex: "providing_organization",
        width: 250,
        isAprilChange: true,
      },
      {
        title: t("Goal achievement"),
        dataIndex: "achievement_goal_type",
        render: (value, row) => {
          return (
            <>
              <SelectInput
                height={40}
                placeholder={"--"}
                className={"input"}
                options={ACHIEVEMENT_GOAL_TYPE}
                value={value}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => {
                  handleFieldChange({
                    fieldName: "achievement_goal_type",
                    value,
                    id: row.child_monitoring_item_id,
                    index: row.change_index,
                  })
                }}
              />{" "}
              <br />
              {value === 4 && (
                <TextField
                  bgcolor={"white"}
                  value={row?.achievement_goal_type_text}
                  className={"text-field"}
                  onChange={(e) => {
                    handleFieldChange({
                      fieldName: "achievement_goal_type_text",
                      value: e.target.value,
                      id: row.child_monitoring_item_id,
                      index: row.change_index,
                    })
                  }}
                />
              )}
            </>
          )
        },
        width: 120,
      },
      {
        title: t("Evaluation"),
        dataIndex: "evaluation_type",
        render: (value, row) => {
          return (
            <>
              <SelectInput
                height={40}
                placeholder={"--"}
                options={EVALUATION_TYPES}
                className={"input"}
                value={value}
                onChange={(value) => {
                  handleFieldChange({
                    fieldName: "evaluation_type",
                    value,
                    id: row.child_monitoring_item_id,
                    index: row.change_index,
                  })
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
              <br />
              {value === 4 && (
                <TextField
                  bgcolor={"white"}
                  value={row?.evaluation_type_text}
                  className={"text-field"}
                  onChange={(e) => {
                    handleFieldChange({
                      fieldName: "evaluation_type_text",
                      value: e.target.value,
                      id: row.child_monitoring_item_id,
                      index: row.change_index,
                    })
                  }}
                />
              )}
            </>
          )
        },
        width: 120,
      },
      {
        title: t("Consideration"),
        dataIndex: "study",
        render: (data, row) => {
          return (
            <TextField
              type={"textarea"}
              value={data}
              rows={2}
              bgcolor={"white"}
              onChange={(e) => {
                handleFieldChange({
                  fieldName: "study",
                  value: e?.target?.value,
                  id: row.child_monitoring_item_id,
                  index: row.change_index,
                })
              }}
            />
          )
        },
        width: 465,
        align: "center" as const,
      },
      {
        title: t("Priority"),
        dataIndex: "priority",
        isAprilChange: true,
        width: 100,
      },
    ]
    return isAprilChangeApplicable
      ? cols
      : cols.filter((col) => !col.isAprilChange)
  }, [data, isAprilChangeApplicable])

  const formattedDataSource = useMemo(() => {
    const formattedData = []
    data?.forEach((d) => {
      d?.achievement_goals?.forEach((goal, index) => {
        const row: any = {
          ...d,
          title: d?.title,
          titleRowSpan: index == 0 ? d?.achievement_goals?.length : 0,
          achievement_goals: d?.achievement_goals?.[index],
          priority: d?.priority?.[index],
          providing_organization: d?.providing_organization?.[index],
          support_guidelines: d?.support_guidelines?.[index],
          change_index: index,
          achievement_goal_type: d?.achievement_goal_type?.[index],
          achievement_goal_type_text: d?.achievement_goal_type_text?.[index],
          evaluation_type: d?.evaluation_type?.[index],
          evaluation_type_text: d?.evaluation_type_text?.[index],
          study: d?.study?.[index],
        }
        formattedData.push(row)
      })
    })
    return formattedData
  }, [data])

  return (
    <TableWrapper>
      <Table
        columns={COLUMN_DEFINATION}
        dataSource={formattedDataSource}
        pagination={false}
        scroll={{ x: 1300 }}
      />
    </TableWrapper>
  )
}

export { ChildMonitoringContent }
