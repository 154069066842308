import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { fetchProgressWithCount } from "../../../services"
import { useContext, useState } from "react"
import { AuthContext } from "../../../utils"
import { ArrowDiv, FlexContainer, ProgressContainer } from "./index.styles"

interface IProps {
  onFilter?: (string) => void
}

const ProgressComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { collapsed } = useContext(AuthContext)

  //Progress management
  const { data: progressManagementData } = useQuery(
    ["fetch-progress-management-data-3", "1", "Infinity"],
    fetchProgressWithCount,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (response: any) => {
        return {
          data: response?.data,
          count: response?.count,
        }
      },
    }
  )
  const { onFilter } = props
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  return (
    <ProgressContainer>
      <div>{t("※Click on the progress below to narrow down.")}</div>
      <FlexContainer isCenter={collapsed}>
        {progressManagementData?.data?.map((item, index) => (
          <ArrowDiv
            onClick={() => {
              setActiveIndex(index)
              onFilter({ progress_status: item.id })
            }}
            key={item.id}
            isActive={activeIndex === index}
          >
            <div className={"number-container"}>{index + 1}</div>
            <div className={"text-container-1"}>{item.progress_name}</div>
            <div className={"number-container-main"}>
              <div className={"number-container-2"}>{item.total}</div>
              <div className={"text-container-2"}>{"人"}</div>
            </div>
          </ArrowDiv>
        ))}
      </FlexContainer>
    </ProgressContainer>
  )
}

export { ProgressComponent }
