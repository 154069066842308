// Packages
import { Button, Modal } from "antd"
import styled from "styled-components"

// Themes
import { theme } from "@project/shared"

export const MainContainer = styled.div`
  .middle-back-btn {
    margin: 30px 0;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 20px 0;
    }
  }

  .white-card {
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }

  .flexbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    color: #191919;
  }
`

export const ActualExpensesInfoContainer = styled.div`
  margin-top: 40px;
  .white-card {
    padding: 13px 30px 39px 30px;
    .expense-info-content {
      margin-top: 16px;
      .expense-info-fields {
        margin-top: 24px;
      }
    }
    @media (max-width: ${theme.breakpoints.xmd}) {
      padding: 13px 20px 15px 20px;
      .label {
        height: 40px !important;
      }
      .field {
        padding-top: 10px;
      }
    }
  }
`

export const ActualExpensesInfoTableContainer = styled.div`
  margin-top: 30px;
  .expense-info-table-content {
    .button-group {
      margin-top: 16px;
      margin-bottom: 24px;
      @media (max-width: ${theme.breakpoints.md}) {
        column-gap: 10px;
      }
    }
    .total_count {
      margin: 0 !important;
    }
    .main-pagination-wrapper {
      margin: 0 !important;
    }
    .ant-table-wrapper {
      margin-bottom: 24px;
      .ant-table .ant-table-container .ant-table-content table {
        .ant-table-thead tr th {
          background: ${theme.bgColor2};
          border-bottom: 1px solid ${theme.gray2};
          &:first-child {
            border-top-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
          }

          &:before {
            background-color: ${theme.gray2} !important;
          }

          .ant-table-column-sorter {
            display: none;
          }
        }
        .ant-table-tbody tr td {
          border-right: 1px solid ${theme.gray2};
          border-bottom: 1px solid ${theme.gray2};
          &:first-child {
            border-left: 1px solid ${theme.gray2};
          }
          p {
            margin: 0;
          }
          .link-btn {
            margin-inline: auto !important;
            &.delete-link {
              color: ${theme.red3};
            }
          }
        }
      }
    }
    .bottom-pagination-wrapper {
      display: none;
    }

    @media (min-width: 1200px) {
      .info-wrapper {
        margin-top: 0 !important;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      .total_count {
        margin-top: 10px !important;
      }
    }
  }
`

export const StyledEditButton = styled(Button)`
  height: auto;
  width: auto;
  padding: 12px 30px;
  border-radius: 100px;
  border-color: #0782c8;
  background-color: rgba(7, 130, 200, 0.2);
  display: flex;
  align-items: center;
  column-gap: 10px;
  line-height: 100%;
`

export const Tag = styled.div<{ bgcolor?: string; color?: string }>`
  display: grid;
  place-items: center;
  padding: 8px 14px;
  background-color: ${(props) => props.bgcolor};
  border-radius: 5px;
  width: max-content;
  margin-inline: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.color};
`

export const StyledModal = styled(Modal)`
  &.register-user-modal.ant-modal {
    width: 70% !important;
    max-width: 1147px;
    .ant-modal-content {
      border-radius: 0px;
      .ant-modal-close {
        color: ${theme.base};
      }

      .ant-modal-header {
        background-color: #06bcd4;
        border-radius: 0px;
        padding: 22px 40px;
        .ant-modal-title {
          color: ${theme.base};
        }
      }

      .ant-modal-body {
        padding: 20px 40px;
        .field-group {
          .field {
            & > div {
              .sub-name-dropdown {
                align-self: flex-start;
              }
              .name-dropdown {
                align-self: flex-start;
              }
              @media (max-width: 1010px) {
                width: 100%;
                justify-content: space-between;
                .name-dropdown {
                  flex: 1;
                  .over-rider-select-class {
                    width: 100%;
                    min-width: 200px;
                    max-width: 300px;
                  }
                }
              }
            }
          }
          & .fieldGen-flex-container {
            row-gap: 10px;
            .fieldGen-number-field-container {
              width: 100%;
              .price-input {
                input {
                  background-color: ${theme.base};
                }
                @media (max-width: 863px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 0 40px 30px 40px;
      border-top: 0;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 88% !important;
    }
  }
`

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
`

export const StyledSorter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  .sort-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    span {
      display: grid;
    }
  }
`
