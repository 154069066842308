import React, { useContext, useEffect, useState } from "react"
import { Table, Typography } from "antd"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import { Wrapper } from "./TransportManagementTable.style"
import { OwnerButton, Tag } from "../../atoms"
import {
  CALANDER_HOURS_MINUTES,
  LOCATIONS,
  SelectInput,
  theme,
  DeleteConfimationModal,
  TextField,
  LOCATIONS_FOR_TRANSPORT_BULK_EDIT,
  getElementToPreventHorizontalScroll,
} from "@project/shared"
import { useTransportBulkEditContext } from "../../../context/TransportBulkEditContext"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { japaneseAlphaRegex } from "../../../utils/validation"
import cuid from "cuid"
import { AuthContext } from "../../../utils"
import { ROUTE_NO } from "../../../constants/route"
const IconBlue = <img src={"/assets/icons/user_blue.svg"} alt={"icon"} />
const IconRed = <img src={"/assets/icons/user_red.svg"} alt={"icon"} />

type ManagementTableProps = {
  rowSelection: any
  data: any
  type: "PICKUP" | "DROPOFF"
  otherInfo?: any
  pickupRowSelection?: {
    selectedRowKeys: any[]
    onChange: (selectedRowKeys: React.Key[]) => void
  }
  dropOffRowSelection?: {
    selectedRowKeys: any[]
    onChange: (selectedRowKeys: React.Key[]) => void
  }
}

export const TransportManagementTable: React.FC<ManagementTableProps> = ({
  rowSelection,
  data,
  type,
  otherInfo,
  pickupRowSelection,
  dropOffRowSelection,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [pickRoutes, setPickRoutes] = useState([])
  const [dropRoutes, setDropRoutes] = useState([])
  const { facilities } = useContext(AuthContext)
  const handleFuriganaFilter = (val: string) => {
    const regex = japaneseAlphaRegex(val)
    const filterData =
      val === "---" || !val
        ? instructors
        : instructors
        ? [...instructors]?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )
        : []
    return filterData
  }

  useEffect(() => {
    if (type === "PICKUP") {
      const pickRoute = otherInfo.map(
        (v) =>
          v?.route && {
            id: v?.id,
            route: v?.route,
          }
      )
      setPickRoutes(pickRoute)
    } else {
      const dropRoute = otherInfo.map(
        (v) =>
          v?.route && {
            id: v?.id,
            route: v?.route,
          }
      )
      setDropRoutes(dropRoute)
    }
  }, [otherInfo])

  const {
    instructors,
    cars,
    handleTableDataChange,
    handleResetOfIndividualRow,
    handleExtraInfoChange,
    errorLists,
    handleNewDriverAddition,
    isLoading,
    displayChange,
    handleDriverRemove,
  } = useTransportBulkEditContext()
  useEffect(() => {
    const errorFields = document.getElementsByClassName("error-message")
    if (errorFields?.length) {
      errorFields[0]?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [errorLists])

  const facilityOptions = facilities
    ?.map((val) => ({
      value: `${val?.id}`,
      label: val?.facility_name_short || val?.facility_name,
    }))
    ?.filter((v) => displayChange?.facility?.includes(v?.value))

  const getNewTimeOptions = (row, val?: string) => {
    if (val) {
      const isCurrentHrSame = row?.start_time?.hr === val
      const minIndex = CALANDER_HOURS_MINUTES?.minutes?.findIndex(
        (min) => min?.value === val
      )
      const endMinOptions = isCurrentHrSame
        ? CALANDER_HOURS_MINUTES?.minutes?.slice(minIndex + 1)
        : minIndex < 0
        ? CALANDER_HOURS_MINUTES?.minutes?.slice(minIndex)
        : CALANDER_HOURS_MINUTES?.minutes
      return { endMinOptions, isCurrentHrSame }
    }
    const isSameHours = row?.start_time?.hr === row?.end_time?.hr
    const indexOfStartHr = CALANDER_HOURS_MINUTES?.hours?.findIndex(
      (hr) => hr?.value === row?.start_time?.hr
    )
    const indexOfStartMin = CALANDER_HOURS_MINUTES?.minutes?.findIndex(
      (min) => min?.value === row?.start_time?.min
    )
    const endHourOptions =
      indexOfStartHr < 0
        ? CALANDER_HOURS_MINUTES?.hours
        : CALANDER_HOURS_MINUTES?.hours?.slice(indexOfStartHr)
    const endMinOptions = isSameHours
      ? CALANDER_HOURS_MINUTES?.minutes?.slice(indexOfStartMin + 1)
      : indexOfStartMin < 0
      ? CALANDER_HOURS_MINUTES?.minutes?.slice(indexOfStartHr)
      : CALANDER_HOURS_MINUTES?.minutes
    return { endHourOptions, endMinOptions, isSameHours }
  }
  const pickDropTableEl = () =>
    getElementToPreventHorizontalScroll(
      type === "PICKUP"
        ? "user-transport-management-pickup-table"
        : "user-transport-management-drop-table",
      ".ant-table-content"
    ) as any
  const columns: ColumnsType<any> = [
    {
      title: t("Child name"),
      key: "child_name",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        return a?.child_name?.length - b?.child_name?.length
      },
      render: (row) => (
        <div className={"child_name"}>
          <Typography.Text
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {row?.child_name}
          </Typography.Text>
          <div className={"info"}>
            <div className={"child-origin"}>
              {row?.service_type === "1" ? (
                <Tag backgroundColor={theme.yellow2}>{t("放デ")}</Tag>
              ) : row?.service_type === "2" ? (
                <Tag backgroundColor={theme.blue5}>{t("児発")}</Tag>
              ) : (
                ""
              )}
            </div>

            <div className={"icon"}>
              {row?.gender === 1 ? IconBlue : IconRed}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Facility name")}</span>,
      align: "center",
      key: "facility_name",
      width: "10%",
      render: (row) => (
        <Typography.Text
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {row?.facility_name_short}
        </Typography.Text>
      ),
    },
    {
      title:
        type === "PICKUP" ? t("Desired pick up time") : t("Desired drop time"),
      key: "desired_pick_up_time",
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              textAlign: "center",
              borderLeft: (!row?.is_present || !row?.use_transport) && "none",
              borderRight: (!row?.is_present || !row?.use_transport) && "none",
            },
          },
          children: (
            <>
              {!row?.is_present && !row?.is_unregistered ? (
                <></>
              ) : (
                <>
                  {row?.use_transport ? (
                    <div className={"input__picker"}>
                      <SelectInput
                        height={40}
                        width={60}
                        placeholder={"--"}
                        value={row?.desired_time?.hr}
                        options={CALANDER_HOURS_MINUTES?.hours}
                        onChange={(val) => {
                          handleTableDataChange(
                            row?.id,
                            type,
                            val,
                            "desired_time",
                            "hr"
                          )
                        }}
                        scrollPreventEl={pickDropTableEl()}
                      />
                      <b>{":"}</b>
                      <SelectInput
                        height={40}
                        width={60}
                        value={
                          row?.desired_time?.hr && !row?.desired_time?.min
                            ? "00"
                            : row?.desired_time?.hr && row?.desired_time?.min
                            ? row?.desired_time?.min
                            : ""
                        }
                        disabled={!row?.desired_time?.hr}
                        placeholder={"--"}
                        options={CALANDER_HOURS_MINUTES?.minutes}
                        onChange={(val) =>
                          handleTableDataChange(
                            row?.id,
                            type,
                            val,
                            "desired_time",
                            "min"
                          )
                        }
                        scrollPreventEl={pickDropTableEl()}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ),
        }
      },
    },
    {
      title: type === "PICKUP" ? t("Picked up time") : t("Dropped time"),
      key: "picked_up_time",
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              borderLeft: (!row?.is_present || !row?.use_transport) && "none",
              borderRight: (!row?.is_present || !row?.use_transport) && "none",
            },
          },
          children:
            !row?.is_present && !row?.is_unregistered ? (
              <></>
            ) : row?.use_transport ? (
              <div className={"input__picker"}>
                <SelectInput
                  height={40}
                  width={60}
                  placeholder={"--"}
                  value={row?.pickup_time?.hr}
                  options={CALANDER_HOURS_MINUTES?.hours}
                  onChange={(val) => {
                    handleTableDataChange(
                      row?.id,
                      type,
                      val,
                      "pickup_time",
                      "hr"
                    )
                  }}
                  scrollPreventEl={pickDropTableEl()}
                />
                <b>{":"}</b>
                <SelectInput
                  height={40}
                  width={60}
                  disabled={!row?.pickup_time?.hr}
                  placeholder={"--"}
                  value={
                    row?.pickup_time?.hr && !row?.pickup_time?.min
                      ? "00"
                      : row?.pickup_time?.hr && row?.pickup_time?.min
                      ? row?.pickup_time?.min
                      : ""
                  }
                  options={CALANDER_HOURS_MINUTES?.minutes}
                  onChange={(val) =>
                    handleTableDataChange(
                      row?.id,
                      type,
                      val,
                      "pickup_time",
                      "min"
                    )
                  }
                  scrollPreventEl={pickDropTableEl()}
                />
              </div>
            ) : (
              <></>
            ),
        }
      },
    },
    {
      title: t("Arrival time"),
      key: "arrival_time",
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              borderLeft: (!row?.is_present || !row?.use_transport) && "none",
              borderRight: (!row?.is_present || !row?.use_transport) && "none",
            },
          },

          children:
            !row?.is_present && !row?.is_unregistered ? (
              <Typography.Text
                style={{
                  color: theme.alert,
                }}
              >
                {t("Absent")}
              </Typography.Text>
            ) : (
              <>
                {row?.use_transport ? (
                  <div className={"input__picker"}>
                    <SelectInput
                      height={40}
                      width={60}
                      placeholder={"--"}
                      value={row?.arrival_time?.hr}
                      options={CALANDER_HOURS_MINUTES?.hours}
                      onChange={(val) => {
                        handleTableDataChange(
                          row?.id,
                          type,
                          val,
                          "arrival_time",
                          "hr"
                        )
                      }}
                      scrollPreventEl={pickDropTableEl()}
                    />
                    <b>{":"}</b>
                    <SelectInput
                      height={40}
                      width={60}
                      disabled={!row?.arrival_time?.hr}
                      placeholder={"--"}
                      value={
                        row?.arrival_time?.hr && !row?.arrival_time?.min
                          ? "00"
                          : row?.arrival_time?.hr && row?.arrival_time?.min
                          ? row?.arrival_time?.min
                          : ""
                      }
                      options={CALANDER_HOURS_MINUTES?.minutes}
                      onChange={(val) =>
                        handleTableDataChange(
                          row?.id,
                          type,
                          val,
                          "arrival_time",
                          "min"
                        )
                      }
                      scrollPreventEl={pickDropTableEl()}
                    />
                  </div>
                ) : (
                  <>
                    {!row?.isDataAdding ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <OwnerButton
                          text={t("No transportation")}
                          typeOf={"secondary"}
                          p={"8px"}
                          onClick={() =>
                            handleTableDataChange(
                              row?.id,
                              type,
                              true,
                              "isDataAdding"
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          gap: "0.5rem",
                        }}
                      >
                        <OwnerButton
                          text={t("Use Transportation")}
                          typeOf={"secondary"}
                          p={"8px"}
                          onClick={() =>
                            handleTableDataChange(
                              row?.id,
                              type,
                              true,
                              "use_transport"
                            )
                          }
                        />
                        <OwnerButton
                          text={t("Don’t use transportation")}
                          typeOf={"secondary"}
                          p={"8px"}
                          onClick={() =>
                            handleTableDataChange(
                              row?.id,
                              type,
                              false,
                              "isDataAdding"
                            )
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            ),
        }
      },
    },

    {
      title: t("Place"),
      key: "place",
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              borderLeft: (!row?.is_present || !row?.use_transport) && "none",
              borderRight: (!row?.is_present || !row?.use_transport) && "none",
            },
          },
          children:
            !row?.is_present && !row?.is_unregistered ? (
              <></>
            ) : row?.use_transport ? (
              <>
                <div className={"input__picker"}>
                  <SelectInput
                    height={40}
                    width={120}
                    placeholder={"--"}
                    value={
                      row?.service_type === "1"
                        ? row[`after_day`]
                        : row[`place_id`]
                    }
                    options={
                      row?.service_type === "1"
                        ? LOCATIONS
                        : LOCATIONS_FOR_TRANSPORT_BULK_EDIT
                    }
                    onChange={(val) =>
                      handleTableDataChange(
                        row?.id,
                        type,
                        val,
                        row?.service_type === "1" ? "after_day" : "place_id"
                      )
                    }
                    scrollPreventEl={pickDropTableEl()}
                  />
                </div>
                {row?.service_type === "1" && row["after_day"] === 3 && (
                  <div
                    style={{
                      marginTop: "0.4rem",
                    }}
                  >
                    <TextField
                      bgcolor={"transparent"}
                      height={"40px"}
                      placeholder={t("Place")}
                      value={row?.after_place || ""}
                      onChange={({ target: { value } }) =>
                        handleTableDataChange(
                          row?.id,
                          type,
                          value,
                          "after_place"
                        )
                      }
                    />
                  </div>
                )}
                {row?.service_type === "2" && row["place_id"] === 3 && (
                  <div
                    style={{
                      marginTop: "0.4rem",
                    }}
                  >
                    <TextField
                      bgcolor={"transparent"}
                      height={"40px"}
                      placeholder={t("Place")}
                      value={row?.other_place || ""}
                      onChange={({ target: { value } }) =>
                        handleTableDataChange(
                          row?.id,
                          type,
                          value,
                          "other_place"
                        )
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            ),
        }
      },
    },
    {
      title: t("Route no."),
      key: "route_number",
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              borderLeft: (!row?.is_present || !row?.use_transport) && "none",
              borderRight: (!row?.is_present || !row?.use_transport) && "none",
            },
          },

          children:
            !row?.is_present && !row?.is_unregistered ? (
              <></>
            ) : (
              <>
                {row?.use_transport ? (
                  <>
                    <div className={"input__picker"}>
                      <SelectInput
                        height={40}
                        width={60}
                        placeholder={"--"}
                        value={row?.route || 1}
                        className={"router_number-select"}
                        options={ROUTE_NO}
                        notFoundContent={t("No data")}
                        onChange={(val) => {
                          handleTableDataChange(row?.id, type, val, "route")
                        }}
                        scrollPreventEl={pickDropTableEl()}
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Route")}
                      </span>
                    </div>
                    {row?.route_error && (
                      <p
                        className={"error-message"}
                        style={{ position: "absolute" }}
                      >
                        {t(row?.route_error)}
                      </p>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ),
        }
      },
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t(" Reset")}</span>,
      key: "reset",
      align: "center",

      width: "15%",
      render: (row) =>
        !row?.is_present && !row?.is_unregistered ? (
          <></>
        ) : row?.use_transport ? (
          <Typography.Text
            onClick={() => {
              setSelected(row?.id)
              setIsModalOpen(true)
            }}
            underline
            style={{
              color: theme.blue5,
              cursor: "pointer",
            }}
          >
            {t(" Reset")}
          </Typography.Text>
        ) : (
          <></>
        ),
    },
  ]
  const driverTableEl = () =>
    getElementToPreventHorizontalScroll(
      type === "PICKUP"
        ? "user-transport-management-pickup-driver-table"
        : "user-transport-management-drop-driver-table",
      ".ant-table-content"
    ) as any

  const ROUTE_OPTIONS = [
    {
      label: "--",
      value: 0,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ]

  const PICK_ROUTE_OPTIONS = ROUTE_OPTIONS?.filter(
    (o) => !pickRoutes?.map((o) => o?.route).includes(o?.value)
  )
  const DROP_ROUTE_OPTIONS = ROUTE_OPTIONS?.filter(
    (o) => !dropRoutes?.map((o) => o?.route).includes(o?.value)
  )
  const handleRouteChangeOption = (rowId, val) => {
    if (type === "PICKUP" && val) {
      setPickRoutes((prev) => {
        const updatedRoutes = prev.filter((route) => route?.id !== rowId)
        return [
          ...updatedRoutes,
          {
            id: rowId,
            route: val,
          },
        ]
      })
    }
    if (type === "DROPOFF" && val) {
      setDropRoutes((prev) => {
        const updatedRoutes = prev.filter((route) => route?.id !== rowId)
        return [
          ...updatedRoutes,
          {
            id: rowId,
            route: val,
          },
        ]
      })
    }
  }

  const driverInfoColumns: ColumnsType<any> = [
    {
      title: t("Driver's name"),
      key: "driver_name",
      align: "center",
      className: "driver_name-cell",
      render: (row) => {
        const showDriverErr =
          type === "PICKUP"
            ? pickupRowSelection?.selectedRowKeys?.includes(row?.key)
            : dropOffRowSelection?.selectedRowKeys?.includes(row?.key)
        const options = handleFuriganaFilter(
          row?.driver_staff_furigana_name || "---"
        )
        return (
          <div className={"cell-content-wrapper"}>
            <div className={"input__picker"}>
              <SelectInput
                height={40}
                width={60}
                placeholder={"--"}
                value={row?.driver_staff_furigana_name || null}
                options={FuriganaAlphabetsOption}
                clear
                className={"select_in-r"}
                onChange={(val) =>
                  handleExtraInfoChange(
                    row?.id,
                    val,
                    "driver_staff_furigana_name",
                    type,
                    "driver_staff_id"
                  )
                }
                scrollPreventEl={driverTableEl()}
              />
              <div className={"select_in-l"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={options || []}
                  value={options?.length ? row?.driver_staff_id : null}
                  onChange={(val) =>
                    handleExtraInfoChange(row?.id, val, "driver_staff_id", type)
                  }
                  scrollPreventEl={driverTableEl()}
                />
              </div>
            </div>

            {row?.driver_staff_id_error && showDriverErr && (
              <p className={"error-message"}>{t(row?.driver_staff_id_error)}</p>
            )}
          </div>
        )
      },
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Facility name")}</span>,
      key: "facility_name",
      align: "center",
      render: (row) => {
        if (!row?.isNew) {
          return (
            <div className={"value"} style={{ whiteSpace: "nowrap" }}>
              {t(
                row?.facility_name_short ||
                  facilityOptions?.find((v) => v?.value === row?.facility_id)
                    ?.facility_name_short ||
                  ""
              )}
            </div>
          )
        }
        return (
          <div className={"cell-content-wrapper"}>
            <div className={"input__picker"}>
              <div className={"select_in-l"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={facilityOptions || []}
                  value={
                    facilityOptions?.length && row?.facility_id
                      ? `${row?.facility_id}`
                      : ""
                  }
                  onChange={(val) =>
                    handleExtraInfoChange(row?.id, +val, "facility_id", type)
                  }
                  scrollPreventEl={driverTableEl()}
                />
              </div>
            </div>

            {row?.facility_id_error && (
              <p className={"error-message"}>{t(row?.facility_id_error)}</p>
            )}
          </div>
        )
      },
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {t("Transportation start time")}
        </div>
      ),
      key: "start_time",
      align: "center",
      render: (row) => {
        return (
          <div className={"cell-content-wrapper"}>
            <div className={"input__picker"}>
              <div className={"select_in"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES?.hours}
                  value={row?.start_time?.hr}
                  onChange={(val) => {
                    handleExtraInfoChange(
                      row?.id,
                      val,
                      "start_time",
                      type,
                      "hr"
                    )
                  }}
                  scrollPreventEl={driverTableEl()}
                />
              </div>
              <b>{":"}</b>
              <div className={"select_in"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES?.minutes}
                  value={
                    row?.start_time?.hr && !row?.start_time?.min
                      ? "00"
                      : row?.start_time?.hr && row?.start_time?.min
                      ? row?.start_time?.min
                      : ""
                  }
                  disabled={!row?.start_time?.hr}
                  onChange={(val) =>
                    handleExtraInfoChange(
                      row?.id,
                      val,
                      "start_time",
                      type,
                      "min"
                    )
                  }
                  scrollPreventEl={driverTableEl()}
                />
              </div>
            </div>
            {(row?.start_time_hr_error || row?.start_time_min_error) && (
              <p className={"error-message"}>
                {t(row?.start_time_hr_error) || t(row?.start_time_min_error)}
              </p>
            )}
          </div>
        )
      },
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {t("Transportation end time")}
        </div>
      ),
      key: "end_time",
      align: "center",
      render: (row) => {
        const { endHourOptions, endMinOptions } = getNewTimeOptions(row)

        return (
          <div className={"cell-content-wrapper"}>
            <div className={"input__picker"}>
              <div className={"select_in"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={endHourOptions || []}
                  disabled={!row?.start_time?.hr && !row?.start_time?.min}
                  value={row?.end_time?.hr}
                  onChange={(val) => {
                    const { endMinOptions: opts } = getNewTimeOptions(row, val)
                    handleExtraInfoChange(
                      row?.id,
                      val,
                      "end_time",
                      type,
                      "hr",
                      opts[0]?.value || "00"
                    )
                  }}
                  scrollPreventEl={driverTableEl()}
                />
              </div>
              <b>{":"}</b>
              <div className={"select_in"}>
                <SelectInput
                  height={40}
                  placeholder={"--"}
                  options={endMinOptions || []}
                  value={
                    row?.end_time?.hr && !row?.end_time?.min
                      ? "00"
                      : row?.end_time?.hr && row?.end_time?.min
                      ? row?.end_time?.min
                      : ""
                  }
                  disabled={!row?.end_time?.hr}
                  onChange={(val) =>
                    handleExtraInfoChange(row?.id, val, "end_time", type, "min")
                  }
                  scrollPreventEl={driverTableEl()}
                />
              </div>
            </div>
            {(row?.end_time_hr_error || row?.end_time_min_error) && (
              <p className={"error-message"}>
                {t(row?.end_time_hr_error) || t(row?.end_time_min_error)}
              </p>
            )}
          </div>
        )
      },
    },

    {
      title: t("Conductor"),
      align: "center",
      key: "conductor",
      render: (row) => {
        const options = handleFuriganaFilter(row?.escort_staff_furigana_name)

        return (
          <div className={"cell-content-wrapper"}>
            <div className={"input__picker"}>
              <SelectInput
                height={40}
                width={60}
                placeholder={"--"}
                notFoundContent={t("No data")}
                options={FuriganaAlphabetsOption}
                value={row?.escort_staff_furigana_name || null}
                clear
                onChange={(val) =>
                  handleExtraInfoChange(
                    row?.id,
                    val,
                    "escort_staff_furigana_name",
                    type,
                    "escort_staff_id"
                  )
                }
                scrollPreventEl={driverTableEl()}
              />
              <b>{":"}</b>
              <SelectInput
                height={40}
                width={100}
                placeholder={"--"}
                value={options?.length ? row?.escort_staff_id : ""}
                options={options}
                onChange={(val) =>
                  handleExtraInfoChange(row?.id, val, "escort_staff_id", type)
                }
                scrollPreventEl={driverTableEl()}
              />
            </div>
            {row?.escort_staff_id_error && (
              <p className={"error-message"}>{t(row?.escort_staff_id_error)}</p>
            )}
          </div>
        )
      },
    },

    {
      title: t("Vehicle"),
      key: "Vehicle",
      align: "center",
      render: (row) => (
        <div className={"cell-content-wrapper"}>
          <div className={"input__picker"}>
            <SelectInput
              height={40}
              width={100}
              placeholder={"--"}
              value={row?.car_id}
              options={cars}
              notFoundContent={t("No data")}
              onChange={(val) => {
                handleExtraInfoChange(row?.id, val, "car_id", type)
              }}
              scrollPreventEl={driverTableEl()}
            />
          </div>
          {row?.car_id_error && (
            <p className={"error-message"}>{t(row?.car_id_error)}</p>
          )}
        </div>
      ),
    },
    {
      title: t("Route no."),
      key: "route_number",
      align: "center",
      render: (row) => (
        <div className={"cell-content-wrapper"}>
          {!row?.car_id ? (
            <Typography.Text
              style={{
                fontSize: "14px",
                width: "max-content",
              }}
            >
              {t("Select vehicle first")}
            </Typography.Text>
          ) : (
            <div className={"input__picker"}>
              <SelectInput
                height={40}
                width={60}
                placeholder={"--"}
                value={row?.route}
                className={"router_number-select"}
                options={
                  type === "PICKUP" ? PICK_ROUTE_OPTIONS : DROP_ROUTE_OPTIONS
                }
                notFoundContent={t("No data")}
                onChange={(val) => {
                  handleExtraInfoChange(row?.id, val, "route", type)
                  handleRouteChangeOption(row?.id, val)
                }}
                scrollPreventEl={driverTableEl()}
              />
              <span
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                {t("Route")}
              </span>
            </div>
          )}
          {row?.route_error && (
            <p className={"error-message"}>{t(row?.route_error)}</p>
          )}
        </div>
      ),
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (row) => (
        <div
          className={"cell-content-wrapper"}
          onClick={() => {
            handleDriverRemove(type, row?.id)
          }}
        >
          <Typography.Text
            style={{
              fontSize: "14px",
              width: "max-content",
              color: theme.red1,
              cursor: "pointer",
            }}
          >
            {t("Remove")}
          </Typography.Text>
        </div>
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        {/* pickup/dropoff info table */}
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data || []}
          pagination={false}
          bordered
          scroll={{ x: "1000px" }}
          className={"list__table"}
          loading={isLoading}
          id={
            type === "PICKUP"
              ? "user-transport-management-pickup-table"
              : "user-transport-management-drop-table"
          }
        />

        {/* driver info table */}
        <div>
          <Table
            rowSelection={
              type === "PICKUP" ? pickupRowSelection : dropOffRowSelection
            }
            columns={driverInfoColumns}
            dataSource={otherInfo.length > 0 ? otherInfo : []}
            pagination={false}
            bordered
            scroll={{ x: "1073px" }}
            className={"list__table_2"}
            loading={isLoading}
            id={
              type === "PICKUP"
                ? "user-transport-management-pickup-driver-table"
                : "user-transport-management-drop-driver-table"
            }
          />
          <div
            style={{
              marginTop: "0.5rem",
            }}
          >
            <OwnerButton
              typeOf={"primary"}
              text={t("Add driver")}
              shape={"round"}
              p={"8px 10px"}
              isLoading={false}
              onClick={() => {
                const newData = {
                  facility_id: "",
                  facility_name_furigana: "",
                  car_id: null,
                  car_name: null,
                  route: 0,
                  driver_staff_id: null,
                  driver_staff_furigana_name: 0,
                  escort_staff_id: null,
                  escort_staff_furigana_name: null,
                  start_time: {
                    hr: "",
                    min: "",
                  },
                  end_time: {
                    hr: "",
                    min: "",
                  },
                  id: cuid(),
                  key: cuid(),
                  isNew: true,
                }
                handleNewDriverAddition(type, newData)
              }}
            />
          </div>
        </div>
      </Wrapper>
      {isModalOpen && (
        <DeleteConfimationModal
          open={isModalOpen}
          isOkButtonText={"untranslatedOK"}
          onCancel={() => setIsModalOpen(false)}
          centered
          onOk={() => {
            handleResetOfIndividualRow(selected, type)
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}
