import React from "react"
import { useTranslation } from "react-i18next"
import wareki from "wareki"
import { formatJaEra } from "../../../molecules/MonthlyUsageList/common/mapper"
import { Table } from "antd"
import { useRouter } from "next/router"
import {
  NBLDateMonthInfo,
  Title,
  Wrapper,
  NBLInfoContent,
  NBLMiddleInfoContent,
  NBLManagementCompanyTop,
  ConfirmationWrapper,
  KyotoTable,
  KobeCityTableComponent,
  HiroshimaBurdenTable,
  DiagnoalDiv,
} from "./PrintListContent.style"
import {
  PRINT_CONFIGURATION_OPTION_VALUES,
  PRINT_STYLE_VALUES,
} from "../constants"

/**
 * 〜 2024.3
 */
export const PrintListContent = ({ data, cityNo, printFilter }: any) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { year, month } = router?.query as any

  const era = formatJaEra(
    wareki(`${year}-${month}`, {
      unit: true,
    })
  )

  const censorName = (name: string) => {
    return name
      .split(" ")
      .map((item) => {
        return item
          .split("")
          .map((e, idx) => (idx % 2 === 1 ? "○" : e))
          .join("")
      })
      .join(" ")
  }

  const getColumnInfo = (row, index, title?: any) => {
    const content = (data) => {
      return (
        <div className={"facility_total_amount"}>
          {data?.split("")?.map((v, index) => (
            <div key={index} className={"value"}>
              {v !== "." ? v : ""}
            </div>
          ))}
        </div>
      )
    }
    if (index === 0) {
      return title || ""
    }
    if (index === 1) {
      return row ? row?.beneficiary_certificate_number || "" : ""
    }
    if (index === 2) {
      return printFilter?.configuration?.includes("3")
        ? censorName(row?.child_name || "")
        : row?.child_name || ""
    }
    if (index === 3) {
      return row ? row?.office_number || "" : ""
    }
    if (index === 4) {
      return row?.office_name || ""
    }
    if (index === 5) {
      return row
        ? content(String(row?.total_cost)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 6) {
      return row
        ? content(String(row?.user_fee)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 7) {
      let returnable = row
        ? content(String(row?.user_burden_after_management)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
      if (printFilter?.style == PRINT_STYLE_VALUES.KYOTO) {
        returnable = content(""?.padStart(6, " "))
      }
      return returnable
    }
    return ""
  }
  const getColumnInfoForKobe = (row, index, title?: any) => {
    const content = (data) => {
      return (
        <div className={"facility_total_amount"}>
          {data?.split("")?.map((v, index) => (
            <div key={index} className={"value"}>
              {v !== "." ? v : ""}
            </div>
          ))}
        </div>
      )
    }
    if (index === 0) {
      return title || ""
    }
    if (index === 1) {
      return row ? row?.beneficiary_certificate_number || "" : ""
    }
    if (index === 2) {
      return row?.child_name || ""
    }
    if (index === 3) {
      return row ? row?.office_number || "" : ""
    }
    if (index === 4) {
      return row?.office_name || ""
    }
    if (index === 5) {
      return row
        ? content(String(row?.total_cost)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 6) {
      return row
        ? content(String(row?.total_cost - row?.user_fee)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 7) {
      return row
        ? content(String(row?.user_fee)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }

    if (index == 8) {
      return row
        ? content(String(row?.user_burden_after_management)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }

    if (index === 9) {
      return row
        ? content(
            String(
              row?.total_cost - row?.user_burden_after_management
            )?.padStart(6, ".")
          )
        : content(""?.padStart(6, "."))
    }

    return ""
  }

  const getColumnInfoForHiroshima = (row, index, title?: any) => {
    const content = (data) => {
      return (
        <div className={"facility_total_amount"}>
          {data?.split("")?.map((v, index) => (
            <div key={index} className={"value"}>
              {v !== "." ? v : ""}
            </div>
          ))}
        </div>
      )
    }
    if (index === 0) {
      return title || ""
    }
    if (index === 1) {
      return row ? row?.beneficiary_certificate_number || "" : ""
    }
    if (index === 2) {
      return row?.child_name || ""
    }
    if (index === 3) {
      return row ? row?.office_number || "" : ""
    }
    if (index === 4) {
      return row?.office_name || ""
    }
    if (index === 5) {
      return row
        ? content(String(row?.total_cost)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 6) {
      return row
        ? content(String(row?.user_fee)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }
    if (index === 7) {
      return row
        ? content(String(row?.user_burden_after_management)?.padStart(6, "."))
        : content(""?.padStart(6, "."))
    }

    if (index == 8) {
      return row
        ? content(
            String(
              row?.total_cost - row?.user_burden_after_management
            )?.padStart(6, ".")
          )
        : content(""?.padStart(6, "."))
    }

    return ""
  }

  const generateDynamicColumn = (arry: any[]): any[] => {
    const colum: any = []
    arry?.map((value, index) => {
      colum.push({
        title: index + 1,
        align: "center",
        render: (row, _, idx) => getColumnInfo(value, idx, index + 1),
      })
    })
    // one empty column
    colum.push({
      render: (row, _, index) =>
        index >= 5 && (
          <div className={"facility_total_amount"}>
            {["", "", "", "", "", ""]?.map((v, index) => (
              <div key={index} className={"value"}>
                {v !== "." ? v : ""}
              </div>
            ))}
          </div>
        ),
    })

    return colum
  }

  const renderSixEmptyCells = () => (
    <div className={"facility_total_amount"}>
      {[" ", " ", " ", " ", " ", " "]?.map((v, index) => (
        <div key={index} className={"value"}>
          {v !== "." ? v : ""}
        </div>
      ))}
    </div>
  )

  const generateDynamicColumnForKobe = (arry: any[]): any[] => {
    const colum: any = []
    arry?.map((value, index) => {
      colum.push({
        title: index + 1,
        align: "center",
        className: "kobe-col",
        render: (row, _, idx) => {
          if (idx == 11 || idx == 12) {
            return renderSixEmptyCells()
          }
          return getColumnInfoForKobe(value, idx, index + 1)
        },
      })
    })
    // one total column
    colum.push({
      title: t("Total"),
      align: "center",
      width: 180,
      className: "kobe-col",
      onCell: (_, index) => {
        if (index == 0) {
          return {
            rowSpan: 5,
          }
        }
        if (index < 5) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
      render: (_, __, index) => {
        if (index == 11 || index == 12) {
          return renderSixEmptyCells()
        }

        const row = getTotal()
        return getColumnInfoForKobe(
          {
            total_cost: row?.total_cost || "",
            user_fee: row?.user_fee || "",
            user_burden_after_management:
              row?.user_burden_after_management || "",
          },
          index,
          t("Total")
        )
      },
    })

    return colum
  }

  const generateDynamicColumnForHiroshima = (arry: any[]): any[] => {
    const colum: any = []
    arry?.map((value, index) => {
      colum.push({
        title: index + 1,
        align: "center",
        className: "hiroshima-col",
        render: (row, _, idx) => {
          if (idx == 11 && index > 0) {
            return <DiagnoalDiv />
          }
          if (idx > 8) {
            return renderSixEmptyCells()
          }
          return getColumnInfoForHiroshima(value, idx, index + 1)
        },
      })
    })
    // one total column
    colum.push({
      title: t("Total"),
      align: "center",
      width: 180,
      className: "hiroshima-col",
      onCell: (_, index) => {
        if (index == 0) {
          return {
            rowSpan: 5,
          }
        }
        if (index < 5) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
      render: (_, __, index) => {
        if (index > 8) {
          return renderSixEmptyCells()
        }

        const row = getTotal()
        return getColumnInfoForHiroshima(
          {
            total_cost: row?.total_cost || "",
            user_fee: row?.user_fee || "",
            user_burden_after_management:
              row?.user_burden_after_management || "",
          },
          index,
          t("Total")
        )
      },
    })

    return colum
  }

  const getTotal = () => {
    const withSum = data?.child_maximum_result?.reduce(
      (accumulator, curValue) => {
        return {
          ...curValue,
          total_cost: accumulator?.total_cost + curValue?.total_cost,
          user_fee: accumulator?.user_fee + curValue?.user_fee,
          user_burden_after_management:
            accumulator?.user_burden_after_management +
            curValue?.user_burden_after_management,
        }
      },
      { total_cost: 0, user_fee: 0, user_burden_after_management: 0 }
    )
    return withSum
  }

  const getOfficeInfoAccordingToFId = () => {
    // data?.child_maximum_result?.find((v) => v?.facilityId === +facility_id)
    return data?.child_maximum_result ? data?.child_maximum_result[0] : {}
  }

  //get unique child
  const uniqueChild = Array.from(
    new Map(
      data?.child_maximum_result?.map((item) => [item["child_id"], item])
    ).values()
  )
  getTotal()

  const getTitle = () => {
    const addMultipleTitle = uniqueChild?.length > 1
    let title = ""
    const multipleChildTitle = t("forMultipleChildTitle")
    if (printFilter?.style == PRINT_STYLE_VALUES.KYOTO) {
      title = t(
        "User burden maximum amount management result sheet (Kyoto City style)"
      )
      return addMultipleTitle ? title + multipleChildTitle : title
    }
    if (printFilter?.style == PRINT_STYLE_VALUES.HIROSHIMA) {
      return (
        <>
          {t("User burden maximum amount management result sheet")}
          <br />
          <span style={{ fontWeight: "normal", fontSize: "12px" }}>
            {t(
              "(For those eligible for the Hiroshima City Disability Welfare Service User Bursary Subsidy)"
            )}
          </span>
        </>
      )
    }
    title = t("User burden maximum amount management result sheet")
    return addMultipleTitle ? title + multipleChildTitle : title
  }

  const getUserBurdenTitle = () => {
    if (printFilter?.style == PRINT_STYLE_VALUES.KYOTO) {
      return t("Maximum monthly payment by users (country) ①")
    }
    return t("user burden amount")
  }

  const generateEmptyColumn = (tdColSpan = 1, times = 1) => {
    const numberOfEmptyColumns = Array.from(
      { length: (data?.child_maximum_result?.length || 0) * times },
      (_, i) => i
    )
    return numberOfEmptyColumns.map((_, index) => (
      <td colSpan={tdColSpan} key={index}></td>
    ))
  }

  const renderKyotoAdditionalTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <KyotoTable>
          <tbody className={"kyoto-table-body"}>
            <tr>
              <td>
                {t(
                  "Amount that can be collected for community life support projects: If ① or ② > ③"
                )}
              </td>
              <td>{t("(① or ②-④)")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {t(
                  "Amount that can be collected for community life support projects: In the case of ① or ②≦③"
                )}
              </td>
              <td>{t("(③-④)	")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </KyotoTable>
        <KyotoTable style={{ width: "100%" }}>
          <tbody className={"kyoto-table-body"}>
            <tr>
              <td rowSpan={6}>
                {t("User burden amount aggregation / adjustment column")}
                <br />
                {"_"}
              </td>
              <td className={"solid2 center minW120"} colSpan={1} rowSpan={1}>
                {t("Item number")}
              </td>
              {generateEmptyColumn(6)}
              <td colSpan={6} rowSpan={3}>
                {t("Total")}
              </td>
            </tr>
            <tr>
              <td>{t("Office number")}</td>
              {generateEmptyColumn(6)}
            </tr>
            <tr>
              <td>{t("Business name")}</td>
              {generateEmptyColumn(6)}
            </tr>
            <tr>
              <td>{t("Total cost")}</td>
              {generateEmptyColumn(1, 6)}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{t("User fee")}</td>
              {generateEmptyColumn(1, 6)}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{t("User burden after management results")}</td>
              {generateEmptyColumn(1, 6)}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </KyotoTable>
      </div>
    )
  }

  return (
    <Wrapper>
      {printFilter?.configuration?.includes(
        PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER
      ) && <small>{t("(Form 2)")}</small>}
      <Title>{getTitle()}</Title>
      <NBLDateMonthInfo>
        <div id={"DateMonthInfo"}>
          <table>
            <tbody>
              <tr>
                <td className={"w-large"}>{era?.split("").slice(0, 2)}</td>
                <td>{era?.split("").slice(2, 3)}</td>
                <td>{era?.split("").slice(3, 4)}</td>
                <td className={"w-large"}>{t("Year")}</td>
                {month
                  ?.toString()
                  ?.padStart(2, "0")
                  ?.split("")
                  ?.map((v) => (
                    <td key={v}>{v}</td>
                  ))}
                <td className={"w-large"}>{t("Month")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </NBLDateMonthInfo>
      <NBLInfoContent length={uniqueChild.length}>
        <div id={"top__table_content"}>
          <Table
            columns={[
              {
                title: "",
                dataIndex: "label",
                width: "150px",
                render: (text) => <p>{text}</p>,
              },
              {
                title: "",
                width: "335px",
                dataIndex: "value",
                render: (_, val) => {
                  if (val?.row === "business_number") {
                    return (
                      <div id={"business_number"} style={{}}>
                        {String(val?.value)
                          ?.padEnd(10, ".")
                          ?.split("")
                          ?.map((v, idx) => (
                            <div className={"number"} key={idx}>
                              {v !== "." ? v : ""}
                            </div>
                          ))}
                      </div>
                    )
                  }

                  if (val?.row === "beneficiary_no") {
                    return (
                      <div className={"beneficiary_cont"}>
                        {uniqueChild?.map((val: any, ix) => (
                          <div key={ix} className={"beneficiary_no_total"}>
                            {val.beneficiary_certificate_number
                              ?.padEnd(10, ".")
                              ?.split("")
                              .map((char, index) => (
                                <div key={index} className={"value_total"}>
                                  {char !== "." ? char : ""}
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )
                  }

                  if (val?.row === "city_no") {
                    return (
                      <div className={"city_no_total"}>
                        {cityNo
                          ?.padEnd(10, ".")
                          ?.split("")
                          ?.map((v, index) => (
                            <div key={index} className={"value_total"}>
                              {v !== "." ? v : ""}
                            </div>
                          ))}
                      </div>
                    )
                  }

                  if (val?.row === "child_name") {
                    return (
                      <div id={"child_info_container"}>
                        {uniqueChild?.map((val: any, ix) => (
                          <div key={ix} className={"child_value"}>
                            {printFilter?.configuration?.includes(
                              PRINT_CONFIGURATION_OPTION_VALUES.HIDE_PERSONAL_INFORMATION
                            )
                              ? censorName(val?.child_name || "")
                              : val?.child_name}
                          </div>
                        ))}
                      </div>
                    )
                  }

                  return <p>{val?.value}</p>
                },
              },
            ]}
            dataSource={[
              {
                key: "1",
                label: t("Provision city No."),
                isNumber: true,
                row: "city_no",
              },
              {
                key: "2",
                label: t("Beneficiary no."),
                row: "beneficiary_no",
              },
              {
                key: "3",
                label:
                  printFilter?.style == PRINT_STYLE_VALUES.KOBE
                    ? t("Name of guardian, etc. who will decide on benefits")
                    : printFilter?.configuration?.includes(
                        PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
                      )
                    ? t(
                        "Name of person with a disability for whom payment has been decided"
                      )
                    : t(
                        "Name of person with a disability, etc. whose payment is determined"
                      ),
                value: printFilter?.configuration?.includes(
                  PRINT_CONFIGURATION_OPTION_VALUES.HIDE_PERSONAL_INFORMATION
                )
                  ? censorName(data?.parent_name || "")
                  : data?.parent_name || "",
                row: "parent_name",
              },
              {
                key: "4",
                label: printFilter?.configuration?.includes(
                  PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
                )
                  ? t(
                      "Name of child with a disability related to payment decision "
                    )
                  : t(
                      "Name of child with a disability related to payment decision"
                    ),
                row: "child_name",
              },
            ]}
            pagination={false}
            showHeader={false}
          />
        </div>
        <div id={"top__table_content"}>
          <Table
            className={"management-table"}
            columns={[
              {
                title: "",
                render: () => <p>{t("Management company")}</p>,
                width: 20,

                onCell: (_, index) => {
                  if (index === 0) {
                    return { rowSpan: 2 }
                  }
                  return { rowSpan: 0 }
                },
              },
              {
                title: "",
                dataIndex: "label",
                width: "150px",
                render: (text) => (
                  <p className={"management_company"}>{text}</p>
                ),
              },
              {
                title: "",
                width: "200px",
                dataIndex: "value",
                render: (_, val) => {
                  if (val?.isNumber) {
                    return (
                      <div id={"business_number"} style={{}}>
                        {String(val?.value)
                          ?.padEnd(10, ".")
                          ?.split("")
                          ?.map((v, idx) => (
                            <div className={"number"} key={idx}>
                              {v !== "." ? v : ""}
                            </div>
                          ))}
                      </div>
                    )
                  }
                  return val?.value
                },
              },
            ]}
            dataSource={[
              {
                key: "1",
                label: t("Designated office number"),
                value: getOfficeInfoAccordingToFId()?.office_number || "",
                isNumber: true,
              },
              {
                key: "2",
                label: t("Business office and name of the business office"),
                value: getOfficeInfoAccordingToFId()?.office_name || "",
              },
            ]}
            pagination={false}
            showHeader={false}
          />
        </div>
      </NBLInfoContent>
      <NBLMiddleInfoContent>
        <div id={"NBLMiddleInfoContent"}>
          <div id={"nbl_info_top_content"}>
            <table id={"nbl_info_top_content__table"}>
              <tbody>
                <tr>
                  <td className={"large_cell"}>{getUserBurdenTitle()}</td>
                  {String(getOfficeInfoAccordingToFId()?.user_fee || "")
                    ?.padStart(
                      printFilter?.style == PRINT_STYLE_VALUES.KYOTO ? 6 : 5,
                      "."
                    )
                    ?.split("")
                    ?.map((v, indx) => (
                      <td key={indx}>{v !== "." ? v : ""}</td>
                    ))}
                  {printFilter?.style == PRINT_STYLE_VALUES.KYOTO && (
                    <>
                      <td>{t("Maximum monthly payment by users (city) ②")}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                  {printFilter?.style == PRINT_STYLE_VALUES.KOBE && (
                    <>
                      <td>
                        {t(
                          "Maximum monthly payment by users (Kobe City/Benefits portion)"
                        )}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                  {printFilter?.style == PRINT_STYLE_VALUES.HIROSHIMA && (
                    <>
                      <td>
                        {t("Hiroshima City subsidy upper limit per month")}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                </tr>
                {printFilter?.style == PRINT_STYLE_VALUES.KYOTO && (
                  <tr>
                    <td colSpan={8}>
                      {t(
                        "Maximum monthly payment for users of community living support projects ③"
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div id={"nbl_info_bottom_content"}>
            {printFilter?.style == PRINT_STYLE_VALUES.HIROSHIMA ? (
              <HiroshimaBurdenTable>
                <tr>
                  <td className={"hiroshima-col"}>
                    {t("User burden upper limit management results")}
                  </td>
                  <td className={"hiroshima-col"}>
                    {getOfficeInfoAccordingToFId()
                      ?.max_amount_management_result || ""}
                  </td>
                  <td className={"hiroshima-col"}>
                    {t("User burden upper limit management results")}
                  </td>
                  <td className={"hiroshima-col"}></td>
                  <td className={"hiroshima-col"}>
                    {t(
                      "Calculation category for upper limit management addition"
                    )}
                  </td>
                  <td className={"hiroshima-col"}></td>
                </tr>
                <tr>
                  <td colSpan={4} className={"hiroshima-col"}>
                    <ol className={"ordered-list"}>
                      <li className={"list"}>
                        {t(
                          "Since the management office has appropriated the amount to be paid by the user, there will be no user burden at other offices."
                        )}
                      </li>
                      <li className={"list"}>
                        {t(
                          "Adjustment work will not be carried out because the total amount of user charges is less than the maximum monthly charge."
                        )}
                      </li>
                      <li className={"list"}>
                        {t(
                          "Since the total amount of user charges exceeds the maximum monthly charge, the following adjustments have been made."
                        )}
                      </li>
                    </ol>
                  </td>
                  <td colSpan={2} className={"hiroshima-col"}>
                    <ol className={"ordered-list"}>
                      <li className={"list"}>
                        {t("Calculated as nursing care benefit expenses, etc.")}
                      </li>
                      <li className={"list"}>
                        {t("Calculated as Hiroshima City subsidy.")}
                      </li>
                      <li className={"list"}>{t("Not calculated.")}</li>
                    </ol>
                  </td>
                </tr>
              </HiroshimaBurdenTable>
            ) : (
              <>
                <div className={"monthly_limit__amount"}>
                  <div className={"large"}>
                    {t("User burden upper limit monthly amount")}
                  </div>
                  <div className={"small"}>
                    {getOfficeInfoAccordingToFId()
                      ?.max_amount_management_result || ""}
                  </div>
                </div>
                <div className={"text__content"}>
                  <ol>
                    <li>
                      {t(
                        `Since the user-paid amount was allocated at the managed facility, no user-paid amount is incurred at the other facilities.`
                      )}
                    </li>
                    <li>
                      {printFilter?.style == PRINT_STYLE_VALUES.SHIZUOKA
                        ? t(
                            "The amount will not be adjusted because the total amount paid by the user is less than the maximum monthly payment amount."
                          )
                        : t(
                            `Since the total amount of user charges is less than or equal to the maximum monthly amount to be borne no adjustment is made.`
                          )}
                    </li>
                    <li>
                      {t(
                        `Since the total amount of user charges exceeded the maximum monthly amount to be borne, the following adjustment was made.`
                      )}
                    </li>
                  </ol>
                </div>
              </>
            )}
          </div>
        </div>
      </NBLMiddleInfoContent>
      {printFilter?.style == PRINT_STYLE_VALUES.KOBE ? (
        <KobeCityTableComponent>
          <NBLManagementCompanyTop>
            <div id={"NBLManagementCompanyTop"}>
              <Table
                id={"KobeCityTable"}
                showHeader={false}
                columns={[
                  {
                    title: "",
                    className: "kobe-col",
                    render: (obj, row, index) =>
                      index < 10
                        ? t(
                            "User burden summation / adjustment column ( before Kobe City's own reduction / exemption )"
                          )
                        : index > 10
                        ? t("Kobe City's own tax exemption")
                        : "",
                    width: 20,
                    onCell: (_, index) => {
                      if (index == 11) {
                        return {
                          rowSpan: 2,
                        }
                      }
                      if (index === 0) {
                        return { rowSpan: 10 }
                      }
                      return { rowSpan: 0 }
                    },
                  },
                  {
                    title: "",
                    dataIndex: "title",
                    align: "center",
                    className: "kobe-col",
                    width: 200,
                    onCell: (_, index) => {
                      if (index == 8) {
                        return { rowSpan: 2 }
                      }
                      if (index == 9) {
                        return { rowSpan: 0 }
                      }
                      if (index < 12) {
                        return { colSpan: 2 }
                      }
                      return {}
                    },
                  },
                  {
                    title: "",
                    dataIndex: "title2",
                    align: "center",
                    width: 200,
                    className: "kobe-col",
                    onCell: (_, index) => {
                      if (
                        index != 8 &&
                        index != 9 &&
                        index != 10 &&
                        index != 12
                      ) {
                        return { colSpan: 0 }
                      }
                      return {}
                    },
                  },
                  ...generateDynamicColumnForKobe(
                    data?.child_maximum_result || []
                  ),
                ]}
                pagination={false}
                dataSource={[
                  {
                    key: "1",
                    title: t("Item number"),
                  },
                  {
                    key: "2",
                    title: t("Beneficiary no."),
                  },
                  {
                    key: "3",
                    title: t("Child name"),
                  },
                  {
                    key: "4",
                    title: t("Business no."),
                  },
                  {
                    key: "5",
                    title: t("Business official name"),
                  },
                  {
                    key: "6",
                    title: t("Total amount"),
                  },
                  {
                    key: "7",
                    title: t(
                      "Day-to-day care benefits for disabled children, day-care benefits for disabled children"
                    ),
                  },
                  {
                    key: "8",
                    title: t("User fee"),
                  },
                  {
                    key: "9",
                    title: t("Management results"),
                    title2: t("User fee"),
                  },
                  {
                    key: "10",
                    title: "",
                    title2: t(
                      "Day-to-day care benefits for disabled children, day-care benefits for disabled children"
                    ),
                  },
                  {
                    key: "11",
                  },
                  {
                    key: "12",
                    title: t("Kobe City's own tax exemption"),
                  },
                  {
                    key: "13",
                    title: t("Management results"),
                    title2: (
                      <>
                        {t("User burden")}
                        <br />
                        {t("(Kobe City/benefits portion)")}
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </NBLManagementCompanyTop>
        </KobeCityTableComponent>
      ) : printFilter?.style == PRINT_STYLE_VALUES.HIROSHIMA ? (
        <NBLManagementCompanyTop>
          <div id={"NBLManagementCompanyTop"}>
            <Table
              showHeader={false}
              columns={[
                {
                  title: "",
                  className: "hiroshima-col",
                  render: (obj, row, index) =>
                    index < 9
                      ? t("User burden amount aggregation / adjustment column_")
                      : t(
                          "Hiroshima City subsidy upper limit management column"
                        ),
                  width: 20,
                  onCell: (_, index) => {
                    if (index == 0) {
                      return { rowSpan: 9 }
                    }
                    if (index == 9) {
                      return { rowSpan: 4 }
                    }
                    return { rowSpan: 0 }
                  },
                },
                {
                  title: "",
                  dataIndex: "title",
                  align: "center",
                  className: "hiroshima-col",
                  width: 200,
                  onCell: (_, index) => {
                    if (index < 7) {
                      return { colSpan: 2 }
                    }
                    if (index == 7) {
                      return { rowSpan: 2 }
                    }
                    if (index == 8) {
                      return { colSpan: 0 }
                    }
                    if (index == 10) {
                      return {
                        rowSpan: 3,
                      }
                    }
                    if (index > 10) {
                      return { rowSpan: 0 }
                    }
                    return {}
                  },
                },
                {
                  title: "",
                  dataIndex: "title2",
                  align: "center",
                  width: 200,
                  className: "hiroshima-col",
                  onCell: (_, index) => {
                    if (index < 7) {
                      return { colSpan: 0 }
                    }
                    return {}
                  },
                },
                ...generateDynamicColumnForHiroshima(
                  data?.child_maximum_result || []
                ),
              ]}
              pagination={false}
              dataSource={[
                {
                  key: "1",
                  title: t("Item number"),
                },
                {
                  key: "2",
                  title: t("Beneficiary no."),
                },
                {
                  key: "3",
                  title: t("Child name"),
                },
                {
                  key: "4",
                  title: t("Business no."),
                },
                {
                  key: "5",
                  title: t("Business official name"),
                },
                {
                  key: "6",
                  title: t("Total amount"),
                },
                {
                  key: "7",
                  title: t("User fee"),
                },
                {
                  key: "8",
                  title: t("Management results"),
                  title2: t("User burden amount (a)"),
                },
                {
                  key: "9",
                  title: "",
                  title2: t("Nursing care benefits, etc."),
                },
                {
                  key: "10",
                  title: t("Management results"),
                  title2: t("User burden (a)"),
                },
                {
                  key: "11",
                  title: t("Hiroshima City subsidy amount"),
                  title2: t("Grant amount (A) - (B)"),
                },
                {
                  key: "12",
                  title2: (
                    <>
                      {t("Upper limit management additional amount")}
                      <br />
                      {t("(Note)")}
                    </>
                  ),
                },
                {
                  key: "13",
                  title2: t("total"),
                },
              ]}
            />
          </div>
        </NBLManagementCompanyTop>
      ) : (
        <>
          <NBLManagementCompanyTop>
            <div id={"NBLManagementCompanyTop"}>
              <Table
                showHeader={false}
                columns={[
                  {
                    title: "",
                    render: () =>
                      t("User burden amount aggregation / adjustment column"),
                    width: 20,
                    onCell: (_, index) => {
                      if (index === 0) {
                        return { rowSpan: 8 }
                      }
                      return { rowSpan: 0 }
                    },
                  },
                  {
                    title: "",
                    dataIndex: "title",
                    align: "center",
                    width: 200,
                  },
                  ...generateDynamicColumn(data?.child_maximum_result || []),
                ]}
                pagination={false}
                dataSource={[
                  {
                    key: "1",
                    title: t("S.N"),
                  },
                  {
                    key: "2",
                    title: t("Beneficiary no."),
                  },
                  {
                    key: "3",
                    title: t("Child name"),
                  },
                  {
                    key: "4",
                    title: t("Business no."),
                  },
                  {
                    key: "5",
                    title: t("Business official name"),
                  },
                  {
                    key: "6",
                    title: t("Total amount"),
                  },
                  {
                    key: "7",
                    title: t("User’s burden amount"),
                  },
                  {
                    key: "6",
                    title: t("User burden after management results"),
                  },
                ]}
              />
            </div>
          </NBLManagementCompanyTop>

          <NBLManagementCompanyTop>
            <div id={"NBLManagementCompanyTop"}>
              <Table
                showHeader={false}
                columns={[
                  {
                    title: "",
                    render: () =>
                      t("User burden amount aggregation / adjustment column"),
                    width: 20,
                    onCell: (_, index) => {
                      if (index === 0) {
                        return { rowSpan: 8 }
                      }
                      return { rowSpan: 0 }
                    },
                  },
                  {
                    title: "",
                    dataIndex: "title",
                    align: "center",
                    width: 150,
                  },
                  ...data?.child_maximum_result?.map(() => ({
                    title: "",
                    render: (row, _, index) => getColumnInfo(undefined, index),
                  })),
                  {
                    title: t("Total"),
                    align: "center",
                    width: 180,
                    render: (_, __, index) => {
                      const row = getTotal()
                      return getColumnInfo(
                        {
                          total_cost: row?.total_cost || "",
                          user_fee: row?.user_fee || "",
                          user_burden_after_management:
                            row?.user_burden_after_management || "",
                        },
                        index,
                        t("Total")
                      )
                    },
                    onCell: (_, index) => {
                      if (index === 0) {
                        return { rowSpan: 5 }
                      }
                      if (index === 1) {
                        return { rowSpan: 0 }
                      }
                      if (index === 2) {
                        return { rowSpan: 0 }
                      }
                      if (index === 3) {
                        return { rowSpan: 0 }
                      }
                      if (index === 4) {
                        return { rowSpan: 0 }
                      }
                      return {}
                    },
                  },
                ]}
                pagination={false}
                dataSource={[
                  {
                    key: "1",
                    title: t("S.N"),
                  },
                  {
                    key: "2",
                    title: t("Beneficiary no."),
                  },
                  {
                    key: "3",
                    title: t("Child name"),
                  },
                  {
                    key: "4",
                    title: t("Business no."),
                  },
                  {
                    key: "5",
                    title: t("Business official name"),
                  },
                  {
                    key: "6",
                    title: t("Total amount"),
                  },
                  {
                    key: "7",
                    title: t("User’s burden amount"),
                  },
                  {
                    key: "8",
                    title: t("User burden after management results"),
                  },
                ]}
              />
            </div>
          </NBLManagementCompanyTop>
        </>
      )}

      {printFilter?.style == PRINT_STYLE_VALUES.KYOTO &&
        renderKyotoAdditionalTable()}

      {printFilter?.style == PRINT_STYLE_VALUES.HIROSHIMA && (
        <span style={{ fontSize: "10pt" }}>
          {t(
            "(Note) 150 units are calculated only when calculating the upper limit management addition as Hiroshima City subsidy."
          )}
        </span>
      )}

      <ConfirmationWrapper
        className={
          printFilter?.style == PRINT_STYLE_VALUES.KOBE
            ? "top-dotted-border pt-10"
            : ""
        }
      >
        {t("I have confirmed the above information.")}
        <br />
        {printFilter?.style == PRINT_STYLE_VALUES.SHIZUOKA
          ? "令和　　　　年　　　　月　　　　日"
          : "　　　　令和　　年　　月　　日"}{" "}
        <br />
        <div className={"footer-check"}>
          {t("Name of person with a disability for whom payment is determined")}
          {printFilter?.configuration?.includes(
            PRINT_CONFIGURATION_OPTION_VALUES.PRINT_MARK_ON_CONFIRMATION
          ) && <span style={{ float: "right" }}>{"印"}</span>}
        </div>
      </ConfirmationWrapper>
    </Wrapper>
  )
}
