import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Typography, Button, Form } from "antd"
import { useRouter } from "next/router"
import { RightOutlined } from "@ant-design/icons"
import {
  Checkbox,
  PageCard,
  TextField,
  theme,
  removeBlankAttributes,
} from "@project/shared"
import { Button as CustomButton } from "../../atoms"
import { DatePicker } from "../../molecules"
import { SearchContentSection } from "./InstructorContent.styles"
import { AuthContext } from "../../../utils"
import { useQuery } from "react-query"
import { getOccupations } from "../../../services/occupation"
import { generateURLString } from "../../../utils/generateURLString"

interface IProps {
  setSearchParams: (val: any, action?: string) => void
  defaultValues?: any
  isLoading?: boolean
  setButtonClicked?: (val: any) => void
  hasWriteAccess?: boolean
}
const format = "YYYY-MM-DD"
export const FilterInstructor: React.FC<IProps> = ({
  setSearchParams,
  defaultValues,
  isLoading,
  setButtonClicked,
  hasWriteAccess = false,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const [keyword, setKeyword] = useState(defaultValues?.keyword || "")

  const [checkedFacility, setCheckedFacility] = useState(
    defaultValues?.facility || []
  )

  const [searchClicked, setSearchClicked] = useState<boolean>(false)
  const [resetSearchClicked, setResetSearchClicked] = useState<boolean>(false)
  const [checkedOcupation, setChecketOccupation] = useState([])
  const [joinDate, setJoinDate] = useState<{ from: any; to: any }>({
    from: defaultValues?.joining_date ? defaultValues?.joining_date[0] : "",
    to: defaultValues?.joining_date ? defaultValues?.joining_date[1] : "",
  })
  const [retirementDate, setRetirementDate] = useState<{
    from: any
    to: any
  }>({
    from: defaultValues?.retirement_date
      ? defaultValues?.retirement_date[0]
      : "",
    to: defaultValues?.retirement_date ? defaultValues?.retirement_date[1] : "",
  })
  const { data: occupations } = useQuery("occupations", getOccupations, {
    select: (response) =>
      response?.data?.map((val) => ({
        label: val?.occupation_name,
        value: val?.id,
      })),
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (defaultValues?.occupation) {
      setChecketOccupation(defaultValues?.occupation || [])
    }
  }, [occupations, defaultValues?.occupation])
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target
    if (!checked) {
      setCheckedFacility(checkedFacility.filter((item) => item !== value))
    } else {
      setCheckedFacility([...checkedFacility, value])
    }
  }
  const onOccupationChecked = (e) => {
    const { value, checked } = e.target
    if (!checked) {
      setChecketOccupation(checkedOcupation.filter((item) => item !== value))
    } else {
      setChecketOccupation([...checkedOcupation, value])
    }
  }
  const onSearch = () => {
    const values = {
      keyword,
      joining_date:
        joinDate?.from && joinDate?.to
          ? `${moment(joinDate?.from).format(format)}_${moment(
              joinDate.to
            ).format(format)}`
          : "",
      retirement_date:
        retirementDate?.from && retirementDate?.to
          ? `${moment(retirementDate?.from).format(format)}_${moment(
              retirementDate.to
            ).format(format)}`
          : "",
      facility: checkedFacility?.toString(),
      occupation: checkedOcupation?.toString(),
    }
    setSearchParams(removeBlankAttributes(values))
    setButtonClicked(true)
  }

  const onSearchReset = () => {
    setKeyword("")
    setCheckedFacility([])
    setChecketOccupation([])
    setJoinDate({
      from: "",
      to: "",
    })
    setRetirementDate({
      from: "",
      to: "",
    })
    setSearchParams(
      {
        keyword: "",
        joining_date: "",
        retirement_date: "",
      },
      "reset"
    )
    setButtonClicked(true)
  }
  return (
    <SearchContentSection>
      <PageCard title={t("Operation options")}>
        <Form className={"content"}>
          <div className={"title"}>
            <Typography.Title level={5}>{t("Narrow down")}</Typography.Title>
          </div>
          <div className={"search_input"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Keyword")}
              </Typography.Paragraph>
            </div>
            <TextField
              type={"search"}
              height={"50px"}
              bgcolor={"transparent"}
              placeholder={t("Instructor name")}
              onChange={(e) => setKeyword(e?.target?.value)}
              value={keyword}
            />
          </div>
          <div className={"affiliated_facility"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Affiliated facility")}
              </Typography.Paragraph>
            </div>
            <div className={"checkboxs_container"}>
              <div className={"check_uncheck_all"}>
                <Button
                  icon={<img src={"/assets/icons/check.svg"} alt={"icon"} />}
                  shape={"round"}
                  onClick={() => {
                    const items = facilities?.map((li) => li.id) || []
                    setCheckedFacility([...items, 0])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Check all")}
                  </Typography.Text>
                </Button>
                <Button
                  shape={"round"}
                  style={{ background: theme.gray }}
                  onClick={() => {
                    setCheckedFacility([])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Uncheck all")}{" "}
                  </Typography.Text>
                </Button>
              </div>
              <div className={"checkboxs break-all-class"}>
                {facilities?.map((facility, i) => (
                  <Checkbox
                    key={i}
                    onChange={onFacilityChecked}
                    checked={checkedFacility?.includes(facility?.id)}
                    value={facility?.id || ""}
                    label={facility?.facility_name_short}
                  />
                ))}

                <Checkbox
                  onChange={onFacilityChecked}
                  checked={checkedFacility?.includes(0)}
                  value={0}
                  label={t("All")}
                />
              </div>
            </div>
          </div>
          <div className={"occupation"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Occupation")}
              </Typography.Paragraph>
            </div>
            <div className={"checkboxs_container"}>
              <div className={"checkboxs"}>
                {occupations?.map((occupation) => (
                  <Checkbox
                    key={occupation?.value}
                    onChange={onOccupationChecked}
                    checked={checkedOcupation?.includes(occupation?.value)}
                    value={occupation?.value || ""}
                    label={occupation?.label}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={"date_content"}>
            <div className={"join_date"}>
              <div className={"label header"}>
                <Typography.Paragraph>
                  <RightOutlined className={"icon"} />
                  {t("Join date")}
                </Typography.Paragraph>
              </div>
              <div className={"calender"}>
                <DatePicker
                  onDateChange={(val) =>
                    setJoinDate({
                      ...joinDate,
                      from: moment(val),
                    })
                  }
                  date={
                    moment(joinDate?.from).isValid()
                      ? moment(joinDate?.from)
                      : null
                  }
                  format={"YYYY年MM月DD日"}
                  className={"left-picker"}
                  style={{ width: "150px" }}
                />
                <Typography.Text>{"~"}</Typography.Text>
                <DatePicker
                  onDateChange={(val) =>
                    setJoinDate({
                      ...joinDate,
                      to: moment(val),
                    })
                  }
                  date={
                    moment(joinDate?.to).isValid() ? moment(joinDate?.to) : null
                  }
                  format={"YYYY年MM月DD日"}
                  className={"right-picker"}
                  style={{ width: "150px" }}
                />
              </div>
            </div>
            <div className={"retirement_date sbn-retirement_date"}>
              <div className={"label header"}>
                <Typography.Paragraph>
                  <RightOutlined className={"icon"} />
                  {t("Retirement date")}
                </Typography.Paragraph>
              </div>
              <div className={"calender"}>
                <DatePicker
                  onDateChange={(val) =>
                    setRetirementDate({
                      ...retirementDate,
                      from: moment(val),
                    })
                  }
                  date={
                    moment(retirementDate?.from).isValid()
                      ? moment(retirementDate?.from)
                      : null
                  }
                  format={"YYYY年MM月DD日"}
                  className={"left-picker"}
                  style={{ width: "150px" }}
                />
                <Typography.Text>{"~"}</Typography.Text>
                <DatePicker
                  onDateChange={(val) =>
                    setRetirementDate({
                      ...retirementDate,
                      to: moment(val),
                    })
                  }
                  date={
                    moment(retirementDate?.to).isValid()
                      ? moment(retirementDate?.to)
                      : null
                  }
                  format={"YYYY年MM月DD日"}
                  className={"right-picker"}
                  style={{ width: "150px" }}
                />
              </div>
            </div>
          </div>
          <div className={"cta"}>
            <CustomButton
              text={t("Search")}
              className={"search-btn"}
              backgroundColor={theme.blue5}
              shape={"circle"}
              color={theme.base}
              icon={"search"}
              width={120}
              type={"submit"}
              onClick={() => {
                setResetSearchClicked(false)
                setSearchClicked(true)
                onSearch()
              }}
              isLoading={(keyword != "" || searchClicked) && isLoading}
            />
            <CustomButton
              text={t("Reset search")}
              className={"reset-btn"}
              backgroundColor={theme.gray}
              borderColor={theme.gray2}
              shape={"circle"}
              color={theme.black}
              width={157}
              onClick={() => {
                setResetSearchClicked(true)
                setSearchClicked(false)
                onSearchReset()
              }}
              disabled={keyword == "" && resetSearchClicked && isLoading}
            />
          </div>
          <div className={"add_link"}>
            <div className={"title"}>
              <Typography.Title level={5}>{t("Add data")}</Typography.Title>
            </div>
            <br />
            <CustomButton
              text={t("Register new instructor")}
              backgroundColor={
                hasWriteAccess ? theme.blue5 : theme.disabledBlue
              }
              shape={"circle"}
              color={theme.base}
              padding={12}
              width={237}
              onClick={() =>
                router.push(
                  generateURLString(`${router?.pathname}/add`, defaultValues),
                  `${router?.pathname}/add`
                )
              }
              disabled={!hasWriteAccess}
            />
          </div>
        </Form>
      </PageCard>
    </SearchContentSection>
  )
}
