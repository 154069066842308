import { theme } from "@project/shared"

import styled from "styled-components"
export const ContentWrapper = styled.section``
export const SearchContentSection = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  .content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    row-gap: 20px;
    padding: 17px 0;
    @media (max-width: 768px) {
      row-gap: 0px;
      .label {
        margin-top: 20px;
      }
    }
    .title {
      border-left: 3px solid ${theme.blue5};
      padding-left: 8px;
      h5 {
        font-size: 18px;
        color: ${theme.black};
        margin: 0;
        font-weight: normal;
      }
    }

    .search_input,
    .affiliated_facility,
    .occupation,
    .date_content {
      /*display: flex;*/
      @media (max-width: ${theme.breakpoints?.md}) {
        flex-direction: column;
        align-items: start;
      }

      @media screen and (max-width: 1026px) {
        .header {
          min-width: 150px !important;
          max-width: 150px !important;
        }
      }

      .label {
        min-width: 150px;
        max-width: 150px;
        @media (max-width: ${theme.breakpoints?.md}) {
          min-width: 100%;
          max-width: 100%;
        }
        svg {
          color: ${theme.gray2};
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }
    .search_input {
      display: flex;
      align-items: baseline;
    }
    .search_input,
    .affiliated_facility,
    .occupation {
      display: flex;
      .checkboxs_container {
        .check_uncheck_all {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          gap: 10px;
          button {
            height: 30px;
            display: flex;
            gap: 4px;
            align-items: center;
            outline: 0;
            &:hover,
            &:focus {
              border-color: ${theme.gray2};
            }
            .span {
              @media (max-width: ${theme.breakpoints.md}) {
                font-size: 14px;
              }
            }
          }
        }
        .checkboxs {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }
        .break-all-class {
          @media (max-width: 768px) {
            .ant-checkbox + span {
              white-space: pre-line;
              word-break: break-all;
              padding-left: 5px;
            }
          }
        }
      }
    }

    .date_content {
      display: flex;
      flex-wrap: wrap;
      column-gap: 42px;
      row-gap: 15px;

      @media screen and (max-width: 768px) {
        row-gap: 0px;
      }

      @media screen and (max-width: 1025px) {
        flex-wrap: wrap;
      }
      .join_date,
      .retirement_date {
        display: flex;
        align-items: baseline;
        /*gap: 0px;*/
        @media (max-width: ${theme.breakpoints?.md}) {
          flex-direction: column;
        }
        .calender {
          display: flex;
          gap: 9.5px;
          @media (max-width: 768px) {
            gap: 0px;
            .right-picker {
              margin-left: 10px;
            }
            .left-picker {
              margin-right: 10px;
            }
          }

          @media (max-width: 492px) {
            .right-picker {
              margin-left: 5px;
            }
            .left-picker {
              margin-right: 5px;
            }
          }
          @media (max-width: 374px) {
            flex-wrap: wrap;
            gap: 10px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 37px;
            color: ${theme.black};
          }
        }
      }
      .retirement_date {
        .label {
          min-width: 150px !important;

          @media screen and (max-width: 1025px) {
            margin-right: 0;
          }
        }
      }
      .sbn-retirement_date {
        .label {
          min-width: auto;
          width: auto;
          white-space: nowrap;
        }
        @media screen and (max-width: 1025px) {
          min-width: 150px;
          margin-right: 0;
        }
      }
    }
    .cta {
      display: flex;
      gap: 9.5px;
      @media (max-width: 768px) {
        gap: 0px;
        margin-top: 20px;
        .search-btn {
          margin-right: 9px;
        }
      }
    }
    .add_link {
      margin-top: 33px;
    }
  }
`
export const TableContentSection = styled.div`
  width: 100% !important;
  .col-title {
    white-space: nowrap;
  }

  table {
    thead {
      tr {
        th:first-child {
          min-width: 75px;
        }
      }
    }
  }

  .disabled-edit {
    color: rgba(0, 0, 0, 0.3) !important;
    background: none !important;
  }
`
export const ErrorCounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 0px;
  margin-top: 4px;
`

export const ErrorStyled = styled.span`
  font-size: 12px;
  color: ${theme.alert};
  margin-left: 2px;
  height: 20px;
`
