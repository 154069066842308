import React, { useEffect } from "react"
import { Input, Typography } from "antd"

import styled from "styled-components"
import { CALANDER_HOURS_MINUTES, SelectInput, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import { allowOnlyNumber } from "../../../utils"

export const Container = styled.div`
  padding: 8px 0px;

  h5 {
    font-size: 14px;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    div {
      display: flex;
      align-items: center;
      .attendance-schedule-select-input {
        .ant-select {
          .ant-select-selector {
            .ant-select-selection-item {
              text-align: justify;
              font-size: 14px;
            }
          }
        }
      }
      .time_sep,
      .range_sep {
        font-size: 18px;
        color: ${theme.black};
        font-weight: bold;
      }
      .time_sep {
        margin: 0 5px;
      }
      .range_sep {
        margin-left: 5px;
      }
      &.last-hours {
        @media not print {
          padding-right: 15px;
        }
      }
    }
  }
  .break-time-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    input {
      border-radius: 5px;
      max-width: 58px;
    }
    .break-time-input-unit {
      display: flex;
      gap: 5px;
      font-size: 12px;
      .ant-input {
        padding: 4px !important;
      }

      .break-time {
        margin: 0;
      }
    }
  }
  .break-time-label {
    font-size: 10px;
  }
  .minutes-label {
    font-size: 8px;
  }
  @media print {
    .content {
      h5 {
        text-align: center !important;
      }
      .inputs {
        justify-content: center;
      }
    }
  }
`
type Args = {
  value: any
  id: number
  range: string
  unit: string
}
interface AttendanceScheduleTimeRangePorps {
  range: any
  rangeName: string
  disabled?: boolean
  handleChange: (val: Args) => void
}

export const AttendanceScheduleTimeRangeInput = ({
  range,
  rangeName,
  disabled = false,
  handleChange,
}: AttendanceScheduleTimeRangePorps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (range.from?.hours && !range.from?.minutes) {
      handleChange({
        value: "00",
        id: range?.id,
        range: "from",
        unit: "minutes",
      })
    }
  }, [range.from.hours])

  useEffect(() => {
    if (range.to?.hours && !range.to?.minutes) {
      handleChange({
        value: "00",
        id: range?.id,
        range: "to",
        unit: "minutes",
      })
    }
  }, [range.to.hours])
  return (
    <>
      <Container>
        <div className={"content"}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            {rangeName}
          </Typography.Title>
          <div className={"inputs"}>
            <div className={"first-hours"}>
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.hours}
                value={range.from.hours}
                width={54}
                height={40}
                placeholder={"--"}
                disabled={disabled}
                className={"attendance-schedule-select-input left-align"}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "from",
                    unit: "hours",
                  })
                }
                padding={"0px 5px"}
              />{" "}
              <Typography.Text className={"time_sep"}>{":"}</Typography.Text>{" "}
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.minutes}
                value={range.from.minutes}
                width={54}
                height={40}
                placeholder={"--"}
                disabled={disabled || !range?.from?.hours}
                className={"attendance-schedule-select-input left-align"}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "from",
                    unit: "minutes",
                  })
                }
                padding={"0px 5px"}
              />{" "}
              <Typography.Text className={"range_sep"}>{"~"}</Typography.Text>
            </div>
            <div className={"last-hours"}>
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.hours}
                value={range.to.hours}
                width={54}
                height={40}
                placeholder={"--"}
                disabled={disabled}
                className={"attendance-schedule-select-input left-align"}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "to",
                    unit: "hours",
                  })
                }
                padding={"0px 5px"}
              />{" "}
              <Typography.Text className={"time_sep"}>{":"}</Typography.Text>{" "}
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.minutes}
                value={range.to.minutes}
                width={54}
                height={40}
                disabled={disabled || !range?.to?.hours}
                className={"attendance-schedule-select-input left-align"}
                placeholder={"--"}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "to",
                    unit: "minutes",
                  })
                }
                padding={"0px 5px"}
              />{" "}
            </div>
            <div className={"break-time-container"}>
              <div className={"break-time-label"}>{t("Break time")}</div>
              <div className={"break-time-input-unit"}>
                <Input
                  className={"no-print"}
                  type={"number"}
                  value={range.break_time || ""}
                  min={0}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    if (e.target?.value.length > 3) return false
                    handleChange({
                      value: allowOnlyNumber(e.target.value),
                      id: range?.id,
                      range: "break_time",
                      unit: "",
                    })
                  }}
                  disabled={disabled}
                />
                <p className={"only-print break-time"}>{range.break_time}</p>
                <div className={"minutes-label"}>{t("minutes")}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
