import { API } from "@project/shared"

export const saveColor = async (values: any) => {
  return API.post(`/colors`, values)
}

export const fetchColor = (id: string | string[] | number) =>
  API.get(`/colors/${id}`)

export const updateColors = ({
  values,
  id,
}: {
  values: any
  id: string | number
}) => API.put(`/colors/${id}`, { ...values })

export const fetchAllColors = () => API.get(`/colors`)
