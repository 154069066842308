/**
 * NOTE:
 * - R6 April [established:新設]
 */

import { t } from "i18next"
import { AdditionType } from "../../../../../constants/enums/additioinType"
import { Field } from "../fields"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getCoreFunctionEnhancementAddition(date: Moment) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return null
    default:
      return r6April
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

// TODO: translate
/**
 * @en
 * @ja 中核機能強化加算
 */
const r6April: Field = {
  label: "中核機能強化加算",
  name: AdditionType.CORE_FUNCTION_ENHANCEMENT_ADDITION,
  fields: [
    {
      label: t("No change"),
      value: 0,
    },
    {
      label: t("No"),
      value: 1,
    },
    {
      label: t("I"),
      value: 2,
    },
    {
      label: t("II"),
      value: 3,
    },
    {
      label: t("III"),
      value: 4,
    },
  ],
}

/**
 * --------------------------------
 * @version BASELINE
 *
 * NONE
 * --------------------------------
 */
