// pacakges
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const Container = styled.div`
  .middle-back-btn {
    margin: 30px 0;
  }

  .divider {
    margin: 20px 0;
  }

  .spin-container {
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .underline {
    text-decoration: underline;
  }

  p,
  ul,
  h4,
  h3 {
    margin-bottom: 0;
  }
`

export const InfoBoxWrapper = styled.div`
  border-radius: 12px;
  padding: 24px 30px;
  background-color: ${theme.base};
  border: 1px solid ${theme.red1};

  .info-box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 36px;
    font-weight: 700;
    color: ${theme.black};
    .info-text {
      margin-bottom: 0;
      .red-text {
        color: ${theme.red1};
      }
    }
    a {
      color: ${theme.blue5} !important;
      font-weight: 400;
    }
  }
`

export const ChangeDisplayWrapper = styled.div`
  .change-display-box-content {
    column-gap: 24px;
  }
  .checkbox-group .ant-checkbox-group .ant-checkbox-wrapper {
    margin-bottom: 0;
  }
`

export const NoticeWrapper = styled.div`
  border: 1px solid ${theme.gray2};
  border-radius: 12px;
  padding: 24px 30px;
  background-color: ${theme.base};

  .notice-content {
    color: ${theme.red1};

    .notice-toggle-btn {
      background-color: transparent;
      border: none;
      padding: 0;
      text-align: left;

      .notice-content_title {
        font-size: 18px;
        font-weight: 700;
        .down-facing-arrow {
          .red-down-facing-arrow {
            margin-inline: 5px;
          }
          &.active {
            .red-down-facing-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .notice-content_more-info {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease-in-out;

      .notice-grid-contents {
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        .notice-content_sub-title {
          font-size: 16px;
          font-weight: 400;
        }
        ul li {
          margin-left: 18px;
          .underline {
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }

      &.show {
        grid-template-rows: 1fr;
        .notice-grid-contents {
          opacity: 1;
        }
      }
    }
  }
`

export const FilterOperationContainer = styled.div`
  .operation-rounded-container {
    margin-top: 30px;
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`

export const FilterOperationContent = styled.div`
  &.filter-operation-content {
    display: flex;
    margin-top: 24px;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;

    .date-picker {
      padding: 9px 14px;
      max-width: 177px;
      min-width: 140px;
      width: 100%;
      input {
        line-height: 100%;
      }
    }
    .facility-select {
      width: 100%;
      max-width: 200px;
      min-width: 150px;
      .ant-select-selector {
        padding: 4.2px 14px;
        height: auto !important;
      }
    }

    .form-button-group {
      button.search-button {
        padding: 9px 16px;
      }
    }
  }
`

export const FacilityChildListContainer = styled.div`
  .operation-rounded-container {
    .primary-action-btn-group {
      column-gap: 10px;

      .print-btn {
        border-color: #0782c8;
        background: #0782c8;
        padding: 11px 12px;
        color: ${theme.base};
      }

      .extra-stat-btn {
        background-color: transparent;
        border: 1px solid ${theme.gray2};
        padding: 8px 10px;
        color: ${theme.black};
        font-size: 14px;
        line-height: 20px;
        .bold-text {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }

    .upper-notice-section {
      display: grid;
      gap: 24px;
      margin-top: 24px;
    }

    .multi-functional-btns-section {
      display: grid;
      gap: 20px;

      .secondary-action-btn-group {
        column-gap: 10px;
        button {
          padding: 6px 16px;
          word-break: break-all;
          white-space: break-spaces;
        }
      }
    }

    .table-section {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      flex-direction: column;
      .guide-line-text {
        font-size: 14px;
        font-weight: 400;
        color: ${theme.gray3};
        line-height: 20.27px;
      }

      .name-text {
        font-size: 18px;
        font-weight: 500;
        color: ${theme.black};
        line-height: 26.06px;
      }

      .provision-city-header {
        font-size: 20px;
        font-weight: 500;
        color: ${theme.black};
        padding: 10px;
        border-left: 5px solid green;
        background: ${theme.gray4};
      }

      .stat-info {
        font-size: 16px;
        font-weight: 400;
        color: ${theme.black};
        line-height: 23.17px;
        .red-text {
          color: ${theme.red2};
          .bold-text {
            font-weight: 900;
            font-size: 20px;
          }
        }
      }

      .table-container {
        table {
          border-collapse: collapse;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          overflow: hidden;

          thead.ant-table-thead {
            tr th.ant-table-cell {
              background-color: ${theme.gray2};
              border-color: ${theme.gray2};
              border-top: 1px solid ${theme.gray2};
              white-space: break-spaces;
              text-align: center;
              &:first-child {
                border-left: 1px solid ${theme.gray2};
              }
              &:last-child {
                border-right: 1px solid ${theme.gray2};
              }

              &:before {
                background-color: ${theme.gray3} !important;
              }
            }
          }

          tbody.ant-table-tbody .ant-table-row {
            &.active {
              background-color: ${theme.yellow1} !important;
            }
            .ant-table-cell {
              background-color: ${theme.base} !important;
              text-align: center;

              .ant-checkbox-wrapper {
                margin-inline: auto;
              }

              &.selection-cell {
                padding: 16px;
                .selection-checkbox-wrapper {
                  display: grid;
                  place-content: center;
                  .ant-checkbox-wrapper {
                    gap: 0;
                  }
                }
              }
              &.division-cell {
                padding: 16px;

                .division-selector {
                  margin-inline: auto;
                }
              }

              &.name-of-business-cell {
                min-width: 100px;
              }
              padding: 6px 8px;
              border: 1px solid ${theme.gray2};

              .multi-cell {
                .sub-cell {
                  display: grid;
                  place-content: center;
                  width: 100%;
                  border-bottom: 1px solid ${theme.gray2};
                  padding: 16px;
                  line-height: 110%;

                  &.sub-cell:last-child {
                    border-bottom: none;
                  }
                }
              }
            }

            &.selected-row {
              .ant-table-cell {
                background-color: ${theme.yellow4} !important;
              }
            }
          }

          .selection-checkbox {
            margin-left: auto !important;
          }

          .selected-row {
            background: #ffe694;
          }

          @media print {
            width: 700px !important;
          }
        }
      }
    }

    .lower-footer-section {
      margin-top: 20px;
      display: grid;
      gap: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }

    /* Style for print mode */
    @media print {
      padding: 2px 5px 4px 5px;

      .table-section {
        .table-container {
          .ant-table-container {
            &:before,
            &:after {
              box-shadow: none;
            }
            table {
              border-collapse: collapse;
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              thead.ant-table-thead {
                tr th.ant-table-cell {
                  width: calc(100% / 6);
                  font-size: 12px;
                  padding: 8px;
                  line-height: 110%;

                  &.division-head-cell {
                    border-left: 1px solid ${theme.gray2};
                  }

                  &.item-head-cell {
                    width: auto;
                    min-width: 50px;
                  }
                }
              }

              tbody.ant-table-tbody .ant-table-row {
                .ant-table-cell {
                  font-size: 14px;
                  &.selection-cell {
                    padding: 8px;
                  }
                  &.division-cell {
                    padding: 8px;
                  }
                  .multi-cell {
                    .sub-cell {
                      padding: 8px;
                    }
                  }
                }
                &.selected-row {
                  .ant-table-cell {
                    background-color: ${theme.base} !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const OutputButton = styled.button`
  border: 1px solid ${theme.blue5};
  background-color: ${theme.blue6};
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px 30px;
  font-size: 16px;

  line-height: 23.17px;
  color: ${theme.black};
  column-gap: 12px;

  &:hover {
    filter: brightness(104%);
  }

  &:disabled {
    background-color: ${theme.bgColor2};
    border-color: ${theme.gray2};
    color: ${theme.gray3};
    filter: brightness(100%);
    cursor: not-allowed;
  }
`

export const PrintButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  .print-button {
    background: ${theme.blue5};
  }
  @media print {
    display: none;
  }
`
export const OfficeContainer = styled.div`
  font-size: 20pt;
  text-align: center;
  padding: 100px;
  margin: 20px 0;
  background: #ffffff;
  @media print {
    page-break-before: always;
    page-break-after: always;
  }
`
