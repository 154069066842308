// packages
import { t } from "i18next"

// styled components
import { StyledSorter } from "./styles"

// types
type WithSorterProps = {
  titleProps: any
  title: string
  columnKey: string
}

// components
export const UpArrow = ({ glow }: { glow?: boolean }) => {
  return (
    <svg
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M1 4.07422L5.06082 1.33102L9.15203 4.07422"}
        stroke={glow ? "black" : "#a5a5a5"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export const DownArrow = ({ glow }: { glow?: boolean }) => {
  return (
    <svg
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M9.15234 0.921875L5.09152 3.66508L1.00032 0.921875"}
        stroke={glow ? "black" : "#a5a5a5"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export const WithSorter: React.FC<WithSorterProps> = ({
  titleProps,
  title,
  columnKey,
}) => {
  const sortedColumn = titleProps.sortColumns?.find(
    ({ column }) => column.key === columnKey
  )
  return (
    <StyledSorter>
      <span>{t(title)}</span>
      <div className={"sort-group"}>
        <span className={"sort-up"}>
          <UpArrow glow={sortedColumn?.order === "ascend"} />
        </span>
        <span className={"sort-down"}>
          <DownArrow glow={sortedColumn?.order === "descend"} />
        </span>
      </div>
    </StyledSorter>
  )
}
