// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared/src/theme"

export const RedText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20.27px;
  color: ${theme.red2};
  margin-top: 18px;
  margin-bottom: 22px;
`

export const StyledFieldWrapper = styled.div<{
  $isRowFlexed: boolean
  $isFirstChild?: boolean
}>`
  display: flex;
  justify-content: flex-start;
  border-right: 1px solid ${theme.gray2};
  border-bottom: 1px solid ${theme.gray2};
  border-left: 1px solid ${theme.gray2};
  page-break-inside: avoid;
  border-top: ${({ $isFirstChild }) =>
    $isFirstChild ? `1px solid ${theme.gray2}` : `0px`};
  .label {
    width: 25.42%;
    max-width: 300px;
    min-width: 280px;
    border-right: 1px solid ${theme.gray2};
    text-align: left;
    background-color: ${theme.bgColor2};
    padding: 20px 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    /* flex-wrap: wrap; */
    s span {
      ${theme.typography.label}
      color: ${theme.black}
    }
    .icons-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media (max-width: 780px) {
      gap: 5px;
    }
  }
  .field {
    width: calc(100% - 25.42%);
    padding: 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: ${({ $isRowFlexed }) => (!$isRowFlexed ? "column" : "row")};
    gap: 12px;
    @media print {
      page-break-inside: avoid;
    }
  }

  /*  only for screen not in print */
  @media screen {
    @media (max-width: 940px) {
      flex-direction: column;
      border: 0px;
      .label {
        width: 100%;
        border-right: none;
        max-width: 100%;
        border: 1px solid ${theme.gray2};
      }
      .field {
        width: 100%;
        padding-inline: 0;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }

  @media print {
    border: 0px;

    .label {
      border-right: 1px solid ${theme.black};
      border-top: 1px solid ${theme.black};
      border-left: 1px solid ${theme.black};
      background: transparent;
    }

    .field {
      border-top: 1px solid ${theme.black};
      border-right: 1px solid ${theme.black};
    }

    &:last-child {
      border-bottom: 1px solid ${theme.black};
    }
  }
`

export const FlexContainer = styled.div<{ $isRowFlexed: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: ${({ $isRowFlexed }) => ($isRowFlexed ? "row" : "column")};
  align-items: ${({ $isRowFlexed }) =>
    $isRowFlexed ? "center" : "flex-start"};
  gap: 18px;
  /* @media (max-width: 610px) {
    flex-direction: column;
  } */
  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 469px) {
    & .service-type-dropdown {
      width: 100% !important;
      .over-rider-select-class {
        width: 100% !important;
      }
    }
    & .consultation-support-dropdown {
      width: 100% !important;
      .over-rider-select-class {
        width: 100% !important;
      }
    }

    & .go-to-consultation-support-button {
      width: 100% !important;
    }
    & .maximum_manage_company_dropdown {
      width: 200px !important;
      .over-rider-select-class {
        width: 100% !important;
      }
    }
  }
`

export const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.red2};
`

export const DateRangeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .ant-picker {
    padding: 12px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .ant-picker-input {
    min-width: 125px;
  }
  .datePicker-separator {
    ${theme.typography.label}
    width: 30px;
    text-align: center;
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .ant-picker {
    padding: 12px 15px;
    border-radius: 5px;
  }
  .datePicker-separator {
    ${theme.typography.label}
    width: 30px;
    text-align: center;
  }
`

export const DropDownContainer = styled.div`
  .over-rider-select-class {
    .ant-select-selector {
      height: 40px !important;
      .ant-select-selection-item {
        line-height: 36px !important;
      }
    }
  }
`

export const RequiredTag = styled.span`
  color: ${theme.base} !important;
  background-color: ${theme.red2};
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 12px;
  white-space: nowrap;
`

export const HelperText = styled.p`
  ${theme.typography.PackDesc}
  color: ${theme.gray10};
  margin-top: 6px !important;
`

export const CheckboxesContainer = styled.div`
  display: flex;
`

export const RadioButtonsContainer = styled.div`
  display: flex;
`

export const NumberFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & .ant-input {
    width: 100% !important;
    padding: 8px !important;
  }
`
