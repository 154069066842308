// packages
import styled from "styled-components"

// Theme
import { theme } from "@project/shared"

// styled component
const QuoteStyledText = styled.h4<{
  $margin?: string
}>`
  font-size: 18px;
  font-weight: 500;
  padding-left: 8px;
  line-height: 120%;
  color: ${theme.black};
  border-left: 3px solid ${theme.blue5};
  margin: ${({ $margin }) => ($margin ? $margin : "0px")};
`

export const QuoteText: React.FC<
  {
    children: React.ReactNode
    margin?: string
  } & React.ComponentPropsWithRef<"h4">
> = ({ children, margin, ...props }) => {
  return (
    <QuoteStyledText $margin={margin} {...props}>
      {children}
    </QuoteStyledText>
  )
}
