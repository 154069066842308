import { ComponentProps } from "react"

type Props = {
  name: string
} & ComponentProps<"h4">

export function HeaderParentName({
  name,
  className,
  style = {},
  ...props
}: Props) {
  return (
    <h4
      className={`name-text no-print ${className}`}
      style={{ marginBottom: 8, ...style }}
      {...props}
    >
      {name}
    </h4>
  )
}
