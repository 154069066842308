import { t } from "i18next"
import React from "react"

import moment, { Moment } from "moment"

import { StyledFieldWrapper } from "../styles"
import {
  Field,
  exceedCapacity,
  lackOfChildDevelopmentSupportManagerSubtraction,
  lackOfServiceEmployeeSubtraction,
  subtractionOfOpeningTime,
  unpublishedSubtractionOfSelfEvaluationResults,
  welfareSpecialistPlacementAddition,
} from "./fields"
import {
  getProfessionalSupportSystemAddition,
  getCoreFunctionEnhancementAddition,
  getCoreFunctionEnhancementSiteAddition,
  getProfessionalSupportAddition,
  getAdditionalAllowanceForChildInstructors,
  getProvisionTimeDivision,
  getPhysicalRestraintAbolitionNonImplementationSubtraction,
} from "./version"

// 児童発達支援(Child development support)
export function getChildDevelopmentSupportFields(date: Moment): Field[] {
  return [
    exceedCapacity,
    lackOfServiceEmployeeSubtraction,
    lackOfChildDevelopmentSupportManagerSubtraction,
    unpublishedSubtractionOfSelfEvaluationResults,
    getPhysicalRestraintAbolitionNonImplementationSubtraction(date),
    subtractionOfOpeningTime,
    getProvisionTimeDivision(date),
    // NOTE: Not required after version R6April
    // getAbusePreventionNotImplementedSubtraction(date),
    // getBusinessContinuityPlanNotImplementedSubtraction(date),
    // getInformationDisclosureNotReportedSubtraction(date),
    // getSupportProgramNotPublicizedSubtraction(date),
    getAdditionalAllowanceForChildInstructors(date),
    getProfessionalSupportAddition(date),
    getProfessionalSupportSystemAddition(date),
    welfareSpecialistPlacementAddition,
    getCoreFunctionEnhancementAddition(date),
    getCoreFunctionEnhancementSiteAddition(date),
  ].filter((v) => v !== null)
}

// 放課後等デイサービス(After day school)
export function getAfterDaySchoolServiceFields(date: Moment): Field[] {
  return [
    exceedCapacity,
    lackOfServiceEmployeeSubtraction,
    lackOfChildDevelopmentSupportManagerSubtraction,
    unpublishedSubtractionOfSelfEvaluationResults,
    getPhysicalRestraintAbolitionNonImplementationSubtraction(date),
    subtractionOfOpeningTime,
    getProvisionTimeDivision(date),
    // NOTE: Not required after version R6April
    // getAbusePreventionNotImplementedSubtraction(date),
    // getBusinessContinuityPlanNotImplementedSubtraction(date),
    // getInformationDisclosureNotReportedSubtraction(date),
    // getSupportProgramNotPublicizedSubtraction(date),
    getAdditionalAllowanceForChildInstructors(date),
    getProfessionalSupportAddition(date),
    getProfessionalSupportSystemAddition(date),
    welfareSpecialistPlacementAddition,
    getCoreFunctionEnhancementSiteAddition(date),
  ].filter((v) => v !== null)
}

export const FieldWrapper = ({
  label,
  children,
  required,
  hasInfoIcon,
}: {
  label: string
  children: JSX.Element
  required?: boolean
  hasInfoIcon?: boolean
}) => {
  return (
    <StyledFieldWrapper>
      <div className={"label"}>
        <span>{t(label)}</span>
        {required || hasInfoIcon ? (
          <div className={"icons-wrapper"}>
            {required && <span className={"required"}>{t("Required")}</span>}
            {hasInfoIcon && (
              <img
                src={"/assets/icons/QuestionMarkCircled.svg"}
                alt={"question mark icon"}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className={"field"}>{children}</div>
    </StyledFieldWrapper>
  )
}

export const facilityItemManagementBodyGenerator = (
  values: any,
  facility_id: number
) => {
  const objWithNumbersOnly: any = {}
  const objWithNonNumbers: any = {}

  Object.keys(values).forEach((key) => {
    if (/^\d+$/.test(key) || /^\d+_2$/.test(key)) {
      objWithNumbersOnly[key] = values[key]
    } else {
      objWithNonNumbers[key] = values[key]
    }
  })

  const objWith2Digits: any = {}
  const objWithout2Digits: any = {}

  Object.keys(objWithNumbersOnly).forEach((key) => {
    if (key.endsWith("_2")) {
      objWith2Digits[key] = objWithNumbersOnly[key]
    } else {
      objWithout2Digits[key] = objWithNumbersOnly[key]
    }
  })

  if (Object.keys(objWith2Digits).length) {
    const afterData = {
      service_type: 1,
      items: [],
    }
    const childData = { service_type: 2, items: [] }

    for (const key in objWithout2Digits) {
      if (!isNaN(parseInt(key))) {
        afterData.items.push({
          addition_type: Number(key),
          addition_data: objWithout2Digits[key],
        })
      }
    }
    for (const key in objWith2Digits) {
      if (!isNaN(parseInt(key))) {
        childData.items.push({
          addition_type: Math.trunc(Number(key.replace(/_2$/, ""))),
          addition_data: objWith2Digits[key],
        })
      }
    }

    const bodyArray = {
      date: moment(objWithNonNumbers?.date).format("YYYY-MM-DD"),
      end_date: null,
      facility_id: facility_id,
      data: [afterData, childData],
    }

    if (objWithNonNumbers?.end_date && objWithNonNumbers?.enterByPeriod)
      bodyArray["end_date"] = moment(objWithNonNumbers?.end_date).format(
        "YYYY-MM-DD"
      )

    return bodyArray
  }
  const data = {
    service_type: "serviceType1" in objWithNonNumbers ? 1 : 2,
    items: [],
  }

  for (const key in objWithout2Digits) {
    if (!isNaN(parseInt(key))) {
      data.items.push({
        addition_type: Number(key),
        addition_data: objWithout2Digits[key],
      })
    }
  }
  const singleServiceBodyArray = {
    date: moment(objWithNonNumbers?.date).format("YYYY-MM-DD"),
    end_date: null,
    facility_id: facility_id,
    data: [data],
  }
  if (objWithNonNumbers?.end_date && objWithNonNumbers?.enterByPeriod)
    singleServiceBodyArray["end_date"] = moment(
      objWithNonNumbers?.end_date
    ).format("YYYY-MM-DD")

  return singleServiceBodyArray
}
