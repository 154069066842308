import { API } from "@project/shared"
export interface ShuttleCarResponseObj {
  id: number
  created_datetime?: string
  created_by?: string
  updated_datetime?: string
  updated_by?: string
  deleted_datetime?: null
  deleted_by?: string
  is_deleted?: false
  company_id?: number
  car_name: string
  max_number_of_people: number
  color_id: string
  car_inspection_date: string
  car_number_01: string
  car_number_02: string
  car_number_03: string
  car_number_04: string
}
export interface ShuttleCarResponse {
  count: number
  data: ShuttleCarResponseObj[]
}

export const createShuttleCar = async (values: any) => {
  return API.post(`/shuttlecars`, values)
}
export const fetchShuttleCars = async (
  page: number,
  pageSize: number | string
): Promise<ShuttleCarResponse> => {
  return API.get(`/shuttlecars?page=${page}&pageSize=${pageSize}`)
}
export const fetchShuttleCar = async (
  id: string
): Promise<{ data: ShuttleCarResponseObj }> => {
  return API.get(`/shuttlecars/${id}`)
}
export const updateShuttleCar = async ({ values, id }: any) => {
  return API.put(`/shuttlecars/${id}`, values)
}

export const deleteShuttleCar = async (id) => {
  return API.delete(`/shuttlecars/${id}`)
}
