import { useTranslation } from "react-i18next"
import { Container, VehicleTable } from "./DriverEntry.styles"

const CheckItem = ({ title }: { title: string }) => (
  <div className={"child"}>
    <div className={"title"}>{title}</div>
    <div className={"bb"}>{"□"}</div>
  </div>
)

export const DriverEntry = ({ show }: { show: boolean }) => {
  const { t } = useTranslation()

  if (!show) return <></>

  return (
    <Container>
      <VehicleTable>
        <tbody>
          <tr>
            <td>{t("Meter")}</td>
            <td>{"km (出発)"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Guideline")}</td>
            <td>{t("km(終着)")}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Mileage")}</td>
            <td>{"km(総計)"}</td>
          </tr>
          <tr className={"row"}>
            <td>
              {t("Next oil change")}
              <br />
              {t("Every {{distance}} km", { distance: "5000" })}
            </td>
            <td>{"km"}</td>
          </tr>
          <tr className={"row"}>
            <td>
              {t("Next oil element change")}
              <br />
              {t("Every {{distance}} km", { distance: "10000" })}
            </td>
            <td>{"km"}</td>
          </tr>
          <tr className={"merged-row bg-gray"}>
            <td colSpan={2}>{t("Fuel Purchase")}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Gasoline")}</td>
            <td>{t("Litre")}</td>
          </tr>{" "}
          <tr className={"row"}>
            <td>{t("Oil")}</td>
            <td>{t("Litre")}</td>
          </tr>{" "}
          <tr className={"row"}>
            <td>{t("Oil element")}</td>
            <td>{t("回に１回")}</td>
          </tr>
        </tbody>
      </VehicleTable>
      <VehicleTable>
        <thead>
          <tr>
            <th colSpan={2}>{t("◆出発前　点検事項")}</th>
            <th>{t("Inspector")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={"row"}>
            <td>{t("Engine oil")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
            <td className={"symbol-cell"} rowSpan={5}>
              {"㊞"}
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Brake oil")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Tyre groove")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Radiator condition")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Engine sound")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Child lock Ok")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
            <td className={"symbol-cell"} rowSpan={4}>
              {"㊞"}
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Window lock OK")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Lights")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Mirror OK")}</td>
            <td className={"checkbox-cell"}>
              <CheckItem title={""} />
            </td>
          </tr>
        </tbody>
      </VehicleTable>
    </Container>
  )
}
