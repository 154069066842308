import {
  SelectInput,
  Checkboxes,
  Checkbox,
  TextField,
  CHILD_SERVICE_OPTION,
  setItemToLocalstorage,
} from "@project/shared"
import moment from "moment"
import React, { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getAllChildUnpaginated } from "../../../services"
import { Highlighter, Title, TitleWrapper, AuthContext } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"
import { Form } from "antd"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      facility_ids: string
      year: string
      show_digital_signature: any
      show_non_digital_signature: any
      child_ids: any
      service_type_ids: any
      month: string
      keyword: string
    }>
  >
  queryParam?: {
    facility_ids: string
    year: string
    show_digital_signature: any
    show_non_digital_signature: any
    child_ids: any
    service_type_ids: any
    month: string
    keyword: string
  }
  isLoadingData?: boolean
  setCurrentPage?: (val: number) => void
}

const Wrapper = styled(Form)<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
    padding: 10px 30px;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 9px;
  }
  .select-up {
    margin-right: 6px;
  }
  .title-wrap {
    min-width: 125px;
  }
  /* @media (max-width: 767px) {
    .select-up {
      width: 100%;
    }
  } */
  @media (max-width: 456px) {
    .search-btn {
      font-size: 14px;
    }
    .reset-button {
      font-size: 14px;
    }
  }
  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div<{ isNoBottomMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props?.isNoBottomMargin ? "-15px" : "20px")};
  width: 100%;

  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .text-field {
    @media (max-width: 769px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .facility-wrapper {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 420px) {
    .wrapper_btn {
      padding: 12px 16px;
    }
  }

  @media (max-width: 767px) {
    .facility-wrapper {
      flex-direction: row;
      gap: 10px;

      & > button:last-child {
        margin-top: 0;
        margin-left: 0;
      }
    }
  }

  @media (max-width: 600px) {
    .facility-wrapper {
      flex-direction: row;

      justify-content: space-between;

      & > button:last-child {
        margin-top: 0;
        margin-left: 0;
      }
      span {
        font-size: 12px !important;
      }
    }

    .checkbox-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .info-wrapper {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
  @media (max-width: 456px) {
    font-size: 14px;
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: pre-wrap;
  width: 100%;

  .check-box {
    white-space: pre-wrap;
  }
  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .date-picker {
    height: 40px;
    width: 160px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .select-down {
      width: 100%;
      margin-right: 0;
    }
    .select-class {
      margin-top: 5px;
      width: 100%;
    }
  }

  @media (max-width: 590px) {
    .date-picker {
      width: auto;
    }
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 145px;
  width: 100%;

  & > div {
    width: 100%;
    .checkbox-group .ant-checkbox-group {
      width: 100%;
      .ant-checkbox-wrapper {
        max-width: 100%;
        span:last-child {
          max-width: 100%;
          word-wrap: break-word;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding-left: 0px;
  }
`

const ServiceRecordSheetOperationComponent: React.FC<
  iOperationOptionComponent
> = (props) => {
  const { header, isLoadingData, queryParam, setQueryParam, setCurrentPage } =
    props
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const [check, setCheck] = useState(queryParam?.facility_ids || null)
  const { facilities } = useContext(AuthContext)
  const [keyword, setKeyword] = useState(queryParam?.keyword || "")
  const [childName, setChildName] = useState(null)
  const [childId, setChildId] = useState(queryParam?.child_ids || null)
  const [facilityIds, setFacilityIds] = useState(queryParam?.facility_ids || "")
  const [otherFacilities, setOtherFacilities] = useState([])
  const [displayAlternativeStatement, setDisplayAlternativeStatement] =
    useState(queryParam?.show_non_digital_signature || false)
  const [displayDigitalSignature, setDisplayDigitalSignature] = useState(
    queryParam?.show_digital_signature || false
  )
  const [useService, setUseService] = useState(
    queryParam?.service_type_ids || null
  )

  const [year, setYear] = useState(queryParam?.year || "")
  const [month, setMonth] = useState(queryParam?.month || "")

  const CHILD_FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const SORT_OPTION = FuriganaAlphabetsOption
  const { isLoading, data: childList } = useQuery(
    ["fetch-child-data-only"],
    getAllChildUnpaginated,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const OTHER_CHILD_OPTIONS =
    !isLoading &&
    childList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.child_name || "", //Check for Furigana name first
        furigana: item?.child_name_furigana,
      }
    })

  const allFacility = CHILD_FACILITY_OPTION.map((item) => item.value)
  const handleSearch = () => {
    const params = {
      facility_ids: Array.isArray(facilityIds)
        ? facilityIds?.join(",")
        : facilityIds,
      show_digital_signature: displayDigitalSignature ? "1" : "",
      show_non_digital_signature: displayAlternativeStatement ? "1" : "",
      child_ids: childId,
      service_type_ids: Array.isArray(useService)
        ? useService?.join(",")
        : useService,
      year: year,
      month: month,
      keyword: keyword,
      showAbsence: 1,
    }
    setCurrentPage(1)
    setQueryParam((prev) => {
      return {
        ...prev,
        ...params,
      }
    })
    updateParams(
      {
        ...params,
      },
      "/service-provision-record-sheet"
    )
    setItemToLocalstorage("service-provision-record-sheet", {
      facility_ids: params?.facility_ids?.toString(),
    })
  }

  const handleReset = () => {
    setChildName("")
    setChildId("")
    setCheck(null)
    setFacilityIds("")
    setDisplayAlternativeStatement(false)
    setDisplayDigitalSignature(false)
    setYear(moment().format("YYYY"))
    setMonth(moment().format("MM"))
    setKeyword("")
    setUseService(null)
    setQueryParam((prev) => {
      return {
        ...prev,
        keyword: "",
        facility_ids: null,
        year: moment().format("YYYY"),
        month: moment().format("M"),
        show_digital_signature: null,
        show_non_digital_signature: null,
        child_ids: null,
        showAbsence: 1,
        service_type_ids: null,
      }
    })
    setCurrentPage(1)
    updateParams({}, "/service-provision-record-sheet")
    setItemToLocalstorage("service-provision-record-sheet", {
      facility_ids: null,
    })
  }

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)

    const filteredFacilities =
      value == 0
        ? OTHER_CHILD_OPTIONS
        : OTHER_CHILD_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )

    setChildName(value)
    setOtherFacilities(filteredFacilities)
  }

  useEffect(() => {
    setChildName(0)
    setOtherFacilities(OTHER_CHILD_OPTIONS)
  }, [isLoading])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Keyword")} </Text>
        </TitleWrap>
        <TextField
          name={"company_name"}
          height={"50px"}
          placeholder={"受給者証番号・上限管理事業者・施設名（ふりがなも可）"}
          bgcolor={"#fff"}
          className={"text-field"}
          width={"446px"}
          value={keyword}
          onChange={(evt) => setKeyword(evt.target.value)}
        />
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Child name")} </Text>
        </TitleWrap>
        <SelectWrap>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            placeholder={"---"}
            className={"select-down"}
            value={childName}
            onChange={(values) => {
              handleSort(values)
              setChildId(null)
            }}
          />
          {!isLoading && (
            <SelectInput
              width={300}
              height={40}
              className={"select-class"}
              options={otherFacilities}
              value={childId}
              onChange={(values) => setChildId(values)}
              placeholder={t("All")}
              clear
            />
          )}
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper
        isNoBottomMargin={CHILD_FACILITY_OPTION?.length <= 0 ? true : false}
      >
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("facility name")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            className={"wrapper_btn"}
            text={t("Check all")}
            onClick={() => {
              const facilityValuesIds = allFacility.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button wrapper_btn"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds("")
              setCheck(null)
            }}
          />
        </div>
      </SearchWrapper>

      <SearchWrapper>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={check}
            onChange={(values) => {
              const facilityValuesIds = values.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(values)
            }}
            options={CHILD_FACILITY_OPTION}
          />
        </CheckboxWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Use service")} </Text>
        </TitleWrap>
        <Checkboxes
          key={"footerInformation"}
          name={"footerInformation"}
          value={useService}
          onChange={(values) => {
            setUseService(values)
          }}
          options={CHILD_SERVICE_OPTION}
        />
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={displayAlternativeStatement}
            label={t(
              "Children who do not have enough daily digital signatures"
            )}
            className={"check-box"}
            onChange={(evt) => {
              setDisplayAlternativeStatement(evt.target.checked)
              setDisplayDigitalSignature(!evt.target.checked)
            }}
          />
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={displayDigitalSignature}
            label={t("No digital signature at the bottom of the record sheet")}
            onChange={(evt) => {
              setDisplayDigitalSignature(evt.target.checked)
              setDisplayAlternativeStatement(!evt.target.checked)
            }}
            className={"check-box"}
          />
        </SelectWrap>
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Year month")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <DatePicker
            date={
              moment(`${year}-${month}`).isValid()
                ? moment(`${year}-${month}`)
                : moment()
            }
            style={{
              height: "40px",
            }}
            picker={"month"}
            format={"YYYY年MM月"}
            showArrow={true}
            disabledDate={(current) => {
              return (
                (current && current < moment("2021/01/01")) ||
                (current && current > moment().add(6, "year"))
              )
            }}
            onDateChange={(value) => {
              setYear(moment(value).format("YYYY"))
              setMonth(moment(value).format("MM"))
            }}
          />
        </DateSelectWrap>
      </SearchWrapper>
      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ServiceRecordSheetOperationComponent }
