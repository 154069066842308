// types
import { FormikProps } from "formik"

export enum FormType {
  FIRST,
  SECOND,
}

export type FacilityStatusFieldSchemaProps = {
  checkServiceType: number
  service: number
  handleCapacityChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    name: any
  ) => void
  formik: FormikProps<{
    [key: string]: any
  }>
  type?: FormType
  showInAfterFirstForm?: boolean
  getErrorClass: (
    field: any,
    type?: string
  ) =>
    | ""
    | "has-error"
    | "has-error-picker"
    | "has-error-container"
    | "has-error-container-nest"
}

export type FacilityTypeFormProps = {
  formik: FormikProps<{
    [key: string]: any
  }>
  date: {
    year: string | number
    month: string | number
  }
  setDate: any
}
