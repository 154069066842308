// package
import wareki from "wareki"
import moment from "moment"
import { t } from "i18next"

export const mapper = (
  data: {
    [key: string]: any
  }[],
  service_type: number,
  is_multi_type: boolean
) => {
  const newData = data.map((item) => {
    const newMonthlyData = {}
    item.totalNumberOfUsers = item?.totalNumberOfUsers
      ? item?.totalNumberOfUsers
      : [
          is_multi_type || service_type === 2 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          "0",
        ]
    item.numberOfDaysTheFacilityIsOpen = item?.numberOfDaysTheFacilityIsOpen
      ? item?.numberOfDaysTheFacilityIsOpen
      : [
          is_multi_type || service_type === 2 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          "0",
        ]

    for (let i = 1; i <= item.totalDays; i++) {
      const day = `${i}日`
      if (item[day]) {
        newMonthlyData[day] = item[day]
        continue
      } else {
        newMonthlyData[day] = [
          is_multi_type || service_type === 2 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          is_multi_type || service_type === 1 ? "0" : "-",
          "0",
        ]
      }
    }
    return {
      ...newMonthlyData,
      ...item,
    }
  })
  return newData
}

export const UsageStatusAPIResponseMapper = (
  year: string | number,
  data: {
    [key: string]: any
  }
) => {
  const nextYear = Number(year) + 1
  const newData = {
    facility_id: data?.facility_id,
    facility_name: data?.facility_name,
    facility_name_short: data?.facility_name_short,
    facility_name_furigana: data?.facility_name_short_furigana,
    service_type: data?.service_type,
    is_multi_type: data?.is_multi_type,
    table_data: [],
  }

  const totalNumberOfUsersCount = {
    totalChildDevelopmentSupportUsers: 0,
    totalAfterSchoolServiceUsers: 0,
    TotalUserOnSchoolHolidays: 0,
    totalUsers: 0,
  }

  const monthArray = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] // Month Array starting from April to March

  // Month loop
  monthArray.forEach((i) => {
    const currentYear = i < 4 ? nextYear : year
    let tableDataObj = {}

    const month = i.toString().padStart(2, "0")
    const getTotalDaysOfTheMonth = moment(
      `${currentYear}-${month}`
    ).daysInMonth()
    const era = formatJaEra(
      wareki(`${currentYear}-${month?.toString()?.padStart(2, "0")}`, {
        unit: true,
      })
    )

    // padStart is used to add 0 in front of single digit number
    tableDataObj = {
      key: moment(`${currentYear}-${month}`).format(`${era}MM月`),
      actualDate: moment(`${currentYear}-${month}`).format(`${era}MM月`),
      totalDays: getTotalDaysOfTheMonth,
      totalNumberOfUsers: [
        data?.is_multi_type || data?.service_type === 2 ? "0" : "-",
        data?.is_multi_type || data?.service_type === 1 ? "0" : "-",
        data?.is_multi_type || data?.service_type === 1 ? "0" : "-",
        "0",
      ],
      numberOfDaysTheFacilityIsOpen: [
        data?.is_multi_type || data?.service_type === 2 ? "0" : "-",
        data?.is_multi_type || data?.service_type === 1 ? "0" : "-",
        data?.is_multi_type || data?.service_type === 1 ? "0" : "-",
        "0",
      ],
    }

    const dailyUseStatusOfAMonth = data?.monthly_use_status?.filter((el) => {
      //Adding One because moment get month function uses 0 based indexing i.e 0-11
      return Number(moment(el?.date).month()) + 1 === i
    })

    // Get Data of Each day of a month and add it to tableDataObj
    dailyUseStatusOfAMonth?.forEach((el) => {
      tableDataObj[`${Number(moment(el?.date).date())}日`] = [
        data?.is_multi_type || data?.service_type === 2
          ? el?.total_child_development_support_users || 0
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.total_after_school_service_users || 0
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.number_of_users_on_school_holidays || 0
          : "-",
        el?.total_users,
      ]
    })

    // Get Other Info of a month i.e extended users and facility open days
    const otherInfoOfAMonth = data?.monthly_use_status_other_info?.filter(
      (el) => {
        return Number(el?.month) == i
      }
    )

    // Get other info of Each month and add it to tableDataObj and total count
    otherInfoOfAMonth?.forEach((el) => {
      // Data comes in JSON array from API

      totalNumberOfUsersCount.totalChildDevelopmentSupportUsers +=
        el?.total_child_development_support_users || 0

      totalNumberOfUsersCount.totalAfterSchoolServiceUsers +=
        el?.total_after_school_service_users || 0

      totalNumberOfUsersCount.TotalUserOnSchoolHolidays +=
        el?.total_user_on_school_holidays || 0

      totalNumberOfUsersCount.totalUsers += el?.total_users || 0
      // Adding to the main obj
      tableDataObj[`totalNumberOfUsers`] = [
        data?.is_multi_type || data?.service_type === 2
          ? el?.total_child_development_support_users ?? "-"
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.total_after_school_service_users ?? "-"
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.total_user_on_school_holidays ?? "-"
          : "-",
        el?.total_users ?? "-",
      ]
      tableDataObj[`numberOfDaysTheFacilityIsOpen`] = [
        data?.is_multi_type || data?.service_type === 2
          ? el?.no_of_facility_open_days
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.no_of_facility_open_days
          : "-",
        data?.is_multi_type || data?.service_type === 1
          ? el?.no_of_facility_open_days
          : "-",
        el?.no_of_facility_open_days,
      ]
    })

    newData.table_data.push(tableDataObj)
  })

  // Finally, push the total data at the end of the arry.
  const finalObj = {
    key: t("Total"),
    actualDate: t("Total"),
    totalDays: 32,
    totalNumberOfUsers: [
      data?.is_multi_type || data?.service_type === 2
        ? totalNumberOfUsersCount.totalChildDevelopmentSupportUsers
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? totalNumberOfUsersCount.totalAfterSchoolServiceUsers
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? totalNumberOfUsersCount.TotalUserOnSchoolHolidays
        : "-",
      totalNumberOfUsersCount.totalUsers,
    ],
    numberOfDaysTheFacilityIsOpen: [
      data?.is_multi_type || data?.service_type === 2
        ? data?.total_facility_open
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? data?.total_facility_open
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? data?.total_facility_open
        : "-",
      data?.total_facility_open,
    ],
  }

  data?.total?.forEach((el) => {
    // key name is "date" but we get day from API in this key
    finalObj[`${Number(el?.date)}日`] = [
      data?.is_multi_type || data?.service_type === 2
        ? el?.total_child_development_support_users
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? el?.total_after_school_service_users
        : "-",
      data?.is_multi_type || data?.service_type === 1
        ? el?.number_of_users_on_school_holidays
        : "-",
      el?.total_users,
    ]
  })

  newData.table_data.push(finalObj)
  return newData
}

/**
 * @param inputString (e.g. 令 和 04 年 04 月)
 * @returns (e.g.
 * 令
 * 和
 * 04
 * 年,
 * 04
 * 月)
 */
export const formatDateString = (inputString) => {
  const chars = inputString.split("")
  const formattedArray = []
  let tempDigits = ""

  chars.forEach((char) => {
    if (/\d/.test(char)) {
      tempDigits += char
    } else {
      if (tempDigits) {
        formattedArray.push(
          <span
            className={"individual-date-letter"}
            key={formattedArray.length}
          >
            {tempDigits}
          </span>
        )
        tempDigits = ""
      }
      formattedArray.push(
        <span className={"individual-date-letter"} key={formattedArray.length}>
          {char}
        </span>
      )
    }
  })

  if (tempDigits) {
    formattedArray.push(
      <span className={"individual-date-letter"} key={formattedArray.length}>
        {tempDigits}
      </span>
    )
  }

  return formattedArray
}

export const formatJaEra = (era: string) => {
  return era?.replace(/\b(\d)\b/g, "0$1")
}
