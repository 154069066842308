import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getPaddedMonth } from "../../../utils"

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr 2fr 2fr;
  border: 1px solid black;
  grid-gap: 1px;
  background-color: black;
  margin-top: 24px;
  .cell {
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
`
export const TopInfo = ({ date }: { date: Date }) => {
  const { t } = useTranslation()

  return (
    <Container className={"top__info"}>
      <div className={"cell"}>
        {t("{{year}}年{{month}}月’s schedule table", {
          year: date.getFullYear(),
          month: getPaddedMonth(date.getMonth()),
        })}
      </div>
      <div className={"cell"}>{t("Child name")}</div>
      <div className={"cell"}>{""}</div>
      <div className={"cell"}>{t("Date entered")}</div>
      <div className={"cell"}>{"/"}</div>
    </Container>
  )
}
