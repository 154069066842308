import React, { useEffect } from "react"
import { Skeleton, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { Wrapper } from "./TransportationsList.style"
import { OwnerButton } from "../../atoms"
import { useRouter } from "next/router"
import { useTransportBulkEditContext } from "../../../context/TransportBulkEditContext"
import { TransportManagementTable } from "./TransportManagementTable"
import { getDateStringWithDay } from "../../../utils"
import moment from "moment"
export const TransportationsList = ({ paramsObj }: { paramsObj: any }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data,
    isLoading,
    isUpdating,
    updatedDisplay,
    pickRowSelection,
    dropRowSelection,
    handleRowSelection,
    handleDataSubmission,
    pickUpDriverRowSelection,
    dropOffDriverRowSelection,
    handlePickOrDropDriverTableRowSel,
    setUrlParams,
  } = useTransportBulkEditContext()

  useEffect(() => {
    setUrlParams(paramsObj)
  }, [])

  return (
    <Wrapper>
      <Spin spinning={isLoading}>
        {updatedDisplay?.list_display?.includes("1") ? (
          <div className={"list__card"}>
            <div className={"title"}>
              {t("{{year}} ({{day}})’s user transportation management (Pick)", {
                year: getDateStringWithDay(updatedDisplay?.date),
                day: t(`${moment(updatedDisplay?.date).format("ddd")}`),
              })}
            </div>
            {isLoading ? (
              <>
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                {updatedDisplay?.list_display?.includes("1") && (
                  <TransportManagementTable
                    rowSelection={{
                      selectedRowKeys: pickRowSelection,
                      onChange: (newSelectedRowKeys: React.Key[]) =>
                        handleRowSelection(newSelectedRowKeys, "pick_up"),
                    }}
                    pickupRowSelection={{
                      selectedRowKeys: pickUpDriverRowSelection,
                      onChange: (newSelectedRowKeys: React.Key[]) =>
                        handlePickOrDropDriverTableRowSel(
                          newSelectedRowKeys,
                          "pick_up"
                        ),
                    }}
                    data={data?.pick_up}
                    otherInfo={data?.pickUpOtherInfo}
                    type={"PICKUP"}
                  />
                )}
              </>
            )}
          </div>
        ) : (
          ""
        )}

        <br />

        {updatedDisplay?.list_display?.includes("2") ? (
          <>
            <div className={"list__card"}>
              <div className={"title"}>
                {t(
                  "{{year}}  ({{day}})’s user transportation management (Drop)",
                  {
                    year: getDateStringWithDay(updatedDisplay?.date),
                    day: t(`${moment(updatedDisplay?.date).format("ddd")}`),
                  }
                )}
              </div>
              <TransportManagementTable
                rowSelection={{
                  selectedRowKeys: dropRowSelection,
                  onChange: (newSelectedRowKeys: React.Key[]) =>
                    handleRowSelection(newSelectedRowKeys, "drop_off"),
                }}
                data={data?.drop_off}
                otherInfo={data?.dropOffOtherInfo}
                type={"DROPOFF"}
                dropOffRowSelection={{
                  selectedRowKeys: dropOffDriverRowSelection,
                  onChange: (newSelectedRowKeys: React.Key[]) =>
                    handlePickOrDropDriverTableRowSel(
                      newSelectedRowKeys,
                      "drop_off"
                    ),
                }}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div className={"btn__cta"}>
          <OwnerButton
            text={t("Cancel")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              router.back()
            }}
            disabled={isUpdating}
          />
          <OwnerButton
            text={t("Save")}
            shape={"circle"}
            isLoading={isUpdating}
            onClick={handleDataSubmission}
          />
        </div>
      </Spin>
    </Wrapper>
  )
}
