import styled from "styled-components"
import { OwnerTable } from ".."
import { theme } from "@project/shared"
import { Skeleton } from "antd"

const Wrapper = styled.div`
  width: 100%;
  .title_info {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .ant-table-content {
    min-width: 100%;
    .ant-table-thead {
      .ant-table-cell {
        background: ${theme.gray2};
        &:before {
          background-color: #000000 !important;
        }
        &:first-child {
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }
    .ant-table-tbody {
      td {
        &:first-child {
          border-left: 1px solid ${theme.gray2};
        }
        border-right: 1px solid ${theme.gray2};
        border-bottom: 1px solid ${theme.gray2};
      }
      .checked-row {
        background: ${theme.yellow4};
      }
    }
  }
`

interface IRowSelectableTable {
  data: Array<any>
  columns: any[]
  handleCheck?: (val) => void
  info?: any
  loading?: boolean
}

export const RowSelectableTable = ({
  data,
  columns,
  info,
  loading,
}: IRowSelectableTable) => {
  return (
    <Wrapper>
      <div className={"title_info"}>
        {loading ? (
          <Skeleton.Input block active />
        ) : (
          <>
            {info.provision && info.city_number
              ? `${info.provision}（${info.city_number})`
              : null}
          </>
        )}
      </div>
      <OwnerTable
        columns={columns}
        dataSource={data}
        rowClassName={(row) => (row?.checked ? "checked-row" : "")}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </Wrapper>
  )
}
