import { API } from "@project/shared"

export const postServiceContentCareLog = async (values: any) => {
  return API.post(`/child-service-content-care-log`, values)
}

export const fetchChildServiceContentCareLog = async (
  id: string,
  date: string
): Promise<{ data: any }> => {
  return API.get(`/child-service-provision-result/${id}/${date}`)
}

export const getChildServiceContentCareLogList = async ({
  page,
  pageSize,
  staffId,
  childId,
  keyword,
  facilities,
  usedServices,
  displaySignature,
  createdStatus,
  currentDay,
}: {
  page: number
  pageSize: number
  staffId?: string
  childId?: string
  keyword?: string
  facilities?: any
  usedServices?: any
  displaySignature?: any
  createdStatus?: string
  currentDay?: string
}): Promise<{
  data
  count
}> => {
  let url = `/child-service-content-care-log?year=${currentDay}&page=${page}&pageSize=${pageSize}`
  if (staffId) url = `${url}&staff_id=${staffId}`
  if (childId) url = `${url}&child_id=${childId}`
  if (keyword) url = `${url}&keyword=${keyword}`
  if (facilities) url = `${url}&facilities=${facilities}`
  if (usedServices) url = `${url}&used_services=${usedServices}`
  if (displaySignature) url = `${url}&display_signature=${displaySignature}`
  if (createdStatus) url = `${url}&created_status=${createdStatus}`

  return API.get(url)
}

export const getAllUserServiceProvisionResult = async ({
  staffId,
  childId,
  keyword,
  facilities,
  createdStatus,
  usedServices,
  displaySignature,
  year,
  page,
  pageSize,
  date_from,
  date_to,
}: any): Promise<{
  data: any
  count: number
}> => {
  const filteredFacilities =
    facilities?.filter(function (val: number) {
      return +val !== 0
    }) || null

  const queries = []
  if (staffId) queries.push(`staff_id=${staffId}`)
  if (childId) queries.push(`child_id=${childId}`)
  if (keyword) queries.push(`keyword=${keyword}`)
  if (pageSize) queries.push(`pageSize=${pageSize || "20"}`)
  if (filteredFacilities) queries.push(`facilities=${filteredFacilities}`)
  if (createdStatus && createdStatus != "0")
    queries.push(`created_status=${createdStatus}`)
  if (usedServices) queries.push(`used_services=${usedServices}`)
  if (displaySignature) queries.push(`display_signature=${displaySignature}`)
  if (year) queries.push(`year=${year}`)
  if (date_from) queries.push(`date_from=${date_from}`)
  if (date_to) queries.push(`date_to=${date_to}`)
  return API.get(
    `/child-service-provision-result?page=${page}&${queries.join("&")}`
  )
}

export const deleteChildServiceProvisionResultLog = async (id: any) => {
  return API.delete(`/child-service-content-care-log/${id}`)
}

export const getUserServiceProvisionPrint = async (
  query
): Promise<{ data: any }> => {
  return API.get(`/child-service-provision-result/print${query}`)
}

//api for signature update on print
export const updateUserServiceProvisionPrintSignature = ({
  values,
}: {
  values: {
    id: number
    digital_signature: any
  }
}) => {
  const { id, digital_signature } = values
  return API.put(`/child-service-provision-result/print/${id}`, {
    digital_signature: digital_signature,
  })
}
