import { API } from "@project/shared"

// AdditionSettingChildDay
interface IIntensiveSupportAddition {
  id: number
  facility_id: number
  child_id: number
  date: string
  addition_data: number
  addition_type: number
}

interface IReturnServiceProvisionSheetPrint {
  count?: number
  data?: any
  calculations?: any
  child_info?: any
  next_child?: any
  previous_child?: any
}

interface IReturnServiceProvisionSheet {
  count?: number
  data?: any
  calculations?: any
  child_info?: any
  next_child?: any
  previous_child?: any
  intensive_support_addition: IIntensiveSupportAddition | null
}

interface IServiceProvisionSheetDetailParams {
  year?: string
  month?: string
  content_of_remarks?: string
  addition_items?: string
  show_digital_signature?: string
  show_non_digital_signature?: string
  showAbsence?: string
  showUnusedFacility?: string
  child_ids?: any
  dont_include_zero_cost?: string
}

export interface IReturnServiceProvisionPrintSheet {
  count?: number
  data?: any
  calculations?: any
  child_info?: any
  next_child?: any
  previous_child?: any
}

interface IServiceProvisionSheetParam {
  page?: string
  pageSize?: string
  year: string
  month: string
  facility_ids: string
  show_digital_signature: string
  examContent: string[]
  child_ids: any
  service_type_ids: any
  keyword: string
  show_non_digital_signature: string
  certificate_applicable_year_month?: string
  showAbsence?: string
}

export const fetchServiceProvisionSheetPrint = async ({
  queryKey,
}): Promise<IReturnServiceProvisionSheetPrint> => {
  const params = {} as IServiceProvisionSheetParam
  params.page = queryKey[2] || 1
  params.pageSize = queryKey[3] || "20"
  params.year = queryKey[1].year
  params.month = queryKey[1].month
  if (
    queryKey[1].certificate_applicable_year &&
    queryKey[1].certificate_applicable_month
  ) {
    params.year = queryKey[1].certificate_applicable_year
    params.month = queryKey[1].certificate_applicable_month
  }

  params.facility_ids = Array.isArray(queryKey[1].facility_ids)
    ? queryKey[1].facility_ids?.join(",")
    : queryKey[1].facility_ids
  params.show_digital_signature = queryKey[1].show_digital_signature
  params.show_non_digital_signature = queryKey[1].show_non_digital_signature
  params.keyword = queryKey[1].keyword
  params.child_ids = queryKey[1].child_ids
  params.service_type_ids = Array.isArray(queryKey[1].service_type_ids)
    ? queryKey[1].service_type_ids?.join(",")
    : queryKey[1].service_type_ids

  // if first attendance record is absent then backend doesn't return data unless following param is passed
  params.showAbsence = "1"
  return API.get(`/service-provision-record/list`, { params })
}

export const fetchServiceProvisionBulkPrint = async ({
  queryKey,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetDetailParams
  const id = queryKey[2]
  const query = queryKey[1]
  const { year, month } = query
  params.content_of_remarks = queryKey[1].content_of_remarks?.join(",")
  params.addition_items = queryKey[1].addition_items?.join(",")
  params.show_digital_signature = queryKey[1].show_digital_signature?.join(",")
  params.showAbsence = queryKey[1].showAbsence
  params.showUnusedFacility = queryKey[1].showUnusedFacility
  params.child_ids = queryKey[1].child_ids?.join(",")
  if (queryKey[1].remark_actual_expense == "1")
    params.dont_include_zero_cost = "1"
  return API.get(
    `/service-provision-record/bulk-sheet/${year}/${month}?child_ids=${id}`,
    {
      params,
    }
  )
}

export const fetchChildData = async ({
  queryKey,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetDetailParams
  const id = queryKey[2]
  const query = queryKey[1]
  const { year, month } = query
  return API.get(
    `/child-service-content-results/attendance-data?year=${year}&month=${month}&child_ids=${id}`,
    { params }
  )
}
