import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Skeleton, Space, Typography } from "antd"
import { useRouter } from "next/router"
import { FormOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import {
  ACHIEVEMENT_GOAL_TYPE,
  Checkboxes,
  EVALUATION_TYPES,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES,
  Modal,
  SERVICES,
  SignatureCanvas,
  Table,
  isApril2024ChangeApplicable,
} from "@project/shared"
import { OwnerButton, StyledFormWrapper } from "../../../components"
import { Divisor, scrollToElement } from "../../../utils"
import {
  CONSENT_DATE,
  DISPLAY_CONTENT,
  PARENT_FIELD,
} from "../../../constants/monitoring"
import {
  CheckboxWrap,
  FormWrapper,
  Header,
  OperationsWrapper,
  SearchWrapper,
  Text,
  TitleWrap,
  Wrapper,
} from "./MonitoringDetail.styles"
import { updateSignatureOfMonitoringDetail } from "../../../services/monitoring"
import { toastMsg } from "../../../utils/toastMsg"
import { useReactToPrint } from "react-to-print"

interface IMonitoringFormProps {
  isLoading?: boolean
  data?: any
  handleSave?: (a: any) => void
  saveType?: string | undefined
  isEdit?: boolean
}

const MonitoringDetailFormIndividual = ({
  isLoading,
  data = undefined,
}: IMonitoringFormProps) => {
  const { i18n, t } = useTranslation()
  const router = useRouter()
  const bottomElmRef = useRef<HTMLDivElement>(null)
  const printRefEl = useRef<HTMLDivElement>(null)
  const childName = data?.child_info?.child_name || ""
  const [tempContent, setTempContent] = useState<any>([
    "consent_date",
    "parent_sign",
  ])
  const [content, setContent] = useState<any>(["consent_date", "parent_sign"])
  const [tempConsentDate, setTempConsentDate] = useState<any>([])
  const [consentDate, setConsentDate] = useState<any>([])
  const [temParentOptions, setTempParentOptions] = useState([])
  const [parentOptions, setParentOptions] = useState([])
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const [signOf, setSignOf] = useState("")
  const [sign, setSign] = useState("")
  const [signType, setSignType] = useState<"onlyDate" | "name">("name")
  const [signatures, setSignatures] = useState({
    digital_signature_image: data?.digital_signature_image || "", //parent signature
    child_digital_signature_image: data?.child_digital_signature_image || "",
    manager_digital_signature_image:
      data?.manager_digital_signature_image || "",
    explanation_date_digital_signature_image:
      data?.explanation_date_digital_signature_image || "",
    consent_date_digital_signature_image:
      data?.consent_date_digital_signature_image || "",
  })

  const isAprilChangeApplicable = useMemo(() => {
    return (
      data?.report_create_date &&
      moment(data?.report_create_date).isValid() &&
      isApril2024ChangeApplicable(moment(data?.report_create_date))
    )
  }, [data?.report_create_date])

  useEffect(() => {
    setSignatures({
      digital_signature_image:
        signatures.digital_signature_image ||
        data?.digital_signature_image ||
        "", //parent signature
      child_digital_signature_image:
        signatures.child_digital_signature_image ||
        data?.child_digital_signature_image ||
        "",
      manager_digital_signature_image:
        signatures.manager_digital_signature_image ||
        data?.manager_digital_signature_image ||
        "",
      explanation_date_digital_signature_image:
        signatures.explanation_date_digital_signature_image ||
        data?.explanation_date_digital_signature_image ||
        "",
      consent_date_digital_signature_image:
        signatures.consent_date_digital_signature_image ||
        data?.consent_date_digital_signature_image ||
        "",
    })
  }, [data])

  const { isLoading: isUpdating, mutate } = useMutation(
    updateSignatureOfMonitoringDetail
  )

  const onDisplayChange = () => {
    setContent(tempContent)
    setConsentDate(tempConsentDate)
    setParentOptions(temParentOptions)
    scrollToElement(bottomElmRef, "start")
  }

  const saveSignatures = (key: string, val: string) => {
    mutate(
      {
        ...signatures,
        [key]: val,
        id: data?.id || 0,
      },
      {
        onSuccess: () => {
          setOpenSignatureModal(false)
          setSignatures({
            ...signatures,
            [key]: val,
          })
          toastMsg("success", t("Updated Successfully"))
        },
        onError: () => {
          toastMsg(
            "error",
            t("Something went wrong. Please contact administrator.")
          )
        },
      }
    )
  }

  const COLUMN_DEFINATION: any = useMemo(() => {
    const cols = [
      {
        title: "",
        dataIndex: "sn",
        onCell: (row) => {
          return {
            rowSpan: row?.titleRowSpan,
          }
        },
      },
      {
        title: t("Items (personal needs,etc.)"),
        align: "center",
        render: (row) => {
          return <p>{row?.title || ""}</p>
        },
        className: "items_personal",
        onCell: (row) => {
          return {
            rowSpan: row?.titleRowSpan,
          }
        },
      },
      {
        title: t("specific goals"),
        align: "center",
        dataIndex: "achievement_goals",
        className: "specific_goals",
        render: (row: string) => <div style={{ textAlign: "left" }}>{row}</div>,
      },
      {
        title: t("Guideline items"),
        align: "left",
        dataIndex: "support_guidelines",
        className: "support_guidelines",
        isAprilChange: true,
        render: (data) =>
          Array.isArray(data) &&
          data?.map((guide) => (
            <>
              {guide?.value
                ? INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS?.find(
                    (opt) => opt.value == guide?.value
                  )?.label
                : ""}
              {guide?.value ==
                INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.OTHERS &&
                ` ${guide.title}`}
              <br />
            </>
          )),
      },
      {
        title: (
          <>
            {t("Service provider organization")}
            <br />
            {t("(provider, person in charge, etc.)")}
          </>
        ),
        align: "left",
        dataIndex: "providing_organization",
        className: "providing_organization",
        isAprilChange: true,
      },
      {
        title: t("Goal achievement"),
        align: "center",
        render: (row) => {
          return (
            <p>
              {
                ACHIEVEMENT_GOAL_TYPE?.find(
                  (val) => val?.value === row?.achievement_goal_type
                )?.label
              }
              &nbsp;
              {row?.achievement_goal_type == 4 && row?.achievement_goal_text}
            </p>
          )
        },
        className: "goal_achievement",
      },
      {
        title: t("Evaluation"),
        align: "center",
        render: (row) => {
          return (
            <p>
              {
                EVALUATION_TYPES?.find(
                  (val) => val?.value === row?.evaluation_type
                )?.label
              }
              &nbsp;
              {row?.evaluation_type == 4 && row?.evaluation_type_text}
            </p>
          )
        },
        className: "common_head",
      },
      {
        title: t("Consideration"),
        align: "center",
        dataIndex: "study",
        className: "more-width-common-head",
        render: (row: string) => <div style={{ textAlign: "left" }}>{row}</div>,
      },
      {
        title: t("Priority"),
        align: "center",
        dataIndex: "priority",
        className: "common_head",
        isAprilChange: true,
      },
    ]
    return isAprilChangeApplicable
      ? cols
      : cols?.filter((c) => !c?.isAprilChange)
  }, [data])
  const [isValidUrl, setIsValidUrl] = useState(true)
  const renderSignature = (url) => (
    <>
      {url && isValidUrl ? (
        <img
          src={url}
          alt={"signature"}
          className={"sign-img"}
          onError={() => setIsValidUrl(false)}
        />
      ) : (
        ""
      )}
    </>
  )

  const onPrint = useReactToPrint({
    content: () => printRefEl.current,
  })

  const monitoringItemData = useMemo(() => {
    const items = []
    data?.child_monitoring_item?.forEach((item, i) => {
      item?.child_monitoring_content?.achievement_goals?.forEach(
        (goal, index) => {
          const goalType =
            item?.child_monitoring_content?.achievement_goal_type?.[index]
          let goalText = ""
          if (goalType == 4) {
            goalText =
              item?.child_monitoring_content?.achievement_goal_text?.[index]
          }

          const evalType =
            item?.child_monitoring_content?.evaluation_type?.[index]
          let evalText = ""
          if (evalType == 4) {
            evalText =
              item?.child_monitoring_content?.evaluation_type_text?.[index]
          }

          const row: any = {
            ...item,
            titleRowSpan:
              index == 0
                ? item?.child_monitoring_content?.achievement_goals?.length
                : 0,
            sn: i + 1,
            achievement_goals: goal,
            achievement_goal_type: goalType,
            evaluation_type: evalType,
            study: item?.child_monitoring_content?.study?.[index],
            support_guidelines:
              item?.child_monitoring_content?.support_guidelines?.[index],
            providing_organization:
              item?.child_monitoring_content?.providing_organization?.[index],
            priority: item?.child_monitoring_content?.priority?.[index],
            achievement_goal_text: goalText,
            evaluation_type_text: evalText,
          }
          items.push(row)
        }
      )
    })
    return items
  }, [data?.child_monitoring_item])

  const okToRenderGoals = () =>
    data?.child_support_plan?.long_term_goal ||
    data?.long_term_goal_study ||
    data?.child_support_plan?.short_term_goal ||
    data?.short_term_goal_study
      ? true
      : false

  const okToRenderHopes = () =>
    data?.child_hope || data?.family_hope || data?.concern_hope ? true : false

  const DataSheetList = () => (
    <Wrapper ref={printRefEl} className={"monitoring-detail-container"}>
      <div className={"card__top-content"}>
        <Header className={"card__header_title"}>
          {`${t("{{childName}}’s monitoring table", { childName })}`}
        </Header>
        <Divisor />

        <FormWrapper className={"top-buttons-wrapper"} mt>
          <div className={"card_top"}>
            <div className={"top-btns"}>
              <div className={"flex-row"}>
                <OwnerButton
                  text={t("<< Back")}
                  typeOf={"draft"}
                  shape={"circle"}
                  onClick={() => router.back()}
                />
                {typeof window !== "undefined" && (
                  <OwnerButton
                    text={t("Print (PDF Output)")}
                    icon={"printBlack"}
                    typeOf={"draft"}
                    shape={"circle"}
                    disabled={isLoading}
                    onClick={() => onPrint()}
                  />
                )}
              </div>
            </div>

            <div className={"top-info-wrapper"}>
              <span className={"info-class for-screen"}>
                {t("Facility Name")}
                {" : "}
                <strong style={{ wordBreak: "break-all" }}>
                  {data?.facility?.facility_name || ""}
                </strong>
              </span>
              <span className={"info-class for-print"}>
                {t("Facility Name")}
                {" : "}
                <strong style={{ wordBreak: "break-all" }}>
                  {data?.facility?.facility_name || ""}
                </strong>
              </span>
              <span className={"info-class"}>
                {t("Service used")} {" : "}
                <strong>
                  {
                    SERVICES?.find(
                      (s) => parseInt(s?.value, 10) === data?.service_type
                    )?.label
                  }
                </strong>
              </span>
              <span className={"info-class"}>
                {t("Creation date")}
                {" : "}{" "}
                <strong>
                  {moment(data?.report_create_date).isValid() &&
                    moment(data?.report_create_date).format("YYYY年MM月DD日")}
                </strong>
              </span>
            </div>
          </div>
        </FormWrapper>
      </div>
      <FormWrapper mt>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <StyledFormWrapper
              className={"form-field-wrapper increase-specificity-wrapper"}
              label={t("Created no. times")}
            >
              <p>
                {data?.create_count || 0}
                {t("回目")}
              </p>
            </StyledFormWrapper>
            <StyledFormWrapper
              className={
                "last-wrapper form-field-wrapper increase-specificity-wrapper"
              }
              label={t("Recorder")}
              isHalfBorder
            >
              <p>{data?.staff?.staff_name}</p>
            </StyledFormWrapper>
          </>
        )}
      </FormWrapper>

      {monitoringItemData?.length > 0 && (
        <FormWrapper className={"item-table-wrapper"} mt>
          <div className={"item-content-table"}>
            <Table
              columns={COLUMN_DEFINATION}
              dataSource={monitoringItemData}
              pagination={false}
              loading={isLoading}
            />
          </div>
        </FormWrapper>
      )}

      {okToRenderGoals() && (
        <FormWrapper mt>
          <StyledFormWrapper
            className={"form-field-wrapper increase-specificity-wrapper"}
            label={t("Long term goal")}
          >
            <p>{data?.child_support_plan?.long_term_goal}</p>
          </StyledFormWrapper>
          <StyledFormWrapper
            className={"form-field-wrapper increase-specificity-wrapper"}
            label={t("Consideration for long-term goals")}
          >
            <p>{data?.long_term_goal_study || ""}</p>
          </StyledFormWrapper>
          <StyledFormWrapper
            className={"form-field-wrapper increase-specificity-wrapper"}
            label={t("short term goal")}
          >
            <p> {data?.child_support_plan?.short_term_goal}</p>
          </StyledFormWrapper>
          <StyledFormWrapper
            className={
              "last-wrapper form-field-wrapper increase-specificity-wrapper"
            }
            label={t("Consideration for short term goal")}
          >
            <p>{data?.short_term_goal_study || ""}</p>
          </StyledFormWrapper>
        </FormWrapper>
      )}

      {okToRenderHopes() && (
        <FormWrapper mt className={"second-form-wrapper"}>
          <StyledFormWrapper
            className={"form-field-wrapper  increase-specificity-wrapper "}
            label={t("Hope of the person")}
          >
            <p>{data?.child_hope || ""}</p>
          </StyledFormWrapper>
          <StyledFormWrapper
            className={"form-field-wrapper increase-specificity-wrapper"}
            label={t("Hope of the family")}
            isBorder
          >
            <p>{data?.family_hope || ""}</p>
          </StyledFormWrapper>
          <StyledFormWrapper
            className={
              "last-wrapper form-field-wrapper increase-specificity-wrapper"
            }
            label={t("Hope of the relatives")}
          >
            <p>{data?.concern_hope || ""}</p>
          </StyledFormWrapper>
        </FormWrapper>
      )}

      <FormWrapper mt>
        <div className={"flex-wrapper"}>
          <div className={"flex-3 consent-section-row"}>
            {" "}
            {content?.includes("description") ? (
              <div>
                {`${t(
                  "I have received an explanation of the above monitoring table and agree to its contents."
                )}`}
              </div>
            ) : (
              ""
            )}
            {!consentDate?.includes("separate_date") &&
            !consentDate?.includes("digital_signature") &&
            content?.includes("consent_date") ? (
              <div className={"consent-section"}>
                <div>{`${t("Date of Consent to the explanation")}`}</div>
                <div>{"令和"}</div>
                <div>{"年"}</div> <div>{"月"}</div> <div>{"日"}</div>
              </div>
            ) : (
              ""
            )}
            {consentDate?.includes("digital_signature") ? (
              <>
                {consentDate?.includes("separate_date") && (
                  <div className={"sign-flex"}>
                    <Typography.Text>{t("Explanation date")}</Typography.Text>
                    {renderSignature(
                      signatures?.explanation_date_digital_signature_image
                    )}
                    <FormOutlined
                      onClick={() => {
                        setSign(
                          signatures?.explanation_date_digital_signature_image
                        )
                        setSignType("onlyDate")
                        setOpenSignatureModal(true)
                        setSignOf("explanation_date_digital_signature_image")
                      }}
                    />
                  </div>
                )}
                <div className={"sign-flex"}>
                  <Typography.Text>{t("Consent date")}</Typography.Text>
                  {renderSignature(
                    signatures?.consent_date_digital_signature_image
                  )}
                  <FormOutlined
                    onClick={() => {
                      setSign(signatures?.consent_date_digital_signature_image)
                      setSignType("onlyDate")
                      setOpenSignatureModal(true)
                      setSignOf("consent_date_digital_signature_image")
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                {consentDate?.includes("separate_date") ? (
                  <Space direction={"vertical"}>
                    <div className={"consent-section"}>
                      <div>{`${t("Explanation date")}`}</div>
                      <div>{"令和"}</div>
                      <div>{"年"}</div> <div>{"月"}</div> <div>{"日"}</div>
                    </div>

                    <div className={"consent-section"}>
                      <div>{`${t("Consent Date")}`}</div>
                      <div>{"令和"}</div>
                      <div>{"年"}</div> <div>{"月"}</div> <div>{"日"}</div>
                    </div>
                  </Space>
                ) : (
                  ""
                )}
              </>
            )}
            {content?.includes("parent_sign") &&
            parentOptions?.includes("change_reresentative") ? (
              <div className={"guardian-flex"}>
                <div className={"sign-flex"}>
                  <Typography.Text>
                    {t("Guardian and family representative name")}
                  </Typography.Text>
                  {renderSignature(signatures?.digital_signature_image)}
                  <FormOutlined
                    onClick={() => {
                      setSign(signatures?.digital_signature_image)
                      setSignType("name")
                      setOpenSignatureModal(true)
                      setSignOf("digital_signature_image")
                    }}
                  />
                </div>
                {i18n.language === "ja" && (
                  <div className={"print-label"}>{t("mark")}</div>
                )}
              </div>
            ) : content?.includes("parent_sign") ? (
              <div className={"sing-flex-mark"}>
                <div className={"guardian-container"}>
                  <Typography.Text>{t("Parents Name")}</Typography.Text>
                  {renderSignature(signatures?.digital_signature_image)}
                  <FormOutlined
                    onClick={() => {
                      setSign(signatures?.digital_signature_image)
                      setSignType("name")
                      setOpenSignatureModal(true)
                      setSignOf("digital_signature_image")
                    }}
                    className={"form-outlined"}
                  />
                </div>
                {content?.includes("parent_seal") ? (
                  <Typography.Text>{t("mark")}</Typography.Text>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {content?.includes("child_sign") ? (
              <div className={"sign-flex"}>
                <Typography.Text>{t("child name")}</Typography.Text>
                {renderSignature(signatures?.child_digital_signature_image)}
                <FormOutlined
                  onClick={() => {
                    setSign(signatures?.child_digital_signature_image)
                    setSignType("name")
                    setOpenSignatureModal(true)
                    setSignOf("child_digital_signature_image")
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={"info-wrapper"}>
            <div className={"designation-class"}>
              {data?.facility?.facility_name}
            </div>

            <div className={"designation-class margin-line"}>
              <div className={"designation sign-section"}>
                {t("Child development support manager")}
                {!content?.includes("manager_signature_column") ? (
                  <b>&nbsp;&nbsp;{data?.staff?.staff_name}</b>
                ) : (
                  ""
                )}
              </div>
              <div>
                {content?.includes("manager_signature_column") ? (
                  <>
                    {renderSignature(
                      signatures?.manager_digital_signature_image
                    )}
                    <FormOutlined
                      onClick={() => {
                        setSign(signatures?.manager_digital_signature_image)
                        setSignType("name")
                        setOpenSignatureModal(true)
                        setSignOf("manager_digital_signature_image")
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                {content?.includes("manager_seal") ? (
                  <span className={"mark"}>
                    &nbsp;&nbsp;<Typography.Text>{t("mark")}</Typography.Text>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>

      <FormWrapper className={"bottom-buttons-wrapper"} mt ref={bottomElmRef}>
        <div className={"bottom-btns"}>
          <div className={"left"}>
            <OwnerButton
              text={t("<< Back")}
              typeOf={"draft"}
              shape={"circle"}
              onClick={() => router.back()}
            />
            {typeof window !== "undefined" && (
              <OwnerButton
                text={t("Print (PDF Output)")}
                typeOf={"draft"}
                shape={"circle"}
                disabled={isLoading}
                icon={"printBlack"}
                onClick={() => onPrint()}
              />
            )}
          </div>
          <div className={"right"}>
            <OwnerButton
              text={t("Edit monitoring")}
              icon={"edit"}
              typeOf={"draft"}
              shape={"circle"}
              onClick={() => router.push(`/monitoring/edit/${data?.id}`)}
            />
          </div>
        </div>
      </FormWrapper>
    </Wrapper>
  )

  return (
    <>
      <OperationsWrapper>
        <Header>{t("Operation options")}</Header>
        <Divisor />
        <SearchWrapper className={"field-wrapper_over-rider-indentation"}>
          <TitleWrap style={{ minWidth: "125px" }}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Display contents")} </Text>
          </TitleWrap>
          <CheckboxWrap>
            <Checkboxes
              key={"footerInformation"}
              name={"footerInformation"}
              options={DISPLAY_CONTENT}
              value={tempContent}
              onChange={setTempContent}
            />
          </CheckboxWrap>
        </SearchWrapper>

        {tempContent?.includes("consent_date") ? (
          <SearchWrapper className={"field-wrapper_over-rider-indentation"}>
            <TitleWrap style={{ minWidth: "125px" }}>
              <img
                src={"/assets/icons/arrow.svg"}
                style={{ marginRight: "10px" }}
              />
              <Text>{t("Consent date")} </Text>
            </TitleWrap>
            <CheckboxWrap>
              <Checkboxes
                key={"footerInformation"}
                name={"footerInformation"}
                value={tempConsentDate}
                options={CONSENT_DATE}
                onChange={setTempConsentDate}
              />
            </CheckboxWrap>
          </SearchWrapper>
        ) : (
          ""
        )}
        {tempContent?.includes("parent_sign") ? (
          <SearchWrapper className={"field-wrapper_over-rider-indentation"}>
            <TitleWrap style={{ minWidth: "125px" }}>
              <img
                src={"/assets/icons/arrow.svg"}
                style={{ marginRight: "10px" }}
              />
              <Text>{t("Parent signature field")} </Text>
            </TitleWrap>
            <CheckboxWrap>
              <Checkboxes
                key={"footerInformation"}
                name={"footerInformation"}
                options={PARENT_FIELD}
                value={temParentOptions}
                onChange={setTempParentOptions}
              />
            </CheckboxWrap>
          </SearchWrapper>
        ) : (
          ""
        )}

        <SearchWrapper>
          <div>
            <OwnerButton
              typeOf={"secondary"}
              className={"reset-button"}
              text={t("Change display")}
              onClick={onDisplayChange}
            />
          </div>
        </SearchWrapper>
      </OperationsWrapper>

      {/* To be print list starts here */}
      <DataSheetList />
      {/* To be print list ends here */}
      {/* signature modal */}
      {openSignatureModal && (
        <Modal
          open={openSignatureModal}
          title={t("Confirmation signature")}
          onCancel={() => {
            setOpenSignatureModal(false)
          }}
          footer={null}
          bodyStyle={{
            padding: 0,
          }}
        >
          <SignatureCanvas
            sign={signType == "name" ? sign : null}
            dateSign={signType == "onlyDate" ? sign : null}
            signType={signType}
            isLoading={isUpdating}
            onSave={(sign, dateSign) => {
              saveSignatures(signOf, signType == "name" ? sign : dateSign)
            }}
          />
        </Modal>
      )}
    </>
  )
}

export { MonitoringDetailFormIndividual }
