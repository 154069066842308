import React from "react"
import { useTranslation } from "react-i18next"
import InfoTextBox from "../../molecules/Billing/InfoTextBox"
import { BillingFacilityInfo } from "../../molecules/Billing/BillingFacilityInfo"
import {
  BillingCSVOutputTable,
  BillingDateInfoBox,
  BillingPlanTable,
  BillingRegisteredInfoTable,
  BillingSectionHeader,
} from "../../molecules"
import { BillingExceedUsageAlert } from "../../molecules/Billing/BillingExceedUsageAlert"

export const BillingAlertSection = ({
  alerts,
  data,
  updateData,
  disabled,
  incrementStep = false,
  facilityId,
  childCountText,
}: {
  alerts: any
  data: any
  updateData: (val) => void
  disabled: boolean
  incrementStep?: boolean
  facilityId: string | number
  childCountText: string
}) => {
  const { t } = useTranslation()
  const handleOutputSettingChange = (key, value) => {
    const nhif_csv_output_setting = {
      ...data.nhif_csv_output_setting,
    }
    nhif_csv_output_setting[key] = value
    updateData({ ...data, nhif_csv_output_setting })
  }

  const handleOutputChildChange = (childList) => {
    updateData({ ...data, nhif_children_output: childList })
  }

  const updateOutputSiblingChild = (siblingChild) => {
    updateData({
      ...data,
      nhif_sibling_children_output: siblingChild,
    })
  }

  return (
    <>
      <BillingSectionHeader
        num={incrementStep ? 2 : 1}
        title={t("Select children to output")}
        id={"selectChildrenToOutput"}
        disabled={disabled}
      >
        <div className={"disable-overlay"}></div>
        {Array.isArray(alerts?.exceed_days_used) &&
          alerts?.exceed_days_used?.length > 0 && (
            <BillingExceedUsageAlert alerts={alerts?.exceed_days_used} />
          )}
        {Array.isArray(alerts?.unregistered_child) &&
          alerts.unregistered_child.length > 0 && (
            <BillingFacilityInfo alerts={alerts.unregistered_child} />
          )}
        {!alerts?.is_facility_contact_available && (
          <InfoTextBox
            title={t(
              "The following facility information is missing. It is not printed when printing various forms, so please register it.telephone number"
            )}
            showBtn
            underline
          />
        )}
        {Array.isArray(alerts?.child_receiving_certificates_with_no_acquire) &&
          alerts?.child_receiving_certificates_with_no_acquire?.length > 0 && (
            <InfoTextBox
              title={t(
                'There is a child whose acquisition setting for the upper limit management addition is set to "Do not acquire".'
              )}
              subTitle={t(
                '※ If you want to acquire the upper limit management addition, please change the acquisition setting to "Acquire".'
              )}
              list={alerts?.child_receiving_certificates_with_no_acquire?.map(
                (d) => {
                  return {
                    name: d?.child?.child_name,
                    link: `/child/profile/${d?.child?.id}/recipient-certificate-form/edit/${d?.id}`,
                  }
                }
              )}
            />
          )}
        {Array.isArray(alerts?.expired_child_receiving_certificates) &&
          alerts?.expired_child_receiving_certificates?.length > 0 && (
            <InfoTextBox
              title={t(
                "There are children whose recipient certificates are not valid."
              )}
              showBtn
              list={alerts?.expired_child_receiving_certificates?.map((d) => {
                return {
                  name: d?.child?.child_name,
                  link: `/child/profile/${d?.child?.id}/recipient-certificate-form/edit/${d?.id}`,
                }
              })}
            />
          )}
        {Array.isArray(alerts?.transportation_using_child) &&
          alerts?.transportation_using_child?.length > 0 && (
            <BillingDateInfoBox
              usedTransports={alerts?.transportation_using_child}
            />
          )}
        {Array.isArray(alerts?.children_with_multiple_reduction) &&
          alerts?.children_with_multiple_reduction?.length > 0 && (
            <InfoTextBox
              title={t(
                "There are children who have been set up in the multiple-child reduction eligibility category."
              )}
              showBtn
              list={alerts?.children_with_multiple_reduction?.map((d) => {
                return {
                  name: d?.child?.child_name,
                  link: `/child/profile/${d?.child?.id}/recipient-certificate-form/edit/${d?.id}`,
                }
              })}
            />
          )}
        {Array.isArray(alerts?.children_on_free_period) &&
          alerts?.children_on_free_period?.length > 0 && (
            <InfoTextBox
              title={t("There is a child who is eligible for free admission")}
              showBtn
              list={alerts?.children_on_free_period?.map((d) => {
                return {
                  name: d?.child?.child_name,
                  link: `/child/profile/${d?.child?.id}/recipient-certificate-form/edit/${d?.id}`,
                }
              })}
            />
          )}
        {alerts && alerts?.facility_status_setting_no_data === true && (
          <BillingRegisteredInfoTable facilityId={alerts?.facility_id} />
        )}
        <BillingPlanTable
          childList={
            data?.nhif_children_output?.map((d) => {
              const is_exceed_usage =
                !data?.nhif_csv_output_setting?.bill_on_Exceed &&
                Array.isArray(alerts?.exceed_days_used) &&
                alerts?.exceed_days_used?.find(
                  (e) => e?.child_id == d?.child_id
                )
                  ? true
                  : false
              d.is_exceed_usage = is_exceed_usage
              return d
            }) || []
          }
          updateOutputChild={handleOutputChildChange}
          facilityId={facilityId}
        />
        <BillingCSVOutputTable
          bottomText={childCountText}
          parentList={data?.nhif_sibling_children_output || []}
          outputSetting={data?.nhif_csv_output_setting}
          updateOutputSetting={handleOutputSettingChange}
          updateOutputSiblingChild={updateOutputSiblingChild}
        />
      </BillingSectionHeader>
    </>
  )
}
