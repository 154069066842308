import {
  getBusinessRegistrationMonths,
  getBusinessRegistrationYears,
  PageCard,
  SelectInput,
  dynamicLangString,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Spin } from "antd"
import router from "next/router"
import { useRef, useState, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { createUpdateRecurringScheduleSetting } from "../../../services/recurringScheduleSetting"
import { OwnerButton } from "../../atoms"
import {
  ChangeFormOperations,
  RecurringScheduleCalendar,
} from "../../molecules"
import { scrollToSelectedRefElement } from "../../../utils"

interface IChangeForm {
  childId: number | string
  editYear: string | number
  editMonth: string | number
  year: string | number
  month: string | number
  contractedFacilities: Array<any>
  recurringScheduleSetting: Array<any>
  facilityActivities: Array<any>
  type: "edit" | "create" | "copy"
  onYearMonthChange: any
  serviceType?: number
  showCautionMessageBeforeUpdate: boolean
  showDelete?: boolean
  onDelete?: () => void
  isDeleting?: boolean
}

const Wrapper = styled.div`
  .recurring-title {
    background: ${theme.orange};
    padding: 10px;
    margin: 10px 0;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
    align-items: center;
    .date-select {
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
    }
  }
  .button-container-with-delete {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .button-container {
    display: flex;
    gap: 10px;
  }
  .delete-button {
    background: ${theme.red2};
    color: #fff;
  }
  .no-facility-info {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid ${theme.red2};
    border-radius: 5px;
    color: ${theme.red2};
    h4 {
      color: ${theme.red2};
    }
  }
  .invalid-recurring-setting {
    border: 2px solid ${theme.red2};
  }
`

const Spinner = styled(Spin)`
  width: 100%;
  display: flex;
  margin: 10px;
  justify-content: center;
`

const CautionMessageContainer = styled.div`
  max-width: 750px;
`

const ChangeFormPage = ({
  childId,
  editYear,
  editMonth,
  year,
  month,
  contractedFacilities,
  recurringScheduleSetting,
  facilityActivities,
  type,
  onYearMonthChange,
  showCautionMessageBeforeUpdate,
  serviceType,
  showDelete,
  onDelete,
  isDeleting = false,
}: IChangeForm) => {
  const { t } = useTranslation()

  const numberOfWeeks = 6

  const getDayOfWeek = (n: number) => {
    switch (n) {
      case 0:
        return "sun"
      case 1:
        return "mon"
      case 2:
        return "tue"
      case 3:
        return "wed"
      case 4:
        return "thu"
      case 5:
        return "fri"
      case 6:
        return "sat"
      default:
        return null
    }
  }
  const scrollRef = useRef()
  const getScheduleData = () => {
    const dataSource = []
    let schedulesOnly = recurringScheduleSetting?.map((facilitySetting) => {
      const facility_id = facilitySetting.facility_id
      return facilitySetting?.schedules?.map((schedule) => {
        if (type == "copy" || type == "create") delete schedule.id
        return { ...schedule, facility_id }
      })
    })

    if (Array.isArray(schedulesOnly)) {
      let mergedSchedules = []
      schedulesOnly.map((sch) => {
        mergedSchedules = mergedSchedules.concat(sch)
      })
      schedulesOnly = mergedSchedules
    } else schedulesOnly = []

    for (let weekLoop = 1; weekLoop <= numberOfWeeks; weekLoop++) {
      const weekData = {
        week: t(`week${weekLoop}`),
      }
      for (let dayLoop = 0; dayLoop < 7; dayLoop++) {
        if (weekLoop == numberOfWeeks && dayLoop > 1) break
        weekData[getDayOfWeek(dayLoop)] = schedulesOnly?.find(
          (schedule) =>
            schedule.week_no == weekLoop && schedule.day_of_week == dayLoop
        ) ?? {
          week_no: weekLoop,
          day_of_week: dayLoop,
        }
      }
      dataSource.push(weekData)
    }
    return dataSource
  }

  const filterContractFacilities = (startYear, startMonth) => {
    const toReturn = contractedFacilities
      ?.filter((fac) => {
        const contractStartDate = fac?.contract_start_date
        if (new Date(contractStartDate) <= new Date(+startYear, +startMonth))
          return fac
      })
      ?.map((active) => {
        return {
          value: active?.facility_id,
          label: active?.facility?.facility_name_short,
        }
      })
    return toReturn
  }

  const currentYearMonth = useRef({
    year,
    month,
  })

  const [activeFacilities, setActiveFacilities] = useState(
    filterContractFacilities(
      currentYearMonth.current.year,
      currentYearMonth.current.month
    )
  )

  const [recurringSchedules, setRecurringSchedules] = useState(
    getScheduleData()
  )

  const [reloading, setReloading] = useState(false)

  useEffect(() => {
    setReloading(false)
  }, [recurringSchedules])

  const updateYearMonth = ({ year, month }) => {
    currentYearMonth.current = {
      year: year ?? currentYearMonth.current.year,
      month: month ?? currentYearMonth.current.month,
    }
    const updatedActiveFacilities = filterContractFacilities(
      currentYearMonth.current.year,
      currentYearMonth.current.month
    )
    setActiveFacilities(updatedActiveFacilities)
    if (type == "edit" && updatedActiveFacilities?.length > 0)
      onYearMonthChange({ year, month })
  }

  const handleScheduleUpdate = (schedule) => {
    const newSchedules = [...recurringSchedules]
    newSchedules[schedule.week_no - 1][getDayOfWeek(schedule.day_of_week)] =
      schedule
    setRecurringSchedules(newSchedules)
  }

  const bulkUpdate = ({
    facility_id,
    weeks,
    days,
    pickup,
    pickup_place,
    pickup_place_name,
    pickup_hour,
    pickup_minute,
    drop,
    drop_place,
    drop_place_name,
    drop_hour,
    drop_minute,
    remarks_update,
    remarks,
  }) => {
    setReloading(true)
    const newSchedules = [...recurringSchedules]
    weeks?.map((week) => {
      days?.map((day) => {
        if (week == 6 && day > 1) return
        const updateObject: any = { is_delete: false }
        if (facility_id) updateObject.facility_id = facility_id
        updateObject.pickup = pickup
        updateObject.pickup_place = pickup_place
          ? pickup_place == "others"
            ? pickup_place_name
            : pickup_place
          : null
        updateObject.pickup_time =
          pickup_hour && pickup_minute
            ? pickup_hour + ":" + pickup_minute
            : null
        updateObject.drop = drop
        updateObject.drop_place = drop_place
          ? drop_place == "others"
            ? drop_place_name
            : drop_place
          : null
        updateObject.drop_time =
          drop_hour && drop_minute ? drop_hour + ":" + drop_minute : null
        if (remarks_update) {
          updateObject.remarks = remarks
        }
        newSchedules[week - 1][getDayOfWeek(day)] = {
          ...newSchedules[week - 1][getDayOfWeek(day)],
          ...updateObject,
        }
      })
    })
    setRecurringSchedules(newSchedules)
    setTimeout(() => {
      scrollToSelectedRefElement(scrollRef)
    }, 1)
  }

  const checkValidity = () => {
    let isValid = true
    let firstId = ""
    document
      .querySelectorAll(".invalid-recurring-setting")
      .forEach((elem) => elem.classList.remove("invalid-recurring-setting"))
    recurringSchedules.map((weeklyData) => {
      Object.keys(weeklyData).map((dayData) => {
        const schedule = weeklyData[dayData]
        if (schedule.facility_id && !schedule.is_delete) {
          const id = `week-${schedule?.week_no}-day-${schedule.day_of_week}`
          const validFacility = activeFacilities.find(
            (fac) => fac.value == schedule.facility_id
          )
          if (
            !validFacility ||
            (schedule?.pickup_place == "others" &&
              !schedule?.pickup_place_name) ||
            (schedule?.drop_place == "others" && !schedule?.drop_place_name)
          ) {
            isValid = false
            if (!firstId) firstId = id
            document
              .getElementById(id)
              .classList.add("invalid-recurring-setting")
          }
        }
      })
    })
    if (firstId)
      document
        .getElementById(firstId)
        .scrollIntoView({ behavior: "smooth", block: "center" })
    return isValid
  }

  const saveSchedule = () => {
    if (!checkValidity()) return
    const payload = []
    // convert week based data to facility based data
    recurringSchedules.map((weeklyData) => {
      Object.keys(weeklyData).map((dayData) => {
        const schedule = weeklyData[dayData]
        if (schedule.facility_id) {
          const scheduleDetail = {
            ...schedule,
            facility_id: schedule.facility_id,
          }
          scheduleDetail.pickup = +scheduleDetail.pickup
          scheduleDetail.drop = +scheduleDetail.drop
          if (!scheduleDetail.pickup) {
            scheduleDetail.pickup_place = ""
            scheduleDetail.pickup_place_name = ""
            scheduleDetail.pickup_time = ""
          } else {
            if (scheduleDetail.pickup_place == "others")
              scheduleDetail.pickup_place = scheduleDetail.pickup_place_name
            if (scheduleDetail.pickup_hour && scheduleDetail.pickup_minute)
              scheduleDetail.pickup_time =
                scheduleDetail.pickup_hour + ":" + scheduleDetail.pickup_minute
          }

          if (!scheduleDetail.drop) {
            scheduleDetail.drop_place = ""
            scheduleDetail.drop_place_name = ""
            scheduleDetail.drop_time = ""
          } else {
            if (scheduleDetail.drop_place == "others")
              scheduleDetail.drop_place = scheduleDetail.drop_place_name
            if (scheduleDetail.drop_hour && scheduleDetail.drop_minute)
              scheduleDetail.drop_time =
                scheduleDetail.drop_hour + ":" + scheduleDetail.drop_minute
          }
          const indexWithFacility = payload.findIndex(
            (p) => p.facility_id == schedule.facility_id
          )
          if (indexWithFacility == -1) {
            const newObj = {
              child_id: +childId,
              facility_id: schedule.facility_id,
              year: type == "edit" ? editYear : +currentYearMonth.current.year,
              month:
                type == "edit" ? editMonth : +currentYearMonth.current.month,
              start_year: +currentYearMonth.current.year,
              start_month: +currentYearMonth.current.month,
              child_facility_repeat_schedule_details: [{ ...scheduleDetail }],
            }
            payload.push(newObj)
          } else {
            payload[
              indexWithFacility
            ].child_facility_repeat_schedule_details.push({ ...scheduleDetail })
          }
        }
      })
    })
    mutate(payload)
  }

  const { mutate, isLoading: isSavingSchedules } = useMutation(
    (payload: any) => createUpdateRecurringScheduleSetting(payload),
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Recurring Schedule",
            "Updated Successfully",
          ]),
        })
        router.push({
          pathname: `/child/profile/recurring-schedule-setting-list/${childId}`,
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  return (
    <Wrapper>
      <ChangeFormOperations
        facilities={activeFacilities}
        handleSubmit={bulkUpdate}
        numberOfWeeks={numberOfWeeks}
        serviceType={serviceType}
      />
      <PageCard title={t("Recurring schedule settings")}>
        {activeFacilities.length == 0 && (
          <div className={"no-facility-info"}>
            <h4>{t("Cannot save because there is no contract facility")}</h4>
            <div>
              {t(
                "The contracted facility is not registered in the selected usage start date"
              )}
              <br />
              <Trans>
                {
                  "Please register the contracted facility from the contracted payment amount list on the beneficiary certificate edit screen. "
                }
                <a href={`/child/profile/${childId}`}>
                  {"Click here for a list of beneficiary certificates"}
                </a>
                {" that contract facilities can register"}
              </Trans>
            </div>
          </div>
        )}
        <div className={"recurring-title"} ref={scrollRef}>
          <div>{t("Recurring schedule start year & month：")}</div>
          <div className={"date-select"}>
            <SelectInput
              options={getBusinessRegistrationYears()}
              width={80}
              defaultValue={year}
              onChange={(val) => {
                updateYearMonth({
                  year: val,
                  month: currentYearMonth.current.month,
                })
              }}
            />
            {t("Year")}
            <SelectInput
              options={getBusinessRegistrationMonths()}
              width={60}
              defaultValue={month}
              onChange={(val) => {
                updateYearMonth({
                  year: currentYearMonth.current.year,
                  month: val,
                })
              }}
            />
            {t("Month")}
          </div>
        </div>
        {reloading ? (
          <Spinner size={"large"} />
        ) : (
          <>
            <RecurringScheduleCalendar
              facilities={activeFacilities}
              data={recurringSchedules}
              facilityActivities={facilityActivities}
              onUpdate={handleScheduleUpdate}
              isCDSChild={serviceType == 2}
            />
            <div className={"button-container-with-delete"}>
              <div className={"button-container"}>
                <OwnerButton
                  text={t("Cancel")}
                  typeOf={"secondary"}
                  onClick={() =>
                    router.push(
                      `/child/profile/recurring-schedule-setting-list/${childId}`
                    )
                  }
                />
                {activeFacilities.length > 0 &&
                  (showCautionMessageBeforeUpdate ? (
                    <Popconfirm
                      title={
                        <CautionMessageContainer>
                          {t(
                            "The update will be reflected on facility applications from {{month}}. Any facility application before {{month}} shown based on this recurring schedule will be removed.  If you wish to keep old facility applications  please create a new or copy this recurring schedule. Please click ok to update",
                            {
                              month: `${month}月`,
                            }
                          )}
                        </CautionMessageContainer>
                      }
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      onConfirm={saveSchedule}
                      okButtonProps={{
                        size: "middle",
                        shape: "round",
                        style: { padding: "0px 14px" },
                      }}
                      cancelButtonProps={{
                        size: "middle",
                        shape: "round",
                        style: { padding: "0px 14px" },
                      }}
                    >
                      <OwnerButton
                        text={t("Save")}
                        shape={"circle"}
                        disabled={isSavingSchedules}
                        isLoading={isSavingSchedules}
                      />
                    </Popconfirm>
                  ) : (
                    <OwnerButton
                      text={t("Save")}
                      shape={"circle"}
                      onClick={saveSchedule}
                      disabled={isSavingSchedules}
                      isLoading={isSavingSchedules}
                    />
                  ))}
              </div>
              {showDelete && (
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                  onConfirm={onDelete}
                >
                  <OwnerButton
                    text={t("Delete")}
                    typeOf={"secondary"}
                    className={"delete-button"}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              )}
            </div>
          </>
        )}
      </PageCard>
    </Wrapper>
  )
}

export { ChangeFormPage }
