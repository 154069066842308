import { theme } from "@project/shared"
import { Collapse } from "antd"
import Link from "next/link"
import styled from "styled-components"

interface WarningProps {
  color?: string
}

interface LinkGeneratorProps {
  facility_id: string | number
  child_id: string | number
  date: string
}

interface IReportChildWarning {
  color?: string
  title: string
  detail: string
  additionalInfo?: string
  link: (obj: LinkGeneratorProps) => string
  childInfo: Array<any>
}

const Wrapper = styled.div<WarningProps>`
  margin: 20px 0;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid ${(props) => props?.color ?? "#e00000"};
  padding: 14px 30px;
  .title-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
    color: ${(props) => props?.color ?? theme.red2};
    span {
      font-size: large;
      font-weight: 700;
    }
  }
  .ant-collapse {
    border: none;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .additional-info {
    text-decoration: underline;
    color: ${(props) => props?.color ?? theme.red2};
    margin-bottom: 10px;
  }
  .ant-collapse-header-text {
    text-decoration: underline;
    color: ${(props) => props?.color ?? theme.red2};
  }
  .ant-collapse-expand-icon svg {
    fill: ${(props) => props?.color ?? theme.red2};
  }
  .ant-collapse-header,
  .ant-collapse-content-box {
    background: #ffffff !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .child-detail-table {
    width: 100%;
    border: 1px solid ${theme.gray2};
    tr:not(:last-child) {
      border-bottom: 1px solid ${theme.gray2};
    }
    td {
      padding: 10px;
    }
    td:first-child {
      background: ${theme.bgColor2};
    }
    td:not(:last-child) {
      border-right: 1px solid ${theme.gray2};
    }
  }
`
export const ReportChildWarning = ({
  color,
  title,
  detail,
  link,
  childInfo,
  additionalInfo,
}: IReportChildWarning) => {
  const { Panel } = Collapse

  return (
    <Wrapper color={color}>
      <div className={"title-container"}>
        <img
          src={"/assets/icons/error-info.svg"}
          alt={"img"}
          width={20}
          height={20}
        />
        <span>{title}</span>
      </div>
      {additionalInfo && (
        <div className={"additional-info"}>{additionalInfo}</div>
      )}
      <Collapse>
        <Panel header={detail} key={"1"}>
          <table className={"child-detail-table"}>
            {childInfo?.map((info) => (
              <tr key={info?.id}>
                <td>{info?.child_name}</td>
                <td>{info?.facility_name_short}</td>
                <td>
                  {info?.dates
                    ?.split(",")
                    ?.sort()
                    .map((date, index) => (
                      <>
                        {index > 0 && ", "}
                        <Link
                          href={link({
                            child_id: info?.child_id,
                            facility_id: info?.facility_id,
                            date,
                          })}
                        >
                          <a style={{ textDecoration: "underline" }}>{date}</a>
                        </Link>
                      </>
                    ))}
                </td>
              </tr>
            ))}
          </table>
        </Panel>
      </Collapse>
    </Wrapper>
  )
}
