import { FormOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { PageCard, Table, theme } from "@project/shared"
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getDateStringWithDay } from "../../../utils"
import { Button, Tag } from "../../atoms"

const TableTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: normal;
  div {
    @media (max-width: ${theme.breakpoints.md}) {
      width: 198px;
    }
  }
  gap: 5px;
  button {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d2d1d1;
    border-radius: 100px;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.blue5};
    svg {
      margin-right: 2px;
    }
  }
`

type Iprops = {
  isLoading: boolean
  detail: any
  childId?: number
}

export const RelatedInformationOnIndividualSupportPlans: React.FC<Iprops> = ({
  isLoading,
  detail,
  childId,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const renderEditButton = (
    created?: string,
    is_draft?: boolean,
    created_date?: string,
    link?: string
  ) => {
    return (
      <Link href={link}>
        <a
          style={{
            marginTop: created && "0.4rem",
            minWidth: "200px",
            maxWidth: "300px",
            color: created ? theme.base : theme.red3,
            border: created
              ? is_draft
                ? theme.green1
                : `1px solid ${theme?.red3}`
              : `1px solid ${theme?.red3}`,
            background: created
              ? is_draft
                ? theme.green1
                : theme.red3
              : "transparent",
            display: "flex",
            gap: "0.5rem",
            alignItems: created ? "flex-start" : "center",
            padding: "10px 5px",
            borderRadius: "5px",
            cursor: "pointer",
            marginBottom: "8px",
          }}
        >
          <FormOutlined
            style={{
              marginTop: created && "0.4rem",
            }}
          />
          <span>
            {created
              ? !is_draft
                ? t("Created : {{created}}", {
                    created: moment(created_date).isValid()
                      ? getDateStringWithDay(created_date)
                      : getDateStringWithDay(moment),
                  })
                : t("Draft : {{date}}", {
                    date: moment(created_date).isValid()
                      ? getDateStringWithDay(created_date)
                      : getDateStringWithDay(moment),
                  })
              : t("not created")}
          </span>
        </a>
      </Link>
    )
  }

  const datasource = detail?.map((val) => {
    return {
      ...val,
      assessment: renderEditButton(
        val?.has_assessment,
        val?.is_assessment_draft,
        val?.assessment_created,
        val?.has_assessment
          ? `/assessment/details/${val?.assessment_id}`
          : `/assessment/add?selected_child=${childId}`
      ),
      support_plan_draft: renderEditButton(
        val?.has_support_plan_draft,
        val?.is_support_plan_draft_is_draft,
        val?.support_plan_draft_created,
        val?.has_support_plan_draft
          ? `/individual-support-draft-plans/detail/${val?.support_plan_draft_id}`
          : `/individual-support-draft-plans/add?child_id=${childId}`
      ),
      support_plan: renderEditButton(
        val?.has_support_plan,
        val?.is_support_plan_draft,
        val?.support_plan_created,
        val?.has_support_plan
          ? `/individual-support-plans/detail/${val?.support_plan_id}`
          : `/individual-support-plans/add?child_id=${childId}`
      ),
      minutes: renderEditButton(
        val?.has_support_meeting,
        val?.is_support_meeting_draft,
        val?.support_meeting_created,
        val?.has_support_meeting
          ? `/minutes/detail/${val?.support_meeting_id}`
          : `/minutes/create?c_id=${childId}`
      ),
      monitoring: renderEditButton(
        val?.has_monitoring,
        val?.is_monitoring_draft,
        val?.monitoring_created,
        val?.has_monitoring
          ? `/monitoring/detail/${val?.monitoring_id}`
          : `/monitoring/add?c_id=${childId}`
      ),
    }
  })

  const columns = [
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}> {t("Facility name")}</span>
      ),
      key: "facility_name_short",
      dataIndex: "facility_name_short",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}> {t("Service to use")}</span>
      ),
      dataIndex: "service_to_use",
      key: "service_to_use",
      render: (text: string) => {
        return (
          <>
            {text && (
              <Tag
                backgroundColor={
                  text === "After school day service"
                    ? "rgb(226, 104, 39)"
                    : theme.blue5
                }
              >
                {t(text)}
              </Tag>
            )}
          </>
        )
      },
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{t("No. created")}</div>,
      key: "created_count",
      dataIndex: "created_count",
      render: (value) => <span>{value}</span>,
    },
    {
      title: (
        <TableTitle>
          <div style={{ whiteSpace: "nowrap" }}>{t("Assessment")}</div>
          <Link href={`/assessment/add?selected_child=${childId}`}>
            <a>
              <button>
                <PlusCircleOutlined />
                <span> {t("Create")}</span>
              </button>
            </a>
          </Link>
        </TableTitle>
      ),
      key: "assessment",
      dataIndex: "assessment",
      render: (value) => value,
    },
    {
      title: (
        <TableTitle>
          <div style={{ whiteSpace: "nowrap" }}>
            {t("Individual support plan (draft)")}
          </div>
          <Link
            href={`/individual-support-draft-plans/add?child_id=${childId}`}
          >
            <a>
              <button>
                <PlusCircleOutlined />
                {t("Create")}
              </button>
            </a>
          </Link>
        </TableTitle>
      ),

      dataIndex: "support_plan_draft",
      key: "support_plan_draft",
      render: (value) => value,
    },
    {
      title: (
        <TableTitle>
          <div style={{ whiteSpace: "normal", width: "160px" }}>
            {t("Service Personnel Meeting (Support Meeting) Minutes")}
          </div>
          <Link href={`/minutes/create?c_id=${childId}`}>
            <a>
              <button>
                <PlusCircleOutlined />
                <span> {t("Create")}</span>
              </button>
            </a>
          </Link>
        </TableTitle>
      ),
      key: "minutes",
      dataIndex: "minutes",
      render: (value) => value,
    },
    {
      title: (
        <TableTitle>
          <div style={{ whiteSpace: "nowrap" }}>
            {t("Individual support plan")}
          </div>
          <Link href={`/individual-support-plans/add?child_id=${childId}`}>
            <a>
              <button>
                <PlusCircleOutlined />
                <span> {t("Create")}</span>
              </button>
            </a>
          </Link>
        </TableTitle>
      ),
      key: "support_plan",
      dataIndex: "support_plan",
      render: (value) => value,
    },
    {
      title: (
        <TableTitle>
          <div style={{ whiteSpace: "nowrap" }}>{t("Monitoring")}</div>
          <Link href={`/monitoring/add?c_id=${childId}`}>
            <a>
              <button>
                <PlusCircleOutlined />
                <span> {t("Create")}</span>
              </button>
            </a>
          </Link>
        </TableTitle>
      ),
      key: "monitoring",
      dataIndex: "monitoring",

      render: (value) => value,
    },
  ]

  return (
    <PageCard
      title={t("Related information on individual support plans")}
      extra={
        <Button
          text={t("Status list ")}
          backgroundColor={"transparent"}
          borderColor={theme.gray2}
          icon={"left-arrow"}
          onClick={() => router.push(`/usage-status-list?child_id=${childId}`)}
        />
      }
      toRight
      isMinWidth
    >
      <div style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataSource={datasource}
          loading={isLoading}
          bordered
          scroll={{
            x: "scroll",
          }}
          className={"always-present-scrollbar"}
        />
      </div>
    </PageCard>
  )
}
