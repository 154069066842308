import { theme } from "@project/shared"
import styled from "styled-components"
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    row-gap: 1.5rem;
    column-gap: 2rem;
    flex-wrap: wrap;
    li {
      padding: 0 2rem 0 0;
      color: ${theme.blue5};
      text-decoration: underline;
      text-underline-offset: 8px;
      &:not(:last-child) {
        border-right: 1px solid ${theme.gray2};
      }
    }
  }
  .cta_box {
    border: 1px solid ${theme.red1};
    height: 123px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    border-radius: 12px;
    padding: 0 1rem;
    font-weight: 700;
    a {
      color: ${theme.blue5};
      text-decoration: underline;
    }
  }
  .change-billing-info-button {
    background: transparent;
    border: none;
    color: ${theme.blue5};
    font-weight: 700;
    text-decoration: underline;
  }
  .info-recently-updated {
    border: 1px solid ${theme.red3};
    border-radius: 10px;
    padding: 20px;
    img {
      margin-right: 10px;
    }
    font-weight: 700;
    color: ${theme.red3};
    .content {
      margin-top: 20px;
      padding-left: 20px;
      display: block !important;
      li {
        border: none !important;
        color: ${theme.red3};
        font-weight: 400;
        list-style-type: circle;
        text-decoration: none;
        a {
          border-bottom: 1px solid ${theme.red3};
        }
      }
    }
    .button-container {
      margin-top: 20px;
      button {
        border-radius: 50px;
        padding: 6px 30px;
        background: rgba(7, 130, 200, 0.2);
        border-color: rgba(7, 130, 200, 1);
        color: #000000;
        font-weight: 400;
        height: auto;
        align-items: center;
        display: flex;
        justify-content: center;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .confirmed-billing-info-alert {
    border: 1px solid ${theme.red3};
    border-radius: 10px;
    padding: 20px;
    img {
      margin-right: 10px;
    }
    font-weight: 700;
    color: ${theme.red2};
    .button-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin: 20px 0;
      button {
        border-radius: 50px;
        padding: 6px 30px;
        background: rgba(7, 130, 200, 0.2);
        border-color: rgba(7, 130, 200, 1);
        color: #000000;
        font-weight: 400;
        height: auto;
        align-items: center;
        display: flex;
        justify-content: center;
        span {
          font-size: 14px;
        }
      }
    }
  }
`
