import { useState } from "react"
import { useQuery } from "react-query"
import { getPrintsUserLabelsBillingData } from "../services/NHIFbilling"
import { useRouter } from "next/router"
import { removeBlankAttributes } from "@project/shared"
import moment from "moment"

export const useFetchPrintUserLabels = () => {
  const router = useRouter()
  const [data, setData] = useState([])
  const {
    selected: selectedChildQuery,
    billing_facilities,
    year,
    month,
    facility_id,
    after_school,
  } = router?.query as any

  const billing_fac = billing_facilities
    ? [...billing_facilities?.split(",")?.map((v) => +v)]
    : billing_facilities?.split(",")?.map((v) => +v)

  const [tempBillingFacilities, setTempBillingFacilities] = useState(
    billing_fac || [+facility_id] || []
  )
  const [billingFacilities, setBillingFacilities] = useState(billing_fac || [])
  //fetch data

  const { isFetching, isLoading } = useQuery({
    queryKey: ["print-user-label-data", billingFacilities, facility_id],
    queryFn: () =>
      getPrintsUserLabelsBillingData({
        billingFacilityIds: "",
        year: year || moment().year(),
        month: month || moment().month() + 1,
        facilityIds: billing_facilities
          ? Array.isArray(billingFacilities)
            ? billingFacilities?.join(",")
            : billingFacilities
          : facility_id || "",
        show_zero_invoice_children: 1,
        is_afterschool: after_school,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    select: (response) => {
      const ids = selectedChildQuery
        ? selectedChildQuery?.split(",")?.map((v) => +v)
        : null
      const data = Object.keys(response?.data)?.map((key) => ({
        provision_city: response?.data[key]?.[0].provision_city || "",
        city_number: response?.data[key]?.[0].city_number || "",
        data: selectedChildQuery
          ? response?.data[key]?.filter((val) => ids?.includes(val?.child_id))
          : response?.data[key]?.map((v) => ({
              ...v,
              checked: true,
            })),
      }))

      return !selectedChildQuery
        ? data
        : data?.filter((v) => v?.data?.length >= 1) || []
    },
    onSuccess: (data) => {
      setData(data || [])
    },
  })
  const handleCheckAndUnCheckAll = (key: "ALL" | "NONE") => {
    const newData = data?.map((value) => ({
      ...value,
      data: value?.data?.map((data) => ({
        ...data,
        checked: key === "ALL" ? true : false,
      })),
    }))
    setData(newData)
  }
  const handleSingleCheckAndUncheck = (childId: number, checked: boolean) => {
    const newData = data?.map((value) => ({
      ...value,
      data: value?.data?.map((data) =>
        data?.child_id === childId
          ? {
              ...data,
              checked: checked,
            }
          : data
      ),
    }))

    setData(newData)
  }
  const gotoPrintPage = () => {
    const checked =
      data
        ?.map((val) =>
          val?.data?.filter((v) => v?.checked)?.map((v) => v?.child_id)
        )
        .flat()
        ?.join(",") || null
    router.push({
      pathname: `/national-health-insurance-billing/prints/user-labels/print`,
      query: removeBlankAttributes({
        selected: checked,
        facility_id,
        billing_facilities,
        after_school: after_school,
        year: year,
        month: month,
      }),
    })
  }
  const isPrintEnabled =
    data?.map((val) => val?.data?.filter((v) => v?.checked))?.flat()?.length > 0
  return {
    data,
    isLoading,
    isFetching,
    handleCheckAndUnCheckAll,
    gotoPrintPage,
    tempBillingFacilities,
    setBillingFacilities,
    setTempBillingFacilities,
    handleSingleCheckAndUncheck,
    isPrintEnabled,
  }
}
