import { TopRoundedWhiteCard, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { OwnerTable } from ".."

const Wrapper = styled.div`
  margin: 20px 0;
  .top-buttons {
    margin: 16px 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    .check-buttons {
      height: 35px;
    }
  }
  .print-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    .back-button {
      background: rgb(205, 230, 244);
      color: #000000;
      border-color: ${theme.blue5};
    }
    .print-button {
      background: ${theme.blue5};
      border: none;
    }
    .total-container {
      border: 1px solid #d7d7d7;
      padding: 8px 20px;
    }
  }
  .child-table {
    margin: 20px 0;
    .ant-table {
      overflow-x: auto;
    }
    .ant-table-thead {
      th {
        background: ${theme.gray2};
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: #000000;
    }
    .ant-table-tbody {
      tr td {
        border-left: 1px solid ${theme.gray2};
        border-bottom: 1px solid ${theme.gray2};
        &:last-child {
          border-right: 1px solid ${theme.gray2};
        }
      }
    }
    .selected-child-row {
      background: ${theme.yellow4};
    }
  }
  @media print {
    display: none;
  }
`

interface IUserServiceRecordTodaySituationChildTabl {
  childTableColumn: Array<any>
  childData: Array<any>
  selectChilds: Array<any>
  checkAction: (type) => void
  printAction: () => void
}

export const UserServiceRecordTodaySituationChildTable = ({
  childTableColumn,
  childData,
  selectChilds,
  checkAction,
  printAction,
}: IUserServiceRecordTodaySituationChildTabl) => {
  const { t } = useTranslation()
  const BackPrintTotal = () => {
    return (
      <div className={"print-container"}>
        <OwnerButton
          typeOf={"print"}
          text={t("Print")}
          className={"print-button"}
          disabled={!selectChilds?.length}
          onClick={printAction}
        />

        <div className={"total-container"}>
          {t("totalPeople", { count: selectChilds?.length })}
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className={"child-print-container"}>
        <TopRoundedWhiteCard title={`${t("Choose a child to print")}`}>
          <div className={"top-buttons"}>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Check all")}
              onClick={() => {
                checkAction(true)
              }}
              className={"check-buttons"}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Uncheck all")}
              onClick={() => {
                checkAction(false)
              }}
              className={"check-buttons"}
            />
            {BackPrintTotal()}
          </div>
          <div className={"child-table"}>
            <OwnerTable
              columns={childTableColumn}
              dataSource={childData}
              rowClassName={(row) =>
                selectChilds?.includes(row?.id) ? "selected-child-row" : ""
              }
            />
          </div>
          {BackPrintTotal()}
        </TopRoundedWhiteCard>
      </div>
    </Wrapper>
  )
}
