import { Table } from "@project/shared"
import { buildColumns } from "./utils"
import {
  ReceiptSiblingUpperLimitManagement,
  SiblingSheetData,
  SiblingSheetsDispatcher,
} from "../../../../types"
import { HeaderParentName } from "../HeaderParentName"
import { HeaderChildName } from "../HeaderChildName"
import { CSSProperties } from "react"

type Props = {
  receiptSiblingUpperLimitManagement: ReceiptSiblingUpperLimitManagement
  siblingSheets: SiblingSheetData
  setSiblingSheets: SiblingSheetsDispatcher
  style?: CSSProperties
}

export function UpperSiblingLimitManagementListItem({
  receiptSiblingUpperLimitManagement,
  siblingSheets,
  setSiblingSheets,
  style = {},
}: Props) {
  return (
    <div style={style}>
      <HeaderParentName name={receiptSiblingUpperLimitManagement.parent_name} />
      <HeaderChildName
        name={receiptSiblingUpperLimitManagement.child_name}
        facilityCount={receiptSiblingUpperLimitManagement.facilities.length}
        managementResult={receiptSiblingUpperLimitManagement.management_result}
      />

      <div className={"table-container"}>
        <Table
          columns={buildColumns(
            siblingSheets,
            setSiblingSheets,
            receiptSiblingUpperLimitManagement.facilities.length
          )}
          dataSource={receiptSiblingUpperLimitManagement.facilities}
          scroll={{
            x: "max-content",
          }}
        />
      </div>
    </div>
  )
}
