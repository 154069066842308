import { useCallback, useState } from "react"

// packages
import { t } from "i18next"
import RedDownArrow from "../../../public/assets/icons/RedDownArrow"

// styled components
import { NoticeWrapper } from "./styles"

/**
 * Documentation:
 * 1. This hook is provides NoticeBox component and actions to toggle it.
 * 2. If You send expandable prop as true, then it will render a button to toggle the notice box.
 * 3. If You send expandable prop as false, then it will render a paragraph to show the notice box.
 * 4. In case you won't want to use its default body-element/notice-content_more-info then, you can pass your own children.
 */
export const useNoticeBox = () => {
  const [isNoticeOpen, setIsNoticeOpen] = useState(false)

  const handleNoticeToggle = () => {
    setIsNoticeOpen(!isNoticeOpen)
  }

  const Notice = useCallback(
    ({
      theme,
      children,
      mainTitle,
      expandable,
      description,
      titleClassName,
      containerClassName,
      descriptionClassName,
    }: {
      theme?:
        | "red-1"
        | "red-2"
        | "red-3"
        | Omit<string, "red-1" | "red-2" | "red-3">
      expandable?: boolean
      mainTitle: string
      description?: string
      children?: JSX.Element
      containerClassName?: string
      titleClassName?: string
      descriptionClassName?: string
    }) => {
      const TitleElement = expandable ? "button" : "p"
      return (
        <NoticeWrapper
          $theme={theme}
          className={`common-box-container ${containerClassName}`}
        >
          <div className={`notice-content`}>
            {/* notice main title/btn starts here */}
            <TitleElement
              className={`notice-toggle-btn  ${titleClassName} ${
                expandable && "expandable"
              }`}
              role={`${expandable ? "button" : "text"}`}
              onClick={expandable && handleNoticeToggle}
            >
              <p className={"notice-content_title"}>
                <span className={"alert-icon"}>
                  <img
                    src={"/assets/icons/NotificationAlert.svg"}
                    alt={"alter icon"}
                  />
                </span>
                {expandable ? (
                  <span
                    className={`down-facing-arrow ${isNoticeOpen && "active"}`}
                  >
                    <RedDownArrow />
                  </span>
                ) : null}
                {!expandable && " "}
                {mainTitle && (
                  <span className={"actual-notice-text"}>{t(mainTitle)}</span>
                )}
              </p>
            </TitleElement>
            {/* notice main title/btn ends here */}

            {/* notice more info starts here */}
            {children ? (
              children
            ) : (
              <div
                className={`notice-content_more-info ${
                  isNoticeOpen ? "show" : null
                } ${descriptionClassName}`}
              >
                <div className={"notice-grid-contents"}>
                  {
                    <p className={"notice-content_sub-title"}>
                      {t(description)}
                    </p>
                  }
                </div>
              </div>
            )}
            {/* notice more info ends here */}
          </div>
        </NoticeWrapper>
      )
    },
    [isNoticeOpen]
  )

  return { Notice, handleNoticeToggle, isNoticeOpen }
}
