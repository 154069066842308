import React, { useEffect, useMemo, useState } from "react"
import { benefitPaymentAmount, SelectInput, theme } from "@project/shared"
import { Modal, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { DatePicker } from "../../molecules/DatePicker"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import {
  ContractPaymentModalWrapper,
  ContractPaymentOrangeDatePicker,
  ModalInfo,
} from "../../../module/child/recipient-certificate-form"
import { OwnerTable } from "../../molecules"
import { useQuery } from "react-query"
import { fetchAllJointFacility } from "../../../services/jointFacility"
import moment from "moment"
import { getFacilityContractPaymentById } from "../../../services/childRecievingRecipients"
import styled from "styled-components"
import { japaneseAlphaRegex } from "../../../utils/validation"

const TableWrapper = styled.div`
  .min-width-class {
    min-width: 200px;
  }

  .joint-facility-table {
    .ant-table-content {
      overflow: auto hidden;
      table {
        min-height: 266px;
        .joint-facility-selector .select-facility-input.dynamic-dropdown-width {
          max-width: 800px !important; /* Overriding */
          .ant-select-selection-item {
            text-align: left;
          }
        }
      }
    }
  }
`
interface IJointCompanyRegistrationCompanyModal {
  open: boolean
  onCancel: () => void
  onOkay: () => void
  onSave: (a: any, id?: number) => void
  id: number | null
  loading?: boolean
  data: any
  ownFacilityContractAmount: number
}

const JointCompanyRegistrationCompanyModal: React.FC<
  IJointCompanyRegistrationCompanyModal
> = ({
  open,
  onCancel,
  onOkay,
  data,
  onSave,
  id,
  loading,
  ownFacilityContractAmount,
}) => {
  const [submitCount, setSubmitCount] = useState(0)
  const [datePickerTouched, setDatePickerTouched] = useState(false)
  const { t } = useTranslation()

  const dateFormat = "YYYY年MM月"
  const { data: allJointFacilitiesOptions } = useQuery(
    "list-joint-facilities",
    fetchAllJointFacility,
    {
      retry: 1,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      select: (data) => {
        return data.data.map((otherFacility) => {
          return {
            label: otherFacility.facility_name,
            value: otherFacility.id,
            service_type: otherFacility?.service_type01 ? 1 : 2,
          }
        })
      },
    }
  )

  const { data: facilityContractData, isLoading } = useQuery(
    ["facilityContractById"],
    () => getFacilityContractPaymentById(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      select: (res) => {
        return {
          ...res.data,
          start_year: moment(res?.data?.year).format("YYYY"),
          start_month: moment(res?.data?.month).format("MM"),
          joint_facilities: JSON.parse(res?.data?.joint_facilities),
        }
      },
    }
  )

  const FILTERED_FACILITY_BY_FURIGANA = (furigana) => {
    const regex = japaneseAlphaRegex(furigana)
    if (!furigana) {
      return allJointFacilitiesOptions?.filter(
        ({ service_type, label, value }) => {
          if (data?.service_type > 0 && service_type === data?.service_type) {
            return {
              label,
              value,
            }
          }
          return {
            label,
            value,
          }
        }
      )
    }

    return allJointFacilitiesOptions?.filter(
      ({ label, value, service_type }) => {
        if (
          label?.match(regex) &&
          data?.service_type > 0 &&
          service_type === data?.service_type
        ) {
          return {
            lable: label,
            value,
          }
        } else if (label?.match(regex)) {
          return {
            lable: label,
            value,
          }
        }
      }
    )
  }

  const [dates, setDates] = useState<moment.Moment>(moment())

  const [jointFacilityData, setJointFacilityData] = useState(null)

  const [allJointFacilities, setAllJointFacilities] = useState(null)

  const last_day = useMemo(() => {
    const date = +moment(dates || moment())
      .endOf("month")
      .format("DD")
    return date
  }, [dates])

  // referenses:
  // data -> certificate data
  // id -> selected contract id for edit
  // facilityContractData -> selected facility contract data
  // jointFacilityData -> payment amount vako data[top table data]
  // allJointFacilities -> actual contract data[list of joint contracts]
  useEffect(() => {
    if (jointFacilityData?.length) {
      const updated = jointFacilityData?.map((each) => {
        return {
          ...each,
          amount_provided_under_contracts_with_other_companies: 0,
          benefit_payment_amount:
            +data?.is_benefit_days_in_month < 0
              ? data?.benefit_days === -0.1
                ? last_day
                : last_day + data?.benefit_days
              : data?.benefit_days,
        }
      })
      setJointFacilityData(updated)
    }
  }, [last_day])

  useEffect(() => {
    if (id) {
      const _facilities_data = (
        facilityContractData?.joint_facilities || []
      )?.map((each) => {
        return {
          joint_facility_furigana: "",
          joint_facility_id: each?.facility_other_id,
          joint_facility_name: getFacilityNameFromId(each?.facility_other_id),
          payment_amount: each?.payment_amount < 0 ? -1 : each?.payment_amount,
          payment_amount_new:
            each?.payment_amount < 0 ? each?.payment_amount : 0,
          id: each?.id,
        }
      })
      setDates(
        facilityContractData?.start_year && facilityContractData?.start_month
          ? moment(
              `${facilityContractData?.start_year}-${facilityContractData?.start_month}`
            )
          : moment()
      )
      const contract_year_last_day = +moment(facilityContractData?.year)
        .endOf("month")
        .format("DD") // year has full date
      setJointFacilityData([
        {
          benefit_payment_amount:
            +data?.is_benefit_days_in_month < 0
              ? data?.benefit_days === -0.1
                ? contract_year_last_day
                : contract_year_last_day + data?.benefit_days
              : data?.benefit_days,
          total_contract_amount: facilityContractData?.total_contract_amount,
          own_facility_contract_supply_amount: ownFacilityContractAmount,
          amount_provided_under_contracts_with_other_companies:
            facilityContractData?.facility_contract_payment_amount < 0
              ? facilityContractData?.facility_contract_payment_amount +
                contract_year_last_day
              : facilityContractData?.facility_contract_payment_amount,
        },
      ])
      setAllJointFacilities(_facilities_data)
    } else {
      setJointFacilityData([
        {
          benefit_payment_amount:
            +data?.is_benefit_days_in_month < 0
              ? data?.benefit_days === -0.1
                ? last_day
                : last_day + data?.benefit_days
              : data?.benefit_days,
          total_contract_amount: 0,
          own_facility_contract_supply_amount: ownFacilityContractAmount,
          amount_provided_under_contracts_with_other_companies: 0,
        },
      ])
      setAllJointFacilities([])
    }
  }, [id, facilityContractData])

  useEffect(() => {
    if (jointFacilityData) {
      let total_contract_amount = ownFacilityContractAmount || 0
      let amount_provided_under_contracts_with_other_companies = 0
      allJointFacilities?.forEach((each) => {
        let amount = each?.payment_amount
        if (each?.payment_amount === -1) {
          amount = each?.payment_amount_new
        }
        if (each?.payment_amount) {
          const calculated_amount =
            amount < 0
              ? amount === -0.1
                ? last_day
                : amount + last_day
              : amount
          total_contract_amount += calculated_amount
          amount_provided_under_contracts_with_other_companies +=
            calculated_amount
        }
      })
      setJointFacilityData([
        {
          ...jointFacilityData[0],
          total_contract_amount,
          amount_provided_under_contracts_with_other_companies,
        },
      ])
    }
  }, [allJointFacilities])

  const BENIFIT_AMOUNT_OPTIONS = useMemo(() => benefitPaymentAmount(), [])

  const benefitOptionNegative = BENIFIT_AMOUNT_OPTIONS.map((o) => {
    if (o.value > 0 && o.value != 31) {
      return {
        label: +o.label * -1,
        value: o.value * -1,
      }
    } else if (o.value === 0) {
      return {
        label: +o.label * -1,
        value: -0.1,
      }
    }
  }).filter((o) => o?.value <= 0)

  const handleOnDelete = (id) => {
    const _data = [...allJointFacilities].filter((each) => each.id !== id)
    setAllJointFacilities(_data)
  }

  const handleDateChange = (newDate: moment.Moment) => {
    setDates(newDate)
  }

  const handleJointFacilityDataChange = (id, fieldName, value) => {
    const _data = [...allJointFacilities]
    _data.forEach((each, index) => {
      if (each?.id === id) {
        _data[index][fieldName] = value
      }
    })
    setAllJointFacilities(_data)
  }

  const getCustomContainer = () => {
    const AnchorTable = document.getElementById(
      "popup-container-facility-table"
    )
    return AnchorTable?.querySelector(".ant-table-container")
  }

  /**
   * @returns {HTMLElement} - returns the the element that we
   * want to prevent from scrolling when datepicker's popup is open.
   * */

  const getScrollPreventEl = () => {
    const AnchorTable = document.getElementById(
      "popup-container-facility-table"
    )
    return AnchorTable?.querySelector(".ant-table-content")
  }

  const BENEFIT_PAYMENT_AMOUNT_TABLE = [
    {
      id: "benefit_payment_amount",
      dataIndex: "benefit_payment_amount",
      title: t("Benefit Payment Amount"),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <p>
            {row?.benefit_payment_amount}
            {"日"}
          </p>
        )
      },
    },
    {
      id: "total_contract_amount",
      dataIndex: "total_contract_amount",
      title: t("Total contract amount"),
      className: "min-width-class",
      render: (value, row) => {
        const isDanger = value > row?.benefit_payment_amount
        return (
          <p style={{ color: isDanger && theme.red1 }}>
            {value}
            {"日"}
          </p>
        )
      },
    },
    {
      id: "contract_supply_amount",
      dataIndex: "own_facility_contract_supply_amount",
      title: t("Own facility contract supply amount"),
      className: "min-width-class",
      render: (value) => {
        return (
          <p>
            {value}
            {"日"}
          </p>
        )
      },
    },
    {
      id: "other_amount",
      dataIndex: "amount_provided_under_contracts_with_other_companies",
      title: t("Amount provided under contracts with other companies"),
      className: "min-width-class",
      render: (value, row) => {
        const isDanger = value > row?.benefit_payment_amount
        return (
          <p style={{ color: isDanger && theme.red1 }}>
            {value}
            {"日"}
          </p>
        )
      },
    },
  ]

  const getFacilityNameFromId = (id) => {
    let _name = ""
    allJointFacilitiesOptions?.forEach((facility) => {
      if (facility.value == id) {
        _name = facility.label
      }
    })
    return _name
  }

  const JOINT_FACILITY_TABLE_COLUMN = [
    {
      id: "joint_facility_name",
      dataIndex: "joint_facility_name",
      title: t("Joint facility name"),
      className: "min-width-class",
      render: (_, row) => {
        const jointFacilityOptions = FILTERED_FACILITY_BY_FURIGANA(
          row?.joint_facility_furigana
        )
        return (
          <div
            className={
              "flex items-center gap-5 justify-center joint-facility-selector"
            }
          >
            <SelectInput
              width={80}
              height={40}
              allowClear
              onChange={(v) => {
                handleJointFacilityDataChange(
                  row?.id,
                  "joint_facility_furigana",
                  v
                )
              }}
              placeholder={"--"}
              value={row?.joint_facility_furigana}
              options={FuriganaAlphabetsOption}
              getPopupContainer={() =>
                (getCustomContainer() as HTMLElement) || document.body
              }
              scrollPreventEl={
                getScrollPreventEl() as HTMLElement | undefined | null
              }
            />
            <SelectInput
              className={
                "dynamic-dropdown-width select-facility-input min-w-300"
              }
              width={"auto"}
              height={40}
              options={jointFacilityOptions}
              dropdownMatchSelectWidth={false}
              allowClear
              showSearch={false}
              value={
                jointFacilityOptions?.find(
                  (jf) => jf?.value == row?.joint_facility_id
                )
                  ? row?.joint_facility_id
                  : null
              }
              placeholder={"----"}
              onChange={(v) => {
                handleJointFacilityDataChange(row?.id, "joint_facility_id", v)
                handleJointFacilityDataChange(
                  row?.id,
                  "joint_facility_name",
                  getFacilityNameFromId(v)
                )
              }}
              getPopupContainer={() =>
                (getCustomContainer() as HTMLElement) || document.body
              }
              scrollPreventEl={
                getScrollPreventEl() as HTMLElement | undefined | null
              }
            />
          </div>
        )
      },
    },
    {
      id: "payment_amount",
      dataIndex: "payment_amount",
      title: t("Payment Amount"),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div className={"flex items-center justify-center"}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <SelectInput
                width={80}
                height={40}
                value={row?.payment_amount}
                disabled={!row?.joint_facility_id}
                onChange={(v) => {
                  handleJointFacilityDataChange(row?.id, "payment_amount", v)
                  if (v < 0) {
                    handleJointFacilityDataChange(
                      row?.id,
                      "payment_amount_new",
                      -0.1
                    )
                  }
                }}
                options={BENIFIT_AMOUNT_OPTIONS}
                getPopupContainer={() =>
                  (getCustomContainer() as HTMLElement) || document.body
                }
                scrollPreventEl={
                  getScrollPreventEl() as HTMLElement | undefined | null
                }
              />
              {row?.payment_amount === -1 && (
                <SelectInput
                  width={80}
                  height={40}
                  value={row?.payment_amount_new}
                  disabled={!row?.joint_facility_id}
                  onChange={(v) => {
                    handleJointFacilityDataChange(
                      row?.id,
                      "payment_amount_new",
                      v
                    )
                  }}
                  options={benefitOptionNegative}
                  getPopupContainer={() =>
                    (getCustomContainer() as HTMLElement) || document.body
                  }
                  scrollPreventEl={
                    getScrollPreventEl() as HTMLElement | undefined | null
                  }
                />
              )}
            </div>
            <p className={"m-0"}>{"日"}</p>
          </div>
        )
      },
    },
    {
      id: "delete",
      dataIndex: "delete",
      title: t("Delete"),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div className={"flex items-center gap-5 justify-center"}>
            <OwnerButton
              typeOf={"link"}
              text={t("Delete")}
              className={"delete-button"}
              onClick={() => handleOnDelete(row.id)}
            />
          </div>
        )
      },
    },
  ]

  const disableSaveButton = useMemo(() => {
    return !(
      dates &&
      allJointFacilities?.length > 0 &&
      allJointFacilities.every(
        ({ joint_facility_id, payment_amount }) =>
          !!joint_facility_id &&
          payment_amount !== "" &&
          allJointFacilitiesOptions?.find(
            (jf) => jf?.value == joint_facility_id
          )
      )
    )
  }, [dates, allJointFacilities])

  const handleSave = () => {
    setSubmitCount(submitCount + 1)
    if (!disableSaveButton) {
      onSave(
        {
          id: id || Math.random(),
          joint_facilities: allJointFacilities,
          payment_information: jointFacilityData,
          start_year: dates.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          start_month: dates.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        },
        id
      )
    }
  }

  const handleAddJointFacilities = () => {
    const _data = [...allJointFacilities]
    _data.push({
      id: Math.random(),
      joint_facility_id: "",
      joint_facility_name: "",
      payment_amount: "",
      joint_facility_furigana: "",
    })
    setAllJointFacilities(_data)
  }
  return (
    <Modal
      open={open}
      centered
      title={t("Register the contract payment amount of the joint facility")}
      onCancel={onCancel}
      onOk={onOkay}
      width={"80%"}
      wrapClassName={"custom-modal-for-add-joint-facility"}
      footer={[
        <div
          key={"buttons"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <OwnerButton
            text={id ? t("edit") : t("Save")}
            typeOf={"primary"}
            onClick={handleSave}
            isLoading={loading}
            key={"save-button"}
            disabled={disableSaveButton}
          />
        </div>,
      ]}
      bodyStyle={{ overflowY: "scroll" }}
    >
      <Spin spinning={isLoading}>
        {jointFacilityData &&
          jointFacilityData[0]?.total_contract_amount >
            jointFacilityData[0]?.benefit_payment_amount && (
            <ModalInfo>
              {t(
                `The total contractual payment for the following services exceeds the benefit payment.
                {{serviceName}}`,
                { serviceName: "" }
              )}
            </ModalInfo>
          )}
        {!data?.service_type && (
          <ModalInfo>{t(`No Service has been selected`)}</ModalInfo>
        )}
        <ContractPaymentModalWrapper>
          <div className={"modal-title"}>
            <h3>
              {t("{{YYYY}}年{{MM}}月’s Joint facility contract information", {
                YYYY: moment().year() || "",
                MM: moment().format("MM") || "",
              })}
            </h3>
          </div>
          <TableWrapper>
            <OwnerTable
              dataSource={jointFacilityData}
              columns={BENEFIT_PAYMENT_AMOUNT_TABLE}
              scroll={{ x: 1000 }}
            />
          </TableWrapper>
          <div className={"date-picker-field-container"}>
            <ContractPaymentOrangeDatePicker
              className={"date-picker-field-content"}
              nocolor
            >
              <DatePicker
                picker={"month"}
                className={"contract-payment-datepicker"}
                name={"jointFacilityStartMonth"}
                date={dates?.isValid() ? dates : null}
                onDateChange={(v) => {
                  handleDateChange(moment(v))
                }}
                placeholder={""}
                format={dateFormat}
                inputReadOnly={true}
                status={
                  (datePickerTouched || submitCount > 0) && !dates && "error"
                }
                error={
                  (datePickerTouched || submitCount > 0) &&
                  !dates &&
                  t("Required")
                }
                onBlur={() => {
                  setDatePickerTouched(true)
                }}
              />
              <p className={"date-picker-label"} style={{ minWidth: "40px" }}>
                {"年月"}
              </p>
            </ContractPaymentOrangeDatePicker>
          </div>
          <TableWrapper>
            <OwnerTable
              className={"joint-facility-table"}
              id={"popup-container-facility-table"}
              dataSource={allJointFacilities}
              columns={JOINT_FACILITY_TABLE_COLUMN}
              scroll={{ x: 1000 }}
            />
          </TableWrapper>
          <div
            className={"item flex flex-end"}
            style={{ marginBottom: "10px" }}
          >
            <OwnerButton
              typeOf={"primary"}
              className={"save-button"}
              text={t("Add")}
              onClick={handleAddJointFacilities}
            />
          </div>
        </ContractPaymentModalWrapper>
      </Spin>
    </Modal>
  )
}

export { JointCompanyRegistrationCompanyModal }
