import { Table, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { RecurringScheduleCalendarComponent } from "../RecurringScheduleCalendarComponent"

const CalendarWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  margin: 14px 0;
  ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${theme.bgColor2};
  }
  .ant-table-cell {
    padding: 0;
    border: 1px solid ${theme.gray2};
    background: ${theme.bgColor2};
    vertical-align: initial;
  }
`

const WeekInfoContainer = styled.div`
  height: 100%;
  min-width: 150px;
  text-align: center;
`

const ScheduleWrapper = styled.div`
  height: 100%;
  min-width: 150px;
`

export const RecurringScheduleCalendar = ({
  facilities,
  data,
  onUpdate,
  facilityActivities,
  isCDSChild,
}) => {
  const { t } = useTranslation()

  const renderDaySchedule = (obj) => (
    <ScheduleWrapper>
      {obj && (
        <RecurringScheduleCalendarComponent
          facilities={facilities}
          data={obj}
          onUpdate={onUpdate}
          facilityActivities={facilityActivities}
          isCDSChild={isCDSChild}
        />
      )}
    </ScheduleWrapper>
  )

  const headers = [
    {
      key: "week",
      title: "",
      dataIndex: "week",
      render: (text) => <WeekInfoContainer>{text}</WeekInfoContainer>,
    },
    {
      key: "sun",
      title: t("Sun"),
      dataIndex: "sun",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "mon",
      title: t("Mon"),
      dataIndex: "mon",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "tue",
      title: t("Tue"),
      dataIndex: "tue",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "wed",
      title: t("Wed"),
      dataIndex: "wed",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "thu",
      title: t("Thu"),
      dataIndex: "thu",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "fri",
      title: t("Fri"),
      dataIndex: "fri",
      render: (text) => renderDaySchedule(text),
    },
    {
      key: "sat",
      title: t("Sat"),
      dataIndex: "sat",
      render: (text) => renderDaySchedule(text),
    },
  ]

  return (
    <CalendarWrapper>
      <Table
        style={{ width: "100%" }}
        columns={headers}
        dataSource={data}
        bordered={true}
      />
    </CalendarWrapper>
  )
}
