import { API } from "@project/shared"
export interface MonthsStats {
  current_month: Array<any>
  current_month_total?: number
  last_month: Array<any>
  last_month_total?: number
}
export interface TopPageResponse {
  applicationStatusYTD: number
  applicationStatus: MonthsStats
  capacity: {
    high: number
    low: number
  }
  commuterCount: number
  registeredCount: number
  revenue: {
    salesAmount: number
    salesForecastAmount: number
    usageRate: number
  }
  usersRenewBeneficiaryCount: number
  totalRegisteredCount: number
  usersTodayCount: number
  usersTotalBeneficiaryCount: number
  usersUpdatePlanCount: number
  carInspections: []
  cancelWaitingToday: string
  appliedToday: any
}
export interface FetchTopPageResponse {
  data: TopPageResponse
}
export const fetchTopPageData = (facilityId: number) =>
  facilityId && API.get(`/top-page/${facilityId}`)
