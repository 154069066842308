const PrintIconBlack = () => (
  <svg
    width={"19"}
    height={"18"}
    viewBox={"0 0 19 18"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={"M17.8889 9V17H1V9"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M14.3325 1H4.55469V13.4444H14.3325V1Z"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.22266 3.6665H11.6671"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.22266 6.3335H11.6671"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.22266 9H11.6671"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default PrintIconBlack
