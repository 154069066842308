import React from "react"
import { UpOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import { Collapse, Typography } from "antd"
import styled from "styled-components"
import Link from "next/link"
const Wrapper = styled(Collapse)<any>`
  border: 1px solid ${theme.red1};
  border-radius: 0.5rem;
  background-color: transparent !important;
  .title {
    display: flex;
    width: 40px !important;
    margin-right: 0.6rem !important;
    img {
      margin-right: 0.3rem;
    }
    svg {
      font-size: 13px;
      color: ${theme.red3};
    }
  }
  .panel_title {
    text-decoration: ${({ underline }) => (underline ? "underline" : "normal")};
    color: ${theme.red3};
    font-size: 18px;
  }
  .sub {
    color: ${theme.red3};
    font-size: 16px;
  }
  ul {
    color: ${theme.red3};
    padding: 0 1.5rem;
  }

  .cta {
    width: fit-content;
    .button {
      img {
        margin-top: -4px !important;
        margin-right: 0.3rem;
      }
      span {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 100% !important;
    .panel_title {
      font-size: 14px !important;
    }
    .ant-collapse-header,
    .ant-collapse-extra {
      flex-direction: column;
      row-gap: 0.5rem;
      margin-left: 0 !important;
    }
    ul {
      li {
        font-size: 12px !important;
      }
    }
    .cta {
      .button {
        height: fit-content;
        span {
          font-size: 12px !important;
        }
      }
    }
  }
`

type LType = {
  name: string
  link?: string
}
type InfoTextBoxType = {
  title: string
  subTitle?: string
  list?: LType[] | []
  showBtn?: boolean
  underline?: boolean
  iconType?: "solid" | "outlined"
}

const InfoTextBox: React.FC<InfoTextBoxType> = ({
  title,
  subTitle,
  list,
  underline,
  iconType = "solid",
}): JSX.Element => {
  return (
    <Wrapper
      underline={underline}
      bordered={false}
      ghost
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <div className={"title"}>
          {iconType === "solid" ? (
            <img src={"/assets/icons/exclamation.png"} alt={""} />
          ) : (
            ""
          )}
          <UpOutlined rotate={isActive ? 180 : 0} />
        </div>
      )}
    >
      <Collapse.Panel
        header={
          <Typography.Text className={"panel_title"}>{title}</Typography.Text>
        }
        key={"1"}
      >
        <>
          {" "}
          <Typography.Text className={"sub"}>{subTitle}</Typography.Text>
          <ul className={"list"}>
            {list?.map((val, index) => (
              <li
                key={index}
                onClick={() => (val?.link ? null : "null")}
                style={{
                  cursor: val?.link ? "pointer" : "auto",
                  textDecoration: val?.link ? "underline" : "",
                }}
              >
                <Link href={val?.link}>
                  <a>{val?.name}</a>
                </Link>
              </li>
            ))}
          </ul>
        </>
      </Collapse.Panel>
    </Wrapper>
  )
}

export default InfoTextBox
