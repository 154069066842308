import { TextField, Grid, PageCard, Required, theme } from "@project/shared"
import { notification, Popconfirm, Skeleton } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import {
  createConsultationSupportOffice,
  deleteConsultationSupportOffice,
  getConsultationSupportOffice,
  updateConsultationSupportOffice,
} from "../../../services"
import {
  AuthContext,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms"
import { realTimeValidate } from "../../../utils/validation"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"
import { ConsultationSupportMasterPermission } from "../../../utils/PermissionKeys"

interface iAddConsultationSupportOfficeForm {
  id?: string
}

export const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444748;
  margin-top: 9px;
`
const Container = styled.div`
  @media screen and (max-width: 426px) {
    .title-container {
      border-top: 1px solid #d2d1d1 !important;
    }
  }

  @media screen and (max-width: 1025px) {
    .support_business {
      width: 100%;
    }
  }
`

const HorizontalInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .info {
    margin-left: 21px;
    margin-top: 0;
  }
  input {
    width: 240px;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
    .info {
      margin-left: 0;
      margin-top: 9px;
    }
    .half-field {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
`

const ButtonWrapperWithDeleteButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  .reset-btn {
    margin-right: 10px;
  }
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }
  @media (max-width: 767px) {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .reset-btn {
      margin-right: 10px;
    }
  }
`

const AddConsultationSupportOfficeForm: React.FC<
  iAddConsultationSupportOfficeForm
> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { permissions, isOwner } = useContext(AuthContext)

  const hasDeleteAccess = useMemo(() => {
    return (
      isOwner ||
      hasPermissionForMenu(
        permissions,
        ConsultationSupportMasterPermission,
        "write"
      )
    )
  }, [permissions, isOwner])

  const {
    data: consultationSupportOfficeData,
    isLoading: consultationSupportOfficeLoading,
    isFetching: consultationSupportOfficeFetching,
  } = useQuery<any, Error>(
    ["consultationSupportOffice", id],
    () => id && getConsultationSupportOffice(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: data.id,
          consultation_support_name: data.consultation_support_name,
          consultation_support_name_furigana:
            data.consultation_support_name_furigana,
          consultation_support_number: data.consultation_support_number,
          address: data.address,
          tel_no: data.tel_no,
          fax_no: data.fax_no,
        }
      },
    }
  )

  const { mutate: mutateConsulationSupport, isLoading } = useMutation(
    id ? updateConsultationSupportOffice : createConsultationSupportOffice,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t(
              "Consultation support office master information has been updated."
            ),
          })
        } else {
          notification.success({
            message: t(
              "Consultation support office master information has been added"
            ),
          })
        }
        router.push("/consultation-support-office")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { mutate: deleteConsulationSupport, isLoading: isDeleting } =
    useMutation(deleteConsultationSupportOffice, {
      onSuccess: () => {
        notification.success({
          message: t(
            "Consultation support office master information has been deleted."
          ),
        })

        router.push("/consultation-support-office")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    })

  const initialValues = consultationSupportOfficeData || {
    consultation_support_name: "",
    consultation_support_name_furigana: "",
    consultation_support_number: "",
    address: "",
    tel_no: "",
    fax_no: "",
  }

  const PHONE_NO_REGEX = /^(?=.*[0-9])[-0-9]+$/

  const handleNumberOnly = (fieldName: string, value: string) => {
    const regex = /^\d+$/
    if (value === "" || regex.test(value)) {
      formik.setFieldValue(fieldName, value)
    }
  }

  const handleNumberAndHyphens = (fieldName: string, value: string) => {
    const regex = /^(?=.*[0-9])[-0-9]+$/
    if (value === "" || regex.test(value)) {
      formik.setFieldValue(fieldName, value)
    }
  }

  const validationSchema = yup.object().shape({
    consultation_support_name: yup
      .string()
      .max(50, t("(Within 50 char)"))
      .required(t("Please enter")),
    consultation_support_name_furigana: yup
      .string()
      .max(100, t("(Within 100 char)"))
      .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect")),
    consultation_support_number: yup.string().max(30, t("(Within 30 char)")),
    address: yup.string(),
    tel_no: yup
      .string()
      .max(50, t("(Within 50 char)"))
      .matches(PHONE_NO_REGEX, {
        message: t("(Max 50 with num & hyphens)"),
        excludeEmptyString: true,
      })
      .test(
        "max-hyphens",
        t("Maximum of 3 hyphens allowed"),
        (value) => !value || value.split("-").length < 5
      ),
    fax_no: yup
      .string()
      .max(50, t("(Within 50 char)"))
      .matches(PHONE_NO_REGEX, {
        message: t("(Max 50 with num & hyphens)"),
        excludeEmptyString: true,
      })
      .test(
        "max-hyphens",
        t("Maximum of 3 hyphens allowed"),
        (value) => !value || value.split("-").length < 5
      ),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.consultation_support_number = values?.consultation_support_number
        ? "" + values?.consultation_support_number
        : ""
      mutateConsulationSupport(values)
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  return (
    <Container>
      <PageCard
        title={t("Consultation support office master form")}
        style={{ marginTop: "22px", padding: "12px 0px" }}
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          {consultationSupportOfficeFetching ||
          consultationSupportOfficeLoading ? (
            <Skeleton />
          ) : (
            <>
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Consultation support office name")}
                  </div>
                  <Required />
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"consultation_support_name"}
                  className={getErrorClass(
                    "consultation_support_name",
                    "input"
                  )}
                  height={"50px"}
                  value={formik.values.consultation_support_name}
                  onChange={formik.handleChange}
                  maxLength={50}
                  error={
                    formik.touched.consultation_support_name &&
                    formik.errors.consultation_support_name
                  }
                />
                <Info className={"info"}>{t("(Within 50 char)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Hiragana")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"consultation_support_name_furigana"}
                  height={"50px"}
                  value={formik.values.consultation_support_name_furigana}
                  onChange={(e) => realTimeValidate(e, formik)}
                  maxLength={100}
                  error={
                    formik.touched.consultation_support_name_furigana &&
                    formik.errors.consultation_support_name_furigana
                  }
                />
                <Info className={"info"}>{t("(Within 100 char)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Consultation support \nbusiness number")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"consultation_support_number"}
                    className={
                      (getErrorClass("consultation_support_number", "input"),
                      "support_business")
                    }
                    min={0}
                    value={formik.values.consultation_support_number}
                    onChange={(e) => {
                      handleNumberOnly(
                        "consultation_support_number",
                        e.target.value
                      )
                    }}
                    maxLength={30}
                    placeholder={t("Example：0123456789")}
                    error={
                      formik.touched.consultation_support_number &&
                      formik.errors.consultation_support_number
                    }
                  />
                  <Info className={"info"}>{t("(Max 30 num)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Address")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"address"}
                  height={"50px"}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  maxLength={250}
                  placeholder={t("Example：□□市△△番地 1-23")}
                  error={formik.touched.address && formik.errors.address}
                />
                <Info className={"info"}>{t("(Within 250 char)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Phone number")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"tel_no"}
                    height={"50px"}
                    value={formik.values.tel_no}
                    onChange={(e) => {
                      handleNumberAndHyphens("tel_no", e.target.value)
                    }}
                    maxLength={50}
                    placeholder={t("Example：012-345-6789")}
                    className={"half-field"}
                    error={formik.touched.tel_no && formik.errors.tel_no}
                  />
                  <Info className={"info"}>
                    {t("(Max 50 with num & hyphens)")}
                  </Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("FAX number")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"fax_no"}
                    value={formik.values.fax_no}
                    onChange={(e) => {
                      handleNumberAndHyphens("fax_no", e.target.value)
                    }}
                    maxLength={50}
                    placeholder={t("Example：012-345-6789")}
                    className={"half-field"}
                    error={formik.touched.fax_no && formik.errors.fax_no}
                  />
                  <Info className={"info"}>
                    {t("(Max 50 with num & hyphens)")}
                  </Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <ButtonWrapperWithDeleteButton>
                <ButtonWrapper>
                  <OwnerButton
                    typeOf={"secondary"}
                    className={"reset-btn"}
                    text={t("Cancel")}
                    onClick={() => router.back()}
                  />
                  <OwnerButton
                    typeOf={"primary"}
                    onClick={() => undefined}
                    type={"submit"}
                    text={t("Save")}
                    isLoading={isLoading}
                  />
                </ButtonWrapper>
                {id && hasDeleteAccess && (
                  <ButtonWrapper>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      onConfirm={() => deleteConsulationSupport(id)}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <OwnerButton
                        typeOf={"primary"}
                        text={t("Delete ")}
                        className={"delete-button"}
                        isLoading={isDeleting}
                      />
                    </Popconfirm>
                  </ButtonWrapper>
                )}
              </ButtonWrapperWithDeleteButton>
            </>
          )}
        </StyledForm>
      </PageCard>
    </Container>
  )
}

export { AddConsultationSupportOfficeForm }
