export const months = [
  "1日",
  "2日",
  "3日",
  "4日",
  "5日",
  "6日",
  "7日",
  "8日",
  "9日",
  "10日",
  "11日",
  "12日",
  "13日",
  "14日",
  "15日",
  "16日",
  "17日",
  "18日",
  "19日",
  "20日",
  "21日",
  "22日",
  "23日",
  "24日",
  "25日",
  "26日",
  "27日",
  "28日",
  "29日",
  "30日",
  "31日",
]

// ! [NOTE: THIS IS A DATATYPE AND ITS ABSOLUTE]
// ! This data type as reference to send data from backend.
export type Datatype = {
  actualDate: string
  totalDays: number
  totalNumberOfUsers?: [number, number, number]
  numberOfDaysTheFacilityIsOpen?: [number, number, number]
  key: string | number
  [key: string]: [number, number, number] | string | number | number[]
}[]

export const dynamicMonthHeadCol = () => {
  return months.map((month) => {
    return {
      title: () => {
        if (month.length === 3) {
          return (
            <>
              <span className={"table-cell__month-text"}>
                {month[0] + month[1]}
              </span>
              <span className={"table-cell__month-text"}>{month[2]}</span>
            </>
          )
        }
        return (
          <>
            <span className={"table-cell__month-text"}>{month[0]}</span>
            <span className={"table-cell__month-text"}>{month[1]}</span>
          </>
        )
      },
      dataIndex: month,
      key: `day${month}`,
      className: `table-monthly-column table-column`,
      width: 25,
    }
  })
}
