// utils
import { Trans } from "react-i18next"

// This is a main text that appears on the top of info box component
export const InfoBoxMainText = ({ billingMethod }) => {
  return (
    <>
      <p className={"info-text"}>
        <Trans i18nKey={"currentBillingMethodSetAs"} method={billingMethod}>
          {"It is set to bill the National Health Insurance Federation with"}
          <span className={"red-text"}>{{ method: billingMethod } as any}</span>
        </Trans>
      </p>
    </>
  )
}

export { BulkPeriodSettingOpSection } from "./BulkPeriodSettingOpSection"
export { UsageRecordRewriteFormBlock } from "./UsageRecordRewriteFormBlock"
export { IncrementalUsageRecordForm } from "./IncrementalUsageRecordForm"
