import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import {
  Checkbox,
  Checkboxes,
  PageCard,
  SelectInput,
  setItemToLocalstorage,
  theme,
} from "@project/shared"
import { AuthContext, getYearRange } from "../../../utils"
import { OwnerButton } from "../../atoms"
import { RightOutlined } from "@ant-design/icons"
import { useRemunerationCalculationContext } from "../../../context/RemunerationCalculationContext"
import { DatePicker } from "../../molecules"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
//styles start
const Flex = css`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  @media print {
    visibility: hidden;
  }
`
const FilterContainer = styled.div`
  .title {
    border-left: 3px solid ${theme.blue5};
    padding-left: 4px;
  }
  .filter_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 426px) {
      display: grid;
    }
  }
  .facilities_filter,
  .date_filter {
    .text {
      min-width: 160px;
      margin-top: 8px;
      .icon {
        color: ${theme.gray2};
        height: 8.21px;
        margin-right: 10px;
      }
    }
    .fiscal-year-text {
      min-width: 160px;
      align-self: center;
      @media (max-width: ${theme.breakpoints.md}) {
        align-self: initial;
      }
      .icon {
        color: ${theme.gray2};
        height: 8.21px;
        margin-right: 10px;
      }
    }
  }
  display: flex;
  .facilities_filter {
    display: flex;
    .btns_checkboxs {
      .btns {
        ${Flex};
      }
      .facilities_checkboxs {
        ${Flex};
        flex-wrap: wrap;
        margin-top: 20px;

        @media screen and (max-width: 426px) {
          label {
            gap: 0;
            > span:last-child {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      display: grid;
      gap: 10px;
      .btns {
        gap: 0;
        button {
          padding: 6px 10px !important;
        }
        button:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  .date_filter {
    display: flex;
    @media screen and (max-width: 426px) {
      display: grid;
    }
    .year_selector_btn {
      ${Flex}
      gap:27px;
      @media screen and (max-width: 426px) {
        gap: 0;
        button {
          margin-left: 27px;
        }
        label > span:last-child {
          word-break: keep-all;
        }
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      gap: 10px;
    }
    .flex-col {
      flex-direction: column;
    }
  }
`
//style end
interface Iprops {
  isPrintPage?: boolean
  link?: string
  fiscalYear?: number
  setFiscalYear?: (value: number) => void
}
export const RemunerationCalculationFilter: React.FC<Iprops> = ({
  isPrintPage,
  link,
  fiscalYear,
  setFiscalYear,
}) => {
  const { t } = useTranslation()
  const { defaultFacility, searchParams, onFilter, setIsQueryChanged } =
    useRemunerationCalculationContext()
  const [updateParams] = useUpdateSearchParams()

  const { facilities } = useContext(AuthContext)
  const [isCheckAll, setIsCheckAll] = useState(
    defaultFacility?.length ? true : false
  )

  const [fiscalMonth, setFiscalMonth] = useState(
    searchParams?.fiscal_month || new Date().getMonth() + 1
  )
  const [fiscalDay, setFiscalDay] = useState(
    searchParams?.fiscal_day || new Date().getDate()
  )
  const [checkedFacility, setCheckedFacility] = useState(defaultFacility || [])
  const [tempCheckedFacility, setTempCheckedFacility] = useState([])
  const [serviceType, setServiceType] = useState("1,2")
  const [printConfig, setPrintConfig] = useState(
    searchParams?.print_config || ""
  )
  useEffect(
    () => setFiscalYear(searchParams?.fiscal_year),
    [searchParams?.fiscal_year]
  )
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target
    if (!checked) {
      setCheckedFacility(checkedFacility.filter((item) => item !== value))
    } else {
      setCheckedFacility([...checkedFacility, value])
      setTempCheckedFacility([...checkedFacility, value])
    }
  }

  const renderDisplayChangeBtn = () => {
    return (
      <OwnerButton
        text={t("Change display")}
        typeOf={"secondary"}
        onClick={() => {
          const url = link || "/remuneration-calculation"
          const facility = checkedFacility?.length
            ? checkedFacility
            : tempCheckedFacility

          onFilter({
            facility: facility,
            fiscal_year: fiscalYear,
            fiscal_month: fiscalMonth,
            fiscal_day: fiscalDay,
            service_type: serviceType,
            print_config: printConfig,
            tempFacilities: facility?.map((f) =>
              facilities?.find((v) => v.id === f)
            ),
          })
          setIsQueryChanged(true)
          updateParams(
            {
              facility: facility?.toString(),
              fiscal_year: fiscalYear,
              fiscal_month: fiscalMonth,
              fiscal_day: fiscalDay,
              service_type: serviceType?.toString(),
              print_config: printConfig.toString(),
            },
            url
          )
          setItemToLocalstorage("remuneration-calculation", {
            facility_ids: facility?.toString(),
          })
        }}
      />
    )
  }

  return (
    <Wrapper>
      <PageCard title={t("Operation options")}>
        <FilterContainer>
          <div className={"filter_content"}>
            <div className={"title"}>{t("Narrow down")}</div>
            <div className={"facilities_filter"}>
              <div className={"text"}>
                <RightOutlined className={"icon"} />
                {t("Facility")}
              </div>
              <div className={"btns_checkboxs"}>
                <div className={"btns"}>
                  <OwnerButton
                    text={t("Check all")}
                    typeOf={"check-all"}
                    onClick={() => {
                      setIsCheckAll(true)
                      setCheckedFacility(facilities?.map((li) => li.id))
                      setTempCheckedFacility(facilities?.map((li) => li.id))
                    }}
                  />
                  <OwnerButton
                    text={t("Uncheck all")}
                    typeOf={"secondary"}
                    onClick={() => {
                      setCheckedFacility([])
                      setIsCheckAll(false)
                    }}
                    disabled={!checkedFacility?.length && !isCheckAll}
                  />
                </div>
                <div className={"facilities_checkboxs"}>
                  {facilities?.map((facility, i) => (
                    <Checkbox
                      key={i}
                      onChange={onFacilityChecked}
                      checked={checkedFacility?.includes(facility?.id)}
                      value={facility?.id || ""}
                      label={facility?.facility_name_short}
                    />
                  ))}
                </div>
              </div>
            </div>
            {isPrintPage ? (
              <>
                <div className={"date_filter used_service"}>
                  <div className={"fiscal-year-text"}>
                    <RightOutlined className={"icon"} />
                    {isPrintPage ? t("Use service") : t("Service used")}
                  </div>
                  <div className={"year_selector_btn"}>
                    <Checkboxes
                      value={serviceType}
                      options={[
                        {
                          label: t("After school day service"),
                          value: "1",
                        },
                        {
                          label: t("Child development support"),
                          value: "2",
                        },
                      ]}
                      onChange={(value) => {
                        setServiceType(value)
                      }}
                    />
                  </div>
                </div>
                <div className={"date_filter print_config"}>
                  <div className={"fiscal-year-text"}>
                    <RightOutlined className={"icon"} />
                    {isPrintPage
                      ? t("Stamp settings")
                      : t("Print configuration")}
                  </div>
                  <div className={"year_selector_btn"}>
                    <Checkboxes
                      value={printConfig}
                      options={[
                        {
                          label: t("leave the date blank"),
                          value: "1",
                        },
                        {
                          label: t("Print services not in use"),
                          value: "2",
                        },
                      ]}
                      onChange={(value) => {
                        setPrintConfig(value)
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className={"date_filter"}>
              <div className={"fiscal-year-text"}>
                <RightOutlined className={"icon"} />
                {isPrintPage ? t("Date") : t("Fiscal year")}
              </div>
              <div className={`year_selector_btn`}>
                {isPrintPage ? (
                  <DatePicker
                    picker={"date"}
                    format={"YYYY年MM月DD日"}
                    date={
                      moment(`${fiscalYear}-${fiscalMonth}`).isValid()
                        ? moment(`${fiscalYear}-${fiscalMonth}-${fiscalDay}`)
                        : null
                    }
                    onDateChange={(value) => {
                      setFiscalYear(+moment(value).format("YYYY"))
                      setFiscalMonth(+moment(value).format("M"))
                      setFiscalDay(+moment(value).format("DD"))
                    }}
                    style={{
                      height: "40px",
                      width: "200px",
                    }}
                  />
                ) : (
                  <SelectInput
                    width={80}
                    height={40}
                    options={getYearRange(2, 3)}
                    className={"select-down"}
                    value={fiscalYear}
                    placeholder={"---"}
                    onChange={(val) => setFiscalYear(val)}
                  />
                )}

                {!isPrintPage && renderDisplayChangeBtn()}
              </div>
            </div>
            {isPrintPage && (
              <div style={{ width: "fit-content" }}>
                {renderDisplayChangeBtn()}
              </div>
            )}
          </div>
        </FilterContainer>
      </PageCard>
    </Wrapper>
  )
}
