import {
  dynamicLangString,
  Grid,
  PageCard,
  Required,
  TextField,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Skeleton } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { useMutation, useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import {
  Divisor,
  FormTitle,
  HorizontalInfo,
  Info,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import {
  createProvisionalCity,
  getProvisionalCity,
  updateProvisionalCity,
} from "../../../services"
import styled from "styled-components"
import japanPostalCode from "japan-postal-code"

interface iProvisionalCityForm {
  id?: number
  handleDelete?: () => void
  showDeleteButton?: boolean
  isDeleteLoading?: boolean
}

const Container = styled.div`
  .title-container {
    border-top: 1px solid #d2d2d2 !important;
  }

  .button-wrapper {
    @media screen and (max-width: 761px) {
      margin-top: 16px !important;
    }
  }

  @media screen and (max-width: 1025px) {
    .municipality {
      width: 100%;
    }
  }

  .contract-report {
    margin-top: 43px;

    @media screen and (max-width: 426px) {
      margin-top: 5px !important;
    }
  }
  .provision-cities-button-wrapper {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .cancel-btn {
      margin-right: 20px;
    }
  }

  .provision-cities-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }
`

const ProvisionalCityForm: React.FC<iProvisionalCityForm> = ({
  id,
  handleDelete,
  showDeleteButton = false,
  isDeleteLoading = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    data: provisionCity,
    isLoading: provisionCityLoading,
    isFetching: provisionCityFetching,
  } = useQuery<any, Error>(
    ["provisionalCity", id],
    () => id && getProvisionalCity(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return data
      },
    }
  )

  const { mutate: mutateProvisionalCity, isLoading } = useMutation(
    id ? updateProvisionalCity : createProvisionalCity,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Provision city",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Provision city",
              "Created Successfully",
            ]),
          })
        }
        router.push("/provisional-cities")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const initialValues = provisionCity || {
    provision_city_name: "",
    municipality_number: "",
    subsidy_municipality_number: "",
    mayor_name: "",
    zip_code: "",
    address: "",
  }

  const validationSchema = yup.object().shape({
    provision_city_name: yup.string().required(t("Required")),
    municipality_number: yup
      .string()
      .required(t("Required"))
      .matches(/^\d*$/, t("(Within 6 numbers)")),
    subsidy_municipality_number: yup
      .string()
      .max(6)
      .matches(/^\d*$/, t("(Within 6 numbers)")),
    mayor_name: yup.string().max(50),
    zip_code: yup
      .string()
      .max(8)
      .matches(/^[0-9 -]+$/, t("(Within 8 number & hyphens)")),
    address: yup.string().max(200),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      mutateProvisionalCity(value)
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const setAddressFromPostCode = async () => {
    japanPostalCode.get(formik?.values?.zip_code, function (address) {
      formik.setFieldValue("address", address?.prefecture + address?.city)
    })
  }

  return (
    <Container>
      <PageCard title={t("City information")} style={{ marginTop: "22px" }}>
        {provisionCityLoading || provisionCityFetching ? (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Skeleton />
          </StyledForm>
        ) : (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Provision city name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"provision_city_name"}
                height={"50px"}
                className={getErrorClass("provision_city_name", "input")}
                value={formik.values.provision_city_name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.provision_city_name}
                maxLength={50}
                placeholder={t("例：西尾市")}
              />
              <Info className={"info"}>{t("(within 50 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header title-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("City number")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"municipality_number"}
                  height={"50px"}
                  className={
                    (getErrorClass("municipality_number", "input"),
                    "municipality")
                  }
                  value={formik.values.municipality_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.municipality_number}
                  maxLength={6}
                  placeholder={t("例：123456")}
                />
                <Info className={"info"}>{t("(Within 6 numbers)")}</Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header title-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Grant municipality number")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"subsidy_municipality_number"}
                  height={"50px"}
                  value={formik.values.subsidy_municipality_number}
                  onChange={formik.handleChange}
                  maxLength={6}
                  placeholder={t("例：123456")}
                  error={formik.errors.subsidy_municipality_number}
                  className={"half-field"}
                />
                <Info className={"info"}>{t("(Within 6 numbers)")}</Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />
            <FormTitle className={"contract-report"}>
              {t("Contract report information")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header border-top"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Mayor name")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"mayor_name"}
                  height={"50px"}
                  value={formik.values.mayor_name}
                  onChange={formik.handleChange}
                  error={formik.errors.mayor_name}
                  maxLength={50}
                  placeholder={t("例：山田太郎")}
                />
                <Info className={"info"}>{t("(within 50 char)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header title-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Po. Box no.")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"zip_code"}
                    height={"50px"}
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    error={formik.errors.zip_code}
                    maxLength={8}
                    placeholder={t("例：123-4567")}
                    className={"half-field"}
                  />
                  <Info className={"info"}>
                    {t("(Within 8 number & hyphens)")}
                  </Info>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <OwnerButton
                    typeOf={"secondary"}
                    onClick={setAddressFromPostCode}
                    className={"cancel-btn"}
                    text={t("Autofill address")}
                    disabled={!formik.values.zip_code}
                  />
                </HorizontalInfo>
                <Info style={{ textDecoration: "underline", color: "#0782C8" }}>
                  <a
                    href={"https://www.post.japanpost.jp/zipcode/"}
                    target={"_blank"}
                  >
                    {t("※If you don't know your pobox,search here")}
                  </a>
                </Info>
              </Grid>
              <Grid className={"flex-break title-container"} />
              <Grid className={"grid-header"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Address")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"address"}
                  height={"50px"}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  maxLength={200}
                  placeholder={t("例：○○県□□市△△ 1-23")}
                />
                <Info className={"info"}>{t("(200文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <div className={"provision-cities-button-container"}>
              <div className={"provision-cities-button-wrapper"}>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={() => router.back()}
                  className={"cancel-btn"}
                  text={t("Cancel")}
                  disabled={isLoading}
                />

                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  disabled={isLoading}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isLoading}
                />
              </div>
              {showDeleteButton ? (
                <>
                  <div className={"provision-cities-button-wrapper"}>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      onConfirm={handleDelete}
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <OwnerButton
                        text={t("Delete ")}
                        typeOf={"primary"}
                        className={"delete-button"}
                        isLoading={isDeleteLoading}
                      />
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </StyledForm>
        )}
      </PageCard>
    </Container>
  )
}

export { ProvisionalCityForm }
