import styled, { css } from "styled-components"
import { theme } from "@project/shared"

const TITTLE = css`
  border-left: 3px solid ${theme.blue5};
  padding-left: 8px;
`
const SVG = css`
  color: ${theme.gray2};
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
`
export const Wrapper = styled.div`
  .filter__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    @media screen and (max-width: 426px) {
      display: grid;
    }
    .title {
      ${TITTLE}
    }
    .filter__box {
      display: flex;
      @media screen and (max-width: 426px) {
        display: grid;
      }
      &--label {
        min-width: 150px;
        svg {
          ${SVG}
        }
      }
      &--value {
        .check_uncheck_all {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          @media screen and (max-width: 426px) {
            gap: 0;
            button:last-child {
              margin-left: 6px;
            }
          }
        }
        .value__content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          @media screen and (max-width: 426px) {
            display: grid;
          }
          .date_box {
            display: flex;
            gap: 1rem;

            @media screen and (max-width: 426px) {
              gap: 0;

              button {
                margin-left: 6px;
              }
              .faculty-datepicker {
                width: 60%;
              }
              .update-display {
                width: 40%;
                padding: 10px 10px;
                @media screen and (max-width: 367px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .checkboxs {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          @media (max-width: ${theme.breakpoints.md}) {
            gap: 0;
            .ant-checkbox-wrapper {
              margin-right: 10px;

              span {
                line-height: 34px;
              }
            }
          }
        }
        .break-word {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            label {
              gap: 0;
              .ant-checkbox + span {
                white-space: pre-line;
                word-break: break-all;
                padding-left: 10px;
              }
            }
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        gap: 1rem;
      }
    }

    .bulk_setting__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      .bulk_setting__content {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        gap: 0.5rem;
        .top {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: fit-content;
          white-space: nowrap;
          .icon_text {
            display: flex;
            align-items: center;
            .text {
              margin-right: 0.8rem;
            }
            svg {
              ${SVG}
            }
          }
          .condition {
            white-space: nowrap;
          }
        }
        .mini-flex {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          @media screen and (max-width: 426px) {
            display: grid;
          }
        }
        .time__picker {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          @media screen and (max-width: 426px) {
            display: grid;
          }
          .time__picker--content {
            display: flex;
            align-items: center;
            column-gap: 20px;
            @media screen and (max-width: 426px) {
              display: grid;
            }
            .text_icon {
              display: flex;
              align-items: center;
              svg {
                ${SVG}
              }
              .text {
                width: 6rem;
                font-size: 15px;
              }
              .text-mt {
                width: fit-content;
                margin-right: 0.4rem;
                font-size: 15px;
                white-space: nowrap;
              }
            }
            .inputs {
              display: flex;
              align-items: center;
              gap: 0.2rem;
              width: 100%;

              .input__select {
                width: 60px;
              }

              .input__select--long {
                width: 130px;
              }
              @media screen and (max-width: 426px) {
                b {
                  margin-left: 4px;
                }
                .input__select:last-child {
                  margin-left: 4px;
                }
                .input__select--long:last-child {
                  margin-left: 4px;
                }
              }
            }
            .inputs__place {
              display: flex;
              gap: 0.2rem;
              width: 100%;
              .input__select--long {
                width: 130px;
              }
              flex-direction: column !important;
            }
          }
        }

        @media screen and (max-width: ${theme.breakpoints.lg}) {
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 1rem;
        }
        @media screen and (max-width: ${theme.breakpoints.md}) {
          flex-direction: column;
          .time__picker {
            .time__picker--content {
              .text_icon {
                .text {
                  width: 80%;
                }
              }
              .inputs {
                width: 100%;
                .input__select {
                  width: 80px;
                }
              }
            }
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          flex-direction: column;
          .time__picker {
            width: 100%;
            .time__picker--content {
              flex-direction: column;
              align-items: flex-start;
              gap: 0.5rem;
              .text_icon {
                .text {
                  width: 100%;
                }
              }
              .inputs__place {
                .input__select--long {
                  width: 100%;
                }
              }
              .select_first {
                .ant-select {
                  width: 100%;
                }
              }
              .inputs {
                width: 100%;
                .input__select--long {
                  width: 100%;
                }
                .input__select {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
    .filter__container--bottom {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      align-items: center;
      @media screen and (max-width: 426px) {
        gap: 0;
        button {
          margin-left: 6px;
        }
      }
    }
  }
`
