import React from "react"
import {
  Wrapper,
  NBLInfoContent,
  NBLMiddleInfoContent,
} from "./../../PrintListContent.style"

import { t } from "i18next"
import {
  ReceiptSiblingUpperLimitManagement,
  SiblingChild,
} from "../../../../types"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../constants"
import { YearMonth } from "../../common/header/YearMonth"
import { ManagementFacility } from "../../common/header/ManagementFacility"
import { DefaultUpperMonthLimit } from "../../common/header/upperMonthLimit/DefaultUpperMonthLimit"
import { DefaultUpperLimitManagementResult } from "../../common/header/upperLimitManagementResult/DefaultUpperLimitManagementResult"
import { DefaultSignature } from "../../common/footer/signature/DefaultSignature"
import { KisarazuSheetTitle } from "../header/sheetTitle/KisarazuSheetTitle"
import { KisarazuChildDetail } from "../header/childDetail/KisarazuChildDetail"
import { KisarazuFormat } from "../body/KisarazuFormat"

type Props = {
  receiptSiblingUpperLimitManagement: ReceiptSiblingUpperLimitManagement
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function KisarazuFlame({
  receiptSiblingUpperLimitManagement,
  siblingChildren,
  printConfig = [],
}: Props) {
  return (
    <Wrapper>
      {printConfig.includes(
        PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER
      ) && <small>{t("(Form 2)")}</small>}

      {/* --- Start Header */}
      <KisarazuSheetTitle />
      <YearMonth
        year={receiptSiblingUpperLimitManagement.year}
        month={receiptSiblingUpperLimitManagement.month}
      />
      <NBLInfoContent length={1}>
        <div className={"top__table_content"}>
          <KisarazuChildDetail
            municipalityNumber={
              receiptSiblingUpperLimitManagement.municipality_number
            }
            siblingChildren={siblingChildren}
            parentName={receiptSiblingUpperLimitManagement.parent_name}
            printConfig={printConfig}
          />
        </div>
        <div className={"top__table_content"}>
          <ManagementFacility
            facilityName={receiptSiblingUpperLimitManagement.facility_name}
            facilityNumber={receiptSiblingUpperLimitManagement.facility_number}
          />
        </div>
      </NBLInfoContent>
      <NBLMiddleInfoContent>
        <div className={"NBLMiddleInfoContent"}>
          <div className={"nbl_info_top_content"}>
            <DefaultUpperMonthLimit
              burdenMaxMonth={
                receiptSiblingUpperLimitManagement.burden_max_month
              }
            />
          </div>
          <div className={"nbl_info_bottom_content"}>
            <DefaultUpperLimitManagementResult
              managementResult={
                receiptSiblingUpperLimitManagement.management_result
              }
            />
          </div>
        </div>
      </NBLMiddleInfoContent>
      {/* --- End Header */}

      {/* --- Start Body */}
      <KisarazuFormat
        facilities={receiptSiblingUpperLimitManagement.facilities}
        totalAmount={receiptSiblingUpperLimitManagement.total_amount}
        totalBurdenAmount={
          receiptSiblingUpperLimitManagement.total_burden_amount
        }
        managementResultBurdenAmount={
          receiptSiblingUpperLimitManagement.management_result_burden_amount
        }
        siblingChildren={siblingChildren}
        printConfig={printConfig}
      />
      {/* --- End Body */}

      {/* --- Start Footer */}
      <DefaultSignature printConfig={printConfig} />
      {/* --- End Footer */}
    </Wrapper>
  )
}
