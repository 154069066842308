import styled from "styled-components"
import { theme } from "@project/shared"
import { Form } from "formik"

export const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    flex-wrap: nowrap;
    .grid-row-last {
      padding-bottom: 12px !important;
    }

    & > .flex-break:not(:last-child) {
      ~ .ant-card-grid {
        border-top: 1px solid ${theme.gray2} !important;
      }
    }
  }
  & .label_tag {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .error_message {
    font-size: 12px;
    color: #eb5757;
  }
  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.green2};
    margin-top: 3px;
  }
  .cost-item-button-wrapper {
    display: flex;
    margin-top: 20px;
    flex-basis: 100%;
    button {
      :first-child {
        margin-right: 20px;
      }
      @media (max-width: ${theme.breakpoints.sm}) {
        padding: 8px 16px !important;
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 16px;
    }
  }

  .instructor-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    .cost-item-button-wrapper {
      margin-top: 0px;
      flex-basis: 0;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      display: grid;
      justify-content: center;
      gap: 20px;
    }
  }

  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
`
