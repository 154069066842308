import {
  SelectInput,
  TextField,
  Checkboxes,
  CHILD_SERVICE_OPTION,
  theme,
} from "@project/shared"
import moment from "moment"
import { useRouter } from "next/router"
import React, {
  useState,
  useContext,
  useEffect,
  FormEvent,
  useMemo,
} from "react"
import { Trans, useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import {
  fetchConsultationSupportOffice,
  fetchProvisionalCity,
} from "../../../services"
import { Highlighter, Title, TitleWrapper, AuthContext } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"
import { getAllFacilitiesWithChildCount } from "../../../services/facility"
import { Skeleton } from "antd"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"
import {
  ChildCarePermission,
  NewRegistrationPermission,
} from "../../../utils/PermissionKeys"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  isChildDataFetching?: boolean
  marginBottom?: string
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      keyword: string
      consultation_support_office_id: string
      facility_id: number
      other_facility_id: number
      provision_city_id: string
      child_service_ids: any
      contract_month: any
      contract_year: any
      contract_facility_ids: any
      page?: number
    }>
  >
  queryParam?: {
    keyword: string
    consultation_support_office_id: string
    facility_id: number
    other_facility_id: number
    provision_city_id: string
    child_service_ids: any
    contract_month: any
    contract_year: any
    contract_facility_ids: any
    page?: number
  }
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  clickFilterButtonType?: string
  setClickFilterButtonType?: (val: "search" | "reset") => void
  setCurrentPage?: (val: number) => void
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 27px 30px 27px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: ${theme.breakpoints.md}) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .new-child-btn {
    @media (max-width: 426px) {
      width: 100%;
    }
  }

  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    @media (max-width: 376px) {
      padding: 12px 17px;
    }
  }

  .select-up {
    margin-right: 6px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 13px 20px 30px 20px;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
  input {
    background-color: #ffffff;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .reset-button {
      padding: 0 20px;
      height: 40px;
    }
    @media screen and (max-width: 376px) {
      width: 80%;
      flex-wrap: nowrap;
    }
  }

  .checkbox-wrapper {
    display: flex;
    padding-left: 15px;
    @media (max-width: 768px) {
      .ant-checkbox + span {
        white-space: pre-line !important;
        word-break: break-all !important;
        padding-left: 10px !important;
      }
    }
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;

    .left-margin {
      margin-left: 16px;
      @media (max-width: 1036px) {
        margin-left: 0px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    .facility-wrapper {
      flex-direction: row;
      & > button:last-child {
        margin-top: 0px;
      }
    }
    .reset-button {
      margin-right: 10px;
    }
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
  .text-field {
    flex: 1;
    max-width: 702px;
    /* margin-left: 40px; */
    @media (max-width: 769px) {
      margin-left: 0px;
      width: 100%;
    }
  }

  input {
    background-color: #ffffff;
    width: 100%;
    max-width: 670px;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const TitleWrap = styled.div<{
  $currentLang: string
}>`
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: ${({ $currentLang }) =>
    $currentLang === "en" ? "282px" : "128px"};
  .textfield {
    margin-left: 30px;
  }

  @media (max-width: 530px) {
    width: 100%;
    min-width: 100% !important;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 19px;
  margin-bottom: -6px;

  button {
    height: 40px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    margin-bottom: -10px;
    justify-content: center;
    .search-btn {
      margin-right: 10px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
  font-weight: normal !important;
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;

  .select-class {
    @media (max-width: ${theme.breakpoints.md}) {
      width: auto;
    }
  }

  .select-class-last {
    .dynamic-sel-width {
      min-width: 300px;
      max-width: 100%;
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    > .select-class-last {
      width: 100%;
      .dynamic-sel-width {
        min-width: 205px !important;
        max-width: 100% !important;
      }
    }
  }
`

const InputWrappers = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .select-down {
    margin-right: 0 !important;
    @media (max-width: ${theme.breakpoints.md}) {
      margin-right: 5px !important;
    }
  }
  .select-class-last {
    overflow: hidden;
    flex: 1;

    &.dynamic-sel-width {
      min-width: 300px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
      &.dynamic-sel-width {
        min-width: auto;
      }
    }
    @media (max-width: 517px) {
      .ant-select {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    gap: 10px;
  }
`

const ChildOperationComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const {
    header,
    queryParam,
    setQueryParam,
    isChildDataFetching,
    clickFilterButtonType,
    setClickFilterButtonType,
    setCurrentPage,
  } = props
  const { t } = useTranslation()
  const router = useRouter()
  const currentLang = localStorage.getItem("i18nextLng")
  const [updateParams] = useUpdateSearchParams()
  const [checkedSort, setCheckedSort] = useState(null)
  const [filteredCombinedFacilities, setFilteredCombinedFacilities] =
    useState(null)
  const [keyword, setKeyword] = useState(queryParam.keyword || "")
  const [consultationSupportOfficeId, setConsultationSupportOfficeId] =
    useState(queryParam.consultation_support_office_id || "")
  const [upperLimitManagementCompany, setUpperLimitManagementCompany] =
    useState(null)
  const [provisionCityId, setProvisionCityId] = useState(
    queryParam.provision_city_id || ""
  )
  const [childServiceIds, setChildServiceIds] = useState(
    queryParam.child_service_ids || []
  )
  const [contractMonth, setContractMonth] = useState(
    queryParam.contract_month || ""
  )
  const [contractYear, setContractYear] = useState(
    queryParam.contract_year || ""
  )
  const [contractFacilityIds, setContractFacilityIds] = useState(
    queryParam.contract_facility_ids || null
  )
  const [childContractFacilities, setChildContractFacilities] = useState([])

  const { facilities, otherFacilities, isOwner, permissions } =
    useContext(AuthContext)

  const hasAddChildAccess =
    isOwner ||
    useMemo(
      () =>
        hasPermissionForMenu(permissions, NewRegistrationPermission, "both") &&
        hasPermissionForMenu(permissions, ChildCarePermission, "write"),
      [permissions]
    )

  const SORT_OPTION = FuriganaAlphabetsOption

  const {
    isLoading: isFacilityLoading,
    refetch: refetchFacilitiesCount,
    data: facilitiesWithChildCount,
  } = useQuery<any>(
    ["facilities-with-count", queryParam],
    async () => {
      const data = await getAllFacilitiesWithChildCount({
        ...queryParam,
        year: contractYear,
        month: contractMonth,
      })

      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const { isLoading: isCityLoading, data: provisionCities } = useQuery({
    queryKey: ["provision-cities", 1, "100"],
    queryFn: fetchProvisionalCity,
    select: (res: any) =>
      res?.data?.map((val) => ({
        label: val?.provision_city_name,
        value: val?.id,
      })),
    refetchOnWindowFocus: false,
  })

  const { isLoading: isConsultationLoading, data: consultationOffices } =
    useQuery({
      queryKey: ["consultation_support_office", 1, 100],
      queryFn: fetchConsultationSupportOffice,
      select: (res: any) =>
        res?.data?.map((val) => ({
          label: val?.consultation_support_name,
          parent_name: val?.parent_name,
          value: val?.id,
        })),
      refetchOnWindowFocus: false,
    })

  const CHILD_FACILITY_OPTION =
    !isFacilityLoading &&
    facilitiesWithChildCount?.map((item) => {
      return {
        value: item?.id,
        label: `${item?.facility_name_short} ${
          queryParam?.contract_facility_ids?.includes(Number(item?.id))
            ? `(${item.total || 0} ${t("people")})`
            : ""
        }`,
      }
    })
  const allFacility =
    !isFacilityLoading && CHILD_FACILITY_OPTION?.map((item) => item.value)

  // Separate Maps for upperlimitManagementCompany
  const UPPER_LIMIT_COMPANY_OPTIONS = facilities?.map((item) => {
    return {
      value: `own-${item?.id}`,
      label: item?.facility_name_short, //Check for Furigana name first
      furigana: item?.facility_name_furigana,
    }
  })

  const OTHER_FACILITY_OPTION = otherFacilities?.map((item) => {
    return {
      value: `other-${item?.id}`,
      label: item?.facility_name, //Check for Furigana name first
      furigana: item?.facility_name_furigana,
    }
  })

  // Combined list of Facility and Other facilities.
  const COMBINED_UPPER_LIMIT_COMPANY_OPTIONS = [
    ...UPPER_LIMIT_COMPANY_OPTIONS,
    ...OTHER_FACILITY_OPTION,
  ]

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)

    const filteredFacilities =
      value == 0
        ? COMBINED_UPPER_LIMIT_COMPANY_OPTIONS
        : COMBINED_UPPER_LIMIT_COMPANY_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )

    setCheckedSort(value)
    setFilteredCombinedFacilities(filteredFacilities)
  }

  const handleSearch = () => {
    const upperLimitManagementCompanyPrefix =
      upperLimitManagementCompany?.split("-")
    setClickFilterButtonType("reset")
    setCurrentPage(1)
    setQueryParam((prev) => {
      return {
        ...prev,
        keyword: keyword,
        consultation_support_office_id: consultationSupportOfficeId,
        facility_id:
          upperLimitManagementCompanyPrefix?.[0] === "own"
            ? upperLimitManagementCompanyPrefix?.[1]
            : "",
        other_facility_id:
          upperLimitManagementCompanyPrefix?.[0] === "other"
            ? upperLimitManagementCompanyPrefix?.[1]
            : "",
        provision_city_id: provisionCityId,
        child_service_ids: childServiceIds,
        contract_month: contractMonth,
        contract_year: contractYear,
        contract_facility_ids: contractFacilityIds,
        page: 1,
      }
    })
    refetchFacilitiesCount()
    updateParams(
      {
        page: 1,
        keyword: keyword,
        consultation_support_office_id: consultationSupportOfficeId,
        facility_id:
          upperLimitManagementCompanyPrefix?.[0] === "own"
            ? upperLimitManagementCompanyPrefix?.[1]
            : "",
        other_facility_id:
          upperLimitManagementCompanyPrefix?.[0] === "other"
            ? upperLimitManagementCompanyPrefix?.[1]
            : "",
        provision_city_id: provisionCityId,
        child_service_ids: childServiceIds?.join(","),
        contract_month: contractMonth,
        contract_year: contractYear,
        contract_facility_ids: contractFacilityIds?.join(","),
      },
      "/child"
    )
  }

  const handleReset = () => {
    const ids = facilitiesWithChildCount?.map((val) => val?.id) || []
    setKeyword("")
    setConsultationSupportOfficeId("")
    setCheckedSort("---")
    setUpperLimitManagementCompany(null)
    setProvisionCityId(null)
    setChildServiceIds([])
    setContractMonth(new Date().getMonth() + 1)
    setContractYear(new Date().getFullYear())
    setContractFacilityIds(ids)
    setClickFilterButtonType("reset")
    setQueryParam((prev) => {
      return {
        ...prev,
        keyword: "",
        consultation_support_office_id: "",
        facility_id: null,
        other_facility_id: null,
        provision_city_id: "",
        child_service_ids: ["1", "2"] || [],
        contract_month: new Date().getMonth() + 1,
        contract_year: new Date().getFullYear(),
        contract_facility_ids: ids,
      }
    })
    refetchFacilitiesCount()
    updateParams(
      {
        page: 1,
      },
      "/child"
    )
  }

  useEffect(() => {
    setChildContractFacilities(CHILD_FACILITY_OPTION)
  }, [queryParam, facilitiesWithChildCount, contractFacilityIds])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault()
          handleSearch()
        }}
      >
        <TitleWrapper>
          <Highlighter /> <Title>{t("Narrow down")} </Title>
        </TitleWrapper>
        <FieldWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Keyword")} </Text>
          </TitleWrap>

          <TextField
            name={"company_name"}
            height={"50px"}
            placeholder={
              "名前・受給者証番号・上限管理事業者・ふりがな・学校名・学校の担任・病院名・医師・備考"
            }
            bgcolor={"#fff"}
            value={keyword}
            onChange={(evt) => setKeyword(evt.target.value)}
            className={"text-field"}
          />
        </FieldWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Upper limit management company")} </Text>
          </TitleWrap>
          <InputWrappers>
            <SelectInput
              width={80}
              height={40}
              options={SORT_OPTION}
              className={"select-down"}
              value={checkedSort}
              placeholder={"---"}
              onChange={(values) => handleSort(values)}
            />
            <SelectInput
              width={"auto"}
              height={40}
              options={
                filteredCombinedFacilities?.length > 0
                  ? filteredCombinedFacilities
                  : COMBINED_UPPER_LIMIT_COMPANY_OPTIONS
              }
              placeholder={t("All")}
              value={upperLimitManagementCompany}
              onChange={(values) => setUpperLimitManagementCompany(values)}
              className={"select-class-last dynamic-sel-width"}
              dropdownMatchSelectWidth={false}
            />
          </InputWrappers>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Consultation support office")} </Text>
          </TitleWrap>
          <SelectWrap>
            <SelectInput
              width={"auto"}
              height={40}
              options={consultationOffices}
              value={consultationSupportOfficeId || null}
              placeholder={"---"}
              loading={isConsultationLoading}
              onChange={(values) => setConsultationSupportOfficeId(values)}
              className={"select-class-last dynamic-sel-width"}
              dropdownMatchSelectWidth={false}
            />
          </SelectWrap>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Provision city")} </Text>
          </TitleWrap>
          <SelectWrap>
            <SelectInput
              width={"auto"}
              height={40}
              options={provisionCities}
              value={provisionCityId || null}
              placeholder={"---"}
              loading={isCityLoading}
              onChange={(values) => setProvisionCityId(values)}
              className={"select-class-last dynamic-sel-width"}
              dropdownMatchSelectWidth={false}
            />
          </SelectWrap>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Child Service to use")} </Text>
          </TitleWrap>
          <SelectWrap>
            <Checkboxes
              key={"footerInformation"}
              name={"footerInformation"}
              value={childServiceIds}
              onChange={(values) => setChildServiceIds(values)}
              options={CHILD_SERVICE_OPTION}
            />
          </SelectWrap>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Contract year month")} </Text>
          </TitleWrap>
          <SelectWrap>
            <div className={"contract-date-pic"}>
              <DatePicker
                className={"select-class-last"}
                date={moment({ year: contractYear, month: contractMonth - 1 })}
                style={{
                  height: "40px",
                  width: "300px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment("2021/01/01")) ||
                    (current && current > moment().add(5, "year"))
                  )
                }}
                onDateChange={(value) => {
                  setContractYear(moment(value).format("YYYY"))
                  setContractMonth(moment(value).format("M"))
                }}
              />
            </div>
          </SelectWrap>
        </SearchWrapper>
        <SearchWrapper>
          <div className={"border-dashed"}></div>
        </SearchWrapper>
        <SearchWrapper>
          <TitleWrap $currentLang={currentLang}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Contract facility")} </Text>
          </TitleWrap>
          <div className={"facility-wrapper"}>
            <OwnerButton
              typeOf={"check-all"}
              className={"reset-button"}
              text={t("Check all")}
              onClick={() => {
                setContractFacilityIds(allFacility)
              }}
            />
            <OwnerButton
              typeOf={"secondary"}
              className={"reset-button"}
              text={t("Uncheck all")}
              backgroundColor={"#fff"}
              onClick={() => {
                setContractFacilityIds([])
              }}
            />
          </div>
        </SearchWrapper>
        <SearchWrapper>
          {isFacilityLoading ? (
            <Skeleton active paragraph />
          ) : (
            <div className={"checkbox-wrapper"}>
              <Checkboxes
                key={"footerInformation"}
                name={"footerInformation"}
                value={contractFacilityIds}
                onChange={(values) => setContractFacilityIds(values)}
                options={childContractFacilities}
              />
            </div>
          )}
        </SearchWrapper>
        <SearchWrapper>
          <span className={"info-wrapper"}>
            <Trans i18nKey={"childFilterText"}>
              <span>
                {t(
                  "※The numbers in parentheses for contracted facilities are the number of people contracted."
                )}
              </span>
              <br />
              <span className={"left-margin"}>
                {t(
                  "All facilities are displayed because you are logged in with a user account that has permission to register and delete children."
                )}
              </span>
            </Trans>
          </span>
        </SearchWrapper>
        <SearchWrapper>
          <div className={"border-dashed"}></div>
        </SearchWrapper>
        <Buttonwrapper>
          <OwnerButton
            disabled={isChildDataFetching && clickFilterButtonType === "search"}
            className={"search-btn"}
            text={t("Search")}
            shape={"circle"}
            icon={"search"}
            type={"submit"}
            typeOf={"primary"}
            onClick={handleSearch}
          />
          <OwnerButton
            disabled={isChildDataFetching && clickFilterButtonType === "reset"}
            typeOf={"secondary"}
            className={"reset-button"}
            text={t("Reset search")}
            onClick={handleReset}
          />
        </Buttonwrapper>
      </form>
      <br />
      <SearchWrapper>
        <div className={"border-dashed"}></div>
      </SearchWrapper>
      <TitleWrapper>
        <Highlighter /> <Title>{t("Add data")} </Title>
      </TitleWrapper>
      <Buttonwrapper className={"btn-wrapper"}>
        <OwnerButton
          className={"new-child-btn"}
          text={t("Add new child")}
          shape={"circle"}
          type={"submit"}
          typeOf={"primary"}
          onClick={() => router.push("/child/add")}
          disabled={!hasAddChildAccess}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ChildOperationComponent }
