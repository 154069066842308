import { t } from "i18next"
import { HiroshimaBurdenTable } from "./UpperLimitManagementResult.style"

type Props = {
  managementResult: number
}

export function HiroshimaUpperLimitManagementResult({
  managementResult,
}: Props) {
  return (
    <HiroshimaBurdenTable>
      <tr>
        <td className={"hiroshima-col"} style={{ width: 300 }}>
          {t("User burden upper limit management results")}
        </td>
        <td
          className={"hiroshima-col"}
          style={{ width: 40, textAlign: "center" }}
        >
          {managementResult}
        </td>
        <td className={"hiroshima-col"} colSpan={2}></td>
        <td className={"hiroshima-col"} style={{ width: 300 }}>
          {t("Calculation category for upper limit management addition")}
        </td>
        <td
          className={"hiroshima-col"}
          style={{ width: 40, textAlign: "center" }}
        ></td>
      </tr>
      <tr>
        <td colSpan={4} className={"hiroshima-col"} style={{ padding: 10 }}>
          <ol className={"ordered-list"} style={{ margin: 0 }}>
            <li className={"list"}>
              {t(
                "Since the management office has appropriated the amount to be paid by the user, there will be no user burden at other offices."
              )}
            </li>
            <li className={"list"}>
              {t(
                "Adjustment work will not be carried out because the total amount of user charges is less than the maximum monthly charge."
              )}
            </li>
            <li className={"list"}>
              {t(
                "Since the total amount of user charges exceeds the maximum monthly charge, the following adjustments have been made."
              )}
            </li>
          </ol>
        </td>
        <td colSpan={2} className={"hiroshima-col"} style={{ padding: 10 }}>
          <ol className={"ordered-list"} style={{ margin: 0 }}>
            <li className={"list"}>
              {t("Calculated as nursing care benefit expenses, etc.")}
            </li>
            <li className={"list"}>
              {t("Calculated as Hiroshima City subsidy.")}
            </li>
            <li className={"list"}>{t("Not calculated.")}</li>
          </ol>
        </td>
      </tr>
    </HiroshimaBurdenTable>
  )
}
