import React from "react"
import Link from "next/link"

//libraries
import { Trans, useTranslation } from "react-i18next"
import { Spin, Typography } from "antd"

//components
import { PageCard } from "@project/shared"
import { OwnerButton } from "../../atoms"
import { YearPaginateBtn } from "../../molecules"
import { FACILITY_BILLING_METHOD, getDateString } from "../../../utils"
//styles
import {
  Wrapper,
  TextContentWrapper,
  OverlayBox,
  BillingChangeButtonWrapper,
} from "./styles"

export type TI = any

interface IProps {
  facilityBillingMethod: any
  updateYearMonth: (val) => void
  displayYear: number
  displayMonth: number
  showOverlay?: boolean
  saveBillMethod: (val) => void
  isLoading?: boolean
  switchToBillingChange: () => void
  handleProceedWithoutBillingChange: () => void
}

export const NationalHealthInsuranceBillingFormContent: React.FC<IProps> = ({
  switchToBillingChange,
  handleProceedWithoutBillingChange,
  facilityBillingMethod,
  updateYearMonth,
  displayYear,
  displayMonth,
  showOverlay = false,
  isLoading = false,
  saveBillMethod,
}): JSX.Element => {
  const { t } = useTranslation()
  const TextContent = ({
    text,
    subText,
    billMethod,
  }: {
    text: string
    subText: string
    billMethod: number
  }): JSX.Element => {
    return (
      <BillingChangeButtonWrapper onClick={() => saveBillMethod(billMethod)}>
        <TextContentWrapper>
          <Typography.Text className={"red_txt"}>{text}</Typography.Text>
          <Typography.Text className={"txt"}>{subText}</Typography.Text>
        </TextContentWrapper>
      </BillingChangeButtonWrapper>
    )
  }

  return (
    <Wrapper>
      <div
        style={{
          position: "relative",
        }}
      >
        {showOverlay ? (
          <OverlayBox>
            <div className={"content"}>
              <div>
                <img src={"/assets/icons/exclamation.png"} alt={"icon"} />
              </div>
              <div className={"text_content"}>
                <Trans i18nKey={"nhifBillingMethodCurrentAlert"}>
                  {
                    "Currently, it is set to bill the National Health Insurance Federation as "
                  }
                  <span style={{ color: "red" }}>
                    {"【 "}
                    {
                      {
                        method: t(
                          FACILITY_BILLING_METHOD?.[
                            facilityBillingMethod?.billing_method
                          ]
                        ),
                      } as TI
                    }
                    {"】"}
                  </span>
                  {
                    '. If you want to make another request without reflecting the updated information, click "Change billing information" If you want to proceed without changing, click "Proceed without changing billing"\''
                  }
                </Trans>
              </div>
              <div className={"cta_content"}>
                <OwnerButton
                  onClick={switchToBillingChange}
                  text={t("Change billing information")}
                  icon={"edit"}
                  shape={"circle"}
                  typeOf={"draft"}
                />
                <OwnerButton
                  onClick={handleProceedWithoutBillingChange}
                  text={t("Proceed without changing billing")}
                  icon={"check_mark"}
                  shape={"circle"}
                  typeOf={"primary"}
                />
              </div>
            </div>
          </OverlayBox>
        ) : (
          <></>
        )}

        <PageCard
          title={t(
            "{{date}}  National Health Insurance Federation billing form/CSV output",
            {
              date: getDateString(displayYear, displayMonth),
            }
          )}
          extra={
            <YearPaginateBtn
              prev={t("Prev month")}
              current={t("This month")}
              next={t("Next month")}
              handleClick={(key) => {
                let year = displayYear
                let month = displayMonth
                switch (key) {
                  case "next":
                    month++
                    if (month > 12) {
                      month = 1
                      year++
                    }
                    break
                  case "prev":
                    month--
                    if (month < 1) {
                      month = 12
                      year--
                    }
                    break
                  default:
                    year = new Date().getFullYear()
                    month = new Date().getMonth() + 1
                }
                updateYearMonth({ year, month })
              }}
              btnRadius={10}
            />
          }
          toRight={true}
        >
          <div className={"container"}>
            {isLoading ? (
              <div className={"overlay-loader"}>
                <Spin size={"large"} />
              </div>
            ) : (
              <></>
            )}
            <div className={"top_container"}>
              <div className={"top_container-top"}>
                <Typography.Text className={"txt"}>
                  {t("Please select a billing method.")}
                </Typography.Text>
              </div>
              <Typography.Text className={"top_container-bottom"}>
                <Link href={"#"}>
                  <a>
                    <img src={"/assets/icons/question_mark.svg"} alt={""} />
                    <span>
                      {t("Click here if you don't know which one to choose")}
                    </span>
                  </a>
                </Link>
              </Typography.Text>
            </div>
            <div className={"middle_container"}>
              <div className={"middle_container-content"}>
                <TextContent
                  text={t("Normal billing")}
                  subText={t("Click here if you want to")}
                  billMethod={1}
                />
              </div>
            </div>
            <div className={"bottom_container"}>
              <div className={"bottom_container-content"}>
                <TextContent
                  text={t("Billing with reduced user burden")}
                  subText={t("Click here if you want to")}
                  billMethod={2}
                />
              </div>
              <div className={"bottom_container-content"}>
                <TextContent
                  text={t(
                    'Change the amount of "User\'s upper limit monthly fee (1)"'
                  )}
                  subText={t("Click here to proceed with billing")}
                  billMethod={3}
                />
              </div>
            </div>
          </div>
        </PageCard>
      </div>
    </Wrapper>
  )
}
