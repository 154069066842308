import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Collapse, Typography } from "antd"
import Link from "next/link"
import { getDateStringWithDay } from "../../../utils"
import moment from "moment"
import { UpOutlined } from "@ant-design/icons"
const Wrapper = styled(Collapse)`
  max-width: 100%;
  border: 1px solid ${theme.red1};
  padding: 0.5rem;
  border-radius: 0.5rem;
  .title {
    margin-right: 0px !important;
    width: 50px;
    svg {
      font-size: 14px;
      margin: 0 0.4rem 0 0.2rem;
      color: ${theme.red3};
    }
  }
  .panel_title {
    color: ${theme.red3};
    font-size: 18px;
  }
  .sub {
    color: ${theme.red3};
    font-size: 16px;
    text-decoration: underline;
  }
`
export const BillingRegisteredInfoTable = ({ facilityId }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper
      ghost
      defaultActiveKey={["0"]}
      expandIcon={({ isActive }) => {
        return (
          <div className={"title"}>
            <img src={"/assets/icons/exclamation.png"} alt={""} />
            <UpOutlined rotate={isActive ? 180 : 0} />
          </div>
        )
      }}
    >
      <Collapse.Panel
        key={"1"}
        header={
          <Typography.Text className={"panel_title"}>
            {t("Status management page for this facility is not set yet.")}
          </Typography.Text>
        }
      >
        <Link href={`/facility/system-status/${facilityId}`}>
          <a target={"_blank"}>
            <Typography.Text
              style={{
                color: theme.red3,
                textDecoration: "underline",
              }}
            >
              {t("Click here to update status management", {
                date: getDateStringWithDay(moment()),
              })}
            </Typography.Text>
          </a>
        </Link>
      </Collapse.Panel>
    </Wrapper>
  )
}
