import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Table } from "antd"
import { theme } from "@project/shared"

interface PickupTableProps {
  data?: any
  loading?: boolean
  displaySettings?: any
}

const Container = styled.div`
  margin-top: 12px;

  .table-wrapper {
    width: 100%;
    .ant-table-title {
      height: 44px;
      background: #ccc;
      border: 1px solid black;
      margin-bottom: 2px;

      @media print {
        margin-bottom: 4px;
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      position: relative;
      padding: 8px;
      overflow-wrap: break-word;
    }
    .ant-table-container {
      overflow-x: auto;
      border-left: 1px solid ${theme.black};

      .ant-table-content table {
        border-top: 1px solid ${theme.black};
        tbody tr {
          td {
            font-size: 14px;
            border: 1px solid ${theme.black};
            border-right: none;
            border-top: none;

            &:last-child {
              border-right: 1px solid ${theme.black};
            }

            &:first-child {
              border-left: 0px;
            }
          }
        }
      }
      @media print {
        &::after,
        &::before {
          display: none;
        }

        table {
          table-layout: fixed !important;
          border-collapse: collapse !important;
          thead {
            tr {
              th {
                padding: 3px !important;
                font-size: 10px !important;
              }
            }
          }
          tbody {
            tr {
              page-break-inside: avoid !important;

              td {
                padding: 3px !important;
                font-size: 10px !important;

                .ant-table-expanded-row-fixed {
                  width: 100% !important;
                }
              }

              &:not(:first-child) {
                td {
                  padding: 3px !important;
                  min-width: 80px;
                  max-width: 80px;
                }
              }
            }
          }
        }
      }
    }

    .ant-table-container > .ant-table-content > table > thead > tr > th {
      background: #f5f5f5;
      font-size: 14px;
      outline: none !important;
      border: 1px solid ${theme.black};
      border-top: 0;

      &:not(:last-child) {
        border-right: none;
      }

      &:first-child {
        border-left: 0;
        min-width: 160px;
        max-width: 160px;
      }

      &:before {
        display: none;
      }

      @media print {
        background: transparent;
      }
    }
  }
  @media print {
    overflow-x: visible !important;
    .ant-table-title {
      height: 20px !important;
    }
    .child_name {
      min-width: 175px;
      max-width: 175px;
    }
    .facility_name {
      min-width: 110px;
      max-width: 110px;
    }
  }
`

const FacilityDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 8.68rem;
  word-break: break-word;
  font-size: 14px;

  @media print {
    font-size: 9pt;
    width: auto;
  }
`
const ColumnDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  width: 150px;

  @media print {
    font-size: 9pt;
    width: auto;
  }
`
const RemarksDiv = styled(ColumnDiv)`
  width: 13.5rem;

  @media print {
    font-size: 9pt;
    width: auto;
  }
`

export const PickupTable: React.FC<PickupTableProps> = ({
  data,
  loading,
  displaySettings,
}) => {
  const { t } = useTranslation()
  const tableTitle = () => (
    <h2
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {t("Greet")}
    </h2>
  )

  const pickupColumns: any = [
    {
      title: t("Child name"),
      dataIndex: "child_name",
      key: "child_name",
      align: "center",
      className: "child_name",
    },
    {
      title: t("Facility name"),
      dataIndex: "facility_name",
      key: "facility_name",
      align: "center",
      render: (_, data) => <FacilityDiv>{data?.facility_name}</FacilityDiv>,
      className: "facility_name",
    },
    {
      title: t("Desired pick up time"),
      dataIndex: "desired_pickup_time",
      key: "desired_pickup_time",
      align: "center",
      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderRight: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>{is_present && data?.desired_pickup_time}</ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Picked up time"),
      dataIndex: "picked_up_time",
      key: "picked_up_time",
      align: "center",
      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present

        return {
          props: {
            style: {
              borderRight: (!is_present || !data?.use_transport) && "none",
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present && data?.use_transport && data?.picked_up_time}
            </ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Arrival time"),
      dataIndex: "arrival_time",
      key: "arrival_time",
      align: "center",
      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderRight: (!is_present || !data?.use_transport) && "none",
              borderLeft: (!is_present || !data?.use_transport) && "none",
              color: !is_present && theme.red2,
            },
          },
          children: (
            <ColumnDiv>
              {is_present ? (
                data?.use_transport ? (
                  data?.arrival_time
                ) : (
                  <>{t("No transportation")}</>
                )
              ) : (
                t("Absent")
              )}
            </ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Place"),
      dataIndex: "place",
      key: "place",
      align: "center",
      hidden: !(displaySettings?.showDaycares === true),
      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present && data?.use_transport && data?.place}
            </ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Driver"),
      dataIndex: "driver",
      key: "driver",
      align: "center",

      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present &&
                data?.use_transport &&
                data?.driver?.driver_staff_name}
            </ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Conductor"),
      dataIndex: "conductor",
      key: "conductor",
      align: "center",

      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present &&
                data?.use_transport &&
                data?.driver?.escort_staff_name}
            </ColumnDiv>
          ),
        }
      },
    },
    {
      title: t("Vehicle"),
      dataIndex: "vehicle",
      key: "vehicle",
      align: "center",

      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present && data?.use_transport && data?.driver?.car_name}
            </ColumnDiv>
          ),
        }
      },
    },

    {
      title: t("Route"),
      dataIndex: "route",
      key: "route",
      align: "center",

      render: (_, data) => {
        const is_present = data?.is_present === null ? true : data?.is_present
        return {
          props: {
            style: {
              borderLeft: (!is_present || !data?.use_transport) && "none",
            },
          },
          children: (
            <ColumnDiv>
              {is_present && data?.use_transport && data?.route}
            </ColumnDiv>
          ),
        }
      },
    },

    {
      title: t("Remarks"),
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      render: (_, data) => <RemarksDiv>{data?.remarks}</RemarksDiv>,
    },
  ]

  return (
    <Container>
      <div className={"table-wrapper"}>
        {data && (
          <Table
            dataSource={data}
            columns={pickupColumns.filter((item) => !item.hidden)}
            title={tableTitle}
            loading={loading}
            pagination={false}
          />
        )}
      </div>
    </Container>
  )
}
