/**
 * NOTE:
 * - R6 April [abolished:廃止]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"

type Params = {
  name: string
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getLinkAdmissionRecordsAndSpecialSupportAdditionRecords(
  date: Moment,
  params: Params
) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline(params.name)
    default:
      return null
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 *
 * NONE
 * --------------------------------
 */

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */
function baseline(name: string): FormFieldsProp {
  return {
    fieldLabel: t(
      "Link admission records and special support addition records"
    ),
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        label: t("Link"),
        fieldType: {
          type: "checkbox",
        },
      },
    ],
  }
}
