import {
  dynamicLangString,
  Grid,
  Required,
  SelectInput,
  TextField,
} from "@project/shared"
import { notification, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { Divisor, FormTitle, StyledForm } from "../../../utils"
import { DataContainer } from "../FacilitySystemStatus/index.styles"
import styled from "styled-components"
import TextArea from "antd/lib/input/TextArea"
import { OwnerButton } from "../../atoms"
import { useRouter } from "next/router"
import * as yup from "yup"
import moment from "moment"
import { useFormik } from "formik"
import {
  addFacilityTour,
  getAllOptions,
  updateFacilityTour,
  getFacilityTourSupport,
  deleteFacilityTourSupport,
} from "../../../services"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"
import { useMutation, useQuery } from "react-query"
import { useEffect } from "react"
import { DatePicker } from "../../molecules/DatePicker"

interface IProps {
  id?: string
  isEdit?: boolean
  facilityTourApplicationId?: string
}

const GridFlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.85rem;
  gap: 0.625rem;

  @media screen and (max-width: 426px) {
    margin-top: -10px;
    margin-bottom: 0;
  }

  .flex-2 {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
  }

  @media (max-width: 442px) {
    flex-direction: column;
  }

  .last-btn {
    @media (max-width: 442px) {
      button {
        margin-left: auto;
      }
    }
  }
`
const StyledFormWrapper = styled(StyledForm)`
  margin-bottom: 1.875rem;

  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444748;
  }
  .ant-select-clear {
    transform: scale(1.2);
    margin-right: 6px;
    opacity: 1;
    color: #d2d1d1;
    display: flex;
    align-items: center;
  }

  .ant-select-clear:hover {
    color: #555755;
  }
`

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const FacilityTourApplicationManagementAddForm: React.FC<IProps> = ({
  id,
  facilityTourApplicationId,
  isEdit,
}) => {
  moment.updateLocale("ja", {
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月",
    ],
  })
  const { t } = useTranslation()
  const router = useRouter()

  const {
    isLoading,
    isFetching,
    data: categoryList,
  } = useQuery(["fetch-all-category"], getAllOptions, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
  })

  const OPTIONS =
    !isLoading &&
    !isFetching &&
    categoryList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.progress_name || "",
      }
    })

  const {
    isLoading: getSupportHistoryLoading,
    isFetching: getSupportHistoryFetching,
    data: response,
  } = useQuery({
    queryKey: ["support-history", id],
    queryFn: () => getFacilityTourSupport(id),
    enabled: isEdit === undefined ? false : true,
    refetchOnWindowFocus: false,
  })

  const validationSchema = yup.object().shape({
    PIC: yup.string().max(30),
    date: yup.string().required(t("Required")),
    progress_manage_id: yup.string().nullable(),
    content: yup.string(),
  })

  const initialValues = response?.data || {
    progress_manage_id: 0,
    PIC: "",
    content: "",
    date: null,
  }
  const { isLoading: isSaving, mutate: mutateFacilityTour } = useMutation(
    isEdit ? updateFacilityTour : addFacilityTour,
    {
      onSuccess: () => {
        notification.success({
          message: isEdit
            ? dynamicLangString([
                "Facility Tour Support History",
                "Updated Successfully",
              ])
            : dynamicLangString([
                "Facility Tour Support History",
                "Created Successfully",
              ]),
        })
        router.push(
          `/facility-tour-application-management/detail/${facilityTourApplicationId}`
        )
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        trial_admission_id: Number(facilityTourApplicationId),
        date: values?.date,
        PIC: values?.PIC,
        progress_manage_id: values?.progress_manage_id || 0,
        content: values?.content,
        id: id,
      }
      mutateFacilityTour(data)
    },
  })

  const { isLoading: isDeleting, mutate: deleteFacilityTour } = useMutation(
    isEdit ? deleteFacilityTourSupport : null,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Facility Tour Support History",
            "Deleted Successfully",
          ]),
        })
        router.push(
          `/facility-tour-application-management/detail/${facilityTourApplicationId}`
        )
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scroll(0, 0) // need to change
  }, [formik.submitCount, formik.isValid])

  if (getSupportHistoryLoading || getSupportHistoryFetching) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <DataContainer>
      <FormTitle>{t("Support history information")}</FormTitle>
      <Divisor />
      <StyledFormWrapper
        className={"styled-form"}
        onSubmit={formik.handleSubmit}
      >
        <Grid className={"grid-header"} background>
          <div className={"label_tag"}>
            <GridFlexHeader>
              <div>{t("Date")}</div>
              <Required />
            </GridFlexHeader>
          </div>
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <DatePicker
            date={
              moment(formik?.values?.date).isValid()
                ? moment(formik?.values?.date, "YYYY-MM-DD")
                : null
            }
            onDateChange={(e) => formik.setFieldValue("date", e)}
            placeholder={""}
            style={{ height: "40px" }}
            format={"YYYY年MM月DD日"}
          />
          <ErrorCounterWrapper>
            {formik.errors.date && formik.touched.date && (
              <ErrorStyled>
                {formik.errors.date ? t("Required") : ""}
              </ErrorStyled>
            )}
          </ErrorCounterWrapper>
        </Grid>
        <Grid className={"flex-break"} />

        <Grid className={"grid-header label-container"} background>
          <div className={"label_tag"}>{t("Progress status")}</div>
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <SelectInput
            name={"progress_manage_id"}
            options={OPTIONS}
            width={180}
            height={40}
            placeholder={"----"}
            clear
            value={formik?.values?.progress_manage_id || null}
            onChange={(val) => {
              formik.setFieldValue("progress_manage_id", val)
            }}
          />
        </Grid>
        <Grid className={"flex-break"} />
        <Grid className={"grid-header label-container"} background>
          <div className={"label_tag"}>{t("PIC")}</div>
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <TextField
            name={"PIC"}
            maxLength={30}
            placeholder={"山田　太郎"}
            value={formik.values?.PIC}
            onChange={formik.handleChange}
            error={formik.touched.PIC && formik.errors.PIC}
          />
          <div className={"info"}>{t("(within 30 char)")}</div>
        </Grid>
        <Grid className={"flex-break"} />
        <Grid className={"grid-header label-container"} background>
          <div className={"label_tag"}>{t("Contents")}</div>
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <TextArea
            rows={8}
            name={"content"}
            placeholder={"受給者証の交付を待ち入所"}
            value={formik.values?.content}
            onChange={formik.handleChange}
          />
          {formik.touched.content && formik.errors.content && (
            <div className={"info"}>{t("(within 30 char)")}</div>
          )}
        </Grid>
        <Grid className={"flex-break"} />
      </StyledFormWrapper>
      <ButtonWrapper>
        <div className={"flex-2"}>
          <OwnerButton
            typeOf={"secondary"}
            className={"cancel-btn"}
            text={t("Cancel")}
            onClick={() =>
              router.push(
                `/facility-tour-application-management/detail/${facilityTourApplicationId}`
              )
            }
          />
          <OwnerButton
            typeOf={"primary"}
            text={t("Save")}
            type={"submit"}
            onClick={() => formik.submitForm()}
            isLoading={isSaving}
          />
        </div>
        {isEdit && (
          <div className={"last-btn"}>
            <OwnerButton
              typeOf={"secondary"}
              className={"cancel-btn"}
              text={t("delete")}
              isLoading={isDeleting}
              onClick={() => {
                deleteFacilityTour(id)
              }}
            />
          </div>
        )}
      </ButtonWrapper>
    </DataContainer>
  )
}
export { FacilityTourApplicationManagementAddForm }
