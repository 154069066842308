import { useEffect, useState } from "react"

export const useDebounce = (value: string, delay: number) => {
  const [debounceValue, setDebounceValue] = useState(value)
  useEffect(() => {
    const bounce = setTimeout(() => {
      setDebounceValue(value)
    }, delay || 500)
    return () => {
      clearTimeout(bounce)
    }
  }, [value, delay])
  return [debounceValue]
}
