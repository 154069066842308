import { theme } from "@project/shared"
import styled from "styled-components"
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid ${theme.gray2};
  border-bottom: 0;
  padding: 1rem 0.5rem;
  .individual__top_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &--title {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
    }
    &--tables {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      .tables__left {
        &--top {
          #table__left_top {
            border: 1px solid ${theme.black};
            width: fit-content;
            table {
              tbody {
                tr {
                  th,
                  td {
                    border: 1px solid ${theme.black};
                    min-width: 30px;
                    text-align: center;
                    padding: 0px;
                    font-size: 12px;
                    @media print {
                      font-size: 9pt !important;
                      padding: 0 4px !important;
                      min-width: 15px;
                    }
                  }
                }
              }
            }
          }
          #table__left_middle {
            margin-top: 20px;
            border: 1px solid ${theme.black};
            width: fit-content;
            table {
              tbody {
                tr {
                  th {
                    padding: 0px;
                    font-size: 12px;
                  }
                  th,
                  td {
                    border: 1px solid ${theme.black};
                    min-width: 30px;
                    text-align: center;
                    font-size: 12px;
                    .p-2 {
                      padding: 0px;
                      font-size: 12px;
                    }
                    @media print {
                      font-size: 9pt !important;
                      min-width: 15px !important;
                    }
                  }
                  td {
                    #inner__cell__container {
                      display: flex;
                      .inner__cell {
                        /* padding: 8px; */
                        min-width: 20px;
                      }
                      .inner__cell:not(:last-child) {
                        border-right: 1px solid ${theme.black};
                      }

                      @media print {
                        font-size: 9pt !important;
                        .inner__cell {
                          min-width: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tables__right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        #tables__right--top {
          border: 1px solid ${theme.black};
          width: fit-content;
          table {
            tbody {
              tr {
                th,
                td {
                  border: 1px solid ${theme.black};
                  min-width: 30px;
                  text-align: center;
                  padding: 0px;
                  font-size: 12px;
                  @media print {
                    font-size: 9pt !important;
                    min-width: 15px;
                  }
                }
                th {
                  width: 10px !important;
                  word-break: break-all !important;
                }
                .office__name {
                  padding: 0 !important;
                  font-size: 12px;
                  &--top {
                    border-bottom: 1px solid ${theme.black};
                    padding: 10px;
                    font-size: 12px;
                    @media print {
                      padding: 8px;
                      font-size: 10pt;
                    }
                  }
                  &--bottom {
                    display: flex;
                    & > div:nth-child(1) {
                      display: flex;
                      flex: auto;
                      border-right: 1px solid ${theme.black};
                      justify-content: center;
                      padding: 10px;
                      font-size: 12px;
                      @media print {
                        padding: 8px;
                        font-size: 10pt;
                      }
                    }
                    & > div:nth-child(2) {
                      display: flex;
                      justify-content: center;
                      flex: 3;
                      padding: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #individual__middle_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    #individual__middle--top,
    #individual__middle--bottom {
      border: 1px solid ${theme.black};
      table {
        width: 100%;
        tbody {
          tr {
            th,
            td {
              border: 1px solid ${theme.black};
              padding: 0px;
              text-align: center;
              font-size: 12px;
              min-width: 15px;
            }
            #empty__cell {
              padding: 1px 6px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xmd}) {
    .individual__top_content {
      &--tables {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    .individual__top_content {
      &--tables {
        gap: 0.5rem !important;
        .table__left_top {
          width: 100% !important;
          overflow-x: auto;
          background: red;
          table {
            width: 100% !important;
            overflow-x: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 100% !important;
    overflow-x: auto;
    #individual__middle--top {
      border: unset !important;
    }
    #table__left_middle,
    #individual__middle--top,
    #tables__right--top {
      width: 100% !important;
      table {
        width: 100% !important;
        tbody {
          tr {
            th,
            td {
              word-spacing: nowrap !important;
              word-break: keep-all !important;
            }
          }
        }
      }
    }
    #individual__middle--bottom {
      border: unset !important;
      table tbody tr td {
        padding: 0 7px !important;
      }
    }
    #payment__detail_table {
      .ant-table-wrapper {
        table {
          tr {
            td {
              word-break: keep-all !important;
              word-spacing: nowrap !important;
              text-align: center;
              .inner__value_cell {
                padding: 0 5px !important;
              }
            }
          }
        }
      }
    }
  }
  .thick-right-border {
    border-right: 2px solid ${theme.black} !important;
  }
  .thick-bottom-border {
    border-bottom: 2px solid ${theme.black} !important;
  }
  .wd-17 {
    min-width: 17px;
  }
  @media print {
    border: 0 !important;
    gap: 0.5rem !important;
    padding: 0 !important;
    #form__num {
      display: block !important;
    }
    .individual__top_content {
      gap: 0.5rem !important;
      .tables__left {
        &--top {
          #table__left_middle {
            margin-top: 8px !important;
          }
        }
      }
      .tables__right {
        gap: 0.5rem !important;
      }
    }
    #individual__middle_content {
      gap: 0.5rem !important;
    }
  }
`
