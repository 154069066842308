const LeftArrowIcon = () => (
  <svg
    width={"9"}
    height={"10"}
    viewBox={"0 0 9 10"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={"M7.85938 9.21094L4.99939 5.12071L7.85938 0.999878"}
      stroke={"#444748"}
      strokeWidth={"1.2"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M3.85938 9.21094L0.99939 5.12071L3.85938 0.999878"}
      stroke={"#444748"}
      strokeWidth={"1.2"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default LeftArrowIcon
