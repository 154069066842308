import { Grid, PageCard, TextField, theme } from "@project/shared"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton, Tag } from "../../atoms"
import * as yup from "yup"
import { notification, Skeleton } from "antd"
import { HorizontalInfo, scrollToFirstErrorField } from "../../../utils"
import { fetchCompany, saveCompany } from "../../../services"
import { useMutation, useQuery } from "react-query"

interface iCompanyDetailCard {
  isEdit?: boolean
}
const ExtraInfoStyled = styled.span`
  margin-left: 5px;
  color: #e00;
`
const Wrapper = styled.div`
  .ant-card-head-wrapper {
    justify-content: space-between;
    @media (max-width: 876px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  a {
    text-decoration: underline;
    color: #0782c8;
  }

  .grid-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
  .button-wrapper {
    padding-top: 2%;
  }
  & .label_tag {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ant-card-extra {
    button {
      max-width: 100% !important;
    }
  }
`
const CompanyDetailCard: React.FC<iCompanyDetailCard> = ({ isEdit }) => {
  const { t } = useTranslation()
  /**
   * initial value is empty
   * for now value is just a dummy data
   * need to fetch value from api
   */

  const {
    data: companyInformationData,
    isLoading: companyInformationLoading,
    isFetching: companyInformationFetching,
  } = useQuery<any, Error>(["companyInformationData"], fetchCompany, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return data
    },
  })

  const { mutate: mutateCompany, isLoading } = useMutation(saveCompany, {
    onSuccess: () => {
      notification.success({
        message: t("Company information has been updated."),
      })
      router.push(`/company`)
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const initialValues = companyInformationData || {
    company_name: "",
    representative_director_name: "",
    position_name: "",
    zip_code: "",
    address: "",
    tel_no: "",
    fax_no: "",
    mail_address: "",
    reception_info: "",
  }

  const NUM_HYPEN_REGEX = /^(?=.*[0-9])[-0-9]+$/
  const validationSchema = yup.object().shape({
    company_name: yup
      .string()
      .required(t("Required"))
      .max(100, t("(Within 100 char)")),
    representative_director_name: yup.string().max(50, t("(Within 50 char)")),
    position_name: yup.string().max(50, t("(Within 50 char)")),
    zip_code: yup
      .string()
      .required(t("Required"))
      .max(30, t("(Number within 30 char)"))
      .matches(NUM_HYPEN_REGEX, t("Invalid PostBox")),

    address: yup
      .string()
      .required(t("Required"))
      .max(100, t("(Within 100 char)")),
    tel_no: yup
      .string()
      .required(t("Required"))
      .max(30, t("(Number within 30 char)"))
      .matches(
        NUM_HYPEN_REGEX,
        t("Phone numbers can only be number and hypens")
      ),
    fax_no: yup
      .string()
      .required(t("Required"))
      .max(30, t("(Number within 30 char)"))
      .matches(NUM_HYPEN_REGEX, t("Fax can only be number and hypens")),
    mail_address: yup.string(),
    reception_info: yup
      .string()
      .required(t("Required"))
      .max(100, t("(Within 100 char)")),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateCompany(values)
    },
  })
  const router = useRouter()
  // TODO: child dashboard link should be dynamic from env

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  return (
    <Wrapper>
      <PageCard
        className={"company-information-page-card"}
        title={
          <Trans>
            {"The company information on this screen is use in "}
            <a href={process.env.NEXT_PUBLIC_CHILD_SITE_URL} target={"_blank"}>
              {"My page"}
            </a>
          </Trans>
        }
        style={{ marginTop: "22px" }}
        extra={
          isEdit ? null : (
            <OwnerButton
              typeOf={"secondary"}
              onClick={() => router.push(`/company/edit`)}
              text={t("Edit company information")}
            />
          )
        }
      >
        {companyInformationFetching || companyInformationLoading ? (
          <Skeleton />
        ) : (
          <>
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Company name")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"company_name"}
                  height={"50px"}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  bgcolor={"#ffffff"}
                  error={formik.errors.company_name}
                  maxLength={100}
                />
              ) : (
                formik.values.company_name
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              {t("Representative name")}
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"representative_director_name"}
                  height={"50px"}
                  value={formik.values.representative_director_name}
                  onChange={formik.handleChange}
                  bgcolor={"#ffffff"}
                  maxLength={50}
                  error={formik.errors.representative_director_name}
                />
              ) : (
                formik.values.representative_director_name
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              {t("Position")}
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"position_name"}
                  height={"50px"}
                  value={formik.values.position_name}
                  onChange={formik.handleChange}
                  bgcolor={"#ffffff"}
                  error={formik.errors.position_name}
                  maxLength={50}
                />
              ) : (
                formik.values.position_name
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Po. Box No.")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"zip_code"}
                  height={"50px"}
                  value={formik.values.zip_code}
                  onChange={formik.handleChange}
                  bgcolor={"#ffffff"}
                  error={formik.errors.zip_code}
                  maxLength={30}
                />
              ) : (
                formik.values.zip_code
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Address")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"address"}
                  height={"50px"}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  bgcolor={"#ffffff"}
                  error={formik.errors.address}
                  maxLength={100}
                />
              ) : (
                formik.values.address
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Phone number")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <HorizontalInfo>
                  <TextField
                    name={"tel_no"}
                    height={"50px"}
                    value={formik.values.tel_no}
                    onChange={formik.handleChange}
                    bgcolor={"#ffffff"}
                    error={formik.errors.tel_no}
                    className={"half-field "}
                    maxLength={30}
                  />
                  <ExtraInfoStyled>{"※マイページに表示する"}</ExtraInfoStyled>
                </HorizontalInfo>
              ) : (
                formik.values.tel_no
              )}
              {!isEdit && formik.values.tel_no ? (
                <ExtraInfoStyled>{"※マイページに表示する"}</ExtraInfoStyled>
              ) : null}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("FAX number")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <HorizontalInfo>
                  <TextField
                    name={"fax_no"}
                    height={"50px"}
                    value={formik.values.fax_no}
                    onChange={formik.handleChange}
                    error={formik.errors.fax_no}
                    bgcolor={"#ffffff"}
                    className={"half-field "}
                    maxLength={30}
                  />
                  <ExtraInfoStyled>{"※マイページに表示する"}</ExtraInfoStyled>
                </HorizontalInfo>
              ) : (
                formik.values.fax_no
              )}
              {!isEdit && formik.values.fax_no ? (
                <ExtraInfoStyled>{"※マイページに表示する"}</ExtraInfoStyled>
              ) : null}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              {t("Email")}
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {formik.values.mail_address}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Reception time")}
                {isEdit && <Tag backgroundColor={theme.red2}>{"必須"}</Tag>}
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              {isEdit ? (
                <TextField
                  name={"reception_info"}
                  height={"50px"}
                  value={formik.values.reception_info}
                  onChange={formik.handleChange}
                  error={formik.errors.reception_info}
                  bgcolor={"#ffffff"}
                  maxLength={100}
                />
              ) : (
                formik.values.reception_info
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"button-wrapper"}>
              {isEdit && (
                <OwnerButton
                  typeOf={"primary"}
                  color={"#ffffff !important"}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  text={t("Save")}
                  isLoading={isLoading}
                />
              )}
            </div>
          </>
        )}
      </PageCard>
    </Wrapper>
  )
}

export { CompanyDetailCard }
