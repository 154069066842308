/**
 * NOTE:
 * - R6 April [amended:改定]
 */

import { t } from "i18next"
import { AdditionType } from "../../../../../constants/enums/additioinType"
import { Field } from "../fields"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getProvisionTimeDivision(date: Moment) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline
    default:
      return r6April
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

/**
 * @en Provision time division (former: Child with a disability classification)
 * @ja 提供時間区分（旧：障害児状 態等区分)
 */
const r6April: Field = {
  label: t(
    "Provision time division (former: Child with a disability classification)"
  ), // xaina
  name: AdditionType.PROVISION_TIME_DIVISION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Division 1"), // en: Division, 1 ja:  区分1
      value: 2,
    },
    {
      label: t("Division 2"), // en: Division 2, ja: 区分2
      value: 3,
    },
    {
      label: t("Division 3"), // en: Division 3, ja: 区分3
      value: 4,
    },
  ],
}

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */

/**
 * @en Provision time division (former: Child with a disability classification)
 * @ja 提供時間区分（旧：障害児状 態等区分)
 */
const baseline: Field = {
  label: t(
    "Provision time division (former: Child with a disability classification)"
  ), // xaina
  name: AdditionType.PROVISION_TIME_DIVISION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Division 1"), // en: Division, 1 ja:  区分1
      value: 2,
    },
    {
      label: t("Division 2"), // en: Division 2, ja: 区分2
      value: 3,
    },
  ],
}
