import { API } from "@project/shared"
interface Args {
  company_id: number
  program_category_name: string
}

export interface FacilityAutomaticMailSettingResponseObj {
  company_id?: number
  created_by?: string
  created_datetime: string
  deleted_by?: string
  deleted_datetime?: string | null
  id: number
  is_deleted?: boolean
  program_category_name: string
  updated_by?: string | null
  updated_datetime?: string | null
}

export const createFacilityAutomaticMailSetting = (values: Args) =>
  API.post(`/facility-mail-settings`, { ...values })

export const updateFacilityAutomaticMailSetting = ({
  values,
  id,
}: {
  values: Args
  id: number
}) => API.put(`/facility-mail-settings/${id}`, { ...values })

export const fetchAllFacilityAutomaticMailSetting = ({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) => API.get(`/facility-mail-settings?page=${page}&pageSize=${pageSize}`)

export const fetchFacilityAutomaticMailSetting = (id: number) =>
  API.get(`/facility-mail-settings/${id}`)

export const fetchFacilityAutoMailSettingsByFacilityId = (id: number) =>
  API.get(`/facility-mail-settings/facility/${id}`)
