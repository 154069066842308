import React, { useRef } from "react"

// packages
import { t } from "i18next"
import moment from "moment"

// styled components
import { Container } from "./styles"

// components
import { OwnerButton } from "../../atoms"
import { FilterActionBlock, FormatOutputBlock } from "./components"

// types
import { AllBankFormatOutputPageProps } from "./types"

// utils
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { scrollToSelectedElement } from "../../../utils"

export const AllBankFormatOutputPage: React.FC<
  AllBankFormatOutputPageProps
> = ({
  tableData,
  facilityOpt,
  facilityName,
  temporarySaveData,
  transferAccountInfo,
  currentSearchQueries,
  parentWithNoAccountInfo,
  handleSearchQueriesChange,
  isAllBankFormatDataLoading,
  handleTemporarySaveDataChange,
  billingMethod,
}) => {
  const { month, year, facility_id } = currentSearchQueries
  const [updateParams] = useUpdateSearchParams()
  const contentRef = useRef<HTMLDivElement>(null)
  const handleBackBtnClick = () => {
    updateParams(
      {
        facility_id: facility_id,
        year: year,
        month: month,
      },
      "/national-health-insurance-billing"
    )
  }

  return (
    <Container>
      <FilterActionBlock
        facilityOpt={facilityOpt}
        currentSearchQueries={currentSearchQueries}
        handleSearchQueryChange={(values: any) => {
          handleSearchQueriesChange(values)
          scrollToSelectedElement(contentRef)
        }}
      />
      <OwnerButton
        className={"back-btn no-print"}
        typeOf={"draft"}
        text={t("<< Back")}
        onClick={handleBackBtnClick}
      />
      <div ref={contentRef}>
        <FormatOutputBlock
          tableData={tableData}
          facilityName={facilityName}
          temporarySaveData={temporarySaveData}
          transferAccountInfo={transferAccountInfo}
          parentWithNoAccountInfo={parentWithNoAccountInfo}
          transferAccount={currentSearchQueries.transferAccount}
          yearMonth={
            year &&
            month &&
            moment(`${year}-${month.toString().padStart(2, "0")}`)
          }
          isAllBankFormatDataLoading={isAllBankFormatDataLoading}
          handleTemporarySaveDataChange={handleTemporarySaveDataChange}
          billingMethod={billingMethod}
          facilityId={currentSearchQueries?.facility_id}
        />
      </div>
      <OwnerButton
        className={"back-btn no-print"}
        typeOf={"draft"}
        text={t("<< Back")}
        onClick={handleBackBtnClick}
      />
    </Container>
  )
}
