import React, { useMemo } from "react"
import { Table } from "antd"
import { Wrapper } from "./IndividualSupportPlansSchedule.style"
import { TIME_INTERVALS } from "@project/shared"
import { useTranslation } from "react-i18next"
import { IndividualSupportWeeklyScheduleObj } from "../../../services"
export const IndividualSupportPlansSchedule: React.FC<{
  dataSource: IndividualSupportWeeklyScheduleObj[]
}> = ({ dataSource }) => {
  const { t } = useTranslation()
  const defaultDataSource = Array.from({ length: 1 }, (_, i) => ({
    key: i,
  }))

  const findStartIndexAndRowSpanValue = (data) => {
    const rows = TIME_INTERVALS?.map((val) => val?.value)
    const withIndex = data?.map((val) => {
      const startIndex = rows?.findIndex((v) => v === val?.start_time)
      const endIndex = rows?.findIndex((v) => v === val?.end_time)
      const value =
        startIndex < 0 || endIndex < 0
          ? 0
          : rows?.findIndex((v) => v === val?.end_time) -
            rows?.findIndex((v) => v === val?.start_time)
      const rowSpan = value < 0 ? 0 : value
      return {
        ...val,
        startIndex: rows?.findIndex((v) => v === val?.start_time),
        endIndex: rows?.findIndex((v) => v === val?.end_time),
        rowSpan: rowSpan,
      }
    })

    return withIndex
  }
  const renderColumnContent = (i, week_no, data) => {
    return (
      <div className={"content"}>
        {data?.map((val, i) => (
          <div key={i} className={"content__value"}>
            <span className={"time"}>
              {val?.start_time}
              {"-"}
              {val?.end_time}
            </span>
            <span className={"comment"}>{val?.comment}</span>
          </div>
        ))}
      </div>
    )
  }

  const columns: any = useMemo(
    () => [
      {
        key: 1,
        width: 200,
        title: t("Weekly calendar"),
        className: "top_col",
        children: [
          {
            key: 1,
            width: 200,
            title: t("Monday"),
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 1) || []
              )
              return renderColumnContent(i, 1, data || [])
            },
          },
          {
            key: 2,
            title: t("Tuesday"),
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 2) || []
              )
              return renderColumnContent(i, 2, data || [])
            },
          },
          {
            key: 3,
            title: t("Wednesday"),
            render: (_, __, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 3) || []
              )
              return renderColumnContent(i, 3, data || [])
            },
          },
          {
            key: 4,
            title: t("Thursday"),
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 4) || []
              )
              return renderColumnContent(i, 4, data || [])
            },
          },
          {
            key: 5,
            title: t("Friday"),
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 5) || []
              )
              return renderColumnContent(i, 5, data || [])
            },
          },
          {
            key: 6,
            title: t("Saturday"),
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 6) || []
              )
              return renderColumnContent(i, 6, data || [])
            },
          },
          {
            key: 7,
            title: t("Sunday"),
            colSpan: 1,
            render: (row, _, i) => {
              const data = findStartIndexAndRowSpanValue(
                dataSource?.filter((val) => val?.week_no === 7) || []
              )
              return renderColumnContent(i, 7, data || [])
            },
          },
        ],
      },
    ],
    [dataSource]
  )

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={defaultDataSource}
        pagination={false}
        bordered
        scroll={{ x: true }}
      />
    </Wrapper>
  )
}
