import { theme } from "@project/shared"
import styled from "styled-components"
export const MainContainer = styled.div`
  padding: 36px 0;
`
export const MainWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .top_ctas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 426px) {
      gap: 20px;
    }
  }

  @media screen and (max-width: 426px) {
    .delete_btn {
      margin-left: auto;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    .inner-grid-content {
      width: 100%;
    }
    .grid-header {
      width: 300px;
      padding: 28px 55px 28px 18px;
    }
    .label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .flex-row {
      display: flex;
      column-gap: 20px;
      .lbl {
        width: 300px;
      }
      @media screen and (min-width: 872px) and (max-width: 951px) {
        flex-wrap: wrap;
        row-gap: 10px;
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        column-gap: 0px;
        row-gap: 0px;
        .lbl {
          margin-left: 5px;
        }
      }
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: ${theme.black};
    }

    .create_count {
      display: flex;
      align-items: center;
      gap: 5px;
      div {
        width: 80px;
      }
      span {
        word-break: normal;
        white-space: nowrap;
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        div:first-child {
          margin-right: 4px;
        }
      }
    }
    input,
    textarea {
      background-color: #ffffff;
    }

    .has-error-container {
      border: 1px solid #e00000;
    }
    .has-error-container-nest > div {
      border: 1px solid #e00000 !important;
    }
    .has-error input,
    .has-error select,
    .has-error textarea {
      border: 1px solid #e00000;
    }
    .pick_up_content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .radio_text_content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          label {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      flex-wrap: nowrap;
      .grid-header {
        width: 100%;
        padding: 10px;
      }
      .pick_up_content > .radio_text_content {
        flex-direction: column;
        align-items: flex-start;
      }
      .flex-row {
        align-items: flex-start;
        /* flex-direction: column; */
        row-gap: 5px;
        .lbl {
          width: 100%;
        }
      }
      .create_count {
        div {
          width: 100px;
        }
      }
    }
  }
  .table_content {
    overflow-x: auto;
    white-space: nowrap;
    th.no-display-head {
      display: none;
    }
  }
`
export const BtnsContainer = styled.div`
  display: flex;
  row-gap: 20px;
  flex-wrap: wrap;
  column-gap: 10px;
  @media (max-width: 513px) {
    .draft-btn {
      margin-left: auto;
    }
  }

  @media (max-width: 515px) {
    justify-content: center;

    .draft-btn {
      margin: auto;
    }
  }

  .del-cta {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px;
    margin: 8px 0;
    button {
      padding: 5px 10px;
    }
    width: 100%;
  }
`
