import {
  CALANDER_HOURS_MINUTES,
  PICK_DROP_CDS_OPTIONS,
  PICK_DROP_OPTIONS,
  SelectInput,
  theme,
} from "@project/shared"
import { Input, Switch } from "antd"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import * as Yup from "yup"
import { t } from "i18next"

const ToggleContainer = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  padding: 4px;
  width: 100%;
  background: #ffffff;
  .schedule-info-card {
    margin: 10px 0;
    padding: 8px 4px;
    border-radius: 5px;
    background: ${theme.skyblue};
  }
  .facility-select-container {
    background: ${theme.skyblue};
    button {
      padding: 8px;
      margin: 8px 0;
    }
  }
  .transport-time-select {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  .pick-tag {
    padding: 2px 8px;
    border-radius: 4px;
    color: #ffffff;
    background: ${theme.green1};
  }
  .drop-tag {
    padding: 2px 8px;
    border-radius: 4px;
    color: #ffffff;
    background: ${theme.blue5};
  }
  .transport-switch {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &.disabled-card {
    background: rgba(215, 215, 215, 0.7);
    .overlay-cards {
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(215, 215, 215, 0.7);
        z-index: 99;
      }
    }
    .schedule-info-card {
      background: transparent;
    }
    .facility-select-container {
      background: #ffffff;
    }
  }

  .input-field-with-error-msg-wrapper {
    display: flex;
    flex-direction: column;
    .error-message {
      color: ${theme.red2};
      font-size: 12px;
      line-height: 100%;
    }
  }
`

const CustomInput = styled(Input)`
  height: 30px;
  margin: 4px 0;
  border-radius: 4px;
  &:hover,
  &:focus {
    border-color: #d9d9d9;
  }
`

// validation schema
const validationSchema = Yup.object({
  pickup_place_name: Yup.string().when("pickup_place", {
    is: "others",
    then: Yup.string().typeError(t("Required")).required(t("Required")),
  }),
})

export const RecurringScheduleCalendarComponent = ({
  facilities,
  data,
  onUpdate,
  facilityActivities,
  isCDSChild,
}) => {
  const { t } = useTranslation()
  const same_place_name = ["school", "home"]
  const initialValues = {
    id: data?.id ?? 0,
    week_no: data?.week_no,
    day_of_week: data?.day_of_week,
    facility_id: data?.facility_id ?? null,
    pickup: data?.pickup ?? 1,
    pickup_place: data?.pickup_place
      ? !same_place_name.includes(data?.pickup_place)
        ? "others"
        : data?.pickup_place
      : null,
    pickup_place_name: data?.pickup_place
      ? !same_place_name.includes(data?.pickup_place)
        ? data?.pickup_place
        : null
      : null,
    pickup_hour: data?.pickup_time ? data?.pickup_time?.split(":")[0] : null,
    pickup_minute: data?.pickup_time ? data?.pickup_time?.split(":")[1] : null,
    drop: data?.drop ?? 1,
    drop_place: data?.drop_place
      ? !same_place_name.includes(data?.drop_place)
        ? "others"
        : data?.drop_place
      : null,
    drop_place_name: data?.drop_place
      ? !same_place_name.includes(data?.drop_place)
        ? data?.drop_place
        : null
      : null,
    drop_hour: data?.drop_time ? data?.drop_time?.split(":")[0] : null,
    drop_minute: data?.drop_time ? data?.drop_time?.split(":")[1] : null,
    remarks: data?.remarks ?? null,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: null,
  })

  const getFacilitiesForTheDay = () => {
    const dayActivities = []
    facilityActivities?.map((fact) => {
      if (fact.facility_id == formik.values.facility_id) {
        fact?.activities?.map((act) => {
          if (
            act?.week_no == formik.values.week_no &&
            act?.day_of_week == formik.values.day_of_week
          ) {
            dayActivities.push(act?.activity)
          }
        })
      }
    })
    if (dayActivities.length == 0) dayActivities.push(t("No Activity"))
    return dayActivities
  }

  const [activities, setActivities] = useState(getFacilitiesForTheDay())

  useEffect(() => {
    setActivities(getFacilitiesForTheDay())
  }, [formik.values.facility_id])

  const handleChange = (name, value) => {
    const oldValues = { ...formik.values }
    const newValues = { ...oldValues, [name]: value }
    if (name == "pickup_place") {
      if (value == "others") newValues.pickup_place_name = null
      else newValues.pickup_place_name = value
    } else if (name == "drop_place") {
      if (value == "others") newValues.drop_place_name = null
      else newValues.drop_place_name = value
    } else if (name == "pickup_hour") {
      if (value && !newValues.pickup_minute) newValues.pickup_minute = "00"
    } else if (name == "drop_hour") {
      if (value && !newValues.drop_minute) newValues.drop_minute = "00"
    }
    formik.setValues(newValues)
    const is_delete = name == "facility_id" && !value ? true : false
    if (is_delete) onUpdate({ ...oldValues, is_delete })
    else
      onUpdate({
        ...newValues,
        is_delete,
      })
  }

  const handleCancel = () => {
    formik.setValues({
      ...formik.values,
      facility_id: null,
      pickup: 1,
      pickup_place: null,
      pickup_place_name: null,
      pickup_hour: null,
      pickup_minute: null,
      drop: 1,
      drop_place: null,
      drop_place_name: null,
      drop_hour: null,
      drop_minute: null,
      remarks: null,
    })
    if (formik.values.id) onUpdate({ ...formik.values, is_delete: true })
  }

  const Toggler = ({ handleToggleChange, currentState }) => {
    const [activeState, setActiveState] = useState(currentState)
    const onToggle = (newState) => {
      setActiveState(newState)
      handleToggleChange(newState)
    }
    return (
      <ToggleContainer>
        <Switch checked={activeState} onChange={() => onToggle(!activeState)} />
      </ToggleContainer>
    )
  }

  return (
    <Wrapper
      className={!formik.values.facility_id && "disabled-card"}
      id={`week-${data?.week_no}-day-${data?.day_of_week}`}
    >
      <div className={`schedule-info-card facility-select-container`}>
        <label>
          {t("Facility")}
          {" : "}
        </label>
        <SelectInput
          width={140}
          placeholder={"--"}
          options={[{ value: null, label: "--" }].concat(facilities)}
          name={"facility_id"}
          onChange={(val) => {
            handleChange("facility_id", val)
          }}
          value={formik.values.facility_id}
        />
        <OwnerButton
          typeOf={"secondary"}
          text={t("Cancel")}
          onClick={handleCancel}
        />
      </div>
      <div className={"overlay-cards"}>
        <div className={"schedule-info-card program-container"}>
          <label>
            {t("Program")}
            {" : "}
          </label>
          <div className={"programs"}>
            {activities.map((activity) => (
              <>
                {activity}
                <br />
              </>
            ))}
          </div>
        </div>
        <div className={"schedule-info-card transport-container"}>
          <div className={"transport-switch"}>
            <span className={"pick-tag"}>{t("Pickup")}</span>
            {
              <Toggler
                handleToggleChange={(val) => handleChange("pickup", val)}
                currentState={formik.values.pickup}
              />
            }
          </div>
          <div className={"transport-place"}>
            <label>
              {t("Place")}
              {" : "}
            </label>
            <SelectInput
              options={isCDSChild ? PICK_DROP_CDS_OPTIONS : PICK_DROP_OPTIONS}
              placeholder={"--"}
              value={formik.values.pickup_place}
              disabled={!formik.values.pickup}
              onChange={(val) => {
                handleChange("pickup_place", val)
              }}
            />
            {formik.values.pickup_place == "others" && (
              <div className={"input-field-with-error-msg-wrapper"}>
                <CustomInput
                  value={formik.values.pickup_place_name}
                  disabled={!formik.values.pickup}
                  minLength={0}
                  maxLength={50}
                  status={formik.errors.pickup_place_name && "error"}
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    handleChange("pickup_place_name", e.target.value)
                  }
                />
                {formik.errors.pickup_place_name && (
                  <span className={"error-message"}>
                    {formik.errors?.pickup_place_name
                      ? typeof formik.errors.pickup_place_name == "string"
                        ? formik.errors.pickup_place_name
                        : formik.errors.pickup_place_name[0]
                      : null}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={"transport-time"}>
            <label>
              {t("Time")}
              {" : "}
            </label>
            <div className={"transport-time-select"}>
              <SelectInput
                width={60}
                options={CALANDER_HOURS_MINUTES.hours}
                placeholder={"--"}
                value={formik.values.pickup_hour}
                disabled={!formik.values.pickup}
                onChange={(val) => handleChange("pickup_hour", val)}
                className={"left-align"}
                padding={"0px 5px"}
              />
              {" : "}
              <SelectInput
                width={60}
                options={CALANDER_HOURS_MINUTES.minutes}
                placeholder={"--"}
                value={formik.values.pickup_minute}
                disabled={!formik.values.pickup || !formik.values.pickup_hour}
                onChange={(val) => handleChange("pickup_minute", val)}
                className={"left-align"}
                padding={"0px 5px"}
              />
            </div>
          </div>
        </div>
        <div className={"schedule-info-card transport-container"}>
          <div className={"transport-switch"}>
            <span className={"drop-tag"}>{t("Dropoff")}</span>
            <Toggler
              handleToggleChange={(val) => handleChange("drop", val)}
              currentState={formik.values.drop}
            />
          </div>
          <div className={"transport-place"}>
            <label>
              {t("Place")}
              {" : "}
            </label>
            <SelectInput
              options={isCDSChild ? PICK_DROP_CDS_OPTIONS : PICK_DROP_OPTIONS}
              placeholder={"--"}
              value={formik.values.drop_place}
              disabled={!formik.values.drop}
              onChange={(val) => handleChange("drop_place", val)}
            />
            {formik.values.drop_place == "others" && (
              <CustomInput
                value={formik.values.drop_place_name}
                disabled={!formik.values.drop}
                minLength={1}
                maxLength={15}
                onChange={(e) =>
                  handleChange("drop_place_name", e.target.value)
                }
              />
            )}
          </div>
          <div className={"transport-time"}>
            <label>
              {t("Time")}
              {" : "}
            </label>
            <div className={"transport-time-select"}>
              <SelectInput
                width={60}
                options={CALANDER_HOURS_MINUTES.hours}
                placeholder={"--"}
                value={formik.values.drop_hour}
                disabled={!formik.values.drop}
                onChange={(val) => handleChange("drop_hour", val)}
              />
              {" : "}
              <SelectInput
                width={60}
                options={CALANDER_HOURS_MINUTES.minutes}
                placeholder={"--"}
                value={formik.values.drop_minute}
                disabled={!formik.values.drop || !formik.values.drop_hour}
                onChange={(val) => handleChange("drop_minute", val)}
              />
            </div>
          </div>
        </div>
        <div className={"schedule-info-card remarks-container"}>
          <label>
            {t("Remarks")}
            {" : "}
          </label>
          <CustomInput
            value={formik.values.remarks}
            onChange={(e) => handleChange("remarks", e.target.value)}
            disabled={!formik.values.facility_id}
            maxLength={20}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
    </Wrapper>
  )
}
