import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PageCard, SelectInput } from "@project/shared"
import { OptionWrapper } from "./SearchOption.style"
import { RightOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../../molecules"
import moment from "moment"
import { useFormik } from "formik"

export const SearchOperation = ({
  facilityOptions,
  secondaryBtnText,
  searchOptions,
  onDisplayChange,
  handleReset,
}: {
  facilityOptions?: Array<any>
  printBtnText?: string
  secondaryBtnText?: string
  searchOptions?: any
  onDisplayChange?: (val) => void
  handleReset?: () => void
}) => {
  const { t } = useTranslation()

  const initialValues = {
    facility_id: searchOptions?.facility_id,
    year: searchOptions?.year,
    month: searchOptions?.month,
  }
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      onDisplayChange(formik.values)
    },
  })

  useEffect(() => {
    formik.setValues(searchOptions)
  }, [searchOptions])

  return (
    <PageCard title={t("Search options")}>
      <OptionWrapper>
        <div className={"content"}>
          <div className={"icon_label"}>
            <RightOutlined />
            <Typography.Text>{t("Facility")}</Typography.Text>
          </div>
          <div className={"input_picker"}>
            <SelectInput
              placeholder={t("Select facility")}
              options={facilityOptions?.map((val) => ({
                value: `${val?.id}`,
                label: val?.facility_name_short,
              }))}
              height={40}
              width={"auto"}
              className={"dynamic-dropdown-width"}
              value={`${formik.values?.facility_id}`}
              onChange={(val) => formik.setFieldValue("facility_id", val)}
              dropdownMatchSelectWidth={false}
            />
          </div>
        </div>
        <div className={"content"}>
          <div className={"icon_label"}>
            <RightOutlined />
            <Typography.Text>{t("Year Month")}</Typography.Text>
          </div>
          <div className={"input_picker-date"}>
            <DatePicker
              date={
                formik.values.year && formik.values.month
                  ? moment()
                      .year(formik.values.year)
                      .month(+formik.values.month - 1)
                  : moment()
              }
              format={"YYYY年MM月"}
              picker={"month"}
              onDateChange={(val) => {
                if (val) {
                  formik.setFieldValue("year", val.format("YYYY"))
                  formik.setFieldValue("month", val.format("MM"))
                } else {
                  formik.setFieldValue("year", moment().format("YYYY"))
                  formik.setFieldValue("month", moment().format("MM"))
                }
              }}
              style={{
                height: "40px",
              }}
            />
          </div>
        </div>
        <div className={"content"}>
          <div className={"btn_ctas"}>
            <OwnerButton
              text={t("Search")}
              shape={"circle"}
              typeOf={"search"}
              onClick={() => {
                formik.handleSubmit()
              }}
            />
            <OwnerButton
              text={secondaryBtnText || t("Reset")}
              shape={"circle"}
              typeOf={"secondary"}
              onClick={() => {
                handleReset()
              }}
            />
          </div>
        </div>
      </OptionWrapper>
    </PageCard>
  )
}
