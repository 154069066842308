import { Table } from "@project/shared"
import { buildColumns } from "./utils"
import {
  ReceiptUpperLimitManagement,
  SheetData,
  SheetsDispatcher,
} from "../../../../types"
import { HeaderParentName } from "../HeaderParentName"
import { HeaderChildName } from "../HeaderChildName"
import { CSSProperties } from "react"

type Props = {
  receiptUpperLimitManagement: ReceiptUpperLimitManagement
  sheets: SheetData
  setSheets: SheetsDispatcher
  style?: CSSProperties
}

export function UpperLimitManagementListItem({
  receiptUpperLimitManagement,
  sheets,
  setSheets,
  style = {},
}: Props) {
  return (
    <div style={style}>
      <HeaderParentName name={receiptUpperLimitManagement.parent_name} />
      <HeaderChildName
        name={receiptUpperLimitManagement.child_name}
        facilityCount={receiptUpperLimitManagement.facilities.length}
        managementResult={receiptUpperLimitManagement.management_result}
      />

      <div className={"table-container"}>
        <Table
          columns={buildColumns(
            sheets,
            setSheets,
            receiptUpperLimitManagement.facilities.length
          )}
          dataSource={receiptUpperLimitManagement.facilities}
          scroll={{
            x: "max-content",
          }}
        />
      </div>
    </div>
  )
}
