import { theme } from "@project/shared"
import styled from "styled-components"

export const DataContainer = styled.div`
  .ant-table-tbody {
    text-align: center;
  }

  .min-width {
    min-width: 30px !important;
    max-width: 30px !important;
  }
  .min-width-long {
    min-width: 10px !important;
    max-width: 30px !important;
  }
  .min-width-facility {
    min-width: 130px !important;
  }

  .details_cell {
    min-width: 80px;
    max-width: 90px;
  }

  .month-pagination-wrapper button {
    @media (max-width: 450px) {
      height: 40px;
    }
  }
  @media (max-width: 450px) {
    margin-top: -18px;
  }
  @media (max-width: 450px) {
    .calendar_pagination {
      margin-right: -12px;
    }
  }
  .tabs-btns {
    margin-top: 27px;
    margin-bottom: 17px;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 11px 30px 27px 30px;

  table th {
    white-space: pre-wrap;
    text-align: center;
    font-size: 14px;
    padding: 5px !important;
  }

  .facility-table {
    thead tr th {
      border: solid 1px #d2d1d1 !important;
    }
    /* .ant-table-tbody:last-child {
      background-color: red !important;
    } */

    /* .ant-table-tbody:nth-last-child() {
      background-color: red !important;
    } */
  }
  .ant-select-selector {
    padding: 0 10px !important;
  }
  .flex-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  [class*="FacilityApplications__FlexBox"] {
    display: flex;
  }
  .container {
    padding: 18px 15px;
    border: 1px solid #d2d1d1;
    .program-container {
      .one-program {
        padding-bottom: 5px;
        font-size: 14px;
        word-break: break-word !important;
        &:not(:last-child) {
          border-bottom: 1px dashed #d2d1d1;
        }
      }
    }
  }
  .saturday {
    color: #0782c8;
  }
  .sunday {
    color: #e00000;
  }
  tbody {
    .ant-table-cell {
      padding: 0 !important;
      position: relative;
    }
  }
  .time-flex {
    position: relative;
    left: -0.2em;
  }
  .working-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 2px !important;
    word-break: break-all;
    a {
      color: #0782c8;
      text-decoration: underline;
      font-size: 14px !important;
    }
    span {
      font-size: 14px !important;
    }
  }

  .yellow {
    height: 100%;
    background-color: #feecaf;
    .ant-radio-inner {
      background: #feecaf !important;
    }
  }
  .normal-radio {
    .ant-radio-inner {
      background: white !important;
    }
  }
  .holiday-cell {
    width: 100%;
    min-height: 63px;
    background-color: rgba(224, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-tabs-tab-active {
    border-top: 1px solid #d2d1d1 !important;
    border-left: 1px solid #d2d1d1 !important;
    border-right: 1px solid #d2d1d1 !important;
    border-bottom: 3px solid #0782c8 !important;
    border-radius: 10px 10px 0 0 !important;
    * {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 400;
    }
  }
  .cancel-waiting {
    td {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        background-color: ${theme.gray2} !important;
        .ant-radio-inner {
          background: white !important;
        }
        div {
          background-color: ${theme.gray2} !important;
        }
      }
    }
  }
  /* .ant-table-content {
    overflow: scroll;
  } */
  table {
    min-width: 800px;
  }
  .ant-tabs {
    height: 42px;
  }
  .application-legend {
    margin-bottom: 24px;
  }
  .schedule-settings {
    margin-left: 18px;
    color: #0782c8;
    text-decoration: underline;
    padding-bottom: 2px;
  }
  .btns-grid {
    gap: 10px;
    width: auto;
    margin-bottom: 20px;
  }
  .tab-btns {
    padding: 7px 10px;
  }
  .tab-btns:first-child {
    padding: 7px 16px;
  }
  .sm-breakpoint {
    display: none;
  }
  .nav-btns {
    display: flex;
    justify-content: space-between;
  }
  .check-filters {
    margin-bottom: 20px;
    .ant-checkbox-inner::after {
      display: none;
    }
    @media (max-width: 768px) {
      * {
        font-size: 14px;
      }
    }
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      background: #d2d1d1;
    }
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: #feecaf !important;
    }
  }
  .blue-checkbox {
    .ant-checkbox-inner {
      border: 1px solid #d2d1d1;
      background: white !important;
    }
    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner::after {
        color: black;
      }
      .ant-checkbox-inner {
        border: 1px solid #d2d1d1;
        background: #cde6f4 !important;
      }
    }
    label {
      span {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }

  .facility-application-activity-container {
    display: flex;
    justify-content: flex-start !important;
    .radio-group {
      padding-left: 5px;
      .ant-radio-wrapper {
        margin-right: 8px;
        > span:not(:first-child) {
          display: none;
        }
      }
    }
  }
  .edit-table {
    .ant-table-cell {
      height: 40px;
    }
    .ant-table-cell:last-child {
      min-width: 80px;
    }
    .holiday-cell {
      min-height: 79px;
      font-size: 14px;
    }
    .working-cell {
      min-height: 79px;
      font-size: 14px;
    }
  }
  .ant-table-thead tr:first-child {
    th:nth-last-child(2),
    th:nth-last-child(3) {
      min-width: 85px;
    }
  }
  @media (max-width: 576px) {
    .nav-btns {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    .sm-breakpoint {
      display: block;
    }
    .container {
      padding: 12px;
    }
    .schedule-settings > a {
      font-size: 14px;
    }
    button {
      * {
        font-size: 14px;
      }
    }
  }
  .sm-justify-sb {
    justify-content: start;
  }
  @media (max-width: 1250px) {
    .sm-rev {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
    .sm-justify-sb {
      justify-content: space-between;
    }
  }
  .cal-2 {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 11px 20px 24px 20px;
  }

  @media (max-width: 840px) {
    .cal-1 {
      display: none;
    }
    .cal-2 {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .btns-grid {
      flex-direction: column;
      align-items: flex-end;
    }
    .last_item {
      padding-bottom: 0 !important;
    }
  }

  @media (max-width: 960px) {
    .ant-tabs {
      height: 62px;
      * {
        font-size: 14px;
      }
    }
    .btns-grid {
      width: 100%;
    }
    .sm-left-align {
      justify-content: end;
    }
    .ant-tabs-tab-btn {
      max-width: 120px;
      height: auto;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .sm-display {
    display: none;
  }
  .sm-no-display {
    display: block;
  }
  @media (max-width: 430px) {
    .sm-no-display {
      display: none;
    }
    .sm-display {
      display: block;
    }
  }
  @media (max-width: 768px) {
    table {
      * {
        font-size: 14px;
      }
    }
  }
  @media print {
    background: unset;
    box-shadow: none;
    border-radius: none;
    padding: none;
    table {
      table-layout: fixed;
      width: 100%;
      page-break-inside: auto;
    }

    table th {
      font-size: 10px;
      min-width: 50px !important;
      width: 50px !important;
      word-break: break-all;
      white-space: pre-wrap;
    }
    .working-cell,
    .holiday-cell {
      font-size: 10px;
    }
    .container {
      padding: none;
      border: none;
    }
    .cal-2,
    .sm-rev,
    .nav-btns,
    .btns-grid {
      display: none;
    }
  }
`
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  @media print {
    .title {
      font-size: 14px;
    }
  }
`

interface IRecurringScheduleAlertProps {
  marginBottom?: string
}

export const RecurringScheduleAlert = styled.div<IRecurringScheduleAlertProps>`
  width: 100%;
  padding: 20px;
  border: ${theme.green1};
  color: ${theme.green1};
  background: white;
  border-radius: 10px;
  margin-bottom: ${(props) => props?.marginBottom || "0px"};
  h1 {
    color: ${theme.green1};
    font-weight: 900;
    font-size: large;
  }
`
