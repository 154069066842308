import { TopRoundedWhiteCard, SelectInput, theme } from "@project/shared"
import { Row, Col } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import { useEffect } from "react"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"

const OperationWrapper = styled.div`
  padding: 20px 0;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .flexbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #191919;
  }
  .change-display-container {
    margin-top: 10px;
  }
  .operation-label,
  .operation-option {
    .ant-select-selector {
      height: 40px !important;
      align-items: center;
    }
  }
  @media (max-width: 768px) {
    .ant-row {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      display: grid;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      .operation-option > .facility-select {
        width: 150px;
      }
      .operation-option {
        width: 100%;
        .ant-picker {
          width: 100%;
        }
      }
    }

    .operation-label,
    .operation-option {
      max-width: 100%;
    }
  }
  .date-label {
    margin-right: 10px;
  }
`

interface IOperationOptions {
  initialValues: {
    year: number
    month: number
    facility: number | null
  }
  facilityOptions: Array<any> | null
  handleDisplayChange: any
}

const defaultInitials = {
  year: null,
  month: null,
  facility: null,
}

export const AttendanceScheduleOperationOptions = ({
  initialValues = defaultInitials,
  facilityOptions,
  handleDisplayChange,
}: IOperationOptions) => {
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      handleDisplayChange(formik.values)
    },
  })

  useEffect(() => {
    formik.setValues(initialValues)
  }, [initialValues])

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Facility")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <SelectInput
              width={300}
              name={"facility"}
              options={facilityOptions}
              defaultValue={initialValues.facility}
              onChange={(val) => formik.setFieldValue("facility", val)}
              className={"facility-select"}
              placeholder={t("All")}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Year months")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <DatePicker
              date={moment({
                year: +formik.values.year,
                month: +formik.values.month - 1,
              })}
              style={{
                height: "40px",
              }}
              picker={"month"}
              format={"YYYY年MM月"}
              showArrow={true}
              disabledDate={(current) => {
                return (
                  (current && current < moment().subtract(5, "year")) ||
                  (current && current > moment().add(3, "year"))
                )
              }}
              onDateChange={(value) => {
                formik.setFieldValue("year", +moment(value).format("YYYY"))
                formik.setFieldValue("month", +moment(value).format("MM"))
              }}
              className={"date-picker"}
            />
          </Col>
        </Row>
        <div className={"change-display-container"}>
          <OwnerButton
            text={t("Change display")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
