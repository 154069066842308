// Packages
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { t } from "i18next"

// Components
import { ActualCostBurdenTableBlock } from "./ActualCostBurdenTableBlock"
import { OperationActionBlock } from "./OperationActionBlock"

// Types
import moment from "moment"
import { FieldWrapperProps, TableHeaderProps } from "../types"

const OperationFieldWrapper: React.FC<FieldWrapperProps> = ({
  id,
  label,
  children,
}) => {
  return (
    <div className={"input-group flexbox"}>
      <label className={"caret-right label"} htmlFor={id}>
        {label}
      </label>
      <div className={"input-field"}>{children}</div>
    </div>
  )
}

const TableHeader: React.FC<TableHeaderProps> = ({
  currentDay,
  currentYear,
  currentMonth,
  handleDateChange,
  currentFacilityName,
}) => {
  return (
    <div className={"flexbox table-head"}>
      <h4 className={"table-title"}>
        <span>
          {t(
            "{{facilityName}} {{year}}年{{month}}月{{day}}日Actual cost burden list",
            {
              year: currentYear.toString().padStart(2, "0"),
              month: currentMonth.toString().padStart(2, "0"),
              day: currentDay.toString().padStart(2, "0"),
              facilityName: currentFacilityName,
            }
          )}
        </span>
      </h4>
      <div className={"month-controller"}>
        <Button
          onClick={() => {
            const getMomentDate = moment({
              year: +currentYear,
              month: +currentMonth,
              day: +currentDay,
            }).subtract(1, "days")
            handleDateChange(
              getMomentDate.year(),
              getMomentDate.month(),
              getMomentDate.date()
            )
          }}
          className={"left-btn"}
        >
          <DoubleLeftOutlined />
          <span className={"left-btn-label"}>{t("prev day")}</span>
        </Button>
        <Button
          className={"active active-btn"}
          onClick={() => {
            handleDateChange(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              new Date().getDate()
            )
          }}
        >
          {t("current day")}
        </Button>
        <Button
          onClick={() => {
            const getMomentDate = moment({
              year: +currentYear,
              month: +currentMonth,
              day: +currentDay,
            }).add(1, "days")
            handleDateChange(
              getMomentDate.year(),
              getMomentDate.month(),
              getMomentDate.date()
            )
          }}
          className={"right-btn"}
        >
          <span className={"right-btn-label"}>{t("next day")}</span>
          <DoubleRightOutlined />
        </Button>
      </div>
    </div>
  )
}

export {
  ActualCostBurdenTableBlock,
  OperationActionBlock,
  OperationFieldWrapper,
  TableHeader,
}
