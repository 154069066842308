// Since antd select doesn't support name this populates all select
// input fields with name matching its id
// Example usage: useEffect(addNameToSelectFields, [])
export const addNameToSelectFields = () => {
  const inputs = document.getElementsByClassName(
    "ant-select-selection-search-input"
  )
  Array.from(inputs).forEach((input: any) => {
    if (input.type === "search") {
      input.setAttribute("name", input.id)
    }
  })
}

export const AllowNumbersOnly = (event) => {
  // Allow: backspace, delete, tab, escape, enter, and .
  if (
    [46, 8, 9, 27, 13, 110].includes(event.keyCode) ||
    // Allow: Ctrl+A, Command+A
    (event.keyCode === 65 &&
      (event.ctrlKey === true || event.metaKey === true)) ||
    // Allow: Ctrl+C, Command+C
    (event.keyCode === 67 &&
      (event.ctrlKey === true || event.metaKey === true)) ||
    // Allow: Ctrl+V, Command+V
    (event.keyCode === 86 &&
      (event.ctrlKey === true || event.metaKey === true)) ||
    // Allow: Ctrl+X, Command+X
    (event.keyCode === 88 &&
      (event.ctrlKey === true || event.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (event.keyCode >= 35 && event.keyCode <= 40)
  ) {
    // Let it happen, don't do anything
    return
  }
  // Ensure that it is a number and stop the keypress
  if (
    (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
    (event.keyCode < 96 || event.keyCode > 105)
  ) {
    event.preventDefault()
  }
}
