import React, { useEffect, useState } from "react"
//libraries
import moment from "moment"
import { Spin, Typography } from "antd"
import { useTranslation } from "react-i18next"

//style
import {
  CustomTableWrapper,
  MonthlyCalendarWrapper,
} from "./FacilityApplicationBulkPrint.style"
import {
  generateListApplicationData,
  generateSchedules,
} from "../../../utils/facilityApplications"

export const FacilityApplicationBulkPrint = ({
  facilityData,
  childId,
  year,
  month,
  contractedFacilities,
  isLoading,
  serviceName,
  displaySettings,
}: any) => {
  const { t } = useTranslation()
  const [schedules, setSchedules] = useState([])

  useEffect(() => {
    setSchedules([
      ...generateSchedules(
        childId,
        year,
        month,
        facilityData?.schedules,
        facilityData?.facility_holidays,
        contractedFacilities,
        facilityData?.facility_programs
      ),
    ])
  }, [month, facilityData])

  const data = generateListApplicationData(
    schedules,
    facilityData?.facility_holidays
  )

  return (
    <Spin spinning={isLoading}>
      <CustomTableWrapper>
        <thead>
          <th>{t("Sun")}</th>
          <th>{t("Mon")}</th>
          <th>{t("Tue")}</th>
          <th>{t("Wed")}</th>
          <th>{t("Thu")}</th>
          <th>{t("Fri")}</th>
          <th>{t("Sat")}</th>
        </thead>
        <tbody>
          <td colSpan={7}>
            <MonthlyCalendarWrapper
              tileContent={({ date }) => {
                const currentData = data?.find(
                  (val) => +val?.day === moment(date).date()
                )
                return (
                  <div className={"content facility-application-calendar-date"}>
                    {currentData?.is_holiday ? (
                      <div className={"holiday"}>
                        {/* hidden content for uniform date style in print */}
                        <span style={{ visibility: "hidden" }}>
                          {"holiday holiday holiday"}
                        </span>
                      </div>
                    ) : (
                      <>
                        {currentData ? (
                          <div className={"inner_item"}>
                            {displaySettings?.includes("8") && (
                              <div className={"service_tag"}>
                                {t(serviceName)}
                              </div>
                            )}
                            {displaySettings?.includes("1") && (
                              <Typography.Text className={"f_name"}>
                                {currentData?.facility_name || ""}
                              </Typography.Text>
                            )}
                            {displaySettings?.includes("2") &&
                              typeof currentData?.programs?.programs ==
                                "string" && (
                                <ul className={"program-list"}>
                                  {currentData?.programs?.programs
                                    ?.split(",")
                                    ?.map((program, index) => (
                                      <li key={index}>{program}</li>
                                    ))}
                                </ul>
                              )}
                            <div className={"btns_tags"}>
                              {displaySettings?.includes("3") &&
                                currentData?.go_to_meet_flag && (
                                  <div className={"pick_tag"}>
                                    {t("Pick up")}
                                  </div>
                                )}
                              {displaySettings?.includes("4") &&
                                currentData?.take_flag && (
                                  <div className={"drop_tag"}>
                                    {t("Drop off")}
                                  </div>
                                )}
                            </div>
                            {displaySettings?.includes("5") &&
                              currentData?.pick_time && (
                                <div className={"pick-drop-time"}>
                                  {t("Pickup")} <b>{currentData?.pick_time}</b>
                                </div>
                              )}
                            {displaySettings?.includes("6") &&
                              currentData?.drop_time && (
                                <div className={"pick-drop-time"}>
                                  {t("Dropoff")} <b>{currentData?.drop_time}</b>
                                </div>
                              )}
                            {displaySettings?.includes("7") &&
                              currentData?.complete_remarks && (
                                <div className={"pick-drop-time"}>
                                  {currentData?.complete_remarks}
                                </div>
                              )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                )
              }}
              formatDay={(_, date) => moment(date).format("DD")}
              showNavigation={false}
              locale={"ja"}
              calendarType={"US"}
              activeStartDate={new Date(parseInt(year), month - 1, 1)}
            />
          </td>
        </tbody>
      </CustomTableWrapper>
    </Spin>
  )
}
