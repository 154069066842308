import React, { useEffect, useState } from "react"

// packages
import cuid from "cuid"
import { t } from "i18next"
import { useFormik } from "formik"

// styled components
import { GuideText, UsageRecordRewriteUsageContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { TopRoundedWhiteCard } from "@project/shared"
import { IncrementalUsageRecordForm } from "./IncrementalUsageRecordForm"

// types
import { InitialRewriteData, UsageRecordRewriteFormBlockProps } from "../types"

// constants
import {
  TEMPORARY_SAVE_KEY_USAGE_RECORD_REWRITE,
  initialUsageRecordFormValues,
  requestDataForUsageRecordRewrite,
} from "../constants"
import { getMultipleRewriteFormListSchema } from "../utils/validations"
import { notification } from "antd"
import { UsageRecordRewriteConfirmationPage } from "../../UsageRecordRewriteConfirmationPage"
import { useMutation } from "react-query"
import { saveNHIFUsageRecordRewrite } from "../../../../services/NHIFbilling"
import { useRouter } from "next/router"

export const UsageRecordRewriteFormBlock: React.FC<
  UsageRecordRewriteFormBlockProps
> = ({
  currentSearchQueries,
  childOptions,
  isChildListLoading,
  bulkSettingForPeriodDate,
  initialRecords,
  isConfirmationPage,
  setIsConfirmationPage,
}) => {
  const router = useRouter()
  // This is the state that manages the number of rewriting forms.
  // each individual form has a unique key in order to keep track of the form.
  const [totalUsageRecordForm, setTotalUsageRecordForm] = useState<
    Array<{ uniqueKey: string }>
  >(
    initialRecords
      ? Object?.keys(initialRecords)?.map((key) => {
          return {
            uniqueKey: key,
          }
        })
      : childOptions?.length > 1
      ? [{ uniqueKey: childOptions[1]?.value }]
      : []
  )

  /** [Important]:
   * This formik is handling multiple forms (That are can be added or deleted)
   * Data from each form is stored inside multipleRewriteFormList object with uniqueKey as key.
   * Unique key is generated by cuid() and is used to keep track of each form throughout the process.
   * Each form is validated by getMultipleRewriteFormListSchema function.
   */
  const formik = useFormik<{
    multipleRewriteFormList: {
      [key: string]: InitialRewriteData
    }
  }>({
    initialValues: {
      multipleRewriteFormList: initialRecords,
    },
    validationSchema: getMultipleRewriteFormListSchema(totalUsageRecordForm),
    onSubmit: () => {
      setIsConfirmationPage(true)
    },
  })

  const handleTemporarySave = () => {
    const requestData = requestDataForUsageRecordRewrite(
      formik.values.multipleRewriteFormList
    )
    localStorage.setItem(
      TEMPORARY_SAVE_KEY_USAGE_RECORD_REWRITE,
      JSON.stringify(requestData)
    )
    notification.success({
      message: t("Usage record rewriting form") + " " + t("Temporarily saved"),
    })
  }

  // This function adds total number of rewrite-forms by one with a unique key.
  // As well as it also add another key-value pair (as new rewrite-form data),
  // in formik where object-key being uniqueKey generated by cuid().
  const handleRewritingFormIncrement = () => {
    const newUniqueKey = cuid()
    setTotalUsageRecordForm([
      ...totalUsageRecordForm,
      {
        uniqueKey: newUniqueKey,
      },
    ])
    const initialFormData = initialUsageRecordFormValues(
      bulkSettingForPeriodDate.startDate,
      bulkSettingForPeriodDate.endDate
    )

    formik.setFieldValue(`multipleRewriteFormList.${newUniqueKey}`, {
      ...initialFormData,
    })
  }

  // This function deletes one specific form with the help of unique key.
  // It also removes that key-value pair from formik.
  const handleRewritingFormDeletion = (uniqueKey: string) => {
    const newTotalUsageRecordForm = totalUsageRecordForm.filter(
      (item) => item.uniqueKey !== uniqueKey
    )
    setTotalUsageRecordForm(newTotalUsageRecordForm)
    // remove that key value pair from formik
    formik.setFieldValue(`multipleRewriteFormList.${uniqueKey}`, undefined)
  }

  /**
   * This useEffect is used to set the startDate and endDate of each form
   * because, if bulkSettingForPeriodDate has startDate and endDate, it means
   * every form that is being added after that should have the same startDate and endDate.
   */
  useEffect(() => {
    if (
      bulkSettingForPeriodDate.startDate &&
      bulkSettingForPeriodDate.endDate
    ) {
      totalUsageRecordForm.forEach((key) => {
        formik.setFieldValue(
          `multipleRewriteFormList.${key.uniqueKey}.startDate`,
          bulkSettingForPeriodDate.startDate
        )
        formik.setFieldValue(
          `multipleRewriteFormList.${key.uniqueKey}.endDate`,
          bulkSettingForPeriodDate.endDate
        )
      })
    }
  }, [bulkSettingForPeriodDate])

  const { mutate: saveUsageRecord, isLoading: isSaving } = useMutation(
    () =>
      saveNHIFUsageRecordRewrite({
        facility: currentSearchQueries.facility,
        year: currentSearchQueries.year,
        month: currentSearchQueries.month,
        payload: requestDataForUsageRecordRewrite(
          formik.values.multipleRewriteFormList
        ),
      }),
    {
      onSuccess: () => {
        localStorage.removeItem(TEMPORARY_SAVE_KEY_USAGE_RECORD_REWRITE)
        notification.success({
          message:
            t("Usage record rewriting form") + " " + t("Updated Successfully"),
        })
        router.push(
          `/national-health-insurance-billing?facility_id=${currentSearchQueries.facility}&year=${currentSearchQueries.year}&month=${currentSearchQueries.month}`
        )
      },
      onError: (e: any) => {
        const msg = e?.data?.error?.message || e?.data?.error
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    }
  )

  if (isConfirmationPage) {
    return (
      <UsageRecordRewriteConfirmationPage
        confirmationData={formik.values.multipleRewriteFormList}
        handleBack={() => setIsConfirmationPage(false)}
        handleSave={saveUsageRecord}
        isSaving={isSaving}
      />
    )
  }

  return (
    <UsageRecordRewriteUsageContainer>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Usage record rewriting form")}
      >
        <div className={"multiple-rewrite-form-container"}>
          {totalUsageRecordForm.length > 0 &&
            totalUsageRecordForm.map((UsageRecordForm, index) => (
              <IncrementalUsageRecordForm
                key={UsageRecordForm.uniqueKey}
                childOptions={childOptions}
                formik={formik}
                uniqueKey={UsageRecordForm.uniqueKey}
                isChildListLoading={isChildListLoading}
                hasMoreThanOneForm={totalUsageRecordForm.length > 1}
                lastFormChild={index === totalUsageRecordForm.length - 1}
                handleRewritingFormIncrement={handleRewritingFormIncrement}
                handleRewritingFormDeletion={handleRewritingFormDeletion}
                handleTemporarySave={handleTemporarySave}
              />
            ))}
        </div>
      </TopRoundedWhiteCard>
      <div className={"footer-button-section no-print"}>
        <OwnerButton
          text={t("Proceed to confirmation page of transmission contents")}
          typeOf={"primary"}
          className={"proceed-btn"}
          onClick={() => formik.handleSubmit()}
        />
        <GuideText className={"guide-text"}>
          {t("Press the button only once and wait until the screen changes.")}
        </GuideText>
      </div>
    </UsageRecordRewriteUsageContainer>
  )
}
