import React from "react"
import { Typography } from "antd"

import styled from "styled-components"
import { CALANDER_HOURS_MINUTES, SelectInput, theme } from "@project/shared"

export const Container = styled.div`
  padding: 8px 14px 8px 10px;
  h5 {
    font-size: 14px;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      .time_sep,
      .range_sep {
        font-size: 18px;
        color: ${theme.black};
        font-weight: bold;
      }
      .time_sep {
        margin: 0 5px;
      }
      .range_sep {
        margin-left: 5px;
      }
    }
  }
  .ant-select-arrow {
    width: 10px;
  }

  .error-text {
    color: ${theme.red4};
    font-size: 12px;
    margin-left: 20px;
    @media screen and (max-width: 1200px) {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 415px) {
    .time-range-input {
      width: 50px;
    }
  }
`
type Args = {
  value: string
  id: number
  range: string
  unit: string
}
interface TimeRangePorps {
  range: any
  rangeName: string
  disabled?: boolean
  handleChange: (val: Args) => void
  className?: string
  minutesDisabled?: boolean
  error?: string
  name?: string
  width?: number
}

export const TimeRangeInput = ({
  range,
  rangeName,
  disabled = false,
  handleChange,
  className,
  minutesDisabled,
  error,
  name,
  width,
}: TimeRangePorps) => {
  return (
    <>
      <Container>
        <div className={`content ${className}`}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            {rangeName}
          </Typography.Title>
          <div className={"inputs"}>
            <div>
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.hours}
                value={range?.from?.hours}
                width={width || 60}
                height={40}
                placeholder={"--"}
                disabled={disabled}
                name={name}
                id={name}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "from",
                    unit: "hours",
                  })
                }
                padding={"0 5px"}
                className={"left-align time-range-input"}
                right={10}
              />
              <Typography.Text className={"time_sep"}>{":"}</Typography.Text>
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.minutes}
                value={range?.from?.minutes}
                width={width || 60}
                height={40}
                placeholder={"--"}
                disabled={
                  minutesDisabled === true
                    ? disabled || !range?.from?.hours
                    : null
                }
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "from",
                    unit: "minutes",
                  })
                }
                padding={"0 5px"}
                className={"left-align time-range-input"}
                right={10}
              />
              <Typography.Text className={"range_sep"}>{"~"}</Typography.Text>
            </div>
            <div>
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.hours}
                value={range?.to?.hours}
                width={width || 60}
                height={40}
                placeholder={"--"}
                disabled={disabled}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "to",
                    unit: "hours",
                  })
                }
                padding={"0 5px"}
                className={"left-align time-range-input"}
                right={10}
              />
              <Typography.Text className={"time_sep"}>{":"}</Typography.Text>{" "}
              <SelectInput
                options={CALANDER_HOURS_MINUTES?.minutes}
                value={range?.to?.minutes}
                width={width || 60}
                height={40}
                disabled={
                  minutesDisabled === true
                    ? disabled || !range?.from?.hours
                    : null
                }
                placeholder={"--"}
                onChange={(val) =>
                  handleChange({
                    value: val,
                    id: range?.id,
                    range: "to",
                    unit: "minutes",
                  })
                }
                padding={"0 5px"}
                className={"left-align time-range-input"}
                right={10}
              />
            </div>
          </div>
          <div className={"error-text"}>{error}</div>
        </div>
      </Container>
    </>
  )
}
