// packages
import * as Yup from "yup"
import { t } from "i18next"

// validation schema
export const bulkPeriodSettingsValidation = Yup.object().shape({
  endDate: Yup.date()
    .when("startDate", (startDate: any, schema: any) => {
      if (startDate) {
        return schema.min(
          startDate,
          t("End date should be greater than start date")
        )
      }
    })
    .nullable(),
})

export const getMultipleRewriteFormListSchema = (
  totalUsageRecordForm: {
    uniqueKey: string
  }[]
) => {
  return Yup.object().shape({
    multipleRewriteFormList: Yup.object().shape({
      ...totalUsageRecordForm.reduce((acc, item) => {
        return {
          ...acc,
          [item.uniqueKey]: Yup.object().shape({
            child_id: Yup.number()
              .required(t("Required"))
              .min(1, t("Required")),
            startDate: Yup.date().nullable(),
            endDate: Yup.date()
              .when("startDate", (startDate: any, schema: any) => {
                if (startDate) {
                  return schema.min(
                    startDate,
                    t("End date should be greater than start date")
                  )
                }
              })
              .nullable(),
          }),
        }
      }, {}),
    }),
  })
}
