import { theme } from "@project/shared"
import styled from "styled-components"
import { DataContainer } from "../FacilityApplications/index.styles"

export const ProgressContainer = styled(DataContainer)`
  box-shadow: 0px 0px 0px 0px;
  border: 1px solid #d2d1d1;
  border-radius: 12px;
  margin-bottom: 1.813rem;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 10px 20px 15px 20px;
  }
`

export const FlexContainer = styled.div<{ isCenter?: boolean }>`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-start;
  column-gap: 35px;
  row-gap: 20px;
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 1216px) {
    justify-content: ${(props) => (props.isCenter ? "flex-start" : "center")};
  }
  @media (max-width: 1020px) {
    column-gap: 24px;
    justify-content: center;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    column-gap: 20px;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    column-gap: 14px;
  }
`

export const ArrowDiv = styled.div<{ isActive?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 60px;
  background: ${({ isActive }) => (isActive ? "#ffffff" : theme.blue5)};
  color: ${({ isActive }) => (isActive ? theme.blue5 : "#fff")};
  text-align: center;
  border-radius: 5px;
  border: ${({ isActive }) => (isActive ? "solid 1px #0782c8" : "none")};
  width: 30%;
  max-width: 350px;
  padding: 0 10px;
  cursor: pointer;
  &:last-child {
    &:after {
      display: none;
    }
  }
  .number-container-main {
    display: flex;
    align-items: flex-end;
    margin-right: auto;
    gap: 2px;
    div {
      line-height: 100%;
    }
  }
  .number-container {
    margin-right: auto;
    margin-left: 1rem;
    background-color: ${({ isActive }) => (isActive ? theme.blue5 : "#fff")};
    width: 30px;
    height: 30px;
    color: ${({ isActive }) => (isActive ? "#ffffff" : theme.blue5)};
    border-radius: 50%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .text-container-1 {
    font-style: normal;
    margin-right: auto;
    font-weight: 400;
    font-size: 16px;
  }
  .number-container-2 {
    font-weight: 500;
    font-size: 28px;
  }
  .text-container-2 {
    font-weight: 700;
    font-size: 16px;
  }

  @media (max-width: 1371px) {
    width: 100%;
  }
  @media (max-width: 1130px) {
    height: auto;
    display: flex;
    align-items: center;
    gap: 0rem;

    justify-content: none;
    .text-container-1,
    .text-container-2,
    .number-container,
    .number-container-main {
      margin-right: 0;
    }
  }

  @media (max-width: 1200px) {
    padding: 10px;
    justify-content: space-evenly;
    font-size: 0.5rem;
    .text-container-1,
    .text-container-2 {
      font-size: 1rem;
    }
    .number-container {
      font-size: 1rem;
      height: 25px;
      width: 25px;
    }
  }

  @media (max-width: 812px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    align-items: center;
    .number-container {
      margin: 0;
      font-size: 0.8rem;
    }
    .text-container-1,
    .text-container-2 {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 541px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    height: 106px;
    width: 82px;
    flex-direction: column;
    align-items: center;
    .number-container {
      margin: 0;
      font-size: 0.8rem;
    }
    .text-container-1,
    .text-container-2 {
      font-size: 0.8rem;
    }
    .text-container-1 {
      word-wrap: break-word;
    }
    margin: 5px;
  }
  @media (max-width: 440px) {
    font-size: 0.2rem;
  }

  :after {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    right: 0;
    border: solid 1px #0782c8;
    border-bottom: none;
    border-left: none;
    background: ${({ isActive }) => (isActive ? "#ffffff" : theme.blue5)};
    top: 50%;
    z-index: -1;
    transform: translate(35%, -50%) rotate(45deg);
    @media (max-width: ${theme.breakpoints.md}) {
      transform: translate(25%, -50%) rotate(45deg);
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      transform: translate(10%, -50%) rotate(45deg);
    }
  }

  @media screen and (max-width: 400px) {
    height: auto;
  }
`
