import { API } from "@project/shared"
export interface RequestValues {
  nursery_school_name: string
  nursery_school_name_furigana: string
  division_nursery?: number
  division_school?: number
  division_high_school?: number
  division_junior_nursery?: number
  division_elementary?: number
  division_kindergarden?: number
  division_day_nursery?: number
  tel_no: string
}
export interface SingleDaySchoolResponse extends RequestValues {
  id: number
}
export const createDayNurserySchools = (values: RequestValues) =>
  API.post("/nursery-schools", { ...values })
export const fetchDayNurserySchools = ({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) => API.get(`/nursery-schools?page=${page}&pageSize=${pageSize}`)
export const fetchDayNurserySchool = (id: string) =>
  API.get(`/nursery-schools/${id}`)
export const updateDayNurserySchool = ({
  values,
  id,
}: {
  values: RequestValues
  id: string
}) => API.put(`/nursery-schools/${id}`, { ...values })

export const deleteDayNurserySchool = ({ id }: { id: string | number }) =>
  API.delete(`/nursery-schools/${id}`)
