import React from "react"
import styled, { css } from "styled-components"
import { Card } from "antd"
import { CardProps } from "antd/es/card"
import { ColumnsType } from "antd/es/table"
import { Button } from "../../atoms"
import { Table, theme } from "@project/shared"
import _ from "lodash"
export const WrapperCard = styled(Card)`
  border-radius: 12px;
  border: 0;
  .ant-card-head {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #d2d1d1;
    margin: 0 30px;
    padding: 0px 0;
    min-height: 25px;
    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0 20px;
      font-size: 15px !important;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 0 10px;
      font-size: 8px !important;
    }
  }
  .ant-card-body {
    padding: 15px 24px;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 15px 12px !important;
    }
  }
`
const CommonStyle = css`
  button {
    margin-bottom: 10px;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 12px;
      font-size: 12px !important;
    }
  }
`
export const Content = styled.div`
  ${CommonStyle}
  .ant-table {
    thead.ant-table-thead > tr > th.ant-table-cell,
    tbody.ant-table-tbody > tr > td.ant-table-cell {
      text-align: center;
    }
  }
`
interface IProps extends CardProps {
  columns: ColumnsType<any>
  dataSource: any[]
  emptyText?: string
  btnText: string
  extra?: React.ReactNode | string
  onClick?: () => void
}
export const InstructorInfo: React.FC<IProps> = ({
  columns,
  dataSource,
  emptyText,
  btnText,
  extra,
  onClick,

  ...rest
}) => {
  return (
    <WrapperCard {...rest}>
      <Content>
        <Button
          text={btnText}
          shape={"circle"}
          borderColor={theme.gray2}
          icon={"add"}
          backgroundColor={"transparent"}
          onClick={onClick}
        />
        {extra && extra}
        <Table
          columns={columns}
          dataSource={_.orderBy(
            dataSource,
            ["year", "month"],
            ["desc", "desc"]
          )}
          emptyText={emptyText}
          scroll={{ x: "max-content" }}
          className={"always-present-scrollbar"}
        />
      </Content>
    </WrapperCard>
  )
}
