import React, { createContext, useContext, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useRouter } from "next/router"

import { AuthContext, scrollToSelectedElement } from "../utils"
import { getRemunerationCalculations } from "../services"
import { getLocalstorageItem } from "@project/shared"

interface SearchParams {
  fiscal_year?: number
  fiscal_month?: number
  fiscal_day?: number
  service_type?: string
  print_config?: string
  facility: null | string | []
  tempFacilities?: [
    {
      label: string
      value: string
    }
  ]
}
interface RemunerationCalculationContextProps {
  searchParams: SearchParams
  onFilter?: (values: Partial<SearchParams>) => void
  isLoading: boolean
  response?: any
  breadcrumbItems?: any
  dataSource?: any
  defaultFacility?: any
  calculatePercentage?: (amount: number, total: number) => number
  isQueryChanged?: boolean
  setIsQueryChanged: (val: boolean) => void
  contentRef?: any
  detailDataSource?: any[]
}
const RemunerationCalculationContext = createContext<
  Partial<RemunerationCalculationContextProps>
>({})
const RemunerationCalculationContextProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const router = useRouter()
  const storedData = getLocalstorageItem(
    "remuneration-calculation",
    "facility_ids"
  )
  const { facilities } = useContext(AuthContext)
  const contentRef = useRef<HTMLDivElement>(null)
  const defaultFacilities = facilities
  const {
    facility,
    fiscal_year,
    fiscal_month,
    fiscal_day,
    service_type,
    print_config,
  } = router?.query as any
  const facilityValues = facility?.split(",")
  const filteredFacilities = facilities?.filter((facilityItem) => {
    return facilityValues?.includes(facilityItem.id.toString())
  })

  // calculates the default number of selected facilities based on  conditions.
  const defaultSelected = facility
    ? facility?.split(",")?.map((val) => val)?.length
    : storedData?.split(",")?.map((val) => val)?.length || facilities?.length

  //slices this array from the start to defaultSelected index
  const temFacility = filteredFacilities.length
    ? filteredFacilities?.map((li) => ({
        facility_id: li?.id,
        facility_name: li?.facility_name,
        facility_name_short: li?.facility_name_short,
        pref_id: li?.pref_id,
        pref_id2: li?.pref_id2,
      }))
    : defaultFacilities
        ?.map((li) => ({
          facility_id: li?.id,
          facility_name: li?.facility_name,
          facility_name_short: li?.facility_name_short,
          pref_id: li?.pref_id,
          pref_id2: li?.pref_id2,
        }))
        .slice(0, defaultSelected)

  const [isQueryChanged, setIsQueryChanged] = useState(false)
  const [searchParams, setSearchParams] = useState({
    fiscal_year: fiscal_year ? +fiscal_year : new Date().getFullYear(),
    fiscal_month: fiscal_month ? +fiscal_month : new Date().getMonth() + 1,
    fiscal_day: fiscal_day ? +fiscal_day : new Date().getDate(),
    service_type: service_type || "2",
    facility: facility
      ? facility?.split(",")?.map((val) => +val)
      : storedData?.split(",")?.map((val) => +val) ||
        defaultFacilities?.map((li) => li.id).slice(0, defaultSelected) ||
        "",
    tempFacilities: temFacility,
    print_config: print_config || "",
  })

  const [remunerationPrintData, setRemunerationPrintData] = useState([])

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["remuneration-calculation", searchParams],
    queryFn: () => getRemunerationCalculations(searchParams),
    select: (res) => {
      return {
        msg: res?.msg?.map((val) => ({
          ...val,
        })),
      }
    },
    onSuccess: (res) => {
      setRemunerationPrintData(res?.msg || [])
    },
    onSettled: () => {
      if (isQueryChanged) {
        setIsQueryChanged(false)
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!searchParams?.facility,
    cacheTime: 0,
    retry: 1,
  })

  const defaultTableData = searchParams?.tempFacilities?.map((val) => {
    const v = response?.msg?.find(
      (v: any) => v.facility_id === (val.id || val.facility_id)
    )
    if (v) {
      return v
    }
    return {
      ...val,
    }
  })
  const calculatePercentage = (total, amount) => {
    if (amount && total) {
      const percentage = (amount / total) * 100
      return percentage
    }
    return 0
  }
  return (
    <RemunerationCalculationContext.Provider
      value={{
        defaultFacility: searchParams?.facility,
        dataSource: defaultTableData,
        detailDataSource: remunerationPrintData || [],
        isLoading: isLoading || isFetching,
        response,
        calculatePercentage,
        searchParams,
        isQueryChanged: isQueryChanged,
        setIsQueryChanged: setIsQueryChanged,
        contentRef: contentRef,
        onFilter: (values) => {
          setSearchParams({
            ...searchParams,
            service_type: "2",
            ...values,
          })
          scrollToSelectedElement(contentRef)
        },
      }}
    >
      {children}
    </RemunerationCalculationContext.Provider>
  )
}

const useRemunerationCalculationContext = () => {
  const context = useContext(RemunerationCalculationContext)
  if (context === undefined) {
    throw new Error(
      "RemunerationCalculationContext must be used within a RemunerationCalculationContextProvider"
    )
  }
  return context
}
export {
  RemunerationCalculationContextProvider,
  useRemunerationCalculationContext,
}
