// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared/src/theme"

export const AddItemEditOperationWrapper = styled.div`
  padding: 20px 0;
  .buttons-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    @media (max-width: 610px) {
      justify-content: center;
      gap: 10;
      button:last-child {
        margin-left: 12px;
      }
    }
  }
  .child-dev-support-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: ${theme.black};
    margin-bottom: 10px;
  }
`

export const StyledFieldWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${theme.gray2};
  .label {
    width: 25.42%;
    max-width: 320px;
    min-width: 320px;
    border-right: 1px solid ${theme.gray2};
    text-align: left;
    background-color: ${theme.bgColor2};
    padding: 20px 10px 20px 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    span {
      ${theme.typography.label}
      color: ${theme.black}
    }
    .icons-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      .required {
        color: ${theme.base};
        background-color: ${theme.red2};
        border-radius: 5px;
        padding: 3px 8px;
        font-size: 12px;
      }
    }

    @media (max-width: 780px) {
      gap: 5px;
    }
  }
  .field {
    width: calc(100% - 300px);
    padding: 20px 20px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    border: 0px;
    .label {
      min-height: 40px;
      width: 100%;
      border-right: none;
      min-width: 100%;
      max-width: 100%;
      border: 1px solid ${theme.gray2};
      padding: 5px 20px;
    }
    .field {
      width: 100%;
      padding-inline: 0;
    }
  }
`

export const FlexChildren = styled.div<{ isNoGap?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 424px) {
    gap: ${(props) => (props?.isNoGap ? "0" : "10px")};
    flex-direction: ${(props) => (props?.isNoGap ? "column" : "row")};
    align-items: ${(props) => (props?.isNoGap ? "flex-start" : "center")};
    .period_checkbox {
      margin-top: 10px;
    }
  }
  > label > span:last-child {
    text-wrap: wrap;
  }
`
