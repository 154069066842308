import { API, removeBlankAttributes } from "@project/shared"
import { MutationFunction } from "react-query"

interface IJointFacilityContractParams {
  page?: string
  pageSize?: string
  keyword?: string
  child_id?: string | number
}

export interface UsersNeedingCertificateRenewalParams {
  child_id?: number
  service?: string
  facility?: string
  date?: string
  expiry_date?: string
  beneficiary_information_to_check?: string
  status?: string
  page?: number
  pageSize?: number | string
  children_without_services?: string
}

export const getChildReceivingCertificates = async (id: number) => {
  return API.get(`/child-receiving-certificates/${id}`)
}

export const updateChildReceivingCertificate: MutationFunction<
  unknown,
  any
> = async (values) => {
  return API.put(`/child-receiving-certificates/${values.id}`, values)
}

export const addChildRecevingCertificate: MutationFunction<
  unknown,
  any
> = async (values) => {
  return API.post(`/child-receiving-certificates`, values)
}

export const getAllChildBenefitContracts = (childId) => {
  if (!isNaN(+childId))
    return API.get(`/child-contract-benefit-day?child_id=${childId}`)
}

export const getAllChildBenefitContractsWithoutCertificate = (childId) => {
  if (!isNaN(+childId))
    return API.get(
      `/child-contract-benefit-day/without-certificate?child_id=${childId}`
    )
}

export const getAllChildBenefitContractsWithCertificate = (
  certificateId,
  query = ""
) => {
  return API.get(
    `/child-contract-benefit-day/certificate/${certificateId}${query}`
  )
}

export const getContract = (id: number) => {
  return API.get(`/child-contract-benefit-day/${id}`)
}

export const addAllContracts = async (values) => {
  return API.post("/child-contract-benefit-day", values)
}

export const updateAllContracts = (values) => {
  return API.put(`/child-contract-benefit-day/${values.id}`, values)
}

export const deleteContract = (id: number) => {
  return API.delete(`/child-contract-benefit-day/${id}`)
}

export const getAllJointFacilityContractPayment = (queryKeys?: any) => {
  const initialParams = {} as IJointFacilityContractParams
  initialParams.child_id = queryKeys?.child_id
  initialParams.pageSize = "Infinity"
  const params = removeBlankAttributes(initialParams)
  return API.get(`/facility-contract-payment`, {
    params,
  })
}

export const getAllJointFacilityContractPaymentWithoutCertificate = (
  queryKeys?: any
) => {
  const initialParams = {} as IJointFacilityContractParams
  initialParams.child_id = queryKeys?.child_id
  initialParams.pageSize = "Infinity"
  const params = removeBlankAttributes(initialParams)
  return API.get(`/facility-contract-payment/without-certificate`, {
    params,
  })
}

export const getAllJointFacilityContractPaymentWithCertificateId = (
  queryKeys?: any
) => {
  const initialParams = {} as IJointFacilityContractParams
  initialParams.pageSize = !isNaN(queryKeys?.pageSize)
    ? queryKeys?.pageSize
    : "Infinity"
  const params = removeBlankAttributes(initialParams)
  return API.get(
    `/facility-contract-payment/certificate/${queryKeys?.certificate_id}`,
    {
      params,
    }
  )
}

export const getFacilityContractPaymentById = (id: number) => {
  return API.get(`/facility-contract-payment/${id}`)
}

export const addFacilityContractPayment = (values) => {
  return API.post(`/facility-contract-payment`, values)
}

export const deleteFacilityContractPayment = (id) => {
  return API.delete(`/facility-contract-payment/${id}`)
}

export const updateFacilityContractPayment = (values) => {
  return API.put(`/facility-contract-payment/${values?.id}`, values)
}

export const childReceivingCertificatesByChildId = async (
  childId: string | number
) => {
  return API.get(`/child-receiving-certificates/child/${childId}`)
}

export const getLatestChildRecipientCertificateByChildId = async (
  childId: string | number
) => {
  return API.get(`child-receiving-certificates/latest/${childId}`)
}

export const getLatestChildRecipientCertificate = async () => {
  return API.get(`child-receiving-certificates/recent`)
}

export const getRecipientCertificatesForFacility = (id: number | string) =>
  API.get(`/child-receiving-certificates/child-with-facility/${id}`)

export const getAllUsersNeedingCertificateRenewal = ({
  queries: queries,
}: {
  queries: UsersNeedingCertificateRenewalParams
}): Promise<{
  count: number
  data: any
}> => {
  const params = removeBlankAttributes(queries)
  const URL = "/child-receiving-certificates/users-needing-certificate-renewal"
  return API.get(URL, {
    params,
  })
}

export const deleteOneChildRecipientCertificate = (id) => {
  return API.delete(`/child-receiving-certificates/${id}`)
}
