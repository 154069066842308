/**
 * NOTE:
 * - R6 April [amended:改定]
 */

import { t } from "i18next"
import { AdditionType } from "../../../../../constants/enums/additioinType"
import { Field } from "../fields"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getAdditionalAllowanceForChildInstructors(date: Moment) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline
    default:
      return r6April
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

/**
 * @en Additional allowance for child instructors, etc.
 * @ja 児童指導員等加配加算
 */
const r6April: Field = {
  label: t("Additional allowance for child instructors, etc."),
  name: AdditionType.ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },

    {
      label: "常勤専従・経験５年以上",
      value: 11,
    },
    {
      label: "常勤専従・経験５年未満",
      value: 12,
    },
    {
      label: "常勤換算・経験５年以上",
      value: 13,
    },
    {
      label: "常勤換算・経験５年未満",
      value: 14,
    },
    {
      label: t("Other employees"), // en: Other employees, ja: その他の従業者
      value: 4,
    },
  ],
}

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */

/**
 * @en Additional allowance for child instructors, etc.
 * @ja 児童指導員等加配加算
 */
const baseline: Field = {
  label: t("Additional allowance for child instructors, etc."),
  name: AdditionType.ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Specialized staff (physical therapists, nursery teachers)"), // en: Specialized staff (physical therapists, nursery teachers), ja: 専門職員(理学療法士等・保育士)
      value: 2,
    },
    {
      label: t("Child instructor"), // en: Child instructor, ja: 児童指導員等
      value: 3,
    },

    {
      label: t("Other employees"), // en: Other employees, ja: その他の従業者
      value: 4,
    },
  ],
}
