import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { FilterSection } from "../../molecules/PrintCustomerInvoice/FilterSection"
import { AuthContext, scrollToSelectedElement } from "../../../utils"
import { OwnerButton } from "../../atoms"
import { useTranslation } from "react-i18next"
import { PageCard, TextField } from "@project/shared"
import { InvoiceTable } from "../../molecules/PrintCustomerInvoice/InvoiceTable"
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import {
  getNHIFBillingRemarks,
  getNHIFSelectedBillingChildList,
  saveNHIFBillingRemarks,
  getFinalizedBillingFacilities,
} from "../../../services/NHIFbilling"
import { Spin, notification } from "antd"
import { useFormik } from "formik"
import { Wrapper, BillAmountWarning } from "./InvoiceCustomerPage.style"

interface IPrintUserReceiptPage {
  data: Array<any>
  updateData: (val) => void
  updatePageType: () => void
}

export const PrintInvoiceCustomerPage = ({
  data,
  updateData,
  updatePageType,
}: IPrintUserReceiptPage) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const contentRef = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const { facility, facility_ids, year, month, after_school } = router?.query

  const selectedFacility = facilities?.find((f) => f?.id == +facility)

  const getInitiallyCheckedFacilities = () => {
    const initialCheckedFacilities =
      facility_ids
        ?.toString()
        ?.split(",")
        ?.map?.((f) => Number(f)) || []

    if (!initialCheckedFacilities?.includes(selectedFacility?.id))
      initialCheckedFacilities.push(selectedFacility?.id)

    return initialCheckedFacilities
  }

  if (!selectedFacility) {
    router.push("/national-health-insurance-billing")
    return <></>
  }

  const [searchOptions, setSearchOptions] = useState({
    facility_ids: getInitiallyCheckedFacilities(),
    output: "",
  })
  useEffect(() => {
    const facilityIdsParam = searchOptions.facility_ids
      ? searchOptions.facility_ids.join(",")
      : ""
    const updatedQuery = {
      ...router.query,
      facility_ids: facilityIdsParam,
    }
    router.push(
      {
        pathname:
          "/national-health-insurance-billing/prints/print-invoice-customer",
        query: updatedQuery,
      },
      undefined,
      {
        shallow: true,
      }
    )
  }, [searchOptions?.facility_ids])

  const { isLoading: isChildListLoading } = useQuery(
    ["get-child-list-for-invoice", searchOptions?.facility_ids],
    () => {
      return getNHIFSelectedBillingChildList({
        facilityIds: searchOptions?.facility_ids?.join(","),
        year: +year,
        month: month?.toString()?.padStart(2, "0"),
        after_school: after_school,
      })
    },
    {
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const updatedData = []
        for (const cityNumber in response?.data) {
          updatedData.push(
            response?.data[cityNumber]?.map((d) => {
              return { ...d, checked: true, provision_city_number: cityNumber }
            })
          )
        }
        updateData(updatedData)
      },
    }
  )

  const { data: facilityIdsWithFinalizedBilling } = useQuery(
    ["get-facilities-with-finalized-billing"],
    () => {
      const query = [`year=${+year}`, `month=${+month}`]

      if (after_school) {
        const after_school_string = after_school?.toString()
        if (after_school_string == "0") {
          query.push(`form_of_provision=2`)
        } else if (after_school_string == "1") {
          query.push(`form_of_provision=1`)
        }
      }

      return getFinalizedBillingFacilities(query?.join("&"))
    },
    {
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      select: ({ data }) => data?.map((d) => +d?.id),
    }
  )

  const selectedChildData = useMemo(
    () => data?.flat()?.filter((v) => v?.checked),
    [data]
  )
  const handleSearchReset = () => {
    setSearchOptions({
      facility_ids: [selectedFacility?.id],
      output: null,
    })
    scrollToSelectedElement(contentRef)
  }

  const FACILITY_OPTIONS = useMemo(() => {
    return facilities?.map((fac) => {
      const facilityOption = {
        label: fac.facility_name_short,
        value: fac.id,
        disabled: facilityIdsWithFinalizedBilling?.includes(+fac?.id)
          ? false
          : true,
        type: fac?.pref_id,
      }
      return facilityOption
    })
  }, [facilities, facilityIdsWithFinalizedBilling])

  const checkAllTableData = () => {
    const newData = data?.map((table) =>
      table?.map((d) => {
        return { ...d, checked: true }
      })
    )
    updateData(newData)
  }

  const uncheckAllTableData = () => {
    const newData = data?.map((table) =>
      table?.map((d) => {
        return { ...d, checked: false }
      })
    )
    updateData(newData)
  }

  const handleCheck = (id, e) => {
    const newData = data?.map((table) =>
      table?.map((d) => {
        if (d?.child_id == id) {
          if (d?.checked) d.checked = false
          else d.checked = true
        }
        return d
      })
    )
    updateData(newData)

    const checkedLists = []
    if (e.target.checked) {
      checkedLists.push(id)
    } else {
      const index = checkedLists.indexOf(id)
      if (index !== -1) {
        checkedLists.splice(index, 1)
      }
    }
  }

  const { mutate: saveRemarks } = useMutation(saveNHIFBillingRemarks, {
    onSuccess: () => {
      notification.success({
        message: t("Remarks") + " " + t("Updated Successfully"),
      })
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      year: +year,
      month: +month,
      after_school_text: "",
      child_development_text: "",
    },
    onSubmit: () => {
      saveRemarks(formik.values)
    },
  })

  useQuery(
    ["get-latest-remark"],
    () => getNHIFBillingRemarks({ year, month }),
    {
      retry: 1,
      keepPreviousData: false,
      onSuccess: ({ msg }: any) => {
        formik.setValues({
          year: +year,
          month: +month,
          after_school_text: msg?.after_school_text,
          child_development_text: msg?.child_development_text,
        })
      },
    }
  )
  const TotalAmountChargeToUser = () => {
    return selectedChildData?.reduce(
      (acc, cc) => {
        const total = acc?.billed_amount + cc?.billed_amount
        return {
          ...cc,
          billed_amount: total,
        }
      },
      { billed_amount: 0 }
    )
  }

  return (
    <Wrapper>
      <FilterSection
        facilityOptions={FACILITY_OPTIONS}
        searchOptions={searchOptions}
        onSearchUpdate={(values) => {
          setSearchOptions(values)
          scrollToSelectedElement(contentRef)
        }}
        onSearchReset={handleSearchReset}
        resetFacilityId={[selectedFacility?.id]}
      />
      <div className={"back-button-container"}>
        <OwnerButton
          text={`<< ${t("Back")}`}
          typeOf={"primary"}
          className={"back-button"}
          onClick={() =>
            router.push(
              `/national-health-insurance-billing?facility_id=${facility}&year=${year}&month=${month}`
            )
          }
        />
      </div>
      <div className={"print-receipt-content"} ref={contentRef}>
        <PageCard
          title={t(
            "{{YYYY}}年{{MM}}月{{facilityName}} Print invoice to users",
            {
              YYYY: +year,
              MM: month?.toString()?.padStart(2, "0"),
              facilityName: selectedFacility?.facility_name_short,
            }
          )}
        >
          {searchOptions?.facility_ids?.length > 0 ? (
            <BillAmountWarning>
              <div className={"icon"}>
                <img
                  src={"/assets/icons/exclamation.png"}
                  alt={"alert"}
                  height={18}
                  width={18}
                />
              </div>
              <div className={"content"}>
                {t(
                  "The billed amounts for the following facilities are totaled and output below."
                )}
                <br />
                {facilities
                  ?.filter?.((f) =>
                    searchOptions?.facility_ids?.includes(f?.id)
                  )
                  ?.map((fac, index) => (
                    <>
                      {index > 0 ? "、" : ""}
                      {fac?.facility_name_short}
                    </>
                  ))}
              </div>
            </BillAmountWarning>
          ) : (
            <></>
          )}
          <div className={"receipt-additional-options-container"}>
            <div className={"receipt-addition-info"}>
              {t(
                "If you want to include remarks on the bill to the user, please enter them below."
              )}
            </div>
            <div className={"label-value-block-container"}>
              <div className={"label"}>{t("After school day service")}</div>
              <div className={"value"}>
                <TextField
                  value={formik.values.after_school_text}
                  onChange={(e) =>
                    formik.setFieldValue("after_school_text", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={"label-value-block-container"}>
              <div className={"label"}>{t("Child development support")}</div>
              <div className={"value"}>
                <TextField
                  value={formik.values.child_development_text}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "child_development_text",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className={"temp-button-container"}>
              <OwnerButton
                typeOf={"primary"}
                text={t("Save temporarily")}
                className={"temp-button"}
                onClick={() => formik.handleSubmit()}
              />
            </div>
          </div>
          <div className={"table-container"}>
            <div className={"button-container"}>
              <OwnerButton
                typeOf={"check-all"}
                text={t("Check all")}
                className={"table-button"}
                onClick={checkAllTableData}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Clear")}
                className={"table-button"}
                onClick={uncheckAllTableData}
              />
              <OwnerButton
                typeOf={"print"}
                text={t("Print")}
                className={"print-button"}
                onClick={updatePageType}
                disabled={selectedChildData?.length <= 0}
              />
            </div>
            {/* few repeatations for UI */}
            {isChildListLoading ? (
              <div className={"loader-container"}>
                <Spin size={"large"} />
              </div>
            ) : (
              data?.map((data, index) => (
                <InvoiceTable
                  data={data}
                  key={index}
                  handleCheck={handleCheck}
                />
              ))
            )}
          </div>
          <div className={"print-button-container"}>
            <OwnerButton
              typeOf={"print"}
              text={t("Print")}
              className={"print-button"}
              onClick={updatePageType}
              disabled={selectedChildData?.length <= 0}
            />
            <div className={"invoice-amount"}>
              {t("Amount charged to the user total  {{amount}}円", {
                amount:
                  TotalAmountChargeToUser()?.billed_amount?.toLocaleString(),
              })}
            </div>
          </div>
        </PageCard>
        <div className={"back-button-container"}>
          <OwnerButton
            text={`<< ${t("Back")}`}
            typeOf={"primary"}
            className={"back-button"}
            onClick={() =>
              router.push(
                `/national-health-insurance-billing?facility_id=${facility}&year=${year}&month=${month}`
              )
            }
          />
        </div>
      </div>
    </Wrapper>
  )
}
