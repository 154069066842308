// packages
import { t } from "i18next"
import moment from "moment"
import { DatePicker as AntdDatePicker } from "antd"

// component
import {
  Checkbox,
  TextField,
  Checkboxes,
  SelectInput,
  RadioButtons,
} from "@project/shared"

// styled component
import {
  InfoText,
  HelperText,
  RequiredTag,
  DropDownContainer,
  DateRangeContainer,
  StyledFieldWrapper,
  DatePickerContainer,
  CheckboxesContainer,
  NumberFieldContainer,
  RadioButtonsContainer,
} from "../common/formFields.styles"
import { OwnerButton } from "../../../atoms"
import { DatePicker } from "../../DatePicker"

// types
import { FormikProps } from "formik"
import { FieldTypeOptionsProps, FieldsProps } from "../types"
import Link from "next/link"

export const FieldWrapper = ({
  label,
  children,
  required,
  hasInfoIcon,
  isRowFlexed,
  isFirstChild,
  labelClassName,
  fieldClassName,
}: {
  label: string
  children: JSX.Element
  required?: boolean
  hasInfoIcon?: boolean
  isRowFlexed?: boolean
  isFirstChild?: boolean
  labelClassName?: string
  fieldClassName?: string
}) => {
  return (
    <StyledFieldWrapper
      className={"fieldGen-field-wrapper"}
      $isRowFlexed={isRowFlexed}
      $isFirstChild={isFirstChild}
    >
      <div className={`label ${labelClassName ? labelClassName : ""}`}>
        <span>{t(label)}</span>
        {(required || hasInfoIcon) && (
          <div className={"icons-wrapper"}>
            {required && <RequiredTag>{t("Required")}</RequiredTag>}
            {hasInfoIcon && (
              <img
                src={"/assets/icons/QuestionMarkCircled.svg"}
                alt={"question mark icon"}
              />
            )}
          </div>
        )}
      </div>
      <div className={`field ${fieldClassName ? fieldClassName : ""}`}>
        {children}
      </div>
    </StyledFieldWrapper>
  )
}

export const GetField = ({
  name,
  label,
  width,
  formik,
  require,
  infoNoti,
  fieldType,
  className,
  helperText,
  isDisabled,
  placeholder,
}: FieldsProps & {
  formik: FormikProps<{
    [key: string]: any
  }>
} & { label: string }) => {
  const getIndividualField = (fieldType: FieldTypeOptionsProps) => {
    switch (fieldType.type) {
      case "date":
        return (
          <DatePickerContainer>
            <DatePicker
              date={formik?.values[name]}
              className={className}
              disabled={fieldType?.extraOptions?.isDisabled}
              onChange={(date) => {
                formik?.setFieldValue(name, moment(date))
              }}
              onDateChange={(date) => formik?.setFieldValue(name, moment(date))}
              error={
                formik?.errors[name] && formik?.touched[name] && t("Required")
              }
              format={
                fieldType.extraOptions.format
                  ? fieldType.extraOptions.format
                  : "YYYY年MM月DD日"
              }
              picker={fieldType?.extraOptions?.picker}
            />
          </DatePickerContainer>
        )
      case "checkbox":
        return isDisabled ? (
          <>
            {infoNoti && <p>{infoNoti}</p>}
            <Checkbox
              name={name}
              className={className}
              label={t(label)}
              checked={formik.values[name]}
              disabled
            />
          </>
        ) : (
          <>
            {infoNoti && <p>{infoNoti}</p>}
            <Checkbox
              name={name}
              className={className}
              label={t(label)}
              checked={formik.values[name]}
              onChange={(e) => {
                formik.setFieldValue(name, e.target.checked)
              }}
            />
          </>
        )
      case "checkboxes":
        return (
          <CheckboxesContainer className={className}>
            <Checkboxes
              name={name}
              value={
                fieldType.extraOptions.values
                  ? fieldType.extraOptions.values
                  : formik.values[name]
              }
              onChange={
                fieldType.extraOptions?.onChange
                  ? fieldType.extraOptions.onChange
                  : (values) => {
                      formik.setFieldValue(name, values)
                    }
              }
              options={fieldType.extraOptions.options}
            />
          </CheckboxesContainer>
        )

      case "text":
        return <p className={className}>{t(fieldType.text)}</p>

      case "link":
        return (
          <Link href={fieldType.link}>
            <a className={`underline ${className}`}>{fieldType.text}</a>
          </Link>
        )

      case "button":
        return (
          <OwnerButton
            className={className}
            type={fieldType.extraOptions.type}
            text={t(fieldType.extraOptions.text)}
            typeOf={fieldType.extraOptions.typeOf}
            onClick={fieldType.extraOptions.onClick}
          />
        )

      case "dropdown":
        return (
          <DropDownContainer className={`flex ${className}`}>
            {fieldType.extraOptions?.labelAlignment === "left" && label && (
              <>
                <span>{label}</span>
                {require && <RequiredTag>{t("Required")}</RequiredTag>}
              </>
            )}
            <SelectInput
              name={name}
              options={fieldType.extraOptions.option}
              required={require}
              disabled={isDisabled}
              value={formik.values[name]}
              className={`over-rider-select-class ${fieldType?.extraOptions?.selectInputClassName}`}
              dropdownMatchSelectWidth={
                fieldType?.extraOptions?.dropdownMatchSelectWidth
              }
              placeholder={fieldType.extraOptions.placeholder ?? "-----"}
              onBlur={formik.handleBlur}
              defaultValue={fieldType.extraOptions.initialValue}
              width={typeof width === "number" && width}
              loading={fieldType.extraOptions.isLoading}
              onChange={
                fieldType.extraOptions?.onChange
                  ? fieldType.extraOptions.onChange
                  : (val) => formik.setFieldValue(name, val)
              }
              error={
                formik.touched[name] &&
                formik.errors[name] &&
                formik.errors[name]
              }
            />
            {fieldType.extraOptions?.labelAlignment === "right" && label && (
              <>
                {require && <RequiredTag>{t("Required")}</RequiredTag>}
                <span>{label}</span>
              </>
            )}
          </DropDownContainer>
        )

      case "dateRange":
        return (
          <div>
            <DateRangeContainer className={className}>
              <AntdDatePicker
                onChange={
                  fieldType.extraOptions?.onStartDateChange
                    ? fieldType.extraOptions.onStartDateChange
                    : (v) => {
                        formik.setFieldValue(
                          fieldType.extraOptions.startDateName,
                          v
                        )
                      }
                }
                name={fieldType.extraOptions.startDateName}
                value={
                  fieldType.extraOptions.startDateValue
                    ? fieldType.extraOptions.startDateValue
                    : formik.values[fieldType.extraOptions.startDateName]
                }
                allowClear={false}
                status={fieldType.extraOptions.startDateStatus}
                format={
                  fieldType.extraOptions.format
                    ? fieldType.extraOptions.format
                    : "YYYY年MM月DD日"
                }
              />
              <span className={"datePicker-separator"}>{"~"} </span>
              <AntdDatePicker
                onChange={
                  fieldType.extraOptions?.onEndDateChange
                    ? fieldType.extraOptions.onEndDateChange
                    : (v) =>
                        formik.setFieldValue(
                          fieldType.extraOptions.endDateName,
                          v
                        )
                }
                name={fieldType.extraOptions.endDateName}
                allowClear={false}
                status={fieldType.extraOptions.endDateStatus}
                value={
                  fieldType.extraOptions.endDateValue
                    ? fieldType.extraOptions.endDateValue
                    : formik.values[fieldType.extraOptions.endDateName]
                }
                format={
                  fieldType.extraOptions.format
                    ? fieldType.extraOptions.format
                    : "YYYY年MM月DD日"
                }
              />
            </DateRangeContainer>
            {fieldType.extraOptions.startDateError ||
            fieldType.extraOptions.endDateError ? (
              <p className={"error-message"}>
                {fieldType.extraOptions.startDateError}
                {"  "}
                {fieldType.extraOptions.endDateError}
              </p>
            ) : null}
          </div>
        )

      case "radio":
        return (
          <RadioButtonsContainer className={className}>
            <RadioButtons
              value={formik.values[name]}
              name={name}
              options={
                fieldType.extraOptions.option
                  ? fieldType.extraOptions.option
                  : []
              }
              className={className}
              onChange={
                fieldType.extraOptions.onChange
                  ? fieldType.extraOptions.onChange
                  : (e) => {
                      formik.setFieldValue(name, e.target.value)
                    }
              }
              error={
                formik.touched[name] &&
                formik.errors[name] &&
                formik.errors[name]
              }
            />
          </RadioButtonsContainer>
        )

      case "input":
        return (
          <div
            style={{
              width: width && typeof width === "string" ? width : `${width}px`,
            }}
          >
            <TextField
              name={name}
              bgcolor={"white"}
              placeholder={placeholder}
              className={className}
              width={width && typeof width === "string" ? width : `${width}px`}
              onChange={(e) => formik.setFieldValue(name, e.target.value)}
              value={
                formik.values[name] === undefined ? "" : formik.values[name]
              }
            />
            {helperText && (
              <HelperText className={"helper-text"}>{t(helperText)}</HelperText>
            )}
          </div>
        )

      case "textarea":
        return (
          <div
            style={{
              width: width && typeof width === "string" ? width : `${width}px`,
            }}
          >
            <TextField
              name={name}
              bgcolor={"white"}
              placeholder={placeholder}
              className={className}
              type={"textarea"}
              width={width && typeof width === "string" ? width : `${width}px`}
              onChange={(e) => formik.setFieldValue(name, e.target.value)}
              rows={fieldType.extraOptions?.rows}
              maxLength={fieldType.extraOptions?.maxLength}
              minLength={fieldType.extraOptions?.minLength}
              value={
                formik.values[name] === undefined ? "" : formik.values[name]
              }
            />
            {(helperText || fieldType.extraOptions?.helperText) && (
              <HelperText className={"helper-text"}>
                {t(helperText ? helperText : fieldType.extraOptions.helperText)}
              </HelperText>
            )}
          </div>
        )

      case "number":
        return (
          <NumberFieldContainer className={"fieldGen-number-field-container"}>
            {fieldType.extraOptions.labelAlignment === "left" &&
              fieldType.extraOptions.label && (
                <span>{t(fieldType.extraOptions.label)}</span>
              )}
            <TextField
              type={"number"}
              height={"40px"}
              maxLength={fieldType.extraOptions.maxLength || 3}
              min={fieldType.extraOptions.min}
              max={fieldType.extraOptions.max}
              placeholder={placeholder}
              className={className}
              value={
                formik.values[name] === undefined ? "" : formik.values[name]
              }
              name={name}
              disabled={isDisabled}
              onChange={
                fieldType.extraOptions.onChange
                  ? fieldType.extraOptions.onChange
                  : (e) => formik.setFieldValue(name, e.target.value)
              }
              onBlur={formik.handleBlur}
              width={typeof width === "string" ? width : `${width}px`}
              error={
                formik.touched[name] &&
                formik.errors[name] &&
                formik.errors[name]
              }
            />
            {fieldType.extraOptions.labelAlignment === "right" &&
              fieldType.extraOptions.label && (
                <span>{t(fieldType.extraOptions.label)}</span>
              )}
          </NumberFieldContainer>
        )
      case "JSX-element":
        return (
          <div className={`${className} fieldGen-children-container`}>
            {fieldType.JSXElementData}
          </div>
        )

      default:
        return null
    }
  }

  return (
    <>
      {infoNoti && <InfoText>{t(infoNoti)}</InfoText>}
      {getIndividualField(fieldType)}
    </>
  )
}
