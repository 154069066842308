import { CHILD_GUIDENCE, Grid, PageCard } from "@project/shared"
import { Skeleton, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getWarekiDOB } from "../../../utils"

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`
type Iprops = {
  isLoading: boolean
  detail: any
}

export const ChildInformation: React.FC<Iprops> = ({ isLoading, detail }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  return (
    <PageCard title={t("Child information")}>
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Content>
          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Child name")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {t("{{childName}} ({{child_name_furigana}})", {
                childName: detail?.child_name,
                child_name_furigana: detail?.child_name_furigana,
              })}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Gender")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {detail?.gender === 1 ? t("Male") : t("Female")}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Date of birth")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {getWarekiDOB(
                detail?.birthday,
                false,
                currentLanguage === "ja" ? "JP" : "AD"
              )}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>
              {t("Beneficiary  certificate number")}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {detail?.receiving_certificate_number}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>
              {t("Consultation support office")}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {detail?.consultation_support?.consultation_support_name}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>
              {t("Consultation support specialist")}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {detail?.consultation_support_specialist}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("School")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {detail?.nursery_school_detail?.nursery_school_name}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Guidance")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>
              {
                CHILD_GUIDENCE.find(
                  (guidance) => guidance.value === detail?.guidance
                )?.label
              }
            </Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Teacher ")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>{detail?.teacher}</Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Allergy")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>{detail?.allergy_memo}</Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Symptoms")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>{detail?.symptoms_memo}</Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>
              {t("Special skill· thing of interest")}
            </Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>{detail?.good_at_interest_memo}</Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />

          <Grid className={"grid-header"} background>
            <Typography.Text>{t("Things to watch out for")}</Typography.Text>
          </Grid>
          <Grid className={"flex-full grid-row-last"}>
            <Typography.Text>{detail?.child_remark}</Typography.Text>
          </Grid>
          <Grid className={"flex-break"} />
        </Content>
      )}
    </PageCard>
  )
}
