// packages
import moment from "moment"
import EBCDIC from "ebcdic-ascii"
import Encoding from "encoding-japanese"

// types
import { ITableDataType } from "../types"
import { t } from "i18next"

export const extractDayAsPerMonth = (year: number, month: number) => {
  const yearMonth = moment(`${year}-${month}`)
  const days = []
  const daysInMonth = yearMonth.daysInMonth()
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i)
  }
  return days.map((day) => ({
    label: day,
    value: day,
  }))
}

export const getDataForCSV = (
  data: ITableDataType[][],
  hasTransferAccount: boolean
): any[] => {
  const extraHeader = hasTransferAccount
    ? [
        t("Account holder name"),
        t("Account type"),
        t("Branch name"),
        t("Bank number"),
      ]
    : []

  const header = [
    t("Parent name (Hiraga)"),
    t("Child name (Hiraga)"),
    t("Beneficiary certificate number"),
    t("Facility"),
    t("Invoice amount"),
    ...extraHeader,
  ]

  const spacer = []
  return data?.map((item) => {
    const newArr = item
      .filter((filterItem) => filterItem.checked)
      .map((itm) => {
        const extraData = hasTransferAccount
          ? [
              itm.account_holder_name,
              itm.account_type,
              itm.branch_name,
              itm.bank_no,
            ]
          : []

        return [
          itm.parent_name_hiraga,
          itm.child_name_hiraga,
          itm.beneficiary_certificate_number,
          itm.facility,
          itm.invoice_amount
            ? itm.billed_amount.toLocaleString() + "円"
            : "0円",
          ...extraData,
        ]
      })

    if (newArr?.length > 0) {
      const provisionCityHead =
        item[0]?.provision_city && item[0]?.city_code
          ? `${item[0].provision_city} ${`(${item[0].city_code})`}`
          : ""

      return [[provisionCityHead], header, ...newArr, spacer]
    }
    return [[]]
  })
}

export { formatOutputTableMapper } from "./mapper"

// Convert table data to JIS
export const TableDataToJIS = (tableDataInArray: any[][]) => {
  const arrayToString = tableDataInArray?.toString()

  const unicodeArray = Encoding.stringToCode(arrayToString) // Convert string to code array

  const jisArray = Encoding.convert(unicodeArray, {
    to: "JIS",
    from: "UNICODE",
  })

  return jisArray.toString()
}

// Convert table data to EBCDIC
export const TableDataToEBCDIC = (tableDataInArray: any[][]) => {
  const arrayToString = tableDataInArray?.toString()
  const converter = new EBCDIC("0037")
  let hexAsciiString = ""

  for (let i = 0; i < arrayToString.length; i++) {
    // hex code of character
    const charCode = arrayToString.charCodeAt(i)
    const hexAscii = charCode.toString(16).toUpperCase()
    hexAsciiString += hexAscii.padStart(2, "0")
  }

  return converter.toEBCDIC(hexAsciiString)
}
