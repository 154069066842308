import { API } from "@project/shared"
export interface RequestValuesObj {
  staff_name: string
  staff_name_furiganaame: string
  joining_date: string
  retirement_date: string
}
export interface InstructosResponseObj {
  id: number
  created_datetime?: string
  created_by?: string
  updated_datetime?: string
  updated_by?: string
  deleted_datetime: null
  deleted_by?: string
  is_deleted?: boolean
  company_id?: number
  staff_name: string
  staff_name_furiganaame: string
  joining_date: string
  retirement_date: string
  staff_facility: Array<
    {
      company_id: number
      staff_id: number
      staff: any
      year: number
      month: number
      facility_id: number
      workstyle_type: number
      occupation_id: number
      occupation: any
      manager_work_hour: number
      occupation_id_2: number
      occupation2: any
      manager_work_hour_2: number
      sun: number
      mon: number
      tue: number
      wed: number
      thu: number
      fri: number
      sat: number
    } & API.GenericResponse
  >
}

export interface Response {
  count: number
  data: InstructosResponseObj[]
}

export const createInstructor = (values: RequestValuesObj) =>
  API.post("/instructors", { ...values })

export const fetchInstructors = ({
  page,
  pageSize,
  searchParams,
}: {
  page?: number
  pageSize?: number | string
  searchParams?: {
    keyword?: string
    retirement_date?: string
    joining_date?: string
    facility?: string
    occupation?: string
  }
}): Promise<Response> => {
  let url = `/instructors?page=${page}&pageSize=${pageSize}`
  if (searchParams?.keyword) url = `${url}&keyword=${searchParams?.keyword}`
  if (searchParams?.joining_date)
    url = `${url}&joining_date=${searchParams?.joining_date}`
  if (searchParams?.retirement_date)
    url = `${url}&retirement_date=${searchParams?.retirement_date}`
  if (searchParams?.facility) {
    // api uses facility for single search & facilityIds for multiple search usually
    url = `${url}&facilityIds=${searchParams?.facility}`
  }
  if (searchParams?.occupation)
    url = `${url}&occupation=${searchParams?.occupation}`
  return API.get(url)
}

export const GetOneInstructor = (id: string): Promise<{ data: any }> =>
  API.get(`/instructors/${id}`)

export const updateInstructor = ({
  values,
  id,
}: {
  values: RequestValuesObj
  id: string
}) => API.put(`/instructors/${id}`, { ...values })

export const createInstructorInfo = (values: any) =>
  API.post("/instructors/occupation-facility", { ...values })

export const getQualification = () =>
  API.get("/qualifications?page=1&pageSize=Infinity")

export const GetShiftMasterNames = (facilityId: string) => {
  let url = "/attendance-shift"
  if (facilityId) url = `${url}?keyword=${facilityId}&page=1&pageSize=Infinity`
  return API.get(url)
}

export const getAllStaffs = (): Promise<any> => {
  return API.get("/instructors?page=1&pageSize=Infinity")
}

export const getStaffsByFacility = (values: any): Promise<any> => {
  if (Number(values.queryKey[1]) > 0)
    return API.get(
      `/instructors/facility-staffs?facilityId=${values.queryKey[1]}&page=1&pageSize=Infinity`
    )
}

export const deleteOneInstructor = (id: string) =>
  API.delete(`/instructors/${id}`)
