import { API } from "@project/shared"

interface AttendanceTableProps {
  facilityId: number
  year: number
  month: number
  query?: string
}

export const fetchAttendanceSchedule = async ({
  facilityId,
  year,
  month,
  query = "",
}: AttendanceTableProps) => {
  return API.get(
    `/child-attendance-chart/${facilityId}/${year}/${month}${query}`
  )
}

export const fetchAttendanceScheduleStats = async ({
  facilityId,
  year,
  month,
}: AttendanceTableProps) => {
  return API.get(`/child-attendance-chart/stats/${facilityId}/${year}/${month}`)
}

export const fetchStaffMonthlyAttendanceRecord = async ({
  facilityId,
  staffId,
  year,
  month,
}) => {
  return API.get(
    `/staff-attendance-results/${facilityId}/staff/${staffId}/${year}/${month}`
  )
}

export const updateStaffMonthlyAttendanceRecord = async (
  facilityId,
  payload
) => {
  return API.post(
    `/staff-attendance-results/${facilityId}`,
    JSON.stringify(payload)
  )
}
export const fetchChildAttendance = async (query) =>
  API.get(`/user-attendance?${query}`)

export const createChildAttendance = async (payload) =>
  API.post(`/user-attendance`, JSON.stringify(payload))

export const updateSnack = async (id, payload) =>
  API.put(`/user-attendance/update-snack/${id}`, payload)

export const updateChildEndtime = async (id, payload) =>
  API.put(`/user-attendance/checkout-child/${id}`, JSON.stringify(payload))

export const getChildsWithoutFacility = async (facilityId, date) =>
  API.get(
    `/child-attendance-chart/childs-without-schedule/${facilityId}/${date}`
  )

export const fetchBulkChildAttendance = async (query) =>
  API.get(`/user-attendance/bulk-edit?${query}`)

export const saveBulkChildAttendance = async (value) => {
  return API.post(`/user-attendance/bulk-edit`, JSON.stringify(value))
}

export const fetchAbsenceRecord = async (query) =>
  API.get(`/user-attendance/absence-record?${query}`)

export const getChildAttendanceActualCosts = (childId, date, facility) =>
  API.get(
    `/user-attendance/actual-cost-day/${childId}?date=${date}&facility=${facility}`
  )

export const GetTotalAbsentByFacilityMonth = (
  year,
  month,
  facility,
  child_id
) => API.get(`user-attendance/absence/${facility}/${year}/${month}/${child_id}`)

export const GetChildNextScheduledDate = (facility, child_id, date) =>
  API.get(
    `user-attendance/child-next-scheduled/${facility}/${child_id}/${date}`
  )
