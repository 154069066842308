const CopyIcon = () => (
  <svg
    width={"16"}
    height={"17"}
    viewBox={"0 0 16 17"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M4.26946 12.3081H2.20381C1.60223 12.3081 1.10938 11.8345 1.10938 11.2409V2.0672C1.10938 1.48059 1.59498 1 2.20381 1H10.162C10.7636 1 11.2564 1.47353 11.2564 2.0672V3.95423"
      }
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={
        "M13.787 4.53516H5.82881C5.22437 4.53516 4.73438 5.01296 4.73438 5.60236V14.776C4.73438 15.3654 5.22437 15.8432 5.82881 15.8432H13.787C14.3914 15.8432 14.8814 15.3654 14.8814 14.776V5.60236C14.8814 5.01296 14.3914 4.53516 13.787 4.53516Z"
      }
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.63281 7.35938H11.9816"}
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.63281 10.1875H11.9816"}
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default CopyIcon
