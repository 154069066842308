import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  Checkboxes,
  DISABLE_CHILD_OUT_PRINT_SETTIGNS,
  PageCard,
  theme,
} from "@project/shared"
import { RightOutlined } from "@ant-design/icons"
import { OwnerButton } from "../../atoms"
//styles
const Wrapper = styled.div`
  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .label {
      width: 150px;
      svg {
        color: ${theme.gray2};
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
  @media print {
    display: none !important;
  }
`
interface BenefitsFilterProps {
  onDisplaySwitch?: any
}
export const DisabledChildOutpatientBenefitsFilter: React.FC<
  BenefitsFilterProps
> = ({ onDisplaySwitch }) => {
  const { t } = useTranslation()
  const [selectedPrintSettings, setSelectedPrintSettings] = useState([""])
  return (
    <Wrapper>
      <PageCard title={t("Search options")}>
        <div className={"content"}>
          <div className={"label"}>
            <RightOutlined />
            {t("Print setting")}
          </div>
          <div className={"label__checkboxs"}>
            <Checkboxes
              options={DISABLE_CHILD_OUT_PRINT_SETTIGNS}
              value={selectedPrintSettings}
              onChange={(val) => {
                setSelectedPrintSettings(val)
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <OwnerButton
          text={t("Switch display")}
          shape={"circle"}
          onClick={() => onDisplaySwitch(selectedPrintSettings)}
        />
      </PageCard>
    </Wrapper>
  )
}
