import { API, removeBlankAttributes } from "@project/shared"

export interface IReturnParent {
  count?: number
  data?: any
}

interface IParentParam {
  page?: string
  pageSize?: string
  keyword?: string
  month?: string
  year?: string
  facilityIds?: string
}
interface IParentAssessmentResponseParam {
  page?: string
  pageSize?: string
  service?: string
  year?: string
  facilityIds?: string
  resultId?: string
}

export const fetchParent = async ({ queryKey }): Promise<IReturnParent> => {
  const params = {} as IParentParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.keyword = queryKey[2]?.keyword
  params.year = queryKey[2]?.year
  params.month = queryKey[2]?.month
  params.facilityIds = queryKey[2]?.facilityIds?.join(",")
  const initialParams = removeBlankAttributes(params)

  return API.get(`/parents`, {
    params: initialParams,
  })
}

export const getParent = async (id: number) => {
  return API.get(`/parents/${id}`)
}

export const createParent = async (values): Promise<any> => {
  return API.post(`/parents`, values)
}

export const updateParent = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/parents/${id}`, values)
}

export const fetchParentAssessmentResponse = async ({
  queryKey,
}): Promise<IReturnParent> => {
  const params = {} as IParentAssessmentResponseParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.service = queryKey[2]?.serviceId
  params.facilityIds = queryKey[2]?.facilityIds
  params.year = queryKey[2]?.year
  params.resultId = queryKey[2]?.resultId
  return API.get(`/parent-self-assessments/responses`, {
    params,
  })
}

export const deleteParent = async (id: string | number) => {
  return API.delete(`/parents/${id}`)
}
