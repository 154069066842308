// Packages
import { t } from "i18next"

// Styled components
import { ActualExpensesInfoContainer } from "../styles"

// Components
import { EditButton } from "."
import { TopRoundedWhiteCard } from "@project/shared"
import FormFieldsGen from "../../../molecules/FormFields"

// Constants
import { actualExpensesBasicInfoSchema } from "../constants"

// types
import { ActualExpensesInfoBlockProps } from "../types"

const ActualExpensesInfoBlock: React.FC<ActualExpensesInfoBlockProps> = ({
  onEdit,
  actualExpensesBasicInfoData,
}) => {
  return (
    <ActualExpensesInfoContainer>
      <TopRoundedWhiteCard
        className={"white-card"}
        title={t("Basic information on actual expenses")}
      >
        <div className={"expense-info-content"}>
          <EditButton
            onClick={() => {
              onEdit()
            }}
          />

          {/* Actual expenses basic information form/table starts */}
          <div className={"expense-info-fields"}>
            {actualExpensesBasicInfoSchema(actualExpensesBasicInfoData).map(
              (item, i) => {
                return (
                  <FormFieldsGen key={item.fieldLabel} item={item} index={i} />
                )
              }
            )}
          </div>
          {/* Actual expenses basic information form/table ends */}
        </div>
      </TopRoundedWhiteCard>
    </ActualExpensesInfoContainer>
  )
}

export { ActualExpensesInfoBlock }
