import { RadioButton } from "@project/shared"
import Image from "next/image"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StyledOperationRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 8px;

  .ant-radio-inner {
    border-color: #d2d1d1;
  }
  .ant-radio-inner::after {
    background-color: black;
  }
  img {
    width: 10px !important;
    height: 10px !important;
  }
  .op-label {
    display: flex;
    gap: 4px;
    align-items: center;
    min-width: 138px;
    max-width: 138px;
    @media screen and (max-width: 405px) {
      align-self: center;
    }
  }
  .flex {
    display: flex;
  }
  .flex-radio {
    display: flex;
    flex-wrap: wrap;
  }
`

const ArrowIcon = () => (
  <Image
    src={"/assets/icons/ArrowRight.svg"}
    alt={""}
    width={10}
    height={10}
    className={"arrow-right"}
  />
)
export const OperationRow = (props: {
  title: string
  stateHook: string
  operationFormState: any
  setOperationFormState: any
}) => {
  const { t } = useTranslation()
  const { title, stateHook, operationFormState, setOperationFormState } = props
  return (
    <StyledOperationRow>
      <div className={"flex op-label"}>
        <ArrowIcon />
        {t(title)}
      </div>
      <div className={"flex flex-radio"} style={{ gap: 12 }}>
        <div className={"flex"}>
          <RadioButton
            id={`${stateHook}Checked`}
            checked={operationFormState?.[stateHook]}
            onClick={() =>
              setOperationFormState((prev) => ({
                ...prev,
                [stateHook]: true,
              }))
            }
          />
          <label htmlFor={`${stateHook}Checked`}>{t("Do")}</label>
        </div>
        <div className={"flex"}>
          <RadioButton
            id={`${stateHook}Unchecked`}
            checked={!operationFormState?.[stateHook]}
            onClick={() =>
              setOperationFormState((prev) => ({
                ...prev,
                [stateHook]: false,
              }))
            }
          />
          <label htmlFor={`${stateHook}Unchecked`}>{t("Do not")}</label>
        </div>
      </div>
    </StyledOperationRow>
  )
}
