import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Table, Typography } from "antd"
import styled from "styled-components"
import { Checkbox, SelectInput, theme } from "@project/shared"
import { OwnerButton } from "../../atoms"

import moment from "moment"
import Link from "next/link"
import { AuthContext, FuriganaAlphabetsOption } from "../../../utils"
import { japaneseAlphaRegex } from "../../../utils/validation"
const Wrapper = styled.div`
  .top_content {
    .text {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    .top_btns {
      display: flex;
      margin-top: 1rem;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.5rem;
      & > div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
      button {
        padding: 0.3rem 0.6rem;
      }
    }
  }
  .table_content {
    width: 100%;
    margin-top: 1rem;
    .ant-table-thead .ant-table-cell {
      background-color: ${theme.gray2};
    }
    table:hover,
    tr:hover,
    thead:hover {
      background: unset !important;
    }
    .ant-table-tbody .ant-table-cell {
      text-align: center;
    }
  }
  a {
    color: ${theme.blue5};
  }
  .support-substraction {
    .substraction-button {
      background: ${theme.gray4};
    }
    .active-substraction-button {
      color: ${theme.gray3};
      background: linear-gradient(0deg, #d2d1d1, #d2d1d1),
        linear-gradient(0deg, #f3f3f3, #f3f3f3);
    }
  }
  .selected-row-yellow {
    background: #ffe694 !important;
    &:hover {
      td {
        background: #ffe694 !important;
      }
    }
  }
  .selected-exceed-usage {
    background: ${theme.red4} !important;
    &:hover {
      td {
        background: ${theme.red4} !important;
      }
    }
  }
  .ant-table-tbody > tr.selected-row > td {
    transition: background 0s;
  }
`

export const BillingPlanTable = ({
  childList,
  updateOutputChild,
  facilityId,
}: {
  childList: Array<any>
  updateOutputChild: (val) => void
  facilityId: number | string
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities, otherFacilities } = useContext(AuthContext)
  // facility & joint facility needs to be shown in same select field
  // using prefix facility for facility and joint for joint facility because id may not be unique
  const COMBINED_FACILITY_OPTION = facilities
    ?.map((fac) => {
      return {
        label: fac?.facility_name_short,
        furigana: fac?.facility_name_furigana,
        value: `facility${fac?.id}`,
      }
    })
    ?.concat(
      otherFacilities?.map((other) => {
        return {
          label: other?.facility_name,
          furigana: other?.facility_name_furigana,
          value: `joint${other?.id}`,
        }
      })
    )

  const getFilteredFacilityOption = (alphabet) => {
    if (!alphabet) return COMBINED_FACILITY_OPTION
    return COMBINED_FACILITY_OPTION?.filter((opt) => {
      const regex = japaneseAlphaRegex(alphabet)
      return opt?.furigana?.match(regex) || opt?.label?.match(regex)
    })
  }

  const [furiganaFilters, setFuriganaFilters] = useState([])

  const updateSubstractionMonth = (childId, month) => {
    const data = childList?.map((d) => {
      if (d?.child_id == childId) {
        d.individual_support_plan_non_creation_subtraction = month
      }
      return d
    })
    updateOutputChild(data)
  }

  const bulkUpdateSubstractionMonth = (month) => {
    const d = childList?.map((d) => {
      if (d?.is_selected)
        d.individual_support_plan_non_creation_subtraction = month
      return d
    })
    updateOutputChild(d)
  }

  const columns = [
    {
      key: 1,
      title: t("Select"),
      render: (data) => (
        <Checkbox
          checked={data?.is_selected}
          onChange={() => {
            const child = childList?.map((child) => {
              if (child?.child_id == data?.child_id)
                child.is_selected = !child.is_selected
              return child
            })
            updateOutputChild(child)
          }}
        />
      ),
    },
    {
      key: 2,
      title: t("Child's name (furigana)"),
      render: (data) => (
        <span>{`${data?.child_name} (${data?.child_name_furigana})`}</span>
      ),
    },
    {
      key: 3,
      title: t("Payment municipality"),
      render: (data) => <span>{data?.payment_municipality}</span>,
    },
    {
      key: 4,
      title: t("Service used"),
      render: (data) => (
        <span>
          {data?.service_used == 1
            ? t("After day school")
            : data?.service_used == 2
            ? t("Child development support")
            : ""}
        </span>
      ),
    },
    {
      key: 5,
      title: t("Individual support plan final review date"),
      render: (data) => (
        <span>
          {data?.individual_support_plan_id &&
            data?.individual_support_plan_final_review_date && (
              <Link
                href={`/individual-support-plans/detail/${data?.individual_support_plan_id}`}
              >
                {moment(
                  data?.individual_support_plan_final_review_date
                ).isValid()
                  ? moment(
                      data?.individual_support_plan_final_review_date
                    ).format("YYYY年MM月DD日")
                  : ""}
              </Link>
            )}
        </span>
      ),
    },
    {
      key: 6,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Individual support plan non-creation subtraction")}
        </span>
      ),
      className: "support-substraction",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.3rem",
            }}
          >
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("do not")}
              className={
                data?.individual_support_plan_non_creation_subtraction == 0
                  ? "active-substraction-button substraction-button"
                  : "substraction-button"
              }
              onClick={() => updateSubstractionMonth(data?.child_id, 0)}
              disabled={!data?.is_selected}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("up to 2 months")}
              className={
                data?.individual_support_plan_non_creation_subtraction == 1
                  ? "active-substraction-button substraction-button"
                  : "substraction-button"
              }
              onClick={() => updateSubstractionMonth(data?.child_id, 1)}
              disabled={!data?.is_selected}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("After 3 months")}
              className={
                data?.individual_support_plan_non_creation_subtraction == 2
                  ? "active-substraction-button substraction-button"
                  : "substraction-button"
              }
              onClick={() => updateSubstractionMonth(data?.child_id, 2)}
              disabled={!data?.is_selected}
            />
          </div>
        )
      },
    },
    {
      key: 7,
      width: 100,
      title: t("Payment amount"),

      render: (data) => <span>{`${data?.payment_amount || 0}日`}</span>,
    },
    {
      key: 8,
      title: t("Burden upper limit monthly amount"),
      render: (data) => (
        <span>{`${
          data?.burden_upper_limit_monthly
            ? data?.burden_upper_limit_monthly?.toLocaleString()
            : 0
        }円`}</span>
      ),
    },
    {
      key: 9,
      width: 100,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Upper limit management company")}
        </span>
      ),
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem",
            }}
          >
            {data?.is_free_child && (
              <Typography.Text>
                {t(
                  'Since the child is eligible for free tuition, the upper limit management business operator will be set as "None".'
                )}
              </Typography.Text>
            )}
            <div style={{ display: "flex", gap: "10px" }}>
              <SelectInput
                options={FuriganaAlphabetsOption}
                width={60}
                disabled={
                  !data?.is_selected ||
                  !data?.burden_upper_limit_monthly ||
                  data?.is_free_child
                }
                onChange={(val) => {
                  const newFilters = [...furiganaFilters]
                  if (!val) {
                    setFuriganaFilters(
                      newFilters?.filter((d) => d?.child_id != data?.child_id)
                    )
                  } else {
                    const indexOf = newFilters?.indexOf((f) => {
                      return f?.child_id == data?.child_id
                    })
                    if (indexOf >= 0) {
                      newFilters[indexOf].furigana = val
                    } else {
                      newFilters.push({
                        child_id: data?.child_id,
                        furigana: val,
                      })
                    }
                    setFuriganaFilters(newFilters)
                  }
                }}
              />
              <SelectInput
                options={[
                  {
                    label: t("none"),
                    value: `0`,
                  },
                ].concat(
                  getFilteredFacilityOption(
                    furiganaFilters?.find((f) => f?.child_id == data?.child_id)
                      ?.furigana
                  )
                )}
                width={200}
                value={
                  data?.burden_upper_limit_management_company_type == 1 &&
                  data?.burden_upper_limit_management_company
                    ? `facility${data?.burden_upper_limit_management_company}`
                    : data?.burden_upper_limit_management_company_type == 2 &&
                      data?.burden_upper_limit_management_joint_company
                    ? `joint${data?.burden_upper_limit_management_joint_company}`
                    : null
                }
                disabled={
                  !data?.is_selected ||
                  !data?.burden_upper_limit_monthly ||
                  data?.is_free_child
                }
                onChange={(val) => {
                  const child = childList?.map((c) => {
                    if (c?.child_id == data?.child_id) {
                      if (val.includes("facility")) {
                        c.burden_upper_limit_management_company_type = 1
                        c.burden_upper_limit_management_company = +val.replace(
                          "facility",
                          ""
                        )
                      } else if (val.includes("joint")) {
                        c.burden_upper_limit_management_company_type = 2
                        c.burden_upper_limit_management_joint_company =
                          +val.replace("joint", "")
                      } else if (val == "0") {
                        c.burden_upper_limit_management_company_type = 0
                        c.burden_upper_limit_management_joint_company = ""
                        c.burden_upper_limit_management_company = ""
                      }

                      // NOTE: 自事業所でなければ上限管理加算は取得しないので、加算フラグを加算無しに変更する
                      if (val !== `facility${facilityId}`) {
                        c.maximum_manage_addition_flag = 0
                      }
                    }
                    return c
                  })
                  updateOutputChild(child)
                }}
                placeholder={t("none")}
              />
            </div>
          </div>
        )
      },
    },
    {
      key: 10,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Acquisition settings for upper limit management addition")}
        </span>
      ),
      width: "150",
      render: (data) => {
        return (
          <SelectInput
            placeholder={"---"}
            disabled={
              !data?.burden_upper_limit_management_company_type ||
              !data?.is_selected ||
              data?.burden_upper_limit_management_company != facilityId
            }
            options={[
              { label: t("Do not aquire"), value: "0" },
              { label: t("Acquire"), value: "1" },
            ]}
            value={data?.maximum_manage_addition_flag?.toString() || ""}
            width={150}
            onChange={(val) => {
              const child = childList?.map((child) => {
                if (child?.child_id == data?.child_id)
                  child.maximum_manage_addition_flag = +val
                return child
              })
              updateOutputChild(child)
            }}
          />
        )
      },
    },
    {
      key: 11,
      width: 100,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("参考リンク")}
        </span>
      ),
      render: (data) => (
        <>
          <Link
            href={`/additional-item-management/child?facility_id=${data?.facility_id}&child_id=${data?.child_id}`}
          >
            {t("Daily Addition")}
          </Link>
          {"  "}
          <Link
            href={`/service-provision-record-sheet/detail/${data?.child_id}`}
          >
            {t("Service provision record sheet")}
          </Link>
        </>
      ),
    },
    {
      key: 12,
      width: 100,
      title: t("changer"),
      dataIndex: "updater",
    },
    {
      width: 100,
      key: 13,
      title: t("update time"),
      dataIndex: "update_date_time",
      render: (data) => {
        return (
          <span>
            {moment(data).isValid()
              ? moment(data).format("YYYY年MM月DD日 HH:mm")
              : ""}
          </span>
        )
      },
    },
  ]

  const checkAllChild = () => {
    const child = childList?.map((child) => {
      return { ...child, is_selected: true }
    })
    updateOutputChild(child)
  }

  const checkAllValidCertificates = () => {
    const child = childList?.map((child) => {
      if (child?.is_receiving_certificate_valid) child.is_selected = true
      else child.is_selected = false
      return child
    })
    updateOutputChild(child)
  }

  const clearAllChild = () => {
    const child = childList?.map((child) => {
      return { ...child, is_selected: false }
    })
    updateOutputChild(child)
  }

  return (
    <Wrapper>
      <div className={"top_content"}>
        <Typography.Text className={"text"}>
          {t("Change of all individual support plan uncreated subtraction")}
        </Typography.Text>
        <div className={"top_btns"}>
          <div>
            <OwnerButton
              shape={"circle"}
              typeOf={"check-all"}
              text={t("Check all")}
              padding={1}
              onClick={checkAllChild}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"check-all"}
              text={t(
                "Check only for children with valid recipient certificates"
              )}
              onClick={checkAllValidCertificates}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("Clear all")}
              onClick={clearAllChild}
            />
          </div>
          <div>
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("Do not")}
              onClick={() => bulkUpdateSubstractionMonth(0)}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("up to 2 months")}
              onClick={() => bulkUpdateSubstractionMonth(1)}
            />
            <OwnerButton
              shape={"circle"}
              typeOf={"secondary"}
              text={t("After 3 months")}
              onClick={() => bulkUpdateSubstractionMonth(2)}
            />
          </div>
        </div>
      </div>
      <div className={"table_content"}>
        <Table
          columns={columns}
          dataSource={childList?.map((d) => {
            return {
              ...d,
              key: d?.child_id,
              burden_upper_limit_management_company_type: d?.is_free_child
                ? 0
                : d?.burden_upper_limit_management_company_type,
            }
          })}
          scroll={{ x: "max-content" }}
          bordered
          pagination={false}
          rowClassName={(row) =>
            row?.is_selected
              ? row?.is_exceed_usage
                ? "selected-exceed-usage selected-row "
                : "selected-row-yellow selected-row "
              : ""
          }
          locale={{ emptyText: t("No data to display") }}
          className={"always-present-scrollbar"}
        />
      </div>
    </Wrapper>
  )
}
