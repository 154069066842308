import { Table } from "@project/shared"
import { t } from "i18next"

type Props = {
  facilityNumber: string
  facilityName: string
}

export function ManagementFacility({ facilityNumber, facilityName }: Props) {
  return (
    <Table
      className={"management-table"}
      columns={[
        {
          title: "",
          render: () => <p>{t("Management company")}</p>,
          width: 20,
          onCell: (_, index) => {
            if (index === 0) {
              return { rowSpan: 2 }
            }
            return { rowSpan: 0 }
          },
        },
        {
          title: "",
          dataIndex: "label",
          width: "150px",
          render: (text) => <p className={"management_company"}>{text}</p>,
        },
        {
          title: "",
          width: "200px",
          dataIndex: "value",
          render: (_, val) => {
            if (val?.isNumber) {
              return (
                <div id={"business_number"}>
                  {String(val?.value)
                    ?.padEnd(10, ".")
                    ?.split("")
                    ?.map((v, idx) => (
                      <div className={"number"} key={idx}>
                        {v !== "." ? v : ""}
                      </div>
                    ))}
                </div>
              )
            }
            return <p>{val?.value}</p>
          },
        },
      ]}
      dataSource={[
        {
          key: "1",
          label: t("Designated office number"),
          value: facilityNumber,
          isNumber: true,
        },
        {
          key: "2",
          label: t("Business office and name of the business office"),
          value: facilityName,
        },
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
