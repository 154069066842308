import React, { useMemo } from "react"

// packages
import { t } from "i18next"
import moment from "moment"
import { useFormik } from "formik"
import { CSVLink } from "react-csv"

// styled components
import { BankFormatOutputContainer, NumbOfOutputCounter } from "../styles"

// components
import {
  InfoBoxMainText,
  RequiredFormInfoBox,
  useFormatOutputTable,
  FirstNoticeBoxContent,
  ThirdNoticeBoxContent,
} from "."
import { InfoBox } from "../.."
import { TopRoundedWhiteCard } from "@project/shared"
import { OwnerButton, useNoticeBox } from "../../../atoms"

// utils
import { formatTransferAccountInfo } from "../utils/mapper"
import { getLanguage } from "../../../../utils/getLanguage"
import { TableDataToEBCDIC, TableDataToJIS } from "../utils"

// types
import { BankInfoListProps, FormatOutputBlockProps } from "../types"

export const FormatOutputBlock: React.FC<FormatOutputBlockProps> = ({
  tableData,
  yearMonth,
  facilityName,
  transferAccount,
  temporarySaveData,
  transferAccountInfo,
  parentWithNoAccountInfo,
  isAllBankFormatDataLoading,
  handleTemporarySaveDataChange,
  billingMethod,
  facilityId,
}) => {
  const FILE_NAME = `${facilityName}-${yearMonth.format(
    "YYYY年MM月"
  )}施設名の全銀フォーマットを出力`

  const { Notice } = useNoticeBox()
  const {
    Notice: ExpandableNotice,
    isNoticeOpen: isThirdNoticeOpen,
    handleNoticeToggle: handleThirdNoticeToggle,
  } = useNoticeBox()

  const formik = useFormik({
    initialValues: {
      code_category: 1,
      withdraw_month: moment().month() + 1,
      withdraw_day: moment().date(),
      show_Billing_Amt: false,
    },

    onSubmit: (values) => {
      handleTemporarySaveDataChange(values)
    },
  })

  const {
    Tables,
    billedAmount,
    dataOnlyForCSV,
    isAnyRowSelected,
    selectedRowsCount,
    handleSelectAllRows,
    handleUnselectAllRows,
  } = useFormatOutputTable({
    tableData,
    transferAccount,
    transferAccountInfo,
    isAllBankFormatDataLoading,
  })

  const handleOutputAsTextBtnClick = () => {
    const code_category = formik.values.code_category
    const content =
      formik.values.code_category === 1
        ? TableDataToJIS(dataOnlyForCSV?.flat())
        : TableDataToEBCDIC(dataOnlyForCSV?.flat())

    const element = document.createElement("a")
    const file = new Blob([content], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = `${
      code_category === 1
        ? "表データのJIS平文化_" + new Date().getTime()
        : "テーブルデータをEBCDIC平文に変換_" + new Date().getTime()
    }.text`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  // returns null if everything is registered
  const unregisteredTransferAccount = useMemo(() => {
    const transferAccountObj: BankInfoListProps =
      formatTransferAccountInfo(transferAccountInfo)
    let hasUnregisteredData = false
    Object.keys(transferAccountObj)?.map((key) => {
      if (transferAccountObj[key] == t("Unregistered")) {
        hasUnregisteredData = true
      }
    })
    return {
      transferAccountObj,
      someUnregistered: hasUnregisteredData,
    }
  }, [transferAccountInfo])

  return (
    <BankFormatOutputContainer>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("{{currEngDate}} {{facilityName}} All banks format output", {
          currEngDate: yearMonth.format("YYYY年MM月"),
          currJaDate: yearMonth.format("YYYY年MM月"),
          facilityName,
        })}
      >
        <div className={"all-bank-format-content"}>
          {/* info box starts here */}
          {billingMethod && (
            <InfoBox
              className={"info-box-container no-print"}
              linkText={t("Click here to change the invoice information ")}
              href={`/national-health-insurance-billing?facility_id=${facilityId}&year=${yearMonth?.format(
                "YYYY"
              )}&month=${yearMonth?.format("MM")}&change_billing=1`}
            >
              <InfoBoxMainText billingMethod={t(billingMethod)} />
            </InfoBox>
          )}
          {/* info box ends here */}

          {/* Required Info form box starts here */}
          <RequiredFormInfoBox formik={formik} />
          {/* Required Info form box ends here */}

          {/* first notice box starts here */}
          {parentWithNoAccountInfo.length > 0 && (
            <Notice
              theme={"red-1"}
              containerClassName={"notice-container-1 no-print"}
              mainTitle={
                "Data cannot be output for the following guardians because their account information has not been registered."
              }
            >
              <FirstNoticeBoxContent
                parentWithNoAccountInfo={parentWithNoAccountInfo}
              />
            </Notice>
          )}
          {/* first notice box ends here */}

          {/* second notice box starts here */}
          {temporarySaveData.show_Billing_Amt && (
            <Notice
              theme={"red-3"}
              containerClassName={"notice-container-2 no-print"}
              mainTitle={
                "If you want to output the data of the user whose billing amount is 0 yen, you need to change the settings."
              }
            >
              <p>
                {t(
                  'Check the "Output users who charge {{amt}} yen" check box.',
                  {
                    amt: 0,
                  }
                )}
              </p>
            </Notice>
          )}
          {/* second notice box ends here */}

          {/* third notice box starts here */}
          {unregisteredTransferAccount?.someUnregistered ? (
            <ExpandableNotice
              containerClassName={"no-print"}
              mainTitle={
                "The bank information of the following facilities are either not selected or some data are missing"
              }
            >
              <ThirdNoticeBoxContent
                isNoticeOpen={isThirdNoticeOpen}
                selectedTransferAccountId={transferAccount}
                handleNoticeToggle={handleThirdNoticeToggle}
                listData={unregisteredTransferAccount?.transferAccountObj}
                facilityName={transferAccountInfo?.facility_name}
              />
            </ExpandableNotice>
          ) : (
            <></>
          )}
          {/* third notice box ends here */}

          {/* action button groups starts here */}
          <div className={"primary-action-btns flexbox no-print"}>
            <OwnerButton
              className={"btn-has-icon"}
              text={t("Check all")}
              typeOf={"check-all"}
              onClick={handleSelectAllRows}
            />
            <OwnerButton
              className={"btn-has-no-icon"}
              text={t("Clear all")}
              typeOf={"secondary"}
              onClick={handleUnselectAllRows}
            />
            <OwnerButton
              disabled={!isAnyRowSelected}
              text={t("output as text")}
              typeOf={"text-output"}
              onClick={handleOutputAsTextBtnClick}
            />
            <CSVLink
              data={dataOnlyForCSV?.length ? dataOnlyForCSV?.flat() : []}
              filename={FILE_NAME}
            >
              <OwnerButton
                disabled={!isAnyRowSelected}
                text={t("Output as CSV")}
                typeOf={"csv-btn"}
              />
            </CSVLink>
          </div>
          {/* action button groups ends here */}

          {/* Actual main table starts here */}
          <Tables />
          {/* Actual main table ends here */}

          {/* footer secondary-button group starts here */}
          <div className={"secondary-action-btns flexbox no-print"}>
            <OwnerButton
              disabled={!isAnyRowSelected}
              text={t("output as text")}
              typeOf={"text-output"}
              onClick={handleOutputAsTextBtnClick}
            />
            {/* CSVLink component takes data that we want to export and header like in our table */}
            <CSVLink
              data={dataOnlyForCSV?.length ? dataOnlyForCSV?.flat() : []} // Notice !? we are using dataOnlyForCSV here instead of dataSources because we want to export only selected rows
              filename={FILE_NAME}
            >
              <OwnerButton
                disabled={!isAnyRowSelected}
                text={t("Output as CSV")}
                typeOf={"csv-btn"}
              />
            </CSVLink>
            <NumbOfOutputCounter>
              {getLanguage() === "en" ? (
                <>
                  {t("Number of outputs/total amount billed to users: ")}
                  <span>{selectedRowsCount.toLocaleString()}</span>
                  {"/"}
                  <span>{billedAmount.toLocaleString()}</span> {t("Yen")}
                </>
              ) : (
                <>
                  {"出力数／利用者への請求金額合計"}
                  <span>{selectedRowsCount.toLocaleString()}</span>
                  {"件/"}
                  <span>{billedAmount.toLocaleString()}</span>
                  {"円"}
                </>
              )}
            </NumbOfOutputCounter>
          </div>
        </div>
        {/* footer secondary-button group ends here */}
      </TopRoundedWhiteCard>
    </BankFormatOutputContainer>
  )
}
