import React, { useContext, useEffect, useRef, useState } from "react"
import { Checkboxes, TextField, theme } from "@project/shared"
import { Select } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { FormikProps } from "formik"
import { t } from "i18next"
import { AuthContext } from "../../../utils"

interface IUserRegistrationFormProps {
  handleChange: any
  initialValues?: any
  values?: any
  handleBlur?: any
  handleAffiliationFacilityChange?: (_: any) => void
  handleSelect?: (id: string, value: string) => void
  errors?: any
  touchedFields?: any
  isEditForm?: boolean
  setFieldValue?: FormikProps<any>["setFieldValue"]
  disableUserTypeChange?: boolean
}

export const StyledInputWrapper = styled.div`
  display: flex;
  border: 1px solid #d2d1d1;

  @media (max-width: 1200px) {
    flex-direction: column;
    border: 0px;
    width: 100%;
  }
  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f3f3f3;
    min-width: 300px;
    max-width: 300px;
    border-right: 1px solid #d2d1d1;
    .label {
      margin: auto;
      margin-left: 18px;
    }
    .label-info {
      display: flex;
      align-items: center;
      margin-right: 15px;
      .question-icon {
        margin-left: 12px;
      }
      .label {
        background: #e00000;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        padding: 3px 8px;
        margin: auto;
      }
    }
    @media (max-width: 1200px) {
      min-width: 100%;
      max-width: 100%;
      min-height: 40px;
      border: 1px solid #d2d1d1;
    }
  }
  .children-wrapper {
    display: flex;
    flex: 1;
    align-self: center;
    padding: 20px;
    flex-wrap: wrap;
    gap: 20px;
    width: calc(100% - 300px);

    .input-info {
      color: #444748;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    @media screen and (max-width: 426px) {
      gap: 0;
      .input-info {
        margin-left: 20px;
      }
    }
    .error {
      input {
        border-color: red;
      }
      .ant-select-selector {
        border-color: red;
      }
    }
    .input-container {
      position: relative;
      img {
        position: absolute;
        top: 18px;
        right: 10px;
      }
    }
    .input {
      width: 420px;
      border-radius: 5px;
      input {
        background: white !important;
      }
    }
    span.custom-span {
      margin-top: auto;
      margin-bottom: auto;
    }

    .block {
      display: block;
    }
    .required {
      color: #e00000;

      @media screen and (max-width: 400px) {
        font-size: 13px;
      }
    }
    .select {
      .ant-select-selector {
        width: 200px;
        height: 40px !important;
        border-radius: 5px;
        align-items: center;
      }
    }

    @media (max-width: 1200px) {
      width: 100%;
      align-self: flex-start;
      padding: 20px 0px;
      flex-direction: column;
      .input {
        width: 100%;
      }
      .select {
        .ant-select-selector {
          width: auto;
        }
      }
      .custom-span {
        margin-left: 0px;
      }
      p.input-info {
        margin-left: 0px;
      }
    }
  }

  .certificate-change-container {
    width: 100%;
    > div > div > label > span:last-child {
      min-width: 185px;
    }
  }

  .checkbox-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-checkbox-wrapper {
      white-space: break-spaces;
      @media (max-width: 768px) {
        word-break: break-all;
      }
    }

    @media (max-width: 318px) {
      flex-wrap: wrap;
    }
  }

  &.recipient-certificate-number-wrapper {
    .children-wrapper {
      flex-wrap: nowrap;
      row-gap: 10px;
      /* flex-direction: row !important; */
      .checkbox-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        .ant-checkbox-wrapper {
          white-space: break-spaces;
        }

        @media (max-width: 318px) {
          flex-wrap: wrap;
        }
      }
    }
  }

  &.minutes-addition-edit-form {
    .addition-name-field-container {
      width: 100%;
    }
  }
  .main_affi_msg {
    color: ${theme.red1};
    font-weight: bold;
    margin: 0;
  }
  .changed_recieving_certificate_number {
    min-width: 130px;
  }
`

const USERTYPE_OPTIONS = [
  { label: t("Staff"), value: "0" },
  { label: t("System Administrator"), value: "1" },
]

const PARENT_CHILD_OUTPUT = [{ label: "利用する", value: "利用する" }]

interface InputWrapperProps {
  isRequired?: boolean
  label: string
  children: any
  className?: string
}

export const InputWrapper = ({
  isRequired = false,
  label,
  children,
  className,
}: InputWrapperProps) => {
  const { t } = useTranslation()
  return (
    <StyledInputWrapper className={className}>
      <div className={"label-wrapper"}>
        <div>
          <p className={"label"}>{t(label)}</p>
        </div>
        <div className={"label-info"}>
          {isRequired && <p className={"label"}>{t("Required")}</p>}
        </div>
      </div>
      <div className={"children-wrapper"}>{children}</div>
    </StyledInputWrapper>
  )
}

const UserRegistrationForm: React.FC<IUserRegistrationFormProps> = ({
  handleChange,
  values,
  handleBlur,
  handleAffiliationFacilityChange,
  handleSelect,
  touchedFields,
  errors,
  isEditForm,
  setFieldValue,
  disableUserTypeChange = false,
}) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const triggerRef = useRef(true)

  const { facilities } = useContext(AuthContext)
  const AFFILIATION_FACILITY_OPTIONS = facilities?.map((data) => {
    return {
      value: data?.id,
      label: data?.facility_name_short,
    }
  })

  const mainAffliliationSelect =
    values?.affiliation_facility_tabs &&
    values?.affiliation_facility_tabs?.map((e) => {
      return {
        label: AFFILIATION_FACILITY_OPTIONS?.find((fa) => fa.value === e.value)
          ?.label,
        value: e.value,
      }
    })

  const triggerAdminChange = () => {
    handleSelect("user_type", "1")
    setFieldValue("auth_new_delete_flag", ["設定する"])
    setFieldValue("output_parent_and_child", ["利用する"])
    handleAffiliationFacilityChange(AFFILIATION_FACILITY_OPTIONS)
  }

  useEffect(() => {
    // if user is admin and automatic trigger to set permissions to admin is not done previously
    if (values?.user_type == "1" && triggerRef.current) {
      triggerRef.current = false
      triggerAdminChange()
    }
  }, [values])

  return (
    <>
      <InputWrapper isRequired label={"Username"}>
        <TextField
          name={"user_name"}
          placeholder={"例：山田太郎"}
          className={`input ${
            touchedFields?.user_name && errors.user_name ? "error" : ""
          }`}
          value={values?.user_name}
          onChange={handleChange("user_name")}
          onBlur={handleBlur("user_name")}
          maxLength={20}
          error={errors.user_name}
          required
        />
        <span className={"custom-span input-info"}>{"(20文字以内)"}</span>
      </InputWrapper>

      <InputWrapper isRequired label={"Usertype"}>
        <Select
          options={USERTYPE_OPTIONS}
          defaultActiveFirstOption={true}
          value={values?.user_type}
          onChange={(val) => {
            if (val === "1") {
              triggerAdminChange()
            } else {
              handleSelect("user_type", val)
            }
          }}
          onBlur={handleBlur}
          id={"user_type"}
          className={`select ${
            touchedFields?.user_type && errors.user_type ? "error" : ""
          }`}
          disabled={disableUserTypeChange}
        />
      </InputWrapper>

      <InputWrapper
        isRequired={false}
        label={
          "New registration/delete child management,recipient certificate, contract payment amount"
        }
      >
        <div>
          <Checkboxes
            options={[{ label: "設定する", value: "設定する" }]}
            value={values?.auth_new_delete_flag}
            onChange={(e) => handleSelect("auth_new_delete_flag", e)}
            name={"auth_new_delete_flag"}
            disabled={values?.user_type === "1"}
          />
          <span className={"custom-span required"}>
            {
              "※チェックがない場合は、「児童管理」「受給者証・契約支給量」で「閲覧・編集」を選択しても、新規登録・削除ができません。"
            }
          </span>
        </div>
      </InputWrapper>

      <InputWrapper label={"Affiliation facility"} isRequired>
        {AFFILIATION_FACILITY_OPTIONS?.length && (
          <Checkboxes
            name={"affiliation_facility"}
            options={AFFILIATION_FACILITY_OPTIONS}
            value={values.affiliation_facility}
            onChange={handleAffiliationFacilityChange}
            storeLabelAndValue={true}
            disabled={values?.user_type === "1"}
            error={
              touchedFields.affiliation_facility && errors.affiliation_facility
            }
          />
        )}
      </InputWrapper>

      <InputWrapper label={"Main affiliation"} isRequired>
        {!values?.affiliation_facility?.length ? (
          <p className={"main_affi_msg"}>
            {t(
              "*Affiliation has not been selected. Please select at least one facility."
            )}
          </p>
        ) : (
          <Select
            disabled={!values?.affiliation_facility?.length}
            options={mainAffliliationSelect}
            defaultActiveFirstOption
            value={values?.primary_facility_id}
            onChange={(value) => {
              handleSelect("primary_facility_id", value)
            }}
            onBlur={handleBlur("primary_facility_id")}
            id={"primary_facility_id"}
            className={`select ${
              touchedFields?.primary_facility_id && errors.primary_facility_id
                ? "error"
                : ""
            }`}
          />
        )}
      </InputWrapper>

      <InputWrapper
        label={"Output of parent/child information"}
        isRequired={false}
      >
        <Checkboxes
          name={"output_parent_and_child"}
          options={PARENT_CHILD_OUTPUT}
          value={values.output_parent_and_child}
          onChange={(e) => handleSelect("output_parent_and_child", e)}
          disabled={values?.user_type === "1"}
        />
      </InputWrapper>

      <InputWrapper label={"Email"} isRequired>
        <TextField
          name={"email"}
          className={`input ${
            touchedFields?.email && errors.email ? "error" : ""
          }`}
          value={values?.email}
          onChange={handleChange("email")}
          onBlur={handleBlur("email")}
          type={"email"}
          required
          minLength={4}
          error={errors.email}
        />
        <span className={"custom-span input-info"}>
          {t("(min 4 alphanumeric characters)")}
        </span>
      </InputWrapper>

      <InputWrapper label={"Password"} isRequired={!isEditForm}>
        <div className={"input-container"}>
          <TextField
            className={`input ${
              touchedFields?.password && errors.password ? "error" : ""
            }`}
            value={values?.password}
            onChange={handleChange("password")}
            onBlur={handleBlur("password")}
            name={"password"}
            type={showPassword ? "text" : "password"}
            minLength={6}
            maxLength={16}
            required
            autoComplete={"new-password"}
            error={errors.password}
          />
          {values?.password?.length > 0 ? (
            <>
              {showPassword ? (
                <img
                  src={"/assets/icons/eye-closed.svg"}
                  alt={"eye-closed"}
                  height={15}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <img
                  src={"/assets/icons/eye-open.svg"}
                  alt={"eye-open"}
                  height={15}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </>
          ) : null}
        </div>
        <div>
          <p className={"input-info"}>{t("(6-16 alphanumeric characters)")}</p>
          <p className={"input-info required"}>
            {t("※Please enter only when changing, other than the first time.")}
          </p>
        </div>
      </InputWrapper>
    </>
  )
}

export { UserRegistrationForm }
