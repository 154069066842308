import { t } from "i18next"

const checkLists: { label: string; value: number }[] = [
  {
    label: "Print the form number",
    value: 1,
  },
  {
    label: "Print the consent form for proxy receipt",
    value: 2,
  },
  {
    label: "Sort by municipality of payment",
    value: 3,
  },
  {
    label:
      "Display the service details in the report of the end of service provision based on the contract payment amount of the existing contract.",
    value: 4,
  },
  {
    label: `Do not add "cho" after the destination city name.`,
    value: 5,
  },
]

const PrintStyleValues = {
  COUNTRY_PRINTING_STYLE: 1,
  KYOTO_CITY_PRINTING_STYLE: 2,
  MATSUYAMA_CITY_PRINTING_STYLE: 3,
  MIYAZAKI_CITY_PRINTING_STYLE: 4,
}

const PrintStyleOptions = [
  {
    label: t("Country Printing Style"),
    value: PrintStyleValues.COUNTRY_PRINTING_STYLE,
  },
  {
    label: t("Kyoto City Printing Style"),
    value: PrintStyleValues.KYOTO_CITY_PRINTING_STYLE,
  },
  {
    label: t("Matsuyama City Printing Style"),
    value: PrintStyleValues.MATSUYAMA_CITY_PRINTING_STYLE,
  },
  {
    label: t("Miyazaki City Printing Style"),
    value: PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE,
  },
]

export { checkLists, PrintStyleValues, PrintStyleOptions }
