import styled from "styled-components"

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .col {
    flex-direction: column;
  }
  .ant-card-body {
    flex-direction: column !important;
  }

  .btns-grid {
    display: flex;
    gap: 20px;
    margin: 20px 0;

    @media screen and (max-width: 420px) {
      gap: 0px;

      button:last-child {
        margin-left: 20px;
      }
    }
  }
  .btns-grid > [class*="Button"] {
    height: 40px;
  }
  .info-section {
    max-width: 100%;
    @media print {
      padding: 0;
    }
    .title-typ {
      width: 100%;
      text-align: center;
      @media screen and (max-width: 400px) {
        text-align: left;
        font-size: 18px;
      }
    }
    .entry-column-title {
      margin-top: 24px;
      margin-bottom: 14px;
      text-align: center;
      @media print {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
      }
    }

    .footer-section {
      @media print {
        page-break-before: always !important;
        overflow: hidden;
        white-space: nowrap; /* Avoid wrapping content */
        margin: 0; /* Adjust margins */
        padding: 0; /* Adjust padding */
      }
    }
  }
  @media print {
    overflow-x: visible !important;
    .ant-card {
      border: 1px solid white !important;
      box-shadow: unset;
    }

    .operation-form,
    [class*="Divisor"],
    .ant-card-head,
    [class*="PrintButtonsSection"] {
      display: none;
    }
  }
`
export const PrintButtonsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 8px;
  margin-bottom: 42px;
  button {
    padding: 8px;
    min-width: 110px;
    height: 40px;
  }

  @media screen and (max-width: 420px) {
    gap: 0;
    button:last-child {
      margin-left: 24px;
    }
  }
`
