import React, { useContext, useEffect, useState } from "react"
import { Button, Input, Typography } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { FieldArray, FormikProvider, useFormik } from "formik"
import {
  anchorSelInputAreaAsPopupContainer,
  DeleteConfimationModal,
  isApril2024ChangeApplicable,
  Modal,
  MONTHS,
  RadioButtons,
  SelectInput,
  theme,
  WORK_EXPERIENCE,
  WORK_TYPES,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { InstructorInfo, DatePicker } from "../../molecules"
import * as Yup from "yup"

import moment from "moment"
import { AuthContext, allowOnlyNumber, getDateString } from "../../../utils"
import { useQuery } from "react-query"
import { GetShiftMasterNames } from "../../../services"
import { getOccupations } from "../../../services/occupation"
import Link from "next/link"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  .msg_content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .msg {
      color: ${theme.red2};
      font-weight: 400;
      font-size: 16px;
    }
    @media (max-width: ${theme.breakpoints?.md}) {
      flex-direction: column;
      align-items: start;
      .msg {
        font-size: 14px;
      }
    }
  }
  .col-title {
    white-space: nowrap;
  }
`
const OccupationModalBodyContainer = styled.div`
  padding: 24px;
  .body_content {
    .year_month {
      display: flex;
      margin-bottom: 19px;
      gap: 37px;
      margin-top: 0.4rem;
      flex-wrap: wrap;
      .year {
        display: flex;
        flex-direction: column;
        width: fit-content;
        .year_input {
          display: flex;
          gap: 6px;
          align-items: center;
        }
        .ant-picker {
          border-radius: 5px;
          padding-top: 8px;
          padding-bottom: 8px;
          width: 100px;
          box-shadow: none;
          border: 1px solid ${theme.gray2};
        }
      }

      .month {
        width: 20%;
        display: flex;
        flex-direction: column;
        .month_input {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
    .form_control {
      display: flex;
      border: 1px solid ${theme.gray2};
      &:not(:last-child) {
        border-bottom: none;
      }
      .label {
        border-right: 1px solid ${theme.gray2};
        padding: 10px 10px;
        width: 32%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: ${theme.black};
        @media (max-width: ${theme.breakpoints.md}) {
          border-bottom: 1px solid ${theme.black2};
          border-right: none;
          width: 100%;
          justify-content: flex-start;
        }
      }
      .form_input {
        padding: 10px 10px;
        width: 68%;
        overflow-x: auto;
        .work_pattern {
          table {
            border-collapse: collapse;
            width: 100%;
            td,
            th {
              border: 1px solid ${theme.black2};
              text-align: left;
              padding: 5px;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: ${theme.black2};
            }
            tbody > tr > td {
              width: 60px;
            }
          }
          @media (max-width: ${theme.breakpoints.md}) {
            width: 600px;
          }
          @media (max-width: ${theme.breakpoints.sm}) {
            width: 280px;
            table {
              table-layout: fixed;
              width: 100%;
              display: flex;
              thead {
                flex: 1;
              }
              tbody {
                flex: auto;
              }
              thead tr,
              tbody tr {
                display: flex;
                flex-direction: column;
              }
              th {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              tbody > tr > td {
                width: 100%;
                height: 50px;
              }
            }
          }
        }
        &__occupation {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          &--btn {
            padding: 5px !important;
            border-radius: 4px !important;
            margin: 6px;
          }
          &--icon-add {
            color: ${theme.blue5};
          }
          &--icon {
            color: red;
          }
          .occupation-select-input {
            min-width: 240px;
          }
        }
        .working_hours {
          display: flex;
          align-items: center;
          margin: 5px 0;
          label {
            font-size: 12px;
          }
          input {
            width: 60px;
            margin-left: 2px;
          }
        }
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
        }
      }
      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
      }
    }
  }
`
const BtnContainers = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid ${theme.gray2};
  padding: 10px 24px;
`
interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY"
const intilaValues = {
  year: moment().year(),
  month: moment().month() + 1,
  workstyle_type: "",
  is_short_time_working_system: null,
  experience_year: null,
  facility_id: "",
  occupations: [
    {
      id: null,
      hour: "",
    },
  ],
  manager_work_hour: 0,
  one_week_work_pattern: {
    sun: "",
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
  },
}
export const Occupations: React.FC<IProps> = ({ data, onSave, onDelete }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const firstFacility = facilities ? facilities[0]?.id : ""
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedFacility, setSelectedFacility] = useState(firstFacility)
  const { data: shiftMasters } = useQuery({
    queryKey: ["shitf-master-list", selectedFacility],
    queryFn: () => GetShiftMasterNames(selectedFacility),
    cacheTime: 0,
    retry: 1,
    select: (res: any) => {
      return {
        ...res,
        data: res?.data?.map((val) => ({
          label: val?.attendance_shift_display_name,
          value: val?.id,
        })),
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedFacility,
  })
  const { data: occupations } = useQuery("occupations", getOccupations, {
    select: (response) =>
      response?.data?.map((val) => ({
        label: val?.occupation_name,
        value: val?.id,
      })),
    refetchOnWindowFocus: false,
    retry: 2,
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: intilaValues,
    validationSchema: Yup.object({
      year: Yup.string().required(t("Required")),
      month: Yup.string().required(t("Required")),
      workstyle_type: Yup.string(),
      facility_id: Yup.string()
        .required(t("Required"))
        .default(`${firstFacility}`),
      occupations: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t("Required")),
            hour: Yup.string().nullable(),
          })
        )
        .min(1, t("Required")),
    }),

    onSubmit: (values) => {
      const { one_week_work_pattern, occupations, ...rest } = values
      const hasValue = Object.values(rest)?.some((val) => val)
      const occu = {
        occupation_id: +occupations[0].id || null,
        occupation_id_2: +occupations[1]?.id || null,
        manager_work_hour: +occupations[0].hour || null,
        manager_work_hour_2: +occupations[1]?.hour || null,
      }
      if (hasValue) {
        if (operation === "create") {
          onSave(
            {
              tempId: new Date().getTime(),
              ...rest,
              facility_id: rest?.facility_id || selectedFacility,
              workstyle_type: rest?.workstyle_type || 1,
              year: +values?.year,
              month: +values?.month,
              one_week_work_pattern,
              ...occu,
              occupations,
            },
            operation
          )
        } else {
          onSave(
            {
              tempId: selectedId,
              ...rest,
              facility_id: rest?.facility_id || selectedFacility,
              year: +values?.year,
              month: +values?.month,
              one_week_work_pattern,
              ...occu,
              occupations,
            },
            operation
          )
        }
        setOperation("create")
        handleReset()
      }
    },
  })

  const handleReset = () => {
    formik.resetForm({
      values: intilaValues,
    })
    setSelectedId(null)
    setOperation("create")
    setIsDuplicate(false)
    setIsModalOpen(false)
  }

  const checkForDuplicate = (y?: number, m?: number) => {
    const year = y || formik?.values?.year
    const month = m || formik?.values?.month
    const dd = data?.find((val) => val?.year === +year && val?.month === month)
    if (dd) {
      setIsDuplicate(true)
    } else {
      setIsDuplicate(false)
    }
  }

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "Edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  year: row?.year,
                  month: row?.month,
                  workstyle_type: row?.workstyle_type || 1,
                  facility_id: row?.facility_id,
                  occupations: row?.occupations,
                  one_week_work_pattern: row?.one_week_work_pattern,
                  manager_work_hour: row?.manager_work_hour,
                  experience_year: row?.experience_year,
                  is_short_time_working_system:
                    row?.is_short_time_working_system,
                },
              })
              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.blue1, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Year month")}</span>,
      key: "year_month",
      render: (row) => {
        const m = MONTHS?.find((val) => val?.value === +row?.month)?.value
        return <span>{getDateString(row?.year, m)}</span>
      },
    },
    {
      title: <span className={"col-title"}>{t("Work style")}</span>,
      key: "workstyle_type",
      dataIndex: "workstyle_type",
      render: (id) => (
        <span>{WORK_TYPES?.find((val) => val?.value === id)?.label}</span>
      ),
    },
    {
      title: (
        <span className={"col-title"}>{t("Affiliated facilities/work")}</span>
      ),
      key: "facility_id",
      render: (row) => {
        let experienceYearLabel =
          row?.year > 2024 || (row?.year == 2024 && row?.month >= 4)
            ? !row?.experience_year
              ? t("No setting ")
              : WORK_EXPERIENCE?.find((we) => we.value == row?.experience_year)
                  ?.label
            : ""

        if (experienceYearLabel) {
          experienceYearLabel = `(${t(
            "Child welfare work experience"
          )}: ${experienceYearLabel})`
        }
        return (
          <div>
            <span>
              {
                facilities?.find((val) => val?.id === row?.facility_id)
                  ?.facility_name_short
              }
            </span>
            {" : "}
            <span>
              {
                occupations?.find((val) => val?.value === row?.occupation_id)
                  ?.label
              }
              {experienceYearLabel}
            </span>
            {row?.occupation_id_2 ? (
              <span>
                {",   "}
                {
                  occupations?.find(
                    (val) => val?.value === row?.occupation_id_2
                  )?.label
                }
                {experienceYearLabel}
              </span>
            ) : null}
          </div>
        )
      },
    },
  ]
  const router = useRouter()
  useEffect(() => {
    if (router?.asPath?.includes("#affiliate_facilities")) {
      document
        .getElementById("affiliate_facilities")
        ?.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <>
      <Wrapper id={"affiliate_facilities"}>
        <InstructorInfo
          title={t("List of affiliated facilities and occupations")}
          columns={columns}
          dataSource={data}
          btnText={t("Register affiliated facilities and occupations")}
          emptyText={t("Facility is not registered")}
          onClick={() => {
            if (operation === "create") {
              checkForDuplicate()
              formik.setFieldValue(
                `occupations.0.id`,
                occupations ? occupations[0]?.value : null
              )
              formik?.setFieldValue(
                "facility_id",
                facilities ? facilities[0]?.id : null
              )
            }
            setIsModalOpen(!isModalOpen)
          }}
          extra={
            <div className={"msg_content"}>
              <Typography.Paragraph className={"msg"}>
                {t(
                  " ※It is necessary to register the shift master in order to set the work pattern for the week. Please register the shift master."
                )}
              </Typography.Paragraph>
              <Link href={"/shift-master/add"}>
                <a target={"_blank"}>
                  <OwnerButton
                    text={t("Register shift master")}
                    shape={"circle"}
                    backgroundColor={theme.blue5}
                    color={theme.base}
                  />
                </a>
              </Link>
            </div>
          }
        />
      </Wrapper>
      <Modal
        title={t("Register Affiliated facilities and occupations")}
        closeIcon={<span>{t("Close")}</span>}
        open={isModalOpen}
        onCancel={() => {
          handleReset()
          setSelectedId(null)
          setOperation("create")
          setIsModalOpen(false)
        }}
        width={1000}
        maskClosable={false}
        destroyOnClose
        centered
        btnRightMargin={"24px"}
        footer={null}
        noPadding
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 100px)",
        }}
        wrapClassName={"instructor__info-modal"}
      >
        <form onSubmit={formik.handleSubmit}>
          <OccupationModalBodyContainer>
            <div className={"body_content"}>
              {isDuplicate ? (
                <Typography.Text type={"danger"}>
                  {t("{{date}} is already registered.", {
                    date: getDateString(
                      formik?.values?.year,
                      formik?.values?.month
                    ),
                  })}
                </Typography.Text>
              ) : (
                ""
              )}
              <div className={"year_month"}>
                <div className={"year"}>
                  <div className={"year_input"}>
                    <DatePicker
                      name={"year"}
                      picker={"month"}
                      format={"YYYY年MM月"}
                      style={{
                        width: "150px",
                      }}
                      date={
                        moment({
                          year: Number(formik?.values?.year),
                          month: Number(formik?.values?.month) - 1,
                        }).isValid()
                          ? moment({
                              year: Number(formik?.values?.year),
                              month: Number(formik?.values?.month) - 1,
                            })
                          : moment()
                      }
                      placeholder={""}
                      onDateChange={(e) => {
                        formik.setFieldValue("year", moment(e).format(FORMAT))
                        formik?.setFieldValue("month", moment(e).format("MM"))
                        checkForDuplicate(
                          +moment(e).format(FORMAT),
                          +moment(e).format("M")
                        )
                      }}
                      placement={"bottomLeft"}
                    />
                  </div>
                  <div>
                    <ErrorCounterWrapper>
                      {(formik.errors.year && formik.touched.year) ||
                      (formik.errors.month && formik.touched.month) ? (
                        <ErrorStyled>
                          {formik.errors.year ? t("Required") : ""}
                        </ErrorStyled>
                      ) : (
                        ""
                      )}
                    </ErrorCounterWrapper>
                  </div>
                </div>
              </div>

              <div className={"form_control"}>
                <div className={"label"}>{t("Work type")}</div>
                <div className={"form_input"}>
                  <RadioButtons
                    options={WORK_TYPES}
                    name={"workstyle_type"}
                    onChange={({ target: { value } }) => {
                      if (+value === 1 || +value === 3) {
                        formik?.setFieldValue(
                          "occupations",
                          formik?.values?.occupations?.slice(0, 1)
                        )
                      }
                      formik?.setFieldValue("workstyle_type", value)
                    }}
                    value={formik?.values?.workstyle_type || 1}
                  />
                </div>
              </div>
              {isApril2024ChangeApplicable(
                moment().set({
                  year: formik.values.year,
                  month: formik.values.month - 1,
                  day: 1,
                })
              ) && (
                <>
                  <div className={"form_control"}>
                    <div className={"label"}>{t("Years of experience")}</div>
                    <div className={"form_input"}>
                      <RadioButtons
                        options={WORK_EXPERIENCE}
                        name={"experience_year"}
                        onChange={(evt) => {
                          formik?.setFieldValue(
                            "experience_year",
                            evt.target.value
                          )
                        }}
                        value={formik?.values?.experience_year || null}
                      />
                    </div>
                  </div>

                  {/* <div className={"form_control"}>
                    <div className={"label"}>
                      {t("Short-time working system")}
                    </div>
                    <div className={"form_input"}>
                      <Checkbox
                        label={t("acquire")}
                        value={formik.values?.is_short_time_working_system}
                        checked={
                          formik.values?.is_short_time_working_system
                            ? true
                            : false
                        }
                        name={"is_short_time_working_system"}
                        onChange={(evt) => {
                          formik?.setFieldValue(
                            "is_short_time_working_system",
                            evt.target.checked
                          )
                        }}
                      />
                    </div>
                  </div> */}
                </>
              )}
              <div className={"form_control"}>
                <div className={"label"}>{t("Affiliated facility")}</div>
                <div className={"form_input"}>
                  <SelectInput
                    options={facilities?.map((item) => ({
                      value: item?.id,
                      label: item?.facility_name_short,
                    }))}
                    popupClassName={"select_dropdown_width"}
                    width={240}
                    height={40}
                    placeholder={t("All")}
                    bordercolor={theme.gray2}
                    id={"facility_id"}
                    name={"facility_id"}
                    value={formik?.values?.facility_id || selectedFacility}
                    onChange={(val) => {
                      formik?.setFieldValue("facility_id", val)
                      formik?.setFieldValue("one_week_work_pattern.sun", "")
                      formik?.setFieldValue("one_week_work_pattern.mon", "")
                      formik?.setFieldValue("one_week_work_pattern.tue", "")
                      formik?.setFieldValue("one_week_work_pattern.wed", "")
                      formik?.setFieldValue("one_week_work_pattern.thu", "")
                      formik?.setFieldValue("one_week_work_pattern.fri", "")
                      formik?.setFieldValue("one_week_work_pattern.sat", "")
                      setSelectedFacility(val)
                    }}
                    getPopupContainer={anchorSelInputAreaAsPopupContainer}
                  />
                  <ErrorCounterWrapper>
                    {formik.errors.facility_id &&
                      formik.touched.facility_id && (
                        <ErrorStyled>{formik.errors.facility_id}</ErrorStyled>
                      )}
                  </ErrorCounterWrapper>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Occupation")}</div>
                <div className={"form_input"}>
                  <FormikProvider value={formik}>
                    <FieldArray
                      name={"occupations"}
                      render={(arrayHelpers) => (
                        <>
                          {formik?.values?.occupations?.map(
                            (occupation, index) => (
                              <div key={index}>
                                <div className={"form_input__occupation"}>
                                  <SelectInput
                                    options={(() => {
                                      const selectedOne =
                                        formik?.values?.occupations
                                          ?.filter((_, idx) => idx !== index)
                                          ?.map((v) => v?.id)
                                      return occupations?.filter(
                                        (v) => !selectedOne?.includes(v?.value)
                                      )
                                    })()}
                                    className={
                                      "occupation-select-input dynamic-dropdown-width"
                                    }
                                    popupClassName={"select_dropdown_width"}
                                    width={"auto"}
                                    height={40}
                                    dropdownMatchSelectWidth={false}
                                    bordercolor={theme.gray2}
                                    id={`occupations.${index}.id`}
                                    name={`occupations.${index}.id`}
                                    value={occupation?.id}
                                    onChange={(val) =>
                                      formik?.setFieldValue(
                                        `occupations.${index}.id`,
                                        val
                                      )
                                    }
                                    getPopupContainer={
                                      anchorSelInputAreaAsPopupContainer
                                    }
                                  />
                                  {![2, 4].includes(
                                    +formik.values?.workstyle_type
                                  ) ? null : formik?.values?.occupations
                                      ?.length >= 2 ? (
                                    <Button
                                      icon={
                                        <MinusCircleFilled
                                          className={
                                            "form_input__occupation--icon"
                                          }
                                        />
                                      }
                                      className={"form_input__occupation--btn"}
                                      shape={"round"}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      {t("Delete")}
                                    </Button>
                                  ) : (
                                    <Button
                                      icon={
                                        <PlusCircleFilled
                                          className={
                                            "form_input__occupation--icon-add"
                                          }
                                        />
                                      }
                                      className={"form_input__occupation--btn"}
                                      shape={"round"}
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, {
                                          id: null,
                                          hour: null,
                                        })
                                      }
                                    >
                                      {t("Add")}
                                    </Button>
                                  )}
                                </div>
                                {formik?.errors?.occupations &&
                                  formik?.errors?.occupations[index] &&
                                  formik?.touched?.occupations &&
                                  formik?.touched?.occupations[index] && (
                                    <ErrorCounterWrapper>
                                      {
                                        <ErrorStyled>
                                          {t("Required")}
                                        </ErrorStyled>
                                      }
                                    </ErrorCounterWrapper>
                                  )}
                                {formik?.values?.occupations?.length >= 2 &&
                                  (occupation?.id === 1 ||
                                    occupation?.id === 6) && (
                                    <div className={"working_hours"}>
                                      <label style={{ marginRight: "5px" }}>
                                        {occupation?.id === 1
                                          ? t("Hours worked as administrator:")
                                          : t(
                                              "Number of hours worked as other:"
                                            )}
                                      </label>
                                      <Input
                                        name={`occupations.${index}.hour`}
                                        value={occupation?.hour}
                                        maxLength={4}
                                        onChange={(e) => {
                                          formik?.setFieldValue(
                                            `occupations.${index}.hour`,
                                            allowOnlyNumber(
                                              e.currentTarget.value
                                            )
                                          )
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            )
                          )}
                        </>
                      )}
                    />
                  </FormikProvider>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("One week work pattern")}</div>
                <div className={"form_input"}>
                  <div className={"work_pattern"}>
                    <table>
                      <thead>
                        <tr>
                          <th>{t("Sun")}</th>
                          <th>{t("Mon")}</th>
                          <th>{t("Tue")}</th>
                          <th>{t("Wed")}</th>
                          <th>{t("Thu")}</th>
                          <th>{t("Fri")}</th>
                          <th>{t("Sat")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              placeholder={"--"}
                              id={"one_week_work_pattern.sun"}
                              name={"one_week_work_pattern.sun"}
                              value={
                                formik?.values?.one_week_work_pattern.sun ||
                                null
                              }
                              clear
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.sun",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              clear
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              placeholder={"--"}
                              id={"one_week_work_pattern.mon"}
                              name={"one_week_work_pattern.mon"}
                              value={
                                formik?.values?.one_week_work_pattern.mon ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.mon",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              clear
                              placeholder={"--"}
                              id={"one_week_work_pattern.tue"}
                              name={"one_week_work_pattern.tue"}
                              value={
                                formik?.values?.one_week_work_pattern.tue ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.tue",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              clear
                              placeholder={"--"}
                              id={"one_week_work_pattern.wed"}
                              name={"one_week_work_pattern.wed"}
                              value={
                                formik?.values?.one_week_work_pattern.wed ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.wed",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              clear
                              placeholder={"--"}
                              id={"one_week_work_pattern.thu"}
                              name={"one_week_work_pattern.thu"}
                              value={
                                formik?.values?.one_week_work_pattern.thu ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.thu",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              clear
                              placeholder={"--"}
                              id={"one_week_work_pattern.fri"}
                              name={"one_week_work_pattern.fri"}
                              value={
                                formik?.values?.one_week_work_pattern.fri ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.fri",
                                  val
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              bordercolor={theme.gray2}
                              clear
                              placeholder={"--"}
                              id={"one_week_work_pattern.sat"}
                              name={"one_week_work_pattern.sat"}
                              value={
                                formik?.values?.one_week_work_pattern.sat ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.sat",
                                  val
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </OccupationModalBodyContainer>
          <br />
          <BtnContainers>
            <OwnerButton
              text={t("Save")}
              type={"submit"}
              shape={"circle"}
              color={theme.black}
              backgroundColor={"#FFFFFF"}
              borderColor={theme.black}
              disabled={isDuplicate}
            />
            {operation === "edit" && (
              <OwnerButton
                text={t("Delete")}
                backgroundColor={theme.red1}
                borderColor={"black"}
                color={theme.red1}
                shape={"circle"}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
          </BtnContainers>
        </form>
      </Modal>
      {openDeleteModal && (
        <DeleteConfimationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
