import styled from "styled-components"
import { theme } from "@project/shared"

export const Wrapper = styled.div`
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const OperationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .operation_content {
    display: flex;
    gap: 1rem;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
        color: ${theme.gray2};
        font-size: 14px;
        font-weight: bold;
      }
    }
    .selector-box {
      width: 500px;
    }
  }
  button {
    width: fit-content !important;
    padding: 5px 12px;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .selector-box {
      width: 300px;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .operation_content {
      flex-direction: column;
      align-items: flex-start;
      .selector-box {
        width: 300px !important;
      }
    }
  }
`
export const CTASection = styled.div`
  display: flex;
  gap: 1rem;
  button {
    width: fit-content !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
export const AlertSection = styled.div`
  border: 1px solid ${theme.red3};
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 12px;
  .title {
    display: flex;
    gap: 0.5rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .sub_text_content {
    width: 65%;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      width: 85%;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  }
  .text-red {
    color: ${theme.red3};
  }
  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
`
export const ContentSection = styled.div`
  background: ${theme.base};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;

  .content_data_table {
    tbody tr {
      display: flex;
      flex-wrap: wrap;
      column-gap: 45px;
      td {
        .label {
          display: block;
          line-height: 16px;
          width: 70mm;
          height: 33.9mm;

          .parent-name-class {
            margin-top: 10px;
            margin-left: 10px;
          }
          .parent-address-class {
            margin-bottom: 7px;
          }
          @media print {
            line-height: 12px;
          }
        }
      }
    }
  }
  .content_data {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    min-width: 200px !important;
    padding: 1rem 0 3rem 1rem;
  }
`
