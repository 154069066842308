import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  table {
    .error-message {
      margin: 0;
      padding: 0;
    }
    th {
      background-color: ${theme.rgba.blue} !important;
      font-weight: normal;
      white-space: nowrap;
      font-size: 14px;
      padding: 16px 8px !important;
      div {
        font-size: 14px;
      }
    }
    td {
      padding: 0.5rem;
      height: 85px;
      font-size: 14px !important;
    }
    .child_name {
      display: flex;
      gap: 0.2rem;
      font-size: 14px;

      .info {
        display: flex;
        font-size: 14px !important;
        align-items: center;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 100%;
          background-color: ${theme.blue5};
          color: ${theme.base};
          font-size: 14px !important;
        }
      }
    }

    .cell-content-wrapper {
      width: min-content;
      margin-inline: auto;
      .error-message {
        text-align: left;
        position: absolute;
      }
      .ant-typography {
        display: inline-block;
      }
    }

    .input__picker {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      justify-content: center;

      .select_in-l {
        width: 115px;
      }
      .select_in {
        width: 60px;
      }
      @media screen and (max-width: 426px) {
        gap: 0;

        & > .select_in-r {
          margin-right: 0.6rem;
        }

        b {
          margin-left: 4px;
          margin-right: 4px;
        }

        & > .router_number-select {
          margin-right: 0.3rem;
        }
      }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: transparent;
    }
  }
  .driver__info {
    margin-top: 2rem;
    width: fit-content;
    @media screen and (max-width: ${theme.breakpoints?.md}) {
      width: 100%;
    }
  }
`
