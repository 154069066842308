// Packages
import { t } from "i18next"
import { ButtonProps } from "antd"

// Styled components
import { StyledEditButton, StyledSorter } from "../styles"

// types
import { WithSorterProps } from "../types"

export const EditButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledEditButton
      className={"style-edit-button"}
      onClick={props.onClick}
      {...props}
    >
      <img src={"/assets/icons/edit_v3.svg"} alt={""} />
      <span className={"text"}>{t("Edit")}</span>
    </StyledEditButton>
  )
}

export const UpArrow = ({ glow }: { glow?: boolean }) => {
  return (
    <svg
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M1 4.07422L5.06082 1.33102L9.15203 4.07422"}
        stroke={glow ? "black" : "#a5a5a5"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export const DownArrow = ({ glow }: { glow?: boolean }) => {
  return (
    <svg
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M9.15234 0.921875L5.09152 3.66508L1.00032 0.921875"}
        stroke={glow ? "black" : "#a5a5a5"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export const WithSorter: React.FC<WithSorterProps> = ({
  titleProps,
  title,
  columnKey,
}) => {
  const sortedColumn = titleProps.sortColumns?.find(
    ({ column }) => column.key === columnKey
  )
  return (
    <StyledSorter>
      <span>{t(title)}</span>
      <div className={"sort-group"}>
        <span className={"sort-up"}>
          <UpArrow glow={sortedColumn?.order === "ascend"} />
        </span>
        <span className={"sort-down"}>
          <DownArrow glow={sortedColumn?.order === "descend"} />
        </span>
      </div>
    </StyledSorter>
  )
}

export { RegisterUserModel } from "./RegisterUserModel"
export { ActualExpensesInfoBlock } from "./ActualExpensesInfoBlock"
export { ActualExpensesTableBlock } from "./ActualExpensesInfoTableBlock"
