import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { DatePicker } from "../../../molecules"
import moment from "moment"
import { PrintStyleOptions, checkLists } from "./data"
import { Checkbox, SelectInput } from "@project/shared"
import { OwnerButton } from "../../../atoms"
import { Divisor, FormTitle } from "../../../../utils"

const ArrowFlex = styled.div`
  display: flex;
  min-width: 9rem;
`

const FieldContainer = styled.div`
  background-color: #fff;
  padding: 12px 24px;

  @media print {
    display: none;
  }
`

const FlexContainer = styled.div<{ type: string; top: number }>`
  display: flex;
  gap: 10px;
  align-items: ${(props) => props.type};
  margin-top: ${(props) => props.top + "px"};

  @media screen and (max-width: 500px) {
    flex-direction: column !important;
    align-items: flex-start;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 24px;
  button {
    @media screen and (max-width: 420px) {
      padding: 12px 16px;
    }
  }
`

const CheckBoxContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    white-space: inherit;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column !important;
    align-items: flex-start;
  }
`
interface IProps {
  onCheckboxChange: (val: string, checked: boolean) => void
  handleDisplayChange: () => void
  year: number
  month: number
  setMonth: (value: any) => void
  setYear: (value: any) => void
  setDay: (value: any) => void
  useOfService: Array<number>
  onCancel: () => void
  day: number
  setPrintStyle: (value: any) => void
  printStyle: number
}

const OperationContainer = ({
  onCheckboxChange,
  handleDisplayChange,
  onCancel,
  year,
  month,
  setMonth,
  setYear,
  useOfService,
  setDay,
  day,
  setPrintStyle,
  printStyle,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <FieldContainer>
      <FormTitle>{t("Operation options")}</FormTitle>
      <Divisor />
      <FlexContainer type={"center"} top={0}>
        <ArrowFlex>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
            className={"arrow-img"}
          />
          <div>{t("Reporting Date")}</div>
        </ArrowFlex>
        <DatePicker
          date={
            moment({ year: +year, month: +month - 1 }).isValid()
              ? moment({ year: +year, month: +month - 1, day: +day })
              : null
          }
          style={{
            height: "40px",
          }}
          format={"YYYY年MM月DD日"}
          showArrow={true}
          onDateChange={(value) => {
            setYear(moment(value).format("YYYY"))
            setMonth(moment(value).format("M"))
            setDay(moment(value).format("DD"))
          }}
        />
      </FlexContainer>
      <FlexContainer type={"flex-start"} top={24}>
        <ArrowFlex>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
            className={"arrow-img"}
          />
          <div>{t("Display Settings")}</div>
        </ArrowFlex>
        <CheckBoxContainer>
          {checkLists.map((val) => {
            return (
              <Checkbox
                label={t(val.label)}
                key={val.value}
                value={val.value}
                checked={useOfService.includes(val.value)}
                onChange={(evt) => {
                  onCheckboxChange(evt.target.value, evt.target.checked)
                }}
              />
            )
          })}
        </CheckBoxContainer>
      </FlexContainer>
      <FlexContainer type={"center"} top={24}>
        <ArrowFlex>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
            className={"arrow-img"}
          />
          <div>{t("Select print style")}</div>
        </ArrowFlex>
        <SelectInput
          options={PrintStyleOptions}
          onChange={(val) => setPrintStyle(val)}
          value={printStyle}
          height={40}
        />
      </FlexContainer>
      <ButtonWrapper>
        <OwnerButton
          className={"reset-button"}
          typeOf={"search"}
          icon={"search"}
          text={t("Change display")}
          onClick={handleDisplayChange}
        />

        <OwnerButton
          className={"button"}
          text={t("Reset")}
          typeOf={"secondary"}
          onClick={onCancel}
          width={150}
        />
      </ButtonWrapper>
    </FieldContainer>
  )
}

export { OperationContainer }
