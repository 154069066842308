import { SelectInput, theme } from "@project/shared"
import { notification } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { copyLastMonthJointFacilityData } from "../../../services/joinFacilityStatus"
import { AuthContext, Highlighter, Title, TitleWrapper } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"
interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  refetch?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      keyword: string
      year: any
      month: any
      facility: string
      facilityType: "facility" | "jointfacility" | null
    }>
  >
  queryParam: {
    year: any
    month: any
    facility: string
    facilityType: "facility" | "jointfacility" | null
  }
  hasData?: boolean
  setIsButtonClicked?: React.Dispatch<boolean>
  hasEditAccess?: boolean
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-up {
    margin-right: 4px;
  }
  .search-wrapper {
    @media (max-width: 767px) {
      flex-direction: row;
    }
    @media (max-width: 440px) {
      flex-direction: column;
      & > div:last-child {
        margin-top: 5px;
      }
    }
  }
  .large-select {
    @media (max-width: 520px) {
      flex-direction: column;
      & > div:last-child {
        margin-top: 5px;
      }
    }
  }

  .last-btn-wrapper {
    @media (max-width: 520px) {
      flex-direction: column;
      & > button:last-child {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 12px 20px 24px 20px;
  }
`

const InputSelectWrappers = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;

  @media screen and (max-width: 376px) {
    column-gap: 10px;
    .long-select {
      flex: 1;
    }
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
  .year-text {
    margin-right: 10px;
  }

  &.sbn_date-picker {
    width: 100%;
    @media (max-width: 375px) {
      .faculty-datepicker {
        width: 100%;
        .ant-picker {
          width: 100%;
        }
      }
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media (max-width: 520px) {
    justify-content: center;
  }
`
const Text = styled(Title)`
  font-size: 16px;
`

const UsageStatusOperationComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const currentDate = new Date()

  const router = useRouter()

  const {
    header,
    queryParam,
    setQueryParam,
    refetch,
    setIsButtonClicked,
    hasEditAccess,
  } = props
  const queryFacility = +queryParam.facility
  const queryFacilityType = queryParam.facilityType
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const [checkedFacility, setCheckedFacility] = useState({
    facility: queryFacility,
    facilityType: queryFacilityType,
  })
  const [isReset, setIsReset] = useState(false)
  const [startChar, setStartChar] = useState(null)
  const [year, setYear] = useState(queryParam.year)
  const [month, setMonth] = useState(queryParam.month)

  const { otherFacilities, facilities } = useContext(AuthContext)

  const combinedFacilityOptions = useMemo(() => {
    const opts: Array<any> = [{ value: null, label: t("All") }]

    facilities?.forEach((facility) => {
      opts.push({
        value: facility?.id + "-facility",
        label: facility.facility_name_short,
        facility_name_furigana: facility?.facility_name_furigana,
      })
    })

    otherFacilities?.forEach((item) => {
      opts.push({
        value: item?.id + "-jointfacility",
        label: item.facility_name,
        facility_name_furigana: item?.facility_name_furigana,
      })
    })

    return opts
  }, [otherFacilities, facilities])

  const handleSearch = () => {
    const searchParams: any = {
      year,
      month,
    }

    if (checkedFacility?.facilityType == "facility") {
      searchParams.facility = checkedFacility?.facility
    } else if (checkedFacility?.facilityType == "jointfacility") {
      searchParams.facility_other_id = checkedFacility?.facility
    }

    updateParams(searchParams, "/joint-facility-status-management")
    setQueryParam((prev) => {
      return {
        ...prev,
        year: year,
        month: month,
        facility: String(checkedFacility?.facility),
        facilityType: checkedFacility?.facilityType,
      }
    })
    setIsButtonClicked(true)
  }

  const handleReset = () => {
    setYear(new Date().getFullYear())
    setMonth(new Date().getMonth() + 1)
    setStartChar(null)
    setCheckedFacility(null)
    setIsReset(!isReset)

    setQueryParam((prev) => {
      return {
        ...prev,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        facility: "",
      }
    })
    setIsButtonClicked(true)

    router.push(
      {
        pathname: "/joint-facility-status-management",
        query: {},
      },
      undefined,
      { scroll: false }
    )
  }

  useEffect(() => {
    //Search Param Default to todays date but doesn't search unless hit search btn
    setYear(queryParam.year || currentDate.getFullYear())
    setMonth(queryParam.month || currentDate.getMonth() + 1)
  }, [queryParam, isReset])

  useEffect(() => {
    //Search Param Default to todays date but doesn't search unless hit search btn
    setYear(queryParam.year || currentDate.getFullYear())
    setMonth(queryParam.month || currentDate.getMonth() + 1)
    //   setOtherFacilities(OTHER_FACILITY_OPTIONS)
  }, [queryParam])

  const SORT_OPTION = FuriganaAlphabetsOption

  //copy last month data
  const { mutate: copyLastMonthData, isLoading: isCopying } = useMutation(
    copyLastMonthJointFacilityData,
    {
      onSuccess: () => {
        refetch()
      },
      onError: (err: any) => {
        notification.error({
          message: t(err?.data?.error?.message || `Failed to copy data`),
        })
      },
    }
  )

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>
      <SearchWrapper className={"search-wrapper"}>
        <TitleWrap style={{ minWidth: "165px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Year month")} </Text>
        </TitleWrap>
        <DateSelectWrap className={"sbn_date-picker"}>
          <DatePicker
            date={moment({ year: year, month: month - 1 })}
            style={{
              height: "40px",
            }}
            picker={"month"}
            format={"YYYY年MM月"}
            showArrow={true}
            disabledDate={(current) => {
              return (
                (current && current < moment("2021/01/01")) ||
                (current && current > moment().add(6, "year"))
              )
            }}
            onDateChange={(value) => {
              const y = +moment(value).format("YYYY")
              const m = +moment(value).format("M")
              setYear(y)
              setMonth(m)
            }}
          />
        </DateSelectWrap>
      </SearchWrapper>
      <SearchWrapper className={"search-wrapper large-select"}>
        <TitleWrap style={{ minWidth: "165px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Joint facility name")} </Text>
        </TitleWrap>
        <InputSelectWrappers>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            className={"select-down"}
            value={startChar}
            onChange={setStartChar}
            placeholder={"--"}
          />
          <SelectInput
            width={"auto"}
            height={40}
            className={"long-select dynamic-dropdown-width"}
            dropdownMatchSelectWidth={false}
            options={
              startChar
                ? combinedFacilityOptions?.filter((facility) =>
                    facility.facility_name_furigana?.startsWith(startChar)
                  )
                : combinedFacilityOptions
            }
            value={
              checkedFacility?.facility && checkedFacility?.facilityType
                ? checkedFacility?.facility +
                  "-" +
                  checkedFacility?.facilityType
                : null
            }
            onChange={(values) => {
              const combinedValue = values?.split("-")
              setCheckedFacility({
                facility: combinedValue?.[0],
                facilityType: combinedValue?.[1],
              })
            }}
            placeholder={t("All")}
            clearable={true}
          />
        </InputSelectWrappers>
      </SearchWrapper>
      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
      <Buttonwrapper className={"btn-wrapper last-btn-wrapper"}>
        <OwnerButton
          className={"search-btn"}
          text={t("Add new data")}
          shape={"circle"}
          typeOf={"secondary"}
          onClick={() =>
            router.push(
              `/joint-facility-status-management/add?year=${String(
                year
              )}&month=${String(month)}`
            )
          }
          disabled={isCopying || !hasEditAccess}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Copy last month data")}
          shape={"circle"}
          isLoading={isCopying}
          disabled={!hasEditAccess}
          onClick={() =>
            copyLastMonthData({
              year: year,
              month: month,
            })
          }
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { UsageStatusOperationComponent }
