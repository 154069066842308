import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Image from "next/image"
import * as yup from "yup"
import { useFormik } from "formik"
import { Wrapper } from "./index.styles"
import moment from "moment"
import { Button } from "@project/shared"
import { DatePicker } from "../DatePicker"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

interface ActionOptionsProps {
  setDate?: any
  reference?: React.RefObject<HTMLDivElement>
  date?: {
    year: number
    month: number
  }
}

const ActionOptions = ({
  setDate,
  date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
  reference,
}: ActionOptionsProps) => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const currentUrlPath = window.location.pathname

  const initialValues = date
  const validationSchema = yup.object().shape({
    year: yup.number().required(t("Please enter a number")),
    month: yup.number().required(t("Please enter a number")),
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      setDate({
        year: formik.values.year,
        month: formik.values.month,
      })
      updateParams(
        { year: formik.values.year, month: formik.values.month },
        `${currentUrlPath}`
      )
      reference?.current?.scrollIntoView({ behavior: "smooth" })
    },
  })
  useEffect(() => {
    formik?.setFieldValue("year", date?.year)
    formik?.setFieldValue("month", date?.month)
  }, [date?.month, date?.year])
  return (
    <Wrapper>
      <form className={"search"} onSubmit={formik.handleSubmit}>
        <div className={"title"}>{t("Search1")}</div>
        <div className={"searchbar"}>
          <div className={"item"}>
            <span style={{ marginRight: "3px" }}>
              <Image
                src={"/assets/icons/ArrowRight.svg"}
                alt={""}
                width={10}
                height={10}
                className={"arrow-right"}
              />
            </span>
            <span>{t("Year")}</span>&nbsp;&nbsp;
            <span>{t("Month")}</span>
          </div>
          <div className={"flex-sp"}>
            <div className={"item picker-wrapper"}>
              <DatePicker
                date={moment({
                  year: +formik.values.year,
                  month: +formik.values.month - 1,
                })}
                style={{
                  height: "40px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment().subtract(5, "year")) ||
                    (current && current > moment().add(3, "year"))
                  )
                }}
                onDateChange={(value) => {
                  formik.setFieldValue("year", moment(value).format("YYYY"))
                  formik.setFieldValue("month", moment(value).format("M"))
                }}
              />
            </div>

            <div className={"item submit-btn"}>
              <Button htmlType={"submit"}>{t("Change display")}</Button>
            </div>
          </div>
        </div>
      </form>
    </Wrapper>
  )
}

export { ActionOptions }
