import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import { Button, Spin, Table } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getRevenueData } from "../../../services/revenueManagement"
import { getDayOfWeek } from "../../../utils"
import { OwnerButton } from "../../atoms"
import { RevenueManagementOperation } from "../../molecules"
import { IRevenueManagementOperationOptions } from "../../molecules/RevenueManagementOperation"

const Wrapper = styled.div`
  margin: 20px 0;
  .revenue-list-container {
    margin: 20px 0;
    padding: 13px 30px 20px 30px;
    border-radius: 12px;
    background: #ffffff;
    .title-wrapper {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 11px;
      border-bottom: 1px solid #d2d1d1;
      display: flex;
      gap: 0px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .title-content {
        color: #191919;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
      }
      .pagination-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        button {
          background: white;
          color: black;
          border: 1px solid #d2d1d1;
          border-radius: 10px;
          height: 40px;
          margin-right: 10px;
          padding: 2px 4px;
          min-width: 75px;
          &.active {
            background: rgba(7, 130, 200, 0.2);
          }
        }
        @media screen and (max-width: 573px) {
          margin-left: auto;
          button:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }
    .sub-title-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 0px;
      flex-wrap: wrap;
      .amount-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 10px;
        .amount {
          line-height: 35px;
          font-size: 24px;
        }
      }
      .graph-nav {
        @media screen and (max-width: 600px) {
          margin-left: auto;
        }
      }
    }
    .data-container {
      margin-top: 40px;
    }
  }
`

const RevenueTable = styled(Table)`
  overflow-x: auto;
  .ant-table-cell {
    text-align: center;
    border-color: #d2d1d1 !important;
    padding: 0px;
  }
  .ant-table-cell:first-child {
    border-left: 0.5px solid;
  }
  .ant-table-thead {
    .ant-table-cell {
      border-top: 0.5px solid;
      background: #f3f3f3;
      padding: 16px;
    }
    .ant-table-cell:nth-child(n + 3) {
      min-width: 200px;
    }
  }
  tbody > tr {
    height: 1px;
    td {
      height: inherit;
      .padded-cell {
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
  tbody > tr:nth-child(2) > td:not(:first-child) {
    background: rgba(226, 104, 39, 0.1);
  }
  tbody > tr:nth-child(-n + 3) > td:first-child {
    background: #f3f3f3;
  }
  .min-wd-250 {
    min-width: 250px;
  }
  .min-width-class {
    min-width: 66px;
  }
`

const TotalComponent = styled.div`
  display: flex;
  gap: 0px;
  padding: 16px;
  align-items: flex-start;
  .attendance-info {
    display: flex;
    margin-left: 5px;
    flex-wrap: wrap;
    span {
      text-align: left;
    }
  }
`

const ServiceTag = styled.span`
  border-radius: 5px;
  padding: 2px 6px;
  min-width: 45px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`

const DayWrapper = styled.div`
  display: flex;
  gap: 0px;
  padding: 16px;
  align-items: flex-start;
  .day-info {
    display: flex;
    margin-left: 5px;
    flex-wrap: wrap;
    span {
      text-align: left;
    }
  }
`

const AchievementComponent = styled.div`
  padding: 16px;
  .each-achievement {
    .achievement-heading {
      width: 100%;
      background: ${theme.gray3};
      color: #ffffff;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
    }
    .achievement-info {
      display: flex;
      gap: 0px;
      align-items: flex-start;
      .achievement-info-content {
        text-align: initial;
        margin-left: 5px;
        span {
          margin-right: 5px;
        }
      }
    }
    margin-bottom: 20px;
  }
`

const HolidayWrapper = styled.div`
  background: rgba(224, 0, 0, 0.1);
  color: #e00000;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
`

interface IRevenueManagementPage {
  facility_options: Array<any>
  operationOptions: IRevenueManagementOperationOptions
  setOperationOptions: any
}

interface IAttendanceProps {
  label?: string
  count: number
}

interface ITotalRowProps {
  serviceType: Array<number>
  attendance?: IAttendanceProps
  absence?: IAttendanceProps
  absence1?: IAttendanceProps
  scheduled?: IAttendanceProps
  experienced?: IAttendanceProps
  displayNone?: boolean
}

interface IAchievementRowProps {
  serviceType: Array<number>
  daysUsed: {
    label: string
    displayNone?: boolean
    attendance?: IAttendanceProps
    absence?: IAttendanceProps
    absence1?: IAttendanceProps
    scheduled?: IAttendanceProps
  }
  dailyAverage: {
    label: string
    value: string
  }
  monthsAverage: {
    label: string
    value: string
  }
}

interface IDayService {
  is_holiday?: boolean
  serviceType?: Array<number>
  renderObj?: {
    attendance?: IAttendanceProps
    appointment?: IAttendanceProps
    absence?: IAttendanceProps
    absence1?: IAttendanceProps
  }
}

const getServiceObject = (serviceType: number) => {
  return serviceType === 2
    ? {
        style: {
          background: "#4DA2F8",
          color: "#ffffff",
        },
        name: "childDevelopmentSupportInitial",
      }
    : {
        style: {
          background: "#FDD95F",
          color: "#000000",
        },
        name: "afterDaySchoolServiceInitial",
      }
}

export const RevenueManagementPage = ({
  facility_options,
  operationOptions,
  setOperationOptions,
}: IRevenueManagementPage) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { data: revenueData, isLoading } = useQuery(
    ["revenue-management", operationOptions],
    async () => {
      const { data } = await getRevenueData(operationOptions)
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const renderWithPadding = (children) => {
    return <div className={"padded-cell"}>{children}</div>
  }

  const renderTotalRow = (rowObj: ITotalRowProps) => {
    const { serviceType } = rowObj
    delete rowObj.serviceType
    const serviceObjects = serviceType?.map((type) => getServiceObject(type))

    return (
      <TotalComponent>
        {serviceObjects?.map((serviceObject, index) => (
          <ServiceTag key={index} style={serviceObject.style}>
            {t(serviceObject.name)}
          </ServiceTag>
        ))}
        <div className={"attendance-info"}>
          {rowObj?.displayNone
            ? t("none")
            : Object.keys(rowObj).map((obj) => {
                return (
                  <span key={obj}>
                    {`${rowObj[obj]?.label} : ${rowObj[obj]?.count}`}
                  </span>
                )
              })}
        </div>
      </TotalComponent>
    )
  }

  const renderAchievementRow = (rowObj: IAchievementRowProps) => {
    const { daysUsed, serviceType } = rowObj
    const dayLabel = daysUsed.label
    delete daysUsed.label
    const serviceObjects = serviceType?.map((type) => getServiceObject(type))
    return (
      <AchievementComponent>
        <div className={"each-achievement"}>
          <div className={"achievement-heading"}>{dayLabel}</div>
          <div className={"achievement-info"}>
            {serviceObjects?.map((serviceObject, index) => (
              <ServiceTag key={index} style={serviceObject.style}>
                {t(serviceObject.name)}
              </ServiceTag>
            ))}
            <div className={"achievement-info-content"}>
              {daysUsed?.displayNone
                ? t("None")
                : Object.keys(daysUsed).map((obj) => (
                    <span key={obj}>
                      {`${daysUsed[obj].label} : ${daysUsed[obj].count}`}
                    </span>
                  ))}
            </div>
          </div>
        </div>
        <div className={"each-achievement"}>
          <div className={"achievement-heading"}>
            {rowObj.dailyAverage.label}
          </div>
          <div className={"achievement-info"}>
            {serviceObjects?.map((serviceObject, index) => (
              <ServiceTag key={index} style={serviceObject.style}>
                {t(serviceObject.name)}
              </ServiceTag>
            ))}
            <div className={"achievement-info-content"}>
              {rowObj.dailyAverage.value}
            </div>
          </div>
        </div>
        <div className={"each-achievement"}>
          <div className={"achievement-heading"}>
            {rowObj.monthsAverage.label}
          </div>
          <div className={"achievement-info"}>
            {serviceObjects?.map((serviceObject, index) => (
              <ServiceTag key={index} style={serviceObject.style}>
                {t(serviceObject.name)}
              </ServiceTag>
            ))}
            <div className={"achievement-info-content"}>
              {rowObj.monthsAverage.value}
            </div>
          </div>
        </div>
      </AchievementComponent>
    )
  }

  const renderDayRow = (rowObj: IDayService) => {
    if (rowObj?.is_holiday) {
      return <HolidayWrapper>{t("Holiday")}</HolidayWrapper>
    }
    const serviceObjects = rowObj?.serviceType?.map((type) =>
      getServiceObject(type)
    )
    const renderObject = rowObj?.renderObj
      ? Object.keys(rowObj?.renderObj)?.filter(
          (key) => rowObj?.renderObj?.[key]?.count
        )
      : []

    return (
      <DayWrapper>
        {serviceObjects?.map((serviceObject, index) => (
          <ServiceTag key={index} style={serviceObject.style}>
            {t(serviceObject.name)}
          </ServiceTag>
        ))}
        <div className={"day-info"}>
          {renderObject.length == 0
            ? t("none")
            : renderObject.map((key) => (
                <span
                  key={key}
                >{`${rowObj?.renderObj?.[key]?.label} : ${rowObj?.renderObj?.[key]?.count}`}</span>
              ))}
        </div>
      </DayWrapper>
    )
  }

  const getFacilityHeaders = () => {
    return facility_options
      ?.filter(
        (facility) =>
          operationOptions?.facilityIds?.length == 0 ||
          operationOptions?.facilityIds?.includes(facility?.value)
      )
      .map((facility) => {
        return {
          key: `facility${facility.value}`,
          dataIndex: `facility${facility.value}`,
          title: facility.label,
          className: "min-wd-250",
        }
      })
  }

  const columns: any = [
    {
      key: "day",
      title: t("Day"),
      onCell: (_, index) => {
        if (index < 3)
          return {
            colSpan: 2,
          }
        return {}
      },
      render: (_, row, index) => {
        let displayContent = row["day"]
        switch (index) {
          case 0:
            displayContent = t("Total")
            break
          case 1:
            displayContent = t("Amount of money")
            break
          case 2:
            displayContent = t("Achievement")
        }
        return renderWithPadding(displayContent)
      },
    },
    {
      key: "dayOfWeek",
      title: t("Day of week"),
      className: "min-width-class",
      onCell: (_, index) => {
        if (index < 3)
          return {
            colSpan: 0,
          }
        return {}
      },
      render: (row) => {
        return renderWithPadding(row["dayOfWeek"])
      },
    },
    ...getFacilityHeaders(),
  ]

  const dataSource: any = []

  const prepareDataSource = () => {
    const totalInfoRow: any = {}
    const amountInfoRow: any = {}
    const achievementInfoRow: any = {}
    facility_options?.map((facilityOption) => {
      if (
        operationOptions?.facilityIds?.length == 0 ||
        operationOptions?.facilityIds?.includes(facilityOption?.value)
      ) {
        const facilityData = revenueData?.facility_data?.find(
          (f) => f?.facility_id == facilityOption?.value
        )
        const facilityKey = `facility${facilityOption?.value}`

        const facilityServices = []
        if (facilityData?.facility_type)
          facilityServices?.push(+facilityData?.facility_type)
        if (facilityData?.facility_type2)
          facilityServices?.push(+facilityData?.facility_type2)

        const renderableTotalObject: any = {
          serviceType: facilityServices,
        }

        const achievementObject: any = {
          serviceType: facilityServices,
          daysUsed: {
            label: t("days_used"),
          },
          dailyAverage: {
            label: t("average_daily_users"),
            value: facilityData?.this_month_average_users_rate ?? "0人/0%",
          },
          monthsAverage: {
            label: t("three_months_average_number_of_users_rate"),
            value: facilityData?.three_month_average_users_rate ?? "0.00人/0%",
          },
        }
        if (facilityData?.present_count) {
          renderableTotalObject.attendance = {
            label: t("present_count"),
            count: facilityData?.present_count,
          }
          achievementObject.daysUsed.attendance = {
            label: t("present_count"),
            count: facilityData?.present_count,
          }
        }
        if (facilityData?.absent_count) {
          renderableTotalObject.absence = {
            label: t("Absence 1"),
            count: facilityData?.absent_count,
          }
          achievementObject.daysUsed.absence = {
            label: t("Absence 1"),
            count: facilityData?.absent_count,
          }
        }
        if (facilityData?.absent_no_addition_count) {
          renderableTotalObject.absence1 = {
            label: t("Absence 1 (No addition)"),
            count: facilityData?.absent_no_addition_count,
          }
          achievementObject.daysUsed.absence1 = {
            label: t("Absence 1 (No addition)"),
            count: facilityData?.absent_no_addition_count,
          }
        }
        if (facilityData?.scheduled_count) {
          renderableTotalObject.scheduled = {
            label: t("Scheduled"),
            count: facilityData?.scheduled_count,
          }
          achievementObject.daysUsed.scheduled = {
            label: t("Scheduled"),
            count: facilityData?.scheduled_count,
          }
        }

        if (facilityData?.experience_count) {
          renderableTotalObject.experienced = {
            label: t("Experience"),
            count: facilityData?.experience_count,
          }
          achievementObject.daysUsed.experienced = {
            label: t("Experience"),
            count: facilityData?.experience_count,
          }
        }

        if (Object.keys(renderableTotalObject).length <= 1) {
          renderableTotalObject.displayNone = true
        }

        if (Object.keys(achievementObject.daysUsed).length <= 1) {
          achievementObject.daysUsed.displayNone = true
        }

        totalInfoRow[facilityKey] = renderTotalRow(renderableTotalObject)
        amountInfoRow[facilityKey] =
          (facilityData?.sales_amount?.toLocaleString() ?? `0`) + t("Yen")
        achievementInfoRow[facilityKey] =
          renderAchievementRow(achievementObject)
      }
    })

    dataSource.push(totalInfoRow)
    dataSource.push(amountInfoRow)
    dataSource.push(achievementInfoRow)

    const daysInMonth = new Date(
      operationOptions.year,
      operationOptions.month,
      0
    ).getDate()
    const holidayObj = {
      is_holiday: true,
    }

    for (let thisDate = 1; thisDate <= daysInMonth; thisDate++) {
      const thisDay = new Date(
        operationOptions.year,
        operationOptions.month - 1,
        thisDate
      )
      const thisDayFormat = moment(thisDay).format("YYYY-MM-DD")
      const dayOnWeek = thisDay.getDay()
      const dayOfWeek = t(getDayOfWeek(dayOnWeek))

      const dayData = {
        day: thisDate,
        dayOfWeek,
      }

      facility_options?.map((facilityOption) => {
        if (
          operationOptions?.facilityIds?.length == 0 ||
          operationOptions?.facilityIds?.includes(facilityOption?.value)
        ) {
          const facilityData = revenueData?.facility_data?.find(
            (f) => f?.facility_id == facilityOption?.value
          )
          const dayFacilityServices = []
          if (facilityData?.facility_type)
            dayFacilityServices.push(+facilityData?.facility_type)
          if (facilityData?.facility_type2)
            dayFacilityServices.push(+facilityData?.facility_type2)

          let dayObject: any = {
            serviceType: dayFacilityServices,
            renderObj: {},
          }
          const facilityKey = `facility${facilityOption?.value}`
          const facilityDayInfo = facilityData?.day_attendance_stat?.find(
            (facilityDayData) =>
              moment(facilityDayData?.date).format("YYYY-MM-DD") ==
              thisDayFormat
          )

          if (facilityDayInfo?.is_holiday) {
            dayObject = holidayObj
          } else {
            if (facilityDayInfo?.present_count) {
              dayObject.renderObj.attendance = {
                label: t("present_count"),
                count: facilityDayInfo?.present_count,
              }
            }

            if (facilityDayInfo?.absent_count) {
              dayObject.renderObj.absence = {
                label: t("Absence 1"),
                count: facilityDayInfo?.absent_count,
              }
            }

            if (facilityDayInfo?.absent_no_addition_count) {
              dayObject.renderObj.absence1 = {
                label: t("Absence 1 (No addition)"),
                count: facilityDayInfo?.absent_no_addition_count,
              }
            }

            if (facilityDayInfo?.scheduled_count) {
              dayObject.renderObj.scheduled = {
                label: t("Scheduled"),
                count: facilityDayInfo?.scheduled_count,
              }
            }

            if (facilityDayInfo?.experience_count) {
              dayObject.renderObj.experience = {
                label: t("Experience"),
                count: facilityDayInfo?.experience_count,
              }
            }
          }
          dayData[facilityKey] = renderDayRow(dayObject)
        }
      })

      dataSource.push(dayData)
    }
  }

  prepareDataSource()

  useEffect(() => {
    const queries = []
    Object.keys(operationOptions).map((key) => {
      if (Array.isArray(operationOptions[key])) {
        if (operationOptions[key].length)
          queries.push(`${key}=${operationOptions[key].join(",")}`)
      } else if (operationOptions[key])
        queries.push(`${key}=${operationOptions[key]}`)
    })
    if (queries.length)
      router.push("?" + queries.join("&"), undefined, { shallow: true })
  }, [operationOptions])

  return (
    <Wrapper>
      <RevenueManagementOperation
        facility_options={facility_options}
        operationOptions={operationOptions}
        setOperationOptions={setOperationOptions}
      />
      {isLoading ? (
        <LoaderWrapper>
          <Spin size={"large"} className={"loader"} />
        </LoaderWrapper>
      ) : (
        <div className={"revenue-list-container"}>
          <div className={"title-wrapper"}>
            <div className={"title-content"}>
              {t("salesTitle", {
                year: operationOptions.year,
                month: operationOptions.month.toString().padStart(2, "0"),
              })}
            </div>
            <div className={"pagination-wrapper"}>
              <Button
                onClick={() => {
                  let currentYear = operationOptions.year
                  let currentMonth = operationOptions.month
                  if (currentMonth == 1) {
                    currentMonth = 12
                    --currentYear
                  } else {
                    --currentMonth
                  }
                  setOperationOptions({
                    ...operationOptions,
                    year: currentYear,
                    month: currentMonth,
                  })
                }}
                className={"left-btn"}
              >
                <DoubleLeftOutlined />
                <span className={"left-btn-label"}>{t("Last month")}</span>
              </Button>
              <Button
                className={"active active-btn"}
                onClick={() => {
                  const today = new Date()
                  const month = today.getMonth() + 1
                  const year = today.getFullYear()
                  setOperationOptions({
                    ...operationOptions,
                    year,
                    month,
                  })
                }}
              >
                {t("This month")}
              </Button>
              <Button
                onClick={() => {
                  let currentYear = operationOptions.year
                  let currentMonth = operationOptions.month
                  if (currentMonth == 12) {
                    ++currentYear
                    currentMonth = 1
                  } else {
                    ++currentMonth
                  }
                  setOperationOptions({
                    ...operationOptions,
                    year: currentYear,
                    month: currentMonth,
                  })
                }}
                className={"right-btn"}
              >
                <span className={"right-btn-label"}>{t("Next month")}</span>
                <DoubleRightOutlined />
              </Button>
            </div>
          </div>
          <div className={"sub-title-wrapper"}>
            <div className={"amount-title"}>
              <Trans
                i18nKey={"salesTotal"}
                values={{
                  year: operationOptions.year,
                  month: operationOptions.month.toString().padStart(2, "0"),
                  amount: !isNaN(revenueData?.total_sales)
                    ? revenueData?.total_sales?.toLocaleString()
                    : 0,
                }}
                components={{
                  amountComponent: <span className={"amount"} />,
                }}
              />
            </div>
            <div className={"graph-nav"}>
              <OwnerButton
                typeOf={"secondary"}
                text={t("Display graph")}
                onClick={() => {
                  router.push(
                    `/revenue-management/graph?year=${
                      operationOptions.year
                    }&month=${
                      operationOptions.month
                    }&facilityIds=${operationOptions.facilityIds.join(",")}`
                  )
                }}
              />
            </div>
          </div>
          <div className={"data-container"}>
            <RevenueTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered={true}
              className={"always-present-scrollbar"}
              scroll={{ x: "scroll" }}
            />
          </div>
        </div>
      )}
    </Wrapper>
  )
}
