import { t } from "i18next"
import { Title } from "../../../PrintListContent.style"

export function HiroshimaSheetTitle() {
  return (
    <Title>
      {t("User burden maximum amount management result sheet")}
      <br />
      <span style={{ fontWeight: "normal", fontSize: "12px" }}>
        {t(
          "(For those eligible for the Hiroshima City Disability Welfare Service User Bursary Subsidy)"
        )}
      </span>
    </Title>
  )
}
