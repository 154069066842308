import React from "react"

import {
  ReceiptSiblingUpperLimitManagement,
  SiblingChild,
} from "../../../../types"
import { HiroshimaFlame as HiroshimaOneChildFlame } from "../../OneChildSheet/flame/HiroshimaFlame"

type Props = {
  receiptSiblingUpperLimitManagement: ReceiptSiblingUpperLimitManagement
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

// Same OneChildFlame...
export function HiroshimaFlame({
  receiptSiblingUpperLimitManagement,
  printConfig = [],
}: Props) {
  return (
    <HiroshimaOneChildFlame
      receiptUpperLimitManagement={receiptSiblingUpperLimitManagement}
      printConfig={printConfig}
    />
  )
}
