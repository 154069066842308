import { theme } from "@project/shared"

import styled from "styled-components"

export const ContentWrapper = styled.section`
  @media print {
    @page {
      margin: 5mm 2mm !important;
    }
    [class*="SearchContent"],
    [class*="PrintButtons"],
    [class*="Desc"] {
      display: none;
    }
    [class*="PageCard"] {
      border: none;
    }
    thead {
      th {
        background-color: white;
        color: black;
      }
    }
  }
`
export const PrintButtonsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  button {
    padding: 8px;
    min-width: 90px;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    button {
      width: fit-content;
    }
  }
`
export const DescStyled = styled.div`
  width: 100%;
  a {
    color: #0a82c8;
  }
  .activity-description {
    line-height: 1.8;
  }
  .pagination-buttons-container {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 10px;
    button {
      min-width: 75px;
      max-height: 40px;
      padding: 12px 20px;
      border-radius: 8px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
      margin-left: 0px;
      max-width: 360px;
      justify-content: flex-start;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`
export const SearchContentSection = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .flex {
      display: flex;
    }
    .align-center {
      align-items: center;
    }
    .gap-6 {
      gap: 6px;
    }
    .icon {
      margin-top: 8px;
    }
    .operation-section {
      display: flex;
      .label {
        min-width: 150px;
        max-width: 150px;
        @media (max-width: ${theme.breakpoints?.md}) {
          min-width: 100%;
          max-width: 100%;
        }
        svg {
          color: ${theme.gray2};
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .checkboxs_container {
        .check_uncheck_all {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          gap: 10px;
          button {
            height: 30px;
            display: flex;
            gap: 4px;
            align-items: center;
            outline: 0;
            width: fit-content;

            &:hover,
            &:focus {
              border-color: ${theme.gray2};
            }
            .span {
              @media (max-width: ${theme.breakpoints.md}) {
                font-size: 14px;
              }
            }

            @media screen and (max-width: 480px) {
              &:last-child {
                margin-left: 40px;
              }
            }
          }
          @media (max-width: ${theme.breakpoints?.sm}) {
            display: grid;
            grid-row-gap: 10px;
            grid-column-gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(90px, 110px));
          }
        }
        .checkboxs {
          display: flex;
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 20px;
          @media (max-width: ${theme.breakpoints?.sm}) {
            row-gap: 0px;
            .facility_checkbox {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      @media (max-width: ${theme.breakpoints?.md}) {
        flex-direction: column;
        align-items: start;
      }
    }
    @media (max-width: ${theme.breakpoints?.sm}) {
      display: grid;
      gap: 20px;
    }
  }
`
export const TableContentSection = styled.div`
  width: 100% !important;
  .col-title {
    white-space: nowrap;
  }
  @media screen and (max-width: 1150px) {
    .pagination-buttons-container {
      margin-top: 16px;
    }
  }
`

export const TableContainerStyled = styled.div`
  overflow: auto;
  margin-top: 25px;
  table {
    border-collapse: collapse;
  }
  .ant-table-cell {
    border: 1px solid ${theme.gray2};
  }
  thead {
    th.ant-table-cell {
      background-color: #666;
      color: white;
      padding: 8px 16px;
      position: relative;
      text-align: center;
    }
  }
  table th {
    &:first-child,
    &:nth-child(2) {
      width: 42px;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 0px;
    .saturday {
      color: #2121f7;
    }
    .sunday {
      color: #ea3323;
    }
    .program-text-wrapper {
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &.sunday,
      &.saturday {
        padding: 0px;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-color: #fae0e3;
      }
      .program-text {
        display: flex;
        gap: 4px;
        align-items: center;
        .triangle {
          color: #cc4d25;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 5px solid #cc4d25;
          border-bottom: 5px solid transparent;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    margin-top: 12px;
  }
  @media print {
    overflow: visible !important;
    table {
      overflow: visible !important;
      page-break-after: auto !important;

      .ant-table-thead > tr > th {
        outline: 1px solid ${theme.gray2} !important;
        background-color: transparent !important;
        color: ${theme.black};
      }
      .ant-table-thead {
        display: table-header-group !important;
      }
      tbody {
        tr {
          page-break-inside: avoid !important;
          page-break-after: auto !important;
          td {
            .program-text-wrapper {
              &.sunday,
              &.saturday {
                top: 0.5px !important;
                bottom: 0.5px !important;
                left: 0.5px !important;
                right: 0.5px !important;
                height: calc(100% - 1px) !important;
                width: calc(100% - 1px) !important;
              }
            }
          }
        }
      }
    }
  }
`
