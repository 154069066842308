import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StyledContainer = styled.div`
  .flex {
    display: flex;
  }
  .top-row {
    padding: 8px;
    border: 1px solid black;
  }
  .bottom-row {
    div {
      width: 50%;
      padding: 8px;
      &:first-child {
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
      }
      &:nth-child(2) {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }
`

export const DriversSection = ({
  show,
  driverName = "",
  vehicleName = "",
  vehicleNumber = "",
}: {
  show: boolean
  driverName?: string
  vehicleName?: string
  vehicleNumber?: string
}) => {
  const { t } = useTranslation()
  if (!show) return <></>

  return (
    <StyledContainer>
      <div className={"flex top-row"}>
        {t("Driver's name") + `: ${driverName}`}
      </div>
      <div className={"flex bottom-row"}>
        <div>{t("Vehicle name") + `:  ${vehicleName}`}</div>
        <div>{t("Vehicle number") + `: ${vehicleNumber}`}</div>
      </div>
    </StyledContainer>
  )
}
