import styled from "styled-components"
import { theme } from "@project/shared"
export const Wrapper = styled.div`
  table {
    thead {
      .top_col {
        background: ${theme.blue5};
        color: ${theme.base};
      }
      tr > th {
        background: ${theme.blue6};
      }
    }
    tbody {
      tr > td {
        vertical-align: top !important;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &__value {
      display: flex;
      flex-direction: column;
      .time {
        font-weight: bold;
      }
    }
  }
`
