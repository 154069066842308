// packages
import { t } from "i18next"

// types
import { ITableDataType, TransferAccountInfoProps } from "../types"

// services-types
import {
  BankFormatOutputDataProps,
  BankFormatOutputResponseProps,
} from "../../../../services"
import cuid from "cuid"

export const formatOutputTableMapper = (
  data: BankFormatOutputResponseProps
): Array<ITableDataType[]> => {
  const ObjValArray = Object.values(data.data)

  const mappedData = ObjValArray.map((itemArr: BankFormatOutputDataProps[]) => {
    return itemArr.map((item) => {
      const uniqueIdentifier = cuid()
      return {
        key: item.child_id + "",
        id: item.parent_id + "_" + uniqueIdentifier,
        parent_name_hiraga: `${item.parent_name} (${item.parent_name_furigana})`,
        child_name_hiraga: `${item.child_name} (${item.child_name_furigana})`,
        beneficiary_certificate_number: item.receiving_certificate_number,
        facility: item.facility_name_short,
        invoice_amount: item.billing_amount,
        provision_city: item.provision_city,
        city_code: +item.city_number || null,
        has_account_info: item.has_account_info,
        billed_amount: item.billed_amount,
      }
    })
  })

  return mappedData
}

export const formatTransferAccountInfo = (
  transferAccountInfo: TransferAccountInfoProps
) => {
  if (transferAccountInfo) {
    return {
      directDebitConsignorNo:
        transferAccountInfo.debit_account_transfer_consign_no
          ? transferAccountInfo.debit_account_transfer_consign_no + ""
          : t("Unregistered"),
      consignorNameKana: transferAccountInfo.consign_name_katakana
        ? transferAccountInfo.consign_name_katakana + ""
        : t("Unregistered"),
      bankNumber: transferAccountInfo.bank_no
        ? transferAccountInfo.bank_no + ""
        : t("Unregistered"),
      branchNumber: transferAccountInfo.branch_number
        ? transferAccountInfo.branch_number
        : t("Unregistered"),
      accountType: transferAccountInfo.account_type
        ? transferAccountInfo.account_type + ""
        : t("Unregistered"),
      accountNumber: transferAccountInfo.account_number
        ? transferAccountInfo.account_number + ""
        : t("Unregistered"),
    }
  }
  return {
    directDebitConsignorNo: t("Unregistered"),
    consignorNameKana: t("Unregistered"),
    bankNumber: t("Unregistered"),
    branchNumber: t("Unregistered"),
    accountType: t("Unregistered"),
    accountNumber: t("Unregistered"),
  }
}
