import {
  Checkbox,
  PICKUP_DISPLAY_OPTIONS,
  RadioButtons,
  SelectInput,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Col, Row } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"

interface IOperationDailyReportView {
  facility: Array<any> | null
  date: string
  facilities: Array<any> | null
  onSubmit: any
}

const OperationWrapper = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .flexbox {
    display: flex;
    gap: 10px;
    color: #191919;
  }
  .multiple-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
  .multiple-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .change-display-container {
    margin-top: 10px;
  }
  .custom-input {
    width: auto;
    height: 30px;
  }
  .custom-input:hover,
  .custom-input:focus {
    border-color: #d9d9d9;
  }
  .bulk-setting {
    margin-bottom: 20px;
    padding-left: 12px;
    border-left: 4px solid ${theme.blue5};
  }
  @media (max-width: 768px) {
    .ant-row {
      align-items: flex-start;
      flex-direction: column;
    }
    .operation-label {
      margin-bottom: 5px;
    }
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
  }
`

const DAILY_REPORT_DISPLAY_OPTIONS = [
  {
    key: "facility",
    label: "Facility",
    value: "facility",
  },
  {
    key: "date",
    label: "Date",
    value: "date",
  },
  {
    key: "activity",
    label: "Activity",
    value: "activity",
  },
  {
    key: "entry",
    label: "Entry Person",
    value: "entry",
  },
  {
    key: "manager",
    label: "Facility Manager Confirmation",
    value: "manager",
  },
  {
    key: "approval",
    label: "Approval",
    value: "approval",
  },
  {
    key: "service",
    label: "Service Used",
    value: "service",
  },
  {
    key: "attendance",
    label: "Attendance",
    value: "attendance",
  },
  {
    key: "provision",
    label: "Form of provision",
    value: "provision",
  },
  {
    key: "enter_exit",
    label: "Entering and exiting the room",
    value: "enter_exit",
  },
  {
    key: "temperature",
    label: "Body temperature upon entry",
    value: "temperature",
  },
  {
    key: "actual_cost",
    label: "Actual Cost ",
    value: "actual_cost",
  },
  {
    key: "pickup",
    label: "Pick up destination",
    value: "pickup",
  },
  {
    key: "service_content",
    label: "Service Content",
    value: "service_content",
  },
  {
    key: "content",
    label: "Content",
    value: "content",
  },
  {
    key: "image",
    label: "Image file",
    value: "image",
  },
  {
    key: "staff",
    label: "Worker column",
    value: "staff",
  },
]

export const OperationDailyReportView = ({
  facility,
  date,
  facilities,
  onSubmit = null,
}: IOperationDailyReportView) => {
  const { t } = useTranslation()

  // Load displayContents from localStorage or use default values
  const defaultDisplayContents = [
    "facility",
    "attendance",
    "pickup",
    "date",
    "activity",
    "entry",
    "enter_exit",
    "content",
    "temperature",
    "service",
    "actual_cost",
    "manager",
  ]

  const savedDisplayContents = JSON.parse(
    localStorage.getItem("displayContents")
  )

  const displayContents = savedDisplayContents || defaultDisplayContents

  const initialValues = {
    facility,
    date,
    displayContents,
    pickup_display_style: 2,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      localStorage.setItem(
        "displayContents",
        JSON.stringify(formik.values.displayContents)
      )
      onSubmit(formik.values)
    },
  })

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Facility")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <SelectInput
              width={150}
              height={40}
              name={"facility"}
              options={facilities}
              value={formik.values.facility}
              placeholder={t("All")}
              onChange={(val) => {
                formik.setFieldValue(
                  "facility",
                  facilities.find((fac) => fac.value == val)
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Date")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <DatePicker
              onDateChange={(val) => {
                formik.setFieldValue(
                  "date",
                  moment(val).isValid()
                    ? moment(val, "YYYY-MM-DD")
                    : moment(new Date(), "YYYY-MM-DD")
                )
              }}
              style={{ height: "40px", width: "160px" }}
              date={moment(formik.values.date)}
              format={"YYYY年MM月DD日"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Display contents")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-checkboxes"}>
              {DAILY_REPORT_DISPLAY_OPTIONS.map((option) => (
                <Checkbox
                  key={option.key}
                  label={t(option.label)}
                  value={option.value}
                  name={"displayContents"}
                  onChange={formik.handleChange}
                  checked={formik.values.displayContents.includes(option.value)}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Display pick-up")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <RadioButtons
              options={PICKUP_DISPLAY_OPTIONS}
              value={formik.values.pickup_display_style}
              name={"pickup_display_style"}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <div className={"display-change-container"}>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Change display")}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
