import React from "react"
import { t } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import { Table, Typography } from "antd"
import styled from "styled-components"

import {
  Checkbox,
  Grid,
  RadioButtons,
  SelectInput,
  theme,
} from "@project/shared"
import { ColumnsType } from "antd/lib/table"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .table_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .check_text {
      margin-bottom: 1rem;
      display: flex;
      font-size: 16px;
    }
    .inner_list-container {
      .inner_list {
        display: flex;
        min-height: 60px;
        &:not(:first-child) {
          border-top: 1px solid ${theme.gray2};
        }
        &-content {
          display: flex;
          padding: 0 0.5rem;
          width: 150px;
          align-items: center;
          justify-content: center;
          &:not(:last-child) {
            border-right: 1px solid ${theme.gray2};
          }
        }
      }
    }

    .ant-table-thead .ant-table-cell {
      background-color: ${theme.gray2};
    }
    table:hover,
    tr:hover,
    thead:hover {
      background: unset !important;
    }
    table thead tr th {
      padding: 0 0.2rem !important;
    }
    table tbody tr td {
      padding: 0rem;
    }
  }
  .bottom_content {
    border: 1px solid ${theme.bgColor2};
    padding: 0.5rem;
    font-size: 14px;
    width: fit-content;
  }
  .selected-row-yellow {
    background: #ffe694 !important;
    &:hover {
      td {
        background: #ffe694 !important;
      }
    }
  }
  .ant-table-tbody > tr.selected-row-yellow > td {
    transition: background 0s;
  }

  .required_order {
    border: 1px solid ${theme.red2};
    border-radius: 5px;
  }
`

const radioOptions1 = [
  {
    label: t("do not output"),
    value: 0,
  },
  {
    label: t("output"),
    value: 1,
  },
]
const radioOptions2 = [
  {
    label: t("do not"),
    value: 0,
  },
  {
    label: t("do"),
    value: 1,
  },
]

export const BillingCSVOutputTable = ({
  parentList,
  outputSetting,
  updateOutputSetting,
  updateOutputSiblingChild,
  bottomText,
}: {
  parentList: Array<any>
  outputSetting: any
  updateOutputSetting: (key, value) => void
  updateOutputSiblingChild: (val) => void
  bottomText: string
}) => {
  const { t } = useTranslation()
  const getSiblingOptions = (allChild, thisChild) => {
    const options = []
    const siblingOrderToSkip = []
    allChild?.map((all) => {
      if (all?.child_id != thisChild?.child_id && all?.sibling_order)
        siblingOrderToSkip.push(all?.sibling_order)
    })
    for (let i = 0; i <= allChild.length; i++) {
      if (!siblingOrderToSkip.includes(i))
        options.push({
          label: i ? "" + i : "--",
          value: i,
        })
    }
    return options
  }

  const sharedOnCell = () => {
    return { colSpan: 0 }
  }

  const columns: ColumnsType<any> = [
    {
      key: 1,
      title: t("Select"),
      render: (data) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            checked={data?.is_selected}
            onChange={() => {
              const newParent = parentList?.map((p) => {
                if (p?.parent_id == data?.parent_id)
                  p.is_selected = !p.is_selected
                return p
              })
              updateOutputSiblingChild(newParent)
            }}
          />
        </div>
      ),
    },
    {
      key: 2,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Guardian name")}
        </span>
      ),
      width: 150,
      align: "center",
      render: (data) => {
        return {
          props: {
            style: {
              padding: "0px !important",
            },
          },
          children: (
            <div style={{ padding: "0.5rem" }}>
              {`${data?.parent_name} (${data?.parent_name_furigana})`}
            </div>
          ),
        }
      },
    },
    {
      key: 7,
      title: t("Multiple Child Selection"),
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              padding: "0px !important",
            },
          },
          children: (
            <div className={"inner_list-container"}>
              {row?.child?.map((val, i) => (
                <div className={"inner_list"} key={i}>
                  <div className={"inner_list-content"}>
                    <Checkbox
                      disabled={!row?.is_selected}
                      checked={val?.multiple_child_selection ? true : false}
                      onChange={() => {
                        const newParentList = parentList?.map((p) => {
                          if (p?.parent_id == row?.parent_id) {
                            p.child = p?.child?.map((c) => {
                              if (c?.child_id == val?.child_id) {
                                c.multiple_child_selection =
                                  c.multiple_child_selection ? 0 : 1
                              }
                              return c
                            })
                          }
                          return p
                        })
                        updateOutputSiblingChild(newParentList)
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ),
        }
      },
    },
    {
      key: 2,
      title: t("Order of siblings"),
      width: 150,
      align: "center",
      render: (row) => {
        return {
          props: {
            style: {
              padding: "0px !important",
            },
          },
          children: (
            <div className={"inner_list-container"}>
              {row?.child?.map((val, i) => (
                <div className={"inner_list"} key={i}>
                  <div className={"inner_list-content"}>
                    <SelectInput
                      placeholder={"---"}
                      notFoundContent={t("No data")}
                      options={getSiblingOptions(row?.child, val)}
                      width={80}
                      value={val?.sibling_order}
                      disabled={!row?.is_selected}
                      onChange={(value) => {
                        const newParentList = parentList?.map((p) => {
                          if (p?.parent_id == row?.parent_id) {
                            p.child = p?.child?.map((c) => {
                              if (c?.child_id == val?.child_id) {
                                c.sibling_order = value
                              }
                              return c
                            })
                          }
                          return p
                        })
                        updateOutputSiblingChild(newParentList)
                      }}
                      id={`sibling_order_${val?.child_id}`}
                    />
                  </div>
                  <div className={"inner_list-content"}>
                    {`${val?.child_name} (${val?.child_name_furigana})`}
                  </div>
                  <div className={"inner_list-content"}>
                    {val?.receiving_certificate_number}
                  </div>
                  <div className={"inner_list-content"}>{`${
                    val?.burden_upper_limit_monthly
                      ? val?.burden_upper_limit_monthly?.toLocaleString()
                      : 0
                  } 円`}</div>
                </div>
              ))}
            </div>
          ),
        }
      },
      onCell: () => ({
        colSpan: 4,
      }),
    },
    {
      key: 4,
      align: "center",
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Child name")}
        </span>
      ),
      width: 150,
      onCell: sharedOnCell,
      render: (data) => <>{data?.child_name}</>,
    },
    {
      key: 5,
      align: "center",
      title: t("Beneficiary certificate number"),
      width: 150,
      onCell: sharedOnCell,
    },
    {
      key: 6,
      width: 150,
      align: "center",
      title: t("Burden upper limit monthly amount"),
      onCell: sharedOnCell,
    },
  ]

  return (
    <Wrapper>
      <Typography.Text>
        {t("Select the option to output to CSV.")}
      </Typography.Text>
      <div className={"table_content"}>
        <Grid className={"grid-header"} background>
          {t("National Health Insurance Billing - Form/CSV output")}
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <RadioButtons
            options={radioOptions1}
            value={outputSetting?.insurance_billing}
            onChange={(e) =>
              updateOutputSetting("insurance_billing", e?.target?.value)
            }
          />
        </Grid>
        <Grid className={"flex-break"} />

        <Grid className={"grid-header"} background>
          {t("sibling limit management")}
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <RadioButtons
            options={radioOptions2}
            value={outputSetting?.sibling_limit_management}
            onChange={(e) =>
              updateOutputSetting("sibling_limit_management", e?.target?.value)
            }
          />
          {outputSetting?.sibling_limit_management ? (
            <>
              <Typography.Text>
                {t(
                  "Please select a user who will manage the maximum amount with siblings"
                )}
              </Typography.Text>
              <div className={"check_text"}>
                <Checkbox
                  checked={outputSetting?.upper_limit_management_multiple_child}
                  onChange={(e) =>
                    updateOutputSetting(
                      "upper_limit_management_multiple_child",
                      e?.target?.checked ? 1 : 0
                    )
                  }
                />
                <Typography.Text>
                  <Trans>
                    {t(
                      "Request the upper limit management addition for multiple children in {{value}} household",
                      {
                        value: 1,
                      }
                    )}
                    <span style={{ color: theme.red3 }}>
                      {t(
                        "(※Please check the box only if you can confirm that the municipality can charge.)"
                      )}
                    </span>
                  </Trans>
                </Typography.Text>
              </div>
              <Table
                columns={
                  outputSetting?.upper_limit_management_multiple_child
                    ? columns
                    : columns?.filter((col) => col.key != 7)
                }
                dataSource={parentList?.map((p) => {
                  return {
                    ...p,
                    key: p?.parent_id,
                  }
                })}
                bordered
                scroll={{ x: true }}
                pagination={false}
                rowClassName={(row) =>
                  row?.is_selected ? "selected-row-yellow" : ""
                }
                className={"always-present-scrollbar"}
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid className={"flex-break"} />

        <Grid className={"grid-header"} background>
          {t(
            "Billed even if the number of days used exceeds the contracted amount"
          )}
        </Grid>
        <Grid className={"flex-full grid-row-last"}>
          <RadioButtons
            options={radioOptions2}
            value={outputSetting?.bill_on_Exceed}
            onChange={(e) =>
              updateOutputSetting("bill_on_Exceed", e?.target?.value)
            }
          />
        </Grid>

        <Grid className={"flex-break"} />
      </div>
      <div className={"bottom_content"}>{bottomText}</div>
    </Wrapper>
  )
}
