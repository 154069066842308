const PaperIcon = () => (
  <svg
    width={"10"}
    height={"14"}
    viewBox={"0 0 10 14"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M9.85 3.65L6.35 0.15C6.25 0.05 6.15 0 6 0H1C0.45 0 0 0.45 0 1V13C0 13.55 0.45 14 1 14H9C9.55 14 10 13.55 10 13V4C10 3.85 9.95 3.75 9.85 3.65ZM6 1.2L8.8 4H6V1.2ZM9 13H1V1H5V4C5 4.55 5.45 5 6 5H9V13Z"
      }
      fill={"black"}
    />
    <path d={"M2 10H8V11H2V10ZM2 7H8V8H2V7Z"} fill={"black"} />
  </svg>
)

export default PaperIcon
