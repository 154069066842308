// types
import { ITableDataType } from "../types"

/* Data sample constant remove it later */
export const dataSample: ITableDataType[] = [
  {
    key: "1",
    id: "1",
    parent_name_hiraga: "和田純一 （わだじゅんいち）",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 4600,
  },
  {
    key: "2",
    id: "2",
    parent_name_hiraga: "和田純一 ",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 4600,
  },
  {
    key: "3",
    id: "3",
    parent_name_hiraga: "和田純一 （わだじゅんいち）",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 4345,
  },

  {
    key: "4",
    id: "4",
    parent_name_hiraga: "和田純一 （わだじゅんいち）",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 4212,
  },
  {
    key: "5",
    id: "5",
    parent_name_hiraga: "和田純一 （わだじゅんいち）",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 5600,
  },
  {
    key: "6",
    id: "6",
    parent_name_hiraga: "和田純一 （わだじゅんいち）",
    child_name_hiraga: "和田希美（わだのぞみ）",
    beneficiary_certificate_number: "2040002343",
    facility: "(放) 小泉",
    invoice_amount: 9600,
  },
]

export const parents = ["Midori", "Kamimikawa", "Chitose", "Ishinomaki"]

export const codeCategory = [
  {
    label: "JIS",
    value: 1,
  },
  {
    label: "EBCDIC",
    value: 2,
  },
]
