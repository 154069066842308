import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import {
  Checkbox,
  Checkboxes,
  PRINT_SETTINGS,
  PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED,
  REMARK_CONTENT,
  ADDITIONAL_ITEM,
  DIGITALSIGN_OPTION,
  theme,
  PRINT_SETTINGS_VALUES,
  ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED,
  DIGITALSIGN_SUB_OPTION,
  DIGITALSIGN_VALUES,
  REMARK_CONTENT_VALUES,
  RadioButtons,
  ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED,
  ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED,
} from "@project/shared"
import { Button } from "antd"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { RefetchOptions, RefetchQueryFilters } from "react-query"
import styled from "styled-components"
import {
  StyledMonthPaginationWrapper,
  Title,
} from "../../../utils/shared-style"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../../molecules"

type iQueryParams = {
  year: any
  month: any
  content_of_remarks: any[]
  addition_items: any[]
  show_digital_signature: string
  show_non_digital_signature: string
  showAbsence: string
  print_setting: string[]
  remark_actual_expense: string
  display_absent_reason: string
}

interface iServiceSheetOperationComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  initialQueryParams?: iQueryParams
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      year: any
      month: any
      content_of_remarks: any[]
      addition_items: any[]
      show_digital_signature: string
      show_non_digital_signature: string
      showAbsence: string
    }>
  >
  queryParam?: iQueryParams
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<any>
  isLoadingData?: boolean
  year?: any
  month?: any
  FACILITY_OPTION?: {
    value: string
    label: string
  }[]
}

const Wrapper = styled.div<iServiceSheetOperationComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  @media (max-width: 429px) {
    padding: 13px 0px 30px 16px;
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
  .title-wrapper {
    min-width: 125px;
    max-width: 125px;

    @media (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .inline-field-wrapper {
    align-items: flex-start;
  }
  @media print {
    display: none;
  }

  @media screen and (max-width: 426px) {
    .ant-checkbox-wrapper {
      align-items: start;
    }

    .kvVCRa .ant-checkbox {
      top: 6px;
    }
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 426px) {
    .fyUqT .ant-checkbox-wrapper {
      align-items: start;
    }

    .eKtfag .ant-checkbox {
      top: 7px;
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const SelectInputWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    & > :first-child {
      margin-bottom: 10px;
    }
  }
`
const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
  }
  .check-button {
    height: 30px !important;
    &:last-child {
      margin-left: 10px;
    }
  }
  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  .select-wrapper {
    display: flex;
  }
  .text-field {
    width: 712px;
  }
  .calender {
    display: flex;
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  @media (max-width: 800px) {
    .text-field {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 555px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
    .select-wrapper {
      width: 100%;
    }
    .facility-wrapper {
      flex-direction: column;
      & > button:last-child {
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 405px) {
    .select-wrapper {
      flex-direction: column;
      width: 100%;
    }
    .select-down {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .ant-select {
      width: 100%;
    }
  }
`

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-group {
    text-indent: 0px;
    @media (max-width: 429px) {
      display: flex;
      flex-direction: column;
      .ant-checkbox + span {
        padding-right: 8px;
        padding-left: 0px;
        font-size: 14px;
      }
    }
  }
`
const CheckboxWrapAlign = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-group {
    text-indent: 0px;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 0px;
    @media (max-width: 429px) {
      white-space: normal;
      font-size: 14px;
    }
  }
`
export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox + span {
    white-space: pre-wrap;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-group {
    margin-left: 0;
  }
`

const ButtomComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
    & > :first-child {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 430px) {
    .month-pagination {
      flex-direction: column;
      gap: 0px;
      width: 100%;
      padding-right: 16px;
      button {
        margin-right: 0;
        padding: 0px;
        min-width: 0;
        width: 100%;
      }
      & > button:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 380px) {
    align-items: center;
    justify-content: center;
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled(Title)`
  font-size: 16px;
  line-height: normal;
  @media (max-width: 767px) {
    margin-bottom: 6px;
  }
`

const CustomCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .ant-checkbox-wrapper > span {
    white-space: pre-line;
    font-size: 14px;
  }
`

const ServiceSheetOperationComponent: React.FC<
  iServiceSheetOperationComponent
> = (props) => {
  const { header, month, year, queryParam, setQueryParam, initialQueryParams } =
    props
  const { t } = useTranslation()

  const [displayOptions, setDisplayOptions] = useState({
    contractYear: queryParam.year,
    contractMonth: queryParam.month,
    printSetting: queryParam?.print_setting,
    remark: queryParam?.content_of_remarks,
    additionalItem: queryParam?.addition_items,
    showAbsence: queryParam?.showAbsence,
    digitalSign: null,
    remark_actual_expense: queryParam?.remark_actual_expense,
    display_absent_reason: queryParam?.display_absent_reason,
  })

  const handleSearch = (year, month) => {
    setQueryParam((prev) => {
      return {
        ...prev,
        year: year || displayOptions.contractYear,
        month: month || displayOptions.contractMonth,
        content_of_remarks: displayOptions.remark,
        addition_items: displayOptions.additionalItem,
        show_digital_signature: displayOptions.digitalSign,
        show_non_digital_signature: displayOptions.digitalSign,
        showAbsence: displayOptions.showAbsence,
        print_setting: displayOptions.printSetting,
        digitalSign: displayOptions.digitalSign,
        remark_actual_expense: displayOptions.remark_actual_expense,
        display_absent_reason: displayOptions.display_absent_reason,
      }
    })
  }

  const handleReset = () => {
    setDisplayOptions((prev) => {
      return {
        ...prev,
        contractYear: initialQueryParams.year,
        contractMonth: initialQueryParams.month,
        printSetting: [],
        remark: [],
        additionalItem: [],
        showAbsence: "",
        digitalSign: [],
        remark_actual_expense: "1",
        display_absent_reason: "1",
      }
    })
    setQueryParam((prev) => {
      return {
        ...prev,
        year: displayOptions.contractYear,
        month: displayOptions.contractMonth,
        ...initialQueryParams,
      }
    })
  }

  const printSettingOptions = useMemo(() => {
    let finalSetting = []
    PRINT_SETTINGS?.forEach((setting) => {
      finalSetting.push(setting)
      if (
        setting.value ==
          PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE &&
        displayOptions?.printSetting?.includes(
          PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE
        )
      ) {
        finalSetting = finalSetting.concat(
          ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED
        )
      }
    })
    if (
      displayOptions?.printSetting?.includes(
        PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE
      )
    )
      finalSetting = finalSetting.concat(
        PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED
      )
    return finalSetting
  }, [displayOptions?.printSetting])

  const digitalSignOptions = useMemo(() => {
    let finalOptions = []
    finalOptions = finalOptions.concat(DIGITALSIGN_OPTION)
    if (
      displayOptions?.digitalSign?.includes(
        DIGITALSIGN_VALUES.SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE
      )
    )
      finalOptions = finalOptions.concat(DIGITALSIGN_SUB_OPTION)
    return finalOptions
  }, [displayOptions?.digitalSign])

  const handleRemarkChange = (val) => {
    const isChecked = displayOptions?.remark?.includes(val)
    let newRemarks = []
    if (isChecked) {
      newRemarks = displayOptions?.remark?.filter((v) => v != val)
    } else {
      newRemarks = [...displayOptions?.remark]
      newRemarks.push(val)
    }
    setDisplayOptions((prev) => {
      return { ...prev, remark: newRemarks }
    })
  }

  const renderRemarksOptions = () => (
    <CustomCheckboxContainer>
      {REMARK_CONTENT?.map((remark) => {
        const isChecked = displayOptions?.remark?.includes(remark.value)
        return (
          <>
            <Checkbox
              key={remark.value}
              label={remark.label}
              value={remark.value}
              checked={isChecked}
              onClick={() => handleRemarkChange(remark.value)}
            />
            {remark.value == REMARK_CONTENT_VALUES.ACTUAL_EXPENSES &&
              isChecked && (
                <RadioButtons
                  options={
                    ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED
                  }
                  value={displayOptions?.remark_actual_expense}
                  onChange={(e) =>
                    setDisplayOptions((prev) => {
                      return {
                        ...prev,
                        remark_actual_expense: e?.target?.value,
                      }
                    })
                  }
                />
              )}
            {remark.value ==
              REMARK_CONTENT_VALUES.REASON_EXTRA_CHARGE_ABSENTEEISM &&
              isChecked &&
              displayOptions?.showAbsence == "1" && (
                <RadioButtons
                  options={
                    ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED
                  }
                  value={displayOptions?.display_absent_reason}
                  onChange={(e) =>
                    setDisplayOptions((prev) => {
                      return {
                        ...prev,
                        display_absent_reason: e?.target?.value,
                      }
                    })
                  }
                />
              )}
          </>
        )
      })}
    </CustomCheckboxContainer>
  )

  const renderPrintSettingOptions = () => (
    <CustomCheckboxContainer>
      {printSettingOptions?.map((option) => {
        const isChecked = displayOptions?.printSetting?.includes(option.value)
        return (
          <>
            <Checkbox
              key={option.value}
              label={option.label}
              value={option.value}
              checked={isChecked}
              onChange={(e) => {
                let newValues = []
                if (e?.target?.checked) {
                  newValues = [...displayOptions?.printSetting]
                  newValues.push(option.value)
                  if (
                    option.value ==
                      PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE &&
                    !newValues.includes(
                      PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE
                    )
                  ) {
                    // default check
                    newValues.push(
                      PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE
                    )
                  }
                } else {
                  newValues = displayOptions?.printSetting?.filter(
                    (v) => v != option.value
                  )
                }
                setDisplayOptions((prev) => {
                  return {
                    ...prev,
                    printSetting: newValues,
                  }
                })
              }}
            />
          </>
        )
      })}
    </CustomCheckboxContainer>
  )

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />

      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Year month")} </Text>
        </TitleWrap>
        <SelectWrap>
          <SelectInputWrap>
            <DatePicker
              date={
                moment(
                  `${displayOptions.contractYear}-${displayOptions.contractMonth}`
                ).isValid()
                  ? moment(
                      `${displayOptions.contractYear}-${displayOptions.contractMonth}`
                    )
                  : null
              }
              style={{
                height: "40px",
              }}
              picker={"month"}
              format={"YYYY年MM月"}
              showArrow={true}
              disabledDate={(current) => {
                return (
                  (current && current < moment("2021/01/01")) ||
                  (current && current > moment().add(6, "year"))
                )
              }}
              onDateChange={(value) => {
                setDisplayOptions((prev) => {
                  return {
                    ...prev,
                    contractYear: moment(value).format("YYYY"),
                    contractMonth: moment(value).format("M"),
                  }
                })
              }}
            />
          </SelectInputWrap>
        </SelectWrap>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Print settings")} </Text>
        </TitleWrap>
        <CheckboxWrapAlign>{renderPrintSettingOptions()}</CheckboxWrapAlign>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Remarks contents")} </Text>
        </TitleWrap>
        <CheckboxWrap>{renderRemarksOptions()}</CheckboxWrap>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Additional items")} </Text>
        </TitleWrap>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={displayOptions.additionalItem}
            onChange={(values) =>
              setDisplayOptions((prev) => {
                return { ...prev, additionalItem: values }
              })
            }
            options={ADDITIONAL_ITEM}
          />
        </CheckboxWrap>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Absence information")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <SelectWrap>
            <Checkbox
              key={"footerInformation"}
              name={"footerInformation"}
              checked={displayOptions.showAbsence ? true : false}
              label={t(
                "Absence (No addition)、Display information that does not calculate Absence Support Addition II"
              )}
              onChange={(evt) => {
                setDisplayOptions((prev) => {
                  return {
                    ...prev,
                    showAbsence: evt.target.checked ? "1" : null,
                  }
                })
              }}
            />
          </SelectWrap>
        </DateSelectWrap>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Digital sign settings")} </Text>
        </TitleWrap>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={displayOptions.digitalSign}
            onChange={(values) =>
              setDisplayOptions((prev) => {
                return {
                  ...prev,
                  digitalSign: values,
                }
              })
            }
            options={digitalSignOptions}
          />
        </CheckboxWrap>
      </FieldWrapper>

      <ButtomComponent>
        <div style={{ display: "flex" }}>
          <OwnerButton
            className={"search-btn"}
            text={t("Switch display")}
            shape={"circle"}
            typeOf={"primary"}
            onClick={() => handleSearch("", "")}
          />
          <OwnerButton
            className={"reset-button"}
            text={t("Reset search")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => handleReset()}
          />
        </div>

        <StyledMonthPaginationWrapper className={"month-pagination"}>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (month == 1) {
                currentMonth = 12
                --currentYear
              } else {
                --currentMonth
              }
              setDisplayOptions((prev) => {
                return {
                  ...prev,
                  contractYear: currentYear,
                  contractMonth: currentMonth,
                }
              })
              handleSearch(currentYear, currentMonth)
            }}
            className={"left-btn"}
          >
            <DoubleLeftOutlined />
            <span className={"left-btn-label"}>{t("Last month")}</span>
          </Button>
          <Button
            className={"active active-btn"}
            onClick={() => {
              const today = new Date()
              const month = today.getMonth() + 1
              const year = today.getFullYear()
              setDisplayOptions((prev) => {
                return {
                  ...prev,
                  contractYear: year,
                  contractMonth: month,
                }
              })
              handleSearch(year, month)
            }}
          >
            {t("This month")}
          </Button>
          <Button
            onClick={() => {
              let currentYear = year
              let currentMonth = month
              if (currentMonth == 12) {
                ++currentYear
                currentMonth = 1
              } else {
                ++currentMonth
              }
              setDisplayOptions((prev) => {
                return {
                  ...prev,
                  contractYear: currentYear,
                  contractMonth: currentMonth,
                }
              })
              handleSearch(currentYear, currentMonth)
            }}
            className={"right-btn"}
          >
            <span className={"right-btn-label"}>{t("Next month")}</span>
            <DoubleRightOutlined />
          </Button>
        </StyledMonthPaginationWrapper>
      </ButtomComponent>
    </Wrapper>
  )
}

export { ServiceSheetOperationComponent }
