import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button, Card, notification, Spin } from "antd"
import styled from "styled-components"
import { OwnerButton } from "../.."
import {
  MonthlyStaffAttendanceOperationOptions,
  AttendanceRecordTimeRange,
} from "../../molecules"
import { theme } from "@project/shared"
import moment from "moment"
import Calendar from "react-calendar"
import { getMonth } from "@wojtekmaj/date-utils"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import {
  fetchStaffMonthlyAttendanceRecord,
  updateStaffMonthlyAttendanceRecord,
} from "../../../services/attendanceTable"
import { useFormik } from "formik"
import { useRouter } from "next/router"
const MainContent = styled.div`
  margin: 20px 0;
  @media print {
    @page {
      size: auto;
    }
    body * {
      visibility: hidden;
    }
    .section-to-print {
      visibility: visible;
    }
    .section-not-to-print {
      display: none !important;
    }
    .schedule-button {
      display: none;
      padding: 0 !important;
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      padding: 0px !important;
    }
    .ant-select-arrow {
      display: none;
    }
    .schedule-container {
      margin: 0px !important;
    }
    .inner-schedule-container,
    .inner-schedule-container > div {
      padding: 0px !important;
    }
    .inputs {
      flex-direction: row !important;
      flex-wrap: wrap;
      gap: 0px !important;
      padding-bottom: 10px;
    }
    .inputs > div {
      max-height: 20px !important;
    }
    .react-calendar__month-view__days__day > div {
      padding-bottom: 0px !important;
    }
    .inputs > div:not(.break-time-container) > div {
      font-size: 12px !important;
      max-width: 14px !important;
    }
    .react-calendar__month-view__days__day > div > .break-time-container {
      display: none !important;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .time_sep {
      margin: 0 3px !important;
      font-size: 14px !important;
    }
    .break-time-input-unit input {
      border: none !important;
      background: transparent !important;
      padding: 0 !important;
      max-width: 30px !important;
    }
    .schedule-title:has(+ .staff-attendance-buttons) {
      display: none !important;
    }
    .staff-attendance-buttons {
      display: none !important;
    }
  }
`

const StyledMonthPaginationWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: 10px;
    height: 40px;
    padding: 0px;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
  }
`
const StyledPageCard = styled(Card)`
  margin: 40px 0;
  border-radius: 10px;
  padding-bottom: 40px;
  .info-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .print-container {
    display: flex;
    justify-content: end;
    margin: 10px 0;
    button {
      background-color: ${theme.gray3} !important;
      border-color: ${theme.gray3} !important;
    }
  }
  .button-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
  .with-underline {
    text-decoration: underline;
  }
`
const StyledCalendar = styled(Calendar)`
  margin-top: 25px;
  margin-bottom: 25px;
  [class*="neighboringMonth"] {
    cursor: default;
    abbr {
      display: none;
    }
  }
  [class*="weekday"] {
    border: 0.1px solid #d2d1d1;
    border-bottom: 0;
    text-align: center;
    background-color: #f3f3f3;
    abbr {
      cursor: default;
      text-decoration: none;
    }
  }
  [class*="tile"] {
    border: 0.1px solid #d2d1d1;
    background: transparent;
    pointer-events: none;
    position: relative;
    display: flex;
    * {
      pointer-events: auto;
    }
    > div {
      padding: 40px 4px;
    }
    abbr {
      background: #f3f3f3;
      border: 0.1px solid #d2d1d1;
      border-top: 0;
      border-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 37px;
    }
  }
  @media not print {
    @media (max-width: 1498px) {
      overflow: auto;
      [class*="__days"],
      [class*="__weekdays"] {
        width: 100%;
        min-width: 140px;
      }
    }
  }
  @media not print {
    @media (max-width: 600px) {
      max-width: 100%;
      overflow: auto;
      .react-calendar__viewContainer {
        width: 980px;
      }
    }
  }
  @media print {
    margin-top: 0px;
  }
`

const StyledSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
`
export const StaffMonthlyAttendanceRecordPage = ({
  year,
  month,
  facility,
  facilityOptions,
  handleDisplayChange,
  pageTitle,
  staffList,
  activeStaff,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  if (!activeStaff?.value || !facility?.value) {
    router.push("/staff-attendance")
  }
  const formik = useFormik({
    initialValues: {
      attendance_records: [],
    },
    onSubmit: async () => {
      try {
        let firstErrorElementId = ""
        formik.values.attendance_records.map((record) => {
          if (!record?.isComplete) {
            if (!firstErrorElementId) firstErrorElementId = record.date
            document
              .getElementById(record.date)
              .classList.add("attendance-record-date-with-error")
          } else {
            document
              .getElementById(record.date)
              .classList.remove("attendance-record-date-with-error")
          }
        })
        if (firstErrorElementId) {
          document
            .getElementById(firstErrorElementId)
            .scrollIntoView({ behavior: "smooth", block: "center" })
          return
        }
        await updateStaffMonthlyAttendanceRecord(
          facility.value,
          formik.values.attendance_records
        )
        notification.success({
          message: t("Updated Successfully"),
        })
        router.push(
          `/staff-attendance?year=${year}&month=${month}&facility=${facility.value}`
        )
      } catch (e) {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      }
    },
  })
  const { isLoading, data: AttendanceRecords } = useQuery({
    queryKey: [
      "monthlyStaffAttendanceRecord",
      activeStaff,
      year,
      month,
      facility,
    ],
    queryFn: async () => {
      if (activeStaff?.value && facility?.value && year && month) {
        const { data } = await fetchStaffMonthlyAttendanceRecord({
          staffId: activeStaff.value,
          facilityId: facility.value,
          year,
          month,
        })
        return data
      }
      return []
    },
    onError: (e: any) => {
      notification.error({
        message:
          t(e?.data?.error?.message) ??
          t("Something went wrong. Please contact administrator"),
      })
      router.push(
        `/staff-attendance?year=${year}&month=${month}&facility=${facility?.value}`
      )
    },
    retry: false,
  })
  const getDateInFormat = (date) => {
    const d = new Date(date),
      year = d.getFullYear()
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    return [year, month, day].join("-")
  }
  const getAttendanceDayRecord = (forDay) => {
    if (!Array.isArray(AttendanceRecords?.attendance_results)) return null
    const formattedDate = getDateInFormat(forDay)
    const recordForDay = AttendanceRecords?.attendance_results?.find(
      (record) => formattedDate == getDateInFormat(record?.date)
    )
    if (!recordForDay) return null
    const attendanceRecord = []
    const splittedStartTime1 = recordForDay?.attendance_start_time?.split(":")
    const splittedEndTime1 = recordForDay?.attendance_end_time?.split(":")
    const attendanceRecord1 = {
      id: 1,
      attendanceType: recordForDay?.attendance_type,
      from: {
        hours: Array.isArray(splittedStartTime1) ? splittedStartTime1[0] : null,
        minutes:
          Array.isArray(splittedStartTime1) && splittedStartTime1.length > 1
            ? splittedStartTime1[1]
            : null,
      },
      to: {
        hours: Array.isArray(splittedEndTime1) ? splittedEndTime1[0] : null,
        minutes:
          Array.isArray(splittedEndTime1) && splittedEndTime1.length > 1
            ? splittedEndTime1[1]
            : null,
      },
      break_time: recordForDay?.attendance_rest_minits,
    }
    if (attendanceRecord1) attendanceRecord.push(attendanceRecord1)
    const splittedStartTime2 = recordForDay?.attendance_start_time2?.split(":")
    const splittedEndTime2 = recordForDay?.attendance_end_time2?.split(":")
    const attendanceRecord2 = recordForDay?.attendance_type2
      ? {
          id: 2,
          attendanceType: recordForDay?.attendance_type2,
          from: {
            hours: Array.isArray(splittedStartTime2)
              ? splittedStartTime2[0]
              : null,
            minutes:
              Array.isArray(splittedStartTime2) && splittedStartTime2.length > 1
                ? splittedStartTime2[1]
                : null,
          },
          to: {
            hours: Array.isArray(splittedEndTime2) ? splittedEndTime2[0] : null,
            minutes:
              Array.isArray(splittedEndTime2) && splittedEndTime2.length > 1
                ? splittedEndTime2[1]
                : null,
          },
          break_time: recordForDay?.attendance_rest_minits2,
        }
      : null
    if (attendanceRecord2) attendanceRecord.push(attendanceRecord2)
    return {
      updateId: recordForDay?.id,
      shiftHours: attendanceRecord,
      use_rest_time_flg: recordForDay?.rest_minits_flag,
      rest_hours: recordForDay?.rest_hours,
    }
  }
  const isHoliday = (forDay) => {
    return (
      Array.isArray(AttendanceRecords?.holidays) &&
      AttendanceRecords?.holidays?.includes(forDay)
    )
  }
  const handleAttendanceChange = (dayDetail) => {
    let newRecords = formik.values.attendance_records
    if (!newRecords) newRecords = []
    const indexOfRecord = newRecords.findIndex(
      (record) => record.date == dayDetail.date
    )
    const dayDetailWithOtherProps = {
      ...dayDetail,
      facility_id: facility.value,
      staff_id: activeStaff.value,
    }
    if (indexOfRecord != -1) {
      newRecords[indexOfRecord] = dayDetailWithOtherProps
    } else {
      newRecords = [...newRecords, { ...dayDetailWithOtherProps }]
    }
    formik.setFieldValue("attendance_records", newRecords)
  }
  return (
    <MainContent>
      <MonthlyStaffAttendanceOperationOptions
        initialValues={{
          year,
          month,
          facility: facility?.value,
          staff: activeStaff?.value,
        }}
        facilityOptions={facilityOptions}
        staffOptions={staffList}
        handleDisplayChange={handleDisplayChange}
      />
      {isLoading ? (
        <StyledSpinner size={"large"} />
      ) : (
        <StyledPageCard title={pageTitle}>
          <div className={"info-pagination-wrapper section-not-to-print"}>
            <StyledMonthPaginationWrapper>
              <Button
                onClick={() => {
                  let currentYear = year
                  let currentMonth = month
                  if (month == 1) {
                    currentMonth = 12
                    --currentYear
                  } else {
                    --currentMonth
                  }
                  handleDisplayChange({
                    year: currentYear,
                    month: currentMonth,
                  })
                }}
                className={"left-btn"}
              >
                <DoubleLeftOutlined />
                <span className={"left-btn-label"}>{t("Last month")}</span>
              </Button>
              <Button
                className={"active active-btn"}
                onClick={() => {
                  const today = new Date()
                  const month = today.getMonth() + 1
                  const year = today.getFullYear()
                  handleDisplayChange({ year, month })
                }}
              >
                {t("This month")}
              </Button>
              <Button
                onClick={() => {
                  let currentYear = year
                  let currentMonth = month
                  if (currentMonth == 12) {
                    ++currentYear
                    currentMonth = 1
                  } else {
                    ++currentMonth
                  }
                  handleDisplayChange({
                    year: currentYear,
                    month: currentMonth,
                  })
                }}
                className={"right-btn"}
              >
                <span className={"right-btn-label"}>{t("Next month")}</span>
                <DoubleRightOutlined />
              </Button>
            </StyledMonthPaginationWrapper>
          </div>
          <div className={"print-container section-not-to-print"}>
            <OwnerButton
              text={t("Print this")}
              icon={"print"}
              onClick={() => {
                typeof window != "undefined" && window.print()
              }}
            />
          </div>
          <StyledCalendar
            className={"section-to-print"}
            tileContent={({ activeStartDate, date }) =>
              date.getMonth() === getMonth(activeStartDate) && (
                <AttendanceRecordTimeRange
                  date={getDateInFormat(date) + "T00:00:00.000Z"}
                  dayDetail={getAttendanceDayRecord(date)}
                  isHoliday={isHoliday(getDateInFormat(date))}
                  onChange={handleAttendanceChange}
                />
              )
            }
            formatDay={(_, date) => moment(date).format("DD")}
            showNavigation={false}
            locale={"ja"}
            calendarType={"US"}
            activeStartDate={new Date(year, month - 1, 1)}
          />
          <div className={"button-container section-not-to-print"}>
            <OwnerButton
              text={t("Cancel")}
              shape={"circle"}
              typeOf={"secondary"}
              onClick={() => {
                router.push(
                  `/staff-attendance?year=${year}&month=${month}&facility=${facility.value}`
                )
              }}
            />
            <OwnerButton
              text={t("Save")}
              shape={"circle"}
              onClick={() => {
                formik.handleSubmit()
              }}
              isLoading={formik.isSubmitting}
            />
          </div>
        </StyledPageCard>
      )}
    </MainContent>
  )
}
