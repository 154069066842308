import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { SelectInput, theme } from "@project/shared"
import { OwnerButton } from "../../atoms/OwnerButton"
import { Button } from "../../../components"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import Router, { useRouter } from "next/router"
import { AuthContext } from "../../../utils"

const DataContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #d2d1d1;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;

  @media (max-width: 1230px) {
    height: auto;
  }
  @media print {
    display: none;
  }
`

const Flexwrapper = styled.div<{ isMenuClose?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1354px) {
    flex-wrap: wrap;
  }

  .sec-button {
    width: ${(props) => (props.isMenuClose ? "300px" : "242px")};
    height: 40px;
  }

  /* @media (max-width: 1230px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  } */

  .print-btn {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 370px;
    gap: 10px;

    @media (max-width: 1230px) {
      height: auto;
      width: 100%;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 0;
      button {
        margin-bottom: 10px;
        margin-right: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .secondary-btn {
    @media (max-width: 856px) {
      display: none;
    }

    @media (max-width: 382px) {
      .sec-button {
        width: 87% !important;
      }
    }
    @media (max-width: 1135px) {
      .sec-button {
        width: 100%;
      }
    }
  }

  .sp {
    display: none;
    @media (max-width: 856px) {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const Inputwrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  row-gap: 10px;
  column-gap: 20px;
  @media (max-width: 1354px) {
    flex-wrap: wrap;
  }
  .of_select-input {
    display: flex;
    align-items: center;
    gap: 6px;

    .info-text {
      min-width: 50px;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    .of_select-input {
      width: 100%;
      padding-left: 0;
      .other-child-select {
        width: 100%;
      }
      .text {
        white-space: nowrap;
        min-width: 50px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    .secondary-btn {
      width: 100%;
      button {
        width: 100%;
        padding-inline: 10px;
      }
    }

    @media screen and (max-width: 395px) {
      width: 100%;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 426px) {
      margin-bottom: 18px;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
    }
    @media screen and (max-width: 769px) {
      margin-bottom: 18px;
      align-items: baseline;
    }
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  @media screen and (max-width: 426px) {
    .comment-from-parents {
      width: 100%;
    }
  }

  @media print {
    display: none;
  }
`

interface IProps {
  furiganaSelect?: string
  setFurignaValue?: (string) => void
  itemListFiltered?: any
  itemValue?: any
  setItemSelect?: (string) => void
  fetchTableData: any
  setQueryParams: any
  setDisplayCareChild: any
  queryParams: any
}
const PrintBulkDataComponent: React.FC<IProps> = ({
  furiganaSelect,
  setFurignaValue,
  itemListFiltered,
  itemValue,
  setItemSelect,
  queryParams,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { collapsed } = useContext(AuthContext)

  const getQueryForPrintPage = () => {
    const queries = []
    Object.keys(queryParams).map((key) => {
      if (Array.isArray(queryParams[key])) {
        if (queryParams[key]?.length)
          queries.push(`${key}=${queryParams[key]?.join(",")}`)
      } else if (queryParams[key]) {
        queries.push(`${key}=${queryParams[key]}`)
      }
    })
    return `?${queries.join("&")}`
  }

  return (
    <>
      <DataContainer>
        <Flexwrapper isMenuClose={collapsed}>
          <div className={"print-btn"}>
            <Button
              text={t("Bulk printing")}
              icon={"print"}
              backgroundColor={theme.gray3}
              color={theme.base}
              padding={5}
              className={"btn-1"}
              onClick={() => {
                router.push(
                  `/user-service-provision-result/bulk-print${getQueryForPrintPage()}`
                )
              }}
            />
            <Button
              text={t("Bulk printing of care records")}
              icon={"print"}
              backgroundColor={theme.gray3}
              color={theme.base}
              padding={5}
              className={"btn-2"}
              onClick={() => {
                router.push(
                  `/user-service-provision-result/bulk-print-life-care-record${getQueryForPrintPage()}`
                )
              }}
            />
            <Button
              text={t("Bulk printing of today's situation")}
              icon={"print"}
              backgroundColor={theme.gray3}
              color={theme.base}
              padding={5}
              className={"btn-3"}
              onClick={() => {
                router.push(
                  `/user-service-provision-result/bulk-print-today-situation${getQueryForPrintPage()}`
                )
              }}
            />
          </div>
          <Inputwrapper>
            <SelectInput
              width={60}
              height={40}
              options={FuriganaAlphabetsOption}
              value={furiganaSelect}
              onChange={(val) => setFurignaValue(val)}
              className={"furigana-select"}
              defaultValue={"--"}
            />
            <div className={"of_select-input"}>
              <SelectInput
                width={200}
                height={40}
                options={itemListFiltered}
                value={itemValue}
                onChange={(val: any) => {
                  setItemSelect(val)
                }}
                defaultValue={"---------"}
                placeholder={t("All")}
                className={"other-child-select"}
              />
              <div className={"info-text"}>{"さんの"}</div>
            </div>

            <div className={"secondary-btn"}>
              <OwnerButton
                typeOf={"secondary"}
                className={"sec-button"}
                text={t("Display a list of care")}
                onClick={() => {
                  const queries = [`child_id=${itemValue}`]
                  if (queryParams?.date_from) {
                    queries.push(`date_from=${queryParams?.date_from}`)
                  }
                  if (queryParams?.date_to) {
                    queries.push(`date_to=${queryParams?.date_to}`)
                  }
                  router.push(
                    `/user-service-provision-result/print-life-care-record?${queries.join(
                      "&"
                    )}`
                  )
                }}
                backgroundColor={"#fff"}
                disabled={!itemValue}
              />
            </div>
          </Inputwrapper>
          <div className={"secondary-btn sp"}>
            <OwnerButton
              typeOf={"secondary"}
              className={"sec-button"}
              text={t("Display a list of care")}
              onClick={() => {
                const queries = [`child_id=${itemValue}`]
                if (queryParams?.date_from) {
                  queries.push(`date_from=${queryParams?.date_from}`)
                }
                if (queryParams?.date_to) {
                  queries.push(`date_to=${queryParams?.date_to}`)
                }
                router.push(
                  `/user-service-provision-result/print-life-care-record?${queries.join(
                    "&"
                  )}`
                )
              }}
              backgroundColor={"#fff"}
              disabled={!itemValue}
            />
          </div>
        </Flexwrapper>
      </DataContainer>
      <Buttonwrapper>
        <OwnerButton
          text={t("Comment from parents")}
          shape={"circle"}
          className={"comment-from-parents"}
          type={"submit"}
          typeOf={"primary"}
          onClick={() => {
            Router.push(`/parents/comment`)
          }}
        />
      </Buttonwrapper>
    </>
  )
}

export { PrintBulkDataComponent }
