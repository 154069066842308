import {
  CALANDER_HOURS_MINUTES,
  PageCard,
  PICK_DROP_OPTIONS,
  RadioButtons,
  SelectInput,
  dynamicLangString,
  TextField,
  theme,
  YES_NO_LIST,
} from "@project/shared"
import { notification } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { editIndividualFacility } from "../../../services/facilityApplication"
import { getDayOfWeek, scrollToFirstErrorField } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { StyledFormWrapper } from "../../molecules"
import * as yup from "yup"
import { useEffect } from "react"
import { japaneseAlphaRegex } from "../../../utils/validation"

const Wrapper = styled.div`
  margin: 40px 0;
  width: 100%;
  .ant-card-head-title {
    font-weight: 500;
    font-size: 18px;
  }
`

const FormWrapper = styled.div`
  width: 100%;
  margin-top: "40px";

  p {
    margin: auto 0px;
    width: 100%;
  }

  .label-wrapper p {
    min-height: 70px;
  }

  .error {
    color: ${theme?.red2};
  }

  .error-border {
    border: 1px solid ${theme.red2} !important;
    border-radius: 8px;
  }

  .ant-input {
    background: #ffffff;
    height: 40px;
  }

  .multiple-input-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .label {
    display: flex;
    align-items: center;
  }

  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
    }

    .save-cancel-btn {
      display: flex;
      gap: 15px;
      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

interface IAddChildAttendance {
  date: string
  facility: any
  childsWithoutSchedule: Array<any>
}

export const AddChildAttendancePage = ({
  date,
  facility,
  childsWithoutSchedule,
}: IAddChildAttendance) => {
  const { t } = useTranslation()
  const title = t("addChildAttendanceInfoTitle", {
    facilityName: facility?.facility_name_short,
    year: moment(date).year(),
    month: moment(date).month() + 1,
    day: moment(date).date(),
    dayOfWeek: t(getDayOfWeek(moment(date).day())),
  })
  const router = useRouter()
  const { mutate: saveChildAttendance } = useMutation(editIndividualFacility, {
    onSuccess: () => {
      notification.success({
        message: dynamicLangString(["Child attendance is added successfully"]),
      })
      router.push({
        pathname: `/user-attendance-table/day-list`,
        query: `facilityId=${facility?.id}&date=${date}`,
      })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      date,
      facility_id: facility?.id,
      furigana: null,
      child_options: childsWithoutSchedule,
      child_id: null,
      pickup: "1",
      pickup_place: null,
      pickup_place_name: null,
      pickup_hour: null,
      pickup_minute: null,
      drop: "1",
      drop_place: null,
      drop_place_name: null,
      drop_hour: null,
      drop_minute: null,
      remarks: null,
      remarks_claim: null,
    },
    validationSchema: yup.object().shape({
      child_id: yup.number().required(),
    }),
    onSubmit: (values) => {
      const finalValue = {
        values: [
          {
            facility_id: facility?.id,
            child_id: values?.child_id,
            year: moment(date).year(),
            month: moment(date).month() + 1,
            date: date,
            go_to_meet_flag: values?.pickup == "1",
            goto_meet_place:
              values?.pickup == "1" ? values?.pickup_place_name : null,
            go_to_meet_time:
              values?.pickup == "1" && values?.pickup_hour
                ? values?.pickup_hour + ":" + (values?.pickup_minute ?? "00")
                : null,
            take_flag: values?.drop == "1",
            take_place: values?.drop == "1" ? values?.drop_place_name : null,
            take_time:
              values?.drop == "1" && values?.drop_hour
                ? values?.drop_hour + ":" + (values?.drop_minute ?? "00")
                : null,
            is_applied: true,
            remarks: values?.remarks,
            remarks_nhif: values?.remarks_claim,
          },
        ],
        childId: values?.child_id,
        facilityId: facility?.id,
        year: moment(date).year(),
        month: moment(date).month() + 1,
      }
      saveChildAttendance(finalValue)
    },
  })

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value)
    if (name == "pickup_place") {
      if (value == "others") formik.setFieldValue("pickup_place_name", "")
      else formik.setFieldValue("pickup_place_name", value)
    } else if (name == "pickup_hour") {
      if (!formik.values.pickup_minute)
        formik.setFieldValue("pickup_minute", "00")
    } else if (name == "drop_place") {
      if (value == "others") formik.setFieldValue("drop_place_name", "")
      else formik.setFieldValue("drop_place_name", value)
    } else if (name == "drop_hour") {
      if (!formik.values.drop_minute) formik.setFieldValue("drop_minute", "00")
    } else if (name == "furigana") {
      const regex = japaneseAlphaRegex(value)
      const filteredChilds = value
        ? childsWithoutSchedule.filter(
            (child) =>
              child?.furigana?.match(regex) || child?.label?.match(regex)
          )
        : childsWithoutSchedule
      formik.setFieldValue("child_options", filteredChilds)
      formik.setFieldValue("child_id", null)
    }
  }

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length)
      scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.errors.child_id])

  return (
    <Wrapper>
      <PageCard title={title}>
        <FormWrapper>
          <StyledFormWrapper label={t("date")}>
            <p>{moment(date).format("YYYY年MM月DD日")}</p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Facility name")}>
            <p>{facility?.facility_name_short}</p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Child name")}>
            <p>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  options={FuriganaAlphabetsOption}
                  values={formik.values.furigana}
                  onChange={(val) => {
                    handleChange("furigana", val)
                  }}
                />
                <SelectInput
                  placeholder={t("-----")}
                  height={40}
                  width={300}
                  options={formik.values.child_options}
                  name={"child_id"}
                  value={formik.values.child_id}
                  onChange={(val) => {
                    handleChange("child_id", val)
                  }}
                  className={formik.errors.child_id ? "error-border" : ""}
                />
              </div>
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Pickup")}>
            <p>
              <RadioButtons
                options={YES_NO_LIST}
                value={formik.values.pickup}
                onChange={(e) => {
                  handleChange("pickup", e.target.value)
                }}
              />
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Pick up place ")}>
            <p>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={150}
                  disabled={formik.values.pickup == "0"}
                  options={PICK_DROP_OPTIONS}
                  value={formik.values.pickup_place}
                  onChange={(val) => handleChange("pickup_place", val)}
                />
                {formik.values.pickup_place == "others" && (
                  <TextField
                    value={formik.values.pickup_place_name}
                    onChange={(e) => {
                      handleChange("pickup_place_name", e.target.value)
                    }}
                  />
                )}
              </div>
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Desire pick up time")}>
            <p>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  disabled={formik.values.pickup == "0"}
                  options={CALANDER_HOURS_MINUTES.hours}
                  value={formik.values.pickup_hour}
                  onChange={(val) => handleChange("pickup_hour", val)}
                />
                {" : "}
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  disabled={
                    formik.values.pickup == "0" || !formik.values.pickup_hour
                  }
                  options={CALANDER_HOURS_MINUTES.minutes}
                  value={formik.values.pickup_minute}
                  onChange={(val) => handleChange("pickup_minute", val)}
                />
              </div>
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Drop off")}>
            <p>
              <RadioButtons
                options={YES_NO_LIST}
                value={formik.values.drop}
                onChange={(e) => handleChange("drop", e.target.value)}
              />
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Drop off place")}>
            <p>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  disabled={formik.values.drop == "0"}
                  options={PICK_DROP_OPTIONS}
                  value={formik.values.drop_place}
                  onChange={(val) => handleChange("drop_place", val)}
                />
                {formik.values.drop_place == "others" && (
                  <TextField
                    value={formik.values.drop_place_name}
                    onChange={(e) =>
                      handleChange("drop_place_name", e.target.value)
                    }
                  />
                )}
              </div>
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Desire drop off time")}>
            <p>
              <div className={"multiple-input-container"}>
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  disabled={formik.values.drop == "0"}
                  options={CALANDER_HOURS_MINUTES.hours}
                  value={formik.values.drop_hour}
                  onChange={(val) => handleChange("drop_hour", val)}
                />
                {" : "}
                <SelectInput
                  placeholder={"--"}
                  height={40}
                  width={80}
                  disabled={
                    formik.values.drop == "0" || !formik.values.drop_hour
                  }
                  options={CALANDER_HOURS_MINUTES.minutes}
                  value={formik.values.drop_minute}
                  onChange={(val) => handleChange("drop_minute", val)}
                />
              </div>
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Scheduled actual expenses")}>
            <p></p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Remarks")}>
            <p>
              <TextField
                value={formik.values.remarks}
                onChange={(e) => handleChange("remarks", e.target.value)}
              />
            </p>
          </StyledFormWrapper>
          <StyledFormWrapper
            label={t(
              "Remarks claim to the National Health Insurance Federation"
            )}
          >
            <p>
              <TextField
                value={formik.values.remarks_claim}
                onChange={(e) => handleChange("remarks_claim", e.target.value)}
              />
            </p>
          </StyledFormWrapper>
        </FormWrapper>
        <FormWrapper>
          <div className={"buttons-wrapper"}>
            <OwnerButton
              type={"button"}
              typeOf={"secondary"}
              text={t("Cancel")}
              onClick={() => {
                router.push({
                  pathname: `/user-attendance-table/day-list`,
                  query: `facilityId=${facility?.id}&date=${date}`,
                })
              }}
            />
            <OwnerButton
              type={"button"}
              typeOf={"primary"}
              text={t("Save")}
              onClick={() => formik.handleSubmit()}
              isLoading={formik.isSubmitting}
            />
          </div>
        </FormWrapper>
      </PageCard>
    </Wrapper>
  )
}
