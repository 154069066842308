import { t } from "i18next"
import { NBLDateMonthInfo } from "../../../PrintListContent.style"

import wareki from "wareki"
import { formatJaEra } from "../../../../../../molecules/MonthlyUsageList/common/mapper"

type Props = {
  year: number
  month: number
}

export function YearMonth({ year, month }: Props) {
  const era = formatJaEra(
    wareki(`${year}-${month}`, {
      unit: true,
    })
  )

  return (
    <NBLDateMonthInfo>
      <div className={"DateMonthInfo"}>
        <table>
          <tbody>
            <tr>
              <td className={"w-large"}>{era.split("").slice(0, 2)}</td>
              <td>{era.split("").slice(2, 3)}</td>
              <td>{era.split("").slice(3, 4)}</td>
              <td className={"w-large"}>{t("Year")}</td>
              {month
                .toString()
                .padStart(2, "0")
                .split("")
                .map((v) => (
                  <td key={v}>{v}</td>
                ))}
              <td className={"w-large"}>{t("Month")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </NBLDateMonthInfo>
  )
}
