// packages
import { t } from "i18next"

// styled component
import { RecordContainer } from "../styles"

// components
import { AgreementList } from "."
import {
  Pagination,
  TopRoundedWhiteCard,
  CHILD_SERVICE_TYPES,
} from "@project/shared"

// constants
import {
  HeadMoreInfo,
  LoadingListingSchema,
  RecordListingSchema,
} from "../constants"
import { RecordsBlockProps } from "../types"

export const RecordsBlock: React.FC<RecordsBlockProps> = ({
  total,
  pageSize,
  allRecords,
  currentPage,
  printingOptions,
  isLoading,
  handlePagination,
}) => {
  return (
    <RecordContainer>
      <TopRoundedWhiteCard className={"white-card"}>
        <Pagination
          className={"pagination"}
          current={currentPage}
          defaultPageSize={pageSize}
          total={total}
          onChange={handlePagination}
        />

        {/* In case of loading show this */}
        {isLoading && (
          <AgreementList
            printingOptions={printingOptions}
            mappableHeadMoreInfos={HeadMoreInfo({
              facilityName: t("Loading..."),
              serviceType: t("Loading..."),
              createdAt: t("Loading..."),
            })}
            mappableRecords={LoadingListingSchema()}
          />
        )}
        {/* In case of loading show above */}

        {/* first record content starts here */}
        {allRecords?.map((rec) => {
          return (
            <AgreementList
              key={rec.id}
              printingOptions={printingOptions}
              mappableHeadMoreInfos={HeadMoreInfo({
                facilityName: rec.facility_name || "---",
                serviceType: CHILD_SERVICE_TYPES?.[rec.service_type] || "---",
                createdAt: rec.created_at,
              })}
              mappableRecords={RecordListingSchema(
                {
                  childName: rec.child_name || "---",
                  beneficiaryCertiNum:
                    rec?.beneficiary_certificate_number || "---",
                  consultationSupportOffice:
                    rec.consultation_support_name || "---",
                  correspondingPerson: rec.corresponding_person || "---",
                  implementedDateTime:
                    `${rec.implemented_data_time} (${
                      rec.time_difference + "分間"
                    })` || "---",
                  AdditionMinuteFreeItems: rec.addition_and_minutes_free_items,
                  parentsName: rec.parent_name || "---",
                },
                printingOptions?.digitalSign.includes("4"),
                printingOptions?.digitalSign.includes("5"),
                printingOptions?.stamp.includes("3"),
                printingOptions?.stamp.includes("4")
              )}
              minutesTitle={rec?.minutes_title || ""}
            />
          )
        })}

        <br />
        <br />
        <Pagination
          className={"pagination"}
          current={currentPage}
          defaultPageSize={pageSize}
          total={total}
          onChange={handlePagination}
        />

        {/* first record content starts here */}
      </TopRoundedWhiteCard>
    </RecordContainer>
  )
}
