import styled from "styled-components"

export const PrintSheet = styled.table`
  @page {
    margin: 0mm 5mm;
    size: A4 portrait;
  }
  @media print {
    top: 0 !important;
    left: 0 !important;
    width: 210mm;
    height: 297mm;
    box-sizing: border-box;
    padding: 20mm;

    table th,
    table td,
    table td .value {
      font-size: 0.75rem !important;
    }
  }
`
