import React from "react"

// packages
import { t } from "i18next"

// components
import { Checkbox, RadioButtons, TopRoundedWhiteCard } from "@project/shared"

// styled component
import {
  getAfterDaySchoolServiceFields,
  getChildDevelopmentSupportFields,
  FieldWrapper,
} from "./common"
// import { Calendar } from "../../molecules/DatePicker/index.stories"

// styled component
import { AddItemEditOperationWrapper, FlexChildren } from "./styles"
import { DatePicker } from "../../molecules"
import moment from "moment"
import { useFormik } from "formik"
import { OwnerButton } from "../../atoms"
import { AdditionalItemEditProp } from "./types"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"

const AdditionalItemEditBlock: React.FC<AdditionalItemEditProp> = ({
  initialValues,
  onCancel,
  onSubmit,
  isCreating,
  validationSchema,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      onSubmit(formik.values)
    },
  })

  const afterDaySchoolServiceFields = getAfterDaySchoolServiceFields(
    formik.values.date
  )

  const childDevelopmentSupportFields = getChildDevelopmentSupportFields(
    formik.values.date
  )

  function renderDynamicForm() {
    if ("serviceType1" in formik?.values && "serviceType2" in formik?.values) {
      return (
        <>
          <div>
            <p className={"child-dev-support-text"}>{t("After day school")}</p>
            <div>
              {afterDaySchoolServiceFields.map((item, index) => {
                return (
                  <FieldWrapper key={index} label={item.label}>
                    <FlexChildren>
                      <RadioButtons
                        value={formik.values[item.name]}
                        name={item.name}
                        options={item.fields}
                        onChange={(e) => {
                          formik.setFieldValue(item.name, e.target.value)
                        }}
                      />
                    </FlexChildren>
                  </FieldWrapper>
                )
              })}
            </div>
          </div>
          <div>
            <p className={"child-dev-support-text"}>
              {t("Child development support")}
            </p>
            <div>
              {childDevelopmentSupportFields.map((item, index) => {
                return (
                  <FieldWrapper key={index} label={item.label}>
                    <FlexChildren>
                      <RadioButtons
                        value={formik.values[`${item.name}_2`]}
                        name={`${item.name}_2`}
                        options={item.fields}
                        onChange={(e) => {
                          formik.setFieldValue(`${item.name}_2`, e.target.value)
                        }}
                      />
                    </FlexChildren>
                  </FieldWrapper>
                )
              })}
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <p className={"child-dev-support-text"}>
          {"serviceType1" in formik.values
            ? t("After day school")
            : t("Child development support")}
        </p>
        <div>
          {("serviceType1" in formik.values
            ? afterDaySchoolServiceFields
            : childDevelopmentSupportFields
          ).map((item, index) => {
            return (
              <FieldWrapper key={index} label={item.label}>
                <FlexChildren>
                  <RadioButtons
                    value={formik.values[item.name]}
                    name={item.name}
                    options={item.fields}
                    onChange={(e) => {
                      formik.setFieldValue(item.name, e.target.value)
                    }}
                  />
                </FlexChildren>
              </FieldWrapper>
            )
          })}
        </div>
      </>
    )
  }
  return (
    <AddItemEditOperationWrapper>
      <TopRoundedWhiteCard title={t("Facility information")}>
        <div
          style={{
            marginTop: "16px",
            marginBottom: "20px",
          }}
        >
          <FieldWrapper label={t("date")} required>
            <FlexChildren isNoGap>
              <DatePicker
                date={formik.values?.date}
                onDateChange={(date) => {
                  formik.setFieldValue("date", moment(date))
                  formik.setFieldValue("end_date", null)
                }}
                format={"YYYY年MM月DD日"}
              />
              <Checkbox
                name={"enterByPeriod"}
                label={t("Enter by period")}
                checked={formik.values?.enterByPeriod}
                onChange={(e) => {
                  formik.setFieldValue("enterByPeriod", e.target.checked)
                }}
                className={"period_checkbox"}
              />
              {formik.values?.enterByPeriod ? (
                <DatePicker
                  date={
                    moment(formik.values?.end_date).isValid()
                      ? moment(formik.values?.end_date)
                      : null
                  }
                  /**
                   * NOTE
                   * en: Items may change depending on the year and month.
                   * ja: 年月をまたぐと、選択できる加算の項目が異なる
                   */
                  disabledDate={(date) =>
                    formik.values.date &&
                    date.format("YYYY-MM") !==
                      formik.values.date.format("YYYY-MM")
                  }
                  onDateChange={(date) =>
                    formik.setFieldValue("end_date", moment(date))
                  }
                  format={"YYYY年MM月DD日"}
                />
              ) : null}
            </FlexChildren>
          </FieldWrapper>
          <ErrorCounterWrapper>
            {formik.errors.date && formik.touched.date && (
              <ErrorStyled>{formik.errors.date && t("Required")}</ErrorStyled>
            )}
          </ErrorCounterWrapper>
        </div>

        {renderDynamicForm()}
        <div className={"buttons-container"}>
          <OwnerButton
            className={"button"}
            text={t("Cancel")}
            typeOf={"secondary"}
            onClick={onCancel}
          />
          <OwnerButton
            className={"button"}
            text={t("Save")}
            shape={"circle"}
            isLoading={isCreating}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </AddItemEditOperationWrapper>
  )
}

export { AdditionalItemEditBlock }
