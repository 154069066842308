import { Table } from "@project/shared"
import { BeneficiaryNumber } from "./common/BeneficiaryNumber"
import { ParentName } from "./common/ParentName"
import { ChildName } from "./common/ChildName"
import { SiblingChild } from "../../../../../types"
import { maskDisabilityWord } from "../../../common/utils"

enum RowIndex {
  PARENT_NAME = 0,
  LABELS = 1,
}

type Props = {
  municipalityNumber: string
  parentName: string
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function ShizuokaChildDetail({
  parentName,
  siblingChildren,
  printConfig,
}: Props) {
  return (
    <Table
      style={{ width: 360 }}
      columns={[
        {
          width: "20px",
          dataIndex: "column1",
          onCell: (row, index) => {
            if (row.column1 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }
            if (RowIndex.PARENT_NAME === index) {
              return { colSpan: 2 }
            }
            if (RowIndex.LABELS === index) {
              return { rowSpan: siblingChildren.length + 1 }
            }
            return {}
          },
          render: (v) => <p>{v}</p>,
        },
        {
          dataIndex: "column2",
          onCell: (row) => {
            if (row.column2 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }
            return {}
          },
          render: (v) => <p>{v}</p>,
        },

        {
          dataIndex: "column3",
          onCell: (row) => {
            if (row.column3 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }
            return {}
          },
          render: (v) => <p>{v}</p>,
        },
      ]}
      dataSource={[
        {
          column1: "支給決定保護者名",
          column2: false,
          column3: <ParentName name={parentName} configuration={printConfig} />,
        },
        {
          column1: maskDisabilityWord("対象障害児", printConfig),
          column2: "受給者番号",
          column3: maskDisabilityWord("障害児氏名", printConfig),
        },

        ...siblingChildren.map(
          ({ child_id, receiving_certificate_number, child_name }) => ({
            column1: false,
            column2: (
              <BeneficiaryNumber
                key={child_id}
                number={receiving_certificate_number}
              />
            ),
            column3: (
              <ChildName
                key={child_id}
                name={child_name}
                configuration={printConfig}
              />
            ),
          })
        ),
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
