import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${theme.secondaryBg};
  padding: 36px;
  margin-top: -8px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 18px;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 9px;
  }
  @media print {
    padding: 0 0px !important;
  }
`
export const CtaBtns = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  @media print {
    display: none !important;
  }
`
export const AlertContent = styled.div`
  background-color: ${theme.base};
  padding: 1.5rem 1rem;
  border-radius: 12px;
  border: 1px solid ${theme.red3};
  .title__content {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
    .title {
      font-weight: 700;
      color: ${theme.red3};
    }
  }
  .sub {
    margin-left: 10px;
    font-weight: 400;
    color: ${theme.red3};
  }
  @media print {
    display: none !important;
  }
`
export const PrintBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  background-color: ${theme.base};
  padding: 2rem 1rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  @media print {
    padding: 0 !important;
    row-gap: 0.1rem !important;
    box-shadow: unset !important;
    #disability__benefits {
      page-break-after: always !important;
      page-break-before: always !important;
      padding: 24px 24px 0px 24px;
    }
  }
`
