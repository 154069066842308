import {
  Checkboxes,
  theme,
  Checkbox,
  CHILD_SERVICE_OPTION,
  setItemToLocalstorage,
} from "@project/shared"
import moment from "moment"
import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Title, AuthContext } from "../../../utils"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  setIsButtonClicked?: React.Dispatch<any>
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      facility_ids: string
      certificate_applicable_year: string
      show_digital_signature: any
      show_non_digital_signature: any
      child_ids: any
      service_type_ids: any
      certificate_applicable_month: string
      keyword: string
    }>
  >
  queryParam?: {
    facility_ids: string
    certificate_applicable_year: string
    show_digital_signature: any
    show_non_digital_signature: any
    child_ids: any
    service_type_ids: any
    certificate_applicable_month: string
    keyword: string
  }
  isLoadingData?: boolean
  setCurrentPage?: (val: number) => void
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
  .title-wrap {
    min-width: 125px;
  }
  /* @media (max-width: 767px) {
      .select-up {
        width: 100%;
      }
    } */
  @media (max-width: 456px) {
    .search-btn {
      font-size: 14px;
    }
    .reset-button {
      font-size: 14px;
    }
  }
  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .text-field {
    @media (max-width: 769px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .facility-wrapper {
    display: flex;
    .reset-button,
    .set-button {
      height: 30px;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 456px) {
    .facility-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .checkbox-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .info-wrapper {
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
  @media (max-width: 456px) {
    font-size: 14px;
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: pre-wrap;
  width: 100%;

  .check-box {
    white-space: pre-wrap;
  }
  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .calender {
    display: flex;
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  .date-picker {
    height: 40px;
    width: 160px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .select-down {
      width: 100%;
      margin-right: 0;
    }
    .select-class {
      margin-top: 5px;
      width: 100%;
    }
  }

  @media (max-width: 590px) {
    .date-picker {
      width: auto;
    }
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 145px;

  @media (max-width: 434px) {
    padding-left: 0px;
  }
`

const ServiceRecordSheetOperationComponentPrint: React.FC<
  iOperationOptionComponent
> = (props) => {
  const {
    header,
    isLoadingData,
    queryParam,
    setQueryParam,
    setIsButtonClicked,
    setCurrentPage,
  } = props
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const [check, setCheck] = useState(queryParam?.facility_ids || null)
  const { facilities } = useContext(AuthContext)
  const [facilityIds, setFacilityIds] = useState(queryParam?.facility_ids || "")
  const [displayDigitalSignature, setDisplayDigitalSignature] = useState(
    queryParam?.show_digital_signature || false
  )
  const [useService, setUseService] = useState(
    queryParam?.service_type_ids || null
  )
  const [year, setYear] = useState(
    queryParam?.certificate_applicable_year || ""
  )
  const [month, setMonth] = useState(
    queryParam?.certificate_applicable_month || ""
  )

  const CHILD_FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })

  const allFacility = CHILD_FACILITY_OPTION.map((item) => item.value)
  const handleSearch = () => {
    setIsButtonClicked(true)
    const params = {
      facility_ids: Array.isArray(facilityIds)
        ? facilityIds?.join(",")
        : facilityIds,
      show_digital_signature: displayDigitalSignature ? "1" : "",
      service_type_ids: Array.isArray(useService)
        ? useService?.join(",")
        : useService,
      certificate_applicable_year: year,
      certificate_applicable_month: month,
    }
    setCurrentPage(1)
    setQueryParam((prev) => {
      return {
        ...prev,
        ...params,
      }
    })
    updateParams(
      {
        ...params,
      },
      "/service-provision-record-sheet/print"
    )
    setItemToLocalstorage("service-provision-record-sheet_print", {
      facility_ids: params?.facility_ids,
    })
  }

  const handleReset = () => {
    setIsButtonClicked(true)
    setCheck(null)
    setFacilityIds("")
    setDisplayDigitalSignature(false)
    setYear(moment().format("YYYY"))
    setMonth(moment().format("M"))
    setUseService(null)
    setQueryParam((prev) => {
      return {
        ...prev,
        facility_ids: null,
        certificate_applicable_year: moment().format("YYYY"),
        certificate_applicable_month: moment().format("M"),
        show_digital_signature: null,
        service_type_ids: null,
      }
    })
    setCurrentPage(1)
    updateParams({}, "/service-provision-record-sheet/print")
  }

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Facility")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            className={"set-button"}
            text={t("Check all")}
            onClick={() => {
              const facilityValuesIds = allFacility.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds("")
              setCheck(null)
            }}
          />
        </div>
      </SearchWrapper>

      <SearchWrapper>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={check}
            onChange={(values) => {
              const facilityValuesIds = values.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(values)
            }}
            options={CHILD_FACILITY_OPTION}
          />
        </CheckboxWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Use service")} </Text>
        </TitleWrap>
        <Checkboxes
          key={"footerInformation"}
          name={"footerInformation"}
          value={useService}
          onChange={(values) => {
            setUseService(values)
          }}
          options={CHILD_SERVICE_OPTION}
        />
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Year month")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <DatePicker
            date={
              moment(`${year}-${month}`).isValid()
                ? moment(`${year}-${month}`)
                : moment()
            }
            style={{
              height: "40px",
            }}
            picker={"month"}
            format={"YYYY年MM月"}
            showArrow={true}
            disabledDate={(current) => {
              return (
                (current && current < moment("2021/01/01")) ||
                (current && current > moment().add(6, "year"))
              )
            }}
            onDateChange={(value) => {
              setYear(moment(value).format("YYYY"))
              setMonth(moment(value).format("M"))
            }}
          />
        </DateSelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={displayDigitalSignature}
            label={t(
              "Display even children whose achievements are not registered"
            )}
            className={"check-box"}
            onChange={(evt) => {
              setDisplayDigitalSignature(evt.target.checked)
            }}
          />
        </SelectWrap>
      </SearchWrapper>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ServiceRecordSheetOperationComponentPrint }
