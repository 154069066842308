import React from "react"

// packages
import { t } from "i18next"
import { useFormik } from "formik"

// styled components
import { FilterOpContainer } from "../styles"

// components
import { OwnerButton, useNoticeBox } from "../../../atoms"
import { OperationFieldWrapper } from "../../../molecules"
import { Checkboxes, SelectInput, TopRoundedWhiteCard } from "@project/shared"

// types
import { FilterActionBlockProps } from "../types"

export const FilterActionBlock: React.FC<FilterActionBlockProps> = ({
  facilityOpt,
  currentSearchQueries,
  handleSearchQueryChange,
}) => {
  const { Notice } = useNoticeBox()
  const formik = useFormik({
    initialValues: {
      facility_output: [...currentSearchQueries.facilityOutput],
      transfer_account: currentSearchQueries.transferAccount,
    },
    onSubmit: (values) => {
      handleSearchQueryChange({
        ...currentSearchQueries,
        facilityOutput: values.facility_output,
        transferAccount: values.transfer_account,
      })
    },
  })
  const { values, setFieldValue, handleSubmit } = formik

  const handleResetForm = () => {
    setFieldValue("facility_output", [currentSearchQueries.facility_id])
    setFieldValue("transfer_account", null)
    handleSearchQueryChange({
      ...currentSearchQueries,
      facilityOutput: [],
      transferAccount: null,
    })
  }

  const handleCheckAll = () => {
    setFieldValue(
      "facility_output",
      facilityOpt.map((o: any) => {
        if (!o?.disabled) {
          return o.value
        }
      })
    )
  }

  const handleUncheckAll = () => {
    setFieldValue("facility_output", [])
  }

  return (
    <FilterOpContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Operation options")}
      >
        <Notice
          containerClassName={"extra-notice-container"}
          mainTitle={
            'Facility that have not confirmed their billing information cannot be selected. If the billed amount is added up for a child who is also using Joint facilities, Check the facility to be output and press "Reflect".'
          }
        ></Notice>

        <div className={"form-group"}>
          <OperationFieldWrapper
            className={"facility-output-field-group"}
            label={t("Facility output")}
          >
            <div className={"facility-output-input-content flexbox"}>
              <div className={"btn-group flexbox"}>
                <OwnerButton
                  typeOf={"check-all"}
                  text={t("Check all")}
                  onClick={handleCheckAll}
                />
                <OwnerButton
                  typeOf={"secondary"}
                  text={t("Clear all")}
                  onClick={handleUncheckAll}
                />
              </div>
              <Checkboxes
                name={"facility_output"}
                options={facilityOpt}
                value={values.facility_output}
                onChange={(e) => {
                  setFieldValue("facility_output", e)
                }}
              />
            </div>
          </OperationFieldWrapper>

          <OperationFieldWrapper label={t("Transfer account")}>
            <SelectInput
              name={"transfer_account"}
              className={"facility-select"}
              width={300}
              placeholder={"---"}
              options={facilityOpt}
              value={values.transfer_account}
              clear
              onChange={(e) => {
                setFieldValue("transfer_account", e)
              }}
            />
          </OperationFieldWrapper>

          <div className={"form-button-group flexbox"}>
            <OwnerButton
              text={t("Reflect")}
              typeOf={"primary"}
              className={"search-button btn"}
              onClick={() => {
                handleSubmit()
              }}
            />
            <OwnerButton
              className={"btn"}
              text={t("Reset")}
              typeOf={"secondary"}
              onClick={handleResetForm}
            />
          </div>
        </div>
      </TopRoundedWhiteCard>
    </FilterOpContainer>
  )
}
