import { CHILD_SERVICE_TYPES, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerTable } from ".."
import moment from "moment"

// constants
import { AbsentTypesWithLineBreakOpt } from "./constants"

const RecordWrapper = styled.div`
  margin: 30px 0 60px 0;
  .header {
    font-weight: 500;
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .title {
      display: flex;
      justify-content: flex-end;
      @media print {
        visibility: hidden;
      }
    }
    .service-child-info {
      display: flex;
      justify-content: flex-end;

      text-align: right;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr;
      .title {
        justify-content: center;
        @media print {
          display: none;
        }
      }
      .service-child-info {
        justify-content: center;
        @media print {
          justify-content: flex-end;
        }
      }
    }
    @media print {
      display: flex;
      justify-content: flex-end;
    }
  }

  /* Table styling starts here */
  .absence-table {
    margin: 10px 0;
    .ant-table {
      width: 100%;
      .ant-table-container {
        table {
          border-collapse: collapse;
          .min-width-class {
            min-width: 158px;
          }
          .break-line {
            min-width: 200px;
            white-space: pre;
          }
        }
      }
    }
    thead.ant-table-thead tr th.ant-table-cell {
      background: ${theme.bgColor2};
      border: 1px solid ${theme.gray2};

      &:before {
        display: none;
      }
      &:nth-child(4) {
        min-width: 250px;
      }
    }
    .ant-table-tbody td {
      border: 1px solid ${theme.gray2};

      &:nth-child(4) {
        max-width: 500px;
      }
    }
    .absence-reason-container {
      text-align: left;
      white-space: pre-wrap;
    }
  }

  @media print {
    page-break-inside: avoid;
    .header {
      grid-template-columns: 6fr 4fr;
    }
    table tbody.ant-table-tbody tr td {
      border-bottom-width: 1px !important;
    }
    .absence-table {
      .ant-table {
        .ant-table-container {
          table {
            border-collapse: collapse;
            thead.ant-table-thead {
              tr {
                th.ant-table-cell {
                  text-align: center;
                  outline: none !important;
                  border-color: ${theme.black};

                  background: transparent !important;
                  border: 1px solid ${theme.black};

                  &:first-child {
                    border-left-width: 1px;
                  }

                  &:last-child {
                    border-right-width: 1px;
                  }

                  &.addition-type-cell {
                    min-width: 140px !important;
                  }

                  &.facility-name-cell {
                    min-width: 110px !important;
                  }

                  &.absence-reason-cell {
                    min-width: 140px !important;
                  }

                  &.recorder-name-cell {
                    min-width: 110px !important;
                  }
                }
              }
            }

            tbody.ant-table-tbody {
              tr {
                td.ant-table-cell {
                  white-space: initial;
                  word-break: break-all;
                  border-color: ${theme.black};
                  border-bottom: 1px solid black;

                  &:first-child {
                    border-left-width: 1px;
                  }

                  &:last-child {
                    border-right-width: 1px;
                  }

                  &.break-line {
                    min-width: 140px !important;
                  }
                }

                &:last-child {
                  td.ant-table-cell {
                    border-bottom-width: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /* Table styling ends here */
`

interface IAbsenceRecordData {
  date: string
  facility_name: string
  attendance_type: number
  absence_reason: string
  staff_name: string
  service: number
  child_name: string
}

interface IAbsenceRecordTable {
  records: Array<IAbsenceRecordData>
}

export const AbsenceRecordTable = ({ records }: IAbsenceRecordTable) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      className: "min-width-class",
      render: (_, value) => {
        return (
          <span>
            {value?.date ? moment(value?.date).format("YYYY年MM月DD日") : ""}
          </span>
        )
      },
    },
    {
      title: t("Facility"),
      dataIndex: "facility_name",
      className: "facility-name-cell",
      width: 200,
    },
    {
      title: t("Addition type"),
      dataIndex: "attendance_type",
      className: "break-line addition-type-cell",
      render: (attendance_type) => {
        return AbsentTypesWithLineBreakOpt?.find(
          (a) => a.value == attendance_type
        )?.label
      },
    },
    {
      title: t("Reason"),
      dataIndex: "absence_reason",
      className: "absence-reason-cell",
      render: (absence_reason) => {
        return (
          <div className={"absence-reason-container"}>{absence_reason}</div>
        )
      },
    },
    {
      title: t("Recorder name"),
      dataIndex: "staff_name",
      className: "min-width-class-remarks recorder-name-cell",
    },
  ]

  return (
    <RecordWrapper>
      <div className={"header"}>
        <div className={"title"}>{t("Absence Record")}</div>
        <div className={"service-child-info"}>
          {t("User Service")}
          {" : "}
          {`${CHILD_SERVICE_TYPES?.[records[0]?.service] ?? ""}`} <br />
          {t("Username")}
          {` (${records[0]?.child_name}) `}
        </div>
      </div>
      <div className={"absence-table"}>
        <OwnerTable columns={columns} dataSource={records} />
      </div>
    </RecordWrapper>
  )
}
