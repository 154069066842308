import { API, removeBlankAttributes } from "@project/shared"
export interface IndividualPlanSearchParams {
  child_id?: number
  service?: string
  facility?: string
  date?: string
  expiry_date?: string
  display_settings?: string
  status?: string
  page?: number
  pageSize?: number | string
  children_without_services?: string
  valid_date?: string
}

export interface UsersWhoNeedMonitoringParams {
  child_id?: number
  service?: string
  facility?: string
  date?: string
  expiry_date?: string
  display_settings?: string
  status?: string
  page?: number
  pageSize?: number | string
  children_without_services?: string
}

interface staff {
  id: number
  staff_name: string
}

interface IndividualPlanCommon {
  id: number
  key?: number
  created_datetime: string
  created_by?: string
  updated_datetime?: string
  updated_by?: string
  deleted_datetime?: string | null
  deleted_by?: string
  is_deleted?: boolean
  company_id: number
  child_id: number
  facility_id: number
  create_count: number
  report_create_date: number | null
  start_date: string | null
  end_date: string | null
  staff_id: string | null
  staff: staff | null
  support_plan_draft_type: string | null
  child_family_hope: string | null
  support_policy: string | null
  long_term_goal: string | null
  short_term_goal: string | null
  standard_hr_providing_support?: string | null
  goto_meet_flag: string | null
  goto_meet_place: string | null
  take_flag: string | null
  take_place: string | null
  remarks: string | null
  agree_date: string | null
  draft_save_flag: string | null
  use_service_id: number | null
  child_info?: any
  facility?: any
  digital_signature_image?: string
  child_digital_signature_image?: string
  manager_digital_signature_image?: string
  explanation_date_digital_signature_image?: string
  consent_date_digital_signature_image?: string
}
export interface IndividualSupportPlanItemObj {
  id: number
  created_datetime: string | null
  created_by: string | null
  updated_datetime: string | null
  updated_by: string | null
  deleted_datetime: string | null
  deleted_by: string | null
  is_deleted: boolean
  company_id: number | null
  child_support_pran_draft_id: number | null
  title: string
  support_content?: {
    achievement_goals: string
    achievement_time: string
    support_and_considerations: string
  }
}
export interface IndividualSupportWeeklyScheduleObj {
  child_support_plan_id?: number
  comment?: string
  company_id: number
  created_by: string
  created_datetime: string
  deleted_by: string
  deleted_datetime?: string | null
  end_time: string | null
  id: number
  is_deleted?: boolean | null
  start_time: string
  updated_by: string
  updated_datetime: string
  week_no: number
}
export interface IndividualSupportPlanReponse extends IndividualPlanCommon {
  plan_items: IndividualSupportPlanItemObj[]
  weekly_schedules: IndividualSupportWeeklyScheduleObj[]
}

export interface IndividualSupportDetailResponse {
  childSupport: IndividualPlanCommon
  childSupportItems: IndividualSupportPlanItemObj[]
  childSupportSchedule: IndividualSupportWeeklyScheduleObj[]
  childSupportPlanTable: {
    id: number | string
    notices: string
    reason: string
    offer_time: Array<any>
    before_support_time: Array<any>
    after_support_time: Array<any>
  }
}

interface PlanSignatureResponseObj {
  digital_signature_image?: string //parent signature
  child_digital_signature_image?: string
  manager_digital_signature_image?: string
  manager_date_digital_signature_image?: string
  explanation_date_digital_signature_image?: string
  consent_date_digital_signature_image?: string
  child_id: number
  planId: number
  facility_id: number
}

export const getAllChildSupportPlans = ({
  quires: quires,
  type,
}: {
  quires: IndividualPlanSearchParams
  type: string
}): Promise<{
  count: number
  data: IndividualSupportPlanReponse[]
}> => {
  const params: any = removeBlankAttributes({
    ...quires,
    display_settings: Array.isArray(quires?.display_settings)
      ? quires?.display_settings?.join(",")
      : quires?.display_settings,
    facilityIds: Array.isArray(quires?.facility)
      ? quires?.facility?.join(",")
      : quires?.facility,
    status: Array.isArray(quires?.status)
      ? quires?.status?.join(",")
      : quires?.status,
    service: quires?.service
      ? Array.isArray(quires?.service)
        ? quires?.service?.join(",")
        : quires?.service
      : null,
  })
  delete params?.facility
  const URL = type === "LIST" ? "/child-support-plans" : "/child-support-draft"
  return API.get(URL, {
    params,
  })
}

export const getAllUsersWhoNeedMonitoring = ({
  queries: queries,
}: {
  queries: UsersWhoNeedMonitoringParams
}): Promise<{
  count: number
  data: any
}> => {
  const params = removeBlankAttributes(queries)
  const URL = "/child-support-plans/users-who-need-monitoring"
  return API.get(URL, {
    params,
  })
}

export const getIndividualSupportPlanByChildId = async (
  plan_id: number,
  child_id: number,
  create_count: number,
  type: string,
  operation: string
) => {
  const URL =
    type === "LIST" && (operation === "edit" || operation === "copy")
      ? `/child-support-plans/${plan_id}/child/${child_id}`
      : operation === "create"
      ? `/child-support-draft/child/${child_id}`
      : `/child-support-draft/${plan_id}/child/${child_id}`
  const from = type !== "LIST" ? "draft" : null
  const params = removeBlankAttributes({ create_count, from })
  return API.get(URL, {
    params,
  })
}
export const getIndividualSupportPlanById = async ({
  id,
  type,
}: {
  id: string
  type: string
}): Promise<{
  data: IndividualSupportDetailResponse
}> => {
  const URL = type === "LIST" ? `/child-support-plans` : `/child-support-draft`
  return API.get(`${URL}/${id}`)
}
export const addIndividualSupportPlan = async ({ values, type }: any) => {
  const URL = type === "LIST" ? "/child-support-plans" : "/child-support-draft"
  return API.post(URL, values)
}

export const updateIndividualSupportPlan = async ({
  values,
  type,
  create_count,
}: any) => {
  const id = values.id
  const URL = type === "LIST" ? `/child-support-plans` : `/child-support-draft`
  return API.put(`${URL}/${id}`, values, {
    params: {
      create_count,
    },
  })
}

export const getOneIndividualSupportPlan = async ({
  id,
  type,
}: {
  id: number
  type: string
}) => {
  const URL = type === "LIST" ? "/child-support-plans" : "/child-support-draft"
  return API.get(`${URL}/${id}`)
}

// monitoring individual detail signature update

export const updateIndividualSupportPlanSignature = ({
  values,
  type,
}: {
  values: PlanSignatureResponseObj
  type: "LIST" | "DRAFT"
}) => {
  const data = removeBlankAttributes(values)
  const URL =
    type === "LIST"
      ? `/child-support-plans/${values?.planId}/update_signature`
      : `/child-support-draft/${values?.planId}/update_signature`
  return API.put(`${URL}`, data)
}

export const deleteIndividualSupportPlan = async ({
  id,
  type,
}: {
  id: number
  type: string
}) => {
  const URL =
    type === "LIST"
      ? `/child-support-plans/${id}`
      : `/child-support-draft/${id}`
  return API.delete(URL)
}
