// packages
import styled from "styled-components"

// styled components
export const OperationFieldContent = styled.div<{
  $labelMinWidth?: string
}>`
  width: 100%;
  &.input-group {
    .label.caret-right {
      background: url("/assets/icons/caret-right.svg") no-repeat -3px center / 12px
        12px;
      padding-left: 14px;
      font-size: 16px;
      font-weight: 500;
      width: ${(props) =>
        props.$labelMinWidth ? props.$labelMinWidth : "180px"};
    }
    .input-field {
      flex: 1;
      width: ${(props) =>
        props.$labelMinWidth
          ? `calc(100% - ${props.$labelMinWidth})`
          : "calc(100% - 180px)"};
    }
  }
`

// types
export type FieldWrapperProps = {
  label: string
  children: React.ReactNode
  className?: string
  id?: string
  labelMinWidth?: string
}

const OperationFieldWrapper: React.FC<FieldWrapperProps> = ({
  id,
  label,
  children,
  className,
  labelMinWidth,
}) => {
  return (
    <OperationFieldContent
      $labelMinWidth={labelMinWidth}
      className={`input-group flexbox ${className}`}
    >
      <label className={"caret-right label"} htmlFor={id}>
        {label}
      </label>
      <div className={"input-field"}>{children}</div>
    </OperationFieldContent>
  )
}

export { OperationFieldWrapper }
