import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Button } from "antd"
import { RightOutlined } from "@ant-design/icons"
import { Checkbox, PageCard, theme } from "@project/shared"
import { Button as CustomButton } from "../../atoms"
import { SearchContentSection } from "./index.styles"
import { AuthContext } from "../../../utils"

interface IProps {
  setSearchParams: (facilityIds: number[], displayOptions: number[]) => void
  isLoading?: boolean
  defaultFacilities?: number[]
  defaultDisplayOptions?: number[]
  programFacilities?: number[]
}
export const OperationOptions: React.FC<IProps> = ({
  setSearchParams,
  defaultFacilities,
  defaultDisplayOptions,
  // programFacilities,
}) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [checkedFacility, setCheckedFacility] = useState(defaultFacilities)
  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState(
    defaultDisplayOptions
  )
  const displayOptions = [
    { label: "Program contents" },
    { label: "Notes for multiple activities" },
    { label: "Pick up" },
    { label: "Drop off" },
    { label: "Pick up place" },
    { label: "School", leftText: "(" + t("Display setting") + ": " },
    { label: t("Kindergarden") + " )" },
    { label: "Desired pick up time" },
    { label: "Desired drop off time" },
    { label: "Actual cost " },
    { label: "Remarks" },
    { label: "Informations" },
  ].map((displayOption, i) => ({
    label: t(displayOption?.label),
    value: i + 1,
    leftText: displayOption?.leftText,
  }))
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target

    if (!checked) {
      setCheckedFacility(checkedFacility.filter((item) => item !== value))
    } else {
      setCheckedFacility([...checkedFacility, value])
    }
  }
  const onDisplayContentCheck = (e) => {
    const { value, checked } = e.target
    if (!checked) {
      if (value === 1) {
        const opts = [1, 2]
        setSelectedDisplayOptions(
          selectedDisplayOptions.filter((item) => !opts?.includes(item))
        )
      } else if (value === 3) {
        const opts = [3, 8]
        setSelectedDisplayOptions(
          selectedDisplayOptions.filter((item) => !opts?.includes(item))
        )
      } else if (value === 4) {
        const opts = [4, 9]
        setSelectedDisplayOptions(
          selectedDisplayOptions.filter((item) => !opts?.includes(item))
        )
      } else {
        setSelectedDisplayOptions(
          selectedDisplayOptions.filter((item) => item !== value)
        )
      }
    } else {
      if (value === 1) {
        setSelectedDisplayOptions([...selectedDisplayOptions, value, 2])
      } else {
        setSelectedDisplayOptions([...selectedDisplayOptions, value])
      }
    }
  }

  return (
    <SearchContentSection>
      <PageCard title={t("Operation options")}>
        <div className={"content"}>
          <div className={"operation-section"}>
            <div className={"flex label"}>
              <RightOutlined className={"icon"} />
              <Typography.Paragraph>
                {t("Displaying facility")}
              </Typography.Paragraph>
            </div>
            <div className={"checkboxs_container"}>
              <div className={"check_uncheck_all"}>
                <Button
                  icon={<img src={"/assets/icons/check.svg"} alt={"icon"} />}
                  shape={"round"}
                  onClick={() => {
                    setCheckedFacility(facilities?.map((li) => li.id))
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Check all")}
                  </Typography.Text>
                </Button>
                <Button
                  shape={"round"}
                  onClick={() => {
                    setCheckedFacility([])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Uncheck all")}{" "}
                  </Typography.Text>
                </Button>
              </div>
              <div className={"checkboxs"}>
                {facilities?.map((facility, i) => (
                  <Checkbox
                    key={i}
                    onChange={onFacilityChecked}
                    checked={checkedFacility?.includes(facility?.id)}
                    value={facility?.id || ""}
                    label={facility?.facility_name_short}
                    disabled={
                      checkedFacility?.length === 3 &&
                      !checkedFacility?.includes(facility?.id)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={"operation-section"}>
            <div className={"flex label"}>
              <RightOutlined className={"icon"} />
              <Typography.Paragraph>{t("Display items")}</Typography.Paragraph>
            </div>
            <div className={"flex display-items-checkbox align-center gap-6"}>
              {displayOptions.map((options, i) => {
                return (
                  <div key={i} className={"display-option-labels"}>
                    <span>{options?.leftText || ""}</span>
                    <Checkbox
                      key={i}
                      onChange={onDisplayContentCheck}
                      checked={selectedDisplayOptions?.includes(options?.value)}
                      value={options?.value || ""}
                      label={options?.label}
                      disabled={
                        (!selectedDisplayOptions?.includes(5) &&
                          options?.value === 6) ||
                        (!selectedDisplayOptions?.includes(5) &&
                          options?.value === 7) ||
                        (!selectedDisplayOptions?.includes(3) &&
                          options?.value === 8) ||
                        (!selectedDisplayOptions?.includes(4) &&
                          options?.value === 9) ||
                        (!selectedDisplayOptions?.includes(3) &&
                          !selectedDisplayOptions?.includes(4) &&
                          options?.value === 5)
                      }
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <CustomButton
            text={t("Update display")}
            backgroundColor={theme.gray}
            borderColor={theme.gray2}
            shape={"circle"}
            color={theme.black}
            width={157}
            onClick={() =>
              setSearchParams(checkedFacility, selectedDisplayOptions)
            }
            // disabled={isLoading}
          />
        </div>
      </PageCard>
    </SearchContentSection>
  )
}
