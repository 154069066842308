import { SelectInput, theme } from "@project/shared"
import Link from "next/link"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../.."
import { FuriganaAlphabetsOption } from "../../../utils/common-options"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  row-gap: 12px;
  flex-wrap: wrap;

  .select-containers {
    display: flex;
    gap: 10px;
    margin-bottom: 0 !important;
    justify-content: flex-start !important;

    .ant-select-single .ant-select-selector {
      height: 40px !important;
      align-items: center;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      .staff-select {
        width: 100%;
      }
    }
  }
  .select-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      margin-bottom: 0;
      @media (max-width: 427px) {
        white-space: normal;
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      a {
        button {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
    @media (max-width: ${theme.breakpoints.xxs}) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .disabled-edit-link {
    pointer-events: none;

    button {
      color: ${theme.gray3} !important;
    }
  }

  @media screen and (max-width: 1366px) {
    gap: 0;
    .select-containers {
      gap: 0 !important;
      > div:last-child {
        margin-left: 12px;
      }
    }
    .select-actions {
      margin-top: 10px;
      gap: 0 !important;
      a {
        margin-left: 12px;
      }
    }
  }
`
export const StaffMonthlyScheduleLinkAction = ({
  staffList,
  facility,
  year,
  month,
  navigationLink,
  navigable = true,
}) => {
  const { t } = useTranslation()

  const [furiganaSort, setFuriganaSort] = useState(null)
  const [selectedStaff, setSelectedStaff] = useState(null)

  return (
    <Wrapper>
      <div className={"select-containers"}>
        <SelectInput
          width={90}
          placeholder={t("All")}
          options={FuriganaAlphabetsOption}
          value={furiganaSort}
          onChange={(val) => {
            setFuriganaSort(val)
          }}
          disabled={!navigable}
        />
        <SelectInput
          width={150}
          className={"staff-select"}
          placeholder={t("---")}
          options={
            furiganaSort
              ? staffList?.filter((staff) =>
                  staff?.furigana_name?.startsWith(furiganaSort.charAt(0))
                )
              : staffList
          }
          value={selectedStaff}
          dropdownMatchSelectWidth={false}
          onChange={(val) => setSelectedStaff(val)}
          disabled={!navigable}
        />
      </div>
      <div className={"select-actions"}>
        <p>{t("Search specific staff whole month schedule")}</p>
        {navigable ? (
          <Link
            href={
              selectedStaff && year && month && facility?.value
                ? `/${navigationLink}?staff_id=${selectedStaff}&facility_id=${facility?.value}&year=${year}&month=${month}`
                : ""
            }
          >
            <a
              className={
                selectedStaff && year && month && facility?.value
                  ? "ok"
                  : "disabled-edit-link"
              }
            >
              <OwnerButton
                typeOf={"secondary"}
                text={t("Edit monthly work schedule")}
              />
            </a>
          </Link>
        ) : (
          <OwnerButton
            typeOf={"secondary"}
            text={t("Edit monthly work schedule")}
            disabled={!navigable}
          />
        )}
      </div>
    </Wrapper>
  )
}
