import React from "react"
const CalendarIcon = () => (
  <svg
    width={"16"}
    height={"16"}
    viewBox={"0 0 16 16"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M4.89011 2.55469H1.77656C1.34432 2.55469 1 2.90652 1 3.33165V14.2238C1 14.6563 1.35165 15.0008 1.77656 15.0008H14.2234C14.6557 15.0008 15 14.6489 15 14.2238V3.33165C15 2.89919 14.6484 2.55469 14.2234 2.55469H13.4469"
      }
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M4.89062 1V4.11518"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M11.1094 1V4.11518"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M7.21875 2.55469H11.1089"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M1.30859 6.37891H13.0302"}
      stroke={"#191919"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default CalendarIcon
