import { Checkboxes, theme, CHILD_SERVICE_OPTION } from "@project/shared"
import moment from "moment"
import { useRouter } from "next/router"
import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Title, AuthContext } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../../molecules"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  refetch?: () => void
  setCurrentDate?: any
  setQueryParams?: React.Dispatch<
    React.SetStateAction<{
      facility_id: any
      user_service_id: any
      date: any
    }>
  >
  queryParams?: {
    facility_id: any
    user_service_id: any
    date: any
  }
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  @media (max-width: ${theme.breakpoints.md}) {
    & {
      padding: 15px 20px 20px 20px;
    }
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  /* @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  } */
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    .facility-wrapper {
      gap: 10px;
      flex-wrap: wrap;
      button {
        padding-inline: 10px;
      }
    }
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Text = styled(Title)`
  font-size: 16px;
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;

  .select-class {
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }
  .calender {
    display: flex;
    row-gap: 9.5px;
    column-gap: 10px;
    flex-wrap: wrap;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      .faculty-datepicker {
        width: 100%;
        .faculty-datepicker {
          width: 100%;
        }
      }
    }
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 145px;

  @media (max-width: 768px) {
    .ant-checkbox + span {
      white-space: pre-line;
      word-break: break-all;
      padding-left: 15px;
    }
  }

  @media (max-width: 434px) {
    padding-left: 0px;
  }
`

const UserAttendanceDeleteComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const { header, queryParams, setQueryParams } = props
  const { t } = useTranslation()
  const router = useRouter()
  const [startJoinDate, setStartJoinDate] = useState(
    queryParams.date || moment()
  )
  const [facilityIds, setFacilityIds] = useState(
    queryParams?.facility_id
      ? queryParams?.facility_id?.split(",").map(Number)
      : null
  )
  const [userServiceIds, setUserServiceIds] = useState(
    queryParams?.user_service_id
      ? queryParams?.user_service_id?.split(",").map(Number)
      : null
  )
  const { facilities } = useContext(AuthContext)

  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const allFacility = FACILITY_OPTION.map((item) => item.value)

  const handleDisplayChange = () => {
    setQueryParams((prev) => {
      return {
        ...prev,
        date:
          moment(startJoinDate, "YYYY-MM-DD") ||
          moment(new Date(), "YYYY-MM-DD"),
        facility_id: facilityIds?.join(",") || null,
        user_service_id: userServiceIds?.join(",") || null,
      }
    })

    const query: any = {}

    if (moment(startJoinDate).isValid())
      query.date = moment(startJoinDate).format("YYYY-MM-DD")
    if (facilityIds?.length) query.facility_id = facilityIds?.join(",")
    if (userServiceIds?.length)
      query.user_service_id = userServiceIds?.join(",")

    router.push(
      {
        pathname: "/user-attendance-table/delete-form",
        query,
      },
      undefined,
      { scroll: false }
    )
  }

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display facility")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            text={t("Check all")}
            onClick={() => {
              setFacilityIds(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds(null)
            }}
          />
        </div>
      </SearchWrapper>

      <SearchWrapper>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={facilityIds}
            onChange={(values) => setFacilityIds(values)}
            options={FACILITY_OPTION}
          />
        </CheckboxWrap>
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Use service")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={userServiceIds}
            onChange={(values) => setUserServiceIds(values)}
            options={CHILD_SERVICE_OPTION}
          />
        </SelectWrap>
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Date")} </Text>
        </TitleWrap>
        <SelectWrap>
          <div className={"calender"}>
            <DatePicker
              style={{ height: "40px" }}
              onDateChange={(val) =>
                setStartJoinDate(moment(val, "YYYY-MM-DD"))
              }
              date={
                moment(startJoinDate).isValid()
                  ? moment(startJoinDate, "YYYY-MM-DD")
                  : moment(new Date(), "YYYY-MM-DD")
              }
              className={"faculty-datepicker"}
              format={"YYYY年MM月DD日"}
            />
            <div>
              <OwnerButton
                typeOf={"secondary"}
                className={"reset-button"}
                text={t("Change display")}
                onClick={handleDisplayChange}
              />
            </div>
          </div>
        </SelectWrap>
      </SearchWrapper>
    </Wrapper>
  )
}

export { UserAttendanceDeleteComponent }
