import React, { useEffect } from "react"
import { useRouter } from "next/router"

// packages
import { Spin } from "antd"
import { t } from "i18next"

// styled components
import { FormBlockContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { TopRoundedWhiteCard } from "@project/shared"
import FormFieldsGen from "../../../molecules/FormFields" // Utility component to generate form fields

// hooks
import useForm from "../hooks/useForm"

// utils
import { useUpdateSearchParams } from "../../../../utils/useUpdateSearchParams"

// types
import { FormBlockProps } from "../types"
import moment from "moment"
import { japaneseAlphaRegex } from "../../../../utils/validation"

export const FormBlock: React.FC<FormBlockProps> = ({
  // common props for both add and edit
  pageType,
  formik,
  rawChildOptions,
  facilityOptions,
  isChildListLoading,
  handleMonthChange,
  handleFacilityChange,
  programCategoryOptions,
  isProgramCategoryListLoading,

  // Add props
  addingProgressRecord,

  // Edit props
  isRecFormDataLoading,
}) => {
  const [updateParams] = useUpdateSearchParams()
  const { date, facility_id, child_id } = useRouter().query

  const { values } = formik

  // Filter child options based on furigana sort
  const getFilteredChildOptions = () => {
    if (rawChildOptions?.length > 0) {
      let options = rawChildOptions
      if (values.furiganaSort) {
        const regex = japaneseAlphaRegex(values.furiganaSort)
        options = rawChildOptions.filter(
          (child) =>
            child.furigana_name.match(regex) || child.label.match(regex)
        )
      }
      return [{ label: "--", value: 0 }].concat(options)
    }
  }

  const { recordFormSchema } = useForm({
    formik: formik,
    pageType: pageType,
    facilityOptions: facilityOptions,
    isChildListLoading: isChildListLoading,
    childOptions: getFilteredChildOptions(),
    programCategoryOptions: programCategoryOptions,
    isProgramCategoryListLoading: isProgramCategoryListLoading,
  })

  useEffect(() => {
    if (pageType === "EDIT") {
      handleMonthChange(values.yearMonth.format("YYYY-MM"))
    }
  }, [values.yearMonth])

  useEffect(() => {
    if (pageType === "EDIT") {
      handleFacilityChange(values.facilityId)
    }
  }, [values.facilityId])

  /**
   * Set child id from the url query params
   * when there is child_id in the url query params. Otherwise set the first child id
   * from the child options. If we don't have any child options then set child id to 0.
   */
  useEffect(() => {
    if (rawChildOptions && rawChildOptions.length > 0) {
      const childId = Number(child_id) || formik.values.childId
      const isChildIdValid = rawChildOptions.find(
        (child) => child.value === childId
      )

      if (!isChildIdValid) {
        formik.setFieldValue(`childId`, rawChildOptions[0]?.value)
        return
      }

      formik.setFieldValue(`childId`, childId)
    } else {
      // Every time facility_id changes child options will be updated.
      // And as a side effect of that child id will be set to 0 or the first child id
      formik.setFieldValue(`childId`, 0)
    }
  }, [rawChildOptions])

  return (
    <FormBlockContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Program progress record information")}
      >
        {/* Form section starts here. */}
        <div className={"form-group"}>
          <Spin spinning={isRecFormDataLoading ? isRecFormDataLoading : false}>
            {recordFormSchema().map((item, i) => {
              return (
                <FormFieldsGen
                  index={i}
                  item={item}
                  formik={formik}
                  key={item.fieldLabel}
                />
              )
            })}
          </Spin>
        </div>
        {/* Form section ends here. */}

        {pageType === "ADD" && (
          <div className={"form-button-group flexbox btn-block"}>
            <OwnerButton
              className={"btn"}
              text={t("Cancel")}
              typeOf={"secondary"}
              onClick={() => {
                updateParams(
                  {
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MM"),
                    facility_id: facility_id,
                    child_id: child_id,
                  },
                  `/program-progress-record-list`
                )
              }}
            />
            <OwnerButton
              text={t("save")}
              typeOf={"primary"}
              className={"save-button btn"}
              isLoading={addingProgressRecord}
              onClick={() => formik.handleSubmit()}
            />
          </div>
        )}
      </TopRoundedWhiteCard>
    </FormBlockContainer>
  )
}
