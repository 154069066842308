const ArrowDownIcon = () => (
  <svg
    width={"10"}
    height={"5"}
    viewBox={"0 0 10 5"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={"M9.21094 0.675781L5.12074 3.53577L0.999939 0.675781"}
      stroke={"#191919"}
      strokeWidth={"1.2"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default ArrowDownIcon
