import React, { useState } from "react"
import { Typography } from "antd"
import { DatePicker } from "../../molecules/DatePicker"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import locale from "antd/lib/date-picker/locale/ja_JP"
import {
  theme,
  Modal,
  TextField,
  SelectInput,
  DeleteConfimationModal,
  getPastYearList,
  anchorSelInputAreaAsPopupContainer,
} from "@project/shared"
import { InstructorInfo } from "../../molecules"
import { OwnerButton } from "../../atoms"
import { useQuery } from "react-query"
import { getQualification } from "../../../services"
import {
  ErrorCounterWrapper,
  ErrorStyled,
} from "../InstructorContent/InstructorContent.styles"
export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .ant-typography {
    margin-bottom: 0px !important;
  }
  .msg {
    color: ${theme.red2};
    font-weight: 400;
    font-size: 16px;
    white-space: pre-wrap;
  }
  @media (max-width: ${theme.breakpoints?.md}) {
    flex-direction: column;
    align-items: start;
    .msg {
      font-size: 14px;
    }
  }
`
export const ModalBodyContainer = styled.div`
  margin: 0 auto;
  padding: 24px;
  position: "relative";
  .body_content {
    display: flex;
    .form_control {
      border: 1px solid ${theme.gray2};
      width: 100%;

      &:not(:last-child) {
        border-right: 0px;
      }
      &:nth-child(1) {
        border-right: 0px;
        width: 450px;
      }
      .label {
        border-bottom: 1px solid ${theme.gray2};
        padding: 10px;
      }
      .form_input {
        padding: 10px;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100% !important;
        }
      }
      .error_message {
        font-size: 12px;
        color: #eb5757;
      }
      .ant-picker {
        border-radius: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        box-shadow: none;
      }
      @media (max-width: 1075px) {
        &:not(:last-child) {
          border-bottom: 0px;
          border-right: 1px solid ${theme.gray2};
        }
        &:nth-child(1) {
          /* border-right: 0px; */
          width: 100%;
        }
      }
    }
    @media (max-width: 1075px) {
      flex-direction: column;
    }
    @media (max-width: 610px) {
      max-width: 43rem;
    }
    @media (max-width: 445px) {
      max-width: 30rem;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 12px;
  }
`
const ModalWrapper = styled(Modal)``

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid ${theme.gray2};
  padding: 10px 24px;
`
interface FormValues {
  qualification_id: string
  eligibility: string
  acquired_date: null | Date
  expire_date: null | Date
  year: null | number
}
interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY年MM月DD日"

const initialValues = {
  qualification_id: "",
  eligibility: "",
  acquired_date: null,
  expire_date: null,
  year: null,
}
export const Qualification: React.FC<IProps> = ({ data, onSave, onDelete }) => {
  const { t } = useTranslation()
  const { data: qualifications } = useQuery({
    queryKey: ["qualifications"],
    queryFn: () => getQualification(),
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      year: Yup.number().typeError(t("Required")).nullable(),
      qualification_id: Yup.number()
        .typeError(t("Required"))
        .required(t("Required")),
    }),
    onSubmit: (values: FormValues) => {
      if (operation === "create") {
        onSave(
          {
            ...values,
            tempId: new Date().getTime(),
            is_functional_training: false,
            acquired_date: moment(values?.acquired_date),
            expire_date: moment(values?.expire_date),
            year: values?.year || null,
          },
          operation
        )
      } else {
        onSave(
          {
            tempId: selectedId,
            ...values,
            acquired_date: moment(values?.acquired_date),
            expire_date: moment(values?.expire_date),
            year: values?.year,
          },
          operation
        )
      }
      setOperation("create")
      handleReset()
    },
  })

  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })
    setIsModalOpen(false)
  }

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  qualification_id: row?.qualification_id,
                  eligibility: row?.eligibility,
                  acquired_date: moment(row?.acquired_date),
                  expire_date: moment(row?.expire_date),
                  year: row?.year,
                },
              })

              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.blue1, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Year")}</span>,
      key: "year",
      dataIndex: "year",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Qualification name")}</span>
      ),
      key: "qualification_id",
      dataIndex: "qualification_id",
      render: (id) => (
        <span>
          {
            qualifications?.data?.find((val) => val.id === id)
              ?.qualification_name
          }
        </span>
      ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Eligibility requirements / Others")}
        </span>
      ),
      key: "eligibility",
      dataIndex: "eligibility",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Acquired date")}</span>,
      key: "acquired_date",
      dataIndex: "acquired_date",
      render: (row) => (
        <span>{moment(row)?.isValid() ? moment(row).format(FORMAT) : ""}</span>
      ),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Expire date")}</span>,
      key: "expire_date",
      dataIndex: "expire_date",
      render: (row) => (
        <span>{moment(row)?.isValid() ? moment(row).format(FORMAT) : ""}</span>
      ),
    },
  ]

  const filterQualification = (selectedQualificationId: number) => {
    if (operation === "create") {
      return qualifications?.data?.filter((val) =>
        data?.every(
          (d) =>
            val.qualification_name === "その他" ||
            d?.qualification_id !== val?.id
        )
      )
    }
    return qualifications?.data?.filter((val) =>
      data?.every(
        (d) =>
          val.qualification_name === "その他" ||
          d?.qualification_id === selectedQualificationId ||
          d?.qualification_id !== val?.id
      )
    )
  }
  const isValid = () =>
    Object.keys(formik?.values)?.some((key) => formik?.values[key])

  return (
    <>
      <InstructorInfo
        title={
          <PageTitle>
            <Typography.Paragraph>
              {t("Possession qualification list")}
            </Typography.Paragraph>
            <Typography.Paragraph className={"msg"}>
              {t(
                " ※If you have multiple qualifications, please repeat the registration procedure."
              )}
            </Typography.Paragraph>
          </PageTitle>
        }
        columns={columns}
        dataSource={data}
        btnText={t("Register your qualification")}
        emptyText={t("No qualification is registered")}
        onClick={() => setIsModalOpen(!isModalOpen)}
      />
      <ModalWrapper
        title={t("Register your qualification")}
        closeIcon={<span>{t("Close")}</span>}
        open={isModalOpen}
        onCancel={() => {
          handleReset()
          setOperation("create")
          setIsModalOpen(false)
        }}
        width={1200}
        maskClosable={false}
        destroyOnClose
        centered
        btnRightMargin={"24px"}
        footer={null}
        noPadding
        wrapClassName={"instructor__occu_info-modal"}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 100px)",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <ModalBodyContainer>
            <div className={"body_content"}>
              <div className={"form_control"}>
                <div className={"label"}>{t("Year")}</div>
                <div className={"form_input"}>
                  <SelectInput
                    options={getPastYearList(true)}
                    height={38}
                    bordercolor={theme.gray2}
                    id={"year"}
                    name={"year"}
                    value={formik?.values?.year}
                    onChange={(val) => formik?.setFieldValue("year", val)}
                    getPopupContainer={anchorSelInputAreaAsPopupContainer}
                  />

                  <ErrorCounterWrapper>
                    {formik.errors.year && formik.touched.year && (
                      <ErrorStyled>{t("Required")}</ErrorStyled>
                    )}
                  </ErrorCounterWrapper>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Qualification name")}</div>
                <div className={"form_input"}>
                  <SelectInput
                    options={filterQualification(
                      Number(formik?.values?.qualification_id || 0)
                    )?.map((qualification) => ({
                      label: qualification?.qualification_name,
                      value: qualification?.id,
                    }))}
                    height={38}
                    bordercolor={theme.gray2}
                    id={"qualification_id"}
                    name={"qualification_id"}
                    value={formik?.values?.qualification_id}
                    dropdownMatchSelectWidth={false}
                    onChange={(val) =>
                      formik?.setFieldValue("qualification_id", val)
                    }
                    getPopupContainer={anchorSelInputAreaAsPopupContainer}
                  />
                  <ErrorCounterWrapper>
                    {formik.errors.qualification_id &&
                      formik.touched.qualification_id && (
                        <ErrorStyled>
                          {formik?.errors.qualification_id}
                        </ErrorStyled>
                      )}
                  </ErrorCounterWrapper>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>
                  {t("Eligibility requirements / Others")}
                </div>
                <div className={"form_input"}>
                  <TextField
                    bgcolor={theme.base}
                    padding={"8px 12px"}
                    borderradius={"0px"}
                    name={"eligibility"}
                    value={formik?.values?.eligibility}
                    onChange={formik.handleChange}
                  />
                  <ErrorCounterWrapper>
                    {formik.errors.eligibility &&
                      formik.touched.eligibility && (
                        <ErrorStyled>{formik.errors.eligibility}</ErrorStyled>
                      )}
                  </ErrorCounterWrapper>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Acquired date")}</div>
                <div className={"form_input"}>
                  <DatePicker
                    name={"acquired_date"}
                    format={FORMAT}
                    inputReadOnly
                    date={
                      moment(formik?.values?.acquired_date)?.isValid()
                        ? moment(formik?.values?.acquired_date)
                        : undefined
                    }
                    locale={locale}
                    size={"small"}
                    onDateChange={(e) =>
                      formik.setFieldValue("acquired_date", moment(e))
                    }
                    placement={"bottomLeft"}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                  <ErrorCounterWrapper>
                    {formik.errors.acquired_date &&
                      formik.touched.acquired_date && (
                        <ErrorStyled>{formik.errors.eligibility}</ErrorStyled>
                      )}
                  </ErrorCounterWrapper>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Expire date")}</div>
                <div className={"form_input"}>
                  <DatePicker
                    name={"expire_date"}
                    format={FORMAT}
                    inputReadOnly
                    disabledDate={(current) =>
                      current.isBefore(moment(formik.values?.acquired_date))
                    }
                    locale={locale}
                    date={
                      moment(formik?.values?.expire_date)?.isValid()
                        ? moment(formik?.values?.expire_date)
                        : undefined
                    }
                    onDateChange={(e) => {
                      formik.setFieldValue("expire_date", moment(e))
                    }}
                    placement={"bottomLeft"}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />

                  <ErrorCounterWrapper>
                    {formik.errors.expire_date &&
                      formik.touched.expire_date && (
                        <ErrorStyled>
                          {formik.errors.expire_date ? t("Required") : ""}
                        </ErrorStyled>
                      )}
                  </ErrorCounterWrapper>
                </div>
              </div>
            </div>
          </ModalBodyContainer>
          <BtnContainer>
            <OwnerButton
              text={t("Save")}
              borderColor={"black"}
              backgroundColor={"#FFFFFF"}
              type={"submit"}
              shape={"circle"}
              disabled={!isValid()}
            />
            {operation === "edit" && (
              <OwnerButton
                text={t("Delete")}
                backgroundColor={theme.red1}
                borderColor={"black"}
                color={theme.red1}
                shape={"circle"}
                onClick={() => setOpenDeleteModal(!openDeleteModal)}
              />
            )}
          </BtnContainer>
        </form>
      </ModalWrapper>
      {openDeleteModal && (
        <DeleteConfimationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
