/**
 * NOTE:
 * - R6 June [abolished:廃止]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"

type Params = {
  name: string
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getWelfareTreatmentImprovement(date: Moment, params: Params) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
    case Version.R6_APRIL:
      return baseline(params.name)
    default:
      return null
  }
}

/**
 * --------------------------------
 * @version R6_JUNE
 *
 * NONE
 * --------------------------------
 */

/**
 * --------------------------------
 * @version BASELINE
 * @version R6_APRIL
 * --------------------------------
 */
function baseline(name: string): FormFieldsProp {
  return {
    fieldLabel:
      "Welfare/nursing care staff treatment improvement addition target",
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: [
              {
                label: t("No"),
                value: "0",
              },
              {
                label: t("I"),
                value: "1",
              },
              {
                label: t("II"),
                value: "2",
              },
              {
                label: t("III"),
                value: "3",
              },
            ],
          },
        },
      },
    ],
  }
}
