// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const NoticeWrapper = styled.div<{
  $theme?:
    | "red-1"
    | "red-2"
    | "red-3"
    | Omit<string, "red-1" | "red-2" | "red-3">
}>`
  border: 1px solid
    ${({ $theme }) =>
      ({
        "red-1": theme.red2,
        "red-2": theme.red1,
        "red-3": theme.red3,
      }[$theme as string] || theme.red2)};
  color: ${({ $theme }) =>
    ({
      "red-1": theme.red2,
      "red-2": theme.red1,
      "red-3": theme.red3,
    }[$theme as string] || theme.red2)};

  border-radius: 12px;
  padding: 24px 30px;
  background-color: ${theme.base};

  .notice-content {
    color: inherit;

    .notice-toggle-btn {
      background-color: transparent;
      border: none;
      padding: 0;
      text-align: left;

      .notice-content_title {
        font-size: 18px;
        font-weight: 700;
        .down-facing-arrow {
          .red-down-facing-arrow {
            margin-inline: 5px;
          }
          &.active {
            .red-down-facing-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.expandable {
        cursor: pointer;
      }
    }

    .notice-content_more-info {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease-in-out;

      .notice-grid-contents {
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        .notice-content_sub-title {
          font-size: 16px;
          font-weight: 400;
        }
        ul li {
          margin-left: 18px;
          .underline {
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }

      &.show {
        transition: grid-template-rows 0.3s ease-in-out;
        grid-template-rows: 1fr;
        .notice-grid-contents {
          opacity: 1;
          transition: grid-template-rows 0.3s ease-in-out;
        }
      }
    }
  }
`
