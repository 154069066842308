import { t } from "i18next"

type Props = {
  managementResult: number
}

export function ShizuokaUpperLimitManagementResult({
  managementResult,
}: Props) {
  return (
    <>
      <div className={"monthly_limit__amount"}>
        {/* TODO: translate */}
        <div className={"large"}>{"利用者負担上限額管理結果"}</div>
        <div className={"small"}>{managementResult}</div>
      </div>
      <div className={"text__content"}>
        <ol>
          <li>
            {t(
              `Since the user-paid amount was allocated at the managed facility, no user-paid amount is incurred at the other facilities.`
            )}
          </li>
          <li>
            {t(
              "The amount will not be adjusted because the total amount paid by the user is less than the maximum monthly payment amount."
            )}
          </li>
          <li>
            {t(
              `Since the total amount of user charges exceeded the maximum monthly amount to be borne, the following adjustment was made.`
            )}
          </li>
        </ol>
      </div>
    </>
  )
}
