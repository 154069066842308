import {
  dynamicLangString,
  Grid,
  PageCard,
  Required,
  TextField,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Skeleton } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import * as yup from "yup"
import {
  createServiceProvision,
  deleteServiceProvision,
  getServiceProvision,
  updateServiceProvision,
} from "../../../services"
import { AuthContext, Info, StyledForm } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import styled from "styled-components"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"
import { ServiceProvisionMasterPermission } from "../../../utils/PermissionKeys"

interface iServiceProvisionForm {
  id?: string
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
  }
`

const ServiceProvisionForm: React.FC<iServiceProvisionForm> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)
  const hasDeleteAccess = useMemo(() => {
    if (!id) return false
    return (
      isOwner ||
      hasPermissionForMenu(
        permissions,
        ServiceProvisionMasterPermission,
        "write"
      )
    )
  }, [permissions, isOwner])
  const {
    data: serviceProvision,
    isLoading: serviceProvisionLoading,
    isFetching: serviceProvisionFetching,
  } = useQuery<any, Error>(
    ["serviceProvision", id],
    () => id && getServiceProvision(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: id,
          service_content_category_name: data?.service_content_category_name,
        }
      },
    }
  )

  const { mutate: mutateServiceProvision, isLoading } = useMutation(
    id ? updateServiceProvision : createServiceProvision,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Service provision content category",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Service provision content category",
              "Created Successfully",
            ]),
          })
        }
        router.push("/service-provisions")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { mutate: handleDelete, isLoading: isDeleting } = useMutation(
    deleteServiceProvision,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Service provision content category",
            "Deleted Successfully",
          ]),
        })

        router.push("/service-provisions")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const initialValues = serviceProvision || {
    service_content_category_name: "",
  }

  const validationSchema = yup.object().shape({
    service_content_category_name: yup.string().required(t("Required")),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      mutateServiceProvision(value)
    },
  })

  return (
    <>
      <PageCard
        title={t("Edit service provision content category")}
        style={{ marginTop: "22px" }}
      >
        {serviceProvisionLoading || serviceProvisionFetching ? (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Skeleton />
          </StyledForm>
        ) : (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Service provision\ncontent category name")}
                </div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"service_content_category_name"}
                height={"50px"}
                value={formik.values.service_content_category_name}
                onChange={formik.handleChange}
                maxLength={50}
                placeholder={t("Example：トレーニング")}
                error={formik?.errors?.service_content_category_name}
              />
              <Info className={"info"}>{t("(within 50 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <ButtonContainer>
              <div className={"button-wrapper service-provision-form-btns"}>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={() => router.back()}
                  className={"cancel-btn"}
                  text={t("Cancel")}
                  disabled={isLoading}
                />
                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  disabled={isLoading}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isLoading}
                />
              </div>
              {hasDeleteAccess && (
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                  onConfirm={() => handleDelete(id)}
                >
                  <div className={"button-wrapper service-provision-form-btns"}>
                    <OwnerButton
                      typeOf={"primary"}
                      text={t("Delete ")}
                      className={"delete-button"}
                      isLoading={isDeleting}
                    />
                  </div>
                </Popconfirm>
              )}
            </ButtonContainer>
          </StyledForm>
        )}
      </PageCard>
    </>
  )
}

export { ServiceProvisionForm }
