import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  .back-button-container {
    margin: 20px 0;
    .back-button {
      color: #000000;
      background-color: rgba(7, 130, 200, 0.2);
    }
  }
  .receipt-additional-options-container {
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid ${theme.gray2};
    .receipt-addition-info {
      margin-bottom: 20px;
    }
    .label-value-block-container {
      width: 100%;
      display: grid;
      grid-template-areas: "label value";
      grid-template-columns: 300px calc(100% - 300px);
      border: 1px solid ${theme.gray2};
      .label {
        grid-area: label;
        background: ${theme.bgColor2};
        padding: 15px 10px;
        display: flex;
        align-items: center;
        border-right: 1px solid ${theme.gray2};
      }
      .value {
        grid-area: value;
        padding: 15px 10px;
        input {
          background: #ffffff;
          max-width: 100%;
          width: 330px;
        }
      }
      @media (max-width: 768px) {
        grid-template-columns: max-content;
        .label {
          width: 120px;
        }
        .value {
          input {
            width: 100%;
          }
        }
      }
    }
    .temp-button-container {
      margin: 20px 0;
      .temp-button {
        background: rgba(7, 130, 200, 0.2);
        color: #000000;
      }
    }
  }

  .print-button-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .print-button {
      background: ${theme.blue5};
      border: none;
      padding: 6px 10px;
    }
    .invoice-amount {
      border: 1px solid ${theme.gray2};
      padding: 6px 10px;
      font-weight: bold;
    }
  }

  .table-container {
    width: 100%;
    margin: 20px 0;
    .button-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .table-button {
        background: #ffffff;
        height: 30px !important;
        width: fit-content !important;
        text-align: left !important;
      }
      .print-button {
        background: ${theme.blue5};
        border: none;
        width: 100px !important;
        height: 35px !important;
        white-space: nowrap;
      }
    }
  }

  .loader-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
`

export const BillAmountWarning = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid ${theme.red3};
  color: ${theme.red3};
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 10px;
`
