// packages
import { t } from "i18next"
import { Typography } from "antd"

// styled components
import { Tag } from "../../ActualCostBurdenDetailPage/styles"

// components
import { CHILD_SERVICE_TYPES, TextField } from "@project/shared"
import moment from "moment"

const useTable = (
  handleInputChange: (rowId: number, key: string, value: number) => void
) => {
  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 186,
      render: (date) => {
        return (
          <Tag bgcolor={"none"} color={"#000"}>
            {moment(date).format("YYYY年MM月DD")}
          </Tag>
        )
      },
    },

    {
      title: t("facility name"),
      dataIndex: "facility_name",
      key: "facility_name",
      width: 174,
    },

    {
      title: t("Child name"),
      dataIndex: "child_name",
      key: "child_name",
      width: 174,
    },

    {
      title: t("Service using"),
      dataIndex: "service_type",
      key: "service_type",
      width: 241,
      render: (val) => {
        return <Tag>{CHILD_SERVICE_TYPES[+val]}</Tag>
      },
    },

    {
      title: (
        <div>
          <span>{t("Cost")}</span>
          <span
            style={{
              background: "#EB5757",
              fontSize: "12px",
              color: "#fff",
              marginLeft: "10px",
              borderRadius: "5px",
              padding: "3px 5px",
            }}
          >
            {t("Required")}
          </span>
        </div>
      ),
      dataIndex: "cost",
      key: "cost",
      width: 409,
      render: (_, row) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={"cost-input flexbox"}>
              <TextField
                width={"300px"}
                bgcolor={"transparent"}
                name={`cost_${row?.id}`}
                type={"number"}
                min={0}
                value={row?.cost}
                onChange={({ target: { value } }) => {
                  const trimmedValue = value.substr(0, 6)
                  handleInputChange(row?.id, "cost", +trimmedValue)
                }}
              />
              <Typography.Text>{t("Yen")}</Typography.Text>
            </div>
            {!row.cost ? (
              <span
                style={{
                  alignSelf: "baseline",
                  marginLeft: "26px",
                  color: "#EB5757",
                  fontSize: "12px",
                }}
              >
                {t("Required")}
              </span>
            ) : null}
          </div>
        )
      },
    },
  ]

  return {
    columns,
  }
}

export default useTable
