import React, { useEffect, useMemo, useState } from "react"
import { Checkbox, Modal, Popconfirm, Spin } from "antd"
import { DatePicker } from "../../molecules/DatePicker"
import { OwnerButton } from "../../atoms"
import { OwnerTable } from "../../molecules"
import { benefitPaymentAmount, SelectInput, TextField } from "@project/shared"
import {
  ContractPaymentModalWrapper,
  ContractPaymentOrangeDatePicker,
  ModalInfo,
  TableWrapper,
} from "../../../module/child/recipient-certificate-form"
import { Trans, useTranslation } from "react-i18next"
import moment from "moment"
import { getFacilityNameFromId, getServiceUseByID } from "../../../utils"
import { getContract } from "../../../services/childRecievingRecipients"
import { useQuery } from "react-query"

interface IRegisterContractPaymentMethodModal {
  open: boolean
  onCancel: () => void
  onOkay: () => void
  data?: any
  id: number | null
  handleSave: (a: any, id?: number) => void
  handleDelete: (id: number) => void
  facilitiesInformation: any
  loading: boolean
  deleting: boolean
  allChildBenefitContracts?: any[]
  facilities: []
}

const popStyle = `
  .ant-popover-buttons {
    .ant-btn {
      height: auto;
    }
  }
`

const RegisterContractPaymentMethodModal: React.FC<
  IRegisterContractPaymentMethodModal
> = ({
  open,
  onCancel,
  onOkay,
  handleSave,
  data,
  id,
  handleDelete,
  deleting,
  loading,
  allChildBenefitContracts,
  facilities = [],
}) => {
  const BENIFIT_AMOUNT_OPTIONS = useMemo(() => benefitPaymentAmount(), [])
  const benefitOptionNegative = BENIFIT_AMOUNT_OPTIONS.map((o) => {
    if (o.value > 0 && o.value != 31) {
      return {
        label: +o.label * -1,
        value: o.value * -1,
      }
    } else if (o.value === 0) {
      return {
        label: +o.label * -1,
        value: -0.1,
      }
    }
  }).filter((o) => o?.value <= 0)

  const [paymentTableData, setPaymentTableData] = useState([])
  const [facilityTableData, setFacilityTableData] = useState([])
  const [terminateContract, setTerminateContract] = useState(undefined)
  const [contractEndDate, setContractEndDate] = useState(undefined)
  const [
    lastDayOfContractEndPreviousMonth,
    setLastDayOfContractEndPreviousMonth,
  ] = useState(
    moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD")
  )
  const [contractData, setContractData] = useState<any>()
  const [errors, setErrors] = useState<{
    [key: string]: string | any
  }>({})
  const [startDuration, setStartDuration] = useState({
    startYear: moment(),
    startMonth: moment(),
  })

  const [selectedFacility, setSeletedFacility] = useState(undefined)
  const [
    contractPayoutIsMoreThanBenefitAmount,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setContractPayoutIsMoreThanBenefitAmount,
  ] = useState(false)

  const { t } = useTranslation()
  const dateFormat = "YYYY年MM月DD日"
  const { data: fetchedContractData, isLoading: fetchingContractData } =
    useQuery("getContractData", () => getContract(id), {
      enabled: !!id,
      select: (res) => {
        return {
          ...res.data,
          is_benefit_days_in_month: res.data.is_benefit_days_in_month
            ? -1
            : res.data.benefit_days,
        }
      },
      refetchOnWindowFocus: false,
    })

  // ! This piece of code was responsible for making the button disabled if there was any validation error.
  // ! but since we are showing the error message on the input field itself, we don't need this code anymore.
  // ! but we are keeping it here for future reference or just in case I AM WRONG HERE.
  // useEffect(() => {
  //   const selected_facility = facilityTableData.find((each) => each.contract)
  //   if (
  //     startDuration?.startMonth &&
  //     startDuration?.startYear &&
  //     selected_facility?.contract_entry_number &&
  //     selected_facility?.contract_start_date &&
  //     selected_facility?.payment_amount &&
  //     selected_facility?.service_provision_start_date
  //   ) {
  //     setDisableAddOrEdit(false)
  //   } else {
  //     setDisableAddOrEdit(true)
  //   }
  // }, [startDuration, facilityTableData])

  useEffect(() => {
    return () => {
      setFacilityTableData([])
      setPaymentTableData([])
      setTerminateContract(undefined)
      setStartDuration({
        startMonth: moment(),
        startYear: moment(),
      })
    }
  }, [])

  // update contract end date on start year month month change
  const changeContractEndDateOnStartYearMonthChange = (date) => {
    const startOfMonth = moment(date).startOf("month")
    const endOfPreviousMonth = startOfMonth.subtract(1, "day")
    setContractEndDate(endOfPreviousMonth)
    setLastDayOfContractEndPreviousMonth(
      endOfPreviousMonth.format("YYYY-MM-DD")
    )
  }
  const lastDay = useMemo(() => {
    const date = moment(startDuration?.startYear).endOf("month").format("DD")
    return date
  }, [startDuration])

  useEffect(() => {
    // check if there is id and if there is one then filter out the data as per the id from allChildBenefitContracts
    // and set the data in contractData

    setContractData(
      id
        ? allChildBenefitContracts?.find((contract) => contract?.id == id)
        : allChildBenefitContracts?.[0]
    )
  }, [allChildBenefitContracts])

  // only if we dan't have allChildBenefitContracts sent as props then we are using
  // fetchedContractData to fill in table-fields/form-fields because we don't want two data to clash with each other.
  useEffect(() => {
    if (fetchedContractData && !allChildBenefitContracts) {
      setContractData(fetchedContractData)
    }
  }, [fetchedContractData])

  useEffect(() => {
    const payment_table_data = [
      {
        benefit_payment_amount:
          data?.benefit_days < 0
            ? data?.benefit_days === -0.1
              ? lastDay
              : +lastDay + data?.benefit_days
            : data?.benefit_days,
        total_contract_payout:
          contractData?.benefit_days +
          data?.joint_facility_contract_payment_amount,
        own_facility_contract_supply_amount:
          contractData?.benefit_days < 0
            ? contractData?.benefit_days === -0.1
              ? +lastDay
              : contractData?.benefit_days + +lastDay
            : contractData?.benefit_days,
        joint_facility_contract_payment_amount:
          data?.joint_facility_contract_payment_amount || 0,
        stop_contract_flag: contractData?.stop_contract_flag || false,
      },
    ]

    const selected_faclity_id = contractData?.facility_id

    const FACILITIES_TABLE_DATA = facilities?.map((each: any) => {
      const is_selected_facility = selected_faclity_id === each?.id
      return {
        id: each?.id,
        facility_id: each?.id,
        facility_name: getFacilityNameFromId(facilities, each?.id),
        contract: is_selected_facility,
        contract_start_date: is_selected_facility
          ? contractData?.contract_start_date
          : null,
        service_provision_start_date: is_selected_facility
          ? contractData?.service_start_date
          : null,

        contract_entry_number: is_selected_facility
          ? contractData?.contract_input_number
          : null,
        stop_contract_flag: is_selected_facility
          ? contractData?.stop_contract_flag
          : false,
        payment_amount: is_selected_facility
          ? contractData?.benefit_days
          : null,
        is_benefit_days_in_month: is_selected_facility
          ? contractData?.is_benefit_days_in_month
            ? -1
            : contractData?.benefit_days
          : 0,
      }
    })
    setPaymentTableData(payment_table_data)
    setFacilityTableData(FACILITIES_TABLE_DATA)
    setSeletedFacility(selected_faclity_id)
    setTerminateContract(id ? contractData?.stop_contract_flag : false)
    setContractEndDate(
      contractData?.contract_end_date || lastDayOfPreviousMonth
    )

    setStartDuration({
      startMonth: (id && contractData?.contract_month) || moment(),
      startYear: (id && contractData?.contract_year) || moment(),
    })
  }, [contractData, id])

  useEffect(() => {
    // breakpoint
    if (paymentTableData?.length) {
      // update payment table info when last day changes
      // applicable only if calculation is based on number of months
      const selectedFacilityContractData = facilityTableData?.find(
        (fd) => fd?.facility_id == selectedFacility
      )
      const updatedPaymentInfo = paymentTableData?.map((payment) => {
        return {
          ...payment,
          own_facility_contract_supply_amount:
            selectedFacilityContractData?.is_benefit_days_in_month < 0
              ? selectedFacilityContractData?.payment_amount === -0.1
                ? +lastDay
                : selectedFacilityContractData?.payment_amount + +lastDay
              : selectedFacilityContractData?.payment_amount,
          benefit_payment_amount:
            data?.is_benefit_days_in_month == -1
              ? data?.benefit_days < -0.9
                ? +lastDay + data?.benefit_days
                : +lastDay
              : data?.benefit_days,
        }
      })
      setPaymentTableData(updatedPaymentInfo)
    }
  }, [lastDay])

  const handleContractChange = (id: number) => {
    const _facilityTableData = [...facilityTableData]
    const _paymentTableData = [...paymentTableData]
    _facilityTableData.forEach((each, index) => {
      if (each.id === id) {
        _facilityTableData[index].contract = true
        // this changes total contract payout and own facility contract supply amount value when contract is changed.
        _paymentTableData[0].total_contract_payout =
          _facilityTableData[index].payment_amount
        _paymentTableData[0].own_facility_contract_supply_amount =
          _facilityTableData[index].payment_amount
      } else {
        _facilityTableData[index].contract = false
      }
    })
    setSeletedFacility(id)
    setFacilityTableData(_facilityTableData)
    setPaymentTableData(_paymentTableData)
  }

  const handleContractFieldChange = (id: number, field: string, value: any) => {
    const _facilityTableData = [...facilityTableData]
    const _paymentTableData = [...paymentTableData]
    _facilityTableData.forEach((contract, index) => {
      if (contract.id === id) {
        _facilityTableData[index][field] = value
      }
    })
    if (field === "payment_amount" || field === "is_benefit_days_in_month") {
      let amount = 0
      amount = value < 0 ? +lastDay + value : value
      if (value === -0.1) {
        amount = +lastDay
      }
      _paymentTableData[0].own_facility_contract_supply_amount = amount // adding end month day to value since value is in neg
      _paymentTableData[0].total_contract_payout =
        amount + paymentTableData[0]?.joint_facility_contract_payment_amount
    }
    setFacilityTableData(_facilityTableData)
    setPaymentTableData(_paymentTableData)
  }

  const handleBlur = (e) => {
    if (
      !e.target.value &&
      e.target.name &&
      e.target.name != "is_benefit_days_in_month"
    ) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: t("Required"),
      }))
    } else if (
      e.target.name == "is_benefit_days_in_month" &&
      data?.is_benefit_days_in_month === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        ["payment_amount"]: t("invalid"),
      }))
    }
  }
  /**
   * @returns {HTMLElement} - returns the custom container for the
   * datepicker's popup to which popup will be anchored/be relative to.
   */

  const getCustomContainer = () => {
    const AnchorTable = document.getElementById("popup-container-table")
    return AnchorTable.querySelector(".ant-table-container")
  }

  /**
   * @returns {HTMLElement} - returns the the element that we
   * want to prevent from scrolling when datepicker's popup is open.
   * */

  const getScrollPreventEl = () => {
    const AnchorTable = document.getElementById("popup-container-table")
    return AnchorTable.querySelector(".ant-table-content")
  }

  const currentDate = moment()
  const firstDayOfCurrentMonth = currentDate.clone().startOf("month")
  const lastDayOfPreviousMonth = firstDayOfCurrentMonth
    .clone()
    .subtract(1, "day")

  const FACILITY_TABLE_COLUMNS = [
    {
      id: "facility_name",
      dataIndex: "facility_name",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Facility name")}</span>,
      width: 110,
      className: "min-width-class",
    },

    {
      id: "Contract",
      dataIndex: "contract",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Contract")}</span>,
      width: 30,
      className: "min-width-class",
      render: (_, row) => {
        return (
          <Checkbox
            disabled={terminateContract}
            checked={row.contract && !terminateContract}
            onChange={() => handleContractChange(row.id)}
          />
        )
      },
    },

    {
      id: "contract_start_date",
      dataIndex: "contract_start_date",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Contract Start Date")}</span>
      ),
      width: 150,
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div className={"field-with-error-group"}>
            <DatePicker
              getPopupContainer={() =>
                (getCustomContainer() as HTMLElement) || document.body
              }
              scrollPreventEl={
                getScrollPreventEl() as HTMLElement | undefined | null
              }
              date={
                typeof row?.contract_start_date === "string"
                  ? moment(row?.contract_start_date)
                  : row?.contract_start_date
              }
              className={"table-date-picker"}
              name={"contract_start_date"}
              disabled={row?.id !== selectedFacility}
              onDateChange={(v) => {
                handleContractFieldChange(row.id, "contract_start_date", v)
              }}
              placeholder={""}
              onBlur={handleBlur}
              format={dateFormat}
              inputReadOnly={true}
            />
            <p className={"error-text"}>
              {row.facility_id === selectedFacility &&
                !row?.contract_start_date &&
                errors?.contract_start_date}
            </p>
          </div>
        )
      },
    },

    {
      id: "service_provision_start_date",
      dataIndex: "service_provision_start_date",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Service Provision Start Date")}
        </span>
      ),
      width: 150,
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div className={"field-with-error-group"}>
            <DatePicker
              placeholder={""}
              date={
                typeof row?.service_provision_start_date === "string"
                  ? moment(row?.service_provision_start_date)
                  : row?.service_provision_start_date
              }
              getPopupContainer={() =>
                (getCustomContainer() as HTMLElement) || document.body
              }
              scrollPreventEl={
                getScrollPreventEl() as HTMLElement | undefined | null
              }
              className={"table-date-picker"}
              name={"service_provision_start_date"}
              disabled={row?.id !== selectedFacility}
              onDateChange={(v) => {
                handleContractFieldChange(
                  row.id,
                  "service_provision_start_date",
                  v
                )
              }}
              onBlur={handleBlur}
              format={dateFormat}
              inputReadOnly={true}
            />
            <p className={"error-text"}>
              {row.facility_id === selectedFacility &&
                !row.service_provision_start_date &&
                errors?.service_provision_start_date}
            </p>
          </div>
        )
      },
    },

    {
      id: "payment_amount",
      dataIndex: "Payment Amount",
      width: 50,
      align: "center",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Payment Amount")}</span>
      ),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div
              className={"field-with-error-group"}
              style={{ display: "flex", gap: "4px" }}
            >
              <SelectInput
                width={100}
                value={
                  row?.is_benefit_days_in_month
                    ? row?.is_benefit_days_in_month
                    : row?.benefit_days || "0"
                }
                className={"table-row-select"}
                disabled={row?.id !== selectedFacility}
                getPopupContainer={() =>
                  (getCustomContainer() as HTMLElement) || document.body
                }
                scrollPreventEl={
                  getScrollPreventEl() as HTMLElement | undefined | null
                }
                options={BENIFIT_AMOUNT_OPTIONS}
                name={"is_benefit_days_in_month"}
                onChange={(v) => {
                  handleContractFieldChange(
                    row.id,
                    "is_benefit_days_in_month",
                    v
                  )
                  if (v < 0) {
                    handleContractFieldChange(row.id, "payment_amount", -0.1)
                  }
                }}
                error={
                  row?.facility_id === selectedFacility &&
                  row?.is_benefit_days_in_month === 0 &&
                  errors?.is_benefit_days_in_month
                    ? errors?.is_benefit_days_in_month
                    : ""
                }
                onBlur={handleBlur}
              />
              {row?.is_benefit_days_in_month < 0 && (
                <SelectInput
                  width={100}
                  value={row.payment_amount}
                  className={"table-row-select"}
                  disabled={row?.id !== selectedFacility}
                  getPopupContainer={() =>
                    (getCustomContainer() as HTMLElement) || document.body
                  }
                  scrollPreventEl={
                    getScrollPreventEl() as HTMLElement | undefined | null
                  }
                  options={benefitOptionNegative}
                  name={"payment_amount"}
                  onChange={(v) => {
                    handleContractFieldChange(row.id, "payment_amount", v)
                  }}
                  error={
                    row?.facility_id === selectedFacility &&
                    row?.payment_amount === 0 &&
                    errors?.payment_amount
                      ? errors?.payment_amount
                      : ""
                  }
                  onBlur={handleBlur}
                />
              )}
            </div>
            <div>{t("日")}</div>
          </div>
        )
      },
    },

    {
      id: "contract_entry_number",
      dataIndex: "contract_entry_number",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Contract entry number")}
        </span>
      ),
      width: 150,
      align: "center",
      className: "min-width-class",
      render: (_, row) => {
        return (
          <div>
            <div className={"field-with-error-group"}>
              <TextField
                placeholder={t("例：1")}
                value={row.contract_entry_number}
                width={"100px"}
                disabled={row?.id !== selectedFacility}
                name={"contract_entry_number"}
                type={"number"}
                maxLength={2}
                bgcolor={"white"}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  // prevent e key + - as input
                  if (e.keyCode == 69 || e.keyCode == 187 || e.keyCode == 189)
                    e.preventDefault()
                  if (e.keyCode != 8 && row.contract_entry_number?.length >= 2)
                    e.preventDefault()
                }}
                onChange={(e) => {
                  if (
                    (row.contract_entry_number?.length < 2 ||
                      e.target.value?.length <= 2) &&
                    !(+e.target.value < 0)
                  )
                    handleContractFieldChange(
                      row.id,
                      "contract_entry_number",
                      e.target.value
                    )
                }}
                error={
                  row.facility_id === selectedFacility &&
                  !row.contract_entry_number &&
                  errors?.contract_entry_number
                }
              />
            </div>
          </div>
        )
      },
    },
  ]

  const PAYMENT_TABLE_COLUMN = [
    {
      id: "benefit_payment_amount",
      title: t("Benefit Payment Amount"),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <p>
            {row?.benefit_payment_amount < 0
              ? +lastDay + row?.benefit_payment_amount
              : row?.benefit_payment_amount}{" "}
            {"日"}
          </p>
        )
      },
    },
    {
      id: "Total Contract payout",
      dataIndex: "total_contract_payout",
      title: t("Total Contract payout"),
      className: "min-width-class",
      render: (_, row) => {
        return (
          <p
            className={
              row?.benefit_payment_amount &&
              row?.own_facility_contract_supply_amount &&
              row?.benefit_payment_amount <
                row?.own_facility_contract_supply_amount &&
              !row?.stop_contract_flag
                ? "invalid-contract-payout"
                : ""
            }
          >
            {row?.stop_contract_flag
              ? 0
              : row?.own_facility_contract_supply_amount ||
                0 + row?.joint_facility_contract_payment_amount}{" "}
            {"日"}
          </p>
        )
      },
    },
    {
      id: "own_facility_contract_supply_amount",
      dataIndex: "own_facility_contract_supply_amount",
      title: t("Own facility contract supply amount"),
      className: "min-width-class",
      render: (value, row) => {
        return (
          <p
            className={
              row?.benefit_payment_amount &&
              row?.own_facility_contract_supply_amount &&
              row?.benefit_payment_amount <
                row?.own_facility_contract_supply_amount &&
              !row?.stop_contract_flag
                ? "invalid-contract-payout"
                : ""
            }
          >
            {row?.stop_contract_flag ? 0 : value} {"日"}
          </p>
        )
      },
    },
    {
      id: "joint_facility_contract_payment_amount",
      dataIndex: "joint_facility_contract_payment_amount",
      title: t("Joint facility contract payment amount"),
      className: "min-width-class",
      render: (value) => {
        return (
          <p>
            {value} {"日"}
          </p>
        )
      },
    },
  ]

  const handleSaveInformation = () => {
    const selectedFacilityRowData = facilityTableData.find(
      (item) => item.id === selectedFacility
    )
    const updatedErrors = {
      ...errors,
    }

    if (
      !startDuration?.startYear ||
      !startDuration?.startMonth ||
      !selectedFacilityRowData?.contract_start_date ||
      !selectedFacilityRowData?.service_provision_start_date ||
      !selectedFacilityRowData?.is_benefit_days_in_month ||
      (selectedFacilityRowData?.is_benefit_days_in_month < 0 &&
        !selectedFacilityRowData?.payment_amount) ||
      !selectedFacilityRowData?.contract_entry_number
    ) {
      if (!startDuration?.startYear || !startDuration?.startMonth) {
        updatedErrors.contractStartingMonth = t("Required")
      } else {
        updatedErrors.contractStartingMonth = ""
      }

      if (!selectedFacilityRowData?.contract_start_date) {
        updatedErrors.contract_start_date = t("Required")
      } else {
        updatedErrors.contract_start_date = ""
      }

      if (!selectedFacilityRowData?.service_provision_start_date) {
        updatedErrors.service_provision_start_date = t("Required")
      } else {
        updatedErrors.service_provision_start_date = ""
      }

      if (!selectedFacilityRowData?.is_benefit_days_in_month) {
        updatedErrors.is_benefit_days_in_month = t("Required")
      } else {
        updatedErrors.is_benefit_days_in_month = ""
      }

      if (!selectedFacilityRowData?.contract_entry_number) {
        updatedErrors.contract_entry_number = t("Required")
      } else if (Number(selectedFacilityRowData?.contract_entry_number) > 99) {
        updatedErrors.contract_entry_number = t("(Max 2 num)")
      } else {
        updatedErrors.contract_entry_number = ""
      }
      setErrors(updatedErrors)
      return
    }

    const saveData = {
      id: id || Math.random(),
      terminate_contract: terminateContract,
      payment_information: terminateContract,
      contract_start_year: startDuration?.startYear,
      contract_start_month: startDuration?.startMonth,
      facility_information: facilityTableData,
      selected_facility: selectedFacility,
    }

    const updateData = {
      ...saveData,
      contract_end_date: terminateContract
        ? moment(contractEndDate)?.format("YYYY-MM-DD") + "T00:00:00Z"
        : null,
    }

    handleSave(updateData, id)
  }

  return (
    <Modal
      open={open}
      centered
      title={t("Register contract payment amount")}
      wrapClassName={"custom-modal-for-add-joint-facility"}
      onCancel={onCancel}
      onOk={onOkay}
      destroyOnClose
      width={"80%"}
      footer={[
        <div
          key={"buttons"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {paymentTableData?.[0]?.benefit_payment_amount &&
          paymentTableData?.[0]?.own_facility_contract_supply_amount &&
          paymentTableData?.[0]?.benefit_payment_amount <
            paymentTableData?.[0]?.own_facility_contract_supply_amount ? (
            <Popconfirm
              onConfirm={() => {
                handleSaveInformation()
              }}
              title={
                <Trans i18nKey={"confirmGreaterPayout"}>
                  {
                    "Total contract payout is greater than benefit payment amount."
                  }
                  <br /> {"Do you want to proceed ?"}
                </Trans>
              }
              okText={t("Save")}
              cancelText={t("Cancel")}
              placement={"topLeft"}
            >
              <OwnerButton
                text={id ? t("edit") : t("Save")}
                typeOf={"primary"}
                key={"save-button"}
                isLoading={loading}
              />
              <style>{popStyle}</style>
            </Popconfirm>
          ) : (
            <OwnerButton
              text={id ? t("edit") : t("Save")}
              typeOf={"primary"}
              onClick={handleSaveInformation}
              key={"save-button"}
              isLoading={loading}
            />
          )}
          {id && (
            <OwnerButton
              text={t("delete")}
              typeOf={"secondary"}
              isLoading={deleting}
              onClick={() => handleDelete(id)}
              key={"delete-button"}
            />
          )}
        </div>,
      ]}
      bodyStyle={{ overflowY: "scroll", minHeight: "450px" }}
    >
      {/* If there is allChildBenefitContracts already with the props then we don't need the loading */}
      <Spin spinning={fetchingContractData && !allChildBenefitContracts}>
        {contractPayoutIsMoreThanBenefitAmount && (
          <ModalInfo>
            {t(
              `The total contractual payment for the following services exceeds the benefit payment.
                {{serviceName}}`,
              { serviceName: getServiceUseByID(data?.service_type) }
            )}
          </ModalInfo>
        )}
        <ContractPaymentModalWrapper>
          <div className={"modal-title"}>
            <h3>
              {t("{{YYYY}}年{{MM}}月’s Own facility contract information", {
                YYYY: startDuration.startYear
                  ? moment(startDuration.startYear)?.format("YYYY")
                  : moment().year(),
                MM: startDuration.startMonth
                  ? moment(startDuration.startMonth)?.format("MM")
                  : moment().format("MM"),
              })}
            </h3>
          </div>
          <div className={"item"}>
            <TableWrapper>
              <OwnerTable
                dataSource={paymentTableData}
                columns={PAYMENT_TABLE_COLUMN}
              />
            </TableWrapper>
          </div>

          {id ? null : (
            <ContractPaymentOrangeDatePicker style={{ maxWidth: "24rem" }}>
              <p>{t("Starting year month:")}</p>
              <div>
                <DatePicker
                  picker={"month"}
                  className={"contract-payment-datepicker"}
                  name={"contractStartingMonth"}
                  date={
                    typeof startDuration?.startMonth === "string"
                      ? moment(startDuration?.startMonth)
                      : startDuration?.startMonth
                  }
                  onDateChange={(v) => {
                    setStartDuration({
                      ...startDuration,
                      startYear: v,
                      startMonth: v,
                    })
                    changeContractEndDateOnStartYearMonthChange(v)
                  }}
                  placeholder={""}
                  format={"YYYY年MM月"}
                  inputReadOnly={true}
                />
              </div>
              <p>{"年月"}</p>
              {(!startDuration.startYear || !startDuration.startMonth) && (
                <p className={"error-text custom-padded"}>
                  {errors?.contractStartingMonth}
                </p>
              )}
            </ContractPaymentOrangeDatePicker>
          )}

          <div className={"item"}>
            <TableWrapper>
              <OwnerTable
                id={"popup-container-table"}
                className={"facility-table"}
                dataSource={facilityTableData}
                columns={FACILITY_TABLE_COLUMNS}
              />
            </TableWrapper>
          </div>
          <div className={"item flex"}>
            <Checkbox
              checked={terminateContract}
              onChange={(e) => setTerminateContract(e.target.checked)}
            />
            <p>{t("Check here if you want to unsubscribe")}</p>
          </div>

          {terminateContract ? (
            <div className={"item"}>
              <span className={"title"}>
                {t("My page publication period after suspension of use")}
              </span>
              {contractEndDate &&
              moment(contractEndDate)?.isBefore(
                moment(lastDayOfContractEndPreviousMonth)
              ) &&
              !moment(lastDayOfContractEndPreviousMonth)?.isSame(
                moment(contractEndDate),
                "day"
              ) ? (
                <span>
                  {t(
                    "The date on which My Page will be discontinued has been selected as a date that is earlier than the date on which it will be discontinued ({{date}}).",
                    {
                      date: moment(lastDayOfContractEndPreviousMonth).format(
                        "MMMM DD, YYYY"
                      ),
                    }
                  )}
                  <br />
                  {t("If there is no problem, please save it as is.")}
                </span>
              ) : null}
              <div className={"contract_end_month"}>
                <DatePicker
                  className={"contract-payment-datepicker"}
                  name={"contract_end_month"}
                  date={
                    typeof contractEndDate === "string"
                      ? moment(contractEndDate)
                      : contractEndDate
                  }
                  onDateChange={(v) => {
                    setContractEndDate(moment(v).format("YYYY-MM-DD"))
                  }}
                  placeholder={""}
                  onBlur={handleBlur}
                  format={dateFormat}
                  inputReadOnly={true}
                />
                <span>{t("publish up to")}</span>
              </div>
            </div>
          ) : null}
        </ContractPaymentModalWrapper>
      </Spin>
    </Modal>
  )
}

export { RegisterContractPaymentMethodModal }
