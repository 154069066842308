import { PageHeader } from "antd"
import React from "react"
import styled from "styled-components"

interface PageHeaderComponentProps {
  title: string
  extra?: any
  iconPath?: string
  style?: React.CSSProperties
}

const StyledPageHeader = styled(PageHeader)`
  padding: 0;
  margin-bottom: 33px;
  .ant-page-header-heading-title {
    font-weight: 500;
    white-space: pre-wrap;
  }
  .ant-avatar {
    justify-content: center;
    display: flex;
    align-items: center;
    background: #fff;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    img {
      width: 50%;
      height: 50%;
      margin: 18px;
    }
  }
  @media print {
    margin-bottom: 0 !important;
    box-shadow: none !important;
    display: none;
  }
`

const PageHeaderComponent: React.FC<PageHeaderComponentProps> = ({
  title,
  extra,
  iconPath,
  style,
}) => {
  return (
    <StyledPageHeader
      className={"site-page-header"}
      title={title}
      avatar={iconPath && { src: iconPath }}
      extra={extra}
      style={style}
    />
  )
}

export { PageHeaderComponent }
