import React from "react"
import styled from "styled-components"
import { theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import { Table } from "antd"

const PaymentDetailContentTableWrapper = styled.div`
  border: 1px solid ${theme.black};
  #payment__detail_table {
    width: 100%;
    table {
      width: 100%;
      tbody {
        tr {
          @media print {
            &:last-child {
              td {
                border-bottom: 2px solid ${theme.black};
              }
            }
          }

          td {
            border: 1px solid ${theme.black};
            text-align: center;
            padding: 0 !important;
            font-size: 12px;
            position: relative;
            .inner__value_container {
              display: flex;
              justify-content: space-between;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
              position: absolute;
              .inner__value_cell {
                min-width: 10px;
                min-height: 20px;
                width: 100%;
                height: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .inner__value_cell:not(:last-child) {
                border-right: 1px solid ${theme.black};
              }
            }
            @media print {
              &:first-child {
                border-left: 2px solid ${theme.black};
                border-bottom: 2px solid ${theme.black};
              }
              &:last-child {
                border-right: 2px solid ${theme.black};
              }
            }
          }
          td.first_row {
            border-top: 0 !important;
            padding: 0 2px !important;
          }
          .payment_service_content {
            font-size: 12px;
            text-align: left;
            margin-left: 4px;
            @media print {
              font-size: 9pt !important;
            }
          }
        }
      }
      thead {
        tr {
          th {
            border: 1px solid ${theme.black};
            text-align: center;
            background-color: transparent !important;
            font-size: 12px;
            padding: 4px !important;
          }
          th.first_row {
            border-bottom: 0 !important;
          }
        }
      }
    }
  }
  @media print {
    border: 0 !important;
    #payment__detail_table {
      table {
        page-break-inside: auto !important;
        thead {
          display: table-header-group !important;
          th {
            padding: 4px !important;
          }
        }
        tr {
          page-break-inside: avoid !important;
          page-break-after: auto !important;
        }
      }
    }
  }
`

export const PaymentDetailContentTable = ({ data }: any) => {
  const { t } = useTranslation()

  const dataSources = () => {
    return data
      ? data?.map((key) => {
          return {
            service_contents: t(key?.c_04) || <>&nbsp;</>,
            service_code: `${key?.c_05}`
              ?.padStart(6, key?.c_05 ? "0" : " ")
              ?.split(""),
            units: `${key?.c_06}`?.padStart(4, "0")?.split(""),
            times: `${key?.c_07}`?.padStart(2, "0")?.split(""),
            service_units: `${key?.c_08}`?.padStart(5, "0")?.split(""),
            used_days: data[key]?.used_days,
          }
        })
      : []
  }

  const replaceLeadingZero = (array: [string]) => {
    const arrayToModify = [...array]
    const index = arrayToModify?.findIndex((element) => parseInt(element) !== 0)
    const first = [...arrayToModify]?.slice(0, index)?.map(() => "")
    const last = [...arrayToModify]?.slice(index, array?.length)
    return index !== -1 ? [...first, ...last] : array?.map(() => "")
  }
  const filteredData = dataSources().filter((obj) => obj.used_days !== 0)

  const paymentContentColumns = [
    {
      key: 1,
      title: "",
      width: 8,
      className: "first_row",
      render: () => t("Payment details column"),
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: filteredData?.length,
          }
        }
        if (index !== 0) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
    },
    {
      key: 2,
      title: t("Service contents"),
      render: (data) => (
        <div className={"payment_service_content"}>
          {data?.service_contents}
        </div>
      ),
    },
    {
      key: 3,
      title: t("Service code"),
      render: (record) => {
        return (
          <div className={"inner__value_container"}>
            {record?.service_code?.map((v) => (
              <div key={v} className={"inner__value_cell"}>
                {v}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      key: 4,
      title: t("Units"),
      render: (record) => {
        const values = record?.units
        return (
          <div className={"inner__value_container"}>
            {replaceLeadingZero(values)?.map((v) => (
              <div key={v} className={"inner__value_cell"}>
                {v}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      key: 5,
      title: t("Times"),
      render: (record) => {
        const values = replaceLeadingZero(record?.times)
        return (
          <div className={"inner__value_container"}>
            {values?.map((v) => (
              <div key={v} className={"inner__value_cell"}>
                {v}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      key: 6,
      title: t("Service units= Times * units"),
      render: (record) => {
        const values = replaceLeadingZero(record?.service_units)
        return (
          <div className={"inner__value_container"}>
            {values?.map((v) => (
              <div key={v} className={"inner__value_cell"}>
                {v}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      key: 7,
      title: t("Summary"),
    },
  ]

  return (
    <PaymentDetailContentTableWrapper>
      <div id={"payment__detail_table"}>
        <Table
          columns={paymentContentColumns}
          dataSource={filteredData}
          pagination={false}
        />
      </div>
    </PaymentDetailContentTableWrapper>
  )
}
