import { t } from "i18next"
import { Title } from "../../../PrintListContent.style"
import { maskDisabilityWord } from "../../../common/utils"

type Props = {
  printConfig: string[]
}
export function ShizuokaSheetTitle({ printConfig }: Props) {
  return (
    <Title>
      {maskDisabilityWord("静岡市 複数障害児上限額管理用", printConfig)}
      <small style={{ marginLeft: "1rem" }}>
        {t("User burden maximum amount management result sheet")}
      </small>
    </Title>
  )
}
