import { useEffect, useState } from "react"

// packages
import { t } from "i18next"

// styled components
import { OperationControllerContainer } from "../styles"

// components
import { OperationFieldWrapper } from "."
import { OwnerButton } from "../../../atoms"
import { Checkbox, TopRoundedWhiteCard } from "@project/shared"

// constants
import { OperationOptions } from "../constants"

// types
import {
  OptionsProps,
  FormikDataProps,
  OperationOptionsDataProps,
} from "../types"

export const OperationControllerBlock = ({
  formik,
  handleDisplayChange,
  showDisplay,
}: {
  formik: FormikDataProps
  handleDisplayChange: () => void
  showDisplay?: boolean
}) => {
  const [showParentSign, setShowParentSign] = useState<boolean>(false)
  const [isDigi_parentSignDisabled, setIsDigi_parentSignDisabled] =
    useState<boolean>(true)

  useEffect(() => {
    if (formik.values.parentalVerification.includes("3")) {
      setShowParentSign(true)
      setIsDigi_parentSignDisabled(false)
    } else {
      setShowParentSign(false)
      setIsDigi_parentSignDisabled(true)
    }
  }, [formik.values.parentalVerification])

  const handleCheckBoxChange = (
    e: any,
    opt: OperationOptionsDataProps,
    item: OptionsProps
  ) => {
    if (!e.target.checked) {
      formik.setFieldValue(
        opt.name,
        formik.values[opt.name].filter((val) => val !== item.value)
      )
    }
    if (e.target.checked) {
      if (formik.values[opt.name]?.includes(item.value)) return
      formik.setFieldValue(opt.name, [...formik.values[opt.name], item.value])
    }
  }

  return (
    <OperationControllerContainer>
      <TopRoundedWhiteCard
        className={"white-card"}
        title={t("Operation options")}
      >
        {/* Operation search field options starts here */}
        <div className={"operation-controller-content"}>
          {OperationOptions({
            showParentSign,
            isDigi_parentSignDisabled,
            formik,
          }).map((opt) => {
            if (opt.hide) return null
            return (
              <OperationFieldWrapper key={opt.label} label={t(opt.label)}>
                <div className={"ant-checkbox-group"}>
                  {opt.options.map((item) => {
                    return (
                      <Checkbox
                        key={item.label + item.value}
                        name={opt.name}
                        label={t(item.label)}
                        checked={formik.values[opt.name]?.includes(item.value)}
                        disabled={item.isDisabled}
                        onChange={(e) => {
                          if (item.onChange) {
                            item.onChange(e)
                            return
                          }
                          handleCheckBoxChange(e, opt, item)
                        }}
                      />
                    )
                  })}
                </div>
              </OperationFieldWrapper>
            )
          })}
        </div>
        {/* Operation search field options ends here */}

        {/* Upper operation_container extra operations starts here */}
        <div className={"footer-btns-group flexbox"}>
          {showDisplay ? (
            <OwnerButton
              className={"reset-btn"}
              typeOf={"draft"}
              text={t("Display change")}
              onClick={() => {
                handleDisplayChange()
              }}
            />
          ) : (
            <>
              <OwnerButton
                className={"search-btn"}
                text={t("Search")}
                typeOf={"search"}
                onClick={() => {
                  handleDisplayChange()
                }}
              />
              <OwnerButton
                className={"reset-btn"}
                text={t("Reset")}
                typeOf={"draft"}
                onClick={() => {
                  formik.resetForm()
                  handleDisplayChange()
                }}
              />
            </>
          )}
        </div>
        {/* Upper operation_container extra operations ends here */}
      </TopRoundedWhiteCard>
    </OperationControllerContainer>
  )
}
