import React from "react"

// packages
import { t } from "i18next"
import { useFormik } from "formik"

// styled components
import { FilterOperationContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { Checkboxes, TopRoundedWhiteCard } from "@project/shared"
import { DatePicker, OperationFieldWrapper } from "../../../molecules"

// types
import { FilterOperationBlockProps } from "../types"

// constants
import { DISPLAY_SETTING_OPTIONS } from "../constants"

// utils
import { getLanguage } from "../../../../utils/getLanguage"

export const FilterOperationBlock: React.FC<FilterOperationBlockProps> = ({
  currentSearchQueries,
  handleCurrentSearchQueriesChange,
}) => {
  const formik = useFormik({
    initialValues: currentSearchQueries,
    onSubmit: (values) => {
      handleCurrentSearchQueriesChange(values)
    },
  })

  const { setFieldValue, values, handleSubmit } = formik
  return (
    <FilterOperationContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Operation Options")}
      >
        <div className={"form-group"}>
          <div className={"receipt_and_issue-date-group flexbox"}>
            <OperationFieldWrapper
              className={"receipt-date-wrapper date-wrapper"}
              label={t("Date of receipt")}
              labelMinWidth={getLanguage() === "en" ? "140px" : "100px"}
            >
              <DatePicker
                className={"date-picker"}
                name={"dateOfReceipt"}
                date={values.dateOfReceipt ? values.dateOfReceipt : null}
                onDateChange={(value) => {
                  if (value?.isValid()) {
                    setFieldValue("dateOfReceipt", value)
                    return
                  }
                  setFieldValue("dateOfReceipt", null)
                }}
                format={"YYYY年MM月DD日"}
                allowClear
              />
            </OperationFieldWrapper>

            <OperationFieldWrapper
              className={"issued-date-wrapper date-wrapper"}
              label={t("Date of issue")}
              labelMinWidth={getLanguage() === "en" ? "140px" : "100px"}
            >
              <DatePicker
                className={"date-picker"}
                name={"dateOfIssue"}
                date={values.dateOfIssue ? values.dateOfIssue : null}
                onDateChange={(value) => {
                  if (value?.isValid()) {
                    setFieldValue("dateOfIssue", value)
                    return
                  }
                  setFieldValue("dateOfIssue", null)
                }}
                format={"YYYY年MM月DD日"}
                allowClear
                clearIcon
              />
            </OperationFieldWrapper>
          </div>
          <OperationFieldWrapper
            label={t("Display Setting")}
            labelMinWidth={getLanguage() === "en" ? "140px" : "100px"}
            className={"display-setting-wrapper"}
          >
            <Checkboxes
              value={values.displaySetting ? values.displaySetting : []}
              options={DISPLAY_SETTING_OPTIONS}
              onChange={(value) => {
                setFieldValue("displaySetting", value)
              }}
            />
          </OperationFieldWrapper>

          <OwnerButton
            className={"save-btn"}
            typeOf={"primary"}
            text={t("Switch display")}
            onClick={() => handleSubmit()}
          />
        </div>
      </TopRoundedWhiteCard>
    </FilterOperationContainer>
  )
}
