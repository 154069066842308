// packages
import { t } from "i18next"

// utils
import { getCurrentJapaneseEra } from "../../../../utils"

export const blackDate = t("blankEraYearMonthDay", {
  era: getCurrentJapaneseEra(),
})

export const DISPLAY_SETTING_OPTIONS = [
  {
    label: t("Print copy"),
    value: "printCopy",
  },
  {
    label: t("Only print copy"),
    value: "printCopyOnly",
  },
  {
    label: t("Empty the date of receipt"),
    value: "blankReceiptDate",
  },
  {
    label: t("Empty the date of issue"),
    value: "blankIssueDate",
  },
  {
    label: t(
      "Reflect the amount billed by the municipality subsidy to the user fee"
    ),
    value: "reflectAmtBilledByMuniSubsidy",
  },
  {
    label: t("Display in Hiragana order"),
    value: "phoneticOrderLayout",
  },
  {
    label: t('Change the word "disability" to "disability."'),
    value: "disabilityToDisability",
  },
]
