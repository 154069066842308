import { theme } from "@project/shared"
import styled from "styled-components"

export const KobeCityTableComponent = styled.div`
  .KobeCityTable table tbody tr:nth-child(11) .kobe-col {
    border: none !important;
  }
`
export const KyotoTable = styled.table`
  margin-bottom: 20px;
  border-collapse: collapse;
  .kyoto-table-body {
    tr {
      td {
        text-align: center;
        padding: 0.5em;
        min-width: 2em;
        border: 1px solid ${theme.gray9};
        font-size: 12px;
      }
    }
  }
`
