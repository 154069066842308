import { useCallback, useMemo } from "react"

// package
import { t } from "i18next"
import { Table } from "antd"

// common
import { formatDateString, mapper } from "./mapper"
import { dynamicMonthHeadCol, months } from "./helper"

// styled components
import { MonthlyUsageTableWrapper } from "../styles"
import { IFacilityProps } from "../../MonthlyUsageChartOperation/types"

export const MonthlyUsageTable = ({
  data,
  facilityProps,
  isTableLoading,
  showCombinedAfterSchoolRow,
  showAfterSchoolRow,
  showSchoolHolidayRow,
  showDevelopmentSupportRow,
}: {
  isTableLoading?: boolean
  data: { [key: string]: any }
  showAfterSchoolRow?: boolean
  facilityProps: IFacilityProps
  showCombinedAfterSchoolRow?: boolean
  showSchoolHolidayRow?: boolean
  showDevelopmentSupportRow?: boolean
}) => {
  const columns = useMemo(() => {
    return [
      {
        title: "",
        dataIndex: "actualDate",
        key: "actualDate",
        className: "table-column",
        width: 32,
        colSpan: 2,
      },
      {
        title: "",
        dataIndex: "actualDate",
        key: "actualDate",
        className: "actual-category-columns table-column",
        width: 199,
        colSpan: 0,
      },
      ...dynamicMonthHeadCol(),

      {
        title: "延べ利用者数",
        dataIndex: "totalNumberOfUsers",
        key: "totalNumberOfUsers",
        className: "total-user-col table-column",
        width: 100,
        render: (val) => {
          return (
            <div className={"total-user-cell row-styling"}>
              {val && typeof val === "object" ? (
                <>
                  {showDevelopmentSupportRow && (
                    <p className={"no-child-dev-support-user"}>{val[0]}</p>
                  )}
                  {showAfterSchoolRow && (
                    <p className={"no-school-service-user"}>{val[1]}</p>
                  )}
                  {showSchoolHolidayRow && (
                    <p className={"no-school-service-user"}>{val[2]}</p>
                  )}

                  <p className={"no-of-user"}>{val[3]}</p>
                </>
              ) : (
                <>
                  {showDevelopmentSupportRow && (
                    <p className={"no-child-dev-support-user"}>{"-"}</p>
                  )}
                  {showAfterSchoolRow && (
                    <p className={"no-school-service-user"}>{"-"}</p>
                  )}
                  {showDevelopmentSupportRow && (
                    <p className={"no-school-service-user"}>{"-"}</p>
                  )}

                  <p className={"no-of-user"}>{"-"}</p>
                </>
              )}
            </div>
          )
        },
      },

      {
        title: "施設の開所日数",
        dataIndex: "numberOfDaysTheFacilityIsOpen",
        key: "numberOfDaysTheFacilityIsOpen",
        className: "facility-open-days-col table-column",
        width: 100,
        render: (val) => {
          return (
            <div className={"facility-open-days-cell row-styling"}>
              {val && typeof val === "object" ? (
                <>
                  {showDevelopmentSupportRow && (
                    <p className={"no-child-dev-support-user"}>{val[0]}</p>
                  )}
                  {showAfterSchoolRow && (
                    <p className={"no-school-service-user"}>{val[1]}</p>
                  )}
                  {showSchoolHolidayRow && (
                    <p className={"no-school-service-user"}>{val[2]}</p>
                  )}

                  <p className={"no-of-user"}>{val[3]}</p>
                </>
              ) : (
                <>
                  {showDevelopmentSupportRow && (
                    <p className={"no-child-dev-support-user"}>{"-"}</p>
                  )}
                  {showAfterSchoolRow && (
                    <p className={"no-school-service-user"}>{"-"}</p>
                  )}
                  {showSchoolHolidayRow && (
                    <p className={"no-school-service-user"}>{"-"}</p>
                  )}
                  <p className={"no-of-user"}>{"-"}</p>
                </>
              )}
            </div>
          )
        },
      },
    ]
  }, [data])

  const showSumIfPossible = (item1, item2) => {
    const itemNum1 = +item1
    const itemNum2 = +item2
    if (!isNaN(itemNum1) && !isNaN(itemNum2)) return itemNum1 + itemNum2
    if (!isNaN(itemNum1)) return itemNum1
    if (!isNaN(itemNum2)) return itemNum2
    return item1 || item2
  }

  // Custom Tbody component starts here
  const Tbody = useCallback(
    ({ ...restProps }) => {
      const formattedData: any = mapper(
        data?.table_data,
        data?.service_type,
        data?.is_multi_type
      )

      return formattedData.map((item, index) => {
        return (
          <tbody
            key={item.key + index}
            {...restProps}
            className={"ant-table-tbody"}
          >
            {/* Number of child development support users row */}
            {showDevelopmentSupportRow ? (
              <tr
                className={`ant-table-row top-tbody-row ${
                  (facilityProps?.prefId === 2 ||
                    (facilityProps?.multiType &&
                      facilityProps?.prefId2 === 2)) &&
                  item.numberOfDaysTheFacilityIsOpen[0] !== "-"
                    ? "bg-white"
                    : ""
                }`}
              >
                <td className={"ant-table-cell actual-date-cell"} rowSpan={4}>
                  {/* This function will take two consecutive number/digits in a single <span> tag */}
                  <>{formatDateString(item.actualDate)}</>
                </td>
                <td className={"ant-table-cell actual-category-cell"}>
                  {t("Number of child development support users")}
                </td>
                {months.map((month, i) => {
                  if (item[month] && typeof item[month] === "object") {
                    return (
                      <td
                        key={i}
                        className={`ant-table-cell table-monthly-column`}
                      >
                        {item[month] && item[month][0]}
                      </td>
                    )
                  } else if (item.totalDays === 28 && month === "29日") {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={3}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (item.totalDays === 29 && month === "30日") {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={2}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (item.totalDays === 30 && month === "31日") {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={1}
                        rowSpan={4}
                      ></td>
                    )
                  }

                  return null
                })}

                <td className={"ant-table-cell total-user-cell"}>
                  {item.totalNumberOfUsers[0]}
                </td>
                <td className={"ant-table-cell facility-open-days-cell"}>
                  {item.numberOfDaysTheFacilityIsOpen[0]}
                </td>
              </tr>
            ) : (
              <tr className={`ant-table-row top-tbody-row `}>
                <td className={"ant-table-cell actual-date-cell"} rowSpan={4}>
                  {item.actualDate}
                </td>
              </tr>
            )}

            {/* Combine number of after school day service users row */}
            {showCombinedAfterSchoolRow && (
              <tr
                className={`ant-table-row ${
                  !showDevelopmentSupportRow && "top-tbody-row"
                }
                ${
                  (facilityProps?.prefId === 1 ||
                    (facilityProps?.multiType &&
                      facilityProps?.prefId2 === 1)) &&
                  (item.numberOfDaysTheFacilityIsOpen[1] !== "-" ||
                    item.numberOfDaysTheFacilityIsOpen[2] !== "-")
                    ? "bg-white"
                    : ""
                }
                `}
              >
                <td className={"ant-table-cell actual-category-cell"}>
                  {t("Number of users of after-school day services")}
                </td>
                {months.map((month, i) => {
                  if (i + 1 > item.totalDays && showDevelopmentSupportRow) {
                    return null
                  }

                  if (item[month] && typeof item[month] === "object") {
                    return (
                      <td
                        key={i}
                        className={"ant-table-cell table-monthly-column"}
                      >
                        {item[month] &&
                          showSumIfPossible(item[month]?.[1], item[month]?.[2])}
                      </td>
                    )
                  } else if (
                    item.totalDays === 28 &&
                    month === "29日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={3}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 29 &&
                    month === "30日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={2}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 30 &&
                    month === "31日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={1}
                        rowSpan={4}
                      ></td>
                    )
                  }

                  return null
                })}
                <td className={"ant-table-cell total-user-cell"}>
                  {item.totalNumberOfUsers[1] + +item.totalNumberOfUsers[2]
                    ? item.totalNumberOfUsers[2]
                    : ""}
                </td>
                <td className={"ant-table-cell facility-open-days-cell"}>
                  {item.numberOfDaysTheFacilityIsOpen[1] ||
                    item.numberOfDaysTheFacilityIsOpen[2]}
                </td>
              </tr>
            )}

            {/* Number of after school service users row */}
            {showAfterSchoolRow && (
              <tr
                className={`ant-table-row ${
                  !showDevelopmentSupportRow && "top-tbody-row"
                }
                ${
                  (facilityProps?.prefId === 1 ||
                    (facilityProps?.multiType &&
                      facilityProps?.prefId2 === 1)) &&
                  item.numberOfDaysTheFacilityIsOpen[1] !== "-"
                    ? "bg-white"
                    : ""
                }
                `}
              >
                <td className={"ant-table-cell actual-category-cell"}>
                  {t("Number of after-school users")}
                </td>
                {months.map((month, i) => {
                  if (i + 1 > item.totalDays && showDevelopmentSupportRow) {
                    return null
                  }

                  if (item[month] && typeof item[month] === "object") {
                    return (
                      <td
                        key={i}
                        className={"ant-table-cell table-monthly-column"}
                      >
                        {item[month] && item[month][1]}
                      </td>
                    )
                  } else if (
                    item.totalDays === 28 &&
                    month === "29日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={3}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 29 &&
                    month === "30日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={2}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 30 &&
                    month === "31日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={1}
                        rowSpan={4}
                      ></td>
                    )
                  }

                  return null
                })}
                <td className={"ant-table-cell total-user-cell"}>
                  {item.totalNumberOfUsers[1]}
                </td>
                <td className={"ant-table-cell facility-open-days-cell"}>
                  {item.numberOfDaysTheFacilityIsOpen[1]}
                </td>
              </tr>
            )}

            {/* Number of users on school holidays row */}
            {showSchoolHolidayRow && (
              <tr
                className={`ant-table-row
              ${
                !showAfterSchoolRow &&
                !showDevelopmentSupportRow &&
                "top-tbody-row"
              }
              ${
                (facilityProps?.prefId === 1 ||
                  (facilityProps?.multiType && facilityProps?.prefId2 === 1)) &&
                item.numberOfDaysTheFacilityIsOpen[2] !== "-"
                  ? "bg-white"
                  : ""
              }
              `}
              >
                <td className={"ant-table-cell actual-category-cell"}>
                  {t("Number of users on school holidays")}
                </td>
                {months.map((month, i) => {
                  if (i + 1 > item.totalDays && showAfterSchoolRow) {
                    return null
                  }

                  if (item[month] && typeof item[month] === "object") {
                    return (
                      <td
                        key={i}
                        className={"ant-table-cell table-monthly-column"}
                      >
                        {item[month] && item[month][2]}
                      </td>
                    )
                  } else if (
                    item.totalDays === 28 &&
                    month === "29日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={3}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 29 &&
                    month === "30日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={2}
                        rowSpan={4}
                      ></td>
                    )
                  } else if (
                    item.totalDays === 30 &&
                    month === "31日" &&
                    !showDevelopmentSupportRow
                  ) {
                    return (
                      <td
                        key={i}
                        className={"placeholder-table-cell ant-table-cell"}
                        colSpan={1}
                        rowSpan={4}
                      ></td>
                    )
                  }

                  return null
                })}
                <td className={"ant-table-cell total-user-cell"}>
                  {item.totalNumberOfUsers[2]}
                </td>
                <td className={"ant-table-cell facility-open-days-cell"}>
                  {item.numberOfDaysTheFacilityIsOpen[2]}
                </td>
              </tr>
            )}

            {/* Total number of users row */}
            <tr className={"ant-table-row"}>
              <td
                className={"ant-table-cell actual-category-cell total-number"}
              >
                {t("Total number of users")}
              </td>
              {months.map((month, i) => {
                if (i + 1 > item.totalDays) {
                  return null
                }
                return (
                  <td key={i} className={"ant-table-cell table-monthly-column"}>
                    {item[month] && item[month][3]}
                  </td>
                )
              })}
              <td className={"ant-table-cell total-user-cell"}>
                {item.totalNumberOfUsers[3]}
              </td>
              <td className={"ant-table-cell facility-open-days-cell"}>
                {item.numberOfDaysTheFacilityIsOpen[3]}
              </td>
            </tr>
          </tbody>
        )
      })
    },
    [data, showAfterSchoolRow, showSchoolHolidayRow, showDevelopmentSupportRow]
  )
  // Custom Tbody component ends here
  return (
    <MonthlyUsageTableWrapper>
      <Table
        pagination={false}
        scroll={{ x: "max-content" }}
        bordered
        columns={columns}
        dataSource={mapper(
          data?.table_data,
          data?.service_type,
          data?.is_multi_type
        )}
        loading={isTableLoading}
        components={{ body: { wrapper: Tbody as any } }}
        className={"always-present-scrollbar"}
      />
    </MonthlyUsageTableWrapper>
  )
}
