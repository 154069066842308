import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  generateListApplicationData,
  generateSchedules,
} from "../../../utils/facilityApplications"
import { Table } from "antd"
import styled from "styled-components"
import { theme } from "@project/shared"

export const FacilityApplicationsList = ({
  facilityData,
  childId,
  year,
  month,
  contractedFacilities,
  isLoading,
  applicationCount,
  displaySettings,
  isCDSChild,
}: any) => {
  const { t } = useTranslation()
  const [schedules, setSchedules] = useState([])

  useEffect(() => {
    setSchedules([
      ...generateSchedules(
        childId,
        year,
        month,
        facilityData?.schedules,
        facilityData?.facility_holidays,
        contractedFacilities,
        facilityData?.facility_programs
      ),
    ])
  }, [month, facilityData])

  const data = generateListApplicationData(
    schedules,
    facilityData?.facility_holidays,
    isCDSChild
  )

  const getPickupChildren = () => {
    const children = []
    if (displaySettings?.includes("3"))
      children.push({
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Pickup")}</span>,
        dataIndex: ["pick", "is_holiday"],
        key: "pick",
        width: 62,
        render: (text, record, index) => {
          if (record.is_holiday) {
            return <div className={"holiday-cell"}></div>
          }
          return (
            <div
              className={`working-cell ${
                record.is_applied && record?.go_to_meet_flag ? "yellow" : ""
              } ${index === data?.length ? "last_row" : ""}`}
            >
              {index === data?.length ? record?.applicationCount : record.pick}
            </div>
          )
        },
        onCell: (row) => {
          return {
            className:
              row?.is_applied && row?.go_to_meet_flag
                ? "cell-to-be-highlighted"
                : "",
          }
        },
      })
    if (displaySettings?.includes("4"))
      children.push({
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Dropoff")}</span>,
        dataIndex: ["drop", "is_holiday"],
        key: "drop",
        width: 62,
        render: (text, record, index) => {
          if (record.is_holiday) {
            return <div className={"holiday-cell"}>{t("Holiday")}</div>
          }
          return (
            <div
              className={`working-cell ${
                record.is_applied && record?.take_flag ? "yellow" : ""
              } ${index === data?.length ? "last_row" : ""}`}
            >
              {index === data?.length ? record?.applicationCount : record.drop}
            </div>
          )
        },
        onCell: (row) => {
          return {
            className:
              row?.is_applied && row?.take_flag ? "cell-to-be-highlighted" : "",
          }
        },
      })
    return children
  }

  const getPickupLocationChildren = () => {
    const children = []
    if (displaySettings?.includes("5"))
      children.push({
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Pickup")}</span>,
        dataIndex: ["pick_place", "is_holiday"],
        key: "pick_place",
        width: 80,
        render: (text, record, index) => {
          if (record.is_holiday) {
            return <div className={"holiday-cell"}></div>
          }
          return (
            <div
              className={`working-cell ${
                index === data?.length ? "last_row" : ""
              }`}
            >
              {record.pick_place}
            </div>
          )
        },
      })
    if (displaySettings?.includes("6"))
      children.push({
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Dropoff")}</span>,
        dataIndex: ["drop_place", "is_holiday"],
        key: "drop_place",
        width: 80,
        render: (text, record, index) => {
          if (record.is_holiday) {
            return <div className={"holiday-cell"}></div>
          }
          return (
            <div
              className={`working-cell ${
                index === data?.length ? "last_row" : ""
              }`}
            >
              {record.drop_place}
            </div>
          )
        },
      })
    return children
  }

  const columns = [
    {
      title: (
        <div style={{ whiteSpace: "nowrap", minWidth: "40px" }}>{t("Day")}</div>
      ),
      dataIndex: ["day", "is_holiday"],
      key: "day",
      width: 52,

      render: (text, record, index) => {
        if (!record.is_holiday) {
          return (
            <div
              className={`working-cell ${
                index === data?.length ? "last_row" : ""
              }`}
            >
              {record.day}
            </div>
          )
        }
        return <div className={"holiday-cell"}>{record.day}</div>
      },
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap", minWidth: "40px" }}>
          {t("Day of week")}
        </div>
      ),
      dataIndex: "week",
      key: "week",
      width: 63,
      render: (val, record, index) => {
        if (record?.is_holiday) {
          return (
            <div
              className={`saturday sunday holiday-cell ${
                index === data?.length ? "last_row" : ""
              }`}
            >
              {t(val)}
            </div>
          )
        }
        return (
          <div
            className={`working-cell ${
              index === data?.length ? "last_row" : ""
            }`}
          >
            {t(val)}
          </div>
        )
      },
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap" }}>{t("Applied facility")}</div>
      ),
      children: [
        {
          title: (
            <div style={{ whiteSpace: "nowrap" }}>
              {
                facilityData?.schedules?.find((f) => f?.facility_name)
                  ?.facility_name
              }
            </div>
          ),
          dataIndex: ["participate", "is_holiday"],
          key: "participate",
          width: 122,
          className: "cell-to-be-highlighted",
          render: (text, record, index) => {
            if (record.is_holiday) {
              return <div className={"holiday-cell"}></div>
            }
            return (
              <div
                className={`working-cell ${record.is_applied ? "yellow" : ""} ${
                  index === data?.length ? "last_row" : ""
                }`}
              >
                {index === data?.length
                  ? record?.applicationCount
                  : record?.programs?.programs}
              </div>
            )
          },
        },
        {
          title: (
            <div style={{ whiteSpace: "nowrap" }}>
              {t("Participate\nfacility")}
            </div>
          ),
          dataIndex: "facility",
          key: ["facility", "is_holiday"],
          width: 122,
          className: "cell-to-be-highlighted",
          render: (text, record, index) => {
            if (record.is_holiday) {
              return (
                <div
                  className={`holiday-cell ${
                    index === data?.length ? "last_row" : ""
                  }`}
                ></div>
              )
            }
            return (
              <div
                className={`working-cell ${record.is_applied ? "yellow" : ""} ${
                  index === data?.length ? "last_row" : ""
                }`}
              >
                {index === data?.length
                  ? record?.applicationCount
                  : record?.facility_name}
              </div>
            )
          },
        },
      ],
      filterKeys: "1",
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Pick up")}</span>,
      children: getPickupChildren(),
      filterKeys: ["3", "4"],
    },

    {
      title: <div style={{ whiteSpace: "nowrap" }}>{t("Pick up place")}</div>,
      children: getPickupLocationChildren(),
      filterKeys: ["5", "6"],
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap", width: "auto" }}>
          {t("Pick up\ndesire time")}
        </div>
      ),
      dataIndex: ["pick_time", "is_holiday"],
      key: "pick_time",
      width: 122,
      render: (_, record, index) => {
        if (record.is_holiday) {
          return <div className={"holiday-cell"}></div>
        }
        return (
          <div
            className={`working-cell ${
              index === data?.length ? "last_row" : ""
            }`}
          >
            {record.pick_time}
          </div>
        )
      },
      filterKeys: "7",
    },

    {
      title: (
        <div style={{ whiteSpace: "nowrap" }}>{t("Drop off desire time")}</div>
      ),
      dataIndex: ["drop_time", "is_holiday"],
      key: "drop_time",
      align: "center",
      width: 122,
      render: (_, record, index) => {
        if (record.is_holiday) {
          return <div className={"holiday-cell"}></div>
        }
        return (
          <div
            className={`working-cell ${
              index === data?.length ? "last_row" : ""
            }`}
          >
            {record.drop_time}
          </div>
        )
      },
      filterKeys: "8",
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Remarks")}</span>,
      dataIndex: ["remarks", "is_holiday"],
      key: "remarks",
      width: 119,
      render: (text, record, index) => {
        if (record.is_holiday) {
          return <div className={"holiday-cell"}></div>
        }
        return (
          <div
            className={`working-cell ${
              index === data?.length ? "last_row" : ""
            }`}
          >
            {record.complete_remarks}
          </div>
        )
      },
      filterKeys: "9",
    },
  ]

  return (
    <Wrapper>
      <Table
        className={"user-attendance-date"}
        columns={
          columns?.filter((column) => {
            if (!column?.filterKeys) return true
            if (typeof column?.filterKeys == "string")
              return displaySettings?.includes(column?.filterKeys)
            if (Array.isArray(column?.filterKeys)) {
              for (let i = 0; i < column?.filterKeys?.length; i++) {
                if (displaySettings?.includes(column?.filterKeys[i]))
                  return true
              }
              return false
            }
            return true
          }) as any
        }
        dataSource={[
          ...data,
          {
            applicationCount: applicationCount,
            pickupCount: applicationCount,
            dropOffCount: applicationCount,
          },
        ]}
        bordered
        pagination={false}
        loading={isLoading}
        scroll={{ x: "max-content" }}
        rowClassName={(record) => {
          if (record.is_applied) return "yellow-cell"
        }}
      />
    </Wrapper>
  )
}

//styles
export const Wrapper = styled.div`
  .ant-table-wrapper {
    margin-bottom: 30px;
  }
  display: flex;
  flex-direction: column;

  .working-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    text-align: center;
    a {
      color: #0782c8;
      text-decoration: underline;
    }

    .radio-group .ant-radio-group .ant-radio-wrapper {
      margin: 0;
      & > span:last-child {
        display: none;
      }
    }
  }

  .ant-table-container {
    table {
      width: 100%;
      th {
        word-break: break-all;
        white-space: pre-wrap;
        text-align: center;
      }

      .ant-table-row.yellow-cell {
        td.cell-to-be-highlighted {
          background-color: ${theme.yellowLight};
        }
      }

      .ant-table-row:last-child {
        td.ant-table-cell {
          background-color: ${theme.base} !important;
          .working-cell {
            height: 60px;
            border-top: 1px solid #f0f0f0;
            margin-top: 5px;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              top: -6px;
              left: -1px;
              width: 1px;
              height: 5px;
              background-color: ${theme.base};
            }
          }
        }
      }
    }
  }
  .flex-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .container {
    padding: 38px 30px;
    border: 1px solid #d2d1d1;
  }
  .saturday {
    color: #0782c8;
  }
  .sunday {
    color: #e00000;
  }
  tbody {
    .ant-table-cell {
      padding: 0;
    }
  }
  .time-flex {
    position: relative;
    left: -0.2em;
  }

  .yellow {
    background-color: #feecaf;
    .ant-radio-inner {
      background: #feecaf !important;
    }
  }
  .normal-radio {
    .ant-radio-inner {
      background: white !important;
    }
  }
  .holiday-cell {
    width: 100%;
    height: 63px;
    background-color: rgba(224, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media print {
    .ant-table-wrapper {
      margin-bottom: 10px;
      margin-top: 10px !important;
    }
    .ant-table-container {
      &:before {
        display: none;
      }
      .ant-table-content {
        overflow: visible !important;
      }
      table {
        min-width: 100% !important;
        width: 100%;
        border-collapse: collapse;
        .ant-table-thead {
          border-top: solid 2px black;
        }
        .ant-table-thead > tr > th {
          border-bottom: none !important;
          border-top: solid 1px ${theme.black} !important;
        }
        .ant-table-thead > tr > th:last-child {
          border-right: solid 1px ${theme.black} !important;
        }

        .ant-table-tbody > tr > td {
          border: 1px solid ${theme.gray3} !important;
        }
        tbody > tr:last-child {
          border: solid 2px ${theme.gray3} !important;
          border-bottom: none !important;
        }

        thead.ant-table-thead {
          tr th.ant-table-cell {
            /* width: calc(100% / 8); */
            font-size: 10px;
            font-weight: 400;
            padding: 4px;
            line-height: 125%;
            & > span,
            & > div {
              font-size: 10px;
              min-width: min-content !important;
              width: 100%;
              /* word-wrap: normal;
              white-space: normal !important;
              word-break: normal;
              word-spacing: normal;
              font-weight: 500; */
            }
          }
        }
        tbody.ant-table-tbody {
          tr.ant-table-row {
            page-break-inside: avoid !important;
            /* page-break-after: auto !important; */
            td.ant-table-cell {
              padding: 1px !important;
              font-size: 12;
              font-weight: 400;
              line-height: 110%;
              .working-cell {
                padding: 0px;
              }
              .holiday-cell {
                padding: 0 !important;
                height: 33px !important;
              }
              .yellow {
                padding: 0 !important;
                min-height: 33px !important;
              }
              & > * {
                font-size: 10px;
              }
            }
            &:last-child {
              td.ant-table-cell {
                padding: 0 !important;
                .working-cell {
                  border: 1px solid ${theme.gray3} !important;
                  border-right: none !important;
                }
              }
              td.ant-table-cell:first-child {
                .working-cell {
                  border-left: none !important;
                }
              }
            }
          }
          tr.ant-table-row.yellow-cell {
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6) {
              background-color: transparent !important;
            }
          }
        }
      }
      &:after {
        display: none;
      }
    }
  }
`
