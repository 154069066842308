import {
  afterSchoolServicesOpt,
  BODY_TEMPERATURE_OPTIONS,
  CALANDER_HOURS_MINUTES,
  Checkbox,
  Checkboxes,
  defaultAbsentNote,
  Modal,
  RadioButtons,
  SelectInput,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import TextArea from "antd/lib/input/TextArea"
import { useFormik } from "formik"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AuthContext } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"
import { japaneseAlphaRegex } from "../../../utils/validation"

const Wrapper = styled.div`
  padding: 10px 0;
  .operation-section {
    margin: 16px 0;
  }
  .operation-title {
    border-left: 4px solid ${theme.blue5};
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    padding-left: 12px;
    margin-bottom: 16px;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .operation-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .left-margin {
      @media screen and (max-width: 1366px) {
        gap: 0;

        .ant-checkbox + span {
          margin-left: 10px;
        }
      }
    }
    .label-option-container {
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .operation-label {
        width: 150px;
        margin-bottom: 10px;
      }
      .parent-operation-label {
        width: auto;
      }
      .operation-option {
        max-width: 100%;
        span {
          white-space: break-spaces;
        }
      }

      .bottom-operation-option {
        @media screen and (max-width: 1366px) {
          .bottom-field-container {
            gap: 0;
            .faculty-datepicker {
              margin-right: 10px;
            }
          }
        }
        @media screen and (max-width: 400px) {
          width: 100%;
          .bottom-field-container {
            gap: 0;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            .faculty-datepicker {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .facility-label-option-container {
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      .operation-label {
        min-width: 140px;
        max-width: 140px;
      }
      .operation-option {
        max-width: 100%;
        span {
          white-space: break-spaces;
        }
      }
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
    }
  }
  .multiple-within-multiple {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: ${theme.breakpoints.md}) {
      gap: 0;
    }
  }

  .multiple-input-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: 1366px) {
      gap: 0;

      .left-align {
        margin-right: 5px;
        margin-left: 5px;
      }
      .ant-select-single {
        margin-top: 10px;
      }
      .ant-checkbox {
        margin-top: 10px;
      }

      .reflect-btn {
        margin-right: 10px;
      }
      .reject-btn {
        margin-left: 10px;
      }
    }
  }

  .button-group {
    @media screen and (max-width: 1366px) {
      gap: 0;

      button {
        padding: 6px 16px;
        margin-bottom: 10px;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  .operation-label-nested {
    min-width: 90px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .warning-container {
    color: ${theme.red2};
  }
  .absent-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    .recorder-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    textarea {
      height: 150px;
    }
  }
`

export interface IDisplayOptions {
  facilityIds: Array<any>
  services: Array<any>
  date: string
}

interface IUserAttendanceBulkEditOperation {
  defaultDisplayOptions: IDisplayOptions
  staffList: Array<any>
  onDisplayChange: (value) => void
  onBulkReflect: (value) => void
  onReset: () => void
}

export const UserAttendanceBulkEditOperation = ({
  defaultDisplayOptions,
  staffList,
  onDisplayChange,
  onBulkReflect,
  onReset,
}: IUserAttendanceBulkEditOperation) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const facilityOptions = facilities.map((facility) => {
    return {
      label: facility?.facility_name_short,
      value: facility?.id,
    }
  })
  const serviceOptions = [
    { label: t("After school day service"), value: 1 },
    { label: t("Child development service"), value: 2 },
  ]
  const attendanceOptions = [
    {
      label: t("present_count"),
      value: 1,
    },
    {
      label: t("absent_count"),
      value: 2,
    },
    {
      label: t("Absentee (no extra charge for absenteeism)"),
      value: 3,
    },
  ]

  const displayFormik = useFormik({
    initialValues: defaultDisplayOptions,
    onSubmit: () => {
      onDisplayChange(displayFormik.values)
    },
  })

  const reflectInitialValues = {
    attendance_type: null,
    entry_hour: null,
    entry_minute: null,
    reflect_entry: null,
    out_hour: null,
    out_minute: null,
    reflect_out: null,
    body_temperature1: null,
    body_temperature2: null,
    form_of_provision: null,
    furigana_sort: null,
    absence_reason: null,
    save_staff_id: null,
  }

  const reflectFormik = useFormik({
    initialValues: reflectInitialValues,
    onSubmit: () => {
      onBulkReflect(reflectFormik.values)
    },
  })

  useEffect(() => {
    displayFormik.setValues(defaultDisplayOptions)
  }, [defaultDisplayOptions])

  return (
    <Wrapper>
      <Modal
        title={t("Do you want to overwrite the record?")}
        open={openConfirmModal}
        className={"confirm-modal"}
        onCancel={() => setOpenConfirmModal(false)}
        onOk={() => {
          reflectFormik.handleSubmit()
          setOpenConfirmModal(false)
        }}
      >
        <div className={"confirm-modal-body"}>
          <p>
            {t(
              "If there is currently recorded information, it will be overwritten.Are you sure?"
            )}
          </p>
        </div>
      </Modal>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <div className={"operation-section"}>
          <div className={"operation-title"}>{t("Display change")}</div>
          <div className={"operation-row"}>
            <div className={"facility-label-option-container"}>
              <div className={"caret-right operation-label"}>
                {t("Display facility")}
              </div>
              <div className={"operation-option"}>
                <div className={"multiple-within-multiple"}>
                  <div className={"multiple-input-container button-group"}>
                    <OwnerButton
                      typeOf={"check-all"}
                      text={t("Check All")}
                      onClick={() => {
                        const allFacilities = []
                        facilityOptions.map((f) => allFacilities.push(f.value))
                        displayFormik.setFieldValue(
                          "facilityIds",
                          allFacilities
                        )
                      }}
                    />
                    <OwnerButton
                      typeOf={"secondary"}
                      text={t("Uncheck All")}
                      onClick={() => {
                        displayFormik.setFieldValue("facilityIds", [])
                      }}
                    />
                  </div>
                  <div className={"multiple-input-container"}>
                    <Checkboxes
                      options={facilityOptions}
                      value={displayFormik.values.facilityIds}
                      onChange={(val) => {
                        displayFormik.setFieldValue("facilityIds", val)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"operation-row"}>
            <div className={"label-option-container"}>
              <div className={"caret-right operation-label"}>
                {t("Use service")}
              </div>
              <div className={"operation-option"}>
                <Checkboxes
                  options={serviceOptions}
                  value={displayFormik.values.services}
                  onChange={(val) => {
                    displayFormik.setFieldValue("services", val)
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"operation-row"}>
            <div className={"label-option-container"}>
              <div className={"caret-right operation-label"}>{t("Date")}</div>
              <div className={"operation-option bottom-operation-option"}>
                <div
                  className={"multiple-input-container bottom-field-container"}
                >
                  <DatePicker
                    date={moment(displayFormik.values.date)}
                    onDateChange={(value) => {
                      if (value)
                        displayFormik.setFieldValue(
                          "date",
                          value.format("YYYY-MM-DD")
                        )
                      else
                        displayFormik.setFieldValue(
                          "date",
                          moment().format("YYYY-MM-DD")
                        )
                    }}
                    style={{ height: "40px" }}
                    format={"YYYY年MM月DD日"}
                  />
                  <div className={"change-display-container"}>
                    <OwnerButton
                      typeOf={"secondary"}
                      text={t("Change display")}
                      onClick={() => {
                        displayFormik.handleSubmit()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"operation-section"}>
          <div className={"operation-title"}>{t("Collective Setting")}</div>
          <div className={"operation-row"}>
            <div className={"label-option-container"}>
              <div
                className={"caret-right operation-label parent-operation-label"}
              >
                {t("after day / child support")}
              </div>
              <div className={"operation-option"}>
                <div className={"operation-row"}>
                  <div className={"label-option-container"}>
                    <div className={"caret-right operation-label-nested"}>
                      {t("attendanceBulkPage")}
                    </div>
                    <div className={"operation-option"}>
                      <div className={"multiple-input-container"}>
                        <RadioButtons
                          options={attendanceOptions}
                          value={reflectFormik.values.attendance_type}
                          onChange={(e) => {
                            reflectFormik.setFieldValue(
                              "attendance_type",
                              +e.target.value
                            )
                          }}
                        />
                      </div>
                      {(reflectFormik?.values?.attendance_type == 2 ||
                        reflectFormik?.values?.attendance_type == 3) && (
                        <div className={"absent-container"}>
                          <div className={"recorder-container"}>
                            <span>
                              {reflectFormik?.values.attendance_type == 2
                                ? t("Absence")
                                : t(
                                    "Absentee (no extra charge for absenteeism)"
                                  )}
                            </span>
                            <span>{t("[Recorder]")}</span>
                            <SelectInput
                              placeholder={"--"}
                              width={50}
                              options={FuriganaAlphabetsOption}
                              value={reflectFormik?.values.furigana_sort}
                              onChange={(val) => {
                                reflectFormik.setFieldValue(
                                  "furigana_sort",
                                  val
                                )
                              }}
                            />
                            <SelectInput
                              placeholder={"--"}
                              width={150}
                              options={
                                reflectFormik?.values.furigana_sort
                                  ? staffList.filter((s) => {
                                      const regex = japaneseAlphaRegex(
                                        reflectFormik?.values.furigana_sort
                                      )
                                      if (
                                        s.furigana_name.match(regex) ||
                                        s.label.match(regex)
                                      )
                                        return true
                                      return false
                                    })
                                  : staffList
                              }
                              value={reflectFormik?.values.save_staff_id}
                              onChange={(val) => {
                                reflectFormik.setFieldValue(
                                  "save_staff_id",
                                  val
                                )
                              }}
                            />
                          </div>
                          <div className={"absent-note"}>
                            <TextArea
                              value={
                                reflectFormik?.values.absence_reason ??
                                defaultAbsentNote
                              }
                              onChange={(e) => {
                                reflectFormik.setFieldValue(
                                  "absence_reason",
                                  e.target.value
                                )
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"operation-row"}>
                  <div className={"label-option-container"}>
                    <div className={"caret-right operation-label-nested"}>
                      {t("Enter time")}
                    </div>
                    <div
                      className={"operation-option multiple-input-container"}
                    >
                      <SelectInput
                        placeholder={"--"}
                        width={60}
                        options={CALANDER_HOURS_MINUTES.hours}
                        value={reflectFormik.values.entry_hour}
                        disabled={
                          reflectFormik.values.reflect_entry ? true : false
                        }
                        onChange={(val) => {
                          reflectFormik.setFieldValue("entry_hour", val)
                          if (!reflectFormik.values.entry_minute)
                            reflectFormik.setFieldValue("entry_minute", "00")
                        }}
                        className={"left-align"}
                        padding={"0px 5px"}
                      />
                      {":"}
                      <SelectInput
                        placeholder={"--"}
                        width={60}
                        options={CALANDER_HOURS_MINUTES.minutes}
                        value={reflectFormik.values.entry_minute}
                        disabled={
                          reflectFormik.values.reflect_entry ||
                          !reflectFormik.values.entry_hour
                        }
                        onChange={(val) => {
                          reflectFormik.setFieldValue("entry_minute", val)
                        }}
                        className={"left-align"}
                        padding={"0px 5px"}
                      />
                      &nbsp;&nbsp;
                      <Checkbox
                        className={"left-margin"}
                        label={t(
                          "Reflect the desired pick-up time in the room entry time"
                        )}
                        checked={reflectFormik.values.reflect_entry}
                        onChange={(e) => {
                          const isChecked = e.target.checked
                          reflectFormik.setFieldValue(
                            "reflect_entry",
                            isChecked
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={"operation-row"}>
                  <div className={"label-option-container"}>
                    <div className={"caret-right operation-label-nested"}>
                      {t("Out time")}
                    </div>
                    <div
                      className={"operation-option multiple-input-container"}
                    >
                      <div className={"multiple-input-container"}>
                        <SelectInput
                          placeholder={"--"}
                          width={60}
                          options={CALANDER_HOURS_MINUTES.hours}
                          value={reflectFormik.values.out_hour}
                          disabled={reflectFormik.values.reflect_out}
                          onChange={(val) => {
                            reflectFormik.setFieldValue("out_hour", val)
                            if (!reflectFormik.values.out_minute)
                              reflectFormik.setFieldValue("out_minute", "00")
                          }}
                          className={"left-align"}
                          padding={"0px 5px"}
                        />
                        {":"}
                        <SelectInput
                          placeholder={"--"}
                          width={60}
                          options={CALANDER_HOURS_MINUTES.minutes}
                          value={reflectFormik.values.out_minute}
                          disabled={
                            reflectFormik.values.reflect_out ||
                            !reflectFormik.values.out_hour
                          }
                          onChange={(val) => {
                            reflectFormik.setFieldValue("out_minute", val)
                          }}
                          className={"left-align"}
                          padding={"0px 5px"}
                        />
                      </div>
                      &nbsp;&nbsp;
                      <Checkbox
                        className={"left-margin"}
                        label={t(
                          "Reflect the desired drop-off time in the leaving time"
                        )}
                        checked={reflectFormik.values.reflect_out}
                        onChange={(e) => {
                          const isChecked = e.target.checked
                          reflectFormik.setFieldValue("reflect_out", isChecked)
                        }}
                      />
                      &nbsp;&nbsp;
                      <div className={"multiple-input-container"}>
                        <span>{t("Body temperature upon entry")}</span>
                        <SelectInput
                          placeholder={"--"}
                          width={60}
                          options={BODY_TEMPERATURE_OPTIONS(31, 42)}
                          value={reflectFormik.values.body_temperature1}
                          onChange={(val) => {
                            reflectFormik.setFieldValue(
                              "body_temperature1",
                              val
                            )
                            if (!reflectFormik.values.body_temperature2) {
                              reflectFormik.setFieldValue(
                                "body_temperature2",
                                "0"
                              )
                            }
                          }}
                          className={"left-align"}
                          padding={"0px 5px"}
                        />
                        {"."}
                        <SelectInput
                          placeholder={"--"}
                          width={60}
                          options={BODY_TEMPERATURE_OPTIONS(0, 9)}
                          disabled={!reflectFormik.values.body_temperature1}
                          value={reflectFormik.values.body_temperature2}
                          onChange={(val) => {
                            reflectFormik.setFieldValue(
                              "body_temperature2",
                              val
                            )
                          }}
                          className={"left-align"}
                          padding={"0px 5px"}
                        />
                        &deg;{"C"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"operation-row"}>
                  <div className={"label-option-container"}>
                    <div className={"caret-right operation-label-nested"}>
                      {t("Form of provision (After day)")}
                    </div>
                    <div className={"operation-option"}>
                      <SelectInput
                        placeholder={"--"}
                        width={160}
                        options={afterSchoolServicesOpt}
                        value={reflectFormik.values.form_of_provision}
                        onChange={(val) => {
                          reflectFormik.setFieldValue("form_of_provision", val)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"multiple-input-container"}>
            <OwnerButton
              typeOf={"primary"}
              text={t("Reflect")}
              onClick={() => {
                setOpenConfirmModal(true)
              }}
              className={"reflect-btn"}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Reset")}
              className={"reset-btn"}
              onClick={() => {
                onReset()
              }}
            />
            <div className={"warning-container"}>
              {t(
                '※If there is a child whose record you do not want to overwrite, remove the check and press "Reflect".'
              )}
            </div>
          </div>
        </div>
      </TopRoundedWhiteCard>
    </Wrapper>
  )
}
