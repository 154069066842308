import { useState } from "react"
import { useRouter } from "next/router"

// Packages
import { t } from "i18next"
import moment from "moment"
import { notification } from "antd"
import { useMutation, useQuery } from "react-query"

// Styled components
import { ActualExpensesInfoTableContainer } from "../styles"

// Components
import { RegisterUserModel } from "."
import { OwnerButton } from "../../../atoms"
import { OwnerTable } from "../../../molecules"
import { DeleteConfimationModal, TopRoundedWhiteCard } from "@project/shared"

// hooks
import useTable from "../hooks/useTable"

// types
import { ActualExpensesTableBlockProps } from "../types"

// services
import {
  addActualCostUser,
  deleteActualCostUser,
  updateOneActualCostUser,
} from "../../../../services/actualCost"
import { getActualCostBurdenUserRecord } from "../../../../services/actualCostBurdenRecord"

// utils
import { japaneseAlphaRegex } from "../../../../utils/validation"
import { removeDuplicates } from "../../../../utils/utilityFunction"

const ActualExpensesTableBlock: React.FC<ActualExpensesTableBlockProps> = ({
  dataSource,
  currentDate,
  currentFacility,
  refetch,
  actualItemDefaultCost = 0,
}) => {
  const router = useRouter()
  const { id, facilityId, year, month, day } = router.query

  const dateString = moment(currentDate).format("YYYY-MM-DD")
  const actualCostId = router.query.id as string

  const [isAddNewUserModalVisible, setIsAddNewUserModalVisible] =
    useState(false)
  const [filteredChildren, setFilteredChildren] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [userId, setUserId] = useState<any>({})

  const { mutate: deleteUser } = useMutation(deleteActualCostUser, {
    onSuccess: () => {
      notification.success({
        message: t("User") + t("Deleted Successfully"),
      })
      setOpenDeleteModal(false)
      refetch()
      setIsAddNewUserModalVisible(false)

      refetchChildren()
      setUserId({})
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const { columns } = useTable(
    setOpenDeleteModal,
    setUserId,
    setIsAddNewUserModalVisible
  )

  const { mutate: mutateActualCostUser } = useMutation(
    userId?.id ? updateOneActualCostUser : addActualCostUser,
    {
      onSuccess: () => {
        if (userId?.id) {
          notification.success({
            message: t("User") + t("Updated Successfully"),
          })
        } else {
          notification.success({
            message: t("User") + t("Created Successfully"),
          })
        }
        setIsAddNewUserModalVisible(false)
        refetch()
        refetchChildren()
        setUserId({})
      },
      onError: (error?: any) => {
        const msg =
          error?.status == 403
            ? "User doesn't have permission to edit this page"
            : error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const {
    data: facilityChildren,
    refetch: refetchChildren,
    isLoading: childrenLoding,
  } = useQuery<any, Error>(
    [
      "get-non-joint-facility-children",
      getActualCostBurdenUserRecord,
      {
        facility_id: facilityId,
      },
    ],
    () =>
      actualCostId &&
      getActualCostBurdenUserRecord({
        facility_id: facilityId,
        actual_cost_id: actualCostId,
        child_active_date: dateString,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
    }
  )

  // function to handle add new user
  const handleAddNewUser = (values) => {
    mutateActualCostUser({ ...values, facility_id: +facilityId })
    refetchChildren()
  }

  const NON_FACILITY_CHILD =
    !childrenLoding &&
    facilityChildren?.data &&
    removeDuplicates(facilityChildren?.data)?.map((item: any) => {
      return {
        value: item.id,
        label: item.child_name,
        furigana: item.child_name_furigana,
        date: dateString,
      }
    })

  const handleSort = (value: any) => {
    if (value == 0) {
      setFilteredChildren(NON_FACILITY_CHILD)
      return
    }
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)
    const filteredChildren =
      value == 0
        ? NON_FACILITY_CHILD
        : NON_FACILITY_CHILD?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )

    setFilteredChildren(filteredChildren)
  }

  return (
    <ActualExpensesInfoTableContainer>
      <TopRoundedWhiteCard
        className={"white-card"}
        title={t("{{facilityName}} {{engDate}}’s List of actual expenses", {
          facilityName: currentFacility?.label,
          engDate: moment({
            year: +year,
            month: +month - 1,
            day: +day.slice(0, 2),
          }).format("YYYY年MM月DD日"),
          jaDate: moment({
            year: +year,
            month: +month - 1,
            day: +day.slice(0, 2),
          }).format("YYYY年MM月DD日"),
        })}
      >
        <div className={"expense-info-table-content"}>
          {/* Add user and bulk edit button container starts here */}
          <div className={"button-group flexbox"}>
            <OwnerButton
              text={t("Add user")}
              typeOf={"primary"}
              onClick={() => {
                setUserId({})
                setIsAddNewUserModalVisible(true)
              }}
            />
            <OwnerButton
              text={t("Bulk edit")}
              typeOf={"secondary"}
              onClick={() => {
                router.push(
                  `/actual-cost-burden/${id}/bulk-edit?year=${year}&month=${month}&day=${day}&facilityId=${currentFacility?.value}`
                )
              }}
            />
          </div>
          {/* Add user and bulk edit button container ends here */}

          {/* Table section starts here */}
          <OwnerTable
            columns={columns}
            dataSource={dataSource}
            scroll={{
              x: "max-content",
            }}
          />
          {/* Table section ends here */}

          <OwnerButton
            text={t("Back to index")}
            className={"back-btn"}
            typeOf={"secondary"}
            onClick={() => {
              router.push(
                `/actual-cost-burden/?year=${year}&month=${month}&day=${day}&facilityId=${currentFacility?.value}`
              )
            }}
          />
        </div>
      </TopRoundedWhiteCard>
      <RegisterUserModel
        isVisible={isAddNewUserModalVisible}
        onCancel={() => {
          setIsAddNewUserModalVisible(false)
        }}
        onSave={handleAddNewUser}
        handleSort={handleSort}
        childOptions={filteredChildren || NON_FACILITY_CHILD}
        burdenRecordId={actualCostId}
        selectedUser={userId}
        actualItemDefaultCost={actualItemDefaultCost}
      />
      <DeleteConfimationModal
        open={openDeleteModal}
        title={t("Delete")}
        footer={null}
        onCancel={() => {
          setUserId(null)
          setOpenDeleteModal(false)
        }}
        onOk={() => {
          deleteUser(userId.id)
          setUserId(null)
        }}
      />
    </ActualExpensesInfoTableContainer>
  )
}

export { ActualExpensesTableBlock }
