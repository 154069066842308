import styled, { css } from "styled-components"
import { theme } from "@project/shared"
import { Card } from "antd"

export const PageTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  div {
    padding: 0;
    margin: 0;
  }
  span {
    font-size: 14px;
    color: ${theme.alert};
  }
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    gap: 1rem;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  background: ${theme.base};
  border-radius: 12px;
  .btns {
    display: flex;
    margin: 0px 30px;
    gap: 20px;
    padding: 0px !important;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      button {
        padding: 12px 15px;
      }
    }
  }
  .instructors-button-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 0 0 26px 0;
    @media (max-width: ${theme.breakpoints.sm}) {
      justify-content: center;
      gap: 0px;
      button:first-child {
        margin-right: 20px;
      }
      .delete-instructors {
        margin-top: 20px;
      }
    }
  }
`
export const CustomCardContainer = styled(Card)`
  border-radius: 12px;
  border: 0;
  background: red !important;
  .ant-card-head {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #d2d1d1;
    margin: 0 30px;
    padding: 5px 0;
  }
  .ant-card-body {
    padding: 15px 24px;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 15px 0px !important;
    }
  }
`
const CommonStyle = css`
  button {
    margin-bottom: 10px;
  }
`
export const QualificationContainer = styled.div`
  ${CommonStyle}
`
export const CertificateContainer = styled.div`
  ${CommonStyle}
`
export const FacilityOccupationContainer = styled.div`
  ${CommonStyle}
  .msg_content {
    display: flex;
    gap: 0.5rem;
    .msg {
      color: ${theme.red2};
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
    @media (max-width: ${theme.breakpoints?.md}) {
      flex-direction: column;
    }
  }
`
