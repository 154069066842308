import React, { ReactNode } from "react"
import {
  Table as SharedTable,
  Pagination as SharedPagination,
  theme,
} from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { PrintBulkDataComponent } from "../../organisms"
import { OwnerButton } from "../../atoms"
import { ProgressComponent } from "../ProgressContainer"
const Icon = <img src={"/assets/icons/ico.svg"} alt={"icon"} />

interface ITablecomponentProps {
  className?: any
  dataSource: any
  columns: any
  total?: number
  showRegister?: boolean
  pageSize?: number
  currentPage?: number
  handlePaginationChange?: (a: number) => void
  loading?: boolean
  scroll?: { x?: string | number | true } | Record<string, never>
  bordered?: boolean
  displayInfo?: boolean
  displayInfoNumbers?: number
  displayPrintButton?: boolean
  buttonText?: string
  additionalButton?: boolean
  onClickAdditionalButton?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  displayText?: string
  displayText2?: string
  furiganaSelect?: string
  setFurignaValue?: (string) => void
  itemListFiltered?: any
  itemValue?: any
  setItemSelect?: (string) => void
  fetchTableData?: any
  showCollectionRate?: boolean
  rowClassName?: any
  answeredCount?: number
  bulkPrint?: boolean
  extra?: ReactNode
  handleClickPrint?: () => void
  topComponent?: ReactNode
  displayProgressContainer?: boolean
  progressData?: {
    onFilter?: (string) => void
  }
  setDisplayCareChild?: any
  setQueryParams?: any
  components?: any
  queryParams?: any
  rowSelection?: any
  showHeader?: boolean
}

const Wrapper = styled.div<ITablecomponentProps>`
  .ant-table table {
    border-top: 1px solid ${theme.black} !important;
    border-left: 1px solid ${theme.black} !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${theme.black} !important;
    background-color: #f4f4f4 !important;
    @media print {
      border-bottom: none !important;
    }
  }

  .ant-table-thead > tr > td {
    border-bottom: 1px solid ${theme.black} !important;
    background-color: #f4f4f4 !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: center;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid ${theme.black} !important;
  }

  .ant-table-row .ant-table-row-level-0 {
    border: 1px solid red !important;
  }

  .ant-table-tbody {
    border-top: none !important;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-bottom: 1px solid ${theme.black} !important;
    text-align: center;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none !important;
  }

  .total_count {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: flex-start;
      p {
        padding: 0;
        margin: 0;
      }
      .pagination-btns {
        display: flex;
        justify-content: flex-end !important;
        width: 100%;
      }
    }

    @media screen and (max-width: 426px) {
      .count_data {
        margin-left: auto;
      }
    }
  }

  .main-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: ${(props) => (props.displayInfo ? "flex-start" : "center")};
    padding: 0px 5px;
    margin-top: 10px;

    @media (max-width: 767px) {
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin-top: -2px;
    }
  }
  .bottom-pagination-wrapper {
    @media screen and (min-width: 500px) {
      display: flex;
      justify-content: space-between;
    }
    margin-top: 10px;

    .total_count {
      @media screen and (max-width: 426px) {
        p {
          width: 100%;
          text-align: end;
        }
      }
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    @media (min-width: 1200px) {
      margin-top: -30px;
    }
  }
  .add-btn-wrap {
    margin-bottom: 20px;
  }

  .stat-label {
    position: relative;
    padding: 8px 2px 8px 8px;
    background: #cde6f4;
    margin-right: 20px;
  }
  .stat-label::after {
    position: absolute;
    top: 0;
    right: -20px;
    content: "";
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid #cde6f4;
    height: 100%;
    width: 20px;
  }

  @media print {
    .main-pagination-wrapper {
      display: none;
    }

    .ant-table-container {
      border-color: ${theme.black} !important;
      .ant-table-content table {
        border-color: ${theme.black} !important;
        border-left: none !important;
      }
    }

    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table {
      thead tr {
        th.ant-table-cell {
          border: 1px solid ${theme.black} !important;
        }

        &:first-child th:last-child {
          border-right: 2px solid ${theme.black} !important;
        }
      }
      tbody > tr {
        td.ant-table-cell {
          border: 1px solid ${theme.black} !important;

          &:last-child {
            border-right: 2px solid ${theme.black} !important;
          }
        }
        td:last-child {
          border-right: 2px solid ${theme.black} !important;
        }

        &:first-child {
          display: none;
        }

        &:last-child {
          td.ant-table-cell {
            border-bottom: 2px solid ${theme.black} !important;
            .ant-table-expanded-row-fixed {
              border: none !important;
            }
          }
        }
      }
    }
  }
`

const FilterWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  color: #191919;
  display: flex;
  margin-bottom: 14px;
  @media (max-width: 572px) {
    flex-direction: column;
  }

  .last-item {
    margin-left: 15px;
  }

  .filled-check-box-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 10px;

    .filled-check-box {
      width: 25px;
      height: 25px;
      background: rgba(202, 48, 48, 0.12);
      border-radius: 5px;
    }
  }
`
export const PrintButtonWrapper = styled.div<{ isPagination?: boolean }>`
  margin-top: ${({ isPagination }) => (isPagination ? "-20px" : "10px")};
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-top: ${({ isPagination }) => (isPagination ? "0" : "10px")};
  }
  @media print {
    display: none;
  }
`

const ServiceTablecomponent: React.FC<ITablecomponentProps> = (props) => {
  const { t } = useTranslation()

  const {
    total,
    extra,
    scroll,
    columns,
    loading,
    pageSize,
    bordered,
    className,
    bulkPrint,
    itemValue,
    components,
    dataSource,
    buttonText,
    currentPage,
    displayInfo,
    displayText,
    showRegister,
    displayText2,
    topComponent,
    progressData,
    rowSelection,
    setItemSelect,
    answeredCount,
    furiganaSelect,
    fetchTableData,
    setQueryParams,
    setFurignaValue,
    additionalButton,
    itemListFiltered,
    handleClickPrint,
    rowClassName = "",
    displayInfoNumbers,
    displayPrintButton,
    showCollectionRate,
    setDisplayCareChild,
    handlePaginationChange,
    onClickAdditionalButton,
    displayProgressContainer,
    queryParams,
    showHeader,
  } = props
  return (
    <Wrapper className={className} {...props}>
      {displayProgressContainer && <ProgressComponent {...progressData} />}
      <>
        <div className={"total_count no-print"}>
          <div className={"count_data"}>
            {showCollectionRate && total > 0 ? (
              <div className={"stats"}>
                <span className={"stat-label"}>
                  {t("児発 回収数（回収率）")}
                </span>
                <span>{`${answeredCount}/${total} (${
                  (answeredCount * 100) / total
                }%)`}</span>
              </div>
            ) : total && currentPage && pageSize ? (
              <div className={"flex-end"}>
                <p>{t("Total {{total}} datas", { total })}</p>
              </div>
            ) : null}
          </div>
          {total && currentPage && pageSize ? (
            <div className={"pagination-btns"}>
              <SharedPagination
                total={total}
                current={currentPage}
                pageSize={pageSize}
                onChange={handlePaginationChange}
              />
            </div>
          ) : null}
        </div>
        {showRegister && (
          <div className={"flex-row"}>
            <div className={"pink label"}></div>
            {t("Children who do not have registered recipient certificate")}
          </div>
        )}
        <div className={"main-pagination-wrapper"}>
          <div className={"info-wrapper"}>
            {extra ? extra : ""}
            {displayInfo && (
              <FilterWrapper>
                {displayText2 ? (
                  <div className={"filled-check-box-wrapper"}>
                    <div className={"filled-check-box"}></div>
                    <span> {t(displayText2)}</span>
                  </div>
                ) : null}
                <span>
                  {Icon}
                  &nbsp;
                  {t(displayText)}
                </span>
                {displayInfoNumbers > 1 && (
                  <span className={"last-item"}>
                    {t("Children without full facility contracts")}
                  </span>
                )}
              </FilterWrapper>
            )}
          </div>
        </div>
        {displayPrintButton && (
          <PrintBulkDataComponent
            fetchTableData={fetchTableData ? fetchTableData : undefined}
            furiganaSelect={furiganaSelect ? furiganaSelect : undefined}
            setFurignaValue={setFurignaValue ? setFurignaValue : undefined}
            itemListFiltered={itemListFiltered ? itemListFiltered : undefined}
            itemValue={itemValue ? itemValue : undefined}
            setItemSelect={setItemSelect ? setItemSelect : undefined}
            setQueryParams={setQueryParams}
            setDisplayCareChild={setDisplayCareChild}
            queryParams={queryParams}
          />
        )}
      </>

      {bulkPrint ? (
        <PrintButtonWrapper
          isPagination={!!total && !!currentPage && !!pageSize}
        >
          <OwnerButton
            text={t("Bulk print")}
            icon={"print"}
            onClick={() => handleClickPrint()}
          />
        </PrintButtonWrapper>
      ) : null}
      <div>
        {additionalButton && (
          <div className={"add-btn-wrap"}>
            <OwnerButton
              typeOf={"primary"}
              text={
                buttonText ||
                t("Service provision record/Care record/Life record")
              }
              onClick={onClickAdditionalButton}
            />
          </div>
        )}
        {!!topComponent && topComponent}
        <SharedTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={scroll || {}}
          bordered={bordered ? true : false}
          rowClassName={rowClassName}
          components={components || {}}
          rowSelection={rowSelection}
          showHeader={showHeader}
        />
      </div>
      {total && currentPage && pageSize ? (
        <div className={" bottom-pagination-wrapper no-print"}>
          <div className={"total_count"}>
            <p>{t("Total {{total}} datas", { total })}</p>
          </div>
          <div className={"pagination-class"}>
            <SharedPagination
              total={total}
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </Wrapper>
  )
}

export { ServiceTablecomponent }
