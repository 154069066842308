import { t } from "i18next"
export const SUPPORT_PLAN_DISPLAY_CONTENT = [
  {
    label: t("consent date"),
    value: "consent_date",
  },
  {
    label: t("Parent's sign field"),
    value: "parent_sign",
  },
  {
    label: t("Agreement text"),
    value: "agreement",
  },
  {
    label: t("Child sign field"),
    value: "child_sign",
  },
  {
    label: t("PIC of child development support management consent"),
    value: "pic",
  },
  {
    label: t("Parents seal"),
    value: "parent_seal",
  },
  {
    label: t("Child development support manager seal"),
    value: "manager_seal",
  },
  {
    label: t("Child development support manager signature column"),
    value: "manager_sign",
  },
]

export const SUPPORT_PLAN_CONSENT_DATE = [
  {
    label: t("Separating the explanation date from the consent date"),
    value: "separate_date",
  },
  {
    label: t("Display and fill in digital signature"),
    value: "date_signature",
  },
]

export const SUPPORT_PLAN_PARENT_FIELD = [
  {
    label: t(
      "Change 'Name of Guardian' to 'Name of Guardian and Family Representative'"
    ),
    value: "change_representative",
  },
]
