/**
 * NOTE:
 * - R6 April [established:新設]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"

type Params = {
  name: string
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getBusinessContinuityPlanNotImplementedSubtraction(
  date: Moment,
  params: Params
) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return null
    default:
      return r6April(params.name)
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

function r6April(name: string): FormFieldsProp {
  return {
    // TODO: translate
    fieldLabel: "業務継続計画未策定減算",
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: [
              {
                label: t("No"),
                value: "0",
              },
              {
                label: t("Yes"),
                value: "1",
              },
            ],
          },
        },
      },
    ],
  }
}

/**
 * --------------------------------
 * @version BASELINE
 *
 * NONE
 * --------------------------------
 */
