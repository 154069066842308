import styled from "styled-components"
import { theme } from "@project/shared"
import { Form } from "formik"

export const PriceInputContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  & .requried-length {
    font-size: 14px;
  }
  & .info {
    font-size: 14px;
    color: ${theme.alert};
  }
`

export const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  .title {
    border-top: 1px solid #d2d1d1 !important;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .billing-facility {
      width: 100%;
    }
  }
  & .label_tag {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444748;
    margin-top: 3px;
  }
  .cost-item-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: center;
    }

    .cost-item-button-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      button {
        :first-child {
          margin-right: 10px;
        }
      }
    }
    .delete-button {
      background: ${theme.red2};
      border-color: ${theme.red2};
    }
  }
  .billing-facility-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .select_facility {
      padding: 10px 18px;
      width: 100%;
    }
    .bill-facility-select {
      padding: 10px 18px;
      display: flex;
      gap: 0.4rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      &-input {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
      }
      .cta_btns {
        display: flex;
        justify-content: flex-end;
        gap: 0.2rem;
        button {
          padding: 5px;
          width: fit-content;
        }
      }
    }
  }

  .ant-checkbox-group {
    margin-top: 3px;
  }
  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
`
