import { maskName } from "../../../../common/utils"
import { NameProps } from "./types"

export function ChildName({ name, configuration = [] }: NameProps) {
  return (
    <div className={"child_info_container"}>
      <div className={"child_value"}>{maskName(name, configuration)}</div>
    </div>
  )
}
