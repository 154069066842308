import React from "react"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import { IndividualSupportPlanReponse } from "../../../services"
import { OwnerTable } from "../../molecules"
import styled from "styled-components"
import { theme } from "@project/shared"

export const TableWrapper = styled.div`
  .width-min {
    min-width: 150px;
  }
  .width-min-100 {
    min-width: 100px;
  }
  .min-width-class {
    min-width: 156px;
  }
  .no-certificate-row {
    background-color: ${theme.lightPink} !important;
  }
`
interface IProps {
  loading: boolean
  columns: ColumnsType<IndividualSupportPlanReponse>
  dataSource: IndividualSupportPlanReponse[] | []
  currentPage: number
  total: number | undefined
  pageSize: number
  handlePaginationChange: (val: number) => void
}

export const IndividualSupportPlansList: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <TableWrapper>
      <OwnerTable
        {...props}
        scroll={{ x: "max-content" }}
        bordered
        className={"always-present-scrollbar"}
        extra={
          <div className={"table__top__extra"}>
            <div className={"certi"}>
              {t("Children who do not have a registered recipient certificate")}
            </div>
            <div className={"sign"}>
              <img src={"/assets/icons/ico.svg"} alt={"icon"} />
              <span> {t("Digitally signed by a parent")}</span>
            </div>
          </div>
        }
        rowClassName={(row) => !row?.CertificateId && "no-certificate-row"}
      />
    </TableWrapper>
  )
}
