import { Skeleton } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { Wrapper } from "./ApplicationLegend.styles"

interface iApplicationLegend {
  applicationCount: number
  contractCount: number
  facilityName: string
  absentDates?: string
  isLoading?: boolean
  overflow?: string
  absentCount?: number
}

const ApplicationLegend = ({
  applicationCount = 0,
  contractCount = 0,
  facilityName,
  absentDates,
  isLoading,
  overflow,
  absentCount = 0,
}: iApplicationLegend) => {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <Wrapper className={"application-legend"}>
        <Skeleton.Input active block />
      </Wrapper>
    )
  }
  return (
    <Wrapper className={"application-legend"} overflow={overflow}>
      <div className={"poly-wrapper"}>
        <div className={"poly"}>
          <div className={"blue box1"}>
            {"現在の申込み数"}
            {/* use direct translation because of responsiveness issues (menu open/close) */}
          </div>
          <div className={"blue polygon polygon1"}></div>
        </div>
        <div className={"count-wrapper"}>{applicationCount}</div>
      </div>
      <div className={"poly-wrapper"}>
        <div className={"poly"}>
          <div className={"blue box1"}>{"契約支給量"}</div>
          <div className={"blue polygon polygon1"}></div>
        </div>
        <div className={"count-wrapper"}>{contractCount}</div>
      </div>
      <div className={"poly-wrapper"}>
        <div className={"yellow-box box2"}>{facilityName}</div>
        <div className={""}>
          {`${applicationCount}/${contractCount}`}&nbsp;
          {absentDates ? (
            <small>{`(${t("Absence")} : ${absentDates})`}</small>
          ) : (
            ""
          )}
          <br />
          <small>{`申込み残日数：${
            contractCount - applicationCount + absentCount < 0
              ? 0
              : contractCount - applicationCount + absentCount
          }`}</small>
        </div>
      </div>
    </Wrapper>
  )
}

export default ApplicationLegend
