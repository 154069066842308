import { Table } from "@project/shared"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"
import { t } from "i18next"
import { DefaultProps } from "./types"
import { Content, makeEmptyCell, makeEmptyRowData } from "./utils"
import {
  ReceiptSiblingUpperLimitManagementItem,
  ReceiptUpperLimitManagementItem,
} from "../../../../types"

/**
 * ============================
 * HEAD
 * ============================
 */

enum HeadColumnKey {
  GENERAL = 0,
  LOCAL = 7,
}

const LEFT_HEAD_COLUMN = {
  title: "",
  className: "hiroshima-col",
  render: (_, __, index) =>
    index < 9
      ? t("User burden amount aggregation / adjustment column_")
      : t("Hiroshima City subsidy upper limit management column"),
  width: 20,
  onCell: (_, index) => {
    if (index === HeadColumnKey.GENERAL) {
      return { rowSpan: 7 }
    }
    if (index === HeadColumnKey.LOCAL) {
      return { rowSpan: 4 }
    }
    return { rowSpan: 0 }
  },
}

/**
 * ============================
 * BODY & TITLE (dataSources)
 * ============================
 */
enum ColumnKey {
  LINE_NUMBER,
  FACILITY_NUMBER,
  FACILITY_NAME,
  TOTAL_AMOUNT,
  BURDEN_AMOUNT,
  MANAGEMENT_RESULT_BURDEN_AMOUNT,
  MANAGEMENT_RESULT_INSURANCE_AMOUNT,
  LOCAL_BURDEN_AMOUNT,
  LOCAL_EXEMPTION,
  LOCAL_MANAGEMENT_ADD,
  LOCAL_TOTAL,
}

const COLUMNS = [
  {
    key: ColumnKey.LINE_NUMBER,
    title: t("Item number"),
  },

  {
    key: ColumnKey.FACILITY_NUMBER,
    title: t("Business no."),
  },
  {
    key: ColumnKey.FACILITY_NAME,
    title: t("Business official name"),
  },
  {
    key: ColumnKey.TOTAL_AMOUNT,
    // TODO: translate
    title: "総費用額",
  },
  {
    key: ColumnKey.BURDEN_AMOUNT,
    title: t("User fee"),
  },
  {
    key: ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT,
    title: t("Management results"),
    title2: t("User burden amount (a)"),
  },
  {
    key: ColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT,
    title: "",
    title2: t("Nursing care benefits, etc."),
  },
  {
    key: ColumnKey.LOCAL_BURDEN_AMOUNT,
    title: t("Management results"),
    title2: t("User burden (a)"),
  },
  {
    key: ColumnKey.LOCAL_EXEMPTION,
    title: t("Hiroshima City subsidy amount"),
    title2: t("Grant amount (A) - (B)"),
  },
  {
    key: ColumnKey.LOCAL_MANAGEMENT_ADD,
    title2: (
      <>
        {t("Upper limit management additional amount")}
        <br />
        {t("(Note)")}
      </>
    ),
  },
  {
    key: ColumnKey.LOCAL_TOTAL,
    title2: t("total"),
  },
]

const TITLE1_COLUMN = {
  title: "",
  dataIndex: "title",
  align: "center",
  className: "hiroshima-col",
  width: 200,
  onCell: (_, index) => {
    // 利用者負担額集計・調整欄 上5行
    if (index < ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
      return { colSpan: 2 }
    }
    // 利用者負担額集計・調整欄 介護給付費等（行結合のため出力不要）
    if (index === ColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
      return { rowSpan: 0 }
    }
    // 利用者負担額集計・調整欄 管理結果
    if (index === ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
      return { rowSpan: 2 }
    }
    // 広島市助成上限額管理欄 広島市助成額
    if (index == ColumnKey.LOCAL_EXEMPTION) {
      return { rowSpan: 3 }
    }
    // 利用者負担額集計・調整欄 介護給付費等（行結合のため出力不要）
    if (index === ColumnKey.LOCAL_MANAGEMENT_ADD) {
      return { rowSpan: 0 }
    }
    // 利用者負担額集計・調整欄 広島市助成額 計（行結合のため出力不要）
    if (index === ColumnKey.LOCAL_TOTAL) {
      return { rowSpan: 0 }
    }
    return {}
  },
}

const TITLE2_COLUMN = {
  title: "",
  dataIndex: "title2",
  align: "center",
  width: 200,
  className: "hiroshima-col",
  onCell: (_, index) => {
    // 利用者負担額集計・調整欄 上5行
    if (index < ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
      return { colSpan: 0 }
    }
    return {}
  },
}

function makeColumn(
  row: ReceiptUpperLimitManagementItem | ReceiptSiblingUpperLimitManagementItem,
  index: number
) {
  if (index === ColumnKey.LINE_NUMBER) {
    return row.line_no
  }
  if (index === ColumnKey.FACILITY_NUMBER) {
    return row.facility_number
  }
  if (index === ColumnKey.FACILITY_NAME) {
    return row.facility_name
  }
  if (index === ColumnKey.TOTAL_AMOUNT) {
    return <Content data={String(row.total_amount).padStart(6, ".")} />
  }
  if (index === ColumnKey.BURDEN_AMOUNT) {
    return <Content data={String(row.burden_amount).padStart(6, ".")} />
  }
  if (index === ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_burden_amount).padStart(6, ".")}
      />
    )
  }
  if (index === ColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_insurance_amount).padStart(6, ".")}
      />
    )
  }
  if (index === ColumnKey.LOCAL_BURDEN_AMOUNT) {
    return <Content data={String(row.local_burden_amount).padStart(6, ".")} />
  }
  // NOTE: 広島市助成額 の 上限管理算定は算定しない
  if (index === ColumnKey.LOCAL_MANAGEMENT_ADD) {
    return row.self_check === 1 ? (
      <Content data={"0".padStart(6, ".")} />
    ) : (
      <Content data={"".padStart(6, ".")} style={{ backgroundColor: "#888" }} />
    )
  }
  if (index === ColumnKey.LOCAL_EXEMPTION || index === ColumnKey.LOCAL_TOTAL) {
    return <Content data={String(row.local_exemption).padStart(6, ".")} />
  }
  return makeEmptyCell(6)
}

function generateColumns(
  facilities:
    | ReceiptUpperLimitManagementItem[]
    | ReceiptSiblingUpperLimitManagementItem[]
) {
  const emptyLength = 5 - facilities.length
  if (emptyLength > 0) {
    facilities = [...facilities, ...Array(emptyLength).fill(makeEmptyRowData())]
  }

  return facilities.map((item) => ({
    align: "center",
    className: "hiroshima-col",
    render: (_, __, index) => makeColumn(item, index),
  }))
}

/**
 * ============================
 * TOTAL
 * ============================
 */

enum TotalColumnKey {
  LABEL = 0,
  TOTAL_AMOUNT = 3,
  TOTAL_BURDEN_AMOUNT = 4,
  MANAGEMENT_RESULT_BURDEN_AMOUNT = 5,
  MANAGEMENT_RESULT_INSURANCE_AMOUNT = 6,
  TOTAL_LOCAL_EXEMPTION = 7,
  LOCAL_BURDEN_AMOUNT = 8,
  LOCAL_MANAGEMENT_ADD = 9,
  LOCAL_TOTAL = 10,
}

function generateTotalColumn(
  totalAmount: number,
  totalBurdenAmount: number,
  managementResultBurdenAmount: number,
  managementResultInsuranceAmount: number,
  totalLocalExemption: number,
  localBurdenAmount: number
) {
  return {
    title: t("Total"),
    align: "center",
    width: 180,
    className: "hiroshima-col",
    onCell: (_, index) => {
      if (index === TotalColumnKey.LABEL) {
        return { rowSpan: 3 }
      }
      if (index < TotalColumnKey.TOTAL_AMOUNT) {
        return { rowSpan: 0 }
      }
      return {}
    },
    render: (_, __, index) => {
      if (index === TotalColumnKey.LABEL) {
        return t("Total")
      }
      if (index === TotalColumnKey.TOTAL_AMOUNT) {
        return <Content data={String(totalAmount).padStart(6, ".")} />
      }
      if (index === TotalColumnKey.TOTAL_BURDEN_AMOUNT) {
        return <Content data={String(totalBurdenAmount).padStart(6, ".")} />
      }
      if (index === TotalColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
        return (
          <Content
            data={String(managementResultBurdenAmount).padStart(6, ".")}
          />
        )
      }
      if (index === TotalColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
        return (
          <Content
            data={String(managementResultInsuranceAmount).padStart(6, ".")}
          />
        )
      }
      if (
        index === TotalColumnKey.TOTAL_LOCAL_EXEMPTION ||
        index === TotalColumnKey.LOCAL_TOTAL
      ) {
        return <Content data={String(totalLocalExemption).padStart(6, ".")} />
      }
      if (index === TotalColumnKey.LOCAL_BURDEN_AMOUNT) {
        return <Content data={String(localBurdenAmount).padStart(6, ".")} />
      }
      if (index === TotalColumnKey.LOCAL_MANAGEMENT_ADD) {
        return <Content data={"0".padStart(6, ".")} />
      }
      return makeEmptyCell(6)
    },
  }
}

type Props = DefaultProps & {
  managementResultInsuranceAmount: number
  totalLocalExemption: number
  localBurdenAmount: number
}

export function HiroshimaFormat({
  facilities,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  managementResultInsuranceAmount,
  totalLocalExemption,
  localBurdenAmount,
}: Props) {
  return (
    <>
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              LEFT_HEAD_COLUMN,
              TITLE1_COLUMN,
              TITLE2_COLUMN,
              ...generateColumns(facilities),
              generateTotalColumn(
                totalAmount,
                totalBurdenAmount,
                managementResultBurdenAmount,
                managementResultInsuranceAmount,
                totalLocalExemption,
                localBurdenAmount
              ),
            ]}
            pagination={false}
            dataSource={COLUMNS}
          />
        </div>
      </NBLManagementCompanyTop>
      <span style={{ fontSize: "10pt" }}>
        {t(
          "(Note) 150 units are calculated only when calculating the upper limit management addition as Hiroshima City subsidy."
        )}
      </span>
    </>
  )
}
