import { Table } from "@project/shared"
import { t } from "i18next"
import { CityNumber } from "./common/CityNumber"
import { SiblingChild } from "../../../../../types"

const CIRCLE_NUMBERS = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
}

enum FirstLabel {
  CITY_NUMBER_INDEX,
  PARENT_NAME_INDEX,
  CHILD_NAMES_INDEX,
}

enum SecondLabel {
  RECEIVING_CERTIFICATE_NUMBER_INDEX = 2,
}

enum ReceovomgCertificateNumber {
  START_INDEX = 3,
}

enum FirstValue {
  CITY_NUMBER_INDEX,
  PARENT_NAME_INDEX,
  RECEIVING_CERTIFICATE_NUMBER_INDEX,
  CHILD_START_INDEX,
}

type Props = {
  municipalityNumber: string
  parentName: string
  siblingChildren: SiblingChild[]
}

export function KobeChildDetail({
  municipalityNumber,
  parentName,
  siblingChildren,
}: Props) {
  return (
    <Table
      columns={[
        {
          dataIndex: "label",
          width: "24px",
          render: (v) => <p>{v}</p>,
          onCell: (_, index: number) => {
            if (
              index === FirstLabel.CITY_NUMBER_INDEX ||
              index === FirstLabel.PARENT_NAME_INDEX
            ) {
              return { colSpan: 3 }
            }

            if (index === FirstLabel.CHILD_NAMES_INDEX) {
              return { rowSpan: siblingChildren.length + 1 }
            }

            return { colSpan: 0 }
          },
        },
        {
          dataIndex: "label2",
          width: "24px",
          render: (v) => <p>{v}</p>,
          onCell: (_, index) => {
            if (index < SecondLabel.RECEIVING_CERTIFICATE_NUMBER_INDEX) {
              return { colSpan: 0 }
            }
            if (index === SecondLabel.RECEIVING_CERTIFICATE_NUMBER_INDEX) {
              return { colSpan: 2 }
            }
            return {}
          },
        },
        {
          dataIndex: "number",
          width: "120px",
          render: (v) => <p>{v}</p>,
          onCell: (_, index: number) => {
            if (index < ReceovomgCertificateNumber.START_INDEX) {
              return { colSpan: 0 }
            }
            return {}
          },
        },
        {
          dataIndex: "value",
          width: "160px",
          render: (v, _, index) => {
            if (index === FirstValue.CITY_NUMBER_INDEX) {
              return <CityNumber number={v} />
            }
            return <p>{v}</p>
          },
        },
        {
          dataIndex: "value2",
          width: "160px",
          render: (v) => <p>{v}</p>,
        },
      ]}
      dataSource={[
        {
          label: t("Provision city No."),
          label2: "",
          number: "",
          value: municipalityNumber,
          value2: "",
        },
        {
          label: t("Name of guardian, etc. who will decide on benefits"),
          label2: "",
          number: "",
          value: parentName,
          value2: "",
        },
        {
          label: "対象障害児",
          label2: "受給者番号",
          number: "",
          value: "障害児氏名",
          value2: "利用者負担上限月額（神戸市/福祉部分）",
        },
        ...siblingChildren.map((child, index) => ({
          label2: CIRCLE_NUMBERS[index + 1],
          number: child.receiving_certificate_number,
          value: child.child_name,
          value2: Intl.NumberFormat("ja").format(child.local_burden_max_month),
        })),
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
