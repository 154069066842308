import {
  Checkboxes,
  RadioButtons,
  SelectInput,
  TextField,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"
import moment from "moment"
import { useFormik } from "formik"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { japaneseAlphaRegex } from "../../../utils/validation"

const Wrapper = styled.div`
  margin: 20px 0;
  input {
    background: #ffffff;
  }
  .operation-content {
    padding: 20px 0;
    .ant-row {
      margin-bottom: 20px;
      align-items: flex-start;
    }
    .caret-right {
      background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px
        12px;
      padding-left: 14px;
    }
    .multiple-input-container {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      align-items: center;
    }
    .change-display-container {
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
    .ant-select-selection-item {
      margin-right: 15px;
    }
    .mb-10 {
      margin-bottom: 10px;
    }
    .date-input {
      input {
        height: 32px;
      }
      .field-error {
        display: none;
      }
    }
    @media (max-width: 768px) {
      .ant-row {
        align-items: flex-start;
        flex-direction: column;
      }
      .operation-label {
        margin-bottom: 5px;
      }
      .operation-label,
      .operation-option {
        max-width: 100%;
      }
    }
  }
  @media print {
    display: none;
  }
  .min-auto-width {
    min-width: 200px;
    width: auto;
  }
`

interface IUserServiceProvisionResultTodaySituationOperation {
  show_child?: boolean
  child_options?: Array<any>
  facility_options: Array<any>
  initial_state: any
  handleSearch: any
}

export const UserServiceProvisionResultTodaySituationOperation = ({
  show_child = false,
  child_options = [],
  facility_options,
  initial_state,
  handleSearch,
}: IUserServiceProvisionResultTodaySituationOperation) => {
  const { t } = useTranslation()

  const SERVICE_OPTIONS = [
    { label: t("After school day service"), value: 1 },
    { label: t("Child development service"), value: 2 },
  ]
  const DRAFT_OPTIONS = [
    { label: t("Include"), value: 1 },
    { label: t("Do not include"), value: 2 },
  ]

  const DO_DONOT_LIST = [
    {
      label: t("Do"),
      value: 1,
    },
    { label: t("Do not"), value: 2 },
  ]

  const formik = useFormik({
    initialValues: initial_state,
    onSubmit: () => {
      handleSearch(formik.values)
    },
  })

  const handleReset = () => {
    const reset = {
      keyword: "",
      facility_ids: [],
      from_date: null,
      to_date: null,
      use_service: [],
      draft: 1,
      parents_comment: 1,
      child_id: null,
    }
    formik.setValues(reset)
    handleSearch(reset)
  }

  const getChildOption = () => {
    if (!formik?.values?.furigana) return child_options
    const regex = japaneseAlphaRegex(formik?.values?.furigana)
    return child_options?.filter(
      (child) => child?.furigana?.match(regex) || child?.label?.match(regex)
    )
  }

  return (
    <Wrapper className={"operation-wrapper"}>
      <TopRoundedWhiteCard title={t("Operation options")}>
        <div className={"operation-content"}>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Keyword")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <TextField
                placeholder={t(
                  "Contents of services provided, today’s situation, care records and life records."
                )}
                width={"150"}
                value={formik?.values?.keyword}
                onChange={(e) => {
                  formik.setFieldValue("keyword", e?.target?.value)
                }}
              />
            </Col>
          </Row>
          {show_child && (
            <Row>
              <Col span={3} className={"caret-right operation-label"}>
                {t("Child name")}
              </Col>
              <Col
                span={21}
                className={"operation-option multiple-input-container"}
              >
                <SelectInput
                  width={50}
                  height={40}
                  placeholder={"--"}
                  options={FuriganaAlphabetsOption}
                  value={formik?.values?.furigana}
                  onChange={(val) => {
                    formik.setFieldValue("furigana", val)
                  }}
                />
                <SelectInput
                  className={"min-auto-width"}
                  placeholder={t("All")}
                  options={getChildOption()}
                  height={40}
                  value={formik?.values?.child_id}
                  onChange={(val) => formik.setFieldValue("child_id", val)}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Date")}
            </Col>
            <Col span={20} className={"operation-option multiple-input-row"}>
              <div className={"multiple-input-container date-input"}>
                <DatePicker
                  onDateChange={(val) => {
                    formik.setFieldValue(
                      "from_date",
                      val ? val.format("YYYY-MM-DD") : null
                    )
                  }}
                  date={
                    formik?.values?.from_date
                      ? moment(formik?.values?.from_date)
                      : null
                  }
                  format={"YYYY年MM月DD日"}
                  style={{ width: "150px" }}
                />
                {"〜"}
                <DatePicker
                  onDateChange={(val) => {
                    formik.setFieldValue(
                      "to_date",
                      val ? val.format("YYYY-MM-DD") : null
                    )
                  }}
                  date={
                    formik?.values?.to_date
                      ? moment(formik?.values?.to_date)
                      : null
                  }
                  format={"YYYY年MM月DD日"}
                  style={{ width: "150px" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Facility")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <div className={"multiple-input-container mb-10"}>
                <OwnerButton
                  typeOf={"check-all"}
                  text={t("Check all")}
                  onClick={() => {
                    formik.setFieldValue(
                      "facility_ids",
                      facility_options?.map((facility) => facility?.value)
                    )
                  }}
                />
                <OwnerButton
                  typeOf={"secondary"}
                  text={t("Uncheck all")}
                  onClick={() => {
                    formik.setFieldValue("facility_ids", [])
                  }}
                />
              </div>
              <Checkboxes
                options={facility_options}
                value={formik?.values?.facility_ids}
                onChange={(val) => {
                  formik.setFieldValue("facility_ids", val)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Use service")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <Checkboxes
                options={SERVICE_OPTIONS}
                value={formik?.values?.use_service}
                onChange={(val) => {
                  formik.setFieldValue("use_service", val)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Draft")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <RadioButtons
                options={DRAFT_OPTIONS}
                value={formik?.values?.draft}
                onChange={(e) => {
                  formik.setFieldValue("draft", e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("View comments from parents")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <RadioButtons
                options={DO_DONOT_LIST}
                value={formik?.values?.parents_comment}
                onChange={(e) => {
                  formik.setFieldValue("parents_comment", e.target.value)
                }}
              />
            </Col>
          </Row>
          <div className={"change-display-container"}>
            <OwnerButton
              typeOf={"search"}
              text={t("Search")}
              onClick={() => {
                formik.handleSubmit()
              }}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Reset search")}
              onClick={() => {
                handleReset()
              }}
            />
          </div>
        </div>
      </TopRoundedWhiteCard>
    </Wrapper>
  )
}
