import { t } from "i18next"
import { Title } from "../../../PrintListContent.style"

export function DefaultSheetTitle() {
  return (
    <Title>{`${t(
      "User burden maximum amount management result sheet"
    )} （複数児童用）`}</Title>
  )
}
