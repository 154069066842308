import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Skeleton, Table, Typography } from "antd"
import { useRouter } from "next/router"
import { FormOutlined, RightOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import { useReactToPrint } from "react-to-print"
import {
  Wrapper,
  ContentContainer,
  TableContainer,
  RightAlignedDiv,
  AttachedTableContainer,
} from "./SupportPlans.styles"
import {
  CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER,
  Checkboxes,
  Grid,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES,
  Modal,
  PageCard,
  RadioButtons,
  SignatureCanvas,
  TextField,
  isApril2024ChangeApplicable,
  theme,
  timeDifference,
} from "@project/shared"
import { OwnerButton } from "../../atoms"

import {
  SUPPORT_PLAN_CONSENT_DATE,
  SUPPORT_PLAN_DISPLAY_CONTENT,
  SUPPORT_PLAN_PARENT_FIELD,
} from "../../../constants/supportPlans"
import {
  IndividualSupportDetailResponse,
  updateIndividualSupportPlanSignature,
} from "../../../services"
import { getCurrentJapaneseEra, scrollToElement } from "../../../utils"
import { toastMsg } from "../../../utils/toastMsg"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

React.useLayoutEffect = React.useEffect
interface IProps {
  details: IndividualSupportDetailResponse | undefined
  loading?: boolean
  url?: string
  type?: "LIST" | "DRAFT"
}

export const SupportPlanDetail: React.FC<IProps> = ({
  details,
  loading,
  url,
  type,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const planId = router.query.id as string
  const { options, consent_date, parent_sign, print_setting } =
    router.query as any
  const contentRef = useRef()
  const [updateParams] = useUpdateSearchParams()
  const qContent = options?.split(",")
  const qConsentDate = consent_date?.split(",")
  const qParentSign = parent_sign?.split(",")
  const [tempContent, setTempContent] = useState<any>(
    qContent || ["consent_date", "parent_sign", "manager_seal", "parent_seal"]
  )
  const [content, setContent] = useState<any>(
    qContent || ["consent_date", "parent_sign", "manager_seal", "parent_seal"]
  )
  const [tempConsentDate, setTempConsentDate] = useState<any>(
    qConsentDate || []
  )
  const [consentDate, setConsentDate] = useState<any>(qConsentDate || [])
  const [temParentOptions, setTempParentOptions] = useState(qParentSign || [])
  const [parentOptions, setParentOptions] = useState(qParentSign || [])
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const [signOf, setSignOf] = useState("")
  const [sign, setSign] = useState("")
  const [signType, setSignType] = useState<"date" | "name" | "onlyDate">("name")
  const [signatures, setSignatures] = useState({
    digital_signature_image: "", //parent signature
    child_digital_signature_image: "",
    manager_digital_signature_image: "",
    explanation_date_digital_signature_image: "",
    consent_date_digital_signature_image: "",
  })

  useEffect(() => {
    setSignatures({
      digital_signature_image:
        details?.childSupport?.digital_signature_image || "",
      child_digital_signature_image:
        details?.childSupport?.child_digital_signature_image || "",
      manager_digital_signature_image:
        details?.childSupport?.manager_digital_signature_image || "",
      explanation_date_digital_signature_image:
        details?.childSupport?.explanation_date_digital_signature_image || "",
      consent_date_digital_signature_image:
        details?.childSupport?.consent_date_digital_signature_image || "",
    })
  }, [details])

  const [tempPrintSetting, setTempPrintSetting] = useState(
    print_setting || "landscape"
  )
  const [printSetting, setPrintSetting] = useState(print_setting || "landscape")
  const [statementText, setStatementText] = useState({
    consentStatement: t(
      "I have received an explanation of the above individual support plan, and have agreed to its contents."
    ),
    consentStatementForChild: t(
      "I have explained the above individual support plan."
    ),
  })
  const { isLoading: isUpdating, mutate } = useMutation(
    updateIndividualSupportPlanSignature
  )

  const isAfterMarch2024 =
    details?.childSupport?.start_date &&
    moment(details?.childSupport?.start_date).isValid() &&
    isApril2024ChangeApplicable(moment(details?.childSupport?.start_date))
      ? true
      : false

  const onDisplayChange = () => {
    setContent(tempContent)
    setConsentDate(tempConsentDate)
    setParentOptions(temParentOptions)
    scrollToElement(contentRef, "start")
    setPrintSetting(tempPrintSetting)
    updateParams(
      {
        options: tempContent?.join(","),
        consent_date: tempConsentDate?.join(","),
        parent_sign: temParentOptions?.join(","),
        print_setting: tempPrintSetting,
      },
      `${url}/detail/${planId}`
    )
  }

  const saveSignatures = (key: string, val: string) => {
    mutate(
      {
        values: {
          ...signatures,
          [key]: val,
          child_id: +details?.childSupport?.child_id,
          planId: +planId,
          facility_id: details?.childSupport?.facility_id,
        },
        type: type,
      },
      {
        onSuccess: () => {
          setOpenSignatureModal(false)
          setSignatures({
            ...signatures,
            [key]: val,
          })
          toastMsg("success", t("Updated Successfully"))
        },
        onError: () => {
          toastMsg(
            "error",
            t("Something went wrong. Please contact administrator.")
          )
        },
      }
    )
  }

  const oldColumns = [
    {
      key: "items",
      title: t("Items (personal needs, etc.)"),
      render: (row) => {
        return {
          props: {
            style: {
              background: theme.bgColor2,
              borderColor: theme.gray2,
            },
            className: row?.titleCellClass,
          },
          children: row?.title,
        }
      },
      className: "pre-line-wrap wd-5 align-left-td max-3-jp-char",
    },
    {
      title: (
        <span style={{ whiteSpace: "pre-wrap" }}>{t("Specific goals")}</span>
      ),
      key: "achivement_goal",
      dataIndex: "achievement_goals",
      className: "pre-line-wrap max-10-rem align-left-td max-7-jp-char",
    },
    {
      title: isAfterMarch2024 ? (
        <div className={"title-pre-line ft-14"}>
          {t(
            "Support content\n(content, key points in providing support, relevance to the 5 areas (*), etc.)"
          )}
        </div>
      ) : (
        t("Contents of support/Points to consider")
      ),
      key: "consideration",
      className: "pre-line-wrap",
      children: [
        {
          key: "consideration1",
          dataIndex: "support_and_considerations",
          className: `hide-head pre-line-wrap align-left-td ${
            isAfterMarch2024 ? "max-april" : "max-march"
          }`,
          onCell: () => {
            return {
              colSpan: isAfterMarch2024 ? 1 : 2,
            }
          },
        },
        {
          key: "consideration2",
          className:
            "hide-head consideration2 support_guidelines align-left-td max-6-jp-char",
          dataIndex: "support_guidelines",
          render: (data) => {
            if (!Array.isArray(data)) return <></>
            return data.map((guide) => (
              <>
                {guide?.value
                  ? INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS?.find(
                      (opt) => opt.value == guide?.value
                    )?.label
                  : ""}
                {guide?.value ==
                  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.OTHERS &&
                  ` ${guide.title}`}
                <br />
              </>
            ))
          },
          onCell: () => {
            return {
              colSpan: isAfterMarch2024 ? 1 : 0,
            }
          },
        },
      ],
    },
    {
      title: t("Achievement time"),
      key: "achivment_time",
      dataIndex: "achievement_time",
      className: "pre-line-wrap max-4-jp-char",
    },
  ]

  const columnsPostMarch = [
    {
      title: t("Personal Providing organization"),
      key: "providing_organization",
      dataIndex: "providing_organization",
      className: "pre-line-wrap max-5-rem align-left-td max-8-jp-char",
    },
    {
      title: t("Points to note (including the role of the person)"),
      key: "points_to_note",
      dataIndex: "points_to_note",
      className: "pre-line-wrap max-5-rem align-left-td max-12-jp-char",
    },
    {
      title: t("Priority"),
      key: "priority",
      dataIndex: "priority",
      className: "pre-line-wrap priority max-3-jp-char",
    },
  ]

  const attachedTableColumns: Array<any> = [
    {
      title: "",
      key: "1",
      align: "center",
      dataIndex: "title",
      className: "first-column",
      onCell: (row) => {
        return {
          rowSpan: !isNaN(row?.titleRowSpan) ? row?.titleRowSpan : 1,
        }
      },
    },
    {
      title: t("Mon"),
      key: "mon",
      dataIndex: "mon",
      align: "center",
      className: "column-centered",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 7 : 1,
          className: row?.monClass,
        }
      },
    },
    {
      title: t("Tue"),
      key: "tue",
      align: "center",
      dataIndex: "tue",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
    {
      title: t("Wed"),
      key: "wed",
      align: "center",
      dataIndex: "wed",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
    {
      title: t("Thu"),
      key: "thu",
      align: "center",
      dataIndex: "thu",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
    {
      title: t("Fri"),
      key: "fri",
      align: "center",
      dataIndex: "fri",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
    {
      title: t("Sat"),
      key: "sat",
      align: "center",
      dataIndex: "sat",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
    {
      title: t("Sunday and holidays"),
      key: "sun",
      align: "center",
      dataIndex: "sun",
      className: "last-column",
      onCell: (row) => {
        return {
          colSpan: row?.combineAllDayColumns ? 0 : 1,
        }
      },
    },
  ]

  const columns: Array<any> = isAfterMarch2024
    ? oldColumns.concat(columnsPostMarch)
    : oldColumns

  const childName = details?.childSupport?.child_info?.child_name
  const pageTitle =
    type === "DRAFT"
      ? t("{{childName}}’s Individual support plan (draft) details", {
          childName,
        })
      : t("{{childName}}’s Individual support plan details", {
          childName,
        })

  const renderSinature = (url) => (
    <>{url ? <img src={url} alt={"signature"} className={"sign-img"} /> : ""}</>
  )
  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
    pageStyle: `@media print {
      @page {
        size: ${printSetting} !important;
      }
    }`,
  })
  //main content
  const renderBtns = () => {
    return (
      <div className={"btns__container"}>
        <OwnerButton
          text={"<<" + t("Back")}
          shape={"circle"}
          typeOf={"draft"}
          onClick={() => router.push(url)}
        />
        <OwnerButton
          text={t("Print (PDF Output)")}
          shape={"round"}
          icon={"print"}
          typeOf={"primary"}
          onClick={handlePrint}
        />
      </div>
    )
  }

  const supportItemData = useMemo(() => {
    const rows = []
    if (Array.isArray(details?.childSupportItems)) {
      details?.childSupportItems.forEach((item: any) => {
        const contents = item?.support_content
        const itemLen = contents?.achievement_goals?.length || 1
        for (let i = 0; i < itemLen; i++) {
          let titleCellClass = ""
          if (i == 0 && itemLen == 1) {
            // single cell do nothing
          } else if (i == 0 && itemLen > 1) {
            // first cell and has other cell
            titleCellClass = "title-no-bottom-border"
          } else if (itemLen == i + 1) {
            // last cell no border top
            titleCellClass = "title-no-top-border"
          } else {
            // cell on the middle no top or bottom border
            titleCellClass = "title-no-top-border title-no-bottom-border"
          }
          if (item?.title) {
            const rowData = {
              key: item?.id + "" + i,
              title: i == 0 ? item?.title : "",
              achievement_goals: contents?.achievement_goals?.[i],
              support_and_considerations:
                contents?.support_and_considerations?.[i],
              support_guidelines: contents?.support_guidelines?.[i],
              achievement_time: contents?.achievement_time?.[i],
              providing_organization: contents?.providing_organization?.[i],
              points_to_note: contents?.points_to_note?.[i],
              priority: contents?.priority?.[i],
              titleCellClass,
            }
            rows.push(rowData)
          }
        }
      })
    }
    return rows
  }, [details?.childSupportItems])

  const individualSupportAttachTableData = useMemo(() => {
    // no id means not saved
    const planTable = details?.childSupportPlanTable
    if (
      !planTable?.id ||
      (!planTable?.offer_time?.length &&
        !planTable?.before_support_time?.length &&
        !planTable?.after_support_time?.length)
    )
      return []
    const data = []

    const dayOrder = CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER

    function getDataForDay(day: number, source: Array<any>) {
      return source?.find((s) => s?.week_no == day)
    }

    function getDurationStringFromTime(timeString: string) {
      const splittedString = timeString.split(":")
      if (splittedString.length < 2) return ""
      return `${splittedString[0]}時${splittedString[1]
        .toString()
        .padStart(2, "0")}分`
    }

    // first row as constant since only includes title
    const firstRow = {
      title: t("Offer time"),
      titleRowSpan: 3,
      mon: t("Usage start/end time"),
      tue: t("Usage start/end time"),
      wed: t("Usage start/end time"),
      thu: t("Usage start/end time"),
      fri: t("Usage start/end time"),
      sat: t("Usage start/end time"),
      sun: t("Usage start/end time"),
      highlightRow: true,
    }
    data.push(firstRow)

    // second row with start & end time for each day
    const secondRow = {
      title: "",
      titleRowSpan: 0,
    }
    // third row with time period
    const thirdRow = {
      title: "",
      titleRowSpan: 0,
    }

    if (Array.isArray(details?.childSupportPlanTable?.offer_time)) {
      dayOrder.forEach((key, index) => {
        const dayData = getDataForDay(
          index,
          details?.childSupportPlanTable?.offer_time
        )
        if (dayData?.start_time && dayData?.end_time) {
          secondRow[key] =
            getDurationStringFromTime(dayData?.start_time) +
            " ～ " +
            getDurationStringFromTime(dayData?.end_time)
          const difference = timeDifference(
            dayData?.start_time,
            dayData?.end_time
          )
          if (!difference.error) {
            thirdRow[key] = `${difference.hour}時間${difference.minuteString}分`
          }
        }
      })
    }
    data.push(secondRow)
    data.push(thirdRow)

    // fourth row with notice
    const fourthRow = {
      title: t("Notices"),
      combineAllDayColumns: true,
      mon: details?.childSupportPlanTable?.notices,
      rowClass: "min-height-row",
      monClass: "rt-thick-border",
    }
    data.push(fourthRow)

    // fifth row as constant
    const fifthRow = {
      title: (
        <>
          {t("Extended support time")}
          <br />
          <small>
            {t(
              "*Extended support time must be at least 1 hour before and after support."
            )}
          </small>
        </>
      ),
      titleRowSpan: 5,
      mon: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      tue: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      wed: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      thu: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      fri: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      sat: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      sun: (
        <>
          {t("[Before support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      highlightRow: true,
    }
    data.push(fifthRow)

    // sixth row as time string for before support
    const sixthRow = {
      title: "",
      titleRowSpan: 0,
    }

    // seventh row as constant
    const seventhRow = {
      title: "",
      titleRowSpan: 0,
      mon: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      tue: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      wed: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      thu: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      fri: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      sat: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      sun: (
        <>
          {t("[After support]")}
          <br />
          {t("Extended support time")}
        </>
      ),
      highlightRow: true,
    }

    // eigth row as time string for after support
    const eigthRow = {
      title: "",
      titleRowSpan: 0,
    }

    // ninth row as sum of duration for after support & defore support
    const ninthRow = {
      title: "",
      titleRowSpan: 0,
    }

    if (Array.isArray(details?.childSupportPlanTable?.before_support_time)) {
      dayOrder.forEach((key, index) => {
        const dayData = getDataForDay(
          index,
          details?.childSupportPlanTable?.before_support_time
        )
        if (dayData?.start_time && dayData?.end_time) {
          sixthRow[key] =
            getDurationStringFromTime(dayData?.start_time) +
            " ～ " +
            getDurationStringFromTime(dayData?.end_time)
          const difference = timeDifference(
            dayData?.start_time,
            dayData?.end_time
          )
          if (!difference.error) {
            ninthRow[key] = difference.totalMinute
          }
        }
      })
    }

    if (Array.isArray(details?.childSupportPlanTable?.after_support_time)) {
      dayOrder.forEach((key, index) => {
        const dayData = getDataForDay(
          index,
          details?.childSupportPlanTable?.after_support_time
        )
        if (dayData?.start_time && dayData?.end_time) {
          eigthRow[key] =
            getDurationStringFromTime(dayData?.start_time) +
            " ～ " +
            getDurationStringFromTime(dayData?.end_time)
          const difference = timeDifference(
            dayData?.start_time,
            dayData?.end_time
          )
          if (!difference.error) {
            if (ninthRow[key]) {
              ninthRow[key] += difference.totalMinute
            } else {
              ninthRow[key] = difference.totalMinute
            }
          }
        }
      })
    }

    // now convert to hour and minute for ninth row
    dayOrder.forEach((key) => {
      if (ninthRow[key]) {
        const hour = Math.floor(ninthRow[key] / 60)
        const minute = ninthRow[key] % 60
        ninthRow[key] = `${hour}時間${minute.toString().padStart(2, "0")}分`
      }
    })

    data.push(sixthRow)
    data.push(seventhRow)
    data.push(eigthRow)
    data.push(ninthRow)

    // tenth row as reason
    const tenthRow = {
      title: t("Reason and time required for extension"),
      combineAllDayColumns: true,
      mon: details?.childSupportPlanTable?.reason,
      rowClass: "min-height-row",
      monClass: "rt-thick-border",
    }
    data.push(tenthRow)

    return data
  }, [details])

  const renderIndividualSupportAttachedTable = () => {
    if (!individualSupportAttachTableData.length) return null
    return (
      <AttachedTableContainer className={"child-support-attach-table"}>
        <div className={"attach-title"}>
          {t("Individual support plan attached table")}
        </div>
        <div className={"child-info"}>
          <div className={"gray-background inline-blocks"}>
            {t("Name of child using the service")}
          </div>
          <div className={"inline-blocks"}>{childName}</div>
        </div>
        <Table
          className={"support-attached-table"}
          pagination={false}
          columns={attachedTableColumns}
          dataSource={individualSupportAttachTableData}
          bordered
          rowClassName={(row) =>
            row?.highlightRow
              ? `highlight-row ${row?.rowClass || ""}`
              : row?.rowClass
          }
        />
      </AttachedTableContainer>
    )
  }

  const okToRenderDetails = () => {
    // render if there's at least one detail
    return (
      details?.childSupport?.child_family_hope ||
      details?.childSupport?.support_policy ||
      details?.childSupport?.long_term_goal ||
      details?.childSupport?.short_term_goal ||
      details?.childSupport?.standard_hr_providing_support ||
      details?.childSupport?.remarks
    )
  }

  const okToRenderSupportTable = () => {
    // render if there's at least one data
    return supportItemData?.length != 0
  }

  const renderContent = (action: string) => (
    <ContentContainer
      ref={contentRef}
      printSetting={printSetting}
      className={"detail-container"}
    >
      <div className={"header-on-print mb-10"}>
        <Typography.Title level={5}>{pageTitle}</Typography.Title>
        <div className={"info"}>
          <div className={"box"}>
            <span className={"first"}>
              {t("Facility name")}
              {": "}
            </span>
            <span>{details?.childSupport?.facility?.facility_name}</span>
          </div>
          <div className={"box"}>
            <span className={"first"}>
              {t("Use service")}
              {": "}
            </span>
            <span>
              {details?.childSupport?.use_service_id === 1
                ? t("After school day service")
                : t("Child Development Support")}
            </span>
          </div>
          <div className={"box"}>
            <span className={"first"}>
              {t("Created date")}
              {": "}
            </span>
            <span>
              {details?.childSupport?.report_create_date ? (
                <>
                  {moment(
                    details?.childSupport?.report_create_date
                  ).isValid() &&
                    moment(details?.childSupport?.report_create_date).format(
                      "YYYY年MM月DD日"
                    )}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className={"top__container mb-10"}
        style={{
          alignItems: action === "pdf" ? "flex-end" : "",
        }}
      >
        {action === "render" ? (
          <>{renderBtns()}</>
        ) : (
          <Typography.Title level={3}>{pageTitle}</Typography.Title>
        )}
        <div className={"info no-header-on-print"}>
          <div className={"box"}>
            <span className={"first"}>
              {t("Facility name")}
              {": "}
            </span>
            <span>{details?.childSupport?.facility?.facility_name}</span>
          </div>
          <div className={"box"}>
            <span className={"first"}>
              {t("Use service")}
              {": "}
            </span>
            <span>
              {details?.childSupport?.use_service_id === 1
                ? t("After school day service")
                : t("Child Development Support")}
            </span>
          </div>
          <div className={"box"}>
            <span className={"first"}>
              {t("Created date")}
              {": "}
            </span>
            <span>
              {details?.childSupport?.report_create_date ? (
                <>
                  {moment(
                    details?.childSupport?.report_create_date
                  ).isValid() &&
                    moment(details?.childSupport?.report_create_date).format(
                      "YYYY年MM月DD日"
                    )}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>

      <div className={"stats mb-10"}>
        <div className={"stats--box"}>
          <div className={"box__label"}>{t("Recipient number")}</div>
          <div className={"value"}>
            {
              details?.childSupport?.child_info
                ?.child_receiving_certificates?.[0]
                ?.receiving_certificate_number
            }
          </div>
        </div>
        <div className={"stats--box"}>
          <div className={"box__label short_label"}>{t("Start Date")}</div>
          <div className={"value"}>
            {moment(details?.childSupport?.start_date).isValid() &&
              moment(details?.childSupport?.start_date).format(
                "YYYY年MM月DD日"
              )}
          </div>
        </div>
        <div className={"stats--box"}>
          <div className={"box__label short_label"}>{t("Expiry date")}</div>
          <div className={"value"}>
            {moment(details?.childSupport?.end_date).isValid() &&
              moment(details?.childSupport?.end_date).format("YYYY年MM月DD日")}
          </div>
        </div>
        <div className={"stats--box"}>
          <div className={"box__label"}>{t("No. of created")}</div>
          <div className={"value"}>{details?.childSupport?.create_count}</div>
        </div>
      </div>

      {okToRenderDetails() ? (
        <div className={"content__grid mb-10"}>
          <div className={"table-row "}>
            <Grid
              className={"grid-header first-header support-label"}
              background
            >
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Intention of the person/family")}
                </div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last first-container support-content"
              }
            >
              <div className={""}>
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {details?.childSupport?.child_family_hope}
                </Typography.Text>
              </div>
            </Grid>
          </div>

          <div className={"table-row "}>
            <Grid className={"grid-header support-label"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>
                  {t("Comprehensive support policy")}
                </div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last support-content"
              }
            >
              <div className={"value__wrapper"}>
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {details?.childSupport?.support_policy}
                </Typography.Text>
              </div>
            </Grid>
          </div>

          <div className={"table-row "}>
            <Grid className={"grid-header support-label"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Long term goal")}</div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last support-content"
              }
            >
              <div className={"value__wrapper"}>
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {details?.childSupport?.long_term_goal}
                </Typography.Text>
              </div>
            </Grid>
          </div>

          <div className={"table-row "}>
            <Grid className={"grid-header support-label"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Short term goal")}</div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last support-content"
              }
            >
              <div className={"value__wrapper"}>
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {details?.childSupport?.short_term_goal}
                </Typography.Text>
              </div>
            </Grid>
          </div>

          {isAfterMarch2024 && (
            <div className={"table-row"}>
              <Grid className={"grid-header support-label"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Standard hours for providing support, etc.")}
                    <br />
                    {t("(days of the week,frequency,time)")}
                  </div>
                </div>
              </Grid>
              <Grid
                className={
                  "flex-full second-column grid-row-last support-content"
                }
              >
                <div className={"value__wrapper"}>
                  <Typography.Text style={{ whiteSpace: "pre-line" }}>
                    {details?.childSupport?.standard_hr_providing_support}
                  </Typography.Text>
                </div>
              </Grid>
            </div>
          )}

          <div className={"table-row"}>
            <Grid className={"grid-header support-label"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Transportation")}</div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last support-content"
              }
            >
              <div
                className={"value__wrapper"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: action === "pdf" ? "2rem" : "0.5rem",
                }}
              >
                <Typography.Text>
                  {t("Greet")} {":"}
                  {details?.childSupport?.goto_meet_flag
                    ? details?.childSupport?.goto_meet_place
                    : t("None")}
                </Typography.Text>
                <Typography.Text>
                  {t("Send ")} {":"}
                  {details?.childSupport?.take_flag
                    ? details?.childSupport?.take_place
                    : t("None")}
                </Typography.Text>
              </div>
            </Grid>
          </div>

          <div className={"table-row"}>
            <Grid className={"grid-header support-label"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Remarks")}</div>
              </div>
            </Grid>
            <Grid
              className={
                "flex-full second-column grid-row-last support-content"
              }
            >
              <div className={"value__wrapper"}>
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {details?.childSupport?.remarks}
                </Typography.Text>
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <></>
      )}
      {okToRenderSupportTable() ? (
        <div className={"content__table print-ml-neg1"} id={"content__table"}>
          <Table
            pagination={false}
            className={"items-detail-support-table"}
            columns={columns}
            dataSource={supportItemData}
            scroll={{ x: true }}
            bordered
          />
          {isAfterMarch2024 && (
            <RightAlignedDiv>
              {t(
                '*Perspectives from 5 areas: "Health/Lifestyle", "Motor/Sensory", "Cognition/Behavior", "Language/Communication", "Human Relations/Sociality"'
              )}
            </RightAlignedDiv>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className={"content__footer"}>
        <div className={"agreement__content condense"}>
          <div className={"left"}>
            {content?.includes("agreement") && (
              <>
                <Typography.Text>
                  {statementText?.consentStatement}
                </Typography.Text>
                <br />
                <br />
              </>
            )}
            {content?.includes("consent_date") && (
              <>
                {consentDate?.includes("date_signature") &&
                consentDate?.includes("separate_date") ? (
                  <>
                    <div className={"left__content"}>
                      <div className={"sign__input"}>
                        <span>{t("Explanation date")}</span>
                        <div className={"sign__input__container"}>
                          {renderSinature(
                            signatures?.explanation_date_digital_signature_image
                          )}
                          <FormOutlined
                            className={"icon"}
                            onClick={() => {
                              setSign(
                                signatures?.explanation_date_digital_signature_image
                              )
                              setSignType("onlyDate")
                              setOpenSignatureModal(true)
                              setSignOf(
                                "explanation_date_digital_signature_image"
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"left__content"}>
                      <div className={"sign__input"}>
                        <span>{t("Consent date")}</span>
                        <div className={"sign__input__container"}>
                          {renderSinature(
                            signatures?.consent_date_digital_signature_image
                          )}
                          <FormOutlined
                            className={"icon"}
                            onClick={() => {
                              setSign(
                                signatures?.consent_date_digital_signature_image
                              )
                              setSignType("onlyDate")
                              setOpenSignatureModal(true)
                              setSignOf("consent_date_digital_signature_image")
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : consentDate?.includes("date_signature") &&
                  !consentDate?.includes("separate_date") ? (
                  <div className={"left__content"}>
                    <div className={"sign__input"}>
                      <span>{t("Consent date")}</span>
                      <div className={"sign__input__container"}>
                        {renderSinature(
                          signatures?.consent_date_digital_signature_image
                        )}
                        <FormOutlined
                          className={"icon"}
                          onClick={() => {
                            setSign(
                              signatures?.consent_date_digital_signature_image
                            )
                            setSignType("onlyDate")
                            setOpenSignatureModal(true)
                            setSignOf("consent_date_digital_signature_image")
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : consentDate?.includes("separate_date") ? (
                  <>
                    <div className={"date"}>
                      <Typography.Text>{t("Explanation date")}</Typography.Text>
                      <div className={"date--info"}>
                        <span>{getCurrentJapaneseEra()}</span>
                        <span>{t("Year")}</span>
                        <span>{t("Month")}</span>
                        <span>{t("Day")}</span>
                      </div>
                    </div>
                    <div className={"date"}>
                      <Typography.Text>{t("Consent Date")}</Typography.Text>
                      <div className={"date--info"}>
                        <span>{getCurrentJapaneseEra()}</span>
                        <span>{t("Year")}</span>
                        <span>{t("Month")}</span>
                        <span>{t("Day")}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={"date"}>
                    <Typography.Text>{t("Consent date")}</Typography.Text>
                    <div className={"date--info"}>
                      <span>{getCurrentJapaneseEra()}</span>
                      <span>{t("Year")}</span>
                      <span>{t("Month")}</span>
                      <span>{t("Day")}</span>
                    </div>
                  </div>
                )}
              </>
            )}
            {content?.includes("parent_sign") && (
              <div className={"left__content"}>
                <div className={"sign__input"}>
                  <span>
                    {parentOptions?.includes("change_representative")
                      ? t("Guardian and family representative name")
                      : t("parents name")}
                  </span>
                  <div className={"sign__input__container"}>
                    {renderSinature(signatures?.digital_signature_image)}
                    <FormOutlined
                      className={"icon"}
                      onClick={() => {
                        setSign(signatures?.digital_signature_image)
                        setSignType("name")
                        setOpenSignatureModal(true)
                        setSignOf("digital_signature_image")
                      }}
                    />
                  </div>
                </div>
                {content?.includes("parent_seal") && <span>{t("mark")}</span>}
              </div>
            )}
            {content?.includes("child_sign") && (
              <div className={"left__content"}>
                <div className={"sign__input"}>
                  <span>{t("child name")}</span>
                  <div className={"sign__input__container"}>
                    {renderSinature(signatures?.child_digital_signature_image)}
                    <FormOutlined
                      className={"icon"}
                      onClick={() => {
                        setSign(signatures?.child_digital_signature_image)
                        setSignType("name")
                        setOpenSignatureModal(true)
                        setSignOf("child_digital_signature_image")
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"right"}>
            <div>
              {content?.includes("pic") && (
                <>
                  <Typography.Text>
                    {statementText?.consentStatementForChild}
                  </Typography.Text>
                  <br />
                  <br />
                </>
              )}
              <div className={"f__name"}>
                <p>{details?.childSupport?.facility?.facility_name}</p>
              </div>
              <div className={"m__name"}>
                <div className={"child_support sign-section"}>
                  {/* This is the default display */}
                  <p>{t("Child development support manager")}</p>
                  {/* This is the default display 👆🏻*/}

                  {content?.includes("manager_sign") ? (
                    <>
                      {renderSinature(
                        signatures?.manager_digital_signature_image
                      )}
                      <FormOutlined
                        className={"signatures__formOutlined icon"}
                        onClick={() => {
                          setSign(signatures?.manager_digital_signature_image)
                          setSignType("name")
                          setOpenSignatureModal(true)
                          setSignOf("manager_digital_signature_image")
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={"child-dev-support-manager"}>
                        {details?.childSupport?.staff?.staff_name
                          ? details?.childSupport?.staff?.staff_name
                          : ""}{" "}
                      </p>
                    </>
                  )}
                </div>

                {content?.includes("manager_seal") && (
                  <div className={"mark"}>{"印"}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderIndividualSupportAttachedTable()}

      {details?.childSupportSchedule?.length > 0 ? (
        <TableContainer className={"weekly-calendar"}>
          <div className={"calendar"}>{t("weekly calendar")}</div>
          <div style={{ overflow: "auto" }}>
            <table>
              <tr>
                <th>{t("Monday ")}</th>
                <th>{t("Tuesday ")}</th>
                <th>{t("Wednesday ")}</th>
                <th>{t("Thursday ")}</th>
                <th>{t("Friday ")}</th>
                <th>{t("Saturday ")}</th>
                <th>{t("Sunday ")}</th>
              </tr>
              <tr>
                {[1, 2, 3, 4, 5, 6, 7].map((weekNo) => {
                  const itemsForWeek = details?.childSupportSchedule?.filter(
                    (item) => item.week_no === weekNo
                  )

                  return (
                    <td key={weekNo}>
                      <div className={"calendar_input_container"}>
                        {itemsForWeek?.map((item, index) => (
                          <div key={index} className={"calendar_input"}>
                            <div className={"time"}>
                              {item.start_time + "~" + item.end_time}
                            </div>
                            <span className={"comment"}>{item.comment}</span>
                          </div>
                        ))}
                      </div>
                    </td>
                  )
                })}
              </tr>
            </table>
          </div>
        </TableContainer>
      ) : null}

      {action === "render" ? (
        <div className={"content__footer"}>
          <div className={"bottom-btns"}>
            {renderBtns()}
            <OwnerButton
              text={
                type === "DRAFT"
                  ? t("Edit individual support plan (draft)")
                  : t("Edit individual support plan")
              }
              typeOf={"draft"}
              shape={"circle"}
              icon={"edit"}
              onClick={() =>
                router.push(
                  `${url}/edit/${planId}?child_id=${details?.childSupport?.child_id}&create_count=${details?.childSupport?.create_count}`
                )
              }
            />
          </div>
        </div>
      ) : null}
    </ContentContainer>
  )

  return (
    <>
      <Wrapper>
        <div className={"filter__wrapper"}>
          <PageCard title={t("Operation options")}>
            <div className={"filter__container"}>
              <div className={"content"}>
                <div className={"label"}>
                  <Typography.Paragraph>
                    <RightOutlined className={"icon"} />
                    {t("Display contents")}
                  </Typography.Paragraph>
                </div>
                <div className={"check__items"}>
                  <Checkboxes
                    key={"footerInformation"}
                    name={"footerInformation"}
                    options={SUPPORT_PLAN_DISPLAY_CONTENT}
                    value={tempContent}
                    onChange={setTempContent}
                  />
                  {tempContent?.includes("agreement") && (
                    <TextField
                      bgcolor={"#ffffff"}
                      label={t("Consent Statement")}
                      height={"50px"}
                      value={statementText?.consentStatement}
                      onChange={(event) =>
                        setStatementText({
                          ...statementText,
                          consentStatement: event.target.value,
                        })
                      }
                    />
                  )}
                  {tempContent?.includes("pic") && (
                    <TextField
                      bgcolor={"#ffffff"}
                      label={t("Consent Statement for child management")}
                      height={"50px"}
                      value={statementText?.consentStatementForChild}
                      onChange={(event) =>
                        setStatementText({
                          ...statementText,
                          consentStatementForChild: event.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              {tempContent?.includes("consent_date") && (
                <div className={"content"}>
                  <div className={"label"}>
                    <Typography.Paragraph>
                      <RightOutlined className={"icon"} />
                      {t("Consent date")}
                    </Typography.Paragraph>
                  </div>
                  <div className={"check__items"}>
                    <Checkboxes
                      key={"footerInformation"}
                      name={"footerInformation"}
                      value={tempConsentDate}
                      options={SUPPORT_PLAN_CONSENT_DATE}
                      onChange={setTempConsentDate}
                    />
                  </div>
                </div>
              )}
              {tempContent?.includes("parent_sign") && (
                <div className={"content"}>
                  <div className={"label"}>
                    <Typography.Paragraph>
                      <RightOutlined className={"icon"} />
                      {t("Parent signature field")}
                    </Typography.Paragraph>
                  </div>
                  <div className={"check__items"}>
                    <Checkboxes
                      key={"footerInformation"}
                      name={"footerInformation"}
                      options={SUPPORT_PLAN_PARENT_FIELD}
                      value={temParentOptions}
                      onChange={setTempParentOptions}
                    />
                  </div>
                </div>
              )}

              <div className={"content"}>
                <div className={"label"}>
                  <Typography.Paragraph>
                    <RightOutlined className={"icon"} />
                    {t("Print setting")}
                  </Typography.Paragraph>
                </div>
                <div className={"check__items"}>
                  <RadioButtons
                    value={tempPrintSetting}
                    onChange={(val) => setTempPrintSetting(val?.target?.value)}
                    options={[
                      {
                        value: "landscape",
                        label: t("Landscape"),
                      },
                      {
                        value: "portrait",
                        label: t("Portrait"),
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <OwnerButton
                  text={t("Display change")}
                  shape={"circle"}
                  typeOf={"secondary"}
                  onClick={onDisplayChange}
                />
              </div>
            </div>
          </PageCard>
        </div>
        <PageCard title={pageTitle}>
          {loading ? (
            <>
              <Skeleton />
              <br />
              <Skeleton />
              <br />
              <br />
              <Skeleton />
            </>
          ) : (
            <>{renderContent("render")}</>
          )}
        </PageCard>
      </Wrapper>
      {/* signature modal */}
      {openSignatureModal && (
        <Modal
          open={openSignatureModal}
          title={t("Confirmation signature")}
          onCancel={() => {
            setOpenSignatureModal(false)
          }}
          footer={null}
          bodyStyle={{
            padding: 0,
          }}
        >
          <SignatureCanvas
            sign={signType == "name" ? sign : ""}
            dateSign={signType != "name" ? sign : ""}
            signType={signType}
            isLoading={isUpdating}
            onSave={(sign, dateSign) => {
              saveSignatures(signOf, signType == "name" ? sign : dateSign)
            }}
          />
        </Modal>
      )}
    </>
  )
}
