import { API, removeBlankAttributes } from "@project/shared"
import moment from "moment"

export interface IReturnChildDetail {
  count?: number
  data?: any
}
export interface IReturnChild {
  count?: number
  data?: any
}

interface IChildDetailParam {
  page?: string
  pageSize?: string
  keyword?: string
  consultation_support_office_id?: number
  facility_id?: number
  other_facility_id?: number
  provision_city_id?: number
  child_service_ids?: string
  contract_month?: string
  contract_year?: string
  contract_facility_ids?: string
}

export const fetchChild = async ({ queryKey }): Promise<IReturnChildDetail> => {
  const initialParams = {} as IChildDetailParam
  initialParams.page = queryKey[1] || 1
  initialParams.consultation_support_office_id =
    queryKey[2]?.consultation_support_office_id
  initialParams.facility_id = queryKey[2]?.facility_id
  initialParams.other_facility_id = queryKey[2]?.other_facility_id
  initialParams.provision_city_id = queryKey[2]?.provision_city_id
  initialParams.child_service_ids = queryKey[2]?.child_service_ids?.toString()
  initialParams.contract_facility_ids =
    queryKey[2]?.contract_facility_ids?.toString()
  initialParams.keyword = queryKey[2]?.keyword
  initialParams.contract_year = queryKey[2]?.contract_year
  initialParams.contract_month = queryKey[2]?.contract_month
  initialParams.pageSize = queryKey[2]?.pageSize ? queryKey[2]?.pageSize : "20"

  const params = removeBlankAttributes(initialParams)
  return API.get(`/child`, {
    params,
  })
}

export const getChildDetail = async (id: string) => {
  return id && API.get(`/child/${id}`)
}

export const getChildInDetail = async (id: string) => {
  return id && API.get(`/child/detail/${id}`)
}

export const addChild = async (values: any) =>
  API.post(`/child`, {
    ...values,
    no_allergy_flg: !values.no_allergy_flg ? 0 : values.no_allergy_flg.length,
    birthday: moment(values.birthday)
      .hour(moment().hour())
      .minute(moment().minute())
      .second(moment().second()),
  })

export const updateChild = async (values: any) =>
  API.put(`/child/${values.id}`, {
    ...values,
    no_allergy_flg: !values.no_allergy_flg ? 0 : values.no_allergy_flg.length,
    birthday: moment(values.birthday)
      .hour(moment().hour())
      .minute(moment().minute())
      .second(moment().second()),
  })

export const getChildren = () => API.get(`/child`)

export const getChildrenApplicationStatusFacility = () =>
  API.get(`/child?pageSize=Infinity`)

export const getChildWithDisabilityStatus = (searchParams: any) => {
  const initialParams = {} as any
  initialParams.service_type = searchParams?.service_type?.join(",")
  initialParams.form_of_provision = searchParams?.form_of_provision?.join(",")

  const params = removeBlankAttributes(initialParams)

  return API.get(
    `/child-with-disabiliy-status/monthly/${searchParams?.facility_id}/${searchParams?.year}`,
    {
      params,
    }
  )
}

export const deleteChildById = async (id: string | number) =>
  API.delete(`/child/${id}`)
