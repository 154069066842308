//packages
import styled from "styled-components"

// themes
import { theme } from "@project/shared"

export const ActualCostBurdenRegistrationContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  .white-card {
    padding: 13px 30px 39px 30px;
    .data-registration-content {
      margin-top: 16px;

      .field-group .fieldGen-field-wrapper .fieldGen-flex-container {
        row-gap: 10px;
        @media (max-width: 397px) {
          .actual-cost-item-name {
            width: 100%;
            .over-rider-select-class {
              width: 100%;
            }
          }
        }
      }
    }
    .action-btn-group {
      margin-top: 20px;
      @media (max-width: ${theme.breakpoints.md}) {
        margin-top: 0px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 15px 20px;
    }
  }

  .flexbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    color: #191919;

    @media screen and (max-width: 426px) {
      gap: 0;
      button:last-child {
        margin-left: 20px;
      }
    }
  }
`
