import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import {
  BODY_TEMPERATURE_OPTIONS,
  CALANDER_HOURS_MINUTES,
  Checkbox,
  defaultAbsentNote,
  Modal,
  SelectInput,
  TextField,
  theme,
} from "@project/shared"
import { Button, notification } from "antd"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerTable } from ".."
import { getDayOfWeek } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { getServiceColor, getServiceInitial } from "../../../utils/facility"
import { OwnerButton } from "../../atoms"
import { japaneseAlphaRegex } from "../../../utils/validation"

const Wrapper = styled.div`
  margin: 20px 0;
  border-radius: 16px;
  background: #ffffff;
  padding: 12px 30px;
  .heading-container {
    padding-bottom: 8px;
    gap: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #d2d1d1;
    align-items: center;
  }
  .title-wrapper {
    font-weight: 500;
    font-size: 18px;
  }
  .pagination-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    button {
      height: 40px;
      border-radius: 8px;
    }
    .today {
      background: rgba(7, 130, 200, 0.2);
      border: none;
    }
    @media screen and (max-width: 1366px) {
      gap: 0;
      button {
        margin: 0px 5px;
      }
    }
  }
  .stats-container {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 1366px) {
      gap: 0px;
      .stat-label {
        margin-bottom: 20px;
      }
    }
    .one-stat {
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 1366px) {
        gap: 0px;
        .stat-value {
          margin-bottom: 20px;
          margin-right: 20px;
        }
        .stat-value .value {
          margin-left: 10px;
        }
      }
      .stat-label {
        padding: 5px 20px;
        background: ${theme.pink};
        border-radius: 50px;
      }
      .stat-value .value {
        color: ${theme.red3};
        font-size: 22px;
        font-weight: 5000;
      }
    }
  }
  .experience-legend-container {
    display: flex;
    gap: 10px;
    align-items: center;
    .experience-legend {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      background: rgba(202, 48, 48, 0.12);
    }
  }
  .attendance-table-container {
    margin: 20px 0;
    .main-pagination-wrapper {
      margin: 0;
    }

    table {
      width: 100%;
      overflow-x: auto;
      th {
        min-width: 50px;
      }
      thead tr:first-child th {
        border: none;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: ${theme.blue5};
        color: #ffffff;

        &.child-list-header-cell {
          padding: 12px 16px;
        }
      }
      thead tr:not(:first-child) th {
        background: rgba(7, 130, 200, 0.1);
      }
      thead tr:last-child th {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
      tbody {
        tr {
          td {
            border-right: 1px solid #d2d1d1;
            border-bottom: 1px solid #d2d1d1;
            &:first-child {
              border-left: 1px solid #d2d1d1;
            }
          }
          &:nth-child(2) {
            td {
              border-top: 1px solid #d2d1d1;
            }
          }
        }
      }
      thead tr:nth-child(2) {
        th:first-child {
          min-width: 100px;
        }
        th:not(:nth-child(-n + 2)) {
          min-width: 120px;
        }
        th:last-child {
          min-width: 100px;
        }
        th:nth-last-child(2) {
          min-width: 250px;
        }
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        height: 100%;
      }
      .experience-child-row {
        background-color: rgba(202, 48, 48, 0.12);
      }
    }
  }
  .check-container {
    color: ${theme.blue5};
    text-decoration: underline;
    cursor: pointer;
  }
  .child-name-container {
    .furigana-name {
      font-size: 12px;
      margin-bottom: 4px;
    }
    a {
      color: ${theme.blue5};
      text-decoration: underline;
    }
  }
  .gender-service {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    .service-tag {
      padding: 4px;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
      line-height: 18px;
      height: 26px;
    }
    @media screen and (max-width: 1366px) {
      gap: 0px;

      > img {
        margin-left: 5px;
      }
    }
  }
  .attendance-type-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    button {
      border-radius: 5px;
      height: 40px;
    }
    @media screen and (max-width: 1366px) {
      gap: 0px !important;
      button {
        margin-left: 5px;
      }
    }
  }
  .form-service-container {
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-wrap: wrap;
    button {
      border-radius: 50px;
      height: 40px;
      &disabled {
        background: #fefefe;
      }
    }
  }
  .remarks-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (max-width: 1366px) {
      gap: 0px;

      input:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    input {
      background: #ffffff;
      height: 40px;
    }
  }
  .reset-container {
    color: ${theme.blue5};
    text-decoration: underline;
    cursor: pointer;
  }
  .actual-cost-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    button {
      border-radius: 5px;
      height: 40px;
      &.used-button {
        background: transparent;
        border: none;
        color: green;
        box-shadow: none;
        span {
          text-decoration: underline;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      gap: 0px;

      button:not(:last-child) {
        margin-bottom: 8px;
        margin-right: 8px;
      }
    }
  }
  .absent-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .warning-container {
      color: ${theme.red2};
      cursor: pointer;
    }
    .recorder-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
    }
    .absent-note {
      textarea {
        height: 150px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    justify-content: center;
  }
  .temperature_container,
  .attendance-input-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 4px;
    .ant-select-selector .ant-select-selection-item {
      text-align: center;
    }

    @media (min-width: 2184px) {
      justify-content: center;
    }
    @media screen and (max-width: 1366px) {
      gap: 0px !important;
      .ant-select {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  .button-container {
    margin: 20px 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
`

const AbsenceModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 6px;
    ol {
      padding-left: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .absence-count-month {
      padding: 8px;
      border: 1px solid ${theme.red2};
      color: ${theme.red2};
      font-weight: 500;
      text-align: center;
    }
    .absence-info {
      font-size: 12px;
      color: #777777;
    }
    .recorder-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
    }
    .absent-note {
      textarea {
        height: 150px;
      }
    }
  }
  .ant-modal-footer {
    button {
      height: 40px;
    }
  }
`

interface IBulkOptions {
  attendance_type?: number
  entry_hour?: string
  entry_minute?: string
  reflect_entry?: number
  out_hour?: string
  out_minute?: string
  reflect_out?: number
  body_temperature1?: number
  body_temperature2?: number
  form_of_provision?: number
  reset?: boolean
  save_staff_id?: number
  absence_reason?: string
}

interface IUserAttendanceBulkEditInfoTable {
  date: string
  stats: any
  attendanceData: Array<any>
  bulkOptions: IBulkOptions
  staffList: Array<any>
  cancelRoute: string
  isSaving?: boolean
  handlePagination: (value) => void
  updateUserAttendance: (value) => void
  handleSave: (value) => void
}

const resetValues = {
  attendance_type: null,
  entry_hour: null,
  entry_minute: null,
  out_hour: null,
  out_minute: null,
  temperature1: null,
  temperature2: null,
  service_type: null,
  remarks: null,
  remarks_nhif: null,
  save_staff_id: null,
  absence_reason: null,
  is_deleted: true,
}

export const UserAttendanceBulkEditInfoTable = ({
  date,
  stats,
  attendanceData,
  bulkOptions,
  staffList,
  cancelRoute,
  isSaving = false,
  handlePagination,
  updateUserAttendance,
  handleSave,
}: IUserAttendanceBulkEditInfoTable) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [checkedSchedules, setCheckedSchedules] = useState(
    attendanceData.map((data) => data?.id)
  )
  const [checkAll, setCheckAll] = useState(true)
  const [absentSelectionObj, setAbsentSelectionObj] = useState(null)
  const getPageTitle = () => {
    const mDate = moment(date)
    return t("bulkAttendanceDayTitle", {
      year: mDate.format("YYYY"),
      month: mDate.format("MM"),
      day: mDate.format("DD"),
      dayOfWeek: t(getDayOfWeek(mDate.day())),
    })
  }

  const renderStats = (stats) => (
    <div className={"stats-container"}>
      {Object.keys(stats).map((key) => (
        <div className={"one-stat"} key={key}>
          <div className={"stat-label"}>{t(key)}</div>
          <div className={"stat-value"}>
            <span className={"value"}>{stats[key]}</span>
            {t("people")}
          </div>
        </div>
      ))}
    </div>
  )

  const handleDataChange = (scheduleId, key, value) => {
    const newData = attendanceData?.map((data) => {
      if (data?.id == scheduleId) {
        data[key] = value
        if (key == "entry_hour") {
          if (!data.entry_minute) data.entry_minute = "00"
        } else if (key == "out_hour") {
          if (!data.out_minute) data.out_minute = "00"
        } else if (key == "temperature1") {
          if (!data.temperature2) data.temperature2 = 0
        }
      }
      data.is_deleted = false
      return data
    })
    updateUserAttendance(newData)
  }

  const handleActualCost = (id, costId) => {
    const newData = attendanceData?.map((data) => {
      if (data?.id == id) {
        data.child_actual_cost_burden = data?.child_actual_cost_burden?.map(
          (cost) => {
            if (cost?.actual_cost_id == costId)
              return {
                ...cost,
                used_flag: cost?.used_flag ? false : true,
              }
            return cost
          }
        )
      }
      return data
    })
    updateUserAttendance(newData)
  }

  const handleAbsenceCalculation = (attendance_type: 2 | 3) => {
    const selectedSchedule = absentSelectionObj
    selectedSchedule.attendance_type = attendance_type
    if (attendance_type == 2) {
      // add to absent days for charged absence
      selectedSchedule.absent_days += 1
    }
    const updatedAttendanceData = attendanceData.map((data) => {
      if (data?.id == selectedSchedule?.id) return selectedSchedule
      return data
    })
    updateUserAttendance(updatedAttendanceData)
    setAbsentSelectionObj(null)
  }

  const handleReset = (scheduleId) => {
    const newData = attendanceData?.map((data) => {
      if (data?.id == scheduleId) {
        if (data.attendance_type == 2) {
          // reduce absent days if reset & previous type was charged absence
          data.absent_days -= 1
        }
        const reset_actual_cost_burden = data?.child_actual_cost_burden?.map(
          (burden) => {
            return { ...burden, used_flag: false }
          }
        )
        return {
          ...data,
          ...resetValues,
          child_actual_cost_burden: reset_actual_cost_burden,
          is_deleted: true,
        }
      }
      return data
    })
    updateUserAttendance(newData)
  }

  const resetAttendance = (scheduleId) => {
    const newData = attendanceData?.map((data) => {
      if (data?.id == scheduleId) {
        data.attendance_type = null
        data.entry_hour = null
        data.entry_minute = null
        data.out_hour = null
        data.out_minute = null
        data.save_staff_id = null
        data.absence_reason = null
      }
      return data
    })
    updateUserAttendance(newData)
  }

  useEffect(() => {
    const updatedData = attendanceData?.map((data) => {
      if (checkedSchedules?.includes(data?.id)) {
        if (bulkOptions?.reset) {
          return { ...data, ...resetValues, is_deleted: true }
        }
        if (bulkOptions?.attendance_type) {
          data.attendance_type = bulkOptions.attendance_type
          if (
            bulkOptions?.attendance_type == 2 ||
            bulkOptions?.attendance_type == 3
          ) {
            if (bulkOptions?.save_staff_id)
              data.save_staff_id = bulkOptions.save_staff_id
            if (bulkOptions?.absence_reason)
              data.absence_reason = bulkOptions.absence_reason
            data.entry_hour = null
            data.entry_minute = null
            data.out_hour = null
            data.out_minute = null
            data.temperature1 = null
            data.temperature2 = null
          }
        }
        if (bulkOptions?.reflect_entry) {
          data.entry_hour = null
          data.entry_minute = null
        } else {
          if (bulkOptions?.entry_hour) data.entry_hour = bulkOptions.entry_hour
          if (bulkOptions?.entry_minute)
            data.entry_minute = bulkOptions.entry_minute
        }
        if (bulkOptions?.reflect_out) {
          data.out_hour = null
          data.out_minute = null
        } else {
          if (bulkOptions?.out_hour) data.out_hour = bulkOptions.out_hour
          if (bulkOptions?.out_minute) data.out_minute = bulkOptions.out_minute
        }
        if (bulkOptions?.body_temperature1)
          data.temperature1 = bulkOptions.body_temperature1
        if (bulkOptions?.body_temperature2)
          data.temperature2 = bulkOptions.body_temperature2
        if (data?.user_service_type == 1 && bulkOptions?.form_of_provision)
          data.service_type = bulkOptions.form_of_provision
        data.is_deleted = false
      }
      return data
    })
    updateUserAttendance(updatedData)
  }, [bulkOptions])

  const handleAttendanceSave = () => {
    let hasIncompleteAttendance = false
    const finalValue = attendanceData.map((attendanceData) => {
      attendanceData.entry_time =
        attendanceData.entry_hour && attendanceData.entry_minute
          ? attendanceData.entry_hour + ":" + attendanceData.entry_minute
          : null
      attendanceData.out_time =
        attendanceData.out_hour && attendanceData.out_minute
          ? attendanceData.out_hour + ":" + attendanceData.out_minute
          : null
      attendanceData.temperature =
        attendanceData.temperature1 && attendanceData.temperature2
          ? +(attendanceData.temperature1 + "." + attendanceData.temperature2)
          : null
      attendanceData.date = moment(attendanceData?.date).format("YYYY-MM-DD")

      if (attendanceData?.attendance_type == 1) {
        if (!attendanceData.entry_time) {
          hasIncompleteAttendance = true
        }
      }

      return attendanceData
    })
    if (!hasIncompleteAttendance) {
      handleSave(finalValue)
    } else {
      notification.error({
        message: t("There are children with incomplete attendance"),
      })
    }
  }

  const renderChildColumn = (
    childId,
    childFuriganaName,
    childName,
    childGender,
    serviceType
  ) => (
    <div className={"child-name-container"}>
      <div className={"furigana-name"}>{"(" + childFuriganaName + ")"}</div>
      <Link href={`/child/profile/${childId}`}>
        <a>{childName}</a>
      </Link>
      <div className={"gender-service"}>
        {serviceType ? (
          <div
            className={"service-tag"}
            style={{ background: getServiceColor(serviceType) }}
          >
            {t(getServiceInitial(serviceType))}
          </div>
        ) : (
          <></>
        )}
        {childGender ? (
          <>
            {childGender == 1 ? (
              <img src={"/assets/icons/child-male-icon.svg"} />
            ) : (
              <img src={"/assets/icons/child-female-icon.svg"} />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )

  const columns = [
    {
      title: t("List of children attending"),
      key: "list",
      className: "child-list-header-cell",
      children: [
        {
          title: () => {
            return (
              <div
                className={"check-container"}
                onClick={() => {
                  const newCheckStatus = !checkAll
                  if (!newCheckStatus) {
                    setCheckedSchedules([])
                  } else {
                    setCheckedSchedules(attendanceData?.map((data) => data?.id))
                  }
                  setCheckAll(newCheckStatus)
                }}
              >
                {checkAll ? t("Reset all") : t("Check all")}
              </div>
            )
          },
          key: "setReset",
          render: (row) => (
            <div className={"checkbox-container"}>
              <Checkbox
                checked={checkedSchedules.includes(row?.id)}
                onClick={() => {
                  const isChecked = checkedSchedules.includes(row?.id)
                  let currentChecked = [...checkedSchedules]
                  if (!isChecked) currentChecked.push(row?.id)
                  else
                    currentChecked = currentChecked.filter(
                      (id) => id != row?.id
                    )
                  setCheckedSchedules(currentChecked)
                }}
              />
            </div>
          ),
        },
        {
          title: " ",
          key: "n",
          render: (_, __, index) => index + 1,
        },
        {
          title: () => (
            <>
              {t("Hiragana")} <br /> {t("Child name")}
            </>
          ),
          key: "child",
          render: (row) =>
            renderChildColumn(
              row?.child_id,
              row?.child_name_furigana,
              row?.child_name,
              row?.gender,
              row?.user_service_type
            ),
        },
        {
          title: t("Use facility"),
          key: "facility",
          dataIndex: "facility_name",
        },
        {
          title: t("Enter time"),
          key: "in",
          onCell: (row) => {
            if (!row?.attendance_type || row?.attendance_type != 1)
              return {
                colSpan: 4,
              }
            return {}
          },
          render: (row) => {
            if (!row?.attendance_type)
              return (
                <div className={"attendance-type-container"}>
                  <Button
                    onClick={() => {
                      handleDataChange(row?.id, "attendance_type", 1)
                    }}
                  >
                    {t("present_count")}
                  </Button>
                  <Button
                    onClick={() => {
                      setAbsentSelectionObj(row)
                    }}
                  >
                    {t("absent_count")}
                    {" (" +
                      t("absentDaysInMonth", {
                        days: row?.absent_days,
                        month: moment(date).month() + 1,
                      }) +
                      ")"}
                  </Button>
                  <Button
                    onClick={() => {
                      handleDataChange(row?.id, "attendance_type", 3)
                    }}
                  >
                    {t("Absentee (no extra charge for absenteeism)")}
                  </Button>
                </div>
              )

            if (row?.attendance_type == 1)
              return (
                <div className={"attendance-input-container"}>
                  <SelectInput
                    placeholder={"--"}
                    options={CALANDER_HOURS_MINUTES.hours}
                    width={60}
                    value={row?.entry_hour}
                    onChange={(val) => {
                      handleDataChange(row?.id, "entry_hour", val)
                    }}
                  />
                  {" : "}
                  <SelectInput
                    placeholder={"--"}
                    options={CALANDER_HOURS_MINUTES.minutes}
                    width={60}
                    value={row?.entry_minute}
                    onChange={(val) => {
                      handleDataChange(row?.id, "entry_minute", val)
                    }}
                    disabled={!row?.entry_hour}
                  />
                </div>
              )

            return (
              <div className={"absent-container"}>
                <div
                  className={"warning-container"}
                  onClick={() => {
                    resetAttendance(row?.id)
                  }}
                >
                  {row?.attendance_type == 2
                    ? `${t("Absence")} (${t("absentDaysInMonth", {
                        days: row?.absent_days,
                        month: moment(date).month() + 1,
                      })})`
                    : t("Absentee (no extra charge for absenteeism)")}
                </div>
                <div className={"recorder-container"}>
                  {t("[Recorder]")}
                  <SelectInput
                    placeholder={"--"}
                    width={50}
                    options={FuriganaAlphabetsOption}
                    value={row?.furigana_sort}
                    onChange={(val) => {
                      handleDataChange(row?.id, "furigana_sort", val)
                    }}
                  />
                  <SelectInput
                    placeholder={"--"}
                    width={150}
                    options={
                      row?.furigana_sort
                        ? staffList.filter((s) => {
                            const regex = japaneseAlphaRegex(row.furigana_sort)
                            if (
                              s.furigana_name.match(regex) ||
                              s.label.match(regex)
                            )
                              return true
                            return false
                          })
                        : staffList
                    }
                    value={row?.save_staff_id}
                    onChange={(val) => {
                      handleDataChange(row?.id, "save_staff_id", val)
                    }}
                  />
                </div>
                <div className={"absent-note"}>
                  <TextArea
                    value={
                      row?.absence_reason ??
                      defaultAbsentNote + row?.next_scheduled_date
                    }
                    onChange={(e) => {
                      handleDataChange(
                        row?.id,
                        "absence_reason",
                        e.target.value
                      )
                    }}
                  />
                </div>
              </div>
            )
          },
        },
        {
          title: t("Out time"),
          key: "out",
          onCell: (row) => {
            if (!row?.attendance_type || row?.attendance_type != 1)
              return {
                colSpan: 0,
              }
            return {}
          },
          render: (row) => {
            if (row?.attendance_type == 1)
              return (
                <div className={"attendance-input-container"}>
                  <SelectInput
                    placeholder={"--"}
                    options={CALANDER_HOURS_MINUTES.hours}
                    width={60}
                    value={row?.out_hour}
                    onChange={(val) => {
                      handleDataChange(row?.id, "out_hour", val)
                    }}
                    className={"left-align"}
                  />
                  {" : "}
                  <SelectInput
                    placeholder={"--"}
                    options={CALANDER_HOURS_MINUTES.minutes}
                    width={60}
                    value={row?.out_minute}
                    onChange={(val) => {
                      handleDataChange(row?.id, "out_minute", val)
                    }}
                    disabled={!row?.out_hour}
                    className={"left-align"}
                  />
                </div>
              )
          },
        },
        {
          title: t("Body temperature upon entry"),
          key: "temperatue",
          onCell: (row) => {
            if (!row?.attendance_type || row?.attendance_type != 1)
              return {
                colSpan: 0,
              }
            return {}
          },
          render: (row) => {
            if (row?.attendance_type == 1)
              return (
                <div className={"temperature_container"}>
                  <SelectInput
                    placeholder={"--"}
                    options={BODY_TEMPERATURE_OPTIONS(31, 42)}
                    width={60}
                    value={row?.temperature1}
                    onChange={(val) => {
                      handleDataChange(row?.id, "temperature1", val)
                      if (!row.temperature2) {
                        handleDataChange(row?.id, "temperature2", "0")
                      }
                    }}
                  />
                  {" . "}
                  <SelectInput
                    placeholder={"--"}
                    options={BODY_TEMPERATURE_OPTIONS(0, 9)}
                    width={60}
                    value={row?.temperature2}
                    onChange={(val) => {
                      handleDataChange(row?.id, "temperature2", val)
                    }}
                    disabled={!row?.temperature1}
                  />
                  {"°C"}
                </div>
              )
          },
        },
        {
          title: t("Form of service"),
          key: "service",
          onCell: (row) => {
            if (!row?.attendance_type || row?.attendance_type != 1)
              return {
                colSpan: 0,
              }
            return {}
          },
          render: (row) => {
            if (row?.attendance_type == 1) {
              if (row?.user_service_type == "1")
                return (
                  <div className={"form-service-container"}>
                    <Button
                      disabled={row?.service_type == 1}
                      onClick={() => {
                        handleDataChange(row?.id, "service_type", 1)
                      }}
                    >
                      {t("After school")}
                    </Button>
                    <Button
                      disabled={row?.service_type == 2}
                      onClick={() => {
                        handleDataChange(row?.id, "service_type", 2)
                      }}
                    >
                      {t("School holiday")}
                    </Button>
                  </div>
                )
              return <></>
            }
          },
        },
        {
          title: t("actualCost"),
          key: "actualcost",
          render: (row) => {
            if (
              Array.isArray(row?.child_actual_cost_burden) &&
              row?.child_actual_cost_burden?.length > 0
            ) {
              return (
                <div className={"actual-cost-container"}>
                  {row?.child_actual_cost_burden?.map((cost) => {
                    return (
                      <Button
                        key={cost?.actual_cost_burden_user_record_id}
                        className={cost?.used_flag ? "used-button" : ""}
                        onClick={() => {
                          handleActualCost(row?.id, cost?.actual_cost_id)
                        }}
                      >
                        {cost?.used_flag
                          ? cost?.actual_cost_name + " " + t("already")
                          : cost?.actual_cost_name}
                      </Button>
                    )
                  })}
                </div>
              )
            }
          },
        },
        {
          title: t("Remarks"),
          children: [
            {
              title: t(
                "Remarks output when making a request to the National Health Insurance Federation"
              ),
              render: (row) => {
                return (
                  <div className={"remarks-container"}>
                    <TextField
                      fullWidth={true}
                      placeholder={t("Remarks field")}
                      value={row?.remarks}
                      onChange={(e) => {
                        handleDataChange(row?.id, "remarks", e.target.value)
                      }}
                    />
                    <TextField
                      fullWidth={true}
                      placeholder={t(
                        "Remarks for National Health Insurance Federation"
                      )}
                      value={row?.remarks_nhif}
                      onChange={(e) => {
                        handleDataChange(
                          row?.id,
                          "remarks_nhif",
                          e.target.value
                        )
                      }}
                    />
                  </div>
                )
              },
            },
          ],
        },
        {
          title: t("Reset "),
          render: (row) => {
            return (
              <div
                className={"reset-container"}
                onClick={() => {
                  handleReset(row?.id)
                }}
              >
                {t("Reset ")}
              </div>
            )
          },
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <div className={"heading-container"}>
        <div className={"title-wrapper"}>{getPageTitle()}</div>
        <div className={"pagination-container"}>
          <Button
            onClick={() => {
              handlePagination("yesterday")
            }}
          >
            <DoubleLeftOutlined />
            {t("Prev day")}
          </Button>
          <Button
            className={"today"}
            onClick={() => {
              handlePagination("today")
            }}
          >
            {t("Today")}
          </Button>
          <Button
            onClick={() => {
              handlePagination("tomorrow")
            }}
          >
            {t("Next day")}
            <DoubleRightOutlined />
          </Button>
        </div>
      </div>
      {renderStats(stats)}
      <div className={"experience-legend-container"}>
        <div className={"experience-legend"}></div>
        {t("Experience")}
      </div>
      <div className={"attendance-table-container"}>
        <OwnerTable
          columns={columns}
          dataSource={attendanceData}
          scroll={{ x: "auto" }}
          rowClassName={(row) => {
            if (!row?.receiving_certificate_number) {
              return "experience-child-row"
            }
            return ""
          }}
        />
        <div className={"button-container"}>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Cancel")}
            onClick={() => {
              router.push(cancelRoute)
            }}
          />
          <OwnerButton
            typeOf={"primary"}
            text={t("Save")}
            onClick={() => {
              handleAttendanceSave()
            }}
            isLoading={isSaving}
          />
        </div>
      </div>
      <AbsenceModal
        title={t("Confirm whether to calculate the addition for absence")}
        closeIcon={<span>{t("Close")}</span>}
        open={absentSelectionObj ? true : false}
        onCancel={() => {
          setAbsentSelectionObj(null)
        }}
        maskClosable={false}
        destroyOnClose
        centered
        btnRightMargin={"24px"}
        width={"70%"}
        footer={[
          <Button
            key={"absent1"}
            onClick={() => {
              handleAbsenceCalculation(2)
            }}
          >
            {t("Calculate Absence Charges")}
          </Button>,
          <Button
            key={"absent2"}
            onClick={() => {
              handleAbsenceCalculation(3)
            }}
          >
            {t("Do not calculate extra for absence")}
          </Button>,
        ]}
      >
        <div>
          {t(
            "Additions for absenteeism can be calculated when there is a notice of cancellation on the day before, the day before, or on the day of cancellation."
          )}
        </div>
        <div>
          {t(
            "It is necessary to provide consultation assistance over the phone, etc., and to record the content of the consultation assistance."
          )}
        </div>
        <div>{t("Record example of consultation assistance")}</div>
        <ol className={"absence-record-example"}>
          <li>{t("Date of absence")}</li>
          <li>{t("who called")}</li>
          <li>{t("Respondent who received contact")}</li>
          <li>{t("Reason for absence")}</li>
          <li>{t("Appearance of the person on the day")}</li>
          <li>{t("Scheduled date of the next visit")}</li>
        </ol>
        <div className={"absence-count-month"}>
          {t("absentDaysInMonth", {
            month: moment(date).month() + 1,
            days: absentSelectionObj?.absent_days + 1,
            // show count to be updated on modal
          })}
        </div>
        <div>
          {t(
            `If you want to calculate the addition for absence, please enter the reason from the reason entry column below and press "Reflect".`
          )}
        </div>
        <div className={"absence-info"}>
          {t(
            "*It is possible to leave a reason even if the absence fee is not calculated."
          )}
        </div>
        <div className={"recorder-container"}>
          {t("[Recorder]")}
          <SelectInput
            placeholder={"--"}
            width={50}
            options={FuriganaAlphabetsOption}
            value={absentSelectionObj?.furigana_sort}
            onChange={(val) => {
              setAbsentSelectionObj({
                ...absentSelectionObj,
                furigana_sort: val,
              })
            }}
          />
          <SelectInput
            placeholder={"--"}
            width={150}
            options={
              absentSelectionObj?.furigana_sort
                ? staffList.filter((s) => {
                    const regex = japaneseAlphaRegex(
                      absentSelectionObj.furigana_sort
                    )
                    if (s.furigana_name.match(regex) || s.label.match(regex))
                      return true
                    return false
                  })
                : staffList
            }
            value={absentSelectionObj?.save_staff_id}
            onChange={(val) => {
              setAbsentSelectionObj({
                ...absentSelectionObj,
                save_staff_id: val,
              })
            }}
          />
        </div>
        <div className={"absent-note"}>
          <TextArea
            value={
              absentSelectionObj?.absence_reason ??
              defaultAbsentNote + absentSelectionObj?.next_scheduled_date
            }
            onChange={(e) => {
              setAbsentSelectionObj({
                ...absentSelectionObj,
                absence_reason: e.target.value,
              })
            }}
          />
        </div>
      </AbsenceModal>
    </Wrapper>
  )
}
