import { theme } from "@project/shared"
import { Button, Spin, Table } from "antd"
import styled from "styled-components"
interface ButtonProps {
  background?: string
  shape?: string
  borderColor?: string
  color?: string
}

export const Wrapper = styled.div`
  margin: 20px 0;
  .print-pagination-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .pagination-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1025px) {
    .staff-table .ant-table-cell {
      min-width: 100px;
    }
  }
  @media screen and (max-width: 426px) {
    .print-pagination-container.section-not-to-print {
      display: flex;
      flex-direction: column;
    }
    .pagination-container {
      align-self: flex-end;
    }
  }
  .print-back-container,
  .pagination-container,
  .footer-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .footer-buttons {
    margin: 40px 0;
  }
  .daily-report-container {
    max-width: 100%;
    margin: 20px 0;
    background: #ffffff;
    .title-container {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      padding: 16px 0;
    }
    .table-container {
      max-width: 100%;
      overflow: auto;
      border: none;
    }
    @media print {
      padding: 10px 20px;
      margin: 0;
      .title-container {
        font-size: 9pt;
        padding: 5px 0;
      }
    }
  }

  .no-report-day {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
  }
  .each-report-container {
    margin-bottom: 50px;
    page-break-after: always;

    @media print {
      table {
        border-top: none !important;
      }
      .staff-table {
        .ant-table.ant-table-bordered
          > .ant-table-container
          > .ant-table-content
          > table {
          border-top: none !important;
        }
        table > thead > tr > th {
          border-bottom: none !important;
        }
      }

      .child-table {
        .ant-table .ant-table-thead th {
          border-bottom: 1px solid #000000 !important;
        }
      }

      .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > thead
        > tr:not(:last-child)
        > th {
        border-bottom: none !important;
      }
    }
  }
  @media print {
    body *,
    .section-not-to-print {
      display: none;
    }
    .section-to-print {
      visibility: visible;
      width: 100%;
    }
    table {
      max-width: 100%;
    }
  }
`

export const HeaderTable = styled.table`
  width: 100%;
  overflow: auto;
  border: 1px solid #000000;
  td {
    padding: 4px 0;
    text-align: center;
    border: 1px solid #000000;
    border-bottom: 1px solid #000000;
    @media screen and (max-width: 1025px) {
      min-width: 180px;
    }
  }
  .facility_date {
    width: 200px;
  }
  .stamp_label {
    width: 100px;
  }
  .stamp_value {
    width: 75px;
  }
  @media print {
    td {
      padding: 2px 4px !important;
      font-size: 10px !important;
    }
  }
`

export const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  margin: 10px;
`

export const CustomButton = styled(Button)<ButtonProps>`
  padding: 8px 16px;
  height: auto;
  background: ${(props) => props?.background ?? theme.blue5};
  border-radius: ${(props) => (props?.shape == "circle" ? "40px" : "8px")};
  border-color: ${(props) => props?.borderColor ?? "transparent"};
  color: ${(props) => props?.color ?? "black"};
  &:hover {
    background: ${(props) => props?.background ?? "#ffffff"};
    color: ${(props) => theme.blue5 ?? props?.color};
    border-color: ${(props) => props?.borderColor ?? theme.blue5};
  }
`

export const ContentImageTable = styled.table`
  width: 100%;
  border: 1px solid #000000;
  border-top-width: 0.5px;
  td {
    padding: 2px;
    min-width: 150px;
  }
  tr td:not(:last-child) {
    border-right: 1px solid #000000;
  }
  tr:first-child td:not(:first-child) {
    border-bottom: 1px solid #000000;
  }
  .label-cell {
    width: 150px;
    background: #cccccc;
  }
  .cell-image {
    /* min-width: 35vw; */
    min-width: 100%;
    max-width: 100%;
    margin: auto;
    img {
      width: 25%;
      height: auto;
    }
  }
`

export const ChildTable = styled.div`
  overflow-x: auto;
  #ChildTable table {
    border-collapse: collapse !important;
    thead {
      tr {
        th {
          border: 1px solid #000000 !important;
          background: #cccccc;
          padding: 5px 2px !important;
          .header__content {
            text-align: center;
            font-size: 14px !important;
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid black !important;
          font-size: 14px !important;
          text-align: center;
          padding: 8px 2px !important;
        }
      }
    }
  }
  @media print {
    #ChildTable {
      table {
        thead {
          tr {
            background: #cccccc !important;
            th {
              text-align: center !important;
              font-size: 10px !important;
              border: 1px solid #000000 !important;
              outline: none !important;
              padding: 2px 4px !important;
              background-color: transparent !important;
              position: relative;
              white-space: nowrap;
              z-index: 10;
              .header__content {
                font-size: 10px !important;
                &:before {
                  content: "";
                  position: absolute;
                  top: 0px;
                  left: 1px;
                  right: 1px;
                  bottom: 0px;
                  z-index: -1;
                }
              }
            }
            .last_col {
              border-right: 1px solid #000000 !important;
            }
          }
        }
        tbody {
          tr {
            td {
              text-align: center !important;
              font-size: 10px !important;
              border: 1px solid black !important;
              outline: none !important;
              border-right: 0 !important;
              padding: 2px 4px !important;
              &:last-child {
                border-right: 1px solid black !important;
              }
            }
          }
        }
      }
    }
  }
`
export const StaffTable = styled(Table)`
  #StaffTable table {
    border-collapse: collapse !important;
    thead {
      tr {
        th {
          border: 1px solid #000000 !important;
          background: #cccccc;
          padding: 8px 2px !important;
          .header__content {
            text-align: center;
            font-size: 14px !important;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #000000 !important;
          font-size: 14px !important;
          text-align: center;
          padding: 8px 2px !important;
        }
      }
    }
  }
  @media print {
    margin-top: 0.3rem !important;
    #StaffTable {
      table {
        thead {
          tr {
            background: #cccccc !important;
            th {
              text-align: center !important;
              font-size: 10px !important;
              border: 1px solid #000000 !important;
              outline: none !important;
              border-right: 0 !important;
              padding: 2px !important;
              position: relative;
              background-color: transparent !important;
              z-index: 10;
              .header__content {
                font-size: 10px !important;
                &:before {
                  content: "";
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: -1;
                }
              }
            }
            .last_col {
              border-right: 1px solid #000000 !important;
            }
          }
        }
        tbody {
          tr {
            td {
              text-align: center !important;
              font-size: 10px !important;
              border: 1px solid #000000 !important;
              outline: none !important;
              border-right: 0 !important;
              padding: 3px !important;
              &:last-child {
                border-right: 1px solid #000000 !important;
              }
            }
          }
        }
      }
    }
  }
`
