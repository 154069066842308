import { Checkboxes, SelectInput, TopRoundedWhiteCard } from "@project/shared"
import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { useFormik } from "formik"

const Wrapper = styled.div`
  .my-top-round-card {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .operation-content {
    padding: 20px 0;
    border-bottom: 1px solid #d2d1d1;
    .ant-row {
      margin-bottom: 20px;
      align-items: flex-start;
    }
    .operation-label {
      margin-top: 5px;
    }
    .caret-right {
      background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px
        12px;
      padding-left: 14px;
    }
    .multiple-input-container {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      align-items: center;
    }
    .min-auto-width {
      min-width: 200px;
      width: auto;
    }
    .print-info {
      font-size: 10px;
    }
    .change-display-container {
      margin-top: 10px;
    }
    .multiple-input-row {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .multiple-input-row-label {
        min-width: 80px;
      }
    }
    .ant-select-selection-item {
      margin-right: 15px;
    }
    @media (max-width: 768px) {
      .ant-row {
        align-items: flex-start;
        flex-direction: column;
      }
      .operation-label {
        margin-bottom: 5px;
      }
      .operation-label,
      .operation-option {
        max-width: 100%;
      }
    }
  }
`

interface IUserServiceProvisionResultPrintOperation {
  initialValues: any
  onChange?: (state) => void
}

export const UserServiceProvisionResultPrintOperation = ({
  initialValues,
  onChange,
}: IUserServiceProvisionResultPrintOperation) => {
  const { t } = useTranslation()
  const DISPLAY_OPTIONS = [
    {
      label: t("Care record / Life record"),
      value: "care_record",
    },
    {
      label: t("Comments from Parents"),
      value: "parents_comment",
    },
  ]

  const STAMP_SETTING_OPTIONS_STAFF = [
    {
      label: t("Don't Display"),
      value: 1,
    },
    {
      label: t("Stamp"),
      value: 2,
    },
    {
      label: t("Only display name"),
      value: 3,
    },
  ]

  const STAMP_SETTING_OPTIONS_PARENT = [
    {
      label: t("Don't Display"),
      value: 1,
    },
    {
      label: t("Stamp"),
      value: 2,
    },
    {
      label: t("Digital sign"),
      value: 3,
    },
  ]

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      onChange(formik.values)
    },
  })

  return (
    <Wrapper>
      <TopRoundedWhiteCard
        title={t("Operation options")}
        className={"my-top-round-card"}
      >
        <div className={"operation-content"}>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Displaying contents")}
            </Col>
            <Col span={20} className={"operation-option"}>
              <Checkboxes
                value={formik.values.display_contents}
                options={DISPLAY_OPTIONS}
                onChange={(val) => {
                  formik.setFieldValue("display_contents", val)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} className={"caret-right operation-label"}>
              {t("Stamp setting")}
            </Col>
            <Col span={20} className={"operation-option multiple-input-row"}>
              <div className={"multiple-input-row-label"}>
                {t("【Entered By】")}
              </div>
              <SelectInput
                height={40}
                options={STAMP_SETTING_OPTIONS_STAFF}
                className={"min-auto-width"}
                value={formik.values.staff_stamp_setting}
                onChange={(val) => {
                  formik.setFieldValue("staff_stamp_setting", val)
                }}
              />
              <div className={"multiple-input-row-label"}>
                {t("【Parents】")}
              </div>
              <SelectInput
                height={40}
                options={STAMP_SETTING_OPTIONS_PARENT}
                className={"min-auto-width"}
                value={formik.values.parent_stamp_setting}
                onChange={(val) => {
                  formik.setFieldValue("parent_stamp_setting", val)
                }}
              />
            </Col>
          </Row>
          <div className={"change-display-container"}>
            <OwnerButton
              typeOf={"secondary"}
              text={t("Update display")}
              onClick={() => {
                formik.handleSubmit()
              }}
            />
          </div>
        </div>
      </TopRoundedWhiteCard>
    </Wrapper>
  )
}
