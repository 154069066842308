import { API } from "@project/shared"

export interface IReturnConsultationSupportOffice {
  count?: number
  data?: any
}

interface IConsultationSupportOfficeParam {
  page?: string
  pageSize?: string
}

export const fetchConsultationSupportOffice = async ({
  queryKey,
}): Promise<IReturnConsultationSupportOffice> => {
  const params = {} as IConsultationSupportOfficeParam

  params.page = queryKey[1]
  params.pageSize = queryKey[2] || 10
  return API.get(`/consultation_supports`, {
    params,
  })
}

export const fetchAllConstulationSupportOffice =
  async (): Promise<IReturnConsultationSupportOffice> => {
    const params = {} as IConsultationSupportOfficeParam
    params.pageSize = "Infinity"
    return API.get(`/consultation_supports`, {
      params,
    })
  }

export const getConsultationSupportOffice = async (id) => {
  return API.get(`/consultation_supports/${id}`)
}

export const createConsultationSupportOffice = async (values): Promise<any> => {
  return API.post(`/consultation_supports`, values)
}

export const updateConsultationSupportOffice = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/consultation_supports/${id}`, values)
}

export const deleteConsultationSupportOffice = async (id) => {
  return API.delete(`/consultation_supports/${id}`)
}
