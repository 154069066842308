import React from "react"

// packages
import { t } from "i18next"

// components
import { OwnerButton } from "../../../atoms"
import FormFieldsGen from "../../../molecules/FormFields"

// constants
import { usageRecordFormFieldSchema } from "../constants"

// types
import { IncrementalUsageRecordFormProps } from "../types"
import { japaneseAlphaRegex } from "../../../../utils/validation"

export function IncrementalUsageRecordForm(
  props: Omit<IncrementalUsageRecordFormProps, "bulkSettingForPeriodDate">
) {
  const {
    formik,
    uniqueKey,
    childOptions,
    lastFormChild,
    isChildListLoading,
    hasMoreThanOneForm,
    handleRewritingFormDeletion,
    handleRewritingFormIncrement,
    handleTemporarySave,
  } = props

  const componentRef = React.useRef(null)
  const { values } = formik
  const { furigana_sort } = values.multipleRewriteFormList[uniqueKey]

  const getChildOptions = (key) => {
    if (childOptions?.length > 0) {
      let options = childOptions?.filter((child) => {
        if (child.value == formik.values.multipleRewriteFormList[key].child_id)
          return true
        const uniqueKeys = Object.keys(formik.values.multipleRewriteFormList)
        for (let i = 0; i < uniqueKeys.length; i++) {
          if (
            uniqueKeys[i] != key &&
            formik.values.multipleRewriteFormList[uniqueKeys[i]].child_id ==
              child.value
          ) {
            // skip already selected children
            return false
          }
        }
        return true
      })
      if (furigana_sort) {
        const regex = japaneseAlphaRegex(furigana_sort)
        options = childOptions.filter(
          (child) =>
            child.furigana_name.match(regex) || child?.label?.match(regex)
        )
      }
      return [
        {
          label: "--",
          value: 0,
          furigana_name: "",
          used_days: [],
          extend_support_days: [],
        },
      ].concat(options)
    }
  }

  return (
    <div className={"form-container"} ref={componentRef}>
      <div className={"title-wrapper visible-only-in-print only-print"}>
        {<p className={"title"}>{t("Usage record rewriting form")}</p>}
      </div>
      {/* This FormFieldGen utility component generates 
      form fields based on the schema provided.
      It also handles the formik state and validation.
       */}
      <div className={"actual-form"}>
        {usageRecordFormFieldSchema({
          formik,
          uniqueKey,
          isChildListLoading,
          childOptions: getChildOptions(uniqueKey),
        }).map((item, i) => {
          return (
            <FormFieldsGen
              formik={formik}
              key={item.fieldLabel}
              item={item}
              index={i}
            />
          )
        })}
      </div>

      {/* Individual Form handling action button group starts here */}
      <div className={"usage-record-form-action-btn-group flexbox no-print"}>
        {lastFormChild ? (
          <OwnerButton
            text={t("Temporarily save")}
            typeOf={"print"}
            className={"print-btn"}
            onClick={handleTemporarySave}
          />
        ) : (
          <span style={{ marginRight: "auto" }}>&nbsp;</span>
        )}

        {/* Add child is not required unless the form is last child among 
        multiple forms or its the one and only form in the page */}
        {lastFormChild && (
          <button
            className={"add-child-btn flexbox"}
            onClick={() => {
              handleRewritingFormIncrement()
            }}
          >
            <img src={"/assets/icons/add-icon.svg"} alt={"add-icon"} />
            <span className={"add-child-btn-text"}>{t("Add child")}</span>
          </button>
        )}

        {/* We need at least one form to stay in the page thats why 
        we are not allowing to delete the last form.*/}
        {hasMoreThanOneForm && (
          <OwnerButton
            text={t("Delete")}
            typeOf={"secondary"}
            className={"delete-btn"}
            onClick={() => {
              handleRewritingFormDeletion(uniqueKey)
            }}
          />
        )}
      </div>
      {/* Individual Form handling action button group starts here */}
    </div>
  )
}
