const RedDownArrow = () => {
  return (
    <svg
      className={"red-down-facing-arrow"}
      width={"10"}
      height={"5"}
      viewBox={"0 0 10 5"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M9.22656 0.996094L5.13636 3.85608L1.01556 0.996094"}
        stroke={"#E00000"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export default RedDownArrow
