import styled from "styled-components"
import { OwnerTable } from ".."
import { useTranslation } from "react-i18next"
import { Checkbox } from "antd"
import { theme } from "@project/shared"

const Wrapper = styled.div`
  margin: 20px 0;
  width: 100%;
  .provision-info {
    margin: 5px 0;
    font-weight: 500;
    font-size: large;
  }
  .ant-table-content {
    min-width: 100%;
    overflow-x: auto;
    .ant-table-thead {
      .ant-table-cell {
        background: ${theme.gray2};
        &:before {
          background-color: #000000 !important;
        }
        &:first-child {
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }
    .ant-table-tbody {
      td {
        &:first-child {
          border-left: 1px solid ${theme.gray2};
        }
        border-right: 1px solid ${theme.gray2};
        border-bottom: 1px solid ${theme.gray2};
      }
      .checked-row {
        background: ${theme.yellow4};
      }
    }
  }
`

interface IUserReceiptTable {
  data: Array<any>
  handleCheck: (val, e) => void
}

export const InvoiceTable = ({ data, handleCheck }: IUserReceiptTable) => {
  const { t } = useTranslation()
  const columns = [
    {
      title: t("Uncheck all"),
      key: "uncheckAll",
      render: (row) => {
        return (
          <Checkbox
            checked={row?.checked}
            onChange={(e) => {
              handleCheck(row?.child_id, e)
            }}
          />
        )
      },
    },
    {
      title: t("Parent's name (furigana)"),
      key: "parentName",
      sorter: (a, b) => a?.parent_name > b?.parent_name,
      render: (row) => `${row?.parent_name} (${row?.parent_name_furigana})`,
    },
    {
      title: t("Childs name (furigana)"),
      key: "childName",
      sorter: (a, b) => a?.child_name > b?.child_name,
      render: (row) => `${row?.child_name} (${row?.child_name_furigana})`,
    },
    {
      title: t("Beneficiary certificate number"),
      key: "beneficieryNumber",
      sorter: (a, b) =>
        +a?.receiving_certificate_number > +b?.receiving_certificate_number,
      render: (row) => row?.receiving_certificate_number,
    },
    {
      title: t("Facility"),
      key: "facility",
      sorter: (a, b) => a?.facility_name > b?.facility_name,
      render: (row) => row?.facility_name_short,
    },
    {
      title: t("Billing Amount"),
      key: "billingAmount",
      sorter: (a, b) => a?.billed_amount > b?.billed_amount,
      render: (row) => row?.billed_amount.toLocaleString() + "円",
    },
  ]
  return (
    <Wrapper>
      <div className={"provision-info"}>
        {`${data?.[0]?.provision_city} ${
          data?.[0]?.provision_city_number &&
          `(${data?.[0]?.provision_city_number})`
        }`}
      </div>
      <OwnerTable
        columns={columns}
        dataSource={data}
        rowClassName={(row) => (row?.checked ? "checked-row" : "")}
        scroll={{ x: "max-content" }}
      />
    </Wrapper>
  )
}
