import React, { useEffect, useState } from "react"
import { ChildMonitoringContentMobileTable } from "../../molecules"
import { ChildMonitoringContent } from "../../organisms/ChildMonitoringContent"

interface IChildMonitoringContentTableProps {
  data?: any
  handleFieldChange: (a: any) => void
  isAprilChangeApplicable?: boolean
}

const MonitoringContentTable: React.FC<IChildMonitoringContentTableProps> = ({
  data = [],
  handleFieldChange,
  isAprilChangeApplicable = false,
}) => {
  const [screenWidth, setScreenWidth] = useState(1920)

  const resize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", resize)
  }, [])

  if (screenWidth > 767) {
    return (
      <ChildMonitoringContent
        data={data}
        handleFieldChange={handleFieldChange}
        isAprilChangeApplicable={isAprilChangeApplicable}
      />
    )
  }
  return (
    <ChildMonitoringContentMobileTable
      data={data}
      handleFieldChange={handleFieldChange}
    />
  )
}

export { MonitoringContentTable }
