import React, { useContext, useEffect, useRef, useState } from "react"
import { Menu, Layout } from "antd"
import styled from "styled-components"
import { useRouter } from "next/router"
import Link from "next/link"
import CollapseIcon from "../../../public/assets/icons/CollapseIcon.svg"
import EASYYLOGO from "../../../public/assets/icons/easyylogonew.svg"
import NotificationAlert from "../../../public/assets/icons/NotificationAlert.svg"
import { sideBarItems, hasPermissionForMenu } from "../../../utils/SidebarUtils"
import { AuthContext } from "../../../utils"
import { auth, theme } from "@project/shared"
import { useOutsideAlerter } from "../../../hooks/useDetectOutsideClick"
import { useTranslation } from "react-i18next"
import { LogoutOutlined } from "@ant-design/icons"
import { signOut } from "firebase/auth"

const { Sider } = Layout

interface SiderStyleProps {
  collapsed: boolean
}

const SiderStyled = styled(Sider)<SiderStyleProps>`
  &.faculty-sidebar {
    min-height: 100%;
    border-right: 0;
    background: #fdd95f;
    z-index: 9;
  }
  &.faculty-sidebar ul {
    background: #fdd95f;
    li.ant-menu-item-only-child {
      background-color: ${(props) => (props.collapsed ? "#fff" : "#feecaf")};
      min-width: ${(props) => (props.collapsed ? "350px" : "auto")};
    }
  }
  &.faculty-sidebar .ant-menu-submenu-open,
  &.faculty-sidebar .ant-menu-submenu-open ul {
    background-color: ${(props) => (props.collapsed ? "#fff" : "#feecaf")};
    li {
      min-width: ${(props) => (props.collapsed ? "350px" : "auto")};
    }
  }
  .faculty-sidebar-trigger {
    position: absolute;
    left: ${(props) => (props.collapsed ? "80px" : "280px")};
    top: 44px;
    width: 50px !important;
    background: #ffffff;
    color: #000000;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
  }
  .faculty-sidebar-trigger-rotate {
    transform: rotate(180deg);
  }
  .faculty-siderbar-header-container {
    background: #444748;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }
  .faculty-sidebar-header-title {
    margin-left: 5px;
  }
  &.faculty-sidebar .ant-menu-item-active,
  &.faculty-sidebar .ant-menu-item-selected,
  &.faculty-sidebar .ant-menu-submenu-active {
    background-color: #feecaf;
    color: #000000;
  }
  &.faculty-sidebar .ant-menu-item-selected::after {
    border: none;
  }
  .faculty-sidebar-menu .ant-menu-submenu-title {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .faculty-sidebar-menu-divider {
    margin-left: 16px !important;
    margin-right: 16px !important;
    border-color: #5c5f60;
  }

  .faculty-dashboard-bottom-menu-container {
    position: absolute;
    bottom: 35px;
    width: 100%;
    .logout-menu {
      display: none;
    }
    @media (max-width: 768px) {
      .logout-menu {
        display: block;
      }
    }
    @media (max-width: 412px) {
      .logout-menu {
        display: block;
        padding-bottom: 1rem;
      }
    }
  }

  .faculty-sidebar-menu-container {
    height: calc(100% - 150px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .faculty-dashboard-bottom-menu-container .ant-menu-item {
    display: ${(props) => (props.collapsed ? "block" : "flex")};
    align-items: center;
  }

  &.faculty-sidebar .ant-menu-item,
  &.faculty-sidebar .ant-menu-submenu-title {
    padding: ${(props) =>
      props.collapsed ? "0 calc(50% - 16px / 2);" : "5px 20px !important"};
  }
  &.faculty-sidebar .ant-menu-item:not(:last-child) {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  &.faculty-sidebar .ant-menu-sub .ant-menu-item {
    padding-left: 24px !important;
    padding-right: 0px !important;
    height: min-content;
  }
  &.faculty-sidebar .ant-menu-submenu-arrow {
    color: #000000 !important;
  }
  &.faculty-sidebar .ant-menu-title-content {
    font-size: 16px;
    div {
      white-space: break-spaces;
      line-height: 20px;
      padding: 10px;
    }
  }
  @media (max-width: 768px) {
    &.faculty-sidebar {
      /*considering address bar for mobile view*/
      height: calc(100vh - 40px);
      position: fixed;
      display: ${(props) => (props.collapsed ? "none" : "")};
    }
    .faculty-sidebar-trigger {
      display: none;
    }
    .faculty-siderbar-header-container {
      display: none;
    }
  }
  &.faculty-sidebar .ant-menu-submenu-title {
    height: 40px !important;
  }
  @media print {
    display: none;
  }
  .ant-menu-item-icon {
    min-width: 22px;
  }
  .sub-menu-link,
  .sub-menu-link:hover {
    color: inherit;
  }
`

const Sidebar: React.FC = () => {
  const {
    permissions,
    isOwner,
    openKeys,
    setOpenKeys,
    collapsed,
    activeMenuKeys,
    handleCollapse,
  } = useContext(AuthContext)
  const onOpenChange = (keys) => {
    const rootSubmenuKeys = sideBarItems?.map((val) => val?.key)
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  const [width, setWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const { push } = useRouter()
  addEventListener("resize", () => {
    setWidth(window.innerWidth)
  })

  const logOut = () => {
    signOut(auth)
    window.location.href = "/login"
  }

  useEffect(() => {
    if (width <= 768) {
      handleCollapse(true)
    }
  }, [width])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => handleCollapse(true), collapsed, width)
  const displayMenuItem = ({ key, link, label, isActive }) => (
    <Menu.Item
      key={key}
      onClick={() => {
        push(link)
      }}
    >
      <div
        style={{
          background: isActive ? theme.blue5 : "inherit",
          width: "94%",
          borderRadius: "12px",
          paddingLeft: "9px",
        }}
      >
        <Link href={link}>
          <a style={{ color: isActive ? theme.base : "inherit" }}>{label}</a>
        </Link>
      </div>
    </Menu.Item>
  )

  return (
    <SiderStyled
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={handleCollapse}
      width={280}
      className={"faculty-sidebar"}
      ref={wrapperRef}
    >
      <div
        className={"faculty-sidebar-trigger"}
        onClick={() => {
          handleCollapse(!collapsed)
        }}
      >
        <CollapseIcon
          className={collapsed ? "faculty-sidebar-trigger-rotate" : ""}
        />
      </div>
      <Link href={"/"}>
        <a>
          <div className={"faculty-siderbar-header-container"}>
            <div className={"sidebar-header-logo"}>
              <EASYYLOGO />
            </div>
            {!collapsed && (
              <span className={"faculty-sidebar-header-title"}></span>
            )}
          </div>
        </a>
      </Link>
      <Menu
        className={"faculty-sidebar-menu-container"}
        mode={"inline"}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {sideBarItems.map((sideMenu) => {
          if (
            isOwner ||
            !sideMenu.requiresPermission ||
            !sideMenu.hasTopMenuPermission ||
            hasPermissionForMenu(permissions, sideMenu.permissionKeys)
          )
            return sideMenu.children?.length > 0 ? (
              <>
                <Menu.SubMenu
                  key={sideMenu.key}
                  icon={sideMenu.icon}
                  title={sideMenu.label}
                  className={"faculty-sidebar-menu"}
                >
                  {!collapsed && (
                    <Menu.Divider className={"faculty-sidebar-menu-divider"} />
                  )}
                  {sideMenu.children.map((subMenu) => {
                    if (
                      isOwner ||
                      !subMenu.requiresPermission ||
                      hasPermissionForMenu(permissions, subMenu.permissionKeys)
                    ) {
                      if (subMenu?.children?.length > 0) {
                        return (
                          <Menu.SubMenu
                            key={subMenu.key}
                            title={subMenu.label}
                            style={{
                              paddingLeft: "12px",
                            }}
                          >
                            <Menu.Divider
                              className={"faculty-sidebar-menu-divider"}
                            />
                            {subMenu?.children?.map((nestedMenu) => {
                              return displayMenuItem({
                                key: nestedMenu.key,
                                label: nestedMenu.label,
                                link: nestedMenu.link,
                                isActive:
                                  activeMenuKeys?.length > 2 &&
                                  activeMenuKeys[2] == nestedMenu.key,
                              })
                            })}
                          </Menu.SubMenu>
                        )
                      }

                      return displayMenuItem({
                        key: subMenu.key,
                        label: subMenu.label,
                        link: subMenu.link,
                        isActive:
                          activeMenuKeys?.length > 1 &&
                          activeMenuKeys[1] == subMenu.key,
                      })
                    }
                  })}
                </Menu.SubMenu>
                <Menu.Divider className={"faculty-sidebar-menu-divider"} />
              </>
            ) : (
              <>
                <Menu.Item
                  key={sideMenu.key}
                  icon={sideMenu.icon}
                  onClick={() => push(sideMenu.link)}
                  style={{
                    background:
                      activeMenuKeys?.length > 0 &&
                      activeMenuKeys[0] == sideMenu.key
                        ? theme.yellowLight
                        : "inherit",
                  }}
                >
                  <Link href={sideMenu.link}>
                    <a className={"sub-menu-link"}>{sideMenu.label}</a>
                  </Link>
                </Menu.Item>
                <Menu.Divider className={"faculty-sidebar-menu-divider"} />
              </>
            )
        })}
      </Menu>
      <div className={"faculty-dashboard-bottom-menu-container"}>
        <Menu>
          <Menu.Divider className={"faculty-sidebar-menu-divider"} />
          <Menu.Item icon={React.createElement(NotificationAlert)} key={"help"}>
            {t("Help")}
          </Menu.Item>
        </Menu>
        <Menu className={"logout-menu"}>
          <Menu.Divider className={"faculty-sidebar-menu-divider"} />
          <Menu.Item icon={<LogoutOutlined />} key={"logout"} onClick={logOut}>
            {t("Logout")}
          </Menu.Item>
        </Menu>
      </div>
    </SiderStyled>
  )
}

export { Sidebar }
