import { SyncOutlined } from "@ant-design/icons"
import {
  Button,
  Checkbox,
  DeleteConfimationModal,
  dynamicLangString,
  isApril2024ChangeApplicable,
  SelectInput,
  TextField,
  theme,
} from "@project/shared"
import { notification, Typography } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import {
  MonitoringContentTable,
  OwnerButton,
  StyledFormWrapper,
} from "../../../components"
import { ALL_SERVICE_USE_OPTIONS } from "../../../constants/monitoring"
import { getAllChildUnpaginated } from "../../../services"
import { getChildDetail } from "../../../services/child"
import { getOneFacilityAdmins } from "../../../services/facility"
import { deleteMonitoring } from "../../../services/monitoring"
import { getActiveContractFacilities } from "../../../services/recurringScheduleSetting"
import {
  getDateTimeStringFromDate,
  scrollToFirstErrorField,
} from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { japaneseAlphaRegex } from "../../../utils/validation"
import { DatePicker } from "../../molecules/DatePicker"

const FormWrapper = styled.div<any>`
  width: 100%;
  margin-top: ${(props) => (props.mt ? "40px" : "0px")};
  .child-name-wrapper {
    display: flex;
    & > :first-child {
      margin-right: 20px;
    }
  }

  .facility-name-container {
    width: 100%;
    .facility-dropdown {
      max-width: 100%;
    }
  }

  p {
    margin: auto 0px;
  }

  .children-wrapper {
    width: calc(100% - 300px);
    .child-name-wrapper {
      width: 100%;
      max-width: 100%;
      flex-wrap: wrap;
      row-gap: 10px;
      .child-name-dropdown {
        max-width: 800px;
      }
    }
  }

  @media (max-width: 822px) {
    .child-name-wrapper .child-name-dropdown {
      min-width: 200px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .children-wrapper .alignment {
      margin-left: 18px;
    }
  }

  .error {
    color: ${theme?.red2};
  }

  .error-border {
    border: 1px solid ${theme.red2} !important;
  }

  .faculty-datepicker {
    width: 160px;
    height: 40px;
  }
  .no-created-wrapper {
    display: flex;
    align-items: center;
    & > :first-child {
      margin-right: 10px;
    }
    p {
      margin: auto 0px;
    }
  }
  @media (max-width: 822px) {
    .child-name-wrapper {
      & > :first-child {
        margin-right: 10px;
      }
      .large-select {
        width: 200px;
      }
    }
  }
  @media (max-width: 373px) {
    .child-name-wrapper {
      flex-direction: column;
      & > :first-child {
        margin-bottom: 10px;
      }
    }
  }
  .buttons-wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    button {
      margin-right: 38px;
    }
    .draft-button {
      margin-right: 0px;
      height: 40px;
      padding: 0px 30px;
      background-color: rgba(7, 130, 200, 0.2);
      color: black;
      border: 1px solid rgba(7, 130, 200, 1);
    }
    @media (max-width: 768px) {
      margin-bottom: ${(props) => (props.redundant ? "30px" : "0")};
      button {
        margin-right: 0px;
      }
      .redundant {
        display: flex;
      }
      margin-top: 0px;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        min-width: 120px;
        margin-bottom: 10px;
      }
      .draft-button {
        min-width: 190px;
      }
      .save-button {
        margin-inline: 38px;
      }
    }
    @media (max-width: 401px) {
      .cancel-button {
        margin-right: 10px;
      }
      .save-button {
        margin-inline: 0;
      }
    }
    @media (max-width: 355px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      .btn {
        width: 100%;
      }
      .cancel-button {
        margin-right: 0;
      }
      .draft-button {
        width: 100%;
      }
      .save-button {
        margin-inline: 0px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 965px) {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 12px;
    }
  }
  .redundant {
    display: none;
  }
  .delete_btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    .redundant {
      display: flex;
      margin-bottom: 30px;
    }
  }
`

interface IMonitoringFormProps {
  data?: any
  handleSave: (a: any) => void
  saveType?: string | undefined
  isEdit?: boolean
  setChildId?: (val: number) => void
  setCreateCount?: (val: number) => void
  isCountChanged?: boolean
  setIsCountChanged?: (val: boolean) => void
  isAlreadyCreated?: boolean
  createCount?: number | string
  operation?: "create" | "edt"
}

const MonitoringForm = ({
  data = undefined,
  handleSave,
  isEdit = false,
  saveType = undefined,
  setChildId,
  setCreateCount,
  isCountChanged,
  setIsCountChanged,
  isAlreadyCreated,
  createCount,
}: IMonitoringFormProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const id = router?.query?.id as string
  const { c_id } = router?.query
  const [open, setOpen] = useState(false)
  const { mutate: handleDelete, isLoading: isDeleting } = useMutation(
    deleteMonitoring,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Monitoring list",
            "Deleted Successfully",
          ]),
        })
        setOpen(false)
        router.push("/monitoring")
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const validationSchema = yup.object().shape({
    child_id: yup.string().required(t("required")),
    facility_id: yup.number().min(1).required(t("required")),
    service_type: yup.string().required(t("required")),
    create_count: yup.string().required(t("required")),
    report_create_date: yup.date().required(t("required")),
    child_monitoring_type: yup.string(),
    concern_hope: yup.string(),
    remark: yup.string(),
    family_hope: yup.string(),
    long_term_goal_study: yup.string(),
    child_hope: yup.string(),
    short_term_goal_study: yup.string(),
    draft_save_flag: yup.boolean(),
  })

  const initialValues = {
    child_id: data?.child_id || +c_id || null,
    furigana: isEdit ? undefined : FuriganaAlphabetsOption[0].value,
    facility_id: data?.facility_id || 0,
    service_type: data?.service_type ?? "",
    create_count: createCount || data?.create_count || 1,
    report_create_date: moment(data?.report_create_date).isValid()
      ? moment(data?.report_create_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    staff_id: data?.staff_id ?? 0,
    child_monitoring_type: data?.child_monitoring_type || 0,
    remark: data?.remark ?? "",
    family_hope: data?.family_hope ?? "",
    long_term_goal_study: data?.long_term_goal_study ?? "",
    child_hope: data?.child_hope ?? "",
    concern_hope: data?.concern_hope ?? "",
    short_term_goal_study: data?.short_term_goal_study ?? "",
    draft_save_flag: data?.draft_save_flag ?? false,
    child_monitoring_content: data?.child_monitoring_content || [],
    long_term: data?.long_term || "",
    short_term: data?.short_term || "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (data) => {
      handleSave({
        ...data,
        create_count: +createCount || +data.create_count,
        report_create_date: moment(data?.report_create_date)
          .set({
            hour: moment().hour(),
            minute: moment().minute(),
          })
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      })
    },
  })

  const isAprilChangeApplicable = useMemo(() => {
    return (
      formik.values.report_create_date &&
      moment(formik.values.report_create_date).isValid() &&
      isApril2024ChangeApplicable(moment(formik.values.report_create_date))
    )
  }, [formik.values.report_create_date])

  const { data: facilityOptions, isLoading: isFacilityLoading } = useQuery({
    queryKey: ["active_facilities", formik.values?.child_id],
    queryFn: () =>
      getActiveContractFacilities({
        query: `${`?child_id=${data?.child_id}`}`,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    enabled: data?.child_id ? true : false,
    select: (resp) =>
      resp?.data?.map((fac) => {
        return {
          label: fac?.facility?.facility_name_short,
          value: fac?.facility?.id,
        }
      }),
    onSuccess: (data) => {
      if (data?.length > 0) {
        formik.setFieldValue("facility_id", data?.[0]?.value)
      } else {
        formik.setFieldValue("facility_id", null)
      }
    },
  })

  const {
    data: individualChildDetails,
    isLoading: isIndividualChildDetailsLoading,
    isFetching: isIndividualChildDetailsFetching,
  } = useQuery<any>({
    queryKey: ["fetch-individual-child-details", formik.values?.child_id],
    queryFn: () =>
      getChildDetail(formik.values?.child_id && formik.values?.child_id),
    select: (response) => {
      return {
        recipient_number: response?.data?.child
          .receiving_certificate_number as string,
        facility_id: response?.data?.child?.facility_id as number,
        service_type: response?.data?.child?.service_type as number,
        ...response?.data?.child,
      }
    },
    cacheTime: 0,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: false,
    enabled: !!formik.values?.child_id,
  })

  const { values, handleChange, handleBlur, setFieldValue, errors, touched } =
    formik

  //fetch all children only if new monitoring creating
  const { data: ALLCHILDRENOPTIONS, isLoading: allChildrenLoading } = useQuery(
    ["fetchAllChild", "infinity"],
    getAllChildUnpaginated,
    {
      cacheTime: 0,
      retry: 1,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data.map((child) => ({
          label: child.child_name,
          value: child.id,
          furigana: child.child_name_furigana,
          facility_id:
            child?.child_receiving_certificates[0]?.facility_id || null,
        }))
      },
      onSuccess: (data) => {
        // redirect to back if child id is not valid
        if (
          c_id !== undefined &&
          c_id !== "" &&
          data.findIndex((child) => child.value === Number(c_id)) < 0
        ) {
          router.back()
        }
      },
    }
  )

  const { isLoading: isAllPlannerLoading, data: ALLPLANNEROPTIONS } = useQuery({
    queryKey: ["getFacilityAdmin", !!formik?.values?.facility_id],
    queryFn: () => getOneFacilityAdmins(formik?.values?.facility_id),
    enabled: !!formik?.values?.facility_id,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    select: ({ data }) =>
      data.map((staff) => ({
        label: staff?.staff_name,
        value: staff?.id,
      })),
  })

  const FILTERED_CHILD_BY_FURIGANA = useMemo(() => {
    const regex = japaneseAlphaRegex(values?.furigana)

    if (!values.furigana) {
      return ALLCHILDRENOPTIONS
    }

    return ALLCHILDRENOPTIONS?.filter(({ label, value, furigana }) => {
      if (label.match(regex) || furigana?.match(regex)) {
        return {
          label,
          value,
        }
      }
    })
  }, [values?.furigana, ALLCHILDRENOPTIONS, isEdit])
  ALLCHILDRENOPTIONS?.unshift({
    value: 0,
    label: "---",
  })

  const handleFieldsChange = ({ fieldName, value }) => {
    setFieldValue(fieldName, value)
  }

  const handleChildMonitoringContentChange = ({
    id,
    fieldName,
    value,
    index,
  }) => {
    const _values = values.child_monitoring_content?.map((each) => {
      if (each.child_monitoring_item_id === id) {
        const existingValuesForFied = each?.[fieldName]
        let newValue = []
        if (existingValuesForFied?.length > index) {
          newValue = [].concat(existingValuesForFied)
        } else {
          newValue = Array(index + 1).fill(typeof value == "number" ? 0 : "")
          existingValuesForFied?.forEach((val, i) => {
            newValue[i] = val
          })
        }
        newValue[index] = value

        return {
          ...each,
          [fieldName]: newValue,
        }
      }
      return each
    })
    setFieldValue("child_monitoring_content", _values)
  }

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  useEffect(() => {
    if (!isIndividualChildDetailsLoading && !isEdit) {
      if (+individualChildDetails?.service_type > 0)
        formik.setFieldValue(
          "service_type",
          +individualChildDetails?.service_type
        )
      else formik.setFieldValue("service_type", null)
    }
  }, [isIndividualChildDetailsLoading])

  return (
    <>
      <FormWrapper>
        <div className={"buttons-wrapper redundant"}>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Cancel")}
            className={"btn cancel-button"}
            onClick={() => {
              router.back()
            }}
          />
          <OwnerButton
            typeOf={"primary"}
            type={"submit"}
            text={t("Save")}
            className={"btn save-button"}
            isLoading={saveType === "save"}
            disabled={isAlreadyCreated || isCountChanged}
            onClick={() => {
              formik.setFieldValue("draft_save_flag", false)
              formik.handleSubmit()
            }}
          />
          <Button
            className={"draft-button"}
            loading={saveType === "draft"}
            disabled={isAlreadyCreated || isCountChanged}
            onClick={() => {
              formik.setFieldValue("draft_save_flag", true)
              formik.handleSubmit()
            }}
          >
            {t("Save as Draft")}
          </Button>
        </div>
        {isEdit && (
          <div className={"delete_btns"}>
            <OwnerButton
              typeOf={"secondary"}
              text={t("Delete")}
              className={"btn cancel-button"}
              onClick={() => setOpen(true)}
            />
          </div>
        )}

        <StyledFormWrapper isRequired label={t("Child name")}>
          <div className={"child-name-wrapper"}>
            <SelectInput
              height={40}
              width={80}
              name={"furigana"}
              className={getErrorClass("furigana", "input")}
              value={values?.furigana || null}
              placeholder={"---"}
              onChange={(value) => {
                setFieldValue("child_id", undefined)
                handleFieldsChange({ fieldName: "furigana", value })
              }}
              options={FuriganaAlphabetsOption}
              onBlur={handleBlur}
              disabled={isEdit && data?.child_id}
            />
            {!allChildrenLoading && (
              <SelectInput
                height={40}
                width={"auto"}
                required
                className={
                  "large-select child-name-dropdown dynamic-dropdown-width min-w-300"
                }
                error={
                  touched?.child_id &&
                  errors.child_id &&
                  t("Child name is required")
                }
                value={ALLCHILDRENOPTIONS?.length ? values.child_id : null}
                onChange={(value) => {
                  setChildId(+value)
                  formik.setFieldValue("child_monitoring_content", null)
                  handleFieldsChange({ fieldName: "child_id", value })
                  setCreateCount(null)
                }}
                name={"child_id"}
                options={FILTERED_CHILD_BY_FURIGANA}
                placeholder={t("------")}
                loading={allChildrenLoading}
                disabled={isEdit ? (isEdit && data?.child_id) || !!c_id : false}
              />
            )}
          </div>
        </StyledFormWrapper>
        <StyledFormWrapper isRequired label={t("Facility name")}>
          <div className={"facility-name-container"}>
            {values?.child_id ? (
              !isFacilityLoading && facilityOptions?.length == 0 ? (
                <div style={{ textAlign: "left" }}>
                  {t("There is no contract for all facilities")} <br />
                  <Link
                    href={`/child/profile/${data?.child_id}/recipient-certificate-form`}
                  >
                    <a
                      target={"_blank"}
                      style={{
                        textDecoration: "underline",
                        color: theme.blue5,
                      }}
                    >
                      {"▶"}
                      {t("Confirm the contracted amount")}
                    </a>
                  </Link>
                </div>
              ) : (
                <SelectInput
                  name={"facility_id"}
                  height={40}
                  loading={allChildrenLoading || isFacilityLoading}
                  disabled={!values?.child_id}
                  width={"auto"}
                  required
                  placeholder={t("All")}
                  className={
                    "large-select facility-dropdown dynamic-dropdown-width min-w-300"
                  }
                  value={values.facility_id || null}
                  error={
                    touched?.facility_id &&
                    errors.facility_id &&
                    t("Facility is required")
                  }
                  onChange={(value) => {
                    handleFieldsChange({ fieldName: "facility_id", value })
                  }}
                  options={facilityOptions}
                />
              )
            ) : (
              <>
                <span className={"alignment"}>{t("Select child first")}</span>
              </>
            )}
          </div>
        </StyledFormWrapper>
        <StyledFormWrapper isRequired label={t("Service use")}>
          {values?.facility_id ? (
            <SelectInput
              height={40}
              disabled={!values?.child_id}
              width={300}
              required
              error={
                touched?.service_type &&
                !isIndividualChildDetailsLoading &&
                !isIndividualChildDetailsFetching &&
                errors.service_type &&
                t("Required")
              }
              className={"large-select"}
              value={values.service_type}
              onChange={(value) => {
                handleFieldsChange({ fieldName: "service_type", value })
              }}
              name={"service_type"}
              placeholder={"---"}
              options={ALL_SERVICE_USE_OPTIONS}
              onBlur={handleBlur}
            />
          ) : !isFacilityLoading && facilityOptions?.length == 0 ? (
            <div style={{ textAlign: "left" }}>
              {t(
                "Since there is no contract for all facilities, it is not possible to select the service to be used"
              )}
              <br />
              <Link
                href={`/child/profile/${data?.child_id}/recipient-certificate-form`}
              >
                <a
                  target={"_blank"}
                  style={{
                    textDecoration: "underline",
                    color: theme.blue5,
                  }}
                >
                  {"▶"}
                  {t("Confirm the contracted amount")}
                </a>
              </Link>
            </div>
          ) : (
            <>
              <span className={"alignment"}>{t("Select facility first")}</span>
            </>
          )}
        </StyledFormWrapper>
        <StyledFormWrapper isRequired label={t("No. created")}>
          <div>
            {isAlreadyCreated ? (
              <Typography.Text
                style={{
                  color: "#f00",
                  lineHeight: 1.3,
                  fontSize: "14px",
                }}
              >
                {t("Number of times already created")}
              </Typography.Text>
            ) : null}

            <div className={"no-created-wrapper"}>
              <TextField
                name={"create_count"}
                width={"80px"}
                height={"40px"}
                type={"number"}
                padding={"5px 5px"}
                bgcolor={"transparent"}
                error={errors.create_count ? true : false}
                value={createCount || values.create_count}
                disabled={isCountChanged}
                min={1}
                onChange={({ target: { value } }) => {
                  setIsCountChanged(true)
                  setCreateCount(+value)

                  handleFieldsChange({
                    fieldName: "create_count",
                    value: +value,
                  })
                }}
              />
              {isCountChanged && (
                <SyncOutlined spin style={{ marginRight: "0.5rem" }} />
              )}
              <p>{t("回目")}</p>
            </div>
          </div>
        </StyledFormWrapper>
        <StyledFormWrapper isRequired label={t("Created date")}>
          <div className={"created-date-wrapper"}>
            <DatePicker
              name={"report_create_date"}
              placeholder={""}
              className={`custom-date-picker ${errors?.report_create_date}`}
              date={moment(formik.values.report_create_date)}
              onDateChange={(date) => {
                setFieldValue(
                  "report_create_date",
                  getDateTimeStringFromDate(new Date(date.format("YYYY-MM-DD")))
                )
              }}
              inputReadOnly
              format={"YYYY年MM月DD日"}
            />
            <p className={"error"}>
              {errors?.report_create_date
                ? t("Report create date is required")
                : ""}
            </p>
          </div>
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Planner")}>
          {values?.facility_id ? (
            <SelectInput
              width={300}
              height={40}
              defaultValue={"-----"}
              value={values?.staff_id || null}
              onChange={(value) => {
                handleFieldsChange({ fieldName: "staff_id", value })
              }}
              options={ALLPLANNEROPTIONS}
              loading={isAllPlannerLoading}
              name={"planner"}
            />
          ) : (
            <>
              <span className={"alignment"}>{t("Select facility first")}</span>
            </>
          )}
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Monitoring type")}>
          <Checkbox
            label={t("Alternative Support")}
            checked={values.child_monitoring_type === 1 ? true : false}
            onChange={(e) => {
              handleFieldsChange({
                fieldName: "child_monitoring_type",
                value: e?.target?.checked ? 1 : 0,
              })
            }}
            name={"child_monitoring_type"}
          />
        </StyledFormWrapper>
      </FormWrapper>

      <FormWrapper>
        <MonitoringContentTable
          data={values?.child_monitoring_content}
          handleFieldChange={handleChildMonitoringContentChange}
          isAprilChangeApplicable={isAprilChangeApplicable}
        />
      </FormWrapper>

      <FormWrapper mt>
        <StyledFormWrapper label={t("Long term goal")}>
          <p>{values?.long_term || ""}</p>
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Consideration for long-term goals")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            placeholder={t(
              " 例：自分自身が行わなければならないことを考え、自信を持って取り組めるようにする。"
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            width={"100%"}
            rows={5}
            name={"long_term_goal_study"}
            value={values.long_term_goal_study}
          />
        </StyledFormWrapper>
        <StyledFormWrapper label={t("short term goal")}>
          <p>{values?.short_term || ""}</p>
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Consideration for short term goal")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            placeholder={t(
              " 例：自分自身が行わなければならないことを考え、自信を持って取り組めるようにする。"
            )}
            width={"100%"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"short_term_goal_study"}
            value={values.short_term_goal_study}
          />
        </StyledFormWrapper>
      </FormWrapper>
      <FormWrapper mt>
        <StyledFormWrapper label={t("Self hope")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            rows={2}
            width={"100%"}
            name={"child_hope"}
            className={"over-rider-text-area-class"}
            value={values.child_hope}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Family hope")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            rows={2}
            width={"100%"}
            value={values.family_hope}
            name={"family_hope"}
            className={"over-rider-text-area-class"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </StyledFormWrapper>
        <StyledFormWrapper label={t("Relatives hope")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            rows={2}
            width={"100%"}
            value={values.concern_hope}
            name={"concern_hope"}
            className={"over-rider-text-area-class"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </StyledFormWrapper>
      </FormWrapper>
      <FormWrapper mt>
        <StyledFormWrapper label={t("Remark columns")}>
          <TextField
            type={"textarea"}
            bgcolor={"white"}
            rows={5}
            width={"100%"}
            value={values.remark}
            className={"over-rider-text-area-class"}
            name={"remark"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </StyledFormWrapper>
        <div className={"buttons-wrapper"}>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Cancel")}
            className={"btn cancel-button"}
            onClick={() => {
              router.back()
            }}
          />
          <OwnerButton
            typeOf={"primary"}
            type={"submit"}
            text={t("Save")}
            className={"btn save-button"}
            isLoading={saveType === "save"}
            disabled={isAlreadyCreated || isCountChanged}
            onClick={() => {
              formik.setFieldValue("draft_save_flag", false)
              formik.handleSubmit()
            }}
          />
          <Button
            className={"draft-button"}
            loading={saveType === "draft"}
            disabled={isAlreadyCreated || isCountChanged}
            onClick={() => {
              formik.setFieldValue("draft_save_flag", true)
              formik.handleSubmit()
            }}
          >
            {t("Save as Draft")}
          </Button>
        </div>
      </FormWrapper>
      {open && (
        <DeleteConfimationModal
          open={open}
          onCancel={() => setOpen(false)}
          onOk={() => handleDelete(id)}
          isLoading={isDeleting}
          style={{ marginTop: "10rem", maxWidth: "20rem" }}
        />
      )}
    </>
  )
}

export { MonitoringForm }
