import { ComponentProps, ReactNode } from "react"
import { ReceiptUpperLimitManagementItem } from "../../../../types"
import { t } from "i18next"
import { maskName } from "../../common/utils"

export type Row = {
  line_no: number | string
  facility_number: string
  facility_name: string
  total_amount: number | string
  burden_amount: number | string
  management_result_burden_amount: number | string
  receiving_certificate_number: string
  child_name: string
}

export const TABLE_HEADS = [
  {
    // 項番
    key: "1",
    title: t("S.N"),
  },
  {
    key: "2",
    title: t("Beneficiary no."),
  },
  {
    key: "3",
    title: t("Child name"),
  },
  {
    // 事業所番号
    key: "3",
    // TODO: translate
    title: "事業所番号",
  },
  {
    // 事業所名称
    key: "4",
    title: t("Business official name"),
  },
  {
    // 総費用額
    key: "5",
    // TODO: translate
    title: "総費用額",
  },
  {
    // 利用者負担額
    key: "6",
    title: t("User’s burden amount"),
  },
  {
    // 管理結果後利用者負担額
    key: "7",
    title: t("User burden after management results"),
  },
]

type ContentProps = {
  data: string
} & ComponentProps<"div">

export function Content({ data = "", ...props }: ContentProps) {
  return (
    <div className={"facility_total_amount"} {...props}>
      {data.split("")?.map((v, index) => (
        <div key={index} className={"value"}>
          {v !== "." ? v : ""}
        </div>
      ))}
    </div>
  )
}

export type TableColumn = {
  title?: number
  align?: "left" | "center" | "right"
  width?: number
  render: (
    row: ReceiptUpperLimitManagementItem,
    header: { key: number; title: string },
    title: number
  ) => ReactNode
}

export function generateDynamicColumn(
  facilities: Row[],
  printConfig: string[]
): any[] {
  const columns: TableColumn[] = []
  facilities.map((value) => {
    columns.push({
      width: 180,
      align: "center",
      render: (_, __, idx) => {
        return getColumnInfo(value, idx, printConfig)
      },
    })
  })

  return columns
}

enum BodyRow {
  LINE_NO,
  BENEFICIARY_NUMBER,
  CHILD_NAME,
  FACILITY_NUMBER,
  FACILITY_NAME,
  TOTAL_AMOUNT,
  BURDEN_AMOUNT,
  MANAGEMENT_RESULT_BURDEN_AMOUNT,
}

export function getColumnInfo(row: Row, index: number, printConfig: string[]) {
  // 項番
  if (index === BodyRow.LINE_NO) {
    return row.line_no
  }
  // 受給者証番号
  if (index === BodyRow.BENEFICIARY_NUMBER) {
    return row.receiving_certificate_number
  }
  // 児童名
  if (index === BodyRow.CHILD_NAME) {
    return maskName(row.child_name, printConfig)
  }
  // 事業所番号
  if (index === BodyRow.FACILITY_NUMBER) {
    return row.facility_number
  }
  // 事業所名
  if (index === BodyRow.FACILITY_NAME) {
    return row.facility_name
  }
  // 合計金額
  if (index === BodyRow.TOTAL_AMOUNT) {
    return <Content data={String(row.total_amount ?? "").padStart(6, ".")} />
  }
  // 利用者負担額
  if (index === BodyRow.BURDEN_AMOUNT) {
    return <Content data={String(row.burden_amount ?? "").padStart(6, ".")} />
  }
  // 管理結果後利用者負担額
  if (index === BodyRow.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_burden_amount ?? "").padStart(
          6,
          "."
        )}
      />
    )
  }
  return ""
}

enum TotalRow {
  TOTAL_LABEL = 0,
  TOTAL_AMOUNT = 5,
  TOTAL_BURDEN_AMOUNT = 6,
  MANAGEMENT_RESULT_BURDEN_AMOUNT = 7,
}

export function getTotalColumnInfo(
  totalAmount: number,
  totalBurdenAmount: number,
  managementResultBurdenAmount: number,
  index: number
) {
  if (index === TotalRow.TOTAL_LABEL) {
    return t("Total")
  }
  if (index === TotalRow.TOTAL_AMOUNT) {
    return <Content data={String(totalAmount ?? "").padStart(6, ".")} />
  }
  if (index === TotalRow.TOTAL_BURDEN_AMOUNT) {
    return <Content data={String(totalBurdenAmount ?? "").padStart(6, ".")} />
  }
  if (index === TotalRow.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(managementResultBurdenAmount ?? "").padStart(6, ".")}
      />
    )
  }
  return ""
}

export function padEmptyFacilities(facilities: Row[], length: number) {
  let count = length - facilities.length
  if (length <= 0) {
    return facilities
  }
  while (count > 0) {
    facilities.push({
      line_no: "",
      facility_number: "",
      facility_name: "",
      total_amount: "",
      burden_amount: "",
      management_result_burden_amount: "",
      receiving_certificate_number: "",
      child_name: "",
    })
    count--
  }
  return facilities
}

export function makeEmptyCell(length: number) {
  return (
    <div className={"facility_total_amount"}>
      {Array(length)
        .fill(" ")
        .map((v, index) => (
          <div key={index} className={"value"}>
            {v !== "." ? v : ""}
          </div>
        ))}
    </div>
  )
}

export function makeEmptyRowData() {
  return {
    line_no: "",
    facility_number: "",
    facility_name: "",
    total_amount: "",
    insurance_amount: "",
    burden_amount: "",
    management_result_burden_amount: "",
    management_result_insurance_amount: "",
    local_exemption: "",
    local_burden_amount: "",
  }
}
