/**
 * NOTE:
 * - R6 April [established:新設]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"
import {
  AfterSchoolDayServiceRevenueCondition1,
  ChildDevelopmentSupportRevenueCondition1,
  ServiceType,
} from "./types"

type Params = {
  name: string
  service: number
  condition: number
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getCoreFunctionEnhancementSiteAddition(
  date: Moment,
  params: Params
) {
  /**
   * NOTE
   * ja: 児童発達支援センター では 表示しない
   * en: Do not show for "Child Development Support Center"
   */
  if (
    params.service === ServiceType.CHILD_DEVELOPMENT_SUPPORT &&
    params.condition ===
      ChildDevelopmentSupportRevenueCondition1.CHILD_DEVELOPMENT_SUPPORT_CENTER
  ) {
    return null
  }

  /**
   * NOTE
   * ja: 共生型放課後等デイサービス給付費 では 表示しない
   * en: Do not show for "Symbiosis-type after-school day care service benefit expenses"
   */
  if (
    params.service === ServiceType.AFTER_SCHOOL_DAY_SERVICE &&
    params.condition ===
      AfterSchoolDayServiceRevenueCondition1.SYMBIOSIS_TYPE_AFTER_SCHOOL_DAY_CARE_SERVICE_BENEFIT_EXPENSES
  ) {
    return null
  }

  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return null
    default:
      return r6April(params.name)
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

function r6April(name: string): FormFieldsProp {
  return {
    // TODO: translate
    fieldLabel: "中核機能強化事業所加算",
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: [
              {
                label: t("No"),
                value: "0",
              },
              {
                label: t("Yes"),
                value: "1",
              },
            ],
          },
        },
      },
    ],
  }
}

/**
 * --------------------------------
 * @version BASELINE
 *
 * NONE
 * --------------------------------
 */
