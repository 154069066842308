import { SelectInput, theme } from "@project/shared"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button } from "../../atoms"
import { AttendanceScheduleTimeRangeInput } from "../../molecules"

const Wrapper = styled.div`
  cursor: auto !important;
  width: 100%;
  .shift-select {
    display: flex;
    justify-content: center;
  }
  .schedule-container {
    margin: 20px 0;
    background: #dcf4ff;
    border-radius: 5px;
    .ant-select {
      max-width: 45px;
    }
  }
  .inner-schedule-container {
    padding: 10px 5px;
  }
  button {
    padding: 8px 10px;
    margin: auto;
  }
  &.holiday-wrapper {
    display: flex;
    height: 100%;
    background: rgba(224, 0, 0, 0.1);
    div {
      visibility: hidden;
    }
  }
  &.blank-wrapper {
    display: flex;
    height: 100%;
    div {
      visibility: hidden;
    }
  }
  .schedule-button {
    padding-bottom: 20px;
    button {
      padding: 8px 6px;
      white-space: normal;
      max-width: 100%;
      font-size: 10px;
      div {
        margin-right: 4px;
      }
    }
  }
`

interface IScheduleButton {
  type: "remove" | "add"
}

export type CalendarHours = {
  id: number
  shift_id: number
  from: {
    hours: string
    minutes: string
  }
  to: {
    hours: string
    minutes: string
  }
  break_time: number
}

export const AttendanceScheduleShiftTimeRange = ({
  scheduleOptions,
  currentDateString = null,
  monthlyStaffData = null,
  calendarData = null,
  setCalendarData = null,
  weeklyScheduleData = { id: 0 },
  isHoliday = false,
  renderBlank = false,
}) => {
  const { t } = useTranslation()
  /* added unwanted divs to make it identical with schedule dates in print page */
  if (isHoliday || renderBlank)
    return (
      <Wrapper
        className={`attendance-content ${
          isHoliday ? "holiday-wrapper" : "blank-wrapper"
        }`}
      >
        <div className={"shift-select"}>
          <SelectInput
            width={120}
            height={40}
            className={"left-align"}
            padding={"0px 5px"}
          />
        </div>
        <div className={"schedule-container"}></div>
      </Wrapper>
    )

  const scheduleObject = {}

  scheduleOptions?.map((item) => {
    scheduleObject[item.id] = item
  })

  const scheduleOptionsWithReset: any = [
    {
      id: 0,
      attendance_start_time: null,
      attendance_end_time: null,
      attendance_rest_minits_2: 0,
      attendance_rest_minits: 0,
      attendance_start_time_2: null,
      attendance_end_time_2: null,
      attendance_shift_id: 0,
      label: "---",
      value: 0,
    },
  ].concat(scheduleOptions)

  const initialValues = {
    from: {
      hours: "",
      minutes: "",
    },
    shift_id: null,
    to: {
      hours: "",
      minutes: "",
    },
    break_time: null,
  }

  const possibleScheduleIds = [1, 2]

  const getHours = () => {
    const shiftHours = []
    if (
      !monthlyStaffData[currentDateString] ||
      monthlyStaffData[currentDateString]?.deleted_datetime ||
      !monthlyStaffData[currentDateString]?.attendance_shift?.id
    )
      return []

    for (let i = 1; i <= possibleScheduleIds.length; i++) {
      const keySuffix = i === 1 ? "" : "2"
      const START_HOUR = monthlyStaffData[currentDateString]
        ? monthlyStaffData[currentDateString][
            `attendance_start_time${keySuffix}`
          ]?.split(":")[0]
        : null
      const START_MINUTE = monthlyStaffData[currentDateString]
        ? monthlyStaffData[currentDateString][
            `attendance_start_time${keySuffix}`
          ]?.split(":")[1]
        : null
      const END_HOUR = monthlyStaffData[currentDateString]
        ? monthlyStaffData[currentDateString][
            `attendance_end_time${keySuffix}`
          ]?.split(":")[0]
        : null
      const END_MINUTE = monthlyStaffData[currentDateString]
        ? monthlyStaffData[currentDateString][
            `attendance_end_time${keySuffix}`
          ]?.split(":")[1]
        : null
      const BREAK_TIME = monthlyStaffData[currentDateString]
        ? monthlyStaffData[currentDateString][
            `attendance_rest_minits${keySuffix}`
          ]
        : null
      if (START_HOUR || END_HOUR)
        shiftHours.push({
          id: i,
          shift_id: monthlyStaffData[currentDateString]
            ? monthlyStaffData[currentDateString].attendance_shift?.id
            : null,
          from: {
            hours: START_HOUR ?? null,
            minutes: START_MINUTE ?? null,
          },
          to: {
            hours: END_HOUR ?? null,
            minutes: END_MINUTE ?? null,
          },
          break_time: BREAK_TIME ?? null,
        })
    }

    return shiftHours
  }

  const [shiftHours, setShiftHours] = useState<Array<CalendarHours>>(getHours())
  const [selectedShift, setSelectedShift] = useState(
    monthlyStaffData?.[currentDateString]?.attendance_shift?.id || null
  )

  const onTimeRangeChange = ({ id, range, unit, value }: any) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      [range]: {
        ...data[index][range],
        [unit]: value,
      },
    }
    if (range === "break_time") {
      data[index][range] = Number(value)
    }
    setShiftHours(data)
  }

  const addNewSchedule = () => {
    setShiftHours([
      ...shiftHours,
      { id: shiftHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastSchedule = () => {
    const data = shiftHours?.splice(-1)[0]
    setShiftHours(shiftHours?.filter((v) => v.id !== data.id))
  }

  const ScheduleButton = ({ type }: IScheduleButton) => {
    const { t } = useTranslation()
    return (
      <div className={"schedule-button"}>
        <Button
          text={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"#ffffff"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewSchedule() : removeLastSchedule()
          }}
        />
      </div>
    )
  }

  const setTimeFromSchedule = (scheduleId) => {
    const scheduleData = scheduleOptionsWithReset.find(
      (schedule) => schedule?.id == scheduleId
    )

    const getShiftData = (startKey, endKey, breakKey) => {
      if (!scheduleData) return null
      const startTime = scheduleData[startKey]?.split(":")
      const endTime = scheduleData[endKey]?.split(":")
      const breakTime = scheduleData[breakKey]

      if (startTime?.length < 2 && endTime?.length < 2 && !breakTime)
        return null

      return {
        from: {
          hours: startTime && startTime[0],
          minutes: startTime && startTime[1],
        },
        to: {
          hours: endTime && endTime[0],
          minutes: endTime && endTime[1],
        },
        break_time: breakTime,
      }
    }

    const newShifts = []

    if (
      scheduleId != 0 &&
      scheduleData &&
      scheduleData.number_of_employees_include_flg != 1
    ) {
      possibleScheduleIds.map((id) => {
        const startKey =
          id > 1 ? "attendance_start_time_2" : "attendance_start_time"
        const endKey = id > 1 ? "attendance_end_time_2" : "attendance_end_time"
        const breakKey =
          id > 1 ? "attendance_rest_minits_2" : "attendance_rest_minits"
        const shiftHour = getShiftData(startKey, endKey, breakKey)
        if (shiftHour) newShifts.push({ ...shiftHour, id })
      })
    }
    setSelectedShift(scheduleId)
    setShiftHours(newShifts)
  }

  useEffect(() => {
    if (monthlyStaffData[currentDateString]) {
      if (!monthlyStaffData[currentDateString]?.deleted_datetime)
        setSelectedShift(
          monthlyStaffData[currentDateString].attendance_shift?.id
        )
    } else if (!isHoliday && weeklyScheduleData?.id) {
      const shiftId = weeklyScheduleData?.id
      setSelectedShift(shiftId)
      setTimeFromSchedule(shiftId)
    }
  }, [])

  useEffect(() => {
    const updatedData = calendarData
    const monthlyData = monthlyStaffData[currentDateString]
      ? monthlyStaffData[currentDateString]
      : {}
    updatedData[currentDateString] = {
      ...monthlyData,
      date: monthlyData?.date || moment(currentDateString).utcOffset(0, true),
      attendance_shift_id: selectedShift,
      attendance_start_time:
        shiftHours[0]?.from?.hours && shiftHours[0]?.from?.minutes
          ? `${shiftHours[0]?.from?.hours}:${shiftHours[0]?.from?.minutes}`
          : null,
      attendance_end_time:
        shiftHours[0]?.to?.hours && shiftHours[0]?.to?.minutes
          ? `${shiftHours[0]?.to?.hours}:${shiftHours[0]?.to?.minutes}`
          : null,
      attendance_rest_minits: shiftHours[0]?.break_time,
      attendance_start_time2: shiftHours[1]
        ? `${shiftHours[1]?.from?.hours}:${shiftHours[1]?.from?.minutes}`
        : "",
      attendance_end_time2: shiftHours[1]
        ? `${shiftHours[1]?.to?.hours}:${shiftHours[1]?.to?.minutes}`
        : "",
      attendance_rest_minits2: shiftHours[1] ? shiftHours[1]?.break_time : 0,
      is_deleted: selectedShift == 0 ? true : false,
    }

    setCalendarData({ ...updatedData })
  }, [shiftHours])

  return (
    <Wrapper className={"attendance-content"}>
      <div className={"shift-select"}>
        <SelectInput
          placeholder={"---"}
          options={scheduleOptionsWithReset}
          value={selectedShift}
          onChange={(val) => setTimeFromSchedule(val)}
          width={120}
          height={40}
          className={"left-align"}
          padding={"0px 5px"}
        />
      </div>
      <div className={"schedule-container"}>
        {shiftHours.map((range, index) => (
          <div key={index} className={"inner-schedule-container"}>
            <AttendanceScheduleTimeRangeInput
              key={range?.id}
              range={range}
              rangeName={`${t("Working hours")} ${range?.id}`}
              handleChange={(val) => {
                onTimeRangeChange(val)
              }}
            />
          </div>
        ))}
        {shiftHours.length > 0 && (
          <ScheduleButton
            type={
              shiftHours.length == possibleScheduleIds.length ? "remove" : "add"
            }
          />
        )}
      </div>
    </Wrapper>
  )
}
