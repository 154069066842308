import { Modal, SignatureCanvas, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getServiceColor } from "../../../utils/facility"
import moment from "moment"
import { AuthContext, getDayOfWeek } from "../../../utils"
import React, { useContext, useState } from "react"
import { FormOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import { updateUserServiceProvisionPrintSignature } from "../../../services/serviceProvisionResults"
import { toastMsg } from "../../../utils/toastMsg"
import { useRouter } from "next/router"
import { Spin } from "antd"

interface IPrintPreference {
  printSetting: number
  $index?: number
  isMenuCollapsed?: boolean
}

const Wrapper = styled.div<IPrintPreference>`
  margin-top: 25px;
  .sign-space {
    height: 3.5rem;
  }

  padding-bottom: 30px;
  .header-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .service-info {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
      font-weight: 500;
      font-size: larger;
    }
    .child-info {
      font-weight: 500;
      font-size: large;
    }
  }
  table {
    width: 100%;
    margin: 10px 0 15px 0;

    thead {
      border-bottom: 3px double #222;
      th {
        padding: 10px 0;
        background: #dddddd;
        text-align: center;
        border: 1px solid #000000;
      }
    }

    tbody {
      tr {
        border: 1px solid #000000;
        td {
          padding-left: 4px;
          min-width: 20px;
          &:not(:last-child) {
            border-right: 1px solid #000000;
            text-align: center;
            padding-left: 0;
          }
        }
        .label {
          background: #f5f5f5;
        }
        .event-data {
          text-align: left;
          white-space: pre-wrap;
          height: 50px;
          word-break: break-all;
        }
        .line-row {
          height: 30px;
          border-top-style: dotted;
          border-bottom-style: dotted;
        }
      }
    }

    @media print {
      thead {
        border-bottom: none !important;
      }

      tbody.custom-table-tbody {
        position: relative;
        tr:first-child td.line-row {
          border-top: none;
        }
        &::before {
          line-height: 0.3em;
          content: "-";
          color: white;
          display: block;
        }
      }
    }
  }
  .facility-info-and-stamp {
    width: 100%;
    /* display: table-header-group; */
    position: relative;
    min-height: 125px;
    .facility-info {
      text-align: center;
      width: 74%;

      align-items: center;
      justify-content: center;

      @media print {
        text-align: center;
      }
    }
    .facility-info.full-width {
      width: 100%;
    }
    .stamp-table {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      max-width: 24%;
      .stamp-cell {
        height: 75px;
        text-align: center;
      }
    }
  }
  .facility-official-name {
    font-weight: 500;
    font-size: large;
  }
  .facility-address,
  .facility-telephone {
    font-size: 10pt;
  }
  @media print {
    margin-top: 0px;
    page-break-before: ${(props) =>
      props.$index % 2 === 0 && props.printSetting === 1 ? "always" : "avoid"};
    page-break-inside: ${(props) =>
      props.printSetting === 1 && props.$index === 0 ? "always" : "avoid"};

    page-break-after: always;
    &:last-child {
      page-break-after: avoid;
    }

    .stamp-table {
      min-width: 220px !important;
      thead {
        border-bottom: none;
      }

      tbody {
        position: relative;
        &::before {
          line-height: 0.2em;
          content: "-";
          color: white;
          display: block;
        }
      }
    }
  }
`
const SpinnerWrapper = styled(Spin)`
  width: 100%;
  display: flex;
  justify-content: center;
`

interface IServiceTag {
  background?: string
}

const ServiceTag = styled.div<IServiceTag>`
  background: ${(props) => props?.background ?? theme.blue5};
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 8px;
`

interface IUserServiceProvisionRecordCard {
  showParentsCommentTable: boolean
  showCareRecordTable: boolean
  displayStaffStamp: boolean
  staffStampType?: number
  displayParentStamp: boolean
  parentStampType?: number
  printSetting?: number
  childServiceData: any
  index?: number
  refetchProp?: any
  isLoading?: boolean
}

export const UserServiceProvisionRecordCard = ({
  index,
  staffStampType,
  parentStampType,
  printSetting = 2,
  childServiceData,
  displayStaffStamp,
  displayParentStamp,
  showCareRecordTable,
  showParentsCommentTable,
  refetchProp,
  isLoading,
}: IUserServiceProvisionRecordCard) => {
  const { t } = useTranslation()
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false)
  const router = useRouter()
  const { collapsed } = useContext(AuthContext)

  const getServiceTitle = () => {
    if (childServiceData?.attendance_type == 1) {
      const startTime = childServiceData?.start_time?.split(":")
      const startTimeFormat = startTime
        ? startTime?.[0] + "時" + startTime?.[1] + "分"
        : ""
      const endTime = childServiceData?.end_time?.split(":")

      const endTimeFormat =
        endTime?.length >= 2 ? endTime?.[0] + "時" + endTime?.[1] + "分" : ""
      return t("serviceImplementationTitle", {
        date: moment(childServiceData?.date).format("YYYY年MM月DD日"),
        day: t(getDayOfWeek(moment(childServiceData?.date).day())),
        startTime: startTimeFormat,
        endTime: endTimeFormat,
      })
    }
    return t("Service implementation date and time : {{date}},({{day}})", {
      date: moment(router?.query?.date_from).format("YYYY年MM月DD日"),
      day: t(getDayOfWeek(moment(router?.query?.date_from).day())),
    })
  }

  const { isLoading: isUpdating, mutate } = useMutation(
    updateUserServiceProvisionPrintSignature
  )

  return (
    <>
      {isSignatureModalOpen && (
        <Modal
          open={isSignatureModalOpen}
          title={t("Confirmation signature")}
          onCancel={() => {
            setIsSignatureModalOpen(false)
          }}
          footer={null}
          bodyStyle={{
            padding: 0,
          }}
        >
          <SignatureCanvas
            isLoading={isUpdating}
            onSave={(val) => {
              const SendData = {
                id: childServiceData?.attendance_id,
                digital_signature: val,
              }
              mutate(
                { values: SendData },
                {
                  onSuccess: () => {
                    setIsSignatureModalOpen(false)
                    refetchProp()

                    toastMsg("success", t("Updated Successfully"))
                  },
                  onError: () => {
                    toastMsg(
                      "error",
                      t("Something went wrong. Please contact administrator.")
                    )
                  },
                }
              )
            }}
          />
        </Modal>
      )}
      <Wrapper
        $index={index}
        printSetting={printSetting}
        key={index}
        isMenuCollapsed={collapsed}
      >
        <div className={"header-section"}>
          <div className={"service-info"}>
            {t("Service provision record")}
            <ServiceTag
              background={getServiceColor(
                childServiceData?.service?.toString()
              )}
            >
              {childServiceData?.service == 1
                ? t("After day school service")
                : t("Child development support")}
            </ServiceTag>
          </div>
          <div className={"child-info"}>
            {t("User name")} {" : "} {`${childServiceData?.child_name} 様`}
          </div>
        </div>
        <div className={"service-table"}>
          <table>
            <thead>
              <tr>
                <th colSpan={8}>{getServiceTitle()}</th>
              </tr>
            </thead>
            <tbody className={"custom-table-tbody"}>
              <tr>
                <td className={"label"}>{t("Temperature")}</td>
                <td>{`${childServiceData?.temperature}°C`}</td>
                <td className={"label"}>{t("Food")}</td>
                <td>{`□${t("Morning")} □${t("Day")} □${t("Evening")}`}</td>
                <td className={"label"}>{t("Snack")}</td>
                <td>{`□${t("Morning")} □${t("Day")} □${t("Evening")}`}</td>
                <td className={"label"}>{t("Today's program")}</td>
                <td>
                  {childServiceData?.programs?.split(",")?.map((program) => (
                    <>
                      {program}
                      <br />
                    </>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={"event-table"}>
          <table>
            <thead>
              <tr>
                <th>{t("Today's event")}</th>
              </tr>
            </thead>
            <tbody className={"custom-table-tbody"}>
              <tr>
                <td className={"event-data"}>{childServiceData?.comment}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {showParentsCommentTable && (
          <div className={"parents-comment-table"}>
            <table>
              <thead>
                <tr>
                  <th>{t("Comment from parents")}</th>
                </tr>
              </thead>
              <tbody className={"custom-table-tbody"}>
                {childServiceData?.parent_comment ? (
                  <tr>
                    <td className={"event-data"}>
                      {childServiceData?.parent_comment}
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className={"line-row"}></td>
                    </tr>
                    <tr>
                      <td className={"line-row"}></td>
                    </tr>
                    <tr>
                      <td
                        className={"line-row"}
                        style={{ borderBottomStyle: "none" }}
                      ></td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}
        {showCareRecordTable && (
          <div className={"record-table"}>
            <table>
              <thead>
                <tr>
                  <th>{t("Care records / life records")}</th>
                </tr>
              </thead>
              <tbody className={"custom-table-tbody"}>
                {childServiceData?.service_content_care_log ? (
                  <tr>
                    <td className={"event-data"}>
                      {childServiceData?.service_content_care_log}
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className={"line-row"}></td>
                    </tr>
                    <tr>
                      <td className={"line-row"}></td>
                    </tr>
                    <tr>
                      <td
                        className={"line-row"}
                        style={{ borderBottomStyle: "none" }}
                      ></td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className={"facility-info-and-stamp"}>
          <div
            className={`facility-info ${
              !displayStaffStamp && !displayParentStamp && "full-width"
            }`}
          >
            <div className={"facility-address"}>
              {`〒${childServiceData?.facility_address} ${childServiceData?.facility_postal_code}`}
            </div>
            <div className={"facility-official-name"}>
              {childServiceData?.facility_name}
            </div>
            <div
              className={"facility-telephone"}
            >{`TEL:${childServiceData?.facility_tel} `}</div>
          </div>
          <div className={"stamp-container"}>
            {(displayStaffStamp || displayParentStamp) && (
              <table className={"stamp-table"}>
                <thead>
                  <tr>
                    {displayStaffStamp && <th>{t("Entry Person")}</th>}
                    {displayParentStamp && <th>{t("Parents")}</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {displayStaffStamp && (
                      <td className={"stamp-cell"}>
                        {staffStampType == 2 && <>{"㊞"}</>}
                        {staffStampType == 3 && (
                          <>{childServiceData?.staff_name}</>
                        )}
                      </td>
                    )}
                    {displayParentStamp && (
                      <td className={"stamp-cell"}>
                        {parentStampType == 2 && <>{"㊞"}</>}
                        {parentStampType == 3 &&
                          (childServiceData?.status === "APPROVED" ||
                            childServiceData?.status === "DRAFT") && (
                            <>
                              {isLoading ? (
                                <SpinnerWrapper size={"small"} />
                              ) : childServiceData?.digital_signature ? (
                                <div className={"sign-space"}>
                                  <img
                                    src={childServiceData?.digital_signature}
                                    alt={"sign"}
                                    style={{
                                      height: "auto",
                                      maxHeight: "26px",
                                      maxWidth: "160px",
                                      marginTop: "10px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className={"sign-space"}></div>
                              )}

                              <div
                                style={{
                                  textAlign: "right",
                                  paddingRight: "5px",
                                }}
                              >
                                <FormOutlined
                                  onClick={() => {
                                    setIsSignatureModalOpen(true)
                                  }}
                                />
                              </div>
                            </>
                          )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}
