import {
  TopRoundedWhiteCard,
  SelectInput,
  NO_YES_LIST,
  UPDATE_OR_NOT_LIST,
  PICK_DROP_OPTIONS,
  RadioButtons,
  theme,
  Checkbox,
  CALANDER_HOURS_MINUTES,
  PICK_DROP_CDS_OPTIONS,
} from "@project/shared"
import { Row, Col, Input } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"

const OperationWrapper = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .flexbox {
    display: flex;
    gap: 10px;
    color: #191919;
  }
  .multiple-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    @media (max-width: 428px) {
      gap: 10px;
      .check-all-btn {
        width: 140px;
      }
      .uncheck-all-btn {
        width: 100px;
      }
    }
  }
  .multiple-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }
  .change-display-container {
    margin-top: 10px;
  }
  .custom-input {
    width: auto;
    height: 30px;
  }
  .custom-input:hover,
  .custom-input:focus {
    border-color: #d9d9d9;
  }
  .bulk-setting {
    margin-bottom: 20px;
    padding-left: 12px;
    border-left: 4px solid ${theme.blue5};
  }
  @media (max-width: 768px) {
    .ant-row {
      align-items: flex-start;
      flex-direction: column;
    }
    .operation-label {
      margin-bottom: 5px;
    }
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
  }
  /* 397 */
`

export const ChangeFormOperations = ({
  facilities,
  handleSubmit,
  numberOfWeeks,
  serviceType,
}) => {
  const formik = useFormik({
    initialValues: {
      facility_id: facilities?.[0]?.value,
      weeks: [],
      days: [],
      pickup: 1,
      pickup_place: null,
      pickup_place_name: null,
      pickup_hour: null,
      pickup_minute: null,
      drop: 1,
      drop_place: null,
      drop_place_name: null,
      drop_hour: null,
      drop_minute: null,
      remarks_update: 0,
      remarks: null,
    },
    onSubmit: () => {
      handleSubmit(formik.values)
    },
  })

  const getWeeks = () => {
    return Array.from({ length: numberOfWeeks }, (_, i) => i + 1)
  }

  const getDays = () => {
    return Array.from({ length: 7 }, (_, i) => i)
  }

  const getDayByNumber = (day) => {
    switch (day) {
      case 0:
        return "Sun"
      case 1:
        return "Mon"
      case 2:
        return "Tue"
      case 3:
        return "Wed"
      case 4:
        return "Thu"
      case 5:
        return "Fri"
      case 6:
        return "Sat"
      default:
        return ""
    }
  }

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <div className={"bulk-setting"}>
          <h2>{t("Bulk settings")}</h2>
        </div>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Facility")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <SelectInput
              width={150}
              name={"facility"}
              options={facilities}
              value={formik.values.facility_id}
              onChange={(val) => formik.setFieldValue("facility_id", val)}
              placeholder={t("--")}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Week")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <OwnerButton
                typeOf={"check-all"}
                text={t("Check all")}
                onClick={() => {
                  formik.setFieldValue("weeks", getWeeks())
                }}
                className={"check-all-btn"}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Uncheck all")}
                backgroundColor={"#fff"}
                onClick={() => {
                  formik.setFieldValue("weeks", [])
                }}
                className={"uncheck-all-btn"}
              />
            </div>
            <div className={"multiple-input-container"}>
              <div className={"multiple-checkboxes"}>
                {getWeeks().map((week) => (
                  <span key={week}>
                    <Checkbox
                      label={t(`week${week}`)}
                      value={week}
                      checked={formik.values.weeks.includes(week)}
                      onChange={(e) => {
                        const val = e.target.value
                        let currentWeeks = formik.values.weeks
                        if (currentWeeks.includes(val))
                          currentWeeks = currentWeeks.filter(
                            (week) => week != val
                          )
                        else currentWeeks.push(val)
                        formik.setFieldValue("weeks", currentWeeks)
                      }}
                    />
                  </span>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Day of week")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <OwnerButton
                typeOf={"check-all"}
                text={t("Check all")}
                onClick={() => {
                  formik.setFieldValue("days", getDays())
                }}
                className={"check-all-btn"}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Uncheck all")}
                backgroundColor={"#fff"}
                onClick={() => {
                  formik.setFieldValue("days", [])
                }}
                className={"uncheck-all-btn"}
              />
            </div>
            <div className={"multiple-input-container"}>
              <div className={"multiple-checkboxes"}>
                {getDays().map((day: number) => (
                  <Checkbox
                    label={t(getDayByNumber(day))}
                    key={day}
                    value={day}
                    checked={formik.values.days.includes(day)}
                    onChange={(e) => {
                      const val = e.target.value
                      let currentDays = formik.values.days
                      if (currentDays.includes(val))
                        currentDays = currentDays.filter((day) => day != val)
                      else currentDays.push(val)
                      formik.setFieldValue("days", currentDays)
                    }}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Pickup")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <RadioButtons
                options={NO_YES_LIST}
                value={formik.values.pickup}
                name={"pickup"}
                onChange={(e) => {
                  const val = e.target.value
                  formik.setFieldValue("pickup", val)
                  if (val == 0) {
                    formik.setFieldValue("pickup_place", null)
                    formik.setFieldValue("pickup_place_name", null)
                    formik.setFieldValue("pickup_hour", null)
                    formik.setFieldValue("pickup_minute", null)
                  }
                }}
              />
              <div className={"flexbox"}>
                <span>{t("Pick up place")}</span>
                <SelectInput
                  width={100}
                  placeholder={"--"}
                  disabled={!formik.values.pickup || formik.values.pickup == 0}
                  options={
                    serviceType === 1
                      ? PICK_DROP_OPTIONS
                      : PICK_DROP_CDS_OPTIONS
                  }
                  name={"pickup_place"}
                  value={formik.values.pickup_place}
                  onChange={(val) => {
                    formik.setFieldValue("pickup_place", val)
                    if (val == "others")
                      formik.setFieldValue("pickup_place_name", null)
                    else formik.setFieldValue("pickup_place_name", val)
                  }}
                />
                {formik.values.pickup_place == "others" && (
                  <div>
                    <Input
                      className={"custom-input"}
                      name={"pickup_place_name"}
                      minLength={0}
                      maxLength={50}
                      value={formik.values.pickup_place_name}
                      onChange={formik.handleChange}
                      color={"primary"}
                    />
                  </div>
                )}
              </div>
              <div className={"flexbox"}>
                <span>{t("Pickup desire time")}</span>
                <SelectInput
                  width={60}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES.hours}
                  disabled={!formik.values.pickup || formik.values.pickup == 0}
                  name={"pickup_hour"}
                  value={formik.values.pickup_hour}
                  onChange={(val) => {
                    formik.setFieldValue("pickup_hour", val)
                    if (!formik.values.pickup_minute)
                      formik.setFieldValue("pickup_minute", "00")
                  }}
                  className={"left-align"}
                  padding={"0px 5px"}
                />
                {" : "}
                <SelectInput
                  width={60}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES.minutes}
                  disabled={
                    !formik.values.pickup ||
                    formik.values.pickup == 0 ||
                    !formik.values.pickup_hour
                  }
                  name={"pickup_minute"}
                  value={formik.values.pickup_minute}
                  onChange={(val) => formik.setFieldValue("pickup_minute", val)}
                  className={"left-align"}
                  padding={"0px 5px"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Dropoff")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <RadioButtons
                options={NO_YES_LIST}
                value={formik.values.drop}
                name={"drop"}
                onChange={(e) => {
                  const val = e.target.value
                  formik.setFieldValue("drop", val)
                  if (val == 0) {
                    formik.setFieldValue("drop_place", null)
                    formik.setFieldValue("drop_place_name", null)
                    formik.setFieldValue("drop_hour", null)
                    formik.setFieldValue("drop_minute", null)
                  }
                }}
              />
              <div className={"flexbox"}>
                <span>{t("Drop off place")}</span>
                <SelectInput
                  placeholder={"--"}
                  width={100}
                  disabled={!formik.values.drop || formik.values.drop == 0}
                  options={
                    serviceType === 1
                      ? PICK_DROP_OPTIONS
                      : PICK_DROP_CDS_OPTIONS
                  }
                  name={"drop_place"}
                  value={formik.values.drop_place}
                  onChange={(val) => {
                    formik.setFieldValue("drop_place", val)
                    if (val == "others")
                      formik.setFieldValue("drop_place_name", null)
                    else formik.setFieldValue("drop_place_name", val)
                  }}
                />
                {formik.values.drop_place == "others" && (
                  <Input
                    className={"custom-input"}
                    name={"drop_place_name"}
                    minLength={1}
                    maxLength={15}
                    value={formik.values.drop_place_name}
                    onChange={formik.handleChange}
                  />
                )}
              </div>
              <div className={"flexbox"}>
                <span>{t("Drop off desire time")}</span>
                <SelectInput
                  width={60}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES.hours}
                  disabled={!formik.values.drop || formik.values.drop == 0}
                  name={"drop_hour"}
                  value={formik.values.drop_hour}
                  onChange={(val) => {
                    formik.setFieldValue("drop_hour", val)
                    if (!formik.values.drop_minute)
                      formik.setFieldValue("drop_minute", "00")
                  }}
                  className={"left-align"}
                  padding={"0px 5px"}
                />
                {" : "}
                <SelectInput
                  width={60}
                  placeholder={"--"}
                  options={CALANDER_HOURS_MINUTES.minutes}
                  disabled={
                    !formik.values.drop ||
                    formik.values.drop == 0 ||
                    !formik.values.drop_hour
                  }
                  name={"drop_minute"}
                  value={formik.values.drop_minute}
                  onChange={(val) => formik.setFieldValue("drop_minute", val)}
                  className={"left-align"}
                  padding={"0px 5px"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Remarks")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container"}>
              <RadioButtons
                options={UPDATE_OR_NOT_LIST}
                name={"remarks_update"}
                value={formik.values.remarks_update}
                onChange={formik.handleChange}
              />
              <Input
                className={"custom-input"}
                name={"remarks"}
                disabled={
                  !formik.values.remarks_update ||
                  formik.values.remarks_update == 0
                }
                value={formik.values.remarks}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
        <div className={"change-display-container"}>
          <OwnerButton
            text={t("Reflect")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
