import {
  ACHIEVEMENT_GOAL_TYPE,
  EVALUATION_TYPES,
  SelectInput,
  TextField,
} from "@project/shared"
import { useMemo } from "react"
import styled from "styled-components"

interface IChildMonitoringContentMobileTableProps {
  data?: any
  handleFieldChange: (a: any) => void
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 40px;
  .left-wrapper {
    display: flex;
    justify-content: center;
    min-width: 30px;
    border: 1px solid #d2d1d1;
    border-right: none;
  }
  .right-wrapper {
    width: 100%;
    .column {
      border: 1px solid #d2d2d1;
      border-bottom: none;
      .column-header {
        background: #f3f3f3;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        border-bottom: 1px solid #d2d2d1;
      }
      .field-value {
        width: 100%;
        padding: 10px;
      }
    }
    .flex {
      display: flex;
      .column {
        width: 50%;
      }
      > :first-child {
        border-right: none;
      }
    }
    :last-child {
      border-bottom: 1px solid #d2d2d1;
    }
  }
`

const ChildMonitoringContentMobileTable: React.FC<
  IChildMonitoringContentMobileTableProps
> = ({ data = [], handleFieldChange }) => {
  const _render = useMemo(() => {
    return data?.map(
      (
        {
          child_monitoring_item_id,
          achievement_goal_type,
          evaluation_type,
          study,
          evaluation_type_text,
          achievement_goal_type_text,
          title,
        },
        index
      ) => {
        return (
          <Wrapper key={child_monitoring_item_id}>
            <div className={"left-wrapper"}>
              <p>{index + 1}</p>
            </div>
            <div className={"right-wrapper"}>
              <div className={"column"}>
                <p className={"column-header"}>{"項目（本人のニーズ等）"}</p>
                <div className={"field-value"}>
                  <p>{title}</p>
                </div>
              </div>
              <div className={"column"}>
                <p className={"column-header"}>{"項目（本人のニーズ等）"}</p>
                <div className={"field-value"}>
                  <p>{"〇周りとの適切な距離感を学んでいく"}</p>
                </div>
              </div>
              <div className={"flex"}>
                <div className={"column"}>
                  <p className={"column-header"}>{"目標達成度"}</p>
                  <div className={"field-value"}>
                    <>
                      <SelectInput
                        height={40}
                        defaultValue={"--"}
                        options={ACHIEVEMENT_GOAL_TYPE}
                        value={achievement_goal_type}
                        onChange={(value) => {
                          handleFieldChange({
                            fieldName: "achievement_goal_type",
                            value,
                            id: child_monitoring_item_id,
                          })
                        }}
                      />
                      {achievement_goal_type === 4 && (
                        <TextField
                          value={achievement_goal_type_text}
                          onChange={(e) => {
                            handleFieldChange({
                              fieldName: "achievement_goal_type_text",
                              value: e.target.value,
                              id: child_monitoring_item_id,
                            })
                          }}
                        />
                      )}
                    </>
                  </div>
                </div>
                <div className={"column"}>
                  <p className={"column-header"}>{"評価"}</p>
                  <div className={"field-value"}>
                    <>
                      <SelectInput
                        height={40}
                        defaultValue={"--"}
                        options={EVALUATION_TYPES}
                        value={evaluation_type}
                        onChange={(value) => {
                          handleFieldChange({
                            fieldName: "evaluation_type",
                            value,
                            id: child_monitoring_item_id,
                          })
                        }}
                      />
                      {evaluation_type === 4 && (
                        <TextField
                          value={evaluation_type_text}
                          onChange={(e) => {
                            handleFieldChange({
                              fieldName: "evaluation_type_text",
                              value: e.target.value,
                              id: child_monitoring_item_id,
                            })
                          }}
                        />
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className={"column"}>
                <p className={"column-header"}>{"考察"}</p>
                <div className={"field-value"}>
                  <TextField
                    type={"textarea"}
                    value={study}
                    rows={2}
                    bgcolor={"white"}
                    onChange={(e) => {
                      handleFieldChange({
                        fieldName: "study",
                        value: e?.target?.value,
                        id: child_monitoring_item_id,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </Wrapper>
        )
      }
    )
  }, [data])

  return <> {_render}</>
}

export { ChildMonitoringContentMobileTable }
