import { theme } from "@project/shared"
import styled from "styled-components"

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .red_txt {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: ${theme.red1};
    text-align: center;
  }
  .txt {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: ${theme.black};
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .red_txt {
      font-size: 16px;
    }
    .txt {
      font-size: 14px;
    }
  }
`
export const Wrapper = styled.div`
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    padding: 1rem 0;
    .top_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;
      &-top {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .txt {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
        button {
          padding: 0.3rem 0.5rem;
        }
      }
      &-bottom {
        a {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-decoration-line: underline;
          color: ${theme.blue5};
        }
      }
    }
    .middle_container {
      width: 100%;
      min-height: 164px;
      background: ${theme.gray4};
      border: 1px solid #d2d2d1;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bottom_container {
      display: flex;
      gap: 1.5rem;
      &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${theme.gray4};
        border: 1px solid #d2d2d1;
        border-radius: 12px;
        min-height: 164px;
        flex: 1;
        padding: 0.5rem;
      }
    }
  }
  .ant-card-head-wrapper {
    .ant-card-extra {
      @media screen and (max-width: ${theme.breakpoints.md}) {
        margin-left: auto !important;
        width: 100%;
        & :last-child {
          flex-wrap: wrap !important;
          max-width: fit-content !important;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .container {
      .bottom_container {
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .container {
      .top_container {
        &-top {
          flex-direction: column;
          row-gap: 0.5rem;
        }
      }
    }
  }
  .overlay-loader {
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const OverlayBox = styled.div`
  background: rgba(255, 255, 255, 0.92);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  .content {
    height: 100%;
    width: 60%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.2rem;
    .text_content {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
    }

    .cta_content {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }
`

export const BillingChangeButtonWrapper = styled.button`
  border: none;
  background: transparent;
  padding: 20px;
`
