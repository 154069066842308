const SearchIcon = () => (
  <svg
    width={"19"}
    height={"19"}
    viewBox={"0 0 19 19"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M16 8.25C16 10.78 14.75 13.01 12.84 14.37C11.62 15.24 10.12 15.75 8.5 15.75C4.36 15.75 1 12.39 1 8.25C1 4.11 4.36 0.75 8.5 0.75C12.64 0.75 16 4.11 16 8.25Z"
      }
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M13.9004 13.6992L17.9004 17.6992"}
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={
        "M10.9792 11.76C10.2792 12.26 9.41922 12.55 8.49922 12.55C6.12922 12.55 4.19922 10.63 4.19922 8.25"
      }
      stroke={"white"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default SearchIcon
