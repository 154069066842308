import { auth, Modal } from "@project/shared"
import { Layout } from "antd"
import { signOut } from "firebase/auth"
import { t } from "i18next"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import AlertBell from "../../../public/assets/icons/AlertBell.svg"
import NotificationAlert from "../../../public/assets/icons/NotificationAlert.svg"
import UserIcon from "../../../public/assets/icons/UserIcon.svg"
import { getNotificationCount } from "../../../services"
import { AuthContext } from "../../../utils"
import { NotificationModalComponent } from "../../organisms"

const { Header: AntHeader } = Layout

const HeaderStyled = styled(AntHeader)`
  &.faculty-dashboard-header {
    font-size: 14px;
    background: #ffffff;
    padding: 0;
  }
  @media (min-width: 768px) {
    &.faculty-dashboard-header div,
    &.faculty-dashboard-header span {
      font-size: 12px;
      max-height: 40px;
      display: flex;
      align-items: center;
    }
  }
  @media print {
    display: none;
  }
`
const HeaderTopStyled = styled.div<{ gray?: boolean; blue?: boolean }>`
  &.faculty-dashboard-header-top {
    display: flex;
    justify-content: space-between;
    background: ${(props) => (props.blue ? "#00B6C2" : "#444748")};
    width: 95%;
    color: #ffffff;
    padding: 2px 20px;
    border-bottom-left-radius: 20px;
    margin-left: auto;
    margin-right: 0;
    line-height: 1;
    font-size: 14px;
  }
  .faculty-dashboard-header-top-notification-information {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: ellipsis;
  }
  .faculty-dashboard-header-top-notification {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  .faculty-dashboard-header-top-information {
    margin-left: 16px;
    margin-right: 8px;
  }
  .faculty-dashboard-header-top-notification-information-divider {
    border-left: 1px solid #ffffff;
    height: 40px;
  }
  .faculty-dashboard-header-top-information-new-tag {
    height: 24px;
    background: #e26827;
    border-radius: 5px;
    margin-right: 8px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
  }
  .faculty-dashboard-header-top-announcement {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    svg {
      scale: 1.3;
      width: 50px;
      padding-left: 20px;
    }
  }
  .faculty-dashboard-header-top-announcement-title {
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }
  .faculty-dashboard-header-top-announcement-count {
    text-decoration: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #e00000;
    font-size: 11px !important;
    font-weight: bold;
    color: #ffffff;
    vertical-align: middle;
    margin: auto auto;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -4px;
    left: 29px;
    cursor: pointer;
  }
  .faculty-dashboard-header-top-information-desc {
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    * {
      font-size: 14px;
    }
    &.faculty-dashboard-header-top {
      max-height: 64px;
      height: 64px;
      border-bottom-left-radius: 0px;
      width: 100%;
      padding: 2px;
      display: grid;
      grid-template-columns: 85% 15%;
    }
    .faculty-dashboard-header-top-notification-information-divider {
      display: none;
    }
    .faculty-dashboard-header-top-announcement-title {
      display: none;
    }
    .faculty-dashboard-header-top-notification-information {
      display: grid;
      grid-template-areas: "alert info" "desc desc";
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 32px 32px;
      padding-left: 8px;
    }
    .faculty-dashboard-header-top-notification {
      height: 32px;
      grid-area: alert;
      width: 100%;
      margin-right: 4px;
    }
    .faculty-dashboard-header-top-information {
      height: 32px;
      grid-area: info;
      width: 100%;
      margin-left: 4px;
      display: flex;
      align-items: center;
    }
    .faculty-dashboard-header-top-information span {
      max-height: 32px;
    }
    .faculty-dashboard-header-top-information-desc {
      grid-area: desc;
      width: 100%;
      margin-bottom: 4px;
    }
    .faculty-dashboard-header-top-announcement {
      justify-content: center;
    }
    .faculty-dashboard-header-top-announcement-count {
      height: 16px;
      width: 16px;
      top: 16px;
      left: 45%;
    }
    .faculty-dashboard-header-top-information-new-tag {
      font-size: 12px;
      padding: 3px 12px;
    }
  }
`

const HeaderUserInfoStyled = styled.div`
  .faculty-dashboard-header-user-info-role {
    height: 24px;
    background-color: #f8e6e6;
    padding: 2px 10px;
    border-radius: 5px;
  }
  .faculty-dashboard-header-user-info-name {
    display: flex;
    align-items: center;
  }
  .faculty-dashboard-header-user-info-logout {
    text-decoration: underline;
    cursor: pointer;
  }

  &.faculty-dashboard-header-user-info {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 20px 8px 0;
    line-height: 1;
  }
  &.faculty-dashboard-header-user-info > span:not(:last-child) {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    &.faculty-dashboard-header-user-info {
      display: none;
    }
  }
`

interface iHeader {
  notiTitle?: any
}

const Header: React.FC<iHeader> = () => {
  const [, setUser] = useState(null)
  const logOut = () => {
    localStorage.clear()
    signOut(auth)
    setUser(null)
    localStorage.removeItem("monitoryModalFlag")
    localStorage.removeItem("carModalFlag")
    window.location.href = "/login"
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [notificationDetailData, setNotificationDetailData] = useState(null)
  const { isOwner, companyName } = useContext(AuthContext)
  const [notificationData, setNotificationData] = useState({
    notificationCount: null,
    lastNotificationData: null,
  })

  const [width, setWidth] = useState(window.innerWidth)
  addEventListener("resize", () => {
    setWidth(window.innerWidth)
  })

  let lengthOfTitle = null
  if (width > 1426) {
    lengthOfTitle = 110
  }
  if (width < 1425) {
    lengthOfTitle = 90
  }
  if (width < 1300) {
    lengthOfTitle = 80
  }
  if (width < 1250) {
    lengthOfTitle = 70
  }
  if (width < 1190) {
    lengthOfTitle = 50
  }
  if (width < 1090) {
    lengthOfTitle = 30
  }
  if (width < 960) {
    lengthOfTitle = 20
  }
  if (width < 910) {
    lengthOfTitle = 10
  }
  if (width < 830) {
    lengthOfTitle = 15
  }
  if (width < 386) {
    lengthOfTitle = 35
  }

  const currentDate = moment(new Date()).format("YYYY年MM月DD日")
  const { refetch } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotificationCount(),
    retry: 1,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setNotificationData({
        notificationCount: response?.data?.unSeenNotificationCount,
        lastNotificationData: response?.data?.lastNotification,
      })
    },
  })
  const handleCancel = () => {
    setIsModalOpen(false)
    setNotificationDetailData(null)
    refetch()
  }
  const handleModalOpen = (data) => {
    setIsModalOpen(true)
    setNotificationDetailData(data)
    refetch() // Resets or refetch to new unseen notifications
  }
  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <HeaderStyled className={"faculty-dashboard-header"}>
      <HeaderTopStyled className={"faculty-dashboard-header-top"}>
        <div
          className={"faculty-dashboard-header-top-notification-information"}
        >
          <span className={"faculty-dashboard-header-top-notification"}>
            <NotificationAlert />
            <span style={{ marginLeft: "4px", whiteSpace: "nowrap" }}>
              {t("Notification")}
            </span>
          </span>
          <div
            className={
              "faculty-dashboard-header-top-notification-information-divider"
            }
          />

          <Modal
            title={t("Notification from easyy")}
            closeIcon={<span>{t("Close")}</span>}
            open={isModalOpen}
            onCancel={handleCancel}
            destroyOnClose
            centered
            btnRightMargin={"24px"}
            footer={null}
            className={"modal-popup"}
          >
            <NotificationModalComponent
              notificationDetailData={notificationDetailData}
              refetch={refetch}
            />
          </Modal>

          {notificationData?.lastNotificationData && (
            <>
              <span className={"faculty-dashboard-header-top-information"}>
                <span
                  className={"faculty-dashboard-header-top-information-new-tag"}
                >
                  {"NEW"}
                </span>
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() =>
                    handleModalOpen(notificationData?.lastNotificationData)
                  }
                >
                  {notificationData?.lastNotificationData?.publishing_date &&
                    moment(
                      notificationData?.lastNotificationData?.publishing_date
                    ).format("YYYY年MM月DD日")}
                </span>
                <span
                  className={"faculty-dashboard-header-top-information-desc"}
                  onClick={() =>
                    handleModalOpen(notificationData?.lastNotificationData)
                  }
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {notificationData?.lastNotificationData?.notification_title.substring(
                    0,
                    lengthOfTitle
                  )}
                </span>
                {notificationData?.lastNotificationData?.notification_title
                  ?.length > lengthOfTitle && <>{"..."}</>}
              </span>
            </>
          )}
        </div>
        <span className={"faculty-dashboard-header-top-announcement"}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            <AlertBell />
          </div>
          {notificationData?.notificationCount > 0 && (
            <span
              className={"faculty-dashboard-header-top-announcement-count"}
              onClick={() => setIsModalOpen(true)}
            >
              {notificationData?.notificationCount}
            </span>
          )}
          &nbsp;&nbsp;
          <span
            className={"faculty-dashboard-header-top-announcement-title"}
            onClick={() => setIsModalOpen(true)}
          >
            {t("See list of announcements")}
          </span>
        </span>
      </HeaderTopStyled>
      <HeaderUserInfoStyled className={"faculty-dashboard-header-user-info"}>
        <span>{currentDate}</span>
        <span className={"faculty-dashboard-header-user-info-role"}>
          {isOwner ? t("System Administrator") : t("Company Staff")}
        </span>
        <span className={"faculty-dashboard-header-user-info-name"}>
          <UserIcon />
          &nbsp;&nbsp;{companyName}
        </span>
        <span
          className={"faculty-dashboard-header-user-info-logout"}
          onClick={logOut}
        >
          {t("Logout")}
        </span>
      </HeaderUserInfoStyled>
    </HeaderStyled>
  )
}

export { Header }
