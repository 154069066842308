import {
  dynamicLangString,
  Grid,
  PageCard,
  Required,
  TextField,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Spin } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import {
  AuthContext,
  Info,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms"
import {
  createProgress,
  deleteProgress,
  getProgress,
  updateProgress,
} from "../../../services"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { hasPermissionForMenu } from "../../../utils/SidebarUtils"
import { ProgressManagementMasterPermission } from "../../../utils/PermissionKeys"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`
const Container = styled.div`
  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .delete-button {
      background: ${theme.red2};
      border-color: ${theme.red2};
    }
  }
  @media screen and (max-width: 426px) {
    .grid-row-last {
      border-right: 0 !important;
    }

    .button-wrapper {
      margin-top: 16px;
    }
  }
`
interface iProgressForm {
  id?: number
}

const ProgressManagementForm: React.FC<iProgressForm> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { permissions, isOwner } = useContext(AuthContext)

  const hasDeleteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(
        permissions,
        ProgressManagementMasterPermission,
        "write"
      ),
    [permissions, isOwner]
  )

  const {
    data: progress,
    isLoading: progressLoading,
    isFetching: progressFetching,
  } = useQuery<any, Error>(
    ["progress-management", id],
    () => id && getProgress(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: id,
          progress_name: data?.progress_name,
        }
      },
    }
  )

  const { mutate: mutateProgress, isLoading } = useMutation(
    id ? updateProgress : createProgress,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Progress management",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Progress management",
              "Created Successfully",
            ]),
          })
        }
        router.push("/progress-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { mutate: handleDelete, isLoading: isDeleting } = useMutation(
    deleteProgress,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Progress management",
            "Deleted Successfully",
          ]),
        })

        router.push("/progress-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const initialValues = progress || {
    progress_name: "",
  }

  const validationSchema = yup.object().shape({
    progress_name: yup.string().required(t("Required")),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      mutateProgress(value)
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  return (
    <Container>
      <PageCard
        title={t("Progress management items")}
        style={{ marginTop: "22px" }}
      >
        {progressLoading || progressFetching ? (
          <LoaderWrapper>
            <Spin size={"large"} className={"loader"} />
          </LoaderWrapper>
        ) : (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Progress name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"progress_name"}
                className={getErrorClass("progress_name", "input")}
                height={"50px"}
                value={formik.values.progress_name}
                onChange={formik.handleChange}
                maxLength={50}
                error={
                  formik.touched.progress_name && formik.errors.progress_name
                }
                placeholder={t("Example : Progress name")}
              />
              <Info className={"info"}>{t("(within 50 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"button-container"}>
              <div className={"button-wrapper"}>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={() => router.back()}
                  text={t("Cancel")}
                  disabled={isLoading}
                />

                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  disabled={isLoading}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isLoading}
                />
              </div>
              {id && hasDeleteAccess ? (
                <div className={"button-wrapper"}>
                  <Popconfirm
                    title={t("Deleting.Is that OK?")}
                    okText={"OK"}
                    cancelText={t("Cancel")}
                    okButtonProps={{ size: "middle" }}
                    cancelButtonProps={{ size: "middle" }}
                    onConfirm={() => handleDelete(id)}
                  >
                    <OwnerButton
                      typeOf={"primary"}
                      text={t("Delete ")}
                      className={"delete-button"}
                      isLoading={isDeleting}
                    />
                  </Popconfirm>
                </div>
              ) : (
                <></>
              )}
            </div>
          </StyledForm>
        )}
      </PageCard>
    </Container>
  )
}

export { ProgressManagementForm }
