import React, { useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { scrollToFirstErrorField } from "../../../utils"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import moment from "moment"
import { Grid, TextField, theme } from "@project/shared"
import { Button, Tag } from "../../atoms"
import { FormContainer } from "./InstructionInformationForm.styles"
import { DatePicker } from "../../molecules"
import { Popconfirm } from "antd"

interface IProps {
  handleSubmit: (values: any) => void
  loading: boolean
  defaultValues?: any
  setStaffFormValues?: any
  id?: string
  handleDelete?: (id: string) => void
  isDeleting?: boolean
}

export const InstructionInfomationForm: React.FC<IProps> = ({
  handleSubmit,
  loading,
  defaultValues,
  setStaffFormValues,
  id,
  handleDelete,
  isDeleting = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        defaultValues || {
          staff_name: "",
          staff_name_furiganaame: "",
          joining_date: "",
          retirement_date: "",
        }
      }
      validationSchema={Yup.object().shape({
        staff_name: Yup.string()
          .required(t("Required"))
          .max(50, t("Please enter number within 50 characters.")),
        staff_name_furiganaame: Yup.string()
          .nullable()
          .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect"))
          .max(50, t("Please enter number within 50 characters.")),
      })}
      onSubmit={(values) => {
        const newValues = { ...values }
        if (
          values?.joining_date != "" &&
          values?.joining_date != "Invalid date" &&
          values?.joining_date != null
        )
          newValues["joining_date"] = moment(values.joining_date).format(
            "YYYY-MM-DD"
          )
        else delete newValues.joining_date

        if (
          values?.retirement_date != "" &&
          values?.retirement_date != "Invalid date" &&
          values?.retirement_date != null
        )
          newValues["retirement_date"] = moment(values.retirement_date).format(
            "YYYY-MM-DD"
          )
        else delete newValues.retirement_date
        handleSubmit(newValues)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        setFieldValue,
        submitCount,
        isValid,
      }) => {
        const getErrorClass = (field, type = "input") => {
          if (submitCount === 0) return ""
          if (errors[field]) {
            switch (type) {
              case "input":
                return "has-error"
              case "container":
                return "has-error-container"
              case "nest":
                return "has-error-container-nest"
            }
          }
          return ""
        }
        useEffect(() => {
          if (submitCount == 0) return
          if (isValid) return
          scrollToFirstErrorField(errors)
        }, [submitCount, isValid])
        return (
          <FormContainer onChange={setStaffFormValues(values)}>
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Instructor name")}
                <div style={{ height: "fit-content" }}>
                  {" "}
                  <Tag backgroundColor={theme.red2}>{t("Required")}</Tag>
                </div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"staff_name"}
                value={values?.staff_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.staff_name}
                width={"100%"}
                maxLength={50}
                disableboxshadow
                bgcolor={"transparent"}
                placeholder={t("例：山田太郎")}
                className={getErrorClass("staff_name", "input")}
              />
              <div className={"info"}>{t("(within 50 char)")}</div>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>{t("Hiragana")}</div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"staff_name_furiganaame"}
                value={values?.staff_name_furiganaame}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.staff_name_furiganaame}
                width={"100%"}
                disableboxshadow
                maxLength={50}
                bgcolor={"transparent"}
                placeholder={t("例：やまだたろう")}
              />
              <div className={"info"}>{t("(within 50 char)")}</div>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>{t("Joining date")}</div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DatePicker
                date={
                  moment(values?.joining_date).isValid()
                    ? moment(values?.joining_date)
                    : undefined
                }
                onDateChange={(val) =>
                  setFieldValue(
                    "joining_date",
                    moment(val).format("YYYY-MM-DD")
                  )
                }
                style={{
                  height: "40px",
                }}
                format={"YYYY年MM月DD日"}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>{t("Retirement date")}</div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DatePicker
                date={
                  moment(values?.retirement_date).isValid()
                    ? moment(values?.retirement_date)
                    : undefined
                }
                disabledDate={(current) =>
                  current.isBefore(moment(values?.joining_date))
                }
                onDateChange={(val) => {
                  setFieldValue(
                    "retirement_date",
                    moment(val).format("YYYY-MM-DD")
                  )
                }}
                style={{
                  height: "40px",
                }}
                format={"YYYY年MM月DD日"}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"instructor-button-wrapper"}>
              <div className={"cost-item-button-wrapper"}>
                <Button
                  text={t("Cancel")}
                  shape={"circle"}
                  borderColor={theme.gray2}
                  type={"button"}
                  onClick={() => router.back()}
                  disabled={loading}
                />
                <Button
                  text={t("Save")}
                  shape={"circle"}
                  backgroundColor={theme.blue5}
                  color={theme.base}
                  type={"submit"}
                  disabled={loading}
                  isLoading={loading}
                />
              </div>
              {id && (
                <Popconfirm
                  title={`${t("Data will be deleted")} ${t(
                    'Please press the "delete execution" button if you like.'
                  )}`}
                  onConfirm={() => handleDelete(id)}
                  okText={t("Execute delete")}
                  cancelText={t("Cancel")}
                  placement={"bottomLeft"}
                >
                  <Button
                    text={t("Delete ")}
                    shape={"circle"}
                    backgroundColor={theme.red2}
                    color={theme.base}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              )}
            </div>
          </FormContainer>
        )
      }}
    </Formik>
  )
}
