import { TextField } from "@project/shared"
import { useRouter } from "next/router"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Highlighter, Title, TitleWrapper } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  value: string | undefined
  disableAddButton?: boolean
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .search-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 30px;
    margin-left: 25px;

    @media (max-width: 1025px) {
      margin-left: 0;
    }
  }
  .reset-button {
    height: 40px;
    margin-left: 9.5px;
  }

  @media (max-width: 768px) {
    .search-btn,
    .reset-button {
      width: 130px;
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1025px) {
    flex-wrap: wrap;
    gap: 10px;
  }
  flex-wrap: wrap;

  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px !important;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin-top: 15px;
  }
`

const OperationComponent: React.FC<iOperationOptionComponent> = (props) => {
  const {
    header,
    addButtonText,
    addButtonLink,
    isLoading,
    handleSearchReset,
    handleSearch,
    value,
    disableAddButton = false,
  } = props
  const router = useRouter()
  const { t } = useTranslation()
  const [enteredText, setEnteredText] = useState(value || "")

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>
      <SearchWrapper>
        <TitleWrap>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Title>{t("Keyword")} </Title>
        </TitleWrap>
        <TextField
          placeholder={"施設名・事業所番号・住所・電話番号・ふりがな"}
          height={"50px"}
          className={"search-field"}
          value={enteredText}
          onChange={(e) => setEnteredText(e.target.value)}
          onPressEnter={() => handleSearch(enteredText)}
        />
        <Buttonwrapper>
          <OwnerButton
            className={"search-btn"}
            onClick={() => handleSearch(enteredText)}
            text={t("Search")}
            shape={"circle"}
            icon={"search"}
            type={"submit"}
            typeOf={"primary"}
            isLoading={enteredText === "" ? null : isLoading}
          />

          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button"}
            onClick={() => {
              setEnteredText("")
              handleSearchReset()
            }}
            text={t("Reset search")}
            isLoading={enteredText === "" ? isLoading : null}
          />
        </Buttonwrapper>
      </SearchWrapper>
      <TitleWrapper>
        <Highlighter /> <Title>{t("Add data")} </Title>
      </TitleWrapper>
      <ButtonWrapper>
        <OwnerButton
          typeOf={"primary"}
          text={addButtonText || t("Add new progress item")}
          onClick={() =>
            addButtonLink
              ? router.push(addButtonLink)
              : router.push(`${router.pathname}/add`)
          }
          disabled={disableAddButton}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

export { OperationComponent }
