import { API } from "@project/shared"

interface IFacilityTourParam {
  trial_admission_id?: number
  date?: string
  progress_manage_id?: number
  PIC?: string
  content?: string
  id?: string
}

export const addFacilityTour = async (values): Promise<IFacilityTourParam> => {
  delete values.id
  return API.post(`/facility-tour-support-history`, values)
}

export const getAllOptions = async () => {
  return API.get(`/progress-management?page=1&pageSize=Infinity`)
}

export const updateFacilityTour = async (
  values
): Promise<IFacilityTourParam> => {
  const id = values?.id
  delete values.id
  return API.put(`/facility-tour-support-history/${id}`, values)
}

export const getFacilityTourSupport = async (id: string) =>
  API.get(`/facility-tour-support-history/${id}`)

export const deleteFacilityTourSupport = async (
  values
): Promise<IFacilityTourParam> => {
  const id = values
  return API.delete(`/facility-tour-support-history/${id}`)
}
