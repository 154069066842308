import React from "react"
import { useTranslation } from "react-i18next"
import { Table } from "@project/shared"
import {
  CheckboxDiv,
  Container,
  FirstContainer,
  FourthContainer,
  LeftDiv,
  RightDiv,
  SecondContainer,
  ThirdContainer,
  FifthContainer,
} from "./styles"
import moment from "moment"
import wareki from "wareki"
import { PrintStyleValues } from "../OperationContainer/data"

const TableContainer = ({ item, printObj }) => {
  const { t } = useTranslation()

  const first_columns = [
    {
      title: t(
        "Number written in the operator's entry field on the beneficiary certificate"
      ),
      dataIndex: "certificate",
      key: "certificate",
      align: "center",
      className: "first_column_first_header",
    },
    {
      title: t("Service contents"),
      dataIndex: "service_contents",
      key: "service_contents",
      align: "center",
      className: "first_column_second_header",
    },
    {
      title: t("Contract amount"),
      dataIndex: "contract_amount",
      key: "contract_amount",
      align: "center",
      className: "first_column_third_header",
      render: (_, obj) => {
        const dt = obj?.category != 3 ? obj?.contract_amount : ""
        return <>{dt}</>
      },
    },
    {
      title: (
        <div className={"contract_date_header"}>
          <span>
            {printObj?.printStyle ==
            PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE
              ? t("Contract date *1")
              : t("Contract Date")}
          </span>
          <small>
            {t("(or date when contract payment amount was changed)")}
          </small>
        </div>
      ),
      dataIndex: "contract_date",
      key: "contract_date",
      align: "center",
      className: "first_column_fourth_header",
      render: (_, obj) => {
        const dt = obj?.category != 3 ? obj?.contract_date : ""
        return <>{dt}</>
      },
    },
    {
      title: t("reason"),
      dataIndex: "isChanged",
      key: "isChanged",
      align: "center",
      className: "first_column_fifth_header",
      render: (_, { stopContractFlag, isCategory }) => {
        return (
          <div className={"reason"}>
            <div className={"reason_container"}>
              <CheckboxDiv
                color={
                  stopContractFlag ? "#fff" : isCategory === 1 ? "#000" : "#fff"
                }
              />
              <span>{t("1 New contract")}</span>
            </div>
            <hr className={"solid"} />
            <div className={"reason_container"}>
              <CheckboxDiv
                color={
                  stopContractFlag ? "#fff" : isCategory === 2 ? "#000" : "#fff"
                }
              />
              <span>{t("2 Changes to the contract")}</span>
            </div>
          </div>
        )
      },
    },
  ]
  const first_dataSource = [
    {
      certificate: item.category === 3 ? null : item.business_number,
      service_contents: item.category === 3 ? null : t(item.service_content),
      contract_amount: item.category === 3 ? null : item.contract_amount,
      category: item?.category,
      contract_date:
        item.category === 3
          ? null
          : moment(item?.contract_start_date).isValid()
          ? wareki(
              `${moment(item?.contract_start_date).format("YYYY")}-${moment(
                item?.contract_start_date
              ).format("MM")}`,
              {
                unit: true,
              }
            ) + moment(item?.contract_start_date).format("MM月DD日")
          : "",
      reason: item.category === 3 ? null : item.category,
      isChanged: item?.total_contract_count > 1,
      stopContractFlag: item?.stop_contract_flag == true,
      isCategory: item?.category,

      // prev
      prev_contract_amount: item?.prev_contract_amount,

      prev_contract_end_date: moment(
        item?.item?.prev_contract_end_date
      ).isValid()
        ? wareki(
            `${moment(item?.item?.prev_contract_end_date).format(
              "YYYY"
            )}-${moment(item?.item?.prev_contract_end_date).format("MM")}`,
            {
              unit: true,
            }
          ) + moment(item?.item?.prev_contract_end_date).format("MM月DD日")
        : "",
      prev_contract_start_date: moment(
        item?.item?.prev_contract_start_date
      ).isValid()
        ? wareki(
            `${moment(item?.item?.prev_contract_start_date).format(
              "YYYY"
            )}-${moment(item?.item?.prev_contract_start_date).format("MM")}`,
            {
              unit: true,
            }
          ) + moment(item?.item?.prev_contract_start_date).format("MM月DD日")
        : "",
      prev_contract_end_month_benefit_days:
        item?.prev_contract_end_month_benefit_days,
      prev_contract_stop_flag: item?.prev_contract_stop_flag,
      prev_business_number: item?.prev_business_person_entry_number,
    },
  ]

  const second_columns = [
    {
      title: (
        <>
          {"提供を終了する"}
          <br />
          <small>{"事業者記入欄の番号"}</small>
        </>
      ),
      dataIndex: "entry_column",
      key: "entry_column",
      align: "center",
      className: "second_column_first_header",
      render: (_, obj) => {
        const end_date =
          item?.category === 3
            ? obj?.business_person_entry_number
            : item?.category === 2
            ? obj?.prev_business_number
            : ""
        return <>{end_date}</>
      },
    },
    {
      title: t("End date of provision"),
      dataIndex: "end_date_of_provision",
      key: "end_date_of_provision",
      align: "center",
      className: "second_column_second_header",
      render: (_, obj) => {
        const end_date =
          item?.category == 2
            ? obj?.prev_contract_end_date
            : item?.category == 3
            ? obj?.end_date_of_provision
            : ""
        return <>{end_date}</>
      },
    },
    {
      title: (
        <>
          {"提供終了月中の"}
          <br />
          {printObj?.printStyle == PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE
            ? "終了日までの既提供量"
            : "終了日までの既提供"}
        </>
      ),
      dataIndex: "offer_ends",
      key: "offer_ends",
      align: "center",
      className: "second_column_third_header",
      render: (_, obj) => {
        const end_month_benefit =
          obj?.category === 3
            ? obj?.offer_ends
            : obj?.category === 2
            ? obj?.prev_contract_end_month_benefit_days
            : ""
        return <>{end_month_benefit}</>
      },
    },
    {
      title: (
        <>
          {`既契約の契約支給量での`}
          <br />
          {"サービス提供を終了する理由"}
        </>
      ),
      dataIndex: "terminating",
      key: "terminating",
      align: "center",
      className: "second_column_fourth_header",
      render: (_, obj) => (
        <div className={"reason"}>
          <div className={"reason_container"}>
            <CheckboxDiv color={obj?.category === 3 ? "#000" : "#fff"} />
            <span>{t("1 Termination of contract")}</span>
          </div>
          <hr className={"solid"} />
          <div className={"reason_container"}>
            <CheckboxDiv color={obj?.category === 2 ? "#000" : "#fff"} />
            <span>{t("2 Changes to the contract")}</span>
          </div>
        </div>
      ),
    },
  ]

  if (
    Array.isArray(printObj?.displaySettings) &&
    printObj?.displaySettings.includes(4)
  ) {
    const serviceColumn = {
      title: t("Service contents"),
      dataIndex: "service_contents",
      key: "service_contents",
      align: "center",
      className: "second_column_one_dot_two_header",
      render: (_, obj) => {
        return <>{obj?.service_contents}</>
      },
    }
    second_columns.splice(1, 0, serviceColumn)
  }

  const second_dataSource = [
    {
      entry_column:
        item.category === 1
          ? null
          : item.category === 3
          ? item.business_number
          : item.new_business_number,
      end_date_of_provision:
        item.category === 1
          ? null
          : moment(item?.contract_end_date).isValid()
          ? wareki(
              `${moment(item?.contract_end_date).format("YYYY")}-${moment(
                item?.contract_end_date
              ).format("MM")}`,
              {
                unit: true,
              }
            ) + moment(item?.contract_end_date).format("MM月DD日")
          : "",
      offer_ends: item.category === 1 ? null : item.end_month_benefit_days,
      reason: item.category === 1 ? null : item.category,
      service_contents: t(item.service_content),
      category: item?.category,
      end_date: item?.contract_end_date,
      business_person_entry_number: item?.business_number,
      end_month_benefit_days: item?.contract_end_month_amount,
      // prev
      prev_contract_end_date: moment(item?.prev_contract_end_date).isValid()
        ? wareki(
            `${moment(item?.prev_contract_end_date).format("YYYY")}-${moment(
              item?.prev_contract_end_date
            ).format("MM")}`,
            {
              unit: true,
            }
          ) + moment(item?.prev_contract_end_date).format("MM月DD日")
        : "",

      prev_contract_end_month_benefit_days:
        item?.prev_contract_end_month_benefit_days,
      prev_business_number: item?.prev_business_number,
    },
  ]
  const monthInt = parseInt(printObj?.month, 10)

  let dateObject = moment()
  if (!isNaN(monthInt) || !isNaN(printObj?.year)) {
    dateObject = moment({
      year: printObj?.year,
      month: monthInt - 1,
      day: printObj?.day,
    })
  }
  const formattedDate =
    wareki(`${dateObject?.format("YYYY")}-${dateObject?.format("MM")}`, {
      unit: true,
    }) + dateObject.format("MM月DD日")

  const officeNumber = item?.office_number?.toString()
  const officeNumberArray = new Array(officeNumber?.length)

  for (let i = 0; i < officeNumber.length; i++) {
    officeNumberArray[i] = officeNumber[i]
  }

  const beneficieryNumber = item?.recipient_no?.toString()
  const beneficieryNumberArray = new Array(
    beneficieryNumber?.length > 9 ? beneficieryNumber?.length : 10
  )

  for (let i = 0; i < beneficieryNumberArray.length; i++) {
    beneficieryNumberArray[i] = beneficieryNumber?.[i] || ""
  }

  const renderTitleByStyle = (printStyle = 1) => {
    if (
      printStyle == PrintStyleValues.COUNTRY_PRINTING_STYLE ||
      printStyle == PrintStyleValues.MATSUYAMA_CITY_PRINTING_STYLE
    ) {
      return (
        <strong>
          {t(
            "Contract details (items written on daycare recipient card) report"
          )}
        </strong>
      )
    } else if (printStyle == PrintStyleValues.KYOTO_CITY_PRINTING_STYLE) {
      return (
        <strong>
          {t(
            "(Child development support, medical child development support, after-school day services, home-visit child development support, nursery school, etc. visit support)"
          )}
          <br />
          {t(
            "Contract details (items written on child day center recipient card) report"
          )}
        </strong>
      )
    } else if (printStyle == PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE) {
      return (
        <strong>
          {t(
            "Contract details (items written on disability welfare service recipient certificate) report"
          )}
        </strong>
      )
    }
  }

  return (
    <Container>
      {printObj?.printStyle ==
        PrintStyleValues.MATSUYAMA_CITY_PRINTING_STYLE && (
        <div className={"form_article"}>
          {t("Form No. 30 (related to Article 17)")}
        </div>
      )}
      {printObj?.printStyle ==
        PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE && (
        <div className={"form_article"}>{t("Form No. 2 (Part 1)")}</div>
      )}
      <p>{renderTitleByStyle(printObj?.printStyle)}</p>
      <span className={"form"}>{printObj?.showForm && t("(Form No. 26)")}</span>
      <FirstContainer>
        <LeftDiv
          noBorder={
            printObj?.printStyle ==
            PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE
          }
        >
          <span>{`〒 ${item?.city_zip_code} ${item?.city_address}  `}</span>
          <span>{`${typeof item?.city == "string" ? item?.city : ""} ${
            Array.isArray(printObj?.displaySettings) &&
            printObj?.displaySettings?.includes(5)
              ? ""
              : "長"
          }`}</span>
          <span style={{ margin: item?.mayor_name ? "0" : "0 auto" }}>
            {`${item?.mayor_name}様`}
          </span>
        </LeftDiv>
        <RightDiv>
          <span>{`${formattedDate}`}</span>
          <table className={"first_table"}>
            <thead></thead>
            <tbody>
              <tr>
                <th>{t("Office number")}</th>
                {officeNumberArray.map((val, index) => {
                  return <th key={index}>{val}</th>
                })}
                <th className={"first_table_header"}></th>
              </tr>
              <tr>
                <th style={{ borderBottom: "none" }}>
                  {printObj?.printStyle ==
                  PrintStyleValues.KYOTO_CITY_PRINTING_STYLE
                    ? t("Business name and")
                    : t("Name of business operator an its place of busines")}
                </th>
                <th
                  colSpan={officeNumberArray.length + 1}
                  style={{ textAlign: "left", borderBottom: "none" }}
                >
                  {item?.invoice_company_name} <br />
                  {item?.facility_name}
                </th>
              </tr>
              <tr>
                <th style={{ borderTop: "none" }}>{t("representative")}</th>
                <th
                  colSpan={officeNumberArray.length + 1}
                  style={{ textAlign: "left", borderTop: "none" }}
                >
                  {item?.representative_name}
                </th>
              </tr>
            </tbody>
          </table>
        </RightDiv>
      </FirstContainer>
      <p className={t("longReport")}>
        {printObj?.printStyle == PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE
          ? t(
              "We would like to report on the contents of the contract with this business operator (items written on disability welfare service recipient certificate) as follows."
            )
          : t(
              "We would like to report on the details of the contract with this business operator (items listed on the public benefit certificate) as follows."
            )}
      </p>
      <span className={"record"}>{t("Record")}</span>
      <p className={"second_container_title"}>{t("Report target person")}</p>
      <SecondContainer tableData={officeNumberArray.length}>
        <table className={"second_table"}>
          <thead></thead>
          <tbody>
            <tr>
              <th>{t("Beneficiary ID number")}</th>
              {beneficieryNumberArray.map((val, index) => {
                return (
                  <th key={index} className={!val ? "second_table_header" : ""}>
                    {val}
                  </th>
                )
              })}
              <th colSpan={3}></th>
            </tr>
            <tr>
              <th>
                {printObj?.printStyle ==
                PrintStyleValues?.MIYAZAKI_CITY_PRINTING_STYLE ? (
                  <>
                    {t(
                      "Payment (benefits) decision for people with disabilities"
                    )}
                    <br />
                    {t("(Guardian name)")}
                  </>
                ) : (
                  t("Name of guardian who will receive day-to-day benefits")
                )}
              </th>
              <th colSpan={beneficieryNumberArray.length + 1}>
                {item?.parent_name}
              </th>
              <th>{t("Name of child regarding benefit decision")}</th>
              <th colSpan={beneficieryNumberArray.length + 1}>
                {item?.child_name}
              </th>
            </tr>
            {printObj?.printStyle ==
              PrintStyleValues.KYOTO_CITY_PRINTING_STYLE && (
              <tr>
                <th>{t("Number of days for payment determination")}</th>
                <th
                  colSpan={Math.floor((beneficieryNumberArray.length + 1) / 2)}
                >
                  {item?.contract_amount}
                </th>
                <th
                  colSpan={Math.ceil((beneficieryNumberArray.length + 1) / 2)}
                >
                  {t("Special notes column, etc. contents")}
                </th>
                <th colSpan={beneficieryNumberArray.length + 1}>
                  <div className={"check-wrapper"}>
                    <div className={"check_container"}>
                      <CheckboxDiv color={"#000"} />
                      <span>{t("Indicator applicable")}</span>
                    </div>
                    <div className={"check_container"}>
                      <CheckboxDiv color={"#fff"} />
                      <span>
                        {t(
                          "Children with severe mental and physical disabilities"
                        )}
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </SecondContainer>
      <p className={"third_container_title"}>
        {t(
          "Report of contract payment amount etc. due to contract conclusion or change of contract details"
        )}
      </p>
      <ThirdContainer>
        <Table
          dataSource={first_dataSource}
          columns={first_columns}
          bordered
          className={"third_table"}
        />
      </ThirdContainer>
      {printObj?.printStyle ==
        PrintStyleValues.MIYAZAKI_CITY_PRINTING_STYLE && (
        <p className={"miyazaki_communcal_container"}>
          {t(
            "*1...For communal living care and communal living assistance, please enter the move-in date."
          )}
        </p>
      )}
      <p className={"fourth_container_title"}>
        {t(
          "Report of termination of service provision based on contract payment amount of existing contract"
        )}
      </p>
      <FourthContainer>
        <Table
          dataSource={second_dataSource}
          columns={second_columns}
          bordered
          className={"fourth_table"}
        />
      </FourthContainer>
      {printObj?.showProxyForm ? (
        <FifthContainer>
          <table className={"proxy_table"}>
            <thead></thead>
            <tbody>
              <tr className={"no-bottom-border"}>
                <td colSpan={3} className={"agreement"}>
                  {t("Agreement for proxy receipt")}
                </td>
              </tr>
              <tr className={"no-top-border no-bottom-border"}>
                <td colSpan={3} className={"reiwa"}>
                  {t("Reiwa month day")}
                </td>
              </tr>
              <tr className={"no-top-border"}>
                <td colSpan={3} className={"lp-10"}>
                  {t(
                    "I have consented to the 　above-mentioned business entity billing"
                  )}
                  <br />
                  {t(
                    "and receiving on my behalf the expenses associated with receiving services from the business entity."
                  )}
                </td>
              </tr>
              <tr>
                <th rowSpan={2} style={{ width: "20%" }}>
                  {t("Recipient ( parent in case of child )")}
                </th>
                <td className={"address"} style={{ width: "10%" }}>
                  {t("address")}
                </td>
                <td className={"empty"}></td>
              </tr>
              <tr>
                <td rowSpan={2} style={{ width: "10%" }} className={"name"}>
                  {t("name")}
                </td>
                <td className={"empty"}>
                  <div style={{ padding: "0rem 1rem", float: "right" }}>
                    {"㊞"}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </FifthContainer>
      ) : null}
    </Container>
  )
}

export { TableContainer }
