import { API, removeBlankAttributes } from "@project/shared"

interface ITransportParams {
  facility_ids?: string
  use_service?: string
  service_ids?: string
  date?: string
  driver?: string
}

export interface ITransportData {
  data?: any
}
export const fetchTransportData = (
  year: number,
  month: number,
  facilityIds?: any
) => {
  let query = ""
  if (facilityIds) {
    query = `?facility_ids=${facilityIds.toString()}`
  }
  return API.get(`/transports/${year}/${month}${query}`)
}

export const fetchOneTransportData = (
  queryKey,
  year: number,
  month: number,
  day: number
) => {
  const params = {} as ITransportParams
  params.facility_ids = Array.isArray(queryKey?.facilityIds)
    ? queryKey?.facilityIds?.join(",")
    : queryKey?.facilityIds
  params.service_ids =
    queryKey?.service_ids && Array.isArray(queryKey?.service_ids)
      ? queryKey?.service_ids?.join(",")
      : undefined
  params.use_service = queryKey?.use_service
  params.date = queryKey?.date
  params.driver = queryKey?.driver
  const p = removeBlankAttributes(params)
  return API.get(`/transports/${year}/${month}/${day}`, { params: p })
}

export const fetchOneTransportDataForEdit = async (
  year: number,
  month: number,
  day: number,
  childId: string
): Promise<ITransportData> => {
  return API.get(`/transports/${year}/${month}/${day}?child_id=${childId}`)
}

export const updateTransportData = ({ values }: { values: any }) => {
  return API.post(`/transport-management`, { ...values })
}

export const getSingleTransportData = ({
  year,
  month,
  day,
  service_ids,
  facility_ids,
  list_display,
}: {
  year: string
  month: string
  day: string
  service_ids: string
  facility_ids: string
  list_display: string
}) => {
  const params = removeBlankAttributes({
    service_ids,
    facility_ids,
    list_display,
  })
  return API.get(`/transports/${year}/${month}/${day}`, {
    params,
  })
}

//update
export const saveTransportBulkEdit = (data: any) => {
  const result = {
    ...data,
    dropoff_info: {
      ...data.dropoff_info,
      driver_detail: data.dropoff_info.driver_detail.map(
        (driverDetailItem) => ({
          ...driverDetailItem,
          facility_id: +driverDetailItem.facility_id || null,
        })
      ),
    },
    pickup_info: {
      ...(data.pickup_info || {}),
      driver_detail: data.pickup_info.driver_detail.map((driverDetailItem) => ({
        ...driverDetailItem,
        facility_id: +driverDetailItem.facility_id || null,
      })),
    },
  }

  return API.post("/transport-management/bulk", result)
}

interface ITransportIncompletePickDrop {
  year?: number | string
  month?: number | string
  date?: string
  facilityIds?: string
  child_ids?: string
}

export const getIncompleteTransportData = (
  query: ITransportIncompletePickDrop
) => API.get(`/transports/incomplete-transport`, { params: query })
