import { Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "@project/shared"
import Link from "next/link"
import { getDateStringWithDay } from "../../../utils"
const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${theme.red1};
  border-radius: 12px;
  padding: 1rem;
  .cta_section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.2rem;
    .left {
      display: flex;
      align-items: center;
      img {
        margin-right: 0.5rem;
      }
      span {
        color: ${theme.red3};
        text-decoration: underline;
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        span {
          font-size: 12px !important;
        }
      }
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      button {
        height: fit-content;
        display: flex;
        align-items: center;
        span {
          color: red;
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        gap: 1rem;
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        button {
          span {
            font-size: 12px !important;
          }
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
  .billing_facility-content {
    display: flex;
    width: 100%;
    border: 1px solid ${theme.gray2};
    &:not(:last-child) {
      border-bottom: unset;
    }
    .key {
      background-color: ${theme.bgColor2};
      padding: 0.8rem;
      min-width: 150px;
      display: flex;
      align-items: center;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        font-size: 12px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
      }
    }
    .keyvalue-content {
      display: flex;
      .box {
        padding: 0.8rem;
      }
      .border {
        min-width: 200px;
        border-right: 1px solid ${theme.gray2};
        border-left: 1px solid ${theme.gray2};
        max-width: 100%;
      }
      .box__servicetype {
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        border-top: 1px solid ${theme.gray2};
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column !important;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      .border {
        font-size: 12px;
        min-width: 100% !important;
        border-bottom: 1px solid ${theme.gray2};
        border-right: unset !important;
        border-left: unset !important;
      }
      .value_content {
        font-size: 12px;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
    }
  }
`

export const BillingFacilityInfo = ({ alerts }: { alerts: Array<any> }) => {
  const { t } = useTranslation()

  const renderDates = (dates, facilityId) => {
    try {
      const parsedDates = JSON.parse(dates)
      return (
        <>
          {parsedDates?.sort()?.map((date, index) => (
            <Link
              href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${date}`}
              key={index}
            >
              <a>{`${index > 0 ? ", " : ""}${getDateStringWithDay(date)}`}</a>
            </Link>
          ))}
        </>
      )
    } catch (error) {
      return <></>
    }
  }

  return (
    <Wrapper>
      <div className={"cta_section"}>
        <div className={"left"}>
          <img src={"/assets/icons/exclamation.png"} alt={""} />
          <Typography.Text>
            {t("There is a problem with the attendance sheet.")}
          </Typography.Text>
        </div>
      </div>

      {alerts?.map((val, index) => (
        <div key={index} className={"billing_facility-content"}>
          <div className={"background key"}>{val?.child_name}</div>
          <div className={"keyvalue-content"}>
            <div className={"box border box__servicetype"}>
              {val?.service_type == "1"
                ? t("After school day service")
                : val?.service_type == "2"
                ? t("Child development support")
                : ""}
            </div>
            <div className={"box value_content"}>
              {`出席情報不足：`}
              {renderDates(val?.unregistered_dates, val?.facility_id)}
            </div>
          </div>
        </div>
      ))}
    </Wrapper>
  )
}
