import { API, removeBlankAttributes } from "@project/shared"
export interface RemunirationRespObjTypes {
  facility_id: number
  total_user: number | null
  total_preschooler: number | null
  facility_name?: string
  facility_name_furigana?: string
  preschoolers_in_percentage: number
  child_count_m1: number
  child_count_m2: number
  child_count_m3: number
  child_count_m4: number
  child_count_m5: number
  child_count_m6: number
  child_count_m7: number
  child_count_m8: number
  child_count_m9: number
  child_count_m10: number
  child_count_m11: number
  child_count_m12: number
  pre_schooler_m1: number
  pre_schooler_m2: number
  pre_schooler_m3: number
  pre_schooler_m4: number
  pre_schooler_m5: number
  pre_schooler_m6: number
  pre_schooler_m7: number
  pre_schooler_m8: number
  pre_schooler_m9: number
  pre_schooler_m10: number
  pre_schooler_m11: number
  pre_schooler_m12: number
}
export interface RemunirationResponseTypes {
  msg: RemunirationRespObjTypes[]
}
export interface RmunerationSearchParams {
  fiscal_year?: number
  service_type?: string
  facility?: string[]
  print_config?: string
  [x: string]: any
}
export const getRemunerationCalculations = async (
  searchParams: RmunerationSearchParams
): Promise<RemunirationResponseTypes> => {
  const params: any = removeBlankAttributes({
    ...searchParams,
    tempFacilities: "",
    facility_id: searchParams?.facility?.toString(),
    service_type: searchParams?.service_type?.toString(),
    print_config: searchParams?.print_config?.toString(),
  })
  delete params?.facility
  return API.get("/remuniration-calculation", {
    params,
  })
}
