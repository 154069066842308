export const isStaffScheduledForDate = ({
  dateSchedule,
  weeklySchedule,
  date,
}) => {
  if (dateSchedule?.id) {
    if (
      !dateSchedule.is_deleted &&
      !dateSchedule?.deleted_datetime &&
      dateSchedule?.attendance_shift_id &&
      dateSchedule?.attendance_shift_id == dateSchedule?.attendance_shift?.id
    ) {
      return true
    }
  } else {
    const dayOfWeek = new Date(date).getDay()
    let weeklyBasedSchedule: any = {}
    switch (dayOfWeek) {
      case 0:
        weeklyBasedSchedule = weeklySchedule?.sun_schedule
        break
      case 1:
        weeklyBasedSchedule = weeklySchedule?.mon_schedule
        break
      case 2:
        weeklyBasedSchedule = weeklySchedule?.tue_schedule
        break
      case 3:
        weeklyBasedSchedule = weeklySchedule?.wed_schedule
        break
      case 4:
        weeklyBasedSchedule = weeklySchedule?.thu_schedule
        break
      case 5:
        weeklyBasedSchedule = weeklySchedule?.fri_schedule
        break
      case 6:
        weeklyBasedSchedule = weeklySchedule?.sat_schedule
        break
    }
    if (weeklyBasedSchedule?.id) return true
  }
  return false
}

export const ATTENDANCE_LABEL_FOR_CHILD = [
  "-",
  "Present ",
  "Absence  ",
  "Absence with no additional charge",
]
