import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
  margin-top: 12px;
  .bold {
    font-weight: bold;
  }
  .column {
    display: grid;
    grid-template-rows: 1fr 2fr;
    border: 1px solid black;
    border-left: unset;
    div {
      text-align: center;
      padding: 4px;
    }
    div:first-child {
      border-bottom: 1px solid black;
    }
    div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    .title {
      background-color: #ccc;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 527px) {
        min-width: 85px;
        word-break: break-all;
      }
    }
    &:first-child {
      .title {
        border: 1px solid black;
      }
    }
    &:last-child {
      grid-template-columns: 2fr 6fr;
      .title {
        border: 1px solid black;
        border-top: unset;
      }
      div:last-child {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
    @media print {
      page-break-inside: avoid !important;
    }
  }
`

const ApprovalColumn = ({ title }: { title: string }) => (
  <div className={"column"}>
    <div className={"bold"}>{title}</div>
    <div>{"㊞"}</div>
  </div>
)

export const Approval = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <div className={"row"}>
        <div className={"title"}>{t("Approve column")}</div>
        <ApprovalColumn title={t("Child manage")} />
        <ApprovalColumn title={t("Facility manager")} />
        <ApprovalColumn title={t("CEO")} />
      </div>
      <div className={"row"}>
        <div className={"title"}>{t("Instructions")}</div>
        <div />
      </div>
    </Container>
  )
}
