import { Table } from "@project/shared"
import { t } from "i18next"
import { CityNumber } from "./common/CityNumber"
import { ParentName } from "./common/ParentName"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../../constants"

enum Label {
  CITY_NUMBER_INDEX,
  PARENT_NAME_INDEX,
}

type Props = {
  municipalityNumber: string
  parentName: string
  printConfig: string[]
}

export function KyotoParentDetail({
  municipalityNumber,
  parentName,
  printConfig,
}: Props) {
  return (
    <Table
      style={{ marginBottom: 8 }}
      columns={[
        {
          width: "150px",
          render: (_, __, index) => {
            if (index === Label.CITY_NUMBER_INDEX) {
              return <p>{t("Provision city No.")}</p>
            }

            if (index === Label.PARENT_NAME_INDEX) {
              const parentName = printConfig.includes(
                PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
              )
                ? t(
                    "Name of person with a disability for whom payment has been decided"
                  )
                : t(
                    "Name of person with a disability, etc. whose payment is determined"
                  )
              return <p>{parentName}</p>
            }

            return <></>
          },
        },

        {
          width: "335px",
          dataIndex: "value",
          render: (text) => <p>{text}</p>,
        },
      ]}
      dataSource={[
        // 市区町村番号
        { value: <CityNumber number={municipalityNumber} /> },
        // 支給決定障害者等氏名
        { value: <ParentName name={parentName} configuration={printConfig} /> },
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
