// Components
import { TopRoundedWhiteCard } from "@project/shared"
import { TableHeader } from "."
import { OwnerTable } from "../../../molecules"

// Styled components
import { OperationWrapper } from "../styles"

// Hooks
import { useTable } from "../hooks/useTable"

// Types
import { Skeleton } from "antd"
import { pageSize } from "../../../../constants/pageSize"
import { ActualCostBurdenTableProps } from "../types"

export const ActualCostBurdenTableBlock: React.FC<
  ActualCostBurdenTableProps
> = ({
  currentDay,
  currentYear,
  currentPage,
  currentMonth,
  costBurdenRecord,
  handlePageChange,
  handleDateChange,
  currentFacilityId,
  currentFacilityName,
  handleTableItemSelect,
  selectedRowKeys,
  isLoading,
}) => {
  const { columns } = useTable({
    currentDay,
    currentYear,
    currentMonth,
    currentFacilityId,
    handleTableItemSelect,
    selectedRowKeys,
  })

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        headerElement={
          <TableHeader
            currentDay={currentDay}
            currentYear={currentYear}
            currentMonth={currentMonth}
            handleDateChange={handleDateChange}
            currentFacilityName={currentFacilityName}
          />
        }
      >
        <div className={"table-container"}>
          {isLoading ? (
            <Skeleton></Skeleton>
          ) : (
            <OwnerTable
              columns={columns}
              dataSource={costBurdenRecord?.data}
              total={costBurdenRecord?.count}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePaginationChange={(page) => {
                handlePageChange(page)
              }}
              scroll={{
                x: "max-content",
              }}
              className={"always-present-scrollbar"}
            />
          )}
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
