import React from "react"

// styled components
import { Container } from "./styles"

// components
import { FilterActionBlock, ProgressRecordBlock } from "./components"

// types
import { ProgramProgressRecordListingPageProps } from "./types"

export const ProgramProgressRecordListingPage = React.forwardRef<
  HTMLDivElement,
  ProgramProgressRecordListingPageProps
>((props, ref) => {
  const {
    pageSize,
    currentPage,
    rawChildOptions,
    facilityOptions,
    currentChildName,
    handleMonthChange,
    isChildListLoading,
    currentSearchQueries,
    programProgressRecords,
    handlePaginationChange,
    isProgramProgressRecordLoading,
    handleCurrentSearchQueriesChange,
  } = props
  return (
    <Container>
      <FilterActionBlock
        rawChildOptions={rawChildOptions}
        facilityOptions={facilityOptions}
        isChildListLoading={isChildListLoading}
        currentSearchQueries={currentSearchQueries}
        handleCurrentSearchQueriesChange={handleCurrentSearchQueriesChange}
      />
      <ProgressRecordBlock
        ref={ref}
        pageSize={pageSize}
        currentPage={currentPage}
        currentChildName={currentChildName}
        handleMonthChange={handleMonthChange}
        currentSearchQueries={currentSearchQueries}
        handlePaginationChange={handlePaginationChange}
        programProgressRecords={programProgressRecords}
        isProgramProgressRecordLoading={isProgramProgressRecordLoading}
      />
    </Container>
  )
})
