import {
  TextField,
  Grid,
  PageCard,
  Required,
  Checkboxes,
  dynamicLangString,
} from "@project/shared"
import { notification, Popconfirm, Spin } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import {
  AuthContext,
  HorizontalInfo,
  Info,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms"
import { useRouter } from "next/router"
import styled from "styled-components"
import { useMutation, useQuery } from "react-query"
import {
  createJointFacility,
  getJointFacility,
  updateJointFacility,
} from "../../../services"
import { realTimeValidate } from "../../../utils/validation"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
`

const status_option = [
  {
    label: t("Day service"),
    value: "service_type01",
  },
  {
    label: t("Child development"),
    value: "service_type02",
  },
  {
    label: t("Others"),
    value: "service_type03",
  },
]

interface iJointFacilityForm {
  id?: number
  handleDelete?: () => void
  showDeleteButton?: boolean
  isDeleteLoading?: boolean
}

const JointFacilityForm: React.FC<iJointFacilityForm> = ({
  id,
  handleDelete,
  showDeleteButton = false,
  isDeleteLoading = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { refetchOtherFacilities } = useContext(AuthContext)
  const {
    data: jointFacility,
    isLoading: jointFacilityLoading,
    isFetching: jointFacilityFetching,
  } = useQuery<any, Error>(
    ["combinedFacility", id],
    () => id && getJointFacility(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        const formValues = data
        formValues["service_provided"] = [
          data?.service_type01 ? "service_type01" : "",
          data?.service_type02 ? "service_type02" : 0,
          data?.service_type03 ? "service_type03" : "",
        ]
        return formValues
      },
    }
  )

  const { mutate: mutateJointFacility, isLoading } = useMutation(
    id ? updateJointFacility : createJointFacility,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Joint facility",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Joint facility",
              "Created Successfully",
            ]),
          })
          refetchOtherFacilities()
        }
        router.push("/joint-facility")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const initialValues = jointFacility || {
    facility_name: "",
    facility_name_furigana: "",
    facility_number: null,
    service_provided: [],
    address: "",
    tel_no: "",
    fax_no: "",
    remark_text: "",
  }

  const NO_HYPHEN_REGEX = /^[-0-9]*$/

  const validationSchema = yup.object().shape({
    facility_name: yup
      .string()
      .required(t("Required"))
      .max(50, t("(within 50 char)")),
    facility_name_furigana: yup
      .string()
      .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect"))
      .max(100, t("(within 100 char)")),
    facility_number: yup
      .number()
      .required(t("Required"))
      .typeError(t("Please enter a number")),
    service_provided: yup.array().min(1, t("Required")).required(t("Required")),
    address: yup.string().max(250, t("(Within 250 char)")),
    tel_no: yup
      .string()
      .max(50, t("(Numbers & hyphens 50 char)"))
      .matches(
        NO_HYPHEN_REGEX,
        t("Phone numbers can only be number and hypens")
      ),
    fax_no: yup
      .string()
      .max(50, t("(Numbers & hyphens 50 char)"))
      .matches(NO_HYPHEN_REGEX, t("Fax can only be number and hypens")),
    remark_text: yup.string(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      const formValues = value
      status_option.map((item) => {
        formValues[item.value] = value?.service_provided?.includes(item.value)
          ? 1
          : 0
      })
      mutateJointFacility(formValues)
    },
  })

  const handlePhone = (e) => {
    const inputValue = e.target.value.trim()

    if (NO_HYPHEN_REGEX.test(inputValue)) {
      formik.setFieldValue("tel_no", inputValue)
    }
  }
  const handleFax = (e) => {
    const inputValue = e.target.value.trim()

    if (NO_HYPHEN_REGEX.test(inputValue)) {
      formik.setFieldValue("fax_no", inputValue)
    }
  }
  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  return (
    <>
      <PageCard
        title={t("Joint Facility Information")}
        style={{ marginTop: "22px" }}
      >
        {jointFacilityLoading || jointFacilityFetching ? (
          <LoaderWrapper>
            <Spin size={"large"} className={"loader"} />
          </LoaderWrapper>
        ) : (
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Joint Facility name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"facility_name"}
                className={getErrorClass("facility_name", "input")}
                height={"50px"}
                value={formik.values.facility_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.facility_name && formik.errors.facility_name
                }
                maxLength={50}
              />
              <Info className={"info"}>{t("(Within 50 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Hiragana")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"facility_name_furigana"}
                height={"50px"}
                value={formik.values.facility_name_furigana}
                onChange={(e) => realTimeValidate(e, formik)}
                maxLength={100}
                error={
                  formik.touched.facility_name_furigana &&
                  formik.errors.facility_name_furigana
                }
              />
              <Info className={"info"}>{t("(Within 100 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Business number")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"facility_number"}
                  className={getErrorClass("facility_number", "input")}
                  value={formik.values.facility_number}
                  onChange={formik.handleChange}
                  maxLength={30}
                  error={
                    formik.touched.facility_number &&
                    formik.errors.facility_number
                  }
                  placeholder={t("Example：0123456789")}
                />
                <Info className={"info"}>{t("(Number within 30 char)")}</Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Service provided")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Checkboxes
                name={"service_provided"}
                onChange={(value) => {
                  formik.setFieldValue("service_provided", value)
                }}
                options={status_option}
                value={formik.values.service_provided}
                error={
                  formik.touched.service_provided &&
                  formik.errors.service_provided
                    ? String(formik.errors.service_provided)
                    : null
                }
              />
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Address")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"address"}
                height={"50px"}
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && formik.errors.address}
                maxLength={250}
                placeholder={t("Example：□□市△△番地 1-23")}
              />
              <Info className={"info"}>{t("(Within 250 char)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Phone number")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"tel_no"}
                  height={"50px"}
                  type={"text"}
                  value={formik.values.tel_no}
                  onChange={(e) => handlePhone(e)}
                  maxLength={50}
                  placeholder={t("Example：012-345-6789")}
                  error={formik.touched.tel_no && formik.errors.tel_no}
                />
                <Info className={"info"}>
                  {t("(Numbers & hyphens 50 char)")}
                </Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("FAX number")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"fax_no"}
                  value={formik.values.fax_no}
                  type={"text"}
                  onChange={(e) => handleFax(e)}
                  maxLength={50}
                  placeholder={t("Example：012-345-6789")}
                  error={formik.touched.fax_no && formik.errors.fax_no}
                />
                <Info className={"info"}>
                  {t("(Numbers & hyphens 50 char)")}
                </Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Minutes ")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"remark_text"}
                value={formik.values.remark_text}
                onChange={formik.handleChange}
                type={"textarea"}
                placeholder={t("Example：形態 放課後等デイサービス")}
                rows={3}
              />
            </Grid>
            <Grid className={"flex-break"} />

            <div className={"button-wrapper joint-facility-form-btns"}>
              <div className={"joint-facility-button-wrapper"}>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={() => router.push("/joint-facility")}
                  className={"cancel-btn"}
                  text={t("Cancel")}
                  disabled={isLoading}
                />
                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  disabled={jointFacilityLoading}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isLoading}
                />
              </div>

              {showDeleteButton ? (
                <>
                  <div className={"joint-facility-button-wrapper"}>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      onConfirm={handleDelete}
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <OwnerButton
                        text={t("Delete ")}
                        typeOf={"primary"}
                        className={"delete-button"}
                        isLoading={isDeleteLoading}
                      />
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </StyledForm>
        )}
      </PageCard>
    </>
  )
}

export { JointFacilityForm }
