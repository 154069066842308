// Services
import { API } from "@project/shared"
import { goalAchievementRateProps } from "../components/organisms/EditOrAddProgressRecordFormPage/types"

// types

export type ProgramProgressDataProps = {
  data: {
    company_id: number
    year: number
    month: number
    facility_id: number
    facility: API.FacilityDataProps
    child_id: number
    child: API.ChildProps
    program_category_id: number
    program_category: {
      company_id: number
      program_category_name: string
      programs: any
    } & API.GenericResponse
    overall_goal: string
    review_column: string
    goal_achievement: "1" | "2" | "3" | "4" | "5"
    program_progress_record_information: [
      {
        company_id: number
        date: string
        program_progress_record_id: number
        program_id: number
        program: API.ProgramProps
        details: string
        goal_achievement: `${goalAchievementRateProps}`
        next_goal: string
        staffId: number
        staff: API.StaffProps
      } & API.GenericResponse
    ]
  } & API.GenericResponse
}

export type AllProgramProgressResponse = {
  count: number
  data: Array<ProgramProgressDataProps["data"]>
}

// payload types
export type ProgressRecPayloadDataProps = {
  year: number
  month: number
  child_id: number
  facility_id: number
  overall_goal: string
  review_column: string
  program_category_id: number
  goal_achievement: "0" | "1" | "2" | "3" | "4" | "5"
}

export type InformationDataProps = {
  date: string
  program_id: number
  details: string
  goal_achievement: `${goalAchievementRateProps}`
  next_goal: string
  staffId: number
}

export type ProgressRecInfoPayloadProps = {
  information: Array<InformationDataProps>
} & ProgressRecPayloadDataProps

export type RecTableSourceData = {
  company_id: number
  date: string
  program_progress_record_id: number
  program_progress_record: ProgramProgressDataProps["data"]
  program_id: number
  program: API.ProgramProps
  details: string
  goal_achievement: `${goalAchievementRateProps}`
  next_goal: string
  staffId: number
  staff: API.StaffProps
} & API.GenericResponse

export type RecTableSourceDataProps = {
  count: number
  data: Array<RecTableSourceData>
}

/**
 * Program Progress Record related APIs
 **/
// Add new program progress record
const BASE_END_POINT = "/program-progress-record"

// This end point is used to create a new program progress record
// A.K.A. : It takes form data from the add page.
export const createProgramProgress = (payload: ProgressRecPayloadDataProps) =>
  API.post(`${BASE_END_POINT}`, { ...payload })

// Get all program progress record
// A.K.A. : It takes form data from the list page.
export const getAllProgramProgress = async ({
  queryKey,
}: {
  queryKey: [
    string,
    {
      childId: number
      year: number
      month: number
      facilityId: number
      pageSize?: number
      page?: number
    }
  ]
}): Promise<AllProgramProgressResponse> => {
  const params = {
    child_id: queryKey[1].childId
      ? queryKey[1].childId != 0
        ? queryKey[1].childId
        : undefined
      : undefined,
    year: queryKey[1].year,
    month: queryKey[1].month,
    facility_id:
      queryKey[1].facilityId && Number.isInteger(queryKey[1].facilityId)
        ? queryKey[1].facilityId != 0
          ? queryKey[1].facilityId
          : undefined
        : undefined,
    pageSize: queryKey[1]?.pageSize,
    page: queryKey[1]?.page,
  }
  return API.get(`${BASE_END_POINT}`, {
    params,
  })
}

// Get Program Progress Record By Id
// A.K.A. : Data for the edit page [Note: Data for both form and table are fetched from this end point]
export const getAllProgressRecInfoById = (
  id: number
): Promise<ProgramProgressDataProps> => {
  return API.get(`${BASE_END_POINT}/${id}`)
}

// Update Program Progress Record Info By Id
export const updateProgressRecInfoById = async ({
  id,
  payload,
}: {
  id: number
  payload: ProgressRecInfoPayloadProps
}) => API.put(`${BASE_END_POINT}/${id}`, payload)

// check if the record is already exist
export const checkIfRecordExist: (params: {
  year: number
  month: number
  facility_id: number
  category_id: number
  child_id: number
}) => Promise<{
  data: boolean
}> = async (params) => {
  return API.post(`${BASE_END_POINT}/check-override`, {
    ...params,
  })
}

// Delete Program Progress Record Info By Id
export const deleteProgressRecInfoById = async ({
  id,
  payload,
}: {
  id: number
  payload: { facility_id: number | string }
}) => API.put(`${BASE_END_POINT}/delete/${id}`, payload)
