// packages
import { t } from "i18next"
import moment from "moment"
import { useFormik } from "formik"

// styled components
import { FilterOperationContainer, FilterOperationContent } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { SelectInput, TopRoundedWhiteCard } from "@project/shared"
import { DatePicker, OperationFieldWrapper } from "../../../molecules"

// utils
import { getLanguage } from "../../../../utils/getLanguage"

// types
import { FilterOperationBlockProps } from "../types"

export const FilterOperationBlock: React.FC<FilterOperationBlockProps> = ({
  children,
  currentDate,
  facilityOptions,
  currentFacilityId,
  handleCurrentSearchQueriesChange,
}) => {
  const formik = useFormik({
    initialValues: {
      facility: currentFacilityId,
      date: currentDate,
    },
    onSubmit: (values) => {
      handleCurrentSearchQueriesChange(
        moment(values.date).year(),
        moment(values.date).month() + 1,
        values.facility
      )
    },
  })
  const { values, setFieldValue, handleSubmit } = formik

  const handleReset = () => {
    setFieldValue("facility", facilityOptions[0].value)
    setFieldValue("date", moment().format("YYYY-MM"))
    handleCurrentSearchQueriesChange(
      moment().year(),
      moment().month(),
      facilityOptions[0].value
    )
  }

  return (
    <FilterOperationContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Operation options")}
      >
        <FilterOperationContent className={"filter-operation-content flexbox"}>
          <OperationFieldWrapper label={t("Facility")}>
            <SelectInput
              width={150}
              name={"facility"}
              className={"facility-select"}
              placeholder={t("All")}
              options={facilityOptions}
              value={values.facility}
              defaultValue={values.facility}
              onChange={(value) => {
                setFieldValue("facility", value)
              }}
            />
          </OperationFieldWrapper>

          <OperationFieldWrapper label={t("Date")}>
            <DatePicker
              className={"date-picker"}
              name={"date"}
              date={moment(values.date)}
              onDateChange={(value) => {
                setFieldValue("date", moment(value).format("YYYY-MM"))
              }}
              format={getLanguage() === "ja" ? "YYYY年MM月" : "YYYY-MM"}
              picker={"month"}
            />
          </OperationFieldWrapper>

          <div className={"form-button-group flexbox"}>
            <OwnerButton
              text={t("Search")}
              typeOf={"search"}
              className={"search-button btn"}
              onClick={() => {
                handleSubmit()
              }}
            />
            <OwnerButton
              className={"btn"}
              text={t("Reset")}
              typeOf={"secondary"}
              onClick={handleReset}
            />
          </div>
        </FilterOperationContent>
        {children}
      </TopRoundedWhiteCard>
    </FilterOperationContainer>
  )
}
