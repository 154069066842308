import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Checkbox, Skeleton } from "antd"
import { useRouter } from "next/router"
import { RowSelectableTable, SearchOperation } from "../../molecules"
import {
  ContentCTABtns,
  InfoTableContainer,
  SearchContent,
  Wrapper,
} from "./PrintUserLabels.style"
import { OwnerButton, PageHeader } from "../../atoms"
import { Checkboxes, PageCard } from "@project/shared"
import { AuthContext, getDateString } from "../../../utils"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { useFetchPrintUserLabels } from "../../../hooks/useFetchPrintUserLabels"

export const PrintUserLabels = () => {
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const facilityId = router?.query?.facility_id as string
  const after_school = router?.query?.after_school as string | string[]
  const [searchOptions, setSearchOptions] = useState<any>({
    facility_id: +facilityId || facilities[0]?.id || null,
    year: router?.query?.year || new Date().getFullYear(),
    month: router?.query?.month || new Date().getMonth() + 1,
    billing_facilities: null,
    after_school: after_school,
  })

  const facilities_options = facilities?.map((facility) => ({
    label: facility?.facility_name_short,
    value: facility?.id,
  }))
  const {
    data: userLabelsData,
    isLoading,
    isFetching,
    handleCheckAndUnCheckAll,
    gotoPrintPage,
    setBillingFacilities,
    tempBillingFacilities,
    setTempBillingFacilities,
    handleSingleCheckAndUncheck,
    isPrintEnabled,
  } = useFetchPrintUserLabels()

  const columns = [
    {
      title: t("Clear all"),
      key: "uncheckAll",
      render: (row) => {
        return (
          <Checkbox
            checked={row?.checked}
            onChange={({ target: { checked } }) =>
              handleSingleCheckAndUncheck(row?.child_id, checked)
            }
          />
        )
      },
    },
    {
      title: t("Parent's name (Hiragana)"),
      key: "parentName",
      sorter: (a, b) => a?.id > b?.id,
      render: (row) => `${row?.parent_name} (${row?.parent_name_furigana})`,
    },
    {
      title: t("Childs name (Hiragana)"),
      key: "childName",
      sorter: (a, b) => a?.id > b?.id,
      render: (row) => `${row?.child_name} (${row?.child_name_furigana})`,
    },
    {
      title: t("Beneficiary certificate number"),
      key: "receiving_certificate_number",
      sorter: (a, b) => a?.id > b?.id,
      render: (row) => row?.receiving_certificate_number,
    },
    {
      title: t("Facility"),
      key: "facility",
      sorter: (a, b) => a?.id > b?.id,
      render: (row) => row?.facility_name_short,
    },
  ]

  const navigateToNHIFPage = () => {
    router.push(
      `/national-health-insurance-billing?facility_id=${searchOptions?.facility_id}&year=${searchOptions?.year}&month=${searchOptions?.month}`
    )
  }

  return (
    <Wrapper>
      <PageHeader
        title={t("Print user labels")}
        icon={<img src={"/assets/icons/Cylinder.svg"} alt={"icon"} />}
      />
      <SearchOperation
        facilityOptions={facilities}
        searchOptions={searchOptions}
        onDisplayChange={(val) => {
          setSearchOptions({
            ...searchOptions,
            ...val,
          })
          updateParams(
            { ...searchOptions, ...val },
            "/national-health-insurance-billing"
          )
        }}
        handleReset={() => {
          setSearchOptions({
            ...searchOptions,
            facility_id: facilities?.[0]?.id,
            year: +moment().format("YYYY"),
            month: +moment().format("MM"),
          })
        }}
        secondaryBtnText={t("Unfilter")}
      />
      <div className={"back_cta"}>
        <OwnerButton
          text={t("<<Back")}
          shape={"circle"}
          typeOf={"draft"}
          onClick={navigateToNHIFPage}
        />
      </div>
      <PageCard
        title={t("{{date}}List of facility use children", {
          date: getDateString(searchOptions?.year, searchOptions?.month),
        })}
      >
        <div className={"main_content"}>
          <SearchContent>
            <div>{t("You can filter by selecting billing facilities")}</div>
            <div className={"btns"}>
              <OwnerButton
                className={"button"}
                typeOf={"check-all"}
                text={t("Check all billing facilities")}
                onClick={() =>
                  setTempBillingFacilities(
                    facilities_options?.map((val) => val?.value)
                  )
                }
              />
              <OwnerButton
                className={"button"}
                text={t("Unlock all billing facilities")}
                shape={"circle"}
                typeOf={"secondary"}
                onClick={() => setTempBillingFacilities([])}
              />
            </div>
            <Checkboxes
              options={facilities_options || []}
              value={tempBillingFacilities}
              onChange={(value) => {
                setTempBillingFacilities(value)
              }}
            />
            <OwnerButton
              className={"search_btn"}
              text={t("Search")}
              shape={"circle"}
              typeOf={"secondary"}
              onClick={() => {
                const params = Array.isArray(tempBillingFacilities)
                  ? tempBillingFacilities?.join(",")
                  : tempBillingFacilities
                setBillingFacilities(tempBillingFacilities)
                updateParams(
                  {
                    facility_id: params ? "" : facilityId,
                    billing_facilities: params,
                    year: +router?.query?.year || new Date().getFullYear(),
                    month: +router?.query?.month || new Date().getMonth() + 1,
                    after_school: after_school,
                  },
                  "/national-health-insurance-billing/prints/user-labels"
                )
              }}
            />
          </SearchContent>
          {!isLoading && userLabelsData?.length >= 1 && (
            <ContentCTABtns>
              <OwnerButton
                typeOf={"check-all"}
                text={t("Check all")}
                onClick={() => handleCheckAndUnCheckAll("ALL")}
              />
              <OwnerButton
                text={t("Clear all")}
                shape={"circle"}
                typeOf={"secondary"}
                onClick={() => handleCheckAndUnCheckAll("NONE")}
              />
              <OwnerButton
                className={"search_btn"}
                text={t("Print")}
                shape={"round"}
                typeOf={"primary"}
                icon={"print"}
                onClick={gotoPrintPage}
                disabled={!isPrintEnabled}
              />
            </ContentCTABtns>
          )}

          <InfoTableContainer>
            {isLoading || isFetching ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                {userLabelsData?.map((val, index) => (
                  <RowSelectableTable
                    key={index}
                    data={val?.data}
                    info={{
                      provision: val?.provision_city,
                      city_number: val?.city_number,
                    }}
                    columns={columns}
                    loading={isLoading || isFetching}
                  />
                ))}
              </>
            )}
          </InfoTableContainer>
          {!isLoading && userLabelsData?.length >= 1 && (
            <ContentCTABtns>
              <OwnerButton
                text={t("Print")}
                shape={"round"}
                typeOf={"primary"}
                icon={"print"}
                onClick={gotoPrintPage}
                disabled={!isPrintEnabled}
              />
              <OwnerButton
                text={t("<<Back")}
                shape={"circle"}
                typeOf={"draft"}
                onClick={navigateToNHIFPage}
              />
            </ContentCTABtns>
          )}
        </div>
      </PageCard>
    </Wrapper>
  )
}
