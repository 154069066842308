import React, { useState, useEffect, useContext, useMemo } from "react"
import moment from "moment"
import {
  Checkbox,
  Checkboxes,
  RadioButtons,
  SelectInput,
  TextField,
  benefitPaymentAmount,
  AFTER_DAY_SCHOOL_DISABILITIES,
  CHILD_DEVELOPMENT_SUPPORT_DISABILITIES,
  MULTIPLE_CHILD_REDUCTION_TYPE,
  SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY,
  MEDICAL_CARE_CHILD_TYPE,
  getFacilityOptionsBasedOnMangementCompany,
  UPPER_LIMIT_CONTROL_ADDITION_ACQUISATIONS,
  UPPER_LIMIT_MANAGEMENT_COMPANY_OPTIONS,
  WarikeDatePicker,
  SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY_AFTER_SCHOOL,
  BURDERN_UPPER_LIMIT_WITH_STRING_VALUE,
  getChildDisorderTypeOptions,
} from "@project/shared"
import { OwnerButton } from "../../../components"
import { useTranslation } from "react-i18next"

import { InputWrapper } from "../UserRegistrationForm"
import { ALL_SERVICE_USE_OPTIONS } from "../../../constants/monitoring"
import { AuthContext, scrollToFirstErrorField } from "../../../utils"
import { FormWrapper } from "../../../module/child/recipient-certificate-form"
import { ServiceCodesModal } from "../ServiceCodesModal"
import { AllowNumbersOnly } from "../../../utils/inputs"

interface IChildrenRecipientFormProps {
  data: any
  handleChange: (a: string, b: any) => void
  handleBlur: (e: any) => void
  touched: any
  errors: any
  otherFacilitiesData: any
  otherFacilitiesDataIsLoading: boolean
  consultationSupportOfficeData: any
  consultationSupportOfficeDataIsLoading: any
  provisionalCityDataIsLoading: boolean
  provisionalCityData: any
  formik: any
}

const ChildRegistrationForm: React.FC<IChildrenRecipientFormProps> = ({
  data,
  handleBlur,
  handleChange,
  touched,
  errors,
  consultationSupportOfficeData,
  consultationSupportOfficeDataIsLoading,
  otherFacilitiesData,
  otherFacilitiesDataIsLoading,
  provisionalCityDataIsLoading,
  provisionalCityData,
  formik,
}) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const dateFormat = "YYYY年MM月DD日"
  const [openServiceCodeModal, setOpenServiceCodeModal] = useState(false)
  const [childCurrentAge, setChildCurrentAge] = useState<number>(null)

  const BENIFIT_PAYMENT_AMOUNT = useMemo(() => benefitPaymentAmount(), [])

  const benefitOptionNegative = BENIFIT_PAYMENT_AMOUNT.map((o) => {
    if (o.value > 0 && o.value != 31) {
      return {
        label: +o.label * -1,
        value: o.value * -1,
      }
    } else if (o.value === 0) {
      return {
        label: +o.label * -1,
        value: -0.1,
      }
    }
  }).filter((o) => o?.value <= 0)

  const closeModalOnCancel = () => {
    setOpenServiceCodeModal(false)
  }

  useEffect(() => {
    setChildCurrentAge(moment().diff(data?.birthday, "years", false))
  }, [data?.birthday])

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(errors)
  }, [formik.submitCount, formik.isValid])

  const copyBenefitDeterminationPeriodDate = () => {
    formik.setFieldValue("provision_end_date", data.benefit_end_date)
    formik.setFieldValue("provision_start_date", data.benefit_start_date)
  }

  const copyApplicationPeriodDate = () => {
    formik.setFieldValue("apply_end_date", data.benefit_end_date)
    formik.setFieldValue("apply_start_date", data.benefit_start_date)
  }

  const setSameEmptyDates = (aFormik, date) => {
    aFormik.setFieldValue("benefit_start_date", date)
    aFormik.setFieldValue("provision_start_date", date)
    aFormik.setFieldValue("apply_start_date", date)
  }

  const isSameOrAfterR6April = () => {
    return (
      data.receiving_certificate_start_date &&
      moment(data.receiving_certificate_start_date).isSameOrAfter(
        moment("2024-04-01")
      )
    )
  }
  return (
    <FormWrapper>
      <InputWrapper isRequired label={t("Recipient certificate start date")}>
        <div>
          <WarikeDatePicker
            name={"receiving_certificate_start_date"}
            className={"custom-date-picker"}
            onBlur={handleBlur}
            onDateChange={(date) => {
              handleChange("receiving_certificate_start_date", date)
            }}
            date={data?.receiving_certificate_start_date}
            id={"receiving_certificate_start_date"}
            // value={data?.receiving_certificate_start_date}
            placeholder={""}
            format={dateFormat}
          />
          {errors?.receiving_certificate_start_date &&
            touched?.receiving_certificate_start_date && (
              <span
                className={"error-field"}
                style={{ color: "#EB5757", fontSize: "12px" }}
              >
                {t("Required")}
              </span>
            )}
        </div>
      </InputWrapper>
      <InputWrapper
        className={"recipient-certificate-number-wrapper"}
        isRequired={true}
        label={t("Recipient certificate number")}
      >
        <div className={"certificate-change-container"}>
          <div className={"children-wrapper no-padding"}>
            <div className={"checkbox-group"}>
              <div>
                <p
                  id={"receiving_certificate_number"}
                  className={"beneficiary_certificate_number"}
                >
                  {data?.receiving_certificate_number}
                </p>
              </div>

              <Checkbox
                label={t("Change the beneficiary identification number")}
                name={"change_beneficiary_certificate_number"}
                checked={data?.change_beneficiary_certificate_number}
                onChange={(e) => {
                  handleChange(
                    "change_beneficiary_certificate_number",
                    e.target.checked
                  )
                }}
              />
            </div>
            {data?.change_beneficiary_certificate_number && (
              <TextField
                maxLength={10}
                name={"changed_recieving_certificate_number"}
                value={data?.changed_recieving_certificate_number}
                bgcolor={"white"}
                type={"text"}
                min={0}
                className={"changed_recieving_certificate_number"}
                onKeyDown={(e) => AllowNumbersOnly(e)}
                onChange={(e) => {
                  handleChange(
                    "changed_recieving_certificate_number",
                    e.target.value?.substring(0, 10)
                  )
                }}
                error={
                  touched?.change_beneficiary_certificate_number &&
                  errors?.changed_recieving_certificate_number
                    ? t("Required")
                    : ""
                }
              />
            )}
          </div>
          {errors?.receiving_certificate_number && (
            <div className={"danger-info"}>
              {errors?.receiving_certificate_number}
            </div>
          )}
        </div>
      </InputWrapper>
      <InputWrapper label={t("Provision city")}>
        <div className={"payment_municipality_wrapper"}>
          <SelectInput
            value={data?.provision_city_id}
            className={"custom-select medium-select"}
            name={"provision_city_id"}
            onChange={(v) => handleChange("provision_city_id", v)}
            options={
              Array.isArray(provisionalCityData)
                ? [{ label: "----", value: null }].concat(provisionalCityData)
                : provisionalCityData
            }
            loading={provisionalCityDataIsLoading}
            placeholder={"----"}
          />
          <p className={"danger-info"}>
            {t(
              "*If there is no option, please register from the supply municipality master."
            )}
          </p>
          <OwnerButton
            typeOf={"secondary"}
            type={"button"}
            text={t("Register the provision city")}
            className={"custom-secondary-button"}
            onClick={() => window.open("/provisional-cities/add", "_blank")}
          />
        </div>
      </InputWrapper>
      <InputWrapper label={t("Use service")} isRequired>
        <SelectInput
          className={"custom-select large-select"}
          defaultValue={"放課後等デイサービス"}
          options={ALL_SERVICE_USE_OPTIONS}
          name={"service_type"}
          value={data?.service_type}
          required
          id={"service_type"}
          onBlur={handleBlur}
          error={touched?.service_type && errors?.service_type && t("Required")}
          onChange={(value) => handleChange("service_type", value)}
        />
      </InputWrapper>
      <InputWrapper label={t("Children with disabilities")} isRequired>
        <div>
          <div className={"disabled-children-main-wrapper"}>
            <SelectInput
              className={"custom-select pr-15 min-auto-width"}
              popupClassName={"select-small-popup"}
              value={data?.handicapped_child_type}
              name={"handicapped_child_type"}
              onChange={(v) => handleChange("handicapped_child_type", v)}
              options={
                data?.service_type === 1
                  ? AFTER_DAY_SCHOOL_DISABILITIES
                  : CHILD_DEVELOPMENT_SUPPORT_DISABILITIES
              }
              error={
                touched?.handicapped_child_type &&
                errors?.handicapped_child_type &&
                t("Required")
              }
            />
            <Checkbox
              label={t("Child with medical care")}
              checked={data?.medical_care_child_flg}
              onChange={(e) =>
                handleChange("medical_care_child_flg", e.target.checked)
              }
            />
            <div className={"disabled-child-input"}>
              <p className={"label"}>{t("[Medical Decision Score")}</p>
              <TextField
                type={"number"}
                height={"10px"}
                min={0}
                maxLength={3}
                value={data?.medical_judgement_score}
                name={"medical_judgement_score"}
                disabled={!data?.medical_care_child_flg}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  const key = e?.keyCode
                  // don't allow to input 0 before other number
                  if (!data?.medical_judgement_score && key == 48)
                    e.preventDefault()
                  // prevent default if key isn't backspace or number
                  if (
                    key != 8 &&
                    (key < 47 ||
                      key > 57 ||
                      data?.medical_judgement_score?.length >= 3)
                  )
                    e.preventDefault()
                }}
                onChange={(e) => {
                  if (+e.target.value == 0)
                    handleChange("medical_judgement_score", null)
                  else if (
                    (data?.medical_judgement_score?.length < 3 ||
                      e.target.value?.length <= 3) &&
                    !(+e.target.value < 0)
                  )
                    handleChange("medical_judgement_score", e.target.value)
                }}
              />
              <span>{"："}</span>
            </div>
            <div className={"disabled-child-input"}>
              <p className={"label"}>
                {t("Category for children requiring medical care")}
              </p>
              <span className={"required"}>{t("Required")}</span>
              <SelectInput
                className={"custom-select pr-15 over-ride-select-class"}
                disabled={!data?.medical_care_child_flg}
                options={MEDICAL_CARE_CHILD_TYPE}
                onBlur={handleBlur}
                onChange={(v) => handleChange("medical_care_child_type", v)}
                value={data?.medical_care_child_type}
              />
              <span>{"]"}</span>
            </div>
          </div>
          <div className={"disabled-child-info-wrapper"}>
            <p>
              <span>{t("Decision service code is: ")}</span>
              <span style={{ fontWeight: "bold" }}>
                {data?.service_type === 2
                  ? SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY[
                      data?.handicapped_child_type
                    ]
                  : SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY_AFTER_SCHOOL[
                      data?.handicapped_child_type
                    ]}
              </span>
              <span>{t(" is output.")}</span>
            </p>
          </div>
          <div>
            <OwnerButton
              typeOf={"primary"}
              text={t("Check the list of service codes")}
              onClick={() => {
                setOpenServiceCodeModal(true)
              }}
            />
          </div>
        </div>
      </InputWrapper>
      {!isSameOrAfterR6April() && (
        <InputWrapper label={t("Special support addition")}>
          <div>
            <p className={"danger-info"}>
              {t(
                "*Please check if you want to calculate the special support addition."
              )}
            </p>
            <Checkbox
              label={t("Calculate")}
              name={"special_support_addition_flg"}
              checked={data?.special_support_addition_flg}
              onChange={(e) => {
                handleChange("special_support_addition_flg", e.target.checked)
              }}
            />
          </div>
        </InputWrapper>
      )}

      <InputWrapper label={t("Remarks field")}>
        <div>
          <p className={"danger-info"}>
            {t(
              "*Please check if there is a description on the recipient certificate."
            )}
          </p>
          {data?.receiving_certificate_start_date && data?.service_type && (
            <Checkboxes
              onChange={(v) => {
                handleChange("remarks", v)
              }}
              value={data?.remarks}
              options={getChildDisorderTypeOptions(
                data?.receiving_certificate_start_date,
                data?.service_type
              )}
            />
          )}
        </div>
      </InputWrapper>
      <InputWrapper label={t("Benefit payment amount")}>
        <div className={"small-select-wrapper"}>
          <SelectInput
            options={BENIFIT_PAYMENT_AMOUNT}
            value={
              data?.is_benefit_days_in_month
                ? data?.is_benefit_days_in_month
                : data?.benefit_days || "0"
            }
            onChange={(v) => {
              handleChange("is_benefit_days_in_month", v)
              if (v < 0) {
                handleChange("benefit_days", -0.1)
              } else {
                handleChange("benefit_days", v)
              }
            }}
            name={"is_benefit_days_in_month"}
            className={"custom-select benefit-day-select"}
          />
          {data?.is_benefit_days_in_month < 0 && (
            <div className={"small-select-wrapper"}>
              <SelectInput
                options={benefitOptionNegative}
                value={
                  data?.is_benefit_days_in_month < 0
                    ? data?.benefit_days
                    : data?.benefit_days || "0"
                }
                onChange={(v) => handleChange("benefit_days", v)}
                name={"benefit_days"}
                className={"custom-select small-select"}
              />
            </div>
          )}
          <p className={"beneficiary_certificate_number"}>{"日"}</p>
        </div>
      </InputWrapper>
      <InputWrapper label={t("Benefit determination period")}>
        <div className={"datepicker-wrapper"}>
          <WarikeDatePicker
            className={"custom-date-picker"}
            name={"benefit_start_date"}
            date={
              data?.benefit_start_date ? moment(data?.benefit_start_date) : null
            }
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
            onDateChange={(val) => {
              if (
                formik?.values?.provision_start_date == null &&
                formik?.values?.apply_start_date == null
              ) {
                setSameEmptyDates(formik, val)
              } else {
                formik.setFieldValue("benefit_start_date", val)
              }
            }}
          />
          <span className={"datepicker-seperator"}>{"~"} </span>
          <WarikeDatePicker
            className={"custom-date-picker"}
            onDateChange={(v) => handleChange("benefit_end_date", v)}
            name={"benefit_end_date"}
            date={data?.benefit_end_date}
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
            disabledDate={(current) =>
              current.isBefore(moment(data.benefit_start_date))
            }
          />
        </div>
      </InputWrapper>
      <InputWrapper label={t("Payment period")}>
        <div className={"datepicker-wrapper"}>
          <WarikeDatePicker
            onDateChange={(v) => handleChange("provision_start_date", v)} //
            name={"provision_start_date"}
            date={data?.provision_start_date}
            className={"custom-date-picker"}
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
          />
          <span className={"datepicker-seperator"}>{"~"} </span>
          <WarikeDatePicker
            className={"custom-date-picker"}
            onDateChange={(v) => handleChange("provision_end_date", v)}
            name={"provision_end_date"}
            date={data?.provision_end_date}
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
            disabledDate={(current) =>
              current.isBefore(moment(data.provision_start_date))
            }
          />
          <OwnerButton
            typeOf={"secondary"}
            text={t("Copy")}
            className={"copy"}
            onClick={() => {
              copyBenefitDeterminationPeriodDate()
            }}
          />
        </div>
      </InputWrapper>
      <InputWrapper label={t("Consultation support office")}>
        <div>
          <SelectInput
            className={"custom-select large-select"}
            name={"consultation_support_id"}
            onChange={(value) => handleChange("consultation_support_id", value)}
            options={consultationSupportOfficeData}
            loading={consultationSupportOfficeDataIsLoading}
            value={data?.consultation_support_id}
            placeholder={"--"}
          />
          <p className={"danger-info"}>
            {t(
              "*If there is no option, please register from the consultation support office master."
            )}
          </p>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Register consulation support office")}
            className={"custom-secondary-button"}
            onClick={() =>
              window.open("/consultation-support-office/add", "_blank")
            }
          />
        </div>
      </InputWrapper>
      <InputWrapper label={t("Consultation support specialist")}>
        <div>
          <TextField
            bgcolor={"white"}
            name={"consultation_support_specialist"}
            onChange={(e) =>
              handleChange("consultation_support_specialist", e.target.value)
            }
            value={data?.consultation_support_specialist}
          />
          <p className={"beneficiary_certificate_number"}>
            {t("(Up to 50 characters)")}
          </p>
        </div>
      </InputWrapper>
      <InputWrapper label={t("Burden upper limit monthly amount")}>
        <div>
          <div className={"small-select-wrapper"} style={{ marginBottom: 16 }}>
            <SelectInput
              width={"auto"}
              options={BURDERN_UPPER_LIMIT_WITH_STRING_VALUE}
              className={
                "custom-select small-select burden-upper-limit-monthly-amt-select-input"
              }
              dropdownMatchSelectWidth={false}
              value={data?.burden_max_month ? `${data?.burden_max_month}` : "0"}
              onChange={(v) => handleChange("burden_max_month", v)}
            />
            <p className={"beneficiary_certificate_number"}>{"円"}</p>
          </div>
          <div>
            {/* TODO: translate */}
            <Checkbox
              label={"自治体助成 有り"}
              name={"local_burden_max_month_flg"}
              checked={data?.local_burden_max_month_flg}
              onChange={(e) => {
                handleChange("local_burden_max_month_flg", e.target.checked)
              }}
            />
            {Boolean(data?.local_burden_max_month_flg) && (
              <div style={{ marginTop: 16 }}>
                <h6>{"助成後の利用者負担上限月額"}</h6>
                <div style={{ display: "flex", gap: 10 }}>
                  <TextField
                    height={"36px"}
                    maxLength={5}
                    name={"local_burden_max_month"}
                    value={data?.local_burden_max_month}
                    bgcolor={"white"}
                    type={"text"}
                    min={0}
                    onKeyDown={(e) => AllowNumbersOnly(e)}
                    onChange={(e) => {
                      handleChange(
                        "local_burden_max_month",
                        e.target.value?.substring(0, 5)
                      )
                    }}
                  />
                  <p className={"beneficiary_certificate_number"}>{"円"}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </InputWrapper>
      <InputWrapper label={t("Applicable period")}>
        <div className={"datepicker-wrapper"}>
          <WarikeDatePicker
            className={"custom-date-picker"}
            onDateChange={(v) => handleChange("apply_start_date", v)}
            name={"apply_start_date"}
            date={data?.apply_start_date}
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
          />
          <span className={"datepicker-seperator"}>{"~"} </span>
          <WarikeDatePicker
            className={"custom-date-picker"}
            onDateChange={(v) => handleChange("apply_end_date", v)}
            name={"apply_end_date"}
            date={data?.apply_end_date}
            placeholder={"YYYY年MM月DD日"}
            format={"YYYY年MM月DD日"}
            disabledDate={(current) =>
              current.isBefore(moment(data?.apply_start_date))
            }
          />
          <OwnerButton
            typeOf={"secondary"}
            text={t("Copy")}
            className={"copy"}
            onClick={() => {
              copyApplicationPeriodDate()
            }}
          />
        </div>
      </InputWrapper>
      <InputWrapper label={t("Upper limit management company")}>
        <div>
          <p className={"danger-info"}>
            {t(
              "※If the facility registered with the same EASYY is a business that manages the upper limit, please select 'own facility'."
            )}
          </p>
          <RadioButtons
            value={data?.maximum_manage_company_type}
            name={"maximum_manage_company_type"}
            options={UPPER_LIMIT_MANAGEMENT_COMPANY_OPTIONS}
            onChange={(e) => {
              handleChange("maximum_manage_company_type", e.target.value)
              handleChange("facility_other_id", undefined)
              handleChange("facility_id", undefined)
              if (e.target.value != 1) {
                handleChange("facility_id", undefined)
              }
              if (e.target.value === 1) {
                handleChange("maximum_manage_addition_flg", true)
              } else {
                handleChange("maximum_manage_addition_flg", false)
              }
            }}
          />
          {data?.maximum_manage_company_type > 0 && (
            <div style={{ marginTop: 10 }}>
              <SelectInput
                width={"auto"}
                className={"dynamic-dropdown-width custom-select"}
                dropdownMatchSelectWidth={false}
                options={
                  Number(data?.maximum_manage_company_type)
                    ? getFacilityOptionsBasedOnMangementCompany(
                        Number(data?.maximum_manage_company_type),
                        facilities,
                        otherFacilitiesData
                      )
                    : []
                }
                placeholder={t(
                  `Select ${
                    data?.maximum_manage_company_type === 2 ? "Other " : ""
                  }facility`
                )}
                loading={
                  data?.maximum_manage_company_type === 2 &&
                  otherFacilitiesDataIsLoading
                }
                name={"facility"}
                onChange={(v) => {
                  if (data?.maximum_manage_company_type === 1) {
                    handleChange("facility_id", v)
                    handleChange("facility_other_id", undefined)
                  } else if (data?.maximum_manage_company_type === 2) {
                    handleChange("facility_other_id", v)
                    handleChange("facility_id", undefined)
                  }
                }}
                value={
                  data?.maximum_manage_company_type === 1
                    ? data?.facility_id
                    : data?.facility_other_id
                }
              />
            </div>
          )}
        </div>
      </InputWrapper>
      <InputWrapper
        label={t("Upper limit control addition acquisition setting")}
      >
        {/* //maximum_manage_addition_flg */}
        <SelectInput
          disabled={data?.maximum_manage_company_type === 1 ? false : true}
          defaultValue={"取得しない"}
          name={"maximum_manage_addition_flg"}
          className={"custom-select large-select"}
          placeholder={"---"}
          options={UPPER_LIMIT_CONTROL_ADDITION_ACQUISATIONS}
          value={
            data?.maximum_manage_company_type === 1
              ? data?.maximum_manage_addition_flg
                ? 2
                : 1
              : null
          }
          onChange={(value) =>
            handleChange(
              "maximum_manage_addition_flg",
              value == 2 ? true : false
            )
          }
        />
      </InputWrapper>
      <InputWrapper label={t("Multi-child reduction target category")}>
        <SelectInput
          options={MULTIPLE_CHILD_REDUCTION_TYPE}
          value={data?.multi_child_reduction_type}
          name={"multi_child_reduction_type"}
          onChange={(v) => handleChange("multi_child_reduction_type", v)}
          className={"custom-select medium-select"}
          placeholder={"--"}
        />
      </InputWrapper>
      {childCurrentAge != null && childCurrentAge < 7 ? (
        <InputWrapper label={t("Free period")}>
          <div className={"datepicker-info-wrapper"}>
            <div className={"datepicker-wrapper"}>
              <WarikeDatePicker
                className={"custom-date-picker"}
                onDateChange={(v) =>
                  handleChange("free_of_charge_start_date", v)
                }
                name={"free_of_charge_start_date"}
                date={data?.free_of_charge_start_date}
                placeholder={dateFormat}
                format={dateFormat}
              />
              <span className={"datepicker-seperator"}>{"~"} </span>
              <WarikeDatePicker
                className={"custom-date-picker"}
                onDateChange={(v) => handleChange("free_of_charge_end_date", v)}
                name={"free_of_charge_end_date"}
                date={data?.free_of_charge_end_date}
                placeholder={dateFormat}
                format={dateFormat}
              />
            </div>
            <div className={"danger-info"}>
              {t(
                "*Children who are three years old from April 1, the first day they turn 3 years old, will automatically be exempted from user fees.\nIf you register the date of the target period, it will be used for the error check of the National Health Insurance Federation report and CSV output."
              )}
            </div>
          </div>
        </InputWrapper>
      ) : (
        ""
      )}

      {openServiceCodeModal && (
        <ServiceCodesModal
          open={openServiceCodeModal}
          onCancel={closeModalOnCancel}
        />
      )}
    </FormWrapper>
  )
}

export { ChildRegistrationForm }
