import { notification } from "antd"
type NotificationType = "success" | "info" | "warning" | "error"
export const toastMsg = (
  type: NotificationType,
  msg: string,
  desc?: string
) => {
  notification[type]({
    message: msg,
    description: desc,
    className: "antd-over-ride-notification-container",
  })
}
