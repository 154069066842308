import React from "react"

// packages
import moment from "moment"
import { t } from "i18next"

// components
import {
  ErrorMessage,
  RangeDatePickerGroup,
  BulkPeriodSettingOpSecContainer,
} from "../styles"
import { OwnerButton, QuoteText } from "../../../atoms"
import { DatePicker, OperationFieldWrapper } from "../../../molecules"

// types
import { BulkPeriodSettingOpSectionProps } from "../types"
import { getLanguage } from "../../../../utils/getLanguage"

export const BulkPeriodSettingOpSection: React.FC<
  BulkPeriodSettingOpSectionProps
> = ({ formik }) => {
  return (
    <BulkPeriodSettingOpSecContainer>
      <QuoteText className={"quote-text"}>
        {t("Bulk settings for period")}
      </QuoteText>
      <OperationFieldWrapper
        className={"field-wrapper_year-month"}
        label={t("Year month")}
      >
        <RangeDatePickerGroup className={"range-date-picker-group"}>
          <div className={"date-pickers"}>
            <DatePicker
              name={"startDate"}
              date={
                moment(formik.values.startDate).isValid()
                  ? moment(formik.values.startDate)
                  : null
              }
              onDateChange={(e) => {
                formik.setFieldValue("startDate", moment(e))
              }}
              onBlur={formik.handleBlur}
              status={
                formik.errors.endDate && formik.touched.endDate && "error"
              }
              format={getLanguage() === "ja" ? "YYYY年MM月DD日" : "YYYY-MM-DD"}
            />
            <span className={"datePicker-separator"}>{"~"}</span>
            <DatePicker
              name={"endDate"}
              date={
                moment(formik.values.endDate).isValid()
                  ? moment(formik.values.endDate)
                  : null
              }
              onDateChange={(e) => {
                formik.setFieldValue("endDate", moment(e))
              }}
              status={
                formik.errors.endDate && formik.touched.endDate && "error"
              }
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.errors.endDate && formik.touched.endDate ? (
            <ErrorMessage className={"error-message"}>
              {typeof formik.errors.endDate === "string"
                ? formik.errors.endDate
                : formik.errors.endDate[0]}
            </ErrorMessage>
          ) : null}
        </RangeDatePickerGroup>
      </OperationFieldWrapper>

      <OwnerButton
        text={t("Set during temporary school closure")}
        typeOf={"primary"}
        onClick={() => formik.handleSubmit()}
      />
    </BulkPeriodSettingOpSecContainer>
  )
}
