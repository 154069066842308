import { API } from "@project/shared"
import moment from "moment"

export interface IReturnBusinessRecord {
  count?: number
  data?: any
}

interface IBusinessRecordParams {
  page?: string
  pageSize?: string
  from_date?: string
  to_date?: string
  facility_id?: string
  date?: string
}

export const createBusinessDailyReport = async (values: any) => {
  return API.post(`/business-daily-report`, values)
}

export const fetchAllBusinessReport = async ({
  queryKey,
}): Promise<IReturnBusinessRecord> => {
  const params = {} as IBusinessRecordParams
  params.page = queryKey[1]
  params.pageSize = "20"
  params.facility_id = queryKey[2]?.facility
  params.from_date = queryKey[2]?.from_date
    ? moment(queryKey[2]?.from_date).format("YYYY-MM-DD")
    : null
  params.to_date = queryKey[2]?.to_date
    ? moment(queryKey[2]?.to_date).format("YYYY-MM-DD")
    : null
  params.date = queryKey[2]?.today
    ? moment(queryKey[2]?.today).format("YYYY-MM-DD")
    : null
  return API.get(`/business-daily-report`, { params })
}

export const getBusinessDailyReportView = ({ facilityId, date }) =>
  API.get(`/business-daily-report/view/${facilityId}?date=${date}`)

export const getBusinessDailyReport = async (id) => {
  return API.get(`/business-daily-report/${id}`)
}

export const updateBusinessDailyReport = ({
  values,
  id,
}: {
  values: any
  id: any
}) => API.put(`/business-daily-report/${id}`, { ...values })

export const getBusinessDailyReportBulkPrintView = (query) =>
  API.get(`/business-daily-report/bulk-print?${query}`)

export const deleteBusinessDailyReport = ({
  payload,
  id,
}: {
  payload: { facility_id: string | number }
  id: string | number
}) => API.put(`/business-daily-report/delete/${id}`, payload)
