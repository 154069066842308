import React, { Fragment, useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { PageCard } from "@project/shared"
import {
  ContentWrapper,
  PrintButtonsSection,
  TableContainerStyled,
  TableContentSection,
} from "./index.styles"
import { OperationOptions } from "./OperationOptions"
import {
  WEEKDAYS,
  getHolidayClassName,
  getPaddedMonth,
} from "../../../utils/date"
import { OwnerButton } from "../../atoms"
import { fetchMonthlyActivitiesDetail } from "../../../services/monthylActivities"
import { TopInfo } from "./TopInfo"
import { OwnerTable } from "../../molecules"
import { Typography } from "antd"
import {
  AuthContext,
  getDateStringFromDate,
  getProgramFacilities,
  scrollToSelectedElement,
} from "../../../utils"

export const MonthlyActivitiesApplication = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { year, month } = router.query
  const [date] = useState(new Date(+year, +month - 1))
  const [facilityIds, setFacilityIds] = useState<number[]>(
    (router.query?.facilityIds as string)?.split(",").map(Number) || []
  )
  const [displayOptions, setDisplayOptions] = useState<number[]>(
    (router.query?.displayOptions as string)?.split(",").map(Number) || [1, 2]
  )
  const { facilities } = useContext(AuthContext)
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>({
    queryKey: ["fetchMonthlyActivitiesDetail", date, facilityIds],
    queryFn: () => fetchMonthlyActivitiesDetail(date, facilityIds),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 3,
  })
  const programFacilities = getProgramFacilities(response?.data)

  const isHoliday = (data, record) => {
    const cellClass = getHolidayClassName(
      record?.facilityHolidays.length > 0 &&
        record?.facilityHolidays.length == facilityIds?.length
        ? "土"
        : ""
    )
    const isWeekday = cellClass === "saturday" || cellClass === "sunday"
    return { cellClass, isWeekday }
  }
  const onSharedColumn = (data, facilityId) => {
    let isHoliday = data?.facilityHolidays?.includes(facilityId)
    if (!facilityId) {
      isHoliday =
        data?.facilityHolidays?.length > 0 &&
        data?.facilityHolidays?.length == facilityIds?.length
    }
    const cellClass = getHolidayClassName(isHoliday ? "土" : "")
    const isWeekday = cellClass === "saturday" || cellClass === "sunday"
    if (isWeekday) {
      return { colSpan: 0 }
    }
    return {}
  }

  const generateFacilityColumns = (facilityIds) => {
    const selectedFacilities = facilityIds
    const columns = []
    const findNumberOfColSpan = () => {
      if (displayOptions?.includes(1) && displayOptions?.includes(10)) {
        return 3
      } else if (displayOptions?.includes(1) || displayOptions?.includes(10)) {
        return 2
      }
      return 1
    }
    const getProgramContentAndActualCostData = (record, facilityId) => {
      const thisDate = new Date(
        +(year as string),
        +(month as string) - 1,
        record.day
      )
      const data = record?.arrResponse?.find((resp) => {
        const checkHolidayDates = resp?.isHoliday && JSON.parse(resp?.date)
        return (
          resp?.date?.startsWith(getDateStringFromDate(thisDate)) ||
          checkHolidayDates?.includes(getDateStringFromDate(thisDate))
        )
      })

      const isHoliday = record?.facilityHolidays?.includes(facilityId)

      const cellClass = getHolidayClassName(isHoliday ? "土" : "")
      const isWeekday = cellClass === "saturday" || cellClass === "sunday"
      return { data, cellClass, isWeekday }
    }
    const DynamicFacilityColumn = (facilityId) => {
      const nestedColumns = []
      if (displayOptions?.includes(1)) {
        nestedColumns.push({
          title: t("Program contents"),
          align: "center",
          key: "program_contents",
          onCell: (data) => onSharedColumn(data, facilityId),
          render: (_, record) => {
            const { data, cellClass, isWeekday } =
              getProgramContentAndActualCostData(record, facilityId)
            return {
              props: {
                style: {
                  background: isWeekday ? "#fae0e3" : "inherit",
                },
              },
              children: isWeekday ? (
                <div className={"weekend-holiday"}>{t("Holiday")}</div>
              ) : (
                <>
                  <div className={"program-text-wrapper " + cellClass}>
                    {data?.data
                      ?.filter((val) => val?.facility_id === facilityId)
                      ?.map((program, index) => {
                        return (
                          <Fragment key={index}>
                            {data && data?.isHoliday ? (
                              <div
                                key={record.day}
                                className={"facility-holiday"}
                              >
                                {t("Holiday")}
                              </div>
                            ) : (
                              <div key={record.day} className={"program-text"}>
                                <div className={"triangle"} />
                                {program?.program_text}
                              </div>
                            )}
                          </Fragment>
                        )
                      })}
                  </div>
                </>
              ),
            }
          },
        })
      }
      if (displayOptions?.includes(10)) {
        nestedColumns.push({
          title: t("Actual cost "),
          align: "center",
          key: "Actual cost",
          onCell: (data) => onSharedColumn(data, facilityId),
          render: (_, record) => {
            const { data, cellClass, isWeekday } =
              getProgramContentAndActualCostData(record, 0)
            return {
              props: {
                style: {
                  background: isWeekday ? "#fae0e3" : "inherit",
                },
              },
              children: isWeekday ? (
                <div className={"weekend-holiday"}>{t("Holiday")}</div>
              ) : (
                <>
                  <div className={"program-text-wrapper " + cellClass}>
                    {data?.data
                      ?.filter((val) => val?.facility_id === facilityId)
                      ?.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            {data && data?.isHoliday ? (
                              <div
                                key={record.day}
                                className={"facility-holiday"}
                              >
                                {t("Holiday")}
                              </div>
                            ) : (
                              <div className={"text__content"}>
                                {item?.actual_cost_using_facility?.map(
                                  (actualItem, idx) => (
                                    <div className={"text"} key={idx}>
                                      <div>
                                        {
                                          actualItem?.actual_cost
                                            ?.actual_cost_name
                                        }
                                      </div>
                                      {":"}
                                      <div>
                                        {actualItem?.actual_cost?.price} {"円"}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </Fragment>
                        )
                      })}
                  </div>
                </>
              ),
            }
          },
        })
      }
      return nestedColumns
    }
    for (const facilityId of selectedFacilities) {
      columns.push({
        title: facilities.find((facility) => facility.id === facilityId)
          ?.facility_name_short,
        dataIndex: facilityId,
        key: facilityId,
        children: [
          {
            title: t("application"),
            align: "center",
            key: "application",
            render: (_, record) => {
              const isHoliday = record?.facilityHolidays?.includes(facilityId)
              const cellClass = getHolidayClassName(isHoliday ? "土" : "")
              const isWeekday =
                cellClass === "saturday" || cellClass === "sunday"
              return {
                props: {
                  style: {
                    background: isWeekday ? "#fae0e3" : "inherit",
                  },
                },
                children: isWeekday ? (
                  <div className={"weekend-holiday"}>{t("Holiday")}</div>
                ) : (
                  ""
                ),
              }
            },
            onCell: (data) => {
              const isHoliday = data?.facilityHolidays?.includes(facilityId)
              const cellClass = getHolidayClassName(isHoliday ? "土" : "")
              const isWeekday =
                cellClass === "saturday" || cellClass === "sunday"
              if (isWeekday) {
                return {
                  colSpan: findNumberOfColSpan(),
                }
              }
              return {}
            },
          },
          ...DynamicFacilityColumn(facilityId),
        ],
      })
    }
    return columns
  }

  const generaItemsColumns = (options) => {
    const columns = []

    for (const option of options) {
      if (option === 8) {
        columns.push({
          title: t("Desired pick up time"),
          align: "center",
          key: "Desired pick up time",
          id: option,
          render: (data, record) => {
            const { isWeekday } = isHoliday(data, record)
            return {
              props: {
                style: {
                  background: isWeekday ? "#fae0e3" : "inherit",
                },
              },
              children: isWeekday ? (
                <div className={"weekend-holiday"}>{t("Holiday")}</div>
              ) : (
                <div className={"text__content"}>{":"}</div>
              ),
            }
          },
        })
      }
      if (option === 9) {
        columns.push({
          title: t("Desired drop off time"),
          align: "center",
          key: "Desired drop off time",
          id: option,

          render: (data, record) => {
            const { isWeekday } = isHoliday(data, record)
            return {
              props: {
                style: {
                  background: isWeekday ? "#fae0e3" : "inherit",
                },
              },
              children: isWeekday ? (
                <div className={"weekend-holiday"}>{t("Holiday")}</div>
              ) : (
                <div className={"text__content"}>{":"}</div>
              ),
            }
          },
        })
      }

      if (option === 11) {
        columns.push({
          title: t("Remarks"),
          align: "center",
          key: "remarks",
          id: option,
          render: (data, record) => {
            const { isWeekday } = isHoliday(data, record)
            return {
              props: {
                style: {
                  background: isWeekday ? "#fae0e3" : "inherit",
                },
              },
              children: isWeekday ? (
                <div className={"weekend-holiday"}>{t("Holiday")}</div>
              ) : (
                <div className={"text__content"}></div>
              ),
            }
          },
        })
      }
    }
    return columns?.sort((a, b) => a?.id - b?.id)
  }
  const generaPickupColumns = (options) => {
    const DynamicColumns = () => {
      const nestedColumns = []
      for (const option of options) {
        if (option === 3) {
          nestedColumns.push({
            title: t("Pick up "),
            align: "center",
            key: "pickup",
            id: option,
            render: (data) => {
              const isHoliday =
                data?.facilityHolidays?.length > 0 &&
                data?.facilityHolidays?.length == facilityIds?.length
              const cellClass = getHolidayClassName(isHoliday ? "土" : "")
              const isWeekday =
                cellClass === "saturday" || cellClass === "sunday"
              return {
                props: {
                  style: {
                    background: isWeekday ? "#fae0e3" : "inherit",
                  },
                },
                children: isWeekday ? (
                  <div className={"weekend-holiday"}>{t("Holiday")}</div>
                ) : options?.includes(5) ? (
                  <div className={"text__content"}>
                    {options?.includes(6) && (
                      <>
                        {t("School")} {"/"}
                      </>
                    )}
                    {options?.includes(7) && (
                      <>
                        {t("Kindergarten")} {"/"}
                      </>
                    )}
                    {t("Home")}
                    {"/"} {t("Other")}
                  </div>
                ) : (
                  ""
                ),
              }
            },
            onCell: (data) => {
              const { isWeekday } = isHoliday(data, data)
              const noColSpan =
                displayOptions?.includes(3) && displayOptions?.includes(4)
              if (isWeekday) {
                return {
                  colSpan: noColSpan ? 2 : 1,
                }
              }
              return {}
            },
          })
        }
        if (option === 4) {
          nestedColumns.push({
            title: t("Drop off"),
            align: "center",
            key: "display_item",
            id: option,
            render: (data) => {
              const isHoliday =
                data?.facilityHolidays?.length > 0 &&
                data?.facilityHolidays?.length == facilityIds?.length
              const cellClass = getHolidayClassName(isHoliday ? "土" : "")
              const isWeekday =
                cellClass === "saturday" || cellClass === "sunday"
              return {
                props: {
                  style: {
                    background: isWeekday ? "#fae0e3" : "inherit",
                  },
                },
                children: isWeekday ? (
                  <div className={"weekend-holiday"}>{t("Holiday")}</div>
                ) : options?.includes(5) ? (
                  <div className={"text__content"}>
                    {options?.includes(6) && (
                      <>
                        {t("School")} {"/"}
                      </>
                    )}
                    {options?.includes(7) && (
                      <>
                        {t("Kindergarten")} {"/"}
                      </>
                    )}
                    {t("Home")}
                    {"/"} {t("Other")}
                  </div>
                ) : (
                  ""
                ),
              }
            },
            onCell: (data) => {
              const { isWeekday } = isHoliday(data, data)
              const noColSpan =
                displayOptions?.includes(3) && displayOptions?.includes(4)
              if (isWeekday) {
                return {
                  colSpan: noColSpan ? 0 : 1,
                }
              }
              return {}
            },
          })
        }
      }
      return nestedColumns?.sort((a, b) => a?.id - b?.id)
    }
    if (options?.includes(3) || options?.includes(4) || options?.includes(5)) {
      const columns = [
        {
          title: displayOptions?.includes(5)
            ? t("Fill in ◯ in the desired pick-up location")
            : t("Pick up "),

          align: "center",
          key: "facility_name",
          className: "pick__drop",
          onCell: (data) => onSharedColumn(data, 0),
          // need to find facility id for this
          children: [...DynamicColumns()],
        },
      ]
      return columns
    }
    return []
  }

  const columns = [
    {
      title: (
        <div>
          {getPaddedMonth(date.getMonth())}
          {t("Month")}
        </div>
      ),
      align: "center",
      key: "day",
      dataIndex: "day",
      colSpan: 2,
    },
    {
      title: <span className={"col-title"}>{t("Day of week")}</span>,
      dataIndex: "weekday",
      key: "weekday",
      align: "center",
      colSpan: 0,
      render: (weekday, record) => {
        const isHoliday =
          record?.facilityHolidays?.length > 0 &&
          record?.facilityHolidays?.length == facilityIds?.length
        return (
          <div
            className={getHolidayClassName(
              isHoliday ? (["土", "日"].includes(weekday) ? weekday : "土") : ""
            )}
          >
            {weekday}
          </div>
        )
      },
    },
    ...generateFacilityColumns(facilityIds),
    ...generaPickupColumns(displayOptions),
    ...generaItemsColumns(displayOptions),
  ]

  const tableData = () => {
    const arrData = []
    const arrResponse = []
    const respData = response?.data || {}
    for (const [date, data] of Object.entries(respData?.program || {})) {
      arrResponse.push({ date: date, data: data })
    }

    const holidays: any = {}
    // date: [facility ids]

    for (const data of respData?.holidays || []) {
      let dates = []
      try {
        dates = JSON.parse(data?.dates)
      } catch (e) {
        dates = []
      }
      dates.forEach((d) => {
        if (holidays?.[d] == undefined) {
          holidays[d] = [data?.facility_id]
        } else {
          holidays[d] = [...holidays[d], data?.facility_id]
        }
      })
    }
    const dayCount = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate()
    for (let i = 1; i <= dayCount; i++) {
      const thisDate = new Date(date.getFullYear(), date.getMonth(), i, 0, 0, 0)
      const formattedDate = getDateStringFromDate(thisDate)
      const dateFacilities =
        arrResponse.find((elem) => elem.date?.split("T")?.[0] == formattedDate)
          ?.data || []

      if (thisDate.getDate() !== i) {
        break // Prevent reloop through month condition
      }

      arrData.push({
        arrResponse,
        day: thisDate.getDate(),
        weekday: WEEKDAYS[thisDate.getDay()],
        facilities: dateFacilities?.map((elem) => elem.program_text),
        facilityData: dateFacilities,
        facilityHolidays: holidays?.[formattedDate] || [],
      })
    }
    return arrData
  }
  const listRef = useRef<HTMLDivElement>(null)
  const setSearchParams = (facilities: number[], displayOptions: number[]) => {
    setFacilityIds(facilities)
    setDisplayOptions(displayOptions)
    scrollToSelectedElement(listRef)
  }

  const footerFacilities = Array.from(facilityIds).map(
    (facilityItem, index) => {
      const foundFacility = facilities.find(
        (facility) => facility.id === facilityItem
      )
      return (
        <React.Fragment key={index}>
          {foundFacility &&
            `${foundFacility?.facility_name_short} FAX: ${
              foundFacility.fax_no || "-"
            }`}{" "}
          {`${index !== programFacilities.size - 1 ? ", " : ""}`}
        </React.Fragment>
      )
    }
  )
  return (
    <ContentWrapper>
      <OperationOptions
        isLoading={isLoading || isFetching}
        programFacilities={Array.from(programFacilities)}
        defaultFacilities={facilityIds}
        defaultDisplayOptions={displayOptions}
        setSearchParams={setSearchParams}
      />
      <PageCard
        title={t("{{year}}年{{month}}月's program", {
          year: date?.getFullYear(),
          month: getPaddedMonth(date?.getMonth()),
        })}
      >
        <PrintButtonsSection>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Back")}
            onClick={() => router.back()}
          />
          <OwnerButton
            text={t("Bulk print")}
            icon={"print"}
            onClick={window.print}
          />
        </PrintButtonsSection>
        <TableContentSection ref={listRef}>
          <TopInfo date={date} />
          <TableContainerStyled>
            <OwnerTable
              columns={columns}
              dataSource={tableData()}
              scroll={{ x: "max-content" }}
              bordered
              loading={isFetching || isLoading}
            />
          </TableContainerStyled>
        </TableContentSection>
        {(displayOptions?.includes(2) || displayOptions?.includes(12)) && (
          <>
            <div className={"info"} style={{ width: "100%" }}>
              {displayOptions?.includes(2) && (
                <div className={"info-text"}>
                  {t("montlyApplicationInfoText")}
                </div>
              )}
              {displayOptions?.includes(12) && (
                <div className={"info-informations"}>
                  <Typography.Title level={5}>
                    {t("Informations")}
                  </Typography.Title>
                  {Array.from(facilityIds).map((facilityItem) => {
                    const facilityName =
                      facilities.find(
                        (facility: any) => facility.id === facilityItem
                      )?.facility_name_short || ""

                    return (
                      facilityName && (
                        <div key={facilityItem} className={"facility-box"}>
                          <div>{facilityName}</div>
                          <div>
                            {
                              response?.data?.information?.find(
                                (f) => f.facility_id === facilityItem
                              )?.note
                            }
                          </div>
                        </div>
                      )
                    )
                  })}
                </div>
              )}
              <div className={"info-fax"}>{footerFacilities}</div>
            </div>
          </>
        )}
      </PageCard>
    </ContentWrapper>
  )
}
