import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { t } from "i18next"
import React, { useMemo } from "react"
import styled from "styled-components"

interface IChildMonitoringContentProps {
  data?: any
  handleFieldChange?: (a: any) => void
  align?: string
  checklist?: string
  loading?: boolean
}

const TableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: #d2d1d1;
    border: 1px solid #444748 !important;
  }
  td {
    border: 1px solid #444748 !important;
    border-top: none !important;
    p {
      margin: 0.65em;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0px 10px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    background: #d2d1d1;
  }
  .serial-number-background {
    background: #f3f3f3;
  }
`
const CheckWrapper = styled.div`
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid #191919;
  border-radius: 100px;
  margin-left: 30px;
`
const SerialNumberWrapper = styled.div`
  width: 26px;
  height: 26px;
  background: #f3f3f3;
  border: 1px solid #191919;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 400;
`

const SelfAssessmentResultDetailsParentTable: React.FC<
  IChildMonitoringContentProps
> = ({ data, loading }) => {
  const possibleAnswers = data?.[0]?.questionare?.possible_answers
  const possibleAnswersCols = possibleAnswers?.map((answer) => ({
    title: answer,
    key: answer,
    align: "center",
    dataIndex: answer,
    render: (_, val) => {
      if (val?.answer?.trim() === answer) {
        return (
          <p>
            <CheckWrapper />
          </p>
        )
      }
    },
  }))

  const COLUMN_DEFINATION: ColumnsType<IChildMonitoringContentProps> = useMemo(
    () => [
      {
        title: "",
        dataIndex: "address",
        width: 80,
        colSpan: 2,
        className: "serial-number-background",
        render: (_, __, index) => {
          if (index === 0) {
            return (
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                }}
              >
                {"環 境 ・ 体 制 整 備"}
              </p>
            )
          }
          if (index === 4) {
            return (
              <p
                style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
              >
                {"適切な支援の提供"}
              </p>
            )
          }
          if (index === 9) {
            return (
              <p
                style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
              >
                {"保護者への説明等"}
              </p>
            )
          }
          if (index === 19) {
            return (
              <p
                style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
              >
                &nbsp;{"非常時等の対応"}
              </p>
            )
          }
          if (index === 21) {
            return (
              <p
                style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
              >
                {"満足度"}
              </p>
            )
          }
          return <p>{""}</p>
        },
        onCell: (_, index) => {
          if (index === 0) {
            return { rowSpan: 4 }
          }
          if (index === 4) {
            return { rowSpan: 5 }
          }
          if (index === 9) {
            return { rowSpan: 10 }
          }
          if (index === 19) {
            return { rowSpan: 2 }
          }
          if (index === 21) {
            return { rowSpan: 2 }
          }
          return { rowSpan: 0 }
        },
      },
      {
        title: t(""),
        dataIndex: "address",
        align: "center",
        colSpan: 0,
        className: "serial-number-background",
        render: (_, __, index) => {
          return <SerialNumberWrapper>{index + 1}</SerialNumberWrapper>
        },
        width: 50,
      },
      {
        title: t("Check items"),
        dataIndex: "questionaire",
        align: "center",
        render: (val) => {
          return <p>{val?.question || "Question"}</p>
        },
        width: 680,
      },

      // Dynamic Answers
      ...(possibleAnswersCols?.length > 0 ? possibleAnswersCols : ""),
      {
        title: t("Opinion"),
        dataIndex: "opinion",
        align: "center",
        render: (opinion) => {
          return <p>{opinion}</p>
        },
        width: 550,
      },
    ],
    [data]
  )

  return (
    <TableWrapper>
      <Table
        columns={COLUMN_DEFINATION}
        dataSource={data}
        pagination={false}
        scroll={{ x: "1000px" }}
        loading={loading}
      />
    </TableWrapper>
  )
}

export { SelfAssessmentResultDetailsParentTable }
