import { Button } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
`

const StyledButton = styled(Button)`
  background-color: #ffffff;
  border-color: #d2d1d1;
  border-radius: 5px;
`

export const AttendanceTypeButtons = ({ onChange }) => {
  const { t } = useTranslation()
  return (
    <Wrapper className={"staff-attendance-buttons"}>
      <StyledButton
        onClick={() => {
          onChange(1)
        }}
      >
        {t("Present")}
      </StyledButton>
      <StyledButton
        onClick={() => {
          onChange(2)
        }}
      >
        {t("Absence ")}
      </StyledButton>
    </Wrapper>
  )
}
