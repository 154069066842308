import React, { useMemo } from "react"
import { Popconfirm, Space } from "antd"
import {
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS,
  INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES,
  SelectInput,
  Table,
  TextField,
  isApril2024ChangeApplicable,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { useTranslation } from "react-i18next"
import moment from "moment"

interface IProps {
  dataSource: any[]
  setDataSource: (values: any) => void
  startDate?: any
}
export const ChildSupportDraftPlanContent: React.FC<IProps> = ({
  dataSource,
  setDataSource,
  startDate,
}) => {
  const { t } = useTranslation()
  const handleItemsChange = (id, field, text) => {
    const _data = [...dataSource]
    _data.forEach((each, index) => {
      if (each?.id === id) {
        _data[index][field] = text
      }
    })
    setDataSource(_data)
  }

  const formattedDataSource = useMemo(() => {
    const rows = []
    if (Array.isArray(dataSource)) {
      dataSource.forEach((data, i) => {
        const isLastData = i + 1 == dataSource.length
        const rowSpan = data?.achievement_goals?.length || 1
        for (let i = 0; i < rowSpan; i++) {
          const rowData = {
            is_last_item: isLastData,
            rowSpan: i == 0 ? rowSpan : 0,
            id: data?.id,
            allow_specific_goal_delete: rowSpan != 1,
            is_final_specific_goal_delete: i == rowSpan - 1,
            achievement_goals: data?.achievement_goals?.[i],
            achievement_time: data?.achievement_time?.[i],
            points_to_note: data?.points_to_note?.[i],
            priority: data?.priority?.[i],
            providing_organization: data?.providing_organization?.[i],
            support_and_considerations: data?.support_and_considerations?.[i],
            support_content: data?.support_content?.[i],
            support_guidelines: data?.support_guidelines?.[i],
            title: data?.title,
          }
          rows.push(rowData)
        }
      })
    }
    return rows
  }, [dataSource])

  const handleSpecificNestItemChange = (rowId, goalId, value, key) => {
    const _data = [...dataSource]
    const _matchedRowIndex = _data.findIndex((each) => each.id === rowId)
    _data[_matchedRowIndex]?.[key]?.forEach((each, index) => {
      if (each?.id === goalId) {
        _data[_matchedRowIndex][key][index].title = value
      }
    })
    setDataSource(_data)
  }

  const handleDataSourceAdd = () => {
    setDataSource([
      ...dataSource,
      {
        id: new Date().getTime(),
        title: "",
        achievement_goals: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        support_and_considerations: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        support_guidelines: [
          {
            id: new Date().getTime(),
            title: [
              {
                value: 0,
                title: "",
              },
            ],
          },
        ],
        achievement_time: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        providing_organization: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        points_to_note: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        priority: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
      },
    ])
  }
  const handleDataSourceDelete = (id: number) => {
    setDataSource(dataSource?.filter((val) => val?.id !== id))
  }
  const handleAddSpecificGoalToDataSrouce = (id: number) => {
    const data = dataSource?.map((val) =>
      val?.id === id
        ? {
            ...val,
            achievement_goals: [
              ...val?.achievement_goals,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            support_and_considerations: [
              ...val?.support_and_considerations,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            support_guidelines: [
              ...val?.support_guidelines,
              {
                id: new Date().getTime(),
                title: [
                  {
                    value: 0,
                    title: "",
                  },
                ],
              },
            ],
            achievement_time: [
              ...val?.achievement_time,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            providing_organization: [
              ...val?.providing_organization,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            points_to_note: [
              ...val?.points_to_note,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            priority: [
              ...val?.priority,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
          }
        : val
    )

    setDataSource(data)
  }
  const handleDeleteSpecificGoalFromDataSrouce = (
    id: number,
    goalId: number
  ) => {
    const data = dataSource?.map((val) =>
      val?.id === id
        ? {
            ...val,
            achievement_goals: val?.achievement_goals?.filter(
              (v) => v?.id !== goalId
            ),
            support_and_considerations: val?.support_and_considerations?.filter(
              (v) => v?.id !== goalId
            ),
            achievement_time: val?.achievement_time?.filter(
              (v) => v?.id !== goalId
            ),
            providing_organization: val?.providing_organization?.filter(
              (v) => v?.id !== goalId
            ),
            points_to_note: val?.points_to_note?.filter(
              (v) => v?.id !== goalId
            ),
            priority: val?.priority?.filter((v) => v?.id !== goalId),
          }
        : val
    )
    setDataSource(data)
  }

  const handleSupportGuidelineAddition = (rowId, guidelineId) => {
    const data = dataSource?.map((val) => {
      if (val?.id == rowId) {
        const guidelineIndex = val?.support_guidelines?.findIndex(
          (goal) => goal?.id == guidelineId
        )
        if (guidelineIndex != -1) {
          if (val?.support_guidelines?.length > guidelineIndex) {
            val.support_guidelines[guidelineIndex].title.push({
              value: 0,
              title: "",
            })
          } else {
            val.support_guidelines = [
              ...val.support_guidelines,
              {
                id: new Date().getTime(),
                title: [
                  {
                    value: 0,
                    title: "",
                  },
                ],
              },
            ]
          }
        }
      }
      return val
    })

    setDataSource(data)
  }

  const handleSupportGuidelineDelete = (rowId, guidelineId) => {
    const data = dataSource?.map((val) => {
      if (val?.id == rowId) {
        const guidelineIndex = val?.support_guidelines?.findIndex(
          (goal) => goal?.id == guidelineId
        )
        if (guidelineIndex != -1) {
          if (val?.support_guidelines?.length > guidelineIndex) {
            val.support_guidelines[guidelineIndex].title.pop()
          }
        }
      }
      return val
    })

    setDataSource(data)
  }

  const handleSupportGuidelineChange = (
    rowId,
    guidelineId,
    supportIndex,
    key,
    value
  ) => {
    const data = dataSource?.map((val) => {
      if (val?.id == rowId) {
        const goalIndex = val?.support_guidelines?.findIndex(
          (goal) => goal?.id == guidelineId
        )
        if (goalIndex != -1) {
          if (val.support_guidelines[goalIndex]?.title?.length > supportIndex)
            val.support_guidelines[goalIndex].title[supportIndex][key] = value
        }
      }
      return val
    })

    setDataSource(data)
  }

  const condition = isApril2024ChangeApplicable(moment(startDate))

  const columns = [
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Items (personal needs, etc.)")}
        </span>
      ),
      key: "1",
      render: (row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
            rowSpan: row?.rowSpan,
          },
          children: (
            <Space direction={"vertical"}>
              <TextField
                placeholder={t("例：行動")}
                bgcolor={"transparent"}
                name={"title"}
                value={row?.title}
                maxLength={100}
                onChange={(e) => {
                  if (e?.target?.value?.length > 100) return
                  handleItemsChange(row?.id, "title", e?.target?.value)
                }}
              />
              {dataSource.length == 1 ? (
                <OwnerButton
                  text={t("add")}
                  typeOf={"outlined"}
                  icon={"add"}
                  onClick={handleDataSourceAdd}
                />
              ) : !row?.is_last_item ? (
                <Popconfirm
                  title={t(
                    "Are you sure you want to delete the entered items, specific goals, support details, and notes?"
                  )}
                  onConfirm={() => handleDataSourceDelete(row?.id)}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                >
                  <OwnerButton
                    text={t("delete")}
                    typeOf={"outlined"}
                    icon={"close"}
                  />
                </Popconfirm>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <OwnerButton
                    text={t("add")}
                    typeOf={"outlined"}
                    icon={"add"}
                    onClick={handleDataSourceAdd}
                  />
                  <Popconfirm
                    title={t(
                      "Are you sure you want to delete the entered items, specific goals, support details, and notes?"
                    )}
                    onConfirm={() => handleDataSourceDelete(row?.id)}
                    okText={"OK"}
                    cancelText={t("Cancel")}
                  >
                    <OwnerButton
                      text={t("delete")}
                      typeOf={"outlined"}
                      icon={"close"}
                    />
                  </Popconfirm>
                </div>
              )}
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Specific goals")}
        </span>
      ),
      key: "2",
      render: (row, entireRow) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
              minWidth: "250px",
            },
          },
          children: (
            <Space direction={"vertical"}>
              <TextField
                placeholder={t("例：文字を丁寧に書く")}
                bgcolor={"transparent"}
                type={"textarea"}
                rows={2}
                value={row?.achievement_goals?.title}
                name={"achievement_goals"}
                onChange={(e) => {
                  handleSpecificNestItemChange(
                    entireRow.id,
                    row?.achievement_goals?.id,
                    e.target.value,
                    "achievement_goals"
                  )
                }}
              />
              {!row?.allow_specific_goal_delete ? (
                <OwnerButton
                  text={t("add")}
                  typeOf={"outlined"}
                  icon={"add"}
                  onClick={() => handleAddSpecificGoalToDataSrouce(row?.id)}
                />
              ) : !row?.is_final_specific_goal_delete ? (
                <Popconfirm
                  title={t(
                    "Is this correct that the specific goals, support details, and notes will be deleted?"
                  )}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                  onConfirm={() =>
                    handleDeleteSpecificGoalFromDataSrouce(
                      row?.id,
                      row?.achievement_goals?.id
                    )
                  }
                >
                  <OwnerButton
                    text={t("delete")}
                    typeOf={"outlined"}
                    icon={"close"}
                  />
                </Popconfirm>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <OwnerButton
                    text={t("add")}
                    typeOf={"outlined"}
                    icon={"add"}
                    onClick={() => handleAddSpecificGoalToDataSrouce(row?.id)}
                  />
                  <Popconfirm
                    title={t(
                      "Is this correct that the specific goals, support details, and notes will be deleted?"
                    )}
                    okText={"OK"}
                    cancelText={t("Cancel")}
                    onConfirm={() =>
                      handleDeleteSpecificGoalFromDataSrouce(
                        row?.id,
                        row?.achievement_goals?.id
                      )
                    }
                  >
                    <OwnerButton
                      text={t("delete")}
                      typeOf={"outlined"}
                      icon={"close"}
                    />
                  </Popconfirm>
                </div>
              )}
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "pre",
          }}
        >
          {condition
            ? t(
                "Support content\n(content, key points in providing support, relevance to the 5 areas (*), etc.)"
              )
            : t("Contents of support/Points to consider")}
        </span>
      ),
      key: "3",
      children: [
        {
          className: "no-display-head",
          render: (_, row) => {
            return {
              props: {
                style: {
                  verticalAlign: "top",
                },
                colSpan: condition ? 1 : 2,
              },
              children: (
                <Space direction={"vertical"} style={{ display: "flex" }}>
                  <TextField
                    placeholder={t(
                      "例：次の予定の時刻が近づくと、徐々に文字が雑になってしまうため、焦ることがないように声がけ、見守りを行います。"
                    )}
                    bgcolor={"transparent"}
                    type={"textarea"}
                    value={row?.support_and_considerations?.title}
                    onChange={(e) =>
                      handleSpecificNestItemChange(
                        row.id,
                        row?.support_and_considerations.id,
                        e.target.value,
                        "support_and_considerations"
                      )
                    }
                    rows={3}
                    name={"support_and_considerations"}
                    resizeable
                  />
                </Space>
              ),
            }
          },
        },
        {
          className: "no-display-head",
          onCell: () => {
            return {
              colSpan: condition ? 1 : 0,
            }
          },
          render: (_, row) => {
            return {
              props: {
                style: {
                  verticalAlign: "top",
                },
              },
              children: (
                <Space direction={"vertical"} style={{ display: "flex" }}>
                  {row?.support_guidelines?.title?.map((guide, index) => (
                    <>
                      <SelectInput
                        placeholder={"--"}
                        height={40}
                        value={guide?.value}
                        options={
                          INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS
                        }
                        onChange={(val) =>
                          handleSupportGuidelineChange(
                            row?.id,
                            row?.support_guidelines?.id,
                            index,
                            "value",
                            val
                          )
                        }
                      />
                      {guide.value ==
                        INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.OTHERS && (
                        <TextField
                          placeholder={t(
                            "Example: Understanding health status"
                          )}
                          bgcolor={"transparent"}
                          type={"textarea"}
                          value={guide?.title}
                          rows={3}
                          name={"support_guidelines"}
                          resizeable
                          onChange={(e) =>
                            handleSupportGuidelineChange(
                              row?.id,
                              row?.support_guidelines?.id,
                              index,
                              "title",
                              e?.target?.value
                            )
                          }
                        />
                      )}
                    </>
                  ))}
                  <OwnerButton
                    text={t("add")}
                    typeOf={"outlined"}
                    icon={"add"}
                    onClick={() =>
                      handleSupportGuidelineAddition(
                        row?.id,
                        row?.support_guidelines?.id
                      )
                    }
                  />
                  {row?.support_guidelines?.title?.length > 1 && (
                    <OwnerButton
                      text={t("delete")}
                      typeOf={"outlined"}
                      onClick={() =>
                        handleSupportGuidelineDelete(
                          row?.id,
                          row?.support_guidelines?.id
                        )
                      }
                      icon={"close"}
                    />
                  )}
                </Space>
              ),
            }
          },
        },
      ],
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Achievement time")}
        </span>
      ),
      key: "4",
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
          },
          children: (
            <Space direction={"vertical"} style={{ display: "flex" }}>
              <TextField
                placeholder={t("例：6ヶ月")}
                bgcolor={"transparent"}
                name={"achievement_time"}
                maxLength={100}
                value={row?.achievement_time?.title}
                onChange={(e) => {
                  if (e?.target?.value?.length > 100) return
                  handleSpecificNestItemChange(
                    row.id,
                    row?.achievement_time.id,
                    e.target.value,
                    "achievement_time"
                  )
                }}
              />
            </Space>
          ),
        }
      },
    },
  ]

  const columnsAfterMarch = [
    {
      title: (
        <span
          style={{
            whiteSpace: "pre",
          }}
        >
          {t("Personal Providing organization")}
        </span>
      ),
      key: "5",
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
          },
          children: (
            <Space direction={"vertical"} style={{ display: "flex" }}>
              <TextField
                placeholder={t("例：Staff in charge")}
                bgcolor={"transparent"}
                name={"providing_organization"}
                maxLength={100}
                value={row?.providing_organization?.title}
                onChange={(e) => {
                  if (e?.target?.value?.length > 100) return
                  handleSpecificNestItemChange(
                    row.id,
                    row?.providing_organization.id,
                    e.target.value,
                    "providing_organization"
                  )
                }}
              />
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "pre",
          }}
        >
          {t("Points to note (including the role of the person)")}
        </span>
      ),
      key: "6",
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
          },
          children: (
            <Space direction={"vertical"} style={{ display: "flex" }}>
              <TextField
                placeholder={t("例：Call out as necessary")}
                bgcolor={"transparent"}
                name={"points_to_note"}
                maxLength={100}
                value={row?.points_to_note?.title}
                onChange={(e) => {
                  handleSpecificNestItemChange(
                    row.id,
                    row?.points_to_note.id,
                    e.target.value,
                    "points_to_note"
                  )
                }}
              />
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Priority")}
        </span>
      ),
      key: "7",
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
          },
          children: (
            <Space direction={"vertical"} style={{ display: "flex" }}>
              <SelectInput
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "5",
                    value: "5",
                  },
                  {
                    label: "6",
                    value: "6",
                  },
                  {
                    label: "7",
                    value: "7",
                  },
                  {
                    label: "8",
                    value: "8",
                  },
                  {
                    label: "9",
                    value: "9",
                  },
                  {
                    label: "10",
                    value: "10",
                  },
                ]}
                height={52}
                bgcolor={"transparent"}
                name={"priority"}
                maxLength={100}
                value={row?.priority?.title}
                onChange={(value) => {
                  handleSpecificNestItemChange(
                    row.id,
                    row?.priority.id,
                    value,
                    "priority"
                  )
                }}
              />
            </Space>
          ),
        }
      },
    },
  ]

  const tableColumns = condition ? [...columns, ...columnsAfterMarch] : columns

  return (
    <div className={"table_content"}>
      <Table
        columns={tableColumns}
        dataSource={formattedDataSource}
        hover={false}
        bordered
      />
    </div>
  )
}
