import { theme } from "@project/shared"

import styled from "styled-components"

export const ContentWrapper = styled.section`
  .info {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    h5 {
      margin-top: 12px;
    }
    .facility-box {
      display: grid;
      background-color: black;
      grid-gap: 1px;
      grid-template-columns: 10rem auto;
      border: 1px solid black;
      word-break: break-all !important;
      div {
        padding: 0.6rem;
        background-color: white;
      }
      border-bottom: black 0.1px solid;
    }
    .info-fax {
      margin-top: 24px;
      font-weight: bold;
      text-align: center;
    }
  }
  @media print {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    @page {
      size: landscape !important;
      margin: 5mm !important;
    }
    body {
      margin: 5mm 1mm !important;
    }

    padding: 0 10px !important;
    [class*="SearchContent"],
    [class*="PrintButtons"],
    [class*="Desc"] {
      display: none;
    }
    [class*="PageCard"] {
      border: none;
    }
    .ant-card {
      .ant-card-head {
        padding: 10px !important;
        margin: 0 !important;
        min-height: fit-content !important;
      }
      .ant-card-body {
        margin: 0 !important;
      }
      .top__info {
        margin-top: 12px !important;
        background: transparent !important;
        .cell {
          background: transparent !important;
          font-size: 9px !important;
          padding: 5px !important;
          white-space: nowrap !important;
          word-break: keep-all !important;
        }
        .cell:not(:last-child) {
          border-right: 1px solid ${theme.black} !important;
        }
      }
      .top__head {
        margin-bottom: 8px !important;
        background: transparent !important;
        .col {
          min-height: 100px !important;
          background: transparent !important;
          .cell {
            font-size: 10px !important;
          }
        }
      }
    }
    .info {
      /* page-break-before: always !important; */
    }
  }
`
export const PrintButtonsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  button {
    padding: 8px;
    min-width: 90px;
  }
`

export const SearchContentSection = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .flex {
      display: flex;
    }
    .align-center {
      align-items: center;
    }
    .gap-6 {
      gap: 6px;
    }
    .icon {
      margin-top: 8px;
    }
    .operation-section {
      display: flex;

      .display-items-checkbox {
        display: flex;
        flex-wrap: wrap;
        .display-option-labels {
          white-space: break-spaces;
          display: flex;
        }
      }

      @media (max-width: ${theme.breakpoints?.md}) {
        flex-direction: column;
        align-items: start;
      }
      .label {
        min-width: 150px;
        max-width: 150px;
        @media (max-width: ${theme.breakpoints?.md}) {
          min-width: 100%;
          max-width: 100%;
        }
        svg {
          color: ${theme.gray2};
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .checkboxs_container {
        .check_uncheck_all {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          gap: 10px;
          button {
            height: 30px;
            display: flex;
            gap: 4px;
            align-items: center;
            outline: 0;
            &:hover,
            &:focus {
              border-color: ${theme.gray2};
            }
            .span {
              @media (max-width: ${theme.breakpoints.md}) {
                font-size: 14px;
              }
            }
          }
        }
        .checkboxs {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
      }
    }
  }
`
export const TableContentSection = styled.div`
  min-width: 100% !important;
  .col-title {
    white-space: nowrap;
  }
  @media screen and (max-width: 1150px) {
    .pagination-buttons-container {
      margin-top: 16px;
    }
  }
  @media print {
    overflow-x: visible !important;
  }
`

export const TableContainerStyled = styled.div`
  .program-text-wrapper.saturday,
  .program-text-wrapper.sunday {
    border-right: unset !important;
  }
  table {
    border-collapse: collapse;
    thead > tr > th {
      border-right: 1px solid ${theme.black} !important;
      border-bottom: 1px solid ${theme.black} !important;
      border-top: 1px solid ${theme.black} !important;
      font-weight: bold;
    }
    thead > tr:first-child {
      th {
        font-weight: normal !important;
      }
    }
    thead > tr > th.pick__drop {
      border-bottom: 0 !important;
    }
    thead > tr:not(:last-child) > th {
      border-bottom: 1px solid ${theme.black} !important;
    }

    thead > tr:first-child th:first-child {
      border-left: 1px solid ${theme.black} !important;
    }
    tbody > tr > td {
      border-right: 1px solid ${theme.black} !important;
      border-bottom: 1px solid ${theme.black} !important;
      padding: 0px;
      .saturday {
        color: #2121f7;
      }
      .sunday {
        color: #ea3323;
      }
      .sunday,
      .saturday,
      .weekend-holiday {
        width: 100%;
        min-height: 26px;
        background-color: #fae0e3;
      }
      .text__content {
        padding: 0 3px;
        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.2rem;
        }
      }
      .program-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .facility-holiday {
          color: black;
        }

        .program-text {
          display: flex;
          padding-left: 5px;
          gap: 4px;
          align-items: center;
          .triangle {
            color: #cc4d25;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-left: 5px solid #cc4d25;
            border-bottom: 5px solid transparent;
          }
        }
      }
    }
    tbody > tr > td:first-child {
      border-left: 1px solid ${theme.black} !important;
    }
    tbody:before {
      content: "@";
      display: block;
      line-height: 5px;
      text-indent: -99999px;
    }
  }
  .main-pagination-wrapper {
    display: none !important;
  }

  @media screen and (max-width: 1150px) {
    margin-top: 12px;
  }
  @media print {
    table {
      margin-top: 0px !important;
      overflow: visible !important;
      page-break-inside: auto !important;
      thead {
        display: table-header-group !important;
      }
      tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
      }
      thead > tr > th {
        outline: 0 !important;
        padding: 5px !important;
        background: transparent !important;
        word-break: break-all !important;
        font-size: 10px !important;
        max-width: 60px !important;
      }
      tbody {
        display: table-footer-group !important;
        tr {
          td {
            background-color: transparent !important;
            .program-text-wrapper {
              .program-text,
              .facility-holiday {
                font-size: 9px !important;
              }
              .facility-holiday {
                color: red !important;
              }
            }
            .sunday,
            .saturday,
            .weekend-holiday {
              background: transparent !important;
              color: red !important;
              font-size: 9px !important;
              padding-top: 5px;
            }
            .text__content {
              font-size: 9px !important;
              padding: 0 !important;
              .text {
                & > div {
                  font-size: 10px !important;
                }
              }
            }
          }
        }
      }
      tbody:before {
        content: "@";
        display: block;
        line-height: 5px;
        text-indent: -99999px;
      }
    }
  }
`
