import React, { useContext, useEffect, useMemo, useState } from "react"
import { Skeleton, Typography } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { RightOutlined } from "@ant-design/icons"
import {
  PageCard,
  removeBlankAttributes,
  SelectInput,
  setItemToLocalstorage,
} from "@project/shared"
import { Wrapper } from "./ActualCostBurdenTableContent.styles"
import { OwnerButton } from "../../atoms"
import { AuthContext, getDateString } from "../../../utils"
import { CalenderTable, DatePicker } from "../../molecules"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { useRouter } from "next/router"
import { fetchBusinessDayRegistration } from "../../../services"
import { useQuery } from "react-query"
import { ActualCostBurdenMonthlyRecord } from "../../../services/actualCostBurdenRecord"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { ActualCostBurdenPermission } from "../../../utils/PermissionKeys"

const LOCAL_STORAGE_ACTUAL_COST_BURDEN_KEY = "actual_cost_burden_filters"

export const ActualCostBurdenTableContent = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    facilities: permissibleFacilities,
    isOwner,
    permissions,
  } = useContext(AuthContext)
  const facilities = useMemo(
    () =>
      permissibleFacilities?.map((fac) => {
        return {
          label: fac?.facility_name_short,
          value: fac?.id,
        }
      }),
    [permissibleFacilities]
  )

  const [updateParams] = useUpdateSearchParams()

  const localStorageFilters = localStorage.getItem(
    LOCAL_STORAGE_ACTUAL_COST_BURDEN_KEY
  )

  let localStorageFiltersObject: any = {}

  try {
    localStorageFiltersObject = localStorageFilters
      ? JSON.parse(localStorageFilters)
      : {}
  } catch (e) {
    localStorageFiltersObject = {}
  }
  const [tempSearchParams, setTempSearchParams] = useState<any>({
    facility:
      localStorageFiltersObject?.facility || facilities[0]?.value || null,
    year: moment().format("YYYY"),
    month: moment().month() + 1,
  })
  const [searchParams, setSearchParams] = useState({
    facility:
      localStorageFiltersObject?.facility || facilities[0]?.value || null,
    year: moment().format("YYYY"),
    month: moment().month() + 1,
  })
  const handleEditClick = (date) => {
    const selectedDate = date?.date ? moment(date.date) : moment(date)
    router.push(
      `/actual-cost-burden?year=${selectedDate.year()}&month=${
        selectedDate.month() + 1
      }&day=${selectedDate.date()}&facilityId=${searchParams.facility}`
    )
  }

  useEffect(() => {
    const hasCurrentFacilityInList = facilities?.find(
      (fac) => fac?.value == searchParams?.facility
    )
    if (!hasCurrentFacilityInList) {
      setSearchParams({ ...searchParams, facility: facilities?.[0]?.value })
      setTempSearchParams({
        ...tempSearchParams,
        facility: facilities?.[0]?.value,
      })
    }
  }, [facilities])

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        ActualCostBurdenPermission,
        "write",
        searchParams?.facility
      ),
    [isOwner, permissions, searchParams?.facility]
  )

  const { data: businessDayHolidays } = useQuery(
    [
      "businessDayData",
      searchParams.facility,
      searchParams.month,
      searchParams.year,
    ],
    async () => {
      return fetchBusinessDayRegistration({
        facilityId: +tempSearchParams.facility,
        currentYear: +tempSearchParams.year,
        currentMonth: +tempSearchParams.month,
      })
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      select: ({ data }) => {
        const businessHolidays = data?.business_days
          ?.map((data) => {
            if (data?.holiday == "1") {
              return data.day
            }
          })
          .filter(Boolean)
        return businessHolidays
      },
    }
  )

  const { isLoading, data: costBurdenData } = useQuery(
    [
      "actualCostTableData",
      searchParams.facility,
      searchParams.month,
      searchParams.year,
    ],
    () =>
      ActualCostBurdenMonthlyRecord(
        +searchParams.year,
        +searchParams.month,
        +searchParams.facility,
        true
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      select: ({ data }) => {
        const actualCostMap = {}
        data?.map((actualData) => {
          if (actualData.date in actualCostMap) {
            actualCostMap[actualData.date].push(actualData)
          } else {
            actualCostMap[actualData.date] = [actualData]
          }
        })
        return actualCostMap
      },
    }
  )
  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_ACTUAL_COST_BURDEN_KEY,
      JSON.stringify(searchParams)
    )
  }, [searchParams])
  return (
    <Wrapper>
      <div className={"options__wrapper"}>
        <PageCard title={t("Operation options")}>
          <div className={"options__content"}>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Facility")}</Typography.Text>
              </div>
              <div className={"options--selector facility-sel-input-container"}>
                <SelectInput
                  placeholder={t("All")}
                  height={40}
                  className={"dynamic-dropdown-width min-w-150"}
                  width={"auto"}
                  dropdownMatchSelectWidth={false}
                  options={facilities}
                  value={tempSearchParams?.facility}
                  onChange={(val) =>
                    setTempSearchParams((prev) => ({
                      ...prev,
                      facility: val,
                    }))
                  }
                />
              </div>
            </div>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Year month")}</Typography.Text>
              </div>
              <div className={"year__month--selector"}>
                <DatePicker
                  date={
                    moment({
                      year: +tempSearchParams?.year,
                      month: +tempSearchParams?.month - 1,
                    })?.isValid()
                      ? moment({
                          year: +tempSearchParams?.year,
                          month: +tempSearchParams?.month - 1,
                        })
                      : null
                  }
                  style={{
                    height: "40px",
                  }}
                  picker={"month"}
                  format={"YYYY年MM月"}
                  showArrow={true}
                  disabledDate={(current) => {
                    return (
                      (current && current < moment("2021/01/01")) ||
                      (current && current > moment().add(6, "year"))
                    )
                  }}
                  onDateChange={(value) => {
                    setTempSearchParams((prev) => ({
                      ...prev,
                      month: moment(value).format("M"),
                      year: moment(value).format("YYYY"),
                    }))
                  }}
                />
                <OwnerButton
                  text={t("Update display")}
                  typeOf={"secondary"}
                  onClick={() => {
                    const queries = removeBlankAttributes(tempSearchParams)
                    setItemToLocalstorage("actual-cost-burden-table", {
                      ...queries,
                    })
                    setSearchParams(tempSearchParams)
                    updateParams(queries, "/actual-cost-burden-table")
                  }}
                />
              </div>
            </div>
          </div>
        </PageCard>
      </div>
      <PageCard
        title={t(
          "{{facilityName}} {{date}}’s actual cost burden record table",
          {
            facilityName: facilities?.find(
              (val) => val?.value == searchParams?.facility
            )?.label,
            date: getDateString(searchParams?.year, searchParams?.month),
          }
        )}
      >
        {isLoading ? (
          <Skeleton></Skeleton>
        ) : (
          <CalenderTable
            facility={searchParams.facility}
            year={parseInt(tempSearchParams?.year, 10)}
            month={tempSearchParams?.month}
            handleEditOnClick={handleEditClick}
            businessDayHolidays={businessDayHolidays}
            costBurdenData={costBurdenData}
            handleDateChange={(state: any) => {
              setTempSearchParams({
                ...state,
              })
              setSearchParams({
                ...state,
              })
            }}
            activeStartDate={
              new Date(parseInt(searchParams?.year), searchParams?.month - 1, 1)
            }
            hasEditAccess={hasEditAccess}
          />
        )}
      </PageCard>
    </Wrapper>
  )
}
