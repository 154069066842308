import React from "react"
import { Wrapper } from "./DisabledChildOutpatientBenefitsFacilitySumTable.styles"
import { Table, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { getDOB } from "../../../../utils"
import moment from "moment"
import { moneyFormat } from "../../../../utils/moneyFormat"
import { formatJaEra } from "../../../molecules/MonthlyUsageList/common/mapper"
import wareki from "wareki"

const CellValueContent = ({ data, valueOfCell, decimal }: any) => {
  const { t } = useTranslation()
  return (
    <div className={"cell__value_container"}>
      {data?.map((item, index) => {
        return (
          <div className={"cell__value"} key={index}>
            {valueOfCell === "ServiceType" ? (
              <>
                {item["ServiceType"] === 1 && t("After school day service")}
                {item["ServiceType"] === 2 && t("Child development support")}
              </>
            ) : item[valueOfCell] ? (
              moneyFormat(
                item[valueOfCell],
                typeof decimal != undefined ? decimal : undefined
              )
            ) : (
              ""
            )}
          </div>
        )
      })}
    </div>
  )
}
interface DisabledChildOutpatientBenefitsFacilitySumTableProps {
  data?: any
  selectedPrintSettings?: any[]
}
export const DisabledChildOutpatientBenefitsFacilitySumTable: React.FC<
  DisabledChildOutpatientBenefitsFacilitySumTableProps
> = ({ data, selectedPrintSettings }) => {
  const { t } = useTranslation()
  const topTableColumn: any = [
    {
      key: 1,
      title: "",
      align: "center",
      render: (data) => <div className={"first_cell"}>{data?.title || ""}</div>,
      colSpan: 1,
      width: 10,
      onCell: (data) => {
        if (data?.key === 1) {
          return {
            rowSpan: 5,
          }
        }
        if (data?.key !== 1) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
    },
    {
      key: 2,
      title: "",
      render: (data, __, index) => {
        return index === 0 ? (
          t("Designated Office Number")
        ) : (
          <span style={{ whiteSpace: "pre" }}>{data?.title}</span>
        )
      },
      align: "center",
    },
    {
      key: 3,
      title: "",
      align: "center",
      render: (data, __, index) => {
        return {
          props: {
            style: {
              padding: 0,
            },
          },
          children:
            index === 0 ? (
              <div className={"ofc__number_content"}>
                {data?.value
                  ? data?.value?.split("")?.map((v, index) => (
                      <div className={"ofc__number"} key={index}>
                        {v}
                      </div>
                    ))
                  : Array(10)?.map((_, index) => (
                      <div className={"ofc__number"} key={index} />
                    ))}
              </div>
            ) : (
              <Typography.Text> {data?.value}</Typography.Text>
            ),
        }
      },
    },
  ]
  const mainTableDataSource = (tableData: any) => {
    const benefit_handicapped_child_data = tableData?.Data || []
    const initial = {
      ClaimedAmountOfBenefit: "",
      Subsidy: "",
      Number: "",
      ServiceType: "",
      Amount: "",
      Unit: "",
      UserBurden: "",
    }
    const initial_handicapped_benefits = Array.from(
      { length: 3 - benefit_handicapped_child_data?.length },
      (_, index) => ({
        key: index + 1,
        ...initial,
      })
    )
    const disability_child_benefits = []
    const initial_disability_benefits = Array.from(
      { length: 3 - disability_child_benefits?.length },
      (_, index) => ({
        key: index + 1,
        ...initial,
      })
    )

    const total = benefit_handicapped_child_data?.reduce(
      (acc, curr) => ({
        Number: acc?.Number + curr?.Number,
        Unit: acc?.Unit + curr?.Unit,
        Amount: acc?.Amount + curr?.Amount,
        ClaimedAmountOfBenefit:
          acc?.ClaimedAmountOfBenefit + curr?.ClaimedAmountOfBenefit,
        UserBurden: acc?.UserBurden + curr?.UserBurden,
        Subsidy: acc?.Subsidy + curr?.Subsidy,
      }),
      {
        Number: 0,
        Unit: 0,
        Amount: 0,
        ClaimedAmountOfBenefit: 0,
        UserBurden: 0,
        Subsidy: 0,
      }
    )

    return [
      {
        title: t("Handicapped child commuter benefit"),
        benefit_children_data: [
          ...benefit_handicapped_child_data,
          ...initial_handicapped_benefits,
        ],
      },
      {
        title: t("Child with a disability admission benefit"),
        benefit_children_data: [
          ...disability_child_benefits,
          ...initial_disability_benefits,
        ],
      },
      {
        title: t("Sub total"),
        ...total,
      },
      {
        title: t(
          "Benefits such as food expenses for children with disabilities in specific facilities"
        ),
        number: null,
        units: null,
        total_cost: null,
        claimed_amount_of_benefits: null,
        user_burden_amount: null,
      },
      {
        title: t("Total"),
        ...total,
      },
    ]
  }

  const mainTableColumns: any = [
    {
      title: t("Division"),
      key: 1,
      colSpan: 2,
      width: 20,
      align: "center",
      render: (data) => {
        return (
          <div style={{ padding: "5px", fontSize: "12px" }}>{data?.title}</div>
        )
      },
      onCell: (_, index) => {
        if (index > 1) {
          return { colSpan: 2 }
        }
        return {}
      },
    },
    {
      title: "",
      key: 2,
      colSpan: 0,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"ServiceType"}
          />
        ) : (
          <div>{data?.ServiceType ? moneyFormat(data?.ServiceType) : ""}</div>
        )
      },
      onCell: (_, index) => {
        if (index > 1) {
          return { colSpan: 0 }
        }
        return {}
      },
    },
    {
      title: t("Number"),
      key: 3,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"Number"}
          />
        ) : (
          <div>{data?.Number ? moneyFormat(data?.Number) : ""}</div>
        )
      },
    },
    {
      title: t("Units"),
      key: 4,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"Unit"}
          />
        ) : (
          <div>{data?.Unit ? moneyFormat(data?.Unit) : ""}</div>
        )
      },
    },
    {
      title: t("Total cost"),
      key: 5,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"Amount"}
            decimal={1}
          />
        ) : (
          <div>{data?.Amount ? moneyFormat(data?.Amount, 1) : ""}</div>
        )
      },
    },
    {
      title: t("Claimed amount of benefits"),
      key: 6,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"ClaimedAmountOfBenefit"}
          />
        ) : (
          <div>
            {data?.ClaimedAmountOfBenefit
              ? moneyFormat(data?.ClaimedAmountOfBenefit)
              : ""}
          </div>
        )
      },
    },
    {
      title: t("User burden amount"),
      key: 7,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"UserBurden"}
          />
        ) : (
          <div>{data?.UserBurden ? moneyFormat(data?.UserBurden) : ""}</div>
        )
      },
    },
    {
      title: t("municipality subsidized portion"),
      key: 8,
      align: "center",
      render: (data, __, index) => {
        return index <= 1 ? (
          <CellValueContent
            data={data?.benefit_children_data}
            valueOfCell={"Subsidy"}
          />
        ) : (
          <div>{data?.Subsidy ? moneyFormat(data?.Subsidy) : ""}</div>
        )
      },
    },
  ]

  const getInvoiceAmount = (idx) => {
    const arrayToModify = [...data?.InvoiceAmount]
    const index = arrayToModify?.findIndex((element) => element !== "0")
    const first = [...arrayToModify]?.slice(0, index)?.map(() => "")
    const last = [...arrayToModify]?.slice(index, data?.InvoiceAmount?.length)

    return index !== -1
      ? [...first, ...last][idx]
      : data?.InvoiceAmount?.map(() => "")[idx]
  }

  const yearMonth = `${data?.Year}-${data?.Month}`
  const era =
    data?.Year && data?.Month
      ? formatJaEra(
          wareki(yearMonth, {
            unit: true,
          })
        )
      : ""

  const getAddressValue = (postCode, address) => {
    if (!postCode && !address) return ""
    if (!postCode && address) return address
    return (
      <>
        {`〒${postCode}`}
        <br />
        {address}
      </>
    )
  }

  const renderInvoiceAmountCells = () => {
    // render minimum of 9 cells or cell = amount length + 1(for money symbol)
    const numberOfCellsToRender = Math.max(9, data?.InvoiceAmount?.length + 1)
    const amountStartsAt = numberOfCellsToRender - data?.InvoiceAmount?.length
    const cellArray = Array.from({ length: numberOfCellsToRender }).fill("")
    const scale = [t("Yen"), t("Thousands"), t("百万")]
    return cellArray.map((_, idx) => {
      const place = numberOfCellsToRender - idx - 1
      const denotation = !(place % 3) ? scale?.[place / 3] : ""
      return (
        <div
          className={`cell ${denotation ? "" : "bottom"}`}
          key={`invoice_cell_${idx}`}
        >
          {denotation ? <div className={"txt"}>{denotation}</div> : null}
          {idx == amountStartsAt - 1 && t("￥")}
          {idx >= amountStartsAt && (
            <div className={"bottom"}>
              {getInvoiceAmount(idx - amountStartsAt)}
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <Wrapper>
      {selectedPrintSettings?.includes("1") && (
        <span id={"form__num"}>{t("(Form 1)")}</span>
      )}
      <div className={"invoice-title"}>
        {t(
          "Request Form for Day Care Benefit, Residential Payment, etc. for Disabled Children"
        )}
      </div>
      <div className={"facility__sum_top_content"}>
        <div className={"facility__sum_top_content--left"}>
          <div>
            <Typography.Text>{t("(Invoice to)")}</Typography.Text>
            <br />
            <Typography.Text>
              {t("{{provisionCity}} Mayor", {
                provisionCity: data?.CityName,
              })}
            </Typography.Text>
          </div>
          <div>{t("Invoicing as follow")}</div>
        </div>
        <div className={"facility__sum_top_content--right"}>
          <div className={"right__top"}>
            <div className={"right__top--date"}>
              {getDOB(moment().format("YYYY-MM-DD"), true)}
            </div>
          </div>
          <Table
            columns={topTableColumn}
            dataSource={[
              {
                key: 1,
                title: t("Invoice"),
                value: data?.office_number || "",
              },
              {
                title: t("Address\n(location)"),
                value: getAddressValue(data?.PostCode, data?.Address),
              },
              {
                title: t("Phone number"),
                value: data?.TelNo || "",
              },
              {
                title: t("Official name"),
                value: data?.FacilityName || "",
              },
              {
                title: t("Name / Title"),
                value: (
                  <>
                    {data?.FacilityInvoiceTitle || ""} {"　"}
                    {data?.FacilityInvoiceName || ""}
                  </>
                ),
              },
            ]}
            pagination={false}
            showHeader={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>

      <div className={"other__content-container"}>
        <div className={"facility__sum_era_content"}>
          <div className={"era__content--cell  large-cell"}>
            {t("{{JapaneseEra}}", {
              JapaneseEra: String(era)?.substring(0, 2),
            })}
          </div>
          <div className={"era__content--cell"}>
            {String(era)?.substring(2, 3)}
          </div>
          <div className={"era__content--cell"}>
            {" "}
            {String(era)?.substring(3, 4)}
          </div>
          <div className={"era__content--cell"}>
            {t(`${String(era)?.substring(4, 5)}`)}
          </div>
          <div className={"era__content--cell"}>
            {data?.Month?.substring(0, 1)}
          </div>
          <div className={"era__content--cell"}>
            {data?.Month?.substring(1, 2)}
          </div>
          <div className={"era__content--cell large-cell"}>{t("Month's")}</div>
        </div>
        <div className={"facility__sum_invoice_amount_content"}>
          <div className={"cell vertically-center"}>{t("Invoice amount")}</div>
          {renderInvoiceAmountCells()}
        </div>
        <div id={"facility__sum_main_table"}>
          <Table
            columns={mainTableColumns}
            dataSource={mainTableDataSource(data)}
            pagination={false}
          />
        </div>
      </div>
    </Wrapper>
  )
}
