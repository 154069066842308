import { theme } from "@project/shared"
import styled from "styled-components"

export const Container = styled.div`
  .middle-back-btn {
    margin: 30px 0;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 20px 0;
    }
  }
`

export const OperationWrapper = styled.div`
  .operation-rounded-container {
    margin-top: 30px;

    /* Operation section styling starts here */

    .upper-search-operation {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (max-width: 426px) {
        display: grid;
      }

      .input-group {
        @media screen and (max-width: 426px) {
          display: grid;
        }
        .label.caret-right {
          background: url("/assets/icons/caret-right.svg") no-repeat -3px center /
            12px 12px;
          padding-left: 14px;
          font-size: 16px;
          font-weight: 500;
          min-width: 180px;
        }
        .input-field {
          flex: 1;
          .date-picker {
            padding: 9px 14px;
            max-width: 177px;
            min-width: 140px;
            width: 100%;
            input {
              line-height: 100%;
            }
          }
          .facility-select {
            width: 100%;
            max-width: 200px;
            min-width: 150px;
            .ant-select-selector {
              padding: 4.2px 14px;
              height: auto !important;
            }
          }
        }
      }

      .form-button-group {
        @media screen and (max-width: 426px) {
          gap: 0;

          button:last-child {
            margin-left: 20px;
          }
        }
        button.search-button {
          padding: 9px 16px;
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        gap: 15px;
      }
    }

    .footer-btns-group {
      flex-wrap: wrap;
      margin-top: 50px;
      button {
        padding: 12px 30px;
      }
      @media screen and (max-width: 426px) {
        margin-top: 30px;
        gap: 0;

        button:last-child {
          margin-top: 10px;
        }
      }
    }
    /* Operation section styling ends here */

    /* Table section styling starts here */
    .table-head {
      justify-content: space-between;
      margin-bottom: 9px;

      .table-title {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        color: ${theme.black};
      }
      .month-controller {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;

        @media screen and (max-width: 426px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
        button {
          padding: 8px 16px;
          height: auto;
          width: auto;
          border-radius: 10px;
          span {
            line-height: 100%;
          }
          &.active {
            background: ${theme.blue6};
            border-color: ${theme.blue6};
          }
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        .month-controller {
          align-self: flex-end;
        }
      }
    }
    .table-container {
      margin-top: 24px;
      .total_count {
        margin: 0 !important;
      }
      .main-pagination-wrapper {
        margin: 0 !important;
      }

      .ant-table-wrapper {
        margin-bottom: 20px;
        .ant-table .ant-table-container .ant-table-content table {
          .ant-table-thead tr th {
            background: ${theme.bgColor2};
            border-bottom: 1px solid ${theme.gray2};
            &:first-child {
              border-top-left-radius: 12px;
            }

            &:last-child {
              border-top-right-radius: 12px;
            }

            &:before {
              background-color: ${theme.gray2};
            }
          }
          .ant-table-tbody tr td {
            border-right: 1px solid ${theme.gray2};
            border-bottom: 1px solid ${theme.gray2};
            &:first-child {
              border-left: 1px solid ${theme.gray2};
            }
            p {
              margin: 0;
            }
            .detail-button-link {
              margin-inline: auto !important;
            }
            .edit-checkbox {
              margin-inline: auto !important;
            }
          }
        }
      }
      .bottom-pagination-wrapper {
        display: none;
      }

      @media (min-width: 1200px) {
        .info-wrapper {
          margin-top: 0 !important;
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        .total_count {
          margin-top: 10px !important;
        }
      }
    }
    /* Table section styling ends here */

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }
  }
`
