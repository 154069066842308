import { Button } from "@project/shared"
import React, { useEffect, useState } from "react"
import { USER_REGISTRATION_PERMISSIONS } from "../../../constants/facilityPermissionList"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

export interface FacilityPermissionType {
  label: string
  permission: number
  id: string
}

interface IUserRegistrationFacilityPermissionSectionProps {
  permissions: FacilityPermissionType[]
  title: string
  tabId: string
  handleSectionPermissionChange: (
    tabId: string,
    sectionId: string,
    permission: number
  ) => void
  handlePermissionChange: (
    tabId: string,
    sectionId: string,
    permissionId: string,
    permission: number
  ) => void
}

const Wrapper = styled.div`
  margin: 30px 0px;
  @media (max-width: 1024px) {
    margin: 10px;
  }
  h2 {
    margin: 0px;
    padding: 0px;
    margin-right: 20px;
  }
  .section-heading-wrapper {
    border: 1px solid #d2d1d1;
    display: flex;
    background: #f3f3f3;
    padding: 10px;
    padding-left: 0px;
    align-items: center;
    h2 {
      border-left: 3px solid #0782c8;
      padding-left: 10px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      h2 {
        margin-bottom: 10px;
      }
    }
  }

  .section-button-wrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    p {
      color: #e61a1a;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 6px;
      margin-left: 9px;
    }

    @media (max-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
      gap: 5px;
    }

    @media (max-width: 588px) {
      width: 100%;
      display: grid !important;
      gap: 10px;
      align-items: center;
      grid-template-columns: repeat(auto-fill, minmax(94px, 94px));

      & > div {
        width: 94px;
        button {
          margin-right: 0px;
        }
      }

      p {
        margin-left: 0;
        margin-bottom: 0;
        line-height: 120%;
        min-width: max-content;
      }
    }
  }

  button {
    width: 94px;
    height: 40px;
    border-radius: 5px;
    padding: 0px;
    background: white;
    color: #191919;
    border-color: #d2d1d1;
    margin-right: 10px;
    span {
      font-size: 14px;
    }
    &.active {
      background: #cde6f4;
      border: 1px solid #0782c8;
    }
  }
`

const EachSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1485px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1330px) {
    grid-template-columns: 1fr;
  }
  .each-permission-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #d2d1d1;
    .section-title-wrapper {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      background: #f3f3f3;
      min-height: 60px;
      max-height: 60px;
      min-width: 200px;
      max-width: 200px;
      outline: 1px solid #d2d1d1;
      p {
        font-size: 14px;
        margin: auto 0px;
        padding-left: 10px;
      }
    }
    .section-permission-buttons-wrapper {
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 984px) {
      flex-direction: column;
      align-items: flex-start;
      min-width: 100%;
      .section-title-wrapper {
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        min-height: 40px;
        max-width: 100%;
        min-width: 100%;
      }
      .section-permission-buttons-wrapper {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        padding: 10px;
        margin: 0px;
        width: 100%;
        outline: 1px solid #d2d1d1;
      }
    }

    @media (max-width: 588px) {
      .section-permission-buttons-wrapper {
        display: grid !important;
        align-items: center;
        grid-template-columns: repeat(auto-fill, minmax(94px, 94px));
        gap: 10px;
      }
    }
  }
`

const UserRegistrationFacilityPermissionSection: React.FC<
  IUserRegistrationFacilityPermissionSectionProps
> = ({
  title,
  permissions = [],
  handlePermissionChange,
  handleSectionPermissionChange,
  tabId,
}) => {
  const { t } = useTranslation()
  const [allPermissionSetTo, setAllPermissionTo] = useState<undefined | number>(
    undefined
  )

  useEffect(() => {
    const buttons = {
      browseAndEdit: 0,
      browseOnly: 0,
      dontDisplay: 0,
    }
    permissions.forEach((each) => {
      if (each?.permission === USER_REGISTRATION_PERMISSIONS.dontDisplay) {
        buttons.dontDisplay++
      } else if (
        each?.permission === USER_REGISTRATION_PERMISSIONS.browseOnly
      ) {
        buttons.browseOnly++
      } else if (
        each?.permission === USER_REGISTRATION_PERMISSIONS.browseAndEdit
      ) {
        buttons.browseAndEdit++
      }
    })
    if (buttons.browseAndEdit === permissions.length) {
      setAllPermissionTo(USER_REGISTRATION_PERMISSIONS.browseAndEdit)
    } else if (buttons.browseOnly === permissions.length) {
      setAllPermissionTo(USER_REGISTRATION_PERMISSIONS.browseOnly)
    } else if (buttons.dontDisplay === permissions.length) {
      setAllPermissionTo(USER_REGISTRATION_PERMISSIONS.dontDisplay)
    } else {
      setAllPermissionTo(undefined)
    }
  }, [permissions])

  return (
    <Wrapper>
      <div className={"section-heading-wrapper"}>
        <div>
          <h2>{t(title)}</h2>
        </div>
        <div className={"section-button-wrapper"}>
          <Button
            className={
              allPermissionSetTo === USER_REGISTRATION_PERMISSIONS.browseAndEdit
                ? "active"
                : ""
            }
            onClick={() =>
              handleSectionPermissionChange(
                tabId,
                title,
                USER_REGISTRATION_PERMISSIONS.browseAndEdit
              )
            }
          >
            {t("Browse/Edit")}
          </Button>
          <Button
            className={
              allPermissionSetTo === USER_REGISTRATION_PERMISSIONS.browseOnly
                ? "active"
                : ""
            }
            onClick={() =>
              handleSectionPermissionChange(
                tabId,
                title,
                USER_REGISTRATION_PERMISSIONS.browseOnly
              )
            }
          >
            {t("Browse Only")}
          </Button>
          <Button
            className={
              allPermissionSetTo === USER_REGISTRATION_PERMISSIONS.dontDisplay
                ? "active"
                : ""
            }
            onClick={() =>
              handleSectionPermissionChange(
                tabId,
                title,
                USER_REGISTRATION_PERMISSIONS.dontDisplay
              )
            }
          >
            {t("Don't Display")}
          </Button>
          <p>{`* ${t("Bulk setting")}`}</p>
        </div>
      </div>
      <EachSectionWrapper>
        {permissions?.map(({ label, permission, id }) => (
          <div key={id} className={"each-permission-wrapper"}>
            <div className={"section-title-wrapper"}>
              <p>{t(label)}</p>
            </div>
            <div className={"section-permission-buttons-wrapper"}>
              <Button
                className={
                  permission === USER_REGISTRATION_PERMISSIONS.browseAndEdit
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handlePermissionChange(
                    tabId,
                    title,
                    id,
                    USER_REGISTRATION_PERMISSIONS.browseAndEdit
                  )
                }
              >
                {t("Browse/Edit")}
              </Button>
              <Button
                className={
                  permission === USER_REGISTRATION_PERMISSIONS.browseOnly
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handlePermissionChange(
                    tabId,
                    title,
                    id,
                    USER_REGISTRATION_PERMISSIONS.browseOnly
                  )
                }
              >
                {t("Browse Only")}
              </Button>
              <Button
                className={
                  permission === USER_REGISTRATION_PERMISSIONS.dontDisplay
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handlePermissionChange(
                    tabId,
                    title,
                    id,
                    USER_REGISTRATION_PERMISSIONS.dontDisplay
                  )
                }
              >
                {t("Don't Display")}
              </Button>
            </div>
          </div>
        ))}
      </EachSectionWrapper>
    </Wrapper>
  )
}

export { UserRegistrationFacilityPermissionSection }
