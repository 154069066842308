/**
 * NOTE:
 * - R6 April [established:新設]
 */

import { t } from "i18next"
import { AdditionType } from "../../../../../constants/enums/additioinType"
import { Field } from "../fields"
import { Moment } from "moment"
import { Version, getCurrentVersion } from "@project/shared"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getAbusePreventionNotImplementedSubtraction(date: Moment) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return null
    default:
      return r6April
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

/**
 * @en TODO: translate
 * @ja 虐待防止措置未実施減算
 */
const r6April: Field = {
  label: "虐待防止措置未実施減算",
  name: AdditionType.ABUSE_PREVENTION_NOT_IMPLEMENTED_SUBTRACTION,
  fields: [
    {
      label: t("No change"),
      value: 0,
    },
    {
      label: t("No"),
      value: 1,
    },
    {
      label: t("Yes"),
      value: 2,
    },
  ],
}

/**
 * --------------------------------
 * @version BASELINE
 *
 * NONE
 * --------------------------------
 */
