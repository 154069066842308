import React from "react"

// styled components
import { Container } from "./styles"

// components
import { FormBlock, ProgramProgressRecInfoBlock } from "./components"

// types
import {
  FormInitialValues,
  IndividualDayRecProps,
  AddOrEditProgressRecordFormPageProps,
} from "./types"

// utils
import { ProgressRecFormSchema, recTableFinalDataMapper } from "./utils"
import { useFormik } from "formik"
import { notification } from "antd"
import { useTranslation } from "react-i18next"

export const EditOrAddProgressRecordFormPage: React.FC<
  AddOrEditProgressRecordFormPageProps
> = ({
  // Shared props
  formik: passedFormik,
  pageType,
  rawChildOptions,
  facilityOptions,
  handleMonthChange,
  handleFacilityChange,
  isChildListLoading,
  currentSearchQueries,
  programCategoryOptions,
  isProgramCategoryListLoading,
  handleCurrentSearchQueriesChange,

  // Add props
  addingProgressRecord,

  // Edit-page_form props
  isRecFormDataLoading,

  // Edit-page_table props
  programOptions,
  rawStaffOptions,
  currentChildName,
  isStaffListLoading,
  initialRecTableData,
  isRecTableDataLoading,
  isRecTableDataUpdating,
  currentProgramCategoryName,
  handleProgressRecDataSubmit,
  handleProgressRecDelete,
  isRecDeleting = false,
  showDeleteButton = false,
}) => {
  const { t } = useTranslation()
  /** This function takes form data from above formik
   * as well as the table data from the table below
   * and sends it to the submit handler.
   */
  const recTableDataUpdateFn = (values: {
    individualDayRecords: {
      [key: string]: IndividualDayRecProps
    }
  }) => {
    const mappedData = recTableFinalDataMapper(values)
    if (
      !formik.values?.childId ||
      !formik.values?.facilityId ||
      !formik.values?.programCategoryId ||
      !formik.values?.yearMonth
    ) {
      notification.error({
        message: t("Please fill all the required fields"),
      })
    } else {
      handleProgressRecDataSubmit(formik.values, mappedData)
    }
  }

  const formik = passedFormik
    ? passedFormik
    : useFormik<FormInitialValues>({
        enableReinitialize: true,
        initialValues: currentSearchQueries,
        validationSchema: ProgressRecFormSchema,
        onSubmit: (values) => {
          handleCurrentSearchQueriesChange(values)
        },
      })

  return (
    <Container>
      <FormBlock
        formik={formik}
        pageType={pageType}
        rawChildOptions={rawChildOptions}
        facilityOptions={facilityOptions}
        recFormData={currentSearchQueries}
        isChildListLoading={isChildListLoading}
        addingProgressRecord={addingProgressRecord}
        programCategoryOptions={programCategoryOptions}
        isProgramCategoryListLoading={isProgramCategoryListLoading}
        handleCurrentSearchQueriesChange={handleCurrentSearchQueriesChange}
        // Edit-page props
        handleMonthChange={handleMonthChange}
        handleFacilityChange={handleFacilityChange}
        isRecFormDataLoading={isRecFormDataLoading}
      />

      {pageType === "EDIT" && (
        <ProgramProgressRecInfoBlock
          programOptions={programOptions}
          rawStaffOptions={rawStaffOptions}
          currentChildName={currentChildName}
          isStaffListLoading={isStaffListLoading}
          initialRecTableData={initialRecTableData}
          recTableDataUpdateFn={recTableDataUpdateFn}
          currentSearchQueries={currentSearchQueries}
          isRecTableDataLoading={isRecTableDataLoading}
          isRecTableDataUpdating={isRecTableDataUpdating}
          currentProgramCategoryName={currentProgramCategoryName}
          handleDelete={handleProgressRecDelete}
          showDelete={showDeleteButton}
          isDeleting={isRecDeleting}
        />
      )}
    </Container>
  )
}
