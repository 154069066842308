import React, { useCallback, useEffect, useState } from "react"

// packages
import { Spin } from "antd"
import { t } from "i18next"

// hooks
import useTable from "../hooks/useTable"

// styled component
import { AllTablesContainer } from "../styles"

// components
import { QuoteText } from "../../../atoms"
import { OwnerTable } from "../../../molecules"

// types
import { ITableDataType, TransferAccountInfoProps } from "../types"

// utils
import { getDataForCSV } from "../utils"

const useFormatOutputTable = ({
  tableData,
  transferAccount,
  transferAccountInfo,
  isAllBankFormatDataLoading,
}: {
  tableData: ITableDataType[][]
  transferAccount?: number
  isAllBankFormatDataLoading?: boolean
  transferAccountInfo: TransferAccountInfoProps
}) => {
  const [dataSources, setDataSources] = useState<ITableDataType[][]>([])
  const [selectedRows, setSelectedRows] = useState<ITableDataType[]>([])
  const handleRowSelection = (id: string) => {
    const newDataSrc = dataSources.map((row) =>
      row.map((r) => {
        if (r.id === id) {
          return {
            ...r,
            checked: !r.checked,
          }
        }
        return r
      })
    )
    setDataSources(newDataSrc)
  }

  const handleSelectAllRows = () => {
    const newDataSrc = dataSources.map((row) =>
      row.map((r) => {
        if (r.isDisabled) return r
        return {
          ...r,
          checked: true,
        }
      })
    )
    setDataSources(newDataSrc)
  }

  const handleUnselectAllRows = () => {
    const newDataSrc = dataSources.map((row) =>
      row.map((r) => {
        if (r.isDisabled) return r
        return {
          ...r,
          checked: false,
        }
      })
    )
    setDataSources(newDataSrc)
  }

  const { tableColumns } = useTable({
    handleRowSelection,
    transferAccount,
  })

  useEffect(() => {
    if (!dataSources) return
    const selectedRows = dataSources
      .map((row) => row.filter((r) => r.checked))
      .flat()
      .map((r) => r)
    setSelectedRows(selectedRows)
  }, [dataSources])

  useEffect(() => {
    // This is just a sample, you can filter data by your own logic later
    const filteredData = tableData?.map((row) => {
      return row.map((r) => {
        return {
          ...r,
          isDisabled: r.key === "193",
          account_holder_name: transferAccountInfo?.account_holder_name,
          account_type: transferAccountInfo?.account_type,
          branch_name: transferAccountInfo?.branch_name,
          bank_no: transferAccountInfo?.bank_no,
        }
      })
    })
    setDataSources(filteredData)
  }, [tableData, transferAccountInfo])

  const Tables = useCallback(() => {
    return (
      <Spin spinning={isAllBankFormatDataLoading}>
        <AllTablesContainer>
          {dataSources?.length ? (
            dataSources?.map((ds, i) => {
              return (
                <div className={"individual-table"} key={i}>
                  <QuoteText className={"quote-text"}>
                    <p>
                      {!ds[0].provision_city && !ds[0].city_code ? (
                        <p
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          {"-"}
                        </p>
                      ) : (
                        t("{{ProvisionCity}}（{{cityNumber}}）", {
                          ProvisionCity: ds[0].provision_city,
                          cityNumber: ds[0].city_code,
                        })
                      )}
                    </p>
                  </QuoteText>

                  <div className={"table-container"}>
                    <OwnerTable
                      columns={tableColumns}
                      dataSource={ds}
                      bordered
                      scroll={{ x: "max-content" }}
                      rowClassName={(record) => {
                        if (record.isDisabled) {
                          return "disabled-row"
                        }
                        if (record.checked) {
                          return "ant-table-row-selected"
                        }
                        return ""
                      }}
                    />
                  </div>
                </div>
              )
            })
          ) : (
            <div className={"individual-table"}>
              <QuoteText className={"quote-text"}>
                <p></p>
              </QuoteText>

              <div className={"table-container"}>
                <OwnerTable
                  columns={tableColumns}
                  dataSource={[]}
                  bordered
                  scroll={{ x: "max-content" }}
                />
              </div>
            </div>
          )}
        </AllTablesContainer>
      </Spin>
    )
  }, [dataSources, tableColumns])

  return {
    Tables,
    handleSelectAllRows,
    handleUnselectAllRows,
    selectedRowsCount: selectedRows.length,
    billedAmount: selectedRows.reduce(
      (acc, curr) => acc + curr.billed_amount,
      0
    ),
    isAnyRowSelected: dataSources?.some((row) => row.some((r) => r.checked)),
    dataOnlyForCSV: getDataForCSV(dataSources, transferAccount ? true : false),
  }
}

export { useFormatOutputTable }
