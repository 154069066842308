import { API } from "@project/shared"

export const saveActualCost = async (values: any) => {
  return API.post(`/actual-costs`, values)
}

export const fetchActualCost = (id: string | number) =>
  API.get(`/actual-costs/${id}`)

export const updateActualCost = ({
  values,
  id,
}: {
  values: any
  id: string | number
}) => API.put(`/actual-costs/${id}`, { ...values })

export const fetchAllActualCost = ({
  page,
  pageSize,
}: {
  page: number
  pageSize: any
}) => API.get(`/actual-costs?page=${page}&pageSize=${pageSize}`)

export const deleteActualCost = (id: string) =>
  API.delete(`/actual-costs/${id}`)

export const addActualCostUser = async (values: any) => {
  const id = values.burden_record_id
  return API.post(`actual-cost-burden-records/${id}/add-user`, values)
}

export const getOneActualCost = (id) => {
  return API.get(`/actual-cost-burden-records/${id}`)
}

export const deleteActualCostUser = (id) => {
  return API.delete(`actual-cost-burden-records/users/${id}`)
}

export const updateOneActualCostUser = (values) => {
  const id = values.id
  return API.put(`/actual-cost-burden-records/users/${id}`, values)
}
