import Link from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
//libraries
import { SyncOutlined } from "@ant-design/icons"
import { Spin, Typography } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import * as Yup from "yup"
//components
import {
  CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER,
  Checkbox,
  DeleteConfimationModal,
  Grid,
  PageCard,
  RadioButtons,
  Required,
  SelectInput,
  TIME_INTERVALS,
  TextField,
  isApril2024ChangeApplicable,
  theme,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../../molecules"

import { ALL_SERVICE_USE_OPTIONS } from "../../../constants/monitoring"
import { useDebounce } from "../../../hooks/useDebounce"
import { useIndividualSupportPlanningAPIs } from "../../../module/child/individual-support-planning/useIndividualSupportPlanningAPI"
import { getIndividualSupportPlanByChildId } from "../../../services/individualSupportPlanning"
import { getActiveContractFacilities } from "../../../services/recurringScheduleSetting"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { toastMsg } from "../../../utils/toastMsg"
import { ChildSupportDraftPlanContent } from "./ChildSupportDraftPlanContent"
import { WeeklySchedule } from "./WeeklySchedule"

//styles
import { getOneFacilityAdmins } from "../../../services/facility"
import {
  getDateTimeStringFromDate,
  scrollToFirstErrorField,
} from "../../../utils"
import {
  ChildSupportAttachTable,
  IAttachTable,
} from "./ChildSupportAttachTable"
import {
  BtnsContainer,
  MainContainer,
  MainWrapper,
  Wrapper,
} from "./supportPlanning.styles"

interface IProps {
  type?: "LIST" | "DRAFT"
  operation?: "edit" | "create" | "copy"
  cancelURl?: string
}
const initialDate = `${moment().format("YYYY-MM-DDTHH:mm:ssZ")}`
export const IndividualSupportPlanForm: React.FC<IProps> = ({
  operation,
  type,
  cancelURl,
}) => {
  const { t } = useTranslation()
  const { query, push } = useRouter()
  const [draftFlg, setDraftFlg] = useState(0)
  const [createCount, setCreateCount] = useState(null)
  const [debounceValue] = useDebounce(createCount, 500)
  const [childId, setChildId] = useState(+query?.child_id || null)
  const [isCountChanged, setIsCountChanged] = useState(false)
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { plan_id, id, create_count } = query as any
  const CHILD_ID_FROM_PARAMS = query?.child_id
  const BLANK_DRAFT_ITEMS = [
    {
      id: new Date().getTime(),
      title: "",
      achievement_goals: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
      support_and_considerations: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
      support_guidelines: [
        {
          id: new Date().getTime(),
          title: [
            {
              value: 0,
              title: "",
            },
          ],
        },
      ],
      achievement_time: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
      providing_organization: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
      points_to_note: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
      priority: [
        {
          id: new Date().getTime(),
          title: "",
        },
      ],
    },
  ]

  const {
    childInfoIsLoading,
    childInformation,
    addIndividualSupportPlanFunction,
    addingIndividualSupportPlan,
    updateIndividualSupportPlanFunction,
    updatingIndividualSupportPlan,
    isDeleting,
    deleteSupportPlan,
  } = useIndividualSupportPlanningAPIs({
    child_id: CHILD_ID_FROM_PARAMS || childId,
    type: type,
  })

  const initialValues: any = {
    child_id: CHILD_ID_FROM_PARAMS || null,
    recipient_number: null,
    facility_id: null,
    facility_admin: null,
    use_service_id: null,
    create_count: 1,
    agree_date: moment(initialDate),
    start_date: moment(initialDate),
    end_date: moment(moment(initialDate)).add(6, "M").subtract(1, "d"),
    staff_id: null,
    support_pran_draft_type: true,
    child_family_hope: "",
    support_policy: "",
    long_term_goal: "",
    short_term_goal: "",
    goto_meet_flag: "1",
    goto_meet_place: "",
    take_flag: "1",
    take_place: "",
    remarks: "",
    standard_hr_providing_support: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      child_id: Yup.number().required(t("Required")).nullable(),
      facility_id: Yup.number()
        .min(1, t("Required"))
        .required(t("Required"))
        .nullable(),
      use_service_id: Yup.number().required(t("Required")).nullable(),
      create_count: Yup.number().required(t("Required")),
      goto_meet_place: Yup.string().nullable().max(100, t("(Within 100 char)")),
      take_place: Yup.string().nullable().max(100, t("(Within 100 char)")),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const _weeklyScheduleDataSource = weeklyScheduleDataSource.map((each) => {
        const _each = { ...each }
        delete _each.id
        delete _each?.sIndex
        delete _each?.eIndex
        return _each
      })
      const _plan_items = dataSource
        ?.filter(
          (val, i) =>
            val?.title ||
            val?.achievement_time ||
            (val?.achievement_goals?.length > 0 &&
              val?.achievement_goals[i]?.title) ||
            val?.support_and_considerations
        )
        ?.map((each) => {
          const _each = { ...each }
          const achievement_goals = []
          _each.achievement_goals?.forEach((item) => {
            achievement_goals.push(item.title)
          })
          const support_and_considerations = []
          _each.support_and_considerations?.forEach((item) => {
            support_and_considerations.push(item.title)
          })
          const support_guidelines = []
          _each.support_guidelines?.forEach((item) => {
            support_guidelines.push(
              item?.title?.filter((guide) => guide?.value)
            )
          })
          const achievement_time = []
          _each.achievement_time?.forEach((item) => {
            achievement_time.push(item.title)
          })
          const providing_organization = []
          _each.providing_organization?.forEach((item) => {
            providing_organization.push(item.title)
          })
          const points_to_note = []
          _each.points_to_note?.forEach((item) => {
            points_to_note.push(item.title)
          })
          const priority = []
          _each.priority?.forEach((item) => {
            priority.push(item.title)
          })
          delete _each.id
          return {
            ..._each,
            achievement_goals,
            support_and_considerations,
            achievement_time,
            providing_organization,
            points_to_note,
            priority,
            support_guidelines,
          }
        })
      const plan_table: any = {}
      if (
        attachTableData.notice ||
        attachTableData.reason ||
        Object.keys(attachTableData.offer_time).length > 0 ||
        Object.keys(attachTableData.before_offer_time).length > 0 ||
        Object.keys(attachTableData.after_provision_time).length > 0
      ) {
        // only if some data is input include it as payload
        plan_table.notices = attachTableData.notice
        plan_table.reason = attachTableData.reason
        plan_table.offer_time = []
        for (const keys in attachTableData.offer_time) {
          const weekNo = CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER.indexOf(keys)
          if (
            weekNo != -1 &&
            (attachTableData.offer_time[keys]?.start_hour ||
              attachTableData.offer_time[keys]?.start_minute ||
              attachTableData.offer_time[keys]?.end_hour ||
              attachTableData.offer_time[keys]?.end_minute)
          ) {
            const offerTimes = {
              week_no: weekNo,
              start_time:
                attachTableData.offer_time[keys]?.start_hour +
                ":" +
                attachTableData.offer_time[keys]?.start_minute,
              end_time:
                attachTableData.offer_time[keys]?.end_hour +
                ":" +
                attachTableData.offer_time[keys]?.end_minute,
            }
            plan_table.offer_time.push(offerTimes)
          }
        }
        plan_table.before_support_time = []
        for (const keys in attachTableData.before_offer_time) {
          const weekNo = CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER.indexOf(keys)
          if (
            weekNo != -1 &&
            (attachTableData.before_offer_time[keys]?.start_hour ||
              attachTableData.before_offer_time[keys]?.start_minute ||
              attachTableData.before_offer_time[keys]?.end_hour ||
              attachTableData.before_offer_time[keys]?.end_minute)
          ) {
            const offerTimes = {
              week_no: weekNo,
              start_time:
                attachTableData.before_offer_time[keys]?.start_hour +
                ":" +
                attachTableData.before_offer_time[keys]?.start_minute,
              end_time:
                attachTableData.before_offer_time[keys]?.end_hour +
                ":" +
                attachTableData.before_offer_time[keys]?.end_minute,
            }
            plan_table.before_support_time.push(offerTimes)
          }
        }
        plan_table.after_support_time = []
        for (const keys in attachTableData.after_provision_time) {
          const weekNo = CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER.indexOf(keys)
          if (
            weekNo != -1 &&
            (attachTableData.after_provision_time[keys]?.start_hour ||
              attachTableData.after_provision_time[keys]?.start_minute ||
              attachTableData.after_provision_time[keys]?.end_hour ||
              attachTableData.after_provision_time[keys]?.end_minute)
          ) {
            const offerTimes = {
              week_no: weekNo,
              start_time:
                attachTableData.after_provision_time[keys]?.start_hour +
                ":" +
                attachTableData.after_provision_time[keys]?.start_minute,
              end_time:
                attachTableData.after_provision_time[keys]?.end_hour +
                ":" +
                attachTableData.after_provision_time[keys]?.end_minute,
            }
            plan_table.after_support_time.push(offerTimes)
          }
        }
      }

      const _requestBody = {
        ...values,
        id: +query?.id || null,
        child_id: +values?.child_id,
        start_date: values?.start_date
          ? getDateTimeStringFromDate(values?.start_date)
          : null,
        agree_date: values?.agree_date
          ? getDateTimeStringFromDate(values?.agree_date)
          : null,
        end_date: values?.end_date
          ? getDateTimeStringFromDate(values?.end_date)
          : null,
        report_create_date: values?.agree_date
          ? getDateTimeStringFromDate(values?.agree_date)
          : null,
        goto_meet_flag: values?.goto_meet_flag === "2" ? true : false,
        take_flag: values?.take_flag === "2" ? true : false,
        draft_save_flag: draftFlg === 0 ? false : true,
        weekly_schedules: _weeklyScheduleDataSource,
        plan_items: _plan_items,
        create_count: parseInt(values?.create_count.toString(), 10) || 1,
      }

      if (Object.keys(plan_table).length > 0) {
        _requestBody.plan_table = plan_table
      } else {
        _requestBody.plan_table = {}
      }

      if (operation === "create" || operation === "copy") {
        addIndividualSupportPlanFunction({
          values: _requestBody,
          type: type,
        })
      }
      if (operation === "edit" && CHILD_ID_FROM_PARAMS) {
        updateIndividualSupportPlanFunction({
          values: _requestBody,
          type: type,
          create_count: +query?.create_count,
        })
      }
    },
  })

  const { data: FACILITIES_OPTIONS, isLoading: isFacilityLoading } = useQuery({
    queryKey: ["active_facilities", childId],
    queryFn: () =>
      getActiveContractFacilities({
        query: `${`?child_id=${childId}`}`,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    enabled: childId ? true : false,
    select: (resp) =>
      resp?.data?.map((fac) => {
        return {
          label: fac?.facility?.facility_name,
          value: fac?.facility?.id,
        }
      }),
  })

  const [dataSource, setDataSource] = useState<any>(BLANK_DRAFT_ITEMS)
  const [attachTableData, setAttachTableData] = useState<IAttachTable>({
    offer_time: {},
    notice: "",
    before_offer_time: {},
    after_provision_time: {},
    reason: "",
  })
  const [weeklyScheduleDataSource, setWeeklyScheduleDataSource] = useState([])

  // check for child existence
  const childIdFinal = CHILD_ID_FROM_PARAMS
    ? parseInt(CHILD_ID_FROM_PARAMS.toString())
    : childId

  const existChildInfo =
    !childInfoIsLoading &&
    childInformation?.find((each) => each.value === childIdFinal)

  //get selected child facility
  const getChildContractFacility = (child_receiving_certificates) => {
    if (child_receiving_certificates[0]) {
      const isActiveFacility = FACILITIES_OPTIONS?.find(
        (facility) =>
          facility?.value === child_receiving_certificates[0]?.facility_id
      )
      if (isActiveFacility) {
        formik.setFieldValue(
          "facility_id",
          child_receiving_certificates[0]?.facility_id
        )
      } else if (FACILITIES_OPTIONS?.length)
        formik.setFieldValue("facility_id", FACILITIES_OPTIONS?.[0]?.value)
    } else {
      formik.setFieldValue("facility_id", FACILITIES_OPTIONS?.[0]?.value)
    }
  }
  const { isLoading: isFacilityAdminLoading, data: adminData } = useQuery({
    queryKey: [
      "getFacilityAdmin",
      !!formik?.values?.facility_id,
      formik?.values?.agree_date,
    ],
    queryFn: () => {
      const query = []
      if (moment(formik.values?.agree_date).isValid()) {
        query.push(`year=${moment(formik.values?.agree_date).year()}`)
        query.push(`month=${moment(formik.values?.agree_date).month() + 1}`)
      }
      return getOneFacilityAdmins(formik?.values?.facility_id, query?.join("&"))
    },
    enabled: !!formik?.values?.facility_id,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    select: ({ data }) =>
      data.map((staff) => ({
        label: staff?.staff_name,
        value: staff?.id,
        staffFacilities: staff?.staff_facility || [],
      })),
    onSuccess: (data) => {
      if (formik?.values?.facility_admin) {
        const hasAdmin = data?.find(
          (d) => d?.value == formik?.values?.facility_admin
        )
        if (!hasAdmin) {
          formik.setFieldValue("facility_admin", null)
        }
      }
    },
  })

  const { isLoading: isChildLoading, data: supportPlanData } = useQuery({
    queryKey: ["getIndividualDraftByChildId", childId, debounceValue],
    queryFn: () =>
      getIndividualSupportPlanByChildId(
        plan_id || id,
        childId,
        createCount || query?.create_count || null,
        type,
        operation
      ),
    enabled: operation === "create" ? !!childId : plan_id ? !!plan_id : !!id,
    retry: 1,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    select: (response) => {
      return {
        ...response?.data,
        number_of_create_count:
          operation === "edit"
            ? response?.data?.number_of_create_count?.filter(
                (val) => val !== response?.data?.childSupport?.create_count
              )
            : response?.data?.number_of_create_count,
      }
    },
    onSuccess: (res) => {
      const randomIds = [Math.random()]
      const DRAFT_ITEMS = res?.childSupportItems?.map((each: any) => {
        const achievement_goals = each?.support_content?.achievement_goals
          ? each?.support_content?.achievement_goals?.map(
              (goal: any, index) => {
                if (index > randomIds?.length - 1) {
                  randomIds.push(Math.random())
                }
                return {
                  id: randomIds[index],
                  title: goal,
                }
              }
            )
          : [{ id: randomIds[0], title: "" }]
        const support_and_considerations = each?.support_content
          ?.support_and_considerations
          ? each?.support_content?.support_and_considerations?.map(
              (goal: any, index) => {
                return {
                  id: randomIds[index],
                  title: goal,
                }
              }
            )
          : [{ id: randomIds[0], title: "" }]
        const support_guidelines = each?.support_content?.support_guidelines
          ? each?.support_content?.support_guidelines?.map(
              (guideline: any, index) => {
                return {
                  id: randomIds[index],
                  title: guideline,
                }
              }
            )
          : support_and_considerations?.map((c) => ({
              id: c?.id,
              title: [{ value: 0, title: "" }],
            }))

        const achievement_time = each?.support_content?.achievement_time
          ? each?.support_content?.achievement_time?.map((goal: any, index) => {
              return {
                id: randomIds[index],
                title: goal,
              }
            })
          : [{ id: randomIds[0], title: "" }]
        const providing_organization =
          each?.support_content?.providing_organization &&
          Array.isArray(each?.support_content?.providing_organization) &&
          each?.support_content?.providing_organization?.length > 0
            ? each?.support_content?.providing_organization?.map(
                (org: any, index) => {
                  return {
                    id: randomIds[index],
                    title: org,
                  }
                }
              )
            : [{ id: randomIds[0], title: "" }]
        const points_to_note =
          each?.support_content?.points_to_note &&
          Array.isArray(each?.support_content?.points_to_note) &&
          each?.support_content?.points_to_note.length > 0
            ? each?.support_content?.points_to_note?.map(
                (point: any, index) => {
                  return {
                    id: randomIds[index],
                    title: point,
                  }
                }
              )
            : [{ id: randomIds[0], title: "" }]
        const priority =
          each?.support_content?.priority &&
          Array.isArray(each?.support_content?.priority) &&
          each?.support_content?.priority.length > 0
            ? each?.support_content?.priority?.map((p: any, index) => {
                return {
                  id: randomIds[index],
                  title: p,
                }
              })
            : [{ id: randomIds[0], title: "" }]
        return {
          ...each,
          ...each?.support_content,
          achievement_goals,
          support_and_considerations,
          support_guidelines,
          achievement_time,
          providing_organization,
          points_to_note,
          priority,
        }
      })
      const DRAFT_SCHEDULES = res?.childSupportSchedule?.map((each) => {
        return {
          id: each?.id,
          week_no: each?.week_no,
          start_time: each?.start_time,
          end_time: each?.end_time,
          comment: each?.comment,
          sIndex: TIME_INTERVALS?.findIndex(
            (val) => val?.value === each?.start_time
          ),
          eIndex: TIME_INTERVALS?.findIndex(
            (val) => val?.value === each?.end_time
          ),
        }
      })
      const numberOfItems =
        operation === "create" && create_count
          ? create_count
          : operation === "copy" || operation === "create"
          ? res?.number_of_create_count?.length > 0
            ? res?.number_of_create_count[0] + 1
            : create_count || createCount || 1
          : res?.childSupport?.create_count || createCount || 1

      setWeeklyScheduleDataSource(DRAFT_SCHEDULES || [])
      setDataSource(DRAFT_ITEMS?.length > 0 ? DRAFT_ITEMS : BLANK_DRAFT_ITEMS)
      formik.setFieldValue(
        "recipient_number",
        res?.childSupport.child_info?.child_receiving_certificates[0]
          ?.receiving_certificate_number ||
          existChildInfo?.recipient_number ||
          null
      )
      getChildContractFacility(
        res?.childSupport.child_info?.child_receiving_certificates
      )
      formik.setFieldValue("facility_admin", res?.childSupport?.staff_id)
      formik.setFieldValue("create_count", createCount || numberOfItems)

      formik.setFieldValue("staff_id", res?.childSupport?.staff_id)

      formik.setFieldValue(
        "use_service_id",
        res?.childSupport?.use_service_id ||
          existChildInfo?.service_type ||
          null
      )
      formik.setFieldValue(
        "support_pran_draft_type",
        res?.childSupport?.support_pran_draft_type
      )
      formik.setFieldValue(
        "child_family_hope",
        res?.childSupport?.child_family_hope
      )
      formik.setFieldValue("support_policy", res?.childSupport?.support_policy)
      formik.setFieldValue("long_term_goal", res?.childSupport?.long_term_goal)
      formik.setFieldValue(
        "short_term_goal",
        res?.childSupport?.short_term_goal
      )
      formik.setFieldValue(
        "goto_meet_flag",
        res?.childSupport?.goto_meet_flag ? "2" : "1"
      )
      formik.setFieldValue(
        "goto_meet_place",
        res?.childSupport?.goto_meet_place
      )
      formik.setFieldValue(
        "take_flag",
        res?.childSupport?.take_flag ? "2" : "1"
      )
      formik.setFieldValue("take_place", res?.childSupport?.take_place)
      formik.setFieldValue("remarks", res?.childSupport?.remarks)
      formik.setFieldValue(
        "standard_hr_providing_support",
        res?.childSupport?.standard_hr_providing_support
      )
      const currentNo = createCount
      if (res?.number_of_create_count?.includes(currentNo)) {
        setIsAlreadyCreated(true)
      } else {
        setIsAlreadyCreated(false)
      }
      if (res?.childSupportPlanTable?.id) {
        const attachTableResponseData: IAttachTable = {
          offer_time: {},
          notice: res?.childSupportPlanTable?.notices,
          before_offer_time: {},
          after_provision_time: {},
          reason: res?.childSupportPlanTable?.reason,
        }

        const timeFields = {
          offer_time: res?.childSupportPlanTable?.offer_time,
          before_offer_time: res?.childSupportPlanTable?.before_support_time,
          after_provision_time: res?.childSupportPlanTable?.after_support_time,
        }

        for (const keys in timeFields) {
          const responseData = timeFields[keys]
          if (Array.isArray(responseData)) {
            responseData?.forEach((data) => {
              const dayKey =
                CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER?.[data?.week_no]
              if (dayKey) {
                const startTime = data?.start_time?.split(":")
                const start_hour = startTime?.[0] || ""
                const start_minute = startTime?.[1] || ""
                const endTime = data?.end_time?.split(":")
                const end_hour = endTime?.[0] || ""
                const end_minute = endTime?.[1] || ""
                if (typeof attachTableResponseData[keys] == "object")
                  attachTableResponseData[keys][dayKey] = {
                    start_hour,
                    start_minute,
                    end_hour,
                    end_minute,
                  }
              }
            })
          }
        }

        setAttachTableData(attachTableResponseData)
      }
      if (operation === "edit") {
        formik.setFieldValue(
          "agree_date",
          res?.childSupport?.agree_date
            ? moment(res?.childSupport?.agree_date)
            : moment(initialDate)
        )
        formik.setFieldValue(
          "start_date",
          res?.childSupport?.start_date
            ? moment(res?.childSupport?.start_date)
            : moment(initialDate)
        )
        formik.setFieldValue(
          "end_date",
          res?.childSupport?.end_date
            ? moment(res?.childSupport?.end_date)
            : moment(initialDate).add(6, "M").subtract(1, "d")
        )
      }
    },
    onError: () => {
      toastMsg(
        "error",
        t("Something went wrong. Please contact administrator.")
      )
      setDataSource([
        {
          id: new Date().getTime(),
          title: "",
          achievement_goals: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
          support_and_considerations: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
          achievement_time: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
        },
      ])
      setWeeklyScheduleDataSource([])
      formik.resetForm()
    },
    onSettled: () => {
      setIsCountChanged(false)
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }

  useEffect(() => {
    if (CHILD_ID_FROM_PARAMS) {
      formik.setFieldValue("child_id", CHILD_ID_FROM_PARAMS)
      setChildId(+CHILD_ID_FROM_PARAMS || childId)
    }
  }, [query?.child_id, query?.id])

  useEffect(() => {
    if (
      !isChildLoading &&
      !childInfoIsLoading &&
      (operation === "create" || operation === "copy")
    ) {
      const _child = childInformation?.find(
        (each) => each.value === formik.values.child_id
      )
      if (_child) {
        formik.setFieldValue("recipient_number", _child?.recipient_number || "")
        if (+_child?.service_type > 0)
          formik.setFieldValue("use_service_id", +_child?.service_type)
        else formik.setFieldValue("use_service_id", null)
      }
    }
  }, [isChildLoading, childInfoIsLoading])

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  useEffect(() => {
    if (FACILITIES_OPTIONS) {
      getChildContractFacility(
        supportPlanData?.childSupport?.child_info
          ?.child_receiving_certificates || []
      )
    }
  }, [FACILITIES_OPTIONS])
  const [startChar, setStartChar] = useState(null)

  const handleNoOfCreateChange = (value: number) => {
    if (operation === "edit" || operation === "copy") {
      if (supportPlanData?.number_of_create_count?.includes(value)) {
        setIsAlreadyCreated(true)
      } else {
        setIsAlreadyCreated(false)
      }
    } else {
      setIsCountChanged(true)
      setCreateCount(value)
    }
  }

  const postApril = isApril2024ChangeApplicable(
    moment(formik?.values?.start_date)
  )

  const renderCTABtns = () => (
    <BtnsContainer>
      <OwnerButton
        text={t("Cancel")}
        shape={"circle"}
        typeOf={"secondary"}
        onClick={() => push(cancelURl)}
        disabled={
          addingIndividualSupportPlan ||
          updatingIndividualSupportPlan ||
          isDeleting
        }
      />
      <OwnerButton
        text={t("Save")}
        shape={"circle"}
        isLoading={
          !draftFlg
            ? addingIndividualSupportPlan || updatingIndividualSupportPlan
            : false
        }
        disabled={
          isAlreadyCreated ||
          addingIndividualSupportPlan ||
          updatingIndividualSupportPlan
        }
        onClick={() => {
          setDraftFlg(0)
          formik.handleSubmit()
        }}
      />
      <OwnerButton
        text={t("Save draft")}
        shape={"circle"}
        typeOf={"draft"}
        className={"draft-btn"}
        isLoading={
          draftFlg
            ? addingIndividualSupportPlan || updatingIndividualSupportPlan
            : false
        }
        disabled={
          isAlreadyCreated ||
          addingIndividualSupportPlan ||
          updatingIndividualSupportPlan
        }
        onClick={() => {
          setDraftFlg(1)
          formik.handleSubmit()
        }}
      />
    </BtnsContainer>
  )
  return (
    <MainContainer>
      <PageCard title={""}>
        {query?.child_id && !isChildLoading && !existChildInfo ? (
          <div
            style={{
              width: "100%",
              height: "20rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("Loading...")}
          </div>
        ) : (
          <MainWrapper>
            <Spin size={"large"} spinning={!isCountChanged && isChildLoading}>
              <Wrapper onSubmit={formik.handleSubmit}>
                <div className={"top_ctas"}>
                  {renderCTABtns()}
                  {operation === "edit" && (
                    <OwnerButton
                      text={t("Delete")}
                      icon={"delete-outlined"}
                      typeOf={"secondary"}
                      className={"delete_btn"}
                      disabled={
                        isDeleting ||
                        addingIndividualSupportPlan ||
                        updatingIndividualSupportPlan
                      }
                      isLoading={isDeleting}
                      onClick={() => setIsModalOpen(true)}
                    />
                  )}
                </div>

                <div className={"content"}>
                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Child name")}</div>
                      <Required />
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <div className={"flex-row"}>
                      <SelectInput
                        padding={`0 12px`}
                        options={FuriganaAlphabetsOption}
                        placeholder={"---"}
                        value={startChar}
                        onChange={(v) => {
                          formik.setFieldValue("facility_admin", null)
                          formik.setFieldValue("staff_id", null)
                          setStartChar(v)
                        }}
                        width={80}
                        height={40}
                      />
                      <span className={"lbl"}>
                        <SelectInput
                          name={"child_id"}
                          allowClear
                          placeholder={t("-------")}
                          disabled={
                            operation !== "create" && !!CHILD_ID_FROM_PARAMS
                          }
                          value={
                            childInformation?.length
                              ? parseInt(
                                  formik.values?.child_id || query?.child_id,
                                  10
                                )
                              : null
                          }
                          dropdownMatchSelectWidth={false}
                          onChange={(val) => {
                            setChildId(val)
                            formik.setFieldValue("child_id", val)
                            formik.setFieldValue("facility_id", null)
                          }}
                          options={
                            startChar
                              ? childInformation?.filter((child) =>
                                  child.furigana.startsWith(startChar)
                                )
                              : childInformation
                          }
                          loading={childInfoIsLoading}
                          height={40}
                          padding={`0 15px`}
                          error={
                            formik.touched.child_id && formik.errors.child_id
                          }
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Recipient number")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <Typography.Text className={"text"}>
                      {formik?.values?.child_id
                        ? formik?.values?.recipient_number
                        : t("Select child first")}
                    </Typography.Text>
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Facility name")}</div>
                      <Required />
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    {formik?.values?.child_id ? (
                      !isFacilityLoading && FACILITIES_OPTIONS?.length == 0 ? (
                        <div>
                          {t("There is no contract for all facilities")} <br />
                          <Link
                            href={`/child/profile/${formik?.values?.child_id}/recipient-certificate-form`}
                          >
                            <a
                              target={"_blank"}
                              style={{
                                textDecoration: "underline",
                                color: theme.blue5,
                              }}
                            >
                              {"▶"}
                              {t("Confirm the contracted amount")}
                            </a>
                          </Link>
                        </div>
                      ) : (
                        <SelectInput
                          padding={`0 15px`}
                          name={"facility_id"}
                          placeholder={t("-------")}
                          value={formik.values?.facility_id}
                          onChange={(val) => {
                            formik.setFieldValue("facility_id", val)
                          }}
                          options={FACILITIES_OPTIONS}
                          width={300}
                          height={40}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.facility_id &&
                            !formik.values.child_id &&
                            formik.errors.facility_id
                          }
                        />
                      )
                    ) : (
                      <Typography.Text className={"text"}>
                        {t("Select child first")}
                      </Typography.Text>
                    )}
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Use service")}</div>
                      <Required />
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    {formik?.values?.child_id ? (
                      !isFacilityLoading && FACILITIES_OPTIONS?.length == 0 ? (
                        <div>
                          {t(
                            "Since there is no contract for all facilities, it is not possible to select the service to be used"
                          )}
                          <br />
                          <Link
                            href={`/child/profile/${formik?.values?.child_id}/recipient-certificate-form`}
                          >
                            <a
                              target={"_blank"}
                              style={{
                                textDecoration: "underline",
                                color: theme.blue5,
                              }}
                            >
                              {"▶"}
                              {t("Confirm the contracted amount")}
                            </a>
                          </Link>
                        </div>
                      ) : (
                        <SelectInput
                          padding={`0 15px`}
                          name={"use_service_id"}
                          placeholder={"-----"}
                          value={formik.values?.use_service_id}
                          onChange={(val) =>
                            formik.setFieldValue("use_service_id", val)
                          }
                          options={ALL_SERVICE_USE_OPTIONS}
                          width={300}
                          height={40}
                          className={"responsive-select"}
                          error={
                            formik.touched.use_service_id &&
                            !formik?.values?.recipient_number &&
                            formik.errors.use_service_id
                          }
                        />
                      )
                    ) : (
                      <Typography.Text className={"text"}>
                        {t("Select child first")}
                      </Typography.Text>
                    )}
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("No. created")}</div>
                      <Required />
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    {isAlreadyCreated ? (
                      <Typography.Text
                        style={{
                          color: "#f00",
                          lineHeight: 1.3,
                          fontSize: "14px",
                        }}
                      >
                        {t("Number of times already created")}
                      </Typography.Text>
                    ) : null}
                    <div className={"create_count"}>
                      <div>
                        <TextField
                          name={"create_count"}
                          value={formik?.values?.create_count}
                          onChange={({ target: { value } }) => {
                            formik.setFieldValue("create_count", +value)
                            handleNoOfCreateChange(+value)
                          }}
                          placeholder={"---"}
                          padding={"6px"}
                          min={1}
                          type={"number"}
                          disabled={isCountChanged}
                          error={
                            formik.touched.create_count &&
                            formik.errors.create_count
                          }
                          className={getErrorClass("create_count", "input")}
                        />
                      </div>
                      <Typography.Text>
                        {isCountChanged && (
                          <SyncOutlined
                            spin
                            style={{ marginRight: "0.3rem" }}
                          />
                        )}
                        {t("回目")}
                      </Typography.Text>
                    </div>
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Created date")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <DatePicker
                      placeholder={"---"}
                      date={
                        formik.values?.agree_date &&
                        moment(formik.values?.agree_date).isValid()
                          ? moment(formik.values?.agree_date)
                          : null
                      }
                      onDateChange={(date) => {
                        formik.setFieldValue("agree_date", date)
                        formik.setFieldValue("start_date", date)
                        formik.setFieldValue(
                          "end_date",
                          moment(date).add(6, "M").subtract(1, "d")
                        )
                      }}
                      style={{
                        height: "40px",
                      }}
                      format={"YYYY年MM月DD日"}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Start Date")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <DatePicker
                      placeholder={"---"}
                      disabledDate={(current) => {
                        const createdDate = formik?.values?.agree_date
                        return (
                          createdDate &&
                          current <= moment(createdDate).subtract(1, "d")
                        )
                      }}
                      date={
                        formik.values?.start_date &&
                        moment(formik.values?.start_date).isValid()
                          ? moment(formik.values?.start_date)
                          : null
                      }
                      onDateChange={(date) => {
                        formik.setFieldValue("start_date", date)
                        formik.setFieldValue(
                          "end_date",
                          moment(date).add(6, "M").subtract(1, "d")
                        )
                      }}
                      style={{
                        height: "40px",
                      }}
                      format={"YYYY年MM月DD日"}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Expire date")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <DatePicker
                      placeholder={"---"}
                      date={
                        formik.values?.end_date &&
                        moment(formik.values?.end_date).isValid()
                          ? moment(formik.values?.end_date)
                          : formik.values?.start_date
                          ? moment(formik.values?.start_date).isValid() &&
                            moment(formik.values?.start_date)
                              .add(6, "M")
                              .subtract(1, "d")
                          : null
                      }
                      onDateChange={(date) => {
                        formik.setFieldValue("end_date", date)
                      }}
                      disabledDate={(current) =>
                        current.isBefore(moment(formik.values?.start_date))
                      }
                      style={{
                        height: "40px",
                      }}
                      format={"YYYY年MM月DD日"}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {t("Child Development Support Manager")}
                      </div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    {formik?.values?.facility_id ? (
                      <SelectInput
                        options={adminData}
                        value={formik?.values?.facility_admin}
                        onChange={(v) => {
                          formik.setFieldValue("facility_admin", v)
                          formik.setFieldValue("staff_id", v)
                        }}
                        width={200}
                        height={40}
                        loading={isFacilityAdminLoading}
                        placeholder={t("Please select")}
                      />
                    ) : (
                      <Typography.Text className={"text"}>
                        {t("Please select a facility first")}
                      </Typography.Text>
                    )}
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {t("Types of individual support plans (draft)")}
                      </div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <Checkbox
                      name={"support_pran_draft_type"}
                      label={t("Alternative support")}
                      checked={formik?.values?.support_pran_draft_type}
                      value={formik?.values?.support_pran_draft_type}
                      onChange={({ target: { checked } }) =>
                        formik.setFieldValue("support_pran_draft_type", checked)
                      }
                    />
                  </Grid>
                </div>
                <div className={"content"}>
                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {t("Intention of the person/family")}
                      </div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <TextField
                      name={"child_family_hope"}
                      placeholder={
                        "例：宿題を集中して取り組み直し、採点を自身で行えるようになってほしい（家族）。友達と楽しく活動に参加したい（本人）。"
                      }
                      type={"textarea"}
                      rows={3}
                      value={formik.values.child_family_hope}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {t("Comprehensive support policy")}
                      </div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <TextField
                      name={"support_policy"}
                      placeholder={
                        "例：集中して学習に取り組める環境を整え、宿題、直しプリントを最後までできるよう支援します。"
                      }
                      type={"textarea"}
                      rows={3}
                      value={formik.values.support_policy}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Long term goal")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <TextField
                      name={"long_term_goal"}
                      placeholder={
                        "例：自分自身が行わなければならない事を考え、自信を持って取り組めるようにする。"
                      }
                      type={"textarea"}
                      rows={3}
                      value={formik.values.long_term_goal}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Short term goal")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <TextField
                      name={"short_term_goal"}
                      placeholder={
                        "例：他者からの指摘を受けなくても、自身で判断して行動できるようにする。"
                      }
                      type={"textarea"}
                      rows={3}
                      value={formik.values?.short_term_goal}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />
                  {postApril && (
                    <>
                      <Grid className={"grid-header"} background>
                        <div className={"label-wrapper"}>
                          <div className={"title"}>
                            {t("Standard hours for providing support, etc.")}
                            <br />
                            {t("(days of the week,frequency,time)")}
                          </div>
                        </div>
                      </Grid>
                      <Grid className={"flex-full grid-row-last"}>
                        <TextField
                          name={"standard_hr_providing_support"}
                          type={"textarea"}
                          rows={3}
                          value={formik.values?.standard_hr_providing_support}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid className={"flex-break"} />

                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Pick up")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <div className={"pick_up_content"}>
                      <div className={"radio_text_content"}>
                        <div>
                          <label>
                            {t("Greeted/Pick")} {":"}
                          </label>
                          <div style={{ marginTop: "7px", marginLeft: "10px" }}>
                            <RadioButtons
                              name={"goto_meet_flag"}
                              onChange={({ target: { value } }) =>
                                formik.setFieldValue("goto_meet_flag", value)
                              }
                              value={formik.values?.goto_meet_flag}
                              options={[
                                {
                                  label: t("None"),
                                  value: "1",
                                },
                                {
                                  label: t("Can"),
                                  value: "2",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <TextField
                          name={"goto_meet_place"}
                          id={"goto_meet_place"}
                          value={formik.values?.goto_meet_place}
                          onChange={formik.handleChange}
                          disabled={formik.values?.goto_meet_flag === "1"}
                          bgcolor={
                            formik.values?.goto_meet_flag === "1" ? "gray" : ""
                          }
                          placeholder={t(
                            "Enable the text field only when Can is selected"
                          )}
                          error={
                            formik.touched.goto_meet_place &&
                            formik.errors.goto_meet_place
                          }
                          maxLength={100}
                        />
                      </div>
                      <div className={"radio_text_content"}>
                        <div>
                          <label>
                            {t("Send ")} {":"}
                          </label>
                          <div style={{ marginTop: "7px", marginLeft: "10px" }}>
                            <RadioButtons
                              name={"take_flag"}
                              onChange={({ target: { value } }) =>
                                formik.setFieldValue("take_flag", value)
                              }
                              value={formik.values?.take_flag}
                              options={[
                                {
                                  label: t("None"),
                                  value: "1",
                                },
                                {
                                  label: t("Can"),
                                  value: "2",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <TextField
                          name={"take_place"}
                          id={"take_place"}
                          value={formik.values?.take_place}
                          onChange={formik.handleChange}
                          disabled={formik.values?.take_flag === "1"}
                          bgcolor={
                            formik.values?.take_flag === "1" ? "gray" : ""
                          }
                          placeholder={t(
                            "Enable the text field only when Can is selected"
                          )}
                          error={
                            formik.touched.take_place &&
                            formik.errors.take_place
                          }
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid className={"flex-break"} />
                  <Grid className={"grid-header"} background>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>{t("Remarks")}</div>
                    </div>
                  </Grid>
                  <Grid className={"flex-full grid-row-last"}>
                    <TextField
                      name={"remarks"}
                      placeholder={""}
                      type={"textarea"}
                      rows={3}
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid className={"flex-break"} />
                </div>
                <ChildSupportDraftPlanContent
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  startDate={formik?.values?.start_date}
                />

                <ChildSupportAttachTable
                  attachTableData={attachTableData}
                  updateAttachTableData={setAttachTableData}
                />

                <WeeklySchedule
                  dataSource={weeklyScheduleDataSource}
                  setDataSource={setWeeklyScheduleDataSource}
                />
                {renderCTABtns()}
              </Wrapper>
            </Spin>
          </MainWrapper>
        )}
      </PageCard>
      {isModalOpen && (
        <DeleteConfimationModal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={() => {
            deleteSupportPlan({
              id: +query?.id,
              type,
            })
            setIsModalOpen(false)
          }}
        />
      )}
    </MainContainer>
  )
}
