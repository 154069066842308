import React from "react"

// packages
import { t } from "i18next"

// styled components
import { BillingHead } from "../styles"

// components
import { LoadingState } from "."
import { OwnerTable } from "../../../molecules"

// types
import { IndividualTablesProps } from "../types"

// constants
import { blackDate } from "../constants"
import { Empty } from "antd"
import { moneyFormat } from "../../../../utils/moneyFormat"

export const IndividualTables: React.FC<IndividualTablesProps> = ({
  multipleData,
  currentSearchQueries,
  isBillingDetailsLoading = true,
}) => {
  const { displaySetting } = currentSearchQueries

  // table columns
  const tableColumns = [
    {
      title: null,
      dataIndex: "label",
      key: "label",
      width: 150,
      className: "label-cell",
      render: (value, _, index) => {
        const obj = {
          children: value,
          props: {},
        }
        /**
         * Overall gist of the following code:
         * First four rows will have colSpan of 2 spanning two columns,
         * 5th row will have rowSpan of 3, and colSpan of 1 spanning one column as it should normally,
         * 6th and 7th row will have colSpan of 0
         */
        if (index === 4) {
          obj.props["rowSpan"] = 3
        }

        if (index !== 4 && index !== 5 && index !== 6) {
          obj.props["colSpan"] = 2
        }

        if (index === 5 || index === 6) {
          obj.props["colSpan"] = 0
        }
        return obj
      },
    },

    {
      title: null,
      dataIndex: "sub_label",
      key: "sub_label",
      width: 150,
      className: "sub-label-cell",
      render: (text, _, index) => {
        const obj = {
          children: text,
          props: {},
        }
        /**
         * Overall gist of the following code:
         * First four rows will have colSpan of 0, making them hidden,
         * then starting from 5th row, colSpan will be 1 spanning one column as it should normally,
         */

        if (index === 4 || index === 5 || index === 6) {
          obj.props["colSpan"] = 1
        } else {
          obj.props["colSpan"] = 0
        }

        return obj
      },
    },

    {
      title: null,
      dataIndex: "value",
      key: "value",
      width: 300,
      className: "value-cell",
      render: (val, rec) => {
        if (rec.label === "受領日" || rec.label === "Date of receipt") {
          if (
            currentSearchQueries.displaySetting.includes("blankReceiptDate")
          ) {
            return blackDate
          }

          return val ? val : "---"
        }
        const valSign = Math.sign(val)
        let filterVal = !isNaN(Math.abs(val))
          ? t(`{{amount}}`, {
              amount:
                (valSign == -1 ? "-" : "") + moneyFormat(Math.abs(val), 2),
            })
          : val

        if (typeof rec.value === "number") {
          filterVal = "金" + rec.value.toLocaleString() + "円"
        }

        return filterVal ? filterVal.toLocaleString() : "---"
      },
    },
  ]

  const renderContainer = ({ key, item, title }) => {
    return (
      <div className={"individual-tabulated-info-container"} key={key}>
        {/* Billing head section starts here */}
        <BillingHead className={"billing-head direction-column"}>
          <p className={"date align-right white-space-pre-wrap"}>
            {displaySetting.includes("blankIssueDate")
              ? blackDate
              : item.issuedDate
              ? item.issuedDate
              : "---"}
          </p>
          <div className={"association-content"}>
            <p className={"serial-name"}>{item.receiptSerialNo}</p>
            <p className={"full-address"}>
              {item?.address?.substring(0, 200) || "-"}
            </p>
            <p className={"parent-name"}>{`${item.parentName} 様`}</p>
          </div>

          <div className={"facility-official-name align-right"}>
            {item.facilityOfficialName}
          </div>
        </BillingHead>
        {/* Billing head section ends here */}
        <br></br>
        <div className={"billing-right-content billing-title"}>
          <div
            className={"subject enveloped-layout"}
            style={{ fontWeight: "600" }}
          >
            {title}
          </div>
        </div>
        <div className={"short-description"}>
          {t(
            "We would like to inform you that we received payment from {{provisionCityName}} for the following services provided to {{childName}} on your behalf. If you have any doubts about the content of this notice, please contact our office or {{provisionCityName}}.",
            {
              provisionCityName: item.cityName,
              childName: item.child_name,
            }
          )}
        </div>

        <div className={"record-text"}>{t("Record")}</div>

        <OwnerTable
          className={"owner-table"}
          bordered
          columns={tableColumns}
          dataSource={item.data}
          scroll={{ x: "600px" }}
        />

        <div className={"notice"}>{t("*This notice is not an invoice.")}</div>
      </div>
    )
  }
  const title = displaySetting?.includes("disabilityToDisability")
    ? "Notification of Substitute Receipt of Disabled Children's Outpatient Benefits"
    : "Notification of Substitute Receipt of Handicapped Children's Outpatient Benefits"

  return (
    <div className={"multi-table-container"}>
      {isBillingDetailsLoading && <LoadingState tableColumns={tableColumns} />}

      {multipleData?.length > 0 ? (
        multipleData.map((item, index) => {
          return (
            <>
              {!displaySetting.includes("printCopyOnly") &&
                renderContainer({
                  key: index + item.id,
                  item,
                  title: t(title),
                })}
              {(displaySetting.includes("printCopy") ||
                displaySetting.includes("printCopyOnly")) &&
                renderContainer({
                  key: index + item.id + "copy",
                  item,
                  title: t(
                    "Disabled Children's Outpatient Benefits Notice of Proxy Receipt (copy)"
                  ),
                })}
            </>
          )
        })
      ) : (
        <Empty description={t("No data")} />
      )}
    </div>
  )
}
