import React, { useState } from "react"

// packages
import moment from "moment"
import { t } from "i18next"
import { useFormik } from "formik"
import { NationalInsuranceBillingFormPageProps } from "../NationalInsuranceBillingFormPage/types"

// styled components
import { Container, GuideText } from "./styles"

// components
import {
  InfoBoxMainText,
  BulkPeriodSettingOpSection,
  UsageRecordRewriteFormBlock,
} from "./components"
import {
  InfoBox,
  FilterOperationBlock,
} from "../NationalInsuranceBillingFormPage/components"

// utils
import { bulkPeriodSettingsValidation } from "./utils/validations"

export const UsageRecordRewriteFormPage: React.FC<
  NationalInsuranceBillingFormPageProps
> = ({
  childOptions,
  facilityOptions,
  isChildListLoading,
  currentSearchQueries,
  initialRecords,
  currentBillingMethod = "",
  handleCurrentSearchQueriesChange,
}) => {
  const [isConfirmationPage, setIsConfirmationPage] = useState(false)
  const [bulkSettingForPeriodDate, setBulkSettingForPeriodDate] = useState({
    startDate: null,
    endDate: null,
  })
  const formik = useFormik<{
    startDate: moment.Moment
    endDate: moment.Moment
  }>({
    initialValues: {
      startDate: bulkSettingForPeriodDate.startDate,
      endDate: bulkSettingForPeriodDate.endDate,
    },
    validationSchema: bulkPeriodSettingsValidation,
    onSubmit: (values) => {
      const newStartDate = values.startDate
      const newEndDate = values.endDate
      setBulkSettingForPeriodDate({
        startDate: newStartDate,
        endDate: newEndDate,
      })
    },
  })

  return (
    <>
      {!isConfirmationPage && (
        <GuideText className={"guide-text no-print"}>
          <li>
            {t(
              'Regarding the usage record during temporary school closures, in order to calculate both the "billed amount based on the actual service provision performance (school holidays)" and the "billed amount calculated as if there were no temporary school closures (after school)" Please rewrite.'
            )}
          </li>
        </GuideText>
      )}
      <Container>
        {!isConfirmationPage && (
          <FilterOperationBlock
            currentDate={moment(
              `${currentSearchQueries?.year}-${currentSearchQueries?.month}-1`
            ).format("YYYY-MM-DD")}
            facilityOptions={facilityOptions}
            currentFacilityId={currentSearchQueries.facility}
            handleCurrentSearchQueriesChange={handleCurrentSearchQueriesChange}
          >
            <BulkPeriodSettingOpSection formik={formik} />
          </FilterOperationBlock>
        )}

        {isConfirmationPage && <div style={{ height: "10px" }} />}

        <InfoBox
          href={`/national-health-insurance-billing?facility_id=${currentSearchQueries?.facility}&year=${currentSearchQueries?.year}&month=${currentSearchQueries?.month}&change_billing=1`}
          className={"no-print"}
          linkText={"Change billing information"}
        >
          <InfoBoxMainText billingMethod={currentBillingMethod} />
        </InfoBox>

        <UsageRecordRewriteFormBlock
          currentSearchQueries={currentSearchQueries}
          childOptions={childOptions}
          isChildListLoading={isChildListLoading}
          bulkSettingForPeriodDate={bulkSettingForPeriodDate}
          initialRecords={initialRecords}
          isConfirmationPage={isConfirmationPage}
          setIsConfirmationPage={(val: boolean) => setIsConfirmationPage(val)}
        />
      </Container>
    </>
  )
}
