import { API, removeBlankAttributes } from "@project/shared"

interface IChildAdditionSettingDayParams {
  keyword?: string
  year?: string | number
  month?: string | number
  facility_id?: string | number
  child_id?: string | number
  pageSize?: number | string
  page?: number | string
}

export const getAllChildAdditionSettingDay = async ({
  page,
  pageSize,
  searchParams,
}: {
  page?: number
  pageSize?: number | string
  searchParams?: IChildAdditionSettingDayParams
}) => {
  const initialParams = {} as IChildAdditionSettingDayParams

  initialParams.facility_id = searchParams.facility_id
  initialParams.pageSize = pageSize ? pageSize : "Infinity"
  initialParams.page = page
  initialParams.year = searchParams.year
  initialParams.month = searchParams.month
  initialParams.child_id = searchParams.child_id

  const params = removeBlankAttributes(initialParams)
  return API.get("/child-addition-setting-management", {
    params,
  })
}
