import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "@project/shared"
import { OwnerTable } from ".."

interface PrintSetting {
  print_only: boolean
  padding?: string
}

const Wrapper = styled.div<PrintSetting>`
  padding: ${(props) => props?.padding || "1rem"};
  margin-bottom: 4rem;
  background: #ffffff;
  .title-container {
    text-align: center;
    font-weight: bold;
  }
  .service-user-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: bold;
  }
  .ant-table {
    overflow: auto;
    thead th {
      text-align: center !important;
      background: ${theme.gray2};
      padding: 4px;
      border-top: 2px solid #000000;
      border-left: 2px solid #000000;
      border-right: none !important;
      border-bottom: 2px solid #000000 !important;
      outline: none !important;
      &:last-child {
        border-right: 2px solid #000000 !important;
      }
    }
    tbody tr {
      td {
        padding: 4px;
        border-bottom: 2px solid #000000;
        border-left: 2px solid #000000;
        border-right: none !important;
        border-top: none !important;
        &:last-child {
          border-right: 2px solid #000000 !important;
        }
      }
    }
    .width-class {
      min-width: 105px;
    }
    .width-class-max {
      min-width: 200px;
    }
    .today_situation {
      padding: 7px;
      @media print and (orientation: landscape) {
        max-width: 700px;
      }
      @media print and (orientation: portrait) {
        max-width: 300px;
      }
    }
  }
  @media not print {
    display: ${(props) => (props?.print_only ? "none" : "")};
  }
  @media print {
    padding: 0;
    margin: 0;
    table {
      overflow: visible !important;
    }
    thead {
      display: table-header-group;
    }
    tbody {
      display: table-row-group;
    }
    tr {
      page-break-inside: avoid;
    }
  }
`
export const UserServiceRecordCareChild = ({
  print_only = true,
  header,
  child_records,
  title,
  padding,
}: any) => {
  const { t } = useTranslation()

  return (
    <Wrapper print_only={print_only} padding={padding}>
      <div
        style={{
          pageBreakInside: "avoid",
        }}
      >
        <div className={"title-container"}>{title}</div>
        <div className={"service-user-container"}>
          <div className={"service-container"}>
            {t("Service used")}
            {":"}{" "}
            {child_records?.service == 1
              ? t("After school day service")
              : t("Child development support")}
          </div>
          <div className={"user-container"}>
            {t(" Username")} {" (　"} {child_records?.child_name} {"　)"}
          </div>
        </div>
      </div>
      <OwnerTable
        columns={header}
        dataSource={child_records?.child_service_details}
        bordered={true}
      />
    </Wrapper>
  )
}
