import { removeBlankAttributes } from "@project/shared"

export const generateURLString = (
  baseURL: string,
  obj: { [key: string]: string | number }
): string => {
  const rawObj = removeBlankAttributes(obj)
  const params = new URLSearchParams()

  for (const key in rawObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (rawObj?.hasOwnProperty(key)) {
      params.append(key, rawObj[key])
    }
  }
  return baseURL + "?" + params.toString()
}
