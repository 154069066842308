import { theme } from "@project/shared"
import { Typography } from "antd"
import React from "react"
import styled from "styled-components"

interface IWrapper {
  disabled: boolean
}

const Wrapper = styled.div<IWrapper>`
  .spinner-padding: {
    padding: 1rem;
  }
  position: relative;
  border: 1px solid ${theme.gray2};
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props?.disabled ? "none" : "auto")};
  gap: 0.5rem;
  .top-header {
    background-color: ${theme.bgColor2};
    border-bottom: 1px solid ${theme.gray2};
    padding: 1.25rem;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    height: 70px;
    .indicator__box {
      background: ${theme.blue6};
      min-height: 30px;
      min-width: 30px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 1.25rem;
  }
  .disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: ${(props) => (props?.disabled ? "100%" : "0")};
    width: ${(props) => (props?.disabled ? "100%" : "0")};
    background: rgba(255, 255, 255, 0.6);
    z-index: 99;
    pointer-events: none;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .top-header {
      padding: 0.5rem;
      height: fit-content;
      span {
        font-size: 12 px !important;
      }
    }
    .content {
      padding: 0.5rem !important;
    }
  }
`

type PropType = {
  num?: number
  title: string
  children?: React.ReactNode
  id?: any
  disabled?: boolean
}
export const BillingSectionHeader: React.FC<PropType> = ({
  num,
  title,
  children,
  id,
  disabled = false,
}): JSX.Element => {
  return (
    <Wrapper id={id} disabled={disabled}>
      <div className={"top-header"}>
        <div className={"indicator__box"}>{num}</div>
        <Typography.Text> {title}</Typography.Text>
      </div>
      <div className={"content"}>{children}</div>
    </Wrapper>
  )
}
