/**
 * NOTE:
 * - R6 April [abolished:廃止]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"

type Params = {
  name: string
  showInAfterFirstForm: boolean
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getProfessionalSupport(date: Moment, params: Params) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline(params.name, params.showInAfterFirstForm)
    default:
      return null
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 *
 * NONE
 * --------------------------------
 */

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */
function baseline(name: string, showInAfterFirstForm: boolean): FormFieldsProp {
  return {
    fieldLabel: t("Professional support addition"),
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: [
              {
                label: t("No"),
                value: "0",
              },
              {
                label: t("Physical Therapist"),
                value: "1",
              },
              !showInAfterFirstForm && {
                label: t("Children's instructor"),
                value: "2",
              },
            ],
          },
        },
      },
    ],
  }
}
