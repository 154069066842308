import { Table } from "@project/shared"
import { t } from "i18next"
import { DefaultProps } from "./types"
import { Content, makeEmptyRowData } from "./utils"
import { ReactNode } from "react"
import { KobeCityTableComponent } from "./../../common/body/BodyFormat.style"
import { ReceiptUpperLimitManagementItem } from "../../../../types"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"

/**
 * ===================================
 * Upper Table
 * ===================================
 */

enum UpperColumnKey {
  ITEN_NUMBER,
  FACILITY_NUMBER,
  FACILITY_NAME,
  TOTAL_AMOUNT,
  INSURANCE_AMOUNT,
  BURDEN_AMOUNT,
  MANAGEMENT_RESULT_BURDEN_AMOUNT,
  MANAGEMENT_RESULT_INSURANCE_AMOUNT,
}

const UPPER_COLUMNS = [
  {
    key: UpperColumnKey.ITEN_NUMBER,
    title: t("Item number"),
  },
  {
    key: UpperColumnKey.FACILITY_NUMBER,
    title: t("Business no."),
  },
  {
    key: UpperColumnKey.FACILITY_NAME,
    title: t("Business official name"),
  },
  {
    key: UpperColumnKey.TOTAL_AMOUNT,
    title: t("Total amount"),
  },
  {
    key: UpperColumnKey.INSURANCE_AMOUNT,
    title: t(
      "Day-to-day care benefits for disabled children, day-care benefits for disabled children"
    ),
  },
  {
    key: UpperColumnKey.BURDEN_AMOUNT,
    title: t("User fee"),
  },
  {
    key: UpperColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT,
    title: t("Management results"),
    title2: t("User fee"),
  },
  {
    key: UpperColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT,
    title: "",
    title2: t(
      "Day-to-day care benefits for disabled children, day-care benefits for disabled children"
    ),
  },
]

const UPPER_LEFT_TABLE_HEAD_COLUMN = {
  title: "",
  className: "kobe-col",
  width: 20,
  render: () =>
    t(
      "User burden summation / adjustment column ( before Kobe City's own reduction / exemption )"
    ),

  onCell: (_, index) => ({ rowSpan: index === 0 ? 8 : 0 }),
}

const UPPER_TABLE_TITLE_COLUMN = {
  title: "",
  dataIndex: "title",
  align: "center",
  width: 40,
  className: "kobe-col",
  onCell: (_, index) => {
    if (index === UpperColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
      return { rowSpan: 2 }
    }
    if (index === UpperColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
      return { rowSpan: 0 }
    }
    return { colSpan: 2 }
  },
}

const UPPER_TABLE_MANAGE_TITLE_COLUMN = {
  title: "",
  dataIndex: "title2",
  align: "center",
  width: 80,
  className: "kobe-col",
  onCell: (_, index) => {
    if (
      index === UpperColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT ||
      index === UpperColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT
    ) {
      return { colSpan: 1 }
    }
    return { colSpan: 0 }
  },
}

function getUpperColumn(row: ReceiptUpperLimitManagementItem, index: number) {
  if (index === UpperColumnKey.ITEN_NUMBER) {
    return row.line_no
  }
  if (index === UpperColumnKey.FACILITY_NUMBER) {
    return row.facility_number
  }
  if (index === UpperColumnKey.FACILITY_NAME) {
    return row.facility_name
  }
  if (index === UpperColumnKey.TOTAL_AMOUNT) {
    return <Content data={String(row.total_amount).padStart(6, ".")} />
  }
  if (index === UpperColumnKey.INSURANCE_AMOUNT) {
    // TODO: 障害児通所給付費・障害児入所給付費
    return <Content data={String(row.insurance_amount).padStart(6, ".")} />
  }
  if (index === UpperColumnKey.BURDEN_AMOUNT) {
    return <Content data={String(row.burden_amount).padStart(6, ".")} />
  }
  if (index == UpperColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_burden_amount).padStart(6, ".")}
      />
    )
  }
  if (index === UpperColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
    // TODO: 管理結果 障害児通所給付費・障害児入所給付費
    return (
      <Content
        data={String(row.management_result_insurance_amount).padStart(6, ".")}
      />
    )
  }
  return ""
}

function generateUpperColumn(facilities: ReceiptUpperLimitManagementItem[]) {
  const emptyLength = 5 - facilities.length
  if (emptyLength > 0) {
    facilities = [...facilities, ...Array(emptyLength).fill(makeEmptyRowData())]
  }
  return facilities.map((value) => ({
    align: "center",
    width: 120,
    className: "kobe-col",
    render: (_, __, index) => getUpperColumn(value, index),
  }))
}

function generateUpperSummaryColumn(
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  totalInsuranceAmount,
  managementResultInsuranceAmount
) {
  return {
    title: t("Total"),
    align: "center",
    width: 180,
    className: "kobe-col",
    onCell: (_, index) => {
      let rowSpan = 1
      if (index === 0) {
        rowSpan = 3
      }
      if (index === 1 || index === 2) {
        rowSpan = 0
      }
      return { rowSpan }
    },
    render: (_, __, index) => {
      if (index === UpperColumnKey.ITEN_NUMBER) {
        return t("Total")
      }
      if (index === UpperColumnKey.TOTAL_AMOUNT) {
        return <Content data={String(totalAmount).padStart(6, ".")} />
      }
      if (index === UpperColumnKey.INSURANCE_AMOUNT) {
        // TODO: 障害児通所給付費・障害児入所給付費
        return <Content data={String(totalInsuranceAmount).padStart(6, ".")} />
      }
      if (index === UpperColumnKey.BURDEN_AMOUNT) {
        return <Content data={String(totalBurdenAmount).padStart(6, ".")} />
      }
      if (index === UpperColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
        return (
          <Content
            data={String(managementResultBurdenAmount).padStart(6, ".")}
          />
        )
      }
      if (index === UpperColumnKey.MANAGEMENT_RESULT_INSURANCE_AMOUNT) {
        return (
          <Content
            data={String(managementResultInsuranceAmount).padStart(6, ".")}
          />
        )
      }
    },
  }
}

/**
 * ===================================
 * Lower Table
 * ===================================
 */

enum LowerColumnKey {
  LOCAL_EXEMPTION,
  LOCAL_BURDEN_AMOUNT,
}

const LOWER_COLUMNS = [
  {
    key: LowerColumnKey.LOCAL_EXEMPTION,
    title: t("Kobe City's own tax exemption"),
    title2: "",
  },
  {
    key: LowerColumnKey.LOCAL_BURDEN_AMOUNT,
    title: t("Management results"),
    title2: (
      <>
        {t("User burden")}
        <br />
        {t("(Kobe City/benefits portion)")}
      </>
    ),
  },
]

const LOWER_LEFT_TABLE_HEAD_COLUMN = {
  className: "kobe-col",
  width: 20,
  render: () => t("Kobe City's own tax exemption"),
  onCell: (_, index: number) => ({
    rowSpan: index === 0 ? 2 : 0,
  }),
}

const LOWER_TABLE_TITLE_COLUMN = {
  className: "kobe-col",
  width: 40,
  render: (data: { title: ReactNode; title2: ReactNode }) => data.title,
  onCell: (_, index: number) => ({
    colSpan: index === 0 ? 2 : 1,
  }),
}

const LOWER_TABLE_MANAGE_TITLE_COLUMN = {
  className: "kobe-col",
  width: 80,
  render: (data: { title: ReactNode; title2: ReactNode }) => data.title2,
  onCell: (_, index: number) => ({
    colSpan: index === 0 ? 0 : 1,
  }),
}

function generateLowerColumn(facilities: ReceiptUpperLimitManagementItem[]) {
  const emptyLength = 5 - facilities.length
  if (emptyLength > 0) {
    facilities = [...facilities, ...Array(emptyLength).fill(makeEmptyRowData())]
  }

  return facilities.map((facility) => ({
    align: "center",
    className: "kobe-col",
    width: 120,
    render: (_, __, index) => {
      if (index === LowerColumnKey.LOCAL_EXEMPTION) {
        return (
          <Content data={String(facility.local_exemption).padStart(6, ".")} />
        )
      }
      if (index === LowerColumnKey.LOCAL_BURDEN_AMOUNT) {
        return (
          <Content
            data={String(facility.local_burden_amount).padStart(6, ".")}
          />
        )
      }
    },
  }))
}

// 神戸市独自減免 合計カラム
function generateLowerSummaryColumn(
  totalLocalExemption: number,
  localBurdenAmount: number
) {
  return {
    align: "center",
    width: 180,
    className: "kobe-col",
    render: (_, __, index) => {
      if (index === LowerColumnKey.LOCAL_EXEMPTION) {
        return <Content data={String(totalLocalExemption).padStart(6, ".")} />
      }
      if (index === LowerColumnKey.LOCAL_BURDEN_AMOUNT) {
        return <Content data={String(localBurdenAmount).padStart(6, ".")} />
      }
    },
  }
}

/**
 * ===================================
 * Render
 * ===================================
 */

type Props = DefaultProps & {
  totalInsuranceAmount: number
  managementResultInsuranceAmount: number
  totalLocalExemption: number
  localBurdenAmount: number
}

export function KobeFormat({
  facilities,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  totalInsuranceAmount,
  managementResultInsuranceAmount,
  totalLocalExemption,
  localBurdenAmount,
}: Props) {
  return (
    <KobeCityTableComponent>
      {/* 神戸市独自減免前 */}
      <NBLManagementCompanyTop style={{ marginBottom: 16 }}>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            className={"KobeCityTable"}
            showHeader={false}
            columns={[
              UPPER_LEFT_TABLE_HEAD_COLUMN,
              UPPER_TABLE_TITLE_COLUMN,
              UPPER_TABLE_MANAGE_TITLE_COLUMN,
              ...generateUpperColumn(facilities),
              generateUpperSummaryColumn(
                totalAmount,
                totalBurdenAmount,
                managementResultBurdenAmount,
                totalInsuranceAmount,
                managementResultInsuranceAmount
              ),
            ]}
            pagination={false}
            dataSource={UPPER_COLUMNS}
          />
        </div>
      </NBLManagementCompanyTop>

      {/* 神戸市独自減免 */}
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            className={"KobeCityTable"}
            showHeader={false}
            columns={[
              LOWER_LEFT_TABLE_HEAD_COLUMN,
              LOWER_TABLE_TITLE_COLUMN,
              LOWER_TABLE_MANAGE_TITLE_COLUMN,
              ...generateLowerColumn(facilities),
              generateLowerSummaryColumn(
                totalLocalExemption,
                localBurdenAmount
              ),
            ]}
            pagination={false}
            dataSource={LOWER_COLUMNS}
          />
        </div>
      </NBLManagementCompanyTop>
    </KobeCityTableComponent>
  )
}
