import React from "react"
import {
  Wrapper,
  NBLInfoContent,
  NBLMiddleInfoContent,
} from "./../../PrintListContent.style"
import {
  ReceiptSiblingUpperLimitManagement,
  SiblingChild,
} from "../../../../types"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../constants"
import { t } from "i18next"
import { KyotoSheetTitle } from "../header/sheetTitle/KyotoSheetTitle"
import { YearMonth } from "../../common/header/YearMonth"
import { ManagementFacility } from "../../common/header/ManagementFacility"
import { KyotoFormat } from "../body/KyotoFormat"
import { DefaultSignature } from "../../common/footer/signature/DefaultSignature"
import { KyotoParentDetail } from "../header/childDetail/KyotoParentDetail"
import { KyotoChildDetail } from "../header/childDetail/KyotoChildDetail"
import { KyotoUpperMonthLimit } from "../header/upperMonthLimit/KyotoUpperMonthLimit"
import { KyotoUpperLimitManagementResult } from "../header/upperLimitManagementResult/KyotoUpperLimitManagementResult"

type Props = {
  receiptSiblingUpperLimitManagement: ReceiptSiblingUpperLimitManagement
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function KyotoFlame({
  receiptSiblingUpperLimitManagement,
  siblingChildren = [],
  printConfig = [],
}: Props) {
  return (
    <Wrapper>
      {printConfig.includes(
        PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER
      ) && <small>{t("(Form 2)")}</small>}

      {/* --- Start Header */}
      <KyotoSheetTitle />
      <YearMonth
        year={receiptSiblingUpperLimitManagement.year}
        month={receiptSiblingUpperLimitManagement.month}
      />
      <NBLInfoContent length={1}>
        <div className={"top__table_content"}>
          <KyotoParentDetail
            municipalityNumber={
              receiptSiblingUpperLimitManagement.municipality_number
            }
            parentName={receiptSiblingUpperLimitManagement.parent_name}
            printConfig={printConfig}
          />
          <KyotoChildDetail
            siblingChildren={siblingChildren}
            printConfig={printConfig}
          />
        </div>
        <div className={"top__table_content"}>
          <ManagementFacility
            facilityName={receiptSiblingUpperLimitManagement.facility_name}
            facilityNumber={receiptSiblingUpperLimitManagement.facility_number}
          />
        </div>
      </NBLInfoContent>
      <NBLMiddleInfoContent>
        <div className={"NBLMiddleInfoContent"}>
          <div className={"nbl_info_top_content"}>
            <KyotoUpperMonthLimit
              burdenMaxMonth={
                receiptSiblingUpperLimitManagement.burden_max_month
              }
              localBurdenMaxMonth={
                receiptSiblingUpperLimitManagement.local_burden_max_month
              }
            />
          </div>
          <div className={"nbl_info_bottom_content"}>
            <KyotoUpperLimitManagementResult
              managementResult={
                receiptSiblingUpperLimitManagement.management_result
              }
            />
          </div>
        </div>
      </NBLMiddleInfoContent>
      {/* --- End Header */}

      {/* --- Start Body */}
      <KyotoFormat
        facilities={receiptSiblingUpperLimitManagement.facilities}
        totalAmount={receiptSiblingUpperLimitManagement.total_amount}
        totalBurdenAmount={
          receiptSiblingUpperLimitManagement.total_burden_amount
        }
        managementResultBurdenAmount={
          receiptSiblingUpperLimitManagement.management_result_burden_amount
        }
        totalInsuranceAmount={
          receiptSiblingUpperLimitManagement.total_management_result_insurance_amount
        }
        localManagementResultBurdenAmount={
          receiptSiblingUpperLimitManagement.local_burden_amount
        }
        siblingChildren={siblingChildren}
        printConfig={printConfig}
      />
      {/* --- End Body */}

      {/* --- Start Footer */}
      <DefaultSignature printConfig={printConfig} />
      {/* --- End Footer */}
    </Wrapper>
  )
}
