// packages
import { t } from "i18next"

// components
import { OwnerButton } from "../../atoms"
import { MonthlyUsageTable } from "./common/table"
import { CHILD_SERVICE_TYPES, TopRoundedWhiteCard } from "@project/shared"

// styled components
import { MonthlyUsageListContainer, RedText } from "./styles"
import { IFacilityProps } from "../MonthlyUsageChartOperation/types"

export const MonthlyUsageList = ({
  data,
  onPrint,
  onCancel,
  facilityProps,
  isTableLoading,
  showCombinedAfterSchoolRow = false,
  showAfterSchoolRow,
  showSchoolHolidayRow,
  showDevelopmentSupportRow,
}: {
  data: {
    [key: string]: any
  }
  onPrint: () => void
  onCancel: () => void
  isTableLoading?: boolean
  showAfterSchoolRow?: boolean
  facilityProps: IFacilityProps
  showCombinedAfterSchoolRow?: boolean
  showSchoolHolidayRow?: boolean
  showDevelopmentSupportRow?: boolean
}) => {
  return (
    <MonthlyUsageListContainer>
      <TopRoundedWhiteCard
        className={"top-rounded-white-card"}
        title={t("Monthly usage chart")}
      >
        <>
          {/* monthly usage list container's upper section starts */}
          <RedText className={"no-print"}>
            {t(
              "※If it does not fit on one sheet, set the magnification in the detailed settings to Custom and lower the number so that it fits on one sheet."
            )}
          </RedText>
          <div className={"buttons-container no-print"}>
            <OwnerButton
              className={"button"}
              text={t("Cancel")}
              typeOf={"secondary"}
              onClick={onCancel}
            />
            <OwnerButton
              className={"button"}
              icon={"print"}
              text={t("Print")}
              onClick={onPrint}
            />
          </div>
          <div className={"chartInfo"}>
            <p className={"chartInfo_text"}>
              <span className={"chartInfo_bold-text"}>
                {t(
                  "Monthly use status table (support for children with disabilities)"
                )}{" "}
              </span>{" "}
              <br />
              {t(`${data?.year}`) +
                "  " +
                `${t("office name")}: ${data?.facility_name}  ` +
                `${t("Service Name")}: ${
                  CHILD_SERVICE_TYPES[data?.service_type] || "-"
                }`}
            </p>
          </div>
          {/* monthly usage list container's upper section ends */}

          {/* monthly usage list container's lower section starts */}
          <MonthlyUsageTable
            data={data}
            facilityProps={facilityProps}
            isTableLoading={isTableLoading}
            showCombinedAfterSchoolRow={showCombinedAfterSchoolRow}
            showAfterSchoolRow={showAfterSchoolRow}
            showSchoolHolidayRow={showSchoolHolidayRow}
            showDevelopmentSupportRow={showDevelopmentSupportRow}
          />
          {/* monthly usage list container's lower section ends */}
        </>
      </TopRoundedWhiteCard>
    </MonthlyUsageListContainer>
  )
}
