import { ComponentProps } from "react"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../constants"

export function censorName(name: string) {
  return name
    .split(" ")
    .map((item) => {
      return item
        .split("")
        .map((e, idx) => (idx % 2 === 1 ? "○" : e))
        .join("")
    })
    .join(" ")
}

export function makeEmptyTdTags(
  length: number,
  props: ComponentProps<"td"> = {}
) {
  return Array(length).fill(<td {...props} />)
}

export function maskName(name: string, printConfig: string[]) {
  return printConfig.includes(
    PRINT_CONFIGURATION_OPTION_VALUES.HIDE_PERSONAL_INFORMATION
  )
    ? censorName(name)
    : name
}

export function maskDisabilityWord(text: string, printConfig: string[]) {
  return printConfig.includes(
    PRINT_CONFIGURATION_OPTION_VALUES.CHANGE_DISABILITY_WORD
  )
    ? text.replaceAll("障害", "障がい")
    : text
}
