/**
 * NOTE:
 * - R6 April [amended:改定]
 */

import {
  Field,
  physicalRestraintAbolitionNonImplementationSubtraction,
} from "../fields"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getPhysicalRestraintAbolitionNonImplementationSubtraction(
  date: Moment
) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return Baseline
    default:
      // NOTE: Not required after version R6April
      return null
  }
}

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */

const Baseline: Field = physicalRestraintAbolitionNonImplementationSubtraction
