// pacakges
import styled from "styled-components"

export const StyledSorter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  .sort-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    span {
      display: grid;
    }
  }
`
