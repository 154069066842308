import { t } from "i18next"
import { Title } from "../../../PrintListContent.style"

export function KisarazuSheetTitle() {
  return (
    <Title>{`複数児童用 ${t(
      "User burden maximum amount management result sheet"
    )}`}</Title>
  )
}
