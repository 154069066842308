import { Checkbox, theme } from "@project/shared"
import { Input } from "antd"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button } from "../../atoms"
import {
  AttendanceScheduleTimeRangeInput,
  AttendanceTypeButtons,
} from "../../molecules"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .shift-select {
    display: flex;
    justify-content: center;
  }
  .schedule-container {
    margin: 20px 0;
    background: #dcf4ff;
    border-radius: 5px;
    .ant-select {
      max-width: 45px;
    }
  }
  .inner-schedule-container {
    padding: 10px 5px;
  }
  .schedule-title {
    padding-bottom: 5px;
  }
  .schedule-button {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    color: red !important;
    button {
      margin: 0;
      padding: 8px 6px;
      white-space: normal;
      font-size: 10px;
      div {
        margin-right: 4px;
      }
    }
    @media print {
      display: none;
    }
  }
  .break-time-container {
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    input {
      max-width: 40px;
    }

    /* stylelint-disable property-no-vendor-prefix */
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .break-input-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .attendance-reset {
    color: #e40102;
    text-align: center;
    padding: 10px 0;
  }
`
const HolidayWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 100px;
  width: 100%;
  .holiday-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(224, 0, 0, 0.1);
    z-index: 9;
  }
`

const AbsentWrapper = styled.div`
  color: #e40102;
  text-align: center;
  padding: 10px 0;
`

interface IScheduleButton {
  type: "remove" | "add"
}

export type AttendanceHours = {
  id: number
  attendanceType: number
  from: {
    hours: string
    minutes: string
  }
  to: {
    hours: string
    minutes: string
  }
  break_time: number
}

export const AttendanceRecordTimeRange = ({
  date,
  dayDetail = null,
  onChange,
  isHoliday,
}) => {
  const { t } = useTranslation()

  if (isHoliday)
    return (
      <HolidayWrapper>
        <div className={"holiday-overlay"}></div>
      </HolidayWrapper>
    )

  if (!dayDetail) return <></>

  const initialValues = {
    attendanceType: null,
    from: {
      hours: null,
      minutes: null,
    },
    to: {
      hours: null,
      minutes: null,
    },
    break_time: null,
  }

  const possibleScheduleIds = [1, 2]

  const initialRuns = useRef(0)

  const getHours = () => {
    if (!dayDetail)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    return dayDetail.shiftHours
  }

  const [restTime, setRestTime] = useState({
    is_rest: dayDetail?.use_rest_time_flg ?? 0,
    rest_hours: dayDetail?.rest_hours ?? null,
  })
  const [shiftHours, setShiftHours] = useState<Array<AttendanceHours>>(
    getHours()
  )

  useEffect(() => {
    if (initialRuns.current < 1) {
      initialRuns.current++
      return
    }
    const attendanceDetail: any = {
      date,
      rest_minits_flag: restTime?.is_rest,
      rest_hours: restTime?.rest_hours,
      attendance_type: shiftHours[0].attendanceType,
      isComplete: true,
    }

    if (
      attendanceDetail.rest_minits_flag == 1 &&
      !attendanceDetail.rest_hours
    ) {
      attendanceDetail.isComplete = false
    }

    shiftHours.map((shiftHour) => {
      const namePostfix = shiftHour?.id != 1 ? shiftHour?.id : ""
      attendanceDetail[`attendance_type${namePostfix}`] =
        shiftHour.attendanceType
      attendanceDetail[`attendance_start_time${namePostfix}`] =
        shiftHour.attendanceType == 1
          ? (shiftHour?.from?.hours ?? "00") +
            ":" +
            (shiftHour?.from?.minutes ?? "00")
          : null
      attendanceDetail[`attendance_end_time${namePostfix}`] =
        shiftHour.attendanceType == 1
          ? (shiftHour?.to?.hours ?? "00") +
            ":" +
            (shiftHour?.to?.minutes ?? "00")
          : null
      attendanceDetail[`attendance_rest_minits${namePostfix}`] =
        shiftHour.attendanceType == 1 ? +shiftHour.break_time : null

      // check validity
      if (attendanceDetail.isComplete) {
        if (shiftHour.attendanceType == 1) {
          if (
            !shiftHour?.from?.hours ||
            !shiftHour?.from?.minutes ||
            !shiftHour?.to?.hours ||
            !shiftHour?.to?.minutes
          ) {
            attendanceDetail.isComplete = false
          } else {
            const startInMinutes =
              +shiftHour?.from?.hours * 60 + +shiftHour?.from?.minutes
            const endInMinutes =
              +shiftHour?.to?.hours * 60 + +shiftHour?.to?.minutes
            const breakTime = +shiftHour?.break_time
            if (endInMinutes <= startInMinutes + breakTime) {
              attendanceDetail.isComplete = false
            }
          }
        }
      }
    })

    if (dayDetail?.updateId) {
      attendanceDetail.id = dayDetail.updateId
      if (Array.isArray(shiftHours) && !shiftHours[0]?.attendanceType)
        attendanceDetail.is_deleted = true
      else attendanceDetail.is_deleted = false
    }
    onChange(attendanceDetail)
  }, [restTime, shiftHours])

  const onTimeRangeChange = ({ id, range, unit, value }: any) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    if (range == "break_time") {
      data[index] = {
        ...data[index],
        [range]: value,
      }
    } else {
      data[index] = {
        ...data[index],
        [range]: {
          ...data[index][range],
          [unit]: value,
        },
      }
    }
    setShiftHours(data)
  }

  const addNewSchedule = () => {
    setShiftHours([
      ...shiftHours,
      { id: shiftHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastSchedule = () => {
    const data = shiftHours?.splice(-1)[0]
    setShiftHours(shiftHours?.filter((v) => v.id !== data.id))
  }

  const ScheduleButton = ({ type }: IScheduleButton) => {
    const { t } = useTranslation()
    return (
      <div className={"schedule-button"}>
        <Button
          text={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          shape={"circle"}
          borderColor={theme.gray2}
          backgroundColor={"#ffffff"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewSchedule() : removeLastSchedule()
          }}
        />
      </div>
    )
  }

  const changeAttendanceType = (id, attendanceType) => {
    const data = [...shiftHours]
    const index = data?.findIndex((x) => x?.id === id)
    data[index] = {
      ...data[index],
      attendanceType,
    }
    setShiftHours(data)
  }

  const resetAttendance = () => {
    setShiftHours([
      {
        id: 1,
        ...initialValues,
      },
    ])
  }

  return (
    <Wrapper id={`${date}`}>
      <div className={"break-time-container"}>
        <Checkbox
          name={"break-time"}
          label={t("Break time")}
          checked={restTime.is_rest == 1}
          onClick={() => {
            setRestTime({ ...restTime, is_rest: !restTime.is_rest ? 1 : 0 })
          }}
        />
        <div className={"break-input-container"}>
          <Input
            type={"number"}
            min={0}
            width={10}
            placeholder={t("Eg: 8")}
            value={restTime.rest_hours}
            onChange={(e) => {
              setRestTime({
                ...restTime,
                rest_hours: e.target.value ? +e.target.value : 0,
              })
            }}
            disabled={restTime.is_rest != 1}
          />
          {t("Hrs")}
        </div>
      </div>
      <div className={"schedule-container"}>
        {shiftHours.map((range, index) => (
          <div key={index} className={"inner-schedule-container"}>
            <div className={"schedule-title"}>{`${t("Working hours")} ${
              range?.id
            }`}</div>
            {range.attendanceType == 1 ? (
              <>
                <div
                  className={"attendance-reset"}
                  onClick={() => {
                    range?.id == 1 && resetAttendance()
                  }}
                >
                  {t("Attendance at work")}
                </div>
                <AttendanceScheduleTimeRangeInput
                  key={range?.id}
                  range={range}
                  rangeName={""}
                  handleChange={(val) => {
                    onTimeRangeChange(val)
                  }}
                />
              </>
            ) : range.attendanceType == 2 ? (
              <AbsentWrapper
                onClick={() => {
                  range?.id == 1 && resetAttendance()
                }}
              >
                {t("Absence")}
              </AbsentWrapper>
            ) : (
              <AttendanceTypeButtons
                onChange={(val) => {
                  changeAttendanceType(range?.id, val)
                }}
              />
            )}
          </div>
        ))}
        {shiftHours.length > 0 && shiftHours[0].attendanceType == 1 && (
          <ScheduleButton
            type={
              shiftHours.length == possibleScheduleIds.length ? "remove" : "add"
            }
          />
        )}
      </div>
      <style>
        {`.attendance-record-date-with-error {
                border: 2px solid #FF9494;
                z-index: 10;
              }`}
      </style>
    </Wrapper>
  )
}
