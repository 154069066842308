import { t } from "i18next"
import moment from "moment"
import * as yup from "yup"
import { getDateTimeStringFromDate } from "../../../utils"

export const validationSchemaForChildRecipientForm = yup.object().shape({
  child_id: yup.number().required(),
  service_type: yup.number().required().min(1),
  medical_judgement_score: yup.number().nullable(),
  medical_care_child_type: yup.number().nullable(),
  medical_care_child_flg: yup.boolean(),
  receiving_certificate_start_date: yup.string().required(),
  provision_city_id: yup.string().nullable(),
  handicapped_child_type: yup
    .number()
    .typeError(t("Required"))
    .required(t("Required")),
  maximum_manage_addition_flg: yup.boolean(),
  maximum_manage_company_type: yup.number(),
  facility_id: yup.number().nullable(),
  facility_other_id: yup.number().nullable(),
  multi_child_reduction_type: yup.number().nullable(),
  remarks: yup.array().nullable(),
  benifit_payment_amount: yup.number().nullable(),
  benefit_start_date: yup.date().nullable(),
  benefit_end_date: yup.date().nullable(),
  free_of_charge_start_date: yup.date().nullable(),
  free_of_charge_end_date: yup.date().nullable(),
  provision_start_date: yup.date().nullable(),
  provision_end_date: yup.date().nullable(),
  change_beneficiary_certificate_number: yup.boolean(),
  receiving_certificate_number: yup
    .string()
    .when("change_beneficiary_certificate_number", {
      is: false,
      then: yup
        .string()
        .matches(/^[0-9]\d*$/, t("Recipient Certificate Number must be number"))
        .required(),
      otherwise: yup.string().nullable(),
    }),
  changed_recieving_certificate_number: yup
    .string()
    .when("change_beneficiary_certificate_number", {
      is: true,
      then: yup.string().required(t("Required")),
      otherwise: yup.string().nullable(),
    }),
  burden_max_month: yup.number().nullable(),
  terminate_payment_contract: yup.boolean(),
})

export const generateInitialValueForChildRecipientForm = (data?: any) => {
  return {
    provision_city_id: data?.provision_city?.id || null,
    joint_company: data?.joint_company || [],
    child_id: data?.child_id || 0,
    facility_id: data?.facility_id,
    facility_other_id: data?.facility_other?.id || null,
    service_type: data?.service_type || 1,
    medical_judgement_score: data?.medical_judgment_score || null,
    consultation_support_id: data?.consultation_support?.id || null,
    handicapped_child_type: data?.handicapped_child_type || 1,
    consultation_support_specialist:
      data?.consultation_support_specialist || "",
    medical_care_child_type: data?.medical_care_child_type || null,
    medical_care_child_flg: data?.medical_care_child_flg || false,
    burden_max_month: data?.burden_max_month || 0,
    local_burden_max_month: data?.local_burden_max_month || 0,
    local_burden_max_month_flg: data?.local_burden_max_month_flg || false,
    upper_limit_management_company:
      data?.upper_limit_management_company || "none",
    maximum_manage_addition_flg: data?.maximum_manage_addition_flg || false,
    maximum_manage_addition_flg_display: 1,
    maximum_manage_company_type: data?.maximum_manage_company_type ?? 0,
    multi_child_reduction_type: data?.multi_child_reduction_type || undefined,
    special_support_addition_flg: data?.special_support_addition_flg || false,
    receiving_certificate_number: data?.receiving_certificate_number || "0",
    receiving_certificate_start_date: data?.receiving_certificate_start_date
      ? moment(data?.receiving_certificate_start_date)
      : null,
    remarks: [
      data?.remarks_flg1 && "1",
      data?.remarks_flg2 && "2",
      data?.remarks_flg3 && "3",
      data?.remarks_flg4 && "4",
      data?.remarks_flg5 && "5",
      data?.remarks_flg6 && "6",
    ],
    benefit_start_date: data?.benefit_start_date
      ? moment(data?.benefit_start_date)
      : null,
    benefit_end_date: data?.benefit_end_date
      ? moment(data?.benefit_end_date)
      : null,
    benefit_days: data?.benefit_days || 0,
    free_of_charge_end_date: data?.free_of_charge_end_date
      ? moment(data?.free_of_charge_end_date)
      : null,
    free_of_charge_start_date: data?.free_of_charge_start_date
      ? moment(data?.free_of_charge_start_date)
      : null,
    provision_start_date: data?.provision_start_date
      ? moment(data?.provision_start_date)
      : null,
    provision_end_date: data?.provision_end_date
      ? moment(data?.provision_end_date)
      : null,
    change_beneficiary_certificate_number:
      data?.change_beneficiary_certificate_number || false,
    birthday: data?.child?.birthday || null,

    //contract payment.
    terminate_contract: false,
    contractPaymentFacilityData: [],
    contractStartingMonth: null,
    contractStartingYear: null,
    contractPaymentData: [
      {
        id: 1,
        benefit_payment_amount: 0,
        total_contract_payout: 0,
        own_facility_contract_supply_amount: 0,
        joint_facility_contract_payment_amount: 0,
      },
    ],
    contract_data: [],
    joint_facility_data: [],
    jointFacilityStartYear: null,
    jointFacilityStartMonth: null,
    jointFacilityData: [
      {
        id: 1,
        benefit_payment_amount: 23,
        total_contract_amount: 23,
        own_facility_contract_supply_amount: 23,
        amount_provided_under_contracts_with_other_companies: 0,
      },
    ],
    allJointFacilities: [],
    apply_start_date: data?.apply_start_date
      ? moment(data?.apply_start_date)
      : null,
    apply_end_date: data?.apply_end_date ? moment(data?.apply_end_date) : null,
    is_benefit_days_in_month: data?.is_benefit_days_in_month
      ? -1
      : data?.benefit_days === 0
      ? `0`
      : data?.benefit_days,
  }
}

export const generateRequestBodyForChildRecipientForm = (data) => {
  const benefit_days = +(data?.is_benefit_days_in_month < 0
    ? data?.burden_amount || data?.benefit_days
    : data?.is_benefit_days_in_month)

  const body = {
    ...data,
    remarks_flg1: data?.remarks?.includes("1"),
    remarks_flg2: data?.remarks?.includes("2"),
    remarks_flg3: data?.remarks?.includes("3"),
    remarks_flg4: data?.remarks?.includes("4"),
    remarks_flg5: data?.remarks?.includes("5"),
    remarks_flg6: data?.remarks?.includes("6"),
    receiving_certificate_start_date:
      data?.receiving_certificate_start_date != null &&
      typeof data?.receiving_certificate_start_date === "object"
        ? data?.receiving_certificate_start_date?.format("YYYY-MM-DDThh:mm:ssZ")
        : data?.receiving_certificate_start_date,
    free_of_charge_start_date:
      data?.free_of_charge_start_date != null &&
      typeof data?.free_of_charge_start_date === "object"
        ? getDateTimeStringFromDate(data?.free_of_charge_start_date)
        : data?.free_of_charge_start_date,
    free_of_charge_end_date:
      data?.free_of_charge_end_date != null &&
      typeof data?.free_of_charge_end_date === "object"
        ? getDateTimeStringFromDate(data?.free_of_charge_end_date)
        : data?.free_of_charge_end_date,
    apply_start_date:
      data?.apply_start_date != null &&
      typeof data?.apply_start_date === "object"
        ? getDateTimeStringFromDate(data?.apply_start_date)
        : data?.apply_start_date,
    apply_end_date:
      data?.apply_end_date != null && typeof data?.apply_end_date === "object"
        ? getDateTimeStringFromDate(data?.apply_end_date)
        : data?.apply_end_date,
    provision_start_date:
      data?.provision_start_date != null &&
      typeof data?.provision_start_date === "object"
        ? getDateTimeStringFromDate(data?.provision_start_date)
        : data?.provision_start_date,
    provision_end_date:
      data?.provision_end_date != null &&
      typeof data?.provision_end_date === "object"
        ? getDateTimeStringFromDate(data?.provision_end_date)
        : data?.provision_end_date,
    benefit_start_date:
      data?.benefit_start_date != null &&
      typeof data?.benefit_start_date === "object"
        ? getDateTimeStringFromDate(data?.benefit_start_date)
        : data?.benefit_start_date,
    benefit_end_date:
      data?.benefit_end_date != null &&
      typeof data?.benefit_end_date === "object"
        ? getDateTimeStringFromDate(data?.benefit_end_date)
        : data?.benefit_end_date,
    receiving_certificate_number:
      data?.changed_recieving_certificate_number?.toString() ||
      data?.receiving_certificate_number + "",
    medical_judgement_score: data?.medical_judgement_score
      ? parseInt(data.medical_judgement_score)
      : null,
    facility_id:
      data?.maximum_manage_company_type != 1 ? null : data?.facility_id,
    burden_max_month: data?.burden_max_month
      ? Number(data?.burden_max_month)
      : 0,
    local_burden_max_month: data?.local_burden_max_month
      ? Number(data.local_burden_max_month)
      : 0,
    local_burden_max_month_flg: Boolean(data?.local_burden_max_month_flg),
    benefit_days: benefit_days === -0.1 ? 0 : benefit_days,
    is_benefit_days_in_month: data?.is_benefit_days_in_month === -1,
  }

  delete body.remarks
  delete body.change_beneficiary_certificate_number
  delete body.allJointFacilities
  delete body.contract_data
  delete body.contractPaymentFacilityData
  delete body.contractPaymentData
  delete body.joint_company
  delete body.joint_facility_data
  delete body.jointFacilityData

  return body
}

export const getClearedValueForChildRecipientForm = () => {
  return {
    joint_company: [],
    service_type: null,
    medical_judgement_score: null,
    consultation_support_id: null,
    consultation_support_specialist: null,
    medical_care_child_type: null,
    medical_care_child_flg: false,
    upper_limit_management_company: "none",
    maximum_manage_addition_flg: false,
    maximum_manage_company_type: undefined,
    facility_id: null,
    facility_other_id: null,
    multi_child_reduction_type: null,
    receiving_certificate_start_date: null,
    remarks: [],
    benefit_start_date: null,
    benefit_end_date: null,
    benefit_payment_amount: null,
    free_of_charge_end_date: null,
    free_of_charge_start_date: null,
    provision_start_date: null,
    provision_end_date: null,
    change_beneficiary_certificate_number: false,
    changed_recieving_certificate_number: null,
    special_support_addition_flg: false,
    burden_max_month: null,
    handicapped_child_type: null,
    contract_data: [],
    joint_facility_data: [],
    provision_city_id: null,
    benefit_days: null,
    apply_start_date: null,
    apply_end_date: null,
  }
}

export const getContractInfo = (data) => {
  const {
    contractPaymentData,
    contractStartingYear,
    contractStartingMonth,
    contractPaymentFacilityData,
    terminate_contract,
  } = data
  return {
    contractPaymentData,
    contractStartingYear: contractStartingYear
      ? getDateTimeStringFromDate(contractStartingYear)
      : null,
    contractStartingMonth: contractStartingMonth
      ? getDateTimeStringFromDate(contractStartingMonth)
      : null,
    contractPaymentFacilityData,
    terminate_contract,
  }
}
