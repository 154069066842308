import { useRouter } from "next/router"
import { useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
import { useTodaySituationBulkPrintContext } from "../../../../context/TodaySituationBulkPrintContext"

import styled from "styled-components"
import { OwnerButton } from "../../../atoms"

import { Pagination, SelectInput, theme } from "@project/shared"
import { Typography } from "antd"
import _ from "lodash"
import moment from "moment"
import { useUpdateSearchParams } from "../../../../utils/useUpdateSearchParams"

const StyledWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .main__content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .action__container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .btns__container {
      display: flex;
      column-gap: 16px;
      flex-wrap: wrap;
    }
    #usr__print_pagination {
      display: flex;
      column-gap: 8px;
      align-items: center;
      flex-wrap: wrap;
      .pagination {
        margin: 0 !important;
      }
      .wrapper {
        margin-left: unset !important;
      }
    }
  }
`
const ContentWrapper = styled.div`
  margin-bottom: 4rem;
  @media print {
    padding: 0 16px;
  }
  .table__top {
    margin-bottom: 8px;
    &--title {
      text-align: center;
      font-weight: bold;
    }
    &--service {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      font-weight: bold;
    }
  }
  table#today__situation__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse !important;
    thead {
      tr {
        th {
          text-align: center !important;
          background: ${theme.gray2};
          padding: 4px;
          border-top: 2px solid #000000;
          border-left: 2px solid #000000;
          border-right: none !important;
          border-bottom: 2px solid #000000 !important;
          outline: none !important;
          &:last-child {
            border-right: 2px solid #000000 !important;
          }
        }
        th.width-90 {
          width: 90px !important;
        }
        th.width-133 {
          width: 133px !important;
        }
        th.width-70 {
          width: 70px !important;
        }
        th.width-150 {
          width: 150px !important;
        }
        th.width-100 {
          width: 100px !important;
        }
        th.width-125 {
          width: 125px !important;
        }
        th.width-25-p {
          width: 25%;
        }
      }
    }

    tbody tr {
      td {
        text-align: center;
        border-bottom: 2px solid #000000;
        border-left: 2px solid #000000;
        border-right: none !important;
        border-top: none !important;
        &:last-child {
          border-right: 2px solid #000000 !important;
        }
      }
    }
  }
`
export const UserServiceProvisionResultTodaySituationPrintPreview = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const dataRef = useRef(null)
  const {
    printData,
    selectedChilds,
    displayParams,
    total,
    pageData,
    handlePagination,
  } = useTodaySituationBulkPrintContext()

  const handlePrint = useReactToPrint({
    content: () => dataRef?.current,
  })

  let finalData = printData?.data || []
  useMemo(() => {
    const lastIndex = pageData?.page * pageData?.pageSize
    const firstIndex = lastIndex - pageData?.pageSize
    const currentData = printData?.data?.slice(firstIndex, lastIndex)
    finalData = currentData
  }, [pageData?.page, pageData?.pageSize])

  const renderContent = () => {
    return (
      <>
        {finalData
          ?.filter((data) => selectedChilds?.includes(data?.child_id))
          .map((child, index) => (
            <ContentWrapper key={index}>
              <div className={"table__top"}>
                <div className={"table__top--title"}>
                  {t("Today's situation")}
                </div>
                <div className={"table__top--service"}>
                  <div className={"service-container"}>
                    {t("Service used")}
                    {":"}{" "}
                    {child?.service == 1
                      ? t("After school day service")
                      : t("Child development support")}
                  </div>
                  <div className={"user-container"}>
                    {t(" Username")} {" (　"} {child?.child_name} {"　)"}
                  </div>
                </div>
              </div>
              <table id={"today__situation__table"}>
                <thead>
                  <tr>
                    <th
                      className={"width-133"}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("Date")}
                    </th>
                    <th className={"width-150"}>{t("Facility")}</th>
                    <th>{t("Today's situation")}</th>
                    {displayParams?.parents_comment == 1 && (
                      <th className={"width-25-p"}>
                        {t("Comments from parents")}
                      </th>
                    )}

                    <th className={"width-125"}>{t("Recorder's name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.orderBy(
                    child?.child_service_details?.filter(
                      (child_service_detail) =>
                        child_service_detail?.comment.length > 0
                    ),
                    ["date"],
                    ["asc"]
                  ).map((row, index) => (
                    <tr id={"today__situation__table--tr"} key={index}>
                      <td>
                        {moment(row?.date).isValid()
                          ? moment(row?.date).format("YYYY年MM月DD日")
                          : moment().format("YYYY年MM月DD日")}
                      </td>
                      <td>{row?.facility_name}</td>
                      <td style={{ overflowWrap: "break-word" }}>
                        {row?.comment}
                      </td>
                      {displayParams?.parents_comment == 1 && (
                        <td>{row?.parent_comment}</td>
                      )}
                      <td>{row?.staff_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContentWrapper>
          ))}
      </>
    )
  }

  return printData?.data?.length ? (
    <StyledWrapper>
      <div className={"action__container"}>
        <div className={"btns__container"}>
          <OwnerButton
            text={t("Cancel")}
            shape={"round"}
            typeOf={"secondary"}
            onClick={() => {
              updateParams(
                {
                  ...router?.query,
                  mode: null,
                },
                router?.pathname
              )
            }}
          />
          <OwnerButton
            icon={"print"}
            text={t("Print (PDF Output)")}
            shape={"round"}
            onClick={() => handlePrint()}
          />
        </div>
        <div id={"usr__print_pagination"}>
          <Pagination
            pageSize={pageData?.pageSize}
            current={pageData?.page}
            total={total}
            onChange={(val) => handlePagination("P", val)}
            showSizeChanger={false}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography.Text>{t("Displayed results:")}</Typography.Text>
            <SelectInput
              height={40}
              value={pageData?.pageSize}
              options={[
                {
                  value: 20,
                  label: "20",
                },
                {
                  value: 40,
                  label: "40",
                },
                {
                  value: 80,
                  label: "80",
                },
                {
                  value: 100,
                  label: "100",
                },
              ]}
              onChange={(val) => handlePagination("S", val)}
            />
          </div>
        </div>
      </div>

      <div ref={dataRef} className={"main__content"}>
        {renderContent()}
      </div>
    </StyledWrapper>
  ) : null
}
