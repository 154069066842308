import { API } from "@project/shared"

export const fetchActualCostBurdenUserRecord = async (
  burden_record_id: number,
  date: string,
  facility_id: number
) => {
  return API.get(
    `/actual-cost-burden-records/user-record/${burden_record_id}?date=${date}&facility_ids=${facility_id}`
  )
}

export const saveActualCostBurdenRecord = async (values: any) => {
  return API.post(`/actual-cost-burden-records`, values)
}

export const editActualCostBurdenRecord = async ({ id, ...values }) => {
  return API.put(`/actual-cost-burden-records/${id}`, values)
}

export const fetchActualCostBurdenRecord = async (burden_record_id: number) => {
  return API.get(`/actual-cost-burden-records/${burden_record_id}`)
}

export const bulkUpdateActualCostBurdenUserRecord = async (values) => {
  return API.post(`/actual-cost-burden-records/users/bulk-edit`, values)
}

export const ActualCostBurdenMonthlyRecord = async (
  year: number,
  month: number,
  facility_id: number,
  fetchAll = false
) => {
  return API.get(
    `/actual-cost-burden-records?year=${year}&month=${month}&facilityIds=${facility_id}${
      fetchAll && "&pageSize=Infinity"
    }`
  )
}

export const fetchMonthlyActualCostBurdenRecord = async ({
  page,
  facility_id,
  year,
  month,
  day,
}) =>
  API.get(
    `/actual-cost-burden-records?facilityIds=${facility_id}&date=${year}-${month}-${day}&page=${page}`
  )

export const bulkdeleteActualCostBurdenUserRecord = async (values) => {
  return API.post(`/actual-cost-burden-records/bulk-delete-users`, values)
}

export const getActualCostBurdenUserRecord = async (params) => {
  return API.get(`/actual-cost-burden-records/children`, {
    params,
  })
}
