import { Checkbox, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import moment from "moment"
import { RecordRewriteChildConfirmationTableProps } from "../../organisms/UsageRecordRewriteFormPage/types"
import { getDayOfWeek } from "../../../utils"

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  table {
    width: 100%;
    td {
      border: 1px solid ${theme.gray2};
      padding: 16px 10px;
      &:first-child {
        background: ${theme.bgColor2};
      }
      .multiple-checkbox {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
      }
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      tr {
        display: flex;
        flex-direction: column;
        td {
          width: 100%;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    cursor: default;
    .ant-checkbox {
      pointer-events: none;
      cursor: none;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
  }
`
export const RecordRewriteChildConfirmationTable = ({
  confirmationData,
}: RecordRewriteChildConfirmationTableProps) => {
  const { t } = useTranslation()
  const CHECKBOX_OPTIONS = [
    { label: t("Saturdays and Sundays will be school holidays"), value: 1 },
    { label: t("Make public holidays school holidays"), value: 2 },
  ]

  const getDateLabel = (dateString: string) => {
    const momentDate = moment(dateString)

    return momentDate.format("D日") + `(${getDayOfWeek(momentDate.day())})`
  }

  return (
    <Wrapper>
      <table>
        <tr>
          <td>{t("Child's name (furigana)")}</td>
          <td>{confirmationData?.child_name}</td>
        </tr>
        <tr>
          <td>{t("Temporary school closure period")}</td>
          <td>
            {confirmationData?.startDate && confirmationData?.endDate ? (
              <>
                {moment(confirmationData?.startDate).format("YYYY年MM月DD日")}{" "}
                {" ~ "}
                {moment(confirmationData?.endDate).format("YYYY年MM月DD日")}
                <div className={"multiple-checkbox"}>
                  {confirmationData?.holidayOption?.includes(1) && (
                    <Checkbox
                      label={CHECKBOX_OPTIONS[0].label}
                      value={1}
                      checked={true}
                    />
                  )}
                  {confirmationData?.holidayOption?.includes(2) && (
                    <Checkbox
                      label={CHECKBOX_OPTIONS[1].label}
                      value={2}
                      checked={true}
                    />
                  )}
                </div>
                <div className={"multiple-checkbox"}>
                  {confirmationData?.temporaryClosurePeriodDates?.map(
                    (date) => (
                      <Checkbox
                        key={date}
                        checked={true}
                        label={getDateLabel(date)}
                      />
                    )
                  )}
                </div>
                {confirmationData?.closureDates?.length > 0 && (
                  <div className={"multiple-checkbox"}>
                    {confirmationData?.closureDates?.map((date) => (
                      <Checkbox
                        key={date}
                        checked={true}
                        label={getDateLabel(date)}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              t("No setting")
            )}
          </td>
        </tr>
        <tr>
          <td>{t("Days of use increased due to temporary school closures")}</td>
          <td>
            {confirmationData?.daysOfIncreasedUse?.length > 0 ? (
              <div className={"multiple-checkbox"}>
                {confirmationData?.daysOfIncreasedUse?.map((date) => (
                  <Checkbox
                    key={date}
                    checked={true}
                    label={getDateLabel(date)}
                  />
                ))}
              </div>
            ) : (
              t("No setting")
            )}
          </td>
        </tr>
        <tr>
          <td>
            {t("Extension support addition due to temporary school closure")}
          </td>
          <td>
            {confirmationData?.extendSupportDays?.length > 0 ? (
              <div className={"multiple-checkbox"}>
                {confirmationData?.extendSupportDays?.map((date) => (
                  <Checkbox
                    key={date}
                    checked={true}
                    label={getDateLabel(date)}
                  />
                ))}
              </div>
            ) : (
              t("No setting")
            )}
          </td>
        </tr>
      </table>
    </Wrapper>
  )
}
