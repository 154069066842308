import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Typography } from "antd"
import { RightOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import { useRouter } from "next/router"
import {
  Checkbox,
  Checkboxes,
  PageCard,
  SelectInput,
  getLocalstorageItem,
  setItemToLocalstorage,
} from "@project/shared"
import { Content, Wrapper } from "./IndividualSupportPlansFilter.styles"
import { DatePicker } from "../../molecules"
import {
  getAllChildUnpaginatedAllContract,
  IndividualPlanSearchParams,
} from "../../../services"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { AuthContext } from "../../../utils"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

interface IProps {
  handleSearch: (values: IndividualPlanSearchParams) => void
  handleScroll?: () => void
  planStatus: "list" | "draft"
  addLink: string
  hasAddAccess?: boolean
}

const DATE_FORMAT = "YYYY-MM-DD"
export const IndividualSupportPlansFilter: React.FC<IProps> = ({
  handleSearch,
  planStatus,
  addLink,
  handleScroll,
  hasAddAccess = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const storedData = getLocalstorageItem(
    planStatus === "draft"
      ? "individual-support-draft-plans"
      : "individual-support-plans",
    "facility_ids"
  )
  const {
    child_id,
    service,
    display_settings,
    facility: urlFacility,
    status: urlStatus,
    date,
    expiry_date,
  }: any = router.query

  const urlDate = date?.split("_")
  const urlExpiry = expiry_date?.split("_")
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useContext(AuthContext)
  const [sortOption, setSortOption] = useState(0)
  const [tempChild, setTempChild] = useState(+child_id || null)
  const [serviceType, setServiceType] = useState(service?.split(",") || [])
  const [facility, setFacility] = useState(
    urlFacility?.split(",")?.map((val) => parseInt(val, 10)) ||
      storedData
        ?.split(",")
        ?.map((v) => +v)
        ?.filter((f) => !isNaN(f) && f) ||
      []
  )

  const [createdDate, setCreatedDate] = useState({
    from: urlDate && urlDate[0] ? moment(urlDate[0]).format(DATE_FORMAT) : null,
    to: urlDate && urlDate[1] ? moment(urlDate[1]).format(DATE_FORMAT) : null,
  })
  const [expiryDate, setExpiryDate] = useState({
    from:
      urlExpiry && urlExpiry[0]
        ? moment(urlExpiry[0]).format(DATE_FORMAT)
        : null,
    to:
      urlExpiry && urlExpiry[1]
        ? moment(urlExpiry[1]).format(DATE_FORMAT)
        : null,
  })
  const [disDisplaySettings, setDisplaySettings] = useState(
    display_settings?.split(",") || []
  )
  const [status, setStatus] = useState(urlStatus?.split(",") || [])
  const { data: children } = useQuery<any>(
    ["fetch-child-data-only"],
    getAllChildUnpaginatedAllContract,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 2 * (60 * 1000), // 2mins
      select: ({ data }) => {
        const child = data.map((d) => ({
          label: d.child_name,
          value: d.id,
          furigana: d.child_name_furigana,
        }))
        return child?.length > 0
          ? [{ label: "---", value: null, furigana: null }, ...child]
          : []
      },
    }
  )

  const URL =
    planStatus === "draft"
      ? `/individual-support-draft-plans`
      : `/individual-support-plans`
  const onFacilityChecked = (e) => {
    const { value, checked } = e.target
    const updatedFacility = checked
      ? [...facility, value]
      : facility.filter((item) => item !== value)
    setFacility(updatedFacility)
  }

  const onSearch = () => {
    const values = {
      child_id: tempChild,
      service: serviceType?.toString(),
      facility: facility?.toString(),
      date: createdDate?.from
        ? `${createdDate?.from}_${
            createdDate?.to ||
            moment(createdDate?.from).add(1, "M").format(DATE_FORMAT)
          }`
        : "",
      expiry_date: expiryDate?.from
        ? `${expiryDate?.from}_${
            expiryDate?.to ||
            moment(expiryDate?.from).add(1, "M").format(DATE_FORMAT)
          }`
        : "",
      display_settings: disDisplaySettings?.toString(),
      status: status?.toString(),
    }
    setItemToLocalstorage(
      planStatus === "draft"
        ? "individual-support-draft-plans"
        : "individual-support-plans",
      {
        facility_ids: facility?.toString(),
      }
    )
    handleSearch(values)
    updateParams(values, URL)
    handleScroll && handleScroll()
  }
  const onResetSearch = () => {
    setTempChild(null)
    setServiceType([])
    setFacility([])
    setCreatedDate({
      from: null,
      to: null,
    })
    setExpiryDate({
      from: null,
      to: null,
    })
    setDisplaySettings([])
    setStatus([])
    setServiceType([])
    setItemToLocalstorage(
      planStatus === "draft"
        ? "individual-support-draft-plans"
        : "individual-support-plans",
      {
        facility_ids: "",
      }
    )
    handleSearch({
      child_id: null,
      service: "",
      facility: "",
      date: "",
      expiry_date: "",
      display_settings: "",
      status: "",
    })
    updateParams(
      {
        page: 1,
      },
      URL
    )
    handleScroll && handleScroll()
  }

  return (
    <Wrapper>
      <PageCard title={t("Operation options")}>
        <Content>
          <div className={"title content__child"}>
            <Typography.Title level={5}>{t("Narrow down")}</Typography.Title>
          </div>
          <div className={"child__picker content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Child name")}
              </Typography.Paragraph>
            </div>
            <div className={"child__picker--input-group"}>
              <div className={"child__picker--input-group__furigana"}>
                <SelectInput
                  options={FuriganaAlphabetsOption}
                  height={40}
                  value={sortOption}
                  onChange={(val) => {
                    setSortOption(val)
                    if (
                      !children
                        .filter((data) => data.value === tempChild)?.[0]
                        ?.label.startsWith(sortOption)
                    ) {
                      setTempChild(null)
                    }
                  }}
                  placeholder={"---"}
                />
              </div>
              <div className={"child__picker--input-group__name"}>
                <SelectInput
                  height={40}
                  dropdownMatchSelectWidth={false}
                  options={
                    sortOption
                      ? children?.filter((child) => {
                          return child.furigana?.startsWith(sortOption)
                        })
                      : children || []
                  }
                  onChange={setTempChild}
                  value={tempChild}
                  placeholder={t("All")}
                />
              </div>
            </div>
          </div>
          {planStatus === "list" ? (
            <div className={"use__service content__child"}>
              <div className={"label"}>
                <Typography.Paragraph>
                  <RightOutlined className={"icon"} />
                  {t("Use Service")}
                </Typography.Paragraph>
              </div>
              <div className={"use__service--checkbox"}>
                <Checkboxes
                  options={[
                    {
                      value: "1",
                      label: t("After school day service"),
                    },
                    {
                      value: "2",
                      label: t("Child development support"),
                    },
                  ]}
                  value={serviceType}
                  onChange={setServiceType}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"facility content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Facility")}
              </Typography.Paragraph>
            </div>
            <div className={"facility__checkbox"}>
              <div className={"check_uncheck_all"}>
                <Button
                  icon={<img src={"/assets/icons/check.svg"} alt={"icon"} />}
                  shape={"round"}
                  onClick={() => {
                    setFacility(facilities?.map((li) => li.id))
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Check all")}
                  </Typography.Text>
                </Button>
                <Button
                  shape={"round"}
                  onClick={() => {
                    setFacility([])
                  }}
                >
                  <Typography.Text className={"span"}>
                    {t("Uncheck all")}{" "}
                  </Typography.Text>
                </Button>
              </div>
              <div className={"checkboxs"}>
                {facilities?.map((faci, i) => (
                  <Checkbox
                    key={i}
                    onChange={onFacilityChecked}
                    checked={facility?.includes(faci?.id)}
                    value={faci?.id || ""}
                    label={faci?.facility_name_short}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={"date date--created content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Created date")}
              </Typography.Paragraph>
            </div>
            <div className={"date__picker"}>
              <DatePicker
                placeholder={""}
                date={
                  moment(createdDate?.from).isValid()
                    ? moment(createdDate?.from)
                    : null
                }
                onDateChange={(val) => {
                  setCreatedDate({
                    ...createdDate,
                    from: val ? moment(val)?.format(DATE_FORMAT) : null,
                  })
                }}
                style={{ height: "40px", width: "150px" }}
                format={"YYYY年MM月DD日"}
              />
              <b>{"〜"}</b>
              <DatePicker
                placeholder={""}
                date={
                  moment(createdDate?.to).isValid()
                    ? moment(createdDate?.to)
                    : null
                }
                onDateChange={(val) => {
                  setCreatedDate({
                    ...createdDate,
                    to: moment(val)?.format(DATE_FORMAT),
                  })
                }}
                style={{ height: "40px", width: "150px" }}
                format={"YYYY年MM月DD日"}
              />
            </div>
          </div>

          <div className={"date date--expiry content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Expiry date")}
              </Typography.Paragraph>
            </div>
            <div className={"date__picker"}>
              <DatePicker
                placeholder={""}
                date={
                  moment(expiryDate?.from).isValid()
                    ? moment(expiryDate?.from)
                    : null
                }
                style={{ height: "40px", width: "150px" }}
                onDateChange={(val) => {
                  setExpiryDate({
                    ...expiryDate,
                    from: moment(val)?.format(DATE_FORMAT),
                  })
                }}
                format={"YYYY年MM月DD日"}
              />
              <b>{"〜"}</b>
              <DatePicker
                placeholder={""}
                date={
                  moment(expiryDate?.to).isValid()
                    ? moment(expiryDate?.to)
                    : null
                }
                style={{ height: "40px", width: "150px" }}
                onDateChange={(val) => {
                  setExpiryDate({
                    ...expiryDate,
                    to: moment(val)?.format(DATE_FORMAT),
                  })
                }}
                format={"YYYY年MM月DD日"}
              />
            </div>
          </div>

          <div className={"display__settings content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Display setting")}
              </Typography.Paragraph>
            </div>
            <div className={"status--box"}>
              <Checkboxes
                options={[
                  {
                    value: "1",
                    label: t("Only display alternative assistance"),
                  },
                  {
                    value: "2",
                    label: t("Show only with digital signature blank"),
                  },
                ]}
                value={disDisplaySettings}
                onChange={setDisplaySettings}
              />
            </div>
          </div>

          <div className={"status content__child"}>
            <div className={"label"}>
              <Typography.Paragraph>
                <RightOutlined className={"icon"} />
                {t("Create status")}
              </Typography.Paragraph>
            </div>
            <div className={"display__settings--box"}>
              <Checkboxes
                options={[
                  {
                    value: "0",
                    label: t("Created"),
                  },
                  {
                    value: "1",
                    label: t("Saved as draft"),
                  },
                ]}
                value={status}
                onChange={setStatus}
              />
            </div>
          </div>

          <div className={"filter__cta content__child"}>
            <OwnerButton
              text={t("Search")}
              icon={"search"}
              shape={"circle"}
              onClick={onSearch}
              className={"search_btn"}
            />
            <OwnerButton
              text={t("Reset search")}
              shape={"circle"}
              typeOf={"secondary"}
              onClick={onResetSearch}
              className={"reset_search_btn"}
            />
          </div>

          <div className={"add__cta content__child"}>
            <div className={"title"}>
              <Typography.Title level={5}>{t("Add data")}</Typography.Title>
            </div>
            <div>
              <OwnerButton
                text={
                  planStatus === "list"
                    ? t("Create new Individual support list")
                    : t("Create new Individual support (draft) list")
                }
                shape={"circle"}
                onClick={() =>
                  tempChild
                    ? router.push(`${addLink}?child_id=${tempChild}`)
                    : router.push(addLink)
                }
                className={"create_btn"}
                disabled={!hasAddAccess}
              />
            </div>
          </div>
        </Content>
      </PageCard>
    </Wrapper>
  )
}
