import { NumberProps } from "./types"

export function CityNumber({ number }: NumberProps) {
  return (
    <div className={"city_no_total"}>
      {number
        .padEnd(10, ".")
        .split("")
        .map((v, index) => (
          <div key={index} className={"value_total"}>
            {v !== "." ? v : ""}
          </div>
        ))}
    </div>
  )
}
