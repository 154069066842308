// Styled Components
import { MainContainer } from "./styles"

// Components
import { ActualExpensesInfoBlock, ActualExpensesTableBlock } from "./components"

// constants
import { Skeleton } from "antd"

// types
import { ActualCostBurdenDetailPageProps } from "./types"
import { useRouter } from "next/router"
import { useContext } from "react"
import { AuthContext } from "../../../utils"

export const ActualCostBurdenDetailPage: React.FC<
  ActualCostBurdenDetailPageProps
> = ({
  currentDate,
  currentFacility,
  isLoading,
  id,
  actualBurdenData,
  refetchDatasource,
}) => {
  const router = useRouter()
  const { companyName } = useContext(AuthContext)

  const burdenInfo = {
    date: actualBurdenData?.date,
    facilityName: actualBurdenData?.facility?.facility_name_short,
    actualCostName: actualBurdenData?.actual_cost?.actual_cost_name
      ? actualBurdenData?.actual_cost?.actual_cost_name
      : actualBurdenData?.actual_cost_detail,
    usersTotalCost: actualBurdenData?.totalCost,
  }

  const burdenUserInfo = []

  actualBurdenData?.burden_user_records.map((item: any) => {
    burdenUserInfo.push({
      child_name: item.child.child_name,
      child_id: item.child.id,
      service_type: item.child.active_receiving_certificate?.service_type,
      cost: item.cost,
      last_update: item.updated_datetime,
      id: item.id,
      user_name: companyName,
    })
  })

  const handleEdit = () => {
    router.push(
      `/actual-cost-burden/edit/${id}?&facilityId=${
        currentFacility?.value || undefined
      }`
    )
  }

  return (
    <MainContainer>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <ActualExpensesInfoBlock
            actualExpensesBasicInfoData={burdenInfo}
            onEdit={handleEdit}
          />
          <ActualExpensesTableBlock
            currentDate={currentDate}
            dataSource={burdenUserInfo}
            currentFacility={currentFacility}
            refetch={refetchDatasource}
            actualItemDefaultCost={actualBurdenData?.actual_cost?.price || 0}
          />
        </>
      )}
    </MainContainer>
  )
}

export * from "./components/index"
