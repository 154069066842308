import { Table } from "@project/shared"
import { CityNumber } from "./common/CityNumber"
import { BeneficiaryNumber } from "./common/BeneficiaryNumber"
import { ParentName } from "./common/ParentName"
import { ChildName } from "./common/ChildName"
import { SiblingChild } from "../../../../../types"

const CIRCLE_NUMBERS = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
}

enum RowIndex {
  CITY_NUMBER = 0,
  PARENT_NAME = 1,
  CHILD_NUMBER_LABEL_TOP = 2,
  CHILD_NUMBER_LABEL_BOTTOM = 3,
  CHILDRENS = 4,
}

type Props = {
  municipalityNumber: string
  parentName: string
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function KisarazuChildDetail({
  municipalityNumber,
  parentName,
  siblingChildren,
  printConfig,
}: Props) {
  return (
    <Table
      style={{ width: 360 }}
      columns={[
        {
          width: "20px",
          dataIndex: "column1",
          onCell: (row, index) => {
            if (row.column1 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }

            if (
              index === RowIndex.CITY_NUMBER ||
              index === RowIndex.PARENT_NAME
            ) {
              return { colSpan: 2 }
            }

            if (index === RowIndex.CHILD_NUMBER_LABEL_TOP) {
              return { rowSpan: 2 }
            }

            return {}
          },
          render: (v) => <p>{v}</p>,
        },
        {
          dataIndex: "column2",
          onCell: (row, index) => {
            if (row.column2 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }

            if (index === RowIndex.CHILD_NUMBER_LABEL_TOP) {
              return { colSpan: 2 }
            }

            return {}
          },
          render: (v) => <p>{v}</p>,
        },
        {
          dataIndex: "column3",
          onCell: (row) => {
            if (row.column3 === false) {
              return { colSpan: 0, rowSpan: 0 }
            }
            return {}
          },
          render: (v) => <p>{v}</p>,
        },
      ]}
      dataSource={[
        // 市区町村番号
        {
          column1: "市町村番号",
          column2: false,
          column3: <CityNumber number={municipalityNumber} />,
        },
        // 支給決定障害者等氏名
        {
          column1: "支給決定保護者名",
          column2: false,
          column3: <ParentName name={parentName} configuration={printConfig} />,
        },
        // 児童番号
        {
          column1: "児童番号",
          column2: "対象児",
          column3: false,
        },
        {
          column1: false,
          column2: "受給者証番号",
          column3: "氏名",
        },
        ...siblingChildren.map(
          ({ child_no, receiving_certificate_number, child_name }) => ({
            column1: CIRCLE_NUMBERS[child_no],
            column2: (
              <BeneficiaryNumber
                key={child_no}
                number={receiving_certificate_number}
              />
            ),
            column3: (
              <ChildName
                key={child_no}
                name={child_name}
                configuration={printConfig}
              />
            ),
          })
        ),
      ]}
      pagination={false}
      showHeader={false}
    />
  )
}
