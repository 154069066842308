import { t } from "i18next"
import { ConfirmationWrapper } from "./Signature.style"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../../constants"

type Props = {
  printConfig: string[]
}

export function ShizuokaSignature({ printConfig = [] }: Props) {
  return (
    <ConfirmationWrapper>
      {t("I have confirmed the above information.")}
      <br />
      {"令和　　　　年　　　　月　　　　日"}
      <br />
      <div className={"footer-check"}>
        {t("Name of person with a disability for whom payment is determined")}
        {printConfig.includes(
          PRINT_CONFIGURATION_OPTION_VALUES.PRINT_MARK_ON_CONFIRMATION
        ) && <span style={{ float: "right" }}>{"印"}</span>}
      </div>
    </ConfirmationWrapper>
  )
}
