// packages
import * as Yup from "yup"
import { t } from "i18next"

export const ProgressRecFormSchema = Yup.object().shape({
  yearMonth: Yup.date().required(t("Required")),
  facilityId: Yup.number().required(t("Required")).min(1, t("Required")),
  childId: Yup.number().required(t("Required")).min(1, t("Required")),
  programCategoryId: Yup.number().required(t("Required")).min(1, t("Required")),
  overallGoal: Yup.string().nullable(),
  review: Yup.string().nullable(),
  furiganaSort: Yup.string().nullable(),
  goalAchievementRate: Yup.number().required(t("Required")),
})
