import { API } from "@project/shared"

// AdditionSettingChildDay
interface IIntensiveSupportAddition {
  id: number
  facility_id: number
  child_id: number
  date: string
  addition_data: number
  addition_type: number
}

export interface IReturnServiceProvisionSheet {
  count?: number
  data?: any
  calculations?: any
  child_info?: any
  next_child?: any
  previous_child?: any
  scheduled_usage?: any
  intensive_support_addition: IIntensiveSupportAddition | null
}

interface IServiceProvisionSheetDetailParams {
  year?: string
  month?: string
  content_of_remarks?: string
  addition_items?: string
  show_digital_signature?: string
  show_non_digital_signature?: string
  showAbsence?: string
  dont_include_zero_cost?: string
}
interface IServiceProvisionSheetParam {
  page?: string
  pageSize?: string
  year: string
  showAbsence?: string
  month: string
  facility_ids: string
  show_digital_signature: string
  examContent: string[]
  child_ids: any
  service_type_ids: any
  keyword: string
  show_non_digital_signature: string
  certificate_applicable_year_month?: string
}

export const fetchServiceProvisionSheet = async ({
  page,
  pageSize,
  queryParam,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetParam
  params.page = page || 1
  params.pageSize = pageSize || "20"
  params.year = queryParam.year
  params.month = queryParam.month
  params.certificate_applicable_year_month = `${queryParam.certificate_applicable_year}-${queryParam.certificate_applicable_month}-01`
  params.facility_ids = Array.isArray(queryParam.facility_ids)
    ? queryParam.facility_ids?.join(",")
    : queryParam.facility_ids
  params.show_digital_signature = queryParam.show_digital_signature
  params.show_non_digital_signature = queryParam.show_non_digital_signature
  params.keyword = queryParam.keyword
  params.child_ids = queryParam.child_ids
  params.service_type_ids = Array.isArray(queryParam.service_type_ids)
    ? queryParam.service_type_ids?.join(",")
    : queryParam.service_type_ids
  return API.get(`/service-provision-record`, { params })
}

export const fetchServiceProvisionSheetList = async ({
  page,
  pageSize,
  queryParam,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetParam
  params.page = page || 1
  params.pageSize = pageSize || "20"
  params.year = queryParam.year
  params.month = queryParam.month
  if (
    queryParam.certificate_applicable_year &&
    queryParam.certificate_applicable_month
  )
    params.certificate_applicable_year_month = `${queryParam.certificate_applicable_year}-${queryParam.certificate_applicable_month}-01`
  params.facility_ids = Array.isArray(queryParam.facility_ids)
    ? queryParam.facility_ids?.join(",")
    : queryParam.facility_ids
  params.show_digital_signature = queryParam.show_digital_signature
  params.show_non_digital_signature = queryParam.show_non_digital_signature
  params.keyword = queryParam.keyword
  params.showAbsence = queryParam.showAbsence
  params.child_ids = queryParam.child_ids
  params.service_type_ids = Array.isArray(queryParam.service_type_ids)
    ? queryParam.service_type_ids?.join(",")
    : queryParam.service_type_ids
  return API.get(`/service-provision-record/list`, { params })
}

export const fetchServiceProvisionSheetChildDetail = async ({
  queryKey,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetDetailParams
  const id = queryKey[2]
  const query = queryKey[1]
  const { year, month } = query
  params.content_of_remarks = queryKey[1].content_of_remarks?.join(",")
  queryKey[1].show_non_digital_signature?.join(",")
  params.showAbsence = queryKey[1].showAbsence
  if (queryKey[1]?.remark_actual_expense == "1")
    params.dont_include_zero_cost = "1"
  return API.get(
    `service-provision-record/child-service-provision-sheet/${id}/${year}/${month}`,
    { params }
  )
}

export const popUpUpdate = (values: any) => {
  const { id } = values
  delete values.id
  return API.patch(`service-provision-record/performance-popups/${id}`, {
    ...values,
  })
}
