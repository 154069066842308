import {
  Pagination,
  SelectInput,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import moment from "moment"
import router from "next/router"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import {
  OperationDailyReportBulkPrint,
  ReportChildWarning,
} from "../../molecules"
import { getBusinessDailyReportBulkPrintView } from "../../../services/businessDailyReport"
import { OwnerButton } from "../../atoms"
import { scrollToSelectedElement } from "../../../utils"
import {
  ChildTable,
  ContentImageTable,
  CustomButton,
  HeaderTable,
  Spinner,
  StaffTable,
  Wrapper,
} from "./DailyBusinessReportBulkPrintPage.style"
import { Table } from "antd"
import { ATTENDANCE_LABEL_FOR_CHILD } from "../../../constants/attendance"

interface IDailyBusinessReportViewBulkPrintPage {
  facility_ids: Array<number>
  from_date: string
  to_date: string
  facilities: Array<any> | null
  size?: number
  page?: number
}

const PAGESIZE_OPTIONS = [
  {
    label: 15,
    value: 15,
  },
  { label: 30, value: 30 },
]
const DEFAULT_PAGE_SIZE = 15

export const DailyBusinessReportViewBulkPrintPage = ({
  facility_ids,
  from_date,
  to_date,
  facilities,
  size,
  page,
}: IDailyBusinessReportViewBulkPrintPage) => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useState({
    facility_ids,
    from_date,
    to_date,
    size: size ?? DEFAULT_PAGE_SIZE,
    page: page ?? 1,
  })
  useEffect(() => {
    const queries = []
    if (searchParams.facility_ids.length) {
      queries.push(`facility_ids=${searchParams.facility_ids.join(",")}`)
    }
    if (searchParams.from_date) {
      queries.push(`from_date=${searchParams.from_date}`)
    }
    if (searchParams.to_date) {
      queries.push(`to_date=${searchParams.to_date}`)
    }
    if (searchParams.size != DEFAULT_PAGE_SIZE) {
      queries.push(`size=${searchParams.size}`)
    }
    if (searchParams.page > 1) {
      queries.push(`page=${searchParams.page}`)
    }
    router.push(`?${queries.join("&")}`, undefined, { shallow: true })
  }, [searchParams])

  const { data: reportData, isLoading } = useQuery(
    ["fetch-daily-business-report-bulk-print", searchParams],
    async () => {
      const queries = []
      if (searchParams.facility_ids.length) {
        queries.push(`facility_ids=${searchParams.facility_ids.join(",")}`)
      }
      if (searchParams.from_date) {
        queries.push(`from_date=${searchParams.from_date}`)
      }
      if (searchParams.to_date) {
        queries.push(`to_date=${searchParams.to_date}`)
      }
      if (searchParams.size) {
        queries.push(`pageSize=${searchParams.size}`)
      }
      if (searchParams.page) {
        queries.push(`page=${searchParams.page}`)
      }
      // Dont set current date, uncomment below code if required later.
      // if (!searchParams.from_date && !searchParams.to_date) {
      //   queries.push(`date=${moment().format("YYYY-MM-DD")}`)
      // }

      const { data } = await getBusinessDailyReportBulkPrintView(
        queries.join("&")
      )
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (resp: any) => {
        return resp
      },
    }
  )

  const [operations, setOperations] = useState({
    facility_ids,
    from_date,
    to_date,
    display_contents: [
      "facility",
      "attendance",
      "pickup",
      "date",
      "activity",
      "entry",
      "enter_exit",
      "content",
      "temperature",
      "service",
      "actual_cost",
      "manager",
    ],
    pickup_display_style: 2,
  })

  const CHILD_TABLE_COLUMNS: any = [
    {
      key: "id",
      displayKey: "id",
      dataIndex: "child_name",
      title: "id",
      className: "id-row",
      colSpan: 0,
      render: (text, obj, index) => {
        return index + 1
      },
    },
    {
      key: "name",
      displayKey: "name",
      dataIndex: "child_name",
      title: () => <div className={"header__content"}>{t("Child name")}</div>,
      className: "first_th_cell",
      colSpan: 2,
    },
    {
      key: "service",
      displayKey: "service",
      title: <div className={"header__content"}>{t("Service used")}</div>,
      render: (obj) => {
        if (obj?.service_type == 2) return t("Child development support")
        else if (obj?.service_type == 1) return t("After school day service")
        return ""
      },
    },
    {
      key: "attendance",
      displayKey: "attendance",
      dataIndex: "attendance_type",
      title: <div className={"header__content"}>{t("Attendance")}</div>,
      render: (att) => {
        return t(ATTENDANCE_LABEL_FOR_CHILD[att || 0])
      },
    },
    {
      key: "provision",
      displayKey: "provision",
      dataIndex: "form_of_provision",
      title: <div className={"header__content"}>{t("Form of provision")}</div>,
      render: (att) => (att ? t(att) : "-"),
    },
    {
      key: "enter",
      displayKey: "enter_exit",
      dataIndex: "start_time",
      title: <div className={"header__content"}>{t("Entering the room")}</div>,
    },
    {
      key: "exit",
      displayKey: "enter_exit",
      dataIndex: "end_time",
      title: <div className={"header__content"}>{t("Leaving the room")}</div>,
    },
    {
      key: "temperature",
      displayKey: "temperature",
      dataIndex: "temperature",
      title: (
        <div className={"header__content"}>
          {t("Body temperature upon entry")}
        </div>
      ),
      render: (data) => (data?.length && data != "0" ? `${data}°C` : null),
    },
    {
      key: "actual_cost",
      displayKey: "actual_cost",
      title: <div className={"header__content"}>{t("Actual cost")}</div>,
      render: (obj) => {
        if (Array.isArray(obj?.actual_costs) && obj?.actual_costs?.length > 0) {
          return obj?.actual_costs?.map((cost) => (
            <>
              {`${cost?.actual_cost_name}: ${cost?.actual_cost_price}${t(
                "Yen"
              )}`}
              <br />
            </>
          ))
        }
        return t("None")
      },
    },
    {
      key: "pickup",
      displayKey: "pickup",
      title: (
        <div className={"header__content"}>{t("Pick-up destination")}</div>
      ),
      children: [
        {
          key: "pickup_place",
          dataIndex: "pickup_place",
          title: <div className={"header__content"}>{t("(Pickup)")}</div>,
          className: "nested_th_cell",
          render: (text, row) => {
            if (!row?.is_pickup) return "―"
            if (operations.pickup_display_style == 1) return text
            return "◯"
          },
        },
        {
          key: "drop_place",
          dataIndex: "drop_place",
          title: <div className={"header__content"}>{t("(Drop)")}</div>,
          className: "nested_th_cell-last",
          render: (text, row) => {
            if (!row?.is_drop) return "―"
            if (operations.pickup_display_style == 1) return text
            return "◯"
          },
        },
      ],
    },
    {
      key: "service_content",
      displayKey: "service_content",
      dataIndex: "service_content",
      title: <div className={"header__content"}>{t("Service content")}</div>,
      className: "last_col",
    },
  ]

  const STAFF_TABLE_COLUMNS: any = [
    {
      key: "id",
      dataIndex: "id",
      title: "id",
      colSpan: 0,
      render: (text, obj, index) => index + 1,
    },
    {
      key: "name",
      dataIndex: "staff_name",
      title: <div className={"header__content"}>{t("Name")}</div>,
      className: "first_th_cell",
      colSpan: 2,
    },
    {
      key: "shift",
      dataIndex: "schedule_start_time",
      title: <div className={"header__content"}>{t("Shift")}</div>,
      render: (text, obj) => {
        const shiftText =
          obj?.schedule_start_time && obj?.schedule_end_time
            ? obj?.schedule_start_time + "-" + obj?.schedule_end_time
            : ""
        const shiftText2 =
          obj?.schedule_start_time2 && obj?.schedule_end_time2
            ? obj?.schedule_start_time2 + "-" + obj?.schedule_end_time2
            : ""
        return shiftText + (shiftText2 && `, ${shiftText2}`)
      },
    },
    {
      key: "attendance1",
      dataIndex: "attendance_start",
      title: <div className={"header__content"}>{t("Achievement 1")}</div>,
      render: (text, obj) => {
        return obj?.attendance_start && obj?.attendance_end
          ? obj?.attendance_start + "-" + obj?.attendance_end
          : ""
      },
    },
    {
      key: "attendance2",
      dataIndex: "attendance_start2",
      title: <div className={"header__content"}>{t("Achievement 2")}</div>,
      render: (text, obj) =>
        obj?.attendance_start2 && obj?.attendance_end2
          ? obj?.attendance_start2 + "-" + obj?.attendance_end2
          : "",
    },
    {
      key: "remarks",
      dataIndex: "remarks",
      className: "last_col",
      title: (
        <div className={"header__content"}>
          {t(
            "Record of business performance (especially in the case of overtime, fill in the reason and content)"
          )}
        </div>
      ),
    },
  ]

  const getWeekDay = (day: number) => {
    switch (day) {
      case 0:
        return t("Sun")
      case 1:
        return t("Mon")
      case 2:
        return t("Tue")
      case 3:
        return t("Wed")
      case 4:
        return t("Thu")
      case 5:
        return t("Fri")
      case 6:
        return t("Sat")
    }
  }

  const onPrint = () => {
    if (typeof window !== undefined) {
      const style = document.createElement("style")
      style.innerHTML = `@page {size: landscape}`
      style.id = "page-orientation"
      document.head.appendChild(style)
      window.print()
    }
  }

  const getDisplayDate = (date) => (
    <>
      {moment(date).format("YYYY年MM月DD日")}
      {`（${getWeekDay(new Date(date).getDay())}）`}
    </>
  )

  const shouldBeDisplayed = (key) => operations.display_contents.includes(key)

  const displayFileContent = (blobStr) => {
    const newBlobStr = blobStr.split("|")[0]
    const strSplit = blobStr?.split(";")
    const fileName = blobStr.split("|").pop()
    let type = strSplit[0]?.replaceAll("data:", "")
    if (type) {
      type = type?.split("/")[0]
    }
    switch (type) {
      case "image":
        return <img src={newBlobStr} alt={"report"} />
      default:
        return (
          <a href={newBlobStr} download={fileName}>
            {fileName || t("Image・File")}
          </a>
        )
    }
  }

  return (
    <Wrapper>
      <div className={"section-not-to-print"}>
        <OperationDailyReportBulkPrint
          facility_ids={facility_ids}
          from_date={from_date}
          to_date={to_date}
          facilities={facilities}
          onSubmit={(settings) => {
            setOperations(settings)
            setSearchParams({
              ...searchParams,
              facility_ids: settings.facility_ids,
              from_date: settings.from_date
                ? moment(settings.from_date).format("YYYY-MM-DD")
                : null,
              to_date: settings.to_date
                ? moment(settings.to_date).format("YYYY-MM-DD")
                : null,
            })
            scrollToSelectedElement(contentRef)
          }}
        />
      </div>
      <div className={"section-not-to-print"}>
        {reportData?.warnings?.child_without_attendance?.length > 0 && (
          <ReportChildWarning
            title={t(
              "There are days when attendance is not recorded. Please check."
            )}
            detail={t("Display list by child")}
            childInfo={reportData?.warnings?.child_without_attendance}
            link={({ date, facility_id }) =>
              `/user-attendance-table/day-list?facilityId=${facility_id}&date=${date}`
            }
          />
        )}
        {reportData?.warnings?.child_without_checkout?.length > 0 && (
          <ReportChildWarning
            title={t(
              "Some children have not been recorded as leaving the room. Please check."
            )}
            detail={t("Display list by child")}
            childInfo={reportData?.warnings?.child_without_checkout}
            link={({ date, facility_id }) =>
              `/user-attendance-table/day-list?facilityId=${facility_id}&date=${date}`
            }
          />
        )}
        {reportData?.warnings?.child_with_incomplete_pick_drop?.length > 0 && (
          <ReportChildWarning
            title={t(
              "There are children who have applied for transportation but have not registered their transportation records."
            )}
            detail={t("Display list by child")}
            childInfo={reportData?.warnings?.child_with_incomplete_pick_drop}
            additionalInfo={t(
              `If any of the days below include days when no transportation was provided, please change the application information to "no transportation" in the Transportation Management.`
            )}
            link={({ child_id, date }) =>
              `/user-transport-management/edit/${date}?childId=${child_id}`
            }
            color={theme.red3}
          />
        )}
      </div>
      <TopRoundedWhiteCard
        title={t("Business daily report")}
        className={"business-day-card"}
      >
        {isLoading ? (
          <Spinner size={"large"} />
        ) : !reportData?.count ? (
          <div className={"no-report-day"}>
            {t("Daily report is not registered.")}
          </div>
        ) : (
          <>
            <div className={"print-pagination-container section-not-to-print"}>
              <div className={"print-back-container"}>
                <CustomButton
                  background={"rgba(7, 130, 200, 0.2)"}
                  shape={"circle"}
                  borderColor={theme.blue5}
                  onClick={() => {
                    router.back()
                  }}
                >
                  {"<<"}
                  {t("Back")}
                </CustomButton>
                <OwnerButton
                  text={t("Print")}
                  typeOf={"primary"}
                  icon={"print"}
                  shape={"round"}
                  onClick={onPrint}
                />
              </div>
              <div className={"pagination-container"}>
                <Pagination
                  total={reportData?.count}
                  pageSize={searchParams.size}
                  current={searchParams.page}
                  onChange={(page) =>
                    setSearchParams({ ...searchParams, page })
                  }
                />
                <SelectInput
                  height={40}
                  width={60}
                  options={PAGESIZE_OPTIONS}
                  defaultValue={DEFAULT_PAGE_SIZE}
                  value={searchParams?.size}
                  onChange={(val) =>
                    setSearchParams({ ...searchParams, size: val, page: 1 })
                  }
                />
              </div>
            </div>
            <div
              className={"daily-report-container section-to-print"}
              ref={contentRef}
            >
              {reportData?.business_report?.map((report) => (
                <div
                  className={"each-report-container"}
                  key={report?.report?.id}
                >
                  <div className={"title-container"}>
                    {t("Business daily report")}
                  </div>
                  <div className={"table-container"}>
                    <HeaderTable>
                      <tr>
                        {(shouldBeDisplayed("facility") ||
                          shouldBeDisplayed("date")) && (
                          <td
                            className={"facility_date"}
                            rowSpan={
                              shouldBeDisplayed("facility") &&
                              shouldBeDisplayed("date")
                                ? 1
                                : 2
                            }
                          >
                            {shouldBeDisplayed("facility")
                              ? report?.report?.facility?.facility_name_short
                              : getDisplayDate(
                                  report?.report?.report_create_date
                                )}
                          </td>
                        )}
                        {shouldBeDisplayed("activity") && (
                          <td
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {t("Today's Activity")}
                          </td>
                        )}
                        {shouldBeDisplayed("entry") && (
                          <>
                            <td rowSpan={2} className={"stamp_label"}>
                              {t("Entry Person")}
                            </td>
                            <td rowSpan={2} className={"stamp_value"}>
                              {report?.report?.staff?.staff_name}
                            </td>
                          </>
                        )}
                        {shouldBeDisplayed("manager") && (
                          <>
                            <td rowSpan={2} className={"stamp_label"}>
                              {t("Facility Manager Confirmation")}
                            </td>
                            <td rowSpan={2} className={"stamp_value"}></td>
                          </>
                        )}
                        {shouldBeDisplayed("approval") && (
                          <>
                            <td rowSpan={2} className={"stamp_label"}>
                              {t("Approval")}
                            </td>
                            <td rowSpan={2} className={"stamp_value"}></td>
                          </>
                        )}
                      </tr>
                      <tr>
                        {shouldBeDisplayed("facility") &&
                          shouldBeDisplayed("date") && (
                            <td className={"facility_date"}>
                              {getDisplayDate(
                                report?.report?.report_create_date
                              )}
                            </td>
                          )}
                        {shouldBeDisplayed("activity") && (
                          <td>
                            {report?.activities?.map((activity) => (
                              <>
                                {activity}
                                <br />
                              </>
                            ))}
                          </td>
                        )}
                      </tr>
                    </HeaderTable>
                  </div>
                  <ChildTable>
                    <Table
                      id={"ChildTable"}
                      columns={CHILD_TABLE_COLUMNS.filter(
                        (col) =>
                          col.displayKey == "id" ||
                          col.displayKey == "name" ||
                          shouldBeDisplayed(col.displayKey)
                      )}
                      dataSource={report?.scheduled_childs}
                      showSorterTooltip={false}
                      pagination={false}
                      className={"child-table"}
                      bordered
                    />
                  </ChildTable>

                  {shouldBeDisplayed("content") && (
                    <>
                      <div className={"table-container"}>
                        <ContentImageTable>
                          <tr>
                            <td className={"label-cell"}>{t("Content")}</td>
                            <td>{report?.report?.activities_content}</td>
                          </tr>
                        </ContentImageTable>
                      </div>
                      <div className={"table-container"}>
                        <ContentImageTable>
                          <tr>
                            <td className={"label-cell"}>{t("Transfer")}</td>
                            <td>{report?.report?.transfer}</td>
                          </tr>
                        </ContentImageTable>
                      </div>
                      <div className={"table-container"}>
                        <ContentImageTable>
                          <tr>
                            <td className={"label-cell"}>{t("Remarks")}</td>
                            <td>{report?.report?.minutes_text}</td>
                          </tr>
                        </ContentImageTable>
                      </div>
                    </>
                  )}
                  {shouldBeDisplayed("image") && (
                    <div className={"table-container"}>
                      <ContentImageTable>
                        <tr>
                          <td rowSpan={2} className={"label-cell"}>
                            {t("Image file")}
                          </td>
                          <td className={"cell-image"}>
                            {report?.report?.file_image1 &&
                              displayFileContent(report.report.file_image1)}
                          </td>
                          <td className={"cell-image"}>
                            {report?.report?.file_image2 &&
                              displayFileContent(report.report.file_image2)}
                          </td>
                        </tr>
                        {report?.report?.file_image3 ||
                          (report?.report?.file_image4 && (
                            <tr>
                              <td className={"cell-image"}>
                                {report?.report?.file_image3 &&
                                  displayFileContent(report.report.file_image3)}
                              </td>
                              <td className={"cell-image"}>
                                {report?.report?.file_image4 &&
                                  displayFileContent(report.report.file_image4)}
                              </td>
                            </tr>
                          ))}
                      </ContentImageTable>
                    </div>
                  )}

                  {shouldBeDisplayed("staff") && (
                    <div className={"staff-table"}>
                      <StaffTable
                        columns={STAFF_TABLE_COLUMNS}
                        dataSource={report?.scheduled_staffs}
                        pagination={false}
                        bordered={true}
                        id={"StaffTable"}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </TopRoundedWhiteCard>
      <div className={"footer-buttons section-not-to-print"}>
        <CustomButton
          background={"rgba(7, 130, 200, 0.2)"}
          shape={"circle"}
          borderColor={theme.blue5}
          onClick={() => {
            router.push("/daily-business-report/")
          }}
        >
          {"<<"}
          {t("Back")}
        </CustomButton>
        <OwnerButton
          text={t("Print")}
          typeOf={"primary"}
          icon={"print"}
          shape={"round"}
          onClick={onPrint}
        />
      </div>
    </Wrapper>
  )
}
