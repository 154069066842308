import styled from "styled-components"

// theme
import { theme } from "@project/shared"

// const
const borderColor = theme.black
const backgroundColor = theme.gray2

interface TableDisplaySettings {
  displayChildDevelopment?: boolean
  displayAfterSchoolDay?: boolean
}

export const MonthlyUsageListContainer = styled.div`
  & > div.top-rounded-white-card {
    padding: 20px 30px 40px 30px;
    .title-wrapper {
      .title {
        padding-bottom: 15px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 20px 20px;
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;
    gap: 36px;
    flex-wrap: wrap;

    @media (max-width: 416px) {
      gap: 0;
      button {
        padding: 4px 16px;
        line-height: 20.27px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
  }

  .chartInfo {
    margin-top: 36px;
    margin-bottom: 15px;
    .chartInfo_text {
      ${theme.typography.label}
      line-height: 30px;
      font-weight: 400;
      color: ${theme.black};
      .chartInfo_bold-text {
        font-weight: 500;
      }
    }

    @media print {
      margin-top: 0;
    }
  }
`
export const RedText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #e00000;
  margin: 18px 0;
`

export const MonthlyUsageTableWrapper = styled.div<TableDisplaySettings>`
  /* Table header styling starts here */
  width: 100%;
  height: 100%;

  .cross-cell-parent {
    position: relative;
    width: 100%;
    height: 130px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }

  div.ant-table-container {
    border-color: transparent !important;

    table {
      border-color: ${theme.black} !important;
      border-collapse: collapse !important;
      @media screen {
        @media (max-width: ${theme.breakpoints.sm}) {
          border-collapse: separate !important;
        }
      }
      @media print {
        border: 0 !important;
        border-collapse: separate !important;
        width: 600px !important;
      }
    }

    @media print {
      &:after,
      &:before {
        display: none;
      }
    }
  }

  thead.ant-table-thead {
    tr th.ant-table-cell {
      padding: 4px 0;
      padding-inline: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      background-color: #fff;
      outline: none !important;
      border-color: ${borderColor} !important;

      &:first-child {
        border-left: 1px solid ${borderColor};
      }

      .table-cell__month-text {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      @media print {
        font-size: 6pt;
        padding: 2px;
        border-top: 1px solid ${theme.black};
      }
    }
  }

  /* Table header styling ends here */

  /* styling for right static cells i.e.
  1. Number of child development support users
  2. Number of after school service users
  3. Total number of users */
  tbody.ant-table-tbody {
    page-break-inside: avoid;
    .ant-table-measure-row {
      display: none;
    }

    .ant-table-row {
      td.ant-table-cell {
        padding: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        border-color: ${borderColor} !important;

        &.actual-date-cell {
          max-width: 32px;
          width: 32px;
          .individual-date-letter {
            display: block;
            line-height: 1.3em;
            font-size: 12px;
            font-weight: 400;
          }
        }

        &.table-monthly-column,
        &.total-user-cell,
        &.facility-open-days-cell {
          background-color: ${backgroundColor};
          word-wrap: break-word;
        }

        &.actual-category-cell {
          text-align: left;
        }

        &.placeholder-table-cell {
          padding: 0;
          background: url("/assets/imgs/diagnola-line.svg") no-repeat center
            center/cover;
        }

        @media print {
          font-size: 6pt !important;
          padding: 2px;
        }
      }

      @media screen {
        &.top-tbody-row {
          td {
            border-top: 1px solid ${theme.black};
          }
        }
      }
    }

    &:not(:last-child) {
      .ant-table-row.bg-white {
        .table-monthly-column,
        .total-user-cell,
        .facility-open-days-cell {
          background-color: ${theme.base} !important;
        }
      }
    }

    @media screen {
      &:before {
        line-height: 0.8em;
        content: " ";
        color: red;
        display: block;
        height: 0.2em;
      }

      @media (max-width: ${theme.breakpoints.sm}) {
        &:before {
          line-height: 0.18em !important;
          height: 0.18em !important;
        }
      }
    }

    tr.ant-table-row:first-child {
      td.ant-table-cell:first-child {
        border-left: 1px solid ${theme.black};
      }
    }
  }

  /* common border styling for multiple cell */
  .no-child-dev-support-user {
    width: 100%;
    border-bottom: 1px solid ${borderColor};
  }
  .no-school-service-user {
    width: 100%;
    border-bottom: 1px solid ${borderColor};
  }
  .no-of-user {
    width: 100%;
  }

  @media print {
    tbody.ant-table-tbody
      .ant-table-row
      .ant-table-cell
      .actual-date-cell
      p.actual-date-text,
    .no-child-dev-support-user,
    .no-school-service-user {
      border-width: 2px;
    }
  }

  .total-number {
    height: 70px;
  }

  /* table body styling ends here */
`
