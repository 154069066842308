import Link from "next/link"
import { useEffect, useState } from "react"

// packages
import moment from "moment"
import { t } from "i18next"
import { FormikProps } from "formik"
import RedDownArrow from "../../../../public/assets/icons/RedDownArrow"

// styled components
import { RequiredInfoBoxContainer } from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import { extractDayAsPerMonth } from "../utils"
import { Checkbox, SelectInput, getMonthList } from "@project/shared"

// hooks
import useBankInfoList from "../hooks/useBankInfoList"

// constants
import { codeCategory, parents } from "../constants"

// utils
import { getLanguage } from "../../../../utils/getLanguage"

// types

import {
  ThirdNoticeBoxContentProps,
  ParentWithNoAccountInfoProps,
  IBillingMethodAlert,
} from "../types"
import { Trans } from "react-i18next"

// This is a main text that appears on the top of info box component
export const InfoBoxMainText = ({ billingMethod }: IBillingMethodAlert) => {
  return (
    <>
      {getLanguage() === "ja" ? (
        <p className={"info-text"}>
          {"国保連に"}
          <span className={"red-text"}>{"【通常請求】"}</span>
          {"で請求する設定なっております。"}
        </p>
      ) : (
        <p className={"info-text"}>
          <Trans i18nKey={"currentBillingMethodSetAs"} method={billingMethod}>
            {"It is set to bill the National Health Insurance Federation with"}
            <span className={"red-text"}>
              {{ method: billingMethod } as any}
            </span>
          </Trans>
        </p>
      )}
    </>
  )
}

export const FirstNoticeBoxContent = ({
  parentWithNoAccountInfo,
}: {
  parentWithNoAccountInfo: ParentWithNoAccountInfoProps[]
}) => {
  return (
    <p className={"first-notice-box-description"}>
      {parentWithNoAccountInfo.map((parent, index) => {
        return (
          <>
            <Link
              key={index + parent.parent_id}
              href={"/parents/edit/" + parent.parent_id}
            >
              <a className={"underline"}>{parent.parent_name}</a>
            </Link>
            {index !== parents.length - 1 && (
              <span className={"red-text"}>
                <span className={"normal-weight"}>{"、"}</span>
              </span>
            )}
          </>
        )
      })}
    </p>
  )
}

export const ThirdNoticeBoxContent: React.FC<ThirdNoticeBoxContentProps> = ({
  listData,
  facilityName,
  isNoticeOpen,
  handleNoticeToggle,
  selectedTransferAccountId,
}) => {
  const { BankInfoList } = useBankInfoList(listData, selectedTransferAccountId)
  return (
    <div className={"third-notice-box-container"}>
      <button
        className={"notice-toggle-btn underline"}
        onClick={handleNoticeToggle}
      >
        <span className={`down-facing-arrow ${isNoticeOpen && "active"}`}>
          <RedDownArrow />
        </span>
        {t("{{facilityName}} Display bank information", {
          facilityName: facilityName,
        })}
      </button>

      {
        <div className={`bank-info-list-container ${isNoticeOpen && "show"}`}>
          <BankInfoList className={"bank-info-list_content"} />
        </div>
      }
    </div>
  )
}

export const RequiredFormInfoBox = ({
  formik,
}: {
  formik: FormikProps<{
    code_category: number
    withdraw_month: number
    withdraw_day: number
    show_Billing_Amt: boolean
  }>
}) => {
  const [dayOptions, setDayOptions] = useState([])

  const { setFieldValue, values, handleSubmit } = formik

  useEffect(() => {
    if (values.withdraw_month) {
      setDayOptions(
        extractDayAsPerMonth(moment().year(), values.withdraw_month)
      )
    }
  }, [values.withdraw_month])
  return (
    <RequiredInfoBoxContainer>
      <h4 className={"title"}>
        {t("Select the information required for the all bank format")}
      </h4>

      <div className={"form-field-container"}>
        <div className={"form-label-and-field"}>
          <label className={"label"} htmlFor={"code-category"}>
            {t("Code category") + " :"}
          </label>

          <SelectInput
            className={"form-field common-select-input"}
            id={"code-category"}
            width={150}
            name={"code_category"}
            value={values.code_category}
            options={codeCategory}
            onChange={(val) => {
              setFieldValue("code_category", val)
            }}
          />
        </div>

        <div className={"form-label-and-field"}>
          <label className={"label"} htmlFor={"withdraw-date"}>
            {t("Withdrawal date") + " :"}
          </label>
          <div className={"form-field withdraw-date-fields flexbox"}>
            <div className={"month-select-field"}>
              <SelectInput
                id={"withdraw-date"}
                className={"common-select-input"}
                width={60}
                options={getMonthList()}
                value={values.withdraw_month}
                onChange={(val) => {
                  setFieldValue("withdraw_month", val)
                  setFieldValue("withdraw_day", 1)
                }}
              />
              <span>{t("Month")}</span>
            </div>
            <div className={"day-select-field"}>
              <SelectInput
                id={"withdraw-date"}
                className={"common-select-input"}
                width={60}
                options={dayOptions}
                value={values.withdraw_day}
                onChange={(val) => {
                  setFieldValue("withdraw_day", val)
                }}
              />
              <span>{t("Day")}</span>
            </div>
          </div>
        </div>

        <div className={"form-label-and-field"}>
          <label className={"label"} htmlFor={"output-setting"}>
            {t("Output setting") + " :"}
          </label>
          <Checkbox
            id={"output-setting"}
            className={"form-field"}
            name={"show_Billing_Amt"}
            value={values.show_Billing_Amt}
            label={t("Output users whose billing amount is {{amt}} yen", {
              amt: 0,
            })}
            onChange={(e) => {
              setFieldValue("show_Billing_Amt", e.target.checked)
            }}
          />
        </div>
      </div>

      <OwnerButton
        className={"submit-btn"}
        typeOf={"draft"}
        text={t("Save temporarily")}
        onClick={() => {
          handleSubmit()
        }}
      />
    </RequiredInfoBoxContainer>
  )
}

export { FilterActionBlock } from "./FilterActionBlock"
export { FormatOutputBlock } from "./FormatOutputBlock"
export { useFormatOutputTable } from "./Table"
