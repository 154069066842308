import { API } from "@project/shared"

export type StaffAttendenceRequestValues = {
  facility_id?: number
  staff_id?: number
  attendance_shift_id?: number
  date?: string
  attendance_start_time?: string
  attendance_end_time?: string
  attendance_start_time2?: string
  attendance_end_time2?: string
  attendance_rest_minits?: number
  is_delete?: boolean
  [x: string]: any
}

export interface StaffAttendenceRsponseValues {
  count: number
  data: StaffAttendenceRequestValues[]
}

export const getStaffAttendanceShiftSchedule = (
  facilityId: number,
  date: string
) => API.get(`/attendance_staff_shift_schedules/${facilityId}?date=${date}`)

export const createStaffAttendanceShiftSchedule = ({
  facilityId,
  values,
}: {
  facilityId: number
  values: StaffAttendenceRequestValues[]
}): Promise<StaffAttendenceRsponseValues> =>
  API.post(`/attendance_staff_shift_schedules/${facilityId}`, values)

export const deleteStaffAttendanceShiftSchedule = async (
  facility_id: string,
  attendence_id: string
) =>
  API.delete(
    `/attendance_staff_shift_schedules/${facility_id}/${attendence_id}`
  )
export const getAttendanceShifts = async (facility_id: number) =>
  API.get(`/attendance-shift?facility=${facility_id}`)

export const getStaffMonthlyAttendanceShiftSchedule = ({
  facility,
  staff,
  year,
  month,
}) =>
  API.get(
    `/attendance_staff_shift_schedules/${facility}/staff/${staff}/${year}/${month}`
  )

export const updateOneStaffAttendanceShiftSchedule = ({
  facilityId,
  id,
  values,
}: {
  facilityId: number
  id: number
  values: StaffAttendenceRequestValues[]
}) => API.put(`/attendance_staff_shift_schedules/${facilityId}/${id}`, values)

export const createStaffAttendanceRecordResult = ({
  facilityId,
  values,
}: {
  facilityId: number
  values: StaffAttendenceRequestValues
}): Promise<StaffAttendenceRsponseValues> =>
  API.post(`/staff-attendance-results/${facilityId}`, values)

export const getStaffAttendanceResults = (facilityId: number, date: string) =>
  API.get(
    `/staff-attendance-results/${facilityId}/results?date=${date}&pageSize=Infinity`
  )
