import { Table } from "@project/shared"

import { t } from "i18next"
import { DefaultProps } from "./types"
import { Content, padEmptyFacilities } from "./utils"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"
import { SiblingChild } from "../../../../types"
import { ReactNode } from "react"

const CIRCLE_NUMBERS = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
}

type Props = DefaultProps & { siblingChildren: SiblingChild[] }

function bodyOnCell(_, index: number) {
  if (index === 0) {
    return { rowSpan: 7 }
  }
  return { rowSpan: 0 }
}

export function OtaFormat({
  facilities,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  siblingChildren,
}: Props) {
  const upperRow = padEmptyFacilities(facilities.slice(0, 4), 4)
  const lowerRow = padEmptyFacilities(facilities.slice(4, 7), 3)

  return (
    <>
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              {
                title: "",
                render: () =>
                  t("User burden amount aggregation / adjustment column"),
                width: 20,
                onCell: bodyOnCell,
              },
              {
                title: "",
                dataIndex: "title",
                align: "center",
                width: 200,
              },
              ...generateDynamicColumn(upperRow, siblingChildren),
            ]}
            pagination={false}
            dataSource={TABLE_HEADS}
          />
        </div>
      </NBLManagementCompanyTop>

      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              {
                title: "",
                render: () =>
                  t("User burden amount aggregation / adjustment column"),
                width: 20,
                onCell: bodyOnCell,
              },
              {
                title: "",
                dataIndex: "title",
                align: "center",
                width: 200,
              },
              ...generateDynamicColumn(lowerRow, siblingChildren),
              // 最終列（合計）
              {
                title: t("Total"),
                align: "center",
                width: 180,
                render: (_, __, index) =>
                  getTotalColumnInfo(
                    totalAmount,
                    totalBurdenAmount,
                    managementResultBurdenAmount,
                    index
                  ),
                onCell: (_, index) => {
                  if (index === 0) {
                    return { rowSpan: 4 }
                  }
                  if ([1, 2, 3].includes(index)) {
                    return { rowSpan: 0 }
                  }
                  return {}
                },
              },
            ]}
            pagination={false}
            dataSource={TABLE_HEADS}
          />
        </div>
      </NBLManagementCompanyTop>
    </>
  )
}

/** ================== */

export const TABLE_HEADS = [
  {
    // 項番
    key: "1",
    title: t("S.N"),
  },
  {
    key: "3",
    title: "児童番号",
  },
  {
    // 事業所番号
    key: "3",
    // TODO: translate
    title: "事業所番号",
  },
  {
    // 事業所名称
    key: "4",
    title: t("Business official name"),
  },
  {
    // 総費用額
    key: "5",
    // TODO: translate
    title: "総費用額",
  },
  {
    // 利用者負担額
    key: "6",
    title: t("User’s burden amount"),
  },
  {
    // 管理結果後利用者負担額
    key: "7",
    title: t("User burden after management results"),
  },
]

type TableColumn = {
  title?: number
  align?: "left" | "center" | "right"
  width?: number
  render: (
    row: Row,
    header: { key: number; title: string },
    index: number
  ) => ReactNode
}

function generateDynamicColumn(
  facilities: Row[],
  siblingChildren: SiblingChild[]
): TableColumn[] {
  return facilities.map((value) => ({
    width: 180,
    align: "center",
    render: (_, __, idx) => {
      return getColumnInfo(value, idx, siblingChildren)
    },
  }))
}

enum BodyRow {
  LINE_NO,
  CHILD_NO,
  FACILITY_NUMBER,
  FACILITY_NAME,
  TOTAL_AMOUNT,
  BURDEN_AMOUNT,
  MANAGEMENT_RESULT_BURDEN_AMOUNT,
}

type Row = {
  line_no: number | string
  facility_number: string
  facility_name: string
  total_amount: number | string
  burden_amount: number | string
  management_result_burden_amount: number | string
  receiving_certificate_number: string
  child_name: string
}

function getColumnInfo(
  row: Row,
  index: number,
  siblingChildren: SiblingChild[]
) {
  // 項番
  if (index === BodyRow.LINE_NO) {
    return row.line_no
  }
  // 児童番号
  if (index === BodyRow.CHILD_NO) {
    const siblingChild = siblingChildren.find(
      (child) =>
        child.receiving_certificate_number === row.receiving_certificate_number
    )

    return siblingChild ? CIRCLE_NUMBERS[siblingChild.child_no] : ""
  }
  // 事業所番号
  if (index === BodyRow.FACILITY_NUMBER) {
    return row.facility_number
  }
  // 事業所名
  if (index === BodyRow.FACILITY_NAME) {
    return row.facility_name
  }
  // 合計金額
  if (index === BodyRow.TOTAL_AMOUNT) {
    return <Content data={String(row.total_amount ?? "").padStart(6, ".")} />
  }
  // 利用者負担額
  if (index === BodyRow.BURDEN_AMOUNT) {
    return <Content data={String(row.burden_amount ?? "").padStart(6, ".")} />
  }
  // 管理結果後利用者負担額
  if (index === BodyRow.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_burden_amount ?? "").padStart(
          6,
          "."
        )}
      />
    )
  }
  return ""
}

enum TotalRow {
  TOTAL_LABEL = 0,
  TOTAL_AMOUNT = 4,
  TOTAL_BURDEN_AMOUNT = 5,
  MANAGEMENT_RESULT_BURDEN_AMOUNT = 6,
}

function getTotalColumnInfo(
  totalAmount: number,
  totalBurdenAmount: number,
  managementResultBurdenAmount: number,
  index: number
) {
  if (index === TotalRow.TOTAL_LABEL) {
    return t("Total")
  }
  if (index === TotalRow.TOTAL_AMOUNT) {
    return <Content data={String(totalAmount ?? "").padStart(6, ".")} />
  }
  if (index === TotalRow.TOTAL_BURDEN_AMOUNT) {
    return <Content data={String(totalBurdenAmount ?? "").padStart(6, ".")} />
  }
  if (index === TotalRow.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(managementResultBurdenAmount ?? "").padStart(6, ".")}
      />
    )
  }
  return ""
}
