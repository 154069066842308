import { useTranslation } from "react-i18next"
import styled from "styled-components"

const ExtraDiv = styled.div`
  text-align: start;
  padding: 5px;
  .info-div {
    padding: 5px;
  }
`

const ServiceTag = styled.span`
  padding: 1px 3px;
  border-radius: 5px;
`

const ChildAdditionExtra = (props) => {
  const { data, date } = props
  const { t } = useTranslation()
  const cellData =
    data?.data_map && data?.data_map[date] ? data?.data_map[date] : []

  return (
    <>
      {cellData
        ? Object.keys(cellData).map((additionType, index) => {
            return (
              <ExtraDiv key={`${additionType}-${index}`}>
                <strong>
                  {"- "}
                  {t(
                    `${
                      data?.addition_types?.[
                        cellData?.[additionType]?.addition_type
                      ]
                    }`
                  )}
                </strong>
                <div key={`${additionType}-${index}`} className={"info-div"}>
                  {cellData[additionType]?.child
                    ?.child_receiving_certificates?.[0]?.service_type == 1 ? (
                    <ServiceTag style={{ background: "#FDD95F" }}>
                      <small>{t("after day")}</small>
                    </ServiceTag>
                  ) : (
                    <ServiceTag style={{ background: "#5171ea" }}>
                      <small>{t("child origin")}</small>
                    </ServiceTag>
                  )}
                  <small> {cellData[additionType]?.child?.child_name}</small>
                </div>
              </ExtraDiv>
            )
          })
        : ""}
    </>
  )
}

export default ChildAdditionExtra
