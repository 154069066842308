// types
import { FormFieldsProps } from "../../../molecules/FormFields/types"

// utils
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"

// types
import { RegisterUserFormSchemaProps } from "../types"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { getChildDetail } from "../../../../services/child"
import { useEffect } from "react"

export const actualExpensesBasicInfoSchema = ({
  date,
  facilityName,
  actualCostName,
  usersTotalCost,
}): FormFieldsProps => {
  return [
    {
      fieldLabel: "Date",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: moment(date).format("YYYY年MM月DD日"),
          },
        },
      ],
    },
    {
      fieldLabel: "Facility name",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: facilityName,
          },
        },
      ],
    },
    {
      fieldLabel: "Actual cost item name",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: actualCostName,
          },
        },
      ],
    },
    {
      fieldLabel: "Total cost for users",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: usersTotalCost,
          },
        },
      ],
    },
  ]
}

export const RegisterUserFormSchema = ({
  formik,
  handleSort,
  childOptions,
}: RegisterUserFormSchemaProps): FormFieldsProps => {
  const SORT_OPTION = FuriganaAlphabetsOption
  const { t } = useTranslation()
  const { data: childDetail, refetch } = useQuery<any, Error>(
    ["get-child-detail", formik?.values?.child_id],
    () => getChildDetail(formik?.values?.child_id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!formik?.values?.child_id,
    }
  )

  useEffect(() => {
    refetch()
  }, [formik?.values?.child_id])

  return [
    {
      fieldLabel: "Child name",
      isRowFlexed: true,
      required: true,
      isDisabled: false,
      fields: [
        {
          fieldType: {
            type: "dropdown",
            extraOptions: {
              option: SORT_OPTION,
              onChange: (value: any) => {
                handleSort(value)
                formik.setFieldValue("subName", value)
              },
            },
          },
          name: "subName",
          isDisabled: false,
          placeholder: "---",
          className: "sub-name-dropdown",
          width: 80,
        },
        {
          fieldType: {
            type: "dropdown",

            extraOptions: {
              option: childOptions,
              onChange: (v: any) => {
                formik.setFieldValue("child_id", v)
              },
            },
          },
          isDisabled: false,
          name: "child_id",
          width: 403,
          placeholder: "--------",

          className: "name-dropdown",
        },
      ],
    },
    {
      fieldLabel: "Service using",
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "text",
            text: formik?.values?.child_id
              ? t(childDetail?.data?.info?.service_to_use)
              : t("Please select a child"),
          },
          name: "serviceType",
        },
      ],
    },
    {
      fieldLabel: "Price",
      required: true,
      isRowFlexed: true,
      fields: [
        {
          fieldType: {
            type: "number",
            extraOptions: {
              max: 999999,
              min: 0,
              maxLength: 6,
              labelAlignment: "right",
              label: "Yen",
              onChange: (e) => {
                formik.setFieldValue("cost", parseInt(e.target.value))
              },
            },
          },

          name: "cost",
          className: "price-input",
          placeholder: "0",
          width: 300,
        },
      ],
    },
  ]
}

export const dataSource = [
  {
    key: "1",
    id: 1,
    childName: "志村 唯斗",
    serviceType: "Child development support",
    price: "200",
    lastUpdatedAt: "2023/03/01 15:34:38 つくば",
  },
  {
    key: "2",
    id: 2,
    childName: "髙橋 詩葉",
    serviceType: "Child development support",
    price: "420",
    lastUpdatedAt: "2023/03/01 15:34:38 つくば",
  },
  {
    key: "3",
    id: 3,
    childName: "サビン・シュレスタ",
    serviceType: "Child development support",
    price: "720",
    lastUpdatedAt: "2023/03/01 15:34:38 つくば",
  },
]
