import styled from "styled-components"
import Calendar from "react-calendar"
import { theme } from "@project/shared"
export const MainWrapper = styled.div`
  width: 100%;
  .top {
    margin-bottom: 1rem;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
`

/* stylelint-disable */
export const CostBurdenCalendarWrapper = styled(Calendar)`
  [class*="tile"] * {
    pointer-events: none !important;
  }

  [class*="weekday"] {
    border: 1px solid ${theme.gray2};
    background-color: ${theme.bgColor2};
    text-align: center;
    /* width: 169px; */
    height: 35px;
    &:not(:first-child) {
      border-left: 0;
    }
    abbr {
      cursor: default;
      text-decoration: none;
    }
    & > div:first-child {
      background-color: ${theme.rgba.reddish};
    }
    & > div:last-child {
      background-color: ${theme.rgba.blue};
    }
  }
  .react-calendar__month-view__weekdays {
    border: 0 !important;
  }
  [class*="tile"] {
    border: 1px solid ${theme.gray2};
    border-top: none;
    background: transparent;
    pointer-events: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding: 10px;
    &:not(:nth-child(7n)) {
      border-right: 0 !important;
    }
    * {
      pointer-events: auto;
    }
    abbr {
      text-align: right;
      width: 100%;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .business-holiday {
      display: flex;
      align-items: center;
      padding-top: 20%;
      padding-left: 20%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: ${theme.rgba.reddish} !important;
      z-index: 2;
    }
    .edit-btn {
      margin-right: auto;
      margin-top: auto;
      pointer-events: auto !important;
    }
  }
  [class*="neighboringMonth"] {
    cursor: default;
    abbr,
    .content {
      cursor: default;
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints?.xmd}) {
    [class*="weekday"] {
      display: none !important;
    }
    [class*="tile"] {
      flex-direction: row;
      min-width: 100% !important;
      border-top: thin solid ${theme.gray2};
      padding: 0;
      min-height: 40px !important;
      position: relative;
      &:not(:nth-child(7n)) {
        border-right: 1px solid ${theme.gray2} !important;
      }
      abbr {
        width: 50px;
        height: 100% !important;
        border-right: 1px solid ${theme.gray2} !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
      }
    }
    .content {
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      margin-left: 55px !important;
      padding: 6px 0;
      .content__child {
        display: grid;
        gap: 4px;
      }
      .edit-btn {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .business-holiday {
        padding-top: 2;
        padding-left: 2;
      }
    }
    [class*="neighboringMonth"] {
      display: none !important;
      abbr,
      .content {
        cursor: default;
        display: none;
      }
    }
  }
`
