// packages
import { t } from "i18next"
import { Skeleton } from "antd"

// styled components
import { BillingHead } from "../styles"
import { OwnerTable } from "../../../molecules"

export const LoadingState = ({ tableColumns }) => {
  return (
    <div className={"individual-tabulated-info-container"}>
      {/* Billing head section starts here */}
      <BillingHead className={"billing-head"}>
        <div className={"association-content billing-left-content"}>
          <Skeleton.Input
            className={"serial-name"}
            active
            size={"small"}
            style={{ width: 100 }}
          />

          <Skeleton.Input
            className={"full-address"}
            active
            size={"small"}
            style={{ width: 120 }}
          />

          <Skeleton.Input
            className={"parent-name"}
            active
            size={"small"}
            style={{ width: 100 }}
          />
        </div>

        <div className={"billing-right-content"}>
          <div className={"subject enveloped-layout"}>
            {
              "Notification of Substitute Receipt of Handicapped Children's Outpatient Benefits"
            }
          </div>
          <Skeleton.Input
            className={"date"}
            active
            size={"small"}
            style={{ width: 100 }}
          />

          <Skeleton.Input
            className={"facility-official-name"}
            active
            size={"small"}
            style={{ width: 200 }}
          />
        </div>
      </BillingHead>
      {/* Billing head section ends here */}

      <div className={"short-description"}>
        <Skeleton.Input
          className={"short-description"}
          active
          size={"small"}
          style={{ width: "100%" }}
        />

        <Skeleton.Input
          className={"short-description"}
          active
          size={"small"}
          style={{ width: "40%" }}
        />
      </div>

      <div className={"record-text"}>{t("Record")}</div>

      <OwnerTable
        className={"owner-table"}
        bordered
        columns={tableColumns}
        dataSource={[]}
        scroll={{ x: "600px" }}
      />

      <div className={"notice"}>{t("*This notice is not an invoice.")}</div>
    </div>
  )
}

export { FilterOperationBlock } from "./FilterOperationBlock"
export { ProxyMoreInfoBlock } from "./ProxyMoreInfoBlock"
export { IndividualTables } from "./IndividualTables"
