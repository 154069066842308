import React, { ReactNode } from "react"
import { Tag as TagComp } from "antd"
import styled from "styled-components"

interface IProps {
  backgroundColor?: string
  textColor?: string
  icon?: ReactNode
  children?: ReactNode
  closable?: boolean
  closeIcon?: ReactNode
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  className?: string
  onMouseDown?: any
  style?: object
}

interface StyledTagProps {
  backgroundColor?: string
  textColor?: string
}

const StyledTag = styled(TagComp)<StyledTagProps>`
  background-color: ${(props) => {
    return props.backgroundColor ?? "#4CB476"
  }};

  color: ${(props) => {
    return props.textColor ?? "#fff"
  }};
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
`

const Tag = ({
  children,
  backgroundColor,
  textColor,
  closable,
  icon,
  className,
  closeIcon,
  onClose,
  onClick,
  onMouseDown,
  style,
}: IProps) => {
  return (
    <StyledTag
      closable={closable}
      color={backgroundColor}
      textColor={textColor}
      icon={icon}
      onClose={onClose}
      closeIcon={closeIcon}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={className}
      style={style}
    >
      {children}
    </StyledTag>
  )
}

export { Tag }
