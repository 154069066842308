import { API, removeBlankAttributes } from "@project/shared"

export interface IReturnParentComment {
  count?: number
  data?: any
}

interface IParentCommentParam {
  page?: string
  pageSize?: string
  facilityIds?: string
  userServiceId?: string
  childId?: string
  status?: string
  displayLatestComment?: string
  serviceProvisionDateRange?: string
  commentDateRange?: string
  displayContent?: string
  showParentCommentOnly?: string
}

export const fetchParentComment = async ({
  queryKey,
}): Promise<IReturnParentComment> => {
  const params = {} as IParentCommentParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.facilityIds = queryKey[2]?.facilityIds
  params.userServiceId = queryKey[2]?.userServiceIds
  params.childId = queryKey[2]?.childId
  params.status = queryKey[2]?.status
  params.displayLatestComment = queryKey[2]?.displayLatestComment
  params.serviceProvisionDateRange = queryKey[2]?.serviceProvisionDateRange
  params.commentDateRange = queryKey[2]?.commentDateRange
  params.displayContent = queryKey[2]?.displayContent || null
  params.showParentCommentOnly = "1"

  const queryParams = removeBlankAttributes(params)
  return API.get(`/parent-comments`, {
    params: queryParams,
  })
}

export const markAsRead = async (id: number) => {
  return API.put(`/parent-comments/${id}/mark-read?read_by_facility=1`)
}
