import { ComponentProps } from "react"

type Props = {
  name: string
  facilityCount: number
  managementResult: number
} & ComponentProps<"p">

export function HeaderChildName({
  name,
  facilityCount,
  className,
  managementResult,
  style = {},
  ...props
}: Props) {
  return (
    <p
      className={`stat-info no-print ${className}`}
      style={{ marginBottom: 8, ...style }}
      {...props}
    >
      {name}
      <span className={"red-text"}>
        <span style={{ marginLeft: 20 }}>{"利用事業所数(含自事業所)："}</span>
        <span className={"bold-text"}>{facilityCount}</span>
        <span style={{ marginLeft: 20 }}>{"上限額管理結果："}</span>
        <span className={"bold-text"}>{managementResult}</span>
      </span>
    </p>
  )
}
