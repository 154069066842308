import React, { useContext, useEffect, useMemo } from "react"

// packages
import { t } from "i18next"
import moment from "moment"
import { useFormik } from "formik"

// styled components
import {
  DashedDivider,
  FilterOperationContent,
  FilterBlockExtraContent,
  FilterOperationContainer,
} from "../styles"

// components
import { OwnerButton, QuoteText } from "../../../atoms"
import {
  SelectInput,
  TopRoundedWhiteCard,
  setItemToLocalstorage,
} from "@project/shared"
import { DatePicker, OperationFieldWrapper } from "../../../molecules"

// utils
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"

// types
import { FilterOperationBlockProps } from "../types"
import { useUpdateSearchParams } from "../../../../utils/useUpdateSearchParams"
import { japaneseAlphaRegex } from "../../../../utils/validation"
import { AuthContext } from "../../../../utils"
import { hasPermissionForFacility } from "../../../../utils/SidebarUtils"
import { ProgramProgressRecordPermission } from "../../../../utils/PermissionKeys"

export const FilterActionBlock: React.FC<FilterOperationBlockProps> = ({
  facilityOptions,
  rawChildOptions,
  isChildListLoading,
  currentSearchQueries,
  handleCurrentSearchQueriesChange,
}) => {
  const {
    year,
    month,
    childId: currentChildId,
    facilityId: currentFacilityId,
  } = currentSearchQueries
  const [updateParams] = useUpdateSearchParams()

  const { permissions, isOwner } = useContext(AuthContext)

  const hasCreateAccess = useMemo(() => {
    // if owner return true
    if (isOwner) return true
    if (currentFacilityId) {
      // if facility is selected return based on selected facility permission
      return hasPermissionForFacility(
        permissions,
        ProgramProgressRecordPermission,
        "write",
        currentFacilityId
      )
    }

    for (let i = 0; i < facilityOptions.length; i++) {
      // by default return true if has permission for any facility
      if (
        hasPermissionForFacility(
          permissions,
          ProgramProgressRecordPermission,
          "write",
          facilityOptions[i]?.value
        )
      ) {
        return true
      }
    }

    return false
  }, [permissions, isOwner, currentFacilityId])

  const formik = useFormik({
    initialValues: {
      date: moment({
        year: +year,
        month: +month,
      }).format("YYYY-MM"),
      furiganaSort: null,
      childId: currentChildId,
      facilityId: currentFacilityId,
    },
    onSubmit: (values) => {
      const { childId, facilityId } = values
      setItemToLocalstorage("program-progress-record-list", {
        year: +moment(values.date).format("YYYY"),
        month: +moment(values.date).format("MM"),
        childId,
        facility: facilityId,
      })
      handleCurrentSearchQueriesChange({
        year: +moment(values.date).format("YYYY"),
        month: +moment(values.date).format("MM"),
        childId,
        facilityId,
      })
    },
    onReset: (values) => {
      const { childId, facilityId } = values
      handleCurrentSearchQueriesChange({
        year: +moment(values.date).format("YYYY"),
        month: +moment(values.date).format("MM"),
        childId,
        facilityId,
      })
    },
  })
  const { values, setFieldValue, handleSubmit } = formik

  const handleReset = () => {
    setFieldValue("date", moment().format("YYYY-MM"))
    setFieldValue("furiganaSort", null)
    setFieldValue("childId", undefined)
    setFieldValue("facilityId", undefined)
    handleSubmit()
  }

  const getFilteredChildOptions = () => {
    if (rawChildOptions?.length > 0) {
      let options = rawChildOptions
      if (values.furiganaSort) {
        const regex = japaneseAlphaRegex(values.furiganaSort)
        options = rawChildOptions.filter(
          (child) =>
            child.furigana_name.match(regex) || child?.label.match(regex)
        )
      }
      return [{ label: "--", value: 0 }].concat(options)
    }
  }

  /**
   * We need our datePicker to be updated when the user changes the month
   * from the table header as well as when the user changes the month from
   * the datePicker itself.
   */
  useEffect(() => {
    setFieldValue(
      "date",
      moment({ year: +year, month: +month - 1 }).format("YYYY-MM")
    )
  }, [currentSearchQueries.month])

  // set facility if child has facility id
  useEffect(() => {
    if (values?.childId)
      setFieldValue(
        "facilityId",
        rawChildOptions?.find((child) => child.value === values?.childId)
          ?.facility_id
      )
  }, [values?.childId])

  return (
    <FilterOperationContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Operation options")}
      >
        {/* Operation block upper action sec starts here */}
        <FilterOperationContent className={"filter-operation-content flexbox"}>
          <QuoteText className={"quote-text"}>{t("Search ")}</QuoteText>
          <OperationFieldWrapper labelMinWidth={"100px"} label={t("Facility")}>
            <SelectInput
              name={"facilityId"}
              className={"facility-select dynamic-dropdown-width"}
              placeholder={t("All")}
              options={facilityOptions}
              value={values.facilityId}
              width={"auto"}
              dropdownMatchSelectWidth={false}
              // Issue was only in production, set disabled false to check if it works.
              disabled={false}
              onChange={(value) => {
                setFieldValue("facilityId", value)
              }}
            />
          </OperationFieldWrapper>

          <OperationFieldWrapper labelMinWidth={"100px"} label={t("Child")}>
            <div className={"flexbox child-div"}>
              <SelectInput
                placeholder={t("All")}
                width={60}
                height={40}
                value={values.furiganaSort}
                options={FuriganaAlphabetsOption}
                name={"furiganasort"}
                onChange={(val) => {
                  setFieldValue("furiganaSort", val)
                  setFieldValue("childId", getFilteredChildOptions()[0].value)
                }}
                className={"furigana-child-selector"}
              />
              <SelectInput
                width={"auto"}
                dropdownMatchSelectWidth={false}
                name={"childId"}
                className={"child-select dynamic-dropdown-width"}
                placeholder={t("All")}
                options={getFilteredChildOptions()}
                value={values.childId}
                defaultValue={values.childId}
                onChange={(value) => {
                  setFieldValue("childId", value)
                }}
                loading={isChildListLoading}
              />
            </div>
          </OperationFieldWrapper>

          <OperationFieldWrapper labelMinWidth={"100px"} label={t("Date")}>
            <DatePicker
              className={"date-picker"}
              name={"date"}
              style={{ minWidth: "150px" }}
              date={moment({
                year: Number(formik.values.date.substring(0, 4)),
                month: Number(formik.values.date.substring(5, 7)) - 1,
                day: 1,
              })}
              onDateChange={(value) => {
                setFieldValue("date", moment(value).format("YYYY-MM"))
              }}
              format={"YYYY年MM月"}
              picker={"month"}
            />
          </OperationFieldWrapper>

          <div className={"form-button-group flexbox"}>
            <OwnerButton
              text={t("Search")}
              typeOf={"search"}
              className={"search-button btn"}
              onClick={() => {
                handleSubmit()
              }}
            />
            <OwnerButton
              className={"btn"}
              text={t("Reset")}
              typeOf={"secondary"}
              onClick={handleReset}
            />
          </div>
        </FilterOperationContent>
        {/* Operation block upper action sec ends here */}
        {/* ------------------ */} <DashedDivider /> {/* ------------------ */}
        {/* Operation block lower action sec starts here */}
        <FilterBlockExtraContent className={""}>
          <QuoteText className={"quote-text"}>
            {t("Data registration")}
          </QuoteText>
          <OwnerButton
            text={t("Add program progress")}
            typeOf={"primary"}
            className={"add-program-progress-btn btn"}
            disabled={!hasCreateAccess}
            onClick={() => {
              updateParams(
                {
                  date: values.date,
                  facility_id: values.facilityId,
                  child_id: values.childId,
                },
                " program-progress-record-list/add"
              )
            }}
          />
        </FilterBlockExtraContent>
        {/* Operation block lower action sec ends here */}
      </TopRoundedWhiteCard>
    </FilterOperationContainer>
  )
}
