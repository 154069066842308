import { API } from "@project/shared"

export interface IReturnJointFacilityStatus {
  count?: number
  data?: any
}

interface IJointFacilityStatusParam {
  page?: string
  pageSize?: string
  keyword?: string
  year?: string
  facility_id?: number
  facility_other_id?: number
  month?: string
}

export const fetchJointFacilityStatus = async ({
  queryKey,
}): Promise<IReturnJointFacilityStatus> => {
  const params = {} as IJointFacilityStatusParam
  params.page = queryKey[1]
  params.year = queryKey[2]?.year ? queryKey[2].year : undefined
  params.month = queryKey[2]?.month ? queryKey[2].month : undefined
  if (queryKey[2]?.facility) {
    if (queryKey[2]?.facilityType == "facility")
      params.facility_id = queryKey[2].facility
    else if (queryKey[2]?.facilityType == "jointfacility")
      params.facility_other_id = queryKey[2].facility
  }
  params.pageSize = "20"
  return API.get(`/facility-other-management`, {
    params,
  })
}

export const getJointFacilityStatus = async (id) => {
  return API.get(`/facility-other-management/${id}`)
}

export const getOtherFacilityChildren = async (id, params) => {
  return API.get(`/facility-other-management/${id}/children`, {
    params,
  })
}

export const createJointFacilityStatus = async (values): Promise<any> => {
  return API.post(`/facility-other-management`, values)
}

export const createJointFacilityStatusBasicInformation = async (
  values
): Promise<any> => {
  return API.post(`/facility-other-management/create-basic-information`, values)
}

export const updateJointFacilityStatusBasicInformation = (values) =>
  API.put(
    `/facility-other-management/create-basic-information/${values.id}`,
    values
  )

export const updateJointFacilityStatus = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/facility-other-management/${id}`, values)
}

export const deleteJointFacilityStatus = async (id) => {
  return API.delete(`/facility-other-management/${id}`)
}
export const copyLastMonthJointFacilityData = ({
  year,
  month,
}: {
  year: string
  month: string
}) => {
  const dataOfYear = +month <= 1 ? +year - 1 : +year
  const dataOfMonths = +month <= 1 ? 12 : +month - 1
  return API.get(
    `/facility-other-management/copy/${dataOfYear}/${dataOfMonths}`,
    {
      params: {
        year,
        month,
      },
    }
  )
}
