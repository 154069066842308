// components
import { WithSorter } from "../../../atoms"
import { Checkbox } from "@project/shared"

// utils
import { t } from "i18next"

const useTable = ({
  transferAccount,
  handleRowSelection,
}: {
  transferAccount?: number
  handleRowSelection: (id: string) => void
}) => {
  const extraColumns: any = [
    {
      title: t("Account holder name"),
      dataIndex: "account_holder_name",
      key: "account_holder_name",
      width: 200,
    },
    {
      title: t("Account type"),
      dataIndex: "account_type",
      key: "account_type",
      width: 180,
    },
    {
      title: t("Branch name"),
      dataIndex: "branch_name",
      key: "branch_name",
      width: 180,
    },
    {
      title: t("Bank number"),
      dataIndex: "bank_no",
      key: "bank_no",
      width: 180,
    },
  ]

  const defaultColumns = [
    {
      title: t("Check all "),
      dataIndex: "check_all",
      key: "check_all",
      width: 120,
      className: "ant-table-selection-column",
      render: (_, rec) => {
        return (
          <Checkbox
            checked={rec.checked}
            disabled={rec.isDisabled}
            onChange={() => {
              handleRowSelection(rec.id)
            }}
          />
        )
      },
    },
    {
      title: (props) => {
        return (
          <WithSorter
            columnKey={"parent_name_hiraga"}
            titleProps={props}
            title={"Parent name (Hiraga)"}
            key={"parent_name_hiraga"}
          />
        )
      },
      dataIndex: "parent_name_hiraga",
      key: "parent_name_hiraga",
      width: 339,
      sorter: (a, b) =>
        a.parent_name_hiraga.localeCompare(b.parent_name_hiraga),
    },
    {
      title: (props) => {
        return (
          <WithSorter
            columnKey={"child_name_hiraga"}
            titleProps={props}
            title={"Child name (Hiraga)"}
            key={"child_name_hiraga"}
          />
        )
      },
      dataIndex: "child_name_hiraga",
      key: "child_name_hiraga",
      width: 341,
      sorter: (a, b) => a.child_name_hiraga.localeCompare(b.child_name_hiraga),
    },
    {
      title: (props) => {
        return (
          <WithSorter
            columnKey={"beneficiary_certificate_number"}
            titleProps={props}
            title={"Beneficiary certificate number"}
            key={"beneficiary_certificate_number"}
          />
        )
      },
      dataIndex: "beneficiary_certificate_number",
      key: "beneficiary_certificate_number",
      width: 160,
      sorter: (a, b) =>
        a.beneficiary_certificate_number.localeCompare(
          b.beneficiary_certificate_number
        ),
    },
    {
      title: (props) => {
        return (
          <WithSorter
            columnKey={"facility"}
            titleProps={props}
            title={"Facility"}
            key={"facility"}
          />
        )
      },
      dataIndex: "facility",
      key: "facility",
      width: 142,
      sorter: (a, b) => a.facility.localeCompare(b.facility),
    },
    {
      title: (props) => {
        return (
          <WithSorter
            columnKey={"invoice_amount"}
            titleProps={props}
            title={"Invoice amount"}
            key={"billed_amount"}
          />
        )
      },
      dataIndex: "billed_amount",
      key: "billed_amount",
      width: 141,
      render: (_, row) => {
        return (
          <span>
            {row?.billed_amount.toLocaleString()}
            {"円"}
          </span>
        )
      },
      sorter: (a, b) => a.invoice_amount - b.invoice_amount,
    },
  ]

  let tableColumns = defaultColumns

  if (transferAccount) {
    tableColumns.push(...extraColumns)
  } else {
    tableColumns = defaultColumns
  }

  return {
    tableColumns: defaultColumns,
  }
}

export default useTable
