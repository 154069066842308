import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background-color: ${theme.base};
  padding: 1rem;
  margin-top: 2.5rem;
  .list__card {
    border-radius: 12px;
    padding: 0 !important;
    .title {
      border-bottom: 1px solid ${theme.gray2};
      padding: 0.4rem 0;
      margin-bottom: 0.8rem;
      width: 70%;
    }

    @media screen and (max-width: ${theme.breakpoints.md}) {
      .title {
        width: 100% !important;
        font-size: 12px;
      }
    }
  }
  .btn__cta {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    @media screen and (max-width: 426px) {
      gap: 0;
      button:last-child {
        margin-left: 1rem;
      }
    }
  }
  .list__table {
    margin-bottom: 2rem;
  }
  .list__table_2 {
    .ant-select-arrow {
      right: 5px;
    }
  }
`
