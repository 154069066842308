import { API, removeBlankAttributes } from "@project/shared"

export interface IReturnParentContact {
  count?: number
  data?: any
}

interface IParentContactParam {
  page?: string
  pageSize?: string
  displayContent: string
  parentId: string
  childId: string
  facilityIds: string
  readStatus: string
  dateOfContact: string
  keyword: string
  showParentCommentOnly?: string
}

export const fetchParentContact = async ({
  queryKey,
}): Promise<IReturnParentContact> => {
  const params = {} as IParentContactParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.displayContent = queryKey[2]?.displayContent
  params.parentId = queryKey[2]?.parentId
  params.childId = queryKey[2]?.childId
  params.facilityIds = queryKey[2]?.facilityIds
  params.readStatus = queryKey[2]?.status
  params.dateOfContact = queryKey[2]?.dateOfContact
  params.keyword = queryKey[2]?.keyword
  const p = removeBlankAttributes(params)
  return API.get(`/parent-contacts`, {
    params: p,
  })
}

export const fetchAllParentContact = async ({
  queryKey,
}): Promise<IReturnParentContact> => {
  const params = {} as IParentContactParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.displayContent = queryKey[2]?.displayContent
  params.parentId = queryKey[2]?.parentId
  params.childId = queryKey[2]?.childId
  params.facilityIds = queryKey[2]?.facilityIds
  params.readStatus = queryKey[2]?.status
  params.dateOfContact = queryKey[2]?.dateOfContact
  params.keyword = queryKey[2]?.keyword
  params.showParentCommentOnly = "2"
  return API.get(`/all-parent-contacts`, {
    params,
  })
}

export const addChat = async (values) => {
  return API.post(`/parent-contacts`, values)
}

export const fetchParentChat = async (
  childId
): Promise<IReturnParentContact> => {
  return API.get(
    `/parent-contacts?page=1&pageSize=Infinity&childId=${childId}&sort=first`
  )
}

export const deleteComment = async (id): Promise<IReturnParentContact> => {
  return API.delete(`/parent-contacts/${id}`)
}
