// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

export const JsxStyledContent = styled.div`
  &.jsx-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;
    margin: 0;

    .content_sign-off {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .sign-off_signature-field {
        width: 137px;
        padding-bottom: 12px;
        border-bottom: 1px solid ${theme.gray2};
      }

      @media (max-width: 425px) {
        width: 100%;
        .sign-off_signature-field {
          width: 100%;
        }
      }

      @media print {
        .sign-off_signature-field {
          border-bottom: 1px solid ${theme.black};
        }
      }
    }
  }
`

export const MainContainer = styled.div`
  /* In this styled container I am mostly 
  stylizing common 
  styles throughout the page */
  .field {
    text-align: justify;
  }
  .flexbox {
    display: flex;
    align-items: center;
    column-gap: 20px;
    flex-wrap: nowrap;
    row-gap: 10px;
    color: #191919;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .mid-button-group {
    flex-wrap: wrap;
    row-gap: 10px;
    margin: 32px 0px;
    .print-btn {
      background: ${theme.blue5};
      border: 1px solid ${theme.blue5};
      &:hover {
        color: ${theme.base};
      }
    }
    @media (max-width: ${theme.breakpoints.xs}) {
      .back-btn {
        margin-right: 10px;
      }
    }
    @media print {
      display: none;
    }
  }

  .white-card {
    padding: 13px 30px 39px 30px;
    .agreement-sign-off {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      p {
        font-size: 16px;
        color: ${theme.black};
        margin: 0;
      }

      .agreement-sign-off_signature {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: max-content;
        .sign-off_signature-field {
          width: 267px;
          padding-bottom: 12px;
          border-bottom: 1px solid ${theme.gray2};
          white-space: break-spaces;
        }

        @media (max-width: 425px) {
          width: 100%;
          .sign-off_signature-field {
            width: 100%;
          }
        }

        @media print {
          .sign-off_signature-field {
            border-bottom: 1px solid ${theme.black};
          }
        }
      }

      @media print {
        margin-bottom: 25px !important;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 15px 20px;
    }
    @media print {
      padding: 0px;
      box-shadow: none;
    }
  }

  @media print {
    @page {
      margin: 5mm 0;
    }
  }
`

export const InputGroup = styled.div<{ $currentLang: "en" | "ja" }>`
  ${MainContainer}
  &.input-group {
    align-items: flex-start;
    .label.caret-right {
      background: url("/assets/icons/caret-right.svg") no-repeat -3px center / 12px
        12px;
      padding-left: 14px;
      font-size: 16px;
      font-weight: 500;
      min-width: ${(props) =>
        props.$currentLang === "en" ? "326px" : "226px"};
    }
    .input-field {
      .ant-checkbox-group {
        text-indent: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 20px;
        label.ant-checkbox-wrapper {
          margin-bottom: 0px !important;
          margin-left: 0px !important;
          white-space: normal;
          gap: 0;
          .ant-checkbox {
            margin-right: 10px;
          }
        }
      }
    }
    @media (max-width: 866px) {
      flex-wrap: wrap;
    }
  }
`

export const OperationControllerContainer = styled.div`
  margin-top: 40px;

  .white-card {
    padding: 13px 30px 39px 30px;
    .operation-controller-content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      margin-top: 17px;
      margin-bottom: 24px;
    }

    .footer-btns-group {
      flex-wrap: wrap;
      .search-btn {
        padding: 9px 30px;
        @media (max-width: ${theme.breakpoints.xs}) {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 15px 20px;
    }
  }

  @media print {
    display: none;
  }
`

export const RecordContainer = styled.div`
  .white-card {
    .title-wrapper {
      border: none;
    }
    .pagination {
      justify-content: flex-end;
      margin-top: 0px;
    }

    .first-record-content {
      border: 1px solid ${theme.gray2};
      padding: 20px;
      margin-top: 30px;

      .first-record-content_head {
        justify-content: space-between;
        flex-wrap: wrap;
        h3 {
          font-size: 20px;
          font-weight: 500;
          color: ${theme.black};
        }
        .head_record-info {
          .record-info_individual {
            gap: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            .label {
              color: ${theme.black};
            }
            .value {
              color: ${theme.black};
            }
          }
        }

        @media (max-width: 640px) {
          flex-wrap: wrap;
          h3 {
            width: 100%;
            text-align: left;
          }
        }
      }

      .agreement-sign-off {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        @media print {
          row-gap: 10px;
          page-break-inside: avoid;
        }
        margin-top: 10px;
        margin-bottom: 40px;
        p {
          font-size: 16px;
          color: ${theme.black};
          margin: 0;
        }
        .agreement-sign-off_consent-date,
        .agreement-sign-off_parent-name {
          width: 267px;
          padding-bottom: 12px;
          border-bottom: 1px solid ${theme.gray2};
        }
        .agreement-sign-off_parent-name {
          position: relative;
          .mark {
            position: absolute;
            right: -26px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .record-list-content {
        .fieldGen-field-wrapper {
          .field {
            flex: 1;
            .fieldGen-children-container {
              width: 100%;
            }
          }
        }

        @media (max-width: ${theme.breakpoints.xs}) {
          overflow: auto;
          .label {
            height: 30px;
            min-width: auto;
          }
        }

        @media print {
          display: table;
          width: 100%;
          /** while using display:table the last actual 
            * row within this container do not seem to display border-bottom
            * so I am using border-bottom on the container itself 
          */
          border-bottom: 1px solid ${theme.black};
          .fieldGen-field-wrapper {
            border-color: ${theme.black};
            display: table-row;
            .field {
              padding: 10px 15px;
              font-size: 12px;
              display: table-cell;
            }
            .label {
              padding: 10px 15px;
              font-size: 12px;
              display: table-cell;
              border-color: ${theme.black};
            }
          }
        }
      }

      .confirm-agreement {
        margin-top: 40px;
        margin-bottom: 19px;

        @media print {
          margin-top: 25px !important;
        }
      }

      @media print {
        padding: 20px 25px;
        /* // ! [Note]: This property is important for printing multiple records in one page */
        /* // ! however setting this property causes a blank page to be printed at the end of the document */
        page-break-after: auto;
        page-break-inside: avoid;
        border: none;
        margin-top: 0;
      }
      @media print and (orientation: landscape) {
        padding: 0px 25px;
      }
    }

    @media print {
      .title-wrapper {
        display: none;
      }
    }
  }
`
