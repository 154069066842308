import {
  SelectInput,
  getYearList,
  Checkbox,
  theme,
  removeBlankAttributes,
} from "@project/shared"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Highlighter, Title, TitleWrapper } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

interface iSelfEvaluationOperationComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  id?: any
  handleSearch?: (value: string) => void
  setIsButtonClicked?: React.Dispatch<any>
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      serviceId: string
      year: string
      facilityIds: string | number
    }>
  >
  queryParam?: {
    serviceId: string
    year: string
    facilityIds: string | number
  }
  isLoadingData?: boolean
  year?: string
  setYear?: React.Dispatch<string>
  facilityIds?: string | number
  setFacilityIds?: React.Dispatch<string>
  FACILITY_OPTION: {
    value: string
    label: string
  }[]
}

const Wrapper = styled.div<iSelfEvaluationOperationComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
  .title-wrapper {
    min-width: 125px;
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .f_input_picker {
    width: 300px;
  }
  .year_input_picker {
    width: 100px;
  }
  .f_input_picker,
  .year_input_picker {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      width: 300px;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      min-width: 100% !important;
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .text-field {
    width: 100%;
  }

  input {
    background-color: #ffffff;
    width: 712px;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .ant-checkbox {
      margin-right: 7px;
    }
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`
const Text = styled(Title)`
  font-size: 16px;
`

const SelfEvaluationOperationComponent: React.FC<
  iSelfEvaluationOperationComponent
> = (props) => {
  const {
    header,
    setQueryParam,
    isLoadingData,
    year,
    facilityIds,
    setYear,
    setFacilityIds,
    FACILITY_OPTION,
    setIsButtonClicked,
    id,
  } = props
  const { t } = useTranslation()
  const [schoolDay, setSchoolDay] = useState(null)
  const [childDevelopment, setchildDevelopment] = useState(null)
  const [userServiceIds, setUserServiceIds] = useState(null)
  const [updateParams] = useUpdateSearchParams()

  const handleSearch = () => {
    setIsButtonClicked(true)
    setQueryParam((prev) => {
      return {
        ...prev,
        year: year,
        facilityIds: facilityIds,
        serviceId: userServiceIds,
      }
    })
    const queries = removeBlankAttributes({
      year,
      facilityIds,
      serviceId: userServiceIds,
    })
    updateParams(queries, `/self-assessment-sheet/${id}/results`)
  }

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Facility")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <div className={"f_input_picker"}>
            <SelectInput
              height={40}
              key={"footerInformation"}
              name={"footerInformation"}
              value={facilityIds}
              className={"select-up"}
              onChange={(values) => {
                setFacilityIds(values)
              }}
              options={FACILITY_OPTION}
              placeholder={t("All")}
            />
          </div>
        </DateSelectWrap>
      </FieldWrapper>

      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("F year")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <div className={"year_input_picker"}>
            <SelectInput
              height={40}
              options={getYearList()}
              className={"select-up"}
              onChange={(evt) => {
                setYear(String(evt))
              }}
              value={year}
              defaultValue={"--"}
            />
          </div>
        </DateSelectWrap>
      </FieldWrapper>
      <FieldWrapper>
        <TitleWrap className={"title-wrapper"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Service")} </Text>
        </TitleWrap>
        <DateSelectWrap>
          <SelectWrap>
            <Checkbox
              key={"footerInformation"}
              name={"footerInformation"}
              checked={schoolDay}
              label={t("After day school service")}
              onChange={(evt) => {
                if (userServiceIds !== "1") {
                  setSchoolDay(evt.target.checked)
                  setchildDevelopment(!evt.target.checked)
                  setUserServiceIds("1")
                } else {
                  setchildDevelopment(null)
                  setSchoolDay(null)
                  setUserServiceIds(null)
                }
              }}
            />
            <Checkbox
              key={"footerInformation"}
              name={"footerInformation"}
              checked={childDevelopment}
              label={t("Child development service")}
              onChange={(evt) => {
                if (userServiceIds !== "2") {
                  setchildDevelopment(evt.target.checked)
                  setSchoolDay(!evt.target.checked)
                  setUserServiceIds("2")
                } else {
                  setchildDevelopment(null)
                  setSchoolDay(null)
                  setUserServiceIds(null)
                }
              }}
            />
          </SelectWrap>
        </DateSelectWrap>
      </FieldWrapper>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { SelfEvaluationOperationComponent }
