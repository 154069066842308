import { API } from "@project/shared"

const BASE_URL = "/child-attendance-chart/child"

interface IEditIndividualFacility {
  values: any
  childId: number
  year: number
  month: number
}

export const getIndividualFacility = async (
  childId: number,
  year: number,
  month: number
) => {
  return API.get(`${BASE_URL}/${childId}/${year}/${month}`)
}

export const editIndividualFacility = async ({
  values,
  childId,
  year,
  month,
}: IEditIndividualFacility) => {
  return API.post(`${BASE_URL}/${childId}/${year}/${month}`, values)
}
