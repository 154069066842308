import {
  BreadCrumb,
  Checkbox,
  TopRoundedWhiteCard,
  theme,
} from "@project/shared"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useTodaySituationBulkPrintContext } from "../../../context/TodaySituationBulkPrintContext"
import { OwnerButton, PageContainer, PageHeader } from "../../atoms"
import { UserServiceProvisionResultTodaySituationOperation } from "../UserServiceProvisionResultTodaySituationOperation"
import { Skeleton } from "antd"
import { UserServiceRecordTodaySituationChildTable } from "./UserServiceRecordTodaySituationChildTable"

import { UserServiceProvisionResultTodaySituationPrintPreview } from "./UserServiceProvisionResultTodaySituationPrintPrivew/UserServiceProvisionResultTodaySituationPrintPrivew"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

const BackButtonContainer = styled.div`
  margin: 20px 0;
  button {
    background: rgb(205, 230, 244);
    color: #000000;
    border-color: ${theme.blue5};
  }
`
const UserServiceProvisionResultTodaySituation = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const {
    selectedChilds,
    setSelectedChilds,
    printData,
    isLoading,
    contentRef,
    handleBulkCheck,
    facility_options,
    initialState,
    handleSearch,
  } = useTodaySituationBulkPrintContext()

  const breadcrumbItem = [
    {
      key: "home",
      name: t("Home"),
      href: "/",
    },
    {
      key: "user-service-provision record",
      name: t("Service provision record/Care record/Life record"),
      href: "/user-service-provision-result",
    },
    {
      key: "bulk-print",
      name: t("Bulk printing of today's event"),
    },
  ]

  const CHILD_HEADER = [
    {
      key: "select",
      title: t("Select"),
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            value={row?.child_id}
            checked={selectedChilds?.includes(row?.child_id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedChilds([...selectedChilds, e?.target?.value])
              } else {
                setSelectedChilds(
                  selectedChilds.filter((child) => child != e?.target?.value)
                )
              }
            }}
          />
        </div>
      ),
    },
    {
      key: "child_name",
      title: t("Child name (Hiragana)"),
      render: (row) => <>{row?.child_name_furigana}</>,
    },
    {
      key: "facility",
      title: t("Use facility"),
      render: (row) => <>{row?.facility}</>,
    },
    {
      key: "service",
      title: t("Use service"),
      render: (row) => (
        <>
          {row?.service == 1
            ? t("After school day service")
            : row?.service == 2
            ? t("Child development support")
            : ""}
        </>
      ),
    },
  ]

  return (
    <>
      {router?.query?.mode !== "preview" ? (
        <PageContainer>
          <BreadCrumb items={breadcrumbItem} />
          <PageHeader
            title={t("Bulk printing of today's event")}
            icon={<img src={"/assets/icons/Cylinder.svg"} alt={"icon"} />}
          />
          <UserServiceProvisionResultTodaySituationOperation
            facility_options={facility_options}
            initial_state={initialState}
            handleSearch={handleSearch}
          />
          <BackButtonContainer>
            <OwnerButton
              typeOf={"primary"}
              text={`<< ${t("Back")}`}
              className={"back-button"}
              onClick={() => {
                router.push(`/user-service-provision-result`)
              }}
            />
          </BackButtonContainer>
          <div ref={contentRef}>
            {isLoading ? (
              <TopRoundedWhiteCard title={`${t("Choose a child to print")}`}>
                <br />
                <Skeleton active />
                <Skeleton active />
              </TopRoundedWhiteCard>
            ) : (
              <>
                <UserServiceRecordTodaySituationChildTable
                  childTableColumn={CHILD_HEADER}
                  childData={printData?.data}
                  selectChilds={selectedChilds}
                  checkAction={handleBulkCheck}
                  printAction={() => {
                    const query = router?.query ? { ...router?.query } : {}
                    updateParams(
                      {
                        mode: "preview",
                        ...query,
                      },
                      router?.pathname,
                      true
                    )
                  }}
                />
              </>
            )}
          </div>
        </PageContainer>
      ) : (
        <UserServiceProvisionResultTodaySituationPrintPreview />
      )}
    </>
  )
}

export { UserServiceProvisionResultTodaySituation }
