import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  CALANDER_HOURS_MINUTES,
  DeleteConfimationModal,
  Modal,
  SelectInput,
  Table,
  TextField,
  convertToHalfWidth,
  theme,
} from "@project/shared"
import { Button, OwnerButton, Tag } from "../../atoms"
import {
  Wrapper,
  WorkingHoursContainer,
  ShiftContainer,
} from "./AttendanceScheduleRecord.styles"
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons"
import { fetchInstructors, getAttendanceShifts } from "../../../services"
import moment from "moment"
import { useQuery } from "react-query"
import { AuthContext } from "../../../utils"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { AttendanceSchedulePermission } from "../../../utils/PermissionKeys"

interface IProps {
  date: string
  results: any
  onPaginateByDate: (val: string) => void
  createDate: any
  onMutation: (val: any) => void
  isLoading?: boolean
  facilityId: undefined | number
}

export const AttendanceScheduleRecord: React.FC<IProps> = ({
  date,
  results,
  createDate,
  onPaginateByDate,
  onMutation,
  isLoading,
  facilityId,
}) => {
  const { t } = useTranslation()
  const [page] = React.useState(1)
  const [openListModal, setOpenListModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState<number | null>(null)
  const [dataSource, setDataSource] = useState<any[]>(results?.data || [])

  const selectedStaffObj = useRef(null)
  const { isOwner, permissions } = useContext(AuthContext)

  const hasEditPermission = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AttendanceSchedulePermission,
        "write",
        facilityId
      ),
    [isOwner, permissions, facilityId]
  )

  const { data: response } = useQuery({
    queryKey: ["instructors", page],
    queryFn: () => fetchInstructors({ page, pageSize: "Infinity" }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (resp) => {
      const facilityRelatedStaffs = []
      resp?.data?.map((data: any) => {
        const hasFacility = data?.staff_facility?.find(
          (fac) =>
            fac?.facility_id == facilityId &&
            moment()
              .set({ year: fac?.year, month: fac?.month - 1, date: 1 })
              .isBefore(date, "day")
        )
        if (hasFacility) facilityRelatedStaffs.push(data)
      })
      return { ...resp, data: facilityRelatedStaffs }
    },
  })
  const { isLoading: isShiftLoaing, data: shifts } = useQuery({
    queryKey: ["attedance-shifts"],
    queryFn: () => getAttendanceShifts(facilityId),
    enabled: !!facilityId,
    select: (res) => {
      return res?.data?.map((val) => ({
        label: val?.attendance_shift_name,
        value: val?.id,
        attendance_start_time: val?.attendance_start_time,
        attendance_end_time: val?.attendance_end_time,
        attendance_rest_minits: +val?.attendance_rest_minits,
        attendance_start_time2: val?.attendance_start_time_2,
        attendance_end_time2: val?.attendance_end_time_2,
        attendance_rest_minits2: +val?.attendance_rest_minits_2,
      }))
    },

    refetchOnWindowFocus: false,
  })
  //update state->datasource when table cell value chnage
  const handleValueChange = ({
    rowId,
    key,
    value,
    extra = {},
  }: {
    rowId: number
    key: string
    value: string
    extra?: any
  }) => {
    const data = [...dataSource]?.map((val) =>
      (val?.id || val?.tempId) === rowId
        ? { ...val, [key]: value, ...extra }
        : val
    )
    setDataSource(data)
  }

  const renderWorkingHours = (
    row,
    hasSecondWorkingHours: boolean,
    workingHours: number
  ) => {
    return (
      <WorkingHoursContainer>
        <div
          className={
            workingHours === 1
              ? "hours_ranges_container"
              : "hours_ranges_container with-border"
          }
        >
          <div className={"hour-text"}>
            <span>
              {workingHours === 1 ? t("Working hour 1") : t("Working hour 2")}
            </span>
            {workingHours === 1 ? (
              <Tag backgroundColor={theme.red2}>{t("Required")}</Tag>
            ) : (
              ""
            )}
          </div>
          <div className={"range"}>
            <div className={"start_date"}>
              <SelectInput
                width={70}
                height={40}
                options={CALANDER_HOURS_MINUTES?.hours}
                value={row?.attendance_start_time?.split(":")[0]}
                onChange={(val) => {
                  handleValueChange({
                    rowId: row?.id,
                    key: row?.startKey,
                    value: `${val}:${
                      !row?.attendance_start_time?.split(":")[1]
                        ? "00"
                        : row?.attendance_start_time?.split(":")[1]
                    }`,
                  })
                }}
                placeholder={"---"}
                className={"left-align"}
                padding={"0px 5px"}
              />
              <span>{":"}</span>
              <SelectInput
                width={70}
                height={40}
                options={CALANDER_HOURS_MINUTES?.minutes}
                value={row?.attendance_start_time?.split(":")[1]}
                disabled={!row?.attendance_start_time?.split(":")[0]}
                onChange={(val) =>
                  handleValueChange({
                    rowId: row?.id,
                    key: row?.startKey,
                    value: `${
                      row?.attendance_start_time?.split(":")[0]
                    }:${val}`,
                  })
                }
                placeholder={"---"}
                className={"left-align"}
                padding={"0px 5px"}
              />
            </div>
            <span>{"~"}</span>
            <div className={"end_date"}>
              <SelectInput
                width={70}
                height={40}
                options={CALANDER_HOURS_MINUTES?.hours}
                range={"from"}
                unit={["hours", "minutes"]}
                value={row?.attendance_end_time?.split(":")[0]}
                onChange={(val) =>
                  handleValueChange({
                    rowId: row?.id,
                    key: row?.endKey,
                    value: `${val}:${
                      !row?.attendance_end_time?.split(":")[1]
                        ? "00"
                        : row?.attendance_end_time?.split(":")[1]
                    }`,
                  })
                }
                placeholder={"---"}
                className={"left-align"}
                padding={"0px 5px"}
              />
              <span>{":"}</span>
              <SelectInput
                width={70}
                height={40}
                options={CALANDER_HOURS_MINUTES?.minutes}
                range={"from"}
                unit={["hours", "minutes"]}
                value={row?.attendance_end_time?.split(":")[1]}
                disabled={!row?.attendance_end_time?.split(":")[0]}
                onChange={(val) =>
                  handleValueChange({
                    rowId: row?.id,
                    key: row?.endKey,
                    value: `${row?.attendance_end_time?.split(":")[0]}:${val}`,
                  })
                }
                placeholder={"---"}
                className={"left-align"}
                padding={"0px 5px"}
              />
            </div>
          </div>
          <div className={"break_time"}>
            <p>{t("Break time")}</p>
          </div>
          <div className={"add_cta"}>
            <div className={"break_time_input"}>
              <div>
                <div className={"input"}>
                  <TextField
                    bgcolor={"transparent"}
                    padding={"8px 6px"}
                    value={row?.attendance_rest_minits}
                    onChange={(event) => {
                      if (
                        Number(event?.currentTarget?.value) < 1000 &&
                        Number(event?.currentTarget?.value) > -1
                      )
                        handleValueChange({
                          rowId: row?.id,
                          key: row?.minitsKey,
                          value: convertToHalfWidth(
                            event?.currentTarget?.value
                          ),
                        })
                    }}
                  />
                </div>
                <span>{t("minutes")}</span>
              </div>
            </div>
            <div className={"add_new_range"}>
              <span>{t("(up to 3 half-width digits)")}</span>
              {!hasSecondWorkingHours ? (
                <div
                  className={"btn"}
                  onClick={() => {
                    setDataSource(
                      dataSource?.map((val) =>
                        val?.tempId === row?.id
                          ? {
                              ...val,
                              attendance_start_time2: "00:00",
                              attendance_end_time2: "00:00",
                            }
                          : val
                      )
                    )
                  }}
                >
                  <PlusCircleFilled className={"add-icon"} />
                  <span>{t("Add working hours")}</span>
                </div>
              ) : workingHours === 2 ? (
                <div
                  className={"btn"}
                  onClick={() => {
                    setDataSource(
                      dataSource?.map((val) => {
                        return val?.tempId === row?.id
                          ? {
                              ...val,
                              attendance_start_time2: "",
                              attendance_end_time2: "",
                              attendance_rest_minits2: "",
                            }
                          : val
                      })
                    )
                  }}
                >
                  <MinusCircleFilled className={"remove-icon"} />
                  <span>{t("Remove working hours")}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </WorkingHoursContainer>
    )
  }

  const defaultColumns: any[] = useMemo(
    () => [
      {
        title: t("Day"),
        render: (_, __, index) => <span>{(page - 1) * 10 + index + 1}</span>,
        width: 10,
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>{t("Staff name")}</span>,
        key: "staff-name",
        render: (row) => {
          return {
            props: {
              style: { padding: `5px 5px` },
            },
            children: <span>{row?.staff?.staff_name}</span>,
          }
        },
      },
      {
        title: t("Shift"),
        key: "shift",
        render: (row) => {
          return {
            props: {
              style: { padding: `5px 5px` },
            },
            children: (
              <ShiftContainer>
                <SelectInput
                  options={shifts || []}
                  loading={isShiftLoaing}
                  height={40}
                  value={row?.attendance_shift_id}
                  onChange={(val) => {
                    const selected = shifts?.find((v) => v?.value === val)
                    handleValueChange({
                      extra: selected,
                      rowId: row?.id || row?.tempId,
                      value: val,
                      key: "attendance_shift_id",
                    })
                  }}
                />
              </ShiftContainer>
            ),
          }
        },
      },
      {
        title: t("Working hours"),
        key: "workinghours",
        render: (row) => {
          const hasSecondWorkingHours =
            row?.attendance_start_time2 && row?.attendance_end_time2

          return {
            props: {
              style: { padding: 0 },
            },
            children: (
              <>
                {renderWorkingHours(
                  {
                    id: row?.tempId,
                    attendance_start_time: row?.attendance_start_time,
                    attendance_end_time: row?.attendance_end_time,
                    attendance_rest_minits: row?.attendance_rest_minits,
                    minitsKey: "attendance_rest_minits",
                    startKey: "attendance_start_time",
                    endKey: "attendance_end_time",
                  },
                  hasSecondWorkingHours,
                  1
                )}
                {hasSecondWorkingHours
                  ? renderWorkingHours(
                      {
                        id: row?.tempId,
                        attendance_start_time: row?.attendance_start_time2,
                        attendance_end_time: row?.attendance_end_time2,
                        attendance_rest_minits: row?.attendance_rest_minits2,
                        minitsKey: "attendance_rest_minits2",
                        startKey: "attendance_start_time2",
                        endKey: "attendance_end_time2",
                      },
                      hasSecondWorkingHours,
                      2
                    )
                  : null}
              </>
            ),
          }
        },
      },
      {
        title: t("delete"),
        key: "operation",
        render: (row) => {
          return {
            props: {
              style: { whiteSpace: "nowrap" },
            },
            children: (
              <div
                style={{ color: theme.blue5, cursor: "pointer" }}
                onClick={() => {
                  setSelectedStaff(row?.id || row?.tempId || row?.staff?.id)
                  setOpenDeleteModal(true)
                }}
              >
                {t("Delete item")}
              </div>
            ),
          }
        },
      },
    ],
    [dataSource, shifts]
  )

  useEffect(() => {
    document
      .querySelectorAll<HTMLElement>(".each-staff-select")
      ?.forEach((elem) => {
        elem.style.color = "#000000"
      })
  }, [openListModal])

  return (
    <>
      <Wrapper>
        <div className={"top_content"}>
          <OwnerButton
            text={t("Add staff")}
            shape={"circle"}
            icon={"add"}
            typeOf={"outlined"}
            onClick={() => setOpenListModal(!openListModal)}
          />
          <div className={"pagi"}>
            <Button
              text={t("Prev day")}
              padding={10}
              backgroundColor={"transparent"}
              color={"inherit"}
              borderColor={theme.gray2}
              icon={"left-arrow"}
              onClick={() => {
                onPaginateByDate("prev")
              }}
            />
            <Button
              text={t("Today")}
              padding={10}
              backgroundColor={theme.blue5}
              borderColor={theme.gray2}
              iconPosition={"right"}
              color={"#ffffff"}
              onClick={() => {
                onPaginateByDate("today")
              }}
            />
            <Button
              text={t("Next day")}
              padding={10}
              backgroundColor={"transparent"}
              color={"inherit"}
              borderColor={theme.gray2}
              icon={"right-arrow"}
              iconPosition={"right"}
              onClick={() => {
                onPaginateByDate("next")
              }}
            />
          </div>
        </div>
        <Table
          columns={defaultColumns}
          dataSource={dataSource}
          minWidth={5}
          scroll={{ x: true }}
          rowClassName={(record) => {
            return `${record?.is_deleted ? "deleted_row" : ""} ${
              !hasEditPermission ? "disable-row-action" : ""
            } `
          }}
          bordered
        />
        <br />
        <OwnerButton
          text={t("Save")}
          shape={"circle"}
          isLoading={isLoading}
          disabled={isLoading || dataSource?.length <= 0 || !hasEditPermission}
          onClick={() => {
            const data = dataSource
            const finalData = data?.map(({ staff, ...val }) => {
              const values: any = {
                staff_id: staff?.id || val?.staff_id,
                facility_id: val?.facility_id,
                attendance_shift_id: val?.attendance_shift_id,
                date: val?.date
                  ? val?.date
                  : moment.utc(createDate.toLocaleString()),
                attendance_start_time: val?.attendance_start_time,
                attendance_end_time: val?.attendance_end_time,
                is_deleted: val?.is_deleted,
                attendance_rest_minits: parseInt(
                  val?.attendance_rest_minits,
                  10
                ),
              }
              if (val?.id && val?.id > 0) {
                // negative id is for newly created
                values.id = val?.id
              }
              if (val?.attendance_start_time2 && val?.attendance_end_time2) {
                values.attendance_start_time2 = val?.attendance_start_time2
                values.attendance_end_time2 = val?.attendance_end_time2
                values.attendance_rest_minits2 = +val?.attendance_rest_minits2
              }
              return values
            })
            onMutation(finalData)
          }}
        />
      </Wrapper>
      {/* //selecte new staff modal */}
      <Modal
        open={openListModal}
        onCancel={() => setOpenListModal(false)}
        title={t("Select staff")}
        onOk={() => {
          if (selectedStaffObj.current) {
            const updatedDataSource = dataSource?.filter(
              (d) => d?.staff?.id != selectedStaffObj?.current?.staff?.id
            )
            setDataSource([...updatedDataSource, selectedStaffObj.current])
            selectedStaffObj.current = null
          }
          setOpenListModal(false)
        }}
        okText={t("Set")}
        cancelText={t("Cancel")}
      >
        {response?.data?.map((val) => {
          // don't show staff in list if retriement date is set and less than attendance date or already in schedule list
          const staffCheck =
            (val?.retirement_date &&
              moment(val.retirement_date).isSameOrBefore(date, "day")) ||
            dataSource?.find(
              (data) =>
                (data?.staff?.id == val?.id || data?.staff_id == val?.id) &&
                !data?.is_deleted
            )
          if (!staffCheck)
            return (
              <div
                key={val?.id}
                onClick={() => {
                  document
                    .querySelectorAll<HTMLElement>(".each-staff-select")
                    ?.forEach((elem) => {
                      if (elem.id == `staff-${val?.id}`)
                        elem.style.color = theme.blue5
                      else elem.style.color = "#000000"
                    })

                  const shift = { ...shifts[0] } || {}
                  selectedStaffObj.current = {
                    // negative id for newly created
                    id: -1 * val?.id,
                    tempId: -1 * val?.id,
                    facility_id: facilityId,
                    staff: {
                      id: val?.id,
                      staff_name: val?.staff_name,
                    },
                    attendance_shift_id: shift?.value,
                    ...shift,
                  }
                }}
                id={`staff-${val?.id}`}
                className={"each-staff-select"}
                style={{ cursor: "pointer" }}
              >
                {val?.staff_name}
              </div>
            )
        })}
      </Modal>
      {/* delete staff modal */}
      <DeleteConfimationModal
        open={openDeleteModal}
        title={t("Delete")}
        footer={null}
        onCancel={() => {
          setSelectedStaff(null)
          setOpenDeleteModal(false)
        }}
        onOk={() => {
          const data = dataSource
            ?.filter((val) => val?.id)
            ?.map((val) =>
              val?.id === selectedStaff ? { ...val, is_deleted: true } : val
            )
          setDataSource(data)
          setSelectedStaff(null)
          setOpenDeleteModal(false)
        }}
      />
    </>
  )
}
