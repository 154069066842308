import {
  Checkbox,
  FileUploader,
  SelectInput,
  dynamicLangString,
  TextField,
} from "@project/shared"
import { notification } from "antd"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from "react-query"
import styled from "styled-components"
import { addChat, fetchInstructors } from "../../../services"
import { getChildById } from "../../../services/monitoring"
import { ErrorMessage } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iSendMessage {
  setData?: React.Dispatch<
    React.SetStateAction<
      (
        | {
            date: string
            message: string
            self: boolean
            read?: undefined
          }
        | {
            date: string
            message: string
            self: boolean
            read: boolean
          }
      )[]
    >
  >
  id?: string
  data?: any
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<
      {
        data: any
        count: number
      },
      unknown
    >
  >
}

const Container = styled.div`
  margin: 50px -30px -27px -30px;
  padding: 30px;
  background: #f3f3f3;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-top: 1px solid #0782c8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`
const SelectWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  & > div:last-child {
    margin-left: 25px;
  }
`
const TextWrapper = styled.div`
  margin-top: 22px;
  textarea {
    background-color: #ffffff !important;
  }
`
const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .warn {
    margin-top: 18px;
    display: flex;
  }
`
const CheckboxWrapper = styled.div`
  padding: 12px 16px 12px 24px;
  background: #e6e6e6;
  border: 1px solid #d2d1d1;
  border-radius: 5px;
  margin-top: 23px;
`
const ButtonWrapper = styled.div`
  margin-top: 18px;
  button {
    height: 50px;
  }
`
const FileUploadWrapper = styled.div`
  margin-top: 22px;
`

const SendMessageComponent: React.FC<iSendMessage> = ({ id, refetch }) => {
  const [conveyMessage, setConveyMessage] = useState("")
  const [type, setType] = useState("text")
  const [error, setError] = useState("")
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [staffName, setStaffName] = useState(null)
  const [otherFacilities, setOtherFacilities] = useState([])
  const [staffId, setStaffId] = useState("")
  const [notifyParent, setNotifyParent] = useState(false)
  const SORT_OPTION = FuriganaAlphabetsOption

  const { data } = useQuery(["fetchChildById", id], getChildById, {
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    refetchOnWindowFocus: false,
  })
  const { isLoading, data: staffList } = useQuery({
    queryKey: ["instructors"],
    queryFn: () => fetchInstructors({ page: 1 }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
  })
  const OTHER_STAFF_OPTIONS =
    !isLoading &&
    staffList?.data?.map((item) => {
      return {
        value: item?.id || "",
        furigana_name: item?.staff_name_furiganaame,
        label: item?.staff_name || "", //Check for Furigana name first
      }
    })
  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)
    const filteredFacilities =
      value == 0
        ? OTHER_STAFF_OPTIONS
        : [...otherFacilities]?.filter(
            (item: any) =>
              item?.label?.match(regex) || item?.furigana_name?.match(regex)
          )
    setStaffName(value)
    setOtherFacilities(filteredFacilities)
  }
  const { mutate, isLoading: isMutuating } = useMutation(addChat, {
    onSuccess: () => {
      notification.success({
        message: dynamicLangString(["Contact", "Created Successfully"]),
      })
      refetch()
      resetForm()
    },
    onError: (error?: any) => {
      const msg =
        error?.status == 403
          ? "User doesn't have permission to edit this page"
          : error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })
  const resetForm = () => {
    setConveyMessage("")
    setFile(null)
    setStaffName(null)
    setNotifyParent(false)
    setType("text")
    setFileName(null)
  }
  const handleSubmitData = () => {
    if (type === "text" && !conveyMessage) return
    if (type === "file" && !file) return

    if (type === "text" && conveyMessage.trim().length === 0) {
      return
    }
    const SendData = {
      comment: conveyMessage,
      file_url: file,
      file_name: fileName,
      open_parent_flag: notifyParent,
      save_staff_id: Number(staffId),
      child_id: Number(id),
      facility_id: Number(data?.data?.info?.facility_id),
      type: type,
    }
    if (staffId) {
      mutate(SendData)
    } else {
      setError(t("Please select"))
    }
  }
  useEffect(() => {
    setStaffName(0)
    setOtherFacilities(OTHER_STAFF_OPTIONS)
  }, [isLoading])

  return (
    <Container>
      <div className={"notes"}>
        {t(
          "※Some pictograms and machine-dependent characters (environment-dependent characters) cannot be used."
        )}
      </div>
      <div className={"notes"}>
        {t(
          "※Files can be selected from jpg, png, gif, HEIC, pdf, docx, and xlsx."
        )}
      </div>
      <SelectWrapper>
        <SelectInput
          width={80}
          height={40}
          className={"select-down"}
          defaultValue={"--"}
          options={SORT_OPTION}
          onChange={(value) => {
            handleSort(value)
            setStaffName(value)
          }}
          value={staffName}
        />
        <SelectInput
          width={200}
          height={40}
          options={otherFacilities}
          onChange={(value) => {
            setStaffId(value)
            setError(null)
          }}
          className={"long-select"}
          defaultValue={"---------"}
          value={staffId}
        />
      </SelectWrapper>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <FileUploadWrapper>
        <FileUploader
          currentImage={file}
          onChange={(val) => setFile(val)}
          setFileName={setFileName}
          fileName={fileName}
          isLoading={(value) => value}
          setType={setType}
          allowAllFileTypes={true}
        />
      </FileUploadWrapper>
      <TextWrapper>
        <TextField
          name={"convey-parent"}
          value={conveyMessage}
          onChange={(evt) => setConveyMessage(evt.target.value)}
          type={"textarea"}
          placeholder={t(
            "Please enter the information you would like to convey to the parent."
          )}
          rows={9}
        />
      </TextWrapper>

      <BottomSection>
        {!data?.data?.child?.Parent?.mail_address && (
          <div className={"warn"}>
            <div>
              {t(
                "※メールアドレスが登録されていないため、保護者へのメール通知機能を利用できません。"
              )}
            </div>
            <a
              style={{ textDecoration: "underline", color: "#0782C8" }}
              href={`/parents/edit/${data?.data?.child?.Parent?.id}`}
              target={"_blank"}
            >
              {">設定をする"}
            </a>
          </div>
        )}
        <CheckboxWrapper>
          <Checkbox
            label={t("Notify parents with email")}
            onChange={(evt) => {
              setNotifyParent(evt.target.checked)
            }}
            checked={notifyParent}
            disabled={!data?.data?.child?.Parent?.mail_address}
          />
        </CheckboxWrapper>
        <ButtonWrapper>
          <OwnerButton
            typeOf={"primary"}
            text={t("Send")}
            isLoading={isMutuating}
            onClick={handleSubmitData}
          />
        </ButtonWrapper>
      </BottomSection>
    </Container>
  )
}

export { SendMessageComponent }
