import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import {
  Button,
  isApril2024ChangeApplicable,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Spin, Tabs } from "antd"
import { t } from "i18next"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import {
  AttendanceScheduleCalendarTable,
  AttendanceScheduleOperationOptions,
} from "../.."
import ExcelIcon from "../../../public/assets/icons/ExcelIcon"
import PaperIcon from "../../../public/assets/icons/PaperIcon"
import CalendarIconLight from "../../../public/assets/icons/CalendarIconLight"
import {
  fetchMonthlyStaffRecordsByFacility,
  fetchMonthlyStaffSchedulesByFacility,
  getStaffMonthlyAdditionalExcelData,
} from "../../../services/attendanceSchedule"
import { AuthContext } from "../../../utils"
import Link from "next/link"
import { getFacilitySchedules } from "../../../services/staffSchedule"
import moment from "moment"
import { ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS_OPTION } from "../FacilitySystemStatus/common/constants"

const StyledLoader = styled(Spin)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const MainContent = styled.div`
  margin: 10px 0;
  .work-schedule-options {
    margin: 16px 0;
    display: grid;
    grid-template: "style-list-button style-list-button style-list-button pagination";
  }
  .work-schedule-pagination-container {
    grid-area: pagination;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    align-items: flex-start;
    gap: 10px;
    button {
      background: white;
      color: black;
      border: 1px solid #d2d1d1;
      border-radius: 10px;
      height: 40px;
      padding: 0px;
      min-width: 75px;
      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
  }
  .work-schedule-style-list-button {
    grid-area: style-list-button;
    .excel-buttons,
    .date-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 8px;
    }
  }
  .work-schedule-edit-container {
    display: flex;
    grid-area: edit;
    align-items: center;
    gap: 8px;
    .select-containers {
      display: flex;
      gap: 12px;
    }
    .select-actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .print-button-container {
    margin-left: auto;
    grid-area: print;
  }
  .print-button-container button {
    background-color: ${theme.gray3} !important;
    border-color: ${theme.gray3} !important;
    padding: 8px;
  }

  .footer-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 20px 0;
  }

  .staff-experience-alert {
    width: 100%;
    margin: 10px 0;
    padding: 20px;
    border: 1px solid ${theme.red3};
    border-radius: 10px;
    .alert-title {
      display: flex;
      gap: 5px;
    }
    .alert-links {
      display: flex;
      gap: 10px;
      .alert-link > a {
        text-decoration: underline;
        font-weight: normal;
      }
    }
    div {
      font-weight: bold;
      color: ${theme.red2};
    }
    @media print {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .work-schedule-options {
      grid-template:
        "pagination"
        "style-list-button";
      grid-gap: 6px;
      grid-row-gap: 15px;
    }
    .work-schedule-edit-container {
      flex-direction: column;
      .select-containers {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }
    .print-button-container {
      margin-left: 0;
    }

    .occupation-label-value-container {
      margin-bottom: 10px;
    }
  }
`

const StyledButton = styled(Button)`
  background: rgba(7, 130, 200, 0.2);
  border: 1px solid #0782c8;
  border-radius: 100px;
  padding: 4px 30px;
  color: #000000;
  height: auto;
  span {
    margin-left: 5px;
  }
`

const StyledTab = styled(Tabs)`
  .ant-tabs-tab {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background: #ffffff !important;
    color: #000000 !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid ${theme.blue5} !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000 !important;
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-content-holder {
    padding: 20px;
    border: 1px solid #d2d1d1;
  }
`

export const WorkStyleTablePage = () => {
  const router = useRouter()
  const { facility } = router.query
  let activeFacility = null
  const { facilities } = useContext(AuthContext)
  const FACILITY_OPTIONS = facilities.map((fac) => {
    const facilityOption = {
      label: fac.facility_name_short,
      value: fac.id,
    }
    if (facility && facility == fac.id) activeFacility = facilityOption
    return facilityOption
  })
  const today = new Date()
  const [currentYear, setCurrentYear] = useState(
    router.query?.year ? +router.query?.year : today.getFullYear()
  )
  const [currentMonth, setCurrentMonth] = useState(
    router.query?.month ? +router.query?.month : today.getMonth() + 1
  )
  const [currentFacility, setCurrentFacility] = useState(
    activeFacility
      ? activeFacility
      : Array.isArray(FACILITY_OPTIONS) && FACILITY_OPTIONS.length > 0
      ? FACILITY_OPTIONS[0]
      : null
  )
  const activeTab = useRef(router?.query?.tab?.toString() ?? "recordTable")
  const onDisplayChange = ({ year, month, facility }) => {
    const queries = []
    if (year) {
      setCurrentYear(year)
      queries.push("year=" + year)
    }
    if (month) {
      setCurrentMonth(month)
      queries.push("month=" + month)
    }
    if (facility) {
      const newFacility = FACILITY_OPTIONS.find((fac) => fac.value == facility)
      if (newFacility) {
        setCurrentFacility(newFacility)
        queries.push("facility=" + newFacility?.value)
      }
    }
    router.push("?" + queries.join("&"), undefined, { shallow: true })
  }

  const { isLoading: isSchedulesLoading, data: ScheduleResponse } = useQuery(
    ["monthlySchedules", currentFacility, currentMonth, currentYear],
    async () => {
      const { data } = await fetchMonthlyStaffSchedulesByFacility({
        facilityId: currentFacility?.value,
        year: currentYear,
        month: currentMonth,
      })
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const { isLoading: isRecordLoading, data: RecordResponse } = useQuery(
    ["monthlyRecords", currentFacility, currentMonth, currentYear],
    async () => {
      const { data } = await fetchMonthlyStaffRecordsByFacility({
        facilityId: currentFacility?.value,
        year: currentYear,
        month: currentMonth,
      })
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const { isLoading: isAdditionalDataLoading, data: excelAdditionalData } =
    useQuery(
      ["monthlyAddition", currentFacility, currentMonth, currentYear],
      () =>
        getStaffMonthlyAdditionalExcelData({
          facilityId: currentFacility?.value,
          year: currentYear,
          month: currentMonth,
        }),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 0,
        select: (response) => {
          const additionalAllowanceText = []
          if (response?.data?.additional_allowance) {
            additionalAllowanceText.push(
              ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS_OPTION?.find(
                (opt) => opt.value == response?.data?.additional_allowance
              )?.label || ""
            )
          }

          if (response?.data?.additional_allowance2) {
            additionalAllowanceText.push(
              ADDITIONAL_ALLOWANCE_FOR_CHILD_INSTRUCTORS_OPTION?.find(
                (opt) => opt.value == response?.data?.additional_allowance2
              )?.label || ""
            )
          }
          return {
            ...response.data,
            additional_allowance: additionalAllowanceText
              .filter((a) => a)
              .join(", "),
          }
        },
      }
    )

  const { data: facilitySchedules } = useQuery(
    ["staffSfacilityShiftSchedules", currentFacility],
    async () => {
      return getFacilitySchedules(currentFacility?.value)
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (response) => {
        return response?.data?.map((schedule) => {
          return {
            ...schedule,
            label: schedule.attendance_shift_name,
            value: schedule.id,
          }
        })
      },
    }
  )

  const triggerExcelDownload = (buttonId, tabId) => {
    let elem = document.getElementById(buttonId)
    if (elem) {
      elem.click()
    } else if (activeTab.current != tabId) {
      const isSuccess = invokeCustomTabTrigger()
      if (isSuccess) {
        setTimeout(() => {
          elem = document.getElementById(buttonId)
          if (elem) elem.click()
        }, 100)
      }
    }
  }

  const invokeCustomTabTrigger = () => {
    try {
      const tabs = document.getElementsByClassName("ant-tabs-tab")
      if (tabs) {
        for (let i = 0; i < tabs.length; i++) {
          if (!tabs[i].classList.contains("ant-tabs-tab-active")) {
            const requiredChild = tabs[i].querySelector(
              ".ant-tabs-tab-btn"
            ) as HTMLElement
            if (requiredChild) {
              requiredChild.click()
              return true
            }
          }
        }
      }
    } catch (e) {
      return false
    }
    return false
  }

  const aprilChangeApplicable = useMemo(() => {
    return isApril2024ChangeApplicable(
      moment().set({
        year: currentYear,
        month: currentMonth - 1,
        day: 1,
      })
    )
  }, [currentMonth, currentYear])

  const staffsWhoseExperienceYearIsntRegistered = useMemo(() => {
    return (
      ScheduleResponse?.staff_schedules
        ?.filter((schedule) => !schedule.experience_year)
        .map((schedule) => ({
          staff_id: schedule?.staff_id,
          staff_name: schedule?.staff?.staff_name,
        })) || []
    )
  }, [ScheduleResponse])

  const OutputExcelFileScheduledButton = () => (
    <StyledButton
      icon={<ExcelIcon />}
      onClick={() => {
        triggerExcelDownload("download_Schedule", "scheduleTable")
      }}
    >
      {t("Output Excel file (scheduled)")}
    </StyledButton>
  )

  const OutputExcelFileRecordButton = () => (
    <StyledButton
      icon={<ExcelIcon />}
      onClick={() => {
        triggerExcelDownload("download_Record", "recordTable")
      }}
    >
      {t("Output Excel file (record)")}
    </StyledButton>
  )

  const AttendanceScheduleTableButton = () => (
    <Link
      href={`/attendance-schedule?facility=${currentFacility.value}&year=${currentYear}&month=${currentMonth}`}
    >
      <a>
        <StyledButton icon={<CalendarIconLight />}>
          {t("Attendance schedule table")}
        </StyledButton>
      </a>
    </Link>
  )

  const AttendanceRecordTableButton = () => (
    <Link
      href={`/staff-attendance?facility_id=${currentFacility.value}&year=${currentYear}&month=${currentMonth}`}
    >
      <a>
        <StyledButton icon={<PaperIcon />}>
          {t("Attendance record table")}
        </StyledButton>
      </a>
    </Link>
  )

  return (
    <MainContent>
      <AttendanceScheduleOperationOptions
        facilityOptions={FACILITY_OPTIONS}
        handleDisplayChange={onDisplayChange}
        initialValues={{
          year: currentYear,
          month: currentMonth,
          facility: currentFacility?.value,
        }}
      />
      {isSchedulesLoading || isRecordLoading || isAdditionalDataLoading ? (
        <StyledLoader size={"large"} />
      ) : (
        <div className={"work-schedule-container"}>
          <TopRoundedWhiteCard
            title={t("workStyleTableTitle", {
              facilityName: currentFacility ? currentFacility.label : "",
              year: currentYear,
              month: currentMonth?.toString()?.padStart(2, "0"),
            })}
          >
            <div className={"work-schedule-options"}>
              <div className={"work-schedule-style-list-button"}>
                <div className={"excel-buttons"}>
                  <OutputExcelFileScheduledButton />
                  <OutputExcelFileRecordButton />
                </div>
                <div className={"date-buttons"}>
                  <AttendanceScheduleTableButton />
                  <AttendanceRecordTableButton />
                </div>
              </div>
              <div className={"work-schedule-pagination-container"}>
                <Button
                  className={"left-btn"}
                  onClick={() => {
                    let year = currentYear
                    let month = currentMonth
                    if (month == 1) {
                      --year
                      month = 12
                    } else {
                      --month
                    }
                    onDisplayChange({
                      year,
                      month,
                      facility: currentFacility?.value,
                    })
                  }}
                >
                  <DoubleLeftOutlined />
                  <span className={"left-btn-label"}>{t("Last month")}</span>
                </Button>
                <Button
                  className={"active active-btn"}
                  onClick={() => {
                    const year = new Date().getFullYear()
                    const month = new Date().getMonth() + 1
                    onDisplayChange({
                      year,
                      month,
                      facility: currentFacility?.value,
                    })
                  }}
                >
                  {t("This month")}
                </Button>
                <Button
                  className={"right-btn"}
                  onClick={() => {
                    let year = currentYear
                    let month = currentMonth
                    if (month == 12) {
                      ++year
                      month = 1
                    } else {
                      ++month
                    }
                    onDisplayChange({
                      year,
                      month,
                      facility: currentFacility?.value,
                    })
                  }}
                >
                  <span className={"right-btn-label"}>{t("Next month")}</span>
                  <DoubleRightOutlined />
                </Button>
              </div>
            </div>
            <StyledTab
              defaultActiveKey={activeTab.current}
              type={"card"}
              size={"large"}
              onTabClick={(activeKey) => {
                activeTab.current = activeKey
              }}
              items={[
                {
                  id: "scheduleTable",
                  label: t("Schedule table"),
                  key: "scheduleTable",
                  children: (
                    <AttendanceScheduleCalendarTable
                      year={currentYear}
                      month={currentMonth}
                      facility={currentFacility?.value}
                      calendarData={ScheduleResponse}
                      calendarType={"Schedule"}
                      detailLink={"/attendance-schedule/detail"}
                      staffLink={"/attendance-schedule/monthly-schedule"}
                      downloadable={true}
                      additionalExcelData={excelAdditionalData}
                      facilitySchedules={facilitySchedules || []}
                      aprilChangeApplicable={aprilChangeApplicable}
                    />
                  ),
                },
                {
                  id: "recordTable",
                  label: t("Record table"),
                  key: "recordTable",
                  children: (
                    <AttendanceScheduleCalendarTable
                      year={currentYear}
                      month={currentMonth}
                      facility={currentFacility?.value}
                      calendarData={RecordResponse}
                      calendarType={"Record"}
                      detailLink={"/staff-attendance/detail"}
                      staffLink={"/staff-attendance/monthly-record"}
                      downloadable={true}
                      additionalExcelData={excelAdditionalData}
                      facilitySchedules={facilitySchedules || []}
                      aprilChangeApplicable={aprilChangeApplicable}
                    />
                  ),
                },
              ]}
            />
            <div className={"footer-buttons"}>
              <OutputExcelFileScheduledButton />
              <OutputExcelFileRecordButton />
              <AttendanceScheduleTableButton />
              <AttendanceRecordTableButton />
            </div>
            {aprilChangeApplicable &&
              staffsWhoseExperienceYearIsntRegistered.length > 0 && (
                <div className={"staff-experience-alert"}>
                  <div className={"alert-title"}>
                    <img
                      src={"/assets/icons/exclamation.png"}
                      height={25}
                      width={25}
                      alt={""}
                    />
                    <div>{t("Please register your years of experience.")}</div>
                  </div>
                  <div className={"alert-body"}>
                    {t(
                      "From the link below, if you change the year and month to April 2024, the years of experience item will be displayed."
                    )}
                  </div>
                  <div className={"alert-links"}>
                    {staffsWhoseExperienceYearIsntRegistered.map((staff) => (
                      <div
                        className={"alert-link"}
                        key={`alert-staff-${staff?.staff_id}`}
                      >
                        <Link
                          href={`/instructors/edit/${staff?.staff_id}#affiliate_facilities`}
                        >
                          <a target={"_blank"}>{staff?.staff_name}</a>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </TopRoundedWhiteCard>
        </div>
      )}
    </MainContent>
  )
}
