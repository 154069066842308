import { Table } from "@project/shared"

import { t } from "i18next"
import { DefaultProps } from "./types"
import {
  TABLE_HEADS,
  generateDynamicColumn,
  getTotalColumnInfo,
  padEmptyFacilities,
} from "./utils"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"

type Props = DefaultProps

export function DefaultFormat({
  facilities,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
}: Props) {
  const upperRow = padEmptyFacilities(facilities.slice(0, 5), 5)
  const lowerRow = padEmptyFacilities(facilities.slice(5, 9), 4)

  return (
    <>
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              {
                title: "",
                render: () =>
                  t("User burden amount aggregation / adjustment column"),
                width: 20,
                onCell: (_, index) => {
                  if (index === 0) {
                    return { rowSpan: 8 }
                  }
                  return { rowSpan: 0 }
                },
              },
              {
                title: "",
                dataIndex: "title",
                align: "center",
                width: 200,
              },
              ...generateDynamicColumn(upperRow),
            ]}
            pagination={false}
            dataSource={TABLE_HEADS}
          />
        </div>
      </NBLManagementCompanyTop>

      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              {
                title: "",
                render: () =>
                  t("User burden amount aggregation / adjustment column"),
                width: 20,
                onCell: (_, index) => {
                  if (index === 0) {
                    return { rowSpan: 6 }
                  }
                  return { rowSpan: 0 }
                },
              },
              {
                title: "",
                dataIndex: "title",
                align: "center",
                width: 200,
              },
              ...generateDynamicColumn(lowerRow),
              // 最終列（合計）
              {
                title: t("Total"),
                align: "center",
                width: 180,
                render: (_, __, index) => {
                  return getTotalColumnInfo(
                    totalAmount,
                    totalBurdenAmount,
                    managementResultBurdenAmount,
                    index
                  )
                },
                onCell: (_, index) => {
                  if (index === 0) {
                    return { rowSpan: 3 }
                  }
                  if (index === 1 || index === 2) {
                    return { rowSpan: 0 }
                  }
                  return {}
                },
              },
            ]}
            pagination={false}
            dataSource={TABLE_HEADS}
          />
        </div>
      </NBLManagementCompanyTop>
    </>
  )
}
