import React, { useEffect, useState } from "react"

// packages
import { Spin } from "antd"

// styled components
import { ProxyMoreInfoContainer } from "../styles"

// components
import { TopRoundedWhiteCard } from "@project/shared"
import { IndividualTables } from "./IndividualTables"

// types
import { IndividualTableBlockProps, ProxyMoreInfoBlockProps } from "../types"

// utils
import { tableDataMapper } from "../utils/mapper"

export const ProxyMoreInfoBlock: React.FC<ProxyMoreInfoBlockProps> = ({
  currentSearchQueries,
  isBillingDetailsLoading,
  ChildOutputPrintDetails,
}) => {
  const [dataSources, setDataSources] = useState<IndividualTableBlockProps[]>(
    []
  )

  useEffect(() => {
    if (ChildOutputPrintDetails) {
      const mappedData = tableDataMapper(ChildOutputPrintDetails)
      setDataSources(mappedData)
    }
  }, [ChildOutputPrintDetails])

  return (
    <ProxyMoreInfoContainer>
      <TopRoundedWhiteCard className={"operation-rounded-container"}>
        <Spin spinning={isBillingDetailsLoading}>
          <IndividualTables
            multipleData={dataSources ? dataSources : []}
            currentSearchQueries={currentSearchQueries}
            isBillingDetailsLoading={isBillingDetailsLoading}
          />
        </Spin>
      </TopRoundedWhiteCard>
    </ProxyMoreInfoContainer>
  )
}
