/**
 * NOTE:
 * - R6 April [abolished:廃止]
 */

import { t } from "i18next"
import { AdditionType } from "../../../../../constants/enums/additioinType"
import { Field } from "../fields"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"

/**
 * Version management: Child Disorder Type Options
 * @param {Moment} date
 * @return {Field|null}
 */
export function getProfessionalSupportAddition(date: Moment) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline
    default:
      return null
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 *
 * NONE
 * --------------------------------
 */

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */

/**
 * @en Professional support addition
 * @ja 専門的支援加算
 */
const baseline: Field = {
  label: t("Professional support addition"),
  name: AdditionType.PROFESSIONAL_SUPPORT_ADDITION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Physical Therapist"), // eng: Physical Therapist, ja: 理学療法士等
      value: 2,
    },
    {
      label: t("Children's instructor"), // ena: Children's instructor, ja: 児童指導員
      value: 3,
    },
  ],
}
