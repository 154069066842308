import { theme } from "@project/shared"

import styled from "styled-components"
export const Wrapper = styled.div`
  width: 100%;
  .top_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
      button {
        padding: 6px;
        padding-right: 15px;
      }
    }
    div {
      display: flex;
      align-items: flex-start;
    }
    .pagi {
      display: flex;
      gap: 10px;
      @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
  .deleted_row {
    display: none !important;
  }
  .disable-row-action {
    pointer-events: none !important;
  }
`

export const ShiftContainer = styled.div`
  width: 150px;
  text-align: left;
`
export const WorkingHoursContainer = styled.div`
  .with-border {
    border-top: 1px solid ${theme.gray2};
  }
  .hours_ranges_container {
    width: 100%;
    display: flex;
    .hour-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      white-space: nowrap;
      padding: 10px;
    }
    .range {
      padding: 10px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-left: 1px solid ${theme.gray2};
      border-right: 1px solid ${theme.gray2};
      .start_date,
      .end_date {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
    .break_time {
      display: flex;
      align-items: center;
      margin-top: 10px;
      white-space: nowrap;
      padding: 0 10px;
    }
    .add_cta {
      display: flex;
      .break_time_input {
        display: flex;
        align-items: center;
        padding: 5px;
        border-left: 1px solid ${theme.gray2};
        div {
          display: flex;
          align-items: center;
          height: 40px;
          gap: 5px;
          .input {
            width: 54px;
          }
        }
      }
      .add_new_range {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        padding-right: 5px;
        width: 200px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        span {
          color: ${theme.gray5};
          white-space: nowrap;
        }
        .btn {
          display: flex;
          gap: 4px;
          cursor: pointer;
          align-items: center;
          justify-content: flex-end;
          white-space: nowrap;
          .add-icon {
            color: ${theme.gray3};
          }
          .remove-icon {
            color: ${theme.red2};
          }
          span {
            color: ${theme.blue5};
            text-decoration: underline;
          }
        }
      }
    }
  }
`
