import { t } from "i18next"
import { AdditionType } from "../../../../constants/enums/additioinType"

export type Field = {
  label: string
  name: AdditionType
  fields: { label: string; value: number }[]
}

/**
 * @en Exceed capacity
 * @ja 定員超過
 */
export const exceedCapacity: Field = {
  label: t("Exceed capacity"),
  name: AdditionType.EXCEED_CAPACITY,
  fields: [
    {
      label: t("No change"), // en: , ja: 変更なし
      value: 0,
    },
    {
      label: t("No"), // en: none, ja: なし
      value: 1,
    },
    {
      label: t("Yes"), // en: Yes, ja: あり
      value: 2,
    },
  ],
}

/**
 * @en Lack of Service Employee Subtraction
 * @ja サービス提供職員欠如減算
 */
export const lackOfServiceEmployeeSubtraction: Field = {
  label: t("Lack of Service Employee Subtraction"),
  name: AdditionType.LACK_OF_SERVICE_EMPLOYEE_SUBTRACTION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Up to 2 months "), // en: , ja: 2ヶ月目まで
      value: 2,
    },
    {
      label: t("After 3 months"), // en: After 3 months, ja: 3ヶ月目以降
      value: 3,
    },
  ],
}

/**
 * @en Lack of child development support manager subtraction
 * @ja 児童発達支援管理責任者欠如減算
 */
export const lackOfChildDevelopmentSupportManagerSubtraction: Field = {
  label: t("Lack of child development support manager subtraction"),
  name: AdditionType.LACK_OF_CHILD_DEVELOPMENT_SUPPORT_MANAGER_SUBTRACTION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Until 4 months"), // en: Until 4 months, ja: 4ヶ月目まで
      value: 2,
    },
    {
      label: t("After 5 months"), // en: After 5 months, ja: 5ヶ月目以降
      value: 3,
    },
  ],
}

/**
 * @en Unpublished subtraction of self-evaluation results
 * @ja 自己評価結果等未公表減算
 */
export const unpublishedSubtractionOfSelfEvaluationResults: Field = {
  label: t("Unpublished subtraction of self-evaluation results"),
  name: AdditionType.UNPUBLISHED_SUBTRACTION_OF_SELF_EVALUATION_RESULTS,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Yes"), // xa
      value: 2,
    },
  ],
}

/**
 * @en Physical restraint abolition non-implementation subtraction
 * @ja 身体拘束廃止未実施減算
 */
export const physicalRestraintAbolitionNonImplementationSubtraction: Field = {
  label: t("Physical restraint abolition non-implementation subtraction"),
  name: AdditionType.PHYSICAL_RESTRAINT_ABOLITION_NON_IMPLEMENTATION_SUBTRACTION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Yes"), // xa
      value: 2,
    },
  ],
}

/**
 * @en Subtraction of opening time
 * @ja 開所時間減算
 */
export const subtractionOfOpeningTime: Field = {
  label: t("Subtraction of opening time"),
  name: AdditionType.SUBTRACTION_OF_OPENING_TIME,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Below 4 hrs"), // en: Below 4 hrs, ja: 4時間未満
      value: 2,
    },
    {
      label: t("More than 4 hrs less than 6 hrs"), // en: More than 4 hrs less than 6 hrs, ja: 4時間以上6時間未満
      value: 3,
    },
  ],
}

/**
 * @en Provision time division (former: Child with a disability classification)
 * @ja 提供時間区分（旧：障害児状 態等区分)
 */
export const provisionTimeDivision: Field = {
  label: t(
    "Provision time division (former: Child with a disability classification)"
  ), // xaina
  name: AdditionType.PROVISION_TIME_DIVISION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("Division 1"), // en: Division, 1 ja:  区分1
      value: 2,
    },
    {
      label: t("Division 2"), // en: Division 2, ja: 区分2
      value: 3,
    },
  ],
}

/**
 * @en Welfare Specialist Placement,etc. Addition
 * @ja
 */
export const welfareSpecialistPlacementAddition: Field = {
  label: t("Welfare Specialist Placement,etc. Addition"), // xaina
  name: AdditionType.WELFARE_SPECIALIST_PLACEMENT_ADDITION,
  fields: [
    {
      label: t("No change"), // xaina
      value: 0,
    },
    {
      label: t("No"), // xa
      value: 1,
    },
    {
      label: t("I"),
      value: 2,
    },
    {
      label: t("II"),
      value: 3,
    },
    {
      label: t("III"),
      value: 4,
    },
  ],
}
