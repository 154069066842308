import React from "react"

import {
  Wrapper,
  NBLInfoContent,
  NBLMiddleInfoContent,
} from "./../../PrintListContent.style"
import {
  ReceiptSiblingUpperLimitManagement,
  SiblingChild,
} from "../../../../types"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../../../../constants"
import { t } from "i18next"
import { DefaultSheetTitle } from "../header/sheetTitle/DefaultSheetTitle"
import { YearMonth } from "../../common/header/YearMonth"
import { KobeChildDetail } from "../header/childDetail/KobeChildDetail"
import { ManagementFacility } from "../../common/header/ManagementFacility"
import { KobeUpperMonthLimit } from "../../common/header/upperMonthLimit/KobeUpperMonthLimit"
import { DefaultUpperLimitManagementResult } from "../../common/header/upperLimitManagementResult/DefaultUpperLimitManagementResult"
import { KobeFormat } from "../body/KobeFormat"
import { KobeSignature } from "../../common/footer/signature/KobeSignature"

type Props = {
  receiptSiblingUpperLimitManagement: ReceiptSiblingUpperLimitManagement
  siblingChildren: SiblingChild[]
  printConfig: string[]
}

export function KobeFlame({
  receiptSiblingUpperLimitManagement,
  siblingChildren = [],
  printConfig = [],
}: Props) {
  return (
    <Wrapper style={{ fontSize: "50%" }}>
      {printConfig.includes(
        PRINT_CONFIGURATION_OPTION_VALUES.PRINT_FORM_NUMBER
      ) && <small>{t("(Form 2)")}</small>}

      {/* --- Start Header */}
      <DefaultSheetTitle />
      <YearMonth
        year={receiptSiblingUpperLimitManagement.year}
        month={receiptSiblingUpperLimitManagement.month}
      />
      <NBLInfoContent length={1}>
        <div className={"top__table_content"}>
          <KobeChildDetail
            municipalityNumber={
              receiptSiblingUpperLimitManagement.municipality_number
            }
            siblingChildren={siblingChildren}
            parentName={receiptSiblingUpperLimitManagement.parent_name}
          />
        </div>
        <div className={"top__table_content"}>
          <ManagementFacility
            facilityName={receiptSiblingUpperLimitManagement.facility_name}
            facilityNumber={receiptSiblingUpperLimitManagement.facility_number}
          />
        </div>
      </NBLInfoContent>
      <NBLMiddleInfoContent>
        <div className={"NBLMiddleInfoContent"}>
          <div className={"nbl_info_top_content"}>
            <KobeUpperMonthLimit
              burdenMaxMonth={
                receiptSiblingUpperLimitManagement.burden_max_month
              }
              localBurdenMaxMonth={
                receiptSiblingUpperLimitManagement.local_burden_max_month
              }
            />
          </div>
          <div className={"nbl_info_bottom_content"}>
            <DefaultUpperLimitManagementResult
              managementResult={
                receiptSiblingUpperLimitManagement.management_result
              }
            />
          </div>
        </div>
      </NBLMiddleInfoContent>
      {/* --- End Header */}

      {/* --- Start Body */}
      <KobeFormat
        facilities={receiptSiblingUpperLimitManagement.facilities}
        totalAmount={receiptSiblingUpperLimitManagement.total_amount}
        totalBurdenAmount={
          receiptSiblingUpperLimitManagement.total_burden_amount
        }
        managementResultBurdenAmount={
          receiptSiblingUpperLimitManagement.management_result_burden_amount
        }
        totalInsuranceAmount={
          receiptSiblingUpperLimitManagement.total_insurance_amount
        }
        managementResultInsuranceAmount={
          receiptSiblingUpperLimitManagement.total_management_result_insurance_amount
        }
        totalLocalExemption={
          receiptSiblingUpperLimitManagement.total_local_exemption
        }
        localBurdenAmount={
          receiptSiblingUpperLimitManagement.local_burden_amount
        }
        printConfig={printConfig}
      />
      {/* --- End Body */}

      {/* --- Start Footer */}
      <KobeSignature printConfig={printConfig} />
      <p style={{ fontSize: "0.8rem" }}>
        {
          "この様式により上限管理を行う場合は、請求月10日までに神戸市に提出してください。この様式により請求明細書を作成した場合は、当該受給者の上限管理結果票を国保連に送信しないでください。"
        }
      </p>
      {/* --- End Footer */}
    </Wrapper>
  )
}
