import React from "react"

const ChildCells = ({ cells }: any) => {
  // ChildCells creates child cells inside a ant table cell.
  // This is much easier than passing `children` while rendering cells and also
  // allows us to make assymetrical table structures when required
  return (
    <div className={"child-container"}>
      {cells.map((cell) => (
        <div className={"child-cell"} key={cell}>
          {cell}
        </div>
      ))}
    </div>
  )
}

export { ChildCells }
