/**
 * 〜 2024.3
 */

import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  @media print {
    page-break-inside: avoid;
  }
`
export const Title = styled.h3`
  text-align: center;
  color: ${theme.black};
  font-weight: 700;
`

export const KobeCityTableComponent = styled.div`
  #KobeCityTable table tbody tr:nth-child(11) .kobe-col {
    border: none !important;
  }
`

export const KyotoTable = styled.table`
  margin-bottom: 20px;
  border-collapse: collapse;
  .kyoto-table-body {
    tr {
      td {
        text-align: center;
        padding: 0.5em;
        min-width: 2em;
        border: 1px solid ${theme.gray9};
        font-size: 12px;
      }
    }
  }
`

export const NBLDateMonthInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  #DateMonthInfo {
    table {
      border-collapse: collapse !important;
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.gray9} !important;
            padding: 0.3em;
            width: 10%;
            text-align: center;
            font-size: 12px;
            white-space: nowrap;
            min-width: 20px;
          }
          td.w-large {
            width: 20% !important;
          }
        }
      }
    }
  }
`
export const NBLInfoContent = styled.div<{ length: number }>`
  display: flex;
  justify-content: space-between;
  #top__table_content {
    &:first-child {
      margin-right: 0.5em;
    }
    table {
      border-collapse: collapse !important;
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.gray9} !important;
            padding: 0px !important;
            font-size: 12px;

            .management_company {
              padding: 0px 7px !important;
              display: flex;
              align-items: center;
            }
            .city_no_total {
              display: flex;
              width: 100%;
              position: absolute;
              height: 100%;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              .value_total {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 12px;
                text-align: center;
                &:not(:last-child) {
                  border-right: 1px solid #000;
                }
              }
            }
            .beneficiary_cont {
              .beneficiary_no_total {
                display: flex;
                width: 100%;
                height: 100%;
                top: 0;
                position: ${(props) =>
                  props.length >= 2 ? "relative" : "absolute"};
                bottom: 0;
                left: 0;
                right: 0;
                .value_total {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  font-size: 12px;
                  text-align: center;
                  &:not(:last-child) {
                    border-right: 1px solid #000;
                  }
                }
              }
              > div:not(:first-child) {
                border-top: 1px solid;
              }
            }

            p {
              font-size: 12px;
              padding: 0px 7px;
              display: flex;
              align-items: center;
            }

            #business_number {
              display: flex;
              width: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;

              .number {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                &:not(:last-child) {
                  border-right: 1px solid ${theme.gray2};
                }
              }
            }

            #child_info_container {
              padding: 0 !important;
              .child_value {
                font-size: 12px;
                display: flex;
                align-items: center;
                padding: 0.3em;
                min-height: 20px;
                /* height: 100%; */
                &:not(:last-child) {
                  border-bottom: 1px solid ${theme.gray9};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const NBLManagementCompanyTop = styled.div`
  #NBLManagementCompanyTop {
    table {
      border-collapse: collapse !important;
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.black} !important;
            padding: 0.5em;
            font-size: 12px;
            min-width: 60px !important;
            width: fit-content;
            .facility_total_amount {
              display: flex;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              .value {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 12px;
                text-align: center;

                &:not(:last-child) {
                  border-right: 1px solid ${theme.gray2};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const NBLMiddleInfoContent = styled.div`
  #NBLMiddleInfoContent {
    #nbl_info_top_content {
      table#nbl_info_top_content__table {
        tbody {
          tr {
            td {
              border: 1px solid ${theme.gray9};
              text-align: center;
              min-width: 2em;
              padding: 0 0.5em;
              font-size: 12px;
            }
          }
        }
      }
    }
    #nbl_info_bottom_content {
      margin-top: 1em;
      .monthly_limit__amount {
        display: flex;
        border: 1px solid ${theme.gray9};
        width: fit-content;
        font-size: 12px;
        .small {
          border-left: 1px solid ${theme.gray9};
        }
        .large,
        .small {
          padding: 0.5em;
          font-size: 12px;
        }
      }
      .text__content {
        border: 1px solid ${theme.gray9};
        padding: 0.5em 2em;
        ol {
          padding: 0;
          margin: 0;
          li {
            margin-bottom: 0.5em;
            font-size: 12px;
          }
        }
      }
    }
  }
`
export const ConfirmationWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
  font-size: 10pt;
  &.top-dotted-border {
    border-top: 1px dotted black;
  }
  &.pt-10 {
    padding-top: 10px;
  }
  .footer-check {
    font-size: 10pt;
    width: 48%;
    margin-left: auto;
    text-align: left;
  }
  span {
    font-size: 10pt;
  }
`

export const HiroshimaBurdenTable = styled.table`
  width: 100%;
  .hiroshima-col {
    border: 1px solid ${theme.gray9};
    min-width: 2em;
    font-size: 12px;
    padding: 2px;
  }
  .ordered-list {
    list-style-position: inside;
    .list {
      font-size: 12px;
    }
  }
`
export const DiagnoalDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to top left,
    transparent,
    transparent 49%,
    black 1%,
    black 1%,
    transparent 51%,
    transparent
  );
`
