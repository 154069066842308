import { useContext } from "react"
import { AuthContext } from "./AuthContext"

export const buildFacilityCreateData = (values: any) => {
  return {
    facility_name: values.facilityName,
    business_number: values.businessNumber,
    facility_name_furigana: values.furigana,
    facility_name_short: values.facilityNameAbbreviation,
    facility_type_id: parseInt(values.pref_id),
    support_visits_nurseies_flg:
      values?.typeSupportOffering?.[0] === "typeSupportOffering" ||
      values?.typeSupportOffering === "typeSupportOffering",
    email: values.email,
    show_my_page_info:
      values?.footerInformation === "footerInformation" ||
      values?.footerInformation?.[0] === "footerInformation",
    po_box_no: values.poBox,
    address: values.address,
    phone_no: values.phoneNumber,
    fax_no: values.fax,
    corporate_name: values.corporateName,
    represenative_name: values.representativeName,
    invoice_title: values.invoiceTitle,
    invoice_name: values.invoiceFullName,

    financial_institution_name: values.finInstitute,
    branch_name: values.finBranch,
    financial_institution_name_katakana: values.finInstituteBranchKana,
    branch_name_katakana: values.finInstituteBranchKana,
    account_type: values?.accType || 0,
    administrator_name: values.adminName,
    account_holder_name: values.accHolderName,
    account_number: String(values?.accNumber || ""),
    account_holder_name_katakana: values.accHolderNameKana,
    debit_account_transfer_consign_no: values.debitConsignor,
    consign_name_katakana:
      values?.consignorKana && values.consignorKana.length > 0
        ? values.consignorKana
        : " ",
    bank_no: values.bankNumber,
    branch_no: values.branchNumber,
    pref_id: parseInt(values.pref_id),
    pref_id2: parseInt(values?.pref_id2),
    multi_type: values?.multi_type,
    status: values.status || "DRAFT",
    type1_condition2: parseInt(values.type1_condition2),
    type1_condition1: parseInt(values.type1_condition1),
    type2_condition1: values?.multi_type
      ? parseInt(values.type2_condition1)
      : null,
    type2_condition2: values?.multi_type
      ? parseInt(values.type2_condition2)
      : null,
    class_grade_ads: values?.class_grade_ads || "Others",
    class_grade_cds: values?.class_grade_cds || "Others",
    class_grade_severly_ads: values?.class_grade_severly_ads || "Others",
    class_grade_severly_cds: values?.class_grade_severly_cds || "Others",
  }
}

export const getServiceColor = (service = "") => {
  const serviceName = service?.toLowerCase().replaceAll(" ", "")
  switch (serviceName) {
    case "childdevelopmentsupport":
      return "#4DA2F8"
    case "afterschooldayservice":
      return "#E26827"
    case "2":
      return "#4DA2F8"
    case "1":
      return "#E26827"
    default:
      return "#D2D1D1"
  }
}

export const getServiceInitial = (service = "") => {
  const serviceName = service ? service.toLowerCase().replaceAll(" ", "") : null

  switch (serviceName) {
    case "childdevelopmentsupport":
      return "childDevelopmentSupportInitial"
    case "afterschooldayservice":
      return "afterDaySchoolServiceInitial"
    case "2":
      return "childDevelopmentSupportInitial"
    case "1":
      return "afterDaySchoolServiceInitial"

    default:
      return null
  }
}

/**
 *Get comma or other separator seperated facility names from ID array
 * @param {any} facilities Facilities from AuthContext
 * @param {number[]} facilityIds Array of facility IDs
 * @param {string} separator Default ", "
 * @returns {string} Separated values
 */
export const getSeparatedFacilities = (
  facilities,
  facilityIds: number[],
  separator = ", "
) => {
  if (facilityIds.length) {
    facilities = facilities?.filter((facility) =>
      facilityIds.includes(facility.id)
    )
  }
  return facilities
    ?.map((facility) => facility.facility_name_short)
    ?.join(separator)
}

export const getFacilityNameWithId = (id: any) => {
  const { facilities } = useContext(AuthContext)
  const facility = facilities.filter((obj) => obj.id == id)
  return facility[0]?.facility_name_short
}

export const useFacility = () => {
  const { facilities } = useContext(AuthContext)
  const facilityOptions = facilities.map((facility) => {
    return {
      label: facility?.facility_name_short,
      value: facility?.id,
    }
  })
  return {
    facilities,
    facilityOptions,
    activeFacilityIds: facilities.map((facility) => facility.id),
  }
}
