import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "@project/shared"
import { OwnerButton } from "../../atoms/OwnerButton"
import { Button } from "../../../components"
import { useRouter } from "next/router"

const DataContainer = styled.div`
  width: 100%;
  @media print {
    display: none;
  }
`

const Flexwrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  .print-btn {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 20px;

    @media (max-width: 767px) {
      height: auto;
      width: 100%;
    }
  }
  .btn-1 {
    margin: 5px;
    padding: 10px 11px;
    white-space: nowrap;
  }
  .btn-2 {
    margin: 5px;
  }
  .btn-3 {
    margin: 5px;
  }
  .secondary-action-btns {
    display: flex;
    align-items: center;
  }
`

const PrintButtonComponent: React.FC<any> = ({ onPrint }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const printAction = () => {
    onPrint()
    // if (typeof window != "undefined") window.print()
  }

  return (
    <div>
      <DataContainer>
        <Flexwrapper>
          <div className={"print-btn"}>
            <Button
              text={t("Print")}
              icon={"print"}
              backgroundColor={theme.gray3}
              color={theme.base}
              padding={5}
              className={"btn-1"}
              width={100}
              onClick={printAction}
            />
            <div className={"secondary-action-btns"}>
              <OwnerButton
                typeOf={"secondary"}
                className={"btn-2"}
                text={t("Record absence")}
                onClick={() => {
                  const { date, facilityId } = router?.query
                  const queries = []
                  if (date) queries.push(`date_from=${date}&date_to=${date}`)
                  if (facilityId) queries.push(`facility_ids=${facilityId}`)
                  router.push(
                    `/user-attendance-table/absence-record?${queries.join("&")}`
                  )
                }}
              />
              <OwnerButton
                typeOf={"secondary"}
                className={"btn-3"}
                text={t("Bulk edit")}
                onClick={() => {
                  const { date, facilityId } = router?.query
                  const queries = []
                  if (date) queries.push(`date=${date}`)
                  if (facilityId) queries.push(`facilityIds=${facilityId}`)
                  router.push(
                    `/user-attendance-table/bulk-edit?${queries.join("&")}`
                  )
                }}
              />
            </div>
          </div>
        </Flexwrapper>
      </DataContainer>
    </div>
  )
}

export { PrintButtonComponent }
