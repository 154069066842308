import { useTranslation } from "react-i18next"
import { BillingSectionHeader, OwnerTable } from "../../molecules"
import { Collapse, Spin } from "antd"
import styled from "styled-components"
import { UpOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import { getChildListForChangeLimit } from "../../../services/NHIFbilling"
import Link from "next/link"
import { theme } from "@project/shared"

const AccordionWrapper = styled(Collapse)`
  .ant-collapse-header {
    background: #ffffff;
    .ant-collapse-header-text {
      text-decoration: underline;
    }
  }
  .ant-collapse-content {
    border: none;
  }
  .spin-container {
    display: flex;
    margin: 10px 0;
    justify-content: center;
  }
  .ant-table-wrapper {
    width: 100%;
    overflow: auto;
  }
  .link-container {
    margin-top: 10px;
    a {
      text-decoration: underline;
      color: ${theme.blue5};
    }
  }
`

interface IBillingLimitChangedChild {
  facility_id: number
  year: number
  month: number
  disabled?: boolean
}

export const BillingLimitChangedChild = ({
  facility_id,
  year,
  month,
  disabled = false,
}: IBillingLimitChangedChild) => {
  const { t } = useTranslation()

  const { data: childWithChangedLimit, isLoading } = useQuery(
    ["get-usage-record-rewrite"],
    () =>
      getChildListForChangeLimit({
        facility: facility_id,
        year,
        month,
        query: "get_only_saved_child=1",
      }),
    {
      cacheTime: 1,
      keepPreviousData: false,
      retry: 1,
      select: ({ data }) => data?.child_list,
    }
  )

  const columns = [
    {
      title: t("Child's name (furigana)"),
      render: (row) => `${row?.child_name} (${row?.child_name_furigana})`,
    },
    {
      title: t("National burden upper limit monthly amount"),
      dataIndex: "upper_limit_amount",
      render: (data) => `${data}円`,
    },
    {
      title: t("Upper limit monthly amount to be changed"),
      dataIndex: "changed_upper_limit_amount",
      className: "min-120",
      render: (data) => `${data}円`,
    },
    {
      title: t(
        "Upper limit management information for details such as outpatient benefits for children with disabilities, admission benefits, etc."
      ),
      dataIndex: "output",
      render: (data) => (!data || data == "0" ? t("Output") : t("No output")),
    },
    {
      title: t(
        "Outpatient benefits for children with disabilities, admission benefits, etc. Management result number to be output on the statement"
      ),
      dataIndex: "benefits",
      render: (data) => (!data || data == "0" ? t("No setting") : data),
    },
    {
      title: t(
        "Management result number to be output to the maximum user burden amount management result slip"
      ),
      dataIndex: "management_result",
      render: (data) => (!data || data == "0" ? t("No setting") : data),
    },
  ]

  return (
    <>
      <BillingSectionHeader
        num={1}
        title={t("Confirmation of the changed upper limit monthly amount")}
        disabled={disabled}
      >
        <div className={"disable-overlay"}></div>
        <AccordionWrapper
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          <Collapse.Panel
            header={t(
              "Display the changed upper limit monthly amount for {{month}} {{year}}",
              {
                month: month?.toString()?.padStart(2, "0"),
                year,
              }
            )}
            key={"1"}
          >
            {isLoading ? (
              <div className={"spin-container"}>
                <Spin size={"large"} />
              </div>
            ) : (
              <>
                <OwnerTable
                  columns={columns}
                  dataSource={childWithChangedLimit || []}
                />
                <div className={"link-container"}>
                  <Link
                    href={`/national-health-insurance-billing/change-upper-limit?year=${year}&month=${month}&facility_id=${facility_id}`}
                  >
                    <a>{t("Click here to set the maximum monthly charge")}</a>
                  </Link>
                </div>
              </>
            )}
          </Collapse.Panel>
        </AccordionWrapper>
      </BillingSectionHeader>
    </>
  )
}
