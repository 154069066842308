import { Empty, Skeleton, Spin, Tag } from "antd"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useInfiniteQuery, useMutation } from "react-query"
import styled from "styled-components"
import { fetchCompanyNofications, seenNotification } from "../../../services"
import {
  Detail,
  Divisor,
  Highlighter,
  scrollToElement,
  TagWrapper,
  Title,
  TitleWrapper,
  UrlDetail,
} from "../../../utils"
import { toastMsg } from "../../../utils/toastMsg"
import moment from "moment"
import { notificationTypes, theme } from "@project/shared"
import { isNotificationSeen } from "../../../utils/notifications"
import { useTranslation } from "react-i18next"

const TableContainer = styled.div`
  display: contents;
`
const TableTitle = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 1fr;
  background: #f3f3f3;
  margin-bottom: 16px;
  border: 1px solid #d2d1d1;
  .row {
    padding: 16px;
  }
`
const CenteredText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CustomInfiniteScroll = styled.div`
  height: 260px;
  overflow-y: auto;
  & > div:first-child {
    margin-top: 0;
  }
  .unread {
    font-weight: bold !important;
  }
`
const CustomTable = styled.div`
  min-width: 600px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    min-width: 600px;
  }
`
const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 1fr;
  align-items: center;
`

const getNotiTagBgColor = (
  notiType: "notification" | "important" | "update"
) => {
  if (notiType === "notification") return "#ef8237"
  if (notiType === "important") return "#FF0000"
  if (notiType === "update") return "#2195cb"
}

const NotificationModalComponent: React.FC<{
  notificationDetailData?: any
  refetch?: any
}> = ({ notificationDetailData, refetch }) => {
  const [showDetailData, setShowDetailData] = useState(null)
  const observerElem = useRef(null)
  const notiFicationDetailRef = useRef()
  const [page] = useState(0)
  const pageSize = 5
  const [readNotifications, setReadNotifications] = useState<number[]>([])
  const { t } = useTranslation()

  const {
    data: notificationData,
    isLoading,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["fetch-notification", page, pageSize],
    fetchCompanyNofications,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      getNextPageParam: (lastpage, allPages) => {
        const nextPage = allPages.length + 1
        if (lastpage?.nextPage <= lastpage?.count) {
          return nextPage
        }
        return undefined
      },
    }
  )

  useEffect(() => {
    if (notificationDetailData) {
      setShowDetailData(notificationDetailData)
      mutate({
        notification_id: notificationDetailData.id,
        ...notificationDetailData,
      })
    }
  }, [notificationDetailData])

  const { mutate, isLoading: isLoadingUnseen } = useMutation(seenNotification, {
    onSuccess: (_, data) => {
      setShowDetailData(data)
      refetch()
    },
    onError: (err: any) => {
      toastMsg("error", t(`${err?.data?.error?.message}`))
    },
  })

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries
      if (target.isIntersecting) {
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage]
  )

  useEffect(() => {
    const element = observerElem.current
    const option = { threshold: 0 }

    const observer = new IntersectionObserver(handleObserver, option)
    if (element) {
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [fetchNextPage, hasNextPage, handleObserver])

  useEffect(() => {
    let fetching = false
    const handleScroll = async (e) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
        fetching = true
        if (hasNextPage) await fetchNextPage()
        fetching = false
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [fetchNextPage, hasNextPage])

  return (
    <div ref={notiFicationDetailRef}>
      {!!showDetailData &&
        (isLoadingUnseen ? (
          <Skeleton />
        ) : (
          <>
            <TagWrapper>
              <Tag
                style={{
                  borderRadius: "20px",
                  padding: "5px 20px",
                  color: "#fff",
                }}
                color={getNotiTagBgColor(showDetailData.notification_type)}
              >
                {t(
                  `${showDetailData.notification_type
                    .charAt(0)
                    .toUpperCase()}${showDetailData.notification_type.slice(1)}`
                )}
              </Tag>
              {moment(showDetailData.publishing_date).format("YYYY年MM月DD日")}
            </TagWrapper>
            <Title>{showDetailData.notification_title}</Title>
            <Divisor />
            <Detail>{showDetailData.notification_details}</Detail>
            {showDetailData?.url && (
              <UrlDetail>
                {t("URL")}
                {":"}{" "}
                <a
                  className={"url-text"}
                  href={showDetailData?.url}
                  target={"_blank"}
                >
                  {showDetailData?.url}
                </a>
              </UrlDetail>
            )}
            <TitleWrapper>
              <Highlighter />
              <Title>{t("Past notifications")}</Title>
            </TitleWrapper>
          </>
        ))}
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <CustomTable>
          <TableTitle>
            <div className={"row"}>{t("Notification Type")}</div>
            <div className={"row"}>{t("Publishing date")}</div>
            <div className={"row"}>{t("Notification Title")}</div>
          </TableTitle>
          {(isFetching || isLoading) && !isFetchingNextPage ? (
            <Skeleton style={{ marginTop: "18px" }} />
          ) : (
            <TableContainer id={"scrollableDiv"}>
              {!notificationData ? (
                <Empty description={t("No Data Found")} />
              ) : (
                <CustomInfiniteScroll>
                  {notificationData?.pages?.map((group, index_group) => {
                    return (
                      <div key={index_group}>
                        {group?.data?.map((data, index) => {
                          return (
                            <div key={`${index}-${data?.index || 0}`}>
                              <DataWrapper>
                                <div style={{ padding: "8px 16px" }}>
                                  <Tag
                                    style={{
                                      borderRadius: "20px",
                                      padding: "5px 20px",
                                      color: "#fff",
                                    }}
                                    color={getNotiTagBgColor(
                                      data.notification_type
                                    )}
                                  >
                                    {notificationTypes &&
                                      t(
                                        notificationTypes.find(
                                          (o) =>
                                            o.value === data.notification_type
                                        )?.label
                                      )}
                                  </Tag>
                                </div>
                                <div
                                  style={{ padding: "8px 16px" }}
                                  className={
                                    isNotificationSeen(data, readNotifications)
                                      ? "read"
                                      : "unread"
                                  }
                                >
                                  {moment(data.publishing_date).format(
                                    "YYYY年MM月DD日"
                                  )}
                                </div>
                                <div
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    padding: "8px 16px",
                                  }}
                                  onClick={() => {
                                    mutate({
                                      notification_id: data.id,
                                      ...data,
                                    })
                                    scrollToElement(
                                      notiFicationDetailRef,
                                      "start"
                                    )
                                    setReadNotifications((prev) => [
                                      ...prev,
                                      data?.id,
                                    ])
                                  }}
                                  className={
                                    isNotificationSeen(data, readNotifications)
                                      ? "read"
                                      : "unread"
                                  }
                                >
                                  {data.notification_title}
                                </div>
                              </DataWrapper>
                              <Divisor
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                  <div className={"loader"} ref={observerElem}>
                    {isFetchingNextPage && hasNextPage ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spin />
                      </div>
                    ) : (
                      <CenteredText>
                        {t("This is the end of the notification")}
                      </CenteredText>
                    )}
                  </div>
                </CustomInfiniteScroll>
              )}
            </TableContainer>
          )}
        </CustomTable>
      </div>
    </div>
  )
}

export { NotificationModalComponent }
