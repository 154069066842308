import styled, { css } from "styled-components"
import { theme } from "@project/shared"

export const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  @media print {
    padding: 0;
  }

  .field-error:empty {
    display: none;
  }

  .field-error:not(:empty) {
    display: block;
  }

  .date__picker {
    display: flex;
    gap: 8px !important;
    align-items: baseline;
  }
`

const CLABEL = css`
  min-width: 150px;
  max-width: 150px;
  @media (max-width: ${theme.breakpoints?.md}) {
    min-width: 100%;
    max-width: 100%;
  }
  svg {
    color: ${theme.gray2};
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .long-label-text {
    display: flex;
    align-items: baseline;
  }
  .title {
    border-left: 3px solid ${theme.blue5};
    padding-left: 8px;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: ${theme.black};
      margin: 0;
    }
  }
  .child__picker {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &--furigana {
      width: 80px;
    }
    &--name {
      width: calc(100% - 230px);
      max-width: calc(100% - 230px);
      .dynamic-dropdown-width {
        max-width: 100%;
      }

      @media (max-width: 767px) {
        width: auto !important;
        min-width: 300px;
        max-width: calc(100% - 88px);

        .dynamic-dropdown-width {
          min-width: 100%;
        }
      }

      @media (max-width: 448px) {
        width: auto !important;
        min-width: calc(100% - 88px);
        max-width: calc(100% - 88px);
      }
    }
  }
  .use__service {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &--checkbox {
      display: flex;
    }
  }
  .facility {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &__checkbox {
      .check_uncheck_all {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        gap: 10px;
        button {
          height: 30px;
          display: flex;
          gap: 4px;
          align-items: center;
          outline: 0;
          &:hover,
          &:focus {
            border-color: ${theme.gray2};
          }
          .span {
            @media (max-width: ${theme.breakpoints.md}) {
              font-size: 14px;
            }
          }
        }
      }
      .checkboxs {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media (max-width: ${theme.breakpoints.sm}) {
          .ant-checkbox + span {
            white-space: pre-line;
            word-break: break-all;
            padding-left: 5px;
          }
        }
      }
    }
  }
  .date {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &__picker {
      display: flex;
      gap: 1rem;
    }
  }
  .display__settings,
  .status {
    display: flex;
    gap: 1rem;
    align-items: center;
    .label {
      ${CLABEL}
    }
    &--box {
      display: flex;
      gap: 1rem;
    }
  }
  .filter__cta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media screen and (max-width: 426px) {
      flex-wrap: nowrap;
      gap: 0;
      justify-content: space-between;
    }
  }
  .add__cta {
    border-top: 1px dashed ${theme.gray2};
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (max-width: 426px) {
      .create_btn {
        padding: 12px 12px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    .child__picker {
      flex-wrap: wrap;
      gap: 0.5rem;
      &--name {
        width: calc(100% - 90px);
      }
    }
    .use__service,
    .facility,
    .date,
    .status,
    .display__settings {
      flex-direction: column;
      gap: 0.1rem;
    }
    .use__service {
      &--checkbox {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
    .facility {
      &--checkboxs {
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
    .display__settings {
      gap: 0.5rem;
      .status--box {
        flex-direction: column;
        gap: 0.3rem;
      }
    }
    .status {
      align-items: flex-start;
    }
  }
`
