import React, { FormEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  SelectInput,
  Checkboxes,
  TextField,
  SERVICES,
  removeBlankAttributes,
  theme,
  setItemToLocalstorage,
} from "@project/shared"

import { AuthContext, Highlighter, Title, TitleWrapper } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../../molecules"
import moment from "moment"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearchReset?: () => void
  setIsButtonClicked?: React.Dispatch<any>
  setQueryParams?: React.Dispatch<
    React.SetStateAction<{
      childId: any
      keyword: string
      facilities: any[]
      createdStatus: string
      usedServices: any
      displaySignature: any
    }>
  >
  queryParams?: {
    childId: any
    keyword: string
    facilities: any[]
    createdStatus: string
    usedServices: any
    displaySignature: any
    date_from: string
    date_to: string
  }
  setSelectedChild?: any
  selectedChild?: any
  furiganaSelect?: string
  setFurignaValue?: (string) => void
  childList?: any
}

const Wrapper = styled.form<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-up {
    margin-right: 6px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 12px 20px 24px 20px;
  }

  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
    gap: 10px;
    button {
      height: 30px;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    .facility-wrapper {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }

  &.child-name-field-container {
    @media (max-width: 567px) {
      /* flex-direction: column;
      & > div:last-child {
        margin-top: 5px;
      } */
    }
  }

  .child-name-dropdown-group {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
    flex: 1;
    @media (max-width: 573px) {
      column-gap: 8px;
      .long-select {
        flex: 1;
        .ant-select {
          width: 100%;
        }
      }
    }

    @media (max-width: 440px) {
      width: 100%;
      flex-wrap: wrap;
      .long-select {
        min-width: 120px;
      }
    }
  }
  &.date-range-container {
    .ant-picker {
      height: 42px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .text-field {
    width: 100%;
    @media (max-width: 769px) {
      margin-left: 0px;
    }
  }

  input {
    background-color: #ffffff;
    width: 670px;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;

  .select-class {
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .date-picker {
    height: 40px;
    width: 160px;
    @media (max-width: 590px) {
      width: auto;
    }
  }

  &.display-setting-checkbox .checkbox-group .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        margin: 0;
      }
    }
    label {
      gap: 0;
      span:last-child {
        text-indent: initial;
        margin-left: 10px;
      }
    }
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 145px;

  @media (max-width: 760px) {
    span {
      word-break: break-all;
    }
  }

  @media (max-width: 434px) {
    padding-left: 0px;
  }
`

const UserServiceProvisionResultComponent: React.FC<
  iOperationOptionComponent
> = (props) => {
  const {
    header,
    queryParams,
    setQueryParams,
    handleSearchReset,
    setSelectedChild,
    selectedChild,
    furiganaSelect,
    setFurignaValue,
    childList,
  } = props
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useContext(AuthContext)

  const [temFilterData, setTemFilterData] = useState({
    childId: queryParams?.childId || "",
    keyword: queryParams?.keyword || "",
    facilities: queryParams?.facilities || [],
    createdStatus: queryParams?.createdStatus || "",
    usedServices: queryParams?.usedServices || null,
    displaySignature: queryParams?.displaySignature || null,
    date_from: queryParams?.date_from || null,
    date_to: queryParams?.date_to || null,
  })

  useEffect(() => {
    setTemFilterData((prev) => {
      return {
        ...prev,
        date_from: queryParams?.date_from,
        date_to: queryParams?.date_to,
      }
    })
  }, [queryParams])

  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const allFacility = FACILITY_OPTION.map((item) => item.value)

  const setQueryParamField = (field, value) => {
    const params = { ...temFilterData }
    params[field] = value
    setTemFilterData({ ...params })
  }

  const SIGNATURE_OPTION = [
    {
      label: t("Display Signature"),
      value: "1",
    },
  ]

  const CREATE_STATUS_OPTIONS = [
    {
      label: t("All"),
      value: "0",
    },
    {
      label: t("Not Created"),
      value: "not_created",
    },
    {
      label: t("Created"),
      value: "APPROVED",
    },
    {
      label: t("Draft"),
      value: "DRAFT",
    },
  ]
  const [queryInitialDates] = useState({
    date_from:
      queryParams.date_from && moment(queryParams.date_from)?.isValid()
        ? queryParams.date_from
        : null,
    date_to:
      queryParams.date_to && moment(queryParams.date_to)?.isValid()
        ? queryParams.date_to
        : null,
  })
  const handleSearch = () => {
    setItemToLocalstorage("user-service-provision-result", {
      date_from: temFilterData.date_from,
      date_to: temFilterData.date_to,
      facilities_ids: temFilterData?.facilities?.join(","),
    })
    setQueryParams(temFilterData)
    handleSearchReset()
    updateParams(
      {
        ...temFilterData,
        facilities: temFilterData?.facilities?.join(","),
      },
      "/user-service-provision-result"
    )
  }

  return (
    <Wrapper
      {...props}
      onSubmit={(e: FormEvent) => {
        e.preventDefault()
        handleSearch()
      }}
    >
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>

      <FieldWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Keyword")} </Text>
        </TitleWrap>

        <TextField
          name={"company_name"}
          height={"50px"}
          placeholder={t(
            "Contents of services provided and the state of the day, care records, life records"
          )}
          bgcolor={"#fff"}
          value={temFilterData.keyword}
          onChange={(e) => setQueryParamField("keyword", e.target.value)}
          className={"text-field"}
        />
      </FieldWrapper>
      <SearchWrapper className={"date-range-container"}>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Date")} </Text>
        </TitleWrap>
        <SelectWrap>
          <DatePicker
            style={{ width: "150px" }}
            date={
              temFilterData?.date_from &&
              moment(temFilterData?.date_from).isValid()
                ? moment(temFilterData?.date_from)
                : null
            }
            onDateChange={(d) => {
              setQueryParamField("date_from", d?.format("YYYY-MM-DD"))
            }}
            format={"YYYY年MM月DD日"}
          />
        </SelectWrap>
        &nbsp;{"~"}&nbsp;
        <SelectWrap>
          <DatePicker
            style={{ width: "150px" }}
            date={
              temFilterData?.date_to && moment(temFilterData?.date_to).isValid()
                ? moment(temFilterData?.date_to)
                : null
            }
            onDateChange={(d) => {
              setQueryParamField("date_to", d?.format("YYYY-MM-DD"))
            }}
            format={"YYYY年MM月DD日"}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper className={"child-name-field-container"}>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Child name")} </Text>
        </TitleWrap>
        <div className={"child-name-dropdown-group"}>
          <SelectInput
            width={80}
            height={40}
            options={FuriganaAlphabetsOption}
            onChange={(value) => {
              setFurignaValue(value)
            }}
            className={"select-down"}
            defaultValue={"--"}
            value={furiganaSelect}
          />

          <SelectInput
            width={200}
            height={40}
            options={childList}
            className={"long-select"}
            placeholder={t("All")}
            value={selectedChild || temFilterData.childId}
            onChange={(val) => {
              setQueryParamField("childId", val)
              setSelectedChild(val)
            }}
          />
        </div>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("facility name")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            width={145}
            typeOf={"check-all"}
            text={t("Check All")}
            onClick={() => {
              setQueryParamField("facilities", allFacility)
            }}
          />
          <OwnerButton
            width={100}
            typeOf={"secondary"}
            className={"uncheck-all-btn"}
            text={t("Uncheck All")}
            backgroundColor={"#fff"}
            onClick={() => setQueryParamField("facilities", [])}
          />
        </div>
      </SearchWrapper>

      <SearchWrapper>
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            options={FACILITY_OPTION}
            value={temFilterData?.facilities}
            onChange={(values) => setQueryParamField("facilities", values)}
          />
        </CheckboxWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Service use")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            options={SERVICES}
            value={temFilterData?.usedServices}
            onChange={(values) => setQueryParamField("usedServices", values)}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap className={"display-setting-checkbox"}>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={temFilterData?.displaySignature}
            onChange={(values) =>
              setQueryParamField("displaySignature", values)
            }
            options={SIGNATURE_OPTION}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Create status")} </Text>
        </TitleWrap>
        <SelectWrap>
          <SelectInput
            width={120}
            height={40}
            options={CREATE_STATUS_OPTIONS}
            value={temFilterData?.createdStatus}
            onChange={(value) => setQueryParamField("createdStatus", value)}
            className={"select-down"}
            defaultValue={t("All")}
          />
        </SelectWrap>
      </SearchWrapper>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          onClick={() => {
            const initial = {
              childId: "",
              keyword: "",
              facilities: null,
              createdStatus: "",
              usedServices: null,
              displaySignature: null,
              date_from: queryInitialDates.date_from,
              date_to: queryInitialDates.date_to,
            }
            if (Object.keys(removeBlankAttributes(temFilterData)).length != 0) {
              setTemFilterData(initial)
              setQueryParams(initial)
              updateParams({}, "/user-service-provision-result")
            }
            setSelectedChild(0)
            handleSearchReset()
          }}
          text={t("Reset search")}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { UserServiceProvisionResultComponent }
