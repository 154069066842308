import { API } from "@project/shared"

export interface IReturnProgress {
  count?: number
  data?: any
}

interface IProgressParam {
  page?: string
  pageSize?: string
}

export const fetchProgress = async ({ queryKey }): Promise<IReturnProgress> => {
  const params = {} as IProgressParam

  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"
  return API.get(`/progress-management`, {
    params,
  })
}

export const fetchProgressWithCount = async ({
  queryKey,
}): Promise<IReturnProgress> => {
  const params = {} as IProgressParam

  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"

  return API.get(`/progress-management/with-count`, {
    params,
  })
}

export const getProgress = async (id) => {
  return API.get(`/progress-management/${id}`)
}

export const createProgress = async (values): Promise<any> => {
  return API.post(`/progress-management`, values)
}

export const updateProgress = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/progress-management/${id}`, values)
}

export const deleteProgress = async (id) => {
  return API.delete(`/progress-management/${id}`)
}
