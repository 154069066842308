// packages
import moment from "moment"

// styled components
import {
  FacilityChildListContainer,
  PrintButtonContainer,
  OfficeContainer,
} from "../styles"

// components
import { OwnerButton } from "../../../atoms"
import {
  Checkbox,
  SelectInput,
  Table,
  TopRoundedWhiteCard,
} from "@project/shared"
import { ChangeDisplayBox, InfoBox, NoticeBox, PrimaryActionButtons } from "."

// utils
import { Divisor } from "../../../../utils"

// types
import { FacilityChildListBlockProps } from "../types"
import { getLanguage } from "../../../../utils/getLanguage"
import { useQuery } from "react-query"
import { getMaximumResultSlipOutput } from "../../../../services/NHIFbilling"
import { useRouter } from "next/router"
import { Spin, notification } from "antd"
import { useTranslation } from "react-i18next"
import { useMemo, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { addCommas } from "../utils/commaSeparator"
import { PrintListContent } from "./PrintListContent"
import { PRINT_CONFIGURATION_OPTION_VALUES } from "../constants"
// This is a main text that appears on the top of info box component
const InfoBoxMainText = ({ billingMethod }) => {
  return (
    <>
      {getLanguage() === "ja" ? (
        <p className={"info-text"}>
          {"国保連に"}
          <span className={"red-text"}>{`【${billingMethod}】`}</span>
          {"で請求する設定なっております。"}
        </p>
      ) : (
        <p className={"info-text"}>
          {" "}
          {"It is set to be billed to the National Health Insurance Federation"}
          {"as"}
          <span className={"red-text"}>{`【${billingMethod}】.`}</span>
        </p>
      )}
    </>
  )
}

const LOCAL_STORAGE_KEY = "MAXIMUM_RESULT_SLIP_TEMP"

export const FacilityChildListBlock: React.FC<FacilityChildListBlockProps> = ({
  currentDate,
  handleCSVDownload,
  currentBillingMethodName,
  isBillingInfoLoading,
  currentSearchQueries,
  isCSVDownloading = false,
  isUserFeeConfirmed = false,
  togglePrintPage,
  isPrintPage = false,
  printFilter,
}) => {
  const { t } = useTranslation()

  const router = useRouter()
  const { after_school } = router.query

  const [displayMunicipality, setDisplayByMunicipality] = useState(true)
  const [maximumOutputSlip, setMaximumOutputSlip] = useState({
    output: [],
    siblings: [],
    parentIdsWithSiblings: [],
    upperLimitManagedChild: [],
    upperLimitManagedChildIds: [],
  })

  const selectedRef = useMemo(() => {
    const selectedChilds = []
    maximumOutputSlip?.output?.forEach((o) => {
      o?.child_data?.forEach((p) => {
        if (p?.selected) {
          p?.child_maximum_result?.forEach((c) => {
            if (selectedChilds?.indexOf(c?.child_id) == -1) {
              selectedChilds.push(c.child_id)
            }
          })
        }
      })
    })
    return selectedChilds
  }, [maximumOutputSlip])

  const { isLoading } = useQuery(
    ["maximum-result-output-slip", currentSearchQueries],
    () =>
      getMaximumResultSlipOutput({
        facilityId: currentSearchQueries?.facility,
        year: currentSearchQueries?.year,
        month: currentSearchQueries?.month,
        query: after_school ? `is_afterschool=${after_school}` : "",
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: ({ data }) => {
        let temporarySaved: any = {}
        try {
          const localData = localStorage.getItem(LOCAL_STORAGE_KEY)
          if (localData) temporarySaved = JSON.parse(localData)
        } catch (e) {
          notification.error({
            message: t("Something went wrong. Please contact administrator"),
          })
        }

        const parentIdsWithSiblings = []
        const siblings = Object.keys(data?.siblings)?.map((key) => {
          parentIdsWithSiblings.push(key)
          return data?.siblings[key]
        })
        const upperLimitManagedChild = Object.keys(
          data?.upper_limit_managed_child
        )?.map((key) => {
          return data?.upper_limit_managed_child[key]
        })

        setMaximumOutputSlip({
          output: temporarySaved
            ? data?.output?.map((op) => {
                const newChildData = op?.child_data.map((p) => {
                  const selection =
                    Array.isArray(temporarySaved?.selected_parent_ids) &&
                    temporarySaved?.selected_parent_ids?.includes(p?.parent_id)
                  return {
                    ...p,
                    selected: selection,
                    child_maximum_result: p?.child_maximum_result?.map(
                      (cmr) => {
                        const tempDivision = Array.isArray(
                          temporarySaved?.child_divisions
                        )
                          ? temporarySaved?.child_divisions?.find(
                              (d) => d?.id == cmr?.id
                            )?.division
                          : 0
                        return {
                          ...cmr,
                          selected: selection,
                          division: tempDivision || "1",
                        }
                      }
                    ),
                  }
                })
                return { ...op, child_data: newChildData }
              })
            : data?.output,
          siblings,
          parentIdsWithSiblings,
          upperLimitManagedChild,
          upperLimitManagedChildIds: data?.upper_limit_managed_child_ids,
        })
      },
      onError: (e: any) => {
        const msg =
          e?.status == 403
            ? "User doesn't have permission to view this page"
            : e?.error?.data?.message ||
              "Something went wrong. Please contact administrator"
        notification.error({
          message: t(msg),
        })
      },
    }
  )

  const setChecked = (childId) => {
    const newOutput = maximumOutputSlip.output?.map((op) => {
      const newChildData = op?.child_data.map((p) => {
        const hasChildData = p?.child_maximum_result.find(
          (c) => c?.child_id == childId
        )
        if (hasChildData) {
          const current_selection = p?.selected
          return {
            ...p,
            selected: !current_selection,
            child_maximum_result: p?.child_maximum_result?.map((cmr) => {
              return { ...cmr, selected: !current_selection }
            }),
          }
        }
        return p
      })
      return { ...op, child_data: newChildData }
    })

    setMaximumOutputSlip((prev) => {
      return { ...prev, output: newOutput }
    })
  }

  const handleDivisionChange = (division, id) => {
    const newOutput = maximumOutputSlip.output?.map((op) => {
      const newChildData = op?.child_data.map((p) => {
        return {
          ...p,
          child_maximum_result: p?.child_maximum_result?.map((cmr) => {
            if (cmr.id == id) {
              cmr.division = division
            }
            return cmr
          }),
        }
      })
      return { ...op, child_data: newChildData }
    })

    setMaximumOutputSlip((prev) => {
      return { ...prev, output: newOutput }
    })
  }

  const handleCheckAll = (selection = true) => {
    const newOutput = maximumOutputSlip.output?.map((op) => {
      const newChildData = op?.child_data.map((p) => {
        return {
          ...p,
          selected: selection,
          child_maximum_result: p?.child_maximum_result?.map((cmr) => {
            return { ...cmr, selected: selection }
          }),
        }
      })
      return { ...op, child_data: newChildData }
    })

    setMaximumOutputSlip((prev) => {
      return { ...prev, output: newOutput }
    })
  }

  const handleCheckAllExceptSibling = () => {
    const newOutput = maximumOutputSlip.output?.map((op) => {
      const newChildData = op?.child_data.map((p) => {
        const selection = !maximumOutputSlip?.parentIdsWithSiblings?.includes(
          p?.parent_id?.toString()
        )
        return {
          ...p,
          selected: selection,
          child_maximum_result: p?.child_maximum_result?.map((cmr) => {
            return { ...cmr, selected: selection }
          }),
        }
      })
      return { ...op, child_data: newChildData }
    })

    setMaximumOutputSlip((prev) => {
      return { ...prev, output: newOutput }
    })
  }

  const handleTemporarySave = () => {
    try {
      const localStorageItem = {
        selected_parent_ids: [],
        child_divisions: [],
      }

      maximumOutputSlip.output?.map((op) => {
        op?.child_data.map((p) => {
          if (p.selected) {
            localStorageItem.selected_parent_ids.push(p?.parent_id)
          }
          p?.child_maximum_result?.map((cmr) => {
            if (cmr?.division) {
              localStorageItem.child_divisions?.push({
                id: cmr?.id,
                division: cmr?.division,
              })
            }
          })
        })
      })

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageItem))

      notification.success({
        message: t("Temporarily save") + " " + t("successfully"),
      })
    } catch (e) {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    }
  }

  const outputCount = useMemo(() => {
    let outputCount = 0
    maximumOutputSlip.output?.map((op) => {
      op?.child_data.map((p) => {
        if (p?.selected) outputCount++
      })
    })
    return outputCount
  }, [maximumOutputSlip?.output])

  const columns = [
    {
      title: t("Selection"),
      key: "selection",
      width: 79,
      className: "no-print",
      render: (row) => (
        <Checkbox
          className={"selection-checkbox"}
          checked={row?.selected}
          onChange={() => setChecked(row?.child_id)}
        />
      ),
      onCell: (row, index) => {
        return {
          rowSpan: !index ? row?.total_length : 0,
        }
      },
    },
    {
      title: t("Division"),
      dataIndex: "division",
      key: "division",
      width: 143,
      className: "division-head-cell head-cell",
      render: (data, row) => (
        <SelectInput
          options={[
            { label: t("New"), value: "1" },
            { label: t("Correction"), value: "2" },
            { label: t("Cancel"), value: "3" },
          ]}
          value={data}
          onChange={(val) => handleDivisionChange(val, row?.id)}
        />
      ),
    },
    {
      title: t("Item"),
      key: "item",
      width: 65,
      className: "item-head-cell head-cell",
      render: (data, row, index) => index + 1,
    },
    {
      title: t("Child Name"),
      key: "child_name",
      dataIndex: "child_name",
    },
    {
      title: t("Business number"),
      dataIndex: "office_number",
      key: "businessNumber",
      width: 144,
      className: "business-number-head-cell head-cell",
    },

    {
      title: t("Name of business"),
      dataIndex: "office_name",
      key: "nameOfBusiness",
      className: "name-of-business-head-cell head-cell",
    },

    {
      title: t("Total cost"),
      dataIndex: "total_cost",
      key: "totalCost",
      width: 144,
      className: "total-cost-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },

    {
      title: t("User burden"),
      dataIndex: "user_fee",
      key: "userBurden",
      width: 144,
      className: "user-burden-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },

    {
      title: t("After control result user burden"),
      dataIndex: "user_burden_after_management",
      key: "afterCtrlResultUserBurden",
      width: 144,
      className: "after-ctrl-result-user-burden-head-cell head-cell",
      render: (data) => addCommas(data) + "円",
    },
  ]

  const getParentChildNames = (parentData) => {
    const childNames =
      parentData?.child_maximum_result?.map((child) => child?.child_name) || []

    const nonDuplicatedNames = childNames?.filter(
      (name, index) => index == childNames.indexOf(name)
    )

    return nonDuplicatedNames?.join(" / ")
  }

  const renderNoticeBox = () =>
    maximumOutputSlip?.upperLimitManagedChild?.length > 0 ? (
      <NoticeBox siblings={maximumOutputSlip?.upperLimitManagedChild} />
    ) : (
      <></>
    )
  const printContentRef = useRef<HTMLDivElement>(null)
  const onPrint = useReactToPrint({
    content: () => printContentRef?.current,
    pageStyle: `@media print{
      @page{
        size: auto !important;
        margin: 10mm 4mm !important;
      }
    }`,
  })

  const getOfficeWiseData = () => {
    const officeData = []

    maximumOutputSlip?.output?.forEach((v) => {
      const filteredChilds = v?.child_data?.filter((value) => value?.selected)

      filteredChilds?.forEach((cd) => {
        cd?.child_maximum_result?.forEach((cmr) => {
          const indexOfSameBusiness = officeData.findIndex(
            (od) =>
              od.office_name == cmr.office_name &&
              od.office_number == cmr.office_number
          )
          const selectedItem = {
            ...v,
            child_data: filteredChilds,
          }
          if (indexOfSameBusiness > -1) {
            officeData[indexOfSameBusiness].selectedItems.push(selectedItem)
          } else {
            const newOfficeData = {
              office_name: cmr.office_name,
              office_number: cmr.office_number,
              selectedItems: [selectedItem],
            }
            officeData.push(newOfficeData)
          }
        })
      })
    })

    return officeData
  }

  const isOfficeWisePrintView =
    isPrintPage &&
    printFilter?.configuration?.includes(
      PRINT_CONFIGURATION_OPTION_VALUES.PRINT_BY_OFFICE
    )

  const selectedItems: any = isOfficeWisePrintView
    ? getOfficeWiseData()
    : maximumOutputSlip?.output?.map((v) => {
        return {
          ...v,
          child_data: v?.child_data?.filter((value) => value?.selected),
        }
      })

  const isPrintBtnDisable = isOfficeWisePrintView
    ? selectedItems?.length > 0
    : selectedItems?.flat()?.filter((v) => v?.child_data?.length)?.length > 0

  const startCSVDownload = (csvFor: number) => {
    if (Array.isArray(selectedRef)) {
      const burdens = []
      selectedRef.forEach((childId) => {
        maximumOutputSlip.output.forEach((op) => {
          op?.child_data?.forEach((cd) => {
            cd.child_maximum_result?.every((cm) => {
              if (cm?.child_id == childId) {
                burdens.push(cm?.user_burden_after_management)
                return false
              }
            })
          })
        })
      })
      handleCSVDownload(csvFor, selectedRef?.join(","), burdens?.join(","))
    }
  }

  const disableCSVButton = () => {
    // disable if user fee is not confirmed or no child is selected
    if (!isUserFeeConfirmed || !selectedRef?.length) return true
    let isUpperLimitManagedChildSelected = false
    selectedRef?.some((c) => {
      if (maximumOutputSlip.upperLimitManagedChildIds?.includes(c)) {
        isUpperLimitManagedChildSelected = true
      }
      return isUpperLimitManagedChildSelected
    })
    return isUpperLimitManagedChildSelected
  }

  return (
    <>
      {!isPrintPage ? (
        <FacilityChildListContainer>
          <TopRoundedWhiteCard
            className={"operation-rounded-container"}
            title={t("{{currEngDate}} Facility use children list", {
              currEngDate: moment(currentDate).format("MMM, YYYY"),
              currJaDate: moment(currentDate).format("YYYY年MM月"),
            })}
          >
            {/* Upper notice section starts here */}
            {!isBillingInfoLoading && (
              <div className={"upper-notice-section no-print"}>
                <InfoBox
                  href={`/national-health-insurance-billing?facility_id=${currentSearchQueries?.facility}&year=${currentSearchQueries?.year}&month=${currentSearchQueries?.month}&change_billing=1`}
                  linkText={"Change billing information"}
                >
                  <InfoBoxMainText billingMethod={currentBillingMethodName} />
                </InfoBox>
                <ChangeDisplayBox
                  isDisplayByMunicipality={displayMunicipality}
                  setDisplayByMunicipality={(val) =>
                    setDisplayByMunicipality(val)
                  }
                />
                {renderNoticeBox()}
              </div>
            )}
            {/* Upper notice section ends here */}

            <Divisor className={"divider no-print"} />
            {isLoading ? (
              <div className={"spin-container"}>
                <Spin size={"large"} />
              </div>
            ) : (
              <>
                {/* upper-mid multi-functional buttons section starts here */}
                <div className={"multi-functional-btns-section no-print"}>
                  <PrimaryActionButtons
                    handlePrint={togglePrintPage}
                    handleCSVDownload={startCSVDownload}
                    isCSVDownloading={isCSVDownloading}
                    outputCount={outputCount}
                    disableOutputBtn={disableCSVButton()}
                    disablePrintBtn={!isPrintBtnDisable}
                    handleTemporarySave={handleTemporarySave}
                  />
                  <div className={"secondary-action-btn-group flexbox"}>
                    <OwnerButton
                      text={t("Check all")}
                      typeOf={"check-all"}
                      onClick={() => handleCheckAll()}
                    />
                    <OwnerButton
                      text={t("Check all except siblings")}
                      typeOf={"check-all"}
                      onClick={() => handleCheckAllExceptSibling()}
                    />
                    <OwnerButton
                      text={t("Clear")}
                      typeOf={"outlined"}
                      onClick={() => handleCheckAll(false)}
                    />
                  </div>
                </div>
                {/* upper-mid multi-functional buttons section ends here */}

                {/* mid table section starts here */}
                <div className={"table-section"}>
                  <p className={"guide-line-text no-print"}>
                    {t(
                      "You can change the item number by moving the mouse while clicking on the line marked with ."
                    )}
                  </p>
                  {maximumOutputSlip?.output?.map((byProvisionCity) => (
                    <>
                      {displayMunicipality &&
                      (byProvisionCity.provision_city_name ||
                        byProvisionCity.provision_city_number) ? (
                        <h3 className={"provision-city-header"}>{`${
                          byProvisionCity.provision_city_name
                        } ${
                          byProvisionCity.provision_city_number &&
                          "(" + byProvisionCity.provision_city_number + ")"
                        }`}</h3>
                      ) : (
                        <></>
                      )}
                      {byProvisionCity?.child_data?.map((parent) => (
                        <>
                          <h4 className={"name-text no-print"}>
                            {parent?.parent_name}
                          </h4>

                          <p className={"stat-info no-print"}>
                            {getParentChildNames(parent)}
                            <span className={"red-text"}>
                              {" 利用事業所数(含自事業所)："}
                              <span className={"bold-text"}>
                                {parent?.child_maximum_result?.length}
                              </span>
                              {"  上限額管理結果："}
                              <span className={"bold-text"}>{"1"}</span>
                            </span>
                          </p>

                          <div className={"table-container"}>
                            <Table
                              columns={
                                maximumOutputSlip?.parentIdsWithSiblings?.includes(
                                  parent?.id
                                )
                                  ? columns
                                  : columns?.filter(
                                      (c) => c?.key != "child_name"
                                    )
                              }
                              dataSource={parent?.child_maximum_result?.map(
                                (p) => {
                                  return {
                                    ...p,
                                    total_length:
                                      parent?.child_maximum_result?.length || 0,
                                  }
                                }
                              )}
                              scroll={{
                                x: "max-content",
                              }}
                              rowClassName={(row) =>
                                row?.selected ? "selected-row" : ""
                              }
                            />
                          </div>
                        </>
                      ))}
                    </>
                  ))}
                </div>
                {/* mid table section ends here */}

                {/* Lower footer section starts here */}
                <div className={"lower-footer-section no-print"}>
                  {renderNoticeBox()}
                  <PrimaryActionButtons
                    handlePrint={togglePrintPage}
                    hasExtraBtn
                    disableOutputBtn={disableCSVButton()}
                    disablePrintBtn={!isPrintBtnDisable}
                    handleCSVDownload={startCSVDownload}
                    isCSVDownloading={isCSVDownloading}
                    outputCount={outputCount}
                    handleTemporarySave={handleTemporarySave}
                  />
                </div>
                {/* Lower footer section ends here */}
              </>
            )}
          </TopRoundedWhiteCard>
        </FacilityChildListContainer>
      ) : (
        <div>
          <PrintButtonContainer>
            <OwnerButton
              typeOf={"secondary"}
              text={t("Cancel")}
              onClick={togglePrintPage}
            />
            <OwnerButton
              typeOf={"print"}
              text={t("Print")}
              onClick={onPrint}
              className={"print-button"}
            />
          </PrintButtonContainer>
          <div ref={printContentRef}>
            {isOfficeWisePrintView
              ? selectedItems?.map((se) => {
                  return (
                    <>
                      <div>
                        <OfficeContainer>
                          {se?.office_number + " " + se?.office_name}
                        </OfficeContainer>
                        {se?.selectedItems
                          ?.flat()
                          ?.map((output) =>
                            output?.child_data?.map((v, index) => (
                              <PrintListContent
                                data={v}
                                key={index}
                                cityNo={output?.provision_city_number}
                                printFilter={printFilter}
                              />
                            ))
                          )}
                      </div>
                    </>
                  )
                })
              : selectedItems
                  ?.flat()
                  ?.map((output) =>
                    output?.child_data?.map((v, index) => (
                      <PrintListContent
                        data={v}
                        key={index}
                        cityNo={output?.provision_city_number}
                        printFilter={printFilter}
                      />
                    ))
                  )}
          </div>
        </div>
      )}
    </>
  )
}
