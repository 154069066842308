import { getLanguage } from "../../../../utils/getLanguage"

// This is a main text that appears on the top of info box component
export const InfoBoxMainText = ({ billingMethod }) => {
  return (
    <>
      {getLanguage() === "ja" ? (
        <p className={"info-text"}>
          {"国保連に"}
          <span className={"red-text"}>{`【${billingMethod}】`}</span>
          {"で請求する設定なっております。"}
        </p>
      ) : (
        <p className={"info-text"}>
          {" "}
          {"It is set to be billed to the National Health Insurance Federation"}
          {"as"}
          <span className={"red-text"}>{`【${billingMethod}】.`}</span>
        </p>
      )}
    </>
  )
}
