import {
  FORBIDDEN_STATUS,
  PageCard,
  setItemToLocalstorage,
  theme,
} from "@project/shared"
import { Button, Spin, Typography, notification } from "antd"
import moment from "moment"
import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import {
  confirmUserFee,
  getNHIFBillingAlerts,
  getNHIFChildList,
  getNHIFFacilityBillingMethod,
  saveNHIFBilling,
  updateFacilityBillingMethod,
} from "../../../services/NHIFbilling"
import {
  ICSVDownloadProps,
  getNHIFCSVLink,
} from "../../../services/revenueManagement"
import { getSystemStatus } from "../../../services/systemStatus"
import { FACILITY_BILLING_METHOD } from "../../../utils"
import { OwnerButton } from "../../atoms"
import {
  ChangeBillingInfo,
  SearchOperation,
  YearPaginateBtn,
} from "../../molecules"
import { NationalHealthInsuranceBillingFormContent } from "../NationalHealthInsuranceBillingFormContent"
import { BillingAlertSection } from "./BillingAlertSection"
import { BillingConfirmChangedAttendance } from "./BillingConfirmChangedAttendance"
import { BillingLimitChangedChild } from "./BillingLimitChangedChild"
import { BillingListSection } from "./BillingListSection"

//styles
const Wrapper = styled.div`
  .confirm_info_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100px;
    justify-content: center;
    align-items: center;
    span {
      color: ${theme.red3};
    }
  }
  .btm-btn {
    display: flex;
    justify-content: center;
  }
  .confirm-billing {
    &:hover {
      background: grey;
      color: white;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    .ant-card-head-wrapper {
      .ant-card-extra {
        margin-top: 0.5rem !important;
        & :last-child {
          max-width: 100% !important;
        }
      }
    }
  }
`

const EditBillingInfoWithUpdateWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    border-radius: 50px;
    padding: 8px 30px;
    background: rgba(7, 130, 200, 0.2);
    border-color: rgba(7, 130, 200, 1);
    color: #000000;
    font-weight: 400;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

interface IWrapper {
  disabled: boolean
}

const DisableWrapper = styled.div<IWrapper>`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  position: relative;
  .disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.disabled ? "100%" : "0")};
    height: ${(props) => (props.disabled ? "100%" : "0")};
    background: rgba(255, 255, 255, 0.6);
    z-index: 99;
    pointer-events: none;
  }
`

export const BillingPageContent = ({
  searchOptions,
  setSearchOptions,
  facilities,
  updateBillingType,
  handleBillingMethodResponse,
  change_billing,
}) => {
  const { t } = useTranslation()
  const firstRender = useRef(0)
  const router = useRouter()

  const [saveData, updateSaveData] = useState({
    nhif_children_output: [],
    nhif_csv_output_setting: {
      insurance_billing: 0,
      sibling_limit_management: 0,
      upper_limit_management_multiple_child: 0,
      bill_on_Exceed: 0,
    },
    nhif_sibling_children_output: [],
  })
  // might have to conditionally show confirm billing layout
  const [billView, setBillView] = useState({
    showForm: change_billing,
    showOverlay: change_billing,
  })

  const [editConfirmedBillingInfo, setEditConfirmedBillingInfo] =
    useState(false)

  // for enabling / disabling child list in section one
  const [disableChildList, setDisableChildList] = useState(false)

  const handleUserConfirmFee = () =>
    saveUserFee({
      facility: searchOptions?.facility_id,
      year: searchOptions?.year,
      month: searchOptions?.month,
      form_of_provision: afterSchoolFilter ? 1 : 2,
    })

  const billingMethodRefetchCount = useRef(0)

  const {
    data: facilityBillingMethod,
    isLoading: isBillingInfoLoading,
    refetch: refetchBillingMethod,
  } = useQuery(
    ["facility-billing-method", searchOptions],
    () => {
      billingMethodRefetchCount.current++
      return getNHIFFacilityBillingMethod(searchOptions)
    },
    {
      keepPreviousData: false,
      retry: 1,
      cacheTime: 1,
      select: ({ data }) => data,
      onSuccess: (response) => {
        handleBillingMethodResponse(
          response,
          billingMethodRefetchCount.current > 1 ? true : false
        )
        if (!change_billing || response.not_saved) {
          setBillView({
            showForm: response?.not_saved,
            showOverlay: !response?.not_saved,
          })
        }
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    }
  )

  const afterSchoolFilter = useMemo(() => {
    let afterSchoolFlag = false
    if (facilityBillingMethod?.billing_method?.billing_method == "1") {
      // normal billing has no school holiday flag
      afterSchoolFlag = false
    } else if (facilityBillingMethod?.billing_method?.billing_method == "2") {
      // reduced billing method
      if (facilityBillingMethod?.billing_steps?.current_type <= 1) {
        // school holidays type
        afterSchoolFlag = false
      } else if (facilityBillingMethod?.billing_steps?.current_type == 2) {
        // after school type
        afterSchoolFlag = true
      }
    } else if (facilityBillingMethod?.billing_method?.billing_method == "3") {
      // maimum user burden billing method
      if (facilityBillingMethod?.billing_steps?.current_type < 1) {
        // != 1 not set as school holiday i.e. after school
        afterSchoolFlag = true
      } else {
        // == 1 set as school holiday
        afterSchoolFlag = false
      }
    }
    return afterSchoolFlag
  }, [facilityBillingMethod])

  const { data: alertData, isLoading: isAlertLoading } = useQuery(
    ["alert", searchOptions],
    () => getNHIFBillingAlerts(searchOptions),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled:
        facilityBillingMethod?.billing_method?.id &&
        searchOptions?.facility_id &&
        searchOptions?.year &&
        searchOptions?.month
          ? true
          : false,
      select: ({ data }) => data,
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    }
  )

  const {
    data: nhifData,
    isLoading: isDataLoading,
    refetch: refetchNHIFOutput,
  } = useQuery(
    ["child", searchOptions, afterSchoolFilter],
    () =>
      getNHIFChildList({
        ...searchOptions,
        query: afterSchoolFilter ? "after_school_flag=1" : "",
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled:
        facilityBillingMethod?.billing_method?.id &&
        searchOptions?.facility_id &&
        searchOptions?.year &&
        searchOptions?.month
          ? true
          : false,
      select: ({ data }) => data,
      onSuccess: (data) => {
        const nhif_children_output = data?.child_list?.map((child) => {
          return { ...child, is_selected: child?.id ? true : false }
        })
        const nhif_csv_output_setting = {
          insurance_billing: data?.output_setting?.insurance_billing || 0,
          sibling_limit_management:
            data?.output_setting?.sibling_limit_management || 0,
          upper_limit_management_multiple_child:
            data?.output_setting?.upper_limit_management_multiple_child || 0,
          bill_on_Exceed: data?.output_setting?.bill_on_Exceed || 0,
        }

        const nhif_sibling_children_output = data?.parent_list?.map((p) => {
          return {
            ...p,
            is_selected: p?.child?.find((ch) => ch?.sibling_output_id)
              ? true
              : false,
          }
        })
        updateSaveData({
          nhif_children_output,
          nhif_csv_output_setting,
          nhif_sibling_children_output,
        })
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    }
  )

  const { data: systemStatus } = useQuery(
    ["facility-system-status", searchOptions],
    () =>
      getSystemStatus(
        String(searchOptions?.facility_id),
        searchOptions?.year,
        searchOptions?.month
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const childCountText = useMemo(() => {
    if (systemStatus?.data?.facility_system_manage_items?.length == 1) {
      const childCount = saveData?.nhif_children_output?.filter(
        (c) => c?.is_selected
      )?.length
      return t("{{serviceType}} {{value1}} people Total {{value1}} people", {
        serviceType:
          systemStatus?.data?.facility_system_manage_items?.service_type == 1
            ? t("After school")
            : t("Child development"),
        value1: childCount,
      })
    } else if (systemStatus?.data?.facility_system_manage_items?.length == 2) {
      const afterSchoolUsers =
        saveData?.nhif_children_output?.filter(
          (c) => c?.is_selected && c?.service_used == 1
        )?.length || 0
      const cdsUsers =
        saveData?.nhif_children_output?.filter(
          (c) => c?.is_selected && c?.service_used == 2
        )?.length || 0
      return t(
        "Output number of people Child development support {{value1}} people After school day service {{value2}} people Total {{value3}} people",
        {
          value1: cdsUsers,
          value2: afterSchoolUsers,
          value3: cdsUsers + afterSchoolUsers,
        }
      )
    }
    return ""
  }, [systemStatus, saveData?.nhif_children_output])

  useEffect(() => {
    if (firstRender.current > 0) {
      const queries = []
      Object.keys(searchOptions).map((key) => {
        if (searchOptions[key]) queries.push(`${key}=${searchOptions[key]}`)
      })
      router.push(`?${queries.join("&")}`, undefined, { shallow: true })
    } else {
      firstRender.current = 1
    }
  }, [searchOptions])

  const hasBillingBeenConfirmed = useMemo(() => {
    // for normal billing method completion of 1st step is confirmation
    if (facilityBillingMethod?.billing_method?.billing_method == "1") {
      return facilityBillingMethod?.billing_steps?.current_step >= 2
    } else if (facilityBillingMethod?.billing_method?.billing_method == "2") {
      // for reduced billing method & school holiday completion of 1st step is confirmation
      if (facilityBillingMethod?.billing_steps?.current_type <= 1) {
        return facilityBillingMethod?.billing_steps?.current_step >= 2
      } else if (facilityBillingMethod?.billing_steps?.current_type == 2) {
        // for reduced billing method & after school completion of 2nd step is confirmation
        return facilityBillingMethod?.billing_steps?.current_step2 >= 3
      }
    } else if (facilityBillingMethod?.billing_method?.billing_method == "3") {
      // for maximum user burden billing completion of 2nd step is confirmation for after school & completion of 5th step for school holiday
      return (
        (facilityBillingMethod?.billing_steps?.current_step >= 3 &&
          (facilityBillingMethod?.billing_steps?.current_step < 5 ||
            facilityBillingMethod?.billing_steps?.current_step >= 7) &&
          facilityBillingMethod?.billing_steps?.current_type < 1) ||
        (facilityBillingMethod?.billing_steps?.current_step > 5 &&
          facilityBillingMethod?.billing_steps?.current_type == 1)
      )
    }
    return false
  }, [facilityBillingMethod])
  useEffect(() => {
    if (
      hasBillingBeenConfirmed ||
      (alertData &&
        hasBillingBeenConfirmed &&
        (alertData?.info_updated_after_billing_confirmation?.child_attendance
          ?.length ||
          alertData?.info_updated_after_billing_confirmation
            ?.child_receiving_certificate?.length ||
          alertData?.info_updated_after_billing_confirmation?.facility_schedule
            ?.length))
    ) {
      setDisableChildList(true)
    } else setDisableChildList(false)
  }, [alertData, facilityBillingMethod])

  const { mutate, isLoading: isUpdating } = useMutation(saveNHIFBilling, {
    onSuccess: () => {
      notification.success({
        message: `${t("National Health Insurance Billing Form/CSV Output")} ${t(
          "Updated Successfully"
        )}`,
      })
      refetchBillingMethod()
      refetchNHIFOutput()
      setEditConfirmedBillingInfo(false)
    },
    onError: (error?: any) => {
      const msg =
        error?.status == FORBIDDEN_STATUS
          ? "You don’t have permission to execute"
          : error?.data?.error?.message || error?.data?.error
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const { mutate: updateBillMethod, isLoading: isUpdatingBillMethod } =
    useMutation(updateFacilityBillingMethod, {
      onSuccess: () => {
        notification.success({
          message: `${t("Billing Method Updated Successfully")}`,
        })
        router.push(
          `/national-health-insurance-billing?facility_id=${searchOptions?.facility_id}&year=${searchOptions?.year}&month=${searchOptions?.month}`,
          undefined,
          { shallow: true }
        )
        refetchBillingMethod()
        setBillView({
          ...billView,
          showForm: false,
        })
      },
      onError: (error?: any) => {
        let msg = error?.data?.error?.message || error?.data?.error
        if (msg === "User does not have permission to access the resource") {
          msg = "User doesn't have permission to edit this page"
        }
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    })

  const { mutate: saveUserFee, isLoading: isCalculating } = useMutation(
    confirmUserFee,
    {
      onSuccess: () => {
        handleNHIFCSVDownload(0)
      },
      onError: (error?: any) => {
        const msg =
          error?.status == FORBIDDEN_STATUS
            ? "You don’t have permission to execute"
            : error?.data?.error?.message || error?.data?.error
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { mutate: getNHIFCSVDownloadLink, isLoading: isDownloadingCSV } =
    useMutation(getNHIFCSVLink, {
      onSuccess: (response) => {
        refetchBillingMethod()
        const link = response?.data
        if (link) {
          const newLinkElement = document.createElement("a")
          newLinkElement.setAttribute("href", link)
          newLinkElement.setAttribute("target", "_blank")
          document.body.appendChild(newLinkElement)
          newLinkElement.click()
          newLinkElement.remove()
        }
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message || error?.data?.error
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    })

  const handleNHIFCSVDownload = (csvFor: number) => {
    const payloads: ICSVDownloadProps = {
      year: +searchOptions?.year,
      month: +searchOptions?.month,
      facility_id: +searchOptions?.facility_id,
      service_type: facilities?.find(
        (fac) => fac?.id == +searchOptions?.facility_id
      )?.pref_id,
      receipt_option: +facilityBillingMethod?.billing_method?.billing_method,
      csv_for: csvFor,
    }

    const selectedChildIds = saveData?.nhif_children_output
      ?.map((c) => c?.is_selected && c?.child_id)
      ?.filter((c) => typeof c == "number")

    payloads.child_ids = selectedChildIds?.join(",")

    if (csvFor == 1 || csvFor == 0) {
      payloads.days_over = saveData?.nhif_csv_output_setting?.bill_on_Exceed
      payloads.sibling_upper_limit =
        saveData?.nhif_csv_output_setting?.sibling_limit_management

      // csv settings for siblings
      const parentIds = []
      const siblingIds = []
      const siblingOrders = []

      if (saveData?.nhif_csv_output_setting?.sibling_limit_management) {
        payloads.sibling_upper_limit_addition =
          saveData?.nhif_csv_output_setting?.upper_limit_management_multiple_child
        saveData.nhif_sibling_children_output?.forEach((parent) => {
          let siblingOrder = 0 // if not set in table
          if (parent?.is_selected) {
            parent?.child?.forEach((child) => {
              if (
                !saveData?.nhif_csv_output_setting
                  ?.upper_limit_management_multiple_child ||
                child?.multiple_child_selection
              ) {
                siblingOrder++
                parentIds.push(parent?.parent_id)
                siblingIds.push(child?.child_id)
                siblingOrders.push(child?.sibling_order || siblingOrder)
              }
            })
          }
        })
      } else {
        payloads.sibling_upper_limit_addition = 0
      }

      payloads.sibling_parent_list = parentIds?.join(",")
      payloads.sibling_child_list = siblingIds?.join(",")
      payloads.sibling_order_no = siblingOrders?.join(",")
    }

    if (payloads.receipt_option == 2) {
      // if reduced billing method pass school_clouse payload
      if (afterSchoolFilter) {
        // after school
        payloads.school_closure = 0
      } else {
        // school holiday
        payloads.school_closure = 1
      }
    }

    if (payloads.receipt_option == 3) {
      // if maximum userburden
      const upperLimits = []
      payloads.child_ids?.split(",")?.map((childId) => {
        const selectedChild = saveData.nhif_children_output?.find(
          (c) => c?.child_id == +childId
        )
        if (selectedChild) {
          upperLimits.push(selectedChild?.burden_upper_limit_monthly)
        } else {
          upperLimits.push(0)
        }
      })
      payloads.change_burden_max_amount_lists = upperLimits.join(",")
    }
    getNHIFCSVDownloadLink(payloads)
  }

  const handleSaveValidation = () => {
    // check if siblings order is set for checked parents
    const siblingsWithoutOrder = []
    let firstSiblingOrderAlertElement
    saveData?.nhif_sibling_children_output?.forEach((op) => {
      if (op?.is_selected) {
        op?.child?.forEach((c) => {
          if (!c?.sibling_order) {
            siblingsWithoutOrder.push(c?.child_id)
          }
        })
      }
    })
    siblingsWithoutOrder?.forEach((siblingChildId) => {
      const siblingElement = document.getElementById(
        `sibling_order_${siblingChildId}`
      )
      if (siblingElement) {
        siblingElement.classList.add("required_order")
        if (!firstSiblingOrderAlertElement)
          firstSiblingOrderAlertElement = siblingElement
      }
    })
    if (siblingsWithoutOrder?.length > 0) {
      if (firstSiblingOrderAlertElement)
        firstSiblingOrderAlertElement?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        })
      return true
    }
    return false
  }

  const handleSave = () => {
    if (handleSaveValidation()) return
    const afterSchoolFlag = afterSchoolFilter

    const sibling_child = []
    saveData.nhif_sibling_children_output?.map((parent) => {
      parent?.child?.map((c) => {
        if (
          parent?.is_selected ||
          (!parent?.is_selected && c?.sibling_output_id)
        )
          sibling_child.push({
            id: c?.sibling_output_id || null,
            facility_id: c?.facility_id,
            child_id: c?.child_id,
            child_receiving_certificate_id:
              c?.child_receiving_certificate_id || null,
            year: +searchOptions?.year,
            month: +searchOptions?.month,
            sibling_order: c?.sibling_order || null,
            multiple_child_selection: c?.multiple_child_selection || null,
            is_deleted: !parent?.is_selected,
          })
      })
    })

    const toUpdate = {
      nhif_children_output: saveData.nhif_children_output
        ?.map((child) => {
          const childForpdate = {
            id: child?.id || null,
            facility_id: child?.facility_id,
            child_id: child?.child_id,
            child_receiving_certificate_id:
              child?.child_receiving_certificate_id || null,
            year: +searchOptions?.year,
            month: +searchOptions?.month,
            individual_support_plan_non_creation_subtraction:
              child?.individual_support_plan_non_creation_subtraction,
            upper_limit_management_company_type:
              child?.burden_upper_limit_management_company_type,
            upper_limit_management_company_id:
              child?.burden_upper_limit_management_company_type == 1
                ? child?.burden_upper_limit_management_company
                : child?.burden_upper_limit_management_company_type == 2
                ? child?.burden_upper_limit_management_joint_company
                : null,

            acquisition_setting: child?.maximum_manage_addition_flag,

            after_school_flag: afterSchoolFlag,
            is_deleted: !child?.is_selected,
          }

          // NOTE: 自分の事業所が上限管理事業者でなければ、強制的に加算は「無し」
          if (
            childForpdate.facility_id !==
            childForpdate.upper_limit_management_company_id
          ) {
            childForpdate.acquisition_setting = 0
          }

          // NOTE: 無償化対象児童の場合は上限管理無し
          if (child?.is_free_child) {
            childForpdate.acquisition_setting = 0
            childForpdate.upper_limit_management_company_id = 0
            childForpdate.upper_limit_management_company_type = 0
          }

          return childForpdate
        })
        ?.filter((d) => (d?.id && d?.is_deleted) || !d?.is_deleted),
      nhif_csv_output_setting: {
        ...saveData.nhif_csv_output_setting,
        year: +searchOptions.year,
        month: +searchOptions.month,
        facility_id: +searchOptions.facility_id,
      },
      nhif_sibling_children_output: sibling_child,
    }

    mutate(toUpdate)
  }

  const handleBillMethodUpdate = (billMethod) => {
    if (isUpdatingBillMethod) return
    if (!+searchOptions?.facility_id) {
      notification.error({
        message: t("Select facility first"),
      })
      return
    }
    updateBillMethod({
      facility_id: +searchOptions?.facility_id,
      year: +searchOptions?.year,
      month: +searchOptions?.month,
      billing_method: billMethod?.toString(),
    })
  }

  const unhandledAlert = () => {
    if (!alertData) return true
    if (alertData?.unregistered_child?.length) {
      // check if any of unregistered child is selected
      // disable only if selected
      let hasChildSelectedWithIncompleteAttendance = false
      alertData?.unregistered_child?.some((unregisteredChild) => {
        const isSelected = saveData.nhif_children_output?.find(
          (child) =>
            child?.child_id == unregisteredChild?.child_id && child?.is_selected
        )
        if (isSelected) {
          hasChildSelectedWithIncompleteAttendance = true
        }
        return hasChildSelectedWithIncompleteAttendance
      })
      if (hasChildSelectedWithIncompleteAttendance) return true
    }
    if (alertData?.facility_status_setting_no_data) return true

    // disable if a child whose usage exceeds is selected and csv setting for bill exceed is do not
    if (!saveData?.nhif_csv_output_setting?.bill_on_Exceed) {
      let exceedUsageChildIsSelected = false
      alertData?.exceed_days_used?.every((exceedChild) => {
        const isSelected = saveData?.nhif_children_output?.find(
          (c) => c?.child_id == exceedChild?.child_id && c?.is_selected
        )
        if (isSelected) {
          exceedUsageChildIsSelected = true
          return false
        }
        return true
      })
      if (exceedUsageChildIsSelected) {
        return true
      }
    }

    return false
  }

  const shouldListUserChargeBeDisabled = () => {
    if (!nhifData?.child_list || !alertData) return true
    let disabled = true

    nhifData?.child_list?.map((child) => {
      if (!child?.id) return
      if (child?.has_joint_facility_setup_for_upper_management) {
        const isChildFree = alertData?.children_on_free_period?.find(
          (fChild) => fChild?.child_id == child?.child_id
        )
          ? true
          : false
        if (!isChildFree) disabled = false
      }
    })

    return disabled
  }

  const getUsageStatusOfOtherFacilities = () => {
    if (!nhifData?.child_list || !alertData) return null
    const childs = []
    let disableRegistrationOfUsageOfJointFacilities = true
    let registeredChildCount = 0
    nhifData?.child_list?.map((child) => {
      {
        if (!child?.id) return
        const isChildFree = child?.is_free_child
        if (isChildFree) return

        if (!isChildFree) disableRegistrationOfUsageOfJointFacilities = false

        if (child?.requires_upper_limit_management_registration) {
          if (!isChildFree) {
            const indexOfSameJointFacility = childs?.indexOf(
              (c) => c?.joint_facility_id == child?.joint_facility_id
            )
            if (indexOfSameJointFacility == -1) {
              childs.push({
                joint_facility_id: child?.joint_facility_id,
                joint_facility_name: child?.joint_facility_name,
                children: [child],
              })
            } else {
              childs[indexOfSameJointFacility].children.push(child)
            }
          }
        } else if (child?.is_usage_status_of_joint_facilities_enabled) {
          registeredChildCount++
        }
      }
    })

    if (
      (Number(searchOptions?.year) >= 2024 &&
        Number(searchOptions?.month) >= 4) ||
      Number(searchOptions?.year) >= 2025
    ) {
      const upperLimitCount = nhifData?.upper_limits?.length ?? 0
      const siblingUpperLimitCount = nhifData?.sibling_upper_limits?.length ?? 0
      registeredChildCount = upperLimitCount + siblingUpperLimitCount
    }
    return {
      children: childs,
      disableRegistrationOfUsageOfJointFacilities,
      registeredChildCount,
      disablePrintMaximumAmountManagementResult: registeredChildCount == 0,
    }
  }

  const getUserBurdenDeterminant = () => {
    if (!nhifData?.child_list || !alertData) return null

    let disableRegistrationButton = true
    let childRegCount = 0
    nhifData?.child_list?.map((child) => {
      if (!child?.id) return
      if (child?.burden_upper_limit_management_company_type == 2) {
        const isChildFree = alertData?.children_on_free_period?.find(
          (fChild) => fChild?.child_id == child?.child_id
        )
          ? true
          : false
        if (!isChildFree) {
          disableRegistrationButton = false
          childRegCount++
        }
      }
    })

    return {
      disableRegistrationButton,
      childRegCount,
      disableMunicipalSubcomponentBilling: hasBillingBeenConfirmed
        ? false
        : true,
    }
  }

  const handleProceedWithoutChangingBillingMethod = () => {
    if (
      facilityBillingMethod?.billing_method?.billing_method == "3" &&
      facilityBillingMethod?.billing_steps?.current_step <= 1
    ) {
      router.push(
        `/national-health-insurance-billing/usage-record-rewrite?year=${searchOptions?.year}&month=${searchOptions?.month}&facilityId=${searchOptions?.facility_id}`
      )
    } else
      setBillView({
        showForm: false,
        showOverlay: false,
      })
  }

  const newFirstSection = useMemo(() => {
    const obj = {
      // visible or not
      visible: false,
      // attendance or changed user limit
      attendance: true,
    }
    // visible if billing method is maximum user burden and 1st step is done
    if (facilityBillingMethod?.billing_method?.billing_method == "3")
      if (facilityBillingMethod?.billing_steps?.current_step >= 2) {
        obj.visible = true
      }
    if (facilityBillingMethod?.billing_steps?.current_type == 1) {
      obj.attendance = false
    } else if (facilityBillingMethod?.billing_method?.billing_method == "2") {
      // visible if billing method is reduced user fee (after school)
      if (facilityBillingMethod?.billing_steps?.current_type == 2) {
        obj.visible = true
      }
    }
    return obj
  }, [facilityBillingMethod])

  const isUserFeeConfirmed = useMemo(() => {
    // for normal billing method if 2nd step is completed
    if (facilityBillingMethod?.billing_method?.billing_method == "1") {
      return facilityBillingMethod?.billing_steps?.current_step >= 3
    } else if (facilityBillingMethod?.billing_method?.billing_method == "2") {
      // for reduced billing method
      if (facilityBillingMethod?.billing_steps?.current_type <= 1) {
        // for holiday type if 2nd step is completed
        return facilityBillingMethod?.billing_steps?.current_step >= 3
      } else if (facilityBillingMethod?.billing_steps?.current_type == 2) {
        // for after school if 3rd step is completed
        return facilityBillingMethod?.billing_steps?.current_step2 >= 4
      }
    } else if (facilityBillingMethod?.billing_method?.billing_method == "3") {
      // for maximum user burden billing method if 3rd step is completed for after school
      if (
        !facilityBillingMethod?.billing_steps?.current_type ||
        facilityBillingMethod?.billing_steps?.current_type != 1
      )
        return facilityBillingMethod?.billing_steps?.current_step >= 4

      // for maximum user burden billing method if 6th step is completed for school holiday
      return facilityBillingMethod?.billing_steps?.current_step >= 7
    }
    return false
  }, [facilityBillingMethod])

  const scrollToOutput = () => {
    document.getElementById("outputInformationNHIF")?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  const scrollToConfirmBilling = () => {
    document.getElementById("confirmBilling")?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  return (
    <Wrapper>
      <div
        style={{
          padding: "36px 0",
        }}
      >
        <SearchOperation
          facilityOptions={facilities}
          searchOptions={searchOptions}
          onDisplayChange={(val) => {
            setSearchOptions(val)
            setItemToLocalstorage("NHIF_list_key", {
              facility_ids: val?.facility_id,
              year: val?.year,
              month: val?.month,
            })
          }}
          handleReset={() => {
            const currentYear = +moment().format("YYYY")
            const currentMonth = +moment().format("MM")
            setItemToLocalstorage("NHIF_list_key", {
              facility_ids: facilities?.[0]?.id,
              year: currentYear,
              month: currentMonth,
            })
            setSearchOptions({
              facility_id: facilities?.[0]?.id,
              year: currentYear,
              month: currentMonth,
            })
          }}
        />
      </div>
      {isDataLoading || isAlertLoading || isBillingInfoLoading ? (
        <div
          style={{ margin: "10px", display: "flex", justifyContent: "center" }}
        >
          <Spin size={"large"} />
        </div>
      ) : billView?.showForm ? (
        <NationalHealthInsuranceBillingFormContent
          switchToBillingChange={() => {
            setBillView({
              showForm: true,
              showOverlay: false,
            })
          }}
          handleProceedWithoutBillingChange={
            handleProceedWithoutChangingBillingMethod
          }
          facilityBillingMethod={facilityBillingMethod?.billing_method}
          updateYearMonth={({ year, month }) =>
            setSearchOptions({ ...searchOptions, year, month })
          }
          displayYear={+searchOptions?.year}
          displayMonth={+searchOptions?.month}
          showOverlay={billView?.showOverlay}
          saveBillMethod={handleBillMethodUpdate}
          isLoading={isUpdatingBillMethod}
        />
      ) : (
        <PageCard
          title={t(
            "{{date}} National Health Insurance Federation billing form/CSV output",
            {
              date: moment()
                .year(+searchOptions.year)
                .month(+searchOptions.month - 1)
                .format("YYYY年MM月"),
            }
          )}
          toRight
          extra={
            <YearPaginateBtn
              prev={t("Prev month")}
              current={t("This month")}
              next={t("Next month")}
              handleClick={(val) => {
                let year = +searchOptions.year
                let month = +searchOptions.month
                switch (val) {
                  case "next":
                    month++
                    if (month > 12) {
                      month = 1
                      year++
                    }
                    break
                  case "prev":
                    month--
                    if (month < 1) {
                      month = 12
                      year--
                    }
                    break
                  default:
                    month = +moment().format("MM")
                    year = +moment().format("YYYY")
                }
                setSearchOptions({ ...searchOptions, year, month })
              }}
            />
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <ChangeBillingInfo
              currentBillingMethod={t(
                FACILITY_BILLING_METHOD?.[
                  facilityBillingMethod?.billing_method?.billing_method
                ]
              )}
              showChangeBilling={billView.showOverlay}
              onChangeBillingMethod={() => {
                setBillView({
                  showForm: true,
                  showOverlay: false,
                })
              }}
              recentlyUpdatedInfo={
                alertData?.info_updated_after_billing_confirmation
              }
              editConfirmed={editConfirmedBillingInfo}
              onEditConfirmedBillingInformation={() =>
                setEditConfirmedBillingInfo(true)
              }
              onEditFinalizedBilling={() => {
                if (disableChildList) setDisableChildList(false)
                else scrollToConfirmBilling()
              }}
              isUpdating={isUpdating}
              isBillingConfirmed={hasBillingBeenConfirmed}
              handleOutput={scrollToOutput}
              userFeeConfirmed={isUserFeeConfirmed}
            />
            {newFirstSection?.visible &&
              (newFirstSection?.attendance ? (
                <BillingConfirmChangedAttendance
                  facility_id={searchOptions?.facility_id}
                  year={searchOptions?.year}
                  month={searchOptions?.month}
                  disabled={disableChildList}
                />
              ) : (
                <BillingLimitChangedChild
                  facility_id={searchOptions?.facility_id}
                  year={searchOptions?.year}
                  month={searchOptions?.month}
                  disabled={disableChildList}
                />
              ))}
            <BillingAlertSection
              alerts={alertData}
              data={saveData}
              updateData={updateSaveData}
              disabled={disableChildList}
              incrementStep={newFirstSection?.visible}
              facilityId={searchOptions?.facility_id}
              childCountText={childCountText}
            />
            {(!hasBillingBeenConfirmed || editConfirmedBillingInfo) &&
            !disableChildList ? (
              <div className={"confirm_info_text"} id={"confirmBilling"}>
                <Typography.Text>
                  {t(
                    "Confirm the billing information in order to create a list of user fees and maximum user fee management results."
                  )}
                </Typography.Text>
                <OwnerButton
                  text={t("Confirm billing information")}
                  shape={"round"}
                  typeOf={"print"}
                  onClick={handleSave}
                  disabled={
                    isUpdating ||
                    isAlertLoading ||
                    isDataLoading ||
                    saveData?.nhif_children_output?.length == 0 ||
                    !saveData?.nhif_children_output?.find(
                      (child) => child?.is_selected
                    ) ||
                    unhandledAlert()
                  }
                  isLoading={isUpdating}
                  className={"confirm-billing"}
                />
                {isUpdating && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(255,255,255,0.6)",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          zIndex: 999,
                          background: theme.yellow4,
                          borderRadius: "1rem",
                        }}
                      >
                        <Spin size={"large"} style={{ padding: "1rem" }} />
                        <div style={{ padding: "1rem" }}>
                          <p>
                            {t(
                              "Depending on the amount of data, you may need to wait for about 2 to 3 minutes"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <EditBillingInfoWithUpdateWrapper>
                <Button
                  icon={
                    <img
                      src={"/assets/icons/icon_edit.svg"}
                      height={18}
                      width={18}
                      alt={""}
                    />
                  }
                  onClick={() => {
                    if (!editConfirmedBillingInfo) {
                      setEditConfirmedBillingInfo(true)
                      setDisableChildList(false)
                    } else handleSave()
                  }}
                  loading={isUpdating}
                  disabled={isUpdating}
                >
                  {!editConfirmedBillingInfo
                    ? t("Edit confirmed billing information")
                    : t("Edit finalized billing information")}
                </Button>
                <span>{`${t("Last update")}: ${moment(
                  nhifData?.output_setting?.updated_datetime ||
                    nhifData?.output_setting?.created_datetime
                )?.format("YYYY年MM月DD日 HH:mm:SS")}`}</span>
              </EditBillingInfoWithUpdateWrapper>
            )}
            <DisableWrapper
              disabled={
                !disableChildList || !hasBillingBeenConfirmed ? true : false
              }
            >
              <div className={"disable-overlay"} />

              <BillingListSection
                disableListUserCharges={shouldListUserChargeBeDisabled()}
                usageStatusOfOtherFacilities={getUsageStatusOfOtherFacilities()}
                userBurdenDeterminant={getUserBurdenDeterminant()}
                facility_id={searchOptions?.facility_id}
                year={+searchOptions?.year}
                month={+searchOptions?.month}
                confirmedUserFee={isUserFeeConfirmed}
                setConfirmedUserFee={handleUserConfirmFee}
                incrementStep={newFirstSection?.visible}
                handleCSVDownload={handleNHIFCSVDownload}
                isCSVDownloading={isDownloadingCSV}
                disableOverlayMaximumBurden={
                  facilityBillingMethod?.billing_method?.billing_method ==
                    "3" &&
                  facilityBillingMethod?.billing_steps?.current_step == 4
                }
                isCalculating={isCalculating}
                form_of_provision={afterSchoolFilter ? 1 : 0}
                isCSVDownloadEnabled={isUserFeeConfirmed}
                showBillingDifferenceLink={
                  facilityBillingMethod?.billing_method?.billing_method == "3"
                }
              />
              {facilityBillingMethod?.billing_method?.billing_method == "2" ? (
                <div className={"btm-btn"}>
                  <OwnerButton
                    text={
                      facilityBillingMethod?.billing_steps?.current_type <= 1
                        ? t("「After school day」 unit calculation from here")
                        : t("「School holidays」 unit calculation from here")
                    }
                    onClick={() => {
                      updateBillingType(
                        facilityBillingMethod?.billing_steps?.current_type <= 1
                          ? 2
                          : 1
                      )
                    }}
                    underlineText
                  />
                </div>
              ) : (
                <></>
              )}
            </DisableWrapper>
          </div>
        </PageCard>
      )}
    </Wrapper>
  )
}
