import { t } from "i18next"

type Props = {
  burdenMaxMonth: number
  localBurdenMaxMonth: number
}

export function HiroshimaUpperMonthLimit({
  burdenMaxMonth,
  localBurdenMaxMonth,
}: Props) {
  return (
    <table className={"nbl_info_top_content__table"}>
      <tbody>
        <tr>
          <td className={"large_cell"}>{t("user burden amount")}</td>
          {String(burdenMaxMonth)
            .padStart(6, ".")
            .split("")
            .map((v, indx) => (
              <td key={indx}>{v !== "." ? v : ""}</td>
            ))}

          <td>{t("Hiroshima City subsidy upper limit per month")}</td>
          {String(localBurdenMaxMonth)
            .padStart(6, ".")
            .split("")
            .map((v, indx) => (
              <td key={indx}>{v !== "." ? v : ""}</td>
            ))}
        </tr>
      </tbody>
    </table>
  )
}
