import { Table } from "@project/shared"
import { t } from "i18next"
import { DefaultProps } from "./types"
import { Content, makeEmptyRowData } from "./utils"
import { KobeCityTableComponent } from "./../../common/body/BodyFormat.style"
import { ReceiptSiblingUpperLimitManagementItem } from "../../../../types"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"

/**
 * ===================================
 * Upper Table
 * ===================================
 */

enum ColumnKey {
  ITEN_NUMBER,
  BENEFICIARY_NUMBER,
  CHILD_NAME,
  FACILITY_NUMBER,
  FACILITY_NAME,
  TOTAL_AMOUNT,
  BURDEN_AMOUNT,
  MANAGEMENT_RESULT_BURDEN_AMOUNT,
  LOCAL_EXEMPTION,
  LOCAL_BURDEN_AMOUNT,
}

const COLUMNS = [
  {
    title: t("Item number"),
  },
  {
    title: t("Beneficiary no."),
  },
  {
    title: "対象児氏名",
  },
  {
    title: "事業所番号",
  },
  {
    title: t("Business official name"),
  },
  {
    title: "費用総額",
  },

  {
    title: t("User fee"),
  },
  {
    title: "上限管理後利用者負担額",
  },
  {
    title: t("Kobe City's own tax exemption"),
  },
  {
    title: (
      <>
        {t("User burden")}
        <br />
        {t("(Kobe City/benefits portion)")}
      </>
    ),
  },
]

const UPPER_LEFT_TABLE_HEAD_COLUMN = {
  title: "",
  className: "kobe-col",
  width: 20,
  render: () =>
    t(
      "User burden summation / adjustment column ( before Kobe City's own reduction / exemption )"
    ),

  onCell: (_, index) => ({ rowSpan: index === 0 ? 10 : 0 }),
}

const UPPER_TABLE_TITLE_COLUMN = {
  title: "",
  dataIndex: "title",
  align: "center",
  className: "kobe-col",
}

function getUpperColumn(
  row: ReceiptSiblingUpperLimitManagementItem,
  index: number
) {
  if (index === ColumnKey.ITEN_NUMBER) {
    return row.line_no
  }
  if (index === ColumnKey.BENEFICIARY_NUMBER) {
    return row.receiving_certificate_number
  }
  if (index === ColumnKey.CHILD_NAME) {
    return row.child_name
  }
  if (index === ColumnKey.FACILITY_NUMBER) {
    return row.facility_number
  }
  if (index === ColumnKey.FACILITY_NAME) {
    return row.facility_name
  }
  if (index === ColumnKey.TOTAL_AMOUNT) {
    return <Content data={String(row.total_amount).padStart(6, ".")} />
  }

  if (index === ColumnKey.BURDEN_AMOUNT) {
    return <Content data={String(row.burden_amount).padStart(6, ".")} />
  }
  if (index == ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
    return (
      <Content
        data={String(row.management_result_burden_amount).padStart(6, ".")}
      />
    )
  }
  if (index === ColumnKey.LOCAL_EXEMPTION) {
    return <Content data={String(row.local_exemption).padStart(6, ".")} />
  }
  if (index === ColumnKey.LOCAL_BURDEN_AMOUNT) {
    return <Content data={String(row.local_burden_amount).padStart(6, ".")} />
  }

  return ""
}

function generateColumn(facilities: ReceiptSiblingUpperLimitManagementItem[]) {
  return facilities.map((value) => ({
    align: "center",
    width: "15%",
    className: "kobe-col",
    render: (_, __, index) => getUpperColumn(value, index),
  }))
}

function generateSummaryColumn(
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  totalLocalExemption,
  localBurdenAmount
) {
  return {
    title: t("Total"),
    align: "center",
    width: "15%",
    className: "kobe-col",
    onCell: (_, index) => {
      if (index === 0) {
        return { rowSpan: 5 }
      }
      if (index < 5) {
        return { rowSpan: 0 }
      }
      return {}
    },
    render: (_, __, index) => {
      if (index === ColumnKey.ITEN_NUMBER) {
        return t("Total")
      }
      if (index === ColumnKey.TOTAL_AMOUNT) {
        return <Content data={String(totalAmount).padStart(6, ".")} />
      }

      if (index === ColumnKey.BURDEN_AMOUNT) {
        return <Content data={String(totalBurdenAmount).padStart(6, ".")} />
      }
      if (index === ColumnKey.MANAGEMENT_RESULT_BURDEN_AMOUNT) {
        return (
          <Content
            data={String(managementResultBurdenAmount).padStart(6, ".")}
          />
        )
      }
      if (index === ColumnKey.LOCAL_EXEMPTION) {
        return <Content data={String(totalLocalExemption).padStart(6, ".")} />
      }
      if (index === ColumnKey.LOCAL_BURDEN_AMOUNT) {
        return <Content data={String(localBurdenAmount).padStart(6, ".")} />
      }
    },
  }
}

type Props = DefaultProps & {
  totalInsuranceAmount: number
  managementResultInsuranceAmount: number
  totalLocalExemption: number
  localBurdenAmount: number
}

export function KobeFormat({
  facilities,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  totalLocalExemption,
  localBurdenAmount,
}: Props) {
  const UPPER_FACILITY_COUNT = 5
  const LOWER_FACILITY_COUNT = 4

  let upperFacilities = facilities.slice(0, UPPER_FACILITY_COUNT)
  const upperEmptyLength = UPPER_FACILITY_COUNT - upperFacilities.length
  if (upperEmptyLength > 0) {
    upperFacilities = [
      ...upperFacilities,
      ...Array(upperEmptyLength).fill(makeEmptyRowData()),
    ]
  }

  let lowerFacilities = facilities.slice(
    UPPER_FACILITY_COUNT,
    UPPER_FACILITY_COUNT + LOWER_FACILITY_COUNT
  )
  const lowerEmptyLength = LOWER_FACILITY_COUNT - lowerFacilities.length
  if (lowerEmptyLength > 0) {
    lowerFacilities = [
      ...lowerFacilities,
      ...Array(lowerEmptyLength).fill(makeEmptyRowData()),
    ]
  }

  return (
    <KobeCityTableComponent>
      {/* 上段 */}
      <NBLManagementCompanyTop style={{ marginBottom: 16 }}>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            className={"KobeCityTable"}
            showHeader={false}
            columns={[
              UPPER_LEFT_TABLE_HEAD_COLUMN,
              UPPER_TABLE_TITLE_COLUMN,
              ...generateColumn(upperFacilities),
            ]}
            pagination={false}
            dataSource={COLUMNS}
          />
        </div>
      </NBLManagementCompanyTop>

      {/* 下段 */}
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            className={"KobeCityTable"}
            showHeader={false}
            columns={[
              UPPER_LEFT_TABLE_HEAD_COLUMN,
              UPPER_TABLE_TITLE_COLUMN,
              ...generateColumn(lowerFacilities),
              generateSummaryColumn(
                totalAmount,
                totalBurdenAmount,
                managementResultBurdenAmount,
                totalLocalExemption,
                localBurdenAmount
              ),
            ]}
            pagination={false}
            dataSource={COLUMNS}
          />
        </div>
      </NBLManagementCompanyTop>
    </KobeCityTableComponent>
  )
}
