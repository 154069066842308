import { theme } from "@project/shared"
import Calendar from "react-calendar"
import styled from "styled-components"

//shared calender styles
type CalendarStylesProps = {
  weekdayBg?: string
}
export const MonthlyCalendarWrapper = styled(Calendar)<CalendarStylesProps>`
  [class*="weekday"] {
    border: 1px solid #d1d1d1;
    background-color: ${(props) => props?.weekdayBg || theme.bgColor2};
    text-align: center;
    height: 35px;
    &:not(:first-child) {
      border-left: 0;
    }
    abbr {
      cursor: default;
      text-decoration: none;
    }
    & > div:first-child {
      background-color: #eaeaea;
    }
    & > div:last-child {
      background-color: transparent;
    }
  }

  .react-calendar__month-view__weekdays {
    border: 0 !important;
  }

  [class*="tile"] {
    border: 1px solid ${theme.gray2};
    border-top: none;
    background: ${theme.base};
    pointer-events: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 175px;
    &:not(:nth-child(7n)) {
      border-right: 0 !important;
    }
    * {
      cursor: auto;
    }
    abbr {
      text-align: center;
      width: 100%;
      background: #f1f1f1;
      border-bottom: 1px solid ${theme.gray2};
      @media print {
        display: block;
        width: 100%;
      }
    }
  }
  .holiday {
    width: 100%;
    height: 100%;
    background: #eaeaea;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 5px;
    .inner_item {
      background: #f1f2f1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px;
      gap: 0.2rem;
      .service_tag {
        background: #afafaf;
        border-radius: 5px;
        padding: 5px 10px;
      }
      .f_name {
        font-weight: bold;
        text-align: left;
      }
      .btns_tags {
        display: flex;
        gap: 0.2rem;
        .pick_tag {
          background-color: #c1c1c1;
          padding: 5px;
          border-radius: 8px;
        }
        .drop_tag {
          background-color: #979797;
          padding: 5px;
          border-radius: 8px;
        }
      }
    }

    @media print {
      &.facility-application-calendar-date {
        background: ${theme.base};
        .inner_item {
          height: min-content !important;
        }
      }
    }
  }
  [class*="neighboringMonth"] {
    cursor: default;
    abbr,
    .content {
      cursor: default;
      display: none;
    }
  }
  .program-list {
    li {
      text-align: left;
    }
  }
  .pick-drop-time {
    font-size: small;
    text-align: left;
  }
  @media screen and (max-width: ${theme.breakpoints?.md}) {
    [class*="weekday"] {
      display: none !important;
    }

    [class*="tile"] {
      flex-direction: row;
      min-width: 100% !important;
      border-top: thin solid ${theme.gray2};
      padding: 0;
      min-height: 40px !important;
      &:not(:nth-child(7n)) {
        border-right: 1px solid ${theme.gray2} !important;
      }
      abbr {
        width: 50px;
        height: 100%;
        border-right: 1px solid ${theme.gray2} !important;
        border-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .content {
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      margin-left: 10px !important;
    }
    [class*="neighboringMonth"] {
      display: none !important;
      abbr,
      .content {
        cursor: default;
        display: none;
      }
    }
  }

  @media print {
    margin: 0px !important;
    .react-calendar__month-view__days__day--weekend {
      width: 100%;
    }
    .react-calendar__month-view__weekdays {
      display: none !important;
    }
    [class*="tile"] {
      min-height: 40px !important;
      max-height: 200px !important;
      page-break-inside: avoid !important;
    }
    .content {
      padding: 0 !important;
      font-size: 8px !important;
      width: 100% !important;
      .inner_item {
        display: flex !important;
        align-items: center;
        justify-content: center;
        .service_tag {
          font-size: 8px !important;
          padding: 2px !important;
        }
        .f_name,
        .pick-drop-time {
          font-size: 8px !important;
          b {
            font-size: 8px !important;
          }
        }
        .btns_tags {
          .pick_tag,
          .drop_tag {
            padding: 4px;
            font-size: 8px !important;
          }
        }
      }
    }
  }
`

export const CustomTableWrapper = styled.table`
  thead {
    display: none;
  }
  @media only print {
    margin-top: 10px;
    thead {
      display: table-header-group;
      border: 1px solid ${theme.gray2};
      th {
        background: ${theme.bgColor2};
        width: 14.28%;
        &:not(:last-child) {
          border-right: 1px solid ${theme.gray2};
        }
      }
    }
  }
`
