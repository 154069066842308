import { PageCard, scrollToElementTop } from "@project/shared"
import React, { Fragment, useEffect, useRef } from "react"
import { useRouter } from "next/router"
//libraries
import { useReactToPrint } from "react-to-print"
import wareki from "wareki"
import {
  InnerTableWrapper,
  RemunerationTableWrapper,
  TitleLeftContent,
  WrapperDiv,
} from "./RemunerationCalculationPrintContent.styles"
import { OwnerButton } from "../../../atoms"
import { useTranslation } from "react-i18next"
import { YearPaginateBtn } from "../../../molecules"
import { Divider, Skeleton, Table } from "antd"

import moment from "moment"
import { useRemunerationCalculationContext } from "../../../../context/RemunerationCalculationContext"
import { useUpdateSearchParams } from "../../../../utils/useUpdateSearchParams"
import { formatJaEra } from "../../../molecules/MonthlyUsageList/common/mapper"
import { getLanguage } from "../../../../utils/getLanguage"

export const RemunerationCalculationPrintContent = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const [updateParams] = useUpdateSearchParams()
  const {
    calculatePercentage,
    isLoading,
    isQueryChanged,
    searchParams,
    onFilter,
  } = useRemunerationCalculationContext()
  let { detailDataSource } = useRemunerationCalculationContext()

  const serviceType1Items = detailDataSource.filter(
    (item) => item?.service_type === "1"
  )
  const serviceType2Items = detailDataSource.filter(
    (item) => item?.service_type === "2"
  )

  // Concatenate the filtered arrays
  detailDataSource = serviceType1Items.concat(serviceType2Items)

  const printContentRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const innerTableDataSource = [
    {
      key: 1,
      month: t("April"),
    },
    {
      key: 2,
      month: t("May"),
    },
    {
      key: 3,
      month: t("June"),
    },
    {
      key: 4,
      month: t("July"),
    },
    {
      key: 5,
      month: t("August"),
    },
    {
      key: 6,
      month: t("September"),
    },
    {
      key: 7,
      month: t("October"),
    },
    {
      key: 8,
      month: t("November"),
    },
    {
      key: 9,
      month: t("December"),
    },
    {
      key: 10,
      month: t("January"),
    },
    {
      key: 11,
      month: t("February"),
    },
    {
      key: 12,
      month: t("March"),
    },
    {
      key: 13,
      month: t("Total"),
    },
  ]
  const handleContentPrint = useReactToPrint({
    content: () => printContentRef.current,
    pageStyle: `@media print {
      @page {
        margin: 10mm;
      }
    }`,
  })
  const getDefaultArrayQueries = () => {
    return {
      facility: Array.isArray(searchParams?.facility)
        ? searchParams?.facility?.join(",")
        : "",
    }
  }
  const yearMonth = `${router?.query?.fiscal_year || moment().year()}-${
    router?.query?.fiscal_month || moment().month() + 1
  }`
  const era =
    formatJaEra(
      wareki(yearMonth, {
        unit: true,
      })
    ) || ""
  const currentDate = moment({
    year: +router?.query?.fiscal_year || moment().year(),
    month: +router?.query?.fiscal_month - 1 || moment()?.month(),
    date: +router?.query?.fiscal_day || new Date().getDate(),
  })

  useEffect(() => {
    if (isQueryChanged) {
      scrollToElementTop(listRef)
    }
  }, [isQueryChanged])

  const mainContent = ({
    _index,
    val,
    hasContent = true,
    service,
    service_type,
  }: {
    _index: number
    val: any
    hasContent?: boolean
    service: string
    service_type: number
  }) => {
    return (
      <>
        <RemunerationTableWrapper key={val}>
          <div className={"table_caption"}>
            <div className={"date__content"}>
              {searchParams?.print_config?.includes("1") ? (
                <div className={"empty__date"}>
                  <span id={"middle_s"}>{t("Reiwa")}</span>
                  <span id={"middle_s"}>{t("Year")}</span>
                  <span id={"middle_s"}>{t("Month")}</span>
                  <span>{t("Day")}</span>
                </div>
              ) : (
                <p>
                  {getLanguage() === "en"
                    ? currentDate.format("YYYY年MM月DD日")
                    : t("{{era}}{{month}}月{{date}}日", {
                        era,
                        month: `0${
                          router?.query?.fiscal_month || moment().month() + 1
                        }`?.slice(-2),
                        date: router?.query?.fiscal_day || new Date().getDate(),
                      })}
                </p>
              )}
            </div>
            <div className={"title_text"}>
              {t(
                "Notification form regarding remuneration calculation categories for {{service}}",
                {
                  service,
                }
              )}
            </div>
          </div>
          <div className={"content"}>
            <Table
              bordered
              columns={[
                {
                  key: "1",
                  title: t("Name of office/facility"),
                  width: 200,
                  dataIndex: "title",
                  align: "center",
                },
                {
                  key: "2",
                  title: detailDataSource[_index]?.facility_name,

                  align: "center",
                  render: (_, row, index) => {
                    if (index === 0) {
                      return (
                        <div className={"transfer_category_tr"}>
                          <div>{t("①New")}</div>
                          <div>{t("②Update")}</div>
                          <div>{t("③Ended")}</div>
                        </div>
                      )
                    }
                    return (
                      <>
                        <InnerTableWrapper>
                          <div className={"table_content"}>
                            <Table
                              bordered
                              columns={[
                                {
                                  key: 1,
                                  title: t("Month"),
                                  dataIndex: "month",
                                  align: "center",
                                },
                                {
                                  key: 2,
                                  title: t("①Total number of users"),
                                  align: "center",
                                  render: (_, row, index) => {
                                    return (
                                      <>
                                        {index === 12 ? (
                                          <span>
                                            {hasContent ? (
                                              <>
                                                {row?.data?.total_user || "0"}{" "}
                                                {t("people")}
                                              </>
                                            ) : null}
                                          </span>
                                        ) : (
                                          <span>
                                            {hasContent ? (
                                              <>
                                                {row?.data?.[
                                                  `child_count_m${index + 1}`
                                                ] || "0"}{" "}
                                                {t("people")}
                                              </>
                                            ) : null}
                                          </span>
                                        )}
                                      </>
                                    )
                                  },
                                },
                                {
                                  key: 3,
                                  title:
                                    service_type === 1
                                      ? t("② ①のうち指標の対象児")
                                      : t("② ①のうち未就学児"),
                                  align: "center",
                                  render: (_, row, index) => (
                                    <>
                                      {index === 12 ? (
                                        <span>
                                          {" "}
                                          {hasContent ? (
                                            <>
                                              {" "}
                                              {row?.data?.total_preschooler ||
                                                "0"}{" "}
                                              {t("people")}
                                            </>
                                          ) : null}
                                        </span>
                                      ) : (
                                        <span>
                                          {hasContent ? (
                                            <>
                                              {row?.data?.[
                                                `pre_schooler_m${index + 1}`
                                              ] || "0"}{" "}
                                              {t("people")}
                                            </>
                                          ) : null}
                                        </span>
                                      )}
                                    </>
                                  ),
                                },
                                {
                                  key: 4,
                                  title:
                                    service_type === 1
                                      ? t("③指標の対象児の割合(②/①)")
                                      : t("③未就学児の割合(②/①)"),
                                  align: "center",
                                  render: (_, row, index) => (
                                    <span>
                                      {hasContent ? (
                                        <>
                                          {" "}
                                          {`${calculatePercentage(
                                            row?.data?.[
                                              `child_count_m${index + 1}`
                                            ],
                                            row?.data?.[
                                              `pre_schooler_m${index + 1}`
                                            ]
                                          )?.toFixed(2)}%`}
                                        </>
                                      ) : null}
                                    </span>
                                  ),
                                },
                              ]}
                              dataSource={innerTableDataSource?.map(
                                (inner) => ({
                                  ...inner,
                                  data:
                                    detailDataSource?.map((val) => ({
                                      ...val,
                                      child_count_m1: val?.child_count_m4,
                                      child_count_m2: val?.child_count_m5,
                                      child_count_m3: val?.child_count_m6,
                                      child_count_m4: val?.child_count_m7,
                                      child_count_m5: val?.child_count_m8,
                                      child_count_m6: val?.child_count_m9,
                                      child_count_m7: val?.child_count_m10,
                                      child_count_m8: val?.child_count_m11,
                                      child_count_m9: val?.child_count_m12,
                                      child_count_m10: val?.child_count_m1,
                                      child_count_m11: val?.child_count_m2,
                                      child_count_m12: val?.child_count_m3,
                                      pre_schooler_m1: val?.pre_schooler_m4,
                                      pre_schooler_m2: val?.pre_schooler_m5,
                                      pre_schooler_m3: val?.pre_schooler_m6,
                                      pre_schooler_m4: val?.pre_schooler_m7,
                                      pre_schooler_m5: val?.pre_schooler_m8,
                                      pre_schooler_m6: val?.pre_schooler_m9,
                                      pre_schooler_m7: val?.pre_schooler_m10,
                                      pre_schooler_m8: val?.pre_schooler_m11,
                                      pre_schooler_m9: val?.pre_schooler_m12,
                                      pre_schooler_m10: val?.pre_schooler_m1,
                                      pre_schooler_m11: val?.pre_schooler_m2,
                                      pre_schooler_m12: val?.pre_schooler_m3,
                                    }))[_index] || null,
                                })
                              )}
                              pagination={false}
                            />
                          </div>
                          <div className={"description_text"}>
                            {service_type === 1
                              ? t(
                                  "* If the ratio of (2) to (1) is 50% or more, request in Category 1."
                                )
                              : service_type === 2
                              ? t(
                                  "*If the ratio of ② to ① is 70% or more, please make a claim under the category 1.2.(1) of the Notification of Day-to-day care fees for children with disabilities: ``When providing designated child development support mainly to preschool children.'' ."
                                )
                              : ""}
                          </div>
                        </InnerTableWrapper>
                      </>
                    )
                  },
                },
              ]}
              dataSource={[
                { key: 1, title: t("1 Transfer category") },
                { key: 2, title: t("2 Status of children using") },
              ]}
              pagination={false}
            />
          </div>
          <p className={"footer__info"}>
            {t(
              "Remarks: Please circle the appropriate number in the “Transfer Category” column."
            )}
          </p>
        </RemunerationTableWrapper>

        {searchParams?.print_config?.includes("2") && (
          <RemunerationTableWrapper key={val}>
            <div className={"table_caption"}>
              <div className={"date__content"}>
                {searchParams?.print_config?.includes("1") ? (
                  <div className={"empty__date"}>
                    <span id={"middle_s"}>{t("Reiwa")}</span>
                    <span id={"middle_s"}>{t("Year")}</span>
                    <span id={"middle_s"}>{t("Month")}</span>
                    <span>{t("Day")}</span>
                  </div>
                ) : (
                  <p>
                    {getLanguage() === "en"
                      ? currentDate.format("YYYY年MM月DD日")
                      : t("{{era}}{{month}}月{{date}}日", {
                          era,
                          month: `0${
                            router?.query?.fiscal_month || moment().month() + 1
                          }`?.slice(-2),
                          date:
                            router?.query?.fiscal_day || new Date().getDate(),
                        })}
                  </p>
                )}
              </div>
              <div className={"title_text"}>
                {`${
                  service_type === 1
                    ? t("Child development support")
                    : t("After school day service")
                } ${t(
                  "Notification form regarding remuneration calculation categories for"
                )}`}
              </div>
            </div>
            <div className={"content"}>
              <Table
                bordered
                columns={[
                  {
                    key: "1",
                    title: t("Name of office/facility"),
                    width: 200,
                    dataIndex: "title",
                    align: "center",
                  },
                  {
                    key: "2",
                    title: detailDataSource[_index]?.facility_name,

                    align: "center",
                    render: (_, row, index) => {
                      if (index === 0) {
                        return (
                          <div className={"transfer_category_tr"}>
                            <div>{t("①New")}</div>
                            <div>{t("②Update")}</div>
                            <div>{t("③Ended")}</div>
                          </div>
                        )
                      }
                      return (
                        <>
                          <InnerTableWrapper>
                            <div className={"table_content"}>
                              <Table
                                bordered
                                columns={[
                                  {
                                    key: 1,
                                    title: t("Month"),
                                    dataIndex: "month",
                                    align: "center",
                                  },
                                  {
                                    key: 2,
                                    title: t("①Total number of users"),
                                    align: "center",
                                    render: () => {
                                      return <></>
                                    },
                                  },
                                  {
                                    key: 3,
                                    title:
                                      service_type === 1
                                        ? t("② ①のうち指標の対象児")
                                        : t("② ①のうち未就学児"),
                                    align: "center",
                                    render: () => {
                                      return <></>
                                    },
                                  },
                                  {
                                    key: 4,
                                    title:
                                      service_type === 1
                                        ? t("③指標の対象児の割合(②/①)")
                                        : t("③未就学児の割合(②/①)"),
                                    align: "center",
                                    render: () => {
                                      return <></>
                                    },
                                  },
                                ]}
                                dataSource={innerTableDataSource?.map(
                                  (inner) => ({
                                    ...inner,
                                    data:
                                      detailDataSource?.map((val) => ({
                                        ...val,
                                        child_count_m1: val?.child_count_m4,
                                        child_count_m2: val?.child_count_m5,
                                        child_count_m3: val?.child_count_m6,
                                        child_count_m4: val?.child_count_m7,
                                        child_count_m5: val?.child_count_m8,
                                        child_count_m6: val?.child_count_m9,
                                        child_count_m7: val?.child_count_m10,
                                        child_count_m8: val?.child_count_m11,
                                        child_count_m9: val?.child_count_m12,
                                        child_count_m10: val?.child_count_m1,
                                        child_count_m11: val?.child_count_m2,
                                        child_count_m12: val?.child_count_m3,
                                        pre_schooler_m1: val?.pre_schooler_m4,
                                        pre_schooler_m2: val?.pre_schooler_m5,
                                        pre_schooler_m3: val?.pre_schooler_m6,
                                        pre_schooler_m4: val?.pre_schooler_m7,
                                        pre_schooler_m5: val?.pre_schooler_m8,
                                        pre_schooler_m6: val?.pre_schooler_m9,
                                        pre_schooler_m7: val?.pre_schooler_m10,
                                        pre_schooler_m8: val?.pre_schooler_m11,
                                        pre_schooler_m9: val?.pre_schooler_m12,
                                        pre_schooler_m10: val?.pre_schooler_m1,
                                        pre_schooler_m11: val?.pre_schooler_m2,
                                        pre_schooler_m12: val?.pre_schooler_m3,
                                      }))[_index] || null,
                                  })
                                )}
                                pagination={false}
                              />
                            </div>
                            <div className={"description_text"}>
                              {service_type === 1
                                ? t(
                                    "* If the ratio of (2) to (1) is 50% or more, request in Category 1."
                                  )
                                : service_type === 2
                                ? t(
                                    "*If the ratio of ② to ① is 70% or more, please make a claim under the category 1.2.(1) of the Notification of Day-to-day care fees for children with disabilities: ``When providing designated child development support mainly to preschool children.'' ."
                                  )
                                : ""}
                            </div>
                          </InnerTableWrapper>
                        </>
                      )
                    },
                  },
                ]}
                dataSource={[
                  { key: 1, title: t("1 Transfer category") },
                  { key: 2, title: t("2 Status of children using") },
                ]}
                pagination={false}
              />
            </div>
            <p className={"footer__info"}>
              {t(
                "Remarks: Please circle the appropriate number in the “Transfer Category” column."
              )}
            </p>
          </RemunerationTableWrapper>
        )}
      </>
    )
  }

  return (
    <div ref={listRef}>
      <PageCard
        title={
          <TitleLeftContent>
            <OwnerButton
              text={t("<<Back")}
              typeOf={"secondary"}
              shape={"round"}
              onClick={() => router.back()}
            />
            <OwnerButton
              text={t("Print")}
              typeOf={"print"}
              shape={"round"}
              onClick={handleContentPrint}
              className={"no-hover-effect"}
            />
          </TitleLeftContent>
        }
        toRight
        className={"remuneration-print"}
        extra={
          <YearPaginateBtn
            prev={t("Prev month")}
            current={t("This month")}
            next={t("Next month")}
            handleClick={(key) => {
              if (key === "prev") {
                if (searchParams.fiscal_month <= 1) {
                  onFilter({
                    fiscal_year: searchParams?.fiscal_year - 1,
                    fiscal_month: 12,
                  })
                  updateParams(
                    {
                      ...searchParams,
                      fiscal_year: searchParams?.fiscal_year - 1,
                      fiscal_month: 12,
                      ...getDefaultArrayQueries(),
                    },
                    "/remuneration-calculation/print"
                  )
                } else {
                  onFilter({
                    fiscal_month: searchParams?.fiscal_month - 1,
                  })
                  updateParams(
                    {
                      ...searchParams,
                      fiscal_month: searchParams?.fiscal_month - 1,
                      ...getDefaultArrayQueries(),
                    },
                    "/remuneration-calculation/print"
                  )
                }
              } else if (key === "current") {
                onFilter({
                  fiscal_year: new Date().getFullYear(),
                  fiscal_month: new Date().getMonth() + 1,
                })
                updateParams(
                  {
                    ...searchParams,
                    fiscal_year: new Date().getFullYear(),
                    fiscal_month: new Date().getMonth() + 1,
                    ...getDefaultArrayQueries(),
                  },
                  "/remuneration-calculation/print"
                )
              } else {
                if (searchParams?.fiscal_month >= 12) {
                  onFilter({
                    fiscal_year: searchParams?.fiscal_year + 1,
                    fiscal_month: 1,
                  })

                  updateParams(
                    {
                      ...searchParams,
                      fiscal_year: searchParams?.fiscal_year + 1,
                      fiscal_month: 1,
                      ...getDefaultArrayQueries(),
                    },
                    "/remuneration-calculation/print"
                  )
                } else {
                  onFilter({
                    fiscal_month: searchParams?.fiscal_month + 1,
                  })
                  updateParams(
                    {
                      ...searchParams,
                      fiscal_month: searchParams?.fiscal_month + 1,
                      ...getDefaultArrayQueries(),
                    },
                    "/remuneration-calculation/print"
                  )
                }
              }
            }}
          />
        }
      >
        {isLoading ? (
          <>
            {searchParams?.tempFacilities?.map((_, index) => (
              <Skeleton active key={index} />
            ))}
          </>
        ) : (
          <WrapperDiv ref={printContentRef}>
            {detailDataSource?.map((val, index) => {
              //const type = Array.isArray(searchParams?.service_type)
              // ? searchParams?.service_type
              //: searchParams?.service_type?.split(",")
              return (
                <Fragment key={val}>
                  {detailDataSource[index]?.service_type === "1" &&
                  (!searchParams?.print_config?.includes("2")
                    ? detailDataSource[index]?.service_type ==
                        detailDataSource[index]?.pref_id ||
                      detailDataSource[index]?.service_type ==
                        detailDataSource[index]?.pref_id2
                    : true) ? (
                    <>
                      {mainContent({
                        _index: index,
                        val,
                        hasContent: true,
                        service: t("After school day service"),
                        service_type: 1,
                      })}
                    </>
                  ) : null}
                  {detailDataSource[index]?.service_type === "2" &&
                  (!searchParams?.print_config?.includes("2")
                    ? detailDataSource[index]?.service_type ==
                        detailDataSource[index]?.pref_id ||
                      detailDataSource[index]?.service_type ==
                        detailDataSource[index]?.pref_id2
                    : true) ? (
                    <>
                      {mainContent({
                        _index: index,
                        val,
                        hasContent: true,
                        service: t("Child development support"),
                        service_type: 2,
                      })}
                    </>
                  ) : null}
                  {detailDataSource?.length - 1 !== index && (
                    <Divider className={"content_divider"} />
                  )}
                </Fragment>
              )
            })}
          </WrapperDiv>
        )}
      </PageCard>
    </div>
  )
}
