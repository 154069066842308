// TODO: want to global...
export enum ServiceType {
  AFTER_SCHOOL_DAY_SERVICE = 1,
  CHILD_DEVELOPMENT_SUPPORT = 2,
}

// TODO: want to global...
export enum AfterSchoolDayServiceRevenueCondition1 {
  DISABLE_CHILD_TYPE = 1,
  SYMBIOSIS_TYPE_AFTER_SCHOOL_DAY_CARE_SERVICE_BENEFIT_EXPENSES = 2,
}

// TODO: want to global...
export enum ChildDevelopmentSupportRevenueCondition1 {
  CHILD_DEVELOPMENT_SUPPORT = 1,
  CHILD_DEVELOPMENT_SUPPORT_CENTER = 2,
  SYMBIOTIC_CHILD_DEVELOPMENT_SUPPORT_BENEFITS = 3,
  CRITERIA_APPLICABLE_CHILD_DEVELOPMENT_SUPPORT_BENEFITS = 4,
}
