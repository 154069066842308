import { theme } from "@project/shared"
import styled, { css } from "styled-components"

const COMMONTH = css`
  font-size: 12px;
  background: transparent !important;
  padding: 4px !important;
  border-left: 1px solid black;
  :first-child {
    border-left: 0;
  }
`
const COMMONTD = css`
  font-size: 12px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px 8px !important;
  :first-child {
    border-left: 0;
  }
  :hover {
    background: none !important;
  }
  @media print {
    padding: 6px !important;
  }
`

export const WrapperDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .content_divider {
    background-color: ${theme.gray2};
    display: none !important;
    @media print {
      display: none !important;
      visibility: hidden !important;
    }
  }

  .only_print {
    text-align: center;
    display: none;
    @media print {
      display: block;
    }
  }
  @media print {
    row-gap: 0rem;
  }
`
export const TitleLeftContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  @media screen and (max-width: 426px) {
    gap: 0;
    button:last-child {
      margin-left: 0.5rem;
    }
  }
`

export const RemunerationTableWrapper = styled.div`
  @media print {
    display: inline-block !important;
    vertical-align: top !important;
    width: 100% !important;
    page-break-inside: avoid !important;
  }
  .ant-card-head-wrapper {
    display: none;
  }
  width: 100%;
  .table_caption {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    .date__content {
      padding: 0;
      margin: 0;
      text-align: right;
      .empty__date {
        span#middle_s {
          padding-right: 3rem;
        }
      }
    }
    .title_text {
      text-align: center;
    }
    @media print {
      margin: 0rem 0 1rem 0;
    }
  }
  .content {
    width: 100%;
    border: 1px solid #000;
    margin-bottom: 16px;
    table {
      :hover {
        background: none !important;
      }

      tr:hover {
        background: none !important;
      }
      th {
        ${COMMONTH}
        min-width: 120px;
      }
      td {
        ${COMMONTD}
      }
      .transfer_category_tr {
        display: flex;
        gap: 1rem;
        width: 70%;
        margin: auto;
        justify-content: space-between;

        div {
          font-size: 12px !important;
          min-width: 60px;
        }
      }
    }
    @media print {
      border: 2px solid #000;
      overflow: auto !important;
      margin-bottom: 5px !important;
      .ant-table.ant-table-bordered > .ant-table-container {
        border: none !important;
      }
      .ant-table-thead > tr {
        page-break-inside: avoid !important;
      }
      .ant-table-thead > tr > th {
        outline: none !important;
        border: none !important;
        &:not(:first-child) {
          border-left: 1px solid #000 !important;
        }
        &:nth-last-child(2) {
          border-right: 0.1px solid #000 !important;
        }
      }
      table {
        border: none !important;
        tbody {
          td {
            border-right: none !important;
            border-bottom: none !important;
          }
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      overflow: scroll !important;
    }
  }
  .footer__info {
    font-size: 10pt;
  }
  @page {
    margin-left: 20pt;
    margin-right: 20pt;
  }
`
export const InnerTableWrapper = styled.div`
  width: 100%;
  .table_content {
    border: 1px solid #000;
    table {
      :hover {
        background: none !important;
      }
      tr:hover {
        background: none !important;
      }

      th {
        ${COMMONTH}
      }

      th:first-child {
        min-width: 50px;
      }
      th:nth-child(2) {
        min-width: 100px;
      }
      th:nth-child(3) {
        min-width: 150px;
      }
      th:last-child {
        min-width: 185px;
      }

      td {
        ${COMMONTD}
        span {
          font-size: 12px !important;
        }
      }
    }
  }
  .description_text {
    font-size: 12px;
    padding: 8px;
    text-align: left;
  }
  @media print {
    .table_content {
      border: 2px solid #000;
      .ant-table.ant-table-bordered > .ant-table-container {
        border: none !important;
      }
      .ant-table-thead > tr > th {
        outline: none !important;
        border: none !important;
        &:not(:first-child) {
          border-left: 1px solid #000 !important;
        }
        &:nth-last-child(2) {
          border-right: 0.1px solid #000 !important;
        }
      }
      table {
        border: none !important;
        tbody {
          td {
            border-right: none !important;
            border-bottom: none !important;
          }
        }
      }
    }
  }
`
