import { API } from "@project/shared"

export interface INotification {
  count?: number
  data?: any
  nextPage?: number
}

export const fetchCompanyNofications = async ({
  queryKey,
  pageParam = 1,
}): Promise<INotification> => {
  const params = {} as { page: number; pageSize: number }
  params.page = pageParam
  params.pageSize = queryKey[2]
  const result = await API.get(`/company-notifications`, {
    params,
  })
  result["nextPage"] =
    result["data"].length > result["count"] ? pageParam + 1 : null

  return result
}

export const getNotificationCount = () =>
  API.get(`/company-notifications/count-unseen`)

export const seenNotification = async (values): Promise<any> => {
  delete values.id
  if (values.is_seen) return
  return API.post(`/set-company-notification`, { ...values, is_seen: true })
}
