import { TextField, Checkboxes, theme } from "@project/shared"
import moment from "moment"
import { useRouter } from "next/router"
import React, { FormEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AuthContext, Highlighter, Title, TitleWrapper } from "../../../utils"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../DatePicker"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  lan?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      keyword: string
      year: string | number
      month: string | number
      facilityIds: any[] | []
    }>
  >
  queryParam?: {
    keyword: string
    year: string | number
    month: string | number
    facilityIds: any[] | []
  }
  isLoadingData?: boolean
  setCurrentPage?: (val: number) => void
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }

  .action-btns-wrapper {
    margin-bottom: 20px;
  }

  .facility__checkboxes {
    margin-left: ${({ lan }) => (lan === "en" ? "14.5rem" : "8.7rem")};
  }

  @media (max-width: ${theme.breakpoints.md}) {
    .md_mb-10 {
      margin-bottom: 10px !important;
    }
    .action-btns-wrapper {
      margin-bottom: 15px;
    }
    .facility__checkboxes {
      margin-left: 0rem;
    }
  }
`
const DateSelectWrap = styled.div`
  display: flex;
  align-items: center;
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    @media (max-width: 516px) {
      flex-wrap: no-wrap;
      .check-button {
        height: 30px !important;
        width: 144px;
        display: flex;
        align-items: center;
        line-height: 30px;
        padding: 0px 0;
      }
      .un-check-button {
        height: 30px !important;
        width: 100px;
        display: flex;
        align-items: center;
        line-height: 30px;
        padding: 0px 0;
      }
    }
  }

  .checkbox-wrapper {
    display: flex;
    @media (max-width: 768px) {
      .ant-checkbox + span {
        white-space: pre-line;
        word-break: break-all;
        padding-left: 10px;
      }
    }
  }
  .check-button,
  .un-check-button {
    height: 30px !important;
    margin-left: 10px;
  }
  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 516px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .text-field {
    width: 100%;
  }
  input {
    background-color: #ffffff;
    width: 712px;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  .textfield {
    margin-left: 30px;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`
const Text = styled(Title)`
  font-size: 16px;
`

const ParentOperationComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const { header, setQueryParam, queryParam, setCurrentPage } = props
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const [keyword, setKeyword] = useState("")
  const [year, setYear] = useState(queryParam.year)
  const [month, setMonth] = useState(queryParam.month)
  const [facilityIds, setFacilityIds] = useState(queryParam?.facilityIds || [])
  const [updateParams] = useUpdateSearchParams()
  const FACILITY_OPTION =
    facilities?.map((item) => {
      return {
        value: item?.id,
        label: item?.facility_name_short,
      }
    }) || []
  const allFacility = FACILITY_OPTION.map((item: any) => item.value)
  const handleSearch = () => {
    const queries = {
      keyword: keyword,
      year: year,
      month: Number(month) + 1,
    }
    const selectedFacility =
      facilityIds?.length < 1
        ? [...facilities?.map((val) => val?.id), 0]
        : facilityIds
    setFacilityIds(selectedFacility)
    setCurrentPage(1)
    setQueryParam((prev) => {
      return {
        ...prev,
        ...queries,
        facilityIds: selectedFacility,
      }
    })
    updateParams(
      {
        page: 1,
        ...queries,
        facilityIds: selectedFacility?.join(","),
      },
      "/parents"
    )
  }
  const handleReset = () => {
    const selectedFacility = [...facilities?.map((val) => val?.id), 0]
    setKeyword("")
    setYear(String(new Date().getFullYear()))
    setMonth(String(new Date().getMonth()))
    setCurrentPage(1)
    const values = {
      keyword: "",
      year: String(new Date().getFullYear()),
      month: String(new Date().getMonth() + 1),
      facilityIds: selectedFacility,
    }

    setFacilityIds(selectedFacility)
    setQueryParam((prev) => ({
      ...prev,
      ...values,
    }))
    updateParams(
      {
        page: 1,
      },
      "/parents"
    )
  }

  useEffect(() => {
    setMonth(Number(queryParam.month) - 1)
  }, [])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault()
          handleSearch()
        }}
      >
        <TitleWrapper>
          <Highlighter /> <Title>{t("Narrow down")} </Title>
        </TitleWrapper>

        <FieldWrapper className={"md_mb-10"}>
          <TitleWrap style={{ minWidth: "125px" }}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Keyword")} </Text>
          </TitleWrap>
          <TextField
            name={"company_name"}
            height={"50px"}
            placeholder={t("Name, Beneficiary number")}
            bgcolor={"#fff"}
            className={"text-field"}
            value={keyword}
            onChange={(evt) => setKeyword(evt.target.value)}
          />
        </FieldWrapper>

        <SearchWrapper className={"md_mb-10"}>
          <TitleWrap style={{ minWidth: "125px" }}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("contract")} </Text>
          </TitleWrap>

          <DateSelectWrap>
            <DatePicker
              date={moment({
                year: +year,
                month: +month,
              })}
              style={{
                height: "40px",
              }}
              picker={"month"}
              format={"YYYY年MM月"}
              showArrow={true}
              disabledDate={(current) => {
                return (
                  (current && current < moment().subtract(2, "year")) ||
                  (current && current > moment().add(5, "year"))
                )
              }}
              onDateChange={(value) => {
                const y = +moment(value).format("YYYY")
                const m = +moment(value).format("M") - 1
                setYear(y)
                setMonth(m)
              }}
            />
          </DateSelectWrap>
        </SearchWrapper>

        <SearchWrapper className={"md_mb-10"}>
          <div className={"border-dashed"}></div>
        </SearchWrapper>

        <SearchWrapper className={"md_mb-10"}>
          <TitleWrap style={{ minWidth: "125px" }}>
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ marginRight: "10px" }}
            />
            <Text>{t("Children's contract facility")} </Text>
          </TitleWrap>
          <div className={"facility-wrapper"}>
            <OwnerButton
              typeOf={"check-all"}
              className={"check-button"}
              text={t("Check all")}
              onClick={() => {
                const mutatedFacility = allFacility
                mutatedFacility.push(0)
                setFacilityIds(mutatedFacility)
              }}
            />
            <OwnerButton
              typeOf={"secondary"}
              className={"un-check-button"}
              text={t("Uncheck all")}
              backgroundColor={"#fff"}
              onClick={() => {
                setFacilityIds([])
              }}
            />
          </div>
        </SearchWrapper>

        <SearchWrapper className={"md_mb-10 facility__checkboxes"}>
          <div className={"checkbox-wrapper"}>
            <Checkboxes
              key={"footerInformation"}
              name={"footerInformation"}
              value={facilityIds}
              onChange={(values) => {
                setFacilityIds(values)
              }}
              options={[
                ...FACILITY_OPTION,
                {
                  value: 0,
                  label: t("No contract facility"),
                },
              ]}
            />
          </div>
        </SearchWrapper>

        <SearchWrapper>
          <div className={"border-dashed"}></div>
        </SearchWrapper>

        <Buttonwrapper className={"action-btns-wrapper"}>
          <OwnerButton
            className={"search-btn"}
            text={t("Search")}
            shape={"circle"}
            icon={"search"}
            type={"submit"}
            typeOf={"primary"}
            onClick={handleSearch}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button"}
            text={t("Reset search")}
            onClick={handleReset}
          />
        </Buttonwrapper>
      </form>
      <SearchWrapper>
        <div className={"border-dashed"}></div>
      </SearchWrapper>
      <TitleWrapper>
        <Highlighter /> <Title>{t("Add data")} </Title>
      </TitleWrapper>
      <Buttonwrapper className={"btn-wrapper"}>
        <OwnerButton
          className={"search-btn"}
          text={t("Add new parent")}
          shape={"circle"}
          typeOf={"primary"}
          onClick={() => router.push("/parents/add")}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ParentOperationComponent }
