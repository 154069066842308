const ExcelIconWhite = () => (
  <svg
    width={"17"}
    height={"17"}
    viewBox={"0 0 12 14"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M7.33268 0.333984H1.99935C1.64573 0.333984 1.30659 0.47446 1.05654 0.724509C0.806491 0.974557 0.666016 1.3137 0.666016 1.66732V12.334C0.666016 13.074 1.25935 13.6673 1.99935 13.6673H9.99935C10.7393 13.6673 11.3327 13.074 11.3327 12.334V4.33398L7.33268 0.333984ZM9.99935 12.334H1.99935V1.66732H6.66602V5.00065H9.99935V12.334ZM6.59935 8.66732L8.53268 11.6673H7.33268L5.99935 9.40065L4.66602 11.6673H3.46602L5.39935 8.66732L3.46602 5.66732H4.66602L5.99935 7.93398L7.33268 5.66732H8.53268L6.59935 8.66732Z"
      }
      fill={"white"}
    />
  </svg>
)
export default ExcelIconWhite
