import { useRouter } from "next/router"

// packages
import { t } from "i18next"

// components
import { OwnerButton } from "../../../atoms"
import FormFieldsGen from "../../../molecules/FormFields"

// styled components
import { InputGroup, JsxStyledContent } from "../styles"

// types
import {
  FieldWrapperProps,
  AgreementListProps,
  RecordsListJsxItemProps,
} from "../types"

export const OperationFieldWrapper: React.FC<FieldWrapperProps> = ({
  id,
  label,
  children,
}) => {
  const currentLang = localStorage.getItem("i18nextLng") as "en" | "ja"
  return (
    <InputGroup $currentLang={currentLang} className={"input-group flexbox"}>
      <label className={"caret-right label"} htmlFor={id}>
        {t(label)}
      </label>
      <div className={"input-field"}>{children}</div>
    </InputGroup>
  )
}

export const MidButtons = ({ handlePrint }: { handlePrint: any }) => {
  const router = useRouter()
  return (
    <div className={"flexbox mid-button-group"}>
      <OwnerButton
        className={"back-btn"}
        text={t("<< Back")}
        typeOf={"draft"}
        onClick={() => {
          router.push("/minutes-addition")
        }}
      />
      <OwnerButton
        onClick={() => {
          handlePrint()
        }}
        className={"print-btn"}
        text={t("Print")}
        typeOf={"print"}
      />
    </div>
  )
}

export const RecordsListJsxItem: React.FC<RecordsListJsxItemProps> = ({
  text,
  showMark,
  showSignField,
}) => {
  return (
    <JsxStyledContent className={"jsx-content"}>
      <p>{t(text)}</p>
      <p className={"content_sign-off"}>
        {showSignField && (
          <span className={"sign-off_signature-field"}>{t("Signature")}</span>
        )}
        {showMark && <span className={"sign-off_mark"}>{t("mark")}</span>}
      </p>
    </JsxStyledContent>
  )
}

export const AgreementList: React.FC<AgreementListProps> = ({
  mappableHeadMoreInfos,
  mappableRecords,
  printingOptions,
  minutesTitle,
}) => {
  return (
    <div className={"first-record-content"}>
      {/* Head starts here */}
      <div className={"first-record-content_head flexbox"}>
        <h3>{minutesTitle}</h3>
        <div className={"head_record-info"}>
          {mappableHeadMoreInfos.map((item) => (
            <div className={"record-info_individual flexbox"} key={item.key}>
              <span className={"label"}>{t(item.label)}</span>
              <span>{":"}</span>
              <span className={"value"}>{t(item.value)}</span>
            </div>
          ))}
        </div>
      </div>
      {/* Head ends here */}

      {/* agreement sign off starts here */}
      <div className={"agreement-sign-off"}>
        {printingOptions?.parentalConsent.includes("1") && (
          <p className={"agreement-sign-off_title"}>
            {t("I agree to receive other records.")}
          </p>
        )}

        {printingOptions?.parentalConsent.includes("2") && (
          <p className={"agreement-sign-off_signature"}>
            <span className={"sign-off_signature-field"}>
              {t("Consent date")}
            </span>
          </p>
        )}

        {printingOptions?.parentalConsent.includes("3") && (
          <p className={"agreement-sign-off_signature"}>
            <span className={"sign-off_signature-field"}>
              {t(
                !printingOptions?.parentSign.includes("1")
                  ? "Parents name"
                  : "Parents and \nfamily representative name"
              )}
            </span>
            {printingOptions?.stamp.includes("1") &&
              printingOptions?.parentalConsent.includes("3") && (
                <span className={"mark"}>{t("mark")}</span>
              )}
          </p>
        )}
      </div>
      {/* agreement sign off ends here */}

      {/* record list starts here */}
      <div className={"record-list-content"}>
        {mappableRecords.map((item, index) => {
          if (!item || !item.fieldLabel) return null
          return (
            <FormFieldsGen
              index={index}
              item={item}
              key={item.fieldLabel + index}
            />
          )
        })}
      </div>
      {/* record list ends here */}

      {/* agreement sign off starts here */}
      <div className={"agreement-sign-off confirm-agreement"}>
        {printingOptions?.parentalVerification.includes("1") && (
          <p className={"agreement-sign-off_title"}>
            {t("I have confirmed the above.")}
          </p>
        )}

        {printingOptions?.parentalVerification.includes("2") && (
          <p className={"agreement-sign-off_signature"}>
            <span className={"sign-off_signature-field"}>
              {t("Confirmed day")}
            </span>
          </p>
        )}

        {printingOptions?.parentalVerification.includes("3") && (
          <p className={"agreement-sign-off_signature"}>
            <span className={"sign-off_signature-field"}>
              {t(
                !printingOptions?.parentSign.includes("1")
                  ? "Parents name"
                  : "Parents and \nfamily representative name"
              )}
            </span>
            {printingOptions?.stamp.includes("1") &&
              printingOptions?.parentalVerification.includes("3") && (
                <span className={"mark"}>{t("mark")}</span>
              )}
          </p>
        )}
      </div>
      {/* agreement sign off ends here */}
    </div>
  )
}

export * from "./OperationControllerBlock"
export * from "./RecordsBlock"
