import {
  Grid,
  PageCard,
  Required,
  TextField,
  SelectInput,
  ACCOUNT_TYPE,
  Checkbox,
  PREFECTURE_OPTIONS,
  theme,
  convertToHalfWidth,
} from "@project/shared"
import { notification, Popconfirm, Skeleton } from "antd"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import * as japanPostalCode from "japan-postal-code"
import {
  createParent,
  deleteParent,
  getParent,
  updateParent,
} from "../../../services"
import {
  allowOnlyNumberAndHyphen,
  AuthContext,
  Divisor,
  ErrorMessage,
  FormTitle,
  Info,
  scrollToFirstErrorField,
  StyledForm,
} from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import { realTimeValidate } from "../../../utils/validation"
import { toastMsg } from "../../../utils/toastMsg"
interface styleProps {
  iscenterAlign?: boolean
}
interface iParent {
  id?: string | string[]
}

const HorizontalInfo = styled.div<styleProps>`
  display: flex;
  align-items: center;
  width: 100%;
  .info {
    margin-left: 21px;
    margin-top: ${(props) => (props.iscenterAlign ? "-21px" : "0")};
  }
  input {
    width: 240px;
  }
  @media (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
    .info {
      margin-left: 0;
      margin-top: 9px;
    }
    .half-field {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
`
const Wrapper = styled.div`
  .button-wrap {
    margin-top: 30px;
    display: flex;
    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #000000;
  }

  .quater-field {
    width: 75%;
    input {
      width: 100%;
    }
  }
  @media (max-width: 1050px) {
    .button-wrap {
      flex-direction: column;
      button {
        :first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
    .quater-field {
      width: 100%;
    }
  }
  @media (max-width: 874px) {
    .styled-form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .grid-header field-label-container {
      width: 100%;
    }
    .grid-header
      field-label-container
      ~ div:not(.grid-header field-label-container) {
      padding-left: 0;
      padding-right: 0;
      border-right: 0;
      width: 100%;
    }
  }

  .ant-card {
    .ant-card-body {
      .styled-form {
        .button-wrapper {
          @media (max-width: ${theme.breakpoints.md}) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .form-title {
    margin-top: 40px;
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 0px;
    }
  }
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 416px) {
    .ant-checkbox-wrapper {
      gap: 0px;
      .ant-checkbox + span {
        margin-left: 5px;
      }
    }
  }
`

const ParentManagementForm: React.FC<iParent> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)
  const hasParentDeleteAccess = useMemo(() => {
    return isOwner || permissions?.auth_new_delete_flag == "1"
  }, [isOwner, permissions])

  const {
    data: parentData,
    isLoading: parentDataLoading,
    isFetching: parentDataFetching,
  } = useQuery<any, Error>(["parent", id], () => id && getParent(Number(id)), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: ({ data }) => {
      data.hasNoChild = data?.child == null || data?.child.length == 0
      return data
    },
  })

  const { isLoading: isDeleting, mutate: deleteOneParent } = useMutation(
    deleteParent,
    {
      onSuccess: () => {
        toastMsg("success", t("Deleted Successfully"))
        router.push("/parents")
      },
      onError: (error: { data }) => {
        const message =
          error?.data?.error?.message ||
          "Something went wrong. Please contact administrator."
        toastMsg("error", t(message))
      },
    }
  )

  const { mutate: mutateParent, isLoading } = useMutation(
    id ? updateParent : createParent,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Updated Successfully"),
          })
        } else {
          notification.success({
            message: t("Created Successfully"),
          })
        }
        router.push("/parents")
      },
      onError: (error?: any) => {
        const msg =
          error?.status == 403
            ? "User doesn't have permission to edit this page"
            : error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  const initialValues = parentData || {
    parent_name: "",
    parent_name_furigana: "",
    zip_code: "",
    pref_id: null,
    address: "",
    tel_no: "",
    child_reretion: "",
    parent_remaks: "",
    emergency_tel_no1: "",
    emergency_contact1: "",
    emergency_tel_no2: "",
    emergency_contact2: "",
    emergency_remarks: "",
    financial_institution_name: "",
    branch_name_katakana: "",
    bank_no: "",
    branch_no: "",
    account_type: 0,
    account_number: "",
    account_holder_name: "",
    customer_number: "",
    mail_address: "",
    mail_address_multi: "",
    mail_receive_noti_enter_flag: false,
    mail_receive_noti_leave_flag: false,
    mail_receive_noti_pick_flag: false,
    mail_receive_noti_up_flag: false,
  }
  const validationSchema = yup.object().shape({
    parent_name: yup.string().required(t("Please enter")),
    parent_name_furigana: yup
      .string()
      .required(t("Please enter"))
      .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect")),
    zip_code: yup.string().max(8).required(t("Please enter")),
    pref_id: yup.number().required(t("Please enter")).nullable(),
    address: yup
      .string()
      .max(200, t("(within 200 char)"))
      .required(t("Please enter")),
    tel_no: yup
      .string()
      .required(t("Please enter"))
      .max(30, t("(within 30 char)"))
      .matches(
        /^([^-\n]*-){0,2}[^-\n]*$/,
        t("(Half-width numbers and hyphens (-) within 30 characters)")
      ),
    child_reretion: yup.string(),
    parent_remaks: yup.string(),
    emergency_tel_no1: yup.string(),
    emergency_contact1: yup.string(),
    emergency_tel_no2: yup.string(),
    emergency_contact2: yup.string(),
    emergency_remarks: yup.string(),
    financial_institution_name: yup.string(),
    branch_name_katakana: yup
      .string()
      .nullable()
      .matches(/^[ァ-ヶー　| ]*$/, t("※Katakana is incorrect")),
    bank_no: yup.string(),
    branch_no: yup.string(),
    account_type: yup.number().nullable(),
    account_number: yup.string().max(7),
    account_holder_name: yup.string(),
    customer_number: yup.string(),
    mail_address: yup.string().email(t("Email address format is incorrect")),
    mail_address_multi: yup
      .string()
      .matches(
        /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/,
        t("Email address format is incorrect")
      ),
    mail_receive_noti_enter_flag: yup.boolean(),
    mail_receive_noti_leave_flag: yup.boolean(),
    mail_receive_noti_pick_flag: yup.boolean(),
    mail_receive_noti_up_flag: yup.boolean(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      mutateParent(value)
    },
  })
  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }

  const setAddressFromPostCode = async () => {
    japanPostalCode.get(formik?.values?.zip_code, function (address) {
      formik.setFieldValue("address", address?.city)
      const prefectureNumber =
        PREFECTURE_OPTIONS.find((item) => {
          return item.label == address?.prefecture
        })?.value || null
      formik.setFieldValue("pref_id", prefectureNumber)
    })
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  return (
    <Wrapper>
      <PageCard title={t("Parents information")} style={{ marginTop: "22px" }}>
        {parentDataLoading || parentDataFetching ? (
          <StyledForm>
            <Skeleton />
          </StyledForm>
        ) : (
          <StyledForm className={"styled-form"} onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Parent name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"parent_name"}
                className={getErrorClass("parent_name", "input")}
                value={formik.values.parent_name}
                onChange={formik.handleChange}
                placeholder={t("例：鈴木太郎")}
                maxLength={200}
                error={formik.touched.parent_name && formik.errors.parent_name}
              />
              <Info>{t("(200文字以内)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Hiragana")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"parent_name_furigana"}
                className={getErrorClass("parent_name_furigana", "input")}
                value={formik.values.parent_name_furigana}
                onChange={(e) => realTimeValidate(e, formik)}
                placeholder={t("例：すずき　たろう")}
                maxLength={200}
                error={
                  formik.touched.parent_name_furigana &&
                  formik.errors.parent_name_furigana
                }
              />
              <Info>{t("(200文字以内)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Po box")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo
                iscenterAlign={
                  formik?.errors?.zip_code == "Please enter" &&
                  formik?.submitCount == 1
                }
              >
                <TextField
                  name={"zip_code"}
                  value={formik.values.zip_code}
                  onChange={({ target: { value } }) => {
                    formik.setFieldValue(
                      "zip_code",
                      allowOnlyNumberAndHyphen(value)
                    )
                  }}
                  maxLength={8}
                  placeholder={t("例：000-0000")}
                  className={getErrorClass("zip_code", "input")}
                  error={formik.touched.zip_code && formik.errors.zip_code}
                />
                <Info className={"info"}>
                  {t(
                    "(Half-width numbers and hyphens (-) within 8 characters)"
                  )}
                </Info>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={setAddressFromPostCode}
                  className={"cancel-btn"}
                  text={t("Autofill address")}
                  disabled={!formik.values.zip_code}
                />
              </HorizontalInfo>
              <Info style={{ textDecoration: "underline", color: "#0782C8" }}>
                <a
                  href={"https://www.post.japanpost.jp/zipcode/"}
                  target={"_blank"}
                >
                  {t("※If you don't know your pobox,search here")}
                </a>
              </Info>
            </Grid>
            <Grid className={"flex-break"} />

            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Prefecture")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <SelectInput
                options={PREFECTURE_OPTIONS}
                height={40}
                width={200}
                onChange={(e) => {
                  formik.setFieldValue("pref_id", e)
                }}
                className={getErrorClass("pref_id", "input")}
                value={formik.values.pref_id}
              />

              {formik.touched.pref_id && formik.errors.pref_id && (
                <ErrorMessage>{String(formik.errors.pref_id)}</ErrorMessage>
              )}
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Municipality/Address")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"address"}
                className={getErrorClass("address", "input")}
                value={formik.values.address}
                onChange={formik.handleChange}
                placeholder={t("例：□□市△△番地　1-23")}
                maxLength={200}
                error={formik.touched.address && formik.errors.address}
              />
              <Info>{t("(200文字以内)")}</Info>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Phone number")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo
                iscenterAlign={
                  formik?.errors?.tel_no == "Please enter" &&
                  formik?.submitCount == 1
                }
              >
                <TextField
                  name={"tel_no"}
                  value={formik.values.tel_no}
                  onChange={({ target: { value } }) => {
                    formik.setFieldValue(
                      "tel_no",
                      convertToHalfWidth(allowOnlyNumberAndHyphen(value))
                    )
                  }}
                  maxLength={30}
                  placeholder={t("例：012-345-6789")}
                  className={getErrorClass("tel_no", "input")}
                  error={formik.touched.tel_no && formik.errors.tel_no}
                />
                <Info className={"info"}>
                  {t(
                    "(Half-width numbers and hyphens (-) within 30 characters)"
                  )}
                </Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Child")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Info>
                {t("Register from 'child information' of 'child management'")}
              </Info>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Relation with child")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <HorizontalInfo>
                <TextField
                  name={"child_reretion"}
                  value={formik.values.child_reretion}
                  onChange={formik.handleChange}
                  maxLength={30}
                  placeholder={t("例：父親")}
                  className={"half-field"}
                  error={
                    formik.touched.child_reretion &&
                    formik.errors.child_reretion
                  }
                />
                <Info className={"info"}></Info>
              </HorizontalInfo>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header field-label-container"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Remarks")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"parent_remaks"}
                value={formik.values.parent_remaks}
                onChange={formik.handleChange}
                maxLength={200}
                type={"textarea"}
                rows={3}
                placeholder={t(
                  "例：10時～15時の間は連絡が付きにくいため、緊急連絡先1に連絡すること"
                )}
                error={
                  formik.touched.parent_remaks && formik.errors.parent_remaks
                }
              />
            </Grid>
            <Grid className={"flex-break"} />
            <FormTitle className={"form-title"}>
              {t("Emergency contact list")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact \nphone number 1")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"emergency_tel_no1"}
                    value={formik.values.emergency_tel_no1}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue(
                        "emergency_tel_no1",
                        convertToHalfWidth(allowOnlyNumberAndHyphen(value))
                      )
                    }}
                    maxLength={30}
                    placeholder={t("例：012-345-6789")}
                    className={"half-field"}
                    error={
                      formik.touched.emergency_tel_no1 &&
                      formik.errors.emergency_tel_no1
                    }
                  />
                  <Info className={"info"}>
                    {t(
                      "(Half-width numbers and hyphens (-) within 30 characters)"
                    )}
                  </Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact address 1")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"emergency_contact1"}
                  value={formik.values.emergency_contact1}
                  onChange={formik.handleChange}
                  placeholder={t("例：株式会社○○（母親 勤務先）")}
                  maxLength={200}
                  error={
                    formik.touched.emergency_contact1 &&
                    formik.errors.emergency_contact1
                  }
                />
                <Info>{t("(200文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact \nphone number 2")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"emergency_tel_no2"}
                    value={formik.values.emergency_tel_no2}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue(
                        "emergency_tel_no2",
                        convertToHalfWidth(allowOnlyNumberAndHyphen(value))
                      )
                    }}
                    maxLength={30}
                    placeholder={t("例：012-345-6789")}
                    className={"half-field"}
                    error={
                      formik.touched.emergency_tel_no2 &&
                      formik.errors.emergency_tel_no2
                    }
                  />
                  <Info className={"info"}>
                    {t(
                      "(Half-width numbers and hyphens (-) within 30 characters)"
                    )}
                  </Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact address 2")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"emergency_contact2"}
                  value={formik.values.emergency_contact2}
                  onChange={formik.handleChange}
                  placeholder={t("Example: Kazuko Suzuki (grandmother)")}
                  maxLength={200}
                  error={
                    formik.touched.emergency_contact2 &&
                    formik.errors.emergency_contact2
                  }
                />
                <Info>{t("(200文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Emergency contact remarks")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"emergency_remarks"}
                  value={formik.values.emergency_remarks}
                  onChange={formik.handleChange}
                  maxLength={200}
                  type={"textarea"}
                  rows={3}
                  placeholder={t(
                    "例：両親ともに連絡が付かなかったときのみ、緊急連絡先2に連絡すること"
                  )}
                  error={
                    formik.touched.emergency_remarks &&
                    formik.errors.emergency_remarks
                  }
                />
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle className={"form-title"}>
              {t("Account information")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Financial institution name kana")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"financial_institution_name"}
                    value={formik.values.financial_institution_name}
                    onChange={formik.handleChange}
                    maxLength={15}
                    placeholder={t("例：ハグ")}
                    className={"quater-field"}
                    error={
                      formik.touched.financial_institution_name &&
                      formik.errors.financial_institution_name
                    }
                  />
                  <Info className={"info"}>{t("(15文字以内)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid
                className={"grid-header field-label-container border-top"}
                background
              >
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Branch name Kana")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"branch_name_katakana"}
                    value={formik.values.branch_name_katakana}
                    onChange={formik.handleChange}
                    maxLength={15}
                    placeholder={t("例：カナヤマシテン")}
                    className={"quater-field"}
                    error={
                      formik.touched.branch_name_katakana &&
                      formik.errors.branch_name_katakana
                    }
                  />
                  <Info className={"info"}>{t("(15文字以内)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Financial institute code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"bank_no"}
                    value={formik.values.bank_no}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue(
                        "bank_no",
                        allowOnlyNumberAndHyphen(value)
                      )
                    }}
                    maxLength={4}
                    placeholder={t("例：1234")}
                    className={"half-field"}
                  />
                  <Info className={"info"}>{t("(半角数字4文字以内)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Branch code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"branch_no"}
                    value={formik.values.branch_no}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue(
                        "branch_no",
                        allowOnlyNumberAndHyphen(value)
                      )
                    }}
                    maxLength={3}
                    placeholder={t("例：567")}
                    className={"half-field"}
                  />
                  <Info className={"info"}>{t("(半角数字3文字以内)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account type")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <SelectInput
                  options={ACCOUNT_TYPE}
                  height={40}
                  width={200}
                  onChange={(e) => formik.setFieldValue("account_type", e)}
                  className={"select"}
                  value={
                    formik.values.account_type === 0
                      ? ""
                      : formik.values.account_type
                  }
                />
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account number")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <HorizontalInfo>
                  <TextField
                    name={"account_number"}
                    value={formik.values.account_number}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue(
                        "account_number",
                        allowOnlyNumberAndHyphen(value)
                      )
                    }}
                    maxLength={7}
                    placeholder={t("例：1234567")}
                    className={"half-field"}
                  />
                  <Info className={"info"}>{t("(半角数字7文字以内)")}</Info>
                </HorizontalInfo>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Account holder name")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"account_holder_name"}
                  value={formik.values.account_holder_name}
                  onChange={formik.handleChange}
                  placeholder={t("スズキタロウ")}
                  maxLength={50}
                />
                <Info>{t("(50文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Customer code")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"customer_number"}
                  value={formik.values.customer_number}
                  onChange={formik.handleChange}
                  placeholder={t("1234567890")}
                  maxLength={20}
                />
                <Info>{t("(半角英数字20文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
            </StyledForm>
            <FormTitle className={"form-title"}>
              {t("Email notification setting")}
            </FormTitle>
            <Divisor />
            <StyledForm>
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>{t("Email Address")}</div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"mail_address"}
                  type={"email"}
                  value={formik.values.mail_address}
                  onChange={formik.handleChange}
                  placeholder={t("例：info@example.com")}
                  maxLength={100}
                  error={
                    formik.touched.mail_address && formik.errors.mail_address
                  }
                />
                <Info>{t("(100文字以内)")}</Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Email address(If multiple)")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <TextField
                  name={"mail_address_multi"}
                  value={formik.values.mail_address_multi}
                  onChange={formik.handleChange}
                  placeholder={t("例：info1@example.com,info2@example.com")}
                  maxLength={100}
                  error={
                    formik.touched.mail_address_multi &&
                    formik.errors.mail_address_multi
                  }
                />
                <Info>
                  {t(
                    "If you want to register more than one email address, separate each email address with a comma (,). (within 100 characters)"
                  )}
                </Info>
              </Grid>
              <Grid className={"flex-break"} />
              <Grid className={"grid-header field-label-container"} background>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {t("Email notification settings")}
                  </div>
                </div>
              </Grid>
              <Grid className={"flex-full grid-row-last"}>
                <CheckboxWrapper>
                  <Checkbox
                    name={"mail_receive_noti_enter_flag"}
                    checked={formik.values.mail_receive_noti_enter_flag}
                    onChange={formik.handleChange}
                    label={t("Enter")}
                  />
                  <Checkbox
                    name={"mail_receive_noti_leave_flag"}
                    checked={formik.values.mail_receive_noti_leave_flag}
                    onChange={formik.handleChange}
                    label={t("Exit")}
                  />
                  <Checkbox
                    name={"mail_receive_noti_pick_flag"}
                    checked={formik.values.mail_receive_noti_pick_flag}
                    onChange={formik.handleChange}
                    label={t("Greet")}
                  />

                  <Checkbox
                    name={"mail_receive_noti_up_flag"}
                    checked={formik.values.mail_receive_noti_up_flag}
                    onChange={formik.handleChange}
                    label={t("Pick off")}
                  />
                </CheckboxWrapper>
                <Info style={{ color: "#E00000" }}>
                  {t(
                    "*Email notification settings cannot be used unless an automatic reply email is set. Check your auto-reply email if email notifications aren't available please."
                  )}
                </Info>
                <div className={"button-wrap"}>
                  <OwnerButton
                    typeOf={"secondary"}
                    className={"cancel-btn"}
                    text={t("Set automatic emails")}
                    onClick={() => window.open("/facility", "_blank")}
                  />
                </div>
              </Grid>

              <Grid className={"flex-break"} />
            </StyledForm>
            <div className={"child-registration-buttons"}>
              <div className={"button-wrapper"}>
                <OwnerButton
                  typeOf={"secondary"}
                  onClick={() => router.push("/parents")}
                  className={"cancel-btn"}
                  text={t("Cancel")}
                />

                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isLoading}
                />
              </div>

              {hasParentDeleteAccess &&
                id !== undefined &&
                parentData?.hasNoChild && (
                  <Popconfirm
                    title={`${t("Data will be deleted")} ${t(
                      'Please press the "delete execution" button if you like.'
                    )}`}
                    onConfirm={() => deleteOneParent(id?.toString())}
                    okText={t("Execute delete")}
                    cancelText={t("Cancel")}
                    placement={"bottomLeft"}
                  >
                    <OwnerButton
                      text={t("Delete")}
                      className={"cancel-btn"}
                      typeOf={"outlined"}
                      icon={"close"}
                      isLoading={isDeleting}
                    />
                  </Popconfirm>
                )}
            </div>
          </StyledForm>
        )}
      </PageCard>
    </Wrapper>
  )
}

export { ParentManagementForm }
