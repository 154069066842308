import { t } from "i18next"
import { DefaultProps } from "./types"
import { NBLManagementCompanyTop } from "../../PrintListContent.style"
import { Table } from "@project/shared"
import {
  ReceiptSiblingUpperLimitManagementItem,
  SiblingChild,
} from "../../../../types"
import { Content } from "./utils"

/**
 * ======================
 * Constans
 * ======================
 */

enum FirstLabel {
  TITLE_INDEX,
}

enum SecondLabel {
  LINE_NO_INDEX,
  CHILD_NO_INDEX,
  FACILITY_NO_INDEX,
  FACILITY_NAME_INDEX,
  TOTAL_AMOUNT_INDEX,
  INSURANCE_AMOUNT_INDEX,
  COUNTRY_BASIS_INDEX,
  COUNTRY_BASIS_EMPTY_INDEX,
  CITY_BASIS_INDEX,
  CITY_BASIS_EMPTY_INDEX,
}

enum ThirdLabel {
  START_INDEX = 6,
}

enum BodyRow {
  LINE_NO_INDEX,
  CHILD_NO_INDEX,
  FACILITY_NO_INDEX,
  FACILITY_NAME_INDEX,
  TOTAL_AMOUNT_INDEX,
  INSURANCE_AMOUNT_INDEX,
  BEFORE_BURDEN_AMOUNT_INDEX,
  MANAGE_BURDEN_AMOUNT_INDEX,
  LOCAL_FOOD_EXPENSE_INDEX,
  LOCAL_MANAGE_BURDEN_AMOUNT_INDEX,
}

enum SummaryColumn {
  TOTAL_LABEL_INDEX = 0,
  TOTAL_AMOUNT_INDEX = 4,
  TOTAL_INSURANCE_AMOUNT_INDEX = 5,
  TOTAL_BEFORE_BURDEN_AMOUNT_INDEX = 6,
  MANAGE_BURDEN_AMOUNT_INDEX = 7,
  TOTAL_LOCAL_FOOD_EXPENSE_INDEX = 8,
  LOCAL_MANAGE_BURDEN_AMOUNT_INDEX = 9,
}

const CIRCLE_NUMBERS = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
}

const LABELS = [
  {
    label: "項番",
  },
  {
    label: "児童番号",
  },
  {
    label: "事業所番号",
  },
  {
    label: "事業所名称",
  },
  {
    label: "総費用額",
  },
  {
    label: "給付費負担額",
  },
  {
    label: "国基準",
    label2: "給付率に応じた利用者負担額",
  },
  {
    label2: "管理後利用者負担額",
  },
  {
    label: "市基準",
    label2: "食費等",
  },
  {
    label2: "管理後利用者負担額",
  },
]

const FIRST_COLUMN = {
  width: 20,
  render: (_, __, index: number) => {
    if (index === FirstLabel.TITLE_INDEX) {
      return t("User burden amount aggregation / adjustment column")
    }
    return {}
  },
  onCell: (_, index: number) => {
    if (index === FirstLabel.TITLE_INDEX) {
      return { rowSpan: 10 }
    }
    return { colSpan: 0 }
  },
}

const SECOND_COLUMN = {
  dataIndex: "label",
  align: "center",
  width: 20,
  onCell: (_, index: number) => {
    if (
      index === SecondLabel.COUNTRY_BASIS_INDEX ||
      index === SecondLabel.CITY_BASIS_INDEX
    ) {
      return { rowSpan: 2 }
    }
    if (
      index === SecondLabel.COUNTRY_BASIS_EMPTY_INDEX ||
      index === SecondLabel.CITY_BASIS_EMPTY_INDEX
    ) {
      return { rowSpan: 0 }
    }
    return { colSpan: 2 }
  },
}

const THIRD_COLUMN = {
  dataIndex: "label2",
  align: "center",
  width: 200,
  onCell: (_, index: number) => {
    if (index < ThirdLabel.START_INDEX) {
      return { rowSpan: 0 }
    }
    return {}
  },
}

const EMPTY_FACILITY = {
  line_no: "",
  receiving_certificate_number: "",
  facility_number: "",
  facility_name: "",
  total_amount: "",
  insurance_amount: "",
  burden_amount: "",
  management_result_burden_amount: "",
  local_burden_amount: "",
}

/**
 * ======================
 * Utils
 * ======================
 */

function generateColumns(
  facilities: ReceiptSiblingUpperLimitManagementItem[],
  childNumbers: Map<string, string>
) {
  return facilities.map((facility) => ({
    align: "center",
    width: "160px",
    render: (_, __, index: number) => {
      if (index === BodyRow.LINE_NO_INDEX) {
        return facility.line_no
      }

      if (index === BodyRow.CHILD_NO_INDEX) {
        return childNumbers.get(facility.receiving_certificate_number)
      }

      if (index === BodyRow.FACILITY_NO_INDEX) {
        return facility.facility_number
      }

      if (index === BodyRow.FACILITY_NAME_INDEX) {
        return facility.facility_name
      }

      if (index === BodyRow.TOTAL_AMOUNT_INDEX) {
        return <Content data={String(facility.total_amount).padStart(6, ".")} />
      }

      if (index === BodyRow.INSURANCE_AMOUNT_INDEX) {
        return (
          <Content data={String(facility.insurance_amount).padStart(6, ".")} />
        )
      }

      if (index === BodyRow.BEFORE_BURDEN_AMOUNT_INDEX) {
        return (
          <Content data={String(facility.burden_amount).padStart(6, ".")} />
        )
      }

      if (index === BodyRow.MANAGE_BURDEN_AMOUNT_INDEX) {
        return (
          <Content
            data={String(facility.management_result_burden_amount).padStart(
              6,
              "."
            )}
          />
        )
      }

      if (index === BodyRow.LOCAL_FOOD_EXPENSE_INDEX) {
        return (
          <Content data={String(facility.line_no ? 0 : "").padStart(6, ".")} />
        )
      }

      if (index === BodyRow.LOCAL_MANAGE_BURDEN_AMOUNT_INDEX) {
        return (
          <Content
            data={String(facility.local_burden_amount).padStart(6, ".")}
          />
        )
      }
    },
  }))
}

function totalColumn(
  totalAmount: number,
  totalInsuranceAmount: number,
  totalBurdenAmount: number,
  managementResultBurdenAmount: number,
  localManagementResultBurdenAmount: number
) {
  return {
    align: "center",
    width: "160px",
    render: (_, __, index: number) => {
      if (index === SummaryColumn.TOTAL_LABEL_INDEX) {
        return t("Total")
      }

      if (index === SummaryColumn.TOTAL_AMOUNT_INDEX) {
        return <Content data={String(totalAmount).padStart(6, ".")} />
      }

      if (index === SummaryColumn.TOTAL_INSURANCE_AMOUNT_INDEX) {
        return <Content data={String(totalInsuranceAmount).padStart(6, ".")} />
      }

      if (index === SummaryColumn.TOTAL_BEFORE_BURDEN_AMOUNT_INDEX) {
        return <Content data={String(totalBurdenAmount).padStart(6, ".")} />
      }

      if (index === SummaryColumn.MANAGE_BURDEN_AMOUNT_INDEX) {
        return (
          <Content
            data={String(managementResultBurdenAmount).padStart(6, ".")}
          />
        )
      }

      if (index === SummaryColumn.TOTAL_LOCAL_FOOD_EXPENSE_INDEX) {
        return <Content data={String(0).padStart(6, ".")} />
      }

      if (index === SummaryColumn.LOCAL_MANAGE_BURDEN_AMOUNT_INDEX) {
        return (
          <Content
            data={String(localManagementResultBurdenAmount).padStart(6, ".")}
          />
        )
      }
      return ""
    },
    onCell: (_, index: number) => {
      if (index === SummaryColumn.TOTAL_LABEL_INDEX) {
        return { rowSpan: 4 }
      }
      if (index < SummaryColumn.TOTAL_AMOUNT_INDEX) {
        return { rowSpan: 0 }
      }
      return {}
    },
  }
}

/**
 * =======================
 * Main component
 * =======================
 */

type Props = DefaultProps & {
  siblingChildren: SiblingChild[]
  totalInsuranceAmount: number
  localManagementResultBurdenAmount: number
}

export function KyotoFormat({
  facilities,
  siblingChildren,
  totalAmount,
  totalBurdenAmount,
  managementResultBurdenAmount,
  totalInsuranceAmount,
  localManagementResultBurdenAmount,
}: Props) {
  const UPPER_FACILITY_COUNT = 6
  const LOWER_FACILITY_COUNT = 5

  let upperFacilities = facilities.slice(0, UPPER_FACILITY_COUNT)
  const upperEmptyLength = UPPER_FACILITY_COUNT - upperFacilities.length
  if (upperEmptyLength > 0) {
    upperFacilities = [
      ...upperFacilities,
      ...Array(upperEmptyLength).fill(EMPTY_FACILITY),
    ]
  }

  let lowerFacilities = facilities.slice(
    UPPER_FACILITY_COUNT,
    UPPER_FACILITY_COUNT + LOWER_FACILITY_COUNT
  )
  const lowerEmptyLength = LOWER_FACILITY_COUNT - lowerFacilities.length
  if (lowerEmptyLength > 0) {
    lowerFacilities = [
      ...lowerFacilities,
      ...Array(lowerEmptyLength).fill(EMPTY_FACILITY),
    ]
  }

  const childNumbers = new Map<string, string>()
  siblingChildren.forEach((child, index) => {
    childNumbers.set(
      child.receiving_certificate_number,
      CIRCLE_NUMBERS[index + 1]
    )
  })

  return (
    <>
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              FIRST_COLUMN,
              SECOND_COLUMN,
              THIRD_COLUMN,
              ...generateColumns(upperFacilities, childNumbers),
            ]}
            pagination={false}
            dataSource={LABELS}
          />
        </div>
      </NBLManagementCompanyTop>
      <NBLManagementCompanyTop>
        <div className={"NBLManagementCompanyTop"}>
          <Table
            showHeader={false}
            columns={[
              FIRST_COLUMN,
              SECOND_COLUMN,
              THIRD_COLUMN,
              ...generateColumns(lowerFacilities, childNumbers),
              totalColumn(
                totalAmount,
                totalInsuranceAmount,
                totalBurdenAmount,
                managementResultBurdenAmount,
                localManagementResultBurdenAmount
              ),
            ]}
            pagination={false}
            dataSource={LABELS}
          />
        </div>
      </NBLManagementCompanyTop>
    </>
  )
}
