import { Checkboxes, SelectInput, TopRoundedWhiteCard } from "@project/shared"
import { FilterOperationContainer, FilterOperationContent } from "../styles"
import { useTranslation } from "react-i18next"
import { OperationFieldWrapper } from "../../../molecules"
import { OwnerButton } from "../../../atoms"
import { PRINT_CONFIGURATION_OPTIONS, PRINT_STYLE_OPTIONS } from "../constants"
import { useState } from "react"
import { PrintFilter } from "../types"

export const PrintFilterBlock = ({
  initialState,
  onDisplayChange,
}: PrintFilter) => {
  const { t } = useTranslation()

  const [displaySetting, setDisplaySetting] = useState(initialState)

  return (
    <FilterOperationContainer className={"no-print"}>
      <TopRoundedWhiteCard
        className={"operation-rounded-container"}
        title={t("Operation options")}
      >
        <FilterOperationContent className={"filter-operation-content flexbox"}>
          <OperationFieldWrapper label={t("Print configuration")}>
            <Checkboxes
              options={PRINT_CONFIGURATION_OPTIONS}
              value={displaySetting.configuration}
              onChange={(val) =>
                setDisplaySetting((prev) => ({ ...prev, configuration: val }))
              }
            />
          </OperationFieldWrapper>

          <OperationFieldWrapper label={t("Select print style")}>
            <SelectInput
              options={PRINT_STYLE_OPTIONS}
              value={displaySetting.style}
              onChange={(val) =>
                setDisplaySetting((prev) => ({ ...prev, style: val }))
              }
              width={"200px"}
            />
          </OperationFieldWrapper>

          <div className={"form-button-group flexbox"}>
            <OwnerButton
              text={t("Change display")}
              typeOf={"primary"}
              className={"search-button btn"}
              onClick={() => onDisplayChange(displaySetting)}
            />
          </div>
        </FilterOperationContent>
      </TopRoundedWhiteCard>
    </FilterOperationContainer>
  )
}
