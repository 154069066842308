import { theme } from "@project/shared"
import { Layout } from "antd"
import styled from "styled-components"
const { Footer: AntFooter } = Layout

const FooterStyled = styled(AntFooter)`
  a {
    color: ${theme.blue5};
  }
  &.faculty-dashboard-footer {
    height: 4rem;
    background: #ffffff;
    display: flex;
    padding: 24px;
    font-size: 12px;
    justify-content: space-between;
    text-align: center;
    .line-break {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    @media print {
      display: none !important;
      padding: 0px;
      height: 0px;
    }
  }
  .faculty-dashboard-footer-info-highlight-blue {
    color: #0a82c8;
    font-size: inherit;
  }

  .hide-dot-on-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .faculty-dashboard-footer-copyright {
    font-size: clamp(0.75rem, 0.5115rem + 1.0178vw, 1rem);
  }
  @media (max-width: 768px) {
    &.faculty-dashboard-footer {
      flex-direction: column;
    }
    .faculty-dashboard-footer-info {
      text-align: center;
      font-size: inherit !important;
    }
    .faculty-dashboard-footer-copyright {
      margin-top: 10px;
      padding-bottom: 1rem;
    }
  }
`

const Footer = () => {
  return (
    <FooterStyled className={"faculty-dashboard-footer"}>
      <div>
        {"問い合わせ先: "}
        <a href={"mailto:easyy-support@onelife.co.jp"}>
          {"easyy-support@onelife.co.jp"}
        </a>
      </div>
      <div className={"faculty-dashboard-footer-copyright"}>
        {"Copyright "}
        {"©"}
        {" Onelife.Inc"}
      </div>
    </FooterStyled>
  )
}

export { Footer }
