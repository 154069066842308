import {
  Checkboxes,
  Grid,
  SelectInput,
  TextField,
  theme,
} from "@project/shared"
import { notification, Popconfirm, Select, Typography } from "antd"
import * as Yup from "yup"
import { FieldArray, Formik, getIn } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { OwnerButton, Tag } from "../../atoms"
import { FormContainer, PriceInputContent } from "./ActualCostItemForm.styles"
import { useMutation } from "react-query"
import { useRouter } from "next/router"
import { saveActualCost, updateActualCost } from "../../../services/actualCost"
import {
  AuthContext,
  allowOnlyNumber,
  scrollToFirstErrorField,
} from "../../../utils"

interface Iprops {
  defaultValues?: any
  edit?: boolean
  id?: string | string[]
  handleDelete?: () => void
  showDeleteButton?: boolean
  isDeleteLoading?: boolean
}
export const ActualCostItemForm: React.FC<Iprops> = ({
  edit,
  id,
  defaultValues,
  handleDelete,
  showDeleteButton = false,
  isDeleteLoading = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const [prefId, setPrefId] = useState(null)

  const facilityItem = {
    value: -1,
    id: -1,
    label: t("Select all facilities"),
    facility_name_short: t("Select all facilities"),
    pref_id: 3,
  }

  const facilitiesOption = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
      pref_id: item?.pref_id && item?.pref_id2 ? 3 : item?.pref_id,
    }
  })

  const { isLoading: isCreating, mutate: saveActualCostItem } = useMutation(
    saveActualCost,
    {
      onSuccess: () => {
        notification.success({
          message: "実費項目情報を追加しました",
        })
        router.push("/actual-cost-items")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { isLoading: isUpdating, mutate: editActualCostItem } = useMutation(
    updateActualCost,
    {
      onSuccess: () => {
        notification.success({
          message: "実費項目情報を更新しました",
        })

        router.push("/actual-cost-items")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  const selectedFacilityPrefId = (id: number, data: any) => {
    const pref_id = data?.find((val) => val?.value === id)?.pref_id
    setPrefId(pref_id)

    switch (pref_id) {
      case 3:
        return [
          {
            label: t("After school day service"),
            value: "1",
          },
          {
            label: t("Child Development Support"),
            value: "2",
          },
        ]
      case 2:
        return [
          {
            label: t("Child Development Support"),
            value: "2",
          },
        ]
      case 1:
        return [
          {
            label: t("After school day service"),
            value: "1",
          },
        ]
    }
  }

  return (
    <Formik
      initialValues={
        (defaultValues !== undefined && {
          ...defaultValues,
          billing_using_facility: defaultValues?.billing_using_facility?.map(
            (v) => {
              return {
                facility_id: v?.FacilityId || 0,
                service_type:
                  v?.ServiceType && v?.ServiceType2
                    ? [String(v?.ServiceType), String(v?.ServiceType2)]
                    : String(v?.ServiceType) || 0 || ["1", "2"],
                index: v?.index,
              }
            }
          ),
        }) || {
          actual_cost_name: "",
          facility_use_invoice_flg: false,
          price: null,
          billing_using_facility: [
            {
              facility_id: null,
              service_type: "" || ["1", "2"] || [],
              index: 0,
            },
          ],
        }
      }
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        actual_cost_name: Yup.string()
          .required(t("Required"))
          .max(50, t("Please enter number within 50 characters.")),
        facility_use_invoice_flg: Yup.boolean().required(),
        facility_id: Yup.string(),
        billing_using_facility: Yup.array().of(
          Yup.object().shape({
            facility_id: Yup.number()
              .typeError(t("Required"))
              .nullable()
              .test(
                "condition-required-test",
                t("Required"),
                (value, context: Yup.TestContext) => {
                  const contextGeneric = context as unknown as {
                    from: { value: Record<string, unknown> }[]
                  }
                  const { facility_use_invoice_flg } =
                    contextGeneric.from[1].value
                  return facility_use_invoice_flg === true
                    ? value !== null
                    : true
                }
              ),
          })
        ),
        price: Yup.string()
          .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "not valid"
          )
          .nullable()
          .when(
            "facility_use_invoice_flg",
            (facility_use_invoice_flg: boolean) => {
              if (facility_use_invoice_flg === true)
                return Yup.number()
                  .nullable()
                  .lessThan(1000000, t("Within 6 numbers"))
                  .required(t("Required"))
            }
          ),
      })}
      onSubmit={(values) => {
        const requestData = {
          actual_cost_name: values?.actual_cost_name,
          price: Number(values?.price),
          facility_use_invoice_flg: values?.facility_use_invoice_flg,
          billing_using_facility:
            values.facility_use_invoice_flg === true
              ? values?.billing_using_facility.map((v) => {
                  return {
                    facility_id: Number(v?.facility_id) || 0,
                    service_type:
                      Array.isArray(v?.service_type) &&
                      v?.service_type?.length > 1
                        ? Number(v?.service_type[0])
                        : Number(v?.service_type) || 0,
                    service_type2:
                      Array.isArray(v?.service_type) &&
                      v?.service_type?.length > 1
                        ? Number(v?.service_type[1])
                        : 0,
                  }
                })
              : [],
        }
        if (edit) {
          const updateRequestBody = { ...requestData, id: Number(id) }
          editActualCostItem({ values: updateRequestBody, id: Number(id) })
        } else {
          saveActualCostItem(requestData)
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        setFieldValue,
        touched,
        handleSubmit,
        submitCount,
        isValid,
      }) => {
        const getErrorClass = (field, type = "input") => {
          if (submitCount === 0) return ""
          if (errors[field]) {
            switch (type) {
              case "input":
                return "has-error"
              case "container":
                return "has-error-container"
              case "nest":
                return "has-error-container-nest"
            }
          }
          return ""
        }
        useEffect(() => {
          if (submitCount == 0) return
          if (isValid) return
          scrollToFirstErrorField(errors)
        }, [submitCount, isValid])

        const getOptions = (key, idx) => {
          const d = values?.billing_using_facility
            ?.filter((v, i) => i !== idx)
            ?.map((val) => val?.facility_id)
          const filtered = facilitiesOption?.filter(
            (val) => !d?.includes(val?.value)
          )
          if (idx === 0) {
            return [facilityItem, ...filtered]
          }
          return filtered
        }
        return (
          <FormContainer onSubmit={handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label_tag"}>
                {t("Actual cost item name")}
                <Tag backgroundColor={theme.red2}>{"必須"}</Tag>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"actual_cost_name"}
                value={values?.actual_cost_name}
                className={getErrorClass("actual_cost_name", "input")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.actual_cost_name && errors.actual_cost_name}
                width={"100%"}
                maxLength={50}
                disableboxshadow
                bgcolor={"transparent"}
                placeholder={t("例：XXXXXX")}
              />
              <div className={"info"}>{t("(within 50 char)")}</div>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header title"} background>
              {t("Billing when used by facility")}
            </Grid>
            <Grid className={"flex-full grid-row-last"} padding={"0"}>
              <div className={"billing-facility-container"}>
                <div className={"select_facility"}>
                  <Select
                    placeholder={t("Select")}
                    className={"billing-facility"}
                    size={"large"}
                    style={{
                      width: "150px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    options={[
                      {
                        label: t("Do not"),
                        value: false,
                      },
                      {
                        label: t("Do"),
                        value: true,
                      },
                    ]}
                    onChange={(val) => {
                      if (val === 0) {
                        setFieldValue("price", null)
                        setFieldValue("facility_id", 0)
                        setFieldValue("service_type", 0)
                      }
                      setFieldValue("facility_use_invoice_flg", val)
                    }}
                    value={values?.facility_use_invoice_flg}
                  />
                </div>
                {values?.facility_use_invoice_flg == true && (
                  <FieldArray
                    name={"billing_using_facility"}
                    render={(arrayHelpers) => (
                      <>
                        {values?.billing_using_facility?.map(
                          (billingFacility, index) => (
                            <div
                              className={"bill-facility-select"}
                              key={index}
                              style={{
                                borderTop: `1px solid ${theme.bgColor2}`,
                              }}
                            >
                              <div className={"bill-facility-select-input"}>
                                <SelectInput
                                  placeholder={"---"}
                                  className={"billing-facility"}
                                  height={40}
                                  width={220}
                                  options={getOptions(
                                    `facilities_${index}`,
                                    index
                                  )}
                                  onChange={(val) => {
                                    const service = selectedFacilityPrefId(
                                      +val,
                                      getOptions(`facilities_${index}`, index)
                                    )
                                    setFieldValue(
                                      `billing_using_facility[${index}].facility_id`,
                                      val
                                    )

                                    setFieldValue(
                                      `billing_using_facility[${index}].service_type`,
                                      service?.length > 1
                                        ? ["1", "2"]
                                        : service[0]?.value
                                    )
                                  }}
                                  value={billingFacility?.facility_id || null}
                                  error={getIn(
                                    errors,
                                    `billing_using_facility[${index}].facility_id`
                                  )}
                                  name={`billing_using_facility[${index}].facility_id`}
                                />

                                {billingFacility?.facility_id && (
                                  <Checkboxes
                                    name={`billing_using_facility.${index}.service_type`}
                                    value={
                                      values?.billing_using_facility[index]
                                        .facility_id === -1
                                        ? values?.billing_using_facility[index]
                                            .service_type
                                        : values?.billing_using_facility[index]
                                            .service_type
                                    }
                                    onChange={(value) => {
                                      setFieldValue(
                                        `billing_using_facility[${index}].service_type`,
                                        value
                                      )
                                    }}
                                    options={selectedFacilityPrefId(
                                      billingFacility?.facility_id,
                                      getOptions(`facilities_${index}`, index)
                                    )}
                                  />
                                )}
                              </div>
                              <div className={"cta_btns"}>
                                {billingFacility?.facility_id &&
                                  values?.billing_using_facility?.length >
                                    1 && (
                                    <OwnerButton
                                      text={t("Delete ")}
                                      icon={"delete-outlined"}
                                      typeOf={"secondary"}
                                      type={"button"}
                                      onClick={() => {
                                        arrayHelpers.remove(index)
                                      }}
                                    />
                                  )}
                                {prefId !== 3 &&
                                  facilitiesOption?.length !==
                                    values?.billing_using_facility?.length && (
                                    <>
                                      {facilitiesOption?.length - 1 !== index &&
                                        billingFacility?.facility_id &&
                                        values?.billing_using_facility?.length -
                                          1 ===
                                          index && (
                                          <OwnerButton
                                            text={t("Add")}
                                            icon={"add"}
                                            typeOf={"secondary"}
                                            width={100}
                                            type={"button"}
                                            onClick={() => {
                                              arrayHelpers.push({
                                                facility_id: null,
                                                service_type: "",
                                                index:
                                                  billingFacility?.index + 1,
                                              })
                                            }}
                                          />
                                        )}
                                    </>
                                  )}
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  />
                )}
              </div>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header title"} background>
              <div className={"label_tag"}>
                {t("Price")}
                <Tag backgroundColor={theme.red2}>{"必須"}</Tag>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <PriceInputContent>
                <div>
                  <TextField
                    name={"price"}
                    value={values?.price}
                    className={getErrorClass("price", "input")}
                    onChange={({ target: { value } }) => {
                      value.length <= 6 &&
                        setFieldValue("price", allowOnlyNumber(value))
                    }}
                    onBlur={handleBlur}
                    width={"120px"}
                    min={0}
                    disableboxshadow
                    disabled={values?.facility_use_invoice_flg === false}
                    bgcolor={"transparent"}
                    error={touched.price && errors.price}
                  />
                  <Typography.Text>{"円"}</Typography.Text>
                </div>

                <Typography.Text className={"requried-length"}>
                  {t("Within 6 numbers")}
                </Typography.Text>
                <Typography.Text className={"info"}>
                  {t("※Only enter when facility is selected")}
                </Typography.Text>
              </PriceInputContent>
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"cost-item-button-wrapper"}>
              <div className={"cost-item-button-container"}>
                <OwnerButton
                  text={t("Cancel")}
                  typeOf={"secondary"}
                  type={"button"}
                  disabled={isCreating || isUpdating}
                  onClick={() => {
                    router.push("/actual-cost-items")
                  }}
                />
                <OwnerButton
                  type={"submit"}
                  typeOf={"primary"}
                  text={id ? t("Edit") : t("Save")}
                  isLoading={isCreating || isUpdating}
                />
              </div>

              {showDeleteButton ? (
                <>
                  <div className={"cost-item-button-container"}>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      onConfirm={handleDelete}
                      okText={"OK"}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <OwnerButton
                        text={t("Delete ")}
                        typeOf={"primary"}
                        className={"delete-button"}
                        isLoading={isDeleteLoading}
                      />
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </FormContainer>
        )
      }}
    </Formik>
  )
}
