// constants
import { divisionOptions } from "../constants"

export function addCommas(number) {
  const str = number?.toString()
  const parts = str?.split(".")
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

export const getDivisionLabel = (value: number) => {
  const division = divisionOptions.find((item) => item.value === value)
  return division?.label
}
