import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import {
  Button,
  getLocalstorageItem,
  isApril2024ChangeApplicable,
  setItemToLocalstorage,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { t } from "i18next"
import { useContext, useMemo, useState } from "react"
import styled from "styled-components"
import {
  AttendanceScheduleCalendarTable,
  AttendanceScheduleOperationOptions,
  OwnerButton,
  StaffMonthlyScheduleLinkAction,
} from "../.."
import { AuthContext } from "../../../utils"
import { useQuery } from "react-query"
import {
  fetchMonthlyStaffRecordsByFacility,
  getAllStaffList,
} from "../../../services/attendanceSchedule"
import { Spin } from "antd"
import { useRouter } from "next/router"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { StaffAttendanceRecordPermission } from "../../../utils/PermissionKeys"
import { getFacilitySchedules } from "../../../services/staffSchedule"
import moment from "moment"
import Link from "next/link"

const StyledLoader = styled(Spin)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const MainContent = styled.div`
  margin: 10px 0;

  .individual-layer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    column-gap: 20px;
    flex-wrap: wrap;
    &.attendance-schedule-header-upper-layer {
      @media (max-width: 637px) {
        flex-direction: column;
        align-items: flex-start;
        .pagination-buttons-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .pagination-buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    button {
      background: white;
      color: black;
      border: 1px solid #d2d1d1;
      border-radius: 10px;
      height: 40px;
      margin-right: 10px;
      padding: 0px;
      min-width: 75px;
      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
  }

  .work-record-edit-container {
    display: flex;
    grid-area: edit;
    align-items: center;
    gap: 8px;
    .select-containers {
      display: flex;
      gap: 12px;
    }
    .select-actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .print-button-container {
    @media (max-width: ${theme.breakpoints.md}) {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    button {
      background-color: ${theme.gray3} !important;
      border-color: ${theme.gray3} !important;
      padding: 8px;
    }
  }
  .occupations-container {
    margin: 20px 0;
    border: 1px solid #d2d1d1;
    border-radius: 12px;
    padding: 20px;
    @media print {
      page-break-inside: avoid;
      margin: 10px 0 0 0;
      padding: 10px;
    }
  }
  .occupation-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;
    .occupation-label-value-container {
      display: flex;
      align-items: center;
      margin-right: 16px;
      .occupation-label {
        margin-right: 4px;
        font-size: 14px;
        color: #ffffff;
        padding: 6px 10px;
        border-radius: 5px;
        text-align: center;
        min-width: 90px;
      }
      .occupation-value {
        font-size: 14px;
      }
    }
  }
  .occupation-row:last-child {
    margin-bottom: 0;
  }
  .staff-experience-alert {
    width: 100%;
    margin: 10px 0;
    padding: 20px;
    border: 1px solid ${theme.red3};
    border-radius: 10px;
    .alert-title {
      display: flex;
      gap: 5px;
    }
    .alert-links {
      display: flex;
      gap: 10px;
      .alert-link > a {
        text-decoration: underline;
        font-weight: normal;
      }
    }
    div {
      font-weight: bold;
      color: ${theme.red2};
    }
    @media print {
      display: none;
    }
  }
  .time-stat-section {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #d2d1d1;
    border-radius: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    .time-label-value {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
      .time-label {
        position: relative;
        background: #cde6f4;
        padding: 8px;
        margin-right: 20px;
        height: 40px;
      }
      .time-label::after {
        width: 15px;
        position: absolute;
        top: 0;
        right: -20px;
        content: "";
        border-left: 20px solid #cde6f4;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }
  }

  @media (max-width: 1366px) {
    .work-record-edit-container {
      flex-direction: column;
      .select-containers {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }
    .print-button-container {
      margin-top: 10px;
      margin-left: 0;
    }
    .time-label-value {
      margin-bottom: 5px;
    }
    .occupation-row {
      margin-bottom: 0px;
    }
    .occupation-label-value-container {
      margin-bottom: 10px;
    }
  }

  @media print {
    @page {
      margin: 0 !important;
      size: landscape;
    }
    th,
    td {
      padding: 0 2px !important;
      min-width: 0 !important;
      font-size: 10px !important;
      div,
      span a {
        font-size: 10px !important;
        padding: 0 !important;
        min-width: 0 !important;
      }
    }
    thead > tr:not(:first-child) {
      th {
        max-width: 20px !important;
      }
    }
    .section-not-to-display {
      display: none;
    }
    .work-work-container > div {
      padding: 0 !important;
    }
    .occupation-row {
      margin-bottom: 4px;
      gap: 4px;
    }
    .styled-link {
      line-height: 10px;
    }
  }
`

const occupations = [
  [
    {
      label: "管理者",
      value: "… 管理者",
      code: "#E00000",
    },
    {
      label: "児発管",
      value: "… 児童発達支援管理責任者",
      code: "#E26827",
    },
    {
      label: "嘱託医",
      value: "… 嘱託医",
      code: "#0782C8",
    },
  ],
  [
    {
      label: "保育士",
      value: "… 保育士",
      code: "rgba(226, 104, 39, 0.7)",
    },
    {
      label: "看護職員",
      value: "… 看護職員",
      code: "#4CB476",
    },
    {
      label: "理学療法士",
      value: "… 理学療法士",
      code: "rgba(76, 180, 118, 0.7)",
    },
    {
      label: "作業療法士",
      value: "… 作業療法士",
      code: "#1FCBDC",
    },
    {
      label: "言語聴覚士",
      value: "… 言語聴覚士",
      code: "rgba(7, 130, 200, 0.8)",
    },
  ],
  [
    {
      label: "心理指員等",
      value: "… 心理指導担当職員等",
      code: "rgba(76, 180, 118, 0.6)",
    },
  ],
  [
    {
      label: "公認心理師",
      value: "… 公認心理師",
      code: "rgba(7, 130, 200, 0.6)",
    },
    {
      label: "臨床心理士",
      value: "… 臨床心理士",
      code: "#888787",
    },
    {
      label: "柔道整復師",
      value: "… 柔道整復師",
      code: "rgba(136, 135, 135, 0.7)",
    },
    {
      label: "鍼灸師",
      value: "… 鍼灸師",
      code: "#AA8DF8",
    },
    {
      label: "あん摩師",
      value: "… あん摩マッサージ指圧師",
      code: "#79E3AA",
    },
  ],
  [
    {
      label: "児童指導員",
      value: "… 児童指導員",
      code: "#4DA2F8",
    },
    {
      label: "障害福祉",
      value: "… 障害福祉サービス経験者",
      code: "rgba(170, 141, 248, 0.8)",
    },
    {
      label: "指導員(他)",
      value: "… 指導員(その他)",
      code: "rgba(240, 48, 99, 0.7)",
    },
    {
      label: "訪問支援員",
      value: "… 訪問支援員",
      code: "rgba(77, 162, 248, 0.8)",
    },
    {
      label: "その他",
      value: "… その他",
      code: "#F03063",
    },
  ],
  [
    {
      label: "栄養士",
      value: "… 栄養士",
      code: "rgba(31, 203, 220, 0.6)",
    },
    {
      label: "調理員",
      value: "… 調理員",
      code: "rgba(136, 135, 135, 0.5)",
    },
  ],
]

export const AttendanceRecordTablePage = () => {
  const router = useRouter()
  const { facility } = router.query
  let activeFacility = null
  const { facilities, permissions, isOwner } = useContext(AuthContext)
  const storedData = getLocalstorageItem("staff-attendance", "facility")
  const FACILITY_OPTIONS = facilities.map((fac) => {
    const facilityOption = {
      label: fac.facility_name_short,
      value: fac.id,
    }
    if (facility && facility == fac.id) {
      activeFacility = facilityOption
    } else if (storedData && storedData == fac.id) {
      activeFacility = facilityOption
    }
    return facilityOption
  })
  const today = new Date()
  const [currentYear, setCurrentYear] = useState(
    router.query?.year ? +router.query?.year : today.getFullYear()
  )
  const [currentMonth, setCurrentMonth] = useState(
    router.query?.month ? +router.query?.month : today.getMonth() + 1
  )
  const [currentFacility, setCurrentFacility] = useState(
    activeFacility
      ? activeFacility
      : Array.isArray(FACILITY_OPTIONS) && FACILITY_OPTIONS.length > 0
      ? FACILITY_OPTIONS[0]
      : null
  )
  const [staffList, setStaffList] = useState(null)

  const { isLoading: staffListLoading } = useQuery(
    ["staffList", currentFacility],
    async () => {
      const staffData = await getAllStaffList(currentFacility?.value)
      if (staffData.data) {
        const staffOptions = []
        staffData.data.map((staff) => {
          let isStaffRetiredByTime = false
          if (staff?.retirement_date) {
            const staffRetirementDate = staff?.retirement_date
              ?.toString()
              ?.split("T")?.[0]
            const retiredDate = new Date(staffRetirementDate)
            const retiredYear = retiredDate.getFullYear()
            const retiredMonth = retiredDate.getMonth() + 1
            if (
              retiredYear < currentYear ||
              (retiredYear == currentYear && retiredMonth < currentMonth)
            )
              isStaffRetiredByTime = true
          }
          if (!isStaffRetiredByTime) {
            const staffObj = {
              key: staff?.id,
              label: staff?.staff_name,
              value: staff?.id,
              furigana_name: staff?.staff_name_furiganaame,
              facilities: staff?.staff_facility,
            }
            staffOptions.push(staffObj)
          }
        })
        setStaffList(staffOptions)
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const {
    isLoading: isRecordLoading,
    data: RecordResponse,
    refetch,
  } = useQuery(
    ["monthlySchedules", currentFacility, currentMonth, currentYear],
    async () => {
      const { data } = await fetchMonthlyStaffRecordsByFacility({
        facilityId: currentFacility?.value,
        year: currentYear,
        month: currentMonth,
      })
      return data
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )
  const onDisplayChange = ({ year, month, facility }) => {
    const queries = []
    if (year) {
      setCurrentYear(year)
      queries.push("year=" + year)
    }
    if (month) {
      setCurrentMonth(month)
      queries.push("month=" + month)
    }
    if (facility) {
      const newFacility = FACILITY_OPTIONS.find((fac) => fac.value == facility)
      if (newFacility) {
        setCurrentFacility(newFacility)
        queries.push("facility=" + newFacility?.value)
      }
      setItemToLocalstorage("staff-attendance", {
        facility: facility,
      })
    }
    router.push("?" + queries.join("&"), undefined, { shallow: true })
  }

  const hasEditAccess = useMemo(() => {
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        StaffAttendanceRecordPermission,
        "write",
        currentFacility?.value
      )
    )
  }, [isOwner, permissions, currentFacility])

  const { data: facilitySchedules } = useQuery(
    ["staffSfacilityShiftSchedules", currentFacility],
    async () => {
      return getFacilitySchedules(currentFacility?.value)
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      select: (response) => {
        return response?.data?.map((schedule) => {
          return {
            ...schedule,
            label: schedule.attendance_shift_name,
            value: schedule.id,
          }
        })
      },
    }
  )

  const aprilChangeApplicable = useMemo(() => {
    return isApril2024ChangeApplicable(
      moment().set({
        year: currentYear,
        month: currentMonth - 1,
        day: 1,
      })
    )
  }, [currentMonth, currentYear])

  const staffsWhoseExperienceYearIsntRegistered = useMemo(() => {
    return (
      RecordResponse?.staff_facility
        ?.filter((schedule) => !schedule.experience_year)
        .map((schedule) => ({
          staff_id: schedule?.staff_id,
          staff_name: schedule?.staff?.staff_name,
        })) || []
    )
  }, [RecordResponse])

  return (
    <MainContent>
      <div className={"section-not-to-display"}>
        <AttendanceScheduleOperationOptions
          facilityOptions={FACILITY_OPTIONS}
          handleDisplayChange={onDisplayChange}
          initialValues={{
            year: currentYear,
            month: currentMonth,
            facility: currentFacility?.value,
          }}
        />
      </div>
      {isRecordLoading ? (
        <StyledLoader size={"large"} />
      ) : (
        <div className={"work-record-container"}>
          <TopRoundedWhiteCard
            title={t("attendanceRecordTableTitle", {
              year: currentYear,
              month: currentMonth?.toString()?.padStart(2, "0"),
              facilityName: currentFacility ? currentFacility.label : "",
            })}
          >
            <div
              className={
                "attendance-schedule-table-header section-not-to-display"
              }
            >
              <div
                className={
                  "attendance-schedule-header-upper-layer individual-layer"
                }
              >
                {/* Work style list button starts */}
                <OwnerButton
                  text={t("Working style list")}
                  typeOf={"secondary"}
                  onClick={() => {
                    router.push(
                      `/work-style?year=${currentYear}&month=${currentMonth}&facility=${currentFacility.value}&tab=recordTable`
                    )
                  }}
                />
                {/* Work style list button ends */}

                {/* Pagination buttons starts here */}
                <div className={"pagination-buttons-container"}>
                  <Button
                    className={"left-btn"}
                    onClick={() => {
                      let year = currentYear
                      let month = currentMonth
                      if (month == 1) {
                        --year
                        month = 12
                      } else {
                        --month
                      }
                      onDisplayChange({
                        year,
                        month,
                        facility: currentFacility?.value,
                      })
                    }}
                  >
                    <DoubleLeftOutlined />
                    <span className={"left-btn-label"}>{t("Last month")}</span>
                  </Button>
                  <Button
                    className={"active active-btn"}
                    onClick={() => {
                      const year = new Date().getFullYear()
                      const month = new Date().getMonth() + 1
                      onDisplayChange({
                        year,
                        month,
                        facility: currentFacility?.value,
                      })
                    }}
                  >
                    {t("This month")}
                  </Button>
                  <Button
                    className={"right-btn"}
                    onClick={() => {
                      let year = currentYear
                      let month = currentMonth
                      if (month == 12) {
                        ++year
                        month = 1
                      } else {
                        ++month
                      }
                      onDisplayChange({
                        year,
                        month,
                        facility: currentFacility?.value,
                      })
                    }}
                  >
                    <span className={"right-btn-label"}>{t("Next month")}</span>
                    <DoubleRightOutlined />
                  </Button>
                </div>
                {/* Pagination buttons ends here */}
              </div>

              <div
                className={
                  "attendance-schedule-header-lower-layer individual-layer"
                }
              >
                <div className={"work-record-edit-container"}>
                  {!staffListLoading && (
                    <StaffMonthlyScheduleLinkAction
                      staffList={staffList}
                      facility={currentFacility}
                      year={currentYear}
                      month={currentMonth}
                      navigationLink={"staff-attendance/monthly-record"}
                      navigable={hasEditAccess}
                    />
                  )}
                </div>
                <div className={"print-button-container"}>
                  <OwnerButton
                    text={t("Print this")}
                    icon={"print"}
                    onClick={() => {
                      typeof window != "undefined" && window.print()
                    }}
                  />
                </div>
              </div>
            </div>
            <AttendanceScheduleCalendarTable
              year={currentYear}
              month={currentMonth}
              facility={currentFacility?.value}
              calendarData={RecordResponse}
              calendarType={"Record"}
              detailLink={"/staff-attendance/detail"}
              staffLink={"/staff-attendance/monthly-record"}
              refetch={refetch}
              editable={hasEditAccess}
              facilitySchedules={facilitySchedules || []}
              aprilChangeApplicable={aprilChangeApplicable}
            />
            <div className={"time-stat-section section-not-to-display"}>
              <div className={"time-label-value"}>
                <div className={"time-label"}>{t("1 day")}</div>
                <div className={"time-value"}>
                  {RecordResponse?.facility_work_hours?.day_hours ||
                  RecordResponse?.facility_work_hours?.day_hours
                    ? t("workHoursSetting", {
                        hour: RecordResponse?.facility_work_hours?.day_hours,
                        minute:
                          RecordResponse?.facility_work_hours?.day_minutes,
                      })
                    : t("No Setting")}
                </div>
              </div>
              <div className={"time-label-value"}>
                <div className={"time-label"}>{t("1 week")}</div>
                <div className={"time-value"}>
                  {RecordResponse?.facility_work_hours?.week_hours ||
                  RecordResponse?.facility_work_hours?.week_hours
                    ? t("workHoursSetting", {
                        hour: RecordResponse?.facility_work_hours?.week_hours,
                        minute:
                          RecordResponse?.facility_work_hours?.week_minutes,
                      })
                    : t("No Setting")}
                </div>
              </div>
            </div>
            {aprilChangeApplicable &&
              staffsWhoseExperienceYearIsntRegistered.length > 0 && (
                <div className={"staff-experience-alert"}>
                  <div className={"alert-title"}>
                    <img
                      src={"/assets/icons/exclamation.png"}
                      height={25}
                      width={25}
                      alt={""}
                    />
                    <div>{t("Please register your years of experience.")}</div>
                  </div>
                  <div className={"alert-body"}>
                    {t(
                      "From the link below, if you change the year and month to April 2024, the years of experience item will be displayed."
                    )}
                  </div>
                  <div className={"alert-links"}>
                    {staffsWhoseExperienceYearIsntRegistered.map((staff) => (
                      <div
                        className={"alert-link"}
                        key={`alert-staff-${staff?.staff_id}`}
                      >
                        <Link
                          href={`/instructors/edit/${staff?.staff_id}#affiliate_facilities`}
                        >
                          <a target={"_blank"}>{staff?.staff_name}</a>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            <div className={"occupations-container"}>
              {occupations.map((occupation, index) => (
                <div className={"occupation-row"} key={index}>
                  {occupation.map((occ) => (
                    <div
                      className={"occupation-label-value-container"}
                      key={occ.value}
                    >
                      <span
                        className={"occupation-label"}
                        style={{ background: occ.code }}
                      >
                        {occ.label}
                      </span>
                      <span className={"occupation-value"}>{occ.value}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </TopRoundedWhiteCard>
        </div>
      )}
    </MainContent>
  )
}
