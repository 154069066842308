import { theme } from "@project/shared"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { t } from "i18next"
import React, { useMemo } from "react"
import styled from "styled-components"

interface IChildMonitoringContentProps {
  data?: any
  handleFieldChange?: (a: any) => void
  align?: string
}

const TableWrapper = styled.div`
  th,
  td {
    border: 1px solid ${theme.gray2};
    border-right: none;
  }
  th:last-child,
  td:last-child {
    border-right: 1px solid ${theme.gray2};
  }
`

const ChildMonitoringDetailTable: React.FC<IChildMonitoringContentProps> = ({
  data,
}) => {
  const COLUMN_DEFINATION: ColumnsType<IChildMonitoringContentProps> = useMemo(
    () => [
      {
        title: "",
        dataIndex: "",
        render: (_, __, index) => {
          return <p>{index + 1}</p>
        },
        width: 60,
      },
      {
        title: t("Items (personal needs,etc.)"),
        dataIndex: "",
        align: "center",
        render: () => {
          return <p>{"社会性"}</p>
        },
        width: 180,
      },
      {
        title: t("specific goals"),
        dataIndex: "",
        align: "center",
        render: () => {
          return (
            <p>
              {
                "嫌なこと、苦手なことがあっても、しっかり毎日通所し、経験を積み重ね、克服できるようになる。"
              }
            </p>
          )
        },
        width: 160,
      },
      {
        title: t("Goal achievement"),
        dataIndex: "achievement_goal_type",
        align: "center",
        render: () => {
          return <p>{""}</p>
        },
        width: 80,
      },
      {
        title: t("Evaluation"),
        dataIndex: "evaluation_type",
        align: "center",
        render: () => {
          return <p>{""}</p>
        },
        width: 80,
      },
      {
        title: t("Consideration"),
        dataIndex: "study",
        align: "center",
        render: () => {
          return <p>{""}</p>
        },
        width: 280,
      },
    ],
    [data]
  )

  return (
    <TableWrapper>
      <Table
        columns={COLUMN_DEFINATION}
        dataSource={data}
        pagination={false}
        scroll={{ x: "1000px" }}
      />
    </TableWrapper>
  )
}

export { ChildMonitoringDetailTable }
