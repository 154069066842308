import { theme } from "@project/shared"
import styled from "styled-components"

const Footer = styled.footer`
  a {
    color: ${theme.blue5};
  }

  display: flex;
  background: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  min-height: auto;
  ul {
    display: flex;
    align-items: center;
    margin: auto;
    margin-left: 28px;
    li {
      padding: 0px;
      list-style: none;
      margin-right: 45px;
      font-size: 16px;
    }
  }
  .copyright-text {
    font-size: 12px;
    margin: auto;
    margin-right: 60px;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 45px 20px;
    ul {
      margin-left: 0 !important;
    }
  }

  @media screen and (max-width: 670px) {
    justify-content: center;
    gap: 20px;
    .footer-content {
      ul {
        width: 100%;
        gap: 10px;

        li {
          margin-right: 0;
        }
      }
    }
    .copyright-text-content {
      .copyright-text {
        margin: 0;
        padding-bottom: 0;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    flex-wrap: wrap;
    gap: 0.5rem;
    ul {
      margin-left: 8px;
    }
    .copyright-text {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
`

const UnAuthorizedFooter = () => {
  return (
    <Footer>
      <div className={"footer-content"}>
        <ul>
          <li>
            {"問い合わせ先: "}
            <a href={"mailto:easyy-support@onelife.co.jp"}>
              {"easyy-support@onelife.co.jp"}
            </a>
          </li>
        </ul>
      </div>
      <div className={"copyright-text-content"}>
        <p className={"copyright-text"}>{"Copyright © Onelife.Inc"}</p>
      </div>
    </Footer>
  )
}

export { UnAuthorizedFooter }
