import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  header: string
  subheader?: string
  marginTop?: string
  marginBottom?: string
  afterText?: string
  hide?: boolean
  acutalLink?: string | undefined
  disableAddButton?: boolean
}
const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 30px;
  .subheader {
    border-left: 3px solid #0782c8;
    padding-left: 8px;
    margin-bottom: 16px;
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
    .after-text {
      margin-left: 27px;
    }
  }
  @media (max-width: 575px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    .btn-wrapper {
      flex-direction: column;
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border-top: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const OperationOptionComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const {
    header,
    subheader,
    addButtonText,
    addButtonLink,
    afterText,
    hide,
    acutalLink,
    disableAddButton = false,
  } = props
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <div className={"subheader"}>{subheader ? subheader : ""}</div>
      <div className={"btn-wrapper"}>
        {(hide == undefined || !hide) && (
          <OwnerButton
            className={"search-btn"}
            shape={"circle"}
            typeOf={"primary"}
            onClick={() =>
              addButtonLink
                ? router.push(addButtonLink, acutalLink)
                : router.push(`${router.pathname}/add`)
            }
            text={addButtonText || t("Add new progress item")}
            disabled={disableAddButton}
          />
        )}
        {hide && afterText ? (
          <div className={"after-text"}>{afterText}</div>
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  )
}

export { OperationOptionComponent }
