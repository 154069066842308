import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import {
  Checkbox,
  RadioButtons,
  TextField,
  TimeRanges,
  theme,
  CALANDER_HOURS_MINUTES,
  DeleteConfimationModal,
  SelectInput,
} from "@project/shared"
import { OwnerButton, StyledFormWrapper } from "../../../components"
import { Divisor, FuriganaAlphabetsOption } from "../../../utils"
import { useFormik } from "formik"
import * as yup from "yup"
import { useRouter } from "next/router"
import { t } from "i18next"
import { useMutation } from "react-query"
import {
  resetAttendanceStaffResults,
  saveAttendanceStaffResults,
  updateAttendanceStaffResults,
} from "../../../services/attendanceStaffResult"
import { toastMsg } from "../../../utils/toastMsg"
import moment from "moment"
import { Input } from "antd"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iStylingProps {
  top?: boolean
}

const FormWrapper = styled.div<any>`
  width: 100%;
  margin-top: ${(props) => (props.mt ? "40px" : "0px")};
  .child-name-wrapper {
    display: flex;
    & > :first-child {
      margin-right: 20px;
    }
  }
  p {
    margin: auto 0px;
  }

  .error {
    color: ${theme?.red2};
  }

  .error-border {
    border: 1px solid ${theme.red2} !important;
  }

  .faculty-datepicker {
    width: 160px;
    height: 40px;
  }
  .no-created-wrapper {
    display: flex;
    & > :first-child {
      margin-right: 10px;
    }
    p {
      margin: auto 0px;
    }
  }
  @media (max-width: 822px) {
    .child-name-wrapper {
      & > :first-child {
        margin-right: 10px;
      }
      .large-select {
        width: 200px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    margin-top: 0;
  }
  @media (max-width: 373px) {
    .child-name-wrapper {
      flex-direction: column;
      & > :first-child {
        margin-bottom: 10px;
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
    }

    .save-cancel-btn {
      display: flex;
      gap: 15px;
      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  margin-bottom: 40px;
  padding: 13px 30px 36px 30px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }

  .staff-name-class {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #0782c8;
  }

  .flex-col {
    display: flex;
    flex-direction: column;

    .info-info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #e00000;
      padding-bottom: 12px;
    }

    .flex-row {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      .second-child {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      @media (max-width: ${theme.breakpoints.md}) {
        gap: 20px;
      }
      @media (max-width: ${theme.breakpoints.sm}) {
        gap: 10px;
      }
    }
  }
  .minutes {
    padding: 10px 8px;
  }
  .last-btn {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .flex-both {
    display: flex;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 13px 20px 24px 20px;
  }
  .ant-typography {
    margin-right: 0.8rem;
  }
`

const Header = styled.div<iStylingProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  padding-top: ${(props) => (props.top ? "30px" : "0px")};
  @media (max-width: ${theme.breakpoints.md}) {
    padding-top: 10px;
  }
`

const StyledInput = styled(Input)`
  height: 40px;
  min-width: 400px;
  width: 50%;
`

const SelectContainer = styled.div`
  display: flex;
  gap: 20px;
  .mwd-60 {
    min-width: 60px;
  }
  .mwd-200 {
    min-width: 200px;
  }
`

const options = [
  {
    label: t("Attend"),
    value: "1",
  },
  {
    label: t("Absence "),
    value: "2",
  },
]
interface ITimeTableProps {
  date?: any
  data?: any
  staffId?: any
  type?: string
  staffOptions?: Array<any>
  att_id?: string
}

const AttendanceTimeTableComponent = ({
  date,
  data = undefined,
  staffId,
  type = "edit",
  staffOptions,
  att_id,
}: ITimeTableProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [restBtnClicked, setResetBtnClicked] = useState(false)
  const validationSchema = yup.object().shape({
    staff_id: yup.number().required(),
    facility_id: yup.number().required(),
    date: yup.string().required(t("Required")),
  })
  const initialValues = {
    id: data?.attendance_staff_result_id ?? 0,
    date: date,
    furigana: "",
    staff_id: data?.staff_facility?.staff_id || staffId || "",
    staff_name: data?.staff_name ?? "",
    facility_id: data?.staff_facility?.facility_id,
    attendance_type:
      data?.attendance_staff_results_attendance_type?.toString() ?? "",
    attendance_type2:
      data?.attendance_staff_results_attendance_type2?.toString() ?? "",
    attendance_start_time:
      data?.attendance_staff_results_attendance_start_time || "",
    attendance_end_time:
      data?.attendance_staff_results_attendance_end_time ?? "",
    attendance_rest_minits:
      data?.attendance_staff_results_attendance_rest_minits ?? 0,
    attendance_start_time2:
      data?.attendance_staff_results_attendance_start_time2 ?? "",
    attendance_end_time2:
      data?.attendance_staff_results_attendance_end_time2 ?? "",
    attendance_rest_minits2:
      data?.attendance_staff_results_attendance_rest_minits2 || 0,
    remarks: data?.attendance_staff_results_remarks || "",
    rest_hours: data?.attendance_staff_results_rest_hours || 0,
    rest_minits_flg: data?.attendance_staff_results_rest_minits_flg || 0,
  }

  const { isLoading: isCreating, mutate: createTimeTable } = useMutation(
    saveAttendanceStaffResults,
    {
      onSuccess: () => {
        toastMsg("success", t("Created Successfully"))
        router.push(
          `/staff-attendance/detail?date=${date}&facilityIds=${formik.values.facility_id}`
        )
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )

  const { isLoading: isUpdating, mutate: updateTimeTable } = useMutation(
    updateAttendanceStaffResults,
    {
      onSuccess: () => {
        toastMsg("success", t("Updated Successfully"))
        setResetBtnClicked(false)
        setIsResetModalOpen(false)
        router.push(
          `/staff-attendance/detail?date=${date}&facilityIds=${formik.values.facility_id}`
        )
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )

  const { mutate: deleteStaffAttResult, isLoading: isReseting } = useMutation(
    resetAttendanceStaffResults,
    {
      onSuccess: () => {
        toastMsg("success", t("Updated Successfully"))
        setResetBtnClicked(false)
        setIsResetModalOpen(false)
        router.push(
          `/staff-attendance/detail?date=${date}&facilityIds=${formik.values.facility_id}`
        )
      },
      onError: () => {
        toastMsg(
          "error",
          t("Something went wrong. Please contact administrator.")
        )
      },
    }
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const postData = {
        ...values,
        date: moment(values.date).format("YYYY-MM-DD"),
        staff_id: Number(formik.values.staff_id),
        attendance_type: Number(values.attendance_type),
        attendance_type2: Number(values.attendance_type2),
        attendance_rest_minits: Number(values.attendance_rest_minits),
        attendance_rest_minits2: Number(values.attendance_rest_minits2),
      }
      if (values.id !== 0) {
        updateTimeTable({ id: values.id, values: postData })
      } else {
        createTimeTable(postData)
      }
    },
  })

  const onInputChange = ({ value, unit, field }) => {
    const previousValueSplit = formik.values[field].split(":")
    const newValue = `${
      unit == "hours"
        ? value
        : previousValueSplit.length == 2
        ? previousValueSplit[0]
        : ""
    }:${
      unit == "minutes"
        ? value
        : previousValueSplit.length == 2
        ? previousValueSplit[1] || "00"
        : "00"
    }`
    formik.setFieldValue(field, newValue)
  }

  const getStaffOptions = () =>
    formik.values.furigana
      ? staffOptions.filter((s) => {
          const regex = japaneseAlphaRegex(formik.values.furigana)
          if (s.furigana_name.match(regex) || s.label.match(regex)) return true
          return false
        })
      : staffOptions

  return (
    <>
      <Wrapper>
        <Header>{`${moment(formik.values?.date).format("YYYY年MM月DD日")} ${
          formik.values?.staff_name
        } ${t("attendance information")}`}</Header>
        <Divisor />
        <FormWrapper mt>
          <StyledFormWrapper label={t("Date ")}>
            <p>{moment(formik.values?.date).format("YYYY年MM月DD日")}</p>
          </StyledFormWrapper>
          <StyledFormWrapper label={t("staff name")}>
            {type == "edit" ? (
              <p className={"staff-name-class"}>
                <a
                  onClick={() => {
                    router.push(`/instructors/edit/${formik.values?.staff_id}`)
                  }}
                >
                  {data?.staff_name}
                </a>
              </p>
            ) : (
              <SelectContainer>
                <SelectInput
                  height={40}
                  options={FuriganaAlphabetsOption}
                  className={"mwd-60"}
                  value={formik.values.furigana}
                  onChange={(val) => formik.setFieldValue("furigana", val)}
                />
                <SelectInput
                  height={40}
                  className={"mwd-200"}
                  options={getStaffOptions()}
                  value={formik.values.staff_id}
                  onChange={(val) => formik.setFieldValue("staff_id", val)}
                />
              </SelectContainer>
            )}
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Remarks")}>
            <StyledInput
              value={formik.values.remarks}
              onChange={(e) =>
                formik.setFieldValue("remarks", e?.target?.value)
              }
            />
          </StyledFormWrapper>
        </FormWrapper>
        <Header top>{t("Break time")}</Header>
        <Divisor />
        <StyledFormWrapper label={t("Break time")}>
          <div className={"flex-col"}>
            <div className={"info-info"}>
              {t(
                "※please set if you want to include vacation time such as half-holidays in the number of hours that a full-time employee should work."
              )}
            </div>
            <div className={"flex-row"}>
              <div>
                <Checkbox
                  label={t("Set break time")}
                  defaultChecked={formik.values.rest_minits_flg}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "rest_minits_flg",
                      e.target.checked ? 1 : 0
                    )
                  }}
                />
              </div>
              <div className={"second-child"}>
                <TextField
                  bgcolor={"#FFFFFF"}
                  width={"59px"}
                  height={"30px"}
                  value={formik.values.rest_hours}
                  disabled={!formik.values.rest_minits_flg}
                  onChange={(e) => {
                    formik.setFieldValue("rest_hours", Number(e.target.value))
                  }}
                />
                <span className={"hr-text"}>{t("Hr")}</span>
              </div>
            </div>
          </div>
        </StyledFormWrapper>
        <Header top>
          {t("Work information")}
          {"1"}
        </Header>
        <Divisor />
        <FormWrapper mt>
          <StyledFormWrapper label={t("Attendance ")}>
            <RadioButtons
              options={options}
              value={formik.values?.attendance_type}
              onChange={(evt) => {
                formik.setFieldValue("attendance_type", evt.target.value)
              }}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("In time")} isRequired={true}>
            <TimeRanges
              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
              range={"from"}
              unit={["hours", "minutes"]}
              handleChange={(value, unit) =>
                onInputChange({
                  value,
                  unit,
                  field: "attendance_start_time",
                })
              }
              values={formik.values?.attendance_start_time}
              width={80}
              height={40}
              typoText={"時"}
              disabled={!(formik?.values?.attendance_type === "1")}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("out time")}>
            <TimeRanges
              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
              range={"from"}
              unit={["hours", "minutes"]}
              values={formik.values?.attendance_end_time}
              handleChange={(value, unit) =>
                onInputChange({
                  value,
                  unit,
                  field: "attendance_end_time",
                })
              }
              width={80}
              height={40}
              typoText={"時"}
              disabled={!(formik?.values?.attendance_type === "1")}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Break time")}>
            <div className={"flex-both"}>
              <TextField
                width={"80px"}
                height={"40px"}
                bgcolor={"#fff"}
                value={formik.values.attendance_rest_minits}
                onChange={(e) => {
                  formik.setFieldValue("attendance_rest_minits", e.target.value)
                }}
              />
              <span className={"minutes"}>{t("mins")}</span>
            </div>
          </StyledFormWrapper>
        </FormWrapper>
        <Header top>
          {t("Work information")}
          {"2"}
        </Header>
        <Divisor />
        <FormWrapper mt>
          <StyledFormWrapper label={t("Attendance")}>
            <RadioButtons
              options={options}
              value={formik.values.attendance_type2}
              onChange={(evt) => {
                formik.setFieldValue("attendance_type2", evt.target.value)
              }}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("In time")}>
            <TimeRanges
              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
              range={"from"}
              unit={["hours", "minutes"]}
              values={formik.values?.attendance_start_time2}
              handleChange={(value, unit) =>
                onInputChange({
                  value,
                  unit,
                  field: "attendance_start_time2",
                })
              }
              width={80}
              height={40}
              typoText={"時"}
              disabled={!(formik?.values?.attendance_type2 === "1")}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Out time")}>
            <TimeRanges
              hoursOptions={CALANDER_HOURS_MINUTES?.hours}
              minutesOptions={CALANDER_HOURS_MINUTES?.minutes}
              range={"from"}
              unit={["hours", "minutes"]}
              values={formik.values?.attendance_end_time2}
              handleChange={(value, unit) =>
                onInputChange({
                  value,
                  unit,
                  field: "attendance_end_time2",
                })
              }
              width={80}
              height={40}
              typoText={"時"}
              disabled={!(formik?.values?.attendance_type2 === "1")}
            />
          </StyledFormWrapper>
          <StyledFormWrapper label={t("Break time")}>
            <div className={"flex-both"}>
              <TextField
                width={"80px"}
                height={"40px"}
                bgcolor={"#fff"}
                value={formik.values.attendance_rest_minits2}
                onChange={(e) => {
                  formik.setFieldValue(
                    "attendance_rest_minits2",
                    e.target.value
                  )
                }}
              />
              <span className={"minutes"}>{t("mins")}</span>
            </div>
          </StyledFormWrapper>
        </FormWrapper>
        <FormWrapper mt>
          <div className={"buttons-wrapper"}>
            <div className={"save-cancel-btn"}>
              <OwnerButton
                type={"button"}
                typeOf={"secondary"}
                text={t("Cancel")}
                disabled={isCreating || isUpdating}
                onClick={() => {
                  router.push(
                    `/staff-attendance/detail?date=${date}&facilityIds=${formik.values.facility_id}`
                  )
                }}
              />

              <OwnerButton
                type={"button"}
                typeOf={"primary"}
                text={t("Save")}
                isLoading={
                  (!restBtnClicked && isCreating) ||
                  (!restBtnClicked && isUpdating)
                }
                onClick={() => {
                  formik.handleSubmit()
                }}
              />
            </div>
            {type == "edit" && (
              <div>
                <OwnerButton
                  text={t("Reset attendance information ")}
                  typeOf={"draft"}
                  shape={"circle"}
                  disabled={isCreating || isUpdating}
                  onClick={() => {
                    setResetBtnClicked(true)
                    setIsResetModalOpen(true)
                  }}
                />
              </div>
            )}
          </div>
        </FormWrapper>
      </Wrapper>
      {/* //modal for reset confirmation  */}
      {isResetModalOpen && (
        <DeleteConfimationModal
          title={t("Reset attendance information ")}
          open={isResetModalOpen}
          onCancel={() => {
            setResetBtnClicked(false)
            setIsResetModalOpen(false)
          }}
          isLoading={
            (restBtnClicked && isCreating) || (restBtnClicked && isReseting)
          }
          onOk={() => deleteStaffAttResult(att_id)}
        />
      )}
    </>
  )
}

export { AttendanceTimeTableComponent }
