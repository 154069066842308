import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .policy_container {
    border: 1px solid red;
    border-radius: 12px;
    overflow: hidden;
    &-header {
      background: ${theme.red1};
      padding: 22px 32px;
      .title_content {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        flex-wrap: wrap;
        &-left {
          background: ${theme.base};
          border-radius: 6px;
          padding: 1px 6px;
          display: flex;
          gap: 6px;
          align-items: center;
          color: ${theme.red1};
          width: fit-content;
        }
        &-right {
          color: ${theme.base};
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
        }
      }
      .subtitle_content {
        margin-top: 10px;
        color: ${theme.base};
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        padding: 12px 22px;
        .title_content {
          &-right {
            font-size: 18px;
            line-height: 29px;
          }
        }
        .subtitle_content {
          font-size: 16px;
        }
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 12px 22px;
        .title_content {
          &-right {
            font-size: 14px;
          }
        }
        .subtitle_content {
          font-size: 12px;
        }
      }
    }
    &-body {
      padding: 22px 32px;
      .ant-typography {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        padding: 0;
        margin-bottom: 0 !important;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        padding: 12px 22px;
        p {
          font-size: 15px;
        }
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        p {
          font-size: 14px;
        }
      }
    }
  }
  .cta_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    .checkbox_btn {
      background: ${theme.base};
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      padding: 12px 24px;
    }
  }
  .log_container {
    table {
      thead {
        tr {
          th {
            background: ${theme.gray2} !important;
            &:first-child {
              border-top-left-radius: 12px !important;
            }
            &:last-child {
              border-top-right-radius: 12px !important;
            }
            &::before {
              height: 2rem !important;
              background: ${theme.gray3} !important;
            }
          }
        }
      }
    }
    .main-pagination-wrapper {
      margin-top: 0 !important;
    }
  }
`
