import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
//libraries
import { useQuery } from "react-query"

//components
import {
  Checkboxes,
  PageCard,
  SelectInput,
  removeBlankAttributes,
} from "@project/shared"
import { Highlighter, Title, TitleWrapper } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"
//styles
import {
  Block,
  TitleWrap,
  WrapperContainer,
  Text,
  DateSelectWrap,
  Content,
} from "./FacilityApplicationPrintOperation.styles"
import { getChildrenApplicationStatusFacility } from "../../../services/child"
import moment from "moment"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"

interface IProps {
  queryParams: any
  setSearchParams?: (values: any) => void
  displayOptions: Array<any>
}
export const FacilityApplicationPrintOperation: React.FC<IProps> = ({
  queryParams,
  setSearchParams,
  displayOptions,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const [childFurigana, setChildFurigana] = useState("")
  const mode = router?.query?.mode as string
  const [tempQuery, setTempQuery] = useState({
    childId: queryParams?.childId,
    year: queryParams?.year,
    month: queryParams?.month,
    display_settings: queryParams?.display_settings || "",
  })
  useEffect(() => {
    setTempQuery({
      ...queryParams,
    })
  }, [queryParams?.year, queryParams?.month])
  const { data: AllChild } = useQuery({
    queryKey: ["all_child"],
    queryFn: () => getChildrenApplicationStatusFacility(),
    select: (res) => {
      return res?.data?.map((val) => ({
        label: val?.child_name,
        value: val?.id,
        child_name_furigana: val?.child_name_furigana,
      }))
    },
    retry: 1,
    refetchOnWindowFocus: false,
  })
  const handleDisplayChange = () => {
    const { childId, ...rest } = tempQuery
    const data = removeBlankAttributes({ ...rest, childId }) as any
    setSearchParams(data)
    updateParams(
      { ...rest, mode: mode },
      `/child/profile/facility-application/${data?.childId}/print`
    )
  }
  return (
    <WrapperContainer>
      <PageCard title={t("Operation options")}>
        <Content>
          <TitleWrapper>
            <Highlighter /> <Title>{t("Narrow down")} </Title>
          </TitleWrapper>
          <Block className={"date-select"}>
            <TitleWrap style={{ minWidth: "125px" }}>
              <img
                src={"/assets/icons/arrow.svg"}
                style={{ marginRight: "10px" }}
              />
              <Text>{t("Year month")} </Text>
            </TitleWrap>
            <DateSelectWrap>
              <DatePicker
                date={
                  moment(`${tempQuery?.year}-${tempQuery?.month}`)?.isValid()
                    ? moment(`${tempQuery?.year}-${tempQuery?.month}`)
                    : null
                }
                style={{
                  height: "40px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment("2021/01/01")) ||
                    (current && current > moment().add(5, "year"))
                  )
                }}
                onDateChange={(value) => {
                  setTempQuery({
                    ...tempQuery,
                    year: +moment(value).format("YYYY"),
                    month: +moment(value).format("M"),
                  })
                }}
              />
            </DateSelectWrap>
          </Block>
          <Block className={"child_selector"}>
            <TitleWrap style={{ minWidth: "125px" }}>
              <img
                src={"/assets/icons/arrow.svg"}
                style={{ marginRight: "10px" }}
              />
              <Text>{t("Child name")} </Text>
            </TitleWrap>
            <div className={"selector"}>
              <SelectInput
                width={80}
                height={40}
                options={FuriganaAlphabetsOption}
                placeholder={"--"}
                onChange={(val) => {
                  setChildFurigana(val)
                  setTempQuery({
                    ...tempQuery,
                    childId: "",
                  })
                }}
                value={childFurigana}
              />
              <SelectInput
                width={300}
                height={40}
                value={tempQuery?.childId || null}
                options={AllChild || []}
                onChange={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    childId: +value,
                  })
                }
                placeholder={t("All")}
              />
            </div>
          </Block>
          <Block className={"settings_checkboxes"}>
            <TitleWrap
              style={{
                minWidth: "125px",
                whiteSpace: "pre-line",
              }}
            >
              <img
                src={"/assets/icons/arrow.svg"}
                style={{ marginRight: "10px" }}
              />
              <Text>{t("Display settings")} </Text>
            </TitleWrap>
            <div className={"checkboxes"}>
              <Checkboxes
                options={displayOptions}
                value={tempQuery?.display_settings}
                onChange={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    display_settings: value?.join(","),
                  })
                }
              />
            </div>
          </Block>
          <OwnerButton
            text={t("Change display")}
            typeOf={"secondary"}
            isLoading={false}
            onClick={handleDisplayChange}
          />
        </Content>
      </PageCard>
    </WrapperContainer>
  )
}
