import { t } from "i18next"

export const ALL_SERVICE_USE_OPTIONS = [
  { label: t("After school day service"), value: 1 },
  { label: t("Child development support"), value: 2 },
]

export const DUMMY_MONITORING_CONTENT = [
  {
    child_monitoring_item_id: 1,
    achievement_goal_type: 1,
    evaluation_type: 2,
    study: "this is study of the world",
  },
  {
    child_monitoring_item_id: 2,
    achievement_goal_type: 1,
    evaluation_type: 2,
    study: "this is study of the world in wor",
  },
  {
    child_monitoring_item_id: 3,
    achievement_goal_type: 1,
    evaluation_type: 2,
    study: "this is study of the world in wor",
  },
  {
    child_monitoring_item_id: 4,
    achievement_goal_type: 1,
    evaluation_type: 2,
    study: "this is study of the world in wor",
  },
  {
    child_monitoring_item_id: 5,
    achievement_goal_type: 1,
    evaluation_type: 2,
    study: "this is study of the world in wor",
  },
]

export const DISPLAY_CONTENT = [
  {
    label: t("consent date"),
    value: "consent_date",
  },
  {
    label: t("Parents sign field"),
    value: "parent_sign",
  },
  {
    label: t("Consent description"),
    value: "description",
  },
  {
    label: t("Child sign field"),
    value: "child_sign",
  },
  {
    label: t("Parents seal"),
    value: "parent_seal",
  },
  {
    label: t("Child development support manager seal"),
    value: "manager_seal",
  },
  {
    label: t("Child development support manager signature column"),
    value: "manager_signature_column",
  },
]

export const CONSENT_DATE = [
  {
    label: t("Separating the explanation date from the consent date"),
    value: "separate_date",
  },
  {
    label: t("Display and fill in digital signature"),
    value: "digital_signature",
  },
]

export const PARENT_FIELD = [
  {
    label: t(
      "Change 'Name of Guardian' to 'Name of Guardian and Family Representative'"
    ),
    value: "change_reresentative",
  },
]
