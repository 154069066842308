import React, { useRef } from "react"

import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"

import { OwnerButton } from "../../atoms"
import {
  Wrapper,
  CTASection,
  ContentSection,
} from "./UserLabelsPrintContent.styles"
import { useRouter } from "next/router"
import { useFetchPrintUserLabels } from "../../../hooks/useFetchPrintUserLabels"
import { Spin } from "antd"

export const UserLabelsPrintContent = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    data: userLabelsData,
    isLoading,
    isFetching,
  } = useFetchPrintUserLabels()

  const mergedUserLabelsData = userLabelsData.reduce((mergedData, cityData) => {
    if (cityData?.data) {
      return mergedData.concat(cityData.data)
    }
    return mergedData
  }, [])

  const listRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => listRef.current,
  })

  return (
    <Wrapper>
      <CTASection>
        <OwnerButton
          text={t("Cancel")}
          shape={"circle"}
          typeOf={"secondary"}
          onClick={() => router.back()}
        />
        <OwnerButton
          text={t("Print")}
          typeOf={"primary"}
          icon={"print"}
          shape={"round"}
          onClick={handlePrint}
        />
      </CTASection>
      <Spin spinning={isLoading || isFetching}>
        <ContentSection ref={listRef}>
          <table className={"content_data_table"}>
            <tbody>
              <tr key={Math.random()}>
                {mergedUserLabelsData?.map((row, rowIndex) => (
                  <td key={rowIndex}>
                    <div className={"label"}>
                      <div className={"parent-zip-class"}>
                        {"〒"} {row?.parent_zip_code}
                      </div>
                      <div className={"parent-address-class"}>
                        {row?.parent_address}
                      </div>
                      <div className={"parent-name-class"}>
                        {row?.parent_name} &nbsp;{"様"}
                      </div>
                      <div className={"hash"}></div>
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </ContentSection>
      </Spin>
    </Wrapper>
  )
}
