import { t } from "i18next"

export const AbsentTypesWithLineBreakOpt = [
  {
    label: t("Addition for Absence."),
    value: 2,
  },
  {
    label: t("Addition for Absence【No addition】."),
    value: 3,
  },
]
