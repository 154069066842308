import styled from "styled-components"
import { Button, Spin, Table } from "antd"
import { theme } from "@project/shared"
export const Wrapper = styled.div`
  margin: 20px 0;
  .print-pagination-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .staff-table {
    border: solid 1px black;
    border-width: 1px 1px 1px 1px;
  }

  @media screen and (max-width: 1025px) {
    .staff-table .ant-table-cell {
      min-width: 100px;
    }
  }
  @media screen and (max-width: 426px) {
    .print-pagination-container.section-not-to-print {
      display: flex;
      flex-direction: column;
    }
    .pagination-container {
      align-self: flex-end;
    }
  }
  .print-back-container,
  .pagination-container,
  .footer-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .daily-report-container {
    width: 100%;
    margin: 20px 0;
    padding: 15px;
    background: #ffffff;
    .title-container {
      text-align: center;
      padding: 5px 0;
      font-weight: 500;
    }
    .table-container {
      max-width: 100%;
      overflow: auto;
    }
  }
  .ant-table {
    min-width: 100%;
    overflow: auto;
    .anticon.anticon-caret-up.ant-table-column-sorter-up,
    .anticon.anticon-caret-down.ant-table-column-sorter-down {
      color: rgba(0, 0, 0, 0.35);
    }
    .anticon.anticon-caret-up.ant-table-column-sorter-up.active,
    .anticon.anticon-caret-down.ant-table-column-sorter-down.active {
      color: #000;
    }
  }
  .no-report-day {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
  }
  @media print {
    margin: 0 !important;
    @page {
      size: auto;
      margin: 0 !important;
    }
    .body-temperature-column {
      white-space: break-spaces;
    }
    body *,
    .section-not-to-print {
      display: none;
    }
    .section-to-print {
      visibility: visible;
    }
    .ant-table-cell {
      padding: 0;
    }
    .daily-report-container {
      padding: 20px;
      margin: 0;
      .title-container {
        font-weight: 900 !important;
      }
    }
  }
`

export const HeaderTable = styled.table`
  width: 100%;
  overflow: auto;
  border: 1px solid #000000;

  td {
    padding: 4px 0;
    text-align: center;
    border: 1px solid #000000;
    border-bottom: 1px solid #000000;
    @media screen and (max-width: 1025px) {
      min-width: 180px;
    }
  }
  .facility_date {
    width: 200px;
  }
  .stamp_label {
    width: 100px;
  }
  .stamp_value {
    width: 75px;
  }
  .white-space-no-wrap {
    white-space: nowrap;
  }

  @media print {
    overflow: auto !important;
    tr {
      td {
        font-size: 10px !important;
        padding: 2px !important;
      }
    }
  }
`

export const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  margin: 10px;
`

interface ButtonProps {
  background?: string
  shape?: string
  borderColor?: string
  color?: string
}

export const CustomButton = styled(Button)<ButtonProps>`
  padding: 8px 16px;
  height: auto;
  background: ${(props) => props?.background ?? theme.blue5};
  border-radius: ${(props) => (props?.shape == "circle" ? "40px" : "8px")};
  border-color: ${(props) => props?.borderColor ?? "transparent"};
  color: ${(props) => props?.color ?? "black"};
  &:hover {
    background: ${(props) => props?.background ?? theme.blue5};
    color: ${(props) => props?.color ?? "black"};
    border-color: ${(props) => props?.borderColor ?? "transparent"};
  }
`

export const ContentImageTable = styled.table`
  width: 100%;
  border: 1px solid #000000;
  border-top-width: 0.5px;
  td {
    padding: 2px;
    min-width: 150px;
  }
  .label-cell {
    width: 150px;
    background: #cccccc;
    border-right: 1px solid #000;
  }
  .cell-image {
    min-width: 35vw;
    margin: auto;
    overflow: hidden;
    img {
      width: 25%;
      height: auto;
    }
  }
`

export const ChildTable = styled(Table)`
  .ant-table-content {
    table {
      border-color: 1px solid black;
      border-collapse: collapse;
      tbody:before {
        content: "@";
        display: block;
        line-height: 2px;
        text-indent: -99999px;
      }

      thead {
        tr {
          th {
            text-align: center;
            background: #cccccc;
            padding: 5px;
            border-color: #000000 !important;
            border: 1px solid black !important;
          }
        }
        tr:first-child th:first-child {
          border-left: transparent;
        }
      }

      tbody {
        tr {
          td {
            padding: 4px;
            text-align: center;
            border: 1px solid black !important;
          }
          td:first-child {
            border-left: transparent;
          }
        }
        tr:nth-child(even) {
          td {
            background: #eeeeee;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1025px) {
    td {
      min-width: 140px;
    }
  }
  @media print {
    table {
      overflow: auto;
      thead {
        border-top: 2px solid black !important;
        tr {
          background-color: ${theme.gray2} !important;
          th {
            font-size: 10px !important;
            padding: 2px 4px !important;
            text-align: center !important;
            position: relative !important;
            background-color: transparent !important;
            outline: unset !important;

            .ant-table-column-sorters {
              .ant-table-column-title {
                font-size: 10px !important;
                white-space: nowrap !important;
                z-index: 20 !important;
              }
              .ant-table-column-sorter {
                display: none !important;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 10px !important;
            padding: 2px 4px !important;
          }
        }
        tr:nth-child(even) {
          background-color: transparent !important;
          td {
            position: relative !important;
            background-color: transparent !important;
            z-index: 2 !important;
            &:before {
              content: " ";
              position: absolute;
              background-color: #eeeeee !important;
              left: 1px;
              right: 1px;
              top: 1px;
              bottom: 1px;
              z-index: -1 !important;
            }
          }
        }
      }
    }
  }
`

export const StaffTable = styled(Table)`
  .ant-table-container {
    border-left: none !important;
    table {
      border: none !important;
      border-collapse: collapse !important;
      .ant-table-thead {
        background: #cccccc;
        th.ant-table-cell {
          outline: unset !important;
          background: transparent;
          padding: 5px;
          text-align: center;
          border-color: #000 !important;
          &.no-right-border {
            border-right: none !important;
          }
        }
      }
      .ant-table-tbody {
        .staff-row {
          td.ant-table-cell {
            padding: 5px;
            text-align: center;
            border-color: #000 !important;
            &.no-right-border {
              border-right: none !important;
            }
          }
          &.no-bottom-border td {
            border-bottom: none;
          }
        }
      }
    }
  }
`
