import { theme } from "@project/shared"
import styled from "styled-components"

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .content {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    .icon_label {
      min-width: 150px;
      svg {
        color: ${theme.gray2};
        margin-right: 0.5rem;
      }
    }
    .input_picker {
      @media (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
      }
    }
    .input_picker-date {
      display: flex;
      gap: 0.5rem;
    }
    .btn_ctas {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  }
`
