import { theme } from "@project/shared"
import React, { ReactNode } from "react"
import styled from "styled-components"

interface iPageHeader {
  title: string
  icon: ReactNode
  className?: string
  marginTop?: string
  marginBottom?: string
  extra?: React.ReactNode
}
const Wrapper = styled.div<iPageHeader>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "10px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "0px")};
  @media print {
    visibility: hidden;
  }
  .edit-btn {
    border: 1px solid #0782c8;
    background: rgba(7, 130, 200, 0.2);
    :hover {
      border: 1px solid #0782c8;
      background: rgba(7, 130, 200, 0.2);
      opacity: 0.8;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.8rem;
  }
  @media print {
    display: none;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #191919;
  margin-left: 10px;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`

const PageHeader: React.FC<iPageHeader> = (props) => {
  const { title, icon, className, extra } = props
  return (
    <Wrapper className={className} {...props}>
      <TitleWrapper>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
      </TitleWrapper>
      {extra}
    </Wrapper>
  )
}

export { PageHeader }
