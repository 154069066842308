import styled, { css } from "styled-components"
import { theme } from "@project/shared"

export const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  @media print {
    padding: 0;
  }

  .field-error:empty {
    display: none;
  }

  .field-error:not(:empty) {
    display: block;
  }

  .date__picker {
    display: flex;
    gap: 8px !important;
    align-items: baseline;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
      b {
        margin: 0 4px;
      }
    }
  }
`

const CLABEL = css`
  min-width: 150px;
  max-width: 150px;
  @media (max-width: ${theme.breakpoints?.md}) {
    min-width: 100%;
    max-width: 100%;
  }
  svg {
    color: ${theme.gray2};
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .label .ant-typography {
    margin-bottom: 10px;
  }

  .title {
    border-left: 3px solid ${theme.blue5};
    padding-left: 8px;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: ${theme.black};
      margin: 0;
    }
  }

  .child__picker {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }

    &--input-group {
      display: flex;
      &__furigana {
        width: 80px;
        margin-right: 10px;
      }
      &__name {
        width: 300px;
      }
    }
  }

  .use__service {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &--checkbox {
      display: flex;
    }
  }

  .facility {
    display: flex;
    gap: 1rem;
    .label {
      ${CLABEL}
    }
    &__checkbox {
      .check_uncheck_all {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        gap: 10px;
        button {
          height: 30px;
          display: flex;
          gap: 4px;
          align-items: center;
          outline: 0;
          &:hover,
          &:focus {
            border-color: ${theme.gray2};
          }
          .span {
            @media (max-width: ${theme.breakpoints.md}) {
              font-size: 14px;
            }
          }
        }
      }
      .checkboxs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .ant-checkbox + span {
          white-space: pre-line !important;
          word-break: break-all !important;
        }
      }
    }
  }

  .date {
    display: flex;
    gap: 1rem;

    .label {
      ${CLABEL}
    }
    &__picker {
      display: flex;
      gap: 1rem;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
    }
  }

  .display__settings,
  .status {
    display: flex;
    gap: 1rem;
    align-items: center;
    .label {
      ${CLABEL}
    }
    &--box {
      display: flex;
      gap: 1rem;
    }
  }

  .filter__cta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-wrap: nowrap;
      gap: 0;
      .search_btn {
        margin-right: 1rem;
      }
    }
  }

  .add__cta {
    border-top: 1px dashed ${theme.gray2};
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      .create_btn {
        padding: 12px 12px;
        width: 100%;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
      margin: 1rem 0;
      .title {
        margin: 0.5rem 0;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    gap: 0;
    .child__picker {
      flex-wrap: wrap;
      gap: 0.5rem;
    }
    .use__service,
    .facility,
    .date,
    .status,
    .display__settings {
      flex-direction: column;
      gap: 0.1rem;
    }
    .use__service {
      &--checkbox {
        flex-direction: column;
        margin-bottom: 0.5rem;
      }
      margin-top: 0.5rem;
    }
    .facility {
      .check_uncheck_all {
        gap: 0px;
        button:first-child {
          margin-right: 10px;
        }
      }
      .checkboxs {
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
        .ant-checkbox {
          margin-right: 5px;
        }
      }
    }
    .display__settings {
      .status--box {
        flex-direction: column;
      }
    }
    .status {
      align-items: flex-start;
    }

    .content__child {
      &.add__cta {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 504px) {
    display: grid !important;
    gap: 1.25rem;
    .child__picker {
      &--input-group {
        width: 100%;
        &__name {
          width: calc(100% - 90px);
        }
      }
    }

    .facility {
      &__checkbox {
        .checkboxs {
          gap: 0px;
          margin-bottom: -10px;

          .ant-checkbox-wrapper {
            gap: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            & > span:last-child {
              padding: 0;
            }
          }
        }
      }
    }
  }
`
