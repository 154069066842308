import { API, removeBlankAttributes } from "@project/shared"

export const fetchMonthlyActivities = (year: number) => {
  return API.get(`/program_schedule/${year}`)
}

export const fetchMonthlyActivitiesDetail = (
  date: Date,
  facilityIds: number[]
) => {
  const params = removeBlankAttributes({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    facilityIds: facilityIds.join(","),
  })
  return API.get(`/program_schedule/details`, {
    params,
  })
}
