import { CHILD_SERVICE_OPTION, Checkboxes, theme } from "@project/shared"
import moment from "moment"
import React, { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Title, AuthContext } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import { DatePicker } from "../../molecules"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value) => void
  handleSearchReset?: () => void
  defaultFacilities?: Array<any>
  defaultDate?: Date
  setSearchDate?: any
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 1px 36px;
  @media (max-width: 500px) {
    padding: 13px 30px 22px 36px;
  }
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;

    @media screen and (max-width: 426px) {
      margin-left: 0px;
    }
  }
  .select-down {
    margin-right: 18px;
  }
  .select-up {
    margin-right: 6px;
  }
  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .border-dashed {
    border: 1px dashed #d2d1d1;
    width: 100%;
  }
  .facility-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
    .mb-5 {
      margin-bottom: 5px;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    &.sm_mb-10 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Text = styled(Title)`
  font-size: 16px;
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;

  .select-class {
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }
  .calender {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .faculty-datepicker {
      margin-right: 20px;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      .faculty-datepicker {
        width: 100%;
        .ant-picker {
          width: 100%;
        }
      }
    }
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
`
const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 145px;
  @media (max-width: 768px) {
    .ant-checkbox + span {
      white-space: pre-line;
      word-break: break-all;
      padding-left: 10px;
    }
  }

  @media (max-width: 434px) {
    padding-left: 0px;
  }
`

const AttendanceDayListComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const {
    header,
    defaultFacilities,
    defaultDate,
    handleSearch,
    setSearchDate,
  } = props
  const { t } = useTranslation()
  const [check, setCheck] = useState(null)
  const [attendanceDate, setAttendanceDate] = useState(
    moment(defaultDate).isValid() ? defaultDate : new Date()
  )
  const [facilityIds, setFacilityIds] = useState(defaultFacilities)
  const { facilities } = useContext(AuthContext)

  const FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })
  const allFacility = FACILITY_OPTION.map((item) => item.value)

  useEffect(() => {
    setAttendanceDate(moment(defaultDate).isValid() ? defaultDate : new Date())
  }, [defaultDate])
  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <SearchWrapper className={"sm_mb-10"}>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display facility")} </Text>
        </TitleWrap>
        <div className={"facility-wrapper"}>
          <OwnerButton
            typeOf={"check-all"}
            text={t("Check all")}
            onClick={() => {
              setFacilityIds(allFacility)
            }}
            className={"mb-5"}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button mb-5"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds(null)
            }}
          />
        </div>
      </SearchWrapper>

      <SearchWrapper className={"sm_mb-10"}>
        <CheckboxWrap>
          <Checkboxes
            key={"facilities"}
            name={"facilities"}
            value={facilityIds}
            onChange={(vals) => {
              setFacilityIds(vals)
            }}
            options={FACILITY_OPTION}
          />
        </CheckboxWrap>
      </SearchWrapper>

      <SearchWrapper className={"sm_mb-10"}>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Use service")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={check}
            onChange={(values) => setCheck(values)}
            options={CHILD_SERVICE_OPTION}
          />
        </SelectWrap>
      </SearchWrapper>

      <SearchWrapper>
        <TitleWrap style={{ minWidth: "125px" }}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("date")} </Text>
        </TitleWrap>
        <SelectWrap>
          <div className={"calender"}>
            <DatePicker
              style={{ height: "40px", width: "150px", marginBottom: "5px" }}
              date={moment(attendanceDate)}
              onDateChange={(dateJs) => {
                setAttendanceDate(
                  dateJs
                    ? dateJs.toDate()
                    : moment(defaultDate).isValid()
                    ? defaultDate
                    : new Date()
                )
              }}
              className={"faculty-datepicker"}
              format={"YYYY年MM月DD日"}
            />
            <div>
              <OwnerButton
                typeOf={"secondary"}
                className={"reset-button"}
                text={t("Display change")}
                width={120}
                onClick={() => {
                  const searchFacilities =
                    facilityIds && typeof facilityIds == "object"
                      ? Object?.values(facilityIds).join(",")
                      : facilityIds
                  handleSearch({
                    facilityIds: searchFacilities,
                    date: moment(attendanceDate).format("YYYY-MM-DD"),
                    seviceProvisions: check,
                  })
                  setSearchDate(moment(attendanceDate).format("YYYY-MM-DD"))
                }}
              />
            </div>
          </div>
        </SelectWrap>
      </SearchWrapper>
    </Wrapper>
  )
}

export { AttendanceDayListComponent }
