import { API } from "@project/shared"

export const fetchCompany = async () => {
  return API.get(`/company_informations`)
}

export const saveCompany = async (values: any) => {
  return API.post(`/company_informations`, values)
}

export const fetchCars = async () => {
  return API.get(`/car_facilities`)
}
