import { useEffect, useMemo, useState } from "react"

// packages
import { t } from "i18next"
import moment from "moment"
import { FormikProps } from "formik"

// icons
import { StarFilled } from "@ant-design/icons"

// components
import { WithSorter } from "../../ActualCostBurdenDetailPage"
import { SelectInput, Table, TextField } from "@project/shared"

// types
import {
  CommonOption,
  RawTableDataProps,
  DataSourceDataType,
  RawStaffOptionsData,
  OptimizedTextareaProps,
  RecTableDataSourcesProps,
} from "../types"
import { ColumnsType } from "antd/es/table"

// constants
import { goalAchievementRateOptions } from "../constants"

// utils
import { FuriganaAlphabetsOption } from "../../../../utils"
import { getlabelFromOptions, getTableRowData } from "../utils"
import { japaneseAlphaRegex } from "../../../../utils/validation"

export const useTable = (
  programOptions: CommonOption[],
  currentDate: moment.Moment,
  formik: FormikProps<RecTableDataSourcesProps>,
  rawStaffOptions: RawStaffOptionsData[],
  initialRecTableData: RawTableDataProps[],
  isRecTableDataLoading: boolean,
  isStaffListLoading: boolean
) => {
  // getting the number of days in the current month
  const { setFieldValue, values, handleBlur } = formik

  const allStaffOptions = useMemo(() => {
    return [{ label: "-", value: 0, furigana_sort: "" }].concat(
      rawStaffOptions || []
    )
  }, [rawStaffOptions])

  const [staffOptions, setStaffOptions] = useState({})

  // Filter staff options based on furigana sort
  const getFilteredStaffOptions = (key: string) => {
    if (rawStaffOptions?.length > 0) {
      let options = rawStaffOptions
      if (values.individualDayRecords[key]?.furigana_sort) {
        const regex = japaneseAlphaRegex(
          values.individualDayRecords[key]?.furigana_sort
        )
        options = rawStaffOptions.filter((staff) =>
          staff.furigana_sort.match(regex)
        )
      }
      return [{ label: "--", value: 0, furigana_sort: "" }].concat(options)
    }
  }

  const handleFuriganaChange = (furigana: string, key: string) => {
    let options = []
    if (rawStaffOptions?.length > 0) {
      options = rawStaffOptions
      if (furigana) {
        const regex = japaneseAlphaRegex(furigana)
        options = rawStaffOptions.filter((staff) =>
          staff.furigana_sort.match(regex)
        )
      }
    }
    setStaffOptions((prev) => {
      return {
        ...prev,
        [key]: [{ label: "--", value: 0, furigana_sort: "" }].concat(options),
      }
    })
  }

  const columns: ColumnsType<DataSourceDataType> = useMemo(() => {
    return [
      {
        title: (titleProps) => {
          return (
            <WithSorter
              titleProps={titleProps}
              columnKey={"day"}
              title={t("Day")}
            />
          )
        },
        dataIndex: "day",
        key: "day",
        className: "day-column",
        width: 77,
        sorter: (a, b) => a.day - b.day,
        sortDirections: ["descend", "ascend", "descend"],
      },

      {
        title: t("Day of week"),
        dataIndex: "day_of_week",
        key: "day_of_week",
        className: "day-of-week-column",
        width: 80,
        render: (val, record) => {
          if (record.is_holiday) return null
          return val
        },
      },

      {
        title: t("Program name"),
        dataIndex: "program_id",
        key: "program_id",
        width: 210,
        render: (val, record) => {
          if (record.is_holiday) return null
          return (
            <>
              <div className={"program-name-select-wrapper no-print"}>
                <SelectInput
                  width={171.79}
                  placeholder={"---"}
                  name={`individualDayRecords.${record.key}.program_id`}
                  value={val}
                  className={"program-name-select"}
                  options={programOptions}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue(
                      `individualDayRecords.${record.key}.program_id`,
                      value
                    )
                  }}
                />
              </div>
              <p className={"only-print"}>
                {getlabelFromOptions(programOptions, val)}
              </p>
            </>
          )
        },
      },

      {
        title: t("Details"),
        dataIndex: "details",
        key: "details",
        width: 219,
        render: (val, record) => {
          if (record.is_holiday) return null
          return (
            <>
              <OptimizedTextArea
                formik={formik}
                uniqueKey={record.key}
                fieldName={"details"}
                value={val}
                disabled={!record.program_id || +record.program_id <= 0} // disable if program is not selected
              />
              <p className={"only-print"}>{val}</p>
            </>
          )
        },
      },

      {
        title: t("Goal achievement"),
        dataIndex: "goal_achievement",
        key: "goal_achievement",
        width: 212,
        className: "goal-achievement-column",
        render: (val, record) => {
          if (record.is_holiday) return <p>{t("Holiday ")}</p>
          return (
            <>
              <div
                className={"program-goal-achievement-wrapper flexbox no-print"}
              >
                <SelectInput
                  placeholder={"---"}
                  options={goalAchievementRateOptions}
                  value={val}
                  name={`individualDayRecords.${record.key}.goal_achievement`}
                  onChange={(value) => {
                    setFieldValue(
                      `individualDayRecords.${record.key}.goal_achievement`,
                      value
                    )
                  }}
                />
                <span>
                  <StarFilled />
                </span>
              </div>
              <p className={"only-print"}>
                <span>{val}</span>
                <span>
                  <StarFilled />
                </span>
              </p>
            </>
          )
        },
      },

      {
        title: t("Next Goal"),
        dataIndex: "next_goal",
        key: "next_goal",
        width: 200,
        render: (val, record) => {
          if (record.is_holiday) return null
          return (
            <>
              <OptimizedTextArea
                formik={formik}
                uniqueKey={record.key}
                fieldName={"next_goal"}
                value={val}
                disabled={
                  !record.goal_achievement || record.goal_achievement <= 0 // disable if goal achievement is not selected
                }
              />

              <p className={"only-print"}>{val}</p>
            </>
          )
        },
      },

      {
        title: t("Recorder"),
        dataIndex: "staffId",
        key: "staffId",
        width: 221,
        render: (val, record) => {
          if (record.is_holiday) return null
          return (
            <>
              <div className={"program-recorder-wrapper no-print"}>
                <SelectInput
                  placeholder={"---"}
                  width={169}
                  options={FuriganaAlphabetsOption}
                  name={`individualDayRecords.${record.key}.furigana_sort`}
                  onChange={(value) => {
                    handleFuriganaChange(value, record.key)
                    setFieldValue(
                      `individualDayRecords.${record.key}.furigana_sort`,
                      value
                    )
                    setFieldValue(
                      `individualDayRecords.${record.key}.staffId`,
                      null
                    )
                  }}
                />
                <SelectInput
                  placeholder={"---"}
                  width={169}
                  options={staffOptions?.[record.key] || allStaffOptions}
                  name={`individualDayRecords.${record.key}.staffId`}
                  loading={isStaffListLoading}
                  value={isStaffListLoading ? null : val}
                  onChange={(value) => {
                    setFieldValue(
                      `individualDayRecords.${record.key}.staffId`,
                      value
                    )
                  }}
                />
              </div>
              <p className={"only-print"}>
                {getlabelFromOptions(getFilteredStaffOptions(record.key), val)}
              </p>
            </>
          )
        },
      },
    ]
  }, [programOptions, rawStaffOptions, staffOptions])

  const programCatTable = useMemo(() => {
    const mappedDataSources =
      values.individualDayRecords && Object.values(values.individualDayRecords)
    return (
      <div className={"table-container-custom"}>
        <Table
          columns={columns}
          dataSource={mappedDataSources}
          loading={isRecTableDataLoading}
          scroll={{
            x: "max-content",
          }}
          rowClassName={(record) => {
            return record.is_holiday ? "holiday-row" : ""
          }}
        />
      </div>
    )
  }, [columns, values.individualDayRecords])

  useEffect(() => {
    /**
     * [READ]: This function retrieves the data for the table based on the current date
     * and the initial data that was fetched from the API.
     * If there is data no coming from the API, it will generate dummy row-data for the table
     * for all the days of the current month and set it to the formik values
     * If there is data coming from the API, it will filter/map the fetched-data accordingly
     * and set it to the formik values
     */
    getTableRowData(initialRecTableData, currentDate, formik) // go into the function to see how it works
  }, [currentDate, initialRecTableData])

  return { programCatTable }
}

/**
 * Reason for using this component:
 * 1. The table has a lot of rows and columns, and each row has a lot of input fields
 * 2. The table is re-rendered every time the user types in the input field
 * 3. The typing experience for the user was not smooth and was laggy
 * 4. This component uses the useEffect hook to set the formik values only after the user has stopped typing
 * 5. This way, the table is not re-rendered every time the user types in the input field
 * 6. This improves the performance of the app and the typing experience for the user
 */

const OptimizedTextArea: React.FC<OptimizedTextareaProps> = ({
  value,
  formik,
  disabled,
  uniqueKey,
  fieldName,
}) => {
  const { setFieldValue, handleBlur } = formik
  const [textAreaVal, setTextAreaVal] = useState(value)

  const handleTextAreaChange = (e) => {
    setTextAreaVal(e.target.value)
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setFieldValue(
        `individualDayRecords.${uniqueKey}.${fieldName}`,
        textAreaVal
      )
    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [textAreaVal])

  return (
    <div className={"optimized-text-field no-print"}>
      <TextField
        rows={2}
        maxLength={255}
        type={"textarea"}
        value={textAreaVal}
        onBlur={handleBlur}
        disabled={disabled}
        name={`individualDayRecords.${uniqueKey}.${fieldName}`}
        onChange={(e) => {
          handleTextAreaChange(e)
        }}
      />
    </div>
  )
}
