import { API } from "@project/shared"

export const fetchMonthlyStaffSchedulesByFacility = async ({
  facilityId,
  year,
  month,
}) =>
  API.get(`/monthly-staff-attendance-schedules/${facilityId}/${year}/${month}`)

export const fetchMonthlyStaffRecordsByFacility = async ({
  facilityId,
  year,
  month,
}) =>
  API.get(`/monthly-staff-attendance-records/${facilityId}/${year}/${month}`)

export const getAllStaffList = async (facilityId = 0) =>
  API.get(
    `/instructors?pageSize=Infinity${
      facilityId ? `&facility=${facilityId}` : ""
    }`
  )

export const getStaffMonthlyAdditionalExcelData = ({
  facilityId,
  year,
  month,
}) =>
  API.get(
    `/monthly-staff-attendance-schedules/excel-additions/${facilityId}/${year}/${month}`
  )
