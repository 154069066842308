// packages
import styled from "styled-components"

// theme
import { theme } from "@project/shared"

// constants
const textarea_bg = "#fcfcfc"
const textarea_disabled_bg = "#f5f5f5"

export const Container = styled.div`
  .underline {
    text-decoration: underline;
    color: ${theme.blue5};
  }
  p,
  ul,
  h4 {
    margin-bottom: 0;
  }
`

export const FormBlockContainer = styled.div`
  .form-button-group.btn-block {
    margin-top: 24px;
  }

  .operation-rounded-container {
    margin-top: 30px;
    .form-group {
      margin-top: 24px;

      .child-dropdown-field {
        width: calc(100% - 300px);
        .child-dropdown-container {
          width: 100%;
        }

        @media (max-width: 940px) {
          width: 100%;
        }
      }

      .program-cat-dropdown-field {
        width: calc(100% - 300px);
        .program-cat-dropdown-container {
          width: 100%;
          .dynamic-dropdown-width {
            width: auto;
            max-width: 100%;

            /* Media query for viewport widths between 940px and 1040px */
            @media (min-width: 940px) and (max-width: 1040px) {
              min-width: 100%;
            }
          }
        }

        @media (max-width: 1160px) {
          .dynamic-dropdown-width {
            min-width: 300px;
            width: 100%;
            max-width: 100%;
          }
        }

        @media (max-width: 940px) {
          width: 100%;
        }
      }
    }
    .form-group .label {
      padding: 0.8rem 20px;
    }
    .form-group .field {
      @media (max-width: ${theme.breakpoints.xs}) {
        padding: 20px 0px;
      }
    }

    .form-button-group > {
      margin-top: 24px;
    }

    .fieldGen-field-wrapper .field {
      .fieldGen-flex-container {
        .facility-dropdown-container {
          width: 100%;
          .dynamic-dropdown-width {
            min-width: 180px;
          }
        }

        .fieldGen-children-container {
          width: 100%;
          .multiple-input-container {
            width: 100%;
            .dynamic-dropdown-width {
              min-width: 180px;
              max-width: 800px;
            }
          }
        }
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
      .multiple-input-container.flexbox {
        gap: 0;
        > div:first-child {
          margin-right: 10px;
        }
      }
    }
  }
`

export const GoalAchievementSelectField = styled.div`
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 10px;
    .ant-radio-wrapper {
      display: inline-flex;
      align-items: center;
      margin: 0 !important;
      :hover .ant-radio::after {
        visibility: hidden;
      }
    }
  }
  .radio-label {
    font-size: 18px;
  }
  .ant-radio {
    top: 0;
    :hover::after {
      visibility: hidden;
    }
    .ant-radio-inner {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      width: 25px;
      height: 25px;
      box-shadow: none;
    }
    &.ant-radio-checked {
      .ant-radio-inner {
        ::after {
          background-color: #000;
          transform: scale(0.8);
          transition: none;
        }
      }
    }
    + span {
      line-height: 30px;
    }
  }
`

export const ProgressRecInfoContainer = styled.div`
  .operation-rounded-container {
    margin-top: 30px;

    /* progress record head section styling starts here */
    .table-head {
      justify-content: space-between;
      margin-bottom: 9px;

      .table-title {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        color: ${theme.black};
      }
      .month-controller {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        button {
          padding: 8px 16px;
          height: auto;
          width: auto;
          border-radius: 10px;
          span {
            line-height: 100%;
          }
          &.active {
            background: ${theme.blue6};
            border-color: ${theme.blue6};
          }
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        .month-controller {
          align-self: flex-end;
        }
      }
    }
    /* progress record head section styling ends here */

    /* progress record body section styling starts here */
    .progress-record-info-body {
      margin-top: 24px;
      .print-btn {
        border-color: #0782c8;
        background: #0782c8;
        padding: 11px 12px;
        color: ${theme.base};
        margin-bottom: 20px;
      }

      .table-container-custom {
        .ant-table-container {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          overflow: hidden;
          border-left: 1px solid ${theme.gray2};
          table {
            border-top: 1px solid ${theme.gray2};
            .ant-table-column-sorter {
              display: none;
            }

            .optimized-text-field .ant-input {
              background-color: ${textarea_bg};
              &:disabled {
                background-color: ${textarea_disabled_bg};
              }
            }

            thead.ant-table-thead {
              tr th.ant-table-cell {
                background-color: ${theme.gray2};
                border-bottom: 1px solid ${theme.gray2} !important;
                white-space: break-spaces;
                border-color: ${theme.gray2};
                text-align: center;

                &:before {
                  background-color: ${theme.gray3} !important;
                }

                &:last-child {
                  border-right: 1px solid ${theme.gray2} !important;
                }
              }
            }

            tbody.ant-table-tbody .ant-table-row {
              .ant-table-cell {
                text-align: center;
                padding: 8px;
                border-right: 1px solid ${theme.gray2};
                border-bottom: 1px solid ${theme.gray2};
                background-color: ${theme.base};

                .program-goal-achievement-wrapper {
                  & > div {
                    flex: 1;
                    max-width: 153.4px;
                  }
                  column-gap: 10px;
                  width: 100%;
                  justify-content: center;
                }

                .program-name-select-wrapper {
                  width: 100%;
                  & > div > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                .program-recorder-wrapper {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  padding-left: 20px;
                }
              }

              &.holiday-row {
                .ant-table-cell:not(.program-category-column) {
                  background-color: ${theme.red5} !important;
                  padding: 25px 8px;
                }
              }
            }

            @media print {
              width: 500px !important;
            }
          }
        }
      }

      /* table styling for print mode starts here*/
      @media print {
        .table-container-custom {
          .ant-table-container {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border: none;
            &:before,
            &:after {
              box-shadow: none;
            }
            table {
              overflow: visible !important;
              border: none !important;
              thead.ant-table-thead {
                display: table-header-group;
                tr th.ant-table-cell {
                  outline: none !important;
                  border: 1px solid ${theme.black} !important;
                  border-right: 0 !important;
                  background: transparent !important;
                  padding: 8px 16px;

                  &:before {
                    right: -1px;
                    height: 100%;
                    border-right: 1px solid ${theme.black} !important;
                  }

                  &:after {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    border-top: 1px solid ${theme.black} !important;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.06);
                    content: "";
                  }

                  &:first-child {
                    border-top-left-radius: 0px;
                  }

                  &:last-child {
                    border-right: 1px solid ${theme.black} !important;
                    &:before {
                      position: absolute;
                      top: 50%;
                      border-right: 1px solid black;
                      right: -1px;
                      width: 1px;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.06);
                      transform: translateY(-50%);
                      transition: background-color 0.3s;
                      content: "";
                    }
                  }
                }
              }
              tbody.ant-table-tbody {
                tr.ant-table-row {
                  td.ant-table-cell {
                    border: 1px solid ${theme.black} !important;
                    border-right: 0 !important;
                    border-top: 0 !important;
                    background: transparent !important;

                    &:last-child {
                      border-right: 1px solid ${theme.black} !important;
                    }
                  }

                  &:last-child {
                    .ant-table-cell {
                      border-bottom: 1px solid ${theme.black} !important;
                    }
                  }

                  &:first-child td.ant-table-cell {
                    border-top: 1px solid ${theme.black} !important;
                  }

                  &.holiday-row
                    td.ant-table-cell:not(.program-category-column) {
                    background-color: transparent !important;
                    padding: 8px;
                  }
                }
              }

              tfoot {
                display: table-row-group;
              }
              tr {
                page-break-inside: avoid;
              }
              .sort-group {
                display: none;
              }
            }
          }
        }
      }
      /* table styling for print mode ends here*/
    }
    /* progress record body section styling ends here */

    /* progress record footer section styling starts here */
    .form-button-group {
      margin-top: 24px;
    }
    /* progress record footer section styling ends here */

    /* button container with delete button */
    .button-container-with-delete {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .delete-button {
        background: ${theme.red2};
        border-color: ${theme.red2};
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 24px 20px;
    }

    @media print {
      margin: 0;
      box-shadow: none;
      padding: 0;

      .title-wrapper {
        display: none;
      }
      .progress-record-info-body {
        margin-top: 0;
      }
    }
  }
`

export const PrintOnlyHeaderContent = styled.div`
  .heading {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    background-color: #f3f3f3;
  }

  .more-info {
    justify-content: space-between;
    margin: 10px 0;
    p {
      font-size: 14px;
    }
  }
`

export const ErrorMessage = styled.p`
  color: ${theme.red4};
  font-size: 14px;
`
