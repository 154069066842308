// API
import { API } from "@project/shared"
import { QueryFunctionContext } from "react-query/types/core/types.d"

// Types
export type BankFormatOutputDataProps = {
  child_id: number
  parent_id: number
  child_name: string
  parent_name: string
  facility_id: number
  city_number: string
  facility_name: string
  facility_name_short: string
  provision_city: string
  billing_amount: number
  has_account_info: boolean
  child_name_furigana: string
  parent_name_furigana: string
  facility_name_furigana: string
  receiving_certificate_number: string
  billed_amount?: number
}

export type BankFormatOutputResponseProps = {
  data: {
    [key: string]: Array<BankFormatOutputDataProps>
  }
}

type PayloadProps = {
  facilityIds: number
  year: number
  month: number
}

export const getAllBankFormatOutput = async (
  obj?: QueryFunctionContext<any>
): Promise<BankFormatOutputResponseProps> => {
  const payload = obj.queryKey[1] as PayloadProps
  // if any of the data is null or undefined then remove it from the payload
  Object.keys(payload).forEach((key) => {
    if (!payload[key]) {
      payload[key] = undefined
    }
  })

  return API.get(`/revenue-management/nhif/child_output_table`, {
    params: payload,
  })
}
