import {
  Checkboxes,
  theme,
  Checkbox,
  DIGITALSIGN_OPTION,
  PRINT_SETTINGS_ENHANCED,
  ADDITIONAL_ITEM_ENHACED,
  INITIAL_PRINT_SETTINGS_ENHANCED,
  INITIAL_DIGITALSIGN_OPTION,
} from "@project/shared"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Title } from "../../../utils"
import { OwnerButton } from "../../atoms/OwnerButton"
import { useRouter } from "next/router"
import { Checkbox as AntdCheckbox, Radio } from "antd"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  childIds?: string
  marginBottom?: string
  handleSearch?: (value: string) => void
  setIsButtonClicked?: React.Dispatch<any>
  handleSearchReset?: () => void
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      year?: any
      month?: any
      content_of_remarks: any[]
      addition_items: any[]
      show_digital_signature: string
      show_non_digital_signature: string
      showAbsence: string
    }>
  >
  queryParam?: {
    year?: any
    month?: any
    content_of_remarks: any[]
    addition_items: any[]
    show_digital_signature: string
    show_non_digital_signature: string
    showAbsence: string
  }
  setCurrentPage?: (val: number) => void
  isLoadingData?: boolean
  year?: any
  month?: any
  handleDisplayChange?: ({ year, month }: { year: any; month: any }) => void
  FACILITY_OPTION?: {
    value: string
    label: string
  }[]
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }
  .reset-button {
    margin-left: 9.5px;
  }
  .select-down {
    margin-right: 18px;
  }
  .title-wrap {
    min-width: 125px;
    max-width: 125px;

    @media (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .select-up {
    margin-right: 6px;
  }
  /* @media (max-width: 767px) {
      .select-up {
        width: 100%;
      }
    } */
  @media (max-width: 456px) {
    .search-btn {
      font-size: 14px;
    }
    .reset-button {
      font-size: 14px;
    }
  }
  @media print {
    display: none;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .text-field {
    @media (max-width: 769px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .facility-wrapper {
    display: flex;
    .reset-button,
    .set-button {
      height: 30px;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }

  .check-button {
    height: 30px !important;
    margin-left: 10px;
  }

  .info-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #e00000;
  }

  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 456px) {
    .facility-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .checkbox-wrapper {
      span {
        font-size: 14px !important;
      }
    }
    .info-wrapper {
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 5px;
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .textfield {
    margin-left: 30px;
  }
`
const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
    }
  }
`
const Text = styled(Title)`
  font-size: 16px;
  @media (max-width: 456px) {
    font-size: 14px;
  }
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: pre-wrap;
  width: 100%;
  .ant-checkbox + span {
    white-space: pre-wrap;
  }

  .check-box {
    white-space: pre-wrap;
  }
  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .calender {
    display: flex;
    gap: 9.5px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }
  }
  .date-picker {
    height: 40px;
    width: 160px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .select-down {
      width: 100%;
      margin-right: 0;
    }
    .select-class {
      margin-top: 5px;
      width: 100%;
    }
  }

  @media (max-width: 590px) {
    .date-picker {
      width: auto;
    }
  }
`

const StyledCheckboxes = styled(AntdCheckbox.Group)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const ServiceProvisionPerformanceRecordSheetPrintDetail: React.FC<
  iOperationOptionComponent
> = (props) => {
  const { header, isLoadingData, setQueryParam, setIsButtonClicked, childIds } =
    props
  const { t } = useTranslation()
  const router = useRouter()
  const [printSetting, setPrintSetting] = useState(null)
  const [remark, setRemark] = useState([])
  const [additionalItem, setAdditionalItem] = useState(null)
  const [showAbsence, setShowAbsence] = useState(null)
  const [digitalSign, setDigitalSign] = useState(null)
  const [showUnusedFacility, setShowUnusedFacility] = useState(null)

  const [printSettingOptions, setPrintSettingOptions] = useState(
    INITIAL_PRINT_SETTINGS_ENHANCED
  )
  const [digitalSignOpt, setDigitalSignOpt] = useState(
    INITIAL_DIGITALSIGN_OPTION
  )
  const [radioValue, setRadioValue] = useState("5")

  const handleSearch = () => {
    setIsButtonClicked(true)
    const params = {
      content_of_remarks: remark,
      addition_items: additionalItem,
      show_digital_signature: digitalSign,
      show_non_digital_signature: printSetting,
      showAbsence: showAbsence,
      showUnusedFacility: showUnusedFacility,
    }
    if (remark?.includes("3")) {
      if (!remark?.includes("5") && !remark?.includes("6")) {
        setRemark((prev) => [...prev, radioValue])
      }
    }
    setQueryParam((prev) => {
      return {
        ...prev,
        ...params,
      }
    })
    router.push(
      `/user-service-provision-result/service-provision-performance-record-sheet-print/print/${childIds}`
    )
  }

  /**
   * the following use effects is
   * to handle different dynamic checkbox options
   * for search param component
   */
  useEffect(() => {
    if (printSetting?.includes("6")) {
      setPrintSettingOptions(PRINT_SETTINGS_ENHANCED)
    }
    if (printSetting?.includes("8")) {
      setPrintSettingOptions((prev) => {
        return [
          ...prev,
          {
            label: t(
              "Reflect the desired pick-up time and desired drop-off time in the start time and end time"
            ),
            value: "14",
          },
        ]
      })
    }
    if (digitalSign?.includes("1")) {
      setDigitalSignOpt(DIGITALSIGN_OPTION)
    }
    return () => {
      setPrintSettingOptions(INITIAL_PRINT_SETTINGS_ENHANCED)
      setDigitalSignOpt(INITIAL_DIGITALSIGN_OPTION)
    }
  }, [printSetting, digitalSign])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Print configuration")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={printSetting}
            onChange={(values) => setPrintSetting(values)}
            options={printSettingOptions}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Absence information")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={showAbsence}
            label={t(
              "Absence (No addition)、Display information that does not calculate Absence Support Addition II"
            )}
            onChange={(evt) => {
              setShowAbsence(evt.target.checked ? "1" : null)
            }}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Contents of remarks")} </Text>
        </TitleWrap>
        <SelectWrap>
          <StyledCheckboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={remark}
            onChange={(values) => setRemark(values)}
          >
            <Checkbox
              name={"footerInformation"}
              value={"1"}
              label={t("Remarks")}
            />
            <Checkbox
              name={"footerInformation"}
              value={"2"}
              label={t(
                "Remarks output when making a request to the National Health Insurance Federation"
              )}
            />
            <Checkbox
              name={"footerInformation"}
              value={"3"}
              label={t("Actual expenses/expenses")}
            />
            {remark?.includes("3") ? (
              <Radio.Group
                style={{ paddingLeft: "10px" }}
                value={radioValue}
                onChange={(e) => setRadioValue(e.target.value)}
              >
                <Radio value={"5"}>
                  {t("Does not include the actual cost of 0 yen")}
                </Radio>
                <Radio value={"6"}>{t("Include actual cost of 0 yen")}</Radio>
              </Radio.Group>
            ) : null}
            <Checkbox
              name={"footerInformation"}
              value={"4"}
              label={t("Reasons for extra charge for absenteeism")}
            />
          </StyledCheckboxes>
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Addition item")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={additionalItem}
            onChange={(values) => setAdditionalItem(values)}
            options={ADDITIONAL_ITEM_ENHACED}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Digital Sign Settings")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={digitalSign}
            onChange={(values) => setDigitalSign(values)}
            options={digitalSignOpt}
          />
        </SelectWrap>
      </SearchWrapper>
      <SearchWrapper>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={showUnusedFacility}
            label={t("Show unused facilities")}
            className={"check-box"}
            onChange={(evt) => {
              setShowUnusedFacility(evt.target.checked ? "1" : null)
            }}
          />
        </SelectWrap>
      </SearchWrapper>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ServiceProvisionPerformanceRecordSheetPrintDetail }
