import {
  Checkboxes,
  RadioButtons,
  SelectInput,
  CALANDER_HOURS_MINUTES,
  theme,
  PICK_DROP_OPTIONS,
  TextField,
  PICK_DROP_CDS_OPTIONS,
} from "@project/shared"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { weekDays } from "../constants/date"
import { truncate } from "./string"
import { checkEmptyOrNull } from "./validation"
import styled from "styled-components"

export const serviceOptions = [
  { label: "自宅", value: 1 },
  { label: "学校", value: 2 },
]

export const SAME_VALUE_PICK_DROP = ["home", "school"]

const DisplayOnlyRadio = styled(RadioButtons)`
  display: flex;
  justify-content: center;
  .ant-radio-wrapper {
    margin: 0;
    flex-direction: column;
    gap: 0;
    &:after {
      display: none;
    }
  }
`

export const generateSchedules = (
  childId: number,
  year: number,
  month: number,
  schedules: any,
  facility_holidays: any,
  contractedFacilities: any,
  facility_programs: any
) => {
  // Get all populated individual facility days in this month
  const facilityDataDates = schedules?.map((day) => day?.date?.slice(0, 10))
  const daysInMonth = new Date(year, month, 0).getDate()
  const fullMonthSchedules = []
  for (let i = 1; i <= daysInMonth; i++) {
    // Get this iteration date in `YYYY-MM-DD` format (like facilityDataDates)
    const iterationDate = `${year}-${month.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${i.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`

    const programOnThisDay = Array.isArray(facility_programs)
      ? facility_programs?.find(
          (program) => program?.date?.slice(0, 10) == iterationDate
        )
      : []

    // If individual facility for day exists in db then populate it else push non applied row
    const indexOfFacility = facilityDataDates?.indexOf(iterationDate)
    if (indexOfFacility >= 0) {
      const cleanedSchedule = {
        ...schedules[indexOfFacility],
        date: iterationDate,
        go_to_meet_time: schedules[indexOfFacility]?.go_to_meet_flag
          ? schedules[indexOfFacility]?.go_to_meet_time?.slice(0, 6)
          : "",
        take_time: schedules[indexOfFacility]?.take_flag
          ? schedules[indexOfFacility]?.take_time?.slice(0, 6)
          : "",
        goto_meet_place: schedules[indexOfFacility]?.go_to_meet_flag
          ? schedules[indexOfFacility]?.goto_meet_place
          : "",
        goto_meet_place_name: schedules[indexOfFacility]?.go_to_meet_flag
          ? schedules[indexOfFacility]?.goto_meet_place
          : "",
        take_place: schedules[indexOfFacility]?.take_flag
          ? schedules[indexOfFacility]?.take_place
          : "",
        take_place_name: schedules[indexOfFacility]?.take_flag
          ? schedules[indexOfFacility]?.take_place
          : "",
        is_holiday:
          schedules[indexOfFacility]?.is_holiday == "1"
            ? true
            : !schedules[indexOfFacility]?.facility_id
            ? false
            : facility_holidays
                ?.find(
                  (f) =>
                    f?.facility_id == schedules[indexOfFacility]?.facility_id
                )
                ?.holidays?.includes(iterationDate),
        is_created_by_facility:
          schedules[indexOfFacility]?.is_created_by_facility,
        old_facility_id: schedules[indexOfFacility]?.facility_id,
        programs: programOnThisDay,
      }
      // Remove these fields as api throws error trying to parse them
      delete cleanedSchedule.created_datetime
      delete cleanedSchedule.updated_datetime
      fullMonthSchedules.push(cleanedSchedule)
    } else {
      fullMonthSchedules.push({
        date: iterationDate,
        child_id: childId,
        is_applied: false,
        go_to_meet_flag: false,
        go_to_meet_time: null,
        goto_meet_place: null,
        take_flag: false,
        take_time: null,
        take_place: null,
        remarks: null,
        goto_meet_place_name: null,
        take_place_name: null,
        is_holiday: holidayCheckForEditPage(
          iterationDate,
          facility_holidays,
          contractedFacilities
        ),
        is_cancel_waiting: false,
        is_created_by_facility: false,
        programs: programOnThisDay,
      })
    }
  }
  return fullMonthSchedules
}

const holidayCheckForEditPage = (
  date,
  allFacilityHolidays,
  contractedFacilities
) => {
  // returns true only if the day is holiday for all of the applicable facilities
  // useful on edit page
  if (contractedFacilities.length == 0) return false
  for (let i = 0; i < contractedFacilities.length; i++) {
    if (
      !allFacilityHolidays
        ?.find((f) => f?.facility_id == contractedFacilities[i]?.value)
        ?.holidays?.includes(moment(date).format("YYYY-MM-DD"))
    )
      return false
  }
  return true
}

const checkHoliday = (date, allFacilityHolidays, scheduledFacilities) => {
  // returns true if the day is holiday if it is holiday for any of applicable facility
  // useful on list page
  for (let i = 0; i < scheduledFacilities.length; i++) {
    if (
      allFacilityHolidays
        ?.find((f) => f?.facility_id === scheduledFacilities[i])
        ?.holidays?.includes(moment(date).format("YYYY-MM-DD"))
    )
      return true
  }
  return false
}

export const generateListApplicationData = (
  schedules,
  facility_holidays,
  isCDSChild = false
) => {
  const data = []
  const TRANSPORT_OPTIONS = isCDSChild
    ? PICK_DROP_CDS_OPTIONS
    : PICK_DROP_OPTIONS
  const scheduledFacilityIds = schedules
    ?.map((s) => {
      if (s?.facility_id) return s?.facility_id
    })
    .filter((item, i, ar) => ar.indexOf(item) === i)
  schedules.forEach((day, i) => {
    data.push({
      is_applied: day.is_applied,
      day: (i + 1).toString(),
      week: weekDays[new Date(day?.date).toDateString().slice(0, 3)],
      is_holiday: day?.is_holiday
        ? true
        : checkHoliday(day?.date, facility_holidays, scheduledFacilityIds),
      facility: day.facility_name,
      participate: day.facility_id,
      pick: +day.go_to_meet_flag ? (
        <DisplayOnlyRadio
          options={[{ label: "", value: 1 }]}
          value={+day.go_to_meet_flag}
        />
      ) : (
        <></>
      ),
      drop: +day.take_flag ? (
        <DisplayOnlyRadio
          options={[{ label: "", value: 1 }]}
          value={+day.take_flag}
        />
      ) : (
        <></>
      ),
      pick_place: SAME_VALUE_PICK_DROP.includes(day.goto_meet_place)
        ? TRANSPORT_OPTIONS.find(
            (option) => day.goto_meet_place === option.value
          )?.label
        : day.goto_meet_place &&
          TRANSPORT_OPTIONS?.find((o) => o.value == "others")?.label +
            ` (${day.goto_meet_place})`,
      pick_place_name: !SAME_VALUE_PICK_DROP.includes(day.goto_meet_place)
        ? day.goto_meet_place
        : "",
      drop_place: SAME_VALUE_PICK_DROP.includes(day.take_place)
        ? TRANSPORT_OPTIONS.find((option) => day.take_place == option.value)
            ?.label
        : day.take_place &&
          TRANSPORT_OPTIONS?.find((o) => o.value == "others")?.label +
            ` (${day.take_place})`,
      drop_place_name: !SAME_VALUE_PICK_DROP.includes(day.take_place)
        ? day.take_place
        : "",
      pick_time: day.go_to_meet_time,
      drop_time: day.take_time,
      remarks: truncate(day.remarks, 10),
      remarks_full: day.remarks,
      programs: day?.programs,
      facility_name: day?.facility_name,
      go_to_meet_flag: day?.go_to_meet_flag,
      take_flag: day?.take_flag,
      complete_remarks: day?.remarks,
      is_cancel_waiting: day?.is_cancelled_waiting,
      is_created_by_facility: day?.is_created_by_facility,
    })
  })
  return data
}

// Generate table data for monthly individual facility applications
export const generateMonthlyApplications = (
  schedules: any,
  setSchedules: any,
  facility_holidays: any,
  setApplicationCount?: any,
  isASDService?: boolean
) => {
  const { t } = useTranslation()
  const data = []

  // Toggle fields like checkboxes, radio buttons or select
  const toggleField = (
    fieldName: string,
    newValue: boolean | number | string,
    i: number
  ) => {
    const currentSchedule = schedules[i]
    // Increase number only if the schedule wasn empty from the beginning i.e without any inputs
    if (
      checkEmptyOrNull([
        currentSchedule?.facility_id,
        currentSchedule?.go_to_meet_flag,
        currentSchedule?.go_to_meet_time,
        currentSchedule?.goto_meet_place,
        currentSchedule?.goto_meet_place_name,
        currentSchedule?.take_flag,
        currentSchedule?.take_time,
        currentSchedule?.take_place,
        currentSchedule?.take_place_name,
        currentSchedule?.remarks,
      ])
    ) {
      // Increase legends numbers by 1 if a schedule is cancelled
      if (typeof setApplicationCount != "undefined")
        setApplicationCount((prev) => Number(prev) + 1)
    }
    const sched = { ...schedules[i], is_applied: true, [fieldName]: newValue }

    if (fieldName === "facility_id") {
      sched["go_to_meet_flag"] = true
      sched["take_flag"] = true
      sched["goto_meet_place"] = "home"
      sched["take_place"] = "home"
    } else if (fieldName == "go_to_meet_flag") {
      if (!newValue) {
        sched["goto_meet_place"] = ""
        sched["goto_meet_place_name"] = ""
      } else {
        sched["goto_meet_place"] = "home"
        sched["goto_meet_place_name"] = "home"
      }
    } else if (fieldName == "take_flag") {
      if (!newValue) {
        sched["take_place"] = ""
        sched["take_place_name"] = ""
      } else {
        sched["take_place"] = "home"
        sched["take_place_name"] = "home"
      }
    } else if (fieldName == "goto_meet_place") {
      if (!SAME_VALUE_PICK_DROP.includes(newValue.toString())) {
        sched["goto_meet_place_name"] = ""
      }
    } else if (fieldName == "take_place") {
      if (!SAME_VALUE_PICK_DROP.includes(newValue.toString())) {
        sched["take_place_name"] = ""
      }
    }

    setSchedules([
      ...schedules.slice(0, i),
      sched,
      ...schedules.slice(i + 1, schedules.length),
    ])
  }
  // Reset one field
  const resetOneField = (i: number) => {
    const currentSchedule = schedules[i]
    // Decrease number only if the schedule wasn't empty from the beginning i.e already had some valid inputs
    if (
      !checkEmptyOrNull([
        currentSchedule?.facility_id,
        currentSchedule?.go_to_meet_flag,
        currentSchedule?.go_to_meet_time,
        currentSchedule?.goto_meet_place,
        currentSchedule?.goto_meet_place_name,
        currentSchedule?.take_flag,
        currentSchedule?.take_time,
        currentSchedule?.take_place,
        currentSchedule?.take_place_name,
        currentSchedule?.remarks,
        currentSchedule?.is_created_by_facility,
      ])
    ) {
      // Decrease legends numbers by 1 if a schedule is cancelled
      if (typeof setApplicationCount != "undefined")
        setApplicationCount((prev) => Number(prev) - 1)
    }

    setSchedules([
      ...schedules.slice(0, i),
      {
        ...schedules[i],
        is_applied: false,
        old_facility_id: schedules[i]?.facility_id,
        facility_id: null,
        go_to_meet_flag: false,
        go_to_meet_time: null,
        goto_meet_place: null,
        goto_meet_place_name: null,
        take_flag: null,
        take_time: null,
        take_place: null,
        take_place_name: null,
        remarks: "",
      },
      ...schedules.slice(i + 1, schedules.length),
    ])
  }

  schedules.forEach((day, i) => {
    data.push({
      // Handle form components here for simplicity
      day: (i + 1).toString(),
      week: weekDays[new Date(day?.date).toDateString().slice(0, 3)],
      is_holiday: day?.is_holiday,
      facility: (facilityId) => (
        <>
          <RadioButtons
            options={[{ label: "", value: facilityId }]}
            value={day?.is_applied ? day?.facility_id : 0}
            disabled={
              facility_holidays
                ?.find((f) => f?.facility_id == facilityId)
                ?.holidays?.includes(day?.date) ?? false
            }
            onChange={() => {
              toggleField("facility_id", facilityId, i)
            }}
          />

          {day?.programs?.programs ? (
            <div className={"program-container"}>
              {day?.programs?.programs?.split(",")?.map((program) => (
                <div className={"one-program"} key={program}>
                  {program}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </>
      ),
      pick: (
        <Checkboxes
          disabled={!day.is_applied}
          options={[{ label: "", value: "pick" }]}
          value={day?.go_to_meet_flag ? "pick" : null}
          onChange={(v) => {
            toggleField("go_to_meet_flag", v ? !!v.length : false, i)
          }}
        />
      ),
      drop: (
        <Checkboxes
          disabled={!day.is_applied}
          options={[{ label: "", value: "pick" }]}
          value={day?.take_flag ? "pick" : null}
          onChange={(v) => toggleField("take_flag", v ? !!v.length : false, i)}
        />
      ),
      pick_place: (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <SelectInput
            width={80}
            height={40}
            options={isASDService ? PICK_DROP_OPTIONS : PICK_DROP_CDS_OPTIONS}
            className={"select-up"}
            disabled={!day.go_to_meet_flag}
            onChange={(v) => toggleField("goto_meet_place", v, i)}
            value={
              day.go_to_meet_flag
                ? SAME_VALUE_PICK_DROP.includes(day?.goto_meet_place)
                  ? day?.goto_meet_place
                  : day?.goto_meet_place && "others"
                : ""
            }
          />
          {day?.goto_meet_place &&
            !SAME_VALUE_PICK_DROP.includes(day?.goto_meet_place) && (
              <div style={{ padding: "5px 0px" }}>
                <TextField
                  maxLength={100}
                  height={"40px"}
                  bgcolor={"#ffffff"}
                  value={day?.goto_meet_place_name}
                  disabled={!day.go_to_meet_flag}
                  onChange={(v) =>
                    toggleField("goto_meet_place_name", v.target.value, i)
                  }
                />
              </div>
            )}
        </div>
      ),
      drop_place: (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <SelectInput
            width={75}
            height={40}
            options={isASDService ? PICK_DROP_OPTIONS : PICK_DROP_CDS_OPTIONS}
            className={"select-up"}
            disabled={!day.take_flag}
            onChange={(v) => toggleField("take_place", v, i)}
            value={
              day.take_flag
                ? SAME_VALUE_PICK_DROP.includes(day?.take_place)
                  ? day?.take_place
                  : day?.take_place && "others"
                : ""
            }
          />
          {day?.take_place && !SAME_VALUE_PICK_DROP.includes(day?.take_place) && (
            <div style={{ padding: "5px 0px" }}>
              <TextField
                maxLength={100}
                height={"40px"}
                bgcolor={"#ffffff"}
                value={day?.take_place_name}
                disabled={!day.take_flag}
                onChange={(v) =>
                  toggleField("take_place_name", v.target.value, i)
                }
              />
            </div>
          )}
        </div>
      ),
      pick_desire_time1: (
        <SelectInput
          width={64}
          height={40}
          options={CALANDER_HOURS_MINUTES.hours}
          className={"select-up"}
          disabled={!day.go_to_meet_flag}
          onChange={(v) => {
            const newTime = `${v}:${
              day?.go_to_meet_time?.length > 2
                ? day?.go_to_meet_time?.slice(3, 5)
                : "00"
            }`
            toggleField("go_to_meet_time", newTime, i)
          }}
          value={
            day?.go_to_meet_time?.length && day?.go_to_meet_time[0] === ":"
              ? "--"
              : day?.go_to_meet_time?.slice(0, 2)
          }
        />
      ),
      pick_desire_time2: (
        <SelectInput
          width={64}
          height={40}
          options={CALANDER_HOURS_MINUTES.minutes}
          className={"select-up"}
          disabled={
            !day?.go_to_meet_time?.slice(0, 2) ||
            !day?.goto_meet_place ||
            !day.is_applied ||
            !day?.go_to_meet_flag ||
            (day?.go_to_meet_time?.length && day?.go_to_meet_time[0] === ":")
          }
          onChange={(v) =>
            toggleField(
              "go_to_meet_time",
              `${day?.go_to_meet_time?.slice(0, 2)}:${v}`,
              i
            )
          }
          value={day?.go_to_meet_time?.slice(3, 5)}
        />
      ),
      drop_desire_time1: (
        <SelectInput
          width={64}
          height={40}
          options={CALANDER_HOURS_MINUTES.hours}
          className={"select-up"}
          disabled={!day.take_flag}
          onChange={(v) => {
            const newTime = `${v}:${
              day?.take_time?.length > 2 ? day?.take_time?.slice(3, 5) : "00"
            }`
            toggleField("take_time", newTime, i)
          }}
          value={
            day?.take_time?.length && day?.take_time[0] === ":"
              ? "--"
              : day?.take_time?.slice(0, 2)
          }
        />
      ),
      drop_desire_time2: (
        <SelectInput
          width={64}
          height={40}
          options={CALANDER_HOURS_MINUTES.minutes}
          className={"select-up"}
          disabled={
            !day?.take_time?.slice(0, 2) ||
            !day?.take_place ||
            !day.is_applied ||
            !day?.take_flag ||
            (day?.take_time?.length && day?.take_time[0] === ":")
          }
          onChange={(v) =>
            toggleField("take_time", `${day?.take_time?.slice(0, 2)}:${v}`, i)
          }
          value={day?.take_time?.slice(3, 5)}
        />
      ),
      remarks: (
        <TextArea
          style={{
            resize: "none",
            border: "1px solid #d2d1d1",
            borderRadius: "5px",
            width: "124px",
          }}
          rows={4}
          value={day?.remarks}
          disabled={!day.is_applied}
          onChange={(v) => toggleField("remarks", v.target.value, i)}
        />
      ),
      cancel: (
        <span
          style={{
            color: theme.blue5,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            resetOneField(i)
          }}
        >
          {t("Cancel")}
        </span>
      ),
    })
  })
  return data
}

interface RecurringScheduleAlertResponse {
  has_setting: boolean
  facility_name: string
  year_month: string
  all_days_reflected: boolean
  days: string
}

export const generateRecurringScheduleAlertObject = (
  schedules: [any],
  holidays: [any]
): RecurringScheduleAlertResponse => {
  const response: RecurringScheduleAlertResponse = {
    has_setting: false,
    facility_name: "",
    year_month: "",
    all_days_reflected: false,
    days: "",
  }
  const datesWithRecurring = schedules?.filter(
    (schedule) => schedule?.is_recurring_setting
  )
  if (datesWithRecurring.length) {
    response.has_setting = true
    response.facility_name = datesWithRecurring?.[0]?.facility_name
    response.year_month = datesWithRecurring?.[0]?.recurring_setting_used
    const daysInMonth = moment(
      datesWithRecurring?.[0]?.date?.split("T")?.[0]
    ).daysInMonth()
    const all_days_reflected =
      daysInMonth - holidays.length == datesWithRecurring.length
    response.all_days_reflected = all_days_reflected

    if (!all_days_reflected) {
      response.days = datesWithRecurring
        ?.map((reoccur) =>
          moment(reoccur?.date?.split("T")?.[0])?.format("YYYY年MM月DD日")
        )
        ?.join(", ")
    }
  }
  return response
}
