import { NumberProps } from "./types"

export function BeneficiaryNumber({ number }: NumberProps) {
  return (
    <div className={"beneficiary_cont"}>
      <div className={"beneficiary_no_total"}>
        {number
          .padEnd(10, ".")
          .split("")
          .map((char, index) => (
            <div key={index} className={"value_total"}>
              {char !== "." ? char : ""}
            </div>
          ))}
      </div>
    </div>
  )
}
