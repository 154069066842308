import { useState } from "react"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"
import moment from "moment"

// styled components
import { ActualCostBulkEditContainer } from "./styles"

// components
import { OwnerButton, OwnerTable } from "../.."
import { TopRoundedWhiteCard } from "@project/shared"

// hooks
import useTable from "./hooks/useTable"

// types
import { ActualCostBurdenBulkEditPageProps } from "./types"
import { useMutation, useQuery } from "react-query"
import {
  bulkUpdateActualCostBurdenUserRecord,
  fetchActualCostBurdenUserRecord,
} from "../../../services/actualCostBurdenRecord"
import { notification } from "antd"

export const ActualCostBurdenBulkEditPage = ({
  currentDate,
  currentFacilityId,
}: ActualCostBurdenBulkEditPageProps) => {
  const router = useRouter()
  const { id, facilityId } = router.query
  const year = moment(currentDate).format("YYYY")
  const month = moment(currentDate).format("MM")
  const day = moment(currentDate).format("DD")
  const [tableData, setTableData] = useState([])

  const handleInputChange = (rowId: number, key: string, value: number) => {
    const data = tableData?.map((val) =>
      val?.id === rowId ? { ...val, [key]: value } : val
    )
    setTableData(data)
  }
  const { columns } = useTable(handleInputChange)

  useQuery({
    queryKey: ["fetch-bulk-actual-burden-user-record"],
    queryFn: () =>
      fetchActualCostBurdenUserRecord(
        +id,
        moment(currentDate).format("YYYY-MM-DD"),
        +facilityId
      ),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    onSuccess: ({ data }) => {
      setTableData(data)
    },
  })

  const { isLoading, mutate: bulkUpdateCostBurden } = useMutation(
    bulkUpdateActualCostBurdenUserRecord,
    {
      onSuccess: () => {
        notification.success({
          message: `${t("Updated Successfully")}`,
        })
        router.back()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  // TODO: HANDLE BULK EDIT SUBMIT HERE
  const handleSubmit = () => {
    bulkUpdateCostBurden(tableData)
  }

  let disablebtn = false

  tableData.forEach((item) => {
    if (!item.cost) {
      disablebtn = true
    }
  })

  return (
    <ActualCostBulkEditContainer>
      <TopRoundedWhiteCard
        className={"white-card"}
        title={t("Bulk Edit Actual Cost Burden Record Table")}
      >
        {/* Table section starts here */}
        <OwnerTable
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: "max-content",
          }}
        />

        {/* Table section ends here */}

        {/* Button section starts here */}
        <div className={"action-btn-group flexbox"}>
          <OwnerButton
            className={"action-btn"}
            onClick={() => {
              handleSubmit()
            }}
            typeOf={"primary"}
            text={t("Save")}
            isLoading={isLoading}
            disabled={disablebtn}
          />
          <OwnerButton
            className={"action-btn"}
            onClick={() => {
              router.push(
                `/actual-cost-burden/${id}/details?year=${year}&month=${month}&day=${day}&facilityId=${currentFacilityId}`
              )
            }}
            typeOf={"secondary"}
            text={t("Cancel")}
          />
        </div>
      </TopRoundedWhiteCard>
    </ActualCostBulkEditContainer>
  )
}
