import { PRINT_STYLE_VALUES } from "../../../constants"
import {
  ReceiptSiblingUpperLimitManagementItem,
  SiblingChild,
  SiblingSheet,
} from "../../../types"
import { DefaultFlame } from "./flame/DefaultFlame"
import { HiroshimaFlame } from "./flame/HiroshimaFlame"
import { KisarazuFlame } from "./flame/KisarazuFlame"
import { KobeFlame } from "./flame/KobeFlame"
import { KyotoFlame } from "./flame/KyotoFlame"
import { OtaFlame } from "./flame/OtaFlame"
import { ShizuokaFlame } from "./flame/ShizuokaFlame"

// Header 表示用の 児童リスト（ユニーク） を line_no 順に取得
function makeChildren(facilities: ReceiptSiblingUpperLimitManagementItem[]) {
  const children = new Map<number, SiblingChild>()
  facilities.forEach(
    ({
      child_id,
      child_name,
      receiving_certificate_number,
      line_no,
      local_burden_max_month,
    }) => {
      children.set(child_id, {
        child_id,
        child_name,
        receiving_certificate_number,
        line_no,
        local_burden_max_month,
        child_no: 0,
      })
    }
  )

  return Array.from(children.values())
    .sort((a, b) => a.line_no - b.line_no)
    .map((child, i) => {
      child.child_no = i + 1
      return child
    })
}

type Props = {
  sheet: SiblingSheet
  printStyle: string
  printConfig: string[]
}

/**
 * 利用者負担額上限管理結果票（複数児童バージョン）
 */
export function SiblingChildSheet({
  sheet,
  printConfig = [],
  printStyle = "",
}: Props) {
  const props = {
    receiptSiblingUpperLimitManagement:
      sheet.receiptSiblingUpperLimitManagement,
    printConfig,
    siblingChildren: makeChildren(
      sheet.receiptSiblingUpperLimitManagement.facilities
    ),
  }

  switch (printStyle) {
    case PRINT_STYLE_VALUES.KOBE:
      return <KobeFlame {...props} />

    case PRINT_STYLE_VALUES.HIROSHIMA:
      return <HiroshimaFlame {...props} />

    case PRINT_STYLE_VALUES.KISARAZU:
      return <KisarazuFlame {...props} />

    case PRINT_STYLE_VALUES.OTA:
      return <OtaFlame {...props} />

    case PRINT_STYLE_VALUES.KYOTO:
      return <KyotoFlame {...props} />

    case PRINT_STYLE_VALUES.SHIZUOKA:
      return <ShizuokaFlame {...props} />
  }

  return <DefaultFlame {...props} />
}
