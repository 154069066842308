// types.d.ts:
import {
  CommonOption,
  RecTableDataSourcesProps,
  RecTablePayloadDataProps,
} from "../types"

export const recTableFinalDataMapper = (
  data: RecTableDataSourcesProps
): RecTablePayloadDataProps[] => {
  const ValueArray = Object.values(data.individualDayRecords)
  return ValueArray.map((item) => ({
    date: item.date,
    details: item.details,
    goal_achievement: `${item.goal_achievement}`,
    next_goal: item.next_goal,
    program_id: item.program_id,
    staffId: item.staffId,
  }))
}

export const getlabelFromOptions = (options: CommonOption[], value: number) => {
  const targetOption = options?.find((item) => item.value === value)
  return targetOption?.label
}

export * from "./getTableRowData"
export * from "./validation"
