// packages
import styled from "styled-components"

// themes
import { theme } from "@project/shared"

export const ActualCostBulkEditContainer = styled.div`
  margin-top: 40px;

  .white-card {
    padding: 13px 30px 39px 30px;
    .ant-table .ant-table-container .ant-table-content table {
      .ant-table-thead tr th {
        background: ${theme.bgColor2};
        border-bottom: 1px solid ${theme.gray2};
        border-top: 1px solid ${theme.gray2};
        &:first-child {
          border-left: 1px solid ${theme.gray2};
        }

        &:last-child {
          border-right: 1px solid ${theme.gray2};
        }

        /* This is the little line on the right of every table header acts like a border */
        &:before {
          background-color: ${theme.gray2} !important;
        }

        .custom-table-column-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 14px;
          .sort-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 6px;
            span {
              display: grid;
            }
          }
        }
      }
      .ant-table-tbody tr td {
        border-right: 1px solid ${theme.gray2};
        border-bottom: 1px solid ${theme.gray2};
        &:first-child {
          border-left: 1px solid ${theme.gray2};
        }
        p {
          margin: 0;
        }
      }
    }

    .action-btn-group {
      justify-content: flex-start;
      margin-top: 24px;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 13px 20px 15px 20px;
    }
  }

  .flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`
