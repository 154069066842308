import moment from "moment"
import { Typography } from "antd"
import { useQuery } from "react-query"
import styled from "styled-components"
import { useRouter } from "next/router"
import { DatePicker } from "../DatePicker"
import { useTranslation } from "react-i18next"
import { getAllChildUnpaginated } from "../../../services"
import { OwnerButton } from "../../atoms/OwnerButton"
import React, { useState, useContext, useEffect } from "react"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import {
  SelectInput,
  Checkboxes,
  Checkbox,
  theme,
  setItemToLocalstorage,
} from "@project/shared"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import { Highlighter, Title, TitleWrapper, AuthContext } from "../../../utils"
import { japaneseAlphaRegex } from "../../../utils/validation"

interface iOperationOptionComponent {
  addButtonText?: string
  addButtonLink?: string
  searchText?: string
  header: string
  marginTop?: string
  isLoading?: boolean
  marginBottom?: string
  handleSearch?: (value: string) => void
  handleSearchReset?: () => void
  setIsButtonClicked?: React.Dispatch<any>
  setQueryParam?: React.Dispatch<
    React.SetStateAction<{
      display: string
      status: string
      child_firstname: string
      child_lastname: string
      facilityIds: string
      child_monitoring_type?: any
      digital_signature_blank?: any
    }>
  >
  queryParam?: {
    display: string
    status: string
    child_firstname: string
    child_lastname: string
    facilityIds: string
    child_id: string
    period_start_date: string
    period_end_date: string
    draft_save_flg: string
    facility_ids: string
    child_monitoring_type?: any
    digital_signature_blank?: any
  }
  isLoadingData?: boolean
}

const Wrapper = styled.div<iOperationOptionComponent>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "20px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "40px"};
  padding: 13px 30px 30px 36px;
  .search-field {
    width: 380px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .search-btn {
    margin-left: 0;
  }

  .select-up {
    margin-right: 6px;
  }

  @media (max-width: 430px) {
    padding: 15px 20px 25px 20px;
  }
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 10px;
  margin: 24px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 125px;
  max-width: 125px;
  /* different styling for checkbox field title wrap */
  &.black-spacer {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .textfield {
    margin-left: 30px;
  }
  @media (max-width: 550px) {
    width: 100%;
    max-width: 100%;
  }
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  row-gap: 10px;
  column-gap: 20px;
  width: calc(100% - 125px);
  /* Different styling for child name field container */
  &.child-name_input-field-container {
    .over-ride-sort-styling {
      max-width: 100px;
    }
    .over-ride-child-select-styling {
      @media (max-width: 990px) {
        width: 100%;
        max-width: 300px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 0px;
      & > .over-ride-sort-styling {
        margin-right: 10px;
      }
    }
  }

  /* Different styling for facility name field container*/
  &.facility-name_input-field-container {
    @media (max-width: 860px) {
      flex-wrap: wrap;
    }
    @media (max-width: 430px) {
      gap: 10px;
      button {
        padding: 6px 16px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      gap: 0;

      button:first-child {
        margin-right: 10px;
      }
    }
  }

  /* Different styling for Display setting field container */
  &.display-setting_input-field-container {
    flex-wrap: wrap;
  }

  .extend-icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    color: #191919;
    padding: 5px;
  }

  .date-picker {
    height: 40px;
    width: 160px;
    @media (max-width: 590px) {
      width: auto;
    }
  }

  .calender {
    display: flex;
    @media screen and (max-width: 424px) {
      flex-direction: column;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      color: ${theme.black};
    }

    .seperator {
      margin: auto 9px;
    }
    .ant-picker-input > input {
      height: 28px;
    }
  }

  .ant-checkbox-wrapper {
    white-space: normal !important;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    .ant-checkbox + span {
      white-space: pre-line;
      word-break: break-all;
      padding-left: 10px;
    }
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
`

const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const Buttonwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  .search-btn,
  .reset-button {
    min-width: 130px;
  }
  .search-btn {
    margin-right: 10px;
  }
  .btn-last {
    @media (max-width: 429px) {
      font-size: 12px;
      width: 100%;
      white-space: normal;
    }
  }
`

const Text = styled(Title)`
  font-size: 16px;
`

const ChildMonitoringComponent: React.FC<iOperationOptionComponent> = (
  props
) => {
  const {
    header,
    setQueryParam,
    isLoadingData,
    queryParam,
    setIsButtonClicked,
  } = props
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const [check, setCheck] = useState(
    queryParam?.facility_ids?.split(",")?.map((val) => +val)
  )
  const { facilities } = useContext(AuthContext)
  const [display, setDisplay] = useState<any>(queryParam?.display)
  const [status, setStatus] = useState(queryParam?.status?.split(",") || [])
  const [childName, setChildName] = useState(queryParam?.child_firstname)
  const [childId, setChildId] = useState(+queryParam?.child_id)
  const [facilityIds, setFacilityIds] = useState(queryParam?.facility_ids)
  const [otherFacilities, setOtherFacilities] = useState([])
  const [startJoinDate, setStartJoinDate] = useState(
    moment(queryParam?.period_start_date).isValid()
      ? moment(queryParam?.period_start_date)
      : null
  )
  const [endJoinDate, setEndJoinDate] = useState(
    moment(queryParam?.period_end_date).isValid()
      ? moment(queryParam?.period_end_date)
      : null
  )
  const [displayAlternativeStatement, setDisplayAlternativeStatement] =
    useState(queryParam?.child_monitoring_type)
  const [displayDigitalSignature, setDisplayDigitalSignature] = useState<any>(
    queryParam?.digital_signature_blank
  )
  const router = useRouter()

  const CHILD_FACILITY_OPTION = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name_short,
    }
  })

  const SORT_OPTION = FuriganaAlphabetsOption
  const { isLoading, data: childList } = useQuery(
    ["fetch-all-child"],
    getAllChildUnpaginated,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
    }
  )

  const OTHER_CHILD_OPTIONS =
    !isLoading &&
    childList?.data?.map((item) => {
      return {
        value: item?.id || "",
        label: item?.child_name || "", //Check for Furigana name first
        furigana: item?.child_name_furigana,
      }
    })
  !isLoading &&
    OTHER_CHILD_OPTIONS?.unshift({
      value: "0",
      label: t("All"),
    })
  const allFacility = CHILD_FACILITY_OPTION?.map((item) => item.value)
  const handleSearch = () => {
    setIsButtonClicked(true)
    const data = {
      child_monitoring_type: displayAlternativeStatement,
      draft_save_flg: status?.join(","),
      child_id: Number(childId) === 0 ? "" : childId,
      startJoinDate,
      period_start_date: moment(startJoinDate).isValid()
        ? moment(startJoinDate).format("YYYY-MM-DD")
        : null,
      period_end_date: moment(endJoinDate).isValid()
        ? moment(endJoinDate).format("YYYY-MM-DD")
        : null,
      facility_ids: facilityIds,
      display: display,
      digital_signature_blank: displayDigitalSignature,
    }
    setItemToLocalstorage("monitoring_list_key", {
      facility_ids: data?.facility_ids,
    })
    setQueryParam((prev) => {
      return {
        ...prev,
        ...data,
      }
    })
    updateParams(data, "/monitoring")
  }

  const handleReset = () => {
    setIsButtonClicked(true)
    setDisplay("")
    setStatus([])
    setChildName("")
    setChildId(0)
    setCheck(null)
    setStartJoinDate(null)
    setEndJoinDate(null)
    setDisplayAlternativeStatement(false)
    setDisplayDigitalSignature(false)
    setQueryParam((prev) => {
      return {
        ...prev,
        child_monitoring_type: null,
        draft_save_flg: null,
        child_id: "",
        period_start_date: null,
        period_end_date: null,
        facility_ids: "",
        display: null,
        digital_signature_blank: null,
      }
    })
    updateParams({}, "/monitoring")
  }

  const handleSort = (value) => {
    //Regex to search Furigana letter
    const regex = japaneseAlphaRegex(value)

    const filteredFacilities =
      value == 0
        ? OTHER_CHILD_OPTIONS
        : OTHER_CHILD_OPTIONS?.filter(
            (item: any) =>
              item?.furigana?.match(regex) || item?.label?.match(regex)
          )

    setChildName(value)
    setOtherFacilities(filteredFacilities)
  }
  useEffect(() => {
    setChildName("")
    setOtherFacilities(OTHER_CHILD_OPTIONS)
  }, [isLoading])

  return (
    <Wrapper {...props}>
      <Header>{header || t("Operation options")}</Header>
      <Divisor />
      <TitleWrapper>
        <Highlighter /> <Title>{t("Narrow down")} </Title>
      </TitleWrapper>

      <FieldContainer>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Child name")} </Text>
        </TitleWrap>
        <SelectWrap className={"child-name_input-field-container"}>
          <SelectInput
            width={80}
            height={40}
            options={SORT_OPTION}
            placeholder={t("All")}
            className={"select-down over-ride-sort-styling"}
            value={childName}
            onChange={(values) => {
              handleSort(values)
              setChildId(null)
            }}
          />
          {!isLoading && (
            <SelectInput
              width={300}
              height={40}
              className={"select-class over-ride-child-select-styling"}
              options={otherFacilities}
              placeholder={t("All")}
              value={childId || null}
              onChange={(values) => setChildId(values)}
            />
          )}
        </SelectWrap>
      </FieldContainer>

      <FieldContainer>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("facility name")} </Text>
        </TitleWrap>
        <SelectWrap className={"facility-name_input-field-container"}>
          <OwnerButton
            typeOf={"check-all"}
            text={t("Check all")}
            onClick={() => {
              const facilityValuesIds = allFacility.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(allFacility)
            }}
          />
          <OwnerButton
            typeOf={"secondary"}
            className={"reset-button"}
            text={t("Uncheck all")}
            backgroundColor={"#fff"}
            onClick={() => {
              setFacilityIds("")
              setCheck(null)
            }}
          />
        </SelectWrap>
      </FieldContainer>

      <FieldContainer>
        <TitleWrap className={"black-spacer"} />
        <CheckboxWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={check}
            onChange={(values) => {
              const facilityValuesIds = values.join(",")
              setFacilityIds(facilityValuesIds)
              setCheck(values)
            }}
            options={CHILD_FACILITY_OPTION}
          />
        </CheckboxWrap>
      </FieldContainer>

      <FieldContainer>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Period")} </Text>
        </TitleWrap>
        <SelectWrap>
          <div className={"calender"}>
            <DatePicker
              onDateChange={(val) =>
                setStartJoinDate(moment(val, "YYYY-MM-DD"))
              }
              date={
                moment(startJoinDate).isValid()
                  ? moment(startJoinDate, "YYYY-MM-DD")
                  : null
              }
              format={"YYYY年MM月DD日"}
              style={{ width: "150px" }}
            />
            <Typography.Text className={"seperator"}>{"~"}</Typography.Text>
            <DatePicker
              onDateChange={(val) => setEndJoinDate(moment(val, "YYYY-MM-DD"))}
              date={
                moment(endJoinDate).isValid()
                  ? moment(endJoinDate, "YYYY-MM-DD")
                  : null
              }
              format={"YYYY年MM月DD日"}
              style={{ width: "150px" }}
            />
          </div>
        </SelectWrap>
      </FieldContainer>

      <FieldContainer>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Display setting")} </Text>
        </TitleWrap>
        <SelectWrap className={"display-setting_input-field-container"}>
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={displayAlternativeStatement}
            label={t("Show only for alternative assistance")}
            onChange={(evt) => {
              setDisplayAlternativeStatement(evt.target.checked)
            }}
          />
          <Checkbox
            key={"footerInformation"}
            name={"footerInformation"}
            checked={displayDigitalSignature}
            label={t("Display only digital signature blank")}
            onChange={(evt) => {
              setDisplayDigitalSignature(evt.target.checked)
              setDisplay(evt.target.checked)
            }}
          />
        </SelectWrap>
      </FieldContainer>

      <FieldContainer>
        <TitleWrap className={"title-wrap"}>
          <img
            src={"/assets/icons/arrow.svg"}
            style={{ marginRight: "10px" }}
          />
          <Text>{t("Create status")} </Text>
        </TitleWrap>
        <SelectWrap>
          <Checkboxes
            key={"footerInformation"}
            name={"footerInformation"}
            value={status}
            onChange={(values) => {
              setStatus(values)
            }}
            options={[
              {
                label: t("Created"),
                value: "0",
              },
              {
                label: t("Draft"),
                value: "1",
              },
            ]}
          />
        </SelectWrap>
      </FieldContainer>

      <Buttonwrapper>
        <OwnerButton
          className={"search-btn"}
          text={t("Search")}
          shape={"circle"}
          icon={"search"}
          type={"submit"}
          typeOf={"primary"}
          onClick={handleSearch}
          isLoading={isLoadingData}
        />
        <OwnerButton
          typeOf={"secondary"}
          className={"reset-button"}
          text={t("Reset search")}
          onClick={handleReset}
        />
      </Buttonwrapper>
      <br />

      <TitleWrapper>
        <Highlighter /> <Title>{t("Add data")} </Title>
      </TitleWrapper>

      <Buttonwrapper className={"btn-wrapper"}>
        <OwnerButton
          className={"btn-last"}
          text={t("Create a new monitoring results record")}
          shape={"circle"}
          type={"submit"}
          onClick={() =>
            router.push(
              queryParam?.child_id
                ? `/monitoring/add?c_id=${queryParam?.child_id}`
                : `/monitoring/add`
            )
          }
          typeOf={"primary"}
        />
      </Buttonwrapper>
    </Wrapper>
  )
}

export { ChildMonitoringComponent }
