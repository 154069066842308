import {
  TopRoundedWhiteCard,
  SelectInput,
  RadioButtons,
  YES_NO_LIST,
  theme,
} from "@project/shared"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import { useEffect } from "react"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"

const OperationWrapper = styled.div`
  padding: 20px 0;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .caret-right {
    background: url(assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .operation-row {
    display: flex;
    width: 100%;
    max-width: 1000px;
    flex-wrap: wrap;
    .label-option-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .operation-label {
        width: 150px;
      }
    }
    .label-option-container:first-child {
      margin-right: 25px;
    }
  }
  .flexbox {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #191919;
    .flex_content {
      display: flex;
      gap: 0.3rem;
      align-items: center;
    }
  }
  .change-display-container {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    .operation-row {
      flex-direction: column;
      margin-bottom: 0;
      .label-option-container {
        max-width: 400px;
        width: 100%;
        margin-bottom: 10px;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    .operation-row .label-option-container {
      .facility-select-container {
        width: 100%;
      }
    }
  }
`

interface IAttendanceOptions {
  facilityOptions: Array<any>
  handleDisplayChange: any
  defaultFacility?: number
  defaultYear?: number
  defaultMonth?: number
  defaultDesiredPickUps?: string
  defaultActivity?: string
  defaultCommuter?: string
}

export const AttendanceOperation = ({
  facilityOptions,
  handleDisplayChange,
  defaultFacility = null,
  defaultYear = null,
  defaultMonth = null,
  defaultDesiredPickUps = "1",
  defaultActivity = "0",
  defaultCommuter = "0",
}: IAttendanceOptions) => {
  const initialValues = {
    facility: defaultFacility,
    year: defaultYear ?? new Date().getFullYear(),
    month: defaultMonth ?? new Date().getMonth() + 1,
    with_desired_pickup: defaultDesiredPickUps,
    with_program: defaultActivity,
    with_commuters: defaultCommuter,
  }
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      const {
        facility,
        year,
        month,
        with_desired_pickup,
        with_program,
        with_commuters,
      } = formik.values
      handleDisplayChange({
        facility,
        year,
        month,
        with_desired_pickup,
        with_program,
        with_commuters,
      })
    },
  })
  useEffect(() => {
    formik.setFieldValue("year", defaultYear)
    formik.setFieldValue("month", defaultMonth)
  }, [defaultYear, defaultMonth])

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <div className={"operation-row"}>
          <div className={"label-option-container"}>
            <div className={"caret-right operation-label"}>{t("Facility")}</div>
            <div className={"operation-option facility-select-container"}>
              <SelectInput
                className={"dynamic-dropdown-width min-w-160"}
                dropdownMatchSelectWidth={false}
                width={"auto"}
                height={40}
                options={facilityOptions}
                name={"facility"}
                value={formik.values.facility}
                onChange={(value) => {
                  formik.setFieldValue("facility", value)
                }}
                placeholder={t("All")}
              />
            </div>
          </div>

          <div className={"label-option-container"}>
            <div className={"caret-right operation-label"}>
              {t("Year months")}
            </div>
            <div className={"operation-option"}>
              <DatePicker
                date={moment({
                  year: +formik.values.year,
                  month: +formik.values.month - 1,
                })}
                style={{
                  height: "40px",
                  width: "160px",
                }}
                picker={"month"}
                format={"YYYY年MM月"}
                showArrow={true}
                disabledDate={(current) => {
                  return (
                    (current && current < moment("2015/01/01")) ||
                    (current && current > moment().add(3, "year"))
                  )
                }}
                onDateChange={(value) => {
                  formik.setFieldValue("year", moment(value).format("YYYY"))
                  formik.setFieldValue("month", moment(value).format("MM"))
                }}
              />
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container"}>
            <div className={"caret-right operation-label"}>
              {" "}
              {t("Desired pick up time")}
            </div>
            <div className={"operation-option"}>
              <RadioButtons
                options={YES_NO_LIST}
                name={"with_desired_pickup"}
                value={formik.values.with_desired_pickup}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className={"operation-row"}>
          <div className={"label-option-container"}>
            <div className={"caret-right operation-label"}>
              {t("Program name")}
            </div>
            <div className={"operation-option"}>
              <RadioButtons
                options={YES_NO_LIST}
                name={"with_program"}
                value={formik.values.with_program}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={"label-option-container"}>
            <div className={"caret-right operation-label"}>
              {t("Attendees")}
            </div>
            <div className={"operation-option"}>
              <RadioButtons
                options={YES_NO_LIST}
                name={"with_commuters"}
                value={formik.values.with_commuters}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className={"change-display-container"}>
          <OwnerButton
            text={t("Change display")}
            shape={"circle"}
            typeOf={"secondary"}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
