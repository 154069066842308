import moment from "moment"
import { API } from "@project/shared"

export interface AttendanceStaffResultsResponse {
  count: number
  data: any[]
}

export const saveAttendanceStaffResults = async (values: any) => {
  return API.post(`/attendance-staff-results`, values)
}

export const fetchAttendanceStaffResults = (id: string | string[] | number) =>
  API.get(`/attendance-staff-results/${id}`)

export const updateAttendanceStaffResults = ({
  values,
  id,
}: {
  values: any
  id: string | number
}) => API.put(`/attendance-staff-results/${id}`, { ...values })

export const resetAttendanceStaffResults = (id) =>
  API.delete(`/attendance-staff-results/${id}`)

export const fetchAllAttendanceStaffResults = ({
  searchParams,
}: {
  searchParams: {
    facilityIds: string
    date: string
  }
}): Promise<AttendanceStaffResultsResponse> => {
  const date = moment()
  const defaultDate = `${date.year()}-${
    date.month() < 10 ? "0" + (date.month() + 1) : date.month() + 1
  }-${date.day() < 10 ? "0" + (date.day() + 1) : date.day() + 1}`
  let url = `/attendance-staff-results?pageSize=Infinity`
  if (searchParams?.facilityIds)
    url = `${url}&facilityIds=${searchParams?.facilityIds}`
  if (searchParams?.date) {
    url = `${url}&date=${searchParams?.date}`
  } else {
    url = `${url}&date=${defaultDate}`
  }
  return API.get(url)
}

export const fetchStaffTimeTable = ({ staffId, date }) => {
  return API.get(`/attendance-staff-results/staff/${staffId}/${date}`)
}

export const fetchStaffListWhoseAttendanceCanBeAdded = ({ facility, date }) =>
  API.get(
    `/attendance-staff-results/add-staff/list?facility=${facility}&date=${date}`
  )
