import { useEffect, useRef, useState } from "react"

// packages
import moment from "moment"
import { Spin } from "antd"
import { useFormik } from "formik"
import { useQuery } from "react-query"
import { useReactToPrint } from "react-to-print"

// styled components
import { MainContainer } from "./styles"

// components
import {
  MidButtons,
  RecordsBlock,
  OperationControllerBlock,
} from "./components"

// types
import { PrintingOptionProps } from "./types"

// utils
import { removeDuplicates, scrollToSelectedElement } from "../../../utils"

// services
import { getAllAdditionMinutes } from "../../../services/additionMinutes"
import { useRouter } from "next/router"

export const AddAndMinMgmtBulkPrintPage = () => {
  const pageSize = 10
  const scrollRef = useRef(null)
  const componentRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [printingOptions, setPrintingOptions] = useState<PrintingOptionProps>()
  const currentLang = localStorage.getItem("i18nextLng") || "ja"
  const router = useRouter()

  const formik = useFormik({
    initialValues: {
      parentalConsent: ["1", "2", "3"],
      parentalVerification: ["1", "2", "3"],
      digitalSign: ["1", "2", "3", "4", "5"],
      stamp: ["1", "2", "3", "4"],
      parentSign: [""],
      facility_ids:
        router?.query?.facility_ids !== undefined
          ? router?.query?.facility_ids
          : undefined,
      keyword:
        router?.query?.keyword !== undefined
          ? router?.query?.keyword
          : undefined,
      use_service:
        router?.query?.use_service !== undefined
          ? router?.query?.use_service
          : undefined,
      start_date_from:
        router?.query?.start_date_from !== undefined
          ? moment(router?.query?.start_date_from)
          : undefined,
      start_date_to:
        router?.query?.start_date_to !== undefined
          ? moment(router?.query?.start_date_to)
          : undefined,
    },
    onSubmit: (values) => {
      setPrintingOptions(values)
      scrollToSelectedElement(scrollRef)
    },
  })

  const {
    isLoading,
    data: minuteData,
    refetch,
  } = useQuery(
    ["getAllAdditionMinutes", null, currentPage],
    () =>
      getAllAdditionMinutes({
        page: currentPage,
        pageSize,
        params: { ...formik.values },
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      select: (response: any) => {
        return {
          count: response?.count,
          data: response?.data?.map((data: any) => {
            const startTime = moment(
              data.event_date + " " + data.event_start_time,
              "YYYY年MM月DD日 HH:mm"
            )

            const endTime = moment(
              data.event_date + " " + data.event_end_time,
              "YYYY年MM月DD日 HH:mm"
            )

            const result = endTime.diff(startTime, "minutes")

            return {
              id: String(data.id) || "",
              //required data for printing
              facility_name: data.facility_name || "",
              service_type: data.service_type,
              consultation_support_name:
                (data?.consultation_support_name || "") +
                " " +
                (data?.consultation_support_person_name || ""),
              created_at: moment(data.created_datetime).format(
                currentLang === "en" ? "MMMM DD, YYYY" : "YYYY年MM月DD日"
              ),

              child_name: data.child_name || "",
              beneficiary_certificate_number:
                data?.receiving_certificate_number || "",
              parents_name: data.parents_name || "",
              consultation_support_office:
                data.consultation_support_office || "",
              corresponding_person: data?.recorder_staff_name || "",
              implemented_data_time:
                moment(data.event_date).format("YYYY年MM月DD日") +
                " " +
                data.event_start_time.replace(":", "時") +
                "分" +
                " - " +
                data.event_end_time.replace(":", "時") +
                "分",
              time_difference: result,
              parent_name: data.parent_name || "",
              addition_and_minutes_free_items:
                data.addition_and_minutes_free_items &&
                removeDuplicates(
                  JSON.parse(data.addition_and_minutes_free_items)
                ),
              minutes_title: data?.minutes_title || "",
            }
          }),
        }
      },
    }
  )

  const handlePagination = (page) => {
    setCurrentPage(page)
    refetch()
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    setPrintingOptions(formik.values)
  }, [])
  return (
    <div ref={componentRef}>
      <MainContainer>
        <OperationControllerBlock
          formik={formik}
          handleDisplayChange={formik.handleSubmit}
        />
        <MidButtons handlePrint={handlePrint} />
        <div ref={scrollRef}></div>
        <Spin spinning={isLoading}>
          <RecordsBlock
            printingOptions={printingOptions}
            pageSize={pageSize}
            total={minuteData?.count}
            currentPage={currentPage}
            handlePagination={handlePagination}
            allRecords={minuteData?.data}
            isLoading={isLoading}
          />
        </Spin>
      </MainContainer>
    </div>
  )
}
