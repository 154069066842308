import { API, removeBlankAttributes } from "@project/shared"

export type ChildOutputPrintDetailType = {
  parent_name: string
  parent_name_furigana: string
  parent_address: string
  parent_zip_code: string
  provision_city: string
  city_number: string
  year: number
  month: number
  day: number
  facility_id: number
  facility_name: string
  facility_name_furigana: string
  record: string
  service_delivery_date: string
  service_content: number
  date_of_receipt: string
  total_proxy_amount: string
  total_amount_cost: number
  user_burden_amount: number
  invoice_total_price: number
  invoice_reward_price: number
  invoice_number_of_credits: number
  invoice_service_code: number
  burden_max_month_without_subsidy: number
  child_name: string
}

export type ChildOutputPrintDetailResponse = {
  data: ChildOutputPrintDetailType[]
}

export const getNHIFBillingAlerts = ({ facility_id, year, month }) =>
  API.get(`/revenue-management/nhif/alerts/${facility_id}/${year}/${month}`)

export const getNHIFChildList = ({ facility_id, year, month, query = "" }) =>
  API.get(
    `/revenue-management/nhif/child/${facility_id}/${year}/${month}?${query}`
  )

export const saveNHIFBilling = (values) =>
  API.post(`/revenue-management/nhif/billing`, values)

export const getNHIFBillingSummary = (query) =>
  API.get(`/revenue-management/nhif/billing-summary?${query}`)

export const getNHIFBillingChildOutputPrintDetail = (
  query
): Promise<ChildOutputPrintDetailResponse> => {
  return API.get(`/revenue-management/nhif/child_output_print_detail`, {
    params: query,
  })
}
export const getNHIFFacilityBillingMethod = ({ facility_id, year, month }) =>
  API.get(
    `/revenue-management/nhif/billing-method/${facility_id}/${year}/${month}`
  )

export const updateFacilityBillingMethod = (values) =>
  API.post(`/revenue-management/nhif/billing-method`, values)

export const getNHIFSelectedBillingChildList = ({
  facilityIds,
  year,
  month,
  after_school,
}: {
  facilityIds: string | number
  year: string | number
  month: string | number
  after_school: string | string[]
}) => {
  return API.get(
    `/revenue-management/nhif/child_output_table?facilityIds=${facilityIds}&year=${year}&month=${month}&is_afterschool=${after_school}`
  )
}

export const getNHIFDayWiseInvoiceDetail = (query) =>
  API.get(`/revenue-management/nhif/daywise_invoice_detail?${query}`)

export const saveNHIFBillingRemarks = (payload) =>
  API.post(
    `/revenue-management/nhif/receipt-provision-details/${payload?.year}/${payload?.month}`,
    payload
  )

export const getNHIFBillingRemarks = ({ year, month }) =>
  API.get(`/revenue-management/nhif/receipt-provision-details/${year}/${month}`)

//prints-user-labels
export const getPrintsUserLabelsBillingData = ({
  facilityIds,
  year,
  month,
  billingFacilityIds,
  show_zero_invoice_children,
  is_afterschool,
}: {
  facilityIds: string | number
  year: string | number
  month: string | number
  billingFacilityIds?: string
  show_zero_invoice_children?: number
  is_afterschool: string | string[]
}) => {
  const params = removeBlankAttributes({
    billingFacilityIds,
    show_zero_invoice_children,
  })
  return API.get(
    `/revenue-management/nhif/child_output_table?facilityIds=${facilityIds}&year=${year}&month=${month}&is_afterschool=${is_afterschool}`,
    { params }
  )
}

export const getConfirmedBillingFacilities = ({ year, month, facilityIds }) =>
  API.get(
    `/revenue-management/nhif/confirmed-billing-facilities?year=${year}&month=${month}&facilityIds=${facilityIds}`
  )

export const getNHIFUsageRecordRewrite = ({ facility, year, month }: any) =>
  API.get(
    `/revenue-management/nhif/usage-record-rewrite/${facility}/${year}/${month}`
  )

export const saveNHIFUsageRecordRewrite = ({
  facility,
  year,
  month,
  payload,
}: any) =>
  API.post(
    `/revenue-management/nhif/usage-record-rewrite/${facility}/${year}/${month}`,
    payload
  )

export const updateBillingLatestType = ({
  facility,
  year,
  month,
  billing_type,
}: any) =>
  API.post(
    `/revenue-management/nhif/billing-method-type/${facility}/${year}/${month}`,
    { billing_type }
  )

export const getDisabledChildOutpatientBenefitsPrintData = (
  initialValues: any
) => {
  const params = removeBlankAttributes(initialValues)
  return API.get(`/revenue-management/nhif/disabled_child_outpatients_print`, {
    params,
  })
}

// this is a dummy route only used to update billing step after "confirm user fee" is clicked
// required to remove / update after actual calculation route is ready
export const confirmUserFee = ({ facility, year, month, form_of_provision }) =>
  API.post(
    `/revenue-management/nhif/confirm-user-fee/${facility}/${year}/${month}`,
    { form_of_provision }
  )

export const getChildListForChangeLimit = ({
  facility,
  year,
  month,
  query = "",
}) =>
  API.get(
    `/revenue-management/nhif/change-upper-limit/${facility}/${year}/${month}?${query}`
  )

export const saveChildListForChangeLimit = ({
  facility,
  year,
  month,
  payload,
}) =>
  API.post(
    `/revenue-management/nhif/change-upper-limit/${facility}/${year}/${month}`,
    payload
  )
export const getAllDecideServiceCode = () => API.get("/decide-services")

export const getFinalizedBillingFacilities = (query: string) =>
  API.get(`/revenue-management/nhif/billing-finalized-facilities?${query}`)

export const getFinalizedBillingFacilitiesForInvoiceDifference = ({
  year,
  month,
  facilityIds,
}) =>
  API.get(
    `/revenue-management/nhif/billing-finalized-facilities-for-difference-invoice?year=${year}&month=${month}&facilityIds=${facilityIds}`
  )

export const getMaximumResultSlipOutput = ({
  facilityId,
  year,
  month,
  query,
}) =>
  API.get(
    `/revenue-management/nhif/maximum-amount-management-result-slip/${facilityId}/${year}/${month}?${query}`
  )

export const getNHIFOutputSetting = ({ facilityId, year, month }) =>
  API.get(
    `/revenue-management/nhif/output-setting/${facilityId}/${year}/${month}`
  )

export const getADSBillingDifference = ({ year, month, facilityId }) =>
  API.get(
    `/revenue-management/nhif/billing-difference/${facilityId}/${year}/${month}`
  )
