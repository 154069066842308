import { theme } from "@project/shared"
import styled from "styled-components"

export const Wrapper = styled.div`
  border: 1px solid ${theme.gray2};
  border-bottom: 0;
  padding: 1rem 0.5rem;

  @media print {
    padding: 32px;
  }

  .invoice-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
  }

  .facility__sum_top_content {
    display: flex;
    gap: 0.1rem;
    justify-content: space-between;
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      & > div,
      span {
        font-size: 14px;
      }
    }
    &--right {
      .right__top {
        &--title {
          font-weight: 700;
          font-size: 12px;
        }
        &--date {
          text-align: right;
          font-size: 12px;
        }
      }
      .ant-table-wrapper {
        table {
          border-collapse: collapse;
          thead > tr {
            th {
              border: 1px solid ${theme.black} !important;
              font-size: 12px;
            }
            th:not(:last-child) {
              border-right: 0 !important;
            }
          }
          tbody > tr {
            td {
              border: 1px solid ${theme.black} !important;
              padding: 0 5px !important;
              font-size: 12px;
              .ofc__number_content {
                display: flex;
                justify-content: space-between;
                .ofc__number {
                  min-width: 60px;
                  max-width: 100%;
                  height: 100%;
                  font-size: 12px;
                  @media print {
                    min-width: 40px !important;
                  }
                }
                .ofc__number:not(:last-child) {
                  border-right: 1px solid ${theme.black};
                  font-size: 12px;
                }
              }
              & > span {
                font-size: 12px;
              }
            }
            td:not(:last-child) {
              border-right: 0 !important;
            }
            .first_cell {
              max-width: 30px;
              padding: 5px !important;
              font-size: 12px;
            }
            &.ant-table-measure-row {
              display: none;
            }
          }
        }
      }
    }
  }

  .other__content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    .facility__sum_era_content {
      display: flex;
      width: fit-content;
      font-size: 12px;
      border-right: 1px solid ${theme.black};
      border-top: 1px solid ${theme.black};
      .era__content--cell {
        border: 1px solid ${theme.black};
        padding: 4px 10px;
        text-align: center;
        font-size: 12px;
        border-right: 0;
        border-top: 0;
      }
      .era__content--cell:not(:last-child) {
        border-right: 0 !important;
      }
      .large-cell {
        width: 60px;
      }
    }
    .facility__sum_invoice_amount_content {
      display: flex;
      width: fit-content;
      border-right: 1px solid ${theme.black};
      border-top: 1px solid ${theme.black};
      .cell {
        border: 1px solid ${theme.black};
        padding: 0 15px;
        text-align: center;
        min-width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0 !important;
        border-right: 0 !important;
        gap: 0.5rem;
        font-size: 14px;
        .txt {
          font-size: 14px;
        }
        &.vertically-center {
          justify-content: center;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 14px;
      }
    }

    #facility__sum_main_table {
      .ant-table-container {
        /* border-right: 1px solid ${theme.black}; */
        /* border-bottom: 1px solid ${theme.black}; */
      }
      table {
        border-collapse: collapse;
        thead tr th {
          background: transparent !important;
          border: 1px solid ${theme.black};
          font-size: 12px;
          padding: 5px !important;

          &:before {
            display: none;
          }

          &:last-child {
            border-right: 1px solid ${theme.black};
          }
        }

        tbody tr td {
          padding: 0 !important;
          background: transparent !important;
          border: 1px solid ${theme.black};
          font-size: 12px;

          .cell__value_container {
            height: 100%;

            .cell__value {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 50px;
              max-height: 100%;
              font-size: 12px;
            }

            .cell__value:not(:first-child) {
              border-top: 1px solid ${theme.black};
            }
          }

          & > div {
            font-size: 12px;
          }

          &:last-child {
            border-right: 1px solid ${theme.black};
          }
        }
        tbody tr:first-child td {
          border-top: 1px solid ${theme.black};
        }
        tbody tr:last-child td {
          border-bottom: 1px solid ${theme.black};
        }
        tr.ant-table-row:hover > td {
          background: none !important;
        }
      }
    }

    #facility__sum_main_table table:hover,
    #facility__sum_main_table tr:hover,
    #facility__sum_main_table thead:hover {
      background: none !important;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.lg}) {
    .facility__sum_top_content {
      flex-direction: column;
      &--right {
        overflow-x: auto;
      }
    }
    .other__content-container {
      .facility__sum_invoice_amount_content {
        width: 100%;
        overflow-x: auto;
      }
      #facility__sum_main_table {
        overflow-x: auto;
        width: 100%;
        table {
          overflow-x: auto;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xxs}) {
    .facility__sum_era_content {
      width: 100% !important;
      overflow-x: auto;
      .era__content--cell {
        padding: 4px 7px !important;
      }
    }
    .facility__sum_invoice_amount_content {
      .cell {
        padding: 0 7px !important;
      }
    }
  }

  @media print {
    border: 0 !important;
    #form__num {
      display: block !important;
    }
    .other__content-container {
      gap: 0.5rem !important;
      margin-top: 4px !important;
      #facility__sum_main_table {
        margin-top: 10px;
        table {
          page-break-inside: auto !important;
          thead {
            display: table-header-group !important;
            tr {
              th {
                outline: none !important;
              }
            }
          }
          tbody {
            tr {
              page-break-inside: avoid !important;
              page-break-after: auto !important;
              td {
                .cell__value_container {
                  .cell__value {
                    min-height: 10 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
