import { TopRoundedWhiteCard, theme } from "@project/shared"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { OwnerTable } from ".."
import { useRouter } from "next/router"

const Wrapper = styled.div`
  margin: 40px 0;
  .top-buttons {
    margin: 16px 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    button {
      padding: 6px 20px;
    }
  }
  .back-print-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    .back-button {
      background: rgb(205, 230, 244);
      color: #000000;
      border-color: ${theme.blue5};
    }
    .print-button {
      background: ${theme.blue5};
      border: none;
    }
    .total-container {
      border: 1px solid #d7d7d7;
      padding: 8px 20px;
    }
  }
  .child-table {
    margin: 20px 0;
    .ant-table {
      overflow-x: auto;
    }
    .ant-table-thead {
      th {
        background: ${theme.gray2};
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: #000000;
    }
    .ant-table-tbody {
      tr td {
        border-left: 1px solid ${theme.gray2};
        border-bottom: 1px solid ${theme.gray2};
        &:last-child {
          border-right: 1px solid ${theme.gray2};
        }
      }
    }
  }
  @media print {
    display: none;
  }
`

interface IUserServiceRecordCareChildTable {
  childTableColumn: Array<any>
  childData: Array<any>
  selectCount: number
  checkAction: (type) => void
  printAction: () => void
}

export const UserServiceRecordCareChildTable = ({
  childTableColumn,
  childData,
  selectCount,
  checkAction,
  printAction,
}: IUserServiceRecordCareChildTable) => {
  const { t } = useTranslation()
  const router = useRouter()
  const BackPrintTotal = (showTotal = false) => {
    return (
      <div className={"back-print-container"}>
        <OwnerButton
          typeOf={"primary"}
          text={`<< ${t("Back")}`}
          className={"back-button"}
          onClick={() => {
            router.push("/user-service-provision-result")
          }}
        />
        <OwnerButton
          typeOf={"print"}
          text={t("Print")}
          className={"print-button"}
          disabled={!selectCount}
          onClick={printAction}
        />
        {showTotal && (
          <div className={"total-container"}>
            {t("totalPeople", { count: selectCount })}
          </div>
        )}
      </div>
    )
  }

  return (
    <Wrapper>
      <div className={"child-print-container"}>
        <TopRoundedWhiteCard title={`▼ ${t("Select child you want to print")}`}>
          <div className={"top-buttons"}>
            <OwnerButton
              typeOf={"check-all"}
              text={t("Check all")}
              onClick={() => {
                checkAction(true)
              }}
            />
            <OwnerButton
              typeOf={"secondary"}
              text={t("Uncheck all")}
              onClick={() => {
                checkAction(false)
              }}
            />
          </div>
          {BackPrintTotal(true)}
          <div className={"child-table"}>
            <OwnerTable columns={childTableColumn} dataSource={childData} />
          </div>
          {BackPrintTotal()}
        </TopRoundedWhiteCard>
      </div>
    </Wrapper>
  )
}
